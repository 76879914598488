import moment from "moment";
//import { getERPASValidDates } from "../common/utils"
const isDevEnv = false;
export const REACT_APP_API_URL = isDevEnv ? `${process.env.REACT_APP_API_URL}` : `${process.env.REACT_APP_PROD_API_URL}`;
export const REACT_APP_SITE_KEY = `${process.env.REACT_APP_SITE_KEY}`

export const API_KEY =  isDevEnv ?`${process.env.REACT_APP_API_KEY}`:`${process.env.REACT_APP_PROD_API_KEY}`
export const CLIENT_ID = isDevEnv ? `${process.env.REACT_APP_CLIENT_ID}`:`${process.env.REACT_APP_PROD_CLIENT_ID}`

export const DEFAULT_COUNTRY = {
  name: 'Sri Lanka',
  id: 'e2f886a7-3299-4c82-af2e-987fe92fab40',
  latitude: 7.6237,
  longitude: 80.6966,
  zoom_level: 8,
};

export const SRILANKA_COORDINATE = {
  lat: 7.6237,
  long: 80.6966,
};

export const DEFAULT_ATTRIBUTES_FARM_TRACKER = {
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  AREA: 'Area'
}

export const ADMIN_DISCLAIMER = `Disclaimer: IWMI has been following the guidelines of the UN on territorial boundaries for publications. The
guidelines can be accessed from: https://www.ifpri.org/sites/default/files/guidelines_countries-territories-maps.pdf`

export const LOCATION = {
  Country: 'Country',
  State: 'State',
  District: 'District',
  Province: 'Province',
  Divisions: 'Divisions',
  Sub_District: 'subdistrict',
  Block: 'block'
};

export const FILE_EXTENSION = {
  MP4: 'mp4',
};

export const ERPAS_STATIC_CONST = {
  id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
  name: 'ERPAS (Static)',
}

export const MONTHS = [
  { value: '01', label: 'January', days: 31 },
  { value: '02', label: 'February', days: 28 },
  { value: '03', label: 'March', days: 31 },
  { value: '04', label: 'April', days: 30 },
  { value: '05', label: 'May', days: 31 },
  { value: '06', label: 'June', days: 30 },
  { value: '07', label: 'July', days: 31 },
  { value: '08', label: 'August', days: 31 },
  { value: '09', label: 'September', days: 30 },
  { value: '10', label: 'October', days: 31 },
  { value: '11', label: 'November', days: 30 },
  { value: '12', label: 'December', days: 31 },
];

export const YEARS = [
  { value: 2022, label: 2022 },
  { value: 2021, label: 2021 },
];

export const DROUGHT_MODULES_CONST = [
  {
    value: 'drought prediction',
    label: 'Drought Prediction',
    api_slug: 'drought-prediction',
  },
  {
    value: 'drought decision support',
    label: 'Drought Decision Support',
    api_slug: '',
  },
  {
    value: 'drought monitor',
    label: 'Drought Monitor',
    api_slug: 'drought-monitor',
  },
  {
    value: 'other indices',
    label: 'Other Indices',
    api_slug: 'drought-monitor-v2',
  },
];

export const LOCATION_ZOOM_LEVEL_THRESHOLD_CONSTS  = [
  { location_type: 'country', zoom_threshold:[4.5,5,6,7,8]},
  { location_type: 'states_or_provinces', zoom_threshold: [9]},
  { location_type: 'districts_or_cities', zoom_threshold: [10]},
  { location_type: 'talukas', zoom_threshold: [11,12]},
]

export const DROUGHT_MODULE_INDICATORS = [
  { id: 'Mandatory', name: 'Mandatory', category: 'Mandatory' },
  { id: 'Impact', name: 'Impact', category: 'Impact' },
];

export const CROP_MONITOR_INDICATORS_CONST = {
  SAR: "sentinel1_raw_data",
  SAR_RGB: "sentinel1_rgb_visualization"
}

export const CROP_PRODUCTION_FILTER_TABS = {
  SEASONAL: { label: "Seasonal", value: 'seasonal'  },
  PERENNIAL: { label: "Perennial", value: 'perennial' }
}

export const WEATHER_MONITORING_GLDAS_BANDS = [
  {
    id: 'bb77ca3b-fa8e-4943-be55-3d7e0fd7c799',
    max: '1323.35',
    min: '187.48',
    name: 'Average Surface Skin Temperature',
    unit: '°C'
  },
  {
    id: 'bc16e126-caae-4416-b5c6-e42336e637f2',
    max: '0.07',
    min: '-0.02',
    name: 'Specific Humidity ',
    unit: 'kg/kg'
  },
  {
    id: '65586d92-dd9b-4534-a82d-ee6169cc1b16',
    max: '0.01',
    min: '0',
    name: 'Rain Precipitation Rate',
    unit: 'kg/m^2/s'
  },
  {
    id: 'ae3a7ef5-48e2-4357-8a45-0cbcbacb5d69',
    max: '57.7',
    min: '0',
    name: 'Wind Speed',
    unit: 'm/s'
  },
];

export const CROP_MONITOR_MIN_MAX_HIDE_INDICATORS_SLUG = [
  "sm_swadi",
  "sm_smci",
  "cwp",
  "sentinel1_raw_data",
  "sentinel1_rgb_visualization",
  "reservoir_extent",
  "flooding"
]

export const RISK_ANALYTICS_GRAPH_INDICATORS_CATEGORY = [
  { id:'crop-indicators', slug_list: ["ndvi","smci","modis_vci"]},
  { id:'weather-indicators', slug_list:["dry_spell","spi","rain_fall"]},
  { id:'combined-indicators', slug_list:["risk_score","idsi"]}
]

export const CROP_PRODUCTION_FILTER_SLUG_CONSTS = {
  MAJOR_SLUG: 'major',
  MINOR_SLUG: 'minor',
  YALA_SLUG: 'yala',
  MAHA_SLUG: 'maha'
}

export const DROUGHT_TEMPORAL_AGGREGATION = [
  { id: 0, name: 'Mean', value: 'mean', label: 'Mean' },
  // { id: 1, name: 'Median', value: 'median', label: 'Median' },
  { id: 2, name: 'Minimum', value: 'min', label: 'Minimum' },
  { id: 3, name: 'Maximum', value: 'max', label: 'Maximum' },
];

export const POLYGON_STYLING_COLORS = [
  '#ff0000',
  '#ff8000',
  '#ffbf00',
  '#ffff00',
  '#bfff00',
  '#80ff00',
  '#00ff80',
  '#00ffff',
  '#00bfff',
  '#0080ff',
  '#0040ff',
  '#8000ff',
  '#bf00ff',
  '#ff00ff',
  '#ff00bf',
  '#ff0080',
  '#ff0040',
  '#e67e22',
  '#e74c3c',
  '#ecf0f1',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d',
  '#66ffcc',
  '#008000',
  '#8B0000',
  '#FFDAB9',
  '#EEE8AA',
  '#BDB76B',
  '#808000',
  '#556B2F',
  '#008B8B',
  '#66CDAA',
  '#483D8B',
  '#000000',
  '#FFC0CB',
  '#8B008B',
]

export const DEFAULT_PARAMETERS_CONST = {
  PRECIPITATION: 'Precipitation',
  CUMULATIVE_ANAMOLY: 'Cumulative anomaly',
};

export const MAX_NO_OF_XAXIS_TICKS = 50;

export const RISK_ANALYTICS_GRAPH_INDICATORS_LIST = [
  { id: 'crop-indicators', label: 'Crop Indicators', list: [], selectedIndicators: [] },
  { id: 'weather-indicators', label: 'Weather Indicators', list: [], selectedIndicators: [] },
  { id: 'combined-indicators', label: 'Combined', list: [], selectedIndicators: [] },
]

export const FARM_TRACKER_OPTIONS = [
  { id:'draw_farm', selected: false},
  {id: 'upload_farm', selected: false}
]

export const OPEN_WEATHER_PARAMETERS_CONST = [
  {
    id: 'Temperature',
    name: 'Temperature',
    layerName: 'temp_new',
    measure: '°C',
    min: -20,
    max: +40,
    //palette: ['#821692','#821692','#821692','#821692','#8257db','#208cec','#20c4e8','#23dddd','#c2ff28','#fff028','#ffc228','#fc8014'],
    dataSource: 'Open Weather',
    parameter: 'Temperature',
    image: 'temp-background',
  },
  {
    id: 'Rainfall',
    name: 'Rainfall',
    layerName: 'rain_cls',
    measure: 'mm',
    min: 0.1,
    max: 100,
    // palette: ['#000000','#000000','#000000','#000000','#6d6dcd','#5050e1','#1414ff'],
    dataSource: 'Open Weather',
    parameter: 'Rainfall',
    image: 'rain-background',
  },
  {
    id: 'Precipitation',
    name: 'Precipitation',
    layerName: 'precipitation_cls',
    measure: 'mm/h',
    min: 0,
    max: 60,
    palette: [
      '#0000BB',
      '#0000F7',
      '#004FFE',
      '#00ADFE',
      '#07FDF7',
      '#4FFDAF',
      '#9BFE63',
      '#E3FE1B',
      '#FED300',
      '#FE8B00',
      '#FF3F00',
      '#F70000',
      '#BB0000',
    ],
    dataSource: 'Open Weather',
    parameter: 'Precipitation',
    image: '',
  },
  {
    id: 'Wind speed',
    name: 'Wind speed',
    layerName: 'wind_new',
    measure: 'm/s',
    min: 0,
    max: 100,
    //palette: ['#000000','#edcdcd','#b264bc','#b264bc','#3e203b','#734bac','#4600af','#0d1126'],
    dataSource: 'Open Weather',
    parameter: 'Wind speed',
    image: 'wind-background',
  },
  {
    id: 'Cloud',
    name: 'Cloud',
   // layerName: 'clouds_new',
    layerName: 'clouds_cls',
    measure: '%',
    min: 0,
    max: 100,
    //palette: ['#000000','#edcdcd','#b264bc','#b264bc','#3e203b','#734bac','#4600af','#0d1126'],
    dataSource: 'Open Weather',
    parameter: 'Cloud',
    image: 'cloud-background',
  },
  {
    id: 'Pressure',
    name: 'Pressure',
    layerName: 'pressure_new',
    measure: 'hPa',
    min: 950,
    max: 1070,
    //palette: ['#000000','#edcdcd','#b264bc','#b264bc','#3e203b','#734bac','#4600af','#0d1126'],
    dataSource: 'Open Weather',
    parameter: 'Pressure',
    image: 'pressure-background',
  },
];

export const MONTHS_LIST = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const SELECT = 'select'
export const TALUKAS = 'talukas'

export const FORECAST_LEAD_TIME_LIST = [
  {value: 1, label: "January", id:1.0, name: "January"},
  {value: 2, label: "February", id:2.0, name: "February"},
  {value: 3, label: "March", id:3.0, name:"March"},
  {value: 4, label: "April", id:4.0, name:"April"},
  {value: 5, label: "May", id:5.0, name:"May"},
  {value: 6, label: "June", id:6.0, name:"June"},
  {value: 7, label: "July", id:7.0, name:"July"},
  {value: 8, label: "August", id:8.0, name:"August"},
  {value: 9, label: "September", id:9.0, name:"September"},
  {value: 10, label: "October", id:10.0, name:"October"},
  {value: 11, label: "November", id:11.0, name:"November"},
  {value: 12, label: "December", id:12.0, name:"December"},
]

export const GEOJSON_COUNTRY_TOOLTIP_DATA = [
  {
    selectedCountry: 'South Asia',
    country: 'NAME_EN'
  },
  {
    selectedCountry: 'Afghanistan',
    states_or_provinces: 'Province_N',
    districts_or_cities: 'Dist_name',
    talukas: '',
    meteorological_locations: 'Station_Na',
    basins: 'MJ_BASIN__',
    rivers_or_reservoirs: 'R_NAME',
  },
  {
    selectedCountry: 'Bangladesh',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: 'NAME_3',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Bhutan',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: '',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'India',
    states_or_provinces: 'stname',
    districts_or_cities: 'dtname',
    talukas: 'sdtname',
    meteorological_locations: '',
    basins: 'MAJ_NAME',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Nepal',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: 'NAME_3',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Pakistan',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: 'NAME_3',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Sri Lanka',
    states_or_provinces: 'ADM1_EN',
    districts_or_cities: 'ADM2_EN',
    talukas: 'ADM3_EN',
    meteorological_locations: 'ST_Name',
    basins: 'basin_name',
    rivers_or_reservoirs: 'Name',
  },
];

export const GEOJSON_LABELS = [
  'country',
  'states_or_provinces',
  'districts_or_cities',
  'talukas',
   "meteorological_locations",
  'basins',
  'rivers_or_reservoirs',
]
export const weatherForecastIndices = [
  'Open Weather',
  'GFS - NOAA',
  'ERPAS',
  'IRI',
  'ERPAS (Static)',
  'ECMWF (Static)',
  'CPC (Static)',
];
const getERPASValidDates = () => {
  const t =
    new Date().getDate() + (6 - new Date().getDay() - 1) - (new Date().getDay() === 6 ? 0 : 7);
  const lastFriday = new Date();
  lastFriday.setDate(t);
  let endDate = moment(lastFriday)
    .add(28, 'days')
    .format('YYYY-MM-DD');
  return endDate;
};

export const WEATHER_FORECAST_MODULE = {
  id : "c9a6f07e-940c-4579-b213-b6746edb8224"
}
export const CROP_HEALTH_MODULE = {
  id : "afeaf56b-c3e6-48bf-b159-6bdba64c2c78"
}
export const FARM_TRACKER_MODULE = {
  id : "989f833d-67d5-4975-94c6-934c3be7066d"
}

export const RISK_ANALYTICS_MODULE = {
  id:"c9e8233c-5e8b-4dcc-8c94-ebf3357d1493"
}

export const FIELD_DATA_MODULE = {
  id:"b026932e-1cb7-4103-9242-b81830bfc858"
}


export const DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_FORMAT = 'DD-MM-YYYY';
export const DISPLAY_CHART_DATE = 'DD/MM/YYYY';
export const DISPLAY_CHART_DATE_TWO = 'YYYY-MM-DD';
export const DATE_FORMAT_ONE = 'YYYY-MM-DD hh:mm:ss';

export const DATA_SOURCES = {
  OPEN_WEATHER: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
    name: 'Open Weather',
    dateRange: {
      endDate: moment()
        .add(6, 'days')
        .format(DATE_FORMAT),
      minStartDate: moment().format(DATE_FORMAT),
      startDate: moment().format(DATE_FORMAT),
      maxStartDate: moment()
        .add(6, 'days')
        .format(DATE_FORMAT),
      maxEndDate: moment()
        .add(6, 'days')
        .format(DATE_FORMAT),
    },
  },
  GFS_NOAA: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
    name: 'GFS - NOAA',
    dateRange: {
      endDate: moment()
        .add(15, 'days')
        .format(DATE_FORMAT),
      minStartDate: moment('2015-07-01T00:00:00Z').format(DATE_FORMAT),
      startDate: moment().format(DATE_FORMAT),
      maxStartDate: moment()
        .add(15, 'days')
        .format(DATE_FORMAT),
      maxEndDate: moment()
        .add(15, 'days')
        .format(DATE_FORMAT),
    },
  },
  ERPAS: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
    name: 'ERPAS',
    dateRange: {
      startDate: moment().format(DATE_FORMAT),
      endDate: getERPASValidDates(),
      minStartDate: moment('2019-05-01T00:00:00Z').format(DATE_FORMAT),
      maxStartDate: getERPASValidDates(),
      maxEndDate: getERPASValidDates(),
    },
  },
  ECMWF: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
    name: 'ECMWF (Static)',
  },
  CPC: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
    name: 'CPC (Static)',
  },
  IRI: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
    name: 'IRI',
    parameterName: 'Probability (%) of Most Likely Category'
  }
};

export const TEMPORAL_AGGREGATION = [
  { id: 0, name: 'Mean', value: 'mean', label: 'Mean' },
  { id: 1, name: 'Median', value: 'median', label: 'Median' },
  { id: 2, name: 'Minimum', value: 'min', label: 'Minimum' },
  { id: 3, name: 'Maximum', value: 'max', label: 'Maximum' },
];




export const STATIC_RESOURCE_GET_CATEGORY_LIST = [
  {
    category: 'Weather Forecast',
    data: [
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa103',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
          name: 'Open Weather',
          summary: '',
          type: 'non-static',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf72',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf72',
            name: 'Weather Forecast',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf48',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                name: 'Precipitation',
                icon:
                  'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Precipitation.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf49',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon:
                  'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf50',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                name: 'Wind speed',
                icon: 'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Wind_speed.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf51',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab05',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab05',
                name: 'Wind direction',
                icon:
                  'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Wind_direction.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf52',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                name: 'Humidity',
                icon: 'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Humidity.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf53',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab07',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab07',
                name: 'Wind Gust',
                icon: 'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Wind_Gust.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa101',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
          name: 'GFS - NOAA',
          summary: '',
          type: 'non-static',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf72',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf72',
            name: 'Weather Forecast',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf34',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                name: 'Precipitation',
                icon:
                  'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Precipitation.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf35',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon:
                  'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf36',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                name: 'Wind speed',
                icon: 'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Wind_speed.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf38',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                name: 'Humidity',
                icon: 'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Humidity.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
    ],
  },
  {
    category: 'Sub - Seasonal',
    data: [
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa100',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
          name: 'ERPAS',
          summary: '',
          type: 'non-static',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
            name: 'Sub - Seasonal',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf26',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
                name: 'Rainfall',
                icon: 'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Rainfall.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf28',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon:
                  'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa105',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
          name: 'ECMWF (Static)',
          summary: '',
          type: 'static',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
            name: 'Sub - Seasonal',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf61',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
                name: 'Rainfall',
                icon: 'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Rainfall.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf63',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon:
                  'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa107',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
          name: 'ERPAS (Static)',
          summary: '',
          type: 'static',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
            name: 'Sub - Seasonal',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf75',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
                name: 'Rainfall',
                icon: 'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Rainfall.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf77',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon:
                  'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf78',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                name: 'Wind speed',
                icon: 'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Wind_speed.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
    ],
  },

  {
    category: 'Seasonal',
    data: [
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa106',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
          name: 'CPC (Static)',
          summary: '',
          type: 'static',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
            name: 'Seasonal',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
                name: 'Rainfall',
                icon: 'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Rainfall.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf70',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon:
                  'https://idmt.s3.ap-south-1.amazonaws.com/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
      {
        id: "e2f886a7-3299-4c82-af2e-123fe92fa126",
        country_id: "e2f886a7-3299-4c82-af2e-987fe92fab40",
        forecast_data_source_id: "e2f886a7-3299-4c82-af2e-123fe92faf84",
        forecast_data_source: {
          id: "e2f886a7-3299-4c82-af2e-123fe92faf84",
          name: "IRI",
          type: "non-static",
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
            name: 'Seasonal',
          },
          forecast_data_source_with_parameter: [
            {
            id:"",
            forecast_data_source_id:"e2f886a7-3299-4c82-af2e-123fe92faf84",
            parameter_id: "e2f886a7-3299-4c82-af2e-123fe92fab02",
            parameter: {
              id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
              name: 'Precipitation',
              icon: 'https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/precipitation.svg',
              status: true,
              drought_indices_category_id: null,
            },
            status: true,
            }, 
            // {
            //   "type": "",
            //   "weather_forecast_parameter": [
            //     {
            //       "id": "e2f886a7-3299-4c82-af2e-123fe92faf41",
            //       "weather_forecast_source_id": "e2f886a7-3299-4c82-af2e-123fe92faf84",
            //       "weather_forecast_parameter_id": "e2f886a7-3299-4c82-af2e-123fe92fab02",
            //       "weather_forecast_parameter": {
            //         "id": "e2f886a7-3299-4c82-af2e-123fe92fab02",
            //         "name": "Precipitation",
            //         "icon": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/precipitation.svg",
            //         "icon_selected": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/precipitation_selected.svg",
            //         "status": true,
            //         "slug": "rain_ind_",
            //         "parameter_type": "",
            //         "min_date": "",
            //         "max_date": ""
            //       },
            //       "status": true
            //     },
            //     // {
            //     //   "id": "e2f886a7-3299-4c82-af2e-123fe92faf42",
            //     //   "weather_forecast_source_id": "e2f886a7-3299-4c82-af2e-123fe92faf84",
            //     //   "weather_forecast_parameter_id": "e2f886a7-3299-4c82-af2e-123fe92fab03",
            //     //   "weather_forecast_parameter": {
            //     //     "id": "e2f886a7-3299-4c82-af2e-123fe92fab03",
            //     //     "name": "Temperature",
            //     //     "icon": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/temperature.svg",
            //     //     "icon_selected": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/temperature_selected.svg",
            //     //     "status": true,
            //     //     "slug": "temp",
            //     //     "parameter_type": "",
            //     //     "min_date": "",
            //     //     "max_date": ""
            //     //   },
            //     //   "status": true
            //     // },
            //     // {
            //     //   "id": "e2f886a7-3299-4c82-af2e-123fe92faf43",
            //     //   "weather_forecast_source_id": "e2f886a7-3299-4c82-af2e-123fe92faf84",
            //     //   "weather_forecast_parameter_id": "e2f886a7-3299-4c82-af2e-123fe92fab04",
            //     //   "weather_forecast_parameter": {
            //     //     "id": "e2f886a7-3299-4c82-af2e-123fe92fab04",
            //     //     "name": "Wind speed",
            //     //     "icon": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/wind_speed.svg",
            //     //     "icon_selected": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/wind_speed_selected.svg",
            //     //     "status": true,
            //     //     "slug": "wind_speed",
            //     //     "parameter_type": "",
            //     //     "min_date": "",
            //     //     "max_date": ""
            //     //   },
            //     //   "status": true
            //     // },
            //     // {
            //     //   "id": "e2f886a7-3299-4c82-af2e-123fe92faf44",
            //     //   "weather_forecast_source_id": "e2f886a7-3299-4c82-af2e-123fe92faf84",
            //     //   "weather_forecast_parameter_id": "e2f886a7-3299-4c82-af2e-123fe92fab05",
            //     //   "weather_forecast_parameter": {
            //     //     "id": "e2f886a7-3299-4c82-af2e-123fe92fab05",
            //     //     "name": "Wind direction",
            //     //     "icon": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/wind_direction.svg",
            //     //     "icon_selected": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/wind_direction_selected.svg",
            //     //     "status": true,
            //     //     "slug": "wind_dir",
            //     //     "parameter_type": "",
            //     //     "min_date": "",
            //     //     "max_date": ""
            //     //   },
            //     //   "status": true
            //     // },
            //     // {
            //     //   "id": "e2f886a7-3299-4c82-af2e-123fe92faf45",
            //     //   "weather_forecast_source_id": "e2f886a7-3299-4c82-af2e-123fe92faf84",
            //     //   "weather_forecast_parameter_id": "e2f886a7-3299-4c82-af2e-123fe92fab06",
            //     //   "weather_forecast_parameter": {
            //     //     "id": "e2f886a7-3299-4c82-af2e-123fe92fab06",
            //     //     "name": "Humidity",
            //     //     "icon": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/humidity.svg",
            //     //     "icon_selected": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/humidity_selected.svg",
            //     //     "status": true,
            //     //     "slug": "humdity",
            //     //     "parameter_type": "",
            //     //     "min_date": "",
            //     //     "max_date": ""
            //     //   },
            //     //   "status": true
            //     // },
            //     // {
            //     //   "id": "e2f886a7-3299-4c82-af2e-123fe92faf46",
            //     //   "weather_forecast_source_id": "e2f886a7-3299-4c82-af2e-123fe92faf84",
            //     //   "weather_forecast_parameter_id": "e2f886a7-3299-4c82-af2e-123fe92fab07",
            //     //   "weather_forecast_parameter": {
            //     //     "id": "e2f886a7-3299-4c82-af2e-123fe92fab07",
            //     //     "name": "Wind gust",
            //     //     "icon": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/wind_gust.svg",
            //     //     "icon_selected": "https://eo4ai.s3.ap-south-1.amazonaws.com/uat/parameter_icons/wind_gust_selected.svg",
            //     //     "status": true,
            //     //     "slug": "wind_gust",
            //     //     "parameter_type": "",
            //     //     "min_date": "",
            //     //     "max_date": ""
            //     //   },
            //     //   "status": true
            //     // }
            //   ]
            // }
          ]
        }
      },
    ],
  },
];

export const DATASOURCE_PARAMETER = [
  {id: "e2f886a7-3299-4c82-af2e-123fe92faf85", value: "OPEN_WEATHER_PARAMETER_SLUG"},
  {id: "e2f886a7-3299-4c82-af2e-123fe92faf81", value: "ERPAS_PARAMETER_SLUG"},
  {id: "e2f886a7-3299-4c82-af2e-123fe92faf83", value: "NOAA_PARAMETER_SLUG"},
  {id: "e2f886a7-3299-4c82-af2e-123fe92faf87", value: "ECMWF_PARAMETER_SLUG"},
  {id: "e2f886a7-3299-4c82-af2e-123fe92faf88", value: "CPC_PARAMETER_SLUG"},
  {id: "e2f886a7-3299-4c82-af2e-123fe92faf84", value: "IRI_PARAMETER_SLUG"},
  {id: "e2f886a7-3299-4c82-af2e-123fe92faf89", value: "ERPAS_STATIC_PARAMETER_SLUG"}
]


export const ERPAS_STATIC_PARAMETER_SLUG = [
  { name: 'Cumulative', value: 'Cumulative Rainfall', api_slug: 'serfactual'},
  { name: 'Cumulative anomaly', value: 'Cumulative Rainfall Anomaly', api_slug: 'serfanom'},
  { name: 'Maximum', value: 'Maximum Temperature', api_slug: 'tmax'},
  { name: 'Maximum anomaly', value: 'Maximum Temperature Anomaly', api_slug: 'setmaxanom'},
  { name: 'Minimum', value: 'Minimum Temperature', api_slug: 'tmin'},
  { name: 'Minimum anomaly', value: 'Minimum Temperature Anomaly', api_slug: 'setminanom'},
  { name: "Wind actual (200 hpa)", value: 'Wind Actual (200 hpa)', api_slug: "se200wactual"},
  {name: "Wind anomaly (200 hpa)", value: 'Wind Anomaly (200 hpa)', api_slug: "se200wanom"},
  {name: "Wind actual (500 hpa)", value: "Wind Actual (500 hpa)", api_slug:"se500wactual"},
  {name: "Wind anomaly (500 hpa)", value: "Wind Anomaly (500 hpa)", api_slug: "se500wanom"},
  {name: "Wind actual (850 hpa)", value: "Wind Actual (850 hpa)", api_slug: "se850wactual"},
  {name: "Wind anomaly (850 hpa)", value: "Wind Anomaly (850 hpa)" ,api_slug: "se850wanom"}
]

export const ERPAS_PARAMETER_SLUG = [
  { name: 'Precipitation', value: 'Precipitation', api_slug: 'rain_ind_' },
  { name: 'Rainfall_climatology', value: 'Precipitation climatology', api_slug: 'rain_ind_clim_' },
  { name: 'Relative', value: 'Relative Humidity', api_slug: 'rh2m' },
  { name: 'Relative_climatology', value: 'Relative Humidity climatology', api_slug: 'rh2m_clim_' },
  { name: 'Minimum', value: 'Minimum Temperature', api_slug: 'tmin' },
  { name: 'Maximum', value: 'Maximum Temperature', api_slug: 'tmax' },
  { name: 'Minimum_climatology', value: 'Minimum Temperature climatology', api_slug: 'tmin_clim_' },
  { name: 'Maximum_climatology', value: 'Maximum Temperature climatology', api_slug: 'tmax_clim_' },
];

export const IRI_PARAMETER_SLUG = [
  { name: 'Precipitation', value: 'Precipitation', api_slug: 'rain_ind_' },
  { name: 'Rainfall_climatology', value: 'Rainfall climatology', api_slug: 'rain_ind_clim_' },
  { name: 'Relative', value: 'Relative', api_slug: 'rh2m' },
  { name: 'Relative_climatology', value: 'Relative climatology', api_slug: 'rh2m_clim_' },
  { name: 'Minimum', value: 'Minimum', api_slug: 'tmax' },
  { name: 'Maximum', value: 'Maximum', api_slug: 'tmin' },
  { name: 'Minimum_climatology', value: 'Minimum climatology', api_slug: 'tmax_clim_' },
  { name: 'Maximum_climatology', value: 'Maximum climatology', api_slug: 'tmin_clim_' },
]

export const NOAA_PARAMETER_SLUG = [
  {
    name: 'Precipitation',
    value: 'Precipitation',
    api_slug: 'precipitable_water_entire_atmosphere',
  },
  { name: 'Temperature', value: 'Temperature', api_slug: 'temperature_2m_above_ground' },
  { name: 'Wind_speed', value: 'Wind speed', api_slug: 'u_component_of_wind_10m_above_ground' },
  { name: 'Humidity', value: 'Humidity', api_slug: 'relative_humidity_2m_above_ground' },
];


export const COUNTRIES_JSON_LIST = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

// export const bannersData = 
// {
//   "result": [
//     {
//       "id": "3a1a2dfe-0401-11ec-9a03-0242ac130003",
//       "created_date": "2021-08-11T09:27:28.116000Z",
//       "updated_date": "2021-08-11T09:27:28.116000Z",
//       "banner_image": "https://d9humphp55mhv.cloudfront.net/uat/banners/banner1.png",
//       "banner_image_name": "banner1",
//       "banner_title": "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     },
//     {
//       "id": "3a1a2dfe-0401-11ec-9a03-0242ac130004",
//       "created_date": "2021-08-11T09:27:28.116000Z",
//       "updated_date": "2021-08-11T09:27:28.116000Z",
//       "banner_image": "https://d9humphp55mhv.cloudfront.net/uat/banners/banner2.png",
//       "banner_image_name": "banner2",
//       "banner_title": "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     },
//     {
//       "id": "3a1a2dfe-0401-11ec-9a03-0242ac130005",
//       "created_date": "2021-08-11T09:27:28.116000Z",
//       "updated_date": "2021-08-11T09:27:28.116000Z",
//       "banner_image": "https://d9humphp55mhv.cloudfront.net/uat/banners/banner3.png",
//       "banner_image_name": "banner3",
//       "banner_title": "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     },
//     {
//       "id": "3a1a2dfe-0401-11ec-9a03-0242ac130006",
//       "created_date": "2021-10-29T09:27:28.116000Z",
//       "updated_date": "2021-10-29T09:27:28.116000Z",
//       "banner_image": "https://d9humphp55mhv.cloudfront.net/uat/banners/banner4.png",
//       "banner_image_name": "banner4",
//       "banner_title": "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     }
//   ],
//   "code": 200
// }

export const UserList = {
  result: {
    "count": 20,
    "next": "http://3.109.78.90:8000/accesskey/getAllByType/approve?limit=10&page=2",
    "previous": null,
    "results": [
      {
        "id": "4a47486c-e703-43f7-8476-fcf5ea454f17",
        "name": "gaurav",
        "email": "grv.12@yopmail.com",
        "request_status": "approve",
        "access_key": "INfXsUSt.pExtW1yLsRC9y1dzuPWqQQPxn2Q7Pyzw",
        "expiry_status": "active",
        "country_name": "Costa Rica"
      },
      {
        "id": "f447be72-2928-4b91-9ffb-ca2de11c4ff9",
        "name": "Ajantha test user2",
        "email": "ajanthai@yahoo.com",
        "request_status": "approve",
        "access_key": "sSznieLi.ea3d86BvWeo9HozGQOebgm7P3vpSqDlO",
        "expiry_status": "active",
        "country_name": "Sri Lanka"
      },
      {
        "id": "b40a2d6f-0297-4b5c-a014-4304f9542cfc",
        "name": "Ajantha Test Pub Access",
        "email": "ajanthai@gmail.com",
        "request_status": "approve",
        "access_key": "rNbYvOsm.c8pN9IwQ0JWjjv7NOQaFOVMzdqc3lRFE",
        "expiry_status": "active",
        "country_name": "Sri Lanka"
      },
      {
        "id": "331f490c-50d8-4642-a797-5efba91746bd",
        "name": "ihalawela",
        "email": "a.ihalawela@cgiar.org",
        "request_status": "approve",
        "access_key": "cs6sjBG5.OPZtW0CgBrFBmdl5FdC33T0JCDAaaSrK",
        "expiry_status": "active",
        "country_name": "India"
      },
      {
        "id": "f78a54ea-fc7d-4e47-967e-aa7294877942",
        "name": "Ajantha",
        "email": "a1.ihalawela@cgiar.org",
        "request_status": "approve",
        "access_key": "ktbIw3rA.1OkL4FSpQm8aqCkC9jC5GE9rto3Uc1Tm",
        "expiry_status": "active",
        "country_name": "India"
      },
      {
        "id": "c6aadfd9-cafd-4768-b381-0b36a20836ac",
        "name": "sai",
        "email": "sai156@yopmail.com",
        "request_status": "approve",
        "access_key": "aRIvN0Qp.WbQZfucadTp5sPLEwVvzZmbypAT2HaGy",
        "expiry_status": "active",
        "country_name": "India"
      },
      {
        "id": "64e1a1cd-1b29-449a-8e86-1a5ccd5b4e8e",
        "name": "Saral",
        "email": "saral@3ck.design",
        "request_status": "approve",
        "access_key": "wX0ZzNpJ.KkbfvMYDzyHV0a6tRooBRQIvkXY7Gujb",
        "expiry_status": "active",
        "country_name": "India"
      },
      {
        "id": "9eebc487-3a3f-4cec-b2c3-c07bb11ffda3",
        "name": "Gaurav kumar Chaudhary",
        "email": "gauravhyd1@yopmail.com",
        "request_status": "approve",
        "access_key": "xosVTlIJ.xMnVX2mStyjoJiTT38ibAUSo2cw4Rsc3",
        "expiry_status": "active",
        "country_name": "Equatorial Guinea"
      },
      {
        "id": "7be52126-7516-4da4-bbb2-c079dbbda20f",
        "name": "Gaurav kumar Chaudhary",
        "email": "gauravhyd12@yopmail.com",
        "request_status": "approve",
        "access_key": "nujMxMco.LVl7kc0DTKyGToayBFwsOqgx7EDmoLFM",
        "expiry_status": "active",
        "country_name": "Netherlands"
      },
      {
        "id": "5bd98608-cff9-4fcd-a718-aa703e6eb413",
        "name": "gaurav kumar chaughary",
        "email": "gauravhyd123@yopmail.com",
        "request_status": "approve",
        "access_key": "11bf6CuA.6RJ6oYzx1RBgG4DLgv2SoAMMZv1PalqU",
        "expiry_status": "active",
        "country_name": "France"
      }
    ]
  },
  "code": 200
}

// export const MAP_PREFERENCES_lABELS = {
//   COUNTRY: 'country',
//   STATES: 'states_or_provinces',
//   DISTRICTS: 'districts_or_cities',
//   TALUKAS: 'talukas',
//   NPSC_LULC: 'npsc_lulc',
//   JRC: 'jrc',
//   MOD44: 'mod44',
// };

export const MAP_PREFERENCES_lABELS = {
  COUNTRY: 'country',
  STATES: 'states_or_provinces',
  DISTRICTS: 'districts_or_cities',
  TALUKAS: 'talukas',
  NPSC_LULC: 'npsc_lulc',
  JRC: 'jrc',
  MOD44: 'mod44',
  BASINS: 'basins',
  RIVERS: 'rivers_or_reservoirs',
  METEOROLOGICAL_LOCATIONS: 'meteorological_locations',
  PROBA_LULC: 'proba_lulc',
  SRILANKA_LULC: 'sri_lanka_lulc'
};


export const OPEN_WEATHER_CHART_PARAMS = [
  { name: 'Precipitation', measure: 'mm/h', visible:true, dash: "",type: "bar", mode: ""},
  { name: 'Temperature', measure: '°C', visible:  "legendonly", dash: "dash", type: "scatter", mode: "",},
  // { name: 'Humidity', measure: '', visible: "legendonly", dash: "dash", type: "scatter", mode: "",},
  { name: 'Wind speed', measure: 'm/s', visible: "legendonly",dash: "dash", type: "scatter", mode: ""},
  // { name: 'Wind Gust', measure: '', visible: "legendonly", dash: "",type: "scatter", mode: "" },
  { name: 'Pressure', measure: 'hPa', visible: "legendonly", dash:"",type: "scatter", mode: ""},
];

export const SOUTH_ASIA_CONST = { name: "South Asia", id:'e2f886a7-3299-4c82-af2e-987fe92fab39' }

export const OPEN_WEATHER_PARAMETER_SLUG = [
  { name: 'Precipitation', value: 'Precipitation', api_slug: 'rain', measure: 'mm/h' },
  { name: 'Temperature', value: 'Temperature', api_slug: 'temp', measure: '°C' },
  { name: 'Wind_speed', value: 'Wind speed', api_slug: 'wind_speed', measure: 'm/s' },
  { name: 'Cloud', value: 'Cloud', api_slug: 'clouds', measure: '%' },
  { name: 'Pressure', value: 'Pressure', api_slug: 'pressure', measure: 'hPa' },
];


export const GRAPH_YAXIS_COLORS = ['#29619F','#F4A85E','#0FA094','#D40000', '#1A9850']

export const CHART_LABEL_COLORS = [
  '#29619F',
  '#297A18',
  '#E84F0B',
  '#FF4B65',
  '#775dd0',
  '#28609e',
  '#00e396',
  '#feb019',
  '#ff4560',
  '#775dd0',
  '#28609e',
  '#00e396',
  '#feb019',
  '#ff4560',
  '#775dd0',
];

export const CWP_COLORS = {
  "Rainfed": {'Sown Area (ACRES)': '#A0AD51', 'Harvested Area (ACRES)':'#F2A672', 'Yield (BUSHELS PER NETT ACRE)':'#797EF6'},
  "Major": {'Sown Area (ACRES)': '#1C6E1C', 'Harvested Area (ACRES)':'#BE6111', 'Yield (BUSHELS PER NETT ACRE)':'#1E2F97'},
  "Minor": {'Sown Area (ACRES)': '#51752D', 'Harvested Area (ACRES)':'#EA7C30', 'Yield (BUSHELS PER NETT ACRE)':'#1AA7EC'},
}

export const CWP_PERENNIAL_CROP_AREAS= {
  "Extent (Hectares)": "Extent (Hectares)",
  "COP (Rs/Kg)":"COP (Rs/Kg)",
  "COP (Rs/1000 nuts)": "COP (Rs/1000 nuts)",
  "Production (MT)": "Production (MT)"
}

export const CWP_PERENNIAL_COLORS = {
  "perennial": {"Extent (Hectares)": '#008ffb', "COP (Rs/Kg)":'#2ca02c', "COP (Rs/1000 nuts)": '#A0AD51', "Production (MT)":'#feb019'},
}

export const DRAW_SHAPE = {
  MAXIMUM_SHAPE: 2,
  MAXIMUM_DRAW_SHAPE: 3,
  FARM_TRACKER_MAXIMUM_DRAW_SHAPE: 1
};

export const WEATHER_MONITOR_CONSTS = {
  DRYSPELL: 'dryspell',
  EXTREME_RAINFALL: 'no_of_rainy_days'
}

export const PlotlyPNGLogo = {
  name: 'newplotlyPNGlogo',
  svg:
    '<svg xmlns="http://www.w3.org/2000/svg" fill="#808080" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM128 256c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32zm88 32c5.3 0 10.2 2.6 13.2 6.9l88 128c3.4 4.9 3.7 11.3 1 16.5s-8.2 8.6-14.2 8.6H216 176 128 80c-5.8 0-11.1-3.1-13.9-8.1s-2.8-11.2 .2-16.1l48-80c2.9-4.8 8.1-7.8 13.7-7.8s10.8 2.9 13.7 7.8l12.8 21.4 48.3-70.2c3-4.3 7.9-6.9 13.2-6.9z"/></svg>',
};

export const PlotlyCSVLogo = {
  name: 'newplotlyCSVlogo',
  svg:
    '<svg xmlns="http://www.w3.org/2000/svg" fill="#808080" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 224H96c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H80c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8H96c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H80c-22.1 0-40-17.9-40-40V264c0-22.1 17.9-40 40-40zm72 46.4c0-25.6 20.8-46.4 46.4-46.4H216c8.8 0 16 7.2 16 16s-7.2 16-16 16H198.4c-7.9 0-14.4 6.4-14.4 14.4c0 5.2 2.8 9.9 7.2 12.5l25.4 14.5c14.4 8.3 23.4 23.6 23.4 40.3c0 25.6-20.8 46.4-46.4 46.4H168c-8.8 0-16-7.2-16-16s7.2-16 16-16h25.6c7.9 0 14.4-6.4 14.4-14.4c0-5.2-2.8-9.9-7.2-12.5l-25.4-14.5C160.9 302.4 152 287 152 270.4zM280 240v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V240c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V240c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>',
};

export const PlotlyCloseLogo = {
  width: 300,
  height: 500,
  path: 'M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z',
};

export const kgMUnits = "kg/m<sup>2</sup>";
export const kgCmUnits = "kg*C/m<sup>2</sup>";
export const mmUnits = "mm";
export const wmUnits = "W/m<sup>2</sup>";
export const kgmsUnits = "kg/m<sup>2</sup>/s";

//google authorization
export const  SCOPE  =  'https://www.googleapis.com/auth/drive';
export const  discoveryUrl  =  ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
export const  deleteDiscoveryUrl  =  ['https://www.googleapis.com/discovery/v1/apis/drive/v2/rest'];
//deployed
// export const CLIENT_ID = "284098644315-222e995ovfcr84knq7tmhvefnrmdbuvc.apps.googleusercontent.com"
// export const API_KEY = "AIzaSyBZr4tMeHx025pQJfat5Mfmd06Xju3yKrA"

//local
// export const API_KEY = "AIzaSyBBgYgWFgOEWr4kwHOmN-POQ-QHP126geo"
// export const CLIENT_ID = "524810988245-ditf6o50gqu3smffkhutut5cb6tofnbt.apps.googleusercontent.com";

export const SHEETS_SCOPE = 'https://www.googleapis.com/auth/drive.file'
export const SHEETS_DISCOVERY_URL = ['https://www.googleapis.com/discovery/v1/apis/sheets/v4/rest']

//export const SHEETS_DISCOVERY_URL = ['https://sheets.googleapis.com/$discovery/rest?version=v4']
