import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { t } from 'react-multi-lang';
import Right_Arrow from '../../assets/Images/right-arrow.png';
import Facebook from '../../assets/Images/icons/bx_bxl-facebook-circle.svg';
import Twitter from '../../assets/Images/icons/akar-icons_twitter-fill.svg';
import Flickr from '../../assets/Images/icons/bx_bxl-flickr.svg';
import Youtube from '../../assets/Images/icons/bx_bxl-youtube.svg';
import RSSFeed from '../../assets/Images/icons/ic_twotone-rss-feed.svg';
import Mail from '../../assets/Images/icons/fluent_mail-16-filled.svg';
import LinkedIn from '../../assets/Images/icons/bx_bxl-linkedin.svg';
import IWMI_NEW from '../../assets/Images/iwmi_neww.svg';
import NEW_IWMI from '../../assets/Images/NEW_IWMI.svg';
import NEW_CGIAR from '../../assets/Images/NEW_CGIAR.svg';
import NEW_GIZ from '../../assets/Images/NEW_GIZ.svg';
import IntiativeClimber from '../../assets/Images/InitiativeClimber.jpg'
import MAFF_logo from '../../assets/Images/MAFF_logo.jpg'
import SADMS_NEW from '../../assets/Images/sadms_new.svg';
import ICAR_NEW from '../../assets/Images/icar_neww.svg';
import CGIAR_NEW from '../../assets/Images/cgiar_neww.svg';
import PUBLIC_IWMI_NEW_LOGO from '../../assets/Images/publicIWMILogo.svg';
import PUBLIC_SADMS_NEW_LOGO from '../../assets/Images/publicSADMSLogo.svg';
import PUBLIC_ICAR_NEW_LOGO from '../../assets/Images/publicICARLogo.svg';
import PUBLIC_CGIAR_NEW_LOGO from '../../assets/Images/publicCGIARLogo.svg';
import gizImage from '../../assets/Images/gizImage.png';

import myConstClass from '../../Constants';

const RenderSocialMediaProfiles = () => {
  return (
    <>
      <div className="follow" >
        <p className="follow-text md-flw1">{t('FOOTER.FOLLOW')}</p>
        <div className="follow-icon-wrapper md-icon1">
          <ul className="list-unstyled icon-ul footer-logos">
            <li className="icon-circle">
              <a href={myConstClass.IWMI_FB_URL} target="_blank" rel="noreferrer">
                <img src={Facebook} className="f-img-icon img-fluid" alt="FACEBOOK" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={myConstClass.IWMI_FLICKR_URL} target="_blank" rel="noreferrer">
                <img src={Flickr} className="f-img-icon img-fluid" alt="FLICKR" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={myConstClass.IWMI_LINKEDIN_URL} target="_blank" rel="noreferrer">
                <img src={LinkedIn} className="f-img-icon img-fluid" alt="LINKEDIN" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={`mailto:${myConstClass.IWMI_EMAIL}?subject="subject text"`}>
                <img src={Mail} className="f-img-icon img-fluid" alt="MAIL" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={myConstClass.IWMI_RSS_FEEDS} target="_blank" rel="noreferrer">
                <img src={RSSFeed} className="f-img-icon img-fluid" alt="RSSFEED" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={myConstClass.IWMI_TWITTER_LINK} target="_blank" rel="noreferrer">
                <img src={Twitter} className="f-img-icon img-fluid" alt="TWITTER" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={myConstClass.IWMI_YOUTUBE_LINK} target="_blank" rel="noreferrer">
                <img src={Youtube} className="f-img-icon img-fluid" alt="YOUTUBE" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const RenderContactus = () => {
  return (
    // <Row style={{ marginLeft: '8%', marginTop: '5%' }}>
    
      <div className='margin-contact'>
        <a href="/terms-conditions" target="_blank" className="footer-terms-style">
          <p className="follow-text">{t('FOOTER.TERMS_CONDITIONS')}</p>
        </a>
        </div>
      // </Col>
    // </Row>
  );
};

const RenderCopyRightText = () => {
  return (
    <>
      <p className="copyright-text copy-text-left " >
        <span class="copy-icon">&copy;</span>	&nbsp; {` ${new Date().getFullYear()} `}
        {t('FOOTER.COPY_RIGHT_TEXT')}
      </p>
    </>
  );
};

const PublicFooter = () => {
  return (
    <footer className="footer-wrapper">
      <Container className='logo-footer'>
        <Row className="item-alignment">
          <Col lg={2} md={2} sm={2} xs={2} style={{ textAlign: 'center' }}>
            <a href={myConstClass.IWMI_URL} target="_blank" rel="noreferrer">
              <img src={NEW_IWMI} className="img-fluid" alt="IWMI" />{' '}
            </a>
          </Col>
          <Col lg={2} md={2} sm={2} xs={2} style={{ textAlign: 'center' }}>
            <a href={myConstClass.CGIAR_RESOURCES_URL} target="_blank" rel="noreferrer">
              <img src={NEW_CGIAR} className="img-fluid-cgiar" alt="CGAIR_WLE" />{' '}
            </a>
          </Col>
          <Col lg={3} md={3} sm={3} xs={3} style={{ textAlign: 'center' }}>
            <a href={myConstClass.GIZ_URL} target="_blank" rel="noreferrer">
              <img src={NEW_GIZ} className="img-fluid " alt="GIZ" />{' '}
            </a>
          </Col>
          <Col lg={2} md={2} sm={2} xs={2} style={{ textAlign: 'center' }}>
            <a href={myConstClass.CGIAR_CLIMATE_RESILIENCE} target="_blank" rel="noreferrer">
              <img src={IntiativeClimber} className="img-fluid" alt="IWMI" />{' '}
            </a>
          </Col>
        <Col lg={2} md={2} sm={2} xs={2} style={{ textAlign: 'center'}}>
            <a href={myConstClass.MAFF_URL} target="_blank" rel="noreferrer">
              <img src={MAFF_logo} className="img-fluid-maff" alt="IWMI" />{' '}
            </a>
          </Col>
        </Row>
      </Container>
      {/* <hr className="hr-color" /> */}

      <div className="footer-social-meida-profils icons-footer">
        <Row>
          <Col className="fr-content ml-auto pt-3" lg={4} sm={6} xs={12}>
          <div className="home-footer-right">
             <RenderSocialMediaProfiles />
                  <RenderContactus />
              </div>
          </Col>
        </Row>
        <RenderCopyRightText />
      </div>
    </footer>
  );
};

const AfterLoginFooter = (props) => {
  return (
    <footer className="common-footer-wrapper">
      {/* <Container> */}
        <Row className="item-alignment pt-4 md-alg1">
          <Col className="md-logos1" md={12} xs={12} lg={6}>
            <ul className="list-unstyled d-flex footer-logos md-logos1">
              <li>
                <a href={myConstClass.IWMI_URL} target="_blank" rel="noreferrer">
                  <img src={PUBLIC_IWMI_NEW_LOGO} className="footer-icar_icon" alt="iwmi" />{' '}
                </a>
              </li>
              <li className='px-2'>
                <a href={myConstClass.SADMS_URL} target="_blank" rel="noreferrer">
                  <img src={PUBLIC_SADMS_NEW_LOGO} className="footer-sadms-icon" alt="iwmi" />{' '}
                </a>
              </li>
              <li className='px-2'>
                <a href={myConstClass.CGIAR_URL} target="_blank" rel="noreferrer">
                  <img src={PUBLIC_ICAR_NEW_LOGO} className="footer-iwmi-icon" alt="sadms" />{' '}
                </a>
              </li>
              <li className='px-2'>
                <a href={myConstClass.CGIAR_RESOURCES_URL} target="_blank" rel="noreferrer">
                  <img src={PUBLIC_CGIAR_NEW_LOGO} className="footer-cgiar-icon" alt="cgair_wle" />{' '}
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={6}>
            <div className={props.userGuide?"home-footer-right1":"home-footer-right"}>
              <RenderSocialMediaProfiles />
            </div>
          </Col>
        </Row>
        <div>
          <RenderCopyRightText />
        </div>
      {/* </Container> */}
    </footer>
  );
};

const Footer = ({ publicFooter , userGuide }) => {
  return <>{publicFooter ? <PublicFooter userGuide={userGuide} /> : <AfterLoginFooter userGuide={userGuide} />}</>;
};

export default Footer;
