/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js';
import _ from 'lodash';
import { renderGenericDateFormatWithTime } from './common/utils';
import { CHART_LABEL_COLORS, GRAPH_YAXIS_COLORS } from '../Components/constants/index';
import PlotGEEERPASCharts from './common/PlotGEEERPASCharts';
import PlotGEENOAACharts from './common/PlotGEENOAACharts';
import { PlotlyPNGLogo, PlotlyCSVLogo, PlotlyCloseLogo, DISPLAY_CHART_DATE } from './constants';
import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Ggplot2(props) {
  const {
    isOpenWeather,
    chartData,
    parametersData,
    renderCharts,
    dataSource,
    location,
    fileName,
    selectedParams,
    initialSelectedDate,
    temporalAggregation,
    region,
  } = props;
  const ButtonClick = useRef('');
  const [csvFileData, setCsvFileData] = useState([]);
  const [objKeysData, setObjKeysData] = useState([]);
  const [dates, setDates] = useState([]);
  const [isFirstYAxisDeselected, setIsFirstYAxisDeselected] = useState(false);
  const [yOptions, setYOptions] = useState([]);
  const [newFileName, setFileName] = useState(
    `${fileName.split('-')[0]}-${fileName.split('-')[1]}`,
  );
  const [openWeatherParams, setOpenWeatherParams] = useState([
    { name: 'Precipitation', measure: 'mm/h', visible: true, dash: '', type: 'bar', mode: '' },
    {
      name: 'Temperature',
      measure: '°C',
      visible: true,
      dash: '',
      type: 'scatter',
      mode: 'lines',
    },
    {
      name: 'Wind speed',
      measure: 'm/s',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: 'lines',
    },
    {
      name: 'Pressure',
      measure: 'hPa',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: 'lines',
    },
  ]);

  const [layout, updateLayout] = useState({
    bargap: 0.5,
    responsive: true,
    title: {
      text: `Data Source : ${dataSource[0]?.name} (Forecast for 2 days)`,
      plot_bgcolor: '#E5ECF6',
      font: {
        family: 'National',
        color: '#28609e',
        size: 15,
      },
      x: 0.5,
      xanchor: 'center',
    },
    // plot_bgcolor: "#E5ECF6",
    // paper_bgcolor: "#E5ECF6",
    modebardisplay: true,
    // width: 1600,
    font: {
      size: 12,
      color: '#000',
    },
    xaxis: {
      showline: true,
      tickangle: 50,
      tickfont: {
        size: 12,
        color: '#000',
        family: 'National',
      },
      showgrid: false,
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      // x: 0.3,
      x: 0.5,
      xanchor: 'center',
      y: `${region?.length ? -0.8 : -0.75}`,
      font: {
        // family: 'sans-serif',
        size: 15,
        color: '#000',
        family: 'National',
      },
    },
    margin: {
      t: 55,
      b: 50,
    },
  });
  useEffect(() => {
    if (selectedParams && selectedParams?.weather_forecast_parameter) {
      let reOrderedParams = [];
      openWeatherParams.forEach((param, index) => {
        if (param.name === selectedParams?.weather_forecast_parameter.name) {
          param.visible = true;
          reOrderedParams.push(param);
          if (param?.name === 'Precipitation') {
            updateLayout((preValues) => ({
              ...preValues,
              yaxis: {
                title: getTitle(param?.name,param?.measure,location,region), //for multiple y-axis------
                titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
                tickfont: { color: GRAPH_YAXIS_COLORS[index] },
                showline: true,
                rangemode: 'tozero',
                showgrid: false,
                zeroline: false,
              },
            }));
          } else {
            updateLayout((preValues) => ({
              ...preValues,
              yaxis: {
                title: getTitle(param?.name,param?.measure,location,region), //for multiple y-axis------
                titlefont: { color: GRAPH_YAXIS_COLORS[0], family: 'National' },
                tickfont: { color: GRAPH_YAXIS_COLORS[0] },
                showline: true,
                showgrid: false,
                zeroline: false,
                rangemode: 'tozero',
              },
            }));
          }
        }
      });
      if (reOrderedParams.length) {
        openWeatherParams.forEach((param) => {
          if (param.name !== selectedParams?.weather_forecast_parameter.name) {
            param.visible = 'legendonly';
            reOrderedParams.push(param);
          }
        });
      }
      setOpenWeatherParams(reOrderedParams);
    }
  }, [selectedParams]);

  const handleTraces = (chart_data, index, p) => {
    let ydata = {
      y: [],
    };
    chart_data?.forEach((i) => {
      ydata.type = p.type;
      ydata.mode = p.mode;
      ydata.visible = p.visible;
      ydata.position = p.position;
      ydata.name = `${p?.name}`;
      // ydata.line = { color: CHART_LABEL_COLORS[index], dash: p.dash };
      if (p.type === 'scatter') {
        ydata.line = { color: GRAPH_YAXIS_COLORS[index], dash: p.dash, width: 3 };
      } else {
        ydata.marker = { color: GRAPH_YAXIS_COLORS[index], dash: p.dash };
      }
      ydata.units = p.measure;
      if (p.name === 'Temperature') {
        if (index === 0) {
          ydata.yaxis = `y`;
        }
        ydata.y.push(i?.temp);
      } else if (p.name === 'Precipitation') {
        if (index === 0) {
          ydata.yaxis = `y`;
        }
        ydata.y.push(i?.rain ? i.rain['1h'] : 0);
      } else if (p.name === 'Wind speed') {
        if (index === 0) {
          ydata.yaxis = `y`;
        }
        ydata.y.push(i?.wind_speed);
      } else if (p.name === 'Pressure') {
        if (index === 0) {
          ydata.yaxis = `y`;
        }

        ydata.y.push(i?.pressure);
      }
    });

    return ydata;
  };
  useEffect(() => {
    if (chartData && chartData?.length && isOpenWeather) {
      let arr = chartData?.map((item) => renderGenericDateFormatWithTime(item.dt_txt));
      setDates(arr);
      let options = [];
      openWeatherParams?.map((p, index) => {
        let returned_values = handleTraces(chartData, index, p);
        options.push(returned_values);
      });
      let x = [];
      chartData?.map((i) => {
        x.push(renderGenericDateFormatWithTime(i.dt_txt));
      });
      options.map((o) => {
        o.x = x;
        o.hovertemplate = `%{x} : ${o.name} : %{y}<extra></extra>`;
      });
      setYOptions(options);
    }
  }, [chartData, openWeatherParams]);

  const getLayoutWidths = [
    { yaxisCount: 1, domain: [0, 0] },
    { yaxisCount: 2, domain: [0, 0] },
    { yaxisCount: 3, domain: [0, 0.93] },
    { yaxisCount: 4, domain: [0, 0.87] },
  ];

  const getTitle = (name, measure, location, region) => {
    if (region?.length) {
      return `<b>${name} (${measure})</b>`
    }
    else {
      return `<b>${name} (${measure}) <br>${location ? `(${location})` : ''
        }</b>`
    }
  }

  const handlePosition = (axisCount, sortedListLength, key, index) => {
    let positionValue;
    if (axisCount) {
      if (axisCount === 3) {
        if (sortedListLength === 4) {
          if (key === 3) positionValue = 1;
          else if (key === 2) positionValue = 0.93;
        } else {
          if (key === 2) positionValue = 1;
          else if (key === 1) positionValue = 0.93;
        }
      } else if (axisCount === 4) {
        if (key === 3) {
          positionValue = 1;
        } else {
          positionValue = 0.93;
        }
      } else if (axisCount === 5) {
        positionValue = 0.85;
      } else {
        positionValue = 1;
      }
      return positionValue;
    }
  };
  //function to order multiple y axes
  const getOrderedYAxes = (layoutData, YoptionsLength, index) => {
    let yaxisArray = [];
    Object.keys(layoutData).forEach((element) => {
      if (element.includes('yaxis')) {
        yaxisArray.push(element);
      }
    });
    // let position = 1 - Number(`0.${YoptionsLength - 3}`);
    if (!yaxisArray.find((e) => e === 'yaxis')) {
      yaxisArray.push('yaxis');
    }
    let order = ['yaxis', 'yaxis2', 'yaxis3', 'yaxis4'];
    yaxisArray.sort((a, b) => order.indexOf(a) - order.indexOf(b));
    yaxisArray.forEach((item, key) => {
      let position = handlePosition(YoptionsLength, yaxisArray?.length, key, index);
      if (key === 0) {
        if (layoutData[item]) {
          layoutData[item].side = 'left';
          layoutData[item].position = undefined;
        }
      } else if (key === 1) {
        layoutData[item].anchor = 'x';
        layoutData[item].side = 'right';
        layoutData[item].position = undefined;
      } else {
        layoutData[item].anchor = 'free';
        layoutData[item].side = 'right';
        layoutData[item].position = position;
        position += 0.1;
      }
    });
    return layoutData;
  };
  
  const handleLegends = (graphData) => {
    let index = graphData.expandedIndex;
    let data = graphData.data[index];
    let newYOptions = [...yOptions];
    if (data.visible !== true) {
      newYOptions[index]['yaxis'] = `y${index + 1}`;
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      if (yaxisCount > 2) {
        newYOptions[yaxisCount - 1]['position'] =
          yaxisCount === newYOptions.length ? 1 : handlePosition(yaxisCount);
        // newYOptions[yaxisCount - 1]['position'] = yaxisCount === newYOptions.length ? 1 : 0.9;
      }
      setYOptions(newYOptions);
      // layout.width = getLayoutWidths.find(item => item.yaxisCount === yaxisCount)?.width;
      layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      if (index !== 0) {
        if (isFirstYAxisDeselected)
          layout.xaxis.domain = getLayoutWidths.find(
            (item) => item.yaxisCount === yaxisCount + 1,
          )?.domain;
        else
          layout.xaxis.domain = getLayoutWidths.find(
            (item) => item.yaxisCount === yaxisCount,
          )?.domain;
        let yaxisName = `yaxis${index + 1}`;
        layout[yaxisName] = {
          title: getTitle(data?.name,data?.units,location,region),
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          anchor: yaxisCount >= 2 ? 'x' : 'free',
          overlaying: 'y',
          side: 'right',
          position: newYOptions[yaxisCount - 1]['position'],
          showline: true,
          rangemode: 'tozero',
          showgrid: false,
          zeroline: false,
        };
      } else if (index === 0) {
        setIsFirstYAxisDeselected(false);
        layout.xaxis.domain = getLayoutWidths.find(
          (item) => item.yaxisCount === yaxisCount,
        )?.domain;
        layout.yaxis = {
          title: getTitle(data?.name,data?.units,location,region),
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          side: 'left',
          showline: true,
          rangemode: 'tozero',
          showgrid: false,
          zeroline: false,
        };
      }
      let orderedLayoutData = getOrderedYAxes(layout, yaxisCount);
      // let orderedLayoutData = getOrderedYAxes(layout, yOptions.length);
      updateLayout(orderedLayoutData);
    } else {
      delete newYOptions[index]['yaxis'];
      setYOptions(newYOptions);
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      let yaxisName = index === 0 ? `yaxis` : `yaxis${index + 1}`;
      let layoutData = { ...layout };
      delete layoutData[yaxisName];
      if(yaxisName === "yaxis"){
        layoutData[yaxisName] = { showgrid: false, zeroline:false, tickfont : { color: '#fff'}}
      }
      if (index === 0) setIsFirstYAxisDeselected(true);
      let updatedYAxisCount = index === 0 || isFirstYAxisDeselected ? yaxisCount + 1 : yaxisCount;
      layout.xaxis.domain = getLayoutWidths.find(
        (item) => item.yaxisCount === updatedYAxisCount,
      )?.domain;
      // let orderedLayoutData = getOrderedYAxes(layoutData, yOptions.length);
      let orderedLayoutData = getOrderedYAxes(layoutData, yaxisCount, index);
      updateLayout(orderedLayoutData);
    }
  };

  let config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true, //by default we are showing
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        click: function (gd) {
          getActiveLegends(false, gd);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        click: function (gd) {
          getActiveLegends(true, gd);
        },
      },
      {
        name: 'Close',
        icon: PlotlyCloseLogo,
        click: function () {
          renderCharts();
        },
      },
    ],
    showTips: false,
  };

  //function for getting active legends for graph then download png/csv format
  const getActiveLegends = (isDownloadCSV, graphData) => {
    if (document.getElementById('openWeatherId')?.data?.length) {
      const activeLegends = graphData.data?.filter((item) => item.visible === true);
      if (activeLegends.map((data) => data.name)?.length) {
        //setting file name based on active legends
        setFileName(
          `${fileName.split('-')[0]}${fileName.split('-')[1] ? `-${fileName.split('-')[1]}` : ''}${
            region?.length
              ? `-Point(${region[0]?.latlngs[1].toFixed(2)}-${region[0]?.latlngs[0].toFixed(2)})`
              : ''
          }-${activeLegends.map((data) => data.name).join(' ')}`,
        );
      }
      if (activeLegends?.length) {
        //formatting data for csv file
        const xaixsValue = activeLegends[0].x;
        let data = [];
        xaixsValue.forEach((date, key) => {
          activeLegends.forEach((item, index) => {
            if (index === 0) {
              data.push({ Date_Time: date, [item.name]: item.y[key] });
            } else {
              data[key][item.name] = item.y[key];
            }
          });
        });
        setCsvFileData(data);
        let objKeys = Object.keys(data[0]);
        if (objKeys) {
          setObjKeysData(objKeys);
        }
        if (isDownloadCSV) {
          //downloading csv file
          ButtonClick.current.click();
        } else {
          //downloading png file
          Plotly.downloadImage(graphData, {
            filename: `${fileName.split('-')[0]}${
              fileName.split('-')[1] ? `-${fileName.split('-')[1]}` : ''
            }${
              region?.length
                ? `-Point(${region[0]?.latlngs[1].toFixed(2)}-${region[0]?.latlngs[0].toFixed(2)})`
                : ''
            }-${activeLegends.map((data) => data.name).join(' ')}`,
            format: 'png',
            width: graphData._fullLayout.width,
            height: graphData._fullLayout.height,
          });
        }
      }
    }
  };

  if (!props.isOpenWeather) {
    if (
      !_.isEmpty(props.geeTimeSeriesData) &&
      dataSource[0]?.id === 'e2f886a7-3299-4c82-af2e-123fe92faf83'
    ) {
      return (
        <PlotGEENOAACharts
          renderCharts={renderCharts}
          fileName={fileName}
          temporalAggregation={temporalAggregation}
          chartData={props.geeTimeSeriesData}
          parametersData={parametersData}
          dataSource={dataSource}
          isOpenWeather={props.isOpenWeather}
          selectedParams={selectedParams}
          location={location}
          region={region}
          initialSelectedDate={initialSelectedDate}
        />
      );
    } else if (
      !_.isEmpty(props.geeTimeSeriesData) &&
      dataSource[0]?.id !== 'e2f886a7-3299-4c82-af2e-123fe92faf83'
    ) {
      return (
        <PlotGEEERPASCharts
          renderCharts={renderCharts}
          temporalAggregation={temporalAggregation}
          fileName={fileName}
          chartData={props.geeTimeSeriesData}
          parametersData={parametersData}
          dataSource={dataSource}
          isOpenWeather={props.isOpenWeather}
          selectedParams={selectedParams}
          location={location}
          region={region}
          initialSelectedDate={initialSelectedDate}
        />
      );
    }
  }
  return (
    <>
      <span
        style={{
          color: '#28609e',
          position: 'absolute',
          left: '5px',
          top: '2%',
          family: 'National',
          fontSize: '15px',
          zIndex: 100,
        }}
      >
        Date : {moment(initialSelectedDate?.graph?.startDate).format(DISPLAY_CHART_DATE)} to{' '}
        {moment(initialSelectedDate?.graph?.endDate).format(DISPLAY_CHART_DATE)}
      </span>
      <Plot
        data={yOptions}
        layout={layout}
        config={config}
        onLegendClick={(data) => handleLegends(data)}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        divId="openWeatherId"
      />
      <ExcelFile
        filename={newFileName}
        element={
          <button
            ref={ButtonClick}
            className="btn btn-download ml-auto"
            style={{ display: 'none' }}
          >
            Download
          </button>
        }
      >
        <ExcelSheet data={csvFileData} name="Weather_and_Water">
          {objKeysData?.map((item) => (
            <ExcelColumn label={item} value={item} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </>
  );
}
export default Ggplot2;
