import { CLIMATE_WEATHER_LOCATION_CONST, DATA_SOURCES_CONST } from './climateWeatherConst';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  locationSuccess,
  locationFailed,
  dataSourcesSuccess,
  dataSourcesFailed,
} from './climateWeatherAction';
import {
  LOCATION_URL,
  AXIOS_INSTANCE,
  PERMISSIONS_URL,
  WEATHER_FORECAST_URL,
} from '../api/apiEndPoint';
import baseMethod from '../baseService.js';
import { checkHttpStatus } from '../api/apiUtils';

function* locationRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${LOCATION_URL}`, action.payload),
      '',
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(locationSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(locationFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(locationFailed(errorData));
  }
}

function* dataSourcesRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${WEATHER_FORECAST_URL}/sources/${action.payload.country_id}`,
        action.payload,
      ),
      '',
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(dataSourcesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(dataSourcesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(dataSourcesFailed(errorData));
  }
}

export function* watchUserList() {
  yield takeEvery(CLIMATE_WEATHER_LOCATION_CONST.CLIMATE_WEATHER_LOCATION_REQUEST, locationRequest);
  yield takeEvery(DATA_SOURCES_CONST.DATA_SOURCES_REQUEST, dataSourcesRequest);
}

function* climateWeatherSaga() {
  yield all([fork(watchUserList)]);
}

export default climateWeatherSaga;
