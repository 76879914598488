import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js';
import _ from 'lodash';
import {
  DATA_SOURCES,
  DISPLAY_CHART_DATE,
  ERPAS_PARAMETER_SLUG,
  GRAPH_YAXIS_COLORS,
} from '../../Components/constants';
import { isOpenWeatherDataSource, renderGenericDateFormat, renderGenericDateFormatWithTime } from '../../Components/common/utils';
import { PlotlyPNGLogo, PlotlyCSVLogo, PlotlyCloseLogo } from '../../Components/constants';
import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const PlotsCharts1 = (props) => {
  const {
    renderCharts,
    dataSources,
    noaaRegionTimeSeries,
    selectedParam,
    comparedLocation,
    location,
    temporalAggregation,
    compareParams,
    initialSelectedDate,
    region,
    IRIForecastTime,
  } = props;
  const [yOptions, setYOptions] = useState([]);
  const [newFileName, setFileName] = useState('');
  const ButtonClick = useRef('');
  const [csvFileData, setCsvFileData] = useState([]);
  const [objKeysData, setObjKeysData] = useState([]);

  const handleParameterName = () => {
    if (selectedParam?.weather_forecast_source_id === DATA_SOURCES.ERPAS.id) {
      let name = '';
      ERPAS_PARAMETER_SLUG?.map((i) => {
        if (i.api_slug === selectedParam?.weather_forecast_parameter?.slug) {
          name = i.value;
        }
      });
      return name;
    } else {
      if (dataSources[0]?.id === DATA_SOURCES.IRI.id) {
        return `${selectedParam?.weather_forecast_parameter?.name} (%)`;
      } else {
        return selectedParam?.weather_forecast_parameter?.name;
      }
    }
  };

  const [layout, updateLayout] = useState({
    title: {
      text: `Parameter: ${handleParameterName()} ${
        dataSources[0]?.id === DATA_SOURCES.IRI.id ||
        dataSources[0]?.id === DATA_SOURCES.OPEN_WEATHER.id
          ? ''
          : `(${temporalAggregation?.name})`
      }`,
      plot_bgcolor: '#E5ECF6',
      font: {
        family: 'National',
        color: '#28609e',
        size: 15,
      },
      x: 0.5,
      xanchor: 'center',
    },
    bargap: region?.length === 1 ? 0.95 : 0.9,
    barmode: 'group',
    responsive: true,
    xaxis: {
      autorange: true,
      showline: true,
      tickangle: 30,
      tickfont: {
        size: 12,
        color: '#000',
        family: 'National',
      },
      showgrid: false,
      zeroline: false,
    },
    showlegend: true,
    modebardisplay: true,
    yaxis: {
      type: 'linear',
      showline: true,
      zeroline: false,
      showgrid: false,
      title: {
        text: '',
        plot_bgcolor: '#E5ECF6',
        font: {
          family: 'National',
          color: '#28609e',
          size: 15,
        },
      },
    },
    font: {
      size: 12,
      color: '#000',
    },
    legend: {
      orientation: 'h',
      x: 0.5,
      xanchor: 'center',
      y: `${region?.length ? -0.8 : -0.4}`,
      font: {
        size: 15,
        color: '#000',
        family: 'National',
      },
    },
    margin: {
      t: 60,
      b: 50,
    },
  });

  const getTickAngle = (xaxisData) => {
    let tickangle;
    if (xaxisData.length < 75 && xaxisData.length > 50) {
      tickangle = 60;
    } else if (xaxisData.length < 100 && xaxisData.length > 75) {
      tickangle = 40;
    } else {
      tickangle = 50;
    }
    return tickangle;
  };

  useEffect(() => {
    let options = [];
    let xaxisLabels = [];
    if (noaaRegionTimeSeries && noaaRegionTimeSeries) {
      noaaRegionTimeSeries.map((i, index) => {
        let keys = Object.keys(i);
        if (i?.layerType === 'IRI' || keys.includes('millis')) {
          let datesList = i?.millis;
          datesList = i?.millis?.length > i?.millis?.length ? i?.millis : i?.millis;
          if (!_.isEmpty(datesList)) {
            xaxisLabels = datesList?.length
              ? datesList.map((item) => {
                  let dateFormat = new Date(item);
                  return renderGenericDateFormat(dateFormat);
                })
              : '';
          }
        } else {
          if(region?.length === 2 && isOpenWeatherDataSource(dataSources)){
            i?.Timestamp?.map((a)=>{
              xaxisLabels.push(renderGenericDateFormatWithTime(a))
            })
          }
          else 
          i?.Timestamp?.map((a) => {
            xaxisLabels.push(renderGenericDateFormat(a));
          });
        }
      });
      let fileNamesArray = [];
      let layoutData = { ...layout };
      layoutData.xaxis.tickangle = getTickAngle(xaxisLabels);
      updateLayout(layoutData);

      // setDates(xaxisLabels);
      let view = [
        { visible: true, dash: 'dash', type: 'scatter', mode: 'lines' },
        { visible: true, dash: 'dash', type: 'scatter', mode: 'lines' },
        { visible: true, dash: 'dash', type: 'scatter', mode: 'lines' },
        { visible: true, dash: '', type: 'scatter', mode: '' },
        { visible: true, dash: '', type: 'scatter', mode: '' },
        { visible: true, dash: '', type: 'bar', mode: '' },
      ];
      noaaRegionTimeSeries?.map((i, index) => {
        if (i?.layerType !== '') {
          fileNamesArray.push(
            `${i?.layerType}-${handleParameterName()}-${index === 0 ? location : comparedLocation}`,
          );
          let ab = {
            y: [],
          };
          i?.data?.map((item) => {
            ab.y.push(item?.toFixed(2));
          });
          // ab.fill = 'toself';
          ab.visible = view[index]?.visible;
          // ab.type = view[index].type;
          if (index > 0) {
            ab.yaxis = `y${index + 1}`;
          }

          //for grouping the bars
          ab.offsetgroup = index + 1;

          // ab.name = dataSources[0]?.name;
          if (i?.layerType === 'IRI') {
            ab.width = [0.05];
          }
          ab.type = i?.data?.length === 1 ? 'bar' : 'scatter';
          ab.mode = view[index]?.mode;
          ab.latlng = i?.latlng;

          ab.layerType = i?.layerType;

          if (i?.layerType !== 'marker' && i?.layerType !== 'polygon') {
            ab.name = i?.layerType;
            ab.hovertemplate = `%{x} : ${i?.layerType} : %{y}<extra></extra>`;
          } else {
            ab.name =
              i?.layerType === 'marker'
                ?
                  `Point(${i?.latlng?.[1].toFixed(2)},${i?.latlng?.[0].toFixed(2)})`
                : i?.layerType === 'polygon' && `${_.capitalize(i?.layerType)}-${i?.polygonCount}`;
          }
          ab.x = xaxisLabels;
          ab.line = { color: GRAPH_YAXIS_COLORS[index], dash: view[i]?.dash, width: 3 };
          ab.showgrid = false;

          options.push(ab);
        }
      });
    }
    setYOptions(options);
  }, [noaaRegionTimeSeries]);

  var trace = yOptions;
  var chartsData = trace;

  const getTitle = (data, location, index) => {
    let title = '';
    if (data?.layerType === 'marker') {
      title =
        `<b>Point(${data?.latlng?.[1].toFixed(2)},${data?.latlng?.[0].toFixed(2)})</b>`;
    } else if (data?.layerType === 'polygon') {
      title = `<b>${_.capitalize(data?.layerType)}-${
        data?.polygonCount !== undefined ? data?.polygonCount : index + 1
      }</b>`;
    }   
    else {
      title = `<b>${data?.layerType}<br>${location ? `(${location})` : ''}</b>`;
    }
    return title;
  };

  useEffect(() => {
    if (noaaRegionTimeSeries && noaaRegionTimeSeries.length) {
      let layoutData = { ...layout };
      noaaRegionTimeSeries.forEach((data, index) => {
        let yaxisName = index === 0 ? 'yaxis' : `yaxis${index + 1}`;
        layoutData[yaxisName] = {
          titlefont: { color: GRAPH_YAXIS_COLORS[index] },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          showline: true,
          side: index === 0 ? 'left' : 'right',
          overlaying: index === 0 ? '' : 'y',
          zeroline: false,
          showgrid: false,
          rangemode: 'tozero',
          title: {
            text:
              index === 0 ? getTitle(data, location) : getTitle(data, comparedLocation),
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
        };
      });
      updateLayout(layoutData);
    }
  }, [noaaRegionTimeSeries]);

  const getLayoutWidths = [
    { yaxisCount: 1, width: 1600, domain: [0, 0] },
    { yaxisCount: 2, width: 1500, domain: [0, 0] },
    { yaxisCount: 3, width: 1400, domain: [0, 0.95] },
    { yaxisCount: 4, width: 1300, domain: [0, 0.9] },
  ];

  //function to order multiple y axes
  const getOrderedYAxes = (layoutData, YoptionsLength) => {
    let yaxisArray = [];
    Object.keys(layoutData).forEach((element) => {
      if (element.includes('yaxis')) {
        yaxisArray.push(element);
      }
    });
    let position = 1 - Number(`0.${YoptionsLength - 3}`);
    if (!yaxisArray.find((e) => e === 'yaxis')) {
      yaxisArray.push('yaxis');
    }
    let order = ['yaxis', 'yaxis2', 'yaxis3', 'yaxis4'];
    yaxisArray.sort((a, b) => order.indexOf(a) - order.indexOf(b));

    yaxisArray.forEach((item, key) => {
      if (key === 0) {
        if (layoutData[item]) {
          layoutData[item].side = 'left';
          layoutData[item].position = undefined;
        }
      } else if (key === 1) {
        layoutData[item].anchor = 'x';
        layoutData[item].side = 'right';
        layoutData[item].position = undefined;
      } else {
        layoutData[item].anchor = 'free';
        layoutData[item].side = 'right';
        layoutData[item].position = position;
        position += 0.1;
      }
    });
    return layoutData;
  };

  const handleLegends = (graphData) => {
    let index = graphData.expandedIndex;
    let data = graphData.data[index];
    let newYOptions = [...yOptions];
    if (data.visible === 'legendonly') {
      newYOptions[index]['yaxis'] = `y${index + 1}`;
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      if (yaxisCount > 2) {
        newYOptions[yaxisCount - 1]['position'] = yaxisCount === newYOptions.length ? 1 : 0.9;
      }
      setYOptions(newYOptions);
      layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      if (index !== 0) {
        let yaxisName = `yaxis${index + 1}`;
        layout[yaxisName] = {
          title: {
            text: getTitle(data, comparedLocation, index),
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
          titlefont: { color: GRAPH_YAXIS_COLORS[index] },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          anchor: yaxisCount === 2 ? 'x' : 'free',
          overlaying: 'y',
          side: 'right',
          position: newYOptions[yaxisCount - 1]['position'],
          showline: true,
          zeroline: false,
          showgrid: false,
          scaleanchor: 'x',
          rangemode: 'tozero',
        };
      } else if (index === 0) {
        layout.yaxis = {
          title: {
            text: getTitle(data, location, index),
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
          titlefont: { color: GRAPH_YAXIS_COLORS[index] },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          side: 'left',
          showline: true,
          zeroline: false,
          showgrid: false,
          rangemode: 'tozero',
        };
      }
      let orderedLayoutData = getOrderedYAxes(layout, yOptions.length);
      updateLayout(orderedLayoutData);
    } else {
      delete newYOptions[index]['yaxis'];
      setYOptions(newYOptions);
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      let yaxisName = index === 0 ? `yaxis` : `yaxis${index + 1}`;
      let layoutData = { ...layout };
      delete layoutData[yaxisName];
      if(yaxisName === "yaxis"){
        layoutData[yaxisName] = { showgrid: false, zeroline:false, tickfont : { color: '#fff'}}
      }
      layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      let orderedLayoutData = getOrderedYAxes(layoutData, yOptions.length);
      updateLayout(orderedLayoutData);
    }
  };

  //function for getting active legends for graph then download png/csv format
  const getActiveLegends = (isDownloadCSV, graphData, noaaRegionTimeSeries) => {
    let mappedDataList = [];
      let graphDataList = [...graphData?.data];
      graphDataList?.forEach((i,key)=>{
          mappedDataList[key] = []
          i?.x?.forEach((k,index)=>{
            let filteredData = noaaRegionTimeSeries?.[key]
            let obj = {};
            obj.name = i?.name
            obj.date = k;
            obj.value = filteredData?.data?.[index]?.toFixed(4)
            mappedDataList[key]?.push(obj)
        })
      })
    let name = `${selectedParam?.dataSource}(${handleParameterName()})${
      location ? `-(${location})` : ''
    }-${compareParams[0]?.name}(${handleParameterName()})${
      comparedLocation ? `-(${comparedLocation})` : ''
    }`;
    if (document.getElementById('compareGraph')?.data?.length) {
      const activeLegends = graphData.data?.filter((item) => item.visible === true);
      let checkForRegions = noaaRegionTimeSeries?.every(
        (i) => i.layerType !== 'marker' && i.layerType !== 'polygon',
      );
      if (activeLegends.map((data) => data.name)?.length && checkForRegions) {
        if (activeLegends.length === 2) {
          name = `${activeLegends[0]?.name}(${handleParameterName()})${
            location ? `-(${location})` : ''
          }-${activeLegends[1]?.name}(${handleParameterName()})${
            comparedLocation ? `-(${comparedLocation})` : ''
          }`;
        } else {
          name = `${activeLegends[0]?.name}(${handleParameterName()})${
            location ? `-(${location})` : ''
          }`;
        }
        //setting file name based on active legends
        setFileName(name);
      } else {
        if (activeLegends.length === 2) {
          name = `${selectedParam?.dataSource}-${activeLegends[0]?.name}(${handleParameterName()})${
            location ? `-(${location})` : ''
          }-${activeLegends[1]?.name}(${handleParameterName()})${
            comparedLocation ? `-(${comparedLocation})` : ''
          }`;
        } else {
          name = `${selectedParam?.dataSource}-${activeLegends[0]?.name}(${handleParameterName()})${
            location ? `-(${location})` : ''
          }`;
        }
        //setting file name based on active legends
        setFileName(name);
      }
      if (activeLegends?.length) {
        //formatting data for csv file
        let filteredDates = Array.from(new Set(activeLegends[0].x)).toString().split(',');
        const xaixsValue = filteredDates;
        let data = [];
        let getKey = '';
        xaixsValue.forEach((date, key) => {
          activeLegends.forEach((item, index) => {
            let mappedList = mappedDataList?.find(x => x?.find(i => i.name === item?.name));
            let dateObj = mappedList?.find(item => item?.date === date);
            getKey = dateObj?.value || ' -';
            if (index === 0) {
              data.push({ Date: date, [item.name]: getKey });
            } else {
              data[key][item.name] = getKey;
            }
          });
        });
        setCsvFileData(data);
        let objKeys = Object.keys(data[0]);
        if (objKeys) {
          setObjKeysData(objKeys);
        }
        if (isDownloadCSV) {
          //downloading csv file
          ButtonClick.current.click();
        } else {
          //downloading png file
          Plotly.downloadImage(graphData, {
            filename: name,
            format: 'png',
            width: graphData._fullLayout.width,
            height: graphData._fullLayout.height,
          });
        }
      }
    }
  };

  let config = {
    className: '',
    displaylogo: false,
    responsive: true,
    displayModeBar: true,
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        click: function (gd) {
          getActiveLegends(false, gd, noaaRegionTimeSeries);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        click: function (gd) {
          getActiveLegends(true, gd, noaaRegionTimeSeries);
        },
      },
      {
        name: 'Close',
        icon: PlotlyCloseLogo,
        click: function () {
          renderCharts();
        },
      },
    ],
    showTips: false,
  };

  return (
    <>
      <span
        style={{
          color: '#28609e',
          position: 'absolute',
          left: '5px',
          top: '2%',
          family: 'National',
          fontSize: '15px',
          zIndex: 100,
        }}
      >
        {selectedParam?.dataSource === 'IRI'
          ? `Forecast Lead Time : 01-${IRIForecastTime?.lead_time?.id}-${IRIForecastTime?.year?.value}`
          : `Date: ${moment(initialSelectedDate?.graph?.startDate).format(
              DISPLAY_CHART_DATE,
            )} to ${moment(initialSelectedDate?.graph?.endDate).format(DISPLAY_CHART_DATE)}`}
      </span>
      <Plot
        data={chartsData}
        layout={layout}
        config={config}
        onLegendClick={(data) => handleLegends(data)}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        divId="compareGraph"
      />
      <ExcelFile
        filename={newFileName}
        element={
          <button
            ref={ButtonClick}
            className="btn btn-download ml-auto"
            style={{ display: 'none' }}
          >
            Download
          </button>
        }
      >
        <ExcelSheet data={csvFileData} name="Weather_and_Water">
          {objKeysData?.map((item) => (
            <ExcelColumn label={item} value={item} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
export default PlotsCharts1;
