import RightArrow from '../../assets/Images/back-button.svg';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from 'react-multi-lang';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import DummyResourceImage from '../../assets/Images/dummy_resource_image.jpg';
import { AiFillCheckCircle } from 'react-icons/ai';
import { GoCircleSlash } from 'react-icons/go';
import Loader from '../../Components/common/loader';
import { DISPLAY_DATE_FORMAT } from '../../Components/constants';
import moment from 'moment';
import { showSuccess, showError } from '../../Components/Notifications';
import { FILE_EXTENSION } from '../../Components/constants/index';
import InfiniteScroll from 'react-infinite-scroll-component';

import { BiPlus } from 'react-icons/bi';
import CreateUserGuidePage from './CreateUserGuidePage';
import { matchSorter } from 'match-sorter';
import { MdKeyboardArrowLeft ,MdKeyboardArrowRight} from 'react-icons/md';
import Filter from './Filter';
import AlertModal from './AlertModal';

import {
  Row,
  Card,
  Col,
  CardImg,
  CardTitle,
  CardBody,
  CardSubtitle,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import {
  getAllUserGuideRequest,
  changeStatusUserGuideRequest,
  deleteUserGuideRequest,
  updateUserGuideRequest,
  getAllTopicsRequest,
  deleteUserGuideSuccess,
  changeStatusUserGuideSuccess,
  getAllUserGuideSuccess,
  addUserGuideSuccess,
  updateUserGuideSuccess,
} from '../../redux/userGuide/userGuideActions';
import { isStaffUser, isSuperAdmin } from '../../Components/common/utils';
const ManageUserGuide = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  let form = new FormData();
  const [isHideBar, setHideBar] = useState(false);
  const [toggleRightBar, setToggleRightBar] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [userGuideList, setUserGuideList] = useState([]);
  const [isPublishModal, setIsPublishModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isToggleRightBar, setIsToggleRightBar] = useState(false);
  const [updateUserGuide, setUpdateUserGuide] = useState('');
  const [fromListing, setFromListing] = useState(false);
  const [defaultUserGuideOptions, setDefaultUserGuideOptions] = useState({});
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(); //
  const [categoryList, setCategoryList] = useState([]);
  const [filterCategoryList, setFilterCategoryList] = useState();
  const [content, setContent] = useState('');
  const [filterSubCategoriesList, setFilterSubCategoriesList] = useState();
  const [typeList, setTypeList] = useState([]);
  const [filterChanges, setFilterChanges] = useState({
    filterCategory: '',
    filterSubCategory: '',
  });
  const [formUserGuideData, setFormUserGuideData] = useState({
    id: '',
    topic: '',
    topic_id: '',
    sub_topic: '',
    sub_topic_id: '',
    title: '',
    description: '',
    publish_status: false,
  });
  const [showChangeStatus, setShowChangeStatus] = useState();
  const [scrollValue, setScrollValue] = useState(0);
  const { UserGuide } = useSelector(state => ({
    UserGuide: state.UserGuide,
  }));

  useEffect(() => {
    window.scrollTo({ top: scrollValue, behavior: 'auto' });
    if (isSuperAdmin() || isStaffUser()) {
      dispatch(getAllUserGuideRequest({ page: pageNumber, fromBackOffice: true, data: { is_delete: 'False',is_backoffice: "True" } }));
      dispatch(getAllTopicsRequest());
      setFromListing(false);
      setIsToggleRightBar(false);

      return () => {
        dispatch(addUserGuideSuccess({}));
        dispatch(updateUserGuideSuccess({}));
        dispatch(deleteUserGuideSuccess({}));
        dispatch(getAllUserGuideSuccess({}));
        dispatch(changeStatusUserGuideSuccess({}));
        dispatch(getAllUserGuideSuccess({}));
      };
    } else {
      history.push('/home');
    }
  }, []);

  useEffect(() => {
    if (UserGuide?.getAllTopicData) {
      if (UserGuide?.getAllTopicData?.data) {
        const {
          data: { result },
        } = UserGuide?.getAllTopicData?.data;
        setFilterCategoryList(result);
      }
    }
  }, [UserGuide.getAllTopicData]);

  useEffect(() => {
    if (UserGuide.updateUserGuideData) {
      setIsPublishModal(false);
      setPageNumber(1);
      setFormUserGuideData({
        id: '',
        topic: '',
        topic_id: '',
        sub_topic: '',
        sub_topic_id: '',
        title: '',
        description: '',
        publish_status: false,
      });
      setUserGuideList([]);
      setFromListing(false);
      dispatch(getAllUserGuideSuccess({}));
      dispatch(getAllUserGuideRequest({ page: 1, fromBackOffice: true, data: { is_delete: 'False',is_backoffice: "True" } }));
    }
  }, [UserGuide.updateUserGuideData]);


  useEffect(() => {
    if (UserGuide.changeStatusUserGuideData) {
      const result = UserGuide?.changeStatusUserGuideData?.data?.data?.result;

      if (result) {
        setScrollValue(parseInt(window.pageYOffset));
        if (showChangeStatus) {
          showSuccess(t('USER_GUIDE.SHOW_PUBLISH'));
        } else {
          showSuccess(t('USER_GUIDE.SHOW_UNPUBLISH'));
        }
        setFromListing(false);
        setPageNumber(1);
        setUserGuideList([]);
        setIsPublishModal(false);
        dispatch(getAllUserGuideSuccess({}));
        dispatch(getAllUserGuideRequest({ page: 1, fromBackOffice: true, data: { is_delete: 'False',is_backoffice: "True" } }));
      }
    }
  }, [UserGuide.changeStatusUserGuideData]);

  useEffect(() => {
    if (UserGuide?.deleteUserGuideData) {
      const result = UserGuide?.deleteUserGuideData?.data?.data?.result;
      if (result) {
        showSuccess(result);
        setPageNumber(1);
        setIsDeleteModal(false);
        setUserGuideList([]);
        setFromListing(false);
        dispatch(getAllUserGuideSuccess({}));
        dispatch(getAllUserGuideRequest({ page: 1, fromBackOffice: true, data: { is_delete: 'False',is_backoffice: "True" } }));
      }
    }
  }, [UserGuide.deleteUserGuideData]);

  useEffect(() => {
    if (UserGuide.getAllUserGuideData?.data?.result?.results) {
      let new_list = [];
      const {
        data: { result },
      } = UserGuide.getAllUserGuideData;

      if (result) {
        if (userGuideList) {
          if (pageNumber > 1) {
            new_list = userGuideList.concat(result.results);
          } else {
            new_list = result.results;
          }
        } else {
          new_list = result.results;
        }
        setUserGuideList(new_list);
        setPageCount(result?.count);
      } else {
        setUserGuideList([]);
      }
    }
    if (scrollValue !== 0) {
      window.scrollTo({ top: scrollValue, behavior: 'auto' });
    }
  }, [UserGuide.getAllUserGuideData]);

  const handlePublishModal = data => {
    setShowChangeStatus(data.status);
    setIsPublishModal(!isPublishModal);
    if (!isPublishModal) setUpdateUserGuide(data);
  };

  const handleDeleteModal = data => {
    setScrollValue(parseInt(window.pageYOffset));
    setIsDeleteModal(!isDeleteModal);
    if (!isDeleteModal) setUpdateUserGuide(data);
  };

  const handlePublishChanges = (data, { publish }) => {
    form.append('id', data.id);
    form.append('status', `${_.capitalize(publish)}`);
    dispatch(changeStatusUserGuideRequest(form));
  };

  const handleDelete = value => {
    dispatch(deleteUserGuideRequest({ id: value }));
  };

  const handleAddUserGuide = () => {
    setIsToggleRightBar(!isToggleRightBar);
  };

  const fetchNextUserGuideData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getAllUserGuideRequest({
        page: pageNumber + 1,
        fromBackOffice: true,
        data: {
          topic_id: filterChanges?.filterCategory?.id,
          subtopic_id: filterChanges?.filterSubCategory?.id,

          status: '',
          is_delete: 'False',
          is_backoffice: "True"
        },
      }),
    );
  };

  const handleReadMore = data => {
    if (!_.isEmpty(data.subtopic_id) && !_.isEmpty(data.topic_id)) {
      history.push(`/user-guide-details?topicId=${data.topic_id}&&sub-topic-name=${data?.subtopic_name}`)

    } else {
      history.push(
        `/user-guide-detail-search-content?unique=${data.id}&&category=${data.topic_id}`,
      );
    }
  };

  const handleFilterCategoryChange = data => {
    if (data.id === 'select') {
      filterChanges.filterCategory = '';
      filterChanges.filterSubCategory = '';
      setPageNumber(1);
      dispatch(getAllUserGuideRequest({ page: pageNumber, fromBackOffice: true, data: { is_delete: 'False',is_backoffice: "True" } }));
    } else {
      filterChanges.filterCategory = data;
    }
    setFilterChanges({ ...filterChanges });
  };

  const handleFilterSubCategoryChange = data => {
    if (data.id === 'select') {
      filterChanges.filterSubCategory = '';
    } else {
      filterChanges.filterSubCategory = data;
    }
    setFilterChanges({ ...filterChanges });
  };

  const handleFilterSubmit = () => {
    if (
      filterChanges &&
      (!_.isEmpty(filterChanges.filterCategory) || !_.isEmpty(filterChanges.filterSubCategory))
    ) {
      dispatch(
        getAllUserGuideRequest({
          fromBackOffice: true,
          data: {
            topic_id: filterChanges?.filterCategory?.id,
            subtopic_id: filterChanges?.filterSubCategory?.id,
            status: '',
            is_delete: 'False',
            is_backoffice: "True"
          },
        }),
      );
      setUserGuideList([]);
      setPageNumber(1);
    } else {
      dispatch(getAllUserGuideRequest({ page: 1, fromBackOffice: true, data: { is_delete: 'False',is_backoffice: "True" } }));
    }
  };

  useEffect(() => {
    if (UserGuide?.addUserGuideData?.data?.data?.result) {
      showSuccess(UserGuide?.addUserGuideData?.data?.data?.result);
    }
  }, [UserGuide.addUserGuideData]);

  useEffect(() => {
    if (UserGuide?.updateUserGuideData?.data?.data?.result) {
      showSuccess(UserGuide?.updateUserGuideData?.data?.data?.result);
    }
  }, [UserGuide.updateUserGuideData]);

  return (
    <>
      {isPublishModal && (
        <>
          <AlertModal
            isOpen={isPublishModal}
            isToggle={handlePublishModal}
            onClick={handlePublishChanges}
            data={updateUserGuide}
            onPublishClick={true}
            onDeleteClick={false}
          />
        </>
      )}
      {isDeleteModal && (
        <>
          <AlertModal
            isOpen={isDeleteModal}
            isToggle={handleDeleteModal}
            onClick={handleDelete}
            data={updateUserGuide}
            onDeleteClick={true}
            onPublishClick={false}
          />
        </>
      )}

      {isToggleRightBar && (
        <CreateUserGuidePage
          formUserGuideData={formUserGuideData}
          initialValues={defaultUserGuideOptions}
          fromListing={fromListing}
          categoryList={categoryList}
        />
      )}
      <div className="st-helper-sidebar">
        <div className="static-resource-wrapper">
          <Row className="">
            {!isHideBar && (
              <Col md={2} lg={2} className="filter-col">
                <div className="hide-bar md-expand1">
                  <span className="cursor-ponter navyBlueColor ">
                    {t('FILTER.HIDE_BAR')}
                    <span>
                      <MdKeyboardArrowRight
                        className="hide-icon expand-margin"
                        onClick={() => setHideBar(!isHideBar)}
                        size={25}
                      />
                    </span>
                  </span>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            {!isHideBar ? (
              <Col lg={2} md={2} className="filter-col">
                <div className="user-guide-filter-section-scroll">
                  <Filter
                    categoryList={filterCategoryList}
                    subCategoryList={filterSubCategoriesList}
                    typeList={''}
                    handleFilterCategoryChange={handleFilterCategoryChange}
                    handleFilterSubCategoryChange={handleFilterSubCategoryChange}
                    filterTitleChange={''}
                    filterDateHandler={''}
                    selectedFilterCategory={''}
                    handleFilterSubmit={handleFilterSubmit}
                  />
                </div>
              </Col>
            ) : (
              ''
            )}
            <Col lg={isHideBar ? 12 : 10} md={10} className="static-data-col">
              {isHideBar && (
                <div className="filter-expand-bar md-expand1 manage-user-expand">
                  <span className="cursor-ponter navyBlueColor ">
                    {t('FILTER.EXPAND_BAR')}
                    <span>
                      <MdKeyboardArrowLeft
                        className="hide-icon expand-margin"
                        onClick={() => setHideBar(!isHideBar)}
                        size={25}
                      />
                    </span>
                  </span>
                </div>
              )}
              <div className={isHideBar ? 'manage-user-heading-left row mt-4' : 'manage-static-heading row'}>
                <span className="col-lg-1">
                  <img
                    src={RightArrow}
                    className="manage-static-header-back-button"
                    onClick={() => history.goBack()}
                  />
                </span>
                <span className="col-lg-5">
                  <h5>{t('USER_GUIDE.MANAGE_USER_GUIDE')}</h5>
                </span>
                <div className='manage-user-guide-filter-icon-text col-lg-6'>
                  <span
                    onClick={() => history.push('/manage-user-guide-form/add')}
                    className="manage-news-feed-cursor-pointer"
                  >
                    <BiPlus size={30} color="#2a547c" className="news-feed-add-style" />
                    <h5 className="st-filter-text news-feed-add-text">
                      {t('USER_GUIDE.ADD_USER_GUIDE')}
                    </h5>
                  </span>
                </div>
              </div>

              <div
                className={isHideBar ? 'st-tab-content mt-4 st-tab-margin' : 'st-tab-content mt-4'}
              >
                <Row>
                  <Row>
                    <Col lg="12">
                      <div className="st-card-wrapper">
                        <div className="loader-div">
                          {UserGuide.isGetAllUserGuideRequesting && <Loader />}
                        </div>
                        {_.isEmpty(userGuideList) && !UserGuide.isGetAllUserGuideRequesting && _.isEmpty(UserGuide.getAllUserGuideData) && (
                          <h2 className="user-guide-no-records-found" style={{textAlign: 'center'}}>
                            {t('USER_GUIDE.NO_RECORDS_FOUND')}
                          </h2>
                        )}
                        <InfiniteScroll
                          dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
                          next={fetchNextUserGuideData}
                          hasMore={pageNumber * 10 < pageCount ? true : false}
                          loader={
                            UserGuide.isGetAllUserGuideRequesting && (
                              <center>
                                {/* <h4>{t('USER_GUIDE.LOADING')} </h4> */}
                                <Loader />
                              </center>
                            )
                          }
                        >
                          {userGuideList.map(i => {
                            return (
                              <Card className="st-card card-one" style={{paddingBottom:"15px" , marginLeft:"15px"}}>
                                <div className="card-body manage-user-guide-card-body">
                                  <h5 className="card-title font-weight-light st-txt-primary">
                                    {i?.title}
                                  </h5>
                                  {i?.content?.length > 100 ? (
                                    <div id="card-description">
                                      <div
                                        className="news-feed-raw-data-section"
                                        dangerouslySetInnerHTML={{
                                          __html: i?.content?.substring(0, 200),
                                        }}
                                      />
                                      <span>...</span>
                                      <a className="read-more" onClick={() => handleReadMore(i)}>
                                        {t('USER_GUIDE.READ_MORE')}
                                      </a>
                                    </div>
                                  ) : (
                                    <div>
                                      <div dangerouslySetInnerHTML={{ __html: i?.content }} />
                                      {/* <span>...</span>
                                      <a className="read-more" onClick={() => handleReadMore(i)}>
                                        {t('USER_GUIDE.READ_MORE')}
                                      </a> */}
                                    </div>
                                  )}
                                  <p className="card-text">
                                    {t('USER_GUIDE.CATEGORY')}: {i?.topic_name}
                                  </p>
                                  {
                                    <p className="card-text">
                                      {t('USER_GUIDE.SUB_CATEGORY')}:{' '}
                                      {i?.subtopic_name
                                        ? i?.subtopic_name
                                        : t('USER_GUIDE.NOT_AVAILABLE')}
                                    </p>
                                  }
                                  {!_.isEmpty(i?.updated_date) && (
                                    <p className="card-text">
                                      {t('USER_GUIDE.UPDATED_DATE')}:{' '}
                                      {moment(i?.updated_date).format(DISPLAY_DATE_FORMAT)}
                                    </p>
                                  )}

                                  <div className="edit-delete-options" style={{position:"absolute" , right:"16px" , bottom:"0px"}}>
                                    {i?.status === true ? (
                                      <>
                                        <i
                                          id="unpublish_news"
                                          data-tip
                                          data-for="unpublish_news"
                                          size={23}
                                          className="fa fa-check edit-btn publish-icon-style"
                                          onClick={() => handlePublishModal(i)}
                                          style={{fontSize:"21px"}}
                                        ></i>
                                      </>
                                    ) : (
                                      <>
                                        <i
                                          id="publish_news"
                                          className=" fa fa-times edit-btn publish-icon-style"
                                          data-tip
                                          data-for="publish_news"
                                          size={23}
                                          onClick={() => handlePublishModal(i)}
                                          style={{fontSize:"21px"}}

                                        ></i>
                                      </>
                                    )}
                                    <i
                                      class="fa fa-pencil edit-btn"
                                      onClick={() => {
                                        if (!_.isEmpty(i.topic_id) && !_.isEmpty(i.subtopic_id)) {
                                          history.push(
                                            `/manage-user-guide-form/edit?unique=${i.id}&&category=${i.topic_id}&&sub-category=${i.subtopic_id}`,
                                          );
                                        } else {
                                          history.push(
                                            `/manage-user-guide-form/edit?unique=${i.id}&&category=${i.topic_id}`,
                                          );
                                        }
                                      }}
                                      id="edit-news"
                                      data-tip
                                      data-for="edit_news"
                                      style={{fontSize:"21px"}}

                                    ></i>
                                    <i
                                      class="fa fa-trash delete-btn"
                                      onClick={() => handleDeleteModal(i)}
                                      id="delete-news"
                                      data-tip
                                      data-for="delete_news"
                                      style={{fontSize:"21px"}}

                                    ></i>
                                  </div>
                                  <ReactTooltip id="edit_news" effect="solid" place="top">
                                    {t('NEWS_FEED.EDIT')}
                                  </ReactTooltip>
                                  <ReactTooltip id="delete_news" effect="solid" place="top">
                                    {t('NEWS_FEED.DELETE')}
                                  </ReactTooltip>
                                  <ReactTooltip id="publish_news" effect="solid" place="top">
                                    {t('NEWS_FEED.CLICK_PUBLISH')}
                                  </ReactTooltip>
                                  <ReactTooltip id="unpublish_news" effect="solid" place="top">
                                    {t('NEWS_FEED.CLICK_UNPUBLISH')}
                                  </ReactTooltip>
                                </div>
                              </Card>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    </Col>
                  </Row>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ManageUserGuide;
