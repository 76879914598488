import {
  FIELD_DATA_CONST,
    // FIELD_DATA_BY_ID_CONST,
    
    INSURANCE_COMPANY_LIST_CONST,

    // SUBMIT_CONFIGURATION_CONST,

  } from './manageFieldDataConst';
  
  const fieldDataList = (state = { }, action) => {
    switch (action.type) {
      case FIELD_DATA_CONST.FIELD_DATA_LIST_REQUEST:
        return {
          ...state,
          isFieldDataListDataRequesting: true,
          FieldDataListData: false,
          FieldDataListDataError: false,
          FieldDataListDataNetworkError: false,
          
          isFarmerFieldDataRequesting: true,
          isEnrollmentFieldDataRequesting: true,
          isInsuranceFieldDataRequesting: true,
          isImpactFieldDataRequesting: true,
          isClaimFieldDataRequesting: true,

        };
      case FIELD_DATA_CONST.FIELD_DATA_LIST_SUCCESS:
        return {
          ...state,
          isFieldDataListDataRequesting: false,
          FieldDataListData: action.payload.response,
          FieldDataListDataError: false,
          FieldDataListDataNetworkError: false,
          FarmerFieldData:action.payload.response?.id === "Farmer Information" ? action.payload.response : true,
          EnrollmentFieldData:action.payload.response?.id === "Enrollment" ? action.payload.response : true,
          InsuranceFieldData:action.payload.response?.id === "Understanding the Insurance context" ?  action.payload.response : true,
          ImpactFieldData:action.payload.response?.id === "Impact Evaluation" ?  action.payload.response : true,
          ClaimFieldData:action.payload.response?.id === "Claim settlement" ?  action.payload.response : true,
          // ClaimFieldData:action.payload.response?.id === "Claim settlement" ? action.payload.response : false,
          isFarmerFieldDataRequesting: action.payload.response?.id === "Farmer Information" && false,
          isEnrollmentFieldDataRequesting: action.payload.response?.id === "Enrollment" && false,
          isInsuranceFieldDataRequesting: action.payload.response?.id === "Understanding the Insurance context" && false,
          isImpactFieldDataRequesting: action.payload.response?.id === "Impact Evaluation" && false,
          isClaimFieldDataRequesting: action.payload.response?.id === "Claim settlement" && false,

        };
      case FIELD_DATA_CONST.FIELD_DATA_LIST_FAILED:
        return {
          ...state,
          isFieldDataListDataRequesting: false,
          FieldDataListData: false,
          FieldDataListDataError: action.payload.error ? false : action.payload.response,
          FieldDataListDataNetworkError: action.payload.error ? action.payload.error : false,
          FarmerFieldData:action.payload.response?.id === "Farmer Information" ? false : action.payload.response,
          EnrollmentFieldData:action.payload.response?.id === "Enrollment" ?  false : action.payload.response,
          InsuranceFieldData:action.payload.response?.id === "Understanding the Insurance context" ? false :  action.payload.response,
          ImpactFieldData:action.payload.response?.id === "Impact Evaluation" ? false :  action.payload.response,
          ClaimFieldData:action.payload.response?.id === "Claim settlement" ?  false : action.payload.response,
          // FarmerFieldData:false,
          // EnrollmentFieldData:false,
          // InsuranceFieldData: false,
          // ImpactFieldData:false,
          // ClaimFieldData:false,
          isFarmerFieldDataRequesting: action.payload.response?.id === "Farmer Information" && false,
          isEnrollmentFieldDataRequesting: action.payload.response?.id === "Enrollment" && false,
          isInsuranceFieldDataRequesting: action.payload.response?.id === "Understanding the Insurance context" && false,
          isImpactFieldDataRequesting: action.payload.response?.id === "Impact Evaluation" && false,
          isClaimFieldDataRequesting: action.payload.response?.id === "Claim settlement" && false,

        };
  
      case FIELD_DATA_CONST.FIELD_DATA_BY_ID_REQUEST:
        return {
          ...state,
          isFieldDataByIdRequesting: true,
          fieldDataById: false,
          fieldDataByIdError: false,
          fieldDataByIdNetworkError: false,
        };
      case FIELD_DATA_CONST.FIELD_DATA_BY_ID_SUCCESS:
        return {
          ...state,
          isFieldDataByIdRequesting: false,
          fieldDataById: action.payload.response,
          fieldDataByIdError: false,
          fieldDataByIdNetworkError: false,
        };
      case FIELD_DATA_CONST.FIELD_DATA_BY_ID_FAILED:
        return {
          ...state,
          isFieldDataByIdRequesting: false,
          fieldDataById: false,
          fieldDataByIdError: action.payload.error ? false : action.payload.response,
          fieldDataByIdNetworkError: action.payload.error ? action.payload.error : false,
        };
  
        case FIELD_DATA_CONST.INSURANCE_COMPANY_LIST_REQUEST:
          return {
            ...state,
            insuranceCompanyListRequesting: true,
            insuranceCompanyListData: false,
            insuranceCompanyListDataError: false,
            insuranceCompanyListDataNetworkError: false,
          };
        case FIELD_DATA_CONST.INSURANCE_COMPANY_LIST_SUCCESS:
          return {
            ...state,
            insuranceCompanyListRequesting: false,
            insuranceCompanyListData: action.payload.response,
            insuranceCompanyListDataError: false,
            insuranceCompanyListDataNetworkError: false,
          };
        case FIELD_DATA_CONST.INSURANCE_COMPANY_LIST_FAILED:
          return {
            ...state,
            insuranceCompanyListRequesting: false,
            insuranceCompanyListData: false,
            insuranceCompanyListDataError: action.payload.error ? false : action.payload.response,
            insuranceCompanyListDataNetworkError: action.payload.error ? action.payload.error : false,
          };
      

        case FIELD_DATA_CONST.SUBMIT_CONFIGURATION_REQUEST:
        return {
          ...state,
          isSubmitConfigurationRequesting: true,
          submitConfiguration: false,
          submitConfigurationError: false,
          submitConfigurationNetworkError: false,
        };
      case FIELD_DATA_CONST.SUBMIT_CONFIGURATION_SUCCESS:
        return {
          ...state,
          isSubmitConfigurationRequesting: false,
          submitConfiguration: action.payload.response,
          submitConfigurationError: false,
          submitConfigurationNetworkError: false,
        };
      case FIELD_DATA_CONST.SUBMIT_CONFIGURATION_FAILED:
        return {
          ...state,
          isSubmitConfigurationRequesting: false,
          submitConfiguration: false,
          submitConfigurationError: action.payload.error ? false : action.payload.response,
          submitConfigurationNetworkError: action.payload.error ? action.payload.error : false,
        };

  

        case FIELD_DATA_CONST.INVITE_CONFIGURATION_REQUEST:
        return {
          ...state,
          isInviteConfigurationRequesting: true,
          isInviteConfigurationRequesting1: true,
          inviteConfigurationData: false,
          inviteConfigurationError: false,
          inviteConfigurationNetworkError: false,
        };
      case FIELD_DATA_CONST.INVITE_CONFIGURATION_SUCCESS:
        return {
          ...state,
          isInviteConfigurationRequesting: false,
          isInviteConfigurationRequesting1: false,
          inviteConfigurationData: action.payload.response,
          inviteConfigurationError: false,
          inviteConfigurationNetworkError: false,
        };
      case FIELD_DATA_CONST.INVITE_CONFIGURATION_FAILED:
        return {
          ...state,
          isInviteConfigurationRequesting: false,
          isInviteConfigurationRequesting1: false,
          inviteConfigurationData: false,
          inviteConfigurationError: action.payload.error ? false : action.payload.response,
          inviteConfigurationNetworkError: action.payload.error ? action.payload.error : false,
        };

        case FIELD_DATA_CONST.COMPONENTS_LIST_REQUEST:
        return {
          ...state,
          componentsListRequesting: true,
          componentsListData: false,
          componentsListError: false,
          componentsListNetworkError: false,
        };
      case FIELD_DATA_CONST.COMPONENTS_LIST_SUCCESS:
        return {
          ...state,
          componentsListRequesting: false,
          componentsListData: action.payload.response,
          componentsListError: false,
          componentsListNetworkError: false,
        };
      case FIELD_DATA_CONST.COMPONENTS_LIST_FAILED:
        return {
          ...state,
          componentsListRequesting: false,
          componentsListData: false,
          componentsListError: action.payload.error ? false : action.payload.response,
          componentsListNetworkError: action.payload.error ? action.payload.error : false,
        };

        case FIELD_DATA_CONST.COMPONENT_BY_ID_REQUEST:
        return {
          ...state,
          componentsByIdRequesting: true,
          componentsByIdData: false,
          componentsByIdError: false,
          componentsByIdNetworkError: false,
        };
      case FIELD_DATA_CONST.COMPONENT_BY_ID_SUCCESS:
        return {
          ...state,
          componentsByIdRequesting: false,
          componentsByIdData: action.payload.response,
          componentsByIdError: false,
          componentsByIdNetworkError: false,
        };
      case FIELD_DATA_CONST.COMPONENT_BY_ID_FAILED:
        return {
          ...state,
          componentsByIdRequesting: false,
          componentsByIdData: false,
          componentsByIdError: action.payload.error ? false : action.payload.response,
          componentsByIdNetworkError: action.payload.error ? action.payload.error : false,
        };


        case FIELD_DATA_CONST.MANAGE_SURVEYORS_LIST_REQUEST:
        return {
          ...state,
          managesurveyorsListRequesting: true,
          managesurveyorsListData: false,
          managesurveyorsListError: false,
          managesurveyorsListNetworkError: false,
        };
      case FIELD_DATA_CONST.MANAGE_SURVEYORS_LIST_SUCCESS:
        return {
          ...state,
          managesurveyorsListRequesting: false,
          managesurveyorsListData: action.payload.response,
          managesurveyorsListError: false,
          managesurveyorsListNetworkError: false,
        };
      case FIELD_DATA_CONST.MANAGE_SURVEYORS_LIST_FAILED:
        return {
          ...state,
          managesurveyorsListRequesting: false,
          managesurveyorsListData: false,
          managesurveyorsListError: action.payload.error ? false : action.payload.response,
          managesurveyorsListNetworkError: action.payload.error ? action.payload.error : false,
        };


        case FIELD_DATA_CONST.DELETE_SURVEYORS_REQUEST:
        return {
          ...state,
          deleteSurveyorsRequesting: true,
          deleteSurveyorsData: false,
          deleteSurveyorsError: false,
          deleteSurveyorsNetworkError: false,
        };
      case FIELD_DATA_CONST.DELETE_SURVEYORS_SUCCESS:
        return {
          ...state,
          deleteSurveyorsRequesting: false,
          deleteSurveyorsData: action.payload.response,
          deleteSurveyorsError: false,
          deleteSurveyorsNetworkError: false,
        };
      case FIELD_DATA_CONST.DELETE_SURVEYORS_FAILED:
        return {
          ...state,
          deleteSurveyorsRequesting: false,
          deleteSurveyorsData: false,
          deleteSurveyorsError: action.payload.error ? false : action.payload.response,
          deleteSurveyorsNetworkError: action.payload.error ? action.payload.error : false,
        };
      
        case FIELD_DATA_CONST.SURVEY_CONFIGURATION_REQUEST:
          return {
            ...state,
            isSurveyConfigurationRequesting: true,
            surveyConfigurationData: false,
            surveyConfigurationDataError: false,
            surveyConfigurationDataNetworkError: false,
          };
        case FIELD_DATA_CONST.SURVEY_CONFIGURATION_SUCCESS:
          return {
            ...state,
            isSurveyConfigurationRequesting: false,
            surveyConfigurationData: action.payload.response,
            surveyConfigurationDataError: false,
            surveyConfigurationDataNetworkError: false,
          };
        case FIELD_DATA_CONST.SURVEY_CONFIGURATION_FAILED:
          return {
            ...state,
            isSurveyConfigurationRequesting: false,
            surveyConfigurationData: false,
            surveyConfigurationDataError: action.payload.error ? false : action.payload.response,
            surveyConfigurationDataNetworkError: action.payload.error ? action.payload.error : false,
          };


      default:
        return { ...state };
    }
  };
  
  export default fieldDataList;
  