import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import _, { identity } from 'lodash';
import moment from 'moment';
import { HiDotsHorizontal, HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { t } from 'react-multi-lang';
import { ReactComponent as FarmDefaultIcon } from '../../../assets/Images/farm_default_icon.svg';
import Loader from '../../../Components/common/loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAllByCriteriaFarmListRequest } from '../../../redux/actions';

const FarmListing = (props) => {
  const {
    farmTrackerList,
    toggle,
    getSelectedFarm,
    handleDelete,
    getFarmData,
    projectDetails,
    setProjectDetails,
    selectedFarmsList,
    selectedFarm,
    selectedFarmIndex,
  } = props;
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setCount] = useState();
  const [filteredFarmsList, setfilteredFarmsList] = useState([]);

  const {  FarmTracker } = useSelector((state) => ({
    FarmTracker: state.FarmTracker,
  }));

  useEffect(() => {
    setCount(Number(projectDetails?.farm_count));
  }, [projectDetails]);

  useEffect(() => {
    setfilteredFarmsList([...new Map(farmTrackerList.map(item => [item.id, item])).values()]) //remving duplicates objects
  }, [farmTrackerList]);
  
  const fetchNextStaticResources = () => {
    let reqData = {
      page: pageNumber + 1,
      farm_tracker_project_id: projectDetails?.id,
    };
    setPageNumber(pageNumber + 1);
    dispatch(getAllByCriteriaFarmListRequest(reqData));
  };
  return (
    <>
      <div id="scrollableDiv" className="farm-list-scroll-st">
        <InfiniteScroll
          dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
          next={fetchNextStaticResources}
          hasMore={pageNumber * 10 < pageCount ? true : false}
          loader={<h4>Loading ... </h4>}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scrollableDiv"
        >
          {filteredFarmsList?.length ? (
            filteredFarmsList?.map((item, key) => {
              return (
                <>
                  <div>
                    <Row>
                      <Col>
                        <Card
                          onClick={() => getFarmData(item, key)}
                          className={
                            selectedFarm?.id === item?.id || selectedFarmIndex === key
                              ? "selected-farm-track-list-st mb-1 cursor-ponter"
                              : 'farm-track-list-st mb-1 cursor-ponter'
                          }
                        >
                          <CardBody key={key}>
                            <Row className="mx-1 listing-st">
                              <Col sm="4" lg="4" md="4">
                                {' '}
                                {item?.profile_icon?.includes("data") ? (
                                  <img
                                    src={item.profile_icon}
                                    alt="profile icon"
                                    width={50}
                                    height={40}
                                  />
                                ) : (
                                  <FarmDefaultIcon />
                                )}
                              </Col>
                              <Col sm="6" lg="6" md="6">
                                <Row>
                                  {' '}
                                  <Col
                                    style={
                                      selectedFarm?.id === item?.id || selectedFarmIndex === key
                                        ? { fontWeight: 'bold' }
                                        : {}
                                    }
                                  >
                                    {_.capitalize(item?.name)}
                                  </Col>
                                </Row>
                                <Row className="date-st">
                                  <Col
                                    style={
                                      selectedFarm?.id === item?.id || selectedFarmIndex === key
                                        ? { fontWeight: 'bold' }
                                        : {}
                                    }
                                  >
                                    {moment(new Date(item?.updated_date))
                                      .local()
                                      .format('Do MMM YYYY')}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </>
              );
            })
          ) : (
            <div className="mx-1 draw-msg-st">
              <div className="mt-1">{t('FARM_TRACKER.NO_FARMS')}</div>
              <div className="sub-text mt-1"> {t('FARM_TRACKER.DRAW_FARM_TEXT')}</div>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default FarmListing;
