const FARM_TRACKER_CONST = {
 ADD_FARM_TRACKER_REQUEST: 'ADD_FARM_TRACKER_REQUEST',
 ADD_FARM_TRACKER_SUCCESS: 'ADD_FARM_TRACKER_SUCCESS',
 ADD_FARM_TRACKER_FAILED: 'ADD_FARM_TRACKER_FAILED',

 EDIT_FARM_TRACKER_REQUEST: 'EDIT_FARM_TRACKER_REQUEST',
 EDIT_FARM_TRACKER_SUCCESS: 'EDIT_FARM_TRACKER_SUCCESS',
 EDIT_FARM_TRACKER_FAILED: 'EDIT_FARM_TRACKER_FAILED',

 DELETE_FARM_TRACKER_REQUEST: 'DELETE_FARM_TRACKER_REQUEST',
 DELETE_FARM_TRACKER_SUCCESS: 'DELETE_FARM_TRACKER_SUCCESS',
 DELETE_FARM_TRACKER_FAILED: 'DELETE_FARM_TRACKER_FAILED',

 GET_FARM_DETAILS_BY_ID_REQUEST:  'GET_FARM_DETAILS_BY_ID_REQUEST',
 GET_FARM_DETAILS_BY_ID_SUCCESS:'GET_FARM_DETAILS_BY_ID_SUCCESS',
 GET_FARM_DETAILS_BY_ID_FAILED: 'GET_FARM_DETAILS_BY_ID_FAILED',

 GET_FARM_LIST_REQUEST: 'GET_FARM_LIST_REQUEST',
 GET_FARM_LIST_SUCCESS: 'GET_FARM_LIST_SUCCESS',
 GET_FARM_LIST_FAILED: 'GET_FARM_LIST_FAILED',

 ADD_PROJECT_REQUEST: 'ADD_PROJECT_REQUEST',
 ADD_PROJECT_SUCCESS: 'ADD_PROJECT_SUCCESS',
 ADD_PROJECT_FAILED: 'ADD_PROJECT_FAILED',

 EDIT_PROJECT_REQUEST: 'EDIT_PROJECT_REQUEST',
 EDIT_PROJECT_SUCCESS: 'EDIT_PROJECT_SUCCESS',
 EDIT_PROJECT_FAILED: 'EDIT_PROJECT_FAILED',

 GET_PROJECT_DETAILS_BY_ID_REQUEST:  'GET_PROJECT_DETAILS_BY_ID_REQUEST',
 GET_PROJECT_DETAILS_BY_ID_SUCCESS:'GET_PROJECT_DETAILS_BY_ID_SUCCESS',
 GET_PROJECT_DETAILS_BY_ID_FAILED: 'GET_PROJECT_DETAILS_BY_ID_FAILED',

 GET_PROJECT_LIST_REQUEST: 'GET_PROJECT_LIST_REQUEST',
 GET_PROJECT_LIST_SUCCESS: 'GET_PROJECT_LIST_SUCCESS',
 GET_PROJECT_LIST_FAILED: 'GET_PROJECT_LIST_FAILED',
 
 DELETE_PROJECT_LIST_REQUEST: 'DELETE_PROJECT_LIST_REQUEST',
 DELETE_PROJECT_LIST_SUCCESS: 'DELETE_PROJECT_LIST_SUCCESS',
 DELETE_PROJECT_LIST_FAILED: 'DELETE_PROJECT_LIST_FAILED',

}

export default FARM_TRACKER_CONST;