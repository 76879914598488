import React, { useState, useEffect, useRef } from 'react';
import graphCrossIcon from '../../assets/Images/graphCrossIcon.svg';

import { Row, Col, Input, Label } from 'reactstrap';

import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import EmailIcon from '../../assets/Images/emailIcon.png';
import { getIndices } from '../../redux/actions';
import _ from 'lodash';
import { Button } from 'reactstrap';
import RiskAnalyticsPlotChart from './plotCharts';
import ExpandIcon from '../../assets/Images/expand.png';

import ReactTooltip from 'react-tooltip';

import {
  RISK_ANALYTICS_MODULE,
  RISK_ANALYTICS_GRAPH_INDICATORS_CATEGORY,
  DATE_FORMAT,
  RISK_ANALYTICS_GRAPH_INDICATORS_LIST,
} from '../../Components/constants';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';

const CurrentWatherForecast = (props) => {
  const {
    setIndicatorsCategoryList,
    indicatorsCategoryList,
    rightBarActiveTab,
    setUpdateGraphValues,
    updatedGraphValues,
    isShareInfomodalOpen,
    handleInitialDate,
    handleOnChangeDates,
    initialSelectedDate,
    setGraphScreenshotImage,
    handleViewRiskGraph,
    newRiskGraphData,
    renderCharts,
    isHideBar,
    handleOpenModal,
    selectedIndicatorRiskParam,
    openFullScreen,
    handleOpenCurrentWeatherSplitter,
    handleOpen,
    setIsRightCollapseOpen,
    setSelectedDate,
  } = props;
  const dispatch = useDispatch();

  const { RiskAnalytics } = useSelector((state) => ({
    RiskAnalytics: state.RiskAnalytics,
  }));

  const elementRef = useRef(null);

  const [indicators, setIndicators] = useState();

  const [indicatorsList, setIndicatorsList] = useState('');

  const [selectedParam, setSelectedParam] = useState([{ value: 'NDVI' }]);
  const [isGraphDisabled, setIsGraphDisabled] = useState(false);
  const [dateInvalidError, setDateInvalidError] = useState(false);

  useEffect(() => {
    dispatch(getIndices());
  }, []);

  useEffect(() => {
    if (
      indicatorsCategoryList?.length &&
      indicatorsCategoryList?.some((i) => i.selectedIndicators?.length) &&
      !dateInvalidError
    ) {
      // setDateInvalidError(false)
      setIsGraphDisabled(false);
    } else {
      setIsGraphDisabled(true);
      // setDateInvalidError(true);
    }
  }, [indicatorsCategoryList]);

  useEffect(() => {
    let endDateError =
      document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true' ? true : false;
    let startDateError =
      document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true' ? true : false;
    if (endDateError || startDateError) {
      // setDateInvalidError(true);
      setIsGraphDisabled(true);
    } else {
      // setDateInvalidError(false);
      setIsGraphDisabled(false);
    }
  }, [initialSelectedDate]);

  const handleExpand = (value, type) => {
    if (type === 'fullScreen') {
      handleOpen(value);
    } else {
      handleOpenCurrentWeatherSplitter(value);
      handleOpen(false);
    }
  };

  const handleClose = () => {
    indicatorsCategoryList.map((item) => (item.selectedIndicators = []));
    handleOpenCurrentWeatherSplitter(false);
    if (openFullScreen) {
      setIsRightCollapseOpen(true);
    }
    setSelectedDate((prev) => {
      return {
        map: {
          ...initialSelectedDate.map,
          startDate: moment().subtract(30, 'days').format(DATE_FORMAT),
          endDate: moment(new Date()).format(DATE_FORMAT),
        },
      };
    });
  };

  const renderAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map((param) => {
          if (param?.name) {
            let data = {
              value: param.name,
              label: param.name === 'Dry spell' ? 'Dry Spell' : param.name,
              id: param.id,
              api_url: param.api_url,
            };
            options.push(data);
          }
          return true;
        });
        return options;
      }
    }
  };

  useEffect(() => {
    let arr = [];
    if (!_.isEmpty(RiskAnalytics?.indicesData)) {
      let result = '';
      if (RiskAnalytics?.indicesData?.data)
        if (RiskAnalytics?.indicesData?.data?.result) {
          result = RiskAnalytics?.indicesData?.data?.result
            ? RiskAnalytics?.indicesData?.data?.result
            : [];
          if (result) {
            result?.map((i) => {
              if (i.name === 'SPI') {
                return arr.push({
                  id: i.id,
                  name: i.name,
                  value: i.name,
                  label: i.name,
                  api_url: 'risk-analytics/spi/monthly',
                  slug: i.slug,
                });
              }
              return arr.push({
                id: i.id,
                name: i.name,
                value: i.name,
                label: i.name,
                api_url: i.api_url,
                slug: i.slug,
              });
            });
          }
          if (arr?.length) {
            let indicatorsData = RISK_ANALYTICS_GRAPH_INDICATORS_LIST;
            let updatedIndicatorsData = indicatorsData?.map((item) => {
              let itemData = _.cloneDeep(item);
              let indicatorsList = [];
              let slugList = RISK_ANALYTICS_GRAPH_INDICATORS_CATEGORY?.find(
                (i) => i.id === itemData?.id,
              )?.slug_list;
              if (slugList?.length) {
                arr?.map((m) => {
                  if (m?.label === 'Dry spell') {
                    let changedToDrySpell = {
                      api_url: m?.api_url,
                      id: m?.id,
                      label: 'Dry Spell',
                      name: 'Dry Spell',
                      slug: m?.slug,
                      value: 'Dry Spell',
                    };
                    if (slugList?.includes(m?.slug)) {
                      indicatorsList?.push(changedToDrySpell);
                    }
                  } else {
                    if (slugList?.includes(m?.slug)) {
                      indicatorsList?.push(m);
                    }
                  }
                });
              }
              if (indicatorsList?.length) {
                itemData.list = indicatorsList;
                let selectedIndicators = [];
                if (item?.id === 'crop-indicators') {
                  selectedIndicators?.push(indicatorsList[0]);
                }
                if (selectedIndicators?.length) itemData.selectedIndicators = selectedIndicators;
              }
              return itemData;
            });
            if (updatedIndicatorsData?.length) {
              setIndicatorsCategoryList(updatedIndicatorsData);
            }
          }
          setIndicatorsList({
            id: 'Select All',
            value: 'Select All',
            label: 'Select All',
            api_url: 'Selecte All',
            slug: 'Selecte All',
          });
          setIndicatorsList(arr);
        }
    }
  }, [RiskAnalytics?.indicesData, rightBarActiveTab]);

  useEffect(() => {
    if (RiskAnalytics) {
      setIndicators(RiskAnalytics?.indicesData?.data?.result);
    }
  }, [RiskAnalytics]);

  useEffect(() => {
    if (isShareInfomodalOpen) {
      handleTakeScreenshot();
    }
  }, [isShareInfomodalOpen]);

  //for enabling and disable start and end date field when ONLY SPI is selected
  useEffect(() => {
    if (indicatorsCategoryList?.length) {
      let selectedIndicatorArray = [];
      indicatorsCategoryList.forEach((element) => {
        element?.selectedIndicators?.forEach((indicator) => {
          selectedIndicatorArray.push(indicator);
        });
      });
    }
  }, [indicatorsCategoryList]);

  const handleIndicators = (value, data) => {
    let indicatorsData = [...indicatorsCategoryList];
    let updatedData = indicatorsData?.map((item) => {
      let itemData = _.cloneDeep(item);
      if (itemData?.id === data?.id) {
        itemData.selectedIndicators = value;
      }
      return itemData;
    });
    if (updatedData) {
      setIndicatorsCategoryList(updatedData);
    }
  };

  const handleViewGraph = (e) => {
    clearGraphData();
    handleViewRiskGraph({ isDefaultParam: false });
  };

  useEffect(() => {
    if (newRiskGraphData?.data?.result) {
    }
  }, [newRiskGraphData]);

  const handleOpenModal1 = (value) => {
    handleOpenModal(value);
  };

  const handleOptionDisable = (options, item) => {
    let selectedIndicatorCount = indicatorsCategoryList?.find((i) => i.id === item?.id)
      ?.selectedIndicators?.length;
    let maxCount = item?.id === 'crop-indicators' || item?.id === 'weather-indicators' ? 2 : 1;
    if (selectedIndicatorCount >= maxCount) return true;
    else {
      return false;
    }
  };

  const takeScreenShot = async (node) => {
    const dataURI = await htmlToImage.toJpeg(node);
    return dataURI;
  };

  const downloadScreenshot = () => {
    takeScreenShot(elementRef.current).then((res) => setGraphScreenshotImage(res));
  };

  const handleTakeScreenshot = () => {
    downloadScreenshot();
  };

  const handleInfo = (e, item) => {
    let cropIndicator = 'What are the crop indicators?';
    let weatherIndicator = 'What are the Weather Indicators?';
    let combinedIndicators = 'Combined Indicators';
    e?.stopPropagation();
    if (item.id === 'crop-indicators') {
      window.open(
        `/user-guide-details?topicId=${RISK_ANALYTICS_MODULE.id}&&sub-topic-name=${cropIndicator}&topic_name=Risk Analytics`,
      );
    } else if (item.id === 'weather-indicators') {
      window.open(
        `/user-guide-details?topicId=${RISK_ANALYTICS_MODULE.id}&&sub-topic-name=${weatherIndicator}&topic_name=Risk Analytics`,
      );
    } else if (item.id === 'combined-indicators') {
      window.open(
        `/user-guide-details?topicId=${RISK_ANALYTICS_MODULE.id}&&sub-topic-name=${combinedIndicators}&topic_name=Risk Analytics`,
      );
    }
  };

  const clearGraphData = () => {
    setUpdateGraphValues({});
  };

  return (
    <>
      <div className="risk-analytics-graph-header-st">
        <div className="analytics-graph-text-st">
          <p className="mt-2 mx-2">Analytics</p>
        </div>
        <div className="analytics-graph-st">
          {openFullScreen === false && (
            <>
              <div>
                {/* Expand */}
                <a
                  data-for="expand1"
                  data-tip="Hello<br />multiline<br />tooltip"
                  data-iscapture="true"
                >
                  <img
                    src={ExpandIcon}
                    onClick={() => handleExpand(true, 'fullScreen')}
                    style={{ cursor: 'pointer', height: '24px' }}
                  />
                  <ReactTooltip
                    id="expand1"
                    aria-haspopup="true"
                    role="example"
                    type="info"
                    place={'bottom'}
                  >
                    Expand
                  </ReactTooltip>
                </a>

                {/* </button> */}
                {/* )} */}
              </div>
            </>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img
            src={EmailIcon}
            alt="crossIcon"
            width={25}
            height={27}
            onClick={() => handleOpenModal1(true)}
            style={{ cursor: 'pointer' }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img
            src={graphCrossIcon}
            onClick={(e) => handleClose()}
            alt="crossIcon"
            style={{ cursor: 'pointer', width: '35px' }}
            width={25}
          />
        </div>
      </div>
      <div>
        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="py-2 px-3 risk-indicator-st d-flex"
            style={isHideBar ? { marginLeft: '25px' } : {}}
          >
            {indicatorsCategoryList && indicatorsCategoryList.length
              ? indicatorsCategoryList?.map((item) => {
                  return (
                    <Col lg={2} className="d-flex flex-column risk-text-label-input mx-1">
                      <span>
                        {' '}
                        <Label for="indicators" style={{ marginLeft: '6px' }}>
                          {item?.label}
                        </Label>{' '}
                        <span className="info-style">
                          <i
                            className="cursor-ponter fa fa-info info-icon-edit-btn"
                            id="info-icon-tool-tip"
                            data-tip
                            data-for="info-icon-tool-tip"
                            style={{ float: 'right', margin: '0 10px 5px 0px' }}
                            onClick={(e) => handleInfo(e, item)}
                          />
                          <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                            Click here to know more.
                          </ReactTooltip>
                        </span>
                      </span>
                      <Select
                        id="multi-select"
                        name={item?.id}
                        placeholder="Select indicator"
                        options={renderAPIParameterOptions(item?.list)}
                        value={item?.selectedIndicators}
                        defaultValue={item?.selectedIndicators}
                        onChange={(val) => handleIndicators(val, item)}
                        isMulti={true}
                        required={true}
                        style={{ width: '100%', padding: '0px' }}
                        className="mb-3 indicator select-analytics-input-st"
                        maxMenuHeight={150}
                        isOptionDisabled={(options) => handleOptionDisable(options, item)}
                      />
                    </Col>
                  );
                })
              : ''}
            <Col lg={2} className="risk-text-label-input mx-1">
              <Label for="startDate" style={{ marginBottom: '3px', marginLeft: '6px' }}>
                Start Date
              </Label>
              <Input
                type="date"
                name="startDate"
                placeholder="Start date"
                id="startDate"
                bsSize="sm"
                className={
                  document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true'
                    ? // ? 'input-style invalid-filter-date analytics-input-st mt-0'
                      'input-style analytics-input-st mt-0'
                    : 'input-style analytics-input-st mb-3'
                }
                value={initialSelectedDate?.map?.startDate}
                onChange={(e) => handleOnChangeDates('startDate', e)}
                min={moment(initialSelectedDate?.map['minStartDate']).format(DATE_FORMAT)}
                max={moment(initialSelectedDate?.map['maxStartDate']).format(DATE_FORMAT)}
                invalid={
                  new Date(handleInitialDate('minStartDate')) >
                    new Date(handleInitialDate('startDate')) ||
                  new Date(handleInitialDate('maxStartDate')) <
                    new Date(handleInitialDate('startDate'))
                  // || handleInitialDate('startDate') === "Invalid date"
                }
                style={{ width: '100%', minHeight: '48px' }}
              />
            </Col>
            <Col lg={2} className="risk-text-label-input mx-1">
              <Label for="endDate" style={{ marginBottom: '3px', marginLeft: '6px' }}>
                End Date
              </Label>
              <Input
                type="date"
                name="endDate"
                placeholder="End date"
                id="endDate"
                bsSize="sm"
                className={
                  document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true'
                    ? // ? 'input-style invalid-filter-date analytics-input-st mt-0'
                      'input-style analytics-input-st mt-0'
                    : 'input-style analytics-input-st'
                }
                min={moment(initialSelectedDate?.map['startDate']).format(DATE_FORMAT)}
                max={moment(initialSelectedDate?.map['maxStartDate']).format(DATE_FORMAT)}
                value={initialSelectedDate?.map?.endDate}
                onChange={(e) => handleOnChangeDates('endDate', e)}
                invalid={
                  new Date(handleInitialDate('startDate')) >
                    new Date(handleInitialDate('endDate')) ||
                  new Date(handleInitialDate('maxEndDate')) <
                    new Date(handleInitialDate('endDate')) ||
                  // || handleInitialDate('endDate') === "Invalid date"
                  new Date(handleInitialDate('startDate')).getTime() ===
                    new Date(handleInitialDate('endDate')).getTime()
                  // || finalNoaaEndDate > finalNoaaMaxEndDate
                }
                style={{ width: '100%', minHeight: '48px' }}
              />
            </Col>
            <Col md={2} className="mt-3">
              <Button
                outline
                onClick={(e) => handleViewGraph(e)}
                className="risk-submit-st"
                style={{ width: '120px', marginLeft: '5px', marginTop: '5px' }}
                disabled={isGraphDisabled}
              >
                View Graph
              </Button>
            </Col>
          </Col>
          <Col lg={12} md={12} sm={12} className="risk-graph-col">
            {!_.isEmpty(updatedGraphValues?.graph_data) ? (
              <div className="risk-graph-st" ref={elementRef}>
                <RiskAnalyticsPlotChart
                  chartData={updatedGraphValues || {}}
                  selectedParam={
                    selectedIndicatorRiskParam?.length ? selectedIndicatorRiskParam : selectedParam
                  }
                  initialSelectedDate={initialSelectedDate?.map}
                  handleClose={handleClose}
                  handleTakeScreenshot={handleTakeScreenshot}
                  renderCharts={renderCharts}
                />
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default CurrentWatherForecast;
