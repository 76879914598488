import React, { useEffect, useState } from 'react';

import { Container, Row, Col, FormGroup, Label } from 'reactstrap';

// import RightArrow from '../../assets/Images/back-button.svg';
import RightArrow from '../../assets/Images/back-button.svg';

import { useHistory, useLocation } from 'react-router-dom';

import ImagePlaceHolder from '../../assets/Images/imagePlaceholder.png';
import Image1 from '../../assets/Images/camera.png';

import { useDispatch, useSelector } from 'react-redux';

import { getFieldDataRequest } from '../../redux/manageFieldData/manageFieldDataActions';

import Loader from '../../Components/common/loader';
import { BsArrowLeft } from 'react-icons/bs';
import _ from 'lodash';

const SurveyDetails = () => {
  // return <h1>Survey Details</h1>

  let history = useHistory();
  const paramLocation = useLocation();

  let dispatch = useDispatch();

  const nextProps = useSelector((state) => ({
    isFieldDataListDataRequesting: state.fieldDataList.isFieldDataListDataRequesting,
    fieldDataListData: state?.fieldDataList?.FieldDataListData,
    loginDetailData: state.Login.loginDetailData || null,
  }));

  const [newRequestList, setNewRequestList] = useState([
    // {
    //   'Farmer Name': 'test',
    //   'Farmer ID': '12345',
    //   'Phone No.': '9876543210',
    //   District: 'test',
    //   'DS Division': 'test',
    //   'GN Division': 'test',
    //   'Surveyor Name': 'test',
    //   'Status of Insurance': 'test',
    // },
  ]);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // dispatch(
    //   getFieldDataRequest({
    //     // url: 'https://docs.google.com/spreadsheets/d/1PEhNaXwDJBTSGeNI5k19FxCRZMZFb8aG1HtNzqFVxXg/edit?usp=sharing',
    //     url: 'https://docs.google.com/spreadsheets/d/1UYTpGCbvu7l1XkylHB4AuqKVPBOk2MAGqPxsd-a86i4/edit?usp=sharing',
    //   }),
    // );
    // dispatch(
    //   getFieldDataRequest({
    //     // url: 'https://docs.google.com/spreadsheets/d/14-7qtq5maCsIylAU6F7rDxnKC-6K9qsMCpiLV9pzmzU/edit?usp=sharing',
    //     url: 'https://docs.google.com/spreadsheets/d/14-7qtq5maCsIylAU6F7rDxnKC-6K9qsMCpiLV9pzmzU/edit?usp=sharing',
    //   }),
    // );
    // dispatch(
    //   getFieldDataRequest({
    //     // url: 'https://docs.google.com/spreadsheets/d/1--BRovsKmHKJpDseEhUY49qEl8RI_PfUfQDUioESg08/edit?usp=sharing',
    //     year: paramLocation?.state?.selectedDate,
    //     component_id: paramLocation?.state?.id,
    //   }),
    //   // history.push('/manageFieldData?UnderstandingTheInsuranceContext'),
    //   // history.push('/manageFieldData?UnderstandingTheInsuranceContext'),
    // );
  }, []);

  // useEffect(() => {
  //   if (nextProps.fieldDataListData) {
  //     if (nextProps?.fieldDataListData?.data?.result) {
  //       setNewRequestList(
  //         nextProps?.fieldDataListData?.data?.result?.['farm_odk_data ']?.filter(
  //           (item) => item?.['data-meta-instanceID'] === history.location?.pathname.split('/')[2],
  //         ),
  //       );
  //     } else {
  //       setNewRequestList([]);
  //     }
  //   } else {
  //     setNewRequestList([]);
  //   }
  // }, [nextProps.fieldDataListData]);

  useEffect(() => {
    if (!_.isEmpty(paramLocation?.state?.newRequestList) && paramLocation?.state?.uuid) {
      setNewRequestList(
        paramLocation?.state?.newRequestList.filter(
          (item) => item?.['data-meta-instanceID'] === paramLocation?.state?.uuid,
        ),
      );
    } else {
      setNewRequestList([]);
    }
  }, [paramLocation?.state?.newRequestList]);

  useEffect(() => {
    if (newRequestList) {
      setTableData(
        newRequestList?.map((item, key) => {
          if (item?.['data-meta-instanceID'] === history.location?.pathname.split('/')[2]) {
            return {
              name:
                item?.['data-Setting-farmer-ID-Name-of-the-farmer'] !== ' '
                  ? item?.['data-Setting-farmer-ID-Name-of-the-farmer']
                    ? item?.['data-Setting-farmer-ID-Name-of-the-farmer']
                    : 'N/A'
                  : 'N/A',
              // farmerId:item?.[""]
              phoneNo:
                item?.['data-sub_section-Telephone-No'] !== ' '
                  ? item?.['data-sub_section-Telephone-No']
                    ? item?.['data-sub_section-Telephone-No']
                    : 'N/A'
                  : 'N/A',
              district:
                item?.['data-Setting-farmer-ID-District'] !== ' ' &&
                item?.['data-Setting-farmer-ID-District']
                  ? item?.['data-Setting-farmer-ID-District']
                  : 'N/A',
              dsDivision:
                item?.['data-Setting-farmer-ID-DS-division'] !== ' '
                  ? item?.['data-Setting-farmer-ID-DS-division']
                    ? item?.['data-Setting-farmer-ID-DS-division']
                    : 'N/A'
                  : 'N/A',
              gnDivision:
                item?.['data-Setting-farmer-ID-GN-division'] !== ' '
                  ? item?.['data-Setting-farmer-ID-GN-division']
                    ? item?.['data-Setting-farmer-ID-GN-division']
                    : 'N/A'
                  : 'N/A',
              id:
                item?.['data-meta-instanceID'] !== ' '
                  ? item?.['data-meta-instanceID']
                    ? item?.['data-meta-instanceID']
                    : 'N/A'
                  : 'N/A',
              farmerId: item?.['data-Setting-farmer-ID-Farmer-ID'],
              gender: item?.['data-sub_section-Gender'],
              houseHoldGeoPoint: item?.['data-Location-Info-Location-of-household'],
              rankSeriousDiasters:
                item?.[
                  'data-Disaster-occurrences-and-risk-perception-rank-the-two-most-serious-disasters'
                ],
              farmFieldsEffectedFloods:
                item?.['data-How-frequent-are-your-farm-fields-affected-by-Floods'],
              farmFieldsEffectedDroughts:
                item?.['data-How-frequent-are-your-farm-fields-affected-by-Droughts'],
              majorCropsPrimilarlygrown:
                item?.[
                  'data-How-frequent-are-your-farm-fields-affected-by-major-crops-are-primarily-grown'
                ],
              abilityToProtectFromDisasters:
                item?.[
                  'data-How-frequent-are-your-farm-fields-affected-by-ability-to-protect-from-disasters'
                ],
              // abilityToProtectdisasters:item?.["data-How-frequent-are-your-farm-fields-affected-by-ability-to-protect-from-disasters"],
              vulnerabilityTodisasters:
                item?.[
                  'data-How-frequent-are-your-farm-fields-affected-by-judge-your-vulnerability-to-disasters'
                ],
              economicEffectOfdroughts:
                item?.['data-Drought-flood-effects-economic-effects-of-the-latest-drought'],
              mahaSeason:
                item?.[
                  'data-Drought-flood-effects-flood-affected-the-agricultural-income-of-Maha-season'
                ],
              yalaSeason:
                item?.[
                  'data-Drought-flood-effects-flood-affected-the-agricultural-income-of-Yala-season'
                ],
              droughtsEffectedotherIncomeSouces:
                item?.['data-Drought-flood-effects-flood-affected-other-income-sources'],
              startegesadoptedminimizedroughts:
                item?.[
                  'data-Coping-strategies-Strategies-adopted-in-the-past-to-minimize-the-drought'
                ],
              strategiesDamagedDrroughts:
                item?.['data-Coping-strategies-damages-caused-by-drought'],
              problemsEncounterDroughts:
                item?.['data-Coping-strategies-Problems-encountered-in-drought'],
              reportTheCropDamagecaused:
                item?.[
                  'data-Compensation-relief-and-Insurance-experience-To-whom-do-you-report-the-crop-damage-caused-by-drought'
                ],
              latestDamage:
                item?.[
                  'data-Compensation-relief-and-Insurance-experience-assesses-the-crop-damages-of-the-latest-drought'
                ],
              recieveAnyCompensation: item?.['data-Did-you-receive-any-compensation'],
              recievedCompensationAmount:
                item?.['data-compensation-information-If-yes-the-amount-of-compensation-received'],
              timeTakenToRecieveTheAmount:
                item?.[
                  'data-compensation-information-If-yes-the-time-taken-to-receive-the-compensation'
                ],
              compensatedTheDamage:
                item?.['data-compensation-information-Which-agency-compensated-the-damage'],
              roleFarmerOrganizationInDamage:
                item?.[
                  'data-compensation-payment-insurance-schemes-What-role-farmer-organization-in-damage-assessment'
                ],
              participateInsuranceCompany:
                item?.[
                  'data-compensation-payment-insurance-schemes-Have-you-participate-in-any-of-the-following-insurance'
                ],
              haveInsuranceCompany: item?.['data-D-you-like-to-have-agricultural-insurance'],
              awareOfInsuranceCompany:
                item?.[
                  'data-Are-you-aware-of-your-eligibility-for-crop-insurance-rolled-out-in-your-village'
                ],
              ifNoWhy: item?.['data-If-No-why'],
              awareOfEligibility:
                item?.[
                  'data-Are-you-aware-of-your-eligibility-for-crop-insurance-rolled-out-in-your-village'
                ],
              ifYesHowYouKnowAboutInsurance:
                item?.['data-If-yes-how-did-you-know-about-your-enrolment-for-crop-insurance'],
              adaquateKnowledgeAboutCropsPremiumInsurance:
                item?.[
                  'data-Do-you-have-adequate-knowledge-about-the-following-crop-insurance-scheme-details-Premium-amount'
                ],
              adaquateKnowledgeAboutCrops:
                item?.[
                  'data-Do-you-have-adequate-knowledge-about-the-following-crop-insurance-scheme-details-Coverage-of-crops'
                ],
              knowledgeAboutInsuranceSumInsured:
                item?.[
                  'data-Do-you-have-adequate-knowledge-about-the-following-crop-insurance-scheme-details-Sum-insured'
                ],
              triggeringPoints:
                item?.[
                  'data-Do-you-have-adequate-knowledge-about-the-following-crop-insurance-scheme-details-Trigger-point-for-payments'
                ],

              administratorPrivateRatherThanGovernment:
                item?.[
                  'data-Do-you-have-adequate-knowledge-about-the-following-crop-insurance-scheme-details-Would-you-continue-to-purchase-crop-insurance-if-it-were-administered-by-a-private-rather-than-government-organization'
                ],
              // weatherAdvisorOnTime: item?.['data-Did-you-receive-weather-advisories-on-time'],
              weatherAdvisorOnTime:
                item?.['data-weather-advisories-Did-you-receive-weather-advisories-on-time'],
              weatherAdvisorUsefull:
                item?.['data-weather-advisories-Are-weather-advisories-useful'],
              irrigation:
                item?.[
                  'data-weather-advisories-Did-you-change-irrigation-application-after-receiving-weather-advisories'
                ],

              recievingWeatherAdvisor:
                item?.[
                  'data-weather-advisories-Did-you-change-agronomic-and-or-crop-management-practices-after-receiving-weather-and-agronomic-advisories'
                ],
              receiveSeed:
                item?.[
                  'data-weather-advisories-Did-you-receive-seeds-bundled-with-insurance-for-cultivation-in-this-season'
                ],
              bundleSolution: item?.['data-Is-insurance-a-part-of-a-bundled-solution'],
              increasesbunddledsolution:
                item?.[
                  'data-weather-advisories-Did-crop-yield-increase-with-the-given-seed-variety-in-bundled-solution'
                ],

              photo1: item?.['data-title-Photo-1'],
              photo2: item?.['data-title-Photo-2'],
              //photo3: item?.["data-title-Photo-3"],
            };
          } else return;
        }),
      );
    }
  }, [newRequestList]);

  return (
    <Container fluid>
      {nextProps.isFieldDataListDataRequesting && <Loader />}

      <div className="right-img-wrapper api-key-list">
        <Row>
          {/* <Col>
                <h1>Survey Details</h1>
                </Col> */}
          <Col xs="12" sm="12" md="12" lg="12" className="subheader-manage-user">
            {/* <img
              src={RightArrow}
              alt="back-arrow"
              className="manage-user-header-back-button"
              onClick={() => history.push('/manageFieldData?UnderstandingTheInsuranceContext')}
            /> */}

            <span
              style={{
                float: 'left',
                marginLeft: '10px',
                borderRadius: '700px',
                border: '1px solid #ada6a6',
                height: '43px',
                width: '43px',
                fontSize: '25px',
                color: '#595252',
                fontWeight: 'bold',
                marginTop: '2.5%',
              }}
              className="list-tl-st"
            >
              {/* <img
                      src={RightArrow}
                      className="cursor-ponter"
                      onClick={() => history.goBack()}
                      alt='back-bottom'
                    /> */}
              <BsArrowLeft
                className="cursor-ponter"
                //onClick={() => history.goBack()}
                onClick={() => history.push('/manageFieldData?UnderstandingTheInsuranceContext&fromBack=true'+ '&date=' + paramLocation?.state?.selectedDate)}
                style={{ margin: 'auto' }}
              />
            </span>

            <h3 className="heading-manage-user manage-user-heading-mt">
              {/* {t('MANAGE_USER.MANAGE_USERS')} */}
              Understanding the Insurance Context
            </h3>
          </Col>

          <Col style={{ marginLeft: '4%', marginRight: '4%' }}>
            <form action="/action_page.php">
              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  General Information
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  <Col md={3} style={{ overflowWrap: 'break-word', fontWeight: 'bold' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0' }}>
                        Farmer name
                      </Label>
                      <div>
                        <span>
                          {/* Farmer name */}
                          {tableData[0]?.name !== ' ' ? (
                            tableData[0]?.name ? (
                              tableData[0]?.name
                            ) : (
                              'N/A'
                            )
                          ) : (
                            "N/A"
                          )}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    {/* </Col> */}
                    {/* <Col md={4} style={{ overflowWrap: 'break-word' }}> */}
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0' }}>
                        District
                      </Label>
                      <div>
                        <span>
                          {/* District */}
                          {tableData[0]?.district !== ' ' ? (
                            tableData[0]?.district ? (
                              tableData[0]?.district
                            ) : (
                              'N/A'
                            )
                          ) : (
                            "N/A"
                          )}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}

                    <br />
                  </Col>

                  <Col md={3} style={{ overflowWrap: 'break-word', fontWeight: 'bold' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0' }}>
                        DS Division
                      </Label>
                      <div>
                        <span>
                          {/* DS Division */}
                          {tableData[0]?.dsDivision !== ' ' ? (
                            tableData[0]?.dsDivision ? (
                              tableData[0]?.dsDivision
                            ) : (
                              'N/A'
                            )
                          ) : (
                            "N/A"
                          )}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}

                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0' }}>
                        Farmer ID
                      </Label>
                      <div>
                        <span>
                          {/* 0123456789 */}
                          {tableData[0]?.farmerId !== ' ' ? (
                            tableData[0]?.farmerId ? (
                              tableData[0]?.farmerId
                            ) : (
                              'N/A'
                            )
                          ) : (
                            "N/A"
                          )}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                  </Col>

                  <Col md={3} style={{ overflowWrap: 'break-word', fontWeight: 'bold' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0' }}>
                        GN Division
                      </Label>
                      <div>
                        <span>
                          {/* GN Division */}
                          {tableData[0]?.gnDivision !== ' ' ? (
                            tableData[0]?.gnDivision ? (
                              tableData[0]?.gnDivision
                            ) : (
                              'N/A'
                            )
                          ) : (
                            "N/A"
                          )}
                        </span>
                      </div>
                    </FormGroup>

                    <br />

                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0' }}>
                        Household geopoint
                      </Label>
                      <div>
                        <span>
                          {/* 0000000, 000000 */}
                          {tableData[0]?.houseHoldGeoPoint
                            ? tableData[0]?.houseHoldGeoPoint
                              ? parseFloat(tableData[0]?.houseHoldGeoPoint.split(',')[0]).toFixed(
                                  2,
                                ) +
                                ',' +
                                parseFloat(tableData[0]?.houseHoldGeoPoint.split(',')[1]).toFixed(2)
                              : ''
                            : <br/>}
                        </span>
                      </div>
                    </FormGroup>

                    {/* </Col> */}
                  </Col>

                  <Col xs={3}>
                    <div style={{ marginLeft: '15px' }}>
                    {/* <img src={Image1} />
                      &nbsp;
                      {tableData[0]?.photo3 ? (
                        <a
                          href={tableData[0]?.photo3 ? tableData[0]?.photo3 : ''}
                          target="_blank"
                          style={{ color: '#363636' }}
                        >
                          Homestead
                        </a>
                      ) : (
                        'Homestead'
                      )} */}
                      {/* <br /> */}
                      <img src={Image1} />
                      &nbsp;
                      {tableData[0]?.photo1  != ' ' ? (
                        <a
                          href={tableData[0]?.photo1 ? tableData[0]?.photo1 : ''}
                          target="_blank"
                          style={{ color: '#363636' }}
                        >
                          Homestead/farm
                        </a>
                      ) : (
                        'Homestead/farm'
                      )}
                      <br />
                      <img src={Image1} />
                      {/* &nbsp; Homestead/farm */}
                      &nbsp;
                      {tableData[0]?.photo2  != ' ' ? (
                        <a
                          href={tableData[0]?.photo2 ? tableData[0]?.photo2 : ''}
                          target="_blank"
                          style={{ color: '#363636' }}
                        >
                          Homestead/field
                        </a>
                      ) : (
                        'Homestead/field'
                      )}
                      <br />
                      {/* <img src={Image1} />
                      &nbsp; Homestead/field */}
                    </div>
                  </Col>
                </Row>
              </fieldset>

              <br />

              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  Disaster occurrences and risk perception
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  <Col md={6} style={{ overflowWrap: 'break-word', fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Please rank the two most-serious disasters in terms of damage in your area
                        during <br /> the last 5 years
                      </p>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Frequency your farm fields affected by flood
                      </p>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Frequency your farm fields affected by Drought
                      </p>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Major crops that are primarily grown in your farm fields
                      </p>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        {/* Major crops that are primarily grown in your farm fields */}
                        Ability to protect from disasters or epidemics that are causing damage to
                        agricultural production
                      </p>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        {/* Major crops that are primarily grown in your farm fields */}
                        {/* Ability to protect from disasters or epidemics that are causing damage to agricultural production */}
                        How do you judge your vulnerability to disasters/epidemics compared with
                        other households
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ overflowWrap: 'break-word', fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ marginLeft: '25px' }}>
                        {/* Already experienced disasters */}
                        {tableData[0]?.rankSeriousDiasters !== ' ' ? (
                          tableData[0]?.rankSeriousDiasters ? (
                            tableData[0]?.rankSeriousDiasters
                          ) : (
                            'N/A'
                          )
                        ) : (
                          "N/A"
                        )}

                        {/* {tableData[0]?.farmFieldsEffectedFloods?tableData[0]?.rankSeriousDiasters:""} */}
                      </p>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <p style={{ marginLeft: '25px', marginTop: '15px' }}>
                        {/* Every year */}
                        {tableData[0]?.farmFieldsEffectedFloods !== ' ' ? (
                          tableData[0]?.farmFieldsEffectedFloods ? (
                            tableData[0]?.farmFieldsEffectedFloods.split('-').join(' ')
                          ) : (
                            'N/A'
                          )
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <p style={{ marginLeft: '25px' }}>
                        {/* Once in three years */}
                        {tableData[0]?.farmFieldsEffectedDroughts !== ' ' ? (
                          tableData[0]?.farmFieldsEffectedDroughts ? (
                            tableData[0]?.farmFieldsEffectedDroughts.split('-').join(' ')
                          ) : (
                            'N/A'
                          )
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <p style={{ marginLeft: '25px' }}>
                        {/* Paddy */}
                        {tableData[0]?.majorCropsPrimilarlygrown !== ' ' ? (
                          tableData[0]?.majorCropsPrimilarlygrown ? (
                            tableData[0]?.majorCropsPrimilarlygrown
                          ) : (
                            'N/A'
                          )
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <p style={{ marginLeft: '25px', marginTop: '10px' }}>
                        {tableData[0]?.abilityToProtectFromDisasters !== ' ' ? (
                          tableData[0]?.abilityToProtectFromDisasters ? (
                            tableData[0]?.abilityToProtectFromDisasters.split('-').join(' ')
                          ) : (
                            'N/A'
                          )
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <p style={{ marginLeft: '25px', marginTop: '25px' }}>
                        {tableData[0]?.vulnerabilityTodisasters !== ' ' ? (
                          tableData[0]?.vulnerabilityTodisasters ? (
                            tableData[0]?.vulnerabilityTodisasters.split('-').join(' ')
                          ) : (
                            'N/A'
                          )
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>
              </fieldset>

              <br />

              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  Drought/ flood effects
                </legend>
                <Row>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        The crop/economic effects of the latest drought/flood experienced
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {' '}
                      {tableData[0]?.economicEffectOfdroughts !== ' ' ? (
                        tableData[0]?.economicEffectOfdroughts ? (
                          /\d/.test(tableData[0]?.economicEffectOfdroughts.split('-').join(' ')) ? tableData[0]?.economicEffectOfdroughts.split('-').join(' ') + " %" : tableData[0]?.economicEffectOfdroughts.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    {/* <div style={{ display: 'flex' }}> */}
                    <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                      Percentage the agricultural income of Maha season affected by the latest
                      drought/ <br /> flood{' '}
                    </p>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Reduced 25-50% */}
                      {tableData[0]?.mahaSeason !== ' ' ? (
                        tableData[0]?.mahaSeason ? (
                          /\d/.test(tableData[0]?.mahaSeason.split('-').join(' '))? tableData[0]?.mahaSeason.split('-').join(' ') + ' %' : tableData[0]?.mahaSeason.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                    {/* <div style={{ display: 'flex' }}> */}
                    <p style={{ marginLeft: '17px' }}>
                      Percentage the agricultural income of Yala season affected by the latest
                      drought/ <br /> flood{' '}
                    </p>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Reduced 25-50% */}
                      {tableData[0]?.yalaSeason !== ' ' ? (
                        tableData[0]?.yalaSeason ? (
                          /\d/.test(tableData[0]?.yalaSeason.split('-').join(' '))? tableData[0]?.yalaSeason.split('-').join(' ') + ' %' : tableData[0]?.yalaSeason.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Has the drought/flood affected other income sources (secondary income other
                        than <br /> agriculture)?
                      </p>
                      {/* <p style={{ marginLeft: '105px' }}>Yes, reduce 30-50%</p> */}
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Yes, reduce 30-50% */}
                      {tableData[0]?.droughtsEffectedotherIncomeSouces !== ' ' ? (
                        tableData[0]?.droughtsEffectedotherIncomeSouces ? (
                          /\d/.test(tableData[0]?.droughtsEffectedotherIncomeSouces.split('-').join(' '))? tableData[0]?.droughtsEffectedotherIncomeSouces.split('-').join(' ') + ' %' : tableData[0]?.droughtsEffectedotherIncomeSouces.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>
                  {/* </Col> */}
                </Row>
              </fieldset>

              <br />

              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  Croping strategies
                </legend>
                <Row>
                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Strategies adopted in the past to minimize the drought/flood damages in crop{' '}
                        <br />
                        cultivation
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {' '}
                      {tableData[0]?.startegesadoptedminimizedroughts !== ' ' ? (
                        tableData[0]?.startegesadoptedminimizedroughts ? (
                          tableData[0]?.startegesadoptedminimizedroughts.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}{' '}
                    </p>
                  </Col>
                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        The problems encountered in drought/flood management in the past
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {' '}
                      {tableData[0]?.strategiesDamagedDrroughts !== ' ' ? (
                        tableData[0]?.strategiesDamagedDrroughts ? (
                          tableData[0]?.strategiesDamagedDrroughts.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}{' '}
                    </p>
                  </Col>
                </Row>
              </fieldset>

              <br />

              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  Compensation/drought relief and Insurance experience
                </legend>
                <Row>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        The person you report the crop damage caused by drought/flood in the recent
                        past
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '17px', marginLeft: '25px' }}>
                      {tableData[0]?.reportTheCropDamagecaused !== ' ' ? (
                        tableData[0]?.reportTheCropDamagecaused ? (
                          tableData[0]?.reportTheCropDamagecaused.split('-').join('/')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        The person who assesses the crop damages of the latest drought/flood
                      </p>
                    </div>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {tableData[0]?.latestDamage !== ' ' ? (
                        tableData[0]?.latestDamage ? (
                          tableData[0]?.latestDamage.split('-').join('/')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Any compensation or relief you received for the crop damages for the latest
                        drought/ <br /> flood
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Yes */}
                      {tableData[0]?.recieveAnyCompensation !== ' ' ? (
                        tableData[0]?.recieveAnyCompensation ? (
                          tableData[0]?.recieveAnyCompensation
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        If yes, mention the amount of compensation received
                      </p>
                    </div>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* &#62;  */}
                      {/* 80% of the claim */}
                      {tableData[0]?.recievedCompensationAmount !== ' ' ? (
                        tableData[0]?.recievedCompensationAmount ? (
                          tableData[0]?.recievedCompensationAmount.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        If yes, mention the time taken to receive the compensation/relief after the
                        assessment
                      </p>
                    </div>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {tableData[0]?.timeTakenToRecieveTheAmount !== ' ' ? (
                        tableData[0]?.timeTakenToRecieveTheAmount ? (
                          tableData[0]?.timeTakenToRecieveTheAmount.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Role farmer organization in damage assessment and compensation payment
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {tableData[0]?.roleFarmerOrganizationInDamage !== ' ' ? (
                        tableData[0]?.roleFarmerOrganizationInDamage ? (
                          tableData[0]?.roleFarmerOrganizationInDamage.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Mention any of the following insurance schemes that you participated now
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {' '}
                      {tableData[0]?.participateInsuranceCompany !== ' ' ? (
                        tableData[0]?.participateInsuranceCompany ? (
                          tableData[0]?.participateInsuranceCompany.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Interested to have agricultural insurance to transfer the drought risk of
                        your crops/ <br /> livestock
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* No */}
                      {tableData[0]?.haveInsuranceCompany !== ' ' ? (
                        tableData[0]?.haveInsuranceCompany ? (
                          tableData[0]?.haveInsuranceCompany
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        If yes, mention how you know about your enrollment for crop insurance
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Insurance agent */}
                      {tableData[0]?.ifYesHowYouKnowAboutInsurance !== ' ' ? (
                        tableData[0]?.ifYesHowYouKnowAboutInsurance ? (
                          tableData[0]?.ifYesHowYouKnowAboutInsurance.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Have adequate knowledge about crop coverage
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Not aware */}
                      {tableData[0]?.adaquateKnowledgeAboutCrops !== ' ' ? (
                        tableData[0]?.adaquateKnowledgeAboutCrops ? (
                          tableData[0]?.adaquateKnowledgeAboutCrops.split('-').join(' ')
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Have adequate knowledge about premium amount
                      </p>
                    </div>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Somewhat aware */}
                      {tableData[0]?.adaquateKnowledgeAboutCropsPremiumInsurance !== ' ' ? (
                        tableData[0]?.adaquateKnowledgeAboutCropsPremiumInsurance ? (
                          tableData[0]?.adaquateKnowledgeAboutCropsPremiumInsurance
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Have adequate knowledge about trigger points for payments
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Somewhat aware */}
                      {tableData[0]?.triggeringPoints !== ' ' ? (
                        tableData[0]?.triggeringPoints ? (
                          tableData[0]?.triggeringPoints
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Have adequate knowledge about sum insured
                      </p>
                      {/* <p style={{ marginLeft: '359px' }}>Fully aware</p> */}
                    </div>
                  </Col>

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Fully aware */}
                      {tableData[0]?.knowledgeAboutInsuranceSumInsured !== ' ' ? (
                        tableData[0]?.knowledgeAboutInsuranceSumInsured ? (
                          tableData[0]?.knowledgeAboutInsuranceSumInsured
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Interested to continue/begin to purchase crop insurance if it were
                        administered by a <br /> private rather than government, organization
                      </p>
                      {/* <p style={{ marginLeft: '97px' }}>Yes</p> */}
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Yes */}
                      {tableData[0]?.administratorPrivateRatherThanGovernment !== ' ' ? (
                        tableData[0]?.administratorPrivateRatherThanGovernment ? (
                          tableData[0]?.administratorPrivateRatherThanGovernment
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>
                        Mention if insurance a part of a bundled solution, including seeds, weather,
                        and <br /> agronomic advisories etc.
                      </p>
                      {/* <p style={{ marginLeft: '129px' }}>Yes</p> */}
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Yes */}
                      {tableData[0]?.bundleSolution !== ' ' ? (
                        tableData[0]?.bundleSolution ? (
                          tableData[0]?.bundleSolution
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={12} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', marginLeft: '17px' }}>If yes,</p>
                      {/* <p style={{ marginLeft: '150px' }}>
                      Yes
                      </p> */}
                    </div>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        1. Mention if you receive weather/agronomic advisories on time
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Yes */}
                      {tableData[0]?.weatherAdvisorOnTime !== ' ' ? (
                        tableData[0]?.weatherAdvisorOnTime ? (
                          tableData[0]?.weatherAdvisorOnTime
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        2. Mention if weather advisories useful
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Very useful */}
                      {tableData[0]?.weatherAdvisorUsefull !== ' ' ? (
                        tableData[0]?.weatherAdvisorUsefull ? (
                          tableData[0]?.weatherAdvisorUsefull
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        3. Mention if you change irrigation application after receiving weather
                        advisories
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Yes */}
                      {tableData[0]?.irrigation !== ' ' ? (
                        tableData[0]?.irrigation ? (
                          tableData[0]?.irrigation
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        4. Mention if you change agronomic and or crop management practices after{' '}
                        <br /> weather and agronomic advisories
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Yes */}
                      {tableData[0]?.recievingWeatherAdvisor !== ' ' ? (
                        tableData[0]?.recievingWeatherAdvisor ? (
                          tableData[0]?.recievingWeatherAdvisor
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        5. Mention if you receive seeds bundled with insurance for cultivation in
                        this season
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* Yes */}
                      {tableData[0]?.receiveSeed !== ' ' ? (
                        tableData[0]?.receiveSeed ? (
                          tableData[0]?.receiveSeed
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>

                  <br />

                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        6. Crop yield increase with the given seed variety in bundled solution
                      </p>
                    </div>
                  </Col>
                  <Col md={6} style={{ fontWeight: 'bold' }}>
                    <p style={{ marginLeft: '25px' }}>
                      {/* No */}
                      {tableData[0]?.increasesbunddledsolution !== ' ' ? (
                        tableData[0]?.increasesbunddledsolution ? (
                          tableData[0]?.increasesbunddledsolution
                        ) : (
                          'N/A'
                        )
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </Col>
                  {/* </Col> */}
                </Row>
              </fieldset>
            </form>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default SurveyDetails;
