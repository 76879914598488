import React from 'react';
import Footer from './footer';

const PrivacyPolicyPage = () => {
  return (
    <>
      <div className="right-img-wrapper right-img-wrapper-terms">
        <h2>
          <b>Privacy policy</b>
        </h2>
        <p className="terms-conditions-body">
          Pursuant to the Data Privacy Act of 2012, IWMI guarantees your personal information will
          only be accessed by authorized personnel and managed securely under appropriate online
          security measures to protect your personal information against loss or destruction. IWMI
          further guarantees that your personal information will not be disclosed to unauthorized
          third parties. IWMI may share your personal information to authorized third parties, but
          only for the circumstance below: To send cross-promotions on behalf of their affiliates
          and associates. IWMI may, from time to time, contact you on behalf of its affiliates about
          particular offers and/or services that may be of interest to you. In these cases, your
          unique personal information is not transferred to the affiliate.
        </p>
      </div>
      <div className="terms-conditions-footer">
        <Footer publicFooter={false} />
      </div>
    </>
  );
};
export default PrivacyPolicyPage;
