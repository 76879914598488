import {
  ACCESS_KEY_CONST,
  GET_ALL_BY_TYPE_CONST,
  GET_BY_ID_CONST,
  GET_PARAMETERS_TYPE_CONST,
  CHANGE_STATUS_CONST,
  DELETE_CONST,
  GET_INDICES_CONST,
  GET_GLDAS_CONST
} from './accessKeyConsts';

const ACCESS_KEY = (state = {}, action) => {
  switch (action.type) {
    case ACCESS_KEY_CONST.GET_ACCESS_KEY_REQUEST:
      return {
        ...state,
        accessKeyRequesting: true,
        accessKeyData: false,
        accessKeyDataError: false,
        accessKeyDataNetworkError: false,
      };
    case ACCESS_KEY_CONST.GET_ACCESS_KEY_SUCCESS:
      return {
        ...state,
        accessKeyRequesting: false,
        accessKeyData: action.payload.response,
        accessKeyDataError: false,
        accessKeyDataNetworkError: false,
      };
    case ACCESS_KEY_CONST.GET_ACCESS_KEY_FAILED:
      return {
        ...state,
        accessKeyRequesting: false,
        accessKeyData: false,
        accessKeyDataError: action.payload ? action.payload.response : false,
        accessKeyDataNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
      };

    case GET_ALL_BY_TYPE_CONST.GET_ALL_BY_TYPE_REQUEST:
      return {
        ...state,
        getAllByTypeRequesting: true,
        getAllByTypeData: false,
        getAllByTypeError: false,
        getAllByTypeNetworkError: false,
      };
    case GET_ALL_BY_TYPE_CONST.GET_ALL_BY_TYPE_SUCCESS:
      return {
        ...state,
        getAllByTypeRequesting: false,
        getAllByTypeData: action.payload.response,
        getAllByTypeError: false,
        getAllByTypeNetworkError: false,
      };
    case GET_ALL_BY_TYPE_CONST.GET_ALL_BY_TYPE_FAILED:
      return {
        ...state,
        getAllByTypeRequesting: false,
        getAllByTypeData: false,
        getAllByTypeError: action.payload ? action.payload.response : false,
        getAllByTypeNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
      };

    case GET_BY_ID_CONST.GET_BY_ID_REQUEST:
      return {
        ...state,
        getByIdRequesting: true,
        getByIdData: false,
        getByIdError: false,
        getByIdNetworkError: false,
      };
    case GET_BY_ID_CONST.GET_BY_ID_SUCCESS:
      return {
        ...state,
        getByIdRequesting: false,
        getByIdData: action.payload.response,
        getByIdError: false,
        getByIdNetworkError: false,
      };
    case GET_BY_ID_CONST.GET_BY_ID_FAILED:
      return {
        ...state,
        getByIdRequesting: false,
        getByIdData: false,
        getByIdError: action.payload ? action.payload.response : false,
        getByIdNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
      };

    case GET_PARAMETERS_TYPE_CONST.GET_PARAMETERS_TYPE_REQUEST:
      return {
        ...state,
        getParametersRequesting: true,
        getParametersData: false,
        getParametersError: false,
        getParameterNetworkError: false,
      };
    case GET_PARAMETERS_TYPE_CONST.GET_PARAMETERS_TYPE_SUCCESS:
      return {
        ...state,
        getParametersRequesting: false,
        getParametersData: action.payload.response,
        getParametersError: false,
        getParameterNetworkError: false,
      };
    case GET_PARAMETERS_TYPE_CONST.GET_PARAMETERS_TYPE_FAILED:
      return {
        ...state,
        getParametersRequesting: false,
        getParametersData: false,
        getParametersError: action.payload ? action.payload.response : false,
        getParameterNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
      };

    case CHANGE_STATUS_CONST.CHANGE_STATUS_CONST_REQUEST:
      return {
        ...state,
        changeStatusRequesting: true,
        changeStatusData: false,
        changeStatusError: false,
        changeStatusNetworkError: false,
      };
    case CHANGE_STATUS_CONST.CHANGE_STATUS_CONST_SUCCESS:
      return {
        ...state,
        changeStatusRequesting: false,
        changeStatusData: action.payload.response,
        changeStatusError: false,
        changeStatusNetworkError: false,
      };
    case CHANGE_STATUS_CONST.CHANGE_STATUS_CONST_FAILED:
      return {
        ...state,
        changeStatusRequesting: false,
        changeStatusData: false,
        changeStatusError: action.payload ? action.payload.response : false,
        changeStatusNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
      };

    case DELETE_CONST.DELETE_CONST_REQUEST:
      return {
        ...state,
        deleteRequesting: true,
        deleteData: false,
        deleteError: false,
        deleteNetworkError: false,
      };
    case DELETE_CONST.DELETE_CONST_SUCCESS:
      return {
        ...state,
        deleteRequesting: false,
        deleteData: action.payload.response,
        deleteError: false,
        deleteNetworkError: false,
      };
    case DELETE_CONST.DELETE_CONST_FAILED:
      return {
        ...state,
        deleteRequesting: false,
        deleteData: false,
        deleteError: action.payload ? action.payload.response : false,
        deleteNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
      };
    case GET_INDICES_CONST.GET_INDICES_REQUEST:
      return {
        ...state,
       isGetIndicesRequesting: true,
       getIndicesData: false,
       getIndicesDataError: false,
       getIndicesDataNetworkError: false,
      };
    case GET_INDICES_CONST.GET_INDICES_SUCCESS:
      return {
        ...state,
        isGetIndicesRequesting: false,
        getIndicesData: action.payload.response,
        getIndicesDataError: false,
        getIndicesDataNetworkError: false,
      };
    case GET_INDICES_CONST.GET_INDICES_FAILED:
      return {
        ...state,
        isGetIndicesRequesting: false,
        getIndicesData: false,
        getIndicesDataError: action.payload ? action.payload.response : false,
        getIndicesDataNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
      };


      case GET_GLDAS_CONST.GET_GLDAS_REQUEST:
      return {
        ...state,
       isGetGLDASRequesting: true,
       getGLDASData: false,
       getGLDASDataError: false,
       getGLDASDataNetworkError: false,
      };
    case GET_GLDAS_CONST.GET_GLDAS_SUCCESS:
      return {
        ...state,
        isGetGLDASRequesting: false,
        getGLDASData: action.payload.response,
        getGLDASDataError: false,
        getGLDASDataNetworkError: false,
      };
    case GET_GLDAS_CONST.GET_GLDAS_FAILED:
      return {
        ...state,
        isGetGLDASRequesting: false,
        getGLDASData: false,
        getGLDASDataError: action.payload ? action.payload.response : false,
        getGLDASDataNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
      };


    default:
      return state;
  }
};

export default ACCESS_KEY;
