import React, { useState, useEffect } from 'react';
import { Collapse, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { RiArrowDropUpLine, RiArrowDropDownLine } from 'react-icons/ri';
import PropTypes from 'prop-types';
import _ from 'lodash';
import '../../assets/CSS/LeafletWrapper.css';
import { t } from 'react-multi-lang';
import Loader from '../../Components/common/loader';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  getMapPreferenceBoundariesFailed,
  getMapPreferenceBoundariesRequest,
  getMapPreferenceBoundariesSuccess,
} from '../../redux/actions';
import { showError } from '../../Components/Notifications';
import {
  DEFAULT_COUNTRY,
  TALUKAS,
  MAP_PREFERENCES_lABELS,
  SELECT,
  LOCATION_ZOOM_LEVEL_THRESHOLD_CONSTS,
} from '../../Components/constants';
import CommonToolTip from '../../Components/common/CommonToolTip';

require('dotenv').config();

function MapPreferences(props) {
  const {
    isToggle,
    handleToggle,
    loc,
    getMapPreferencesData,
    geoJsonLayersData,
    geoJsonData,
    mapPreferencesAllData,
    getMapPreferencesGeojsonData,
    isMapPerferGeoJsonLoading,
    cropHealthMonitorStateData,
    selectedParamLegendData,
    fromCropHealthMonitor,
    cordinateAndZoom,
    staticData,
    districtData,
    cropWiseProRenderState,
    activeTab,
    loading,
    setLoading,
    modal,
    setModal,
    selectedDistrict,
  } = props;

  const dispatch = useDispatch();
  // const [modal, setModal] = useState('');
  const [handleCateogry, setHandleCategory] = useState([]);
  const [defaultBoundariesData, setDefaultBoundariesData] = useState([]);
  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [geoJsonLayerData, setGeoJsonLayerData] = useState([]);
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [categoryModal, setCategoryModal] = useState([]);
  const [selectedMapPreferences, setSelectedMapPreferences] = useState([]);
  const [legendList, setLegendList] = useState([]);
  const [talukasBoundaryData, setTalukasBoundaryData] = useState();
  const [talukasDisable, setTalukasDisable] = useState(true);

  // const [geoJsonLayers,setGeoJsonLayers] = useState([]);
  const { boundries } = useSelector((state) => ({
    boundries: state.Map_Preference,
  }));
  useEffect(() => {
    setModal(isToggle);
  }, [isToggle]);
  const {
    isMapPreferenceBoundariesRequesting,
    mapPreferenceBoundariesData,
    mapPreferenceBoundariesDataError,
  } = boundries;

  useEffect(() => {
    //  handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.STATES, {
    //       checked: true,
    //       fromMapPreferences: false,
    //     });
    setMapPreferencesData([]);
    getMapPreferencesData([]);
    // setGeoJsonLayers(geoJsonLayers)
    return () => {
      dispatch(getMapPreferenceBoundariesFailed(''));
    };
  }, []);

  useEffect(() => {
    setSelectedMapPreferences([]);
  }, [activeTab]);

  // useEffect(() => {
  //   if (
  //     selectedMapPreferences?.length === 0
  //   ) {
  //     map?.removeControl(opacityControl);
  //   }
  // }, [activeTabSwitch]);

  useEffect(() => {
    if (boundries?.mapPreferenceData) {
      let data = boundries?.mapPreferenceData?.data?.result;
      let categoryList = [];
      let updatedCategoryList = [];
      if (data?.length) {
        data?.map((item) => {
          categoryList.push({ name: item.name, checked: true });
        });
      }
      setHandleCategory(categoryList);
      if (data) {
        updatedCategoryList = data?.map((item) => {
          if (item && item.hasOwnProperty('isShowCategory')) {
            return item;
          } else {
            item.isShowCategory = true;
            return item;
          }
        });
      }
      if (updatedCategoryList?.length) {
        setGetBoundariesData(updatedCategoryList);
        setDefaultBoundariesData(updatedCategoryList);
      }
    }
  }, [boundries?.mapPreferenceData, activeTab]);

  useEffect(() => {
    if (cropHealthMonitorStateData?.id === SELECT) {
      if (talukasBoundaryData?.e?.target?.checked) {
        let event = { target: { checked: false } };
        handleCheckbox(event, talukasBoundaryData.boundry, { fromMapPreferences: true });
      }
      setTalukasDisable(true);
    } else {
      setTalukasDisable(false);
    }
  }, [cropHealthMonitorStateData]);

  useEffect(() => {
    if (cordinateAndZoom?.zoom) {
      let zoomLevelThreshold = LOCATION_ZOOM_LEVEL_THRESHOLD_CONSTS?.find(
        (item) => item.location_type === MAP_PREFERENCES_lABELS.DISTRICTS,
      )?.zoom_threshold;
      if (
        zoomLevelThreshold?.length &&
        (zoomLevelThreshold?.includes(cordinateAndZoom?.zoom) ||
          cordinateAndZoom?.zoom > zoomLevelThreshold[zoomLevelThreshold?.length - 1])
      ) {
        setTalukasDisable(false);
      } else if (
        districtData?.name === 'select' ||
        _.isEmpty(districtData) ||
        cropHealthMonitorStateData?.name === 'select' ||
        _.isEmpty(cropHealthMonitorStateData)
      ) {
        setTalukasDisable(true);
      } else {
        setTalukasDisable(false);
      }
    }
  }, [cordinateAndZoom, districtData, cropHealthMonitorStateData]);

  let mapPreferences = mapPreferencesData;
  useEffect(() => {
    if (boundries.isMapPreferenceBoundariesRequesting) {
      setLoading(true);
    }
    if (boundries.mapPreferenceBoundariesData) {
      setLoading(false);
      const { result } = boundries.mapPreferenceBoundariesData.data;
      const { boundaryId, country, category, name, slug, legendName } =
        boundries.mapPreferenceBoundariesData.payload;
      if (result.code === 200) {
        mapPreferences.push({
          result,
          id: boundaryId,
          category: category,
          dataSource: name,
          parameter: category,
          slug: slug,
          legendName: legendName,
        });
        setMapPreferencesData(mapPreferences);
        getMapPreferencesData(mapPreferences);
      } else if (result.code === 400) {
        showError(result?.message);
      }
    } else if (boundries.mapPreferenceBoundariesDataError) {
      setLoading(false);
      if (
        boundries.mapPreferenceBoundariesDataError?.data &&
        boundries.mapPreferenceBoundariesDataError?.data?.response
      ) {
        const { data } = boundries.mapPreferenceBoundariesDataError?.data?.response;
        if (data?.exception) {
          showError(data?.exception);
        }
      }
    }
  }, [
    isMapPreferenceBoundariesRequesting,
    mapPreferenceBoundariesData,
    mapPreferenceBoundariesDataError,
  ]);

  useEffect(() => {
    setGeoJsonLayerData(geoJsonData);
  }, [geoJsonData]);

  useEffect(() => {
    setMapPreferencesData(mapPreferencesAllData);
  }, [mapPreferencesAllData]);

  useEffect(() => {
    let categoryList = JSON.parse(JSON.stringify(handleCateogry));
    function defaultCheckList(layerData) {
      layerData?.map((item) => {
        let index = categoryList?.findIndex((x) => x.name === item.category && !x.checked);
        if (index >= 0) {
          categoryList[index] = {
            ...categoryList[index],
            checked: true,
          };
        }
      });
    }
    if (geoJsonLayerData?.length) {
      defaultCheckList(geoJsonLayerData);
      let categoryListData = [];
      geoJsonLayerData?.map((item) => {
        categoryListData.push(item?.category);
      });
      setCategoryModal(categoryList);
    }
    if (mapPreferencesData?.length) {
      defaultCheckList(mapPreferencesData);
    }
    if (categoryList?.length) {
      setHandleCategory(categoryList);
    }
  }, [geoJsonLayerData, mapPreferencesData]);

  const handleCateogryToggle = (item) => {
    let categoryList = [...handleCateogry];
    const index = _.findIndex(categoryList, (list) => list.name === item.name);
    if (index >= 0) {
      categoryList[index] = {
        ...categoryList[index],
        checked: !categoryList[index].checked,
      };
    }
    setHandleCategory(categoryList);
  };

  const toggle = () => {
    setModal(!modal);
    handleToggle(!modal);
  };

  let geoJsonLayers = geoJsonLayerData;
  const addGeoJsonLayer = (boundary) => {
    geoJsonLayers.push({
      name: boundary?.name,
      url: boundary?.json_url,
      color: boundary?.color_code,
      id: boundary?.id,
      category: boundary?.name,
      country_id: DEFAULT_COUNTRY.id,
      fromMapPreferences: boundary?.fromMapPreferences,
      legend_name: boundary?.legend_name,
    });
    getMapPreferencesGeojsonData(geoJsonLayers);
    setGeoJsonLayerData(geoJsonLayers);
  };

  const removeGeoJsonLayer = (firstItem, boundary) => {
    let tempLayer = geoJsonLayers.filter((layer) => layer.id !== firstItem?.id);
    geoJsonLayers = tempLayer;
    getMapPreferencesGeojsonData(geoJsonLayers);
    setGeoJsonLayerData(geoJsonLayers);
  };

  const addMapPreferencesLayer = (firstItem, boundary) => {
    dispatch(
      getMapPreferenceBoundariesRequest(
        firstItem?.id,
        'Sri Lanka',
        firstItem?.map_preference_category?.name,
        firstItem?.name,
        firstItem?.slug,
        firstItem?.legend_name,
      ),
    );
  };

  let mapRemove = [];
  const removeMapPreferencesLayer = (firstItem) => {
    mapPreferences = mapPreferences.filter((layer) => layer.id !== firstItem?.id);
    getMapPreferencesData(mapPreferences);
    setMapPreferencesData(mapPreferences);
    mapRemove.push(firstItem?.id);
    setLegendList(mapRemove);
  };

  let selectedMapArr = selectedMapPreferences;
  const addMapLayer = (firstItem, boundary) => {
    if (selectedMapPreferences?.length) {
      let selectedData = selectedMapPreferences?.find((x) => x.id === firstItem?.id);
      if (_.isEmpty(selectedData)) {
        setSelectedMapPreferences((prev) => [...prev, firstItem]);
        addMapPreferencesLayer(firstItem, boundary);
      }
    } else {
      setSelectedMapPreferences((prev) => [...prev, firstItem]);
      addMapPreferencesLayer(firstItem, boundary);
    }
  };

  const removeMapLayer = (firstItem, boundary) => {
    const filteredMapArr = selectedMapArr?.filter((item) => item.id !== firstItem?.id);
    // if (filteredMapArr?.length) {
    setSelectedMapPreferences(filteredMapArr);
    // }
    removeMapPreferencesLayer(firstItem);
  };

  const handleCheckbox = (e, boundry, { fromMapPreferences }) => {
    if (boundry?.slug == TALUKAS) {
      setTalukasBoundaryData({ e, boundry });
    }
    let data = JSON.parse(JSON.stringify(boundry));
    data.fromMapPreferences = fromMapPreferences;
    if (e.target.checked) {
      !_.isEmpty(boundry?.json_url) ? addGeoJsonLayer(data) : addMapLayer(data);
    } else {
      !_.isEmpty(boundry?.json_url) ? removeGeoJsonLayer(data) : removeMapLayer(data);
    }
  };

  // const handleCheckbox = (e, firstItem, boundary) => {
  //   if (e.target.checked) {
  //     !_.isEmpty(firstItem?.json_url)
  //       ? addGeoJsonLayer(firstItem, boundary)
  //       : addMapLayer(firstItem, boundary);
  //   } else {
  //     !_.isEmpty(firstItem?.json_url)
  //       ? removeGeoJsonLayer(firstItem, boundary)
  //       : removeMapLayer(firstItem, boundary);
  //   }
  // };

  const isCheckbox = (inputId) => {
    if (mapPreferencesData.find((item) => item?.id === inputId)) {
      return true;
    }
    return geoJsonLayerData.find((item) => item?.id === inputId); // country state district data
  };

  const handleTalukasEnableDisable = (id, boundry) => {
    if (talukasDisable) {
      // todo need to dynamically remove checkbox while selecting state as select in left side bar
      if (isCheckbox(id)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkboxStyle = {
    color: '#2A547C',
  };

  const renderCheckboxes = (items) => {
    return items?.map((item, index) => {
      if (item?.isShowCategory) {
        const selectedItem = handleCateogry?.find((x) => x.name === item.name);
        return (
          <>
            <span
              onClick={() => handleCateogryToggle(item)}
              data-tip
              data-for={item.name === 'Admin' ? 'registerTip' : ''}
            >
              {item.name === 'Admin' ? (
                <p className="map-preferences-labels" style={checkboxStyle}>
                  {item.name} &nbsp;
                  {item.name === 'Admin' ? (
                    <CommonToolTip moduleName={'Map Preferences'}>
                      <span className="info-style">
                        <i
                          className="cursor-ponter fa fa-info info-icon-edit-btn"
                          id="info-icon-tool-tip"
                          data-tip
                          data-for="info-icon-tool-tip"
                          // onClick={handleInfo}
                        />
                      </span>
                    </CommonToolTip>
                  ) : (
                    <span className="info-style">
                      <i
                        className="cursor-ponter fa fa-info info-icon-edit-btn"
                        id="info-icon-tool-tip"
                        data-tip
                        data-for="info-icon-tool-tip"
                        // onClick={handleInfo}
                      />
                    </span>
                  )}
                  <span className="category-span">
                    <span className="category-span">
                      {selectedItem?.checked ? (
                        <RiArrowDropUpLine size={25} />
                      ) : (
                        <RiArrowDropDownLine size={25} />
                      )}
                    </span>
                  </span>
                </p>
              ) : (
                <p className="map-preferences-labels" style={checkboxStyle}>
                  {item.name}
                  {/* <span className="category-span"> */}
                  <span className="category-span">
                    {selectedItem?.checked ? (
                      <RiArrowDropUpLine size={25} />
                    ) : (
                      <RiArrowDropDownLine size={25} />
                    )}
                  </span>
                  {/* </span> */}
                </p>
              )}
            </span>

            <Collapse
              id={'collapse' + index}
              isOpen={selectedItem?.checked}
              className="collapse-moadal"
            >
              {returnCheckbox(item, item.data, index)}
            </Collapse>
          </>
        );
      }
    });
  };

  useEffect(() => {
    let categoryList = JSON.parse(JSON.stringify(getBoundariesData));
    let updatedList = [];
    if (categoryList?.length) {
      updatedList = categoryList?.map((item) => {
        let isShowData = [];
        if (item?.data) {
          item?.data.map((boundary, index) => {
            let isShow = showPreferences(boundary.json_url, isMapPerferGeoJsonLoading);
            isShowData.push(isShow);
          });
          if (isShowData?.includes(false)) {
            item.isShowCategory = false;
          } else {
            item.isShowCategory = true;
          }
        }
        return item;
      });
      if (updatedList?.length) {
        setGetBoundariesData(updatedList);
      }
    }
  }, [isMapPerferGeoJsonLoading]);

  const showPreferences = (url, loading) => {
    let isGeoJsonData = url ? true : false;
    if (isGeoJsonData) {
      return !loading;
    }
    return true;
  };

  const showCheckBox = (mapPreferences, url, id, boundaryName, boundry) => {
    let isAddedLayer = selectedParamLegendData?.some((i) => i.add);
    if (isAddedLayer && mapPreferences?.length === 1 && _.isEmpty(url)) {
      if (mapPreferences?.some((i) => i?.id === id)) {
        return false;
      } else {
        return true;
      }
    } else if (mapPreferences?.length === 2 && _.isEmpty(url)) {
      if (mapPreferences?.some((i) => i?.id === id)) {
        return false;
      } else {
        return true;
      }
    } else if (boundaryName === TALUKAS) {
      // return false
      if (fromCropHealthMonitor) {
        return handleTalukasEnableDisable(id, boundry);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (!_.isEmpty(props?.districtData) && getBoundariesData?.length) {
      getBoundariesData?.map((item) => {
        if (item.category === 'Admin') {
          if (item.data?.length) {
            handleCateogryToggle(item);
            item.data.map((x) => {
              let e = { target: {} };
              e.target.checked = true;
              if (
                x?.slug === MAP_PREFERENCES_lABELS.TALUKAS ||
                x?.slug === MAP_PREFERENCES_lABELS.DISTRICTS
              ) {
                handleCheckbox(e, x, { fromMapPreferences: false });
              }
            });
          }
        }
      });
    }
  }, [props?.districtData, getBoundariesData]);

  useEffect(() => {
    if (fromCropHealthMonitor) {
      if (!_.isEmpty(props?.stateData) && getBoundariesData?.length) {
        handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.DISTRICTS, { checked: true, fromMapPreferences: false});
        if (props?.stateData?.name == 'select' && talukasBoundaryData?.e?.target?.checked) {
          let event = { target: { checked: false } };
          handleCheckbox(event, talukasBoundaryData.boundry, { fromMapPreferences: true });
        }
      }
    }
  }, [props?.stateData, getBoundariesData, fromCropHealthMonitor]);

  useEffect(()=>{
    if(fromCropHealthMonitor){
      if(cropWiseProRenderState && getBoundariesData?.length && geoJsonLayersData?.length){
        let event = { target: { checked: true } };
        handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.DISTRICTS, { checked: true, fromMapPreferences: false});
      }
    }
  },[cropWiseProRenderState, fromCropHealthMonitor,getBoundariesData, geoJsonLayersData])

  useEffect(() => {
    if (fromCropHealthMonitor) {
      if (
        !_.isEmpty(props?.staticData) &&
        props?.staticData?.name !== 'select' &&
        getBoundariesData?.length
      ) {
        // handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.DISTRICTS, true);
        handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.DISTRICTS, {
          checked: true,
          fromMapPreferences: false,
        });
        // if(props?.staticData?.name == 'select' && talukasBoundaryData?.e?.target?.checked){
        // let event = { target: { checked: false } };
        if (talukasBoundaryData?.e?.target?.checked) {
          ///added
          let event = { target: { checked: false } };
          handleCheckbox(event, talukasBoundaryData.boundry, { fromMapPreferences: true });
        }
        // handleCheckbox(event,talukasBoundaryData.boundry,{ fromMapPreferences: true })
        // }
        // handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.DISTRICTS, { checked: false, fromMapPreferences: false});
      } else if (_.isEmpty(props?.staticData) || props?.staticData === '') {
        if (talukasBoundaryData?.e?.target?.checked) {
          let event = { target: { checked: false } };
          handleCheckbox(event, talukasBoundaryData.boundry, { fromMapPreferences: true });
        }
        // if(!cropWiseProRenderState){
          handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.DISTRICTS, {
            checked: false,
            fromMapPreferences: false,
          });
        // }
      }
    }
  }, [props?.staticData, activeTab, getBoundariesData, fromCropHealthMonitor]);

  useEffect(() => {
    if (fromCropHealthMonitor) {
      if (
        !_.isEmpty(props?.countryData) &&
        getBoundariesData?.length
        // props?.countryData.name === DEFAULT_COUNTRY.name
      ) {
        // setTalukasDisable(true);
        // handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.COUNTRY, true);

        setTalukasDisable(true);
        // handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.COUNTRY, {
        //   checked: true,
        //   fromMapPreferences: false,
        // });
        // handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.STATES, {
        //   checked: true,
        //   fromMapPreferences: false,
        // });
        
      }
      // else {
      //   if (!_.isEmpty(props?.countryData) && getBoundariesData?.length) {
      //     setTalukasDisable(true);

      //     handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.STATES, true);
      //   }
      // }
    }
  }, [props?.countryData, getBoundariesData, fromCropHealthMonitor]);

  useEffect(() => {
    if (fromCropHealthMonitor) {
      if (!_.isEmpty(props?.districtData) && getBoundariesData?.length) {
        // handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.TALUKAS, true);
        handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.TALUKAS, {
          checked: true,
          fromMapPreferences: false,
        });
      } else if (_.isEmpty(props?.districtData) && selectedDistrict?.value === 'Select') {
        if (talukasBoundaryData?.e?.target?.checked) {
          let event = { target: { checked: false } };
          handleCheckbox(event, talukasBoundaryData.boundry, { fromMapPreferences: true });
        }
        setTalukasDisable(true);
        handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.DISTRICTS, {
          checked: true,
          fromMapPreferences: false,
        });
      }
    }
  }, [props?.districtData, getBoundariesData, fromCropHealthMonitor]);

  const handleDefaultCheckboxes = (
    boundaryData,
    selectedLocationName,
    { checked, fromMapPreferences },
  ) => {
    boundaryData?.map((item) => {
      if (item.name === 'Admin') {
        if (item.data?.length) {
          // handleCateogryToggle(item);
          item.data.map((x) => {
            let e = { target: {} };
            e.target.checked = checked;
            if (x?.slug === selectedLocationName) {
              if (selectedLocationName == MAP_PREFERENCES_lABELS.COUNTRY) {
                handleCheckbox(e, x, { fromMapPreferences: fromMapPreferences });
              } else {
                handleCheckbox(e, x, { fromMapPreferences: fromMapPreferences });
              }
            }
          });
        }
      }
    });
  };

  const showToolTip = (selectedMapPreferences, selectedParamLegendData, firstItem) => {
    if (firstItem?.json_url) {
      return false;
    } else {
      if (
        selectedMapPreferences?.length === 2 ||
        (selectedMapPreferences?.length === 1 &&
          selectedParamLegendData?.filter((i) => i.add)?.length === 1)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const returnCheckbox = (boundary, boundryTypes, index) => {
    return boundryTypes?.map((firstItem, index) => {
      return (
        <>
          <Row className="row-mb m-2">
            <Col
              md="2"
              lg="2"
              sm="2"
              key={index}
              className="checkbox-rp"
              // id={firstItem.json_url || !showCheckBox(selectedMapPreferences,firstItem) ? 'geojson_input' : 'map_preference_input'}
              // data-tip
              // data-for={firstItem.json_url || !showCheckBox(selectedMapPreferences,firstItem) ? 'geojson_input' : 'map_preference_input'}
            >
              <input
                id={`mapreference${firstItem?.id}`}
                className="checkbox checkbox-mt"
                type="checkbox"
                name={firstItem?.id}
                // defaultChecked={firstItem.checked}
                defaultChecked={isCheckbox(firstItem?.id)}
                onChange={(e) => handleCheckbox(e, firstItem, { fromMapPreferences: true })}
                // checked={isCheckbox(firstItem?.id)}
                disabled={
                  loading
                    ? true
                    : showCheckBox(
                        selectedMapPreferences,
                        firstItem.json_url,
                        firstItem.id,
                        firstItem.slug,
                        firstItem,
                      )
                }
              />
            </Col>
            <Col
              md="10"
              lg="10"
              sm="10"
              className="label-lp"
              id={firstItem?.json_url ? 'geojson_input' : 'map_preference_input'}
              data-tip
              // data-for={firstItem?.json_url  ? 'geojson_input' : 'map_preference_input'}
              data-for={
                firstItem?.json_url ||
                !showCheckBox(
                  selectedMapPreferences,
                  firstItem.json_url,
                  firstItem.id,
                  firstItem.slug,
                  firstItem,
                )
                  ? 'geojson_input'
                  : `map_preference_input`
              }
            >
              <>
                <label htmlFor="checkbox">{firstItem?.name}</label>
              </>
            </Col>
            {showToolTip(selectedMapPreferences, selectedParamLegendData, firstItem) ? (
              <ReactTooltip
                id={`map_preference_input`}
                effect="float"
                place={'top'}
                type="info"
                multiline={true}
                className="map-prefer-tooltip-st"
              >
                {t('FILTER.REMOVE_LAYER')}
              </ReactTooltip>
            ) : (
              ''
            )}
          </Row>
        </>
      );
    });
  };

  return (
    <>
      <div>
        {/* {modal && loading && <Loader />} */}
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="modal-map-sm col-2 order-modal model-content"
        >
          <ModalHeader className="close-map-preference" toggle={toggle}>
            <p className="map-preferences-side-bar-title left-mrg-header" style={{ width: 'auto' }}>
              {t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.HEADER.TITLE')} <br />
              <span className="map-preferences-side-bar-sub-title" style={{ fontSize: '10px' }}>
                {t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.HEADER.SUB_TITLE')}
              </span>
            </p>
          </ModalHeader>
          <hr />
          <ModalBody className="modal-bdy">
            {renderCheckboxes(getBoundariesData)}
            <br />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}
export default React.memo(MapPreferences);
MapPreferences.propTypes = {
  // eslint-disable-next-line react/no-typos
  isToggle: PropTypes.boolean,
  productModalData: PropTypes.object,
  handleToggle: PropTypes.func,
  Map_Preferences: PropTypes.object,
  handleRenderingList: PropTypes.func,
  handleCheckBoxRenderingList: PropTypes.func,
  renderingList: PropTypes.object,
  handleShow: PropTypes.func,
};
