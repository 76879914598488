import React, { useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  ZoomControl,
  LayersControl,
  LayerGroup,
} from 'react-leaflet';
import _, { times } from 'lodash';
import 'leaflet-side-by-side';
import { Button } from 'reactstrap';
import 'leaflet/dist/leaflet.css';
import 'leaflet.control.opacity/dist/L.Control.Opacity.css';
import 'leaflet.control.opacity';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import '../../assets/CSS/LeafletWrapper.css';
import '../../assets/CSS/Header.css';
import myConstClass from '../../Constants';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMapPreferenceRequest,
  getMapPreferenceSuccess,
  getMapPreferenceBoundariesSuccess,
} from '../../redux/actions';
import Loader from '../../Components/common/loader';
import CustomLegend from '../WeatherForecast/CustomLayerControl/CustomLegend';
import DiscreteLegend from '../WeatherForecast/CustomLayerControl/DiscreteLegend';
import MapPreferences from './MapPreferences';
import DrawShape from './drawshape';
import { AXIOS_INSTANCE } from '../../redux/api/apiEndPoint';
import mapPreferenceIcon from '../../assets/Images/mapPreferenceIcon.svg';
import { MAP_PREFERENCES_lABELS } from '../../Components/constants';
import { DEFAULT_COUNTRY, LOCATION } from '../../Components/constants';
import { GEOJSON_COUNTRY_TOOLTIP_DATA } from '../../Components/constants/index';
import { Row, Col } from 'reactstrap';
const Map = (props) => {
  const {
    applyLayers,
    cordinateAndZoom,
    ChangeView,
    selectedLocation,
    setMapReference,
    params,
    selectedParamLegendData,
    compareParamLegendData,
    cropWiseProRenderState,
    layerRef,
    map,
    showCharts,
    errorData,
    graphData,
    isGraphLoading,
    renderChartsUI,
    isLoading,
    activeTab,
    handleSelectedRegion,
    addLayers,
    mapRefState,
    removeTransparencyToolLayers,
    handleMapLayerOpacity,
    isHideBar,
    cropHealthMonitorStateData,
    selectedParams,
    setIndexLayers,
    indexLayers,
    showMapPreferenceTooltip,
    setShowMapPreferenceTooltip,
    activeTabSwitch,
    isCompareClicked,
    compareSelectedParam,
    loading,
    setLoading,
    modal,
    setModal,
    setIsLoading,
    setSelectedParamLegendData,
    selectedParam,
    compareParam,
    selectedDistrict
  } = props;
  const dispatch = useDispatch();
  const { Overlay } = LayersControl;
  const [isOpenMapModel, setIsOpenMapModel] = useState(false);
  const splitterRef = useRef(null);
  const [mapPreferRefs, setMapPreferRefs] = useState([]);
  // const [isHideBar, setBar] = useState(false);
  // const [selectedParam, setSelectedParam] = useState('');
  const [previousParam, setPreviousParam] = useState('');
  // const [compareSelectedParam, setCompareSelectedParam] = useState('');
  const [initialSelectedDate, setInitialSelectedDate] = useState([]);
  const [finalSelectedDate, setFinalSelectedDate] = useState([]);
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [geoJsonAllData, setGeoJsonAllData] = useState([]);
  const [geoJsonLayersData, setGeoJsonLayerData] = useState([]);
  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [selectedMapPreferLegendData, setSelectedMapPreferLegendData] = useState([]);
  const mapRef = useRef(null);
  const [mapControl, setMapControl] = useState({});
  const [mapLayerOpacity, setMapLayerOpacity] = useState({ layer: '' });
  // const [opacityControl, setOpacityControl] = useState('');
  const [compareView, setCompareView] = useState([]);
  // const [isCompareClicked, setIsCompareClicked] = useState(false);
  const [mapPreferenceGeoJsonloading, setMapPreferenceGeoJsonloading] = useState(false);
  const [selectedRegionId, setSelectedRegionId] = useState('');

  const [mapPreferences, setMapPreferences] = useState({
    country: { layer: useRef(), color: '', data: '' },
    states_or_provinces: { layer: useRef(), color: '', data: '' },
    districts_or_cities: { layer: useRef(), color: '', data: '' },
    meteorological_locations: { layer: useRef(), color: '', data: '' },
    basins: { layer: useRef(), color: '', data: '' },
    rivers_or_reservoirs: { layer: useRef(), color: '', data: '' },
    talukas: { layer: useRef(), color: '', data: '' },
  });
  // const [cordinateAndZoom, setCordinateAndZoom] = useState({
  //   cordinates: [23.4073, 79.446],
  //   locationCordinates: [23.4073, 79.446],
  //   markerCordinates: [23.4073, 79.446],
  //   zoom: 5.5,
  // });
  const [compareCordinates, setCompareCordinates] = useState([]);

  const [mapData, setMapData] = useState('');
  const [mapUrl, setMapUrl] = useState({
    name: '',
    url: '',
  });

  const [state, setState] = useState({
    min: 0,
    max: 0,
  });

  const [layerControl, setLayerControl] = useState({
    firstLayer: {},
    secondLayer: {},
  });

  const { Weather, DroughtMonitor, boundries } = useSelector((state) => ({
    Weather: state.Weather,
    boundries: state.Map_Preference,
  }));

  setTimeout(() => {
    if (document.getElementsByTagName('body')) {
      if (document.getElementsByTagName('body')[0].children) {
        Object.values(document.getElementsByTagName('body')[0].children).forEach((data) => {
          if (data.toString().includes('HTMLIFrameElement')) {
            data.style = 'display: none !important;';
          }
        });
      }
    }
  }, 4000);

  //useeffect for getting map layers data from redux
  useEffect(() => {
    // handleMapModel();
    dispatch(getMapPreferenceSuccess(''));
    dispatch(getMapPreferenceRequest({ country_id: DEFAULT_COUNTRY.id, module_name: 'crop' }));
    setMapData('');

    return () => {
      //setSelectedParamLegendData('');
      // setCompareParamLegendData('');
      // dispatch(getMapPreferenceBoundariesSuccess(''));
    };
  }, []);

  useEffect(() => {
    // handleMapModel();
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        selectedLayer.country = selectedLocation?.country;
        selectedLayer?.layer?.current?.clearLayers();
      });
    }
    setGeoJsonData([]);
    // setSelectedParamLegendData([]);
    // removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
  }, [activeTab]);

  useEffect(() => {
    if (mapPreferencesData?.length === 2) setShowMapPreferenceTooltip(true);
    else setShowMapPreferenceTooltip(false);
  }, [mapPreferencesData]);

  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (boundries?.mapPreferenceData) {
      setGetBoundariesData(boundries?.mapPreferenceData?.data?.result);
    }
  }, [boundries?.mapPreferenceData]);
  useEffect(() => {
    if (getBoundariesData && getBoundariesData?.length) {
      let API_URL = [];
      mapPreferRefs.current = [];
      // setIsLoading(true);
      getBoundariesData?.map((item) => {
        if (item?.data && item?.data.length) {
          item.data.map((boundary) => {
            // boundary?.map(each => {
            if (boundary?.json_url?.length) {
              setGeoJsonData([]);
              setGeoJsonAllData([]);
              let mapPreferKeys = Object.keys(mapPreferences);
              if (mapPreferKeys) {
                mapPreferKeys.map((item) => {
                  let selectedLayer = mapPreferences[item];
                  if (selectedLayer?.layer && selectedLayer?.layer?.current)
                    selectedLayer?.layer?.current?.clearLayers();
                  selectedLayer.color = '';
                  setMapPreferences((prev) => {
                    return {
                      ...prev,
                      [item]: selectedLayer,
                    };
                  });
                });
              }
              setMapPreferenceGeoJsonloading(true);
              let config = {
                name: boundary?.slug,
                id: boundary?.id,
                color: boundary?.color_code,
                category: boundary?.map_preference_category?.name,
                country_id: 'e2f886a7-3299-4c82-af2e-987fe92fab40',
              };
              API_URL.push(AXIOS_INSTANCE.get(boundary?.json_url, config));
            } else {
              let ref = React.createRef();
              ref.current = {
                boundary_id: boundary?.id,
                layer: React.createRef(),
              };
              mapPreferRefs.current.push(ref);
            }
            // });
          });
        }
      });
      Promise.all(API_URL).then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        setMapPreferenceGeoJsonloading(false);
        setGeoJsonLayerData(res);
        // setIsLoading(false);
      });
    }
  }, [getBoundariesData]);

  useEffect(() => {
    if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) {
      map.on('baselayerchange', function (element) {
        element?.layer?.bringToBack();
      });
    }
  }, [map]);

  useEffect(() => {
    let selectedGeoJsonData = [];
    if (geoJsonLayersData?.length && geoJsonData?.length) {
      geoJsonData?.map((item) => {
        let filteredObj = geoJsonLayersData?.find(
          (layer) => layer.config.id === item.id && layer.config.country_id === item.country_id,
        );
        if (!_.isEmpty(filteredObj)) {
          filteredObj.fromMapPreferences = item.fromMapPreferences;
          selectedGeoJsonData.push(filteredObj);
        }
      });
    } else {
      selectedGeoJsonData = [];
    }
    setGeoJsonAllData(selectedGeoJsonData);
  }, [geoJsonLayersData, geoJsonData]);

  useEffect(() => {
    if (splitterRef && splitterRef.current) {
      if (errorData?.showError) {
        splitterRef.current.setState({ secondaryPaneSize: '30%' });
      } else {
        splitterRef.current.setState({ secondaryPaneSize: '70%' });
      }
    }
  }, [errorData]);

  useEffect(() => {
    if (!_.isEmpty(mapControl)) {
      mapControl?.on('dividermove', map.dragging.enable());
    } else if (!_.isEmpty(map)) {
      map?.dragging?.enable();
    }
  }, [mapControl]);

  //useeffect to reset all the map preferences states data when country gets changed
  useEffect(() => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
    let updatedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
    if (selectedLegend.length) {
      updatedLegend= selectedLegend.map(function(value, i) {
        if (value.add) {
          value.add = false;
        }
        return value;
      });
      setSelectedMapPreferLegendData(updatedLegend);
    }
    //removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
  }, [activeTab, selectedRegionId]);

  useEffect(() => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));

    let updatedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
    if (selectedLegend.length) {
      updatedLegend= selectedLegend.map(function(value, i) {
        if (value.add) {
          value.add = false;
        }
        return value;
      });
      setSelectedMapPreferLegendData(updatedLegend);
    }
  }, [selectedLocation?.country]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      let MapPrefereArr = [];
      mapPreferencesData?.map((item) => {
        let legendItem = JSON.parse(JSON.stringify(item.result));
        legendItem.id = item?.dataSource;
        // legendItem.parameter =
        //   item.slug === MAP_PREFERENCES_lABELS.JRC
        //     ? t('WEATHER_FORECAST.MAP_PREFERENCES.WATER_OCCURRENCE')
        //     : item.slug === MAP_PREFERENCES_lABELS.MOD44
        //     ? t('WEATHER_FORECAST.MAP_PREFERENCES.PERMANENT_WATER')
        //     : item?.category;
        legendItem.dataSource =
          item.slug === MAP_PREFERENCES_lABELS.JRC
            ? t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.WATER_OCCURRENCE')
            : item.slug === MAP_PREFERENCES_lABELS.MOD44
            ? t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.PERMANENT_WATER')
            : item?.category;
        // legendItem.dataSource = item?.dataSource;
        legendItem.parameter = item?.legendName;
        legendItem.add = true;
        legendItem.measure = legendItem.units;
        legendItem.isCompare = false;
        legendItem.legendId = item.id;
        legendItem.slug = item.slug;
        legendItem.fromMapPreferences = true;
        if (selectedMapPreferLegendData?.length) {
          let isDuplicate = selectedMapPreferLegendData?.find(
            (x) => x.legendId === item.id && item.add === true,
          );
          if (_.isEmpty(isDuplicate)) {
            MapPrefereArr.push(legendItem);
          }
        } else {
          MapPrefereArr.push(legendItem);
        }
      });
      setSelectedMapPreferLegendData(MapPrefereArr);
    }
  }, [mapPreferencesData]);

  // useEffect(() => {
  //   if (geoJsonAllData?.length) {
  //     geoJsonAllData.map((item) => {
  //       let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
  //       let selectedLayer = mapPreferences[mapGeojsonItem];
  //       if (selectedLayer?.layer && selectedLayer?.layer?.current) {
  //         let data = JSON.parse(JSON.stringify(item.data));
  //         if (
  //           item.config.name !== MAP_PREFERENCES_lABELS.DISTRICTS &&
  //           item.config.name !== MAP_PREFERENCES_lABELS.TALUKAS
  //         ) {
  //           selectedLayer.country = selectedLocation?.country;
  //           selectedLayer?.layer?.current?.clearLayers();
  //           selectedLayer.layer.current.addData(data);
  //           selectedLayer.color = item.config.color;
  //           setMapPreferences((prev) => {
  //             return {
  //               ...prev,
  //               [mapGeojsonItem]: selectedLayer,
  //             };
  //           });
  //         }
  //       }
  //     });
  //   }
  // }, [geoJsonAllData, activeTab, mapPreferences?.state?.layer?.current, selectedLocation?.country]);

  useEffect(() => {
    if (geoJsonAllData?.length && cropWiseProRenderState) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          !item.fromMapPreferences &&
          item.config.name === MAP_PREFERENCES_lABELS.DISTRICTS
        ) {
          selectedLayer.country = selectedLocation?.country;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(item.data);
          selectedLayer.color = item.config.color;
          setMapPreferences((prev) => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
      });
    }

  }, [cropWiseProRenderState, geoJsonAllData])

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          let data = JSON.parse(JSON.stringify(item.data));
          if (
            (item.config.name !== MAP_PREFERENCES_lABELS.DISTRICTS &&
              item.config.name !== MAP_PREFERENCES_lABELS.TALUKAS) ||
             ((selectedLayer?.layer &&
              selectedLayer?.layer?.current &&
              item.fromMapPreferences &&
              (_.isEmpty(selectedLocation?.state) || selectedLocation?.state === '') &&
              item.config.name === MAP_PREFERENCES_lABELS.DISTRICTS) ||
             (selectedLayer?.layer &&
              selectedLayer?.layer?.current &&
              item.fromMapPreferences &&
              (_.isEmpty(selectedLocation?.district) || selectedLocation?.district === '') &&
              item.config.name === MAP_PREFERENCES_lABELS.TALUKAS))
          ) {
            selectedLayer.country = selectedLocation?.country;
            selectedLayer?.layer?.current?.clearLayers();
            selectedLayer.layer.current.addData(data);
            selectedLayer.color = item.config.color;
            setMapPreferences((prev) => {
              return {
                ...prev,
                [mapGeojsonItem]: selectedLayer,
              };
            });
          }
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          if (!_.isEmpty(selectedLocation?.district) || selectedLocation?.district !== '') {
            let filteredLayer = returnFilteredGeoJsonData(
              selectedLayer,
              item,
              selectedLocation?.district,
              MAP_PREFERENCES_lABELS.DISTRICTS,
              MAP_PREFERENCES_lABELS.TALUKAS,
              selectedLocation?.country,
            );
            if (filteredLayer) {
              setMapPreferences((prev) => {
                return {
                  ...prev,
                  [mapGeojsonItem]: filteredLayer,
                };
              });
            }
          }
          if (!_.isEmpty(selectedLocation?.state) || selectedLocation?.state !== '') {
            let filteredLayer = returnFilteredGeoJsonData(
              selectedLayer,
              item,
              selectedLocation?.state,
              MAP_PREFERENCES_lABELS.STATES,
              MAP_PREFERENCES_lABELS.DISTRICTS,
              selectedLocation?.country,
            );
            if (filteredLayer) {
              setMapPreferences((prev) => {
                return {
                  ...prev,
                  [mapGeojsonItem]: filteredLayer,
                };
              });
            }
          }
        }
      });
    }
  }, [geoJsonAllData]);


  // useEffect(() => {
  //   if (geoJsonAllData?.length) {
  //     geoJsonAllData.map((item) => {
  //       let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
  //       let selectedLayer = mapPreferences[mapGeojsonItem];
  //       if (
  //         selectedLayer?.layer &&
  //         selectedLayer?.layer?.current &&
  //         item.fromMapPreferences &&
  //         (_.isEmpty(selectedLocation?.state) || selectedLocation?.state === '') &&
  //         item.config.name === MAP_PREFERENCES_lABELS.DISTRICTS
  //       ) {
  //         selectedLayer.country = selectedLocation?.country;
  //         selectedLayer?.layer?.current?.clearLayers();
  //         selectedLayer.layer.current.addData(item.data);
  //         selectedLayer.color = item.config.color;
  //         setMapPreferences((prev) => {
  //           return {
  //             ...prev,
  //             [mapGeojsonItem]: selectedLayer,
  //           };
  //         });
  //       }
  //     });
  //   }
  // }, [geoJsonAllData]);

  // useEffect(() => {
  //   if (geoJsonAllData?.length) {
  //     geoJsonAllData.map((item) => {
  //       let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
  //       let selectedLayer = mapPreferences[mapGeojsonItem];
  //       if (
  //         selectedLayer?.layer &&
  //         selectedLayer?.layer?.current &&
  //         item.fromMapPreferences &&
  //         (_.isEmpty(selectedLocation?.district) || selectedLocation?.district === '') &&
  //         item.config.name === MAP_PREFERENCES_lABELS.TALUKAS
  //       ) {
  //         selectedLayer.country = selectedLocation?.country;
  //         selectedLayer?.layer?.current?.clearLayers();
  //         selectedLayer.layer.current.addData(item.data);
  //         selectedLayer.color = item.config.color;
  //         setMapPreferences((prev) => {
  //           return {
  //             ...prev,
  //             [mapGeojsonItem]: selectedLayer,
  //           };
  //         });
  //       }
  //     });
  //   }
  // }, [geoJsonAllData]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      if (mapPreferRefs?.current?.length) {
        mapPreferencesData?.map((item) => {
          let selectedMapPreferences = mapPreferRefs.current.find(
            (x) => x.current.boundary_id === item.id,
          );
          let currentParamRef = selectedMapPreferences.current?.layer;
          currentParamRef?.current?.eachLayer((mapLayer) => {
            let updatedIndexLayer = _.cloneDeep(indexLayers);
            updatedIndexLayer.push({ isCompare: false, layer: mapLayer});
            setIndexLayers(updatedIndexLayer);
            mapLayer.setUrl(item?.result?.map_url);
            handleMapLayerOpacity({ layer: mapLayer });
            //setMapLayerOpacity({ layer: mapLayer });
          });
          if (currentParamRef && currentParamRef?.current) {
            addLayers(currentParamRef);
          }
        });
      }
    }
  }, [mapPreferencesData, mapPreferRefs.current]);


  useEffect(() => {
    if (
      // isCompareClicked &&
      // !_.isEmpty(compareSelectedParam) &&
      compareParamLegendData?.length &&
      compareParamLegendData?.some((i) => i.add && !i.descret_legend) &&
      selectedMapPreferLegendData?.length &&
      selectedMapPreferLegendData?.some((i) => i.add && i.descret_legend)
    ) {
      const leafletEle = document.getElementsByClassName('left-legend-style');
      if (leafletEle && leafletEle[0]) {
        leafletEle[0].classList.add('left-legend-pos-st');
      }
      const discreteLeafletEle = document.getElementsByClassName('left-discrete-legend-style');
      if (discreteLeafletEle && discreteLeafletEle[0]) {
        discreteLeafletEle[0].classList.add('left-discrete-legend-pos-st');
      }
    } else if (
      compareParamLegendData?.length &&
      compareParamLegendData?.findIndex((i) => i.add && !i.descret_legend) < 0
    ) {
      const leafletEle = document.getElementsByClassName('left-legend-style');
      const leftPosleafletEle = document.getElementsByClassName('left-legend-pos-st');

      const discreteLeafletEle = document.getElementsByClassName('left-discrete-legend-style');
      const leftPosdiscreteLeafletEle = document.getElementsByClassName(
        'left-discrete-legend-pos-st',
      );

      if (leafletEle && leafletEle[0] && leftPosleafletEle) {
        leafletEle[0].classList.remove('left-legend-pos-st');
      }
      if (discreteLeafletEle && discreteLeafletEle[0] && leftPosdiscreteLeafletEle) {
        discreteLeafletEle[0].classList.remove('left-discrete-legend-pos-st');
      }
    }
  }, [compareParamLegendData, selectedMapPreferLegendData, isCompareClicked, compareSelectedParam]);

  useEffect(() => {
    if (
      selectedParamLegendData?.length &&
      selectedParamLegendData?.some((i) => i.add && i.descret_legend) &&
      selectedMapPreferLegendData?.length &&
      selectedMapPreferLegendData?.some((i) => i.add && !i.descret_legend)
    ) {
      const leafletEle = document.getElementsByClassName('right-legend-style');
      if (leafletEle && leafletEle[0]) {
        leafletEle[0].classList.add('crop-right-legend-pos-st');
      }
      const discreteLeafletEle = document.getElementsByClassName('right-discrete-legend-style');
      if (discreteLeafletEle && discreteLeafletEle[0]) {
        discreteLeafletEle[0].classList.add('crop-right-discrete-legend-pos-st');
      }
    } else {
      const leafletEle = document.getElementsByClassName('right-legend-style');
      const rightPosLeafletEle = document.getElementsByClassName('crop-right-legend-pos-st');
      const discreteLeafletEle = document.getElementsByClassName('right-discrete-legend-style');
      const rightPosdiscreteLeafletEle = document.getElementsByClassName(
        'crop-right-discrete-legend-pos-st',
      );
      if (leafletEle && leafletEle[0] && rightPosLeafletEle) {
        leafletEle[0].classList.remove('crop-right-legend-pos-st');
      }
      if (discreteLeafletEle && discreteLeafletEle[0] && rightPosdiscreteLeafletEle) {
        discreteLeafletEle[0].classList.remove('crop-right-discrete-legend-pos-st');
      }
    }
  }, [selectedParamLegendData, selectedMapPreferLegendData]);

  const returnFilteredGeoJsonData = (
    selectedLayer,
    item,
    location,
    propertyName,
    locationName,
    countryData,
  ) => {
    // let updatedSelectedLayer = _.cloneDeep(selectedLayer);
    if (selectedLayer?.layer && selectedLayer?.layer?.current) {
      let data = JSON.parse(JSON.stringify(item.data));
      let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
        (item) => item.selectedCountry === selectedLocation?.country?.name,
      );
      let labelprop = selectedCountryData ? selectedCountryData[propertyName] : '';
      if (item.config.name === locationName && !_.isEmpty(location)) {
        data.features = item.data?.features.filter((x) => {
          if (_.startCase(_.lowerCase(x?.properties[labelprop])) === location?.value) {
            selectedLayer.country = selectedLocation?.country;
            selectedLayer?.layer?.current?.clearLayers();
            return x;
          }
        });
        selectedLayer.layer.current.addData(data);
        selectedLayer.color = item.config.color;
      }
      return selectedLayer;
    }
  };


  const getMapPreferencesGeojsonData = (geoJsonList) => {
    let data = JSON.parse(JSON.stringify(geoJsonList));
    let mapPreferKeys = Object.keys(mapPreferences);
    if (mapPreferKeys) {
      mapPreferKeys.map((item) => {
        let selectedLayer = mapPreferences[item];
        if (selectedLayer?.layer && selectedLayer?.layer?.current)
          selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.color = '';
        setMapPreferences((prev) => {
          return {
            ...prev,
            [item]: selectedLayer,
          };
        });
      });
    }
    setGeoJsonData(data);
  };

  //function to handle map preferences model toggling
  const handleMapModel = () => {
    setIsOpenMapModel(!isOpenMapModel);
  };

  //function to remove layers from map
  const removeLayers = (selectedParamRef) => {
    if (mapRefState && mapRefState?.current && selectedParamRef && selectedParamRef?.current) {
      const map = mapRefState?.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => map.removeLayer(layer));
    }
  };

  const removeMapPreferenceLayer = (mapPreferRefs, mapPreferencesData) => {
    if (mapPreferRefs?.current?.length && mapPreferencesData?.length) {
      mapPreferencesData?.map((item) => {
        let selectedMapIndex = _.findIndex(
          mapPreferRefs.current,
          (list) => list.current.boundary_id === item.id,
        );
        let previousParamRef = mapPreferRefs?.current[selectedMapIndex].current.layer;
        if (previousParamRef && previousParamRef?.current) {
          removeLayers(previousParamRef);
          previousParamRef?.current?.eachLayer(layer => {
            removeTransparencyToolLayers(indexLayers, layer?._url)
          });
        }
      });
    }
  };

  const getMapPreferencesData = (mapPreferencesLayerData) => {
    /** removing previously added map preferences layers before setting the state */
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    // setIndexLayers([]);
    // if (mapPreferencesLayerData?.length === 0) {
    //   props.setMapDataStateFromMapFile(!props.mapDataStateFromMapFile);
    // }
    /** setting map preferences state*/
    let arr = JSON.parse(JSON.stringify(mapPreferencesLayerData));
    let paramData = '';
    let paramArr = '';
    if (selectedMapPreferLegendData?.length) {
      paramData = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      paramData?.map((item, index) => {
        if (item.add) {
          let legendItem = { ...item };
          legendItem.add = false;
          paramData[index] = legendItem;
        }
      });
      paramArr = [...paramData];
    }
    setSelectedMapPreferLegendData(paramArr);
    setMapPreferencesData(arr);
  };

  //function for compare props
  // const handleCompareProps = (props) => {
  //   setFinalSelectedDate(props.finalSelectedDate);
  //   setCompareSelectedParam(props.compareSelectedParam);
  // };

  //function to create overlays for the selected indices/params
  const renderParamLayer = (paramList) => {
    if (paramList && paramList?.length) {
      return paramList?.map((item, index) => {
        return (
          <>
            <Overlay name={item.name}>
              <LayerGroup ref={layerRef.current[item.paramIndex]}>
                <TileLayer
                  url={''}
                  id={item.name}
                  attribution=''
                />
              </LayerGroup>
            </Overlay>
          </>
        );
      });
    }
  };

  //for basins
  const handleEachFeatureChange = (countryData, locationType, feat, layer) => {
    let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
      (item) => item.selectedCountry === countryData?.name,
    );
    let labelprop = selectedCountryData ? selectedCountryData[locationType] : '';
    if (labelprop && feat.properties[labelprop]) {
      layer.bindTooltip(feat.properties[labelprop] + '', { sticky: true, direction: 'auto' });
    }
    /** TODO: alternative solution */

    // if(!_.isEmpty(layer) && !_.isEmpty(feat?.properties)){
    //   layer?.on({
    //     'mousemove': (e) => {
    //       const name = e.target.feature.properties['MJ_BASIN__'] ? e.target.feature.properties['MJ_BASIN__'] : '';
    //       layer?.bindTooltip(name)
    //       layer?.openTooltip(e?.latlng);
    //     },
    //     // 'mouseout': () => {
    //     //   layer?.unbindTooltip();
    //     //   layer?.closeTooltip();
    //     // },
    //   });
    // }
  };
  
  //function to render map
  const renderMapContainer = () => {
    let x = document.getElementsByClassName("leaflet-control-layers");
    const parent = document.getElementsByClassName('map-preferences-button');
    if (x?.length){
      x[4]?.addEventListener(
        "mouseover", over);     
        x[4]?.addEventListener(
        "mouseout", out);
    function over() {
      // parent[0].style.marginTop = "23px"
      parent[0].style.marginTop = "1.7%"
    }
    function out() {
      parent[0].style.marginTop = "0%"
    }
    }
    return (
      <>
        <MapContainer
          center={cordinateAndZoom.cordinates}
          id="drought-leaflet-map"
          zoom={cordinateAndZoom.zoom}
          className="crop-map-container"
          zoomControl={false}
          scrollWheelZoom={true}
          whenCreated={setMapReference}
        >
          <ChangeView center={cordinateAndZoom.cordinates} zoom={cordinateAndZoom.zoom} />

          <LayersControl position='topright'> 
            <LayersControl.BaseLayer  name="Satellite">
              <TileLayer
                url={myConstClass.SATELLITE_TILE_LAYER_URL}
                options={{ tileSize: 256 }}
                attribution={`© <a href='https://www.mapbox.com/about/maps/' target='_blank'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a> ©<a href="https://www.maxar.com/" target='_blank'> Maxar</a>`}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked name="Streets">
              <TileLayer
                url={myConstClass.TITLE_LAYER_URL}
                options={{ tileSize: 256 }}
                attribution={`© <a href='https://www.mapbox.com/about/maps/' target='_blank'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a>`}
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          <ZoomControl position="topright" className="map-zoom-control" />
          <DrawShape
            handleSelectedRegion={handleSelectedRegion}
            applyLayers={applyLayers}
            country={selectedLocation?.country}
            state={selectedLocation?.state}
            district={selectedLocation?.district}
            activeTab={activeTab}
            activeTabSwitch={activeTabSwitch}
            selectedParam={selectedParam}
            compareParam={compareParam}
          />
          
          <GeoJSON
            /////
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.COUNTRY}
            /////
            ref={mapPreferences['country'].layer}
            style={{ color: mapPreferences['country'].color, fillColor: '#0000', weight: 1 }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['country'].country, 'country', feat, layer)
            }
          />

          <GeoJSON
            // key={selectedLocation.country}
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.STATES}
            ref={mapPreferences['states_or_provinces'].layer}
            style={{
              color: mapPreferences['states_or_provinces'].color,
              fillColor: '#0000',
              weight: 1.6,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['states_or_provinces'].country,
                'states_or_provinces',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.DISTRICTS}
            ref={mapPreferences['districts_or_cities'].layer}
            style={{
              color: mapPreferences['districts_or_cities'].color,
              weight: 1,
              fillColor: '#0000',
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['districts_or_cities'].country,
                'districts_or_cities',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.METEOROLOGICAL_LOCATIONS}
            ref={mapPreferences['meteorological_locations'].layer}
            style={{
              color: mapPreferences['meteorological_locations'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['meteorological_locations'].country,
                'meteorological_locations',
                feat,
                layer,
              )
            }
          />
          
          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.BASINS}
            ref={mapPreferences['basins'].layer}
            style={{
              color: mapPreferences['basins'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['basins'].country, 'basins', feat, layer)
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.RIVERS}
            ref={mapPreferences['rivers_or_reservoirs'].layer}
            style={{
              color: mapPreferences['rivers_or_reservoirs'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['rivers_or_reservoirs'].country,
                'rivers_or_reservoirs',
                feat,
                layer,
              )
            }
          />
          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.TALUKAS}
            ref={mapPreferences['talukas'].layer}
            style={{
              color: mapPreferences['talukas'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['talukas'].country, 'talukas', feat, layer)
            }
          />
          {/* <LayersControl position="topleft">
            <TileLayer
              //attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
              url={myConstClass.TITLE_LAYER_URL}
            />
          </LayersControl> */}
          <LayersControl position="topleft">
            {mapPreferRefs?.current?.map((mapLayerRef, index) => {
              if (!_.isEmpty(mapLayerRef)) {
                return (
                  <>
                    <Overlay>
                      <LayerGroup ref={mapLayerRef.current.layer}>
                        <TileLayer
                          url=""
                          attribution=''
                        />
                      </LayerGroup>
                    </Overlay>
                  </>
                );
              }
            })}
          </LayersControl>
          {/* <LayersControl position="bottomleft">{renderParamLayer(params)}</LayersControl> */}
          <LayersControl position="topleft">{renderParamLayer(params)}</LayersControl>

          {compareParamLegendData && compareParamLegendData.length ? (
            <LayersControl position="topleft">
              {compareParamLegendData.map((item) => {
                return (
                  <>
                    {item?.descret_legend ? (
                      <DiscreteLegend map={map} add={item.add} layer={item} position="bottomleft" />
                    ) : item?.id !== 'SAR' && item?.id !== 'SAR RGB' ? (
                      <CustomLegend map={map} add={item.add} layer={item} activeTabSwitch={activeTabSwitch} position="bottomleft" />
                    ) : (
                      ''
                    )}
                  </>
                );
              })}
            </LayersControl>
          ) : (
            ''
          )}
          {selectedParamLegendData && selectedParamLegendData.length ? (
            
            <LayersControl position="topleft">
              {selectedParamLegendData.map((item) => {
                return (
                  <>
                    {item?.descret_legend ? (
                      <DiscreteLegend
                        map={map}
                        add={item.add}
                        layer={item}
                        // position="bottomright"
                        position="bottomright"
                      />
                    ) : item?.id !== 'SAR' && item?.id !== 'SAR RGB' ? (
                      <CustomLegend map={map} add={item.add} layer={item} activeTabSwitch={activeTabSwitch} position="bottomright" />
                    ) : (
                      ''
                    )}
                  </>
                );
              })}
            </LayersControl>
          ) : (
            ''
          )}
          <LayersControl position="topleft">
            {selectedMapPreferLegendData.length
              ? selectedMapPreferLegendData.map((item) => {
                  return (
                    <>
                      {item?.descret_legend ? (
                        <DiscreteLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomleft"
                        />
                      ) : (
                        <CustomLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      )}
                    </>
                  );
                })
              : ''}
          </LayersControl>

          <LayersControl position="topleft">
            <div className="logoContainer1">
              <Button className="map-preferences-button" onClick={handleMapModel}>
                <img src={mapPreferenceIcon} alt="map preference" width={15} />
              </Button>
            </div>
          </LayersControl>
        </MapContainer>
      </>
    );
  };

  return (
    // <>
    <>
      {/* <Row>
        <Col lg={isHideBar ? 12 : 10} className="right-map map-position"> */}
      <div
        style={{
          height: '79.4vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* {isLoading && <Loader />} */}

        <>
          <SplitterLayout
            ref={splitterRef}
            primaryIndex={0}
            vertical
            percentage
            primaryMinSize="30%"
            secondaryInitialSize="70%"
          >
            <div style={{ height: '100%' }}>{renderMapContainer()}</div>
            {renderChartsUI()}
          </SplitterLayout>
        </>
        {getBoundariesData?.length && (
        <MapPreferences
          isToggle={isOpenMapModel}
          handleToggle={handleMapModel}
          loc={selectedLocation?.country?.name}
          cropWiseProRenderState={cropWiseProRenderState}
          regionId={selectedRegionId}
          isMapPerferGeoJsonLoading={mapPreferenceGeoJsonloading}
          getMapPreferencesData={getMapPreferencesData}
          getMapPreferencesGeojsonData={getMapPreferencesGeojsonData}
          geoJsonData={geoJsonData}
          mapPreferencesAllData={mapPreferencesData}
          fromContingencyPlan={false}
          cropHealthMonitorStateData={cropHealthMonitorStateData}
          selectedParamLegendData={selectedParamLegendData}
          fromCropHealthMonitor={true}
          countryData={selectedLocation?.country}
          staticData={selectedLocation?.state}
          districtData={selectedLocation?.district}
          cordinateAndZoom={cordinateAndZoom}
          activeTab={activeTab}
          loading={loading}
          setLoading={setLoading}
          modal={modal}
          setModal={setModal}
          geoJsonLayersData={geoJsonLayersData}
          selectedDistrict={selectedDistrict}
        />
      )}
      </div>
      {/* </Col>
      </Row> */}
    </>
  );
};

export default Map;
