import DROUGHT_CONST from './droughtMonitorConsts';

const DroughtMonitor = (state = {}, action) => {
    switch(action.type) {
        case DROUGHT_CONST.GET_DROUGHT_MAP_REQUEST: 
            return {
                ...state,
                isGetDroughtMapDataRequesting: true,
                getDroughtMapData: false,
                getDroughtMapDataError: false,
                getDroughtMapDataNetworkError: false,
            }
        case DROUGHT_CONST.GET_DROUGHT_MAP_SUCCESS:
            return { 
                ...state,
                isGetDroughtMapDataRequesting: false,
                getDroughtMapData: action.payload ? action.payload : '',
                getDroughtMapDataError: false,
                getDroughtMapDataNetworkError: false,
            }
        case DROUGHT_CONST.GET_DROUGHT_MAP_FAILED: 
            return {
                ...state,
                isGetDroughtMapDataRequesting: false,
                getDroughtMapData: false,
                getDroughtMapDataError:  action.payload ? action.payload : false,
                getDroughtMapDataNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
            }
        case DROUGHT_CONST.GET_DROUGHT_TIMESERIES_REQUEST:
            return {
                ...state,
                isGetDroughtTimeseriesRequesting: true,
                getDroughtTimeseriesData: false,
                getDroughtTimeseriesDataError: false,
                getDroughtTimeseriesDataNetworkError: false,
            }
        case DROUGHT_CONST.GET_DROUGHT_TIMESERIES_SUCCESS: 
            return { 
                ...state,
                isGetDroughtTimeseriesRequesting: false,
                getDroughtTimeseriesData: action.payload.response,
                getDroughtTimeseriesDataError: false,
                getDroughtTimeseriesDataNetworkError: false,
            }
        case DROUGHT_CONST.GET_DROUGHT_TIMESERIES_FAILED: 
            return {
                ...state,
                isGetDroughtTimeseriesRequesting: false,
                getDroughtTimeseriesData: false,
                getDroughtTimeseriesDataError: action.payload ? action.payload : false,
                getDroughtTimeseriesDataNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
            }
        default: 
          return state;
    }
};

export default DroughtMonitor;