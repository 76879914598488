import {
  FIELD_DATA_CONST,
  // FIELD_DATA_BY_ID_CONST  ,
  // SUBMIT_CONFIGURATION_CONST ,
} from './manageFieldDataConst';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { FIELD_DATA_BY_ID_CONST, INSURANCE_COMPANY_LIST_CONST } from './manageFieldDataConst';
import {
  fieldDataListSuccess,
  fieldDataListFailed,
  fieldDataByIdFailed,
  fieldDataByIdSuccess,
  insuranceCompanyListSuccess,
  insuranceCompanyListFailed,
  submitConfigurationSuccess,
  componentsListSuccess,
  componentsListFailed,
  componentsByIdSuccess,
  componentsByIdFailed,
  submitConfigurationFailed,
  inviteConfigurationSuccess,
  inviteConfigurationFailed,
  manageSurveyorsListSuccess,
  manageSurveyorsListFailed,
  deleteSurveyorsSuccess,
  deleteSurveyorsFailed,
  surveyConfigurationSuccess,
  surveyConfigurationFailed,
} from './manageFieldDataActions';
import { USER_URL, AXIOS_INSTANCE, PERMISSIONS_URL, RISK_ANALYTICS,ODK_URL} from '../api/apiEndPoint';
import baseMethod from '../baseService.js/index.js';
import { checkHttpStatus } from '../api/apiUtils';

// const getFieldDataRequest = async (payload) => {
//   let response = {};
//   try {
//     const responseData = await AXIOS_INSTANCE.get(`${RISK_ANALYTICS}/odk/getFormData` , payload);
//     response = {
//       data: responseData.data,
//       status: 200,
//     };
//   } catch (error) {
//     const data = error;
//     response = {
//       error: data,
//       status: 400,
//     };
//   }
//   return response;
// };

const getFieldDataRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${ODK_URL}/getFieldData`, payload);
    response = {
      data: data.data,
      status: 200,
      id:payload?.id
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
      id:payload?.id
    };
  }
  return response;
};

function* manageFieldListRequest(action) {
  try {
    const apiResponse = yield call(getFieldDataRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
          id:apiResponse.id
        },
      };
      yield put(fieldDataListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
          id:apiResponse.id
        },
      };
      yield put(fieldDataListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(fieldDataListFailed(errorData));
  }
}

// const fieldDataByIdRequest = async (payload) => {
//   let response = {};
//   try {
//     const data = await AXIOS_INSTANCE.post(`${RISK_ANALYTICS}/odk/getFormData`, payload);
//     response = {
//       data: data.data,
//       status: 200,
//     };
//   } catch (error) {
//     const data = error;
//     response = {
//       error: data,
//       status: 400,
//     };
//   }
//   return response;
// };

// function* manageFieldDataByIdRequest(action) {
//   try {
//     const apiResponse = yield call(fieldDataByIdRequest, action.payload);
//     if (apiResponse.status === 200) {
//       const responseData = {
//         response: {
//           statusCode: 200,
//           data: apiResponse.data,
//         },
//       };
//       yield put(fieldDataByIdSuccess(responseData));
//     } else {
//       const responseData = {
//         response: {
//           statusCode: 200,
//           data: apiResponse.error,
//         },
//       };
//       yield put(fieldDataByIdFailed(responseData));
//     }
//   } catch (error) {
//     const errorData = {
//       error: {
//         statusText: error,
//         netWorkError: true,
//       },
//     };
//     yield put(fieldDataByIdFailed(errorData));
//   }
// }

const getInsuranceCompanyListRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${ODK_URL}/insuranceCompanies`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* insuranceCompanyListRequest(action) {
  try {
    const apiResponse = yield call(getInsuranceCompanyListRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(insuranceCompanyListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(insuranceCompanyListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(insuranceCompanyListFailed(errorData));
  }
}

///

const submitConfigurationRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${ODK_URL}/uploadOdkXmlFile`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

// function* submitConfigurationDataRequest(action) {
//   try {
//     const apiResponse = yield call(submitConfigurationRequest, action.payload);
//     if (apiResponse.status === 200) {
//       const responseData = {
//         response: {
//           statusCode: 200,
//           data: apiResponse.data,
//         },
//       };
//       yield put(submitConfigurationSuccess(responseData));
//     } else {
//       const responseData = {
//         response: {
//           statusCode: 200,
//           data: apiResponse.error,
//         },
//       };
//       yield put(submitConfigurationFailed(responseData));
//     }
//   } catch (error) {
//     const errorData = {
//       error: {
//         statusText: error,
//         netWorkError: true,
//       },
//     };
//     yield put(submitConfigurationFailed(errorData));
//   }
// }

function* submitConfigurationDataRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${ODK_URL}/uploadSurveyData`, action.payload),
      '',
      true,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(submitConfigurationSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(submitConfigurationFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(submitConfigurationFailed(errorData));
  }
}

function* surveyConfigurationRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${ODK_URL}/surveyConfiguration`, action.payload),
      '',
      false,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(surveyConfigurationSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(surveyConfigurationFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(surveyConfigurationFailed(errorData));
  }
}

const inviteConfigurationRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${ODK_URL}/inviteSurveyor`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* inviteConfigurationDataRequest(action) {
  try {
    const apiResponse = yield call(inviteConfigurationRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(inviteConfigurationSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(inviteConfigurationFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(inviteConfigurationFailed(errorData));
  }
}

const componentsListRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${ODK_URL}/surveyComponentList`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* componentsListDataRequest(action) {
  try {
    const apiResponse = yield call(componentsListRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(componentsListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(componentsListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(componentsListFailed(errorData));
  }
}

const componentsByIdRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${ODK_URL}/getSurveyComponentDetails`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* componentsByIdDataRequest(action) {
  try {
    const apiResponse = yield call(componentsByIdRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(componentsByIdSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(componentsByIdFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(componentsByIdFailed(errorData));
  }
}

const manageSurveyorsListRequest = async (action) => {
  
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${ODK_URL}/inviteSurveyorList?page=${action.payload.page}&limit=${action.payload.limit}`, action);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* manageSurveyorsDataRequest(action) {
  try {
    // const apiResponse = yield call(manageSurveyorsListRequest, action.payload);
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
       action.payload.email ? `${ODK_URL}/inviteSurveyorList?page=${action.payload.page}&limit=${action.payload.limit}&email=${action.payload.email}`: `${ODK_URL}/inviteSurveyorList?page=${action.payload.page}&limit=${action.payload.limit}`,
        action.payload,
      ),
      '',
      true,
      // true,
      false
    );
    const response = yield call(checkHttpStatus, apiResponse);

    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(manageSurveyorsListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(manageSurveyorsListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(manageSurveyorsListFailed(errorData));
  }
}

function* deleteSurveyorsRequest(action) {
  try {
    const apiResponse = baseMethod(
      // AXIOS_INSTANCE.post(`${ODK_URL}/deleteSurveyor`, action.payload),
      AXIOS_INSTANCE.delete(`${ODK_URL}/deleteSurveyor/${action.payload.email_id}`),
      '',
      true,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(deleteSurveyorsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(deleteSurveyorsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteSurveyorsFailed(errorData));
  }
}

// const deleteSurveyorsRequest = async (payload) => {
//   let response = {};
//   try {
//     // const data = await AXIOS_INSTANCE.get(`${USER_URL}/odk/inviteSurveyorList`, payload);
//     const data = await AXIOS_INSTANCE.delete(`${USER_URL}/odk/deleteSurveyor`, payload);
//     response = {
//       data: data.data,
//       status: 200,
//     };
//   } catch (error) {
//     const data = error;
//     response = {
//       error: data,
//       status: 400,
//     };
//   }
//   return response;
// };

// function* deleteSuveyorsDataRequest(action) {
//   try {
//     const apiResponse = yield call(deleteSurveyorsRequest, action.payload);
//     if (apiResponse.status === 200) {
//       const responseData = {
//         response: {
//           statusCode: 200,
//           data: apiResponse.data,
//         },
//       };
//       yield put(deleteSurveyorsSuccess(responseData));
//     } else {
//       const responseData = {
//         response: {
//           statusCode: 200,
//           data: apiResponse.error,
//         },
//       };
//       yield put(deleteSurveyorsFailed(responseData));
//     }
//   } catch (error) {
//     const errorData = {
//       error: {
//         statusText: error,
//         netWorkError: true,
//       },
//     };
//     yield put(deleteSurveyorsFailed(errorData));
//   }
// }

///

export function* watchFieldDataList() {
  yield takeEvery(FIELD_DATA_CONST.FIELD_DATA_LIST_REQUEST, manageFieldListRequest);
  // yield takeEvery(FIELD_DATA_CONST.FIELD_DATA_BY_ID_REQUEST, manageFieldDataByIdRequest);
  yield takeEvery(FIELD_DATA_CONST.SUBMIT_CONFIGURATION_REQUEST, submitConfigurationDataRequest);
  yield takeEvery(FIELD_DATA_CONST.INVITE_CONFIGURATION_REQUEST, inviteConfigurationDataRequest);
  yield takeEvery(FIELD_DATA_CONST.COMPONENTS_LIST_REQUEST, componentsListDataRequest);
  yield takeEvery(FIELD_DATA_CONST.COMPONENT_BY_ID_REQUEST, componentsByIdDataRequest);
  yield takeEvery(FIELD_DATA_CONST.INSURANCE_COMPANY_LIST_REQUEST, insuranceCompanyListRequest);

  yield takeEvery(FIELD_DATA_CONST.MANAGE_SURVEYORS_LIST_REQUEST, manageSurveyorsDataRequest);
  yield takeEvery(FIELD_DATA_CONST.DELETE_SURVEYORS_REQUEST, deleteSurveyorsRequest);
  yield takeEvery(FIELD_DATA_CONST.SURVEY_CONFIGURATION_REQUEST, surveyConfigurationRequest)
}

function* fieldDataListSaga() {
  yield all([fork(watchFieldDataList)]);
}

export default fieldDataListSaga;
