import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Collapse, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import CloseIcon from '../../assets/Images/icons/close.png';
import UpIcon from '../../assets/Images/icons/up.png';
import DownIcon from '../../assets/Images/icons/down.png';
import Button from 'reactstrap-button-loader';
import Select from 'react-select';
import { Field, reduxForm, change, formValues, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { t } from 'react-multi-lang';
import { FORECAST_LEAD_TIME_LIST } from '../../Components/constants/index';
import { renderTextField, renderDateField } from '../../Components/common/renderInputField';
import { getIRIAvailableDatesDataRequest } from '../../redux/actions';

const validate = (values) => {
  return {};
};

const RightSideBar = (props) => {
  const nextProps = useSelector((state) => ({
    ForeCastSourcesData: state.Weather.weatherForecastSourcesData,
    StaticResourceData: state.staticResource.staticResourceData || null,
    isStaticResourceRequesting: state.staticResource.isStaticResourceRequesting,
  }));

  const dispatch = useDispatch();
  const {
    closed,
    handleClose,
    filterDataSources,
    handleClearFilterInitialValues,
    initialValues,
    formFilterData,
    handleFilterFormData,
    handleSubmit,
    reset,
    closeRightBar,
    handleFilter,
    noRecordsErrorHandler,
    updateInitialValues,
    staticResourceList,
    setStaticResourcesList,
    handleResetInitializeData,
  } = props;

  const { Weather } = useSelector((state) => ({
    Weather: state.Weather,
  }));

  const [isDataSourceOpen, setIsDataSourceOpen] = useState(false);
  const [isParametersOpen, setIsParametersOpen] = useState(false);
  const [isDataRangeOpen, setIsDataRangeOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isIRISelected, setIsIRISelected] = useState(false);
  const [forecastAvailableDates, setForecastAvailableDates] = useState([]);
  const [IRIAvailableYearList, setIRIAvailableYearList] = useState([]);
  const [endIRIAvailableYearList, setEndIRIAvailableYearList] = useState([]);
  const [IRIAvailableMonthList, setIRIAvailableMonthList] = useState([]);
  const [endIRIAvailableMonthList, setEndIRIAvailableMonthList] = useState([]);
  const [IRIAvailableLeadTimeList, setIRIAvailableLeadTimeList] = useState([]);
  const [IRIForecastTime, setIRIForecastTime] = useState({
    year: null,
    month: null,
    lead_time: null,
  });
  const [endIRIForecastTime, setEndIRIForecastTime] = useState({
    year: null,
    month: null,
    lead_time: null,
  });
  const [invalidYear, setInvalidyear] = useState(false);
  const [invalidMonth, setInvalidMonth] = useState(false);
  const [emptyIriFields, setEmptyIriFields] = useState(false);
  const [isDataSourceSelected, setIsDataSourceSelected] = useState(false);

  const handleForecastLeadMonthFormat = (a) => {
    let b;
    FORECAST_LEAD_TIME_LIST.map((i) => {
      if (parseInt(i?.value) === parseInt(a?.category)) {
        b = {
          name: i?.name,
          value: i?.name,
          label: i?.name,
          id: i?.id,
          data: a?.data,
        };
      }
    });
    return b;
  };

  const handleForecastLeadTimeFormat = (a) => {
    let latest_list;
    let list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    let option_list = list?.splice(parseInt(a?.forecast_time_month), 4);
    let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    let new_arr = new_list?.splice(parseInt(0), 4 - option_list.length);
    latest_list = option_list.concat(new_arr);
    latest_list?.map((i, index) => {
      i.name = i.name + '(' + `${index + 1} Month` + ')';
    });
    return latest_list[parseInt(a?.forecast_lead_time) - 1];
  };

  useEffect(() => {
    if (isIRISelected) dispatch(getIRIAvailableDatesDataRequest());
  }, [isIRISelected, dispatch]);

  useEffect(() => {
    if (Weather.iriAvailableDatesData) {
      const {
        data: { result },
      } = Weather.iriAvailableDatesData;
      if (result) {
        setForecastAvailableDates(result);
      }
    }
  }, [Weather.iriAvailableDatesData]);

  useEffect(() => {
    if (!_.isEmpty(forecastAvailableDates)) {
      let list = [];
      forecastAvailableDates?.map((i) => {
        list.push({
          id: i?.category,
          name: i?.category,
          value: i?.category,
          label: i?.category,
          data: i?.data,
        });
      });
      setIRIAvailableYearList(list);
      setEndIRIAvailableYearList(list);
    }
  }, [forecastAvailableDates]);

  useEffect(() => {
    if (!_.isEmpty(IRIForecastTime.year)) {
      let data;
      let list = [];
      IRIAvailableYearList?.map((i) => {
        if (i?.value === IRIForecastTime?.year?.value) {
          data = i?.data;
        }
      });
      data?.map((a) => {
        list.push(handleForecastLeadMonthFormat(a));
      });
      setIRIAvailableMonthList(list);
    }
  }, [IRIForecastTime.year]);
  useEffect(() => {
    if (!_.isEmpty(endIRIForecastTime.year)) {
      let data;
      let list = [];
      endIRIAvailableYearList?.map((i) => {
        if (i?.value === endIRIForecastTime?.year?.value) {
          data = i?.data;
        }
      });
      data?.map((a) => {
        list.push(handleForecastLeadMonthFormat(a));
      });
      setEndIRIAvailableMonthList(list);
    }
  }, [endIRIForecastTime.year]);

  useEffect(() => {
    if (!_.isEmpty(IRIForecastTime.month)) {
      let data;
      let list = [];
      IRIAvailableMonthList.map((i) => {
        if (i?.value === IRIForecastTime.month?.value) {
          data = i?.data;
        }
      });
      data?.map((a) => {
        list.push(handleForecastLeadTimeFormat(a));
      });
      setIRIAvailableLeadTimeList(list);
    }
  }, [IRIForecastTime.month]);

  useEffect(() => {
    if (nextProps.StaticResourceData) {
      const {
        data: { result },
      } = nextProps.StaticResourceData;
      if (result.length <= 0) {
        noRecordsErrorHandler();
      }
    }
  }, [nextProps.StaticResourceData]);

  useEffect(() => {
    if (closed) {
      handleClearFilters();
    }
  }, [closed]);

  useEffect(() => {
    if (formFilterData) {
      if (formFilterData?.weather_forecast_source_id?.length !== 0) {
        setIsDataSourceOpen(true);
      }
      if (formFilterData?.weather_forecast_parameter_id?.length !== 0) {
        setIsParametersOpen(true);
      }
    }
  }, [formFilterData]);
  useEffect(() => {
    if (!closed) {
      if (filterDataSources) {
        const { data } = filterDataSources;
        if (data?.result) {
          let categories = [];
          let paramArr = [];
          let filtered = [];
          data?.result.map((i) => {
            if (i.category !== 'Weather Forecast') {
              i.name = i.category;
              {
                i.data.map((item) => {
                  i.id = item?.weather_forecast_source_id;
                  item.checked = false;
                });
              }
              categories.push(i);
            }
            if (i.category === 'Seasonal' || i.category === 'Sub - Seasonal') {
              let params =
                i.data[0]?.weather_forecast_source?.weather_forecast_source_with_parameter;
              if (params?.length) {
                i.data?.map((item) => {
                  if (item?.weather_forecast_source?.name !== 'ERPAS') {
                    item?.weather_forecast_source?.weather_forecast_source_with_parameter.map(
                      (each) => {
                        each?.weather_forecast_parameter?.map((p) => {
                          let x = { ...p };
                          x.name = p.weather_forecast_parameter.name;
                          x.id = p.weather_forecast_parameter_id;
                          x.slug = p.weather_forecast_parameter.slug;
                          x.checked = false;
                          paramArr.push(x);
                        });
                      },
                    );
                  }
                });
                if (paramArr?.length) {
                  const ids = paramArr.map((o) => o.id);
                  filtered = paramArr.filter(({ id }, index) => !ids.includes(id, index + 1));
                }
              }
              updateInitialValues({
                dataSourceData: categories,
                parameterData: [],
                start_date: '',
                end_date: '',
              });
            }
          });
        }
      }
    }
  }, [filterDataSources]);

  useEffect(() => {
    if (IRIForecastTime?.year && IRIForecastTime.month) {
      formFilterData.iri_start_date = IRIForecastTime?.year.label + '-' + IRIForecastTime?.month.id;
      handleFilterFormData(formFilterData);
    } else {
      formFilterData.iri_start_date = '';
      handleFilterFormData(formFilterData);
    }
  }, [IRIForecastTime]);

  useEffect(() => {
    if (endIRIForecastTime?.year && endIRIForecastTime.month) {
      formFilterData.iri_end_date =
        endIRIForecastTime?.year.label + '-' + endIRIForecastTime?.month.id;
      handleFilterFormData(formFilterData);
    } else {
      formFilterData.iri_end_date = '';
      handleFilterFormData(formFilterData);
    }
  }, [endIRIForecastTime]);

  useEffect(() => {
    if (Number(endIRIForecastTime?.year?.label) < Number(IRIForecastTime?.year?.label)) {
      setInvalidyear(true);
    } else {
      setInvalidyear(false);
    }
  }, [
    endIRIForecastTime?.year,
    IRIForecastTime?.year,
    endIRIForecastTime?.month,
    IRIForecastTime?.month,
  ]);

  useEffect(() => {
    if (Number(endIRIForecastTime?.year?.label) == Number(IRIForecastTime?.year?.label)) {
      if (Number(IRIForecastTime?.month?.id) >= Number(endIRIForecastTime?.month?.id)) {
        setInvalidMonth(true);
      } else {
        setInvalidMonth(false);
      }
    } else {
      setInvalidMonth(false);
    }
  }, [
    endIRIForecastTime?.month,
    IRIForecastTime?.month,
    endIRIForecastTime?.year,
    IRIForecastTime?.year,
  ]);

  useEffect(() => {
    if (
      endIRIForecastTime?.year?.label ||
      IRIForecastTime?.year?.label ||
      endIRIForecastTime?.month?.label ||
      IRIForecastTime?.month?.label
    ) {
      if (
        endIRIForecastTime?.year?.label &&
        IRIForecastTime?.year?.label &&
        endIRIForecastTime?.month?.label &&
        IRIForecastTime?.month?.label
      ) {
        setEmptyIriFields(false);
      } else {
        setEmptyIriFields(true);
      }
    } else {
      setEmptyIriFields(false);
    }
  }, [endIRIForecastTime, IRIForecastTime]);

  const handleChangeParameters = (e, id) => {
    let a = formFilterData.weather_forecast_parameter_id;
    initialValues.parameterData.map((item) => {
      if (item.id === id) {
        if (a.includes(id)) {
          item.checked = false;
          formFilterData.weather_forecast_parameter_id = a.filter((i) => i !== id);
          handleFilterFormData(formFilterData, item);
          if (a.length < 0) {
            setSubmitDisabled(true);
          }
        } else {
          item.checked = true;
          a.push(item.id);
          formFilterData.weather_forecast_parameter_id = a;
          handleFilterFormData(formFilterData, item);
          setSubmitDisabled(false);
        }
      }
    });
  };

  //setting parameters based on selected data source
  const handleSetParameters = (dataSourceName) => {
    if (filterDataSources) {
      const { data } = filterDataSources;
      if (data?.result) {
        let paramArr = [];
        let filtered = [];
        data?.result.map((i) => {
          if (i.category === 'Seasonal' || i.category === 'Sub - Seasonal') {
            let params = i.data[0]?.weather_forecast_source?.weather_forecast_source_with_parameter;
            if (params?.length) {
              i.data?.map((item) => {
                if (item?.weather_forecast_source?.name === dataSourceName) {
                  item?.weather_forecast_source?.weather_forecast_source_with_parameter.map(
                    (each) => {
                      each?.weather_forecast_parameter?.map((p) => {
                        let x = { ...p };
                        x.name = p.weather_forecast_parameter.name;
                        x.id = p.weather_forecast_parameter_id;
                        x.slug = p.weather_forecast_parameter.slug;
                        x.checked = false;
                        paramArr.push(x);
                      });
                    },
                  );
                }
              });
              if (paramArr?.length) {
                const ids = paramArr.map((o) => o.id);
                filtered = paramArr.filter(({ id }, index) => !ids.includes(id, index + 1));
              }
            }
            updateInitialValues({
              ...initialValues,
              parameterData: filtered,
              start_date: '',
              end_date: '',
            });
          }
        });
      }
    }
  };

  const handleDataSourceChange = (e, id, dataSourceName) => {
    setIsDataSourceSelected(true);
    handleSetParameters(dataSourceName);
    if (id === 'e2f886a7-3299-4c82-af2e-123fe92faf84') {
      setIsIRISelected(true);
    } else {
      formFilterData.iri_start_date = '';
      formFilterData.iri_end_date = '';
      handleFilterFormData(formFilterData);
      setIsIRISelected(false);
      setIRIForecastTime({
        year: null,
        month: null,
        lead_time: null,
      });
      setEndIRIForecastTime({
        year: null,
        month: null,
        lead_time: null,
      });
    }
    let a = formFilterData.weather_forecast_source_id;
    initialValues.dataSourceData.forEach((i) => {
      i.data.forEach((item) => {
        // if (item.weather_forecast_source.id === id && item?.checked) {
        //   console.log('if')
        //   if (a?.includes(id)) {
        //     item.checked = false;
        //     formFilterData.weather_forecast_source_id = a.filter(i => i !== id);
        //     handleFilterFormData(formFilterData, i);
        //     if (a.length < 0) {
        //       setSubmitDisabled(true);
        //     }
        //   }
        //   else {
        //     console.log('else')
        //     item.checked = true;
        //     a?.push(item.weather_forecast_source.id);
        //     formFilterData.weather_forecast_source_id = a;
        //     handleFilterFormData(formFilterData, i);
        //     setSubmitDisabled(false);
        //   }
        // }
        if (item.weather_forecast_source.id === id && item?.checked) {
          //item.checked = false;
          // formFilterData.weather_forecast_source_id = a.filter((i) => i !== id);
          // handleFilterFormData(formFilterData, i);
          if (a.length < 0) {
            setSubmitDisabled(true);
          }
        } else if (item.weather_forecast_source.id === id) {
          item.checked = true;
          a = [];
          a?.push(item.weather_forecast_source.id);
          formFilterData.weather_forecast_source_id = a;
          handleFilterFormData(formFilterData, i);
          setSubmitDisabled(false);
        } else {
          item.checked = false;
        }
      });
    });
  };

  const onFormSubmit = (formProps) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    handleFilter(formFilterData);
  };
  const handleStartDate = (e) => {
    formFilterData.start_date = e.target.value;
    updateInitialValues({
      ...initialValues,
      start_date: e.target.value !== '' ? e.target.value : null,
    });
    handleFilterFormData(formFilterData);
    setSubmitDisabled(false);
  };

  const handleEndDate = (e) => {
    formFilterData.end_date = e.target.value;
    updateInitialValues({
      ...initialValues,
      end_date: e.target.value !== '' ? e.target.value : null,
    });
    handleFilterFormData(formFilterData);
    setSubmitDisabled(false);
  };
  const handleClearFilters = (e) => {
    setIRIForecastTime({
      year: null,
      month: null,
      lead_time: null,
    })
    setEndIRIForecastTime({
      year: null,
      month: null,
      lead_time: null,
    })
    reset('filter-form');
    dispatch(change('filter-form', 'start_date', ''));
    dispatch(change('filter-form', 'end_date', ''));
    let updatedInitialValues = JSON.parse(JSON.stringify(initialValues));
    let updatedParamValues = JSON.parse(JSON.stringify(initialValues));
    if (initialValues.dataSourceData) {
      updatedInitialValues = initialValues.dataSourceData.map((i) => {
        let x = JSON.parse(JSON.stringify(i));
        x.data = i.data.map((item) => {
          item.checked = false;
          return item;
        });
        return x;
      });
      handleFilter({
        weather_forecast_source_id: [],
        weather_forecast_parameter_id: [],
        start_date: null,
        end_date: null,
        limit: 0,
      });
      window.scrollTo({ top: 0, behavior: 'auto' });
    }
    if (initialValues.parameterData) {
      updatedParamValues = initialValues.parameterData.map((i) => {
        let x = JSON.parse(JSON.stringify(i));
        x.checked = false;
        return x;
      });
    }
    setIsDataRangeOpen(false);
    setIsParametersOpen(false);
    setIsDataSourceOpen(false);
    handleClearFilterInitialValues(updatedInitialValues, updatedParamValues);
    setIsDataSourceSelected(false);
    updateInitialValues({
      ...initialValues,
      parameterData: [],
      start_date: '',
      end_date: '',
    });
  };

  const renderAPIParameterOptions = (list, action) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        if (action === 'forecast_lead_time_list') {
          params.map((param) => {
            let data = {
              value: param.name,
              label: `${param.name}`,
              id: param.id,
            };
            options.push(data);
            return true;
          });
        } else {
          params.map((param) => {
            options.push(param);
            return true;
          });
        }
        return options;
      }
    }
  };

  const handleForecastTimeYear = (e) => {
    setIRIAvailableMonthList([]);
    setIRIAvailableLeadTimeList([]);
    IRIForecastTime.year = e;
    IRIForecastTime.month = null;
    IRIForecastTime.lead_time = null;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  const handleForecastTimeMonth = (e) => {
    setIRIAvailableLeadTimeList([]);
    IRIForecastTime.month = e;
    IRIForecastTime.lead_time = null;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  const handleEndForecastTimeYear = (e) => {
    setEndIRIAvailableMonthList([]);
    //setIRIAvailableLeadTimeList([]);
    endIRIForecastTime.year = e;
    endIRIForecastTime.month = null;
    //endIRIForecastTime.lead_time = null;
    setEndIRIForecastTime({ ...endIRIForecastTime });
  };

  const handleEndForecastTimeMonth = (e) => {
    //setIRIAvailableLeadTimeList([]);
    endIRIForecastTime.month = e;
    //IRIForecastTime.lead_time = null;
    setEndIRIForecastTime({ ...endIRIForecastTime });
  };

  const handleForecastLeadTimeChange = (e) => {
    IRIForecastTime.lead_time = e;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  return (
    <div className="st-right-sidebar-wrapper">
      <div className="d-flex st-filter-header">
        <h5 className="st-txt-primary">{t('FILTER.FILTER')}</h5>
        <div className="st-close ml-auto">
          <img
            className="st-close-img"
            alt={'not-available'}
            src={CloseIcon}
            onClick={() => {
              handleClose();
              closeRightBar();
            }}
          />
        </div>
      </div>
      <div className="st-acc-bar" onClick={() => setIsDataSourceOpen(!isDataSourceOpen)}>
        <div className="d-flex">
          <h6 className="st-txt-primary">{t('FILTER.DATA_SOURCE')}</h6>
          <div className="st-close ml-auto">
            <img
              className="st-close-img"
              alt={'not-available'}
              src={isDataSourceOpen ? UpIcon : DownIcon}
            />
          </div>
        </div>
      </div>
      <form id="filter-form" onSubmit={handleSubmit(onFormSubmit)}>
        <div>
          <Collapse isOpen={isDataSourceOpen}>
            {initialValues?.dataSourceData?.map((i) => {
              return (
                <div className="st-collapse-padding">
                  <p className="">{i.name}</p>
                  {i.data.map((item) => {
                    return (
                      <>
                        {item?.weather_forecast_source?.name !== 'ERPAS' && (
                          <span className="data-source-checkbox">
                            {/* <Field
                              name={item?.weather_forecast_source?.name}
                              id={item?.weather_forecast_source?.id}
                              onChange={(e) =>
                                handleDataSourceChange(e, item?.weather_forecast_source?.id)
                              }
                              component={'input'}
                              type="checkbox"
                              checked={item.checked}
                            ></Field> */}

                            <Field
                              name="radioButtons"
                              component={'input'}
                              type={'radio'}
                              id={item?.weather_forecast_source?.id}
                              onClick={(e) =>
                                handleDataSourceChange(
                                  e,
                                  item?.weather_forecast_source?.id,
                                  item?.weather_forecast_source?.name,
                                )
                              }
                              value={item?.weather_forecast_source?.id.toString()}
                              checked={item.checked}
                            />
                            <label htmlFor={item?.weather_forecast_source?.id}>
                              {item?.weather_forecast_source?.name}
                            </label>
                          </span>
                        )}
                      </>
                    );
                  })}
                </div>
              );
            })}
          </Collapse>
        </div>
        <br />
        {isDataSourceSelected ? (
          <>
            <div className="st-acc-bar" onClick={() => setIsParametersOpen(!isParametersOpen)}>
              <div className="d-flex">
                <h6 className="st-txt-primary">{t('FILTER.PARAMETERS')}</h6>
                <div className="st-close ml-auto">
                  <img
                    className="st-close-img"
                    alt={'not-available'}
                    src={isParametersOpen ? UpIcon : DownIcon}
                  />
                </div>
              </div>
            </div>
            <div>
              <Collapse isOpen={isParametersOpen}>
                <div className="st-collapse-padding">
                  {/* <p className="">{t('FILTER.SUB_SEASONAL')}</p> */}
                  {initialValues?.parameterData?.map((item) => {
                    return (
                      <>
                        <FormGroup check inline style={{ display: 'block' }}>
                          <Label check>
                            <Field
                              component={'input'}
                              type="checkbox"
                              name={item.name}
                              onChange={(e) => handleChangeParameters(e, item.id)}
                              checked={item.checked}
                            />{' '}
                            {item.name}
                          </Label>
                        </FormGroup>
                      </>
                    );
                  })}
                </div>
              </Collapse>
            </div>
          </>
        ) : (
          ''
        )}

        <br />
        <div className="st-acc-bar" onClick={() => setIsDataRangeOpen(!isDataRangeOpen)}>
          <div className="d-flex">
            <h6 className="st-txt-primary">{t('FILTER.DATE_RANGE')}</h6>
            <div className="st-close ml-auto">
              <img
                className="st-close-img"
                alt={'not-available'}
                src={isDataRangeOpen ? UpIcon : DownIcon}
              />
            </div>
          </div>
        </div>
        <div>
          <Collapse isOpen={isDataRangeOpen}>
            <div className="st-collapse-padding">
              {isIRISelected ? (
                <>
                  <Select
                    className="placeholder-style my-3"
                    id="forecast_category"
                    isMulti={false}
                    onChange={handleForecastTimeYear}
                    options={renderAPIParameterOptions(IRIAvailableYearList)}
                    placeholder="Select Start Forecast Time Year"
                    required={false}
                    type="select"
                    value={IRIForecastTime?.year}
                    invalid={true}
                  />
                  <Select
                    placeholder="Select Start Forecast Time Month"
                    className="placeholder-style my-3"
                    type="select"
                    onChange={handleForecastTimeMonth}
                    required={false}
                    isMulti={false}
                    id="forecast_category"
                    value={IRIForecastTime?.month}
                    options={renderAPIParameterOptions(IRIAvailableMonthList)}
                  />
                  {/* <Select
                    placeholder="Select Forecast Lead Time"
                    className="placeholder-style my-3"
                    type="select"
                    onChange={handleForecastLeadTimeChange}
                    required={false}
                    isMulti={false}
                    value={IRIForecastTime?.lead_time}
                  options={renderAPIParameterOptions(IRIAvailableLeadTimeList , "forecast_lead_time_list")}
                    id="forecast_category"
                  /> */}
                  <span className={invalidYear ? 'select-field-year' : ''}>
                    <Select
                      className="placeholder-style my-3"
                      id="forecast_category"
                      isMulti={false}
                      onChange={handleEndForecastTimeYear}
                      options={renderAPIParameterOptions(endIRIAvailableYearList)}
                      placeholder="Select End Forecast Time Year"
                      required={false}
                      type="select"
                      value={endIRIForecastTime?.year}
                    />
                  </span>
                  {invalidYear && (
                    <FormFeedback invalid className="feedback">
                      {'End year should be greater than or equal to start year.'}
                    </FormFeedback>
                  )}
                  <span className={invalidMonth ? 'select-field-month' : ''}>
                    <Select
                      placeholder="Select End Forecast Time Month"
                      className="placeholder-style my-3"
                      type="select"
                      onChange={handleEndForecastTimeMonth}
                      required={false}
                      isMulti={false}
                      id="forecast_category"
                      value={endIRIForecastTime?.month}
                      options={renderAPIParameterOptions(endIRIAvailableMonthList)}
                    />
                  </span>
                  {invalidMonth && (
                    <FormFeedback invalid className="feedback">
                      {'End month should be greater than start month.'}
                    </FormFeedback>
                  )}
                </>
              ) : (
                <>
                  <FormGroup>
                    <Field
                      component={renderDateField}
                      name="start_date"
                      bsSize="sm"
                      className="mt-1 my-3 input-style filter-select-placeholder"
                      placeholder="Select Start date"
                      type="date"
                      onChange={(e) => handleStartDate(e)}
                      isMulti={false}
                    />
                  </FormGroup>
                  <br />
                  <FormGroup>
                    <Field
                      component={renderDateField}
                      name="end_date"
                      bsSize="sm"
                      className="mt-1 my-3 input-style filter-select-placeholder"
                      placeholder="Select End date"
                      type="date"
                      onChange={(e) => handleEndDate(e)}
                      isMulti={false}
                    ></Field>
                  </FormGroup>
                </>
              )}
            </div>
          </Collapse>
        </div>
        <hr />
        <div className=" filter-buttons">
          <Button
            type="reset"
            className="btn st-btn-custom-ot clear-filter-btn"
            onClick={(e) => handleClearFilters(e)}
          >
            {t('FILTER.CLEAR_FILTERS')}
          </Button>
          <Button
            style={{
              height: '38px',
              width: '115px',
              cursor: 'pointer',
            }}
            className="btn st-btn-custom"
            disabled={submitDisabled || invalidMonth || invalidYear || emptyIriFields}
            // loading={nextProps.isStaticResourceRequesting}
          >
            {t('FILTER.SHOW_RESULT')}
          </Button>
        </div>
        <hr />
        <div style={{ height: '200px' }} />
      </form>
    </div>
  );
};
RightSideBar.propTypes = {
  noRecordsErrorHandler: PropTypes.func,
  handleFilterFormData: PropTypes.func,
  formFilterData: PropTypes.object,
};
export default reduxForm({
  form: 'filter-form',
  validate,
})(RightSideBar);
