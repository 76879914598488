import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Label } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { Field, reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';
import { showSuccess } from '../../Components/Notifications';
import _ from 'lodash';
import {
  deleteStaticResourceSuccess,
  staticResourceAddRequest,
  staticResourceAddSuccess,
  updateStaticResourceRequest,
  updateStaticResourceSuccess,
} from '../../redux/staticResource/staticResourceAction';
import moment from 'moment';
import { t } from 'react-multi-lang';
import { isSuperAdmin } from '../../Components/common/utils';
import {
  renderReactSelectInputField,
  renderTextField,
  renderImageField,
} from '../../Components/common/renderInputField';
import { DISPLAY_DATE_FORMAT, FORECAST_LEAD_TIME_LIST } from '../../Components/constants';

const validate = values => {
  const errors = {};
  if (!values.forecast_category) {
    errors.forecast_category = 'Required';
  }
  if (!values.datasource_type) {
    errors.datasource_type = 'Required';
  }
  if (!values.parameter_type) {
    errors.parameter_type = 'Required';
  }
  if (!values.image) {
    errors.image = 'Required';
  }
  if (!values.start_date) {
    errors.start_date = 'Required';
  }
  if (!values.end_date) {
    errors.end_date = 'Required';
  }
  if (values.start_date && values.end_date && values.start_date >= values.end_date) {
    errors.start_date = 'start date must be  less than end date';
  }
  return errors;
};

const Filter = props => {
  const {
    invalid,
    setPageNumber,
    descriptionHandler,
    handleSubmit,
    pristine,
    reset,
    submitting,
    editClicked,
    dataSources,
    handleCategoryData,
    dataSourceList,
    handleSelectedDataSource,
    handlePreSelectedDataSource,
    parameterList,
    handleParameter,
    forecastYearMonthHandler,
    startDateHandler,
    endDateHandler,
    linkHandler,
    fileHandler,
    handleSelectedTab,
    handleUpdatedListData,
    handleResetInitialValues,
    handleDataSourcesEmptyListChange,
    updatedPermissions,
    setEditClicked,
    isIRISelected,
    forecastLeadTimeHandler,
    handleResetIRIFields,
    isHideBar,
  } = props;

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    category_name: '',
    weather_forecast_source_id: '',
    weather_forecast_parameter_id: '',
    forecast_year_month: '',
    forecast_lead_time: '',
    start_date: '',
    end_date: '',
    source_link: '',
    description: '',
    image: '',
  });
  const [forecastLeadTimeList, setForecastLeadTimeList] = useState([]);
  const [isImgaeUploaded, setIsImgaeUploaded] = useState(false);
  const [file, setFile] = useState();
  const [isPreventAddDispatch, setIsPreventAddDispatch] = useState(true);
  let form = new FormData();

  const nextProps = useSelector(state => ({
    staticResource: state.staticResource || null,
  }));

  const {
    staticResourceAddData,
    isStaticResourceAddRequesting,
    deleteStaticResourceData,
    updateStaticResourceData,
    deleteStaticResourceDataError,
    isUpdateStaticResourceRequesting,
  } = nextProps.staticResource;

  useEffect(() => {
    return () => {
      dispatch(staticResourceAddSuccess({}));
      dispatch(updateStaticResourceSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (props.initialValues) {
      setFormData(props.initialValues);
      dataSources?.map(i => {
        if (i.name === props.initialValues?.forecast_category?.id) {
          handleCategoryData(i.data);
          i.data.map(item => {
            if (item.weather_forecast_source_id === props.initialValues?.datasource_type?.id) {
              handlePreSelectedDataSource(
                item.weather_forecast_source.weather_forecast_source_with_parameter,
                item.weather_forecast_source_id,
              );
            }
          });
        }
      });
      if (props.initialValues.image) {
        fileHandler(props.initialValues.image);
      }
    }
  }, [props.initialValues]);

  useEffect(() => {
    if (staticResourceAddData) {
      const {
        data: { result },
      } = staticResourceAddData;
      if (result) {
        handleResetIRIFields(false);
        showSuccess(result);
        reset();
        dispatch(staticResourceAddSuccess({}))
        // dispatch(change('FilterForm', 'Upload', ''));
        handleSelectedTab();
      }
    }
  }, [staticResourceAddData]);

  useEffect(() => {
    if (formData.forecast_year_month) {
      let month = formData.forecast_year_month.split('-')[1];
      let list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
      let option_list = list?.splice(parseInt(month), 4);
      let latest_list;
      if (month >= 9) {
        let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
        let new_arr = new_list?.splice(parseInt(0), 4 - option_list.length);
        latest_list = option_list.concat(new_arr);
      } else {
        latest_list = option_list;
      }
      latest_list?.map((i, index) => {
        i.name = i.name + '(' + `${index + 1} Month` + ')';
      });
      setForecastLeadTimeList(latest_list);
    }
  }, [formData.forecast_year_month]);

  useEffect(() => {
    setPageNumber(1);
    if (updateStaticResourceData) {
      const {
        data: { result },
      } = updateStaticResourceData;
      if (result) {
        handleResetIRIFields(false);
        setEditClicked(false);
        setIsImgaeUploaded(false);
        showSuccess(result);
        handleSelectedTab();
        handleResetInitialValues();
        dispatch(updateStaticResourceSuccess({}))
      }
    }
  }, [updateStaticResourceData]);

  useEffect(() => {
    if (deleteStaticResourceDataError) {
      handleResetInitialValues();
    }
  }, [deleteStaticResourceDataError]);

  useEffect(() => {
    if (deleteStaticResourceData) {
      const {
        data: { result },
      } = deleteStaticResourceData;
      if (result) {
        showSuccess(result);
        handleUpdatedListData();
       dispatch(deleteStaticResourceSuccess({}))
      }
    }
  }, [deleteStaticResourceData]);

  const renderAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        if (
          action_name !== 'forecast_lead_time' &&
          action_name !== 'parameter_type' &&
          action_name !== 'dataSources' &&
          action_name !== 'categories'
        ) {
          let default_option = { value: 'select', label: 'select', id: 'select' };
          options.push(default_option);
        }
        params.map(param => {
          let data = {
            value: param.name,
            label: `${param.name}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleCategoryChange = e => {
    if (e.value === 'select') {
      handleCategoryData();
      formData.weather_forecast_source_id = '';
      setFormData({ ...formData });
    } else {
      formData.weather_forecast_source_id = e.id;
      setFormData({ ...formData });
      dataSources.map(i => {
        if (i.id === e.id) {
          handleCategoryData(i.data);
        }
      });
    }
    formData.datasource_type = '';
    formData.parameter = '';
    formData.forecast_lead_time = '';
    setFormData({ ...formData });
    handleSelectedDataSource([], e.id);
    dispatch(change('FilterForm', 'datasource_type', ''));
    dispatch(change('FilterForm', 'parameter_type', ''));
    dispatch(change('FilterForm', 'forecast_lead_time', ''));
  };

  const handleDataSourceChange = e => {
    if (e.value === 'select') {
      handleSelectedDataSource([], e.id);
      formData.weather_forecast_source_id = '';
      setFormData({ ...formData });
    } else {
      formData.weather_forecast_source_id = e.id;
      setFormData({ ...formData });
      let parameter_list = [];
      dataSources?.map(i => {
        i.data.map(d => {
          if (d.weather_forecast_source_id === e.id) {
            d.weather_forecast_source.weather_forecast_source_with_parameter.map(i => {
              i.weather_forecast_parameter.map(data => {
                parameter_list.push(data);
              });
            });
            // if(e.id === "e2f886a7-3299-4c82-af2e-123fe92faf84"){
            //   handleSelectedDataSource([], e.id)
            // }
            // else{
            handleSelectedDataSource(parameter_list, e.id);
            // }
          }
        });
      });
    }
    dispatch(change('FilterForm', 'parameter_type', ''));
    dispatch(change('FilterForm', 'forecast_lead_time', ''));
  };
  const handleParameterChange = e => {
    formData.weather_forecast_parameter_id = e.id;
    setFormData({ ...formData });
    handleParameter(e.id);
  };

  const handleforecastYearMonth = e => {
    formData.forecast_lead_time = null;
    dispatch(change('FilterForm', 'forecast_lead_time', ''));
    formData.forecast_year_month = e.target.value;
    setFormData({ ...formData });
    forecastYearMonthHandler(e.target.value);
  };

  const handleForecastLeadTimeChange = e => {
    let data = {...formData};
    data.forecast_lead_time = e;
    setFormData(data);
    forecastLeadTimeHandler(e);
  };

  const handleStartDate = e => {
    formData.start_date = e.target.value;
    setFormData({ ...formData });
    startDateHandler(moment(e.target.value).format(DISPLAY_DATE_FORMAT));
  };

  const handleEndDate = e => {
    formData.end_date = e.target.value;
    setFormData({ ...formData });
    endDateHandler(moment(e.target.value).format(DISPLAY_DATE_FORMAT));
  };

  const handleLink = e => {
    if (e.target.value === '') {
      formData.source_link = '';
    } else {
      formData.source_link = e.target.value;
    }
    setFormData({ ...formData });
    linkHandler(e.target.value);
  };

  const handleDescription = e => {
    if (e.target.value === '') {
      formData.description = '';
    } else {
      formData.description = e.target.value;
    }
    setFormData({ ...formData });
    descriptionHandler(e.target.value);
  };

  const handleFileChange = e => {
    // setPageNumber(1);
    fileHandler(URL.createObjectURL(e.target.files[0]), e);
    setFile(e.target.files[0]);
    setIsImgaeUploaded(true);
  };

  const onFormSubmit = e => {
    if (isIRISelected) {
      form.append('forecast_time_year', formData?.forecast_year_month?.split('-')[0]);
      form.append('forecast_time_month', formData?.forecast_year_month?.split('-')[1]);
      form.append(
        'forecast_lead_time',
        parseInt(
          formData?.forecast_lead_time?.label?.substr(
            formData?.forecast_lead_time?.label?.indexOf('(') + 1,
            formData?.forecast_lead_time?.label?.indexOf(')') -
              formData?.forecast_lead_time?.label?.indexOf('(') -
              1,
          ),
        ),
      );
      form.append('weather_forecast_parameter_id', formData?.weather_forecast_parameter_id);
      form.append('image', file);
      form.append('weather_forecast_source_id', formData.weather_forecast_source_id);
      if (!formData.source_link) {
        form.append('source_link', '');
      } else {
        form.append('source_link', formData.source_link);
      }
      if (!formData.description) {
        form.append('description', '');
      } else {
        form.append('description', formData.description);
      }
    } else {
      form.append('image', file);
      form.append('weather_forecast_source_id', formData.weather_forecast_source_id);
      form.append('weather_forecast_parameter_id', formData.weather_forecast_parameter_id);
      form.append('start_date', formData.start_date);
      form.append('end_date', formData.end_date);
      if (!formData.source_link) {
        form.append('source_link', '');
      } else {
        form.append('source_link', formData.source_link);
      }
      if (!formData.description) {
        form.append('description', '');
      } else {
        form.append('description', formData.description);
      }
    }
    if (isPreventAddDispatch) {
      dispatch(staticResourceAddRequest(form));
    }
    setIsPreventAddDispatch(true);
  };
  const updateStaticResource = () => {
    if (isIRISelected) {
      form.append('forecast_time_year', formData?.forecast_year_month?.split('-')[0]);
      form.append('forecast_time_month', formData?.forecast_year_month?.split('-')[1]);
      if (formData?.forecast_lead_time) {
        form.append(
          'forecast_lead_time',
          formData?.forecast_lead_time?.id - parseInt(formData?.forecast_year_month?.split('-')[1]),
        );
      }

      if (formData?.weather_forecast_parameter_id) {
        form.append('weather_forecast_parameter_id', formData.weather_forecast_parameter_id);
      }

      form.append('weather_forecast_source_id', formData.weather_forecast_source_id);
      if (!formData.source_link) {
        form.append('source_link', '');
      } else {
        form.append('source_link', formData.source_link);
      }
      if (!formData.description) {
        form.append('description', '');
      } else {
        form.append('description', formData.description);
      }
    } else {
      form.append('weather_forecast_source_id', formData.weather_forecast_source_id);
      if (formData?.weather_forecast_parameter_id) {
        form.append('weather_forecast_parameter_id', formData.weather_forecast_parameter_id);
      } else {
        form.append('weather_forecast_parameter_id', props?.initialValues?.parameter_type?.id);
      }
      form.append('start_date', formData.start_date);
      form.append('end_date', formData.end_date);
      if (!formData.source_link) {
        form.append('source_link', '');
      } else {
        form.append('source_link', formData.source_link);
      }
      if (!formData.description) {
        form.append('description', '');
      } else {
        form.append('description', formData.description);
      }
    }
    form.append('content_id', formData.content_id);
    setIsPreventAddDispatch(false);
    // setFile(null);
    if (isImgaeUploaded) {
      form.append('image', file);
    }
    dispatch(updateStaticResourceRequest(form));
    // handleResetInitialValues();
    // setFile();
  };
  return (
    <>
      {!isHideBar ? (
        <Card className="card-style manage-static-data-add-form">
          <CardBody
            className={
              isSuperAdmin() ||
              updatedPermissions?.includes('add_content') ||
              updatedPermissions?.includes('change_content')
                ? ''
                : 'not-add-static-resource'
            }
          >
            <form
              onSubmit={handleSubmit(onFormSubmit)}
              id={!editClicked ? 'publish_btn' : 'update_btn'}
            >
              <div className="">
                <Field
                  component={renderReactSelectInputField}
                  name="forecast_category"
                  placeholder="Select Category"
                  className="static-resource-filter-field my-3 filter-select-placeholder"
                  type="select"
                  onChange={e => handleCategoryChange(e)}
                  required={true}
                  isMulti={false}
                  options={renderAPIParameterOptions(dataSources, 'categories')}
                  id="forecast_category"
                ></Field>
                <div className="filter-section-one">
                  <Label className="static-resource-filter-field">
                    {t('STATIC_RESOURCE_ADMIN.DATA_SOURCES_PARAMETERS')}
                  </Label>
                  <Field
                    component={renderReactSelectInputField}
                    name="datasource_type"
                    placeholder="Select Data Source"
                    className="my-3 static-resource-filter-field filter-select-placeholder"
                    type="select"
                    onChange={e => handleDataSourceChange(e)}
                    required={true}
                    isMulti={false}
                    options={renderAPIParameterOptions(dataSourceList, 'dataSources')}
                    id="datasource_type"
                  ></Field>
                  {!_.isEmpty(parameterList) && (
                    <Field
                      component={renderReactSelectInputField}
                      placeholder="Select Parameters"
                      name="parameter_type"
                      className="my-3 static-resource-filter-field filter-select-placeholder"
                      type="select"
                      onChange={e => handleParameterChange(e)}
                      required={true}
                      isMulti={false}
                      options={renderAPIParameterOptions(parameterList, 'parameter_type')}
                      id="parameter_type"
                    ></Field>
                  )}
                </div>
                {isIRISelected ? (
                  <>
                    <div className="filter-section-one static-resource-filter-field">
                      <Field
                        component={renderTextField}
                        name="forecast_year_month"
                        bsSize="sm"
                        className="mt-1 my-3 input-style filter-select-placeholder"
                        placeholder="Select Start date"
                        type="month"
                        onChange={e => handleforecastYearMonth(e)}
                        isMulti={false}
                      ></Field>
                      <Field
                        component={renderReactSelectInputField}
                        name="forecast_lead_time"
                        placeholder="Select Forecast Lead Time"
                        className=" my-3 filter-select-placeholder"
                        type="select"
                        onChange={e => handleForecastLeadTimeChange(e)}
                        required={true}
                        isMulti={false}
                        options={renderAPIParameterOptions(
                          forecastLeadTimeList,
                          'forecast_lead_time',
                        )}
                        id="forecast_lead_time"
                      ></Field>
                    </div>
                  </>
                ) : (
                  <div className="filter-section-one static-resource-filter-field">
                    <Label>{t('STATIC_RESOURCE_ADMIN.DATE_RANGE')}</Label>
                    <Field
                      component={renderTextField}
                      name="start_date"
                      bsSize="sm"
                      className="mt-1 my-3 input-style filter-select-placeholder"
                      placeholder="Select Start date"
                      type="date"
                      onChange={e => handleStartDate(e)}
                      isMulti={false}
                      max="2999-12-31"
                    ></Field>
                    <Field
                      component={renderTextField}
                      name="end_date"
                      bsSize="sm"
                      className="mt-1 my-3 input-style filter-select-placeholder"
                      placeholder="Select End date"
                      type="date"
                      onChange={e => handleEndDate(e)}
                      required={true}
                      isMulti={false}
                      max="2999-12-31"
                    ></Field>
                  </div>
                )}
                <div className="filter-section-one static-resource-filter-field">
                  <Label>{t('STATIC_RESOURCE_ADMIN.UPLOAD_CONTENT')}</Label>
                  <Field
                    component={renderTextField}
                    name="source_link"
                    className="mt-1 my-3 filter-select-placeholder"
                    type="textarea"
                    onChange={e => handleLink(e)}
                    required={false}
                    isMulti={false}
                    placeholder="Add a Link"
                  ></Field>
                  <Field
                    component={renderTextField}
                    name="description"
                    className="mt-1 my-3 filter-select-placeholder"
                    type="textarea"
                    onChange={e => handleDescription(e)}
                    required={false}
                    isMulti={false}
                    placeholder="Add Description"
                  ></Field>
                  <Field
                    component={renderImageField}
                    id="image"
                    name="image"
                    type="file"
                    onChange={e => handleFileChange(e)}
                    required={true}
                    isMulti={false}
                    className="mt-1 my-3 filter-select-placeholder"
                    placeholder="Upload image"
                  ></Field>
                </div>
                <div className="publish-button">
                  {!editClicked ? (
                    <Button
                      type="submit"
                      disabled={pristine || submitting || invalid}
                      className="publish-btn"
                      loading={isStaticResourceAddRequesting}
                    >
                      {t('STATIC_RESOURCE_ADMIN.PUBLISH')}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      disabled={pristine || submitting || invalid}
                      className="publish-btn"
                      loading={isUpdateStaticResourceRequesting}
                      onClick={updateStaticResource}
                    >
                      {t('STATIC_RESOURCE_ADMIN.UPDATE')}
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      ) : (
        ''
      )}
    </>
  );
};

Filter.propTypes = {
  dataSources: PropTypes.array,
  handleCategoryData: PropTypes.func,
  dataSourceList: PropTypes.object,
  handleSelectedDataSource: PropTypes.func,
  parameterList: PropTypes.object,
  handleParameter: PropTypes.func,
  startDateHandler: PropTypes.func,
  endDateHandler: PropTypes.func,
  linkHandler: PropTypes.func,
  fileHandler: PropTypes.func,
  publishButtonClicked: PropTypes.bool,
  descriptionHandler: PropTypes.func,
  handleSelectedTab: PropTypes.func,
  handleUpdatedListData: PropTypes.func,
  handleResetInitialValues: PropTypes.func,
};

export default reduxForm({
  form: 'FilterForm',
  enableReinitialize: true,
  validate,
})(Filter);
