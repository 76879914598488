import React, { useEffect, useState } from 'react';

import { Container, Row, Col, FormGroup, Label } from 'reactstrap';

// import RightArrow from '../../assets/Images/back-button.svg';
import RightArrow from '../../assets/Images/back-button.svg';

import { useHistory, useLocation } from 'react-router-dom';

import ImagePlaceHolder from '../../assets/Images/imagePlaceholder.png';
import Image1 from '../../assets/Images/camera.png';

import { useDispatch, useSelector } from 'react-redux';
import { getFieldDataRequest } from '../../redux/manageFieldData/manageFieldDataActions';

import Loader from '../../Components/common/loader';
import { BsArrowLeft } from 'react-icons/bs';
import _ from 'lodash'

const SurveyDetails = () => {
  // return <h1>Survey Details</h1>

  let history = useHistory();
  const paramLocation = useLocation();

  const nextProps = useSelector((state) => ({
    isFieldDataListDataRequesting: state.fieldDataList.isFieldDataListDataRequesting,
    fieldDataListData: state?.fieldDataList?.FieldDataListData,
    loginDetailData: state.Login.loginDetailData || null,
  }));

  const [newRequestList, setNewRequestList] = useState([
    {
      'Farmer Name': 'test',
      'Farmer ID': '12345',
      'Phone No.': '9876543210',
      District: 'test',
      'DS Division': 'test',
      'GN Division': 'test',
      'Surveyor Name': 'test',
      'Status of Insurance': 'test',
    },
  ]);
  const [tableData, setTableData] = useState([]);

  let dispatch = useDispatch();

  useEffect(() => {
    // dispatch(
    //   getFieldDataRequest({
    //     // url: 'https://docs.google.com/spreadsheets/d/1PEhNaXwDJBTSGeNI5k19FxCRZMZFb8aG1HtNzqFVxXg/edit?usp=sharing',
    //     url: 'https://docs.google.com/spreadsheets/d/1UYTpGCbvu7l1XkylHB4AuqKVPBOk2MAGqPxsd-a86i4/edit?usp=sharing',
    //   }),
    // );

    // dispatch(
    //   getFieldDataRequest({
    //     // url: 'https://docs.google.com/spreadsheets/d/14-7qtq5maCsIylAU6F7rDxnKC-6K9qsMCpiLV9pzmzU/edit?usp=sharing',
    //     url: 'https://docs.google.com/spreadsheets/d/14-7qtq5maCsIylAU6F7rDxnKC-6K9qsMCpiLV9pzmzU/edit?usp=sharing',
    //   }),
    // );

    // dispatch(
    //   getFieldDataRequest({
    //     // url: 'https://docs.google.com/spreadsheets/d/1PEhNaXwDJBTSGeNI5k19FxCRZMZFb8aG1HtNzqFVxXg/edit?usp=sharing',
    //     url: 'https://docs.google.com/spreadsheets/d/1UYTpGCbvu7l1XkylHB4AuqKVPBOk2MAGqPxsd-a86i4/edit?usp=sharing',
    //   }),
    // );

    // dispatch(
    //   getFieldDataRequest({
    //     // url: 'https://docs.google.com/spreadsheets/d/1YBD2gRI21H74pLsVAr0Xr52kMgWizwOsgayI7__wP7A/edit?usp=sharing',
    //     year: paramLocation?.state?.selectedDate,
    //     component_id: paramLocation?.state?.id,
    //   }),
    // );
  }, []);

  // useEffect(() => {
  //   if (nextProps.fieldDataListData) {
  //     if (nextProps?.fieldDataListData?.data?.result) {
  //       // setNewRequestList(nextProps?.fieldDataListData?.data?.results);
  //       setNewRequestList(
  //         nextProps?.fieldDataListData?.data?.result?.['farm_odk_data ']?.filter(
  //           (item) => item?.['data-meta-instanceID'] === history.location?.pathname.split('/')[2],
  //         ),
  //       );
  //       // setPageCount(nextProps?.fieldDataListData?.data?.count);
  //       // setRecordsCount(nextProps?.fieldDataListData?.data?.result.length);
  //     } else {
  //       setNewRequestList([]);

  //       // setPageCount(0);
  //       // setRecordsCount(0);
  //     }
  //   } else {
  //     setNewRequestList([]);
  //   }
  // }, [nextProps.fieldDataListData]);

  useEffect(() => {
    if (!_.isEmpty(paramLocation?.state?.newRequestList) && paramLocation?.state?.uuid) {
      
    setNewRequestList(
      paramLocation?.state?.newRequestList.filter(
      (item) => item?.['data-meta-instanceID'] === paramLocation?.state?.uuid,
    ),
  );    } else {
    setNewRequestList([]);
  }}, [paramLocation?.state?.newRequestList]);

  useEffect(() => {
    if (newRequestList) {
      setTableData(
        newRequestList?.map((item, key) => {
          if (item?.['data-meta-instanceID'] === history.location?.pathname.split('/')[2]) {
            return {
              name:
                item?.['data-farmer-informatiom-farmer-name'] !== ' '
                  ? item?.['data-farmer-informatiom-farmer-name']
                    ? item?.['data-farmer-informatiom-farmer-name']
                    : 'N/A'
                  : 'N/A',
              // farmerId:item?.[""]
              phoneNo:
                item?.['data-sub_section-Telephone-No'] !== ' '
                  ? item?.['data-sub_section-Telephone-No']
                    ? item?.['data-sub_section-Telephone-No']
                    : 'N/A'
                  : 'N/A',
              district:
                item?.['data-basic-informations-district'] !== ' ' &&
                item?.['data-basic-informations-district']
                  ? item?.['data-basic-informations-district']
                  : 'N/A',
              dsDivision:
                item?.['data-basic-informations-ds-division'] !== ' '
                  ? item?.['data-basic-informations-ds-division']
                    ? item?.['data-basic-informations-ds-division']
                    : 'N/A'
                  : 'N/A',
              gnDivision:
                item?.['data-basic-informations-gn-division'] !== ' '
                  ? item?.['data-basic-informations-gn-division']
                    ? item?.['data-basic-informations-gn-division']
                    : 'N/A'
                  : 'N/A',
              id:
                item?.['data-meta-instanceID'] !== ' '
                  ? item?.['data-meta-instanceID']
                    ? item?.['data-meta-instanceID']
                    : 'N/A'
                  : 'N/A',
              gender: item?.['data-farmer-informatiom1-Gender-of-farmer'],
              farmerId: item?.['data-basic-informations-farmer-id'],
              houseHoldGeoPoint:
                item?.['data-the-amount-of-insurance-claim-provided-Latitude-of-household'],
              experienceAnyDamage: item?.['data-insurance-claim-information-experience-any-crop-damage-in-last-cropping'],
              didYouReceiveAnyInsurance: item?.['data-insurance-claim-information-untitled20'],
              theAmountOfClaim: item?.['data-insurance-claim-information-what-is-the-amount-of-claim'],
              didNotReceiveCompensation:
                item?.[
                  'data-the-amount-of-insurance-claim-provided-did-not-receive-a-compensation-what-are-the-reasons'
                ],
              whatisyouropinion:
                item?.[
                  'data-the-amount-of-insurance-claim-provided-what-is-your-opinion-on-the-amount-of-insurance-claim'
                ],
              remarks: item?.['data-the-amount-of-insurance-claim-provided-Remarks'],
              insuranceClaimCaused: item?.['data-insurance-claim-information-hazard-caused-the-crop'],

              photo1: item?.['data-farmer-informatiom-NIC-number'],
              photo2: item?.['data-sub-topic-information-Photo-2'],
              photo3: item?.['data-sub-topic-information-Photo-3'],
            };
          } else return;
        }),
      );
    }
  }, [newRequestList]);




  return (
    <Container fluid>
      {nextProps.isFieldDataListDataRequesting && <Loader />}

      <div className="right-img-wrapper api-key-list">
        <Row>
          {/* <Col>
                <h1>Survey Details</h1>
                </Col> */}
          <Col xs="12" sm="12" md="12" lg="12" className="subheader-manage-user">
            {/* <img
              src={RightArrow}
              alt="back-arrow"
              className="manage-user-header-back-button"
              onClick={() => history.push('/manageFieldData?Component5')}
            /> */}

            <span
              style={{
                float: 'left',
                marginLeft: '10px',
                borderRadius: '700px',
                border: '1px solid #ada6a6',
                height: '43px',
                width: '43px',
                fontSize: '25px',
                color: '#595252',
                fontWeight: 'bold',
                marginTop: '2.5%',
              }}
              className="list-tl-st"
            >
              {/* <img
                      src={RightArrow}
                      className="cursor-ponter"
                      onClick={() => history.goBack()}
                      alt='back-bottom'
                    /> */}
              <BsArrowLeft
                className="cursor-ponter"
                //onClick={() => history.goBack()}
                onClick={() => history.push('/manageFieldData?Component5&fromBack=true' + '&date=' + paramLocation?.state?.selectedDate)}
                style={{ margin: 'auto' }}
              />
            </span>

            <h3 className="heading-manage-user manage-user-heading-mt">
              {/* {t('MANAGE_USER.MANAGE_USERS')} */}
              Claim Settlement
            </h3>
          </Col>

          <Col style={{ marginLeft: '4%', marginRight: '4%' }}>
            <form action="/action_page.php">
              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  General Information
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    {/* <Col md={4} style={{ overflowWrap: 'break-word' }}> */}
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        District
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* District */}
                          {tableData[0]?.district!==" " ?tableData[0]?.district ? tableData[0]?.district : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}

                    <br />

                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        DS Division
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* DS Division */}
                          {tableData[0]?.dsDivision!==" " ?tableData[0]?.dsDivision ? tableData[0]?.dsDivision : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}
                  </Col>

                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Farmer ID
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* 0123456789 */}
                          {tableData[0]?.farmerId!==" " && tableData[0]?.farmerId!==undefined ?tableData[0]?.farmerId ? tableData[0]?.farmerId : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    {/* </Col> */}
                    {/* <Col md={4} style={{ overflowWrap: 'break-word' }}> */}
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        GN Division
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* GN Division */}
                          {tableData[0]?.gnDivision!==" " ?tableData[0]?.gnDivision ? tableData[0]?.gnDivision : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}
                  </Col>
                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0' ,fontWeight: 'bold' }}>
                        Household geopoint
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Household geopoint */}
                          {tableData[0]?.houseHoldGeoPoint
                            ? tableData[0]?.houseHoldGeoPoint
                              ? parseFloat(tableData[0]?.houseHoldGeoPoint.split(',')[0]).toFixed(
                                  2,
                                ) +
                                ',' +
                                parseFloat(tableData[0]?.houseHoldGeoPoint.split(',')[1]).toFixed(2)
                              : ''
                            : <br/>}
                        </span>
                      </div>
                    </FormGroup>

                    {/* <br/>
                      <FormGroup style={{marginLeft:"17px"}}>
                        <Label className="label" style={{color:"#7C8EA0" , fontWeight:"bold"}}>Farmer Number</Label>
                        <div>
                          <span style={{fontWeight:"bold"}}>0987</span>
                        </div>
                      </FormGroup> */}
                  </Col>

                  <Col xs={3}>
                    {/* <img src={ImagePlaceHolder} style={{height:"150px"}} /> */}
                    {/* <br/> */}
                  </Col>
                </Row>
              </fieldset>

              <br />

              <fieldset className="border rounded-3 p-3" style={{ marginBottom: '30px' }}>
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  Crop Land Information
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  <Col md={6} style={{ overflowWrap: 'break-word' }}>
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      Have experienced any crop damage in last cropping season{' '}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      Hazard that caused the crop damage{' '}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      You receive any insurance claim for the last crop season{' '}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      The amount of claim received LKR
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      If you experienced a crop damage and did not receive a compensation, mention
                      the raesons for that
                    </p>
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      If you received a claim for the crop damages occurred, mention your opinion on
                      the amount of insurance claim provided
                    </p>
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      Remarks
                    </p>
                  </Col>

                  <Col md={6} style={{ overflowWrap: 'break-word' }}>
                    <p style={{ color: '#363636', fontWeight: 'bold' }}>
                      {/* Yes */}
                      {tableData[0]?.experienceAnyDamage!==" " ?tableData[0]?.experienceAnyDamage
                        ? tableData[0]?.experienceAnyDamage
                        : 'N/A':"N/A"}
                    </p>

                    <p style={{ color: '#363636', fontWeight: 'bold' }}>
                      {tableData[0]?.insuranceClaimCaused!==" " ?tableData[0]?.insuranceClaimCaused
                        ? tableData[0]?.insuranceClaimCaused
                        : 'N/A':"N/A"}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#363636', fontWeight: 'bold' }}>
                      {/* Flood */}
                      {tableData[0]?.didYouReceiveAnyInsurance!==" " ?tableData[0]?.didYouReceiveAnyInsurance
                        ? tableData[0]?.didYouReceiveAnyInsurance
                        : 'N/A':"N/A"}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#363636', fontWeight: 'bold' }}>
                      {/* Yes */}
                      {tableData[0]?.theAmountOfClaim!==" " ?tableData[0]?.theAmountOfClaim ? tableData[0]?.theAmountOfClaim : 'N/A':"N/A"}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#363636', fontWeight: 'bold' }}>
                      {/* 1 lakh */}
                      {tableData[0]?.didNotReceiveCompensation!==" " ?tableData[0]?.didNotReceiveCompensation
                        ? tableData[0]?.didNotReceiveCompensation
                        : 'N/A':"N/A"}
                    </p>

                    <br />

                    {/* <br/> */}
                    <p style={{ color: '#363636', fontWeight: 'bold' }}>
                      {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. */}
                      {tableData[0]?.whatisyouropinion!==" " ?tableData[0]?.whatisyouropinion ? tableData[0]?.whatisyouropinion.split("-").join(" ") : 'N/A':"N/A"}
                    </p>

                    <br />

                    <p style={{ color: '#363636', fontWeight: 'bold' }}>
                      {/* Claim provided was sufficient and happy to receive compared to premium paid */}
                      {tableData[0]?.remarks ? tableData[0]?.remarks : 'N/A'}
                    </p>
                    {/* <p style={{ color: '#363636', fontWeight: 'bold' }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p> */}
                  </Col>
                </Row>
              </fieldset>
            </form>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default SurveyDetails;
