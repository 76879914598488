import Register from '../pages/account/Register';
import Login from '../pages/account/Login';
import { ReactComponent as Home } from '../assets/Images/home-filled.svg';
import { ReactComponent as ClimateWeather } from '../assets/Images/climate-weather-blue.svg';
import { ReactComponent as CropHealthMonitoring } from '../assets/Images/crop-health-monitoring.svg';
import { ReactComponent as FarmTracker } from '../assets/Images/farm-tracker.svg';
import { ReactComponent as RiskAnalytics } from '../assets/Images/risk-analytics.svg';
import { ReactComponent as UserGuideIcon } from '../assets/Images/user-guide.svg';
import ManageStaticData from '../pages/manage-static-data';
import ManageFieldData from '../pages/fieldData';
import ResetPassword from '../pages/account/ResetPassword';
import ManageUserGuide from '../pages/ManageUserGuide/index';
import CreateUserGuidePage from '../pages/ManageUserGuide/CreateUserGuidePage';
import ManageUserGuideForm from '../pages/ManageUserGuide/ManageForm';
import HomePage from '../pages/landing-page/home-page';
import ManageUser from '../pages/manage-users';
import ManageFarmsList from '../pages/manage-users/manageFarmsList';
import FarmTrackerMapComponent from '../pages/FarmTracker/map';
import FarmTrackerListing from '../pages/FarmTracker';
import CreateFarmTrackerComponent from '../pages/FarmTracker/create-project';
import RiskAnalyticsMapComponent from '../pages/RiskAnalytics/map';

import SettingsDashboard from '../Components/SettingsDashboard';
import SurveyDataSettingsDashboard from '../Components/SurveyDataSettingsDashboard';
import ClimateWeatherPage from '../pages/dashboard';
import CropHealthMonitoringPage from '../pages/CropHealthMonitoring';
import WeatherMonitoringPage from '../pages/WeatherMonitoring';
import UserGuide from '../pages/UserGuide/index';
import UserGuideDetailPage from '../pages/UserGuide/UserGuideDetailPage';
import UserSearchContentPage from '../pages/UserGuide/UserSearchContentPage';
import UserSearchDetailPage from '../pages/UserGuide/UserSearchDetailPage';
import TermsConditions from "../pages/landing-page/terms-conditions"
import PrivacyPolicyPage from '../pages/landing-page/privacy-policypage';

import FieldDataDetails2 from "../pages/fieldData/SurveyDetails"
import FieldDataDetails3 from "../pages/fieldData/Component3Details"
import FieldDataDetails4 from "../pages/fieldData/Component4Details"
import FieldDataDetails1 from "../pages/fieldData/Component1Details"
import FieldDataDetails5 from "../pages/fieldData/Component5Details"


import ConfigurationPage from "../pages/fieldData/Configuration"
import manageSurveyorsList from "../pages/fieldData/manageSurveyors"


const Routes = [
  {
    path: '/home',
    name: 'Home',
    icon: <Home />,
    component: HomePage,
    visible: true,
  },
  {
    path: '/weather-forecast',
    name: 'Weather & Water',
    icon: <ClimateWeather />,
    component: ClimateWeatherPage,
    visible: true,
  },

  {
    path: '/weather-monitoring',
    name: 'Weather & Water',
    icon: <ClimateWeather />,
    component: WeatherMonitoringPage,
    visible: true,
  },
  {
    path: '/crop-health-monitoring',
    name: 'Crop Health Monitor',
    icon: <CropHealthMonitoring />,
    component: CropHealthMonitoringPage,
    visible: true,
  },
  {
    path: '/crop-health-water',
    name: 'Crop Health Monitor',
    icon: <CropHealthMonitoring />,
    component: CropHealthMonitoringPage,
    visible: true,
  },
  {
    path: '/farms-list',
    name: ' Farm Listing',
    icon: <FarmTracker />,
    component: FarmTrackerListing,
    visible: true,
  },
  {
    path: '/farm-tracker',
    name: ' Farm Tracker',
    icon: <FarmTracker />,
    component: FarmTrackerMapComponent,
    visible: true,
  },
  {
    path: '/risk-analytics',
    name: 'Risk Analytics',
    icon: <RiskAnalytics />,
    component: RiskAnalyticsMapComponent,
    visible: true,
  },
  {
    path: '/create-project/:action',
    name: 'Create Project',
    component: CreateFarmTrackerComponent,
    visible: false,
  },
  {
    path: '/user-guide-details',
    name: 'UserGuideDetailsPage',
    component: UserGuideDetailPage,
    visible: false,
  },
  {
    path: '/user-guide-search',
    name: 'UserSearchContentPage',
    component: UserSearchContentPage,
    visible: false,
  },
  {
    path: '/user-guide-detail-search-content',
    name: 'UserGuideDetailSearchContent',
    component: UserSearchDetailPage,
    visible: false,
  },
  {
    path: '/user-guide',
    name: 'User Guide',
    icon: <UserGuideIcon />,
    component: UserGuide,
    visible: true,
  },
  {
    path: '/user-guide-form',
    name: 'CreateUserGuidePage',
    component: CreateUserGuidePage,
  },
  {
    path: '/manage-userguide',
    name: 'ManageUserGuide',
    component: ManageUserGuide,
  },

  {
    path: '/manage-user-guide-form/:action',
    name: 'Manage User guide form',
    component: ManageUserGuideForm,
    visible: false,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    visible: false,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ResetPassword,
    visible: false,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    visible: false,
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ResetPassword,
    visible: false,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    visible: false,
  },
  {
    path: '/manage-user',
    name: 'manage-user',
    component: ManageUser,
    visible: false,
  },
  {
    path: '/manage-farms-list',
    name: 'manage-farms-list',
    component: ManageFarmsList,
    visible: false,
  },
  {
    path: '/manageStaticData',
    name: 'manageStaticData',
    component: ManageStaticData,
  },
  {
    path: '/manageFieldData',
    name: 'manageFieldData',
    component: ManageFieldData,
  },
  {
    path: '/viewDetails2/:id',
    name: 'manageFieldDataDetails',
    component: FieldDataDetails2,
  },
  {
    path: '/viewDetails1/:id',
    name: 'manageFieldDataDetails',
    component: FieldDataDetails1,
  },
  {
    path: '/viewDetails3/:id',
    name: 'manageFieldDataDetails',
    component: FieldDataDetails3,
  },
  {
   path: '/viewDetails4/:id',
    name: 'manageFieldDataDetails',
     component: FieldDataDetails4,
  },
  {
   path: '/viewDetails3/:id',
    name: 'manageFieldDataDetails',
     component: FieldDataDetails1,
  },
  {
    path: '/viewDetails5/:id',
     name: 'manageFieldDataDetails',
      component: FieldDataDetails5,
   },
  {
    path: '/configuration',
     name: 'manageFieldDataDetails',
      component: ConfigurationPage,
   },
   {
    path: '/manageSurveyors',
     name: 'manageSurveyorsDataDetails',
      component: manageSurveyorsList,
   },
  {
    path: '/settingsDashboard',
    name: 'SettingsDashboard',
    component: SettingsDashboard,
  },
  {
    path: '/surveyDataSettingsDashboard',
    name: 'SurveyDataSettingsDashboard',
    component: SurveyDataSettingsDashboard,
  },
  {
    path: '/terms-conditions',
    name: 'TermsConditions',
    component: TermsConditions,
    visible: false,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage,
    visible: false,
  },
];
export default Routes;
