import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import _ from 'lodash';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import PropTypes from 'prop-types';

L.Marker.prototype.options.icon = L.icon({
  iconUrl: icon,
  iconSize: [25, 41],
});

const myIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

export default function DraggableMarker({
  cordinates,
  handleCordinates,
  forecastData,
  renderCharts,
  applyLayers,
}) {
  const [position, setPosition] = useState(cordinates);
  const [chartData, setChartData] = useState('');
  const markerRef = useRef(null);

  useEffect(() => {
    let positionArr = comparePositionType(position);
    let cordinatesArr = comparePositionType(cordinates);
    if (!_.isEqual(cordinatesArr, positionArr)) {
      setPosition(cordinates);
    }
  }, [cordinates]);

  useEffect(() => {
    let positionArr = comparePositionType(position);
    let cordinatesArr = comparePositionType(cordinates);
    if (!_.isEqual(positionArr, cordinatesArr)) {
      handleCordinates(position);
    }
  }, [position]);

  useEffect(() => {
    setChartData(forecastData);
  }, [forecastData]);

  const comparePositionType = (pos) => {
    let positionArr = [];
    if (!Array.isArray(pos)) {
      positionArr[0] = pos.lat;
      positionArr[1] = pos.lng;
    } else {
      positionArr = pos;
    }
    return positionArr;
  };

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        setPosition(marker.getLatLng());
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
      click: (e) => {
        if (applyLayers) {
          const resizeEle = document.getElementsByClassName(
            'ResizePanel-module_ResizeContentVertical__1OGHY',
          );
          if (resizeEle[0]) {
            resizeEle[0].style.height = '16.4vh';
          }
          renderCharts();
        }
      },
    }),
    [position, applyLayers],
  );

  return (
    <>
      <Marker
        draggable="true"
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
        icon={myIcon}
      />
    </>
  );
}
DraggableMarker.propTypes = {
  cordinates: PropTypes.array,
};
