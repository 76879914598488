import React from 'react';
import { useTranslation } from 'react-multi-lang';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'reactstrap-button-loader';

const DeleteConfirmationModal = props => {
  const { className, isDeleteModalOpen, handleDeleteConfirm } = props;
  const t = useTranslation();

  // const nextProps = useSelector((state) => ({
  //   isDeleteRequesting: state.AccessKey.deleteRequesting,
  // }));

  const toggle = value => {
    if (value === 'no') {
      handleDeleteConfirm(false, 'no');
    } else if (value === 'yes') {
      handleDeleteConfirm(false, 'yes');
    }
  };
  const handleClose = () => {
    handleDeleteConfirm(false);
  };

  return (
    <div>
      <Modal isOpen={isDeleteModalOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={handleClose} className="reason-modal-header">
          {t('MANAGE_USER.CONFIRMATION')}
        </ModalHeader>
        <ModalBody>
          <p className="confirmation-modal-text">{t('MANAGE_USER.DELETE_CONFIRMATION')}</p>
        </ModalBody>
        <ModalFooter className="reason-modal">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
            <Button color="secondary" onClick={() => toggle('no')} className="cancel-button me-3">
              {t('MANAGE_USER.NO')}
            </Button>{' '}
            <Button color="primary" onClick={() => toggle('yes')} className="modal-reject-button">
              {t('MANAGE_USER.YES')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteConfirmationModal;
DeleteConfirmationModal.propTypes = {
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteConfirm: PropTypes.func,
};
