import FARM_TRACKER_CONST from './farmTrackerConsts';

const FARM_TRACKER = (state = {}, action) => {
  switch (action.type) {
    case FARM_TRACKER_CONST.ADD_FARM_TRACKER_REQUEST:
      return {
        ...state,
        addFarmTrackerRequesting: true,
        addFarmTrackerData: false,
        addFarmTrackerDataError: false,
        addFarmTrackerDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.ADD_FARM_TRACKER_SUCCESS:
      return {
        ...state,
        addFarmTrackerRequesting: false,
        addFarmTrackerData: action.payload.response,
        addFarmTrackerDataError: false,
        addFarmTrackerDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.ADD_FARM_TRACKER_FAILED:
      return {
        ...state,
        addFarmTrackerRequesting: false,
        addFarmTrackerData: false,
        addFarmTrackerDataError: action.payload.response ? action.payload.response : false,
        addFarmTrackerDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };
    case FARM_TRACKER_CONST.EDIT_FARM_TRACKER_REQUEST:
      return {
        ...state,
        editFarmTrackerRequesting: true,
        editFarmTrackerData: false,
        editFarmTrackerDataError: false,
        editFarmTrackerDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.EDIT_FARM_TRACKER_SUCCESS:
      return {
        ...state,
        editFarmTrackerRequesting: false,
        editFarmTrackerData: action.payload.response,
        editFarmTrackerDataError: false,
        editFarmTrackerDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.EDIT_FARM_TRACKER_FAILED:
      return {
        ...state,
        editFarmTrackerRequesting: false,
        editFarmTrackerData: false,
        editFarmTrackerDataError: action.payload.response ? action.payload.response : false,
        editFarmTrackerDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };
    case FARM_TRACKER_CONST.DELETE_FARM_TRACKER_REQUEST:
      return {
        ...state,
        deleteFarmTrackerRequesting: true,
        deleteFarmTrackerData: false,
        deleteFarmTrackerDataError: false,
        deleteFarmTrackerDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.DELETE_FARM_TRACKER_SUCCESS:
      return {
        ...state,
        deleteFarmTrackerRequesting: false,
        deleteFarmTrackerData: action.payload.response,
        deleteFarmTrackerDataError: false,
        deleteFarmTrackerDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.DELETE_FARM_TRACKER_FAILED:
      return {
        ...state,
        deleteFarmTrackerRequesting: false,
        deleteFarmTrackerData: false,
        deleteFarmTrackerDataError: action.payload.response ? action.payload.response : false,
        deleteFarmTrackerDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };
    case FARM_TRACKER_CONST.GET_FARM_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        getFarmDetailsByIdRequesting: true,
        getFarmDetailsByIdData: false,
        getFarmDetailsByIdDataError: false,
        getFarmDetailsByIdDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.GET_FARM_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        getFarmDetailsByIdRequesting: false,
        getFarmDetailsByIdData: action.payload.response,
        getFarmDetailsByIdDataError: false,
        getFarmDetailsByIdDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.GET_FARM_DETAILS_BY_ID_FAILED:
      return {
        ...state,
        getFarmDetailsByIdRequesting: false,
        getFarmDetailsByIdData: false,
        getFarmDetailsByIdDataError: action.payload.response ? action.payload.response : false,
        getFarmDetailsByIdDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };
    case FARM_TRACKER_CONST.GET_FARM_LIST_REQUEST:
      return {
        ...state,
        getAllByCriteriaRequesting: true,
        getAllByCriteriaData: false,
        getAllByCriteriaDataError: false,
        getAllByCriteriaDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.GET_FARM_LIST_SUCCESS:
      return {
        ...state,
        getAllByCriteriaRequesting: false,
        getAllByCriteriaData: action.payload.response,
        getAllByCriteriaDataError: false,
        getAllByCriteriaDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.GET_FARM_LIST_FAILED:
      return {
        ...state,
        getAllByCriteriaRequesting: false,
        getAllByCriteriaData: false,
        getAllByCriteriaDataError: action.payload.response ? action.payload.response : false,
        getAllByCriteriaDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    case FARM_TRACKER_CONST.ADD_PROJECT_REQUEST:
      return {
        ...state,
        addProjectRequesting: true,
        addProjectData: false,
        addProjectDataError: false,
        addProjectDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.ADD_PROJECT_SUCCESS:
      return {
        ...state,
        addProjectRequesting: false,
        addProjectData: action.payload.response,
        addProjectDataError: false,
        addProjectDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.ADD_PROJECT_FAILED:
      return {
        ...state,
        addProjectRequesting: false,
        addProjectData: false,
        addProjectDataError: action.payload.response ? action.payload.response : false,
        addProjectDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };
    case FARM_TRACKER_CONST.EDIT_PROJECT_REQUEST:
      return {
        ...state,
        editProjectRequesting: true,
        editProjectData: false,
        editProjectDataError: false,
        editProjectDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        editProjectRequesting: false,
        editProjectData: action.payload.response,
        editProjectDataError: false,
        editProjectDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.EDIT_PROJECT_FAILED:
      return {
        ...state,
        editProjectRequesting: false,
        editProjectData: false,
        editProjectDataError: action.payload.response ? action.payload.response : false,
        editProjectDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };
    case FARM_TRACKER_CONST.GET_PROJECT_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        getProjectDetailsByIdRequesting: true,
        getProjectDetailsByIdData: false,
        getProjectDetailsByIdDataError: false,
        getProjectDetailsByIdDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.GET_PROJECT_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        getProjectDetailsByIdRequesting: false,
        getProjectDetailsByIdData: action.payload.response,
        getProjectDetailsByIdDataError: false,
        getProjectDetailsByIdDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.GET_PROJECT_DETAILS_BY_ID_FAILED:
      return {
        ...state,
        getProjectDetailsByIdRequesting: false,
        getProjectDetailsByIdData: false,
        getProjectDetailsByIdDataError: action.payload.response ? action.payload.response : false,
        getProjectDetailsByIdDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };
    case FARM_TRACKER_CONST.GET_PROJECT_LIST_REQUEST:
      return {
        ...state,
        getAllByCriteriaProjectListRequesting: true,
        getAllByCriteriaProjectListData: false,
        getAllByCriteriaProjectListDataError: false,
        getAllByCriteriaProjectListDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        getAllByCriteriaProjectListRequesting: false,
        getAllByCriteriaProjectListData: action.payload.response,
        getAllByCriteriaProjectListDataError: false,
        getAllByCriteriaProjectListDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.GET_PROJECT_LIST_FAILED:
      return {
        ...state,
        getAllByCriteriaProjectListRequesting: false,
        getAllByCriteriaProjectListData: false,
        getAllByCriteriaProjectListDataError: action.payload.response ? action.payload.response : false,
        getAllByCriteriaProjectListDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };
    case FARM_TRACKER_CONST.DELETE_PROJECT_LIST_REQUEST:
      return {
        ...state,
        deleteProjectListRequesting: true,
        deleteProjectListData: false,
        deleteProjectListDataError: false,
        deleteProjectListDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.DELETE_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        deleteProjectListRequesting: false,
        deleteProjectListData: action.payload.response,
        deleteProjectListDataError: false,
        deleteProjectListDataNetworkError: false,
      };
    case FARM_TRACKER_CONST.DELETE_PROJECT_LIST_FAILED:
      return {
        ...state,
        deleteProjectListRequesting: false,
        deleteProjectListData: false,
        deleteProjectListDataError: action.payload.response ? action.payload.response : false,
        deleteProjectListDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };
    default:
      return state;
  }
};

export default FARM_TRACKER;
