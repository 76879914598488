import React from 'react';
import { t } from 'react-multi-lang';
import { Card, CardBody } from 'reactstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import CreateFarm from './create-farm';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import _ from 'lodash';
import { CgCloseO } from 'react-icons/cg';

const FarmCollapse = (props) => {
  const { showFarmInputFields, isCreateFarm, showPolygonDraw, setChangePolygonOptions, defaultPolygonProps } = props;

  const handleCloseFarmCollapse = () => {
    // props.setIsHideFarmCollapse(!props.isHideFarmCollapse);
    props.setSelectedFarmIndex('');
    props.handleEmptyKmlFile();
    props.setShowPolygonDraw(false);
    let data = [...props.farmOptions];
    let updatedData = data?.map((item) => (item?.selected ? { ...item, selected: false } : item));
    if (updatedData) {
      setChangePolygonOptions(updatedData)
      props.setFarmOptions(updatedData);
    }
  };

  return (
    <>
      <div>
        <div className="filter-sidebar-section-drought-monitor collapse-drought-filter-section">
          <div className="filter-div">
            <Card className="card-style">
              <CardBody>
                <div className="d-flex cursor-ponter navyBlueColor py-1 pb-2 mb-3 justify-content-between farm-details-st">
                  {' '}
                  <span className="farm-cls-st" style={{ marginLeft: '10px' }}>
                    <span>
                      {' '}
                      <GiHamburgerMenu size={20} />{' '}
                    </span>{' '}
                    {t('FARM_TRACKER.FARM_DETAILS')}
                  </span>
                  <span className="farm-cl-icon-st" onClick={() => handleCloseFarmCollapse()}>
                    {/* <span>
                      <MdKeyboardArrowLeft
                        onClick={() => props.setIsHideFarmCollapse(!props.isHideFarmCollapse)}
                        size={25}
                      />
                    </span> */}
                    <span className="hidebar-show">
                      <CgCloseO size={23} />
                      {/* {t('FARM_TRACKER.CLOSE')} */}
                    </span>{' '}
                  </span>
                </div>{' '}
                {showFarmInputFields ? (
                  <CreateFarm
                    initialValues={props.createFarmData}
                    farmAttributes={props.farmAttributes}
                    defaultPolygonProps={defaultPolygonProps}
                    farmTitle={props.farmTitle}
                    attributeLimit={props.attributeLimit}
                    isCreateFarm={isCreateFarm}
                    isUploadFarm={props.isUploadFarm}
                    subDistrictList ={props.subDistrictList}
                    blockList ={props.blockList}
                    handleSubDistrictChange={props.handleSubDistrictChange}
                    handleBlockChange={props.handleBlockChange}
                    handleChangePolygonOptions={props.handleChangePolygonOptions}
                    handleResetCollapseFarm={props.handleResetCollapseFarm}
                    setFarmTitle={props.setFarmTitle}
                    setFarmDescription={props.setFarmDescription}
                    farmDescription={props.farmDescription}
                    handleFormFieldData={props.handleFormFieldData}
                    handleDeleteAttribute={props.handleDeleteAttribute}
                    handleAddMoreAttributes={props.handleAddMoreAttributes}
                    handleFarmAttributeChange={props.handleFarmAttributeChange}
                    imageFile={props.imageFile}
                    fileHandler={props.fileHandler}
                    geoTaggedImages={props.geoTaggedImages}
                    handleGeoTaggedImages={props.handleGeoTaggedImages}
                    geoTaggedImagesFiles={props.geoTaggedImagesFiles}
                    handleGeoTaggedImageRemoval={props.handleGeoTaggedImageRemoval}
                    showPolygonDraw={showPolygonDraw}
                    changePolygonOptions={props.changePolygonOptions}
                    farmOptions={props.farmOptions}
                  />
                ) : (
                  <>
                    <div className="forecast-monitoring mx-2 my-auto">
                      {t('FARM_TRACKER.NO_FARMS')}
                      <div className="sub-text"> {t('FARM_TRACKER.DRAW_FARM_TEXT')}</div>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default FarmCollapse;
