import {
  USER_LIST_CONST,
  USER_BY_ID_CONST,
  USER_DELETE_CONST,
  UPDATE_STATUS_CONST,
  USER_PERMISSIONS_CONST,
  USER_PERMISSIONS_BY_ID_CONST,
  UPDATE_PERMISSIONS_CONST,
  USER_DETAIL_CONST,
} from './manageUserConst';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  userListSuccess,
  userListFailed,
  userByIdSuccess,
  deleteUserSuccess,
  deleteUserFailed,
  updateStatusFailed,
  updateStatusSuccess,
  userByIdFailed,
  userPermissionsSuccess,
  userPermissionsFailed,
  userPermissionsByIdSuccess,
  userPermissionsByIdFailed,
  updatePermissionsSuccess,
  updatePermissionsFailed,
  userDetailFailed,
  userDetailSuccess,
} from './manageUserAction';
import { USER_URL, AXIOS_INSTANCE, PERMISSIONS_URL } from '../api/apiEndPoint';
import baseMethod from '../baseService.js';
import { checkHttpStatus } from '../api/apiUtils';

function* userListRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${USER_URL}/getAllByCriteria?${action.payload.key}=${action.payload.bool_value}&&${action.payload.pageKey}=${action.payload.page}&&${action.payload.limitKey}=${action.payload.limit}&&name=${action.payload.name}&&email=${action.payload.email}&&mobile=${action.payload.mobile}`,
        action.payload,
      ),
      '',
      true,
      // true,
      false,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(userListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(userListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userListFailed(errorData));
  }
}

function* userByIdRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${USER_URL}/getById?id=${action.payload.request_Id}`, action.payload),
      '',
      true,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(userByIdSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(userByIdFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userListFailed(errorData));
  }
}

function* deleteUserRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.delete(`${USER_URL}/delete/${action.payload.id}`, action.payload),
      '',
      true,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(deleteUserSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(deleteUserFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteUserFailed(errorData));
  }
}

function* userPermissionsRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${PERMISSIONS_URL}/getAllPermission`, action.payload),
      '',
      true,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(userPermissionsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(userPermissionsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userPermissionsFailed(errorData));
  }
}

function* updatePermissionsRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${PERMISSIONS_URL}/assign`, action.payload),
      '',
      true,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(updatePermissionsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(updatePermissionsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updatePermissionsFailed(errorData));
  }
}

function* userPermissionsByIdRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(
        `${PERMISSIONS_URL}/getUserPermissions/${action.payload.id}`,
        action.payload,
      ),
      '',
      true,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(userPermissionsByIdSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(userPermissionsByIdFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userPermissionsByIdFailed(errorData));
  }
}

// function* userDetailRequest(action) {
//   try {
//     const response = yield call(userDetailApiRequest, action.payload);
//     if (response.status === 200) {
//       const responseData = {
//         response: {
//           statusCode: 200,
//           data: response.data,
//         },
//       };
//       yield put(userDetailSuccess(responseData));
//     } else {
//       const responseData = {
//         response: {
//           statusCode: 200,
//           data: response.error,
//         },
//       };
//       yield put(userDetailFailed(responseData));
//     }
//   } catch (error) {
//     const errorData = {
//       error: {
//         statusText: error,
//         netWorkError: true,
//       },
//     };
//     yield put(userDetailFailed(errorData));
//   }
// }

function* updateStatusRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(`${USER_URL}/update`, action.payload),
      '',
      true,
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(updateStatusSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(updateStatusFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updateStatusFailed(errorData));
  }
}

///
const userDetailApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${USER_URL}/loginUserDetail`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* userDetailRequest(action) {
  try {
    const response = yield call(userDetailApiRequest, action.payload);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(userDetailSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(userDetailFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userDetailFailed(errorData));
  }
}

///

export function* watchUserList() {
  yield takeEvery(USER_LIST_CONST.USER_LIST_REQUEST, userListRequest);
  yield takeEvery(USER_BY_ID_CONST.USER_BY_ID_REQUEST, userByIdRequest);
  yield takeEvery(USER_DELETE_CONST.USER_DELETE_REQUEST, deleteUserRequest);
  yield takeEvery(USER_PERMISSIONS_CONST.USER_PERMISSION_REQUEST, userPermissionsRequest);
  yield takeEvery(UPDATE_PERMISSIONS_CONST.UPDATE_PERMISSION_REQUEST, updatePermissionsRequest);
  yield takeEvery(UPDATE_STATUS_CONST.UPDATE_STATUS_REQUEST, updateStatusRequest);
  yield takeEvery(
    USER_PERMISSIONS_BY_ID_CONST.USER_PERMISSIONS_BY_ID_REQUEST,
    userPermissionsByIdRequest,
  );
  yield takeEvery(USER_DETAIL_CONST.USER_DETAIL_REQUEST, userDetailRequest);
}

function* userListSaga() {
  yield all([fork(watchUserList)]);
}

export default userListSaga;
