import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Container,
  Input,
  FormGroup,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
// import { useTranslation } from 'react-multi-lang';
// import { userListRequest, userListSuccess } from '../../redux/manage-user/manageUserAction';
// import { getFieldDataRequest } from '../../redux/manageFieldData/manageFieldDataActions';

import Loader from '../../Components/common/loader';
import { change, Field, reduxForm } from 'redux-form';
import compose from 'compose-function';
import { renderTextField, renderDateField } from '../../Components/common/renderInputField';
import ConfigurationIcon from '../../assets/Images/configuration.png';
import FilterIcon from '../../assets/Images/filter.png';
import SolidFilterIcon from '../../assets/Images/solidFilter.png';
import moment from 'moment';
import { DATE_FORMAT } from '../../Components/constants/index';
import { showError } from '../../Components/Notifications';
import { ManageSurveyorsTable } from './manageSurveyorsTable';
import { manageSurveyorsListRequest } from '../../redux/manageFieldData/manageFieldDataActions';
import RightArrow from '../../assets/Images/back-button.svg';
import { isValidEmail } from '../../Components/common/utils';

import { t } from 'react-multi-lang';
import jwt_decode from 'jwt-decode';

import {
  SCOPE,
  deleteDiscoveryUrl,
  CLIENT_ID,
  API_KEY,
  discoveryUrl,
} from '../../Components/constants/index';

import _ from 'lodash';

import { loginDetailrequest } from '../../redux/login/loginActions';


import { getToken } from '../../Components/common/utils';


// import { Field, reduxForm } from 'redux-form';

const ManageFieldData = (props) => {
  const nextProps = useSelector((state) => ({
    isFieldDataListDataRequesting: state.fieldDataList.isFieldDataListDataRequesting,
    fieldDataListData: state?.fieldDataList?.FieldDataListData,
    inviteConfigurationData: state?.fieldDataList?.inviteConfigurationData,
    managesurveyorsListData: state?.fieldDataList?.managesurveyorsListData,
    managesurveyorsListRequesting: state?.fieldDataList?.managesurveyorsListRequesting,
    loginDetailData: state.Login.loginDetailData || null,
    deleteSurveyorsRequesting: state.fieldDataList.deleteSurveyorsRequesting || null,
    deleteSurveyorsData: state.fieldDataList.deleteSurveyorsData || null,
    loginDetailData: state.Login.loginDetailData || null,
  }));

  const dispatch = useDispatch();
  let history = useHistory();

  const [newRequestList, setNewRequestList] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [userPermissions, setUserPermissions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [recordsCount, setRecordsCount] = useState();
  const [allowedPermission, setAllowedPermission] = useState();

  const [openFilter, setOpenFilter] = useState(false);

  const [email, setemail] = useState(null);

  const [signedInUser, setSignedInUser] = useState('');

  const [signedInUserDetails, setSignedInUserDetails] = useState('');

  const [userDetails, setUserDetails] = useState('');

  const [disableSearch, setDisableSearch] = useState(true);
  const [isLoading,setIsLoading] = useState(false);

  useEffect(() => {

    if(getToken()){

    dispatch(loginDetailrequest({}));
    dispatch(
      manageSurveyorsListRequest({
        page: pageNumber,
        limit: pageLimit,
      }),
    );

    let signedInUser = localStorage.getItem('googleAccountDetails');
    if(signedInUser){
      signedInUser = JSON.parse(signedInUser);
      setSignedInUser(signedInUser)
      setSignedInUserDetails(signedInUser?.email)
    } else history.push('/surveyDataSettingsDashboard')
    }
    else {
    history.push("/home")

    }
  }, []);


  // const t = useTranslation();
  useEffect(() => {
    if(nextProps.deleteSurveyorsRequesting){
    }
    else if(nextProps.deleteSurveyorsData) {
      dispatch(
        manageSurveyorsListRequest({
          page: pageNumber,
          limit: pageLimit,
        }),
      );
      setIsLoading(false)
    }
  }, [nextProps.deleteSurveyorsRequesting, nextProps.deleteSurveyorsData]);

  useEffect(() => {
    if (nextProps.managesurveyorsListData) {
      if (nextProps?.managesurveyorsListData?.data?.result?.results?.length) {
        // setNewRequestList(nextProps?.fieldDataListData?.data?.results);
        setNewRequestList(nextProps?.managesurveyorsListData?.data?.result?.results);
        setPageCount(nextProps?.managesurveyorsListData?.data?.result?.count);
        setRecordsCount(nextProps?.managesurveyorsListData?.data?.result?.results?.length);
      } else {
        setNewRequestList([]);
        setPageCount(0);
        setRecordsCount(0);
      }
    } else {
      setNewRequestList([]);
    }
  }, [nextProps.managesurveyorsListData]);

  const handlePageNumber = (value) => {
    setPageNumber(value);
    dispatch(
      manageSurveyorsListRequest({
        page: value,
        limit: pageLimit,
      }),
    );
  };

  const handlePageSize = (value) => {
    setPageLimit(value);
    setPageNumber(1);
    dispatch(
      manageSurveyorsListRequest({
        page: pageNumber,
        limit: value,
      }),
    );
  };

  useEffect(() => {
    if (nextProps.loginDetailData) {
      // setFolderId(nextProps?.loginDetailData?.data?.result?.user_profile?.drive_folder_id);

      setUserDetails(nextProps?.loginDetailData?.data?.result?.user_profile);
    }
  }, [nextProps.loginDetailData?.data?.result]);

  useEffect(() => {
  }, []);

  const handleClientLoad = async() => {
    /* global google */
    await window?.google?.accounts?.id.initialize({
      client_id: CLIENT_ID,
      apiKey: API_KEY,
      useOneTap: false,
      auto_select: true,
      callback: async function(response) {
        let userObj = jwt_decode(response.credential);
        setSignedInUser(userObj)
        setSignedInUserDetails(userObj?.email)
      }
    })
    window?.google?.accounts?.id.prompt();
  };

  const handleCallbackResponse = (response) => {
    // window.google.accounts.id.prompt();
    let userObj = jwt_decode(response.credential);
    setSignedInUser(userObj)
    setSignedInUserDetails(userObj?.email)
  }

  useEffect(() => {
   if (!_.isEmpty(userDetails?.google_email)&& !_.isEmpty(signedInUserDetails)) {
    if (signedInUserDetails && userDetails && userDetails?.google_email !== signedInUserDetails) {
      
      // showError("Please login with this account.")
      showError(
        `Email you have authenticated is not related with your organization please authenticate with this email ${userDetails?.google_email}`,
      );
      handleSignOutClick();
      // history.goBack()
    }
  }
  }, [signedInUserDetails, userDetails]);

  const handleSignOutClick = () => {
    history.goBack();
  };

  const handleLatestData = (value) => {
    // dispatch(userListSuccess({}));
    setNewRequestList([]);

    dispatch(
      manageSurveyorsListRequest({
        page: pageNumber,
        limitKey: 'limit',
        limit: value,
      }),
    );
  };

  const onSubmit = (e) => {
    dispatch(
      manageSurveyorsListRequest({
        page: pageNumber,
        // limitKey: 'limit',
        limit: 10,
        email: e?.email,
      }),
    );
  };

  const handleSearch = (e) => {
    if (!email) {
      showError('Please enter email.');
    } else if (!isValidEmail(email.trim())) {
      showError(t('LOGIN.EMAIL_ERROR_MSG'));
    } else {
      if (email) {
        dispatch(
          manageSurveyorsListRequest({
            page: pageNumber,
            // limitKey: 'limit',
            limit: 10,
            email: email,
          }),
        );
      } else {
        dispatch(
          manageSurveyorsListRequest({
            page: pageNumber,
            // limitKey: 'limit',
            limit: 10,
            // email:email
          }),
        );
      }
    }
  };

  const handleEmail = (e) => {
    setemail(e);
  };

  const reset = () => {
    document.getElementById('email').value = '';
    dispatch(
      manageSurveyorsListRequest({
        page: pageNumber,
        // limitKey: 'limit',
        limit: 10,
        // email:email
      }),
    );
  };

  const handleFilter = (e) => {
    setOpenFilter(!openFilter);
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <Container fluid>
        {(nextProps.managesurveyorsListRequesting || isLoading )&& <Loader />}

        <div className="right-img-wrapper api-key-list">
          <Row style={{ display: 'flex', alignItems: 'center' }} className="manage-surveyor-dsn">
            <Col
              xs="12"
              sm="12"
              md="12"
              xl={openFilter ? '5' : '10'}
              lg={openFilter ? '4' : '10'}
              className="subheader-manage-user"
            >
              <img
                src={RightArrow}
                alt="back-arrow"
                className="manage-user-header-back-button"
                onClick={() => history.push('/surveyDataSettingsDashboard')}
              />
              <h3 className="heading-manage-user manage-user-heading-mt">
                {/* {t('MANAGE_USER.MANAGE_USERS')} */}
                Manage Surveyors
              </h3>
            </Col>

            {openFilter && (
              <>
                <Col lg={3} md={3} xs={12} sm={12}>
                  <FormGroup className="registertextOnInput " style={{ marginRight: '10px' }}>
                    <Input
                      type="text"
                      name="email"
                      placeholder="Email"
                      // component={renderTextField}
                      className="register-input"
                      id="email"
                      onChange={(e) => handleEmail(e.target.value)}
                    />
                  </FormGroup>
                </Col>

                <Col lg={1} md={3} xs={12} sm={12}>
                  <Button
                    style={{
                      backgroundColor: '#3870AF',
                      color: '#FFF',
                      marginRight: '10px',
                      borderRadius: '5px',
                      borderStyle: 'none',
                      padding: '7px',
                      width: '100%',
                    }}
                    className="search-button-disabled"
                    onClick={(e) => handleSearch(e)}
                    disabled={_.isEmpty(email)}
                  >
                    Search{' '}
                  </Button>
                </Col>

                <Col lg={2} md={3} xs={12} sm={12} style={{ width: '135px' }}>
                  <Button
                    style={{
                      backgroundColor: '#3870AF',
                      color: '#FFF',
                      marginRight: '10px',
                      borderRadius: '5px',
                      borderStyle: 'none',
                      padding: '7px',
                      width: '100%',
                    }}
                    className="search-button-disabled"
                    onClick={reset}
                    disabled={_.isEmpty(email)}
                  >
                    Clear Filter{' '}
                  </Button>
                </Col>
              </>
            )}

            <Col lg={openFilter ? 1 : 1} md={openFilter ? 3 : 1} xs={12} sm={12}>
              <button
                className="filter-btn1"
                onClick={(e) => handleFilter(e)}
                style={{ marginRight: '10px' }}
              >
                <span className="text-left">Filter</span>
                <img
                  src={FilterIcon}
                  alt="back-arrow"
                  className="manage-user-header-back-button"
                  onClick={(e) => handleFilter(e)}
                />
              </button>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" className="table-col">
              <>
                

                {newRequestList && (
                  <ManageSurveyorsTable
                    loading={false}
                    req_data={newRequestList}
                    handleLatestData={handleLatestData}
                    // handleLatestDataForDelete={handleLatestDataForDelete}
                    handlePageSize={handlePageSize}
                    pageCount={pageCount}
                    handlePageNumber={handlePageNumber}
                    signedInUser={signedInUser}
                    signedInUserDetails={signedInUserDetails}
                    pageNumber={pageNumber}
                    userDetails={userDetails}
                    setIsLoading={setIsLoading}
                    recordsCount={recordsCount}
                  />
                )}
              </>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default ManageFieldData;
