import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-multi-lang';
import Button from 'reactstrap-button-loader';

import { Field, reduxForm } from 'redux-form';

import { useDispatch } from 'react-redux';



import 'react-multi-email/style.css';


const ProjectDeleteModal = (props) => {
  const { modalOpen, setModalOpen, handleConfiguration } = props;
  const [selectedTab, setSelectedTab] = useState();
  //const [continueClicked, setContinueClicked] = useState(false);
  const t = useTranslation();
  const history = useHistory();
  const [modal, setModal] = useState();

  const [emails, setEmails] = useState([
    // "fulano@email.com",
    // "sicrano@email.com",
    // "beltrano@email.com"
  ]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  let _invalidEmails = [];
  const [showError1, setError1] = useState(false);
  const removeDuplicates = (list) => Array.from(new Set(list));

  useEffect(() => {
    setModal(modalOpen);
    setSelectedTab('weather-forecast');
  }, [modalOpen]);

  const viewtoggle = (value) => {
    setModal(false);
    // handleModalToggle();
  };

  const dispatch = useDispatch();

  const handleClose = () => {
    setModal(false);
    setModalOpen(false);
    // handleModalToggle();
  };

  const handleContinue = (e) => {
    if (e === 'cancel') {
      handleClose();
    } else {
      // dispatch(deleteProjectRequest(projectId));
      // handleClose();
      handleConfiguration();
    }
  };

  return (
    <>
      <div className="shareinformationmodal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className="shareinformationmodal"
          backdrop="static"
          style={{ width: '75%' }}
        >
          

          <ModalHeader toggle={() => handleClose()} className="reason-modal-header">
          {t('STATIC_RESOURCE_ADMIN.CONFIRMATION')}
        </ModalHeader>
        <ModalBody>
          <p className="confirmation-modal-text">Are you sure do you want to change the year?</p>
        </ModalBody>

          {/* <ModalBody style={{ textAlign: 'center' }}>
            <span>Are you sure you want to change the date</span>
          </ModalBody> */}

          <ModalFooter className="reason-modal">
          <Button color="secondary" onClick={() => handleContinue('cancel')} className="cancel-button">
            {t('STATIC_RESOURCE_ADMIN.NO')}
          </Button>{' '}
          <Button color="primary" onClick={() => handleContinue('yes')} className="modal-reject-button">
            {t('STATIC_RESOURCE_ADMIN.YES')}
          </Button>
        </ModalFooter>
          {/* </form> */}
        </Modal>
        {/* </form> */}
      </div>
      {/* {continueClicked &&
      (
      <FilterTabs 
      selectedTab={selectedTab}
      />
      )
      } */}
    </>
  );
};

// export default RiskAnalyticsModal;

export default reduxForm({
  form: 'ProjectDeleteModal',

  enableReinitialize: true,
})(ProjectDeleteModal);
