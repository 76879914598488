import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Row, Col } from 'reactstrap';
import CommonPlotly from './CommonPlotly';
import moment from 'moment';
import _ from 'lodash';
import Loader from '../../../Components/common/loader';
import ReactExport from 'react-export-excel';
const endpoint = 'https://geoglows.ecmwf.int/api/';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function TabOne(props) {
  const { reachId, dailyAverageData, latitude, longitude, returnPeriodsData,isLoading,updateLoading} = props;
  const [traces, updateTraces] = useState([]);
  // const [isLoading, updateLoading] = useState(true);
  const [error, upadteError] = useState(false);
  const [date, setDate] = useState(null);
  const colors = ['#04f7ca', '#3cc4ab', '#f2ea09', '#bcb727', '#e209f2', '#903a96'];
  const maxDate = new Date(new Date().setDate(new Date().getDate() - 1));
  const maxDateFormat = moment(maxDate).format('YYYY-MM-DD');
  const [forecastData, setForcastData] = useState();
  const [returnPeriodData, setReturnPeriodData] = useState(returnPeriodsData);

  useEffect(() => {
    if (returnPeriodsData) {
      setReturnPeriodData(returnPeriodsData)
    }
  }, [
    returnPeriodsData
  ]);

  useEffect(() => {
    if (reachId) {
      plotForecastStats();
    }
  }, [reachId]);

  const updateGraph = () => {
    plotForecastStats();
  }

  const plotForecastStats = () => {
    updateLoading(true);
    let apiDate = date
      ? `${new Date(date).getFullYear()}${String(new Date(date).getMonth() + 1).padStart(
          2,
          '0',
        )}${String(new Date(date).getDate()).padStart(2, '0')}.00`
      : `${new Date(maxDateFormat).getFullYear()}${String(
          new Date(maxDateFormat).getMonth() + 1,
        ).padStart(2, '0')}${String(new Date(maxDateFormat).getDate()).padStart(2, '0')}.00`;
    
        axios
      .get(endpoint + 'ForecastStats/?return_format=json&reach_id=' + reachId + '&date=' + apiDate)
      .then((res) => {
        if (res.data && res.status === 200) {
          const data = res.data;
          let forecast = [];
          if (data?.time_series) {
            let forecastData25 = data.time_series['flow_25%_m^3/s'];
            let forecastData75 = data.time_series['flow_75%_m^3/s'];
            let forecastDataMax = data.time_series['flow_max_m^3/s'];
            let forecastDataMin = data.time_series['flow_min_m^3/s'];
            let forecastDataAvg = data.time_series['flow_avg_m^3/s'];
            forecast = data.time_series.datetime.map((item, index) => {
              return {
                datetime: item,
                'flow_25%_m^(3/s)': forecastData25[index],
                'flow_75% _m^(3/s)': forecastData75[index],
                'flow_avg_m^(3/s)': forecastDataAvg[index],
                'flow_min_m^(3/s)': forecastDataMin[index],
                'flow_max_m^(3/s)': forecastDataMax[index],
              };
            });
            setForcastData(forecast);
          }
          let ts = data['time_series'];
          let dt_rv = ts['datetime'].concat(ts['datetime'].slice().reverse());
          let shortDates = _.uniqBy(
            dt_rv.map((data) => moment(new Date(data).toISOString()).format('MM/DD')),
          );
          const dailyAverageDate = dailyAverageData.time_series.datetime
            .map((date, index) => {
              return {
                date: date,
                value: dailyAverageData.time_series.flow[index],
              };
            })
            .filter((data) => shortDates.includes(data.date));
          let tracesData = [];
          tracesData = [
            {
              name: 'Max/Min Flow',
              x: dt_rv,
              y: ts['flow_max_m^3/s'].concat(ts['flow_min_m^3/s'].slice().reverse()),
              mode: 'lines',
              type: 'scatter',
              // fill: 'toself',
              fill: 'tozeroy',
              fillcolor: 'lightorange',
              line: { color: 'orange', dash: 'dash' },
            },
            {
              name: '25-75% Flow',
              x: dt_rv,
              y: ts['flow_75%_m^3/s'].concat(ts['flow_25%_m^3/s'].slice().reverse()),
              mode: 'lines',
              type: 'scatter',
              // fill: 'toself',
              fill: 'tozeroy',
              fillcolor: 'lightgreen',
              line: { color: 'darkgreen', dash: 'dash' },
            },
            {
              name: 'Forecasted Flow',
              x: ts['datetime'],
              y: ts['flow_avg_m^3/s'],
              mode: 'lines',
              type: 'scatter',
              fill: 'none',
              line: { color: 'blue' },
            },
            {
              name: 'Historical Average Flow',
              x: dailyAverageDate?.map((data) =>
                moment(data.date + '/' + new Date().getFullYear())
                  .utc()
                  .format(),
              ),
              y: dailyAverageDate.map((data) => data.value),
              mode: 'lines',
              type: 'scatter',
              fill: 'none',
              line: { color: 'black', dash: 'dash' },
              visible: 'legendonly',
            },
          ];
          let traces2 = [];
          if (returnPeriodData?.length) {
            returnPeriodData.forEach((data, index) => {
              if (data) {
                traces2.push({
                  name: `${data.label}: ${Math.round(data.value)}`,
                  x: [ts['datetime'][0], ts['datetime'][ts['datetime'].length - 1]],
                  y: ts['flow_max_m^3/s']
                    .concat(ts['flow_min_m^3/s'].slice().reverse())
                    .map((date) => Math.round(data.value)),
                  mode: 'lines+markers',
                  fill: 'tonexty',
                  // type: 'scatter',
                  visible: 'legendonly',
                  legendgrouptitle: {
                    text: 'Return Periods ',
                  },
                  legendgroup: 'returnGroup',
                  // stackgroup: 'one',
                  line: { color: colors[index], dash: 'dash' },
                });
              }
            });
          }
          tracesData = tracesData.concat(traces2);
          updateTraces(tracesData);
          updateLoading(false);
          upadteError(false);
        }
      })
      .catch(function (error) {
        // handle error
        upadteError(error?.response?.status);
        updateLoading(false);
      });
  };

  let layout = {
    width: 900,
    yaxis: {
      title: 'Flow (cub m)',
      type: 'linear',
      showline: true,
      showgrid: false,
    },
    font: {
      size: 12,
      color: '#3870AF',
      weight: 'bold',
    },
    legend: {
      // orientation: 'h'
      font: {
        size: 12,
        color: '#000',
      },
    },
  };
  const currentData = date
    ? moment(new Date(date)).format('DD-MM-YYYY')
    : moment(new Date(maxDateFormat)).format('DD-MM-YYYY');
  const minDate = new Date().setMonth(new Date().getMonth() - 1);
  
  return (
    <>
      {!isLoading ? (
        <div>
          <div className="py-3">
            <Row>
              <Col lg={6}>
                <div className="blue-text">
                  <h5>
                    Reach ID : <b>{reachId}</b> (Lat : {latitude}, Log : {longitude})
                  </h5>
                </div>
              </Col>
              <Col lg={6}>
                <div style={{ float: 'right' }}>
                  <span style={{ color: '#3870af' }}>Forecast Date : </span>
                  <input
                    type="date"
                    id="datepicker"
                    max={maxDateFormat}
                    min={moment(new Date(minDate)).format('YYYY-MM-DD')}
                    value={date ? moment(new Date(date)).format('YYYY-MM-DD') : maxDateFormat}
                    onChange={(e) => setDate(e.target.value)}
                    onBlur={updateGraph}
                  />
                </div>
              </Col>
              {/* <Col lg={3}> */}
              {/* <a
              className="btn btn-download ml-auto"
              role="button"
              id="download-forecast-btn"
              target="_blank"
              href={`${endpoint}ForecastStats/?reach_id=${reachId}`}
              rel="noreferrer"
            >
              <span style={{ color: 'white' }}>Download Forecast</span>
            </a> */}
              {/* </Col> */}
            </Row>
          </div>
          {error === 500 ? (
            <span>No Records Found</span>
          ) : (
            <>
              <Card>
                <CommonPlotly
                  traces={traces}
                  config={layout}
                  layout={layout}
                  customFileName={`${reachId}_${currentData}_ForecastData`}
                />
              </Card>
              <div style={{ float: 'right', marginTop: '10px' }}>
                {forecastData && (
                  <ExcelFile
                    filename={`${reachId}_${currentData}_ForecastData`}
                    element={
                      <button className="btn btn-download ml-auto" style={{ color: 'white' }}>
                        Download
                      </button>
                    }
                  >
                    <ExcelSheet data={forecastData} name="ForecastData">
                      <ExcelColumn label="datetime" value="datetime" />
                      <ExcelColumn label="flow_25%_m^(3/s)" value="flow_25%_m^(3/s)" />
                      <ExcelColumn label="flow_75% _m^(3/s)" value="flow_75% _m^(3/s)" />
                      <ExcelColumn label="flow_avg_m^(3/s)" value="flow_avg_m^(3/s)" />
                      <ExcelColumn label="flow_max_m^(3/s)" value="flow_max_m^(3/s)" />
                      <ExcelColumn label="flow_min_m^(3/s)" value="flow_min_m^(3/s)" />
                    </ExcelSheet>
                    {/* {returnPeriodData && ( */}
                      <ExcelSheet data={returnPeriodData} name="ReturnPeriodsData">
                      <ExcelColumn label="Year" value="year" />
                      <ExcelColumn label="value" value="value" />
                    </ExcelSheet>
                    {/* )}  */}
                  </ExcelFile>
                )}
              </div>
            </>
          )}
        </div>
      ) : error !== false && !isLoading? (
        <span>We're sorry! Server is not responding. Please try again.</span>
      ) : (
        <Loader />
      )}
    </>
  );
}