import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-multi-lang';
import Button from 'reactstrap-button-loader';

import { Field, reduxForm } from 'redux-form';
import { renderTextField } from '../../Components/common/renderInputField';
import { useDispatch } from 'react-redux';

import { sendEmailRequest, getIndices, deleteProjectRequest } from '../../redux/actions';

import { ReactMultiEmail } from 'react-multi-email';

import 'react-multi-email/style.css';

import CrossIcon from '../../assets/Images/crossIcon.png';

import projectDeleteConfirmationIcon from '../../assets/Images/projectDeleteConfirmationIcon.png';

import { emailRegex } from '../../Components/common/utils';

const ProjectDeleteModal = props => {
  const { modalOpen, setModalOpen, projectId } = props;
  const [selectedTab, setSelectedTab] = useState();
  //const [continueClicked, setContinueClicked] = useState(false);
  const t = useTranslation();
  const history = useHistory();
  const [modal, setModal] = useState();

  const [emails, setEmails] = useState([
    // "fulano@email.com",
    // "sicrano@email.com",
    // "beltrano@email.com"
  ]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  let _invalidEmails = [];
  const [showError1, setError1] = useState(false);
  const removeDuplicates = list => Array.from(new Set(list));

  useEffect(() => {
    setModal(modalOpen);
    setSelectedTab('weather-forecast');
  }, [modalOpen]);

  const viewtoggle = value => {
    setModal(false);
    // handleModalToggle();
  };

  const dispatch = useDispatch();

  const handleClose = () => {
    setModal(false);
    setModalOpen(false);
    // handleModalToggle();
  };

  const handleContinue = e => {
    if (e==="cancel") {
    handleClose();

    }
    else {

    
    dispatch(deleteProjectRequest(projectId));
    handleClose();



    }



  };

  return (
    <>
      <div className="shareinformationmodal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className="shareinformationmodal"
          backdrop="static"
          style={{ width: '75%' }}
        >
          <ModalBody style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <img src={projectDeleteConfirmationIcon} alt="projectDelete" />
          </ModalBody>
          
          <ModalBody style={{ textAlign: 'center' }}>
            <b style={{fontSize:"19px"}}>Delete Project?</b>
            <br />
            <span style={{ color: 'rgba(54, 54, 54, 0.7)'  , fontSize:"14px"}}>
            Deleting the selected project will result in automatic deletion of the related farms and information.
            </span>
          </ModalBody>

          <ModalFooter className="modal-viewdeatil-footer" style={{ width: '100%' }}>
            <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Col lg={5}> </Col>
              <Col lg={3}>
                <div className="update-graph " id="view_graph" data-tip data-for="view_graph">
                  <Button
                    className="navyBlueBgColor-outline"
                    onClick={() => handleContinue("cancel")}
                    style={{ width: '100px', height: '45px', fontSize: '16px', fontWeight: '600' }}
                    type="submit"
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
              <Col lg={3}>
                <Button
                  className="weather-forecast-continue-button"
                  onClick={() => handleContinue("submit")}
                  style={{ width: '100px' }}
                  type="submit"
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </ModalFooter>
          {/* </form> */}
        </Modal>
        {/* </form> */}
      </div>
      {/* {continueClicked &&
      (
      <FilterTabs 
      selectedTab={selectedTab}
      />
      )
      } */}
    </>
  );
};

// export default RiskAnalyticsModal;

export default reduxForm({
  form: 'ProjectDeleteModal',

  enableReinitialize: true,
})(ProjectDeleteModal);
