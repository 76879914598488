import '../../assets/CSS/static-page.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Row, Col } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import ConfirmationModal from './Confirmation';
import classnames from 'classnames';
import RightSideBar from './RightFilter';
import Filter from './Filter';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT, DEFAULT_COUNTRY } from '../../Components/constants';
import Loader from '../../Components/common/loader';
import RightArrow from '../../assets/Images/back-button.svg';
import Image_not_available from '../../assets/Images/Image_not_available.svg';
import { t } from 'react-multi-lang';
import { getCountryRequest } from '../../redux/location/actions';
import { getWeatherForecastSources } from '../../redux/weather/weatherActions';
import FilterIcon from '../../assets/Images/icons/mi_filter.svg';
import { FORECAST_LEAD_TIME_LIST } from '../../Components/constants';
import {
  staticResourceRequest,
  deleteStaticResourceRequest,
  deleteStaticResourceSuccess,
} from '../../redux/staticResource/staticResourceAction';
import { userDetailRequest } from '../../redux/manage-user/manageUserAction';
import { showError } from '../../Components/Notifications.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isStaffUser, isSuperAdmin } from '../../Components/common/utils';

const ManageStaticData = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [activeTab, setActiveTab] = useState('2');
  const [toggleRightBar, setToggleRightBar] = useState(false);
  const [isHideBar, setHideBar] = useState(false);
  const [countryList, setCountryList] = useState();
  const [dataSources, setDataSources] = useState();
  const [dataSourceList, setDataSourceList] = useState();
  const [parameterList, setParameterList] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [isIRISelected, setIsIRISelected] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [file, setFile] = useState();
  const regionList = [{ id: DEFAULT_COUNTRY.id, name: 'Sri Lanka' }];
  const req_data = {
    region: '',
    country: '',
    forecast_data_source: '',
    parameter: '',
    start_date: '',
    end_date: '',
    source_link: '',
    description: '',
    image: '',
    forecast_year_month: '',
  };
  const filter_req_data = {
    // region_id: '',
    // country_id: '',
    weather_forecast_source_id: [],
    weather_forecast_parameter_id: [],
    start_date: null,
    end_date: null,
    limit: 0,
    iri_start_date:"",
    iri_end_date: ""
  };
  const dataSource_type = 'static';
  const dataSource = 'ERPAS (Static)';
  const [formFilterData, setFormFilterData] = useState(filter_req_data);
  const [formData, setFormData] = useState(req_data);
  const [imageExtension, setImageExtension] = useState();
  const [deleteId, setDeleteId] = useState();
  const [haltInfiniteScroll, setHaltInfiniteScroll] = useState(true);
  const [staticResourceList, setStaticResourcesList] = useState();
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [selectedStaticResourceId, setSelectedStaticResourceId] = useState();
  const [editClicked, setEditClicked] = useState(false);
  const [pageCount, setCount] = useState();
  const [filterFormData, setFilterFormData] = useState([]);
  const [filterDataSources, setFilterDataSources] = useState();
  const [closed, setClosed] = useState(false);
  const [updatedPermissions, setUpdatedPermissions] = useState();
  const [categoryName, setCategoryName] = useState('');
  const [isDelete, setIsDelete] = useState();

  const ORDER_BY = { order_by: 'created_date' };

  const nextProps = useSelector((state) => ({
    Location: state.Location.countryList,
    staticResource: state.staticResource,
    ForeCastSourcesData: state.Weather.weatherForecastSourcesData,
    userDetailData: state.UserList.userDetailData || null,
  }));

  useEffect(() => {
    if (nextProps.userDetailData) {
      let a = [];
      const {
        data: { result },
      } = nextProps.userDetailData;
      if (result && result?.user_permissions?.length) {
        result?.user_permissions?.map((i) => {
          a.push(i.codename);
        });
        setUpdatedPermissions(a);
      }
    }
  }, [nextProps.userDetailData]);

  const {
    staticResourceData,
    isStaticResourceRequesting,
    isDeleteStaticResourceRequesting,
    staticResourceDataError,
    staticResourceAddData,
    updateStaticResourceData,
  } = nextProps.staticResource;
  useEffect(() => {
    return () => {
      dispatch(deleteStaticResourceSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (staticResourceDataError) {
      const {
        data: { response },
      } = staticResourceDataError;
      if (response) {
        showError(response?.data?.message);
      }
    }
  }, [staticResourceDataError]);

  const toggle = (tab) => {
    // setPageNumber(1);
    if (activeTab !== tab) setActiveTab(tab);
    if (tab === '2') {
      setHaltInfiniteScroll(true);
      let reqData = {
        order_by: ORDER_BY.order_by,
        page: pageNumber,
      };
      setNoRecordsFound(false);
      dispatch(staticResourceRequest(reqData));
    } else {
      setHaltInfiniteScroll(false);
    }
  };

  const handleSelectedTab = () => {
    //setPageNumber(1);
    setActiveTab('2');
    setHaltInfiniteScroll(true);
    let reqData = {
      order_by: ORDER_BY.order_by,
      page: pageNumber,
    };
    // dispatch(staticResourceRequest(reqData));
  };

  const handleFilter = (data) => {
    let dataSrcIdArray = [];
    let tempData = { ...data };
    filterFormData.dataSourceData.forEach((i) => {
      i.data.forEach((item) => {
        if (item?.checked) {
          dataSrcIdArray?.push(item.weather_forecast_source.id);
          tempData.weather_forecast_source_id = dataSrcIdArray;
        }
      });
    });
    setActiveTab('2');
    setHaltInfiniteScroll(true);
    setFormFilterData(data);
    let reqData = {
      ...tempData,
      order_by: ORDER_BY.order_by,
      // page: pageNumber,
      page: 1,
    };
    setPageNumber(1);
    setNoRecordsFound(false);
    dispatch(staticResourceRequest(reqData));
  };

  useEffect(() => {
    if (staticResourceData) {
      // window.scroll(0, 0);
      // window.scrollTo({ top: 0, behavior: 'auto' });
      const {
        data: { result },
      } = staticResourceData;
      let new_list = [];
      if (staticResourceList) {
        if (pageNumber > 1) {
          new_list = staticResourceList.concat(result.results);
        } else {
          new_list = result.results;
        }
      } else {
        new_list = result.results;
      }
      setStaticResourcesList(new_list);
      setCount(result.count);
    }
  }, [staticResourceData]);

  useEffect(() => {
    if (activeTab === '1') {
      setToggleRightBar(false);
    }
  }, [activeTab]);

  useEffect(() => {
    if (staticResourceAddData || updateStaticResourceData) {
      setPageNumber(1);
      let reqData = {
        order_by: ORDER_BY.order_by,
        page: 1,
      };
      dispatch(staticResourceRequest(reqData));
    }
  }, [staticResourceAddData, updateStaticResourceData]);

  useEffect(() => {
    window.scroll(0, 0);
    if (isSuperAdmin() || isStaffUser()) {
      let reqData = {
        order_by: ORDER_BY.order_by,
        page: pageNumber,
      };
      setActiveTab('2');
      setHaltInfiniteScroll(true);
      dispatch(getCountryRequest({ location_type: 'Country' }));
      dispatch(staticResourceRequest(reqData));
      dispatch(getWeatherForecastSources({ country_id: DEFAULT_COUNTRY.id }));
    } else {
      history.push('/home');
    }
    dispatch(userDetailRequest());
  }, []);

  useEffect(() => {
    if (nextProps.ForeCastSourcesData) {
      setFilterDataSources(nextProps.ForeCastSourcesData);
      const { data } = nextProps.ForeCastSourcesData;
      if (data?.result) {
        let d = [];
        data?.result.map((i) => {
          if (i.category !== 'Weather Forecast') {
            i.name = i.category;
            i.id = i.category;
            d.push(i);
          }
        });
        setDataSources(d);
      }
    }
  }, [nextProps.ForeCastSourcesData]);

  const handleDataSourcesEmptyListChange = () => {
    setDataSources([]);
  };

  const closeRightBar = () => {
    setToggleRightBar(false);
  };

  const handleCategoryData = (data) => {
    if (data) {
      let d = [];
      data.map((i) => {
        if (
          i.weather_forecast_source.type === dataSource_type &&
          i.weather_forecast_source.name !== dataSource
        ) {
          i.name = i.weather_forecast_source.name;
          i.id = i.weather_forecast_source.id;
          d.push(i);
        }
      });
      setDataSourceList(d);
    } else {
      setDataSourceList([]);
    }
  };

  const handlePreSelectedDataSource = (data, id) => {
    if (data) {
      formData.weather_forecast_source = id;
      setFormData({ ...formData });
      let a = [];
      // if(id !== "e2f886a7-3299-4c82-af2e-123fe92faf84"){
      data.map((i) => {
        i.weather_forecast_parameter.map((item) => {
          item.name = item.weather_forecast_parameter.name;
          item.id = item.weather_forecast_parameter.id;
          a.push(item);
        });
      });
      setParameterList(a);
      if (id !== 'e2f886a7-3299-4c82-af2e-123fe92faf84') {
        setIsIRISelected(false);
      } else {
        setIsIRISelected(true);
      }
      //}
      //else{
      //setIsIRISelected(true)
      //setParameterList([])
      //}
    } else {
      setParameterList([]);
    }
  };

  const handleSelectedDataSource = (data, id) => {
    if (id === 'e2f886a7-3299-4c82-af2e-123fe92faf84') {
      setIsIRISelected(true);
    } else {
      setIsIRISelected(false);
    }
    if (data) {
      formData.weather_forecast_source = id;
      setFormData({ ...formData });
      let a = [];
      data.map((i) => {
        i.name = i.weather_forecast_parameter.name;
        i.id = i.weather_forecast_parameter.id;
        a.push(i);
      });
      setParameterList(a);
    } else {
      setParameterList([]);
    }
  };

  const handleParameter = (id) => {
    formData.parameter = id;
    setFormData({ ...formData });
  };

  const startDateHandler = (value) => {
    formData.start_date = value;
    setFormData({ ...formData });
  };

  const forecastYearMonthHandler = (value) => {
    formData.forecast_year_month = value;
    setFormData({ ...formData });
  };

  const forecastLeadTimeHandler = (e) => {
    formData.forecast_lead_time = e.value;
    setFormData({ ...formData });
  };

  const handleManageCropsBackButton = () => {
    history.push('/settingsDashboard');
  };

  const endDateHandler = (value) => {
    formData.end_date = value;
    setFormData({ ...formData });
  };
  const linkHandler = (value) => {
    formData.source_link = value;
    setFormData({ ...formData });
  };

  const descriptionHandler = (value) => {
    formData.description = value;
    setFormData({ ...formData });
  };
  const fileHandler = (value, e) => {
    if (e?.target?.value) {
      setImageExtension(e?.target?.value?.substr(e?.target?.value?.lastIndexOf('.') + 1));
    }
    setHaltInfiniteScroll(false);
    setActiveTab('1');
    setStaticResourcesList([]);
    setFile(value);
  };

  const noRecordsErrorHandler = () => {
    setNoRecordsFound(true);
  };

  const handleFilterFormData = (data, i) => {
    setPageNumber(1);
    setFormFilterData({ ...data });
  };
  const handleEditDataSource = (i) => {
    setEditClicked(true);
    let categoryName;
    if (
      i.weather_forecast_source_name === 'CPC (Static)' ||
      i.weather_forecast_source_name === 'IRI'
    ) {
      categoryName = 'Seasonal';
    } else {
      categoryName = 'Sub - Seasonal';
    }

    let filterInitialValues = {
      content_id: i.id,
      weather_forecast_source_id: i.weather_forecast_source_id,
      weather_forecast_parameter_id: i.weather_forecast_parameter_id,
      start_date: i.start_date,
      source_link: i.source_link,
      forecast_category: {
        value: categoryName,
        label: categoryName,
        id: categoryName,
      },

      datasource_type: {
        value: i.weather_forecast_source_name,
        label: i.weather_forecast_source_name,
        id: i.weather_forecast_source_id,
      },
      parameter_type: {
        value: i.weather_forecast_parameter_name,
        label: i.weather_forecast_parameter_name,
        id: i.weather_forecast_parameter_id,
      },
      end_date: i.end_date,
      description: i.description,
      image: i.image,
      forecast_lead_time: forecastPreSelectedLeadTime(
        i?.forecast_lead_time,
        i?.forecast_time_month,
      ),
      forecast_year_month: forecastPreSelectedYearMonth(
        i?.forecast_time_year,
        i?.forecast_time_month,
      ),
    };
    setEditFormData(filterInitialValues);

    dispatch(getWeatherForecastSources({ country_id: DEFAULT_COUNTRY.id }));
  };

  const forecastPreSelectedLeadTime = (y, month) => {
    let list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    let option_list = list?.splice(parseInt(month), 4);
    let item;
    let latest_list;
    // if(month >= 9){
    let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    let new_arr = new_list?.splice(parseInt(0), 4 - option_list.length);
    latest_list = option_list.concat(new_arr);
    // }
    latest_list?.map((i, index) => {
      i.name = i.name + '(' + `${index + 1}.0` + ')';
      i.label = i.label + '(' + `${index + 1}.0` + ')';
    });
    latest_list?.map((k) => {
      if (
        parseInt(y) ===
        parseInt(
          k.name?.substr(k.name?.indexOf('(') + 1, k.name?.indexOf(')') - k.name?.indexOf('(') - 1),
        )
      ) {
        item = k;
      }
    });
    return item;
  };

  const forecastPreSelectedYearMonth = (x, y) => {
    return x + '-' + y;
  };

  const updateInitialValues = ({ dataSourceData, parameterData, start_date, end_date }) => {
    let data = {
      dataSourceData,
      parameterData,
      start_date,
      end_date,
    };
    setFilterFormData(data);
  };

  const handleResetInitialValues = () => {
    let filterInitialValues = {
      content_id: '',
      weather_forecast_source_id: '',
      weather_forecast_parameter_id: '',
      category_name: '',
      start_date: '',
      source_link: '',
      datasource_type: '',
      parameter_type: '',
      end_date: '',
      description: '',
      image: '',
      static_resource_id: '',
      forecast_lead_time: '',
    };
    setEditFormData(filterInitialValues);
  };

  const handleDeleteDataSource = (id) => {
    setIsDelete(id);
    setConfirmationModalOpen(true);
    setSelectedStaticResourceId(id);
  };

  const handleUpdatedListData = () => {
    setPageNumber(1);
    let reqData = {
      order_by: ORDER_BY.order_by,
      page: 1,
    };
    dispatch(staticResourceRequest(reqData));
  };

  const handleResetIRIFields = (bool_val) => {
    setIsIRISelected(bool_val);
  };

  const handleModalToggle = () => {
    setConfirmationModalOpen(false);
  };

  const handleDeleteConfirm = () => {
    setDeleteId(selectedStaticResourceId);
    dispatch(deleteStaticResourceRequest(selectedStaticResourceId));
  };

  const fetchNextStaticResources = () => {
    if (haltInfiniteScroll) {
      let reqData = {
        ...formFilterData,
        order_by: ORDER_BY.order_by,
        page: pageNumber + 1,
      };
      setPageNumber(pageNumber + 1);
      dispatch(staticResourceRequest(reqData));
    }
  };
  const handleClearFilterInitialValues = (item, paramItem) => {
    setFormFilterData(filter_req_data);
    let data = {
      dataSourceData: item,
      parameterData: paramItem,
      start_date: '',
      end_date: '',
    };
    setFilterFormData(data);
  };
  const handleClose = () => {
    setClosed(true);
  };
  const handleResetInitializeData = () => {
    setPageNumber(1);
    let reqData = {
      order_by: ORDER_BY.order_by,
      page: 1,
    };
    setFormFilterData({});
    dispatch(staticResourceRequest(reqData));
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  const handlePreForecastLeadTime = (lead_time, month) => {
    let list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    let option_list = list?.splice(parseInt(month), 4);
    let lead_time_label;
    let latest_list;
    let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    let new_arr = new_list?.splice(parseInt(0), 4 - option_list.length);
    latest_list = option_list.concat(new_arr);
    latest_list?.map((i, index) => {
      i.name = i.name + '(' + `${index + 1}.0` + ')';
    });
    latest_list?.map((k) => {
      if (
        parseInt(lead_time) ===
        parseInt(
          k.name?.substr(k.name?.indexOf('(') + 1, k.name?.indexOf(')') - k.name?.indexOf('(') - 1),
        )
      ) {
        lead_time_label = k;
      }
    });
    return lead_time_label?.name;
  };

  return (
    <>
      <div className="loader-div">
        {isStaticResourceRequesting &&  <Loader isManageStaticResourceData = {true}/>}
      </div>
      <div className="st-helper-sidebar">
        {toggleRightBar && (
          <RightSideBar
            formFilterData={formFilterData}
            handleFilterFormData={handleFilterFormData}
            closeRightBar={closeRightBar}
            handleFilter={handleFilter}
            noRecordsErrorHandler={noRecordsErrorHandler}
            initialValues={filterFormData}
            updateInitialValues={updateInitialValues}
            handleClearFilterInitialValues={handleClearFilterInitialValues}
            filterDataSources={filterDataSources}
            closed={closed}
            handleClose={handleClose}
            staticResourceList={staticResourceList}
            setStaticResourcesList={setStaticResourcesList}
            handleResetInitializeData={handleResetInitializeData}
          />
        )}

        <div className="static-resource-wrapper">
          <Row className="">
            {!isHideBar && (
              <Col md={2} lg={2} className="filter-col">
                <div className="hide-bar">
                  <span className="cursor-ponter navyBlueColor ">
                    {t('FILTER.HIDE_BAR')}
                    <span>
                      <MdKeyboardArrowRight
                        className="hide-icon expand-margin"
                        onClick={() => setHideBar(!isHideBar)}
                        size={25}
                      />
                    </span>
                  </span>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            {/* {!isHideBar ? ( */}
            {!isHideBar?
            <Col lg={2} md={2} className="filter-col">
              <div className="filter-section">
                <Filter
                  updatedPermissions={updatedPermissions}
                  descriptionHandler={descriptionHandler}
                  setPageNumber={setPageNumber}
                  dataSources={dataSources}
                  editClicked={editClicked}
                  handleCategoryData={handleCategoryData}
                  dataSourceList={dataSourceList}
                  handleDataSourcesEmptyListChange={handleDataSourcesEmptyListChange}
                  handleSelectedDataSource={handleSelectedDataSource}
                  handlePreSelectedDataSource={handlePreSelectedDataSource}
                  parameterList={parameterList}
                  handleParameter={handleParameter}
                  startDateHandler={startDateHandler}
                  forecastYearMonthHandler={forecastYearMonthHandler}
                  endDateHandler={endDateHandler}
                  linkHandler={linkHandler}
                  fileHandler={fileHandler}
                  handleSelectedTab={handleSelectedTab}
                  initialValues={editFormData}
                  handleUpdatedListData={handleUpdatedListData}
                  handleResetInitialValues={handleResetInitialValues}
                  setEditClicked={setEditClicked}
                  isIRISelected={isIRISelected}
                  forecastLeadTimeHandler={forecastLeadTimeHandler}
                  handleResetIRIFields={handleResetIRIFields}
                  isHideBar={isHideBar}
                />
              </div>
            </Col>:""}
            {/* ) : (
              ''
            )} */}
            <Col lg={isHideBar ? 12 : 10} md={10} className="static-data-col">
              {isHideBar && (
                <div className="filter-expand-bar">
                  <span className="cursor-ponter navyBlueColor ">
                    {t('FILTER.EXPAND_BAR')}
                    <span>
                      <MdKeyboardArrowLeft
                        className="hide-icon expand-margin"
                        onClick={() => setHideBar(!isHideBar)}
                        size={25}
                      />
                    </span>
                  </span>
                </div>
              )}
              <div className={isHideBar ? 'st-heading-left row mt-4' : 'manage-static-heading row'}>
                <span className="col-lg-1">
                  <img
                    src={RightArrow}
                    className="manage-static-header-back-button"
                    onClick={() => handleManageCropsBackButton()}
                  />
                </span>
                <span className="col-lg-5">
                  <h5>{t('FILTER.MANAGE_DATA')}</h5>
                </span>
                {activeTab === '2' ? (
                  <span
                    onClick={() => {
                      setToggleRightBar(!toggleRightBar);
                    }}
                    className="filter-icon-text col-lg-6"
                  >
                    <img src={FilterIcon} alt={'not-available'} className="st-filter-img" />
                    <h5 className="st-filter-text">{t('FILTER.FILTER')} </h5>
                  </span>
                ) : (
                  ''
                )}
              </div>

              <div className="st-tab-wrapper">
                <Row>
                  <Nav className="st-tabs" tabs>
                    <NavItem className="st-li">
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggle('1');
                        }}
                      >
                        {t('FILTER.NEW_UPLOADS')}
                      </NavLink>
                    </NavItem>
                    <NavItem className="st-li">
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          toggle('2');
                        }}
                      >
                        {t('FILTER.PREVIOUSLY_UPLOADED')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Row>
              </div>
              <hr className="st-hr" />
              <div className="st-tab-content mt-4">
                <Row>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col lg="6" className="mx-auto">
                          <div className="mb-5">
                            {file ? (
                              file.substr(file.lastIndexOf('.') + 1) !== 'tiff' ? (
                                imageExtension !== 'tiff' ? (
                                  <img className="img-fluid" src={file} alt={'not-available'} />
                                ) : (
                                  <img
                                    className="img-fluid"
                                    src={Image_not_available}
                                    alt={'preview not-available'}
                                  />
                                )
                              ) : (
                                <img
                                  className="img-fluid"
                                  src={Image_not_available}
                                  alt={'preview not-available'}
                                />
                              )
                            ) : (
                              <p className="new-upload-no-data">No data available</p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col lg="12">
                          <div className="st-card-wrapper">
                            {/* <div className="loader-div">
                              {isStaticResourceRequesting && <Loader />}
                            </div> */}
                            {noRecordsFound && (
                              <div style={{textAlign: 'center'}}>
                                <p>No records found</p>
                              </div>
                            )}
                            <InfiniteScroll
                              dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
                              next={fetchNextStaticResources}
                              hasMore={pageNumber * 10 < pageCount ? true : false}
                              loader={<h4>Loading ... </h4>}
                            >
                              {staticResourceList?.map((i, key) => {
                                return (
                                  <Card className="st-card card-one card-min-height">
                                    {i?.image?.substr(i?.image.lastIndexOf('.') + 1) !== 'tiff' ? (
                                      <img
                                        src={i.image}
                                        alt={'not-available'}
                                        className="card-img-top"
                                      />
                                    ) : (
                                      <img
                                        className="img-fluid"
                                        src={Image_not_available}
                                        alt={'preview not-available'}
                                      />
                                    )}
                                    <div className="card-body">
                                      <h5 className="card-title font-weight-light st-txt-primary">
                                        {i.description}
                                      </h5>
                                      <p className="card-text">
                                        {t('FILTER.SOURCE')}: {i.weather_forecast_source_name} |{' '}
                                        {t('FILTER.PARAMETER')}: {i.weather_forecast_parameter_name}
                                      </p>
                                      {i?.weather_forecast_source_id ===
                                      'e2f886a7-3299-4c82-af2e-123fe92faf84' ? (
                                        <>
                                          <p className="card-text">
                                            {t('FILTER.FORECAST_DATE')} :{' '}
                                            {moment(
                                              i?.forecast_time_year + '-' + i?.forecast_time_month,
                                            ).format('YYYY-MM')}
                                          </p>
                                          <p className="card-text">
                                            {t('FILTER.FORECAST_LEAD_TIME')} :{' '}
                                            {handlePreForecastLeadTime(
                                              i?.forecast_lead_time,
                                              i?.forecast_time_month,
                                            )}
                                          </p>
                                        </>
                                      ) : (
                                        <p className="card-text">
                                          {t('FILTER.DATE_RANGE')} :{' '}
                                          {moment(i.start_date).format(DISPLAY_DATE_FORMAT)} |{' '}
                                          {moment(i.end_date).format(DISPLAY_DATE_FORMAT)}
                                        </p>
                                      )}

                                      {i.source_link && (
                                        <p className="card-text">
                                          {' '}
                                          {t('FILTER.LINK')}:{' '}
                                          <a href={i.source_link}>{i.source_link}</a>
                                        </p>
                                      )}
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'right',
                                        }}
                                      >
                                        {i.weather_forecast_source_name !== 'ERPAS (Static)' && (
                                          <div className="edit-delete-options new-options">
                                            <Button
                                              color="secondary"
                                              // style={{ width: '130px' }}
                                              onClick={() => handleEditDataSource(i)}
                                              disabled={
                                                isSuperAdmin() ||
                                                updatedPermissions?.includes('change_content')
                                                  ? false
                                                  : true
                                              }
                                              style={{ width: '6vw', fontSize: '19px' }}
                                            >
                                              {t('FILTER.EDIT')}
                                            </Button>
                                            <Button
                                              id={`deleteButton${key}`}
                                              color="secondary"
                                              className="delete-btn"
                                              // style={{ width: '130px' }}
                                              loading={i.id == deleteId ? true : false}
                                              // loading={
                                              //   isDeleteStaticResourceRequesting &&
                                              //   isDelete === i.id && {
                                              //     isDeleteStaticResourceRequesting,
                                              //   }
                                              // }
                                              onClick={() => {
                                                handleDeleteDataSource(i.id);
                                                document
                                                  .getElementById(`deleteButton${key}`)
                                                  .blur();
                                              }}
                                              disabled={
                                                isSuperAdmin() ||
                                                updatedPermissions?.includes('delete_content')
                                                  ? false
                                                  : true
                                              }
                                              style={{ width: '9vw', fontSize: '19px' }}
                                            >
                                              {t('FILTER.DELETE')}
                                            </Button>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </Card>
                                );
                              })}
                            </InfiniteScroll>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {confirmationModalOpen && (
        <ConfirmationModal
          isOpen={confirmationModalOpen}
          handleModalToggle={handleModalToggle}
          handleDeleteConfirm={handleDeleteConfirm}
        />
      )}
    </>
  );
};
export default ManageStaticData;
