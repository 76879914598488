import React, { useEffect, useState } from 'react';
import { setShowDroughtMonitorPopup } from '../../Components/common/utils';
import Droughtmodal from './Drought-modal';
import DroughtMap from './rightmap';
import {DROUGHT_MODULES_CONST} from "../../Components/constants/index"

const DroughtMonitor = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [popUpSelectedModule, setpopUpSelectedModule] = useState();

  // useEffect(() => {
  //   const showDroughtPopup = sessionStorage.getItem('show_drought_popup');
  //   if (showDroughtPopup) {
  //     setShowPopup(false);
  //   } else {
  //     setShowDroughtMonitorPopup();
  //     setShowPopup(true);
  //   }
  // }, []);
  // const moduleHandler = data => {
  //   setpopUpSelectedModule(data);
  // };

  useEffect(() => {
    setpopUpSelectedModule(DROUGHT_MODULES_CONST[3])
  },[])

  return (
    <>
      <DroughtMap popUpSelectedModule={popUpSelectedModule} />
      {/* {showPopup && <Droughtmodal moduleHandler={moduleHandler} />} */}
    </>
  );
};

export default DroughtMonitor;
