import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-multi-lang';
import Button from 'reactstrap-button-loader';

import { change, Field, reduxForm } from 'redux-form';
import { renderTextField } from '../../Components/common/renderInputField';
import { useDispatch, useSelector } from 'react-redux';

import { sendEmailRequest } from '../../redux/actions';

import { ReactMultiEmail } from 'react-multi-email';

import 'react-multi-email/style.css';

import CrossIcon from '../../assets/Images/crossIcon.png';

import { emailRegex } from '../../Components/common/utils';

const validate = (values) => {
  const errors = {};

  const requiredFields = ['subject', 'email', 'description'];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required.';
    }
  });

  return errors;
};

const RiskAnalyticsModal = (props) => {
  const { modalOpen, setModalOpen, graphScreenshotImage, selectedIndicatorRiskParam } = props;
  const [selectedTab, setSelectedTab] = useState();
  const t = useTranslation();
  const history = useHistory();
  let formData = new FormData();
  const [modal, setModal] = useState();

  const [emails, setEmails] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  let _invalidEmails = [];
  const [showError1, setError1] = useState(false);
  const removeDuplicates = (list) => Array.from(new Set(list));
  const { RiskAnalytics } = useSelector((state) => ({
    RiskAnalytics: state.RiskAnalytics,
  }));

  useEffect(() => {
    if (RiskAnalytics?.sendEmailData) {
      if (RiskAnalytics?.sendEmailData?.statusCode === 200) {
        dispatch(change('RiskAnalyticsModal', 'subject', ''));
        dispatch(change('RiskAnalyticsModal', 'description', ''));
        setEmails([]);
      }
    }
  }, [RiskAnalytics?.sendEmailData]);

  useEffect(() => {
    setModal(modalOpen);
    setSelectedTab('weather-forecast');
  }, [modalOpen]);

  const viewtoggle = (value) => {
    setModal(false);
  };

  const dispatch = useDispatch();

  const handleClose = () => {
    setModal(false);
    setModalOpen(false);
  };

  const onSubmit1 = async (e) => {
    if (e.subject) {
      formData.append('subject', e.subject);
    }
    if (emails?.length) {
      formData.append('recipients', emails);
    }
    formData.append('description', e.description);
    if (graphScreenshotImage) {
      const fileData = await dataUrlToFile(
        graphScreenshotImage,
        `Analyze_Risk-${selectedIndicatorRiskParam.map((data) => data.value).join(' ')}.png`,
      );
      formData.append('image', fileData);
    }
    dispatch(sendEmailRequest(formData));
    handleClose();
  };

  async function dataUrlToFile(dataUrl, fileName) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  }

  const validateEmail = (email) => {
    // using this as an "onChange" event and getting rid of old values
    if (invalidEmails !== []) {
      setInvalidEmails([]);
    }

    if (email === 'undefined') {
      return false;
    }

    const isValid = emailRegex.test(email);

    if (!isValid && _invalidEmails.indexOf(email) === -1) {
      _invalidEmails.push(email);
    }
    if (!isValid) {
      setError1(true);
    } else {
      setError1(false);
    }

    return isValid;
  };
  const handleEmail = (e) => {
    validateEmail(e);
    setEmails(e);
  };

  const removeEmail = (e) => {
    setEmails(emails.filter((item, listIndex) => listIndex !== e));
  };

  const { handleSubmit, pristine, reset, submitting } = props;
  return (
    <>
      <div className="shareinformationmodal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className="shareinformationmodal"
          backdrop="static"
          style={{ width: '75%' }}
        >
          <form onSubmit={handleSubmit(onSubmit1)}>
            <ModalHeader className="weather-forecast-modal-title" toggle={handleClose}>
              Share information
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm={6} md={6} lg={12} style={{ marginBottom: '10px' }}>
                  <Field
                    component={renderTextField}
                    name="subject"
                    type="text"
                    placeholder="Subject"
                  />
                </Col>
                <Col sm={6} md={6} lg={12} style={{ marginBottom: '10px' }}>
                  <ReactMultiEmail
                    placeholder="Please enter email addresses."
                    emails={emails}
                    name="email"
                    className="inp-text1"
                    style={{ minHeight: '85px' }}
                    onChange={(_emails) => {
                      handleEmail(_emails);
                    }}
                    getLabel={(email, index) => {
                      return (
                        <div data-tag className="inp-sty1" key={index}>
                          {email}
                          <span data-tag-handle onClick={() => removeEmail(index)}>
                            <img src={CrossIcon} alt="closeIcon" height={7} />
                          </span>
                        </div>
                      );
                    }}
                  />
                </Col>
                <Col sm={6} md={6} lg={12} style={{ marginBottom: '10px' }}>
                  <Field
                    component={renderTextField}
                    name="description"
                    type="textarea"
                    placeholder="Write something here..."
                    style={{ height: '100px' }}
                    rows={3}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="modal-viewdeatil-footer" style={{ width: '100%' }}>
              <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Col lg={9}> </Col>
                <Col lg={3}>
                  <Button
                    className="weather-forecast-continue-button"
                    disabled={emails?.length === 0}
                    style={{ width: '100px' }}
                    type="submit"
                  >
                    Send
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'RiskAnalyticsModal',
  validate,
  enableReinitialize: true,
})(RiskAnalyticsModal);
