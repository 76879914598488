import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-multi-lang';
import {
  FormGroup,
  FormFeedback,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { updateStatusRequest, updateStatusSuccess, userListRequest } from '../../redux/manage-user/manageUserAction';

const ReasonModal = props => {
  const {
    className,
    isOpen,
    modalData,
    latestDataHandler,
    handleRejectModal,
    handleModalToggle,
  } = props;
  const dispatch = useDispatch();
  const t = useTranslation();
  const [textAreaData, setTextAreaData] = useState();
  const [requestData, setRequestData] = useState();
  const [reasonError, setReasonError] = useState(true);
  const [reasonErrorMessage, setReasonErrorMessage] = useState('');
  const nextProps = useSelector(state => ({
    updateStatusData: state.UserList.updateStatusData || '',
    isUpdateStatusRequesting: state.UserList.isUpdateStatusRequesting,
  }));
  useEffect(() => {
    setRequestData(modalData);
  }, [isOpen, modalData]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    return () => {
      dispatch(updateStatusSuccess({}));
    };
  }, [dispatch]);
  const currentprops = nextProps.updateStatusData && nextProps.updateStatusData.data;
  const previousprops = usePrevious(currentprops);

  const isSecondRun = useRef(true);

  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (currentprops && currentprops !== previousprops) {
      if (currentprops) {
        handleRejectModal(false);
        handleModalToggle(false);
        latestDataHandler('reject');
      }
    }
  }, [currentprops, previousprops, handleModalToggle, handleRejectModal, latestDataHandler]);

  const toggle = value => {
    if (value === 'cancel') {
      handleRejectModal(false);
    } else if (value === 'reject') {
      requestData.reject_response = textAreaData;
      dispatch(updateStatusRequest(requestData));
    }
  };

  const handleClose = () => {
    handleRejectModal(false);
  };

  const handleReasonModal = e => {
    setTextAreaData(e.target.value);
    if (e.target.value.length > 250) {
      setReasonError(true);
      setReasonErrorMessage('Reason must be less than 250 characters');
    } else if (e.target.value.length >= 1) {
      setReasonError(false);
    } else if (e.target.value.length === 0) {
      setReasonError(true);
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className={className} >
        <ModalHeader toggle={() => handleClose()} className="reason-modal-header">
          {t('MANAGE_USER.REASON_TITLE')}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <FormFeedback invalid={reasonError}>{reasonErrorMessage}</FormFeedback>
            <Input
              type="textarea"
              name="text"
              id="exampleText"
              className="reason-modal-textarea"
              placeholder="Write a reason for rejection."
              onChange={e => handleReasonModal(e)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="reason-modal">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
            <Button
              color="secondary"
              onClick={() => toggle('cancel')}
              className="cancel-button me-3"
            >
              {t('MANAGE_USER.CANCEL')}
            </Button>{' '}
            <Button
              onClick={() => toggle('reject')}
              className="modal-reject-button"
              loading={nextProps.isUpdateStatusRequesting}
              disabled={reasonError}
            >
              {t('MANAGE_USER.REJECT')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReasonModal;
ReasonModal.propTypes = {
  isOpen: PropTypes.bool,
  modalData: PropTypes.object,
  latestDataHandler: PropTypes.func,
  handleRejectModal: PropTypes.func,
  handleModalToggle: PropTypes.func,
};
