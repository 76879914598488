import {
  CROP_HEALTH_MONITORING_CONST,
  CROP_HEALTH_MONITORING_MAP_CONST,
  CROP_HEALTH_CONST_GET_ALL_INDICES,
  CROP_HEALTH_WATER_MONITORING_MAP_CONST,
  CROP_HEALTH_MONITORING_TIME_SERIES_CONST,
  CROP_HEALTH_MONITORING_WATER_TIME_SERIES_CONST,
  CROP_HEALTH_MONITORING_GLDAS_CONST,
  CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_CONST,
  CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_CONST,
  AGRICULTURE_CONST_INDICATOR_TO_SENSOR,
  WATER_CONST_INDICATOR_TO_SENSOR,
} from './cropHealthMonitoringConsts';

export const getCropIndicesListRequest = data => ({
  type: CROP_HEALTH_MONITORING_CONST.CROP_HEALTH_MONITORING_REQUEST,
  payload: data,
});

export const getCropIndicesListSuccess = response => ({
  type: CROP_HEALTH_MONITORING_CONST.CROP_HEALTH_MONITORING_SUCCESS,
  payload: response,
});

export const getCropIndicesListFailed = error => ({
  type: CROP_HEALTH_MONITORING_CONST.CROP_HEALTH_MONITORING_FAILED,
  payload: error,
});
//AGRICULTURE INDICATOR TO SENSOR
export const getAgricultureIndicatorToSensorListRequest = data => ({
  type: AGRICULTURE_CONST_INDICATOR_TO_SENSOR.AGRICULTURE_CONST_INDICATOR_TO_SENSOR_REQUEST,
  payload: data,
});

export const getAgricultureIndicatorToSensorListSuccess = response => ({
  type: AGRICULTURE_CONST_INDICATOR_TO_SENSOR.AGRICULTURE_CONST_INDICATOR_TO_SENSOR_SUCCESS,
  payload: response,
});

export const getAgricultureIndicatorToSensorListFailed = error => ({
  type: AGRICULTURE_CONST_INDICATOR_TO_SENSOR.AGRICULTURE_CONST_INDICATOR_TO_SENSOR_FAILED,
  payload: error,
});

export const getAgricultureMapRequest = data => ({
  type: CROP_HEALTH_MONITORING_MAP_CONST.CROP_HEALTH_MONITORING_MAP_REQUEST,
  payload: data,
});

export const getAgricultureMapSuccess = response => ({
  type: CROP_HEALTH_MONITORING_MAP_CONST.CROP_HEALTH_MONITORING_MAP_SUCCESS,
  payload: response,
});

export const getAgricultureMapFailed = error => ({
  type: CROP_HEALTH_MONITORING_MAP_CONST.CROP_HEALTH_MONITORING_MAP_FAILED,
  payload: error,
});
// for water resource
export const getAllIndicesRequest = data => ({
  type: CROP_HEALTH_CONST_GET_ALL_INDICES.CROP_HEALTH_CONST_GET_ALL_INDICES_REQUEST,
  payload: data,
});

export const getAllIndicesSuccess = response => ({
  type: CROP_HEALTH_CONST_GET_ALL_INDICES.CROP_HEALTH_CONST_GET_ALL_INDICES_SUCCESS,
  payload: response,
});

export const getAllIndicesFailed = error => ({
  type: CROP_HEALTH_CONST_GET_ALL_INDICES.CROP_HEALTH_CONST_GET_ALL_INDICES_FAILED,
  payload: error,
});

export const getWaterMapRequest = data => ({
  type: CROP_HEALTH_WATER_MONITORING_MAP_CONST.CROP_HEALTH_WATER_MONITORING_MAP_REQUEST,
  payload: data,
});

export const getWaterMapSuccess = response => ({
  type: CROP_HEALTH_WATER_MONITORING_MAP_CONST.CROP_HEALTH_WATER_MONITORING_MAP_SUCCESS,
  payload: response,
});

export const getWaterMapFailed = error => ({
  type: CROP_HEALTH_WATER_MONITORING_MAP_CONST.CROP_HEALTH_WATER_MONITORING_MAP_FAILED,
  payload: error,
});

export const getAgricultureTimeSeriesRequest = data => ({
  type: CROP_HEALTH_MONITORING_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_TIME_SERIES_REQUEST,
  payload: data,
});

export const getAgricultureTimeSeriesSuccess = response => ({
  type: CROP_HEALTH_MONITORING_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_TIME_SERIES_SUCCESS,
  payload: response,
});

export const getAgricultureTimeSeriesFailed = error => ({
  type: CROP_HEALTH_MONITORING_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_TIME_SERIES_FAILED,
  payload: error,
});

// water timeseries

export const getWaterTimeSeriesRequest = data => ({
  type:
    CROP_HEALTH_MONITORING_WATER_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_WATER_TIME_SERIES_REQUEST,
  payload: data,
});

export const getWaterTimeSeriesSuccess = response => ({
  type:
    CROP_HEALTH_MONITORING_WATER_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_WATER_TIME_SERIES_SUCCESS,
  payload: response,
});

export const getWaterTimeSeriesFailed = error => ({
  type:
    CROP_HEALTH_MONITORING_WATER_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_WATER_TIME_SERIES_FAILED,
  payload: error,
});

///gldas

export const getCropGldasIndicesListRequest = data => ({
  type: CROP_HEALTH_MONITORING_GLDAS_CONST.CROP_HEALTH_MONITORING_GLDAS_REQUEST,
  payload: data,
});

export const getCropGldasIndicesListSuccess = response => ({
  type: CROP_HEALTH_MONITORING_GLDAS_CONST.CROP_HEALTH_MONITORING_GLDAS_SUCCESS,
  payload: response,
});

export const getCropGldasIndicesListFailed = error => ({
  type: CROP_HEALTH_MONITORING_GLDAS_CONST.CROP_HEALTH_MONITORING_GLDAS_FAILED,
  payload: error,
});
//Geoglows drainage
export const getWaterResourceDrainageRequest = data => ({
  type:
    CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_REQUEST,
  payload: data,
});

export const getWaterResourceDrainageSuccess = response => ({
  type:
    CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_SUCCESS,
  payload: response,
});

export const getWaterResourceDrainageFailed = error => ({
  type:
    CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_FAILED,
  payload: error,
});
//Geoglows catchment
export const getWaterResourceCatchmentRequest = data => ({
  type:
    CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_REQUEST,
  payload: data,
});

export const getWaterResourceCatchmentSuccess = response => ({
  type:
    CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_SUCCESS,
  payload: response,
});

export const getWaterResourceCatchmentFailed = error => ({
  type:
    CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_FAILED,
  payload: error,
});

//WATER INDICATOR TO SENSOR
export const getWaterIndicatorToSensorListRequest = data => ({
  type: WATER_CONST_INDICATOR_TO_SENSOR.WATER_CONST_INDICATOR_TO_SENSOR_REQUEST,
  payload: data,
});

export const getWaterIndicatorToSensorListSuccess = response => ({
  type: WATER_CONST_INDICATOR_TO_SENSOR.WATER_CONST_INDICATOR_TO_SENSOR_SUCCESS,
  payload: response,
});

export const getWaterIndicatorToSensorListFailed = error => ({
  type: WATER_CONST_INDICATOR_TO_SENSOR.WATER_CONST_INDICATOR_TO_SENSOR_FAILED,
  payload: error,
});
