export const CROP_HEALTH_MONITORING_CONST = {
  CROP_HEALTH_MONITORING_REQUEST: 'CROP_HEALTH_MONITORING_REQUEST',
  CROP_HEALTH_MONITORING_SUCCESS: 'CROP_HEALTH_MONITORING_SUCCESS',
  CROP_HEALTH_MONITORING_FAILED: 'CROP_HEALTH_MONITORING_FAILED',
};
//AGRICULTURE INDICATOR TO SENSOR
export const AGRICULTURE_CONST_INDICATOR_TO_SENSOR = {
  AGRICULTURE_CONST_INDICATOR_TO_SENSOR_REQUEST: 'AGRICULTURE_CONST_INDICATOR_TO_SENSOR_REQUEST',
  AGRICULTURE_CONST_INDICATOR_TO_SENSOR_SUCCESS: 'AGRICULTURE_CONST_INDICATOR_TO_SENSOR_SUCCESS',
  AGRICULTURE_CONST_INDICATOR_TO_SENSOR_FAILED: 'AGRICULTURE_CONST_INDICATOR_TO_SENSOR_FAILED',
};
export const CROP_HEALTH_MONITORING_MAP_CONST = {
  CROP_HEALTH_MONITORING_MAP_REQUEST: 'CROP_HEALTH_MONITORING_MAP_REQUEST',
  CROP_HEALTH_MONITORING_MAP_SUCCESS: 'CROP_HEALTH_MONITORING_MAP_SUCCESS',
  CROP_HEALTH_MONITORING_MAP_FAILED: 'CROP_HEALTH_MONITORING_MAP_FAILED',
};
// for water resource
export const CROP_HEALTH_CONST_GET_ALL_INDICES = {
  CROP_HEALTH_CONST_GET_ALL_INDICES_REQUEST: 'CROP_HEALTH_LOCATION_REQUEST',
  CROP_HEALTH_CONST_GET_ALL_INDICES_SUCCESS: 'CROP_HEALTH_LOCATION_SUCCESS',
  CROP_HEALTH_CONST_GET_ALL_INDICES_FAILED: 'CROP_HEALTH_LOCATION_FAILED',
};
//WATER INDICATOR TO SENSOR
export const WATER_CONST_INDICATOR_TO_SENSOR = {
  WATER_CONST_INDICATOR_TO_SENSOR_REQUEST: 'WATER_CONST_INDICATOR_TO_SENSOR_REQUEST',
  WATER_CONST_INDICATOR_TO_SENSOR_SUCCESS: 'WATER_CONST_INDICATOR_TO_SENSOR_SUCCESS',
  WATER_CONST_INDICATOR_TO_SENSOR_FAILED: 'WATER_CONST_INDICATOR_TO_SENSOR_FAILED',
};
export const CROP_HEALTH_WATER_MONITORING_MAP_CONST = {
  CROP_HEALTH_WATER_MONITORING_MAP_REQUEST: 'CROP_HEALTH_WATER_MONITORING_MAP_REQUEST',
  CROP_HEALTH_WATER_MONITORING_MAP_SUCCESS: 'CROP_HEALTH_WATER_MONITORING_MAP_SUCCESS',
  CROP_HEALTH_WATER_MONITORING_MAP_FAILED: 'CROP_HEALTH_WATER_MONITORING_MAP_FAILED',
};

export const CROP_HEALTH_MONITORING_TIME_SERIES_CONST = {
  CROP_HEALTH_MONITORING_TIME_SERIES_REQUEST: 'CROP_HEALTH_MONITORING_TIME_SERIES_REQUEST',
  CROP_HEALTH_MONITORING_TIME_SERIES_SUCCESS: 'CROP_HEALTH_MONITORING_TIME_SERIES_SUCCESS',
  CROP_HEALTH_MONITORING_TIME_SERIES_FAILED: 'CROP_HEALTH_MONITORING_TIME_SERIES_FAILED',
};

// water timeseries

export const CROP_HEALTH_MONITORING_WATER_TIME_SERIES_CONST = {
  CROP_HEALTH_MONITORING_WATER_TIME_SERIES_REQUEST:
    'CROP_HEALTH_MONITORING_WATER_TIME_SERIES_REQUEST',
  CROP_HEALTH_MONITORING_WATER_TIME_SERIES_SUCCESS:
    'CROP_HEALTH_MONITORING_WATER_TIME_SERIES_SUCCESS',
  CROP_HEALTH_MONITORING_WATER_TIME_SERIES_FAILED:
    'CROP_HEALTH_MONITORING_WATER_TIME_SERIES_FAILED',
};

//GLDAS
export const CROP_HEALTH_MONITORING_GLDAS_CONST = {
  CROP_HEALTH_MONITORING_GLDAS_REQUEST: 'CROP_HEALTH_MONITORING_GLDAS_REQUEST',
  CROP_HEALTH_MONITORING_GLDAS_SUCCESS: 'CROP_HEALTH_MONITORING_GLDAS_SUCCESS',
  CROP_HEALTH_MONITORING_GLDAS_FAILED: 'CROP_HEALTH_MONITORING_GLDAS_FAILED',
};
//Geoglows discharge forecast
export const CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_CONST = {
  CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_REQUEST:
    'CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_REQUEST',
  CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_SUCCESS:
    'CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_SUCCESS',
  CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_FAILED:
    'CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_FAILED',
};
export const CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_CONST = {
  CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_REQUEST:
    'CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_REQUEST',
  CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_SUCCESS:
    'CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_SUCCESS',
  CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_FAILED:
    'CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_FAILED',
};
