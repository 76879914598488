import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, USER_URL } from '../api/apiEndPoint';
import {REGISTER_CONST,VERIFICATION_CONST} from './registerConst';
import { verificationSuccess, verificationFailed, registerSuccess, registerFailed , verifyValidationSuccess , verifyValidationFailed } from './registerAction';
import baseMethod from '../baseService.js';
import { checkHttpStatus } from '../api/apiUtils';
require('dotenv').config();


function* registerRequest(action) {
    
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_URL}/register`, action.payload),
      "register success",
      false,
      true
    ); 
    const response = yield call(checkHttpStatus, apiResponse);
    //  yield call(registerApiRequest, action.payload);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(registerSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(registerFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(registerFailed(errorData));
  }
}


function* verificationRequest(action) {
    
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_URL}/userVerification`,action.payload),
      "Verfication success",
      false,
      true
    )
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(verificationSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(verificationFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(verificationFailed(errorData));
  }
}


function* verifyValidationRequest(action) {
    
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_URL}/register_validation`,action.payload),
      "Verfication success",
      false,
      true
    )
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(verifyValidationSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(verifyValidationFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(verifyValidationFailed(errorData));
  }
}


export function* watchRegister() {
  yield takeEvery(REGISTER_CONST.REGISTER_REQUEST, registerRequest);
  yield takeEvery(VERIFICATION_CONST.VERIFICATION_REQUEST, verificationRequest);
  yield takeEvery(REGISTER_CONST.REGISTER_VALIDATION_REQUEST, verifyValidationRequest);
}

function* registerSaga() {
  yield all([fork(watchRegister)]);
}

export default registerSaga;
