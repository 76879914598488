const DROUGHT_CONST = {
   GET_DROUGHT_MAP_REQUEST: 'GET_DROUGHT_MAP_REQUEST',
   GET_DROUGHT_MAP_SUCCESS: 'GET_DROUGHT_MAP_SUCCESS',
   GET_DROUGHT_MAP_FAILED:  'GET_DROUGHT_MAP_FAILED',

   GET_DROUGHT_TIMESERIES_REQUEST: 'GET_DROUGHT_TIMESERIES_REQUEST',
   GET_DROUGHT_TIMESERIES_SUCCESS: 'GET_DROUGHT_TIMESERIES_SUCCESS',
   GET_DROUGHT_TIMESERIES_FAILED: 'GET_DROUGHT_TIMESERIES_FAILED',
}

export default DROUGHT_CONST;
