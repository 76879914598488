import DROUGHT_CONST from './droughtMonitorConsts';
export const getDroughtMapRequest = (apiURL, requestObj,isDroughtModule) => ({
  type: DROUGHT_CONST.GET_DROUGHT_MAP_REQUEST,
  payload: {
    apiURL,
    requestObj,
    isDroughtModule
  },
});

export const getDroughtMapSuccess = (response) => ({
  type: DROUGHT_CONST.GET_DROUGHT_MAP_SUCCESS,
  payload: response,
});

export const getDroughtMapFailed = (error) => ({
  type: DROUGHT_CONST.GET_DROUGHT_MAP_FAILED,
  payload: error,
});

export const getDroughtTimeSeriesRequest = (apiURL, requestObj) => ({
  type: DROUGHT_CONST.GET_DROUGHT_TIMESERIES_REQUEST,
  payload: {
    apiURL,
    requestObj
  }
});

export const getDroughtTimeSeriesSuccess = (response) => ({
  type: DROUGHT_CONST.GET_DROUGHT_TIMESERIES_SUCCESS,
  payload: response,
});

export const getDroughtTimeSeriesFailed = (error) => ({
  type: DROUGHT_CONST.GET_DROUGHT_TIMESERIES_FAILED,
  payload: error,
});
