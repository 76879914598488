import {REGISTER_CONST, VERIFICATION_CONST} from './registerConst';

/**
 *  User Register actions
 */
export const registerRequest = (data) => ({
  type: REGISTER_CONST.REGISTER_REQUEST,
  payload: data,
});

export const registerSuccess = (response) => ({
  type: REGISTER_CONST.REGISTER_SUCCESS,
  payload: response,
});

export const registerFailed = (error) => ({
  type: REGISTER_CONST.REGISTER_FAILED,
  payload: error,
});


export const verificationRequest = (data) => ({
  type: VERIFICATION_CONST.VERIFICATION_REQUEST,
  payload: data,
});

export const verificationSuccess = (response) => ({
  type: VERIFICATION_CONST.VERIFICATION_SUCCESS,
  payload: response,
});

export const verificationFailed = (error) => ({
  type: VERIFICATION_CONST.VERIFICATION_FAILED,
  payload: error,
});


export const verifyValidationRequest = (data) => ({
  type: REGISTER_CONST.REGISTER_VALIDATION_REQUEST,
  payload: data,
});

export const verifyValidationSuccess = (response) => ({
  type: REGISTER_CONST.REGISTER_VALIDATION_SUCCESS,
  payload: response,
});

export const verifyValidationFailed = (error) => ({
  type: REGISTER_CONST.REGISTER_VALIDATION_FAILURE,
  payload: error,
});

