import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { updateStatusRequest, updateStatusSuccess } from '../../redux/manage-user/manageUserAction';

const ConfirmationModal = props => {
  const {
    className,
    isOpen,
    modalData,
    latestDataHandler,
    handleApprovedModalToggle,
    fromModal,
    reactivateClicked,
    handleReactivateModalToggle,
    activeTab,
  } = props;
  const dispatch = useDispatch();
  const t = useTranslation();
  const nextProps = useSelector(state => ({
    updateStatusData: state.UserList.updateStatusData || '',
    isUpdateStatusRequesting: state.UserList.isUpdateStatusRequesting,
  }));

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const currentprops = nextProps.updateStatusData && nextProps.updateStatusData.data;
  const previousprops = usePrevious(currentprops);

  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (currentprops && currentprops !== previousprops) {
      if (currentprops) {
        handleApprovedModalToggle(false);
        if (reactivateClicked === true) {
          handleReactivateModalToggle();
        }
        latestDataHandler('approve');
      }
    }
  }, [
    currentprops,
    previousprops,
    handleApprovedModalToggle,
    handleReactivateModalToggle,
    latestDataHandler,
    reactivateClicked,
  ]);

  useEffect(() => {
    return () => {
      dispatch(updateStatusSuccess({}));
    };
  }, [dispatch]);

  const toggle = value => {
    if (value === 'no') {
      if (reactivateClicked === true) {
        handleReactivateModalToggle();
      }
      handleApprovedModalToggle(false);
    } else if (value === 'yes') {
      dispatch(updateStatusRequest(modalData));
    }
  };

  const handleClose = () => {
    handleApprovedModalToggle(false);
  };
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={() => handleClose()} className="reason-modal-header">
          {t('MANAGE_USER.CONFIRMATION')}
        </ModalHeader>
        <ModalBody>
          <p className="confirmation-modal-text">
            {fromModal && (activeTab === 'approve' || activeTab === 'reject')
              ? t('MANAGE_USER.APPROVE_CONFIRMATION')
              : reactivateClicked === true
              ? t('MANAGE_USER.REACTIVATE_CONFIRMATION')
              : t('MANAGE_USER.APPROVE_CONFIRMATION')}
          </p>
        </ModalBody>
        <ModalFooter className="reason-modal">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
            <Button color="secondary" onClick={() => toggle('no')} className="cancel-button me-3">
              {t('MANAGE_USER.NO')}
            </Button>{' '}
            <Button
              // loading={nextProps.isUpdateStatusRequesting}
              color="primary"
              onClick={() => toggle('yes')}
              className="modal-reject-button"
            >
              {t('MANAGE_USER.YES')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  modalData: PropTypes.object,
  latestDataHandler: PropTypes.func,
  handleApprovedModalToggle: PropTypes.bool,
  fromModal: PropTypes.bool,
  activeTab: PropTypes.string,
};
