import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card } from "reactstrap";
import Loader from '../../../Components/common/loader';
import CommonPlotly from "./CommonPlotly";
import ReactExport from 'react-export-excel';
const endpoint = "https://geoglows.ecmwf.int/api/";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function TabThree(props) {
  const { reachId, latitude, longitude, returnPeriodsData } = props;
  const [traces, updateTraces] = useState([]);
  const [historicalData, setHistoricalData] = useState(null);
  const [error, upadteError] = useState(false);
  const [isLoading, updateLoading] = useState(true);
  const colors = ['#04f7ca', '#3cc4ab', '#f2ea09', '#bcb727', '#e209f2', '#903a96'];
  let selectorOptions = {
    buttons: [{
        step: 'month',
        stepmode: 'backward',
        count: 1,
        label: '1m'
    }, {
        step: 'month',
        stepmode: 'backward',
        count: 6,
        label: '6m'
    }, {
        step: 'year',
        stepmode: 'backward',
        count: 1,
        label: '1y'
    }, {
        step: 'all',
    }, {
      step: 'year',
      stepmode: 'todate',
      count: 1,
      label: 'YTD'
  }],
};

  useEffect(() => {
    if (reachId) {
      axios
        .get(
          endpoint +
            "HistoricSimulation/?return_format=json&reach_id=" +
            reachId
        )
        .then((res) => {
          if (res.data && res.status === 200) {
            const data = res.data;
            let historicalData = []
            if (data?.time_series) {
              let flowData = data.time_series['flow'];
              historicalData = data.time_series.datetime.map((item, index) => {
                return {
                  datetime: item,
                  'flow': flowData[index],
                };
              });
              setHistoricalData(historicalData);
            }
            let traces = [
              {
                x: data["time_series"]["datetime"],
                y: data["time_series"]["flow"],
                mode: "lines",
                type: "scatter",
                fill: "none",
                name: "Historical Simulation",
                line: {color: "#E49803"}
              },
            ];
            let traces2 = [];
            if (returnPeriodsData?.length) {
              returnPeriodsData.forEach((returnPeriod, index) => {
                if (returnPeriod && data) {
                  traces2.push({
                    name: `${returnPeriod.label}: ${Math.round(returnPeriod.value)}`,
                    x: data["time_series"]["datetime"],
                    y: data["time_series"]["flow"].map((item) => Math.round(returnPeriod.value)),
                    mode: "lines+markers",
                    fill: "tonexty",
                    // type: 'scatter',
                    visible: "legendonly",
                    legendgrouptitle: {
                      text: "Return Periods ",
                    },
                    legendgroup: "returnGroup2",
                    // stackgroup: 'one',
                    line: { color: colors[index] },
                  });
                }
              });
            }
            traces = traces.concat(traces2);
            updateTraces(traces);
            updateLoading(false);
          }
        }).catch(function(error) {
          // handle error
          upadteError(true);
        });;
    }
  }, [reachId]);

  const layout = {
    // title: {
    //   text: `Historical Simulation`,
    //   xref: "paper",
    //   x: 0,
    // },
    width: 900,
    // xaxis: {
    //   autorange: true,
    //   rangeslider: {
    //     title: "Date",
    //     range: [
    //       moment(
    //         new Date(
    //           historicalData && historicalData["time_series"]["datetime"][0]
    //         )
    //       ).format("YYYY-MM-DD"),
    //       moment(
    //         new Date(
    //           historicalData &&
    //             historicalData["time_series"]["datetime"][
    //               historicalData["time_series"]["datetime"].length - 1
    //             ]
    //         )
    //       ).format("YYYY-MM-DD"),
    //     ],
    //   },
    //   type: "date",
    // },
    xaxis: {
      rangeselector: selectorOptions,
      showgrid: false,
  },
    yaxis: {
      title: "Flow (cub m)",
      showline: true,
      showgrid: false,
    },
    font: {
      size: 12,
      color: "#3870AF",
      weight: "bold",
    }
  };
  return (
    <>
      {!isLoading ? (
        <div>
          <div className="py-3 d-flex">
            <div className=" mr-auto blue-text">
            <h5>
                Reach ID : <b>{reachId}</b> (Lat : {latitude}, Log : {longitude})
              </h5>
            </div>
          </div>
          <Card className="graph-card">
            <CommonPlotly traces={traces} layout={layout}  customFileName={`${reachId}_HistoricalData`} />
          </Card>
          <div style={{ float: 'right', marginTop: "10px" }}>
                  {historicalData && (
                    <ExcelFile
                      filename={`${reachId}_HistoricalData`}
                      element={
                        <button className="btn btn-download ml-auto" style={{ color: 'white' }}>
                          Download
                        </button>
                      }
                    >
                       <ExcelSheet data={historicalData} name="historicalData">
                        <ExcelColumn label="Date_time" value="datetime" />
                        <ExcelColumn label="Flow" value="flow" />
                      </ExcelSheet>
                      {/* {returnPeriodsData && ( */}
                         <ExcelSheet data={returnPeriodsData || []} name="ReturnPeriodsData">
                         <ExcelColumn label="Year" value="year" />
                         <ExcelColumn label="Value" value="value" />
                       </ExcelSheet>
                      {/* )} */}
                    </ExcelFile>
                  )}
                </div>
        </div>
      ) : error ? (
        <span>We're sorry! Server is not responding. Please try again.</span>
      ) : (
        <Loader />
      )}
    </>
  );
}
