import React, { useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  useMap,
  useMapEvents,
  ZoomControl,
  LayersControl,
  LayerGroup,
} from 'react-leaflet';
import _, { first } from 'lodash';
import moment from 'moment';
import L from 'leaflet';
import 'leaflet-side-by-side';
import 'leaflet.control.opacity/dist/L.Control.Opacity.css';
import 'leaflet.control.opacity';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Button, Row, Col, FormGroup, Label, Input, Alert } from 'reactstrap';
import Loader from '../../Components/common/loader';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import MapPreferences from './MapPreferences';
import '../../assets/CSS/LeafletWrapper.css';
import '../../assets/CSS/Header.css';
import myConstClass from '../../Constants';
import LeftFilter from '../../Components/common/Filter';
import { t } from 'react-multi-lang';
import LayerControl from './CustomLayerControl/LayerControl';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWeatherForecastData,
  getWeatherForecastDataSuccess,
  getNoaaTimeSeries,
  getMapPreferencesSuccess,
  getErpasTimeSeries,
  getIRITimeSeries,
  getMapPreferenceBoundariesSuccess,
  getIRIAvailableDatesDataRequest,
} from '../../redux/actions';
import {
  staticResourceYearRequest,
  staticResourceRequest,
  staticResourceAllDateRequest,
} from '../../redux/staticResource/staticResourceAction';
import {
  ERPAS_STATIC_CONST,
  DATA_SOURCES,
  DATE_FORMAT,
  ERPAS_PARAMETER_SLUG,
  IRI_PARAMETER_SLUG,
  MAP_PREFERENCES_lABELS,
  NOAA_PARAMETER_SLUG,
  OPEN_WEATHER_CHART_PARAMS,
  OPEN_WEATHER_PARAMETERS_CONST,
  OPEN_WEATHER_PARAMETER_SLUG,
  SOUTH_ASIA_CONST,
  STATIC_RESOURCE_GET_CATEGORY_LIST,
  YEARS,
  FORECAST_LEAD_TIME_LIST,
  GEOJSON_LABELS,
  GEOJSON_COUNTRY_TOOLTIP_DATA,
  TEMPORAL_AGGREGATION,
} from '../../Components/constants/index';
import {
  AXIOS_INSTANCE,
  ERPAS_TIMESERIES,
  NOAA_TIMESERIES,
  IRI_TIMESERIES,
  WEATHER_FORECAST_DATA_URL,
} from '../../redux/api/apiEndPoint';
import CustomLegend from './CustomLayerControl/CustomLegend';
import DiscreteLegend from './CustomLayerControl/DiscreteLegend';
import { StaticResource } from './StaticResource';
import Ggplot2 from '../../Components/PlotCharts.js';
import {
  chartDateLabelsDay,
  handleOpenWeatherCompareRes,
  renderGenericDateFormat,
  renderGeometricTimeSeries,
  renderOpenWeatherResponse,
  renderParamList,
  setWeatherForecastDefaultParam,
  getToken,
  iriDate
} from '../../Components/common/utils';
import { showError } from '../../Components/Notifications.js';
import 'leaflet-draw/dist/leaflet.draw.css';
import DrawShape from '../../pages/drawShape';
import { CgCloseO } from 'react-icons/cg';
import PlotGeometryCharts from '../../Components/common/PlotGeometryCharts';
import PlotIRICharts from '../../Components/common/PlotIRICharts';
import mapPreferenceIcon from '../../assets/Images/mapPreferenceIcon.svg';
import Cookies from 'js-cookie';
import { DEFAULT_COUNTRY } from '../../Components/constants';
import PlotsCharts1 from './plotCharts';
import { useHistory } from 'react-router-dom';
export default function LeafletWrapper(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Overlay } = LayersControl;
  const [map, setMap] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const layerRef = useRef([]);
  //const iriRef = useRef();
  const [mapPreferRefs, setMapPreferRefs] = useState([]);
  const [isOpenWeather, setIsOpenWeather] = useState(false);
  const [mapLayerOpacity, setMapLayerOpacity] = useState({ layer: '' });
  const [opacityControl, setOpacityControl] = useState('');
  const [mapUrl, setMapUrl] = useState('');
  const [isOpenMapModel, setIsOpenMapModel] = useState(false);
  const [isHideBar, setBar] = useState(false);
  const [selectedParams, setSelectedParams] = useState([]);
  const [selectedYear, setSelectedYear] = useState({});
  const [params, setParams] = useState('');
  const [initialParams, setInitialParams] = useState('');
  const [compareParams, setCompareParams] = useState('');
  const [previousParam, setPreviousParam] = useState('');
  const [selectedParam, setSelectedParam] = useState('');
  const [country, setCountry] = useState('');
  const [fileName, setFileName] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [region, setRegion] = useState(null);
  const [dataSources, setDataSources] = useState([]);
  const [compareDataSources, setCompareDataSources] = useState([]);
  const [initialSelectedDate, setInitialSelectedDate] = useState([]);
  const [finalSelectedDate, setFinalSelectedDate] = useState([]);
  //const [finalForecastYearMonth, setFinalForecastYearMonth] = useState([]);
  const [dateSourceWeatherData, setWeatherData] = useState({});
  const [forecastData, setForecastData] = useState([]);
  const [parameterData, setParameterData] = useState([]);
  const [hasQueryParam, setHasQueryParam] = useState(false);
  const [regionId, setRegionId] = useState();
  const [selectedDataSourceId, setSelectedDataSourceId] = useState();
  const [staticResourceParams, setStaticResourceParams] = useState([]);
  const [staticResourceDates, setStaticResourceDates] = useState([]);
  const [staticResourceImage, setStaticResourceImage] = useState();
  const [staticResourceTitle, setStaticResourceTitle] = useState();
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [geoJsonAllData, setGeoJsonAllData] = useState([]);
  const [geoJsonLayersData, setGeoJsonLayerData] = useState([]);
  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [selectedParamDataSourceId, setSelectedParamDataSourceId] = useState();
  const [compareParamLegendData, setCompareParamLegendData] = useState([]);
  const [selectedParamLegendData, setSelectedParamLegendData] = useState([]);
  const [selectedMapPreferLegendData, setSelectedMapPreferLegendData] = useState([]);
  const [showCharts, setShowCharts] = useState(false);
  const [selectedChartTimeRange, setSelectedChartTimeRange] = useState('This week');
  const [chartSeriesData, setChartSeriesData] = useState([]);
  const [isDefaultDataSource, setIsDefaultDataSource] = useState(false);
  const [graphData, setGraphData] = useState('');
  const [selectedDate, setSelectedDate] = useState();
  const [staticResourceWeekData, setStaticResourceWeekData] = useState(null);
  const [mapPreferenceGeoJsonloading, setMapPreferenceGeoJsonloading] = useState(false);
  const [compareView, setCompareView] = useState([]);
  // const [selectedForecastLeadTime,setSelectedForecastLeadTime] = useState(null);
  const [selectedFinalForercastLeadTime, setSelectedFinalForercastLeadTime] = useState(null);
  const [forecastLeadTimeList, setForecastLeadTimeList] = useState([]);
  const [forecastCompareLeadTimeList, setForecastCompareLeadTimeList] = useState([]);
  const [selectedForecastYearMonth, setSelectedForecastYearMonth] = useState({
    graph: {
      forecastYearMonth: moment().format('YYYY-MM'),
      // minStartDate: '',
      // maxStartDate: '',
      // maxEndDate: '',
    },
    map: {
      forecastYearMonth: moment().format('YYYY-MM'),
      // endDate: moment()
      //   .add(6, 'days')
      //   .format(DATE_FORMAT),
      // minStartDate: '',
      // maxStartDate: '',
      // maxEndDate: '',
    },
  });
  const [selectedForecastCompareYearMonth, setSelectedForecastCompareYearMonth] = useState({
    graph: {
      forecastYearMonth: moment().format('YYYY-MM'),
      // minStartDate: '',
      // maxStartDate: '',
      // maxEndDate: '',
    },
    map: {
      forecastYearMonth: moment().format('YYYY-MM'),
      // endDate: moment()
      //   .add(6, 'days')
      //   .format(DATE_FORMAT),
      // minStartDate: '',
      // maxStartDate: '',
      // maxEndDate: '',
    },
  });

  const [mapPreferences, setMapPreferences] = useState({
    country: { layer: useRef(), color: '', data: '', country: '' },
    states_or_provinces: { layer: useRef(), color: '', data: '', country: '' },
    districts_or_cities: { layer: useRef(), color: '', data: '', country: '' },
    meteorological_locations: { layer: useRef(), color: '', data: '', country: '' },
    basins: { layer: useRef(), color: '', data: '', country: '' },
    rivers_or_reservoirs: { layer: useRef(), color: '', data: '', country: '' },
    talukas: { layer: useRef(), color: '', data: '', country: '' },
  });
  const [mapPreferUrl, setMapPreferUrl] = useState('');

  const [requestBody, setRequestBody] = useState({
    weather_forecast_parameter_id: [],
    weather_forecast_source_id: [],
    group_date: '',
    order_by: 'start_date',
    page: 1,
  });
  const [isDefaultSelectedSourceId, setIsDefaultSelectedSourceId] = useState(false);
  const [staticResourceSelectedLocation, setStaticResourceSelectedLocation] = useState(null);
  const mapRef = useRef(null);
  const [mapControl, setMapControl] = useState({});
  const [categoryParams, setCategoryParams] = useState([]);
  const [categorizedCompareParams, setCategoryCompareParams] = useState([]);
  const [selectedParamLegend, setSelectedParamLegend] = useState([]);
  const [temporalAggregation, setTemporalAggregation] = useState([]);
  const [errorData, setErrorData] = useState({ showError: false, data: '' });
  const splitterRef = useRef(null);
  const [range, setRange] = useState({
    min: null,
    max: null,
  });
  const [applyLayers, setApplyLayers] = useState(false);
  const [updateGraph, setUpdateGraph] = useState(false);
  const [isGraphLoading, setIsGraphLoading] = useState();
  //state for timeseries data
  const [noaaTimeSeriesData, setNoaaTimeSeriesData] = useState(null);
  const [erpasTimeSeriesData, setErpasTimeSeriesData] = useState(null);
  const [iriTimeSeriesData, setIRITimeSeriesData] = useState(null);
  const [noaaRegionTimeSeries, setNoaaRegionTimeSeries] = useState([]);
  const [compareSearch, setCompareSearch] = useState({});
  const [yearsList, setYearsList] = useState([]);
  const [forecastAvailableDates, setForecastAvailableDates] = useState([]);
  const [IRIAvailableYearList, setIRIAvailableYearList] = useState([]);
  const [compareIRIAvailableYearList, setCompareIRIAvailableYearList] = useState([]);
  const [IRIAvailableMonthList, setIRIAvailableMonthList] = useState([]);
  const [compareIRIAvailableMonthList, setCompareIRIAvailableMonthList] = useState([]);
  const [IRIAvailableLeadTimeList, setIRIAvailableLeadTimeList] = useState([]);
  const [compareIRIAvailableLeadTimeList, setCompareIRIAvailableLeadTimeList] = useState([]);
  const [IRIForecastTime, setIRIForecastTime] = useState({
    year: null,
    month: null,
    lead_time: null,
  });
  const [previousUrlDataSourceId, setPreviousUrlDataSourceId] = useState('');
  const [urlDataSourceId, setUrlDataSourceId] = useState('');
  const [compareIRIForecastTime, setCompareIRIForecastTime] = useState({
    year: null,
    month: null,
    lead_time: null,
  });
  const [applyLayersDisable, setApplyLayerDisable] = useState(false);
  const [isCompareClicked, setIsCompareClicked] = useState(false);
  const [compareCordinates, setCompareCordinates] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [layerControl, setLayerControl] = useState({
    firstLayer: {},
    secondLayer: {},
  });
  const [cordinateAndZoom, setCordinateAndZoom] = useState({
    cordinates: [28.259, 83.9461],
    locationCordinates: [28.259, 83.9461],
    markerCordinates: [28.259, 83.9461],
    zoom: 4.4,
  });
  const [stateData, setStateData] = useState();
  const [selectedLocation, setSelectedLocation] = useState('');
  // const [selectedCompareLocation, setCompareLocation] = useState('');
  const [compareLocation, setCompareLocation] = useState({
    country: '',
    state: '',
    district: '',
    basin: '',
    region: '',
    markerPointData: '',
  });
  const [selectedLocation1, setSelectedLocation1] = useState({
    country: '',
    state: '',
    district: '',
    basin: '',
    region: '',
    markerPointData: '',
  });
  const [indexLayers, setIndexLayers] = useState([]);
  const [compareParamForGraph, setCompareParamForGraph] = useState([]);


  setTimeout(() => {
    if (document.getElementsByTagName('body')) {
      if (document.getElementsByTagName('body')[0].children) {
        Object.values(document.getElementsByTagName('body')[0].children).forEach((data) => {
          if (data.toString().includes('HTMLIFrameElement')) {
            data.style = 'display: none !important;';
          }
        });
      }
    }
  }, 4000);

  // state for noaa timeseries data
  const { Weather, DroughtMonitor, boundries } = useSelector((state) => ({
    Weather: state.Weather,
    DroughtMonitor: state.DroughtMonitor,
    boundries: state.Map_Preference,
  }));

  const selectedParam_current = selectedParam;
  const selectedParam_prev = usePrevious(selectedParam_current);

  const nextProps = useSelector((state) => ({
    isStaticResourceRequesting: state.staticResource.isStaticResourceRequesting,
    StaticResource: state.staticResource.staticResourceData || null,
    id: state.staticResource.id,
    staticResourceYearData: state.staticResource.staticResourceYearData || null,
    StaticResourceAllDateData: state.staticResource.staticResourceAllDateData || null,
    staticResourceDataError: state.staticResource.staticResourceDataError || null,
  }));

  //useeffect for getting map layers data from redux
  useEffect(() => {
    if(getToken()){
      dispatch(getMapPreferenceBoundariesSuccess({}));
      setMapPreferRefs([]);
      setMapPreferencesData([]);
      return () => {
        dispatch(getMapPreferenceBoundariesSuccess(''));
        // dispatch(getMapPreferencesSuccess(''));
        dispatch(getWeatherForecastDataSuccess(''));
        Cookies.remove('weather-forecast-default-param', { path: '/' });
      };
    }
    else{
      history.push('/home')
    }
   
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(selectedParam) &&
      (_.isEmpty(previousParam) ||
        previousParam?.id !== selectedParam?.id ||
        previousParam?.weather_forecast_parameter?.id !==
          selectedParam?.weather_forecast_parameter?.id)
    ) {
      if (!hasQueryParam) {
        if (selectedParam?.weather_forecast_source_id === DATA_SOURCES.IRI.id) {
          dispatch(getIRIAvailableDatesDataRequest());
        }
      }
    }
  }, [selectedParam]);

  useEffect(() => {
    if (!_.isEmpty(compareDataSources)) {
      if (!hasQueryParam) {
        if (compareDataSources[0].id === DATA_SOURCES.IRI.id) {
          dispatch(getIRIAvailableDatesDataRequest());
        }
      }
    }
  }, [compareDataSources]);

  useEffect(() => {
    if (Weather.iriAvailableDatesData) {
      const {
        data: { result },
      } = Weather.iriAvailableDatesData;
      if (result) {
        setForecastAvailableDates(result);
      }
    }
  }, [Weather.iriAvailableDatesData]);

  useEffect(() => {
    if (nextProps.staticResourceYearData) {
      const {
        data: { result },
      } = nextProps.staticResourceYearData;
      if (result?.length !== 0) {
        let arr = [];
        result?.map((i) => {
          arr.push({
            value: i,
            label: i,
          });
        });
        setYearsList(arr);
      }
    }
  }, [nextProps.staticResourceYearData]);

  useEffect(() => {
    if (
      hasQueryParam &&
      !_.isEmpty(selectedParam) &&
      (_.isEmpty(previousParam) ||
        previousParam?.id !== selectedParam?.id ||
        previousParam?.weather_forecast_parameter?.id !==
          selectedParam?.weather_forecast_parameter?.id)
    ) {
      if (
        !_.isEmpty(requestBody?.weather_forecast_parameter_id) &&
        !_.isEmpty(requestBody?.weather_forecast_source_id) &&
        !_.isEmpty(requestBody?.group_date)
      ) {
        if (selectedDataSourceId === 'e2f886a7-3299-4c82-af2e-123fe92faf88') {
          let data = {
            weather_forecast_parameter_id: requestBody?.weather_forecast_parameter_id,
            weather_forecast_source_id: requestBody?.weather_forecast_source_id,
            // group_date: '',
            order_by: 'start_date',
            page: 1,
          };
          dispatch(staticResourceRequest(data));
        } else {
          dispatch(staticResourceRequest(requestBody));
        }
      }
    }
  }, [requestBody]);

  useEffect(() => {
    if (
      hasQueryParam &&
      !_.isEmpty(selectedParam) &&
      (_.isEmpty(previousParam) ||
        previousParam?.id !== selectedParam?.id ||
        previousParam?.weather_forecast_parameter?.id !==
          selectedParam?.weather_forecast_parameter?.id)
    ) {
      if (
        JSON.stringify(selectedParam_current?.weather_forecast_parameter_id) !==
        JSON.stringify(selectedParam_prev?.weather_forecast_parameter_id)
      ) {
        if (
          selectedDataSourceId &&
          selectedParam_current?.weather_forecast_parameter_id &&
          requestBody?.group_date
        ) {
          setRequestBody({
            weather_forecast_source_id: [selectedDataSourceId],
            weather_forecast_parameter_id: [selectedParam_current?.weather_forecast_parameter_id],
            group_date: requestBody.group_date,
            order_by: 'start_date',
            page: 1,
          });
        }
      }
    }
  }, [
    selectedParam_current?.weather_forecast_parameter_id,
    selectedParam_prev?.weather_forecast_parameter_id,
  ]);

  useEffect(() => {
    if (!_.isEmpty(forecastAvailableDates)) {
      let list = [];
      forecastAvailableDates?.map((i) => {
        list.push({
          id: i?.category,
          name: i?.category,
          value: i?.category,
          label: i?.category,
          data: i?.data,
        });
      });
      setIRIAvailableYearList(list);
      setCompareIRIAvailableYearList(list);
    }
  }, [forecastAvailableDates]);

  useEffect(() => {
    if (!_.isEmpty(IRIForecastTime.year)) {
      let data;
      let list = [];
      IRIAvailableYearList?.map((i) => {
        if (i?.value === IRIForecastTime?.year?.value) {
          data = i?.data;
        }
      });
      data?.map((a) => {
        list.push(handleForecastLeadMonthFormat(a));
      });
      setIRIAvailableMonthList(list);
    }
  }, [IRIForecastTime.year]);

  useEffect(() => {
    if (!_.isEmpty(compareIRIForecastTime.year)) {
      let data;
      let list = [];
      compareIRIAvailableYearList?.map((i) => {
        if (i?.value === compareIRIForecastTime?.year?.value) {
          data = i?.data;
        }
      });
      data?.map((a) => {
        list.push(handleForecastLeadMonthFormat(a));
      });
      setCompareIRIAvailableMonthList(list);
    }
  }, [compareIRIForecastTime.year]);

  useEffect(() => {
    if (!_.isEmpty(IRIForecastTime.month)) {
      let data;
      let list = [];
      IRIAvailableMonthList.map((i) => {
        if (i?.value === IRIForecastTime.month?.value) {
          data = i?.data;
        }
      });
      data?.map((a) => {
        list.push(handleForecastLeadTimeFormat(a));
      });
      setIRIAvailableLeadTimeList(list);
    }
  }, [IRIForecastTime.month]);

  useEffect(() => {
    if (!_.isEmpty(compareIRIForecastTime.month)) {
      let data;
      let list = [];
      compareIRIAvailableMonthList.map((i) => {
        if (i?.value === compareIRIForecastTime.month?.value) {
          data = i?.data;
        }
      });
      data?.map((a) => {
        list.push(handleForecastLeadTimeFormat(a));
      });
      setCompareIRIAvailableLeadTimeList(list);
    }
  }, [compareIRIForecastTime.month]);

  const handleForecastLeadMonthFormat = (a) => {
    let b;
    FORECAST_LEAD_TIME_LIST.map((i) => {
      if (parseInt(i?.value) === parseInt(a?.category)) {
        b = {
          name: i?.name,
          value: i?.name,
          label: i?.name,
          id: i?.id,
          data: a?.data,
        };
      }
    });
    return b;
  };

  const handleForecastLeadTimeFormat = (a) => {
    let latest_list;
    let list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    let option_list = list?.splice(parseInt(a?.forecast_time_month), 4);
    let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    let new_arr = new_list?.splice(parseInt(0), 4 - option_list.length);
    latest_list = option_list.concat(new_arr);
    latest_list?.map((i, index) => {
      i.name = i.name + '(' + `${index + 1} Month` + ')';
      i.indexValue = index + 1
    });
    return latest_list[parseInt(a?.forecast_lead_time) - 1];
  };

  useEffect(() => {
    if (boundries?.mapPreferenceData) {
      setGetBoundariesData(boundries?.mapPreferenceData?.data?.result);
    }
  }, [boundries?.mapPreferenceData]);
  useEffect(() => {
    if (splitterRef && splitterRef.current) {
      if (errorData?.showError) {
        splitterRef.current.setState({ secondaryPaneSize: '30%' });
      } else {
        splitterRef.current.setState({ secondaryPaneSize: '70%' });
      }
    }
  }, [errorData]);

  useEffect(() => {
    if (selectedForecastYearMonth) {
      //setSelectedForecastLeadTime(null)
      let month = selectedForecastYearMonth?.map?.forecastYearMonth.split('-')[1];
      let list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
      let option_list = list?.splice(parseInt(month), 4);
      let latest_list;
      if (month >= 9) {
        let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
        let new_arr = new_list?.splice(parseInt(0), 4 - option_list.length);
        latest_list = option_list.concat(new_arr);
      } else {
        latest_list = option_list;
      }
      latest_list?.map((i, index) => {
        i.name = i.name + '(' + `${index + 1}.0` + ')';
      });
      setForecastLeadTimeList(latest_list);
    }
  }, [selectedForecastYearMonth]);

  useEffect(() => {
    if (selectedForecastCompareYearMonth) {
      setSelectedFinalForercastLeadTime(null);
      let month = selectedForecastCompareYearMonth?.map?.forecastYearMonth.split('-')[1];
      let list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
      let option_list = list?.splice(parseInt(month), 4);
      let latest_list;
      if (month >= 9) {
        let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
        let new_arr = new_list?.splice(parseInt(0), 4 - option_list.length);
        latest_list = option_list.concat(new_arr);
      } else {
        latest_list = option_list;
      }
      latest_list?.map((i, index) => {
        i.name = i.name + '(' + `${index + 1}.0` + ')';
      });
      setForecastCompareLeadTimeList(latest_list);
    }
  }, [selectedForecastCompareYearMonth]);

  useEffect(() => {
    if (getBoundariesData && getBoundariesData?.length && !geoJsonLayersData?.length) {
      let API_URL = [];
      mapPreferRefs.current = [];
      // setIsLoading(true);
      getBoundariesData?.map((item) => {
        if (item?.data && item?.data.length) {
          item.data.map((boundary) => {
            if (boundary?.json_url?.length) {
              setGeoJsonData([]);
              setGeoJsonAllData([]);
              let mapPreferKeys = Object.keys(mapPreferences);
              if (mapPreferKeys) {
                mapPreferKeys.map((item) => {
                  let selectedLayer = mapPreferences[item];
                  if (selectedLayer?.layer && selectedLayer?.layer?.current)
                    selectedLayer?.layer?.current?.clearLayers();
                  selectedLayer.color = '';
                  setMapPreferences((prev) => {
                    return {
                      ...prev,
                      [item]: selectedLayer,
                    };
                  });
                });
              }
              setMapPreferenceGeoJsonloading(true);
              let config = {
                name: boundary?.slug,
                id: boundary?.id,
                color: boundary?.color_code,
                category: boundary?.map_preference_category?.name,
                country_id: DEFAULT_COUNTRY.id,
              };
              API_URL.push(AXIOS_INSTANCE.get(boundary?.json_url, config));
            } else {
              let ref = React.createRef();
              ref.current = {
                boundary_id: boundary?.id,
                layer: React.createRef(),
              };
              mapPreferRefs.current.push(ref);
            }
            // });
          });
        }
      });
      Promise.all(API_URL)
        .then((response) => {
          let res = JSON.parse(JSON.stringify(response));
          setMapPreferenceGeoJsonloading(false);
          setGeoJsonLayerData(res);
          // setIsLoading(false);
        })
        .catch((error) => {
          // setIsLoading(false);
          setMapPreferenceGeoJsonloading(false);
        });
    }
  }, [getBoundariesData]);

  useEffect(() => {
    let selectedGeoJsonData = [];
    if (geoJsonLayersData?.length && geoJsonData?.length) {
      geoJsonData?.map((item) => {
        let filteredObj = geoJsonLayersData?.find(
          (layer) => layer.config.id === item.id && layer.config.country_id === item.country_id,
        );
        if (!_.isEmpty(filteredObj)) {
          filteredObj.fromMapPreferences = item.fromMapPreferences;
          selectedGeoJsonData.push(filteredObj);
        }
      });
    } else {
      selectedGeoJsonData = [];
    }
    setGeoJsonAllData(selectedGeoJsonData);
  }, [geoJsonLayersData, geoJsonData]);

  //global function to compare props
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    let search = window.location.search;
    let URLParams = new URLSearchParams(search);
    let paramId;
    let isDefaultSourceId = URLParams.get('isDefaultSelectedSourceId');
    setIsDefaultSelectedSourceId(isDefaultSourceId);
    paramId = URLParams.get('dataSourceId');
    STATIC_RESOURCE_GET_CATEGORY_LIST.map((item) => {
      item.data.map((i) => {
        if (i.forecast_data_source.id === paramId) {
          if (i.forecast_data_source.type === 'static') {
            setSelectedDataSourceId(paramId);
            if (paramId !== urlDataSourceId) {
              setPreviousUrlDataSourceId(urlDataSourceId);
              setUrlDataSourceId(paramId);
            }
            setHasQueryParam(true);
          } else {
            if (!_.isEmpty(urlDataSourceId)) {
              setPreviousUrlDataSourceId(urlDataSourceId);
              setUrlDataSourceId('');
            }
            setSelectedDataSourceId(paramId);
            setHasQueryParam(false);
          }
        }
      });
    });
  }, [window.location.href]);

  useEffect(() => {
    if (
      !_.isEmpty(selectedParam) &&
      selectedParam?.type === 'static' &&
      selectedParam?.category === 'Sub - Seasonal' &&
      (_.isEmpty(previousParam) ||
        previousParam?.id !== selectedParam?.id ||
        previousParam?.weather_forecast_parameter?.id !==
          selectedParam?.weather_forecast_parameter?.id) &&
      (!_.isEmpty(urlDataSourceId) ||
        _.isEmpty(previousUrlDataSourceId) ||
        urlDataSourceId !== previousUrlDataSourceId)
    ) {
      requestBody.group_date = '';
      setRequestBody({ ...requestBody });
      if (hasQueryParam) {
        let req_body = {
          forecast_data_source_id: urlDataSourceId,
        };
        dispatch(staticResourceYearRequest(req_body));
      }
    }
  }, [urlDataSourceId, selectedParam]);

  const handleDataSourceId = (id) => {
    setSelectedParamDataSourceId(id);
  };

  // useEffect(() => {
  //   if (country) {
  //     setMapPreferencesData([]);
  //     let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
  //     if (selectedLegend.length) {
  //       selectedLegend.forEach(function(value, i) {
  //         if (value.add) {
  //           value.add = false;
  //         }
  //       });
  //       setSelectedMapPreferLegendData(selectedLegend);
  //     }
  //   }
  // }, [country]);
  useEffect(() => {
    if (country) {
      setMapPreferencesData([]);
      removeGeoJsonLayer(mapPreferences);
      let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      let updatedLegend = [];
      if (selectedLegend.length) {
        updatedLegend = selectedLegend?.map((value, i) => {
          if (value.add) value.add = false;
          return value;
        });
        setSelectedMapPreferLegendData(updatedLegend);
      }
    }
  }, [country]);
  const removeGeoJsonLayer = (mapPreferences) => {
    let mapPreferKeys = Object.keys(mapPreferences);
    if (mapPreferKeys) {
      mapPreferKeys.map((item) => {
        let selectedLayer = mapPreferences[item];
        if (selectedLayer?.layer && selectedLayer?.layer?.current)
          selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.color = '';
        setMapPreferences((prev) => {
          return {
            ...prev,
            [item]: selectedLayer,
          };
        });
      });
    }
  };
  useEffect(() => {
    if (isOpenWeather) {
      const leafletEle = document.getElementById('leaflet-map');
      if (leafletEle) {
        leafletEle.classList.add('open-weather-polygon-style');
      }
    } else {
      const leafletEle = document.getElementById('leaflet-map');
      if (leafletEle) {
        leafletEle.classList.remove('open-weather-polygon-style');
      }
    }
  }, [isOpenWeather]);

  useEffect(() => {
    if (
      // isCompareClicked &&
      // compareDataSources?.length &&
      compareParamLegendData?.length &&
      compareParamLegendData?.some((i) => i.add && !i.descret_legend) &&
      selectedMapPreferLegendData?.length &&
      selectedMapPreferLegendData?.some((i) => i.add && i.descret_legend)
    ) {
      const leafletEle = document.getElementsByClassName('left-legend-style');
      if (leafletEle && leafletEle[0]) {
        leafletEle[0].classList.add('left-legend-pos-st');
      }

      const discreteLeafletEle = document.getElementsByClassName('left-discrete-legend-style');
      if (discreteLeafletEle && discreteLeafletEle[0]) {
        discreteLeafletEle[0].classList.add('left-discrete-legend-pos-st');
      }
    } else if (
      compareParamLegendData?.length &&
      compareParamLegendData?.findIndex((i) => i.add && !i.descret_legend) < 0
    ) {
      const leafletEle = document.getElementsByClassName('left-legend-style');
      const leftPosleafletEle = document.getElementsByClassName('left-legend-pos-st');

      const discreteLeafletEle = document.getElementsByClassName('left-discrete-legend-style');
      const leftPosdiscreteLeafletEle = document.getElementsByClassName(
        'left-discrete-legend-pos-st',
      );

      if (leafletEle && leafletEle[0] && leftPosleafletEle) {
        leafletEle[0].classList.remove('left-legend-pos-st');
      }
      if (discreteLeafletEle && discreteLeafletEle[0] && leftPosdiscreteLeafletEle) {
        discreteLeafletEle[0].classList.remove('left-discrete-legend-pos-st');
      }
    }
  }, [compareParamLegendData, selectedMapPreferLegendData, isCompareClicked, compareDataSources]);

  useEffect(() => {
    if (
      selectedParamLegendData?.length &&
      selectedParamLegendData?.some((i) => i.add && i.descret_legend) &&
      selectedMapPreferLegendData?.length &&
      selectedMapPreferLegendData?.some((i) => i.add && !i.descret_legend)
    ) {
      const leafletEle = document.getElementsByClassName('right-legend-style');
      if (leafletEle && leafletEle[0]) {
        leafletEle[0].classList.add('right-legend-pos-st');
      }
      const discreteLeafletEle = document.getElementsByClassName('right-discrete-legend-style');
      if (discreteLeafletEle && discreteLeafletEle[0]) {
        discreteLeafletEle[0].classList.add('right-discrete-legend-pos-st');
      }
    } else {
      const leafletEle = document.getElementsByClassName('right-legend-style');
      const rightPosLeafletEle = document.getElementsByClassName('right-legend-pos-st');
      const discreteLeafletEle = document.getElementsByClassName('right-discrete-legend-style');
      const rightPosdiscreteLeafletEle = document.getElementsByClassName(
        'right-discrete-legend-pos-st',
      );
      if (leafletEle && leafletEle[0] && rightPosLeafletEle) {
        leafletEle[0].classList.remove('right-legend-pos-st');
      }
      if (discreteLeafletEle && discreteLeafletEle[0] && rightPosdiscreteLeafletEle) {
        discreteLeafletEle[0].classList.remove('right-discrete-legend-pos-st');
      }
    }
  }, [selectedParamLegendData, selectedMapPreferLegendData]);

  // useEffect(() => {
  //   if (
  //     isCompareClicked &&
  //     compareDataSources?.length &&
  //     compareView?.some(item => item.name === 'map' && item.selected)
  //   ) {
  //     const leafletEle = document.getElementsByClassName('leaflet-control-layers-expanded');
  //     if (leafletEle && leafletEle[0]) {
  //       leafletEle[0].classList.add('opacity-slider-style');
  //     }
  //   } else if (
  //     compareView?.some(item => item.name === 'graph' && item.selected) &&
  //     _.isEmpty(mapControl)
  //   ) {
  //     const leafletEle = document.getElementsByClassName('leaflet-control-layers-expanded');
  //     if (leafletEle && leafletEle[0]) {
  //       leafletEle[0].classList.remove('opacity-slider-style');
  //     }
  //   }
  // }, [isCompareClicked, compareView, compareDataSources, mapControl , compareParam]);

  useEffect(() => {
    if (forecastData) {
      forecastData.map((item) => {
        let val = {};
        for (let [key, value] of Object.entries(item)) {
          val = value;
          if (val) {
            if (Array.isArray(val)) {
              val[0].name = key;
            } else {
              val.name = key;
            }
          }
        }
        setSelectedParamLegendData([]);
        setWeatherData({
          ...dateSourceWeatherData,
          data: Array.isArray(val) ? val[0] : val,
        });
      });
    }
  }, [forecastData]);

  useEffect(() => {
    if (nextProps.isStaticResourceRequesting) {
      setStaticResourceWeekData([]);
    }
    if (nextProps.StaticResource) {
      const {
        data: { result },
      } = nextProps.StaticResource;
      if (result && result.results && result.results[0]) {
        setStaticResourceWeekData(result.results);
        //setStaticResourceSelectedLocation(result.results[0].region_name);
        setStaticResourceImage(result.results[0].image);
        setStaticResourceTitle(result.results[0].weather_forecast_source_name);
      } else {
        setStaticResourceImage('');
        // setStaticResourceWeekData('');
        setStaticResourceWeekData([]);
        setStaticResourceTitle('');
      }
    } else if (nextProps.staticResourceDataError) {
      setStaticResourceImage('');
      // setStaticResourceWeekData('');
      setStaticResourceWeekData([]);
      setStaticResourceTitle('');
    }
  }, [
    nextProps.StaticResource,
    nextProps.isStaticResourceRequesting,
    nextProps.staticResourceDataError,
  ]);

  useEffect(() => {
    if (nextProps.StaticResourceAllDateData) {
      setIsLoading(false);
      const {
        data: { result },
      } = nextProps.StaticResourceAllDateData;
      if (result) {
        setStaticResourceDates(result);
      } else {
        setStaticResourceWeekData([]);

        setStaticResourceDates([]);
      }
    } else {
      setStaticResourceDates([]);
      setIsLoading(false);
    }
  }, [nextProps.StaticResourceAllDateData]);

  // Handle timeseries data for NOAA GFS
  useEffect(() => {
    if (Weather.noaaTimeSeriesDataRequesting) {
      setIsGraphLoading(true);
      setChartSeriesData([]);
    }
    if (Weather.noaaTimeSeriesData) {
      if (Weather.noaaTimeSeriesData.data) {
        let { code } = Weather.noaaTimeSeriesData?.data;
        if (code === 200) {
          let { result } = Weather.noaaTimeSeriesData?.data;
          const keys = Object.keys(result);
          const parameter = NOAA_PARAMETER_SLUG.find((item) => item.api_slug === keys[0]);
          if (keys.length === 1 && !_.isEmpty(parameter)) {
            let attribute = keys[0];
            setNoaaTimeSeriesData((prev) => {
              return {
                ...prev,
                [parameter.name]: result[attribute],
              };
            });
          }
        } else {
          setIsGraphLoading(false);
          setErrorData({ showError: true, data: 'No records found. please try again' });
        }
      }
    } else if (Weather.noaaTimeSeriesDataError) {
      if (Weather?.noaaTimeSeriesDataError?.data) {
        let noaaData = Weather.noaaTimeSeriesDataError?.data || '';
        if (noaaData?.response) {
          if (noaaData?.response?.data) {
            setErrorData({ showError: true, data: noaaData?.response?.data.message });
          }
        }
      }
      setIsGraphLoading(false);
    }
  }, [
    Weather.noaaTimeSeriesDataRequesting,
    Weather.noaaTimeSeriesData,
    Weather.noaaTimeSeriesDataError,
  ]);

  //handle time series data for erpas
  useEffect(() => {
    if (Weather.erpasTimeSeriesData) {
      if (Weather.erpasTimeSeriesData?.data) {
        let { code } = Weather.erpasTimeSeriesData?.data;
        if (code === 200) {
          let { result } = Weather.erpasTimeSeriesData?.data;
          const keys = Object.keys(result);

          const parameter = ERPAS_PARAMETER_SLUG.find((item) => item.api_slug === keys[0]);
          if (keys.length === 1 && !_.isEmpty(parameter)) {
            let attribute = keys[0];
            setErpasTimeSeriesData((prev) => {
              return {
                ...prev,
                [parameter.name]: result[attribute],
              };
            });
          }
        } else {
          setIsGraphLoading(false);
          setErrorData({ showError: true, data: 'No records found. please try again' });
        }
      }
    }
    if (Weather.erpasTimeSeriesDataError) {
      setIsGraphLoading(false);
      if (Weather.erpasTimeSeriesDataError.data) {
        if (Weather.erpasTimeSeriesDataError.data.response) {
          let {
            data: { message },
          } = Weather.erpasTimeSeriesDataError.data.response;
          setErrorData({ showError: true, data: message });
        }
      }
    }
  }, [Weather.erpasTimeSeriesData, Weather.erpasTimeSeriesDataError]);

  //handle time series data for iri
  useEffect(() => {
    if (Weather.iriTimeSeriesData) {
      if (Weather.iriTimeSeriesData?.data) {
        let { code } = Weather.iriTimeSeriesData?.data;
        if (code === 200) {
          let { result } = Weather.iriTimeSeriesData?.data;
          const keys = Object.keys(result);
          setIRITimeSeriesData(result);
        } else {
          setIsGraphLoading(false);
          setErrorData({ showError: true, data: 'No records found. please try again' });
        }
      }
    }
    if (Weather.iriTimeSeriesDataError) {
      setIsGraphLoading(false);
      if (Weather.iriTimeSeriesDataError?.data) {
        if (Weather.iriTimeSeriesDataError?.data?.response) {
          let {
            data: { message },
          } = Weather.iriTimeSeriesDataError?.data?.response;
          setErrorData({ showError: true, data: message });
        }
      }
    }
  }, [Weather.iriTimeSeriesData, Weather.iriTimeSeriesDataError]);

  useEffect(() => {
    if (
      noaaTimeSeriesData?.Temperature &&
      noaaTimeSeriesData?.Humidity &&
      noaaTimeSeriesData?.Precipitation &&
      noaaTimeSeriesData?.Wind_speed
    ) {
      setIsGraphLoading(false);
      setErrorData({ showError: false,data:''})
      setChartSeriesData(noaaTimeSeriesData);
    }
  }, [noaaTimeSeriesData]);

  useEffect(() => {
    let erpasParameters = ERPAS_PARAMETER_SLUG.map((item) => item.name);
    let arr = [];
    if (!_.isEmpty(erpasTimeSeriesData)) {
      arr = erpasParameters.map((item) => erpasTimeSeriesData.hasOwnProperty(item));
      if (!arr.includes(false)) {
        setIsGraphLoading(false);
        setErrorData({ showError: false,data:''})
        setChartSeriesData(erpasTimeSeriesData);
      }
    }
  }, [erpasTimeSeriesData]);

  useEffect(() => {
    let iriParameters = IRI_PARAMETER_SLUG.map((item) => item.name);
    let arr = [];
    if (!_.isEmpty(iriTimeSeriesData)) {
      // arr = iriParameters.map(item => iriTimeSeriesData.hasOwnProperty(item));
      // if (!arr.includes(false)) {
      setIsGraphLoading(false);
      setErrorData({ showError: false,data:''})
      setChartSeriesData(iriTimeSeriesData);
      //}
    }
  }, [iriTimeSeriesData]);

  useEffect(() => {
    if (Weather.weatherForecastData) {
      if (Weather.weatherForecastData.data) {
        setIsLoading(false);
        const { result } = Weather.weatherForecastData.data;
        if (!isOpenWeather) {
          setForecastData(result);
        }
        // setCompareParamLegendData([]);
        result.map((item) => {
          if (item.Open_Weather) {
            const OpenWeather = item.Open_Weather;
            if (Array.isArray(OpenWeather)) {
              if (OpenWeather.some((x) => x.cod === '400')) {
                const errorCode = OpenWeather.find((x) => x.cod === '400');
                setIsGraphLoading(false);
                //showError(`No record found.${errorCode.message}`);
                setErrorData({ showError: true, data: errorCode.message });
                setGraphData({
                  ...graphData,
                  OPEN_WEATHER_API: [],
                });
                setChartSeriesData([]);
                return false;
              }
            } else {
              setErrorData({ showError: false,data:''})
              setIsGraphLoading(false);
              setGraphData({
                ...graphData,
                OPEN_WEATHER_API: OpenWeather,
              });
              // setChartSeriesData(item.Open_Weather?.daily);
              setChartSeriesData(item.Open_Weather?.hourly);
            }
          }
        });
      }
    } else if (Weather.weatherForecastDataError) {
      if (Weather.weatherForecastDataError) {
        let {
          data: { response },
        } = Weather.weatherForecastDataError;
        if (!_.isEmpty(response?.data?.errors)) {
          showError(Object.values(response?.data?.errors)[0]);
        } else {
          showError(response?.data?.message);
        }
      } else {
        showError('No records found. please try again');
      }

      removeMapLayers(layerControl, compareParamLegendData, selectedParamLegendData);
      setIsLoading(false);
    } else if (Weather.weatherForecastDataNetworkError) {
      setIsLoading(false);
    }
  }, [
    Weather.weatherForecastData,
    Weather.isWeatherForecastRequesting,
    Weather.weatherForecastDataError,
    Weather.weatherForecastDataNetworkError,
  ]);

  useEffect(() => {
    setShowCharts(false);
  }, [
    selectedParam,
    country,
    selectedState,
    selectedDistrict,
    dataSources,
    initialSelectedDate,
    temporalAggregation,
    range,
    region,
  ]);

  useEffect(() => {
    // if (initialParams && initialParams?.length) {
    //   if (compareDataSources?.length) {
    //     if (compareParams && compareParams?.length) {
    //       handleCommonParameters(compareDataSources, initialParams, compareParams);
    //     }
    //   }
    // }
    // let selectedLegend = [...selectedParamLegendData];
    // let compareLegend = [...compareParamLegendData];
    // let previousParamRef = '';
    // if (selectedParams.length) {
    //   previousParamRef = layerRef.current[selectedParams[0]?.parameter?.paramIndex];
    // }
    // if (selectedLegend.length) {
    //   if (selectedLegend.length) {
    //     selectedLegend.forEach(function(value, i) {
    //       if (value.add) {
    //         value.add = false;
    //       }
    //     });
    //     removeLegend(selectedParamLegendData);
    //     const defaultChartTimeRange = renderGenericDateFormat(new Date());
    //     setSelectedChartTimeRange(defaultChartTimeRange);
    //   }
    // }
    // if (compareLegend.length) {
    //   compareLegend.forEach(function(value, i) {
    //     if (value.add) {
    //       value.add = false;
    //     }
    //   });
    // }
    // setCompareParamLegendData(compareLegend);
    // removeLayers(previousParamRef);
    // if (!_.isEmpty(layerControl?.firstLayer)) {
    //   map?.removeControl(layerControl?.firstLayer);
    // }
    // if (!_.isEmpty(layerControl?.secondLayer)) {
    //   map?.removeControl(layerControl?.secondLayer);
    // }
    // if (!_.isEmpty(mapControl)) {
    //   map?.removeControl(mapControl);
    // }
    // setMapControl('');
    // setLayerControl({
    //   firstLayer: {},
    //   secondLayer: {},
    // });
    // dispatch(getWeatherForecastDataSuccess(''));
    // setWeatherData('');
    // setParameterData([]);
    let selectedLegend = [...selectedParamLegendData];
    let compareLegend = [...compareParamLegendData];
    let previousParamRef = '';
    let updatedCompareLegend = [];
    if (selectedParams.length) {
      previousParamRef = layerRef.current[selectedParams[0]?.parameter?.paramIndex];
      previousParamRef?.current?.eachLayer((layer) => {
        removeTransparencyToolLayers(indexLayers, layer?._url);
      });
    }
    if (selectedLegend.length) {
      if (selectedLegend.length) {
        selectedLegend.forEach(function (value, i) {
          if (value.add) {
            value.add = false;
          }
        });
        removeLegend(selectedParamLegendData);
        const defaultChartTimeRange = renderGenericDateFormat(new Date());
        setSelectedChartTimeRange(defaultChartTimeRange);
      }
    }

    updatedCompareLegend = compareLegend?.map((value, i) => {
      if (value.add) value.add = false;
      return value;
    });
    setCompareParamLegendData(updatedCompareLegend);

    removeLayers(previousParamRef);
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      removeTransparencyToolLayers(
        indexLayers,
        layerControl?.firstLayer?._url,
        layerControl?.secondLayer?._url,
      );
      map?.removeControl(mapControl);
    }
    setMapControl('');
    setLayerControl({
      firstLayer: {},
      secondLayer: {},
    });
    dispatch(getWeatherForecastDataSuccess(''));
    setWeatherData('');
    setParameterData([]);
  }, [dataSources]);

  useEffect(() => {
    if (initialParams && initialParams?.length) {
      if (compareDataSources?.length) {
        if (compareParams && compareParams?.length) {
          handleCommonParameters(compareDataSources, initialParams, compareParams);
        }
      }
    }
  }, [dataSources, initialParams]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      let MapPrefereArr = [];
      mapPreferencesData?.map((item) => {
        let legendItem = JSON.parse(JSON.stringify(item.result));
        legendItem.id = item?.dataSource;
        legendItem.slug = item.slug;
        legendItem.dataSource =
          item.slug === MAP_PREFERENCES_lABELS.JRC
            ? t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.WATER_OCCURRENCE')
            : item.slug === MAP_PREFERENCES_lABELS.MOD44
            ? t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.PERMANENT_WATER')
            : item.category;
        // legendItem.dataSource = item?.dataSource;
        legendItem.parameter = item?.legendName ? item?.legendName : item?.dataSource;
        legendItem.add = true;
        legendItem.measure = legendItem.units;
        legendItem.isCompare = false;
        legendItem.legendId = item.id;
        legendItem.fromMapPreferences = true;
        if (selectedMapPreferLegendData?.length) {
          let isDuplicate = selectedMapPreferLegendData?.find(
            (x) => x.legendId === item.id && item.add === true,
          );
          if (_.isEmpty(isDuplicate)) {
            MapPrefereArr.push(legendItem);
          }
        } else {
          MapPrefereArr.push(legendItem);
        }
      });
      setSelectedMapPreferLegendData(MapPrefereArr);
    }
  }, [mapPreferencesData]);

  useEffect(() => {
    if (compareDataSources?.length) {
      if (compareParams && compareParams?.length) {
        if (initialParams && initialParams?.length) {
          handleCommonParameters(compareDataSources, compareParams, initialParams);
        }
      }
    }
  }, [compareDataSources, compareParams]);
  useEffect(() => {
    if (layerRef?.current?.length) {
      const currentParamRef =
        layerRef.current[selectedParam?.weather_forecast_parameter?.paramIndex];
      if (!_.isEmpty(currentParamRef) && !_.isEmpty(currentParamRef?.current)) {
        currentParamRef?.current?.eachLayer((layer) => {
          setMapLayerOpacity({ layer });
        });
      }
    }
  }, [layerRef?.current]);

  // for IRI
  // useEffect(() => {
  //   if (iriRef?.current?.length) {
  //     //const currentParamRef =
  //      // iriRef.current[selectedParam?.weather_forecast_parameter?.paramIndex];
  //     if (!_.isEmpty(iriRef) && !_.isEmpty(iriRef?.current)) {
  //       iriRef?.current?.eachLayer(layer => {
  //         setMapLayerOpacity({ layer });
  //       });
  //     }
  //   }
  // }, [iriRef?.current]);

  // useEffect(() => {
  //   if (!_.isEmpty(mapLayerOpacity) && !_.isEmpty(mapLayerOpacity?.layer)) {
  //     if (!_.isEmpty(opacityControl)) map?.removeControl(opacityControl);
  //     let customOpacityControl = '';
  //     customOpacityControl = L?.control?.opacity(mapLayerOpacity, {
  //       collapsed: false,
  //       position: 'topright',
  //     });
  //     setOpacityControl(customOpacityControl);
  //     if(!_.isEmpty(map) && !_.isEmpty(map?._layers)){
  //       if(!_.isEmpty(customOpacityControl)){
  //         customOpacityControl?.addTo(map);
  //       }
  //     }
  //   }
  // }, [mapLayerOpacity]);

  // const getMapPreferencesGeojsonData = geoJsonList => {
  //   let data = JSON.parse(JSON.stringify(geoJsonList));
  //   let mapPreferKeys = Object.keys(mapPreferences);
  //   if (mapPreferKeys) {
  //     mapPreferKeys.map(item => {
  //       let selectedLayer = mapPreferences[item];
  //       if (selectedLayer?.layer && selectedLayer?.layer?.current)
  //         selectedLayer?.layer?.current?.clearLayers();
  //       selectedLayer.color = '';
  //       setMapPreferences(prev => {
  //         return {
  //           ...prev,
  //           [item]: selectedLayer,
  //         };
  //       });
  //     });
  //   }
  //   setGeoJsonData(data);
  // };
  const getMapPreferencesGeojsonData = (geoJsonList) => {
    let data = JSON.parse(JSON.stringify(geoJsonList));
    removeGeoJsonLayer(mapPreferences);
    setGeoJsonData(data);
  };

  const handleCommonParameters = (compareSource, comparePar, param) => {
    if (compareSource?.length) {
      if (comparePar && comparePar?.length) {
        if (param && param?.length) {
          let paramArr = [];
          let commonParam = JSON.parse(JSON.stringify(param[0]));
          commonParam.parameterData = [];
          param[0]?.parameterData?.map((item) => {
            comparePar[0]?.parameterData?.map((compareItem) => {
              let filteredObj = JSON.parse(JSON.stringify(item));
              const filteredParams = item?.weather_forecast_parameter.filter((o1) =>
                compareItem?.weather_forecast_parameter.some(
                  (o2) => o1.weather_forecast_parameter_id === o2.weather_forecast_parameter_id,
                ),
              );
              if (filteredParams?.length) {
                filteredObj.weather_forecast_parameter = filteredParams;
                commonParam?.parameterData?.push(filteredObj);
              }
            });
          });
          paramArr.push(commonParam);
          setParams(paramArr);
        }
      }
    }
  };

  const removeTransparencyToolLayers = (indexLayers, firstUrl, secondUrl) => {
    let data = _.cloneDeep(indexLayers);
    let updatedData = data?.filter((i) => {
      if (secondUrl) {
        if (i?.layer?._url !== firstUrl && i?.layer?._url !== secondUrl) {
          return i;
        }
      } else {
        if (i?.layer?._url !== firstUrl) {
          return i;
        }
      }
    });
    setIndexLayers(updatedData);
  };

  const handleMapModel = () => {
    setIsOpenMapModel(!isOpenMapModel);
  };

  const removeMapLayers = (layerControl, compareParamLegendData, selectedParamLegendData) => {
    // if (!_.isEmpty(layerControl?.firstLayer)) {
    //   map?.removeControl(layerControl?.firstLayer);
    // }
    // if (!_.isEmpty(layerControl?.secondLayer)) {
    //   map?.removeControl(layerControl?.secondLayer);
    // }
    // if (!_.isEmpty(mapControl)) {
    //   map?.removeControl(mapControl);

    //   setMapControl('');
    //   setLayerControl({
    //     firstLayer: {},
    //     secondLayer: {},
    //   });
    // }

    // let previousParamRef = '';
    //   if (selectedParams.length) {
    //     previousParamRef = layerRef.current[selectedParams[0]?.parameter?.paramIndex];
    //   }
    //   if (previousParamRef && previousParamRef.current) {
    //     removeLayers(previousParamRef);
    //   }

    // // let compareArray = [...compareParamLegendData];
    // // if (compareArray?.length) {
    // //   compareArray?.forEach((value, i) => {
    // //     if (value.add) value.add = false;
    // //   });
    // // }
    // // setCompareParamLegendData(compareArray);
    // let compareArray = [...compareParamLegendData];
    // let updatedCompareArray = [];
    // if (compareArray?.length) {
    //   updatedCompareArray = compareArray?.map((value,i)=>{
    //     if(value.add)  value.add = false;
    //     return value;
    //   })
    // }
    // setCompareParamLegendData(updatedCompareArray);
    // removeLegend(selectedParamLegendData);
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      map?.removeControl(mapControl);
      removeTransparencyToolLayers(
        indexLayers,
        layerControl?.firstLayer?._url,
        layerControl?.secondLayer?._url,
      );

      setMapControl('');
      setLayerControl({
        firstLayer: {},
        secondLayer: {},
      });
    }

    let previousParamRef = '';
    if (selectedParams.length) {
      previousParamRef = layerRef.current[selectedParams[0]?.parameter?.paramIndex];
    }
    if (previousParamRef && previousParamRef.current) {
      previousParamRef = layerRef.current[selectedParams[0]?.parameter?.paramIndex];
      previousParamRef?.current?.eachLayer((layer) => {
        removeTransparencyToolLayers(indexLayers, layer?._url);
      });
      removeLayers(previousParamRef);
    }

    let compareArray = [...compareParamLegendData];
    let updatedCompareArray = [];
    if (compareArray?.length) {
      updatedCompareArray = compareArray?.map((value, i) => {
        if (value.add) value.add = false;
        return value;
      });
    }
    setCompareParamLegendData(updatedCompareArray);
    removeLegend(selectedParamLegendData);
  };
  useEffect(() => {
    let selectedParamArr = selectedParams;
    // let legendArray = selectedParamLegendData;
    // let legendArray = _.cloneDeep(selectedParamLegendData);
    let legendArray = selectedParamLegendData;

    let compareLegendArray = _.cloneDeep(compareParamLegendData);

    // let compareLegendArray = compareParamLegendData;
    if (dateSourceWeatherData?.data) {
      const { data } = dateSourceWeatherData;
      let legendObj = {};
      let compareObj = {};
      let selectedParamItem = '';
      let opacityControl = '';
      if (selectedParamArr[0] && !_.isEmpty(selectedParamArr[0])) {
        selectedParamItem = selectedParamArr[0]?.weather_forecast_parameter;
      }
      let previousParamRef = layerRef.current[selectedParamItem?.paramIndex];
      previousParamRef?.current?.eachLayer((layer) => {
        removeTransparencyToolLayers(indexLayers, layer?._url);
      });
      const currentParamRef =
        layerRef.current[selectedParam?.weather_forecast_parameter?.paramIndex];
      let item = _.cloneDeep(selectedParam);
      if (item?.parameter?.parameter_type) {
        let arr1 = [];
        let updatedName = '';
        const [first, ...rest] = item?.parameter?.name?.split(' ');
        arr1?.push(first);

        if (arr1?.length) {
          arr1?.push(item?.parameter?.parameter_type);
        }
        updatedName = arr1?.concat(rest)?.join(' ');
        item.parameter.name = updatedName;
      }
      const paramName = item?.weather_forecast_parameter?.name;
      const paramSlug = item?.weather_forecast_parameter?.slug;
      let legendData = {};
      if (Array.isArray(data)) {
        if (data[0]?.map_data?.code === 400 || data[1]?.map_data?.code === 400) {
          const filteredMapData = data.find((i) => i?.map_data?.code === 400);
          showError(filteredMapData?.map_data?.message);
          return false;
        }

        if (data[0]?.map_data) {
          compareObj = data[0]?.map_data;
          compareObj.measure = data[0]?.map_data?.units;
          compareObj.dataSource = dataSources[0]?.name;
          compareObj.name = paramName;
          // compareObj.parameter = paramName;
          compareObj.parameter =
            DATA_SOURCES?.IRI.id === dataSources[0]?.id
              ? DATA_SOURCES?.IRI.parameterName
              : paramName;
          compareObj.add = true;
          compareObj.isCompare = true;
          compareObj.id = paramName;
          compareObj.dataSource_id = dataSources[0]?.id;
          compareObj.parameter_slug = paramSlug;
          compareObj.fromMapPreferences = false;
          compareObj.descret_legend = data[0]?.map_data?.descret_legend || false;
          // compareObj.startDate = initialSelectedDate ? initialSelectedDate?.map?.startDate : null;
          // compareObj.endDate = initialSelectedDate ? initialSelectedDate?.map?.endDate : null;
        }
//dates changes
        if (DATA_SOURCES?.IRI.id === dataSources[0]?.id){

          const iriDateRanger = iriDate(IRIForecastTime , IRIAvailableLeadTimeList)
          
          compareObj.selectedDates = {
            startDate:iriDateRanger?.startDate,
            endDate:iriDateRanger?.endDate
          } 
          compareObj.forecastMonth = IRIForecastTime?.month?.value;
        }
        else{
          compareObj.selectedDates = {
            startDate:moment(initialSelectedDate?.map?.startDate).format('DD-MM-YYYY'),
            endDate:moment(initialSelectedDate?.map?.endDate).format('DD-MM-YYYY')
          }
        }

        if (data[1]?.map_data) {
          legendObj = data[1]?.map_data;
          legendObj.measure = data[1]?.map_data?.units;
          legendObj.dataSource = compareDataSources[0]?.name;
          legendObj.name = paramName;
          // legendObj.parameter = paramName;
          legendObj.parameter =
            DATA_SOURCES?.IRI.id === compareDataSources[0]?.id
              ? DATA_SOURCES?.IRI.parameterName
              : paramName;
          legendObj.isCompare = false;
          legendObj.id = paramName;
          legendObj.dataSource_id = compareDataSources[0]?.id;
          legendObj.parameter_slug = paramSlug;
          legendObj.fromMapPreferences = false;
          legendObj.descret_legend = data[1]?.map_data?.descret_legend || false;
          // legendObj.startDate = finalSelectedDate ? finalSelectedDate?.startDate : null;
          // legendObj.endDate = finalSelectedDate ? finalSelectedDate?.endDate : null;
          // legendData = legendObj;

          if (DATA_SOURCES?.IRI.id === compareDataSources[0]?.id){
            const iriDateRanger = iriDate(compareIRIForecastTime , compareIRIAvailableLeadTimeList)
            legendObj.selectedDates = {
              startDate:iriDateRanger?.startDate ,
              endDate: iriDateRanger?.endDate
            } 
            legendObj.forecastMonth = compareIRIForecastTime?.month?.value;
          }
          else{
            legendObj.selectedDates = {
              startDate:moment(finalSelectedDate?.map?.startDate).format('DD-MM-YYYY'),
              endDate:moment(finalSelectedDate?.map?.endDate).format('DD-MM-YYYY')
            }
          }
        }
        if (currentParamRef.current) {
          currentParamRef.current._url = data[0]?.map_data?.map_url;
          currentParamRef.current._url1 = data[1]?.map_data?.map_url;
          if (previousParamRef?.current?._url1?.length) {
            if (!_.isEmpty(layerControl?.firstLayer)) {
              map?.removeControl(layerControl?.firstLayer);
            }
            if (!_.isEmpty(layerControl?.secondLayer)) {
              map?.removeControl(layerControl?.secondLayer);
            }
            if (!_.isEmpty(mapControl)) {
              map?.removeControl(mapControl);
            }
            // legendArray.forEach((value, i) => {
            //   if (value.add) value.add = false;
            // });
            legendArray?.forEach((value, i) => {
              if (value.add) value.add = false;
              // return value;
            });
          }
          if (currentParamRef.current._url1) {
            const firstLayer = L.tileLayer(currentParamRef.current._url);
            firstLayer.addTo(map);
            const secondLayer = L.tileLayer(currentParamRef.current._url1);
            secondLayer.addTo(map);
            let updatedIndexLayer = _.cloneDeep(indexLayers);
            updatedIndexLayer.push({ isCompare: true, layer: firstLayer });
            updatedIndexLayer.push({ isCompare: true, layer: secondLayer });
            setIndexLayers(updatedIndexLayer);
            const control = L.control.sideBySide(firstLayer, secondLayer);
            setLayerControl({ firstLayer, secondLayer });
            setMapControl(control);
            control.addTo(map);
          }
        }
      } else {
        let map_data = '';
        if (data?.hasOwnProperty('map_url')) {
          map_data = data;
        } else if (data?.map_data && !_.isEmpty(data.map_data)) {
          map_data = data?.map_data;
        } else if (data?.code === 400) {
          showError(data?.message);
          return false;
        }
        if (map_data.code === 400) {
          showError(
            map_data?.message
              ? map_data?.message
              : 'No record found . Please select different date range',
          );
          return false;
        }
        legendObj = { ...map_data };
        legendObj.name = paramName;
        // legendObj.parameter = paramName;
        legendObj.parameter =
          DATA_SOURCES?.IRI.id === dataSources[0]?.id ? DATA_SOURCES?.IRI.parameterName : paramName;

        legendObj.measure = map_data?.units;
        legendObj.dataSource = dataSources[0]?.name;
        legendObj.isCompare = false;
        legendObj.id = paramName;
        // legendObj.parameter = DATA_SOURCES?.IRI.id === compareDataSources[0]?.id ? DATA_SOURCES?.IRI.parameterName : paramName;
        legendObj.dataSource_id = dataSources[0]?.id;
        legendObj.parameter_slug = paramSlug;
        legendObj.fromMapPreferences = false;
        legendObj.descret_legend = map_data?.descret_legend || false;
        if (DATA_SOURCES?.IRI.id === dataSources[0]?.id){
          const iriDateRanger = iriDate(IRIForecastTime , IRIAvailableLeadTimeList)
          legendObj.selectedDates = {
          startDate:iriDateRanger?.startDate,
            endDate: iriDateRanger?.endDate
          }
          legendObj.forecastMonth = IRIForecastTime?.month?.value;
        }
        else{
          legendObj.selectedDates = {
          startDate:moment(initialSelectedDate?.map?.startDate).format('DD-MM-YYYY'),
          endDate:moment(initialSelectedDate?.map?.endDate).format('DD-MM-YYYY')
          }
        }
        // legendObj.startDate = initialSelectedDate ? initialSelectedDate?.map?.startDate : null;
        // legendObj.endDate = initialSelectedDate ? initialSelectedDate?.map?.endDate : null;

        if (map?.opacityControl) {
          map.removeControl(opacityControl);
        }
        // TODO: need to change looping through layers
        if (currentParamRef && currentParamRef.current) {
          currentParamRef.current?.eachLayer((layer) => {
            if (map_data) {
              layer.setUrl(map_data?.map_url);
              let updatedIndexLayer = _.cloneDeep(indexLayers);
              updatedIndexLayer.push({ isCompare: false, layer: layer });
              setMapUrl({ name: selectedParam.name, url: map_data?.map_url, layer });
              setMapLayerOpacity({
                layer,
              });
              setIndexLayers(updatedIndexLayer);
            }
          });
        }
      }
      //need to optimize this redundant part
      if (!_.isEmpty(selectedParam)) {
        const index = legendArray.findIndex(
          (item) => item.name === paramName && item.dataSource === legendObj.dataSource,
        );
        if (!_.isEmpty(legendObj)) {
          if (!selectedParamArr.length || index === -1) {
            legendData = legendObj;
            legendData.add = true;
            for (let x of legendArray) {
              if (x.name === paramName) {
                if (x.hasOwnProperty('add') && x.add === false) {
                  if (x.dataSource === legendObj.dataSource) {
                    x.add = true;
                  }
                }
              }
            }
          }

          if (selectedParamArr.length) {
            if (
              (compareDataSources.length && compareDataSources[0].name === legendObj.dataSources) ||
              selectedParamItem?.dataSource === legendObj.dataSource
            )
              if (legendArray.length) {
                for (let x of legendArray) {
                  if (x.name !== paramName) {
                    x.add = false;
                  }
                }
              }
            selectedParamArr = [];
          }
        }
        removeLayers(previousParamRef);
        if (Array.isArray(data)) {
          if (selectedParamArr[0]?.dataSource === legendObj.dataSource) {
            addLayers(currentParamRef);
          }
        } else {
          if (selectedParam?.dataSource === legendObj.dataSource) {
            //if(dataSources[0]?.id === DATA_SOURCES.IRI.id){
            // addLayers(iriRef)
            //}
            //else{
            addLayers(currentParamRef);
            // }
          }
        }

        selectedParamArr.push(selectedParam);
        if (!_.isEmpty(legendData)) {
          legendArray.push(legendData);
        }
        if (!_.isEmpty(compareObj)) {
          compareLegendArray.push(compareObj);
        }

        // setSelectedParamLegendData(legendArray);
        // setCompareParamLegendData(compareLegendArray);
        // setSelectedParamLegend(legendArray);
        // setSelectedParamLegend(legendArray);
        // setSelectedParams(selectedParamArr);
        setSelectedParamLegendData(legendArray);
        setCompareParamLegendData(compareLegendArray);
        setSelectedParamLegend(legendArray);
        setSelectedParams(selectedParamArr);
      }
    }
  }, [dateSourceWeatherData]);

  const handleParameters = (paramsList, id) => {
    // let selectedParamArr = selectedParams;

    // // let legendArray = selectedParamLegendData;
    // // let legendArray = _.cloneDeep(selectedParamLegendData);
    // let legendArray = selectedParamLegendData;
    // let compareArray = _.cloneDeep(compareParamLegendData);
    // // let compareArray = compareParamLegendData;
    // let selectedParamItem = '';
    // let updatedCompareArray = []
    // let updatedParamArray = [];
    // //if (!_.isEmpty(paramsList)) {
    //   if (selectedParamArr[0] && !_.isEmpty(selectedParamArr[0])) {
    //     selectedParamItem = selectedParamArr[0]?.weather_forecast_parameter;
    //   }
    //   const previousParamRef = layerRef.current[selectedParamItem?.paramIndex];
    //  // const paramName = paramsList?.weather_forecast_parameter?.name;
    //   removeLayers(previousParamRef);
    //   if (legendArray.length) {
    //     // for (let x of legendArray) {
    //     //   //if (x.name !== paramName) {
    //     //   x.add = false;
    //     //   //}
    //     // }
    //     // updatedParamArray = legendArray?.map((value,i)=>{
    //     //   value.add = false;
    //     //   return value;
    //     // })
    //     for (let x of legendArray) {
    //       x.add = false;
    //     }
    //   }
    //   if (compareArray.length) {
    //     compareArray.forEach((value, i) => {
    //       if (value.add) value.add = false;
    //     });
    //   }
    //   if (!_.isEmpty(layerControl?.firstLayer)) {
    //     map?.removeControl(layerControl?.firstLayer);
    //   }
    //   if (!_.isEmpty(layerControl?.secondLayer)) {
    //     map?.removeControl(layerControl?.secondLayer);
    //   }
    //   if (!_.isEmpty(mapControl)) {
    //     map?.removeControl(mapControl);
    //     setMapControl('');
    //     setLayerControl({
    //       firstLayer: {},
    //       secondLayer: {},
    //     });
    //   }
    //   if (!_.isEmpty(paramsList)) {

    //   //   selectedParamArr.push(paramsList);
    //   //   setSelectedParam(paramsList);
    //   //   setSelectedParams(selectedParamArr);
    //   // setSelectedParamLegend(legendArray);
    //   // setSelectedParamLegendData(legendArray);
    //   // setCompareParamLegendData(updatedCompareArray);
    //   // }else {
    //   //   setSelectedParam({});
    //   //   setSelectedParams([]);
    //   // }
    //   selectedParamArr.push(paramsList);
    //   setSelectedParam(paramsList);
    //   setSelectedParams(selectedParamArr);

    //   setSelectedParamLegend(legendArray);
    //   setSelectedParamLegendData(legendArray);
    //   setCompareParamLegendData(updatedCompareArray);
    // } else {
    //   setSelectedParam({});
    //   setSelectedParams([]);
    // }

    let selectedParamArr = selectedParams;
    let legendArray = selectedParamLegendData;
    let compareArray = _.cloneDeep(compareParamLegendData);
    let updatedCompareArray = [];
    let updatedParamArray = [];
    let selectedParamItem = '';
    if (selectedParamArr[0] && !_.isEmpty(selectedParamArr[0])) {
      selectedParamItem = selectedParamArr[0]?.weather_forecast_parameter;
    }
    const previousParamRef = layerRef.current[selectedParamItem?.paramIndex];
    previousParamRef?.current?.eachLayer((layer) => {
      removeTransparencyToolLayers(indexLayers, layer?._url);
    });
    removeLayers(previousParamRef);
    if (legendArray.length) {
      // updatedParamArray = legendArray?.map((value,i)=>{
      //   value.add = false;
      //   return value;
      // })
      for (let x of legendArray) {
        //if (x.name !== paramName) {
        x.add = false;
        //}
      }
    }
    if (compareArray.length) {
      updatedCompareArray = compareArray?.map((value, i) => {
        if (value.add) value.add = false;
        return value;
      });
    }
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      removeTransparencyToolLayers(
        indexLayers,
        layerControl?.firstLayer?._url,
        layerControl?.secondLayer?._url,
      );
      map?.removeControl(mapControl);
      setMapControl('');
      setLayerControl({
        firstLayer: {},
        secondLayer: {},
      });
    }
    if (!_.isEmpty(paramsList)) {
      selectedParamArr.push(paramsList);
      if (
        _.isEmpty(selectedParam) ||
        paramsList?.id !== selectedParam?.id ||
        paramsList?.weather_forecast_parameter?.id !== selectedParam?.weather_forecast_parameter?.id
      ) {
        setPreviousParam(selectedParam);
        setSelectedParam(paramsList);
      }
      setSelectedParams(selectedParamArr);
      // setSelectedParamLegend(updatedParamArray);
      // setSelectedParamLegendData(updatedParamArray);
      setSelectedParamLegend(legendArray);
      setSelectedParamLegendData(legendArray);
      setCompareParamLegendData(updatedCompareArray);
    } else {
      setPreviousParam(selectedParam);
      setSelectedParam({});
      setSelectedParams([]);
    }
  };

  const handleEachFeatureChange = (countryData, locationType, feat, layer) => {
    let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
      (item) => item.selectedCountry === countryData?.name,
    );
    let labelprop = selectedCountryData ? selectedCountryData[locationType] : '';
    if (labelprop && feat.properties[labelprop])
      layer.bindTooltip(feat.properties[labelprop] + '', { sticky: true, direction: 'auto' });

    /** TODO: alternative solution */

    // if(!_.isEmpty(layer) && !_.isEmpty(feat?.properties)){
    //   layer?.on({
    //     'mousemove': (e) => {
    //       const name = e.target.feature.properties['MJ_BASIN__'] ? e.target.feature.properties['MJ_BASIN__'] : '';
    //       layer?.bindTooltip(name)
    //       layer?.openTooltip(e?.latlng);
    //     },
    //     // 'mouseout': () => {
    //     //   layer?.unbindTooltip();
    //     //   layer?.closeTooltip();
    //     // },
    //   });
    // }
  };

  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            zoom: mapEvents.getZoom(),
            cordinates: mapEvents.getCenter(),
          };
        });
      },
      dragend: () => {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            zoom: mapEvents.getZoom(),
            cordinates: mapEvents.getCenter(),
          };
        });
      },
    });

    const map = useMap();
    setInterval(function () {
      //map.invalidateSize();
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 500);
    map.setView(center, zoom);
    return null;
  }

  const handleCordinates = (cordinates, zoom) => {
    setCordinateAndZoom((prev) => {
      return {
        ...prev,
        locationCordinates: cordinates,
        cordinates,
        zoom,
      };
    });
  };

  const handleSelectedDefaultYear = (selectedValue) => {
    if (selectedValue !== selectedYear) {
      setSelectedYear(selectedValue);
      setSelectedDate();
      // setStaticResourceWeekData([]);
      setStaticResourceWeekData();
    }
    setSelectedYear(selectedValue);
    if (hasQueryParam) {
      if (selectedParam?.weather_forecast_source_id === selectedDataSourceId) {
        if (selectedParam?.weather_forecast_parameter_id && selectedDataSourceId && selectedValue) {
          dispatch(
            staticResourceAllDateRequest({
              forecast_data_source_id: selectedDataSourceId,
              parameter_id: selectedParam?.weather_forecast_parameter_id,
              year: selectedValue,
            }),
          );
        }
      }
    }
  };

  const setParamData = ({ values, fromCompare }) => {
    // layerRef.current = [];
    // let arr = [];
    // arr.push(values);
    // if (!_.isEmpty(arr)) {
    //   let categoriedArr = renderParamList(arr);
    //   setCategoryParams(categoriedArr);
    //   categoriedArr?.map(item => {
    //     const ref = React.createRef();
    //     layerRef.current.push(ref);
    //   });
    // }
    // if (hasQueryParam) {
    //   setStaticResourceParams(arr);
    // }
    // if (!_.isEmpty(values)) {
    //   setInitialParams(arr);
    //   setParams(arr);
    // }
    layerRef.current = [];
    let arr = [];
    arr.push(values);
    if (!_.isEmpty(arr)) {
      let categoriedArr = renderParamList(arr);
      let updatedCategoriedArr = _.cloneDeep(categoriedArr);
      if (dataSources?.length && dataSources[0]?.name === DATA_SOURCES.ERPAS.name) {
        updatedCategoriedArr = categoriedArr?.map((i) => {
          let item = _.cloneDeep(i);
          if (i?.parameter?.parameter_type) {
            let arr1 = [];
            let updatedName = '';
            const [first, ...rest] = i?.parameter?.name?.split(' ');
            arr1?.push(first);

            if (arr1?.length) {
              arr1?.push(i?.parameter?.parameter_type);
            }
            updatedName = arr1?.concat(rest)?.join(' ');
            item.parameter.name = updatedName;
          }
          return item;
        });
      }
      setCategoryParams(updatedCategoriedArr);
      categoriedArr?.map((item) => {
        const ref = React.createRef();
        layerRef.current.push(ref);
      });
    }
    if (hasQueryParam) {
      setStaticResourceParams(arr);
    }
    if (!_.isEmpty(values)) {
      setInitialParams(arr);
      if (fromCompare || _.isEmpty(params) || params[0]?.id !== arr[0]?.id) {
        setParams(arr);
      }
    }
  };

  const setCompareParam = (values, firstDataSource) => {
    let arr = [];
    if (!_.isEmpty(values)) {
      arr.push(values);
    }
    if (!_.isEmpty(arr)) {
      let categoriedArr = renderParamList(arr);
      setCategoryCompareParams(categoriedArr);
    } else {
      setCategoryCompareParams([]);
      setCompareParams([]);
      // setParamData(firstDataSource);
      setParamData({ values: firstDataSource, fromCompare: true });
    }
    setCompareParams(arr);
  };

  const handleSelectedFilterCountry = (id) => {
    setSelectedCountryId(id);
  };

  const handleCountry = (name) => {
    // if (mapPreferRefs?.current?.length && mapPreferencesData?.length) {
    //   mapPreferencesData?.map(item => {
    //     let selectedMapPreferences = mapPreferRefs.current.find(
    //       x => x.current.boundary_id === item.id,
    //     );
    //     let previousParamRef = selectedMapPreferences.current?.layer;
    //     if (previousParamRef && previousParamRef?.current) {
    //       removeLayers(previousParamRef);
    //     }
    //   });
    // }
    // setCountry(name);
    // setSelectedState('');
    // setSelectedDistrict('');

    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    removeMapLayers(layerControl, compareParamLegendData, selectedParamLegendData);
    setCountry(name);
    setSelectedState('');
    setSelectedDistrict('');
  };

  const handleSelectedDate = (data) => {
    setSelectedDate(data);
    let reqBody = {
      forecast_data_source_id: [],
      parameter_id: [],
      group_date: '',
      order_by: 'start_date',
      page: 1,
    };
    if (
      selectedDataSourceId === 'e2f886a7-3299-4c82-af2e-123fe92faf88' ||
      selectedDataSourceId === 'e2f886a7-3299-4c82-af2e-123fe92faf87'
    ) {
      reqBody.group_date = data;
    } else {
      reqBody.group_date = data ? moment(data, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
    }
    if (selectedParam?.weather_forecast_source_id === selectedDataSourceId) {
      if (selectedParam?.weather_forecast_parameter_id && selectedDataSourceId && selectedYear) {
        setRequestBody({
          weather_forecast_source_id: [selectedDataSourceId],
          weather_forecast_parameter_id: [selectedParam?.weather_forecast_parameter_id],
          group_date: reqBody.group_date,
          order_by: 'start_date',
          page: 1,
        });
      }
    }
  };

  const handleProps = (props) => {
    if (props?.temporalAggregation) {
      setTemporalAggregation(props?.temporalAggregation);
    }
    if (props?.range) {
      if (props?.range.min !== null) setRange(props?.range);
    }
    if (props?.compareDataSource) {
      setCompareDataSources(props?.compareDataSource);
    } else {
      setCompareDataSources([]);
    }
    if (props?.dataSources?.length) {
      if (props?.dataSources[0]?.name !== dataSources[0]?.name) {
        setDataSources(props?.dataSources);
      }
    }
    if (props?.initialSelectedDate) {
      setInitialSelectedDate(props?.initialSelectedDate);
    }
    if (props?.finalSelectedDate) {
      setFinalSelectedDate(props?.finalSelectedDate);
    }
    if (props?.finalForecastYearMonth) {
      setSelectedForecastCompareYearMonth(props?.finalForecastYearMonth);
    }
  };

  const handleRegionId = (value) => {
    setRegionId(value);
  };

  const handleSelectedDataSourceId = (value) => {
    setSelectedDataSourceId(value);
  };

  const forecastTimeYearHandler = (e) => {
    setIRIAvailableMonthList([]);
    setIRIAvailableLeadTimeList([]);
    IRIForecastTime.year = e;
    IRIForecastTime.month = null;
    IRIForecastTime.lead_time = null;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  const forecastTimeMonthHandler = (e) => {
    setIRIAvailableLeadTimeList([]);
    IRIForecastTime.month = e;
    IRIForecastTime.lead_time = null;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  const compareForecastTimeYearHandler = (e) => {
    setCompareIRIAvailableMonthList([]);
    setCompareIRIAvailableLeadTimeList([]);
    compareIRIForecastTime.year = e;
    compareIRIForecastTime.month = null;
    compareIRIForecastTime.lead_time = null;
    setCompareIRIForecastTime({ ...compareIRIForecastTime });
  };

  const compareForecastTimeMonthHandler = (e) => {
    setCompareIRIAvailableLeadTimeList([]);
    compareIRIForecastTime.month = e;
    compareIRIForecastTime.lead_time = null;
    setCompareIRIForecastTime({ ...compareIRIForecastTime });
  };

  const handleChartDisplay = () => {
    setShowCharts(!showCharts);
  };

  const setMapReference = (mapInstance) => {
    mapRef.current = mapInstance;
    setMap(mapInstance);
  };

  const clearDatesHandler = () => {
    setStaticResourceDates([]);
  };

  const returnFilteredGeoJsonData = (
    selectedLayer,
    item,
    location,
    propertyName,
    locationName,
    countryData,
  ) => {
    if (selectedLayer?.layer && selectedLayer?.layer?.current) {
      let data = JSON.parse(JSON.stringify(item.data));
      let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
        (item) => item.selectedCountry === selectedLocation?.country?.name,
      );
      let labelprop = selectedCountryData ? selectedCountryData[propertyName] : '';
      if (item.config.name === locationName && !_.isEmpty(location)) {
        data.features = item.data?.features.filter((x) => {
          if (_.startCase(_.lowerCase(x?.properties[labelprop])) === location?.name) {
            selectedLayer.country = selectedLocation?.country;
            selectedLayer?.layer?.current?.clearLayers();
            return x;
          }
        });
        selectedLayer.layer.current.addData(data);
        selectedLayer.color = item.config.color;
      }
      return selectedLayer;
    }
  };

  useEffect(() => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
    let compareArr = [];
    let updatedLegendArray = [];

    let updatedLegend = [];
    if (selectedLegend.length) {
      updatedLegend = selectedLegend?.map((value, i) => {
        if (value.add) value.add = false;
        return value;
      });
      setSelectedMapPreferLegendData(updatedLegend);
    }
    let previousParamRef = '';
    if (selectedParams.length) {
      previousParamRef = layerRef.current[selectedParams[0]?.parameter?.paramIndex];
    }
    if (previousParamRef && previousParamRef.current) {
      previousParamRef?.current?.eachLayer((layer) => {
        removeTransparencyToolLayers(indexLayers, layer?._url);
      });
      removeLayers(previousParamRef);
    }
    let legendArray = [...selectedParamLegendData];
    let compareLegendArray = [...compareParamLegendData];
    if (selectedParamLegendData.length) {
      // updatedLegendArray = legendArray?.map((value,i)=>{
      //   if(value.add) value.add = false;
      //   return value;
      // })
      legendArray.forEach((value, i) => {
        if (value.add) {
          value.add = false;
        }
      });
    }
    if (compareParamLegendData.length) {
      compareArr = compareLegendArray?.map((value, i) => {
        if (value.add) value.add = false;
        return value;
      });
    }
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      removeTransparencyToolLayers(
        indexLayers,
        layerControl?.firstLayer?._url,
        layerControl?.secondLayer?._url,
      );
      map?.removeControl(mapControl);
    }
    setMapControl('');
    setLayerControl({
      firstLayer: {},
      secondLayer: {},
    });
    // setSelectedParamLegendData(updatedLegendArray);
    setSelectedParamLegendData(legendArray);
    setCompareParamLegendData(compareArr);
  }, [regionId]);

  const addLegend = (paramLegendData) => {
    if (paramLegendData?.length) {
      return paramLegendData?.map((item) => {
        return (
          <>
            {item?.descret_legend ? (
              <DiscreteLegend
                map={map}
                add={item.add}
                layer={item}
                position={
                  item.isCompare
                    ? 'bottomleft'
                    : item.dataSource === 'Land use land cover' ||
                      item.dataSource === 'Permanent Water' ||
                      item.dataSource === 'Water Resources'
                    ? 'bottomleft'
                    : 'bottomright'
                }
              />
            ) : (
              // <DiscreteLegend map={map} add={item.add} layer={item} position={item.isCompare?"bottomleft":item.isCompare?"bottomleft":item.dataSource==="Land use land cover"?"bottomleft":"bottomright"} />
              // <DiscreteLegend map={map} add={item.add} layer={item} position={"bottomleft"} />

              <CustomLegend
                map={map}
                add={item.add}
                layer={item}
                position={item.isCompare ? 'bottomleft' : 'bottomright'}
              />
            )}
          </>
        );
      });
    }
  };

  const removeLegend = (paramLegend) => {
    let selectedLegend = JSON.parse(JSON.stringify(paramLegend));
    if (selectedLegend.length) {
      if (selectedLegend.length) {
        selectedLegend.forEach(function (value, i) {
          if (value.add) {
            value.add = false;
          }
        });
        setSelectedParamLegendData(selectedLegend);
      }
    }
  };

  const addLayers = (selectedParamRef) => {
    if (mapRef && selectedParamRef) {
      if (mapRef && mapRef.current && selectedParamRef.current) {
        const leafletMapRef = mapRef.current;
        const firstLayer = selectedParamRef.current;
        [firstLayer].forEach((layer, index) => {
          leafletMapRef.addLayer(layer);
        });
      }
    }
    return false;
  };

  const removeLayers = (selectedParamRef) => {
    if (mapRef && mapRef?.current && selectedParamRef && selectedParamRef?.current) {
      const map = mapRef.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => map.removeLayer(layer));
    }
  };

  const getMapPreferencesData = (mapPreferencesLayerData) => {
    /** removing previously added map preferences layers before setting the state */
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    /** setting map preferences state*/
    let arr = JSON.parse(JSON.stringify(mapPreferencesLayerData));
    let paramData = '';
    let paramArr = '';
    if (selectedMapPreferLegendData?.length) {
      paramData = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      paramData?.map((item, index) => {
        if (item.add) {
          let legendItem = { ...item };
          legendItem.add = false;
          paramData[index] = legendItem;
        }
      });
      paramArr = [...paramData];
    }
    setSelectedMapPreferLegendData(paramArr);
    setMapPreferencesData(arr);
  };

  const handleAdvancedSearch = (props) => {
    if (props.hasOwnProperty('state')) {
      if (props.state === DEFAULT_COUNTRY.name) {
        setSelectedState('');
        setSelectedDistrict('');
      } else {
        setSelectedState(props.state);
        setSelectedDistrict('');
      }
    }
    if (props.hasOwnProperty('district')) {
      setSelectedDistrict(props.district);
    }
  };

  const handleCompareSearch = (props) => {
    let country = '';
    let state = '';
    let district = '';
    if (props.hasOwnProperty('state')) {
      if (props.state === DEFAULT_COUNTRY.name) {
        state = '';
        district = '';
        setCompareSearch((prev) => {
          return {
            ...prev,
            district,
          };
        });
        setCompareSearch((prev) => {
          return {
            ...prev,
            state,
          };
        });
      } else {
        state = props?.state;
        district = '';
        setCompareSearch((prev) => {
          return {
            ...prev,
            district,
          };
        });
        setCompareSearch((prev) => {
          return {
            ...prev,
            state,
          };
        });
      }
    }
    if (props.hasOwnProperty('district')) {
      district = props?.district;
      setCompareSearch((prev) => {
        return {
          ...prev,
          district,
        };
      });
    }
    if (props.hasOwnProperty('country')) {
      country = props?.country;
      setCompareSearch((prev) => {
        return {
          ...prev,
          country,
        };
      });
    }
  };

  const handleDefaultDataSource = (isDefault) => {
    if (isDefault !== isDefaultDataSource) {
      setIsDefaultDataSource(isDefault);
    }
  };

  const handleSelectedRegion = (reg) => {
    let mapLayerData = [];
    let polyCount = 1;
    let mapData = JSON.parse(JSON.stringify(reg));
    if (mapData?.length) {
      mapData.map((item) => {
        let x = Object.assign(item, {});
        if (item.layerType === 'polygon') {
          x.latlngs = item.latlngs.map((v) => [v[1], v[0]]);
          x?.latlngs?.push(item.latlngs[0]);
          if (!x.hasOwnProperty('polygonCount')) {
            x.polygonCount = polyCount;
            polyCount += 1;
          }
          mapLayerData.push(x);
        } else {
          x.latlngs = [item.latlngs[1], item.latlngs[0]];
          mapLayerData.push(x);
        }
      });
    }
    if (reg?.length) {
      if (reg?.length === 1 && reg[0].layerType === 'marker') {
        let cordinates = reg[0]?.latlngs;
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            markerCordinates: cordinates,
          };
        });
      } else {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            markerCordinates: [],
          };
        });
      }
    } else {
      setCordinateAndZoom((prev) => {
        return {
          ...prev,
          markerCordinates: [],
        };
      });
    }
    if ((!mapLayerData?.length && region !== null) || mapLayerData?.length) setRegion(mapLayerData);
  };

  const renderCordinates = (cordinates) => {
    let arr = [];
    if (Array.isArray(cordinates)) {
      arr = cordinates;
    } else {
      arr[0] = cordinates?.lat;
      arr[1] = cordinates?.lng;
    }
    return arr;
  };

  const forecastLeadTimeHandler = (e) => {
    IRIForecastTime.lead_time = e;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  const forecastCompareLeadTimeHandler = (e) => {
    compareIRIForecastTime.lead_time = e;
    setCompareIRIForecastTime({ ...compareIRIForecastTime });
  };

  const forecastYearMonthHandler = (e) => {
    setSelectedForecastYearMonth((prev) => {
      return {
        ...prev,
        map: {
          //  ...selectedForecastYearMonth.map,
          forecastYearMonth: e,
        },
        graph: {
          //...initialSelectedDate.graph,
          forecastYearMonth: e,
        },
      };
    });
  };

  const forecastCompareYearMonthHandler = (e) => {
    setSelectedFinalForercastLeadTime(null);
    setSelectedForecastCompareYearMonth((prev) => {
      return {
        ...prev,
        map: {
          //  ...selectedForecastYearMonth.map,
          forecastYearMonth: e,
        },
        graph: {
          //...initialSelectedDate.graph,
          forecastYearMonth: e,
        },
      };
    });
  };

  const handleForecastData = () => {
    let requestObj = {};
    if (
      initialSelectedDate['map'].startDate &&
      initialSelectedDate['map'].endDate &&
      dataSources.length &&
      !_.isEmpty(selectedParam)
    ) {
      const cordinates = renderCordinates(cordinateAndZoom?.locationCordinates);
      requestObj.latitude = cordinates[0];
      requestObj.longitude = cordinates[1];

      const forecastData = dataSources?.map((item) => item.id);
      if (!_.isEmpty(country)) requestObj.country_name = country;

      if (!_.isEmpty(selectedState)) requestObj.state_name = selectedState;

      if (!_.isEmpty(selectedDistrict)) requestObj.district_name = selectedDistrict;

      //  if(selectedDistrict){
      //    requestObj.district_name = selectedDistrict || selectedState || country;
      //  }
      //  else if(selectedState){
      //   requestObj.state_name = selectedState
      //  }
      //  else if(country){
      //    requestObj.country_name = country
      //  }
      if (_.isEmpty(country) && _.isEmpty(selectedState) && _.isEmpty(selectedDistrict))
        if (!_.isEmpty(country))
          //requestObj.region_id = selectedRegionId;

          requestObj.country_name = country;

      if (!_.isEmpty(selectedState)) requestObj.state_name = selectedState;

      if (!_.isEmpty(selectedDistrict)) requestObj.district_name = selectedDistrict;

      requestObj.weather_forecast_parameter_id = selectedParam.weather_forecast_parameter_id;
      requestObj.weather_forecast_source_id = forecastData[0];

      let initialRequestObj = Object.assign({}, requestObj);
      let finalRequestObj = Object.assign({}, requestObj);

      if (compareDataSources?.length && moment(finalSelectedDate?.startDate).format(DATE_FORMAT)) {
        const compareData = compareDataSources?.map((item) => item.id);

        initialRequestObj.start_date = moment(initialSelectedDate['map'].startDate).format(
          DATE_FORMAT,
        );
        initialRequestObj.end_date = moment(initialSelectedDate['map'].endDate).format(DATE_FORMAT);
        initialRequestObj.weather_forecast_source_id = forecastData[0];

        finalRequestObj.start_date = moment(finalSelectedDate?.startDate).format(DATE_FORMAT);
        finalRequestObj.end_date = moment(finalSelectedDate?.endDate).format(DATE_FORMAT);

        finalRequestObj.weather_forecast_source_id = compareData[0];

        let layer = OPEN_WEATHER_PARAMETERS_CONST.find(
          (layerObj) => layerObj.name === selectedParam?.weather_forecast_parameter?.name,
        );
        // let compareArray = [...compareParamLegendData];
        // if (compareArray?.length) {
        //   compareArray?.forEach((value, i) => {
        //     if (value.add) value.add = false;
        //   });
        // }
        // setCompareParamLegendData(compareArray);
        let compareArray = [...compareParamLegendData];
        let updatedCompareArray = [];
        if (compareArray?.length) {
          updatedCompareArray = compareArray?.map((value, i) => {
            if (value.add) value.add = false;
            return value;
          });
        }
        setCompareParamLegendData(updatedCompareArray);
        removeLegend(selectedParamLegendData);
        let initialRequestResponse;
        let finalRequestResponse;
        if (dataSources[0]?.id === DATA_SOURCES.OPEN_WEATHER.id) {
          initialRequestResponse = handleOpenWeatherCompareRes(layer);
        } else {
          removeLegend(selectedParamLegendData);
          initialRequestObj.temporal_aggregation = temporalAggregation.value;
          if (range.min) initialRequestObj.min = range.min;
          if (range.max) initialRequestObj.max = range.max;
          if (dataSources[0].id === DATA_SOURCES.IRI.id) {
            initialRequestObj.temporal_aggregation =
              TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
              temporalAggregation?.value;
            if (IRIForecastTime?.year && IRIForecastTime?.month) {
              initialRequestObj.forecast_time_year = IRIForecastTime?.year?.value;
              initialRequestObj.forecast_time_month =
                IRIForecastTime?.month?.id < 9
                  ? '0' + IRIForecastTime?.month?.id
                  : IRIForecastTime?.month?.id;
            }
            if (IRIForecastTime?.lead_time) {
              initialRequestObj.forecast_lead_time = parseInt(
                IRIForecastTime?.lead_time?.value?.substr(
                  IRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                  IRIForecastTime?.lead_time?.value?.indexOf(')') -
                    IRIForecastTime?.lead_time?.value?.indexOf('(') -
                    1,
                ),
              );
            }
          }
          setIsLoading(true);
          initialRequestResponse = AXIOS_INSTANCE.post(
            WEATHER_FORECAST_DATA_URL,
            initialRequestObj,
          );
        }

        if (compareDataSources[0].id === DATA_SOURCES.OPEN_WEATHER.id) {
          finalRequestResponse = handleOpenWeatherCompareRes(layer);
        } else {
          finalRequestObj.temporal_aggregation = TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value 
          if (range.min) finalRequestObj.min = range.min;
          if (range.max) finalRequestObj.max = range.max;
          setIsLoading(true);
          if (compareDataSources[0].id === DATA_SOURCES.IRI.id) {
            finalRequestObj.temporal_aggregation = TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value 
            if (compareIRIForecastTime?.year && compareIRIForecastTime?.month) {
              finalRequestObj.forecast_time_year = compareIRIForecastTime?.year?.value;
              finalRequestObj.forecast_time_month =
                compareIRIForecastTime?.month?.id < 9
                  ? '0' + compareIRIForecastTime?.month?.id
                  : compareIRIForecastTime?.month?.id;
            }
            if (compareIRIForecastTime?.lead_time) {
              // finalRequestObj.forecast_lead_time =  selectedFinalForercastLeadTime?.id - parseInt(selectedForecastCompareYearMonth?.map?.forecastYearMonth.split('-')[1])
              finalRequestObj.forecast_lead_time = parseInt(
                compareIRIForecastTime?.lead_time?.value?.substr(
                  compareIRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                  compareIRIForecastTime?.lead_time?.value?.indexOf(')') -
                    compareIRIForecastTime?.lead_time?.value?.indexOf('(') -
                    1,
                ),
              );
            }
          }
          finalRequestResponse = AXIOS_INSTANCE.post(WEATHER_FORECAST_DATA_URL, finalRequestObj);
        }
        const responseData = [];
        let isError = false;
        Promise.all([initialRequestResponse, finalRequestResponse])
          .then((responses) => {
            setIsLoading(false);
            setSelectedParamLegendData([]);
            setCompareParamLegendData([]);
            responses.map((item) => {
              const { data } = item;
              data?.result.map((res) => {
                let val = {};
                for (let [key, value] of Object.entries(res)) {
                  if (value && Array.isArray(value)) {
                    if (value[0]?.map_data.code === 200) {
                      val = value;
                      val[0].name = key;
                    } else if (value[0]?.map_data.code === 400) {
                      if (!isError) {
                        showError(value[0]?.map_data?.message);
                      }
                      isError = true;
                      return false;
                    }
                  } else {
                    if (value?.map_data.code === 200) {
                      val = value;
                      val.name = key;
                    } else if (value?.map_data.code === 400) {
                      if (!isError) {
                        showError(value?.map_data?.message);
                      }
                      isError = true;
                      return false;
                    }
                  }
                }
                if (!isError) responseData.push(val[0]);
              });
            });
            if (!isError) {
              setWeatherData({
                ...dateSourceWeatherData,
                data: responseData,
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            if (error?.response) {
              let errorRes = error.response;
              if (!_.isEmpty(errorRes?.data?.errors)) {
                showError(Object.values(errorRes?.data?.errors)[0]);
              } else {
                showError(errorRes?.data?.message);
              }
              //setErrorData({ showError: true, data: errorRes?.data?.message });
            } else {
              showError('No records found. please try again');
            }
            return;
            // setErrorData({ showError: true, data: 'No records found. please try again' });
            // }
          });
      } else {
        requestObj.start_date = moment(initialSelectedDate['map'].startDate).format(DATE_FORMAT);
        requestObj.end_date = moment(initialSelectedDate['map'].endDate).format(DATE_FORMAT);
        if (dataSources[0]?.id !== DATA_SOURCES.OPEN_WEATHER.id) {
          if (range.min) requestObj.min = range.min;
          if (range.max) requestObj.max = range.max;
          removeLegend(selectedParamLegendData);
          /** dispatching map request for GEE dataSources */
          requestObj.temporal_aggregation = temporalAggregation.value;
          if (dataSources[0]?.id === DATA_SOURCES.IRI.id) {
            requestObj.temporal_aggregation =
              TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
              temporalAggregation?.value;
            requestObj.forecast_time_year = IRIForecastTime?.year?.value;
            requestObj.forecast_time_month =
              IRIForecastTime?.month?.id < 9
                ? '0' + IRIForecastTime?.month?.id
                : IRIForecastTime?.month?.id;
            //requestObj.forecast_lead_time = selectedForecastLeadTime?.id - parseInt(selectedForecastYearMonth?.map?.forecastYearMonth.split('-')[1])
            requestObj.forecast_lead_time = parseInt(
              IRIForecastTime?.lead_time?.value?.substr(
                IRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                IRIForecastTime?.lead_time?.value?.indexOf(')') -
                  IRIForecastTime?.lead_time?.value?.indexOf('(') -
                  1,
              ),
            );
          }
          setIsLoading(true);
          dispatch(getWeatherForecastData(requestObj));
          setIsDefaultDataSource(false);
        }
      }
      setApplyLayers(true);
    }
  };
  const handleUpdateTimeSeries = async () => {
    setCompareParamForGraph(compareParams);
    let requestObj = {};
    if (
      !_.isEmpty(cordinateAndZoom.locationCordinates) &&
      initialSelectedDate['graph'].startDate &&
      initialSelectedDate['graph'].endDate &&
      dataSources.length &&
      !_.isEmpty(selectedParam)
    ) {
      setErrorData({ showError: false, data: '' });
      const forecastData = dataSources?.map((item) => item.id);
      const compareForecastData = compareDataSources?.map((item) => item.id);
      const cordinates = renderCordinates(cordinateAndZoom.locationCordinates);
      const markerCordinates = cordinateAndZoom.markerCordinates;
      const latitude = markerCordinates.length ? markerCordinates[0] : cordinates[0];
      const longitude = markerCordinates.length ? markerCordinates[1] : cordinates[1];
      // if (

      //   _.isEmpty(country) &&
      //   _.isEmpty(selectedState) &&
      //   _.isEmpty(selectedDistrict)
      // )
      // requestObj.region_id = selectedRegionId;
      if (country) requestObj.country_name = country;

      if (selectedState) requestObj.state_name = selectedState;

      if (selectedDistrict) requestObj.district_name = selectedDistrict;

      requestObj.weather_forecast_parameter_id = !_.isEmpty(selectedParam)
        ? selectedParam.weather_forecast_parameter_id
        : '';

      requestObj.start_date = moment(initialSelectedDate['graph'].startDate).format(DATE_FORMAT);
      requestObj.end_date = moment(initialSelectedDate['graph'].endDate).format(DATE_FORMAT);

      let initialRequestObj = Object.assign({}, requestObj);
      let finalRequestObj = Object.assign({}, requestObj);

      if (compareDataSources?.length) {
        let layer = OPEN_WEATHER_PARAMETERS_CONST.find(
          (layerObj) => layerObj.name === selectedParam?.weather_forecast_parameter?.name, // need to check this line
        );
        let initialRequestResponse;
        let finalRequestResponse;
        let initialDataSourceAPI;
        if (dataSources[0].id === DATA_SOURCES.GFS_NOAA.id) {
          initialDataSourceAPI = NOAA_TIMESERIES;
        } else if (dataSources[0].id === DATA_SOURCES.ERPAS.id) {
          initialDataSourceAPI = ERPAS_TIMESERIES;
        } else if (dataSources[0].id === DATA_SOURCES.IRI.id) {
          initialDataSourceAPI = IRI_TIMESERIES;
        }
        let finalDataSourceAPI;
        // compareDataSources[0].id === DATA_SOURCES.GFS_NOAA.id
        //   ? NOAA_TIMESERIES
        //   : ERPAS_TIMESERIES;
        if (compareDataSources[0].id === DATA_SOURCES.GFS_NOAA.id) {
          finalDataSourceAPI = NOAA_TIMESERIES;
        } else if (compareDataSources[0].id === DATA_SOURCES.ERPAS.id) {
          finalDataSourceAPI = ERPAS_TIMESERIES;
        } else if (compareDataSources[0].id === DATA_SOURCES.IRI.id) {
          finalDataSourceAPI = IRI_TIMESERIES;
        }
        if (dataSources[0]?.id === DATA_SOURCES.OPEN_WEATHER.id) {
          setIsGraphLoading(true);
          initialRequestObj.latitude = latitude;
          initialRequestObj.longitude = longitude;
          initialRequestObj.weather_forecast_source_id = forecastData[0];
          let config = {
            id: dataSources[0]?.id,
            layerType: DATA_SOURCES.OPEN_WEATHER.name,
            location: selectedDistrict || selectedState || country || SOUTH_ASIA_CONST.name,
          };
          initialRequestResponse = AXIOS_INSTANCE.post(
            WEATHER_FORECAST_DATA_URL,
            initialRequestObj,
            config,
          );
        } else {
          initialRequestObj.spatial_aggregation = temporalAggregation.value;
          if (range.min) initialRequestObj.min = range.min;
          if (range.max) initialRequestObj.max = range.max;
          if (dataSources[0].id === DATA_SOURCES.IRI.id) {
            initialRequestObj.spatial_aggregation =
              TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
              temporalAggregation?.value;
            if (IRIForecastTime?.year?.value && IRIForecastTime?.month?.id) {
              initialRequestObj.forecast_time_year = IRIForecastTime?.year?.value;
              initialRequestObj.forecast_time_month =
                IRIForecastTime?.month?.id < 9
                  ? '0' + IRIForecastTime?.month?.id
                  : IRIForecastTime?.month?.id;
            }
            if (IRIForecastTime?.lead_time) {
              // initialRequestObj.forecast_lead_time = selectedForecastLeadTime?.id - parseInt(selectedForecastYearMonth?.map?.forecastYearMonth.split('-')[1])
              initialRequestObj.forecast_lead_time = parseInt(
                IRIForecastTime?.lead_time?.value?.substr(
                  IRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                  IRIForecastTime?.lead_time?.value?.indexOf(')') -
                    IRIForecastTime?.lead_time?.value?.indexOf('(') -
                    1,
                ),
              );
            }
          }
          setIsGraphLoading(true);
          let config = {
            id: dataSources[0]?.id,
            layerType:
              dataSources[0].id === DATA_SOURCES.GFS_NOAA.id
                ? DATA_SOURCES.GFS_NOAA.name
                : dataSources[0].id === DATA_SOURCES.ERPAS.id
                ? DATA_SOURCES.ERPAS.name
                : DATA_SOURCES.IRI.name,
            location: selectedDistrict || selectedState || country || SOUTH_ASIA_CONST.name,
          };
          initialRequestResponse = AXIOS_INSTANCE.post(
            initialDataSourceAPI,
            initialRequestObj,
            config,
          );
        }

        if (
          selectedCountryId &&
          // _.isEmpty(compareSearch?.country) &&
          _.isEmpty(compareSearch?.state) &&
          _.isEmpty(compareSearch?.district)
        )
          if (compareSearch?.country) {
            // finalRequestObj.region_id = selectedRegionId;
            finalRequestObj.country_name = compareSearch?.country;
          } else {
            delete finalRequestObj.country_name;
          }
        if (compareSearch?.state) {
          finalRequestObj.state_name = compareSearch?.state;
        } else {
          delete finalRequestObj.state_name;
        }
        if (compareSearch?.district) {
          finalRequestObj.district_name = compareSearch?.district;
        } else {
          delete finalRequestObj.district_name;
        }
        // if(compareSearch?.country)
        // finalRequestObj.country_name = compareSearch?.country;
        // if(compareSearch?.state)
        // finalRequestObj.state_name = compareSearch?.state;
        // if(compareSearch?.district)
        // finalRequestObj.district_name = compareSearch?.district;
        const compareLatitude = compareCordinates[0];
        const compareLongitude = compareCordinates[1];

        let finalConfig = {
          id: compareDataSources[0]?.id,
          layerType:
            compareDataSources[0].id === DATA_SOURCES.OPEN_WEATHER.id
              ? DATA_SOURCES.OPEN_WEATHER.name
              : compareDataSources[0].id === DATA_SOURCES.GFS_NOAA.id
              ? DATA_SOURCES.GFS_NOAA.name
              : compareDataSources[0].id === DATA_SOURCES.IRI.id
              ? DATA_SOURCES.IRI.name
              : DATA_SOURCES.ERPAS.name,
          location:
            compareSearch?.district ||
            compareSearch?.state ||
            compareSearch?.country ||
            SOUTH_ASIA_CONST.name,
        };
        if (compareDataSources[0].id === DATA_SOURCES.OPEN_WEATHER.id) {
          finalRequestObj.latitude = compareLatitude;
          finalRequestObj.longitude = compareLongitude;
          finalRequestObj.weather_forecast_source_id = compareForecastData[0];
          finalRequestObj.spatial_aggregation = temporalAggregation.value;
          finalRequestResponse = AXIOS_INSTANCE.post(
            WEATHER_FORECAST_DATA_URL,
            // finalDataSourceAPI,
            finalRequestObj,
            finalConfig,
          );
        } else {
          setIsGraphLoading(true);
          finalRequestObj.spatial_aggregation = TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value 
          if (range.min) finalRequestObj.min = range.min;
          if (range.max) finalRequestObj.max = range.max;
          if (compareDataSources[0].id === DATA_SOURCES.IRI.id) {
            finalRequestObj.spatial_aggregation =
              TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value
            if (IRIForecastTime?.year?.value && IRIForecastTime?.month?.id) {
              finalRequestObj.forecast_time_year = IRIForecastTime?.year?.value;
              finalRequestObj.forecast_time_month =
                IRIForecastTime?.month?.id < 9
                  ? '0' + IRIForecastTime?.month?.id
                  : IRIForecastTime?.month?.id;
            }
            if (IRIForecastTime?.lead_time) {
              finalRequestObj.forecast_lead_time = parseInt(
                IRIForecastTime?.lead_time?.value?.substr(
                  IRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                  IRIForecastTime?.lead_time?.value?.indexOf(')') -
                    IRIForecastTime?.lead_time?.value?.indexOf('(') -
                    1,
                ),
              );
            }
          }
          finalRequestResponse = AXIOS_INSTANCE.post(
            finalDataSourceAPI,
            finalRequestObj,
            finalConfig,
          );
        }
        const responseData = [];
        Promise.all([initialRequestResponse, finalRequestResponse])
          .then((responses) => {
            setIsGraphLoading(false);
            responses.map((item) => {
              const { data: itemData } = item;
              if (Array.isArray(itemData?.result)) {
                if (itemData.result.length && itemData.result[0]?.Open_Weather?.message) {
                  setErrorData({
                    showError: true,
                    data: itemData.result[0]?.Open_Weather?.message,
                  });
                } else {
                  let obj = renderOpenWeatherResponse(itemData?.result, item.config, selectedParam);
                  responseData.push(obj);
                }
              } else {
                if (item.config.layerType !== 'IRI') {
                  for (let [key, value] of Object.entries(itemData?.result)) {
                    let val = value;
                    val.layerType = item.config.layerType;
                    val.location = item.config.location;
                    responseData.push(val);
                  }
                } else {
                  let val = itemData?.result;
                  val.layerType = item.config.layerType;
                  val.location = item.config.location;
                  responseData.push(val);
                }
              }
            });
            setErrorData({ showError: false, data: '' });
            setNoaaRegionTimeSeries(responseData);
          })
          .catch((error) => {
            setIsGraphLoading(false);
            if (error?.response) {
              let errorRes = error.response;
              setErrorData({ showError: true, data: errorRes?.data?.message });
            } else {
              setErrorData({ showError: true, data: 'No records found. please try again' });
            }
          });
      } else {
        /** timesereis call */
        if (dataSources?.some((item) => item.id === DATA_SOURCES.OPEN_WEATHER.id)) {
          requestObj.latitude = latitude;
          requestObj.longitude = longitude;
          requestObj.weather_forecast_source_id = forecastData[0];
          setIsGraphLoading(true);
          let isRegionLength =
            region?.length === 2
              ? true
              : region?.length === 1 && region[0]?.layerType === 'polygon'
              ? true
              : false;
          if (isRegionLength) {
            await renderGeometricStateData(region, requestObj);
          } else {
            if (region?.length === 1 && region[0]?.layerType === 'marker') {
              requestObj.latitude = region[0].latlngs[1];
              requestObj.longitude = region[0].latlngs[0];
            }
            dispatch(getWeatherForecastData(requestObj));
          }
        } else {
          setIsDefaultDataSource(false);
          setIsGraphLoading(true);
          if (range.min) requestObj.min = range.min;
          if (range.max) requestObj.max = range.max;
          /** dispatching time series request for GEE data sources  */
          requestObj.spatial_aggregation = temporalAggregation.value;
          if (dataSources[0].id === DATA_SOURCES.IRI.id) {
            requestObj.spatial_aggregation =
              TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
              temporalAggregation?.value;
            if (IRIForecastTime?.year && IRIForecastTime?.month) {
              requestObj.forecast_time_year = IRIForecastTime?.year?.value;
              requestObj.forecast_time_month =
                IRIForecastTime?.month?.id < 9
                  ? '0' + IRIForecastTime?.month?.id
                  : IRIForecastTime?.month?.id;
            }
            if (IRIForecastTime?.lead_time) {
              // requestObj.forecast_lead_time =  selectedForecastLeadTime?.id - parseInt(selectedForecastYearMonth?.map?.forecastYearMonth.split('-')[1])
              requestObj.forecast_lead_time = parseInt(
                IRIForecastTime?.lead_time?.value?.substr(
                  IRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                  IRIForecastTime?.lead_time?.value?.indexOf(')') -
                    IRIForecastTime?.lead_time?.value?.indexOf('(') -
                    1,
                ),
              );
            }
          }
          let isRegionLength =
            region?.length === 2
              ? true
              : region?.length === 1 && region[0]?.layerType === 'polygon'
              ? true
              : false;
          if (isRegionLength) {
            await renderGeometricStateData(region, requestObj);
          } else {
            for (let param of categoryParams) {
              requestObj.weather_forecast_parameter_id = param.weather_forecast_parameter_id;
              if (region?.length === 1 && region[0]?.layerType === 'marker') {
                requestObj.geometry = [region[0]?.latlngs];
              }
              let actionDispatch;
              if (dataSources[0]?.id === DATA_SOURCES.GFS_NOAA.id) {
                actionDispatch = getNoaaTimeSeries;
              } else if (dataSources[0]?.id === DATA_SOURCES.ERPAS.id) {
                actionDispatch = getErpasTimeSeries;
              } else if (dataSources[0]?.id === DATA_SOURCES.IRI.id) {
                actionDispatch = getIRITimeSeries;
              }
              
              dispatch(actionDispatch(requestObj));
            }
          }
        }
        /** */
      }
    }
    setUpdateGraph(true);
  };

  const renderGeometricStateData = async (region, requestObj) => {
    setIsGraphLoading(true);
    setErrorData({ showError: false, data: '' });
    const data = await renderGeometricTimeSeries(
      requestObj,
      region,
      dataSources,
      isOpenWeather,
      selectedParam,
    );
    if (data?.response) {
      setNoaaRegionTimeSeries(data?.noaaResponse);
    } else {
      setErrorData(data?.errorData);
    }
    setIsGraphLoading(false);
  };

  window.onunload = function () {
    Cookies.remove('weather-forecast-default-param', { path: '/' });
  };

  useEffect(() => {
    if (!_.isEmpty(mapControl)) {
      mapControl?.on('dividermove', map.dragging.enable());
    } else if (!_.isEmpty(map)) {
      map?.dragging?.enable();
    }
  }, [mapControl]);

  useEffect(() => {
    if (isOpenWeather && !_.isEmpty(selectedParam)) {
      let paramCookie = Cookies.get('weather-forecast-default-param');
      if (paramCookie === undefined) {
        setWeatherForecastDefaultParam();
        handleApplyLayers(true);
      } else {
        return;
      }
    }
  }, [isOpenWeather, selectedParam]);

  const handleApplyLayers = (applyLayers) => {
    setShowCharts(false);
    if (applyLayers) {
      if (!_.isEmpty(layerControl?.firstLayer)) {
        map?.removeControl(layerControl?.firstLayer);
      }
      if (!_.isEmpty(layerControl?.secondLayer)) {
        map?.removeControl(layerControl?.secondLayer);
      }
      if (!_.isEmpty(mapControl)) {
        map?.removeControl(mapControl);
        removeTransparencyToolLayers(
          indexLayers,
          layerControl?.firstLayer?._url,
          layerControl?.secondLayer?._url,
        );
        setMapControl('');
        setLayerControl({
          firstLayer: {},
          secondLayer: {},
        });
        // let compareArray = [...compareParamLegendData];
        // if (compareArray?.length) {
        //   compareArray?.forEach((value, i) => {
        //     if (value.add) value.add = false;
        //   });
        // }
        // setCompareParamLegendData(compareArray);
        let compareArray = [...compareParamLegendData];
        let updatedCompareArray = [];
        if (compareArray?.length) {
          updatedCompareArray = compareArray?.map((value, i) => {
            if (value.add) value.add = false;
            return value;
          });
        }
        setCompareParamLegendData(updatedCompareArray);
        removeLegend(selectedParamLegendData);
      }
      if (isOpenWeather && !isCompareClicked && !compareDataSources?.length) {
        // setIsLoading(true);
        removeLegend(selectedParamLegendData);
        let layer = OPEN_WEATHER_PARAMETERS_CONST.find(
          (layerObj) => layerObj.name === selectedParam?.weather_forecast_parameter?.name,
        );
        let response = handleOpenWeatherCompareRes(layer);
        if (!_.isEmpty(response)) {
          const { data } = response;
          setForecastData(data?.result);
          // if (!mapPreferenceGeoJsonloading) {
          //   setIsLoading(false);
          // }
        }
      } else {
        handleForecastData();
      }
    }
  };

  const handleGraph = () => {
    setNoaaRegionTimeSeries([]);
    setChartSeriesData([]);
    setParameterData([]);
    handleUpdateTimeSeries();
  };

  const handleCompareCordinates = (cordinates) => {
    setCompareCordinates(cordinates);
  };

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          let data = JSON.parse(JSON.stringify(item.data));
          if (
            item.config.name !== MAP_PREFERENCES_lABELS.DISTRICTS &&
            item.config.name !== MAP_PREFERENCES_lABELS.TALUKAS
          ) {
            selectedLayer.country = selectedLocation?.country;
            selectedLayer?.layer?.current?.clearLayers();
            selectedLayer.layer.current.addData(data);
            selectedLayer.color = item.config.color;
            setMapPreferences((prev) => {
              return {
                ...prev,
                [mapGeojsonItem]: selectedLayer,
              };
            });
          }
        }
      });
    }
  }, [geoJsonAllData, mapPreferences?.state?.layer?.current, selectedLocation?.country]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          if (
            !_.isEmpty(selectedLocation?.district) ||
            selectedLocation?.district?.name !== 'select'
          ) {
            let filteredLayer = returnFilteredGeoJsonData(
              selectedLayer,
              item,
              selectedLocation?.district,
              MAP_PREFERENCES_lABELS.DISTRICTS,
              MAP_PREFERENCES_lABELS.TALUKAS,
              selectedLocation?.country,
            );
            if (filteredLayer) {
              setMapPreferences((prev) => {
                return {
                  ...prev,
                  [mapGeojsonItem]: filteredLayer,
                };
              });
            }
          }
          if (
            !_.isEmpty(selectedLocation?.state) ||
            selectedLocation?.state?.value !== DEFAULT_COUNTRY.name
          ) {
            let filteredLayer = returnFilteredGeoJsonData(
              selectedLayer,
              item,
              selectedLocation?.state,
              MAP_PREFERENCES_lABELS.STATES,
              MAP_PREFERENCES_lABELS.DISTRICTS,
              selectedLocation?.country,
            );
            if (filteredLayer) {
              setMapPreferences((prev) => {
                return {
                  ...prev,
                  [mapGeojsonItem]: filteredLayer,
                };
              });
            }
          }
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          // (item.config.name !== MAP_PREFERENCES_lABELS.DISTRICTS &&
          //   item.config.name !== MAP_PREFERENCES_lABELS.TALUKAS) ||
          //  ((selectedLayer?.layer &&
          //   selectedLayer?.layer?.current &&
          //   item.fromMapPreferences &&
          //   (_.isEmpty(selectedLocation?.state) || selectedLocation?.state === '') &&
          //   item.config.name === MAP_PREFERENCES_lABELS.DISTRICTS) ||
          //  (selectedLayer?.layer &&
          //   selectedLayer?.layer?.current &&
          //   item.fromMapPreferences &&
          //   (_.isEmpty(selectedLocation?.district) || selectedLocation?.district === '') &&
          //   item.config.name === MAP_PREFERENCES_lABELS.TALUKAS))
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          item.fromMapPreferences &&
          (_.isEmpty(selectedLocation?.state) ||
            selectedLocation?.state?.value === DEFAULT_COUNTRY.name) &&
          item.config.name === MAP_PREFERENCES_lABELS.DISTRICTS
        ) {
          selectedLayer.country = selectedLocation?.country;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(item.data);
          selectedLayer.color = item.config.color;
          setMapPreferences((prev) => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          item.fromMapPreferences &&
          (_.isEmpty(selectedLocation?.district) ||
            selectedLocation?.district?.name === 'select') &&
          item.config.name === MAP_PREFERENCES_lABELS.TALUKAS
        ) {
          selectedLayer.country = selectedLocation?.country;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(item.data);
          selectedLayer.color = item.config.color;
          setMapPreferences((prev) => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (!_.isEmpty(indexLayers)) {
      addOpacitySlider();
      if (indexLayers?.length) {
        indexLayers.map((l) => l?.layer?.bringToFront());
      }
    }
  }, [indexLayers]);

  // useEffect(() => {
  //   if (mapPreferencesData?.length) {
  //     if (mapPreferRefs?.current?.length) {
  //       mapPreferencesData?.map(item => {
  //         let selectedMapPreferences = mapPreferRefs.current.find(
  //           x => x.current.boundary_id === item.id,
  //         );
  //         let currentParamRef = selectedMapPreferences?.current?.layer;
  //         currentParamRef?.current?.eachLayer(mapLayer => {
  //           mapLayer.setUrl(item?.result?.map_url);

  //           setMapLayerOpacity({ layer: mapLayer });
  //         });
  //         if (currentParamRef && currentParamRef?.current) {
  //           addLayers(currentParamRef);
  //         }
  //       });
  //     }
  //   }
  // }, [mapPreferencesData, mapPreferRefs.current]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      if (mapPreferRefs?.current?.length) {
        mapPreferencesData?.map((item) => {
          let selectedMapPreferences = mapPreferRefs.current.find(
            (x) => x.current.boundary_id === item.id,
          );
          let currentParamRef = selectedMapPreferences.current?.layer;
          currentParamRef?.current?.eachLayer((mapLayer) => {
            mapLayer.setUrl(item?.result?.map_url);
            let updatedIndexLayer = [...indexLayers];
            updatedIndexLayer.push({ isCompare: false, layer: mapLayer });
            setIndexLayers(updatedIndexLayer);
            setMapPreferUrl({
              name: selectedParam.name,
              url: item?.result?.map_url,
              layer: mapLayer,
            });
            setMapLayerOpacity({ layer: mapLayer });
          });
          if (currentParamRef && currentParamRef?.current) {
            addLayers(currentParamRef);
          }
        });
      }
    }
  }, [mapPreferencesData, mapPreferRefs.current]);

  function addOpacitySlider() {
    // if (!window.map) console.log('not ready');
    if (indexLayers?.length) {
      L.Control.OpacitySlider = L.Control.extend({
        sliding: false,
        updateLayerOpacity: function (opacity) {
          let lastIndex = indexLayers?.length - 1;
          if (indexLayers?.length >= 2) {
            let secondLastIndex = indexLayers?.length - 2;
            if (indexLayers[lastIndex]?.isCompare && indexLayers[secondLastIndex]?.isCompare) {
              indexLayers.forEach((item, i) => {
                if (i === lastIndex || i === secondLastIndex) item?.layer.setOpacity(opacity / 100);
              });
            } else {
              indexLayers.forEach((item, i) => {
                if (i === lastIndex) item?.layer.setOpacity(opacity / 100);
              });
            }
          } else {
            indexLayers.forEach((item, i) => {
              if (i === lastIndex) item?.layer.setOpacity(opacity / 100);
            });
          }
        },
        onAdd: function (map) {
          if (opacityControl) {
            map?.removeControl(opacityControl);
          }
          var container = L.DomUtil.create('div', 'opacityContainer');
          container.innerHTML =
            '<div id=layer-opacity-control><input type="range" min="1" max="100" value="100" defaultValue="100" class="opacityslider"></div>';
          if (!L.Browser.touch) {
            L.DomEvent.disableClickPropagation(container);
            L.DomEvent.on(container, 'mousewheel', L?.DomEvent?.stopPropagation);
          } else {
            L.DomEvent.on(container, 'mousedrag click', L?.DomEvent?.stopPropagation);
          }
          let slider = container.querySelector('.opacityslider');
          slider.onclick = (e) => {
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousedown = (e) => {
            this.sliding = true;
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousemove = (e) => {
            e?.stopPropagation();
            if (this.sliding) this.updateLayerOpacity(e.target.value);
          };
          slider.onmouseup = (e) => {
            this.sliding = false;
          };
          return container;
        },
      });
      L.control.opacitySlider = function (opts) {
        return new L.Control.OpacitySlider(opts);
      };
      let customOpacityControl = L.control.opacitySlider({ position: 'topright' });
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) {
        customOpacityControl?.addTo(map);
      }
      setOpacityControl(customOpacityControl);
    }
  }

  //added
  const renderParamLayer = (paramList) => {
    let isOpenWeather = '';
    if (paramList?.length) {
      return paramList.map((item, index) => {
        isOpenWeather = item.weather_forecast_parameter.id === DATA_SOURCES.OPEN_WEATHER.id;
        const layer = OPEN_WEATHER_PARAMETERS_CONST.find(
          (layer) => layer.name === item?.weather_forecast_parameter?.name,
        );
        if (
          item.weather_forecast_parameter.name === 'Temperature' ||
          item.weather_forecast_parameter.name === 'Precipitation' ||
          (!_.isEmpty(selectedParam) &&
            item.weather_forecast_parameter.name ===
              selectedParam?.weather_forecast_parameter?.name)
        ) {
          const data = OPEN_WEATHER_CHART_PARAMS.find(
            (x) => x.name === item.weather_forecast_parameter.name,
          );
          if (
            (!parameterData.length ||
              parameterData?.findIndex(
                (paramItem) => paramItem.name === item.weather_forecast_parameter.name,
              ) === -1) &&
            ((isOpenWeather && !_.isEmpty(data)) || !isOpenWeather)
          ) {
            parameterData.push({
              name: item.weather_forecast_parameter.name,
              checked: true,
              measure: !_.isEmpty(data) ? data?.measure : '',
            });
          }
        }
        const addOverlay = layer?.id ? true : false;
        return (
          <>
            {/* TODO: need to create separate component */}
            {addOverlay || !isOpenWeather ? (
              <>
                <Overlay name={item.weather_forecast_parameter.name}>
                  <LayerGroup ref={layerRef.current[index]}>
                    <TileLayer
                      // attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url={
                        layer
                          ? layer.layerName
                            ? myConstClass.OPEN_WEATHER_TILE_LAYER_URL.replace(
                                '{layername}',
                                layer.layerName,
                              )
                            : ''
                          : ''
                      }
                      id={item.weather_forecast_parameter.name}
                    />
                  </LayerGroup>
                </Overlay>
              </>
            ) : (
              ''
            )}
          </>
        );
      });
    } else {
      <></>;
    }
  };

  const location =
    selectedLocation1?.state?.value &&
    selectedLocation1?.state?.value !== DEFAULT_COUNTRY.name &&
    selectedLocation1?.district?.value !== 'Select' &&
    selectedLocation1?.district !== ''
      ? `${selectedLocation1?.state?.value}_${selectedLocation1?.district?.value}`
      : selectedLocation1?.state?.value !== DEFAULT_COUNTRY.name && selectedLocation1?.state?.value
      ? `${selectedLocation1?.state?.value}`
      : '';
  const comparedLocation =
    compareLocation?.state?.value &&
    compareLocation?.state?.value !== DEFAULT_COUNTRY.name &&
    compareLocation?.district?.value !== 'Select' &&
    compareLocation?.district !== ''
      ? `${compareLocation?.state?.value}_${compareLocation?.district?.value}`
      : compareLocation?.state?.value !== DEFAULT_COUNTRY.name && compareLocation?.state?.value
      ? `${compareLocation?.state?.value}`
      : '';

  useEffect(() => {
    const parametersNames = handleFileName(parameterData);
    const fileName =
      isCompareClicked && compareDataSources?.length
        ? compareDataSources[0]?.name +
          '-' +
          comparedLocation +
          '_' +
          dataSources[0]?.name +
          '-' +
          location +
          '-' +
          parametersNames
        : dataSources[0]?.name + '-' + location + '-' + parametersNames;
    if (fileName) {
      setFileName(fileName);
    }
  }, [dataSources, compareDataSources, isCompareClicked, selectedLocation1, compareLocation]);

  const handleFileName = (parameterData) => {
    let a = [];
    if (parameterData) {
      parameterData.forEach((element) => {
        if (element.checked === true) {
          a.push(element.name);
        }
      });
      return a.join(' ');
    }
  };

  const handleIsOpenWeather = (selectedDataSource) => {
    setIsOpenWeather(selectedDataSource);
  };

  const renderDefaultChecked = (name) => {
    if (parameterData?.length === 2) {
      if (
        parameterData?.findIndex(
          (item) => item.name === 'Temperature' || item.name === 'Precipitation',
        ) === 0 &&
        (name === 'Temperature' || name === 'Precipitation')
      ) {
        return true;
      } else {
        return parameterData?.some((item) => item.name === name && item.checked === true);
      }
    } else {
      return parameterData?.some((item) => item.name === name && item.checked === true);
    }
  };

  const stateValue = (data) => {
    setStateData(data);
  };

  const handleParamChart = (e) => {
    const data = OPEN_WEATHER_CHART_PARAMS.find((x) => x.name === e.target.defaultValue);
    if (_.findIndex(parameterData, { name: e.target.defaultValue }) === -1) {
      if (e.target.checked) {
        setParameterData((prevData) => [
          ...prevData,
          {
            name: e.target.defaultValue,
            checked: true,
            measure: !_.isEmpty(data) ? data?.measure : '',
          },
        ]);
      }
    } else if (_.findIndex(parameterData, { name: e.target.defaultValue }) >= 0) {
      let chartParamArr = [...parameterData];
      const index = _.findIndex(parameterData, { name: e.target.defaultValue });
      chartParamArr[index] = {
        name: e.target.defaultValue,
        checked: e.target.checked,
        measure: !_.isEmpty(data) ? data?.measure : '',
      };
      setParameterData(chartParamArr);
    }
  };
  const renderGeeTimeSeriesData = (dataSources) => {
    if (dataSources[0]?.id === DATA_SOURCES?.GFS_NOAA?.id) {
      return noaaTimeSeriesData;
    } else if (dataSources[0]?.id === DATA_SOURCES?.ERPAS?.id) {
      return erpasTimeSeriesData;
    } else {
      return [];
    }
  };

  const handleIsCompare = (isCompare) => {
    setIsCompareClicked(isCompare);
  };

  const handleCompareView = (isCompare) => {
    setCompareView(isCompare);
  };

  // const removeMapPreferenceLayer = (mapPreferRefs, mapPreferencesData) => {
  //   if (mapPreferRefs?.current?.length && mapPreferencesData?.length) {
  //     mapPreferencesData?.map(item => {
  //       let selectedMapIndex = _.findIndex(
  //         mapPreferRefs.current,
  //         list => list.current.boundary_id === item.id,
  //       );
  //       let previousParamRef = mapPreferRefs?.current[selectedMapIndex].current.layer;
  //       if (previousParamRef && previousParamRef?.current) {
  //         removeLayers(previousParamRef);
  //       }
  //     });
  //   }
  // };
  const removeMapPreferenceLayer = (mapPreferRefs, mapPreferencesData) => {
    if (mapPreferRefs?.current?.length && mapPreferencesData?.length) {
      mapPreferencesData?.map((item) => {
        let selectedMapIndex = _.findIndex(
          mapPreferRefs.current,
          (list) => list.current.boundary_id === item.id,
        );
        let previousParamRef = mapPreferRefs?.current[selectedMapIndex].current.layer;
        if (previousParamRef && previousParamRef?.current) {
          previousParamRef?.current?.eachLayer((layer) => {
            removeTransparencyToolLayers(indexLayers, layer?._url);
          });
          removeLayers(previousParamRef);
        }
      });
    }
  };
  const clearIRIDataSourceData = () => {
    setIRIForecastTime({ year: null, month: null, lead_time: null });
    setIRIAvailableMonthList([]);
    setIRIAvailableLeadTimeList([]);
  };

  const clearForecastData = () => {
    setCompareIRIForecastTime({ year: null, month: null, lead_time: null });
    setCompareIRIAvailableMonthList([]);
    setCompareIRIAvailableLeadTimeList([]);
  };

  const renderParameterSelect = (item) => {
    return (
      <>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              onChange={handleParamChart}
              value={item.weather_forecast_parameter.name}
              defaultChecked={renderDefaultChecked(item.weather_forecast_parameter.name)}
            />{' '}
            {item.weather_forecast_parameter.name}
          </Label>
        </FormGroup>
        &nbsp; &nbsp;
      </>
    );
  };
  // const location = selectedLocation?.state?.name
  // ?  `(${selectedLocation?.state?.name}_${selectedLocation?.district?.name})`
  // : selectedLocation?.state?.name
  // ? `(${selectedLocation?.state?.name})`
  // : '';
  const handleCompareLocation = (country, state, district) => {
    setCompareLocation((prev) => {
      return {
        ...prev,
        country,
        state: !_.isEmpty(state) && state?.value !== DEFAULT_COUNTRY.name ? state : '',
        district: !_.isEmpty(district) ? district : '',
      };
    });
  };

  const renderChartsUI = () => {
    if (showCharts) {
      return (
        <>
          <div className="footer panel">
            <div
              className="footerArea chart-design"
              style={(region?.length === 1 || region?.length === 2) &&
                !_.isEmpty(region[0]) &&
                (region[0]?.layerType === 'marker' || region[0]?.layerType === 'polygon') ?
               { flexGrow: 1, minHeight: '350px' } : { flexGrow: 1 }
              }
            >
              <FormGroup row>
                {errorData?.showError && (
                  <Col sm={12} md={12} lg={12}>
                    <div className="error-close-icon" onClick={handleChartDisplay}>
                      <CgCloseO size={25} color="#2A547C" />
                    </div>
                  </Col>
                )}
              </FormGroup>
              {!_.isEmpty(chartSeriesData) ? (
                dataSources[0]?.id === DATA_SOURCES.IRI.id ? (
                  <>
                  <PlotIRICharts
                    chartData={chartSeriesData}
                    isOpenWeather={isOpenWeather}
                    dataSource={dataSources}
                    parametersData={parameterData}
                    location={location}
                    renderCharts={handleChartDisplay}
                    initialSelectedDate={initialSelectedDate}
                    fileName={fileName}
                    region={region}
                    IRIForecastTime={IRIForecastTime}
                  />
                  <FormGroup className="periodicity-style" row>
                      {region?.length === 1 &&
                      !_.isEmpty(region[0]) &&
                      region[0]?.layerType === 'marker' ? (
                        <>
                          <p className="marker-style">
                            Point({region[0]?.latlngs[1].toFixed(2)},
                            {region[0]?.latlngs[0].toFixed(2)})
                          </p>
                        </>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                    </>
                ) : (
                  <>
                    <Ggplot2
                      region={region}
                      renderCharts={handleChartDisplay}
                      fileName={fileName}
                      chartData={chartSeriesData}
                      parametersData={parameterData}
                      isOpenWeather={isOpenWeather}
                      dataSource={dataSources}
                      isCompare={isCompareClicked}
                      geeTimeSeriesData={renderGeeTimeSeriesData(dataSources)}
                      location={location}
                      compareDataSource={compareDataSources}
                      comparedLocation={comparedLocation}
                      compareLocation={compareLocation}
                      temporalAggregation={temporalAggregation}
                      selectedParamOnMap={selectedParams?.length ? selectedParams[0] : null}
                      selectedParams={
                        selectedParams && selectedParams.length ? selectedParams[0] : null
                      }

                        initialSelectedDate={initialSelectedDate}
                      

                    />
                    <FormGroup className="periodicity-style" row>
                      {region?.length === 1 &&
                      !_.isEmpty(region[0]) &&
                      region[0]?.layerType === 'marker' ? (
                        <>
                          <p className="marker-style">
                            Point({region[0]?.latlngs[1].toFixed(2)},
                            {region[0]?.latlngs[0].toFixed(2)})
                          </p>
                        </>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  </>
                )
              ) : (
                <>
                  {noaaRegionTimeSeries?.length && !errorData?.showError ? (
                    <>
                      <PlotsCharts1
                        renderCharts={handleChartDisplay}
                        fileName={fileName}
                        selectedParam={selectedParam}
                        compareParams={compareParamForGraph}
                        dataSource={dataSources}
                        isOpenWeather={isOpenWeather}
                        initialSelectedDate={initialSelectedDate}
                        noaaRegionTimeSeries={noaaRegionTimeSeries}
                        region={region}
                        location={location}
                        temporalAggregation={temporalAggregation}
                        isCompareClicked={isCompareClicked}
                        comparedLocation={comparedLocation}
                        dataSources={dataSources}
                        parameterData={parameterData}
                        IRIForecastTime={IRIForecastTime}
                      />
                    </>
                  ) : (
                    <>
                      {isGraphLoading && (
                        <div className="drought-loader-container">
                          <div className="drought-loader">
                            <Loader />
                          </div>
                        </div>
                      )}
                      {errorData?.showError && (
                        <div className="drought-error-container">
                          <div className="drought-error-style">
                            <Alert color="warning">{errorData?.data}</Alert>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  };

  const handleSelectedItem = (value) => {
    setApplyLayerDisable(value);
  };

  const renderMapContainer = () => {
    return (
      <>
        <MapContainer
          center={cordinateAndZoom.cordinates}
          id="leaflet-map"
          zoom={cordinateAndZoom.zoom}
          className="map-container"
          zoomControl={false}
          whenCreated={setMapReference}
          scrollWheelZoom={true}
        >
          <ChangeView center={cordinateAndZoom.cordinates} zoom={cordinateAndZoom.zoom} />

          <LayerControl
            position="topleft"
            className="layer-control-style"
            params={params}
            dataSourceId={selectedDataSourceId}
            handleParameters={handleParameters}
            handleDataSourceId={handleDataSourceId}
            isHideBar={isHideBar}
            isOpenWeather={isOpenWeather}
            key="layer1"
          >
            <LayersControl position="topright">
              <LayersControl.BaseLayer name="Satellite">
                <TileLayer
                url={myConstClass.SATELLITE_TILE_LAYER_URL}
                options={{ tileSize: 256 }}
                attribution={`© <a href='https://www.mapbox.com/about/maps/' target='_blank'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a> ©<a href="https://www.maxar.com/" target='_blank'> Maxar</a>`}
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer checked name="Streets">
                <TileLayer
                url={myConstClass.TITLE_LAYER_URL}
                options={{ tileSize: 256 }}
                attribution={`© <a href='https://www.mapbox.com/about/maps/' target='_blank'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a>`}
                />
              </LayersControl.BaseLayer>
            </LayersControl>
          </LayerControl>

          <ZoomControl position="topright" className="map-zoom-control" />
          {/* <DraggableMarker
            renderCharts={handleChartDisplay}
            cordinates={cordinateAndZoom.locationCordinates}
            handleCordinates={getMarkerPosition}
            forecastData={graphData}
            dataSource={dataSources}
            initialSelectedDate={initialSelectedDate}
          /> */}

          {!hasQueryParam ? (
            <LayersControl position="topleft">
              <div className="logoContainer1">
                <Button className="map-preferences-button" onClick={handleMapModel}>
                  <img src={mapPreferenceIcon} alt="map preference" width={15} />
                </Button>
              </div>
            </LayersControl>
          ) : (
            ''
          )}

          <DrawShape
            handleSelectedRegion={handleSelectedRegion}
            updateGraph={updateGraph}
            country={country}
            state={selectedState}
            district={selectedDistrict}
            handleSelectedItem={handleSelectedItem}
            isOpenWeather={isOpenWeather}
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.COUNTRY}
            ref={mapPreferences['country'].layer}
            style={{ color: mapPreferences['country'].color, fillColor: '#0000', weight: 1 }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['country'].country, 'country', feat, layer)
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.STATES}
            ref={mapPreferences['states_or_provinces'].layer}
            style={{
              color: mapPreferences['states_or_provinces'].color,
              fillColor: '#0000',
              weight: 1.6,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['states_or_provinces'].country,
                'states_or_provinces',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.DISTRICTS}
            ref={mapPreferences['districts_or_cities'].layer}
            style={{
              color: mapPreferences['districts_or_cities'].color,
              weight: 1,
              fillColor: '#0000',
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['districts_or_cities'].country,
                'districts_or_cities',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.METEOROLOGICAL_LOCATIONS}
            ref={mapPreferences['meteorological_locations'].layer}
            style={{
              color: mapPreferences['meteorological_locations'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['meteorological_locations'].country,
                'meteorological_locations',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            //  key={selectedLocation?.country}
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.BASINS}
            ref={mapPreferences['basins'].layer}
            style={{
              color: mapPreferences['basins'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['basins'].country, 'basins', feat, layer)
            }
          />

          <GeoJSON
            // key={selectedLocation?.country}
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.RIVERS}
            ref={mapPreferences['rivers_or_reservoirs'].layer}
            style={{
              color: mapPreferences['rivers_or_reservoirs'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['rivers_or_reservoirs'].country,
                'rivers_or_reservoirs',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            // key={selectedLocation?.country}
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.TALUKAS}
            ref={mapPreferences['talukas'].layer}
            style={{
              color: mapPreferences['talukas'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['talukas'].country, 'talukas', feat, layer)
            }
          />
          {/* {(dataSources[0]?.id !== DATA_SOURCES.IRI.id) ?  */}

          <LayersControl key="layer2" position="topleft">
            {renderParamLayer(categoryParams)}

            {mapPreferRefs?.current?.map((mapLayerRef, index) => {
              if (!_.isEmpty(mapLayerRef)) {
                return (
                  <>
                    <Overlay>
                      <LayerGroup ref={mapLayerRef.current.layer}>
                        <TileLayer
                          // attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
                          url=""
                        />
                      </LayerGroup>
                    </Overlay>
                  </>
                );
              }
            })}
          </LayersControl>

          {compareParamLegendData.length
            ? compareParamLegendData.map((item) => {
                return (
                  <>
                    <LayersControl key="layer3" position="topleft">
                      {item?.descret_legend ? (
                        <DiscreteLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomleft"
                        />
                      ) : (
                        <CustomLegend map={map} add={item.add} layer={item} position="bottomleft" />
                      )}
                    </LayersControl>
                  </>
                );
              })
            : ''}

          {selectedParamLegendData.length
            ? selectedParamLegendData.map((item) => {
                return (
                  <>
                    <LayersControl position="topleft" key="layer4">
                      {item?.descret_legend ? (
                        <DiscreteLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      ) : (
                        <CustomLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      )}
                    </LayersControl>
                  </>
                );
              })
            : ''}

          {selectedMapPreferLegendData?.length ? (
            <>
              <LayersControl position="topleft">
                {addLegend(selectedMapPreferLegendData)}
              </LayersControl>
            </>
          ) : (
            ''
          )}
        </MapContainer>
      </>
    );
  };

  // const handleCompareLocation = (location) => {
  //   setCompareLocation(location)
  // }
  const handleLocation1 = (country, state, district, basin, region) => {
    // if (applyLayers) {
    //   setApplyLayers(false);
    // }
    setSelectedLocation1((prev) => {
      return {
        ...prev,
        country,
        state: !_.isEmpty(state) && state?.value !== DEFAULT_COUNTRY.name ? state : '',
        district: !_.isEmpty(district) ? district : '',
        basin,
        region,
      };
    });
  };
  const handleSelectedLocation = (location) => {
    setSelectedLocation(location);
  };
  return (
    <div className="global-top">
      <div className="w-100">
        {isLoading && <Loader />}

        <div className={isHideBar ? 'sideBar col-lg-2' : 'expand-side-bar col-lg-2'}>
          <LeftFilter
            initialValues={{
              isHideBar: isHideBar,
              selectedParam: selectedParam,
              compareParam: compareParams,
              staticResourceSelectedLocation: staticResourceSelectedLocation,
              fromStaticResources: hasQueryParam,
              dataSourceId: selectedDataSourceId,
              dates: staticResourceDates || '',
              years: yearsList,
              isDefaultSelectedSourceId: isDefaultSelectedSourceId,
              region: region,
              country: country,
              mapPreferencesData: mapPreferencesData,
            }}
            setBar={setBar}
            clearForecastData={clearForecastData}
            clearIRIDataSourceData={clearIRIDataSourceData}
            clearDatesHandler={clearDatesHandler}
            renderCharts={handleChartDisplay}
            showCharts={setShowCharts}
            handleSelectedYear={handleSelectedDefaultYear}
            handleSelectedDate={handleSelectedDate}
            handleCordinates={handleCordinates}
            handleSelectedCountry={handleSelectedFilterCountry}
            handleCountry={handleCountry}
            handleAdvancedSearch={handleAdvancedSearch}
            handleCompareSearch={handleCompareSearch}
            handleProps={handleProps}
            handleDefaultDataSource={handleDefaultDataSource}
            handleParamData={setParamData}
            handleCompareParamsData={setCompareParam}
            handleRegionId={handleRegionId}
            handleSelectedDataSourceId={handleSelectedDataSourceId}
            setImage={setStaticResourceImage}
            setTitle={setStaticResourceTitle}
            handleIsOpenWeather={handleIsOpenWeather}
            handleApplyLayers={handleApplyLayers}
            handleTimeSeries={handleGraph}
            handleCompareView={handleCompareView}
            handleIsCompare={handleIsCompare}
            handleCompareCordinates={handleCompareCordinates}
            selectedForecastYearMonth={selectedForecastYearMonth}
            handleSelectedLocation={handleSelectedLocation}
            handleLocation1={handleLocation1}
            handleCompareLocation={handleCompareLocation}
            //selectedForecastLeadTime={selectedForecastLeadTime}
            selectedFinalForercastLeadTime={selectedFinalForercastLeadTime}
            forecastYearMonthHandler={forecastYearMonthHandler}
            forecastCompareYearMonthHandler={forecastCompareYearMonthHandler}
            forecastLeadTimeHandler={forecastLeadTimeHandler}
            selectedForecastCompareYearMonth={selectedForecastCompareYearMonth}
            forecastCompareLeadTimeHandler={forecastCompareLeadTimeHandler}
            forecastLeadTimeList={forecastLeadTimeList}
            forecastCompareLeadTimeList={forecastCompareLeadTimeList}
            IRIAvailableYearList={IRIAvailableYearList}
            compareIRIAvailableYearList={compareIRIAvailableYearList}
            forecastTimeYearHandler={forecastTimeYearHandler}
            compareForecastTimeYearHandler={compareForecastTimeYearHandler}
            IRIAvailableMonthList={IRIAvailableMonthList}
            compareIRIAvailableMonthList={compareIRIAvailableMonthList}
            IRIForecastTime={IRIForecastTime}
            compareIRIForecastTime={compareIRIForecastTime}
            forecastTimeMonthHandler={forecastTimeMonthHandler}
            compareForecastTimeMonthHandler={compareForecastTimeMonthHandler}
            IRIAvailableLeadTimeList={IRIAvailableLeadTimeList}
            compareIRIAvailableLeadTimeList={compareIRIAvailableLeadTimeList}
            stateValue={stateValue}
            setCompareIRIForecastTime={setCompareIRIForecastTime}
            applyLayersDisable={applyLayersDisable}
            isHideBar={isHideBar}
            isGraphLoading={isGraphLoading}
          />
        </div>
        {!hasQueryParam ? (
          <>
            <Row>
              <Col lg={isHideBar ? 12 : 10} className="map-position">
                <div
                  style={{
                    height: '79.4vh',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <>
                    <SplitterLayout
                      ref={splitterRef}
                      primaryIndex={0}
                      vertical
                      percentage
                      primaryMinSize="30%"
                      secondaryInitialSize="70%"
                    >
                      <div style={{ height: '100%' }}>{renderMapContainer()}</div>
                      {renderChartsUI()}
                    </SplitterLayout>
                  </>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <StaticResource
            dataSourceId={selectedDataSourceId}
            params={staticResourceParams}
            handleParameters={handleParameters}
            staticResourceWeekData={staticResourceWeekData ? staticResourceWeekData : ''}
            groupsData={staticResourceDates}
            handleDataSourceId={handleDataSourceId}
          />
        )}
        {!nextProps.staticResourceData && getBoundariesData?.length ? (
          <MapPreferences
            isToggle={isOpenMapModel}
            handleToggle={handleMapModel}
            loc={country}
            isMapPerferGeoJsonLoading={mapPreferenceGeoJsonloading}
            getMapPreferencesData={getMapPreferencesData}
            getMapPreferencesGeojsonData={getMapPreferencesGeojsonData}
            geoJsonData={geoJsonData}
            mapPreferencesAllData={mapPreferencesData}
            fromContingencyPlan={false}
            selectedParamLegendData={selectedParamLegendData}
            weatherForecastStateData={stateData}
            fromDroughtMonitor={true}
            countryData={selectedLocation?.country}
            staticData={selectedLocation?.state}
            districtData={selectedLocation?.district}
            cordinateAndZoom={cordinateAndZoom}
            selectedLocation1={selectedLocation1}
          />
         ) : (
          ''
        )}
      </div>
    </div>
  );
}

LeafletWrapper.propTypes = {
  cordinates: PropTypes.array,
  zoom: PropTypes.number,
  parameters: PropTypes.array,
};
