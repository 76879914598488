/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js';
import _ from 'lodash';
import { renderGenericDateFormatWithTime } from '../../Components/common/utils';
import { GRAPH_YAXIS_COLORS } from '../../Components/constants/index';
// import { PlotlyPNGLogo, PlotlyCSVLogo, PlotlyCloseLogo } from './constants';
import { PlotlyPNGLogo, PlotlyCSVLogo, PlotlyCloseLogo } from '../../Components/constants';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function CurrentOpenWeatherCharts(props) {
  const { chartData } = props;
  const ButtonClick = useRef('');
  const [csvFileData, setCsvFileData] = useState([]);
  const [objKeysData, setObjKeysData] = useState([]);
  const [dates, setDates] = useState([]);
  const [yOptions, setYOptions] = useState([]);
  const [newFileName, setFileName] = useState('');
  const [isFirstYAxisDeselected, setIsFirstYAxisDeselected] = useState(false);

  const [openWeatherParams, setOpenWeatherParams] = useState([
    { name: 'Precipitation', measure: 'mm/h', visible: true, dash: '', type: 'bar', mode: '' },
    {
      name: 'Temperature',
      measure: '°C',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: 'lines',
    },
    {
      name: 'Wind speed',
      measure: 'm/s',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: 'lines',
    },
    {
      name: 'Pressure',
      measure: 'hPa',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: 'lines',
    },
  ]);

  const [layout, updateLayout] = useState({
    bargap: 0.5,
    responsive: true,
    title: {
      // text: `<b> Data Source : ${dataSource[0]?.name}</b> (Forecast for 2 days.)`,
    },
    modebardisplay: true,
    // width: 1600,
    font: {
      size: 12,
      color: '#000',
    },
    xaxis: {
      // domain: [0, 0.9],
      showline: true,
      tickangle: 50,
      tickfont: {
        size: 12,
        color: '#000',
        family: 'National',
      },
      showgrid: false,
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      // x: 0.3,
      x: 0.5,
      xanchor: 'center',
      y: -0.7,
      font: {
        // family: 'sans-serif',
        size: 15,
        color: '#000',
        family: 'National',
      },
    },
    margin: {
      t: 50,
      b: 50,
    },
  });

  useEffect(() => {
    let reOrderedParams = [];
    openWeatherParams.forEach((param, index) => {
      if (param.name === 'Precipitation') {
        param.visible = true;
        reOrderedParams.push(param);
        if (param?.name === 'Precipitation') {
          updateLayout((preValues) => ({
            ...preValues,
            yaxis1: {
              title: {
                text: `<b>${param?.name} (${param.measure})</b>`,
                plot_bgcolor: '#E5ECF6',
                font: {
                  family: 'National',
                  color: GRAPH_YAXIS_COLORS[index],
                  size: 15,
                },
              },
              titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
              tickfont: { color: GRAPH_YAXIS_COLORS[index] },
              showline: true,
              rangemode: 'tozero',
              showgrid: false,
              zeroline: false,
            },
          }));
        } else {
          updateLayout((preValues) => ({
            ...preValues,
            yaxis1: {
              title: {
                text: `<b>${param?.name} (${param.measure})</b>`,
                plot_bgcolor: '#E5ECF6',
                font: {
                  family: 'National',
                  color: GRAPH_YAXIS_COLORS[index],
                  size: 15,
                },
              },
              ttitlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
              tickfont: { color: GRAPH_YAXIS_COLORS[index] },
              showline: true,
              showgrid: false,
              zeroline: false,
              rangemode: 'tozero',
            },
          }));
        }
      }
    });
    if (reOrderedParams.length) {
      openWeatherParams.forEach((param) => {
        if (param.name !== 'Precipitation') {
          param.visible = 'legendonly';
          reOrderedParams.push(param);
        }
      });
    }
    setOpenWeatherParams(reOrderedParams);
  }, []);
  const handleTraces = (chart_data, index, p) => {
    let ydata = {
      y: [],
    };
    chart_data?.forEach((i) => {
      ydata.type = p.type;
      ydata.mode = p.mode;
      ydata.visible = p.visible;
      ydata.position = p.position;
      ydata.name = `${p?.name}`;
      // ydata.line = { color: CHART_LABEL_COLORS[index], dash: p.dash };
      if (p.type === 'scatter') {
        ydata.line = { color: GRAPH_YAXIS_COLORS[index], dash: p.dash, width: 3 };
      } else {
        ydata.marker = { color: GRAPH_YAXIS_COLORS[index], dash: p.dash };
      }
      ydata.units = p.measure;
      if (p.name === 'Temperature') {
        if (index === 0) {
          ydata.yaxis = `y1`;
        }
        ydata.y.push(i?.temp);
      } else if (p.name === 'Precipitation') {
        if (index === 0) {
          ydata.yaxis = `y1`;
        }
        ydata.y.push(i?.rain ? i.rain['1h'] : 0);
      } else if (p.name === 'Wind speed') {
        if (index === 0) {
          ydata.yaxis = `y1`;
        }
        ydata.y.push(i?.wind_speed);
      } else if (p.name === 'Pressure') {
        if (index === 0) {
          ydata.yaxis = `y1`;
        }

        ydata.y.push(i?.pressure);
      }
    });

    return ydata;
  };
  useEffect(() => {
    if (chartData && chartData?.length) {
      let arr = chartData?.map((item) => renderGenericDateFormatWithTime(item.dt_txt));
      setDates(arr);
      let options = [];
      openWeatherParams?.map((p, index) => {
        let returned_values = handleTraces(chartData, index, p);
        options.push(returned_values);
      });
      let x = [];
      function formatDate() {
        let d = new Date();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month?.length < 2) month = '0' + month;
        if (day?.length < 2) day = '0' + day;

        return [year, month, day].join('-');
      }
      let currentDate = formatDate();
      let filteredDates = chartData.filter((item) => item?.dt_txt.includes(currentDate));
      filteredDates?.map((i) => {
        x.push(renderGenericDateFormatWithTime(i.dt_txt));
      });
      options.map((o) => {
        o.x = x;
        o.hovertemplate = `%{x} : ${o.name} : %{y}<extra></extra>`;
      });
      setYOptions(options);
    }
  }, [chartData, openWeatherParams]);

  const getLayoutWidths = [
    { yaxisCount: 1, domain: [0, 0] },
    { yaxisCount: 2, domain: [0, 0] },
    { yaxisCount: 3, domain: [0, 0.93] },
    { yaxisCount: 4, domain: [0, 0.86] },
  ];
  const handlePosition = (axisCount, key, index) => {
    let positionValue;
    if (axisCount) {
      if (axisCount === 3) {
        if (index === 0 || isFirstYAxisDeselected) {
          if (key === 3) positionValue = 1;
          else if (key === 2) positionValue = 0.93;
        } else if (key === 1) positionValue = 0.93;
        else positionValue = 1;
      } else if (axisCount === 4) {
        if (key === 3) {
          positionValue = 1;
        } else {
          positionValue = 0.93;
        }
      } else if (axisCount === 5) {
        positionValue = 0.85;
      } else {
        positionValue = 1;
      }
      return positionValue;
    }
  };
  //function to order multiple y axes
  const getOrderedYAxes = (layoutData, YoptionsLength, index) => {
    let yaxisArray = [];
    Object.keys(layoutData).forEach((element) => {
      if (element.includes('yaxis')) {
        yaxisArray.push(element);
      }
    });
    // let position = 1 - Number(`0.${YoptionsLength - 3}`);
    // let position = handlePosition(YoptionsLength);
    if (!yaxisArray.find((e) => e === 'yaxis')) {
      yaxisArray.push('yaxis');
    }
    let order = ['yaxis', 'yaxis2', 'yaxis3', 'yaxis4'];
    yaxisArray.sort((a, b) => order.indexOf(a) - order.indexOf(b));

    yaxisArray.forEach((item, key) => {
      let position = handlePosition(YoptionsLength, key, index);
      if (key === 0) {
        if (layoutData[item]) {
          layoutData[item].side = 'left';
          layoutData[item].position = undefined;
        }
      } else if (key === 1) {
        layoutData[item].anchor = 'x';
        layoutData[item].side = 'right';
        layoutData[item].position = undefined;
      } else {
        layoutData[item].anchor = 'free';
        layoutData[item].side = 'right';
        layoutData[item].position = position;
        position += 0.1;
      }
    });
    return layoutData;
  };
  const handleLegends = (graphData) => {
    let index = graphData.expandedIndex;
    let data = graphData.data[index];
    let newYOptions = [...yOptions];
    if (data.visible !== true) {
      newYOptions[index]['yaxis'] = `y${index + 1}`;
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      if (yaxisCount > 2) {
        newYOptions[yaxisCount - 1]['position'] =
          yaxisCount === newYOptions.length ? 1 : handlePosition(yaxisCount);
        // newYOptions[yaxisCount - 1]['position'] = yaxisCount === newYOptions.length ? 1 : 0.9;
      }
      setYOptions(newYOptions);
      // layout.width = getLayoutWidths.find(item => item.yaxisCount === yaxisCount)?.width;
      layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      if (index !== 0) {
        if (isFirstYAxisDeselected)
          layout.xaxis.domain = getLayoutWidths.find(
            (item) => item.yaxisCount === yaxisCount + 1,
          )?.domain;
        else
          layout.xaxis.domain = getLayoutWidths.find(
            (item) => item.yaxisCount === yaxisCount,
          )?.domain;
        let yaxisName = `yaxis${index + 1}`;
        layout[yaxisName] = {
          title: {
            text: `<b>${data?.name} (${data?.units})</b>`,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          anchor: yaxisCount >= 2 ? 'x' : 'free',
          overlaying: 'y',
          side: 'right',
          position: newYOptions[yaxisCount - 1]['position'],
          showline: true,
          showgrid: false,
          zeroline: false,
          rangemode: 'tozero',
        };
      } else if (index === 0) {
        setIsFirstYAxisDeselected(false);
        layout.xaxis.domain = getLayoutWidths.find(
          (item) => item.yaxisCount === yaxisCount,
        )?.domain;
        layout.yaxis = {
          title: {
            text: `<b>${data?.name} (${data?.units})</b>`,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          side: 'left',
          showline: true,
          rangemode: 'tozero',
          showgrid: false,
          zeroline: false,
        };
      }
      // let orderedLayoutData = getOrderedYAxes(layout, yOptions.length);
      let orderedLayoutData = getOrderedYAxes(layout, yaxisCount);
      updateLayout(orderedLayoutData);
    } else {
      delete newYOptions[index]['yaxis'];
      setYOptions(newYOptions);
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      let yaxisName = index === 0 ? `yaxis` : `yaxis${index + 1}`;
      let layoutData = { ...layout };
      delete layoutData[yaxisName];
      if (index === 0) setIsFirstYAxisDeselected(true);
      let updatedYAxisCount = index === 0 || isFirstYAxisDeselected ? yaxisCount + 1 : yaxisCount;
      layout.xaxis.domain = getLayoutWidths.find(
        (item) => item.yaxisCount === updatedYAxisCount,
      )?.domain;
      // let orderedLayoutData = getOrderedYAxes(layoutData, yOptions.length);
      let orderedLayoutData = getOrderedYAxes(layoutData, yaxisCount, index);
      updateLayout(orderedLayoutData);
    }
  };

  let config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true, //by default we are showing
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        click: function (gd) {
          getActiveLegends(false, gd);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        click: function (gd) {
          getActiveLegends(true, gd);
        },
      },
    ],
    showTips: false,
  };

  //function for getting active legends for graph then download png/csv format
  const getActiveLegends = (isDownloadCSV, graphData) => {
    if (document.getElementById('openWeatherId')?.data?.length) {
      const activeLegends = graphData.data?.filter((item) => item.visible === true);
      if (activeLegends.map((data) => data.name)?.length) {
        //setting file name based on active legends
        setFileName(
          `Current_Weather-${activeLegends
            .map((data) => (data?.name === 'Wind speed' ? 'Wind speed' : data.name.split(' ')[0]))
            .join(' ')}`,
        );
      }
      if (activeLegends?.length) {
        //formatting data for csv file
        const xaixsValue = activeLegends[0].x;
        let data = [];
        xaixsValue.forEach((date, key) => {
          activeLegends.forEach((item, index) => {
            if (index === 0) {
              data.push({ Date: date, [item.name]: item.y[key] });
            } else {
              data[key][item.name] = item.y[key];
            }
          });
        });
        setCsvFileData(data);
        let objKeys = Object.keys(data[0]);
        if (objKeys) {
          setObjKeysData(objKeys);
        }
        if (isDownloadCSV) {
          //downloading csv file
          ButtonClick.current.click();
        } else {
          //downloading png file
          Plotly.downloadImage(graphData, {
            filename: `Current_Weather-${activeLegends
              .map((data) => data.name.split(' ')[0])
              .join(' ')}`,
            format: 'png',
            width: graphData._fullLayout.width,
            height: graphData._fullLayout.height,
          });
        }
      }
    }
  };

  return (
    <>
      <Plot
        data={yOptions}
        layout={layout}
        config={config}
        onLegendClick={(data) => handleLegends(data)}
        useResizeHandler={true}
        style={{ width: '100%' }}
        divId="openWeatherId"
      />
      <ExcelFile
        filename={newFileName}
        element={
          <button
            ref={ButtonClick}
            className="btn btn-download ml-auto"
            style={{ display: 'none' }}
          >
            Download
          </button>
        }
      >
        <ExcelSheet data={csvFileData} name="Risk_Analytics_Current_Weather">
          {objKeysData?.map((item) => (
            <ExcelColumn label={item} value={item} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </>
  );
}
export default CurrentOpenWeatherCharts;
