import React, { useState, useEffect, useRef } from 'react';
import graphCrossIcon from '../../assets/Images/graphCrossIcon.svg';
import disableLeftArrow from '../../assets/Images/disableLeftArrow.svg';

import leftArrowIcon from '../../assets/Images/leftArrowIcon.svg';
import rightArrowIcon from '../../assets/Images/rightArrowIcon.svg';
// import windArrowIcon from '../../assets/Images/windArrowIcon.svg';
// import windIcon from '../../assets/Images/windIcon.png';

// import TemperatureSunIcon from '../../assets/Images/temperatureIcon.svg';
import TemperatureSunIcon from '../../assets/Images/Temperature.png';
import PrecipitationIcon from '../../assets/Images/Precipitation.png';
import WindspeedIcon from '../../assets/Images/Windspeed.png';
// import HumidityIcon from '../../assets/Images/Humidity.png';
import PressureIcon from '../../assets/Images/Pressure1.png';

// import CrossIcon from '../../assets/Images/crossIcon.png';

import { Row, Col, Container } from 'reactstrap';

import PlotCharts from './currentWeatherCharts';
import CurrentOpenWeatherCharts from './currentOpenWeatherCharts';

import moment from 'moment';

import ReactTooltip from 'react-tooltip';

import ExpandIcon from '../../assets/Images/expand.png';

const CurrentWatherForecast = (props) => {
  const {
    forecastRightSideBar,
    handleOpenCurrentWeatherSplitter,
    isOpen,
    handleOpen,
    openFullScreen,
    isHideFarmCollapse,
    isHideBar,
    getByIdFarmInfo,
    setIsRightCollapseOpen,
    openWeatherChartSeriesData,
    dailyChartSeriesData,
  } = props;
  const [forecastList, setForecastList] = useState([]);
  const [precipitationValue, setPrecipitationValue] = useState(0);
  const [windSpeedValue, setWindSpeedValue] = useState(0);
  const [temperatureValue, setTemperatureValue] = useState(0);
  const [pressureValue, setPressureValue] = useState(0);

  const allData = [
    'temperature_2m_above_ground',
    'u_component_of_wind_10m_above_ground',
    'relative_humidity_2m_above_ground',
    'precipitable_water_entire_atmosphere',
  ];

  useEffect(() => {
    setPrecipitationValue(dailyChartSeriesData?.[0]?.rain);
    setTemperatureValue(dailyChartSeriesData?.[0]?.temp?.day);
    setWindSpeedValue(dailyChartSeriesData?.[0]?.wind_speed);
    setPressureValue(dailyChartSeriesData?.[0]?.pressure);
  }, [dailyChartSeriesData]);

  useEffect(() => {
    if (forecastRightSideBar) {
      setForecastList(forecastRightSideBar);
    }
  }, [forecastRightSideBar]);

  // useEffect(() => {
  //   let x = [];
  //   if (forecastRightSideBar?.length === 4) {
  //     console.log('kuukukukt');
  //     for (let i of allData) {
  //       console.log(i);
  //       for (let j of forecastRightSideBar) {
  //         console.log(Object.keys(j)[0], 'gsgergetgtege');
  //         if (Object.keys(j)[0] === i) {
  //           x.push(j);
  //           break;
  //         }
  //       }
  //     }
  //     // setForecastList(x);
  //   }
  // },[forecastRightSideBar])

  const temperature = forecastRightSideBar.filter((item) =>
    Object.keys(item).includes('temperature_2m_above_ground'),
  );
  const humidity = forecastRightSideBar.filter((item) =>
    Object.keys(item).includes('relative_humidity_2m_above_ground'),
  );
  const wind = forecastRightSideBar.filter((item) =>
    Object.keys(item).includes('u_component_of_wind_10m_above_ground'),
  );

  const precipitable = forecastRightSideBar.filter((item) =>
    Object.keys(item).includes('precipitable_water_entire_atmosphere'),
  );

  const charts = {
    Precipitation: [precipitable[0]?.precipitable_water_entire_atmosphere],
    Temperature: [temperature[0]?.temperature_2m_above_ground],
    Wind_speed: [wind[0]?.u_component_of_wind_10m_above_ground],
    Humidity: [humidity[0]?.relative_humidity_2m_above_ground],
  };
  // const charts1 = [
  //   temperature[0]?.temperature_2m_above_ground,
  //   humidity[0]?.relative_humidity_2m_above_ground,
  //   wind[0]?.u_component_of_wind_10m_above_ground,
  //   precipitable[0]?.precipitable_water_entire_atmosphere,
  // ];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thusrday', 'Friday', 'Saturday'];

  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  // const [slideLeft, setSlideLeft] = React.useState(0);
  // const [isScrolling, setIsScrolling] = React.useState(false);
  // const sliderWidth = 1400;
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const slide = (shift) => {
    elementRef.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(elementRef.current.scrollWidth - elementRef.current.scrollLeft) <=
      elementRef.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(elementRef.current.scrollLeft);
    if (
      Math.floor(elementRef.current.scrollWidth - elementRef.current.scrollLeft) <=
      elementRef.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  // //on arrow click
  // const moveRight = () => {
  //   const el = document.getElementById(`hscroll`);
  //   setSlideLeft((el.scrollLeft += 200));
  // };

  // const moveLeft = () => {
  //   const el = document.getElementById(`hscroll`);
  //   setSlideLeft((el.scrollLeft -= 200));
  // };

  // if (isScrolling) {
  //   setTimeout(() => setIsScrolling(false), 3000);
  // }

  // const handleHorizantalScroll = (element, speed, distance, step) => {
  //   let scrollAmount = 0;
  //   const slideTimer = setInterval(() => {
  //     element.scrollLeft += step;
  //     scrollAmount += Math.abs(step);
  //     if (scrollAmount >= distance) {
  //       clearInterval(slideTimer);
  //     }
  //     if (element.scrollLeft === 0) {
  //       setArrowDisable(true);
  //     } else {
  //       setArrowDisable(false);
  //     }
  //   }, speed);
  // };
  const handleClose = (value, type) => {
    if (type === 'fullScreen') {
      handleOpen(value);
    } else {
      handleOpenCurrentWeatherSplitter(value);
      handleOpen(false);
    }
    // handleOpenCurrentWeatherSplitter(value);
  };

  const handleClose1 = () => {
    handleOpenCurrentWeatherSplitter(false);
    if (openFullScreen) {
      setIsRightCollapseOpen(true);
    }
  };
  return (
    <>
      {/* <div style={{ backgroundColor: '#F9F9F9' }}> */}
      <div style={{ backgroundColor: '#E7F1FD' }}>
        <div
          style={{
            // backgroundColor: '#F9F9F9',
            backgroundColor: '#E7F1FD',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{ marginLeft: '17px', display: 'flex', flexDirection: 'row' }}
            // onClick={() => handleClose(false)}
          >
            {/* {openFullScreen === true && ( */}
            {/* <img
              src={graphCrossIcon}
              onClick={(e) => handleClose1()}
              alt="crossIcon"
              style={{ cursor: 'pointer', width: '35px' }}
              width={25}
            /> */}
            {/* )} */}
            <p className="mt-2 mx-2 current-weather1">
              Current Weather &nbsp;
              <span style={{ color: '#818F9C', fontSize: '18px', fontStyle: 'italic' }}>
                {moment(new Date()).local().format('Do MMM YYYY')} | {moment().format('hh:mm A')}
              </span>
            </p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {openFullScreen === false && (
              <>
                <div>
                  {/* <button
                    className="button1" */}
                  {/* style={styleToForecast}
                     onMouseEnter={() => setStyleToForecast({ backgroundColor: '#20528a' })}
                     onMouseLeave={() => setStyleToForecast({ backgroundColor: '#28609e' })}
                     style={{ color: '#fff' }}
                     onClick={() => handleClose(true, 'fullScreen')} */}
                  {/* > */}
                  {/* Forecast */}
                  {/* Expand */}
                  <a
                    data-for="expand1"
                    data-tip="Hello<br />multiline<br />tooltip"
                    data-iscapture="true"
                  >
                    <img
                      src={ExpandIcon}
                      onClick={() => handleClose(true, 'fullScreen')}
                      style={{ cursor: 'pointer', height: '24px' }}
                    />
                    <ReactTooltip
                      id="expand1"
                      aria-haspopup="true"
                      role="example"
                      type="info"
                      place={'bottom'}
                    >
                      Expand
                    </ReactTooltip>
                  </a>

                  {/* </button> */}
                  {/* )} */}
                </div>
              </>
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              src={graphCrossIcon}
              onClick={(e) => handleClose1()}
              alt="crossIcon"
              style={{ cursor: 'pointer', width: '35px' }}
              width={25}
            />
          </div>
        </div>
        <div>
          <Container>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: '2%',
                // textAlign: 'center',
              }}
              // className="pt-3"
            >
              {/* <Col > */}
              {/* <Col> <img src={TemperatureSunIcon} className="m-1" /> </Col> */}
              <Col md={3}>
                <h5 title="Precipitation">
                  {/* <span style={{ color: '#818F9C' }}>Precipitation</span> */}
                  <img src={PrecipitationIcon} alt="PrecipitationIcon" className="m-1" />{' '}
                  {/* <br /> */}
                  <span style={{ fontSize: '14px', fontFamily: 'National' }}>
                    {precipitationValue ? `${precipitationValue?.toFixed(2)} mm/h` : 'N/A'}
                  </span>
                </h5>
              </Col>
              <Col md={3}>
                <h5 title="Temperature">
                  <img src={TemperatureSunIcon} alt="TemperatureIcon" className="m-1" />{' '}
                  <span style={{ fontSize: '14px', fontFamily: 'National' }}>
                    {/* {temperatureValue?.toFixed(2)} <span>&#176;</span>C */}
                    {temperatureValue ? `${temperatureValue?.toFixed(2)} ` : 'N/A'}<span>&#176;</span>C
                    
                  </span>
                </h5>
              </Col>

              <Col lg={3}>
                <h5 title="Wind speed">
                  <img src={WindspeedIcon} alt="WindspeedIcon" className="m-1" />{' '}
                  <span style={{ fontSize: '14px', fontFamily: 'National' }}>
                    {windSpeedValue ? `${windSpeedValue?.toFixed(2)} m/s` : 'N/A'}
                  </span>
                </h5>
              </Col>
              <Col md={3}>
                <h5 title="Pressure">
                  <img src={PressureIcon} alt="pressureIcon" className="m-1" height='28px' />{' '}
                  <span style={{ fontSize: '14px', fontFamily: 'National' }}>
                    {pressureValue ? `${pressureValue.toFixed(2)} hPa` : 'N/A'}
                  </span>
                </h5>
              </Col>

              {/* </Col> */}
            </Row>
          </Container>
        </div>
      </div>

      <div style={{ height: '0px' }} className="plot-hgt1">
        <CurrentOpenWeatherCharts
          chartData={openWeatherChartSeriesData}
          height={openFullScreen ? true : false}
          // renderCharts={handleClose1}
          // getByIdFarmInfo={getByIdFarmInfo}
        />
      </div>
      <div
        style={
          openFullScreen && !isHideBar
            ? { display: 'flex', flexDirection: 'column', marginTop: '39%' }
            : isHideFarmCollapse && !isHideBar
            ? { display: 'flex', flexDirection: 'column', marginTop: '350px' }
            : isHideBar
            ? { display: 'flex', flexDirection: 'column', marginTop: '350px' }
            : !isHideFarmCollapse && !isHideBar
            ? { display: 'flex', flexDirection: 'column', marginTop: '350px' }
            : { display: 'flex', flexDirection: 'column', marginTop: '45%' }
        }
      >
        {/* <div style={!openFullScreen && !isHideBar && !isHideFarmCollapse  ?{display:"flex" , flexDirection:"column" , marginTop:"350px"}:isHideFarmCollapse && isHideBar?{display:"flex" , flexDirection:"column" , marginTop:"39%"}:isHideBar?{display:"flex" , flexDirection:"column" , marginTop:"23%"}:!isHideFarmCollapse && !isHideBar ?{display:"flex" , flexDirection:"column" , marginTop:"55%"}:isHideFarmCollapse?{display:"flex" , flexDirection:"column" , marginTop:"39%"}:{display:"flex" , flexDirection:"column" , marginTop:"45%"}}> */}

        <div style={{ backgroundColor: '#F9F9F9', padding: '10px' }} id="forcast-container">
          <div
            style={
              openFullScreen
                ? {
                    marginLeft: '17px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '0px',
                  }
                : {
                    marginLeft: '17px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    // marginTop:"10%"
                    marginTop: '90px',
                  }
            }
          >
            <div>
              <p className="mt-4 mx-2 forecast-text">Forecast (15) Days</p>
            </div>
          </div>
        </div>
        <div style={{ height: '0px' }} className="plot-hgt1">
          <PlotCharts
            chartData={charts}
            height={openFullScreen ? true : false}
            renderCharts={handleClose1}
            getByIdFarmInfo={getByIdFarmInfo}
          />
        </div>
      </div>
    </>
  );
};
export default CurrentWatherForecast;
