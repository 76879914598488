import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {REACT_APP_SITE_KEY} from '../../Components/constants';

const Recaptcha = (props) => {
    const {recaptchaRef} = props;
    return (
                  <div>
    
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={REACT_APP_SITE_KEY}
                      onChange={response => props.input.onChange(response)}
                      onExpired={response => props.input.onExpired(response)}
                    />
                    <div className='invalid-feedback'>
                    {props.meta.touched && props.meta.error}
                    </div>
                    </div>
)

}

export default Recaptcha;
