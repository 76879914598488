import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Optionicon from '../../assets/Images/option.png';
import PropTypes from 'prop-types';
import {
  userByIdRequest,
  userByIdSuccess,
  deleteUserRequest,
  deleteUserSuccess,
} from '../../redux/manage-user/manageUserAction';
import ClientDetailmodal from './DetailsModal';
import ConfirmationModal from './Confirmation';
import ReasonModal from './ReasonModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { isSuperAdmin, isStaffUser } from '../../Components/common/utils';

const DetailsDropdown = props => {
  const {
    request_Id,
    handleLatestData,
    loggedUserData,
    handleLatestDataForDelete,
    activeTab,
    updatedPermissions,
    userDetails
  } = props;
  let history = useHistory();
  const t = useTranslation();
  const nextProps = useSelector(state => ({
    selectedReqIdData: state.UserList.userById || null,
    isUserByIdRequesting: state.UserList.isUserByIdRequesting,
    deleteUser: state.UserList.deleteUser,
    //deleteData: state.AccessKey.deleteData || null,
  }));
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reactivateClicked, setReactivateClicked] = useState(false);
  const [isApproveModalOpen, setApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [isFromEditDetails, setIsFromEditDetails] = useState(false);
  const [selectedReqModalData, setSelectedReqModalData] = useState();
  const [rejectModalData, setRejectModalData] = useState();
  const [approveModalData, setApproveModalData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [allowedPermission, setAllowedPermission] = useState();
  const [selectedTabListData, setSelectedTabListData] = useState({
    view_details: true,
    Edit_details: true,
    approved: true,
    reject: true,
    delete: false,
    re_activate: false,
  });
  const [isFarmsModalOpen, setFarmsModalOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    return () => {
      dispatch(userByIdSuccess({}));
      dispatch(deleteUserSuccess({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (loggedUserData?.length) {
      let a = [];
      loggedUserData.map(i => {
        return a.push(i.codename);
      });
      setAllowedPermission(a);
    }
  }, [loggedUserData]);

  useEffect(() => {
    if (nextProps.deleteUser) {
      const {
        data: { result },
      } = nextProps.deleteUser;
      if (result) {
        handleLatestDataForDelete();
      }
    }
  }, [nextProps.deleteUser]);

  useEffect(() => {
    if (activeTab === 'approve') {
      let a = {
        view_details: true,
        Edit_details: allowedPermission?.includes('assign_permission') || isSuperAdmin() ? true : false,
        approved: false,
        // reject: allowedPermission?.includes('change_user') || isSuperAdmin() ? true : false,
        delete: allowedPermission?.includes('delete_user') || isSuperAdmin() ? true : false,
        re_activate: false,
      };
      setSelectedTabListData(a);
    } else if (activeTab === 'delete') {
      let a = {
        view_details: true,
        Edit_details: false,
        approved: false,
        reject: false,
        delete: false,
        // re_activate: allowedPermission?.includes('change_user') || isSuperAdmin() ? true : false,
      };
      setSelectedTabListData(a);
    }
  }, [activeTab, updatedPermissions, allowedPermission]);

  useEffect(() => {
    if (nextProps.selectedReqIdData) {
      if (nextProps?.selectedReqIdData?.data) {
        setSelectedReqModalData(nextProps?.selectedReqIdData?.data);
      }
    }
  }, [nextProps.selectedReqIdData]);

  const handleViewDetailsModal = () => {
    setIsModalOpen(true);
    setIsFromEditDetails(false);
    dispatch(userByIdRequest({ request_Id: request_Id }));
  };
  const handleEditDetailsModal = () => {
    setIsModalOpen(true);
    setIsFromEditDetails(true);
    dispatch(userByIdRequest({ request_Id: request_Id }));
    //dispatch(getByIdRequest(request_Id));
  };
  const handleModalToggle = () => {
    setIsModalOpen(false);
    setFarmsModalOpen(false);
  };
  const handleApproved = () => {
    let data;
    data = {
      id: request_Id,
      is_approve: 'True',
    };
    setApproveModalData(data);
    setApproveModalOpen(true);
  };

  const handleReactivate = () => {
    let data;
    data = {
      id: request_Id,
      re_activate: 'True',
    };
    setApproveModalData(data);
    setApproveModalOpen(true);
    setReactivateClicked(true);
  };

  const handleReactivateModalToggle = () => {
    setReactivateClicked(false);
  };

  const handleFarms = () => {
    // setFarmsModalOpen(true);
    setIsFromEditDetails(false);
    dispatch(userByIdRequest({ request_Id: request_Id }));
    history.push({pathname : '/manage-farms-list',
    state: request_Id})
  };
  
  const handleRejected = () => {
    let data;
    data = {
      id: request_Id,
      is_reject: 'True',
    };
    setRejectModalOpen(true);
    setRejectModalData(data);
  };

  const handleRejectModal = useCallback(value => {
    setRejectModalOpen(value);
  }, []);

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = (boolean_value, value) => {
    setIsDeleteModalOpen(boolean_value);
    if (value === 'yes') {
      dispatch(deleteUserRequest({ id: request_Id }));
    }
  };

  const handleApprovedModalToggle = useCallback(value => {
    setApproveModalOpen(value);
  }, []);

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-option-icon">
        <DropdownToggle>
          <img src={Optionicon} alt={'not-available'} />
        </DropdownToggle>
        <DropdownMenu className="details-dropdown-menu">
          {selectedTabListData.view_details ? (
            selectedTabListData.Edit_details ? (
              <DropdownItem
                className="details-dropdown-item"
                onClick={() => handleViewDetailsModal()}
              >
                {' '}
                {(allowedPermission?.includes('change_user') || isSuperAdmin()) && activeTab === 'new'
                  ? t('MANAGE_USER.VIEW_DETAILS') + ' & ' + t('MANAGE_USER.EDIT_DETAILS')
                  : (allowedPermission?.includes('assign_permission') || isSuperAdmin()) && activeTab !== 'new'
                  ? t('MANAGE_USER.VIEW_DETAILS') + ' & ' + t('MANAGE_USER.EDIT_DETAILS')
                  : t('MANAGE_USER.VIEW_DETAILS') + ' details.'}
                {/* {t('MANAGE_USER.VIEW_DETAILS')} & {t('MANAGE_USER.EDIT_DETAILS')} */}
              </DropdownItem>
            ) : (
              <DropdownItem
                className="details-dropdown-item"
                onClick={() => handleViewDetailsModal()}
              >
                {' '}
                {/* {t('MANAGE_USER.VIEW_DETAILS')} details. */}
                {((allowedPermission?.includes('change_user') ||
                allowedPermission?.includes('assign_permission'))|| isSuperAdmin())
                  ? t('MANAGE_USER.VIEW_DETAILS') + ' & ' + t('MANAGE_USER.EDIT_DETAILS')
                  : t('MANAGE_USER.VIEW_DETAILS') + ' details.'}
              </DropdownItem>
            )
          ) : (
            ''
          )}
          {/* {selectedTabListData.Edit_details ? <DropdownItem className="details-dropdown-item" onClick={() => handleEditDetailsModal()}>{t("MANAGE_USER.EDIT_DETAILS")}</DropdownItem> : ""} */}

          {selectedTabListData.approved ? (
            <DropdownItem className="details-dropdown-item" onClick={() => handleApproved()}>
              {t('MANAGE_USER.APPROVE')}
            </DropdownItem>
          ) : (
            ''
          )}
          {activeTab === 'approve' && (
            <DropdownItem className="details-dropdown-item" onClick={() => handleFarms()}>
            {/* <DropdownItem className="details-dropdown-item" onClick={() => history.push({pathname : '/manage-farms-list',
            state: selectedReqModalData})}> */}
              {t('MANAGE_USER.MANAGE_FARMS_LIST')}
            </DropdownItem>
          ) }
          {selectedTabListData.reject ? (
            <DropdownItem className="details-dropdown-item" onClick={() => handleRejected()}>
              {t('MANAGE_USER.REJECT')}
            </DropdownItem>
          ) : (
            ''
          )}
          {selectedTabListData.delete ? (
            <DropdownItem className="details-dropdown-item" onClick={() => handleDelete()}>
              {t('MANAGE_USER.DELETE')}
            </DropdownItem>
          ) : (
            ''
          )}
          {selectedTabListData.re_activate ? (
            <DropdownItem className="details-dropdown-item" onClick={() => handleReactivate()}>
              {t('MANAGE_USER.RE_ACTIVATE')}
            </DropdownItem>
          ) : (
            ''
          )}
        </DropdownMenu>
      </Dropdown>
      {isModalOpen && (
        <ClientDetailmodal
          activeTab={activeTab}
          modalOpen={isModalOpen}
          handleModalToggle={handleModalToggle}
          req_data={selectedReqModalData}
          handleLatestData={props.handleLatestData}
          loggedUserData={props.loggedUserData}
          fromEditDetails={isFromEditDetails}
          isUserByIdRequesting={nextProps.isUserByIdRequesting}
        />
      )}
      {isApproveModalOpen && (
        <ConfirmationModal
          modalData={approveModalData}
          reactivateClicked={reactivateClicked}
          handleReactivateModalToggle={handleReactivateModalToggle}
          isOpen={isApproveModalOpen}
          latestDataHandler={handleLatestData}
          handleApprovedModalToggle={handleApprovedModalToggle}
        />
      )}
      {isRejectModalOpen && (
        <ReasonModal
          modalData={rejectModalData}
          isOpen={isRejectModalOpen}
          latestDataHandler={handleLatestData}
          handleRejectModal={handleRejectModal}
          handleModalToggle={handleModalToggle}
        />
      )}
      {isDeleteModalOpen ? (
        <DeleteConfirmationModal
          handleDeleteConfirm={handleDeleteConfirm}
          isDeleteModalOpen={isDeleteModalOpen}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default DetailsDropdown;
DetailsDropdown.propTypes = {
  request_Id: PropTypes.string,
  key: PropTypes.string,
  handleLatestData: PropTypes.func,
  selectedPageTab: PropTypes.string,
  handleLatestDataForDelete: PropTypes.func,
  activeTab: PropTypes.string,
};
