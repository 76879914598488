import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Optionicon from '../../assets/Images/option.png';
import PropTypes from 'prop-types';
import {
  userByIdRequest,
  userByIdSuccess,
  deleteUserRequest,
  deleteUserSuccess,
} from '../../redux/manage-user/manageUserAction';
import { deleteSurveyorsRequest } from '../../redux/manageFieldData/manageFieldDataActions';
import jwt_decode from 'jwt-decode';

import DeleteConfirmationModal from './DeleteConfirmationModal';
import {
  SCOPE,
  deleteDiscoveryUrl,
  CLIENT_ID,
  API_KEY,
  discoveryUrl,
} from '../../Components/constants/index';
import { showError } from '../../Components/Notifications';
import { getODKAccessToken, setODKAccessToken } from '../../Components/common/utils';

const DetailsDropdown = (props) => {
  const {
    request_Id,
    handleLatestData,
    loggedUserData,
    handleLatestDataForDelete,
    activeTab,
    updatedPermissions,
    signedInUserDetails,
    signedInUser,
    userDetails,
    setIsLoading
  } = props;
  let history = useHistory();
  const t = useTranslation();
  const nextProps = useSelector((state) => ({
    selectedReqIdData: state.UserList.userById || null,
    isUserByIdRequesting: state.UserList.isUserByIdRequesting,
    deleteUser: state.UserList.deleteUser,
    //deleteData: state.AccessKey.deleteData || null,
    deleteSurveyorsData: state.fieldDataList?.deleteSurveyorsData,
    managesurveyorsListData: state.fieldDataList?.managesurveyorsListData,
  }));
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reactivateClicked, setReactivateClicked] = useState(false);
  const [isApproveModalOpen, setApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [isFromEditDetails, setIsFromEditDetails] = useState(false);
  const [selectedReqModalData, setSelectedReqModalData] = useState();
  const [rejectModalData, setRejectModalData] = useState();
  const [approveModalData, setApproveModalData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [allowedPermission, setAllowedPermission] = useState();
  const [selectedTabListData, setSelectedTabListData] = useState({
    view_details: false,
    Edit_details: false,
    approved: false,
    reject: false,
    delete: true,
    re_activate: false,
  });
  const [isFarmsModalOpen, setFarmsModalOpen] = useState(false);
  // const [signedInUser, setSignedInUser] = useState('');
  const [accessToken,setAccessToken] = useState('');
  const [tokenClient, setTokenClient] = useState('')
  const [isTokenExpired,setIsTokenExpired] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  useEffect(() => {
    return () => {
      dispatch(userByIdSuccess({}));
      dispatch(deleteUserSuccess({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (loggedUserData?.length) {
      let a = [];
      loggedUserData.map((i) => {
        return a.push(i.codename);
      });
      setAllowedPermission(a);
    }
  }, [loggedUserData]);

  useEffect(() => {
    if (nextProps.deleteUser) {
      const {
        data: { result },
      } = nextProps.deleteUser;
      if (result) {
        handleLatestDataForDelete();
      }
    }
  }, [nextProps.deleteUser]);

  useEffect(() => {
    if (activeTab === 'approve') {
      let a = {
        view_details: true,
        Edit_details: allowedPermission?.includes('assign_permission') ? true : false,
        approved: false,
        reject: allowedPermission?.includes('change_user') ? true : false,
        delete: allowedPermission?.includes('delete_user') ? true : false,
        re_activate: false,
      };
      setSelectedTabListData(a);
    } else if (activeTab === 'reject') {
      let a = {
        view_details: true,
        Edit_details: allowedPermission?.includes('assign_permission') ? true : false,
        approved: allowedPermission?.includes('change_user') ? true : false,
        reject: false,
        delete: allowedPermission?.includes('delete_user') ? true : false,
        re_activate: false,
      };
      setSelectedTabListData(a);
    } else if (activeTab === 'delete') {
      let a = {
        view_details: true,
        Edit_details: false,
        approved: false,
        reject: false,
        delete: false,
        re_activate: allowedPermission?.includes('change_user') ? true : false,
      };
      setSelectedTabListData(a);
    } else if (activeTab === 'new') {
      let a;
      a = {
        view_details: true,
        Edit_details: allowedPermission?.includes('assign_permission') ? true : false,
        approved: allowedPermission?.includes('change_user') ? true : false,
        reject: allowedPermission?.includes('change_user') ? true : false,
        delete: allowedPermission?.includes('delete_user') ? true : false,
        re_activate: false,
      };
      setSelectedTabListData(a);
    }
  }, [activeTab, updatedPermissions, allowedPermission]);

  useEffect(() => {
    if (nextProps.selectedReqIdData) {
      if (nextProps?.selectedReqIdData?.data) {
        setSelectedReqModalData(nextProps?.selectedReqIdData?.data);
      }
    }
  }, [nextProps.selectedReqIdData]);

  const handleViewDetailsModal = () => {
    setIsModalOpen(true);
    setIsFromEditDetails(false);
    dispatch(userByIdRequest({ request_Id: request_Id }));
  };
  const handleEditDetailsModal = () => {
    setIsModalOpen(true);
    setIsFromEditDetails(true);
    dispatch(userByIdRequest({ request_Id: request_Id }));
    //dispatch(getByIdRequest(request_Id));
  };
  const handleModalToggle = () => {
    setIsModalOpen(false);
    setFarmsModalOpen(false);
  };
  const handleApproved = () => {
    let data;
    data = {
      id: request_Id,
      is_approve: 'True',
    };
    setApproveModalData(data);
    setApproveModalOpen(true);
  };

  const handleReactivate = () => {
    let data;
    data = {
      id: request_Id,
      re_activate: 'True',
    };
    setApproveModalData(data);
    setApproveModalOpen(true);
    setReactivateClicked(true);
  };

  const handleReactivateModalToggle = () => {
    setReactivateClicked(false);
  };

  const handleFarms = () => {
    // setFarmsModalOpen(true);
    setIsFromEditDetails(false);
    dispatch(userByIdRequest({ request_Id: request_Id }));
    history.push({ pathname: '/manage-farms-list', state: request_Id });
  };

  const handleRejected = () => {
    let data;
    data = {
      id: request_Id,
      is_reject: 'True',
    };
    setRejectModalOpen(true);
    setRejectModalData(data);
  };

  const handleRejectModal = useCallback((value) => {
    setRejectModalOpen(value);
  }, []);

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };
  useEffect(() => {
    if (nextProps.managesurveyorsListData) {
      handleClientLoad();
    }
  }, [nextProps.managesurveyorsListData]);

  useEffect(()=>{
    if(getODKAccessToken() && isTokenExpired){
      // showError('Unable to proceed due to expired access token. please authorize and continue')
      if(userDetails?.google_email)
      tokenClient?.requestAccessToken({ prompt:'', hint: userDetails?.google_email || '' })
      else tokenClient?.requestAccessToken({ prompt: 'consent' })    }
  },[isTokenExpired])

  const handleClientLoad = () => {
    // /* global google */
    // window?.google?.accounts?.id.initialize({
    //   client_id: CLIENT_ID,
    //   apiKey: API_KEY,
    //   useOneTap: false,
    //   auto_select: true,
    //   callback: handleCallbackResponse
    // })
    // google.accounts.id.prompt();

    //initializing token token Client
    const tokenClient = window?.google?.accounts?.oauth2?.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPE,
      prompt: userDetails?.google_email ? '' : 'consent',
      hint: userDetails?.google_email || '',
      callback: (tokenResponse) => {
        if (tokenResponse && tokenResponse?.access_token) {
          setIsTokenExpired(false);
          setODKAccessToken(tokenResponse?.access_token)
          setAccessToken(tokenResponse?.access_token)
        }
        //we now have access to a live token to use for any google api
      },
      error_callback: (err) => {
        if (err.type == 'popup_failed_to_open') {
          // The popup window is failed to open
          if(err?.message){
            showError(`${err.message}. Please allow the popup to open in browser settings if it is blocked.`)
            history.push("/surveyDataSettingsDashboard")
          }
        } else if (err.type == 'popup_closed') {
          // The popup window is closed before an OAuth response is returned
        }
      }
    });
    setTokenClient(tokenClient)
  };
  
  const handleDeleteConfirm = (boolean_value, value) => {
    setIsDeleteModalOpen(boolean_value);
    if (value === 'yes') {
      // dispatch(deleteUserRequest({ id: request_Id }));
      // deletePermissions("")
      // permissionIdForEmail(props.email)
      setIsLoading(true);
      let access_token = getODKAccessToken()
      if (access_token) getListOfPermissions(props.email, access_token);
      else {
        if(userDetails?.google_email)
        tokenClient?.requestAccessToken({ prompt:'', hint: userDetails?.google_email || '' })
        else tokenClient?.requestAccessToken({ prompt: 'consent' })
        tokenClient.callback = (res) => {
          setAccessToken(res?.access_token)
          setODKAccessToken(res?.access_token)
          getListOfPermissions(props.email, res?.access_token);
        }
      }
    }
  };
  const localUserPermission = localStorage.getItem('userPermissions');

  let driveFolderId = JSON.parse(localUserPermission)?.result?.user_profile?.drive_folder_id;
  
  

  async function getListOfPermissions(email, accessToken) {
    await fetch(`https://www.googleapis.com/drive/v3/files/${driveFolderId}/permissions?key=${API_KEY}&supportsAllDrives=true&fields=permissions(id,emailAddress),nextPageToken`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    }).then(res => res.json())
    .then(response => {
      if(response?.error) throw response?.error;
      response?.permissions?.map((item,index)=>{
        if ((item.emailAddress).toString() === email?.toString()) {
          deletePermissions(driveFolderId, item.id, accessToken);
        }
      })

      if(response?.permissions?.every(item=> item.emailAddress !== email)){
        showError('Surveyor do not have permission to access drive files. Unable to delete')
        setIsLoading(false)
      }
    })
    .catch(err => { 
      setIsLoading(false);
      if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
        setIsTokenExpired(true)
      } else setIsTokenExpired(false)
    })
  }

 async function deletePermissions(driveFolderId, id, accessToken) {
    await fetch(`https://www.googleapis.com/drive/v3/files/${driveFolderId}/permissions/${id}?key=${API_KEY}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then(
      function (response) {
        if(response?.error) throw response?.error;
        dispatch(deleteSurveyorsRequest({ email_id: request_Id }));
      },
    )
    .catch(err=>{
      setIsLoading(false);
      if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
        setIsTokenExpired(true)
      } else {
        if(err?.response?.code === 204){
          setIsLoading(true);
          dispatch(deleteSurveyorsRequest({ email_id: request_Id }));
        }
        setIsTokenExpired(false)
      }
    })
  }

  const handleApprovedModalToggle = useCallback((value) => {
    setApproveModalOpen(value);
  }, []);

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-option-icon">
        <DropdownToggle>
          <img src={Optionicon} alt={'not-available'} />
        </DropdownToggle>
        <DropdownMenu className="details-dropdown-menu">
          {selectedTabListData.view_details ? (
            selectedTabListData.Edit_details ? (
              <DropdownItem
                className="details-dropdown-item"
                onClick={() => handleViewDetailsModal()}
              >
                {' '}
                {allowedPermission?.includes('change_user') && activeTab === 'new'
                  ? t('MANAGE_USER.VIEW_DETAILS') + ' & ' + t('MANAGE_USER.EDIT_DETAILS')
                  : allowedPermission?.includes('assign_permission') && activeTab !== 'new'
                  ? t('MANAGE_USER.VIEW_DETAILS') + ' & ' + t('MANAGE_USER.EDIT_DETAILS')
                  : t('MANAGE_USER.VIEW_DETAILS') + ' details.'}
                {/* {t('MANAGE_USER.VIEW_DETAILS')} & {t('MANAGE_USER.EDIT_DETAILS')} */}
              </DropdownItem>
            ) : (
              <DropdownItem
                className="details-dropdown-item"
                onClick={() => handleViewDetailsModal()}
              >
                {' '}
                {/* {t('MANAGE_USER.VIEW_DETAILS')} details. */}
                {allowedPermission?.includes('change_user') ||
                allowedPermission?.includes('assign_permission')
                  ? t('MANAGE_USER.VIEW_DETAILS') + ' & ' + t('MANAGE_USER.EDIT_DETAILS')
                  : t('MANAGE_USER.VIEW_DETAILS') + ' details.'}
              </DropdownItem>
            )
          ) : (
            ''
          )}
          {/* {selectedTabListData.Edit_details ? <DropdownItem className="details-dropdown-item" onClick={() => handleEditDetailsModal()}>{t("MANAGE_USER.EDIT_DETAILS")}</DropdownItem> : ""} */}

          {selectedTabListData.approved ? (
            <DropdownItem className="details-dropdown-item" onClick={() => handleApproved()}>
              {t('MANAGE_USER.APPROVE')}
            </DropdownItem>
          ) : (
            ''
          )}
          {activeTab === 'approve' && (
            <DropdownItem className="details-dropdown-item" onClick={() => handleFarms()}>
              {/* <DropdownItem className="details-dropdown-item" onClick={() => history.push({pathname : '/manage-farms-list',
            state: selectedReqModalData})}> */}
              {t('MANAGE_USER.MANAGE_FARMS_LIST')}
            </DropdownItem>
          )}
          {selectedTabListData.reject ? (
            <DropdownItem className="details-dropdown-item" onClick={() => handleRejected()}>
              {t('MANAGE_USER.REJECT')}
            </DropdownItem>
          ) : (
            ''
          )}
          {selectedTabListData.delete ? (
            <DropdownItem className="details-dropdown-item" onClick={() => handleDelete()}>
              {t('MANAGE_USER.DELETE')}
            </DropdownItem>
          ) : (
            ''
          )}
          {selectedTabListData.re_activate ? (
            <DropdownItem className="details-dropdown-item" onClick={() => handleReactivate()}>
              {t('MANAGE_USER.RE_ACTIVATE')}
            </DropdownItem>
          ) : (
            ''
          )}
        </DropdownMenu>
      </Dropdown>
      {/* {isModalOpen && (
        <ClientDetailmodal
          activeTab={activeTab}
          modalOpen={isModalOpen}
          handleModalToggle={handleModalToggle}
          req_data={selectedReqModalData}
          handleLatestData={props.handleLatestData}
          loggedUserData={props.loggedUserData}
          fromEditDetails={isFromEditDetails}
          isUserByIdRequesting={nextProps.isUserByIdRequesting}
        />
      )}
      {isApproveModalOpen && (
        <ConfirmationModal
          modalData={approveModalData}
          reactivateClicked={reactivateClicked}
          handleReactivateModalToggle={handleReactivateModalToggle}
          isOpen={isApproveModalOpen}
          latestDataHandler={handleLatestData}
          handleApprovedModalToggle={handleApprovedModalToggle}
        />
      )}
      {isRejectModalOpen && (
        <ReasonModal
          modalData={rejectModalData}
          isOpen={isRejectModalOpen}
          latestDataHandler={handleLatestData}
          handleRejectModal={handleRejectModal}
          handleModalToggle={handleModalToggle}
        />
      )} */}
      {isDeleteModalOpen ? (
        <DeleteConfirmationModal
          handleDeleteConfirm={handleDeleteConfirm}
          isDeleteModalOpen={isDeleteModalOpen}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default DetailsDropdown;
DetailsDropdown.propTypes = {
  request_Id: PropTypes.string,
  key: PropTypes.string,
  handleLatestData: PropTypes.func,
  selectedPageTab: PropTypes.string,
  handleLatestDataForDelete: PropTypes.func,
  activeTab: PropTypes.string,
};
