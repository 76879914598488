import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import _ from 'lodash';
import { LEGEND_UNIT_CONVERTER } from '../../../Components/common/utils';
import { MAP_PREFERENCES_lABELS } from '../../../Components/constants';
import moment from 'moment';

function Legend({ map, add, layer, position }) {
  const categoryNames = ['Vegetation', 'Basic', 'Flood'];
  const id = layer?.id + position;

  const handleMouseOver = () => {
    map?.scrollWheelZoom.disable();
    map?.doubleClickZoom.disable();
  };

  const handleMouseOut = () => {
    map?.scrollWheelZoom.enable();
    map?.doubleClickZoom.enable();
  };
  useEffect(() => {
    if (map) {
      const legend = L?.control({ position: position });
      if (add) {
        legend.onAdd = () => {
          let legendDiv = L.DomUtil.create('div');
          legendDiv.id = id || 'legend_div';
          if (position === 'bottomright') {
            legendDiv.className = 'right-discrete-legend-style';
          } else {
            legendDiv.className = 'left-discrete-legend-style';
          }

          if (layer?.slug !== 'cwp') {
            let datesContainer = L.DomUtil.create('div');
            let spanContainer = L.DomUtil.create('div');
            let monthContainer = L.DomUtil.create('div');
            let monthSpanContainer = L.DomUtil.create('div');
            datesContainer.className =
              position === 'bottomleft' ? 'datesContainLeftDiv' : 'discreteDatesContainRightDiv';
            monthContainer.className =
              position === 'bottomleft' ? 'datesContainLeftDiv' : 'discreteDatesContainRightDiv';
            spanContainer.className = 'dates-span-container';
            monthSpanContainer.className = 'dates-span-container';
            if (layer?.selectedDates?.startDate && layer?.selectedDates?.endDate) {
              spanContainer.innerHTML =
                '' + layer?.selectedDates?.startDate + ' to ' + layer?.selectedDates?.endDate + ' ';
              datesContainer.appendChild(spanContainer);
              legendDiv.appendChild(datesContainer);
            }
            if (layer?.forecastMonth) {
              monthSpanContainer.innerHTML = '' + layer?.forecastMonth + ' ';
              monthContainer.appendChild(monthSpanContainer);
              legendDiv.appendChild(monthContainer);
            }
          }
          let containerDiv = L.DomUtil.create('div');
          containerDiv.id = 'discrete-legend-id';
          containerDiv.onmouseover = handleMouseOver;
          containerDiv.onmouseout = handleMouseOut;
          if (layer?.dataSource === 'SMCI') {
            if (layer.fromMapPreferences) {
              containerDiv.className =
                'legend-container-div discrete-legend-for-indices info-discrete smci-legend';
            } else {
              containerDiv.className =
                'legend-container-div discrete-legend info-discrete smci-legend';
            }
          } else {
            if (layer.fromMapPreferences) {
              containerDiv.className =
                'legend-container-div discrete-legend-for-indices info-discrete';
            } else {
              containerDiv.className = 'legend-container-div discrete-legend info-discrete';
            }
          }
          // left-discrete-legend-style';
          let labels = [];
          let grades = layer?.palette;
          let names = '';
          for (let i = 0; i < grades?.length; i++) {
            if (!Array.isArray(grades[i])) {
              names = grades[i].name;
              labels.push('<i style="background:' + grades[i].color + '"></i> ' + names);
            }
          }

          if (layer.dataSource === 'Dry Spell') {
            containerDiv.innerHTML =
              `<b style="font-size:14px;color:#000"">${layer.dataSource}(Days)</b>` +
              '<div style="padding-top:5px"></div>' +
              labels.join('<br>');
          } else {
            if (layer.units) {
              containerDiv.innerHTML =
                layer?.dataSource === 'IRI'
                  ? '<b style="font-size:14px;color:#000">'+(layer?.dataSource) + '</b><br><br>' +  `<b style="font-size:14px;color:#000;white-space: pre-wrap;max-width: 100%">White indicates Climatological odds</b> <br><br>` +
                    labels.join('<br>') +
                    ' <br><br> <b style="font-size:14px;color:#000;white-space: pre-wrap;max-width: 100%">' +
                    (layer?.fromMapPreferences ? layer?.dataSource : layer.parameter) +
                    ' ' +
                    '(' +
                    layer.units +
                    ')' +
                    '</b><br><br>'
                  : '<b style="font-size:14px;color:#000">' +
                    (layer?.fromMapPreferences
                      ? layer?.slug === MAP_PREFERENCES_lABELS.PROBA_LULC ||
                        layer?.slug === MAP_PREFERENCES_lABELS.SRILANKA_LULC
                        ? layer?.id
                        : layer?.dataSource
                      : layer.parameter) +
                    ' ' +
                    '(' +
                    layer.units +
                    ')' +
                    '</b><br><br>' +
                    labels.join('<br>');
            } else {
              if (categoryNames.includes(layer?.parameter)) {
                containerDiv.innerHTML =
                  '<b style="font-size:14px;color:#000">' +
                  layer.dataSource +
                  '</b><br><br>' +
                  labels.join('<br>');
              } else {
                containerDiv.innerHTML =
                  '<b style="font-size:14px;color:#000">' +
                  (layer?.fromMapPreferences
                    ? layer?.slug === MAP_PREFERENCES_lABELS.PROBA_LULC ||
                      layer?.slug === MAP_PREFERENCES_lABELS.SRILANKA_LULC
                      ? layer?.id
                      : layer?.dataSource
                    : layer.parameter) +
                  '</b><br><br>' +
                  labels.join('<br>');
              }
            }
            // containerDiv.innerHTML = '<b>' + layer.dataSource + '</b><br><br>' + labels.join('<br>');
          }
          // if (layer.dataSource==="IRI") {
          //   // containerDiv.innerHTML = '<b>' + layer.parameter + '</b><br><br>' + labels.join('<br>');
          //   // containerDiv.innerHTML = '<b>'+( layer?.parameter) +" " +"(" + layer.units+")" + '</b><br><br>' + labels.join('<br>');
          //   containerDiv.innerHTML = '<b>'+ "Dominant Tercile Probability" +  '</b><br><br>' + labels.join('<br>');
          //   containerDiv.className = 'legend-container-div  discrete-legend1 info-discrete';
          // }
          // else {
          //   containerDiv.innerHTML = '<b>' + layer.dataSource + '</b><br><br>' + labels.join('<br>');

          // }
          // console.log(layer,'layersds')
          // if(layer.dataSource === "Dry Spell"){
          //   containerDiv.innerHTML = `${layer.dataSource}(Days)` + '<div style="padding-top:5px"></div>' + labels.join('<br>');
          // }
          // else{
          //   containerDiv.innerHTML = layer.parameter==="Land use land cover"? '<b>'+layer.dataSource + '</b><br>' + labels.join('<br>'):layer.dataSource + '<br>' + labels.join('<br>');
          // }
          legendDiv.append(containerDiv);
          return legendDiv;
        };
        if (layer && !_.isEmpty(legend) && !_.isEmpty(map)) {
          if (!_.isEmpty(map?._layers)) {
            legend?.addTo(map);
          }
        }
      } else {
        if (layer) {
          const image = document.getElementById(id || 'legend_div');
          legend.onRemove = () => {
            if (image) {
              L.DomUtil.remove(image);
            }
          };
          legend.onRemove();
        }
      }
    }
  }, [add, map, layer]); //here add map
  return null;
}

export default Legend;
