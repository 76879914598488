import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import UploadIcon from '../../assets/Images/upload-image.svg';

export const renderImageField = ({
  input,
  placeholder,
  className,
  controlledValue,
  readOnly,
  autoFocus,
  type,
  id,
  meta: { touched, error, warning },
}) => (
  <>
    <span className="upload-image">
      <label for={id} className="upload-image-label form-control">
        Upload image
        <img src={UploadIcon} alt={'not-available'} className="upload-icon" />
      </label>
    </span>
    <Input
      {...input}
      placeholder={placeholder}
      className={className}
      value={controlledValue}
      type={type}
      autoFocus={autoFocus}
      readOnly={readOnly}
      invalid={touched && error ? true : false}
      id={id}
      style={{ display: 'none' }}
    />
    <div className="invalid-feedback">
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </>
);

export const renderFileField = ({
  input,
  placeholder,
  className,
  controlledValue,
  readOnly,
  autoFocus,
  type,
  id,
  accept,
  meta: { touched, error, warning },
}) => (
  <>
    <span className="upload-image">
      <label for={id} className="upload-image-label form-control">
        Upload File
        {/* <img src={UploadIcon} alt={"not-available"} className="upload-icon"/> */}
      </label>
    </span>
    <Input
      {...input}
      placeholder={placeholder}
      className={className}
      value={controlledValue}
      type={type}
      autoFocus={autoFocus}
      readOnly={readOnly}
      invalid={touched && error ? true : false}
      required={true}
      id={id}
      style={{ display: 'none' }}
      accept={accept}
    />
    <div className="invalid-feedback">
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </>
);

export const renderTextField = ({
  input,
  placeholder,
  className,
  readOnly,
  autoFocus,
  type,
  required,
  min,
  max,
  disabled,
  rows,
  cols,
  meta: { touched, error, warning },
}) => (
  <>
    <Input
      {...input}
      placeholder={placeholder}
      className={className}
      value={input.value || ''}
      type={type}
      autoFocus={autoFocus}
      readOnly={readOnly}
      invalid={touched && error ? true : false}
      required={required}
      rows={rows}
      disabled={disabled}
      cols={cols}
      min={min || ''}
      max={max || ''}
    />
    <div className="invalid-feedback">
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </>
);

export const renderCheckBoxField = ({
  input,
  placeholder,
  className,
  readOnly,
  disabled,
  type,
  required,
  checked,
  defaultChecked,
  meta: { touched, error, warning },
}) => (
  <>
    <Input
      {...input}
      placeholder={placeholder}
      className={className}
      type={type}
      readOnly={readOnly}
      invalid={touched && error ? true : false}
      required={required}
      checked={checked}
      defaultChecked={defaultChecked}
    />
    {/* <div className="invalid-feedback">
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div> */}
  </>
);

export const renderReactSelectInputField = ({
  input,
  options,
  name,
  id,
  placeholder,
  isClearable,
  isDisabled,
  isSearchable,
  menuIsOpen,
  menuPlacement,
  menuHeight,
  className,
  isMulti,
  isLoading,
  isOptionDisabled,
  noOptionsMessage,
  meta: { touched, error, warning },
}) => {
  return (
    <>
      <Select
        {...input}
        id={id}
        name={name}
        className={className}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        isOptionDisabled={isOptionDisabled}
        options={options}
        menuPlacement={menuPlacement}
        //value={controlledValue}
        value={input.value || ''}
        onChange={value => input.onChange(value)}
        //onBlur={() => input.onBlur()}
        onBlur={() => input.onBlur(input.value)}
        isMulti={isMulti}
        menuIsOpen={menuIsOpen}
        required={true}
        maxMenuHeight={menuHeight || 150}
        isLoading={isLoading}
        noOptionsMessage={noOptionsMessage}
      />
      <div className="invalid-feedback">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </>
  );
};

export const renderReactUserInputField = ({
  input,
  options,
  name,
  id,
  type,
  controlledValue,
  placeholder,
  isClearable,
  isDisabled,
  className,
  required,
  autoFocus,
  isMulti,
  isLoading,
  onMenuOpen,
  onMenuClose,
  meta: { touched, error, warning },
}) => {
  return (
    <>
      <Input
        {...input}
        id={id}
        name={name}
        type={type}
        className={className}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={isDisabled}
        options={options}
        //value={controlledValue}
        value={input.value || ''}
        onChange={value => input.onChange(value)}
        //onBlur={() => input.onBlur()}
        onBlur={() => input.onBlur(input.value)}
        isMulti={isMulti}
        required={true}
        maxMenuHeight={150}
        isLoading={isLoading}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        
      />
      <div className="invalid-feedback">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </>
  );
};

export const renderSelectDropdownField = ({
  input,
  placeholder,
  className,
  controlledValue,
  disabled,
  type,
  meta: { touched, error },
  children,
}) => (
  <>
    <div className="field">
      <div className="control">
        <div className={'select ' + (touched ? (error ? 'is-danger' : 'is-success') : '')}>
          <select
            {...input}
            placeholder={placeholder}
            className={className}
            value={controlledValue}
            type={type}
            disabled={disabled}
            required={true}
            invalid={touched && error ? true : false}
          >
            {children}
          </select>
          <div className="invalid-feedback">
            {touched && error && <p className="text-danger select-style">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  </>
);

export const renderDateField = ({
  input,
  placeholder,
  className,
  readOnly,
  autoFocus,
  required,
  meta: { touched, error, warning },
}) => (
  <>
    <Input
      {...input}
      placeholder={placeholder}
      className={className}
      value={input.value ? moment(input.value) : null}
      type="date"
      autoFocus={autoFocus}
      readOnly={readOnly}
      invalid={touched && error ? true : false}
      required={required}
    />
    <div className="invalid-feedback">
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </>
);

export const renderFileInput = () => {
  return (
    <FormGroup>
      <Label for="exampleFile">File</Label>
      <Input type="file" name="file" id="exampleFile" />
      {/* <FormText color="muted">
  This is some placeholder block-level help text for the above input.
  It's a bit lighter and easily wraps to a new line.
</FormText> */}
    </FormGroup>
  );
};
