import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { REACT_API_URL } from '../constants';
import { NavigateToModuleAfterLogin } from './utils';

export default function CommonToolTip({ moduleName,routeName, children }) {
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: '#4179B7',
    },
  }));
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#4179B7',
      color: '#fff',
      maxWidth: 220,
      fontSize: '13px',
      fontFamily: 'National',
      border: '1px solid transparent',
      borderRadius: '3px',
      padding: '8px 21px',
    },
  }))(Tooltip);

  const NavigateToLogin = () => {
    NavigateToModuleAfterLogin(routeName);
    window.open('/login', '_blank')
  }

  const classes = useStylesBootstrap();
  return moduleName === 'Map Preferences' ? (
    <HtmlTooltip
      classes={classes}
      arrow
      title={
        <>
          <span>
            Disclaimer: IWMI has been following the guidelines of the UN on territorial boundaries
            for publications. The guidelines can be accessed from:
          </span> {' '}
          <a
            style={{ color: '#fff' }}
            href="https://www.ifpri.org/sites/default/files/guidelines_countries-territories-maps.pdf"
            target="_BLANK"
            rel="noreferrer"
          >
            https://www.ifpri.org/sites/default/files/guidelines_countries-territories-maps.pdf
          </a>
        </>
      }
      interactive
    >
      {children}
    </HtmlTooltip>
  ) : (
    <HtmlTooltip
      classes={classes}
      arrow
      title={
        <>
          <span>{`Please `}</span>
          <span
            style={{ color: '#fff', cursor: 'pointer', textDecoration: 'underline' }}
            // onClick={() => history.push('/login')}
            onClick={NavigateToLogin}
          >
            Sign In
          </span>
          <span>{` into the application to explore the ${moduleName}.`}</span>
        </>
      }
      interactive
    >
      {children}
    </HtmlTooltip>
  );
}
