const LOCATION_CONST = {
  //for country
  COUNTRY_LIST_REQUEST: 'COUNTRY_LIST_REQUEST',
  COUNTRY_LIST_SUCCESS: 'COUNTRY_LIST_SUCCESS',
  COUNTRY_LIST_FAILED: 'COUNTRY_LIST_FAILED',
  //for state
  STATE_LIST_REQUEST: 'STATE_LIST_REQUEST',
  STATE_LIST_SUCCESS: 'STATE_LIST_SUCCESS',
  STATE_LIST_FAILED: 'STATE_LIST_FAILED',
  //for district
  DISTRICT_LIST_REQUEST: 'DISTRICT_LIST_REQUEST',
  DISTRICT_LIST_SUCCESS: 'DISTRICT_LIST_SUCCESS',
  DISTRICT_LIST_FAILED: 'DISTRICT_LIST_FAILED',
  //for sub district
  SUB_DISTRICT_LIST_REQUEST: 'SUB_DISTRICT_LIST_REQUEST',
  SUB_DISTRICT_LIST_SUCCESS: 'SUB_DISTRICT_LIST_SUCCESS',
  SUB_DISTRICT_LIST_FAILED: 'SUB_DISTRICT_LIST_FAILED',
  //for block 
  BLOCK_LIST_REQUEST: 'BLOCK_LIST_REQUEST',
  BLOCK_LIST_SUCCESS: 'BLOCK_LIST_SUCCESS',
  BLOCK_LIST_FAILED: 'BLOCK_LIST_FAILED',
  //for Location convention
  LOCATION_CONVENTION_REQUEST: 'LOCATION_CONVENTION_REQUEST',
  LOCATION_CONVENTION_SUCCESS: 'LOCATION_CONVENTION_SUCCESS',
  LOCATION_CONVENTION_FAILED: 'LOCATION_CONVENTION_FAILED',

  //for state by district name
  STATE_BY_DISTRICT_NAME_REQUEST: ' STATE_BY_DISTRICT_NAME_REQUEST',
  STATE_BY_DISTRICT_NAME_SUCCESS: ' STATE_BY_DISTRICT_NAME_SUCCESS',
  STATE_BY_DISTRICT_NAME_FAILED: ' STATE_BY_DISTRICT_NAME_FAILED',
};

export default LOCATION_CONST;
