import {
  CROP_HEALTH_MONITORING_CONST,
  CROP_HEALTH_MONITORING_MAP_CONST,
  CROP_HEALTH_CONST_GET_ALL_INDICES,
  CROP_HEALTH_WATER_MONITORING_MAP_CONST,
  CROP_HEALTH_MONITORING_TIME_SERIES_CONST,
  CROP_HEALTH_MONITORING_WATER_TIME_SERIES_CONST,
  CROP_HEALTH_MONITORING_GLDAS_CONST,
  CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_CONST,
  CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_CONST,
  AGRICULTURE_CONST_INDICATOR_TO_SENSOR,
  WATER_CONST_INDICATOR_TO_SENSOR,
} from './cropHealthMonitoringConsts';

const CropHealthMonitoring = (state = {}, action) => {
  switch (action.type) {
    case CROP_HEALTH_MONITORING_CONST.CROP_HEALTH_MONITORING_REQUEST:
      return {
        ...state,
        isCropIndicesListRequesting: true,
        CropIndicesListData: false,
        CropIndicesListDataError: false,
        CropIndicesListDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_CONST.CROP_HEALTH_MONITORING_SUCCESS:
      return {
        ...state,
        isCropIndicesListRequesting: false,
        CropIndicesListData: action.payload.response,
        CropIndicesListDataError: false,
        CropIndicesListDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_CONST.CROP_HEALTH_MONITORING_FAILED:
      return {
        ...state,
        isCropIndicesListRequesting: false,
        CropIndicesListData: false,
        CropIndicesListDataError: action.payload.error ? false : action.payload.response,
        CropIndicesListDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    //AGRICULTURE INDICATOR TO SENSOR
    case AGRICULTURE_CONST_INDICATOR_TO_SENSOR.AGRICULTURE_CONST_INDICATOR_TO_SENSOR_REQUEST:
      return {
        ...state,
        isAgricultureIndicatorToSensorListRequesting: true,
        AgricultureIndicatorToSensorListData: false,
        AgricultureIndicatorToSensorListDataError: false,
        AgricultureIndicatorToSensorListDataNetworkError: false,
      };
    case AGRICULTURE_CONST_INDICATOR_TO_SENSOR.AGRICULTURE_CONST_INDICATOR_TO_SENSOR_SUCCESS:
      return {
        ...state,
        isAgricultureIndicatorToSensorListRequesting: false,
        AgricultureIndicatorToSensorListData: action.payload.response,
        AgricultureIndicatorToSensorListDataError: false,
        AgricultureIndicatorToSensorListDataNetworkError: false,
      };
    case AGRICULTURE_CONST_INDICATOR_TO_SENSOR.AGRICULTURE_CONST_INDICATOR_TO_SENSOR_FAILED:
      return {
        ...state,
        isAgricultureIndicatorToSensorListRequesting: false,
        AgricultureIndicatorToSensorListData: false,
        AgricultureIndicatorToSensorListDataError: action.payload.error
          ? false
          : action.payload.response,
        AgricultureIndicatorToSensorListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    case CROP_HEALTH_MONITORING_MAP_CONST.CROP_HEALTH_MONITORING_MAP_REQUEST:
      return {
        ...state,
        isGetAgricultureMapDataRequesting: true,
        getAgricultureMapData: false,
        getAgricultureMapDataError: false,
        getAgricultureMapDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_MAP_CONST.CROP_HEALTH_MONITORING_MAP_SUCCESS:
      return {
        ...state,
        isGetAgricultureMapDataRequesting: false,
        getAgricultureMapData: action.payload.response,
        getAgricultureMapDataError: false,
        getAgricultureMapDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_MAP_CONST.CROP_HEALTH_MONITORING_MAP_FAILED:
      return {
        ...state,
        isGetAgricultureMapDataRequesting: false,
        getAgricultureMapData: false,
        getAgricultureMapDataError: action.payload.error ? false : action.payload.response,
        getAgricultureMapDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    // for water resource
    case CROP_HEALTH_CONST_GET_ALL_INDICES.CROP_HEALTH_CONST_GET_ALL_INDICES_REQUEST:
      return {
        ...state,
        isGetAllIndicesRequesting: true,
        getAllIndicesData: false,
        getAllIndicesDataError: false,
        getAllIndicesDataNetworkError: false,
      };
    case CROP_HEALTH_CONST_GET_ALL_INDICES.CROP_HEALTH_CONST_GET_ALL_INDICES_SUCCESS:
      return {
        ...state,
        isGetAllIndicesRequesting: false,
        getAllIndicesData: action.payload.response,
        getAllIndicesDataError: false,
        getAllIndicesDataNetworkError: false,
      };
    case CROP_HEALTH_CONST_GET_ALL_INDICES.CROP_HEALTH_CONST_GET_ALL_INDICES_FAILED:
      return {
        ...state,
        isGetAllIndicesRequesting: false,
        getAllIndicesData: false,
        getAllIndicesDataError: action.payload.error ? false : action.payload.response,
        getAllIndicesDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case CROP_HEALTH_WATER_MONITORING_MAP_CONST.CROP_HEALTH_WATER_MONITORING_MAP_REQUEST:
      return {
        ...state,
        isGetWaterMapDataRequesting: true,
        getWaterMapData: false,
        getWaterMapDataError: false,
        getWaterMapDataNetworkError: false,
      };
    case CROP_HEALTH_WATER_MONITORING_MAP_CONST.CROP_HEALTH_WATER_MONITORING_MAP_SUCCESS:
      return {
        ...state,
        isGetWaterMapDataRequesting: false,
        getWaterMapData: action.payload.response,
        getWaterMapDataError: false,
        getWaterMapDataNetworkError: false,
      };
    case CROP_HEALTH_WATER_MONITORING_MAP_CONST.CROP_HEALTH_WATER_MONITORING_MAP_FAILED:
      return {
        ...state,
        isGetWaterMapDataRequesting: false,
        getWaterMapData: false,
        getWaterMapDataError: action.payload.error ? false : action.payload.response,
        getWaterMapDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case CROP_HEALTH_MONITORING_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_TIME_SERIES_REQUEST:
      return {
        ...state,
        isGetAgricultureTimeseriesRequesting: true,
        getAgriculturreTimeseriesData: false,
        getAgricultureTimeseriesDataError: false,
        getAgricultureTimeseriesDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_TIME_SERIES_SUCCESS:
      return {
        ...state,
        isGetAgricultureTimeseriesRequesting: false,
        getAgriculturreTimeseriesData: action.payload.response,
        getAgricultureTimeseriesDataError: false,
        getAgricultureTimeseriesDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_TIME_SERIES_FAILED:
      return {
        ...state,
        isGetAgricultureTimeseriesRequesting: false,
        getAgriculturreTimeseriesData: false,
        getAgricultureTimeseriesDataError: action.payload ? action.payload : false,
        getAgricultureTimeseriesDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    case CROP_HEALTH_MONITORING_WATER_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_WATER_TIME_SERIES_REQUEST:
      return {
        ...state,
        isGetWaterTimeseriesRequesting: true,
        getWaterTimeseriesData: false,
        getWaterTimeseriesDataError: false,
        getWaterTimeseriesDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_WATER_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_WATER_TIME_SERIES_SUCCESS:
      return {
        ...state,
        isGetWaterTimeseriesRequesting: false,
        getWaterTimeseriesData: action.payload.response,
        getWaterTimeseriesDataError: false,
        getWaterTimeseriesDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_WATER_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_WATER_TIME_SERIES_FAILED:
      return {
        ...state,
        isGetWaterTimeseriesRequesting: false,
        getWaterTimeseriesData: false,
        getWaterTimeseriesDataError: action.payload ? action.payload : false,
        getWaterTimeseriesDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };
    ///GLDAS

    case CROP_HEALTH_MONITORING_GLDAS_CONST.CROP_HEALTH_MONITORING_GLDAS_REQUEST:
      return {
        ...state,
        isCropGldasIndicesListRequesting: true,
        CropGldasIndicesListData: false,
        CropGldasIndicesListDataError: false,
        CropGldasIndicesListDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_GLDAS_CONST.CROP_HEALTH_MONITORING_GLDAS_SUCCESS:
      return {
        ...state,
        isCropGldasIndicesListRequesting: false,
        CropGldasIndicesListData: action.payload.response,
        CropGldasIndicesListDataError: false,
        CropGldasIndicesListDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_GLDAS_CONST.CROP_HEALTH_MONITORING_GLDAS_FAILED:
      return {
        ...state,
        isCropGldasIndicesListRequesting: false,
        CropGldasIndicesListData: false,
        CropGldasIndicesListDataError: action.payload.error ? false : action.payload.response,
        CropGldasIndicesListDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    //geoglows drainage
    case CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_REQUEST:
      return {
        ...state,
        isGetGeoglowsDrainageRequesting: true,
        getGeoglowsDrainageData: false,
        getGeoglowsDrainageDataError: false,
        getGeoglowsDrainageDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_SUCCESS:
      return {
        ...state,
        isGetGeoglowsDrainageRequesting: false,
        getGeoglowsDrainageData: action.payload.response,
        getGeoglowsDrainageDataError: false,
        getGeoglowsDrainageDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_FAILED:
      return {
        ...state,
        isGetGeoglowsDrainageRequesting: false,
        getGeoglowsDrainageData: false,
        getGeoglowsDrainageDataError: action.payload.error ? false : action.payload.response,
        getGeoglowsDrainageDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    //geoglows catchment
    case CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_REQUEST:
      return {
        ...state,
        isGetGeoglowsCatchmentRequesting: true,
        getGeoglowsCatchmentData: false,
        getGeoglowsCatchmentDataError: false,
        getGeoglowsCatchmentDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_SUCCESS:
      return {
        ...state,
        isGetGeoglowsCatchmentRequesting: false,
        getGeoglowsCatchmentData: action.payload.response,
        getGeoglowsCatchmentDataError: false,
        getGeoglowsCatchmentDataNetworkError: false,
      };
    case CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_FAILED:
      return {
        ...state,
        isGetGeoglowsCatchmentRequesting: false,
        getGeoglowsCatchmentData: false,
        getGeoglowsCatchmentDataError: action.payload.error ? false : action.payload.response,
        getGeoglowsCatchmentDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    //WATER INDICATOR TO SENSOR
    case WATER_CONST_INDICATOR_TO_SENSOR.WATER_CONST_INDICATOR_TO_SENSOR_REQUEST:
      return {
        ...state,
        isWaterIndicatorToSensorListRequesting: true,
        WaterIndicatorToSensorListData: false,
        WaterIndicatorToSensorListDataError: false,
        WaterIndicatorToSensorListDataNetworkError: false,
      };
    case WATER_CONST_INDICATOR_TO_SENSOR.WATER_CONST_INDICATOR_TO_SENSOR_SUCCESS:
      return {
        ...state,
        isWaterIndicatorToSensorListRequesting: false,
        WaterIndicatorToSensorListData: action.payload.response,
        WaterIndicatorToSensorListDataError: false,
        WaterIndicatorToSensorListDataNetworkError: false,
      };
    case WATER_CONST_INDICATOR_TO_SENSOR.WATER_CONST_INDICATOR_TO_SENSOR_FAILED:
      return {
        ...state,
        isWaterIndicatorToSensorListRequesting: false,
        WaterIndicatorToSensorListData: false,
        WaterIndicatorToSensorListDataError: action.payload.error ? false : action.payload.response,
        WaterIndicatorToSensorListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    default:
      return { ...state };
  }
};

export default CropHealthMonitoring;
