import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Row, Col } from 'reactstrap';
import _ from 'lodash';
import L from 'leaflet';
import '../../../assets/CSS/FarmTracker.css';
import { ReactComponent as FarmDefaultIcon } from '../../../assets/Images/farm_default_icon.svg';
import TemperatureSunIcon from '../../../assets/Images/WeatherIcn3.png';
import GeoTagImageDownloadIcon from '../../../assets/Images/downloadIcon.png';
import Chart from 'react-apexcharts';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import { RISK_ANALYTICS_MODULE } from '../../../Components/constants';

const FarmRiskDetails = (props) => {
  const {
    handleOpenCurrentWeatherSplitter,
    getByIdFarmData,
    handleOpenAnalyzeRiskSplitter,
    riskScoreAnalyticsInfo,
    handleOpenFullScreen,
    handleOpenCarousalModal,
    openWeatherChartSeriesData,
    setSelectedGeotaggedImage,
    geoTaggedImages,
    setGeoTaggedImages,
    riskPercentage,
    riskLevel,
  } = props;
  const [showData, setShowData] = useState({ data: false, link: false });

  const [precipitationValue, setPrecipitationValue] = useState(0);
  const [windSpeedValue, setWindSpeedValue] = useState(0);
  const [temperatureValue, setTemperatureValue] = useState(0);

  const { RiskAnalytics } = useSelector((state) => ({
    RiskAnalytics: state.RiskAnalytics,
  }));

  useEffect(() => {
    setPrecipitationValue(openWeatherChartSeriesData?.[0]?.rain);
    setTemperatureValue(openWeatherChartSeriesData?.[0]?.temp?.day);
    setWindSpeedValue(openWeatherChartSeriesData?.[0]?.wind_speed);
  }, [openWeatherChartSeriesData]);

  useEffect(() => {
    if (RiskAnalytics.riskScoreTimeSeriesDataRequesting) {
      setShowData({ data: false, link: false });
    } else if (RiskAnalytics.riskScoreTimeSeriesData) {
      if (RiskAnalytics.riskScoreTimeSeriesData?.data) {
        let { code } = RiskAnalytics.riskScoreTimeSeriesData?.data;
        if (code === 200) {
          let { result } = RiskAnalytics.riskScoreTimeSeriesData?.data;
          if (result) {
            setShowData({ data: true, link: true });
          }
        }
      }
    } else if (RiskAnalytics.riskScoreTimeSeriesDataError) {
      if (RiskAnalytics.riskScoreTimeSeriesDataError?.statusCode === 400) {
        setShowData({ data: false, link: true });
      }
    }
  }, [
    RiskAnalytics.riskScoreTimeSeriesDataRequesting,
    RiskAnalytics.riskScoreTimeSeriesData,
    RiskAnalytics.riskScoreTimeSeriesDataError,
  ]);

  useEffect(() => {
    if (getByIdFarmData?.farm?.[0]) {
      let arr = [];
        let image1 = returnImageUrl(getByIdFarmData?.farm[0]?.odk_image1)
        if(image1) arr.push({ index: 1, src: image1 || getByIdFarmData?.farm[0]?.odk_image1 })

        let image2 = returnImageUrl(getByIdFarmData?.farm[0]?.odk_image2)
        if(image2) arr.push({ index: 2, src: image2 || getByIdFarmData?.farm[0]?.odk_image2 })

        let image3 = returnImageUrl(getByIdFarmData?.farm[0]?.odk_image3)
        if(image3) arr.push({ index: 3, src: image3 || getByIdFarmData?.farm[0]?.odk_image3 })
           
      if (arr?.length) setGeoTaggedImages(arr);
    }
  }, [getByIdFarmData]);  

  const options = {
    labels: [''],
    stroke: {
      lineCap: 'round',
    },
    fill: {
      type: 'solid',
      colors: [
        function (riskPercentage) {
          if (riskPercentage !== undefined) {
            if (riskPercentage?.value <= 25) {
              return '#1A9850';
            } else if (riskPercentage?.value > 25 && riskPercentage?.value <= 50) {
              return '#FFFFBF';
            } else if (riskPercentage?.value > 50 && riskPercentage?.value <= 75) {
              return '#F46D43';
            } else if (riskPercentage?.value > 75 && riskPercentage?.value <= 100) {
              return '#D73027';
            }
          }
        },
      ],
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '40%',
        },

        dataLabels: {
          // showOn: 'always',
          // name: {
          //   offsetY: -5,
          //   show: false,
          //   color: '#888',
          //   fontSize: '13px',
          // },
          value: {
            offsetY: -13,
            color: '#0b0e12',
            fontSize: '13px',
            show: true,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    },
  };

  async function downloadImage(imageSrc, index) {
    let src = imageSrc.src; 
    const image = await fetch(src, {
      mode: 'no-cors',
    });
    const imageBlog = await image.blob();
    setTimeout(() => {
      const imageURL = URL.createObjectURL(imageBlog);
      const link = document.createElement('a');
      let img1 = imageURL.split('blob');
      link.href = imageURL;
      link.download = `${getByIdFarmData?.farm?.[0]?.name}_geotagimg${index + 1}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, index * 1000);
  }

  /* Download all images in 'imgs'.
   * Optionaly filter them by extension (e.g. "jpg") and/or
   * download the 'limit' first only  */
  function downloadAll(imgs) {
    for (var i = 0; i < imgs.length; i++) {
      var img = imgs[i];
      var download = `${getByIdFarmData?.farm?.[0]?.name}_geotagimg${i + 1}.png`;
      saveAs(img?.src, `geotagimg${i+1}.png`);
      // downloadImage(img, i);
    }
  }

  const handleImageClick = (index) => {
    setSelectedGeotaggedImage(index)
    handleOpenCarousalModal(true)
  }

  const handleGeoTagImages = async(e) => {
    let arr = [];
    let image1 = returnImageUrl(getByIdFarmData?.farm[0]?.odk_image1)
    if (image1) arr.push({ index: 1, src: image1 || getByIdFarmData?.farm[0]?.odk_image1 })

    let image2 = returnImageUrl(getByIdFarmData?.farm[0]?.odk_image2)
    if (image2) arr.push({ index: 2, src: image2 || getByIdFarmData?.farm[0]?.odk_image2 })

    let image3 = returnImageUrl(getByIdFarmData?.farm[0]?.odk_image3)
    if (image3) arr.push({ index: 3, src: image3 || getByIdFarmData?.farm[0]?.odk_image3 })
    let images = arr?.map((img, key) => {
      return {
        src: img.src,
      };
    });
    downloadAll(images);
  };

  const returnImageUrl = (image) => {
    if (
      image.trim().length !== 0
    ){
      if(image.includes('id'))
        return image.replace('open?', 'uc?export=download&') 
      else{
        const myArray = image.split("/");
        const imageId = myArray?.find((x,index)=> { 
        if(myArray[index-1] === "d" && myArray[index+1].includes("view")) 
         return x 
        })
        if(imageId) {
          let staticUrl = "https://drive.google.com/uc?export=download&id=url"
          return staticUrl.replace('url',imageId) 
        }
        else return image;
      }
    }
  }

  const renderArea = (farm) => {
    let farmCordinates = farm?.features?.[0]?.geometry?.coordinates?.[0];
    let latlngPoints = farmCordinates?.map((dataPoint) => {
      return L.latLng(dataPoint?.[1], dataPoint?.[0]);
    });
    let area = latlngPoints?.length ? L.GeometryUtil?.geodesicArea(latlngPoints) : '';
    return (area * 0.0001)?.toFixed(2); // converting square metres into hectares
  };

  return (
    <>
      <div>
        <Row>
          <Col>
            <Card className="farm-track-list-st mb-1 cursor-ponter">
              <CardBody>
                <Row className="mx-1 listing-st">
                  <Col sm="4" lg="3" md="4">
                    {' '}
                    {getByIdFarmData?.farm?.[0]?.profile_icon?.includes("data") ? (
                      <img
                        src={getByIdFarmData?.farm?.[0]?.profile_icon}
                        alt="profile icon"
                        width={50}
                        height={40}
                      />
                    ) : (
                      <FarmDefaultIcon />
                    )}
                  </Col>
                  <Col sm="6" lg="6" md="6" className="mx-3">
                    <Row>
                      {' '}
                      <Col> {_.capitalize(getByIdFarmData?.farm?.[0]?.name)}</Col>
                    </Row>
                    <Row className="date-st">
                      <Col>
                        {' '}
                        {moment(new Date(getByIdFarmData?.farm?.[0]?.updated_date))
                          .local()
                          .format('Do MMM YYYY')}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="lat-long-text">
          <span>Description : {getByIdFarmData?.farm?.[0]?.description ? getByIdFarmData?.farm?.[0]?.description : 'N/A' }</span>
        </div>
        {getByIdFarmData?.farm_attributes?.map((item) => {
          return (
            <>
              <div className="lat-long-text">
                <span>
                  {item.name} :{' '}
                  {item.name === 'Latitude' ? (
                    <span> {parseFloat(item?.value).toFixed(2)} &#176; N</span>
                  ) : item.name === 'Longitude' ? (
                    <span> {parseFloat(item?.value).toFixed(2)} &#176; E</span>
                  ) : item.name === 'Area' ? (
                    <span>{renderArea(getByIdFarmData?.farm?.[0]?.draw_farm)} ha</span>
                  ) : (
                    item?.value
                  )}
                </span>
              </div>
            </>
          );
        })}
        <div
          className="temperarture-card"
          style={{
            marginBottom: '10px',
          }}
        >
          <Card style={{ borderRadius: '18px' }} className="tmp-card1">
            <CardBody>
              <div className="temperarture-info">
                <div>
                  <img src={TemperatureSunIcon} alt="not-avaliable" className="m-1" />
                </div>
                <div className="">
                  {temperatureValue ? (
                    <h1 className="temperarture-card-text tem-num-style">
                      {temperatureValue.toFixed(2)} <span>&#176;</span>C
                    </h1>
                  ) : (
                    ''
                  )}
                  <span className="temperarture-card-text properties-text pb-2">
                    Precipitation :{' '}
                    <span className="properties-text">
                      <b>
                        {' '}
                        {precipitationValue ? (
                          <span>{precipitationValue.toFixed(2)} mm/h</span>
                        ) : (
                          'N/A'
                        )}{' '}
                      </b>
                    </span>
                  </span>
                  <br />
                  <span className="properties-text">
                    Wind Speed :{' '}
                    <span className="properties-text">
                      <b> {windSpeedValue ? `${windSpeedValue.toFixed(2)} m/s` : 'N/A'} </b>
                    </span>
                  </span>
                  <br />
                </div>
              </div>
              <div style={{ marginLeft: '56px' }}>
                <a
                  href="javascript:void(0)"
                  onClick={() => handleOpenCurrentWeatherSplitter(true)}
                  style={{ fontSize: '13px' }}
                >
                  Current Weather
                </a>
                &nbsp;&nbsp;&nbsp;
                <a
                  href="#forcast-container"
                  onClick={() => handleOpenFullScreen(true)}
                  style={{ fontSize: '13px' }}
                >
                  Forecast
                </a>
              </div>
            </CardBody>
          </Card>
        </div>
        {/* {!showData?.link && (
          <span
            className="risk-score-card-heading properties-text"
            style={{ paddingLeft: '17px', fontWeight: '700' }}
          >
            System is analyzing the risk, please wait...
          </span>
        )} */}
        <div
          className="temperarture-card"
          style={{
            marginBottom: '10px',
          }}
        >
          <Card style={{ borderRadius: '18px' }} className="tmp-card1">
            <CardBody
              className="risk-score-info "
              style={riskPercentage ? { justifyContent: 'flex-start' } : {}}
            >
              <div>
                {riskPercentage ? (
                  <Chart
                    height={120}
                    type="radialBar"
                    series={riskPercentage ? [riskPercentage.toFixed(1)] : [0]}
                    options={options}
                    className="chart-radial"
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="">
                {riskPercentage && riskLevel ? (
                  <>
                    <span className="risk-score-card-heading properties-text">
                    Risk Score : {riskPercentage ? <b>{riskPercentage?.toFixed(1)}</b> : 'N/A'}
                    </span>
                    <br />
                    <span className="risk-score-card-heading properties-text">
                      Risk Level : {riskLevel ? <b>{riskLevel}</b> : 'N/A'}
                    </span>
                  </>
                ):(
                  !showData?.link ? (
                    <span
                      className="risk-score-card-heading properties-text"
                      style={{ fontWeight: '700' }}
                    >
                       {/* <Spinner animation="border" variant="warning" size="sm"/> */}
                       <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant='warning'
                      />{' '}
                      System is analyzing the risk, please wait...
                    </span>
                  ) : (
                    <>
                    {!showData?.data && (
                      <>
                      <span className="risk-score-card-heading properties-text">
                      Risk Score : {'N/A'}
                      </span>
                      <br />
                      <span className="risk-score-card-heading properties-text">
                        Risk Level : {'N/A'}
                      </span>
                    </>
                    )}
                    </>
                  )
                )}
                

                <br />
                <span>
                <a
                  href="javascript:void(0)"
                  style={{ fontSize: '13px' }}
                  onClick={() => {
                    handleOpenAnalyzeRiskSplitter(true);
                  }}
                >
                  Analyze Risk
                </a>
                </span>
              </div>
            </CardBody>
          </Card>
        </div>

        <span
          style={{
            display: 'flex',
            justifycontent: 'space-between',
            alignItems: 'center',
            marginLeft: '13px',
          }}
          className="my-auto"
        >
          <p className="geo-tag-img1" style={{ marginTop: '10px', fontWeight: 'bold' }}>
            Geotagged Farm Images
          </p>{' '}
          {geoTaggedImages?.length ? (
            <img
              src={GeoTagImageDownloadIcon}
              alt="geoTaggedImageDownload"
              onClick={(e) => handleGeoTagImages(e)}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            ''
          )}
        </span>

        {geoTaggedImages.length >= 1 ? (
          geoTaggedImages.map((item, key) => {
            if (!_.isEmpty(item.src)) {
              return (
                <ul
                  className="geo-tag-images-list"
                  style={{ display: 'flex', flexDirection: 'column' }}
                  key={key}
                >
                  <li
                    className="geo-tag-images-list1"
                    style={{ display: 'flex' }}
                    onClick={() => handleImageClick(key)}
                  >
                    <span>
                      <img src={item?.src} alt="Image" className="large-image1" />
                      &nbsp;
                      {moment(new Date(getByIdFarmData?.farm?.[0]?.updated_date))
                        .local()
                        .format('Do MMM YYYY')}
                    </span>
                    <span className="large">
                      <img src={item?.src} className="large-image" alt="geoTaggedImage" />
                    </span>
                  </li>
                </ul>
              );
            }
          })
        ) : (
          <p style={{ marginLeft: '13px' }}>No geotagged images available.</p>
        )}
      </div>
    </>
  );
};

export default FarmRiskDetails;
