import {
  STATIC_RESOURCE_CONST,
  STATIC_RESOURCE_ALL_DATE_CONST,
  STATIC_RESOURCE_ADD_CONST,
  UPDATE_STATIC_RESOURCE_CONST,
  DELETE_STATIC_RESOURCE_CONST,
  STATIC_RESOURCE_YEAR_CONST
} from './staticResourceConst';

/**
 *  static resources actions
 */
export const staticResourceRequest = data => ({
  type: STATIC_RESOURCE_CONST.STATIC_RESOURCE_REQUEST,
  payload: data,
});

export const staticResourceSuccess = response => ({
  type: STATIC_RESOURCE_CONST.STATIC_RESOURCE_SUCCESS,
  payload: response,
});

export const staticResourceFailed = error => ({
  type: STATIC_RESOURCE_CONST.STATIC_RESOURCE_FAILED,
  payload: error,
});

export const staticResourceAllDateRequest = data => ({
  type: STATIC_RESOURCE_ALL_DATE_CONST.STATIC_RESOURCE_ALL_DATE_REQUEST,
  payload: data,
});

export const staticResourceAllDateSuccess = response => ({
  type: STATIC_RESOURCE_ALL_DATE_CONST.STATIC_RESOURCE_ALL_DATE_SUCCESS,
  payload: response,
});

export const staticResourceAllDateFailed = error => ({
  type: STATIC_RESOURCE_ALL_DATE_CONST.STATIC_RESOURCE_ALL_DATE_FAILED,
  payload: error,
});

export const staticResourceAddRequest = data => ({
  type: STATIC_RESOURCE_ADD_CONST.STATIC_RESOURCE_ADD_REQUEST,
  payload: data,
});

export const staticResourceAddSuccess = response => ({
  type: STATIC_RESOURCE_ADD_CONST.STATIC_RESOURCE_ADD_SUCCESS,
  payload: response,
});

export const staticResourceAddFailed = error => ({
  type: STATIC_RESOURCE_ADD_CONST.STATIC_RESOURCE_ADD_FAILED,
  payload: error,
});

export const updateStaticResourceRequest = data => ({
  type: UPDATE_STATIC_RESOURCE_CONST.UPDATE_STATIC_RESOURCE_REQUEST,
  payload: data,
});

export const updateStaticResourceSuccess = response => ({
  type: UPDATE_STATIC_RESOURCE_CONST.UPDATE_STATIC_RESOURCE_SUCCESS,
  payload: response,
});
export const updateStaticResourceFailed = (error) => ({
  type: UPDATE_STATIC_RESOURCE_CONST.UPDATE_STATIC_RESOURCE_FAILED,
  payload: error,
});

export const deleteStaticResourceRequest = id => ({
  type: DELETE_STATIC_RESOURCE_CONST.DELETE_STATIC_RESOURCE_REQUEST,
  payload: id,
});

export const deleteStaticResourceSuccess = response => ({
  type: DELETE_STATIC_RESOURCE_CONST.DELETE_STATIC_RESOURCE_SUCCESS,
  payload: response,
});

export const deleteStaticResourceFailed = error => ({
  type: DELETE_STATIC_RESOURCE_CONST.DELETE_STATIC_RESOURCE_FAILED,
  payload: error,
});


export const staticResourceYearRequest = (req_body) => ({
  type: STATIC_RESOURCE_YEAR_CONST.STATIC_RESOURCE_YEAR_REQUEST,
  payload: req_body,
});

export const staticResourceYearSuccess = (response) => ({
  type: STATIC_RESOURCE_YEAR_CONST.STATIC_RESOURCE_YEAR_SUCCESS,
  payload: response,
});

export const staticResourceYearFailed = (error) => ({
  type: STATIC_RESOURCE_YEAR_CONST.STATIC_RESOURCE_YEAR_FAILED,
  payload: error,
});