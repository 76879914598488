import React, { useEffect, useState } from 'react';
import { Card, CardBody, Form, Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import RightArrow from '../../assets/Images/back-button.svg';
import { t } from 'react-multi-lang';
import {
  renderReactSelectInputField,
  renderTextField,
} from '../../Components/common/renderInputField';
import {
  getBlockBySubDistrictRequest,
  getDistrictByStateRequest,
  getStateByCountryRequest,
  getStateByDistrictName,
  getSubDistrictByDistrictRequest,
} from '../../redux/location/actions';
import { DEFAULT_COUNTRY, LOCATION } from '../../Components/constants';
import _ from 'lodash';
import Loader from '../../Components/common/loader';
import {
  addFarmTrackerSuccess,
  addProjectRequest,
  deleteFarmTrackerSuccess,
  editFarmTrackerSuccess,
  editProjectRequest,
  editProjectSuccess,
  getAllByCriteriaFarmListSuccess,
  getFarmDetailsByIdSuccess,
  addProjectSuccess,
} from '../../redux/actions';
import { showError, showSuccess } from '../../Components/Notifications';
import {BsArrowLeft} from "react-icons/bs"
import { getToken } from '../../Components/common/utils';
import Cookies from 'js-cookie';

// Redux-form validate function
const validate = values => {
  const errors = {};
  const requiredFields = ['title', 'description', 'province', 'district', 'purpose'];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required.';
    }
  });
  if (!values.title) {
    errors.title = t('FARM_TRACKER.TITLE_REQUIRED');
  }
  if (!values.description) {
    errors.description = t('FARM_TRACKER.DESCRIPTION_REQUIRED');
  }
  if (!values.province) {
    errors.province = t('FARM_TRACKER.PROVINCE_REQUIRED');
  }
  if (!values.district) {
    errors.district = t('FARM_TRACKER.DISTRICT_REQUIRED');
  }
  // if (!values.sub_district) {
  //   errors.sub_district = t('FARM_TRACKER.SUB_DISTRICT_REQUIRED');
  // }
  // if (!values.block) {
  //   errors.block = t('FARM_TRACKER.BLOCK_REQUIRED');
  // }
  return errors;
};

const CreateFarm = props => {
  const { handleSubmit, reset, onSubmitting, pristine, submitting, invalid } = props;
  const dispatch = useDispatch();
  const paramData = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [cordinateAndZoom, setCordinateAndZoom] = useState({
    locationCordinates: [],
    zoom: '',
  });
  const [isCreateProject, setIsCreateProject] = useState(true);
  const [createProjectData, setCreateProjectData] = useState([]);

  const [latValues, setLatValues] = useState();
  const [lonValues, setLonValues] = useState();
  const [odkImages,setOdkImages] = useState([]);
  const [filterState,setFilterState] = useState('');
  const [filterDistrict,setFilterDistrict] = useState('')

  const { Location, FarmTracker } = useSelector(state => ({
    Location: state.Location,
    FarmTracker: state.FarmTracker,
  }));

  useEffect(() => {
    let search = window.location.search;
    let URLParams = new URLSearchParams(search);
    let latValue = URLParams.get('lat');
    let lonValue = URLParams.get('lon');
    let district = URLParams.get('district');

    if(district){
      let requestObj = { location_type: LOCATION.District, location_name: district };
      dispatch(getStateByDistrictName({ requestObj, isCompareLocation: false}))
    }
    setFilterDistrict(district)
    setLatValues(latValue);
    setLonValues(lonValue);
    let odkImages = Cookies.get('odk-images');
    if(odkImages){
      setOdkImages(odkImages);
    }
    if (!getToken()) {
      history.push('/login');
    } else {
      let requestObj = { location_type: LOCATION.State, parent_id: DEFAULT_COUNTRY.id };
      dispatch(getStateByCountryRequest({ requestObj, isCompareLocation: false }));
      let item = localStorage.getItem('CreateProjectData');
      let data = item?.length ? JSON.parse(item) : '';
      if (data) {
        setDistrictList(data?.districtList);
        setSubDistrictList(data?.subDistrictList);
        setBlockList(data?.blockList);
      }
      dispatch(addFarmTrackerSuccess({}));
      dispatch(editProjectSuccess({}));
      dispatch(getAllByCriteriaFarmListSuccess({}));
      dispatch(getFarmDetailsByIdSuccess({}));
      dispatch(deleteFarmTrackerSuccess({}));
      dispatch(editFarmTrackerSuccess({}));
    }
    return () => {
      dispatch(addProjectSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (localStorage?.getItem('CreateProjectData')) {
      let item = localStorage.getItem('CreateProjectData');
      let data = item?.length ? JSON.parse(item) : '';
      if (data) {
        let obj = data?.projectData;
        for (let [key, value] of Object.entries(obj)) {
          dispatch(change('CreateFarmTrackerForm', key, value));
        }
      }
    }
  }, [districtList, subDistrictList, blockList]);

  useEffect(() => {
    if (props.match.params.action) {
      let action = props.match.params.action;
      if (action === 'add') {
        setIsCreateProject(true);
      } else if (action === 'edit') {
        setIsCreateProject(false);
      }
    }
  }, [props.match.params.action]);

  useEffect(() => {
    if (paramData?.state) {
      let data = paramData?.state;
      if (data) {
        setCreateProjectData(data);
        dispatch(change('CreateFarmTrackerForm', 'title', data?.name));
        dispatch(change('CreateFarmTrackerForm', 'description', data?.description));
      }
    }
  }, [paramData]);

  useEffect(() => {
    if (Location.stateList) {
      let arr = [];
      let result = '';
      let config = Location?.stateList?.config;
      if (Location?.stateList?.data) result = Location?.stateList?.data?.result;
      if (result) {
        result.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            country_id: i.country_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        setProvinceList(arr);
      }
    }
  }, [Location.stateList]);

  useEffect(()=>{
    if(provinceList?.length){
      if(filterState){
        let stateData = provinceList?.find(item=> item.name === filterState)
        if(stateData){
          dispatch(change('CreateFarmTrackerForm', 'province', stateData))
        }
        else dispatch(change('CreateFarmTrackerForm', 'province', ''));
      }
      else dispatch(change('CreateFarmTrackerForm', 'province', ''));
    }
  },[provinceList,filterState])

  useEffect(() => {
    if (Location.stateByDistrictList && filterDistrict) {
      let result = '';
      if (Location?.stateByDistrictList?.data) result = Location?.stateByDistrictList?.data?.result;
      if (result) {
        let stateObj = result?.[0]
        setFilterState(stateObj?.state_name)
        handleProvinceChange({ ...stateObj, id: stateObj?.state_id})
      }
    }
  }, [Location.stateByDistrictList]);

  useEffect(() => {
    let arr = [];
    if (Location.districtList) {
      let result = '';
      let config = Location?.districtList?.config;
      if (Location?.districtList?.data) result = Location?.districtList?.data?.result;
      if (result) {
        result.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        setDistrictList(arr);
        if(filterDistrict){
          let districtData = arr.find(item=> item.name === filterDistrict)
          if(districtData){
            dispatch(change('CreateFarmTrackerForm', 'district', districtData))
          }
          else dispatch(change('CreateFarmTrackerForm', 'district', ''));
        }
        else dispatch(change('CreateFarmTrackerForm', 'district', ''));
        dispatch(change('CreateFarmTrackerForm', 'sub_district', ''));
        dispatch(change('CreateFarmTrackerForm', 'block', ''));
      }
    }
  }, [Location.districtList]);

  // useEffect(() => {
  //   let arr = [];
  //   if (Location.subDistrictList) {
  //     let result = '';
  //     let config = Location?.subDistrictList?.config;
  //     if (Location?.subDistrictList?.data) result = Location?.subDistrictList?.data?.result;
  //     if (result) {
  //       result.map(i => {
  //         return arr.push({
  //           id: i.id,
  //           name: i.name,
  //           value: i.name,
  //           label: i.name,
  //           state_id: i.state_id,
  //           latitude: i.latitude,
  //           longitude: i.longitude,
  //           zoom_level: i.zoom_level,
  //         });
  //       });
  //     }
  //     if (!config?.isCompareLocation) {
  //       setSubDistrictList(arr);
  //       dispatch(change('CreateFarmTrackerForm', 'sub_district', ''));
  //       dispatch(change('CreateFarmTrackerForm', 'block', ''));
  //     }
  //   }
  // }, [Location.subDistrictList]);

  // useEffect(() => {
  //   let arr = [];
  //   if (Location.blockList) {
  //     let result = '';
  //     let config = Location?.blockList?.config;
  //     if (Location?.blockList?.data) result = Location?.blockList?.data?.result;
  //     if (result) {
  //       result.map(i => {
  //         return arr.push({
  //           id: i.id,
  //           name: i.name,
  //           value: i.name,
  //           label: i.name,
  //           state_id: i.state_id,
  //           latitude: i.latitude,
  //           longitude: i.longitude,
  //           zoom_level: i.zoom_level,
  //         });
  //       });
  //     }
  //     if (!config?.isCompareLocation) {
  //       setBlockList(arr);
  //       dispatch(change('CreateFarmTrackerForm', 'block', ''));
  //     }
  //   }
  // }, [Location.blockList]);

  useEffect(() => {
    if (FarmTracker.addProjectRequesting) {
      setLoading(true);
    } else if (FarmTracker.addProjectData) {
      if (FarmTracker?.addProjectData?.data) {
        let { code, message, project_id } = FarmTracker?.addProjectData?.data;
          if (code === 200) {
          let data = { ...createProjectData };
          data.projectData.project_id = project_id;
          data.projectData.odk_images = odkImages;
          setLoading(false);
          showSuccess(message);
          // if (!_.isEmpty(latValues)&&!_.isEmpty(lonValues) && !_.isEmpty(filterDistrict)){
          //   history.push({
          //     pathname: '/farm-tracker',
          //     search: `lat=${latValues}&lon=${lonValues}&district=${filterDistrict}`,
          //     state: data,
          //   });          
          // }else{
          //   history.push({
          //     pathname: '/farm-tracker',
          //     state: data,
          //   });
          // }
          if (!_.isEmpty(latValues)&&!_.isEmpty(lonValues)){
            history.push({
              pathname: '/farm-tracker',
              search: `lat=${latValues}&lon=${lonValues}`,
              state: data,
            });          
          }else{
            history.push({
              pathname: '/farm-tracker',
              state: data,
            });
          }           
        }
      }
    } else if (FarmTracker.addProjectDataError) {
      if (FarmTracker?.addProjectDataError?.data) {
        let {
          response: { data },
        } = FarmTracker?.addProjectDataError?.data;
        if (data?.message && data?.code === 400) {
          showError(data?.message);
        }
        setLoading(false);
      }
    }
  }, [
    FarmTracker.addProjectRequesting,
    FarmTracker.addProjectData,
    FarmTracker.addProjectDataError,
  ]);

  useEffect(() => {
    if (FarmTracker.editProjectRequesting) {
      setLoading(true);
    } else if (FarmTracker.editProjectData) {
      if (FarmTracker?.editProjectData?.data) {
        let { code, result } = FarmTracker?.editProjectData?.data;
        if (code === 200) {
          setLoading(false);
          showSuccess(result);
          if (!_.isEmpty(latValues)&&!_.isEmpty(lonValues)){
            history.push({
              pathname: '/farms-list',
              search: `lat=${latValues}&lon=${lonValues}&district=${filterDistrict}`,
            });          
          }else{
          history.push({
            pathname: '/farms-list',
          });
        }
        }
      }
    } else if (FarmTracker.editProjectDataError) {
      if (FarmTracker?.editProjectDataError?.data) {
        let {
          response: { data },
        } = FarmTracker?.editProjectDataError?.data;
        if (data?.message && data?.code === 400) showError(data?.message);
        setLoading(false);
      }
    }
  }, [
    FarmTracker.editProjectRequesting,
    FarmTracker.editProjectData,
    FarmTracker.editProjectDataError,
  ]);

  useEffect(() => {
    if (!_.isEmpty(selectedOption)) {
      setCordinateAndZoom(prev => {
        return {
          ...prev,
          locationCordinates: [selectedOption?.latitude, selectedOption?.longitude],
          zoom: selectedOption?.zoom_level || cordinateAndZoom.zoom,
        };
      });
    }
  }, [selectedOption]);

  const removeFarmData = () => {
    localStorage.removeItem('CreateProjectData');
  };

  const handleProvinceChange = data => {
    let requestObj = { location_type: LOCATION.District, parent_id: data?.id };
    setSelectedOption(data);
    removeFarmData();
    dispatch(getDistrictByStateRequest({ requestObj, isCompareLocation: false }));
  };

  const handleDistrictChange = data => {
    let requestObj = { location_type: LOCATION.Sub_District, parent_id: data?.id };
    setSelectedOption(data);
    removeFarmData();
    // dispatch(getSubDistrictByDistrictRequest({ requestObj, isCompareLocation: false }));
  };

  // const handleSubDistrictChange = data => {
  //   let requestObj = { location_type: LOCATION.Block, parent_id: data?.id };
  //   setSelectedOption(data);
  //   setBlockList([]);
  //   removeFarmData();
  //   dispatch(getBlockBySubDistrictRequest({ requestObj, isCompareLocation: false }));
  // };

  // const handleBlockChange = data => {
  //   setSelectedOption(data);
  //   removeFarmData();
  // };

  const onFormSubmit = data => {
    let dataObj = {
      farmData: [],
      projectData: data,
      provinceList: provinceList,
      districtList: districtList,
      subDistrictList: subDistrictList,
      blockList: blockList,
      cordinateAndZoom: { locationCordinates: [data?.district?.latitude, data?.district?.longitude], zoom:data?.district?.zoom_level},
    };
    setCreateProjectData(dataObj);
    localStorage.setItem('CreateProjectData', JSON.stringify(dataObj));
    if (isCreateProject) {
      let requestObj = {
        name: data?.title,
        description: data?.description,
        state_id: data?.province?.id,
        district_id: data?.district?.id,
        // sub_district_id: data?.sub_district?.id,
        // block_id: data?.block?.id,
      };
      dispatch(addProjectRequest(requestObj));
    } else {
      let requestObj = {
        name: data?.title,
        description: data?.description,
        farm_tracker_project_id: createProjectData?.id,
      };
      setCreateProjectData(prev => {
        return {
          ...prev,
          name: data?.title,
          description: data?.description,
        };
      });
      dispatch(editProjectRequest(requestObj));
    }
  };

  const renderAPIParameterOptions = (list, value) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map(param => {
          let data = {
            ...param,
            value: param.name,
            label: `${param.name}`,
            id: value === 'country' ? param.name : param.id,
            cordinateAndZoom: {
              locationCordinates: [param?.latitude, param?.longitude],
              zoom: param?.zoom_level,
            },
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  return (
    <>
      {' '}
      <div className="farm-global-top">
        <div className="w-100">
          <Card
            className={isCreateProject ? 'create-farm-card' : 'create-farm-card edit-farm-card'}
            >
            <CardBody>
            {loading && <Loader />}
              <Row>
                <Col md="3" lg="3" sm="3" style={{height:"36px" ,}}>
                  <span style={{ float: 'left',marginLeft: '10px'  , borderRadius: "700px",
                        border: "1px solid #ada6a6" ,
                        height: "43px" , width:"43px" , fontSize:"25px" , color:"#595252" , fontWeight:"bold"}} className="list-tl-st">
                    {/* <img
                      src={RightArrow}
                      className="cursor-ponter"
                      onClick={() => history.goBack()}
                      alt='back-bottom'
                    /> */}
                    <BsArrowLeft  className="cursor-ponter"
                      onClick={() => history.goBack()} style={{margin:"auto" ,}} />

                      
                  </span>
                </Col>
                <Col md="6" lg="6" sm="6" className="card-shadow-api">
                  <Form onSubmit={handleSubmit(onFormSubmit)} className="mx-5 public-api-form">
                    <div className="pb-3">
                      <h5 className="farm-key-text">
                        {isCreateProject
                          ? t('FARM_TRACKER.CREATE_PROJECT')
                          : t('FARM_TRACKER.EDIT_PROJECT')}
                      </h5>
                    </div>
                    <FormGroup className="textOnInput">
                      <Label for="title">{t('FARM_TRACKER.PROJECT_TITLE')}</Label>
                      <Field
                        name="title"
                        type="text"
                        className="form-control farm-input-style-css"
                        component={renderTextField}
                        required
                      />
                    </FormGroup>
                    <FormGroup className="textOnInput">
                      <Label for="description">{t('FARM_TRACKER.PROJECT_DESCRIPTION')}</Label>
                      <Field
                        name="description"
                        type="textarea"
                        className="farm-input-style-css discription-textarea v-auto"
                        rows={4}
                        cols={4}
                        component={renderTextField}
                        required={true}
                      />
                    </FormGroup>
                    {isCreateProject ? (
                      <>
                        <FormGroup className="textOnInput">
                          <Label for="province">{t('FARM_TRACKER.PROVINCE')}</Label>
                          <Field
                            component={renderReactSelectInputField}
                            name="province"
                            className="farm-input-style-css"
                            placeholder=""
                            type="select"
                            onChange={handleProvinceChange}
                            required
                            isMulti={false}
                            isDisabled={filterState ? true : false}
                            options={renderAPIParameterOptions(provinceList)}
                          ></Field>
                        </FormGroup>
                        <FormGroup className="textOnInput">
                          <Label for="district">{t('FARM_TRACKER.DISTRICTS')}</Label>
                          <Field
                            component={renderReactSelectInputField}
                            name="district"
                            className="farm-input-style-css"
                            placeholder=""
                            type="select"
                            onChange={handleDistrictChange}
                            required
                            isMulti={false}
                            isDisabled={districtList?.length && filterDistrict ? true : false}
                            options={renderAPIParameterOptions(districtList)}
                          ></Field>
                        </FormGroup>
                        {/* <FormGroup className="textOnInput">
                          <Label for="sub_district" className="choose_label">
                            {t('FARM_TRACKER.SUB_DISTRICTS')}
                          </Label>
                          <Field
                            name="sub_district"
                            component={renderReactSelectInputField}
                            className="farm-input-style-css"
                            id="sub_district"
                            options={renderAPIParameterOptions(subDistrictList)}
                            onChange={handleSubDistrictChange}
                            placeholder=""
                            required
                            isMulti={false}
                          />
                        </FormGroup>
                        <FormGroup className="textOnInput">
                          <Label for="block" className="choose_label">
                            {t('FARM_TRACKER.BLOCKS')}
                          </Label>
                          <Field
                            name="block"
                            component={renderReactSelectInputField}
                            className="farm-input-style-css"
                            id="block"
                            options={renderAPIParameterOptions(blockList)}
                            onChange={handleBlockChange}
                            placeholder=""
                            required
                            isMulti={false}
                          />
                        </FormGroup> */}
                      </>
                    ) : (
                      ''
                    )}

                    <div style={{ float: 'right', margin: '10px' }}>
                      <button
                        type="button"
                        className="btn create-farm-bt-outline mr-3 p-3"
                        onClick={reset}
                        disabled={onSubmitting || pristine}
                        style={{ marginRight: '10px' }}
                      >
                        {t('FARM_TRACKER.CANCEL')}
                      </button>
                      <button
                        type="submit"
                        className="btn create-farm-bt mr-3 p-3"
                        disabled={submitting || pristine || invalid}
                      >
                        {isCreateProject ? t('FARM_TRACKER.CREATE') : t('FARM_TRACKER.UPDATE')}
                      </button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'CreateFarmTrackerForm',
  validate,
  enableReinitialize: true,
})(CreateFarm);
