import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const DeleteFarmModal = props => {
  const { isOpen, toggle, farmData } = props;

  const handleDeleteFarm = (id) =>{
    props.handleDeleteFarm(id)
  }
  return (
    <>
      <div>
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle} className="reason-modal-header">
            Delete Confirmation
          </ModalHeader>
          <ModalBody>
            <p className="confirmation-modal-text">Are you sure you want to delete the farm?</p>
          </ModalBody>
          <ModalFooter className="reason-modal">
            <Button color="secondary" className="cancel-button" onClick={toggle}>
              No
            </Button>{' '}
            <Button
              //loading={nextProps.isDeleteUserRequesting}
              color="primary"
              className="modal-reject-button"
              onClick={()=>handleDeleteFarm(farmData?.id)}
            >
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};
export default DeleteFarmModal;
