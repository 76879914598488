import {
    FIELD_DATA_LIST_CONST,
    FIELD_DATA_BY_ID_CONST,
    INSURANCE_COMPANY_LIST_CONST,
  FIELD_DATA_CONST,
    // FIELD_DATA_BY_ID_CONST,
    

    // SUBMIT_CONFIGURATION_CONST,

  } from './manageFieldDataConst';
  export const getFieldDataRequest = data => ({
    type: FIELD_DATA_CONST.FIELD_DATA_LIST_REQUEST,
    payload: data,
  });
  
  export const fieldDataListSuccess = response => ({
    type: FIELD_DATA_CONST.FIELD_DATA_LIST_SUCCESS,
    payload: response,
  });
  
  export const fieldDataListFailed = error => ({
    type: FIELD_DATA_CONST.FIELD_DATA_LIST_FAILED,
    payload: error,
  });
  
  export const fieldDataByIdRequest = data => ({
    type: FIELD_DATA_CONST.FIELD_DATA_BY_ID_REQUEST,
    payload: data,
  });
  
  export const fieldDataByIdSuccess = response => ({
    type: FIELD_DATA_CONST.FIELD_DATA_BY_ID_SUCCESS,
    payload: response,
  });
  
  export const fieldDataByIdFailed = error => ({
    type: FIELD_DATA_CONST.FIELD_DATA_BY_ID_FAILED,
    payload: error,
  });
  

  export const getInsuranceCompanyListRequest = data => ({
    type: FIELD_DATA_CONST.INSURANCE_COMPANY_LIST_REQUEST,
    payload: data,
  });
  
  export const insuranceCompanyListSuccess = response => ({
    type: FIELD_DATA_CONST.INSURANCE_COMPANY_LIST_SUCCESS,
    payload: response,
  });
  
  export const insuranceCompanyListFailed = error => ({
    type: FIELD_DATA_CONST.INSURANCE_COMPANY_LIST_FAILED,
    payload: error,
  });

  
  export const submitConfigurationRequest = data => ({
    type: FIELD_DATA_CONST.SUBMIT_CONFIGURATION_REQUEST,
    payload: data,
  });
  
  export const submitConfigurationSuccess = response => ({
    type: FIELD_DATA_CONST.SUBMIT_CONFIGURATION_SUCCESS,
    payload: response,
  });
  
  export const submitConfigurationFailed = error => ({
    type: FIELD_DATA_CONST.SUBMIT_CONFIGURATION_FAILED,
    payload: error,
  });


  export const inviteConfigurationRequest = data => ({
    type: FIELD_DATA_CONST.INVITE_CONFIGURATION_REQUEST,
    payload: data,
  });
  
  export const inviteConfigurationSuccess = response => ({
    type: FIELD_DATA_CONST.INVITE_CONFIGURATION_SUCCESS,
    payload: response,
  });
  
  export const inviteConfigurationFailed = error => ({
    type: FIELD_DATA_CONST.INVITE_CONFIGURATION_FAILED,
    payload: error,
  });

  

  export const componentsListRequest = data => ({
    type: FIELD_DATA_CONST.COMPONENTS_LIST_REQUEST,
    payload: data,
  });
  
  export const componentsListSuccess = response => ({
    type: FIELD_DATA_CONST.COMPONENTS_LIST_SUCCESS,
    payload: response,
  });
  
  export const componentsListFailed = error => ({
    type: FIELD_DATA_CONST.COMPONENTS_LIST_FAILED,
    payload: error,
  });


  

  export const componentsByIdRequest = data => ({
    type: FIELD_DATA_CONST.COMPONENT_BY_ID_REQUEST,
    payload: data,
  });
  
  export const componentsByIdSuccess = response => ({
    type: FIELD_DATA_CONST.COMPONENT_BY_ID_SUCCESS,
    payload: response,
  });
  
  export const componentsByIdFailed = error => ({
    type: FIELD_DATA_CONST.COMPONENT_BY_ID_FAILED,
    payload: error,
  });


  export const manageSurveyorsListRequest = data => ({
    type: FIELD_DATA_CONST.MANAGE_SURVEYORS_LIST_REQUEST,
    payload: data,
  });
  
  export const manageSurveyorsListSuccess = response => ({
    type: FIELD_DATA_CONST.MANAGE_SURVEYORS_LIST_SUCCESS,
    payload: response,
  });
  
  export const manageSurveyorsListFailed = error => ({
    type: FIELD_DATA_CONST.MANAGE_SURVEYORS_LIST_FAILED,
    payload: error,
  });


  export const deleteSurveyorsRequest = data => ({
    type: FIELD_DATA_CONST.DELETE_SURVEYORS_REQUEST,
    payload: data,
  });
  
  export const deleteSurveyorsSuccess = response => ({
    type: FIELD_DATA_CONST.DELETE_SURVEYORS_SUCCESS,
    payload: response,
  });
  
  export const deleteSurveyorsFailed = error => ({
    type: FIELD_DATA_CONST.DELETE_SURVEYORS_FAILED,
    payload: error,
  });

  export const surveyConfigurationRequest = data => ({
    type: FIELD_DATA_CONST.SURVEY_CONFIGURATION_REQUEST,
    payload: data,
  });
  
  export const surveyConfigurationSuccess = response => ({
    type: FIELD_DATA_CONST.SURVEY_CONFIGURATION_SUCCESS,
    payload: response,
  });
  
  export const surveyConfigurationFailed = error => ({
    type: FIELD_DATA_CONST.SURVEY_CONFIGURATION_FAILED,
    payload: error,
  });
