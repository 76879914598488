import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from 'react-multi-lang';
import _  from 'lodash';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import moment from 'moment';
import AgricultureMonitoring from './Agriculture';
import WaterMonitoring from './WaterResource';
import AgricultureIcon from '../../../assets/Images/agriculture.svg';
import WaterResourceIcon from '../../../assets/Images/water.svg';
import AgricultureColoredIcon from '../../../assets/Images/icons/agriculture-colored-icon.svg';
import WaterResourceColredIcon from '../../../assets/Images/icons/water-colored-icon.svg';
import { CardBody, Card, Button, NavItem, NavLink, Nav } from 'reactstrap';
import {
  getCountryRequest,
  getStateByCountryRequest,
  getDistrictByStateRequest,
} from '../../../redux/actions';
import {
  getCropIndicesListRequest,
  getCropGldasIndicesListRequest,
  getAllIndicesRequest,
  getAgricultureIndicatorToSensorListRequest,
  getWaterIndicatorToSensorListRequest,
} from '../../../redux/cropHealthMonitoring/cropHealthMonitoringAction';
import { LOCATION, DATE_FORMAT } from '../../../Components/constants';
import ReactTooltip from 'react-tooltip';
import TRANSLATIONS from '../../../translations/en.json';

const Filter = (props) => {
  const {
    activeTab,
    handleActiveTab,
    handleApplyLayers,
    temporalAggregation,
    handleTemporalAggregation,
    selectedParam,
    usePrevious,
    setSelectedCompareSensorParam,
    isUserClickedOnPlus,
    handleCompareSelectedItem,
    compareState,
    selectedCompareDistrict,
    setSelectedCompareIndicatorsParam,
    handleSelectedCropParam,
    handleSelectedCompareParam,
    precipitationThreshold,
    handlePrecipitationThreshold,
    compareDistrictList,
    country,
    defaultSelectedOption,
    countryList,
    stateList,
    handleSelectedItem,
    setCompareParam,
    state,
    districtList,
    selectedLocation,
    openAgricultureCollapse,
    setOpenAgricultureCollapse,
    handleParams,
    handleCompareParams,
    initialSelectedDate,
    handleStartDate,
    handleEndDate,
    handleUpdateChartDisplay,
    isCompare,
    handleCompareToggle,
    compareLocation,
    compareCountry,
    compareHandler,
    finalSelectedDate,
    handleFinalStartDate,
    compareStateList,
    compareParam,
    handleFinalEndDate,
    maxEndDate,
    compareError,
    updateGraph,
    applyLayer,
    openWaterCollapse,
    setOpenWaterCollapse,
    handleInitialCompareGldasId,
    isSelectedRegion,
    selectedDistrict,
    // raw data
    polarisation,
    orbitProperties,
    handleSummerStartDate,
    handleSummerEndDate,
    handleMonSoonStartDate,
    handleMonSoonEndDate,
    handleWinterStartDate,
    handleWinterEndDate,
    comparePolarisation,
    compareOrbitProperties,
    handleCompareSummerStartDate,
    handleCompareSummerEndDate,
    handleCompareMonSoonStartDate,
    handleCompareMonSoonEndDate,
    handleCompareWinterStartDate,
    handleCompareWinterEndDate,
    handleCompareToFalse,
    handleSelectedTab1SensorParam,
    selectedSensorParam,
    selectedCompareSensorParam,
    handleSelectedTab1CompareParam,
    activeTabSwitch,
    handleTab1,
    handleTab2,
    // indicators to sensors
    handleSelectedTab2IndicatorsParam,
    selectedIndicatorsParam,
    handleSelectedTab2CompareIndicatorsParam,
    selectedCompareIndicatorsParam,
    showRegionTooltip,
    showMapPreferenceTooltip,
    isCompareClicked,
    error,
    errorMessage,
    setError,
    setInvalidDate,
    isClickedOnPlus,
    setIsUserClickedOnPlus,
    tempState,
    setTempState,
    minError,
    setMinError,
    setIsClickedOnPlus,
    isPreviousCompareClick,
    setIsPreviousCompareClick,
    setCompareTempState,
    compareMinError,
    compareTempState,
    summerError,
    summerErrorMessage,
    monSoonError,
    monSoonErrorMessage,
    winterError,
    winterErrorMessage,
    compareSummerError,
    compareSummerErrorMessage,
    compareMonSoonError,
    compareMonSoonErrorMessage,
    compareWinterError,
    compareWinterErrorMessage,
    cropArea,
    compareCropArea,
    season,
    compareSeason,
    practise,
    comparePractise,
    seasonList,
    practiseList,
    cropYear,
    renderCropYearOptions,
    compareCropYear,
    renderCompareCropYearOptions,
    setSelectedParam,
    setInitialSelectedDate,
    setFinalSelectedDate
  } = props;
 
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedSensor, setSelectedSensor] = useState({});
  const [compareSelectedSensor, setCompareSelectedSensor] = useState({});
  const [sensorsList, setSensorsList] = useState([]);
  const [sensorsCompareList, setSensorsCompareList] = useState([]);

  const { isHideBar, setBar } = props;
  const { CropHealthMonitoring } = useSelector((state) => ({
    Location: state.Location,
    Weather: state.Weather,
    CropHealthMonitoring: state.CropHealthMonitoring,
    State: state,
  }));
  /// Agriculture indicesToSensorsList states
  const [indicatorsList, setIndicatorsList] = useState([]);
  const [indicatorsCompareList, setIndicatorsCompareList] = useState([]);
  const [selectedIndicators, setSelectedIndicators] = useState({});
  const [selectedCompareIndicators, setSelectedCompareIndicators] = useState({});

  // water tab states
  const [waterSensorsList, setWaterSensorsList] = useState([]);
  const [waterSensorsCompareList, setWaterSensorsCompareList] = useState([]);

  const [waterIndicatorsList, setWaterIndicatorsList] = useState([]);
  const [waterIndicatorsCompareList, setWaterIndicatorsCompareList] = useState([]);

  //for getting country
  useEffect(() => {
    dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    dispatch(getCropIndicesListRequest());
    dispatch(getAgricultureIndicatorToSensorListRequest());
    dispatch(getAllIndicesRequest({}));
    dispatch(getCropGldasIndicesListRequest({ module_name: 'crop' }));
    dispatch(getWaterIndicatorToSensorListRequest());
  }, []);
  /// for switching need to reset all
  useEffect(() => {
    setCompareSelectedSensor({});
    setSelectedCompareIndicators({});
  }, [isClickedOnPlus]);

  useEffect(() => {
     setSelectedSensor({});
     setSelectedIndicators({});
    setCompareSelectedSensor({});
    waterSensorsList?.map((i) => {
      i.checked = false;
    });
    waterSensorsCompareList?.map((i) => {
      i.checked = false;
    });
  }, [activeTab, activeTabSwitch]);

  /// Agriculture sensorsToIndicesListlist
  useEffect(() => {
    if (CropHealthMonitoring.CropIndicesListData) {
      const { data } = CropHealthMonitoring.CropIndicesListData;
      if (data) {
        let sensors_list = [];
        data?.map((i) => {
          if(i?.name !== 'Others') { //removing others from sensorsList
            if (i?.data[0]?.crop_monitor_sensor?.length) {
              sensors_list.push(i);
            }
          }
        });
        setSensorsList(sensors_list);
        setSensorsCompareList(sensors_list);
      }
    }
  }, [CropHealthMonitoring.CropIndicesListData]);

  // agriculture indicesToSensorsList
  useEffect(() => {
    if (CropHealthMonitoring.AgricultureIndicatorToSensorListData) {
      const { data } = CropHealthMonitoring.AgricultureIndicatorToSensorListData;
      if (data) {
        let indicator_list = [];
        data?.map((i) => {
          indicator_list.push(i);
        });
        // indicator_list?.map(i => {
        //   i?.data?.map((y, index) => {
        //     y.paramIndex = index;
        //   });
        // });
        setIndicatorsList(indicator_list);
        setIndicatorsCompareList(indicator_list);
      }
    }
  }, [CropHealthMonitoring.AgricultureIndicatorToSensorListData]);

  // water indicesToSensorsList
  useEffect(() => {
    if (CropHealthMonitoring.WaterIndicatorToSensorListData) {
      const { data } = CropHealthMonitoring.WaterIndicatorToSensorListData;
      if (data) {
        let indicator_list = [];
        let compare_indicators_list = [];
        data?.map((i) => {
          indicator_list.push(i);
        });
        setWaterIndicatorsList(indicator_list);
        data?.map((i) => {
          if (i?.category !== 'Forecast') {
            compare_indicators_list.push(i);
          }
        });
        setWaterIndicatorsCompareList(compare_indicators_list);
      }
    }
  }, [CropHealthMonitoring.WaterIndicatorToSensorListData]);

  /// water sensors to indicators list
  useEffect(() => {
    if (CropHealthMonitoring.getAllIndicesData) {
      const { data } = CropHealthMonitoring.getAllIndicesData;
      if (data) {
        let sensors_list = [];
        let compare_sensors_list = [];
        data?.map((i) => {
          if (i?.data[0]?.water_resource_sensor?.length) {
            sensors_list.push(i);
          }
        });
        setWaterSensorsList(sensors_list);
        data?.map((i) => {
          if (i?.name !== 'GeoGlows' && i?.data[0]?.water_resource_sensor?.length) {
            compare_sensors_list.push(i);
          }
        });
        setWaterSensorsCompareList(compare_sensors_list);
      }
    }
  }, [CropHealthMonitoring.getAllIndicesData]);

  useEffect(() => {
    let arr = [];
    arr.push(selectedParam);
    props.handleSelectedParam(selectedParam);
  }, [selectedParam]);

  useEffect(() => {
    if (selectedLocation.country && selectedLocation.country !== 'select') {
      dispatch(
        getStateByCountryRequest({
          requestObj: {
            location_type: LOCATION.State,
            parent_id: selectedLocation.country.id,
          },
          isCompareLocation: false,
        }),
      );
    }
  }, [selectedLocation.country]);

  //for getting state by country-id
  useEffect(() => {
    if (!_.isEmpty(compareLocation.country?.id) && compareLocation.country.id !== 'select') {
      dispatch(
        getStateByCountryRequest({
          requestObj: {
            location_type: LOCATION.State,
            parent_id: compareLocation.country?.id,
          },
          isCompareLocation: true,
        }),
      );
    }
  }, [compareLocation.country]);

  useEffect(() => {
    if (!_.isEmpty(compareLocation.state?.id) && compareLocation.state.id !== 'select') {
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: compareLocation.state?.id,
          },
          isCompareLocation: true,
        }),
      );
    }
  }, [compareLocation.state]);

  useEffect(() => {
    if (selectedLocation.state) {
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: selectedLocation.state.id,
          },
          isCompareLocation: false,
        }),
      );
    }
  }, [selectedLocation.state]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      // setActiveTab(tab);
      handleActiveTab(tab);
      if (tab === 'agriculture') {
        history.push(`/crop-health-monitoring?key=agriculture`);
      } else if (tab === 'water') {
        history.push(`/crop-health-water?key=water`);
      }
    }
  };

  const handleSelectedSensor = (value) => {
    handleSelectedCropParam('');
    handleSelectedTab1SensorParam(value);
    setSelectedSensor(value);
  };

  const handleCompareSelectedSensor = (value) => {
    //handleCompareSelectedCropParam("")
    handleSelectedTab1CompareParam(value);
    handleSelectedCompareParam('');
    setCompareSelectedSensor(value);
  };
  // agriculture indicators to sensors
  const handleSelectedIndicators = (value) => {
    // if(value?.name === 'Crop Wise Production') {
    //   handleSelectedCropParam(value?.data[0]);
    // } else {
      handleSelectedCropParam('');
    // }
    // handleSelectedCropParam('');
    handleSelectedTab2IndicatorsParam(value);
    setSelectedIndicators(value);
  };

  const handleSelectedCompareIndicators = (value) => {
    // if(value?.name === 'Crop Wise Production') {
    //   handleSelectedCompareParam(value?.data[0]);
    // } else {
      handleSelectedCompareParam('');
    // }
    // handleSelectedCompareParam('');
    handleSelectedTab2CompareIndicatorsParam(value);
    setSelectedCompareIndicators(value);
  };

  // useEffect(() => {
  //   if (Object.keys(selectedOption).length) {
  //     const { latitude, longitude, zoom_level } = selectedOption;
  //     return props.handleCordinates(
  //       [
  //         latitude ? latitude : SRILANKA_COORDINATE.lat,
  //         longitude ? longitude : SRILANKA_COORDINATE.long,
  //       ],
  //       zoom_level ? zoom_level : 4.5,
  //     );
  //   }
  //   else {
  //     return props.handleCordinates([SRILANKA_COORDINATE.lat, SRILANKA_COORDINATE.long], 4.5);
  //   }
  // }, [selectedOption]);

  const handleInitialDate = (type) => {
    return moment(initialSelectedDate?.[type]).format(DATE_FORMAT);
  };

  const handleFinalDate = (type) => {
    return moment(finalSelectedDate?.[type]).format(DATE_FORMAT);
  };
  
  return (
    <>
      <div className="global-top">
        <div className="w-100">
          {/* {isLoading && <Loader />} */}
          <div className={isHideBar ? 'sideBar col-lg-2' : 'expand-side-bar col-lg-2'}>
            {' '}
            {isHideBar ? (
              <div className="expand-bar">
                <span className="cursor-pointer navyBlueColor" onClick={() => setBar(!isHideBar)}>
                  {t('CLIMATE_WEATHER_FILTER.EXPAND_BAR')}
                  <span>
                    <MdKeyboardArrowLeft
                      className="hide-icon expand-margin"
                      size={25}
                    />
                  </span>
                </span>
              </div>
            ) : (
              <div className="filter-sidebar-section">
                <div className="filter-div">
                  <Card className="card-style">
                    <CardBody>
                      <form id="climate-weather-filter-form" className="filter-control-dp">
                        {' '}
                        <div className="search-container">
                          <div className="d-flex justify-content-end navyBlueColor mb-3">
                            {' '}
                              <span className='cursor-ponter' onClick={() => setBar(!isHideBar)}>
                                <span className="hidebar-show">
                                  {t('CLIMATE_WEATHER_FILTER.HIDE_BAR')}
                                </span>{' '}
                                <span>
                                  <MdKeyboardArrowRight
                                    className="hide-icon hide-margin"
                                    size={25}
                                  />
                                </span>
                              </span>
                          </div>{' '}
                          <div className="agriculture-water">
                            <Nav tabs>
                              <NavItem>
                                <NavLink
                                  className={
                                    activeTab === t('CROP_HEALTH_FILTER.AGRICULTURE')
                                      ? 'active'
                                      : ''
                                  }
                                  onClick={() => toggle(t('CROP_HEALTH_FILTER.AGRICULTURE'))}
                                >
                                  {activeTab === 'agriculture' ? (
                                    <img
                                      src={AgricultureColoredIcon}
                                      style={{ paddingRight: '6px' }}
                                    />
                                  ) : (
                                    <img src={AgricultureIcon} style={{ paddingRight: '6px' }} />
                                  )}
                                  {t('CROP_HEALTH_FILTER.AGRICULTURE_HEADING')}
                                </NavLink>
                              </NavItem>
                              {/* )} */}
                              <NavItem>
                                <NavLink
                                  className={
                                    activeTab === t('CROP_HEALTH_FILTER.WATER') ? 'active' : ''
                                  }
                                  onClick={() => toggle(t('CROP_HEALTH_FILTER.WATER'))}
                                >
                                  {activeTab === 'water' ? (
                                    <img
                                      src={WaterResourceColredIcon}
                                      style={{ paddingRight: '6px' }}
                                    />
                                  ) : (
                                    <img src={WaterResourceIcon} style={{ paddingRight: '6px' }} />
                                  )}
                                  {t('CROP_HEALTH_FILTER.WATER_HEADING')}
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                          
                          <div className="mt-2 mb-2">
                            {activeTab === 'agriculture' ? (
                              <AgricultureMonitoring
                                compareError={compareError}
                                compareParam={compareParam}
                                finalSelectedDate={finalSelectedDate}
                                handleFinalStartDate={handleFinalStartDate}
                                handleFinalEndDate={handleFinalEndDate}
                                maxEndDate={maxEndDate}
                                countryList={countryList}
                                defaultSelectedOption={defaultSelectedOption}
                                stateList={stateList}
                                handleSelectedItem={handleSelectedItem}
                                setRawDataRenderState={props.setRawDataRenderState}
                                setCompareRawDataRenderState={props.setCompareRawDataRenderState}
                                setRgbVisualizationRenderState={props.setRgbVisualizationRenderState}
                                setCompareRgbVisualizationRenderState={props.setCompareRgbVisualizationRenderState}
                                rawDataRenderState={props.rawDataRenderState}
                                compareRawDataRenderState={props.compareRawDataRenderState}
                                rgbVisualizationRenderState={props.rgbVisualizationRenderState}
                                compareRgbVisualizationRenderState={props.compareRgbVisualizationRenderState}
                                setIsUserClickedOnPlus={setIsUserClickedOnPlus}
                                isUserClickedOnPlus={isUserClickedOnPlus}
                                state={state}
                                previousActiveTab={props.previousActiveTab}
                                previousTabSwitch={props.previousTabSwitch}
                                country={country}
                                districtList={districtList}
                                sensorsList={sensorsList}
                                setSensorsList={setSensorsList}
                                sensorsCompareList={sensorsCompareList}
                                setSensorsCompareList={setSensorsCompareList}
                                compareHandler={compareHandler}
                                handleSelectedSensor={handleSelectedSensor}
                                handleCompareSelectedSensor={handleCompareSelectedSensor}
                                selectedSensor={selectedSensor}
                                compareSelectedSensor={compareSelectedSensor}
                                handleSelectedCropParam={handleSelectedCropParam}
                                handleSelectedCompareParam={handleSelectedCompareParam}
                                temporalAggregation={temporalAggregation}
                                handleTemporalAggregation={handleTemporalAggregation}
                                handlePrecipitationThreshold={handlePrecipitationThreshold}
                                setCategoryWiseCompareAgricultureData={props.setCategoryWiseCompareAgricultureData}
                                categoryWiseCompareAgricultureData={props.categoryWiseCompareAgricultureData}
                                precipitationThreshold={precipitationThreshold}
                                handleParams={handleParams}
                                usePrevious={usePrevious}
                                handleCompareParams={handleCompareParams}
                                initialSelectedDate={initialSelectedDate}
                                handleStartDate={handleStartDate}
                                handleEndDate={handleEndDate}
                                selectedParam={selectedParam}
                                isCompare={isCompare}
                                handleCompareSelectedItem={handleCompareSelectedItem}
                                handleCompareToggle={handleCompareToggle}
                                compareLocation={compareLocation}
                                compareCountry={compareCountry}
                                compareStateList={compareStateList}
                                compareState={compareState}
                                compareDistrictList={compareDistrictList}
                                selectedCompareDistrict={selectedCompareDistrict}
                                selectedLocation={selectedLocation}
                                handleInitialCompareGldasId={handleInitialCompareGldasId}
                                isSelectedRegion={isSelectedRegion}
                                selectedDistrict={selectedDistrict}
                                polarisation={polarisation}
                                orbitProperties={orbitProperties}
                                selectedSensorParam={selectedSensorParam}
                                selectedCompareSensorParam={selectedCompareSensorParam}
                                activeTabSwitch={activeTabSwitch}
                                openAgricultureCollapse={openAgricultureCollapse}
                                setOpenAgricultureCollapse={setOpenAgricultureCollapse}
                                handleTab1={handleTab1}
                                handleTab2={handleTab2}
                                // indicators to sensors
                                indicatorsList={indicatorsList}
                                setIndicatorsList={setIndicatorsList}
                                selectedIndicators={selectedIndicators}
                                handleSelectedIndicators={handleSelectedIndicators}
                                selectedIndicatorsParam={selectedIndicatorsParam}
                                setSelectedIndicators={setSelectedIndicators}
                                setSelectedSensor={setSelectedSensor}
                                setSelectedCompareSensorParam={setSelectedCompareSensorParam}
                                setSelectedCompareIndicatorsParam={setSelectedCompareIndicatorsParam}
                                activeTab={activeTab}
                                indicatorsCompareList={indicatorsCompareList}
                                selectedCompareIndicators={selectedCompareIndicators}
                                setCompareParam={setCompareParam}
                                setSelectedCompareIndicators={setSelectedCompareIndicators}
                                handleSelectedCompareIndicators={handleSelectedCompareIndicators}
                                selectedCompareIndicatorsParam={selectedCompareIndicatorsParam}
                                error={error}
                                errorMessage={errorMessage}
                                setError={setError}
                                setInvalidDate={setInvalidDate}
                                isClickedOnPlus={isClickedOnPlus}
                                tempState={tempState}
                                setTempState={setTempState}
                                minError={minError}
                                setMinError={setMinError}
                                isPreviousCompareClick={isPreviousCompareClick}
                                setIsPreviousCompareClick={setIsPreviousCompareClick}
                                setIsClickedOnPlus={setIsClickedOnPlus}
                                setCompareTempState={setCompareTempState}
                                compareMinError={compareMinError}
                                compareTempState={compareTempState}
                                cropWiseProRenderState={props.cropWiseProRenderState}
                                setCropWiseProRenderState={props.setCropWiseProRenderState}
                                compareCropWiseProRenderState={props.compareCropWiseProRenderState}
                                setCompareCropWiseProRenderState={props.setCompareCropWiseProRenderState}
                                //cropWise production
                                cropArea={cropArea}
                                compareCropArea={compareCropArea}
                                season={season}
                                compareSeason={compareSeason}
                                practise={practise}
                                comparePractise={comparePractise}
                                seasonList={seasonList}
                                practiseList={practiseList}
                                cropYear={cropYear}
                                renderCropYearOptions={renderCropYearOptions}
                                compareCropYear={compareCropYear}
                                renderCompareCropYearOptions={renderCompareCropYearOptions}
                                setSelectedParam={setSelectedParam}
                                setInitialSelectedDate={setInitialSelectedDate}
                                setFinalSelectedDate={setFinalSelectedDate}
                              />
                            ) : (
                              <WaterMonitoring
                                countryList={countryList}
                                defaultSelectedOption={defaultSelectedOption}
                                stateList={stateList}
                                initialRawDataSelectedDate={props.initialRawDataSelectedDate}
                                handleSelectedItem={handleSelectedItem}
                                setIsUserClickedOnPlus={setIsUserClickedOnPlus}
                                isUserClickedOnPlus={isUserClickedOnPlus}
                                isPreviousCompareClick={isPreviousCompareClick}
                                setIsPreviousCompareClick={setIsPreviousCompareClick}
                                initialCompareRawDataSelectedDate={props.initialCompareRawDataSelectedDate}
                                previousClickedOnPlus={props.previousClickedOnPlus}
                                state={state}
                                country={country}
                                usePrevious={usePrevious}
                                districtList={districtList}
                                setRawDataRenderState={props.setRawDataRenderState}
                                setCompareRawDataRenderState={props.setCompareRawDataRenderState}
                                setRgbVisualizationRenderState={props.setRgbVisualizationRenderState}
                                setCompareRgbVisualizationRenderState={props.setCompareRgbVisualizationRenderState}
                                rawDataRenderState={props.rawDataRenderState}
                                compareRawDataRenderState={props.compareRawDataRenderState}
                                rgbVisualizationRenderState={props.rgbVisualizationRenderState}
                                compareRgbVisualizationRenderState={props.compareRgbVisualizationRenderState}
                                previousActiveTab={props.previousActiveTab}
                                previousTabSwitch={props.previousTabSwitch}
                                setCategoryWiseCompareWaterData={props.setCategoryWiseCompareWaterData}
                                categoryWiseCompareWaterData={props.categoryWiseCompareWaterData}
                                initialSelectedDate={initialSelectedDate}
                                openWaterCollapse={openWaterCollapse}
                                setOpenWaterCollapse={setOpenWaterCollapse}
                                handleStartDate={handleStartDate}
                                temporalAggregation={temporalAggregation}
                                handleTemporalAggregation={handleTemporalAggregation}
                                precipitationThreshold={precipitationThreshold}
                                handlePrecipitationThreshold={handlePrecipitationThreshold}
                                handleSelectedCropParam={handleSelectedCropParam}
                                selectedParam={selectedParam}
                                handleParams={handleParams}
                                finalSelectedDate={finalSelectedDate}
                                handleFinalStartDate={handleFinalStartDate}
                                handleFinalEndDate={handleFinalEndDate}
                                selectedLocation={selectedLocation}
                                handleEndDate={handleEndDate}
                                handleCompareToggle={handleCompareToggle}
                                isCompare={isCompare}
                                compareHandler={compareHandler}
                                setCompareParam={setCompareParam}
                                handleCompareParams={handleCompareParams}
                                handleSelectedCompareParam={handleSelectedCompareParam}
                                compareParam={compareParam}
                                maxEndDate={maxEndDate}
                                compareState={compareState}
                                compareCountry={compareCountry}
                                selectedCompareDistrict={selectedCompareDistrict}
                                compareStateList={compareStateList}
                                handleCompareSelectedItem={handleCompareSelectedItem}
                                compareDistrictList={compareDistrictList}
                                isSelectedRegion={isSelectedRegion}
                                selectedDistrict={selectedDistrict}
                                handleCompareToFalse={handleCompareToFalse}
                                activeTabSwitch={activeTabSwitch}
                                handleTab1={handleTab1}
                                handleTab2={handleTab2}
                                waterSensorsList={waterSensorsList}
                                setWaterSensorsList={setWaterSensorsList}
                                waterSensorsCompareList={waterSensorsCompareList}
                                setWaterSensorsCompareList={setWaterSensorsCompareList}
                                handleSelectedSensor={handleSelectedSensor}
                                setSelectedCompareSensorParam={setSelectedCompareSensorParam}
                                setSelectedCompareIndicatorsParam={setSelectedCompareIndicatorsParam}
                                selectedSensorParam={selectedSensorParam}
                                activeTab={activeTab}
                                setSelectedSensor={setSelectedSensor}
                                selectedSensor={selectedSensor}
                                handleCompareSelectedSensor={handleCompareSelectedSensor}
                                selectedCompareSensorParam={selectedCompareSensorParam}
                                compareSelectedSensor={compareSelectedSensor}
                                waterIndicatorsList={waterIndicatorsList}
                                setWaterIndicatorsList={setWaterIndicatorsList}
                                handleSelectedIndicators={handleSelectedIndicators}
                                selectedIndicators={selectedIndicators}
                                setSelectedIndicators={setSelectedIndicators}
                                selectedIndicatorsParam={selectedIndicatorsParam}
                                waterIndicatorsCompareList={waterIndicatorsCompareList}
                                handleSelectedCompareIndicators={handleSelectedCompareIndicators}
                                selectedCompareIndicatorsParam={selectedCompareIndicatorsParam}
                                selectedCompareIndicators={selectedCompareIndicators}
                                polarisation={polarisation}
                                orbitProperties={orbitProperties}
                                handleSummerStartDate={handleSummerStartDate}
                                handleSummerEndDate={handleSummerEndDate}
                                handleMonSoonStartDate={handleMonSoonStartDate}
                                handleMonSoonEndDate={handleMonSoonEndDate}
                                handleWinterStartDate={handleWinterStartDate}
                                handleWinterEndDate={handleWinterEndDate}
                                comparePolarisation={comparePolarisation}
                                compareOrbitProperties={compareOrbitProperties}
                                handleCompareSummerStartDate={handleCompareSummerStartDate}
                                handleCompareSummerEndDate={handleCompareSummerEndDate}
                                handleCompareMonSoonStartDate={handleCompareMonSoonStartDate}
                                handleCompareMonSoonEndDate={handleCompareMonSoonEndDate}
                                handleCompareWinterStartDate={handleCompareWinterStartDate}
                                handleCompareWinterEndDate={handleCompareWinterEndDate}
                                setSelectedCompareIndicators={setSelectedCompareIndicators}
                                handleInitialDate={handleInitialDate}
                                handleFinalDate={handleFinalDate}
                                error={error}
                                errorMessage={errorMessage}
                                setError={setError}
                                setInvalidDate={setInvalidDate}
                                compareError={compareError}
                                isClickedOnPlus={isClickedOnPlus}
                                tempState={tempState}
                                setTempState={setTempState}
                                minError={minError}
                                setMinError={setMinError}
                                setCompareTempState={setCompareTempState}
                                compareMinError={compareMinError}
                                compareTempState={compareTempState}
                                summerError={summerError}
                                summerErrorMessage={summerErrorMessage}
                                monSoonError={monSoonError}
                                monSoonErrorMessage={monSoonErrorMessage}
                                winterError={winterError}
                                winterErrorMessage={winterErrorMessage}
                                compareSummerError={compareSummerError}
                                compareSummerErrorMessage={compareSummerErrorMessage}
                                compareMonSoonError={compareMonSoonError}
                                compareMonSoonErrorMessage={compareMonSoonErrorMessage}
                                compareWinterError={compareWinterError}
                                compareWinterErrorMessage={compareWinterErrorMessage}
                                setInitialSelectedDate={setInitialSelectedDate}
                                setFinalSelectedDate={setFinalSelectedDate}
                              />
                            )}
                          </div>
                          <div className="mx-3 mt-1 mb-2 filter-submit">
                            <div
                              className="apply-layers"
                              id="apply_layers"
                              data-tip
                              data-for="apply_layers"
                            >
                              <Button
                                className="navyBlueBgColor m-auto"
                                onClick={() => handleApplyLayers(true)}
                                disabled={!applyLayer || showMapPreferenceTooltip}
                              >
                                Apply Layers
                              </Button>
                            </div>
                            {showRegionTooltip && isSelectedRegion?.region?.length ? (
                                <ReactTooltip
                                  id="apply_layers"
                                  effect="solid"
                                  place="right"
                                  type="info" 
                                  className="apply-layer-tooltip-st"
                                >
                                   {t('FILTER.PLEASE_REMOVE_MARKERS')}
                                  {/* {TRANSLATIONS.FILTER.PLEASE_REMOVE_MARKERS} */}
                                </ReactTooltip>
                              ) : (
                                ''
                              )}
                              {(!isSelectedRegion?.region?.length && !isCompareClicked && !showMapPreferenceTooltip &&
                                !applyLayer &&
                                (selectedParam?.slug === 'cwp' ||
                                  selectedParam?.slug === 'sentinel1_raw_data' ||
                                  selectedParam?.slug === 'sentinel1_rgb_visualization')) ||
                              (!isSelectedRegion?.region?.length && !applyLayer && isCompare?.[0]?.selected && isCompareClicked && !showMapPreferenceTooltip &&
                                (compareParam?.slug === 'cwp' ||
                                  compareParam?.slug === 'sentinel1_raw_data' ||
                                  compareParam?.slug === 'sentinel1_rgb_visualization' || 
                                  selectedParam?.slug === 'cwp' ||
                                  selectedParam?.slug === 'sentinel1_raw_data' ||
                                  selectedParam?.slug === 'sentinel1_rgb_visualization')) ? (
                                <ReactTooltip
                                  id="apply_layers"
                                  effect="solid"
                                  place="top"
                                  type="info"
                                  className="sar-sar-rgb-apply-layer-tooltip-st"
                                >
                                  {TRANSLATIONS.FILTER.EMPTY_FIELDS}
                                </ReactTooltip>
                              ) : (
                                ''
                              )}
                                 {showMapPreferenceTooltip && !showRegionTooltip ? (
                                <ReactTooltip
                                  id="apply_layers"
                                  effect="solid"
                                  place="right"
                                  type="info"
                                  className="apply-layer-tooltip-st"
                                >
                                  {TRANSLATIONS.FILTER.PLEASE_REMOVE_MAP_PREFERENCE_LAYER}
                                </ReactTooltip>
                              ) : (
                                ''
                              )}
                            <div className="update-graph mt-3">
                              <div id="view_layers" data-tip data-for="view_layers">
                                <Button
                                  outline
                                  className="navyBlueBgColor-outline m-auto"
                                  onClick={handleUpdateChartDisplay}
                                  disabled={!updateGraph || temporalAggregation?.label === 'Median'}
                                >
                                  View Graph
                                </Button>
                              </div>
                              
                              {(((
                                selectedParam?.slug === 'discharge_forecast' ||
                              selectedParam?.slug === 'sentinel1_raw_data' ||
                              selectedParam?.slug === 'cwp'  ||
                              selectedParam?.slug === 'sentinel1_rgb_visualization') && !isCompareClicked) ||
                              (!isSelectedRegion?.region?.length && isCompareClicked && isCompare?.[1]?.selected &&
                              (compareParam?.slug === 'cwp' || 
                              compareParam?.slug === 'sentinel1_raw_data' ||
                              compareParam?.slug === 'sentinel1_rgb_visualization' ||
                              selectedParam?.slug === 'cwp' || 
                              selectedParam?.slug === 'sentinel1_raw_data' ||
                              selectedParam?.slug === 'sentinel1_rgb_visualization'))) ? (
                                <ReactTooltip
                                  id= "view_layers"
                                  effect="solid"
                                  place="top"
                                  type="info"
                                  className="apply-layer-tooltip-st"
                                >
                                  {selectedParam?.slug === 'discharge_forecast'  ?  TRANSLATIONS.FILTER.NO_GRAPH_FOR_GEOGLOWS : TRANSLATIONS.FILTER.NO_CHART_FOR_INDICES  }
                                </ReactTooltip>
                              ) : (
                                ''
                              )}
                               {temporalAggregation?.label === 'Median' && 
                                    selectedParam?.slug !== 'cwp' &&
                                    selectedParam?.slug !== 'sentinel1_raw_data' &&
                                    selectedParam?.slug !== 'sentinel1_rgb_visualization' &&
                                    compareParam?.slug !== 'cwp' &&
                                    compareParam?.slug !== 'sentinel1_raw_data' &&
                                    compareParam?.slug !== 'sentinel1_rgb_visualization'
                                 ? (
                                <ReactTooltip
                                  id="view_layers"
                                  effect="solid"
                                  place="top"
                                  type="info"
                                  className="apply-layer-tooltip-st"
                                >
                                  {TRANSLATIONS.FILTER.MEDIAN_VIEW_GRAPH_DISABLE}
                                </ReactTooltip>
                              ) : (
                                ''
                              )}
                              {/* {(selectedParam?.slug === "sentinel1_raw_data" || selectedParam?.slug === "sentinel1_rgb_visualization") || 
                              (compareParam?.slug === "sentinel1_raw_data" || compareParam?.slug === "sentinel1_rgb_visualization") ? 
                              ""
                              : <Button
                              outline
                              className="navyBlueBgColor-outline m-auto"
                              onClick={handleUpdateChartDisplay}
                              disabled={!updateGraph}
                            >
                              View Graph
                            </Button> } */}
                            </div>
                          </div>
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Filter;