import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js';
import _ from 'lodash';
import { renderGenericDateFormat, renderChartTime } from '../../Components/common/utils';
import { DISPLAY_CHART_DATE, GRAPH_YAXIS_COLORS, MAX_NO_OF_XAXIS_TICKS } from '../../Components/constants';
import { PlotlyPNGLogo, PlotlyCSVLogo, PlotlyCloseLogo } from '../../Components/constants';
import ReactExport from 'react-export-excel';
import { kgMUnits, kgCmUnits, mmUnits, wmUnits, kgmsUnits } from '../../Components/constants';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const CropHealthPlotChart = (props) => {
  const {
    chartData,
    renderCharts,
    temporalAggregation,
    selectedParam,
    compareParam,
    isCompareClicked,
    initialSelectedDate,
    state,
    selectedDistrict,
    compareState,
    selectedCompareDistrict,
    activeTabSwitch,
    isHideBar,
  } = props;
  const [dates, setDates] = useState([]);
  const [yOptions, setYOptions] = useState([]);
  const [chartHeading, setChartHeading] = useState('');
  const [fileName, setFileName] = useState('');
  const ButtonClick = useRef('');
  const [objKeysData, setObjKeysData] = useState([]);
  const [csvFileData, setCsvFileData] = useState([]);
  const [layout, updateLayout] = useState({
    bargap: 0.5,
    responsive: true,
    title: {
      text: !isCompareClicked
        ? activeTabSwitch === 'tab1'
          ? `${selectedParam?.sensor_name} - ${selectedParam.name} (${temporalAggregation.name}) `
          : `${selectedParam.name} - ${selectedParam?.sensor_name} (${temporalAggregation.name}) `
        : activeTabSwitch === 'tab1'
        ? `${selectedParam?.sensor_name} - ${selectedParam.name} / ${compareParam.sensor_name} - ${compareParam.name} (${temporalAggregation.name})`
        : `${selectedParam.name} - ${selectedParam?.sensor_name}  / ${compareParam.name} - ${compareParam.sensor_name} (${temporalAggregation.name})`,
      plot_bgcolor: '#E5ECF6',
      font: {
        family: 'National',
        color: '#28609e',
        size: 15,
      },
      x: 0.5,
      xanchor: 'center',
    },
    xaxis: {
      type:'date',
      showline: true,
      tickangle: 20,
      tickfont: {
        size: 12,
        color: '#000',
        family: 'National',
      },
      tickformat:"%d-%m-%Y",
      showgrid: false,
      zeroline: false,
      rangemode: 'tozero',
      autorange: true,
      // tickformat : ' %b %-m %Y',
      // categoryorder: "category ascending"
    },
    // width: 500,
    // height: 400,
    // autosize:true,
    // width: isHideBar ? 1530 : 1300,
    modebardisplay: false,
    margin: {
      t: 80,
      b: 100,
    },
    yaxis: {
      type: 'linear',
      showline: true,
      showgrid: false,
      title: {
        text: chartHeading,
        plot_bgcolor: '#E5ECF6',
        font: {
          family: 'National',
          color: '#28609e',
          size: 15,
        },
      },
      zeroline: false,
      rangemode: 'tozero',
      autorange: true,
      tickformat: ',.2~f'
    },
    font: {
      size: 12,
      color: '#000',
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      // x: chartData?.graph_data?.length === 2 ? 0.42 : 0.45,
      x: 0.5,
      xanchor: 'center',
      y: -0.7,
      font: {
        // family: 'sans-serif',
        size: 15,
        color: '#000',
        family: 'National',
      },
    },
  });
  useEffect(() => {
    if (!_.isEmpty(temporalAggregation) && !_.isEmpty(selectedParam)) {
      let title = `${selectedParam?.sensor_name} - ${selectedParam.name} (${temporalAggregation.name})`;
      setChartHeading(title);
      if (!_.isEmpty(compareParam) && isCompareClicked) {
        title = `${selectedParam?.sensor_name} - ${selectedParam.name} / ${compareParam.sensor_name} - ${compareParam.name} (${temporalAggregation.name})`;
        setChartHeading(title);
      }
      // updateLayout({ ...layout, title: { text: `<b>${title}</b>` } });
    }
  }, []);

  // const kgUnits = "kg/m<sup>2</sup>";
  const getTickAngle = (xaxisData) => {
    let tickangle;
    if (xaxisData?.length < 75 && xaxisData?.length > 50) {
      tickangle = 60;
    } else if (xaxisData?.length < 100 && xaxisData?.length > 75) {
      tickangle = 40;
    } else {
      tickangle = 50;
    }
    return tickangle;
  };

  const getTickDifference = (startDate,endDate) => {
    let tickDifference;
    let months;
    let dayInMillis = 86400000;
    months = (endDate?.getFullYear() - startDate?.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
    if(months <= 2){
      tickDifference = dayInMillis
    }
    else if(months <= 12)  {
      tickDifference = 10 * dayInMillis;
    }
    else{
      tickDifference = 'M1'
    }
    return tickDifference
  }

  let view = [
    { visible: true, dash: 'dash', type: 'scatter', mode: 'lines' },
    { visible: true, dash: 'dash', type: 'scatter', mode: 'lines' },
    { visible: true, dash: 'dash', type: 'scatter', mode: 'lines' },
    { visible: true, dash: '', type: 'scatter', mode: 'lines' },
    { visible: true, dash: '', type: 'scatter', mode: 'lines' },
    { visible: true, dash: '', type: 'bar', mode: 'lines' },
  ];

  useEffect(() => {
    let formattedDates;
    let datesList;
    let options = [];

    if (chartData && chartData?.graph_data) {
      datesList = chartData?.graph_data[0]?.millis;
      if (chartData?.graph_data?.length === 2) {
        datesList =
          chartData?.graph_data[0]?.millis.length > chartData?.graph_data[1]?.millis.length
            ? chartData?.graph_data[0]?.millis
            : chartData?.graph_data[1]?.millis;
      }
      if (!_.isEmpty(datesList)) {
        formattedDates = datesList?.length
          ? datesList.map((item) => {
              let dateFormat = new Date(item);
              return renderGenericDateFormat(dateFormat);
            })
          : '';
      }
      //setting same dates for all indicators
      let tempDates = [];
      //sorting the millies from chartData and converting into string dates
      tempDates = [...new Set([].concat(...chartData?.graph_data.map((o) => o.millis)))].sort();
      setDates(formattedDates);
      let layoutData = { ...layout };
      layoutData.xaxis.tickangle = getTickAngle(formattedDates);
      if(tempDates?.length > 70){
        layoutData.xaxis.nticks = MAX_NO_OF_XAXIS_TICKS
        layoutData.xaxis.tickmode = "auto"
      }
      else
      layoutData.xaxis.tickvals = tempDates 
      // layoutData.xaxis["tick0"] = new Date(initialSelectedDate.startDate)
      updateLayout(layoutData);
      let xaxisDates = [];
      chartData?.graph_data?.map((i, index) => {
        let selectedParams = [];
        if (index === 0) {
          selectedParams[index] = selectedParam.name;
        } else if (index === 1) {
          selectedParams[index] = compareParam.name;
        }
        if (i?.layerType !== '') {
          let ab = {
            y: [],
            textposition: 'none',
          };
          /**
           * If anyone the chartData is having empty arrays we are setting
           * Yaxis data as [null] for the one which is having empty, so that
           * we are able to see the legends.
           */
          if (i?.data?.length) {
            i?.data?.map((item) => {
              ab.y.push(item.toFixed(2));
            });
          } else {
            ab.y.push(null);
          }
          // ab.fill = 'toself';
          ab.visible = view[index].visible;
          // ab.type = view[index].type;
          if (index > 0) {
            ab.yaxis = `y${index + 1}`;
          }
          ab.type =
            // i?.data?.length === 1
            //   ? 'bar'
            // :
            // selectedParams[index] === 'NDVI' ||
            //   (chartData?.graph_data?.length === 2 &&
            //     i?.layerType === 'ESoil Tavg' &&
            //     index === 1)
            // ? 'bar'
            // :
            'scatter';
          // ab.mode = view[index].mode;
          ab.mode = i?.millis?.length > 1 ? 'lines' : 'markers';
          // if (i?.layerType !== 'marker' && i?.layerType !== 'point') {
          if (i?.layerType !== 'marker' && i?.layerType !== 'polygon') {
            // ab.name = i?.layerType + ` (${i?.location})`;
            ab.visible = view[index].visible;
            // ab.name = i?.layerType;
            if (index === 0) {
              if (activeTabSwitch === 'tab1') {
                // ab.name = `${selectedParam?.sensor_name}-${i?.layerType}`;
                ab.name = `${selectedParam?.sensor_name}-${selectedParam.name}`;
              } else {
                // ab.name = `${i?.layerType}-${selectedParam?.sensor_name}`;
                ab.name = `${selectedParam.name}-${selectedParam?.sensor_name}`;
              }
              // ab.hovertemplate= `%{x} : ${ab.name} - %{y}<extra></extra>`
            } else if (index === 1) {
              if (activeTabSwitch === 'tab1') {
                // ab.name = `${compareParam?.sensor_name}-${i?.layerType}`;
                ab.name = `${compareParam?.sensor_name}-${compareParam.name}`;
              } else {
                // ab.name = `${i?.layerType}-${compareParam?.sensor_name}`;
                ab.name = `${compareParam.name}-${compareParam?.sensor_name}`;
              }
              // ab.hovertemplate= `%{x} : ${ab.name} - %{y}<extra></extra>`
            }
            if (_.isEmpty(i?.layerType)) {
              ab.text = selectedParams[0];
            } else {
              ab.text = i?.layerType;
            }
          } else {
            ab.name =
              i?.layerType === 'marker'
                ? 'Point' + '(' + i?.latlng?.[1].toFixed(2) + ',' + i?.latlng?.[0].toFixed(2) + ')'
                : i?.layerType === 'polygon' && `Polygon-${i?.polygonCount}`;
            ab.text =
              i?.layerType === 'marker'
                ? 'Point' + '(' + i?.latlng?.[1].toFixed(2) + ',' + i?.latlng?.[0].toFixed(2) + ')'
                : i?.layerType === 'polygon' && `Polygon-${i?.polygonCount}`;
            // ab.hovertemplate= `%{x} : ${ab.name} - %{y}<extra></extra>`
          }
          datesList = i?.millis;
          let formattedDates;
          if (!_.isEmpty(datesList)) {
          formattedDates = datesList?.length
              ? datesList.map((item) => {
                  return new Date(item)?.getTime();
                })
              : '';
          }
          ab.x = formattedDates;
          ab.line = { color: GRAPH_YAXIS_COLORS[index], dash: view[i]?.dash, width: 3 };
          ab.layerType = i?.layerType;
          ab.hoverinfo = 'text+x+y';
          if (index === 0) {
            ab.sensor = selectedParam?.sensor_name;
          } else {
            ab.sensor = compareParam?.sensor_name;
          }
          options.push(ab);
        }
      });
    }
    let updatedOptions = options.map((item) => {
      let x = { ...item };
      x.hovertemplate = `%{x} : ${item.name} : %{y}<extra></extra>`;
      /**
       * If anyone the chartData is having empty arrays we are setting
       * Xaxis data as same as the other for the one which is having empty, so that
       * we are able to match dates.
       */
      // if (options?.length === 2) {
      //   if (!options[0]?.x?.length) {
      //     x.x = options[1]?.x?.length ? [...options[1]?.x] : [null];
      //   }
      //   if (!options[1]?.x?.length) {
      //     x.x = options[0]?.x?.length ? [...options[0]?.x] : [null];
      //   }
      // }
      return x;
    });

    if (updatedOptions) setYOptions(updatedOptions);
  }, [chartData]);

  var trace = yOptions;
  var chartsData = trace;

  var icon1 = {
    width: 300,
    height: 500,
    path: 'M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z',
  };

  var icon3 = {
    name: 'newplotlylogo',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"/></svg>',
  };

  const getYaxisName = (data, index) => {
    if (data?.layerType !== 'marker' && data?.layerType !== 'polygon') {
      if (index === 0) {
        if (activeTabSwitch === 'tab1') {
          return`${selectedParam?.sensor_name}-${selectedParam.name}`;
        } else {
          return `${selectedParam.name}-${selectedParam?.sensor_name}`;
        }
      } else if (index === 1) {
        if (activeTabSwitch === 'tab1') {
          return `${compareParam?.sensor_name}-${compareParam.name}`;
        } else {
          return `${compareParam.name}-${compareParam?.sensor_name}`;
        }
      }
    } else {
    return data?.layerType === 'marker'
          ? 'Point' + '(' + data?.latlng?.[1].toFixed(2) + ',' + data?.latlng?.[0].toFixed(2) + ')'
          : data?.layerType === 'polygon' && `Polygon-${data?.polygonCount}`;
    }
  }

  const handleParamName = (selected, compare, index) => {
    let yaxisname;
    if (index === 0) {
      if (activeTabSwitch === 'tab1') {
        if (selected?.sensor_name === 'MODIS' && selected?.name === 'GPP') {
          yaxisname = `${selectedParam?.sensor_name} - ${selectedParam?.name} (${kgCmUnits}) <br> ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (selected?.sensor_name === 'SMAP' && selected?.name === 'Soil Moisture') {
          yaxisname = `${selectedParam?.sensor_name} - ${selectedParam?.name} (${mmUnits}) <br> ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (
          selected?.sensor_name === 'GLDAS' &&
          selected?.slug === 'gldas_soilmoi0_10cm_inst'
        ) {
          yaxisname = `${selectedParam?.sensor_name} - ${selectedParam?.name} <br> (${kgMUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (
          selected?.sensor_name === 'GLDAS' &&
          selected?.slug === 'gldas_soilmoi10_40cm_inst'
        ) {
          yaxisname = `${selectedParam?.sensor_name} - ${selectedParam?.name} <br> (${kgMUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (
          selected?.sensor_name === 'GLDAS' &&
          selected?.slug === 'gldas_soilmoi40_100cm_inst'
        ) {
          yaxisname = `${selectedParam?.sensor_name} - ${selectedParam?.name} <br> (${kgMUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (
          selected?.sensor_name === 'GLDAS' &&
          selected?.slug === 'gldas_soilmoi100_200cm_inst'
        ) {
          yaxisname = `${selectedParam?.sensor_name} - ${selectedParam?.name} <br> (${kgMUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (selected?.sensor_name === 'GLDAS' && selected?.slug === 'gldas_esoil_tavg') {
          yaxisname = `${selectedParam?.sensor_name} - ${selectedParam?.name} <br> (${wmUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (selected?.sensor_name === 'GLDAS' && selected?.slug === 'gldas_evap_tavg') {
          yaxisname = `${selectedParam?.sensor_name} - ${selectedParam?.name} <br> (${kgmsUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else {
          yaxisname = `${selectedParam?.sensor_name} - ${selectedParam?.name} <br> ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        }
      } else if (activeTabSwitch === 'tab2') {
        if (selected?.sensor_name === 'MODIS' && selected?.name === 'GPP') {
          yaxisname = `${selectedParam?.name} - ${selectedParam?.sensor_name} <br> (${kgCmUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (selected?.sensor_name === 'SMAP' && selected?.name === 'Soil Moisture') {
          yaxisname = `${selectedParam?.name} - ${selectedParam?.sensor_name} (${mmUnits}) <br> ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (
          selected?.sensor_name === 'GLDAS' &&
          selected?.slug === 'gldas_soilmoi0_10cm_inst'
        ) {
          yaxisname = `${selectedParam?.name} - ${selectedParam?.sensor_name} <br> (${kgMUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (
          selected?.sensor_name === 'GLDAS' &&
          selected?.slug === 'gldas_soilmoi10_40cm_inst'
        ) {
          yaxisname = `${selectedParam?.name} - ${selectedParam?.sensor_name} <br>(${kgMUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (
          selected?.sensor_name === 'GLDAS' &&
          selected?.slug === 'gldas_soilmoi40_100cm_inst'
        ) {
          yaxisname = `${selectedParam?.name} - ${selectedParam?.sensor_name} <br> (${kgMUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (
          selected?.sensor_name === 'GLDAS' &&
          selected?.slug === 'gldas_soilmoi100_200cm_inst'
        ) {
          yaxisname = `${selectedParam?.name} - ${selectedParam?.sensor_name} <br> (${kgMUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (selected?.sensor_name === 'GLDAS' && selected?.slug === 'gldas_esoil_tavg') {
          yaxisname = `${selectedParam?.name} - ${selectedParam?.sensor_name} <br> (${wmUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else if (selected?.sensor_name === 'GLDAS' && selected?.slug === 'gldas_evap_tavg') {
          yaxisname = `${selectedParam?.name} - ${selectedParam?.sensor_name} <br> (${kgmsUnits}) ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        } else {
          yaxisname = `${selectedParam?.name} - ${selectedParam?.sensor_name} <br> ${
            chartData?.graph_data[0]?.location ? `(${chartData?.graph_data[0]?.location})` : ''
          }`;
        }
      }
    } else {
      if (activeTabSwitch === 'tab1') {
        if (compare?.sensor_name === 'MODIS' && compare?.name === 'GPP') {
          yaxisname = `${compareParam?.sensor_name} - ${compareParam?.name} (${kgCmUnits}) <br> ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (compare?.sensor_name === 'SMAP' && compare?.name === 'Soil Moisture') {
          yaxisname = `${compareParam?.sensor_name} - ${compareParam?.name} (${mmUnits}) <br> ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (
          compare?.sensor_name === 'GLDAS' &&
          compare?.slug === 'gldas_soilmoi0_10cm_inst'
        ) {
          yaxisname = `${compareParam?.sensor_name} - ${compareParam?.name} <br> (${kgMUnits}) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (
          compare?.sensor_name === 'GLDAS' &&
          compare?.slug === 'gldas_soilmoi10_40cm_inst'
        ) {
          yaxisname = `${compareParam?.sensor_name} - ${compareParam?.name} <br>(${kgMUnits}) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (
          compare?.sensor_name === 'GLDAS' &&
          compare?.slug === 'gldas_soilmoi40_100cm_inst'
        ) {
          yaxisname = `${compareParam?.sensor_name} - ${compareParam?.name} <br> (${kgMUnits}) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (
          compare?.sensor_name === 'GLDAS' &&
          compare?.slug === 'gldas_soilmoi100_200cm_inst'
        ) {
          yaxisname = `${compareParam?.sensor_name} - ${compareParam?.name} <br> (${kgMUnits}) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (compare?.sensor_name === 'GLDAS' && compare?.slug === 'gldas_esoil_tavg') {
          yaxisname = `${compareParam?.sensor_name} - ${compareParam?.name} <br> (${wmUnits}) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (compare?.sensor_name === 'GLDAS' && compare?.slug === 'gldas_evap_tavg') {
          yaxisname = `${compareParam?.sensor_name} - ${compareParam?.name} <br> (${kgMUnits}/s) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else {
          yaxisname = `${compareParam?.sensor_name} - ${compareParam?.name} <br> ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        }
      } else if (activeTabSwitch === 'tab2') {
        if (compare?.sensor_name === 'MODIS' && compare?.name === 'GPP') {
          yaxisname = `${compareParam?.name} - ${compareParam?.sensor_name} <br> (${kgCmUnits}) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (compare?.sensor_name === 'SMAP' && compare?.name === 'Soil Moisture') {
          yaxisname = `${compareParam?.name} - ${compareParam?.sensor_name} (${mmUnits}) <br> ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (
          compare?.sensor_name === 'GLDAS' &&
          compare?.slug === 'gldas_soilmoi0_10cm_inst'
        ) {
          yaxisname = `${compareParam?.name} - ${compareParam?.sensor_name} <br> (${kgMUnits}) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (
          compare?.sensor_name === 'GLDAS' &&
          compare?.slug === 'gldas_soilmoi10_40cm_inst'
        ) {
          yaxisname = `${compareParam?.name} - ${compareParam?.sensor_name} <br> (${kgMUnits}) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (
          compare?.sensor_name === 'GLDAS' &&
          compare?.slug === 'gldas_soilmoi40_100cm_inst'
        ) {
          yaxisname = `${compareParam?.name} - ${compareParam?.sensor_name} <br> (${kgMUnits}) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (
          compare?.sensor_name === 'GLDAS' &&
          compare?.slug === 'gldas_soilmoi100_200cm_inst'
        ) {
          yaxisname = `${compareParam?.name} - ${compareParam?.sensor_name} <br> (${kgMUnits}) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (compare?.sensor_name === 'GLDAS' && compare?.slug === 'gldas_esoil_tavg') {
          yaxisname = `${compareParam?.name} - ${compareParam?.sensor_name} <br> (${wmUnits}) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else if (compare?.sensor_name === 'GLDAS' && compare?.slug === 'gldas_evap_tavg') {
          yaxisname = `${compareParam?.name} - ${compareParam?.sensor_name} <br> (${kgMUnits}/s) ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        } else {
          yaxisname = `${compareParam?.name} - ${compareParam?.sensor_name} <br> ${
            chartData?.graph_data[1]?.location && `(${chartData?.graph_data[1]?.location})`
          }`;
        }
      }
    }
    return `<b>${yaxisname}</b>`;
  };
  let x = useRef(false);
  useEffect(() => {
    if (chartsData && chartsData.length && !x.current) {
      let layoutData = { ...layout };
      chartsData.forEach((data, index) => {
        let yaxisName =
          data.layerType === 'marker'
            ? index === 0
              ? 'yaxis'
              : `yaxis${index + 1}`
            : data.layerType === 'polygon'
            ? index === 0
              ? 'yaxis'
              : `yaxis${index + 1}`
            : index === 0
            ? 'yaxis'
            : `yaxis${index + 1}`;
        layoutData[yaxisName] = {
          titlefont: { color: GRAPH_YAXIS_COLORS[index] },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          exponentformat: 'none',
          showline: true,
          showgrid: false,
          zeroline: false,
          rangemode: 'tozero',
          tickformat: ',.2~f',
          autorange: true,
          side:
            data.layerType === 'marker'
              ? index === 0
                ? 'left'
                : 'right'
              : data.layerType === 'polygon'
              ? index === 0
                ? 'left'
                : 'right'
              : index === 0
              ? 'left'
              : 'right',
          overlaying: index === 0 ? '' : 'y',
          title: {
            text:
              data.layerType === 'marker'
                ? index === 0
                  ? `<b>${data?.name}</b>`
                  : `<b>${data?.name}</b>`
                : data.layerType === 'polygon'
                ? index === 0
                  ? `<b>${data?.name}</b>`
                  : `<b>${data?.name}</b>`
                : index === 0
                ? handleParamName(selectedParam, compareParam, index)
                : handleParamName(selectedParam, compareParam, index),
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
        };
      });
      updateLayout(layoutData);
      x.current = true;
    }
  }, [chartsData]);

  //function for getting active legends for graph then download png/csv format
  const getActiveLegends = (isDownloadCSV, graphData, chartData) => {
    let mappedDataList = [];
    let graphDataList = [...graphData?.data];
     graphDataList?.forEach((i,key)=>{
        mappedDataList[key] = []
        i?.x?.forEach((k,index)=>{
          let filteredData = chartData?.graph_data?.[key]
          let obj = {};
          obj.name = i?.name
          obj.date = k;
          obj.value = filteredData?.data?.[index]?.toFixed(4)
          mappedDataList[key]?.push(obj)
        })
      })
    let checkForRegions = graphData.data?.every(
      (item) => item.layerType !== 'marker' && item.layerType !== 'polygon',
    );
    let name;
    if (checkForRegions) {
      if (_.isEmpty(compareParam)) {
        if (activeTabSwitch === 'tab1') {
          name = `${graphData?.data?.[0]?.sensor}-${graphData?.data?.[0]?.text}${
            chartData?.graph_data[0]?.location ? `-(${chartData?.graph_data[0]?.location})` : ''
          }(${renderChartTime(initialSelectedDate?.startDate).replace('/', '_')}-${renderChartTime(
            initialSelectedDate?.endDate,
          ).replace('/', '_')})`;
        } else {
          name = `${graphData?.data?.[0]?.text}-${graphData?.data?.[0]?.sensor}${
            chartData?.graph_data[0]?.location ? `-(${chartData?.graph_data[0]?.location})` : ''
          }(${renderChartTime(initialSelectedDate?.startDate).replace('/', '_')}-${renderChartTime(
            initialSelectedDate?.endDate,
          ).replace('/', '_')})`;
        }
      } else {
        if (activeTabSwitch === 'tab1') {
          name = `${graphData?.data?.[0]?.sensor}-${graphData?.data?.[0]?.text}${
            chartData?.graph_data[0]?.location ? `-(${chartData?.graph_data[0]?.location})` : ''
          }-${graphData?.data?.[1]?.sensor}-${graphData?.data?.[1]?.text}${
            chartData?.graph_data[1]?.location ? `-(${chartData?.graph_data[1]?.location})` : ''
          }(${renderChartTime(initialSelectedDate?.startDate).replace('/', '_')}-${renderChartTime(
            initialSelectedDate?.endDate,
          ).replace('/', '_')})`;
        } else {
          name = `${graphData?.data?.[0]?.text}-${graphData?.data?.[0]?.sensor}${
            chartData?.graph_data[0]?.location ? `-(${chartData?.graph_data[0]?.location})` : ''
          }-${graphData?.data?.[1]?.text}-${graphData?.data?.[1]?.sensor}${
            chartData?.graph_data[1]?.location ? `-(${chartData?.graph_data[1]?.location})` : ''
          }(${renderChartTime(initialSelectedDate?.startDate).replace('/', '_')}-${renderChartTime(
            initialSelectedDate?.endDate,
          ).replace('/', '_')})`;
        }
      }
    } else {
      if (activeTabSwitch === 'tab1') {
        name = `${graphData?.data?.[0]?.sensor}-${
          selectedParam?.name ? `${selectedParam?.name}-` : ''
        }${graphData?.data?.[0]?.text}${
          graphData?.data?.length === 2 ? `-${graphData?.data?.[1]?.text}` : ''
        }${
          chartData?.graph_data[0]?.location ? `-(${chartData?.graph_data[0]?.location})` : ''
        }(${renderChartTime(initialSelectedDate?.startDate).replace('/', '_')}-${renderChartTime(
          initialSelectedDate?.endDate,
        ).replace('/', '_')})`;
      } else {
        name = `${selectedParam?.name ? `${selectedParam?.name}-` : ''}-${
          graphData?.data?.[0]?.sensor
        }-${graphData?.data?.[0]?.text}${
          graphData?.data?.length === 2 ? `-${graphData?.data?.[1]?.text}` : ''
        }-${
          chartData?.graph_data[0]?.location ? `-(${chartData?.graph_data[0]?.location})` : ''
        }(${renderChartTime(initialSelectedDate?.startDate).replace('/', '_')}-${renderChartTime(
          initialSelectedDate?.endDate,
        ).replace('/', '_')})`;
      }
    }
    setFileName(name);
    if (document.getElementById('cropHealthGraphId')?.data?.length) {
      const activeLegends = graphData.data?.filter((item) => item.visible === true);
        if (activeLegends?.length) {
        //formatting data for csv file
        let data = [];
        let getAllDates = [];
        activeLegends?.forEach((i) => {
          i?.x?.forEach((k) => {
            getAllDates.push(k);
          });
        });
        let xaxisSortedDates = getAllDates.sort(function (a, b) {
          return new Date(a) - new Date(b);
        });
        function removeDuplicates(arr) {
          let unique = [];
          for(let i=0; i < arr.length; i++){ 
              if(unique.indexOf(arr[i]) === -1) { 
                  unique.push(arr[i]); 
              } 
          }
          return unique;
        }
        let xaixsValue = removeDuplicates(xaxisSortedDates);
        let tempDates = [];
          //sorting the millies from chartData and converting into string dates
          tempDates = [...new Set([].concat(...xaixsValue))].sort();
          let sortedDates = tempDates?.map((item) => {
            let dateFormat = new Date(item);
            return renderGenericDateFormat(dateFormat);
          });
        sortedDates.forEach((date, key) => {
          activeLegends.forEach((item, index) => {
            // let convertToStringArray = activeLegends[index]?.x?.map((i) =>
            // new Date(i).toDateString().split(' ').slice(1).join(' ')
            // );
            // let z = convertToStringArray?.indexOf(
            //   new Date(date).toDateString().split(' ').slice(1).join(' '),
            // );
            let getKey;
              let mappedList = mappedDataList?.find(x=> x?.find(i=> i.name === item?.name));
              let dateObj= mappedList?.find(item=> renderGenericDateFormat(item?.date) === date);
              getKey = dateObj?.value || ' -';
            if (item.layerType === 'marker' || item.layerType === 'polygon') {
              if (index === 0) {
                data.push({
                  Date: (date),
                  [`${item.text}`]: getKey,
                });
              } else {
                data[key][`${item.text}`] = getKey;
              }
            } else {
              if (activeTabSwitch === 'tab1') {
                if (index === 0) {
                  data.push({
                    Date: (date),
                    [`${item.sensor}-${item.text}`]: getKey,
                  });
                } else {
                  data[key][`${item.sensor}-${item.text}`] = getKey;
                }
              } else {
                if (index === 0) {
                  data.push({
                    Date: (date),
                    [`${item.text}-${item.sensor}`]: getKey,
                  });
                } else {
                  data[key][`${item.text}-${item.sensor}`] = getKey;
                }
              }
            }
          });
        });
        setCsvFileData(data);
        let objKeys = Object.keys(data[0]);
        if (objKeys) {
          setObjKeysData(objKeys);
        }
        if (isDownloadCSV) {
          //downloading csv file
          ButtonClick.current.click();
        } else {
          //downloading png file
          Plotly.downloadImage(graphData, {
            filename: name,
            format: 'png',
            width: graphData._fullLayout.width,
            height: graphData._fullLayout.height,
          });
        }
      }
      // }
    }
  };

  let config = {
    className: '',
    showTips: false,
    displaylogo: false,
    responsive: true,
    displayModeBar: true,
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        click: function (gd) {
          getActiveLegends(false, gd, chartData);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        click: function (gd) {
          getActiveLegends(true, gd, chartData);
        },
      },
      {
        name: 'Close',
        icon: PlotlyCloseLogo,
        click: function () {
          renderCharts();
        },
      },
    ],
  };
  const getTitle = (data, index) => {
    let title = '';
    if (data?.layerType === 'marker') {
      title = `<b>${data.name}</b>`;
    } else if (data?.layerType === 'polygon') {
      title = `<b>${data.name}</b>`;
    } else {
      title = handleParamName(selectedParam, compareParam, index);
    }
    return title;
  };

  const getLayoutWidths = [
    { yaxisCount: 1, width: 1600, domain: [0, 0] },
    { yaxisCount: 2, width: 1500, domain: [0, 0] },
    { yaxisCount: 3, width: 1400, domain: [0, 0.95] },
    { yaxisCount: 4, width: 1300, domain: [0, 0.9] },
  ];
  //function to order multiple y axes
  const getOrderedYAxes = (layoutData, YoptionsLength) => {
    let yaxisArray = [];
    Object.keys(layoutData).forEach((element) => {
      if (element.includes('yaxis')) {
        yaxisArray.push(element);
      }
    });

    let position = 1 - Number(`0.${YoptionsLength - 1}`);
    if (!yaxisArray.find((e) => e === 'yaxis')) {
      yaxisArray.push('yaxis');
    }
    let order = ['yaxis', 'yaxis2', 'yaxis3', 'yaxis4'];
    yaxisArray.sort((a, b) => order.indexOf(a) - order.indexOf(b));

    yaxisArray.forEach((item, key) => {
      if (key === 0) {
        if (layoutData[item]) {
          layoutData[item].side = 'left';
          layoutData[item].position = undefined;
        }
      } else if (key === 1) {
        layoutData[item].anchor = 'x';
        layoutData[item].side = 'right';
        layoutData[item].position = undefined;
      } else {
        layoutData[item].anchor = 'free';
        layoutData[item].side = 'right';
        layoutData[item].position = position;
        position += 0.1;
      }
    });
    return layoutData;
  };
  const handleLegends = (graphData) => {
    let index = graphData.expandedIndex;
    let data = graphData.data[index];
    let newYOptions = [...yOptions];
    if (data.visible === 'legendonly') {
      newYOptions[index]['yaxis'] = `y${index + 1}`;
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      if (yaxisCount > 2) {
        newYOptions[yaxisCount - 1]['position'] = yaxisCount === newYOptions.length ? 1 : 1;
      }
      setYOptions(newYOptions);
      // layout.width = getLayoutWidths.find(item => item.yaxisCount === yaxisCount)?.width;
      layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      if (index !== 0) {
        let yaxisName = `yaxis${index + 1}`;
        layout[yaxisName] = {
          title: {
            text: getTitle(data, index),
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
          titlefont: { color: GRAPH_YAXIS_COLORS[index] },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          anchor: yaxisCount === 2 ? 'x' : 'free',
          overlaying: 'y',
          side: 'right',
          position: newYOptions[yaxisCount - 1]['position'],
          showline: true,
          zeroline: false,
          showgrid: false,
          rangemode: 'tozero',
          autorange: true,
          tickformat: ',.2~f'
        };
      } else if (index === 0) {
        layout.yaxis = {
          title: {
            text: getTitle(data, index),
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
          titlefont: { color: GRAPH_YAXIS_COLORS[index] },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          side: 'left',
          showline: true,
          zeroline: false,
          showgrid: false,
          rangemode: 'tozero',
          autorange: true,
          tickformat: ',.2~f'
        };
      }
      let orderedLayoutData = getOrderedYAxes(layout, yOptions.length);
      updateLayout(orderedLayoutData);
    } else {
      delete newYOptions[index]['yaxis']
      setYOptions(newYOptions);
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      let yaxisName = index === 0 ? `yaxis` : `yaxis${index + 1}`;
      let layoutData = { ...layout };
      delete layoutData[yaxisName];
      if (yaxisName === "yaxis") {
        layoutData[yaxisName] = {showgrid: false, tickfont: {color:"#fff"}, zeroline: false}
      }
      layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      // layoutData.width = getLayoutWidths.find(item => item.yaxisCount === yaxisCount)?.width;
      let orderedLayoutData = getOrderedYAxes(layoutData, yOptions.length);
      updateLayout(orderedLayoutData);
    }
  };
  return (
    <>
      <span
        style={{
          color: '#28609e',
          position: 'absolute',
          left: '5px',
          top: '2%',
          family: 'National',
          fontSize: '15px',
          zIndex: 100,
        }}
      >
        Date : {moment(initialSelectedDate?.startDate).format(DISPLAY_CHART_DATE)} to{' '}
        {moment(initialSelectedDate?.endDate).format(DISPLAY_CHART_DATE)}
      </span>
      <Plot
        data={chartsData}
        layout={layout}
        config={config}
        useResizeHandler={true}
        onLegendClick={(data) => handleLegends(data)}
        // style={{ width: '99%', height: '100%' }}
        style={{ width: '99.5%', height: '100%' }}
        divId="cropHealthGraphId"
      />
      <ExcelFile
        filename={fileName}
        element={
          <button
            ref={ButtonClick}
            className="btn btn-download ml-auto"
            style={{ display: 'none' }}
          >
            Download
          </button>
        }
      >
        <ExcelSheet data={csvFileData} name="Crop_Health_Monitor">
        {objKeysData?.map((item, i) => (
            <ExcelColumn label={item} value={item} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
export default CropHealthPlotChart;
