import React from 'react';
import Plot from 'react-plotly.js';

export default function CommonPlotly(props) {
  const { traces, layout, handleLegends, customFileName } = props;
  const config = {
    displaylogo: false,
    displayModeBar: true,
    modeBarButtonsToRemove: [
      'autoScale',
      'lasso2d',
      'toggleSpikelines',
      'hoverCompareCartesian',
      'hoverClosestCartesian',
    ],
    showTips: false,
    toImageButtonOptions: {filename: customFileName}
  };
  return (
    <>
      {handleLegends ? (
        <Plot
          data={traces}
          layout={layout}
          config={config}
          onLegendClick={(data) => handleLegends(data)}
        />
      ) : (
        <Plot data={traces} layout={layout} config={config} />
      )}
    </>
  );
}
