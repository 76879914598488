import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Container } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-multi-lang';
import { userListRequest, userListSuccess } from '../../redux/manage-user/manageUserAction';
import { loginDetailrequest } from '../../redux/login/loginActions';
import { isStaffUser, isSuperAdmin } from '../../Components/common/utils';
import { ManageUserTable } from './Table.js';
import RightArrow from '../../assets/Images/back-button.svg';
import Loader from '../../Components/common/loader';
import FilterIcon from '../../assets/Images/icons/mi_filter.svg';
import RightSideBar from './Filter';

const ManageUser = () => {
  const nextProps = useSelector((state) => ({
    isUserListDataRequesting: state.UserList.isUserListDataRequesting,
    userListData: state.UserList.userListData || null,
    loginDetailData: state.Login.loginDetailData || null,
    userListDataError: state.UserList.userListDataError || null,
  }));
  const t = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const [activeTab, setActiveTab] = useState('approve');
  const [newRequestList, setNewRequestList] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [userPermissions, setUserPermissions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [recordsCount, setRecordsCount] = useState();
  const [allowedPermission, setAllowedPermission] = useState();
  const [toggleRightBar, setToggleRightBar] = useState(false);
  const [closed, setClosed] = useState(false);


  const COLUMNS = [
    {
      Header: t('MANAGE_USER.NAME'),
      accessor: (row) => (row.name ? row.name : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('MANAGE_USER.EMAIL'),
      accessor: (row) => (row?.user?.email ? row?.user?.email : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('MANAGE_USER.ORGANIZATION'),
      accessor: (row) => (row.organization ? row.organization : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('MANAGE_USER.DESIGNATION'),
      accessor: (row) => (row.designation ? row.designation : 'N/A'),
      sticky: 'top',
    },
  ];

  const APPROVECOLUMNS = [
    {
      Header: t('MANAGE_USER.NAME'),
      accessor: (row) => (row.name ? row.name : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('MANAGE_USER.EMAIL'),
      accessor: (row) => (row?.user?.email ? row?.user?.email : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('MANAGE_USER.ORGANIZATION'),
      accessor: (row) => (row.organization ? row.organization : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('MANAGE_USER.DESIGNATION'),
      accessor: (row) => (row.designation ? row.designation : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('Is verified'),
      accessor: (row) => (row?.user?.is_verified ? 'Yes' : 'No'),
      sticky: 'top',
    },
  ];

  useEffect(() => {
    if (nextProps.userListDataError) {
      if (nextProps?.userListDataError?.statusCode === 200) {
        history.goBack();
      }
    }
  }, [nextProps?.userListDataError]);


  const toggle = (tab) => {
    setActiveTab(tab);
    setPageNumber(1);
    if (tab === 'approve') {
      dispatch(
        userListRequest({
          key: 'is_approve',
          bool_value: 'True',
          pageKey: 'page',
          page: 1,
          limitKey: 'limit',
          limit: pageLimit,
          name: '',
          mobile: '',
          email: '',
        }),
      );
    } else if (tab === 'delete') {
      dispatch(
        userListRequest({
          key: 'is_delete',
          bool_value: 'True',
          pageKey: 'page',
          page: 1,
          limitKey: 'limit',
          limit: pageLimit,
          name: '',
          mobile: '',
          email: '',
        }),
      );
    }
  };

  useEffect(() => {
    if (isStaffUser()) {
      dispatch(
        userListRequest(
          activeTab === 'approve' && {
            key: 'is_approve',
            bool_value: 'True',
            pageKey: 'page',
            page: pageNumber,
            limitKey: 'limit',
            limit: pageLimit,
            name: '',
            mobile: '',
            email: '',
          },
        ),
      );
      dispatch(loginDetailrequest());
    } else {
      history.push('/home');
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (nextProps.userListData) {
      if (nextProps?.userListData?.data?.results?.length) {
        setNewRequestList(nextProps?.userListData?.data?.results);
        setPageCount(nextProps?.userListData?.data?.count);
        setRecordsCount(nextProps?.userListData?.data?.results.length);
      } else {
        setNewRequestList([]);

        setPageCount(0);
        setRecordsCount(0);
      }
    } else {
      setNewRequestList([]);
    }
  }, [nextProps.userListData]);

  useEffect(() => {
    if (nextProps.loginDetailData) {
      const {
        data: { result },
      } = nextProps.loginDetailData;
      if (result?.user_permissions) {
        setUserPermissions(result?.user_permissions);
        let a = [];
        result?.user_permissions.map((i) => {
          return a.push(i.codename);
        });
        setAllowedPermission(a);
      }
    }
  }, [activeTab, nextProps.loginDetailData]);

  const handleLatestData = (value) => {
    const localUserPermission = localStorage.getItem('userPermissions');
    // dispatch(userListSuccess({}));
    setNewRequestList([]);
    if (value === 'approve') {
      if (JSON.parse(localUserPermission)?.result?.user_permissions?.length || isSuperAdmin()) {
        setActiveTab('approve');
        dispatch(
          userListRequest({
            key: 'is_approve',
            bool_value: 'True',
            pageKey: 'page',
            page: pageNumber,
            limitKey: 'limit',
            limit: pageLimit,
            name: '',
            mobile: '',
            email: '',
          }),
        );
      } else {
        history.push('/home');
      }
    } else if (value === 'delete') {
      setActiveTab('delete');
      dispatch(
        userListRequest({
          key: 'is_delete',
          bool_value: 'True',
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: pageLimit,
          name: '',
          mobile: '',
          email: '',
        }),
      );
    }
  };

  const handleLatestDataForDelete = (value) => {
    setActiveTab('delete');
    dispatch(
      userListRequest({
        key: 'is_delete',
        bool_value: 'True',
        pageKey: 'page',
        page: pageNumber,
        limitKey: 'limit',
        limit: pageLimit,
        name: '',
        mobile: '',
        email: '',
      }),
    );
  };

  const handlePageNumber = (value) => {
    setPageNumber(value);
    if (activeTab === 'approve') {
      dispatch(
        userListRequest({
          key: 'is_approve',
          bool_value: 'True',
          pageKey: 'page',
          page: value,
          limitKey: 'limit',
          limit: pageLimit,
          name: '',
          mobile: '',
          email: '',
        }),
      );
    } else if (activeTab === 'delete') {
      dispatch(
        userListRequest({
          key: 'is_delete',
          bool_value: 'True',
          pageKey: 'page',
          page: value,
          limitKey: 'limit',
          limit: pageLimit,
          name: '',
          mobile: '',
          email: '',
        }),
      );
    }
  };

  const handlePageSize = (value) => {
    setPageLimit(value);
    setPageNumber(1);
    if (activeTab === 'approve') {
      dispatch(
        userListRequest({
          key: 'is_approve',
          bool_value: 'True',
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: value,
          name: '',
          mobile: '',
          email: '',
        }),
      );
    } else if (activeTab === 'delete') {
      dispatch(
        userListRequest({
          key: 'is_delete',
          bool_value: 'True',
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: value,
          name: '',
          mobile: '',
          email: '',
        }),
      );
    }
  };

  const closeRightBar = () => {
    setToggleRightBar(false);
  };

  const handleClose = () => {
    setClosed(true);
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <Container fluid>
        {toggleRightBar && (
          <RightSideBar
            closeRightBar={closeRightBar}
            closed={closed}
            handleClose={handleClose}
            activeTab={activeTab}
            pageLimit={pageLimit}
            pageNumber={pageNumber}
          />
        )}
        <div className="right-img-wrapper api-key-list">
          <Row style={{ marginTop: '1%',}}>
            <Col xs="6" sm="6" md="6" lg="6" className="subheader-manage-user">
              <img
                src={RightArrow}
                alt="back-arrow"
                className="manage-user-header-back-button"
                onClick={() => history.push('/settingsDashboard')}
              />
              <h3 className="heading-manage-user manage-user-heading-mt">
                {t('MANAGE_USER.MANAGE_USERS')}
              </h3>
            </Col>
            <Col xs="5" sm="5" md="5" lg="5">
              <span
                onClick={() => {
                  setToggleRightBar(!toggleRightBar);
                }}
                className="filter-icon-text col-lg-6 manage-static-heading"
                style={{
                  float: 'right',
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  marginTop: '3%',
                }}
              >
                <img src={FilterIcon} alt={'not-available'} className="st-filter-img" />
                <h5 className="st-filter-text">{t('FILTER.FILTER')} </h5>
              </span>
            </Col>
          </Row>
          <Nav tabs className="manage-user-navtabs">
            {/* <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'new' })}
                onClick={() => {
                  toggle('new');
                }}
              >
                {t('MANAGE_USER.NEW_REQUEST')}
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'approve' })}
                onClick={() => {
                  toggle('approve');
                }}
              >
                {t('MANAGE_USER.ACTIVE_USERS')}
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'reject' })}
                onClick={() => {
                  toggle('reject');
                }}
              >
                {t('MANAGE_USER.REJECTED')}
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'delete' })}
                onClick={() => {
                  toggle('delete');
                }}
              >
                {t('MANAGE_USER.DELETED_USERS')}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <Row>
                <Col lg="12" md="12" className="table-col">
                  <>
                    {/* {newRequestList && console.log(newRequestList[0]?.name, 'newRequestList')} */}
                    {nextProps.isUserListDataRequesting && <Loader />}
                    {newRequestList && (
                      <ManageUserTable
                        loading={nextProps.isUserListDataRequesting}
                        req_data={newRequestList}
                        handleLatestData={handleLatestData}
                        selectedTab={activeTab}
                        handleLatestDataForDelete={handleLatestDataForDelete}
                        handlePageSize={handlePageSize}
                        pageCount={pageCount}
                        handlePageNumber={handlePageNumber}
                        pageNumber={pageNumber}
                        recordsCount={recordsCount}
                        activeTab={activeTab}
                        loggedUserData={userPermissions}
                        COLUMNS = {activeTab === "approve" ? APPROVECOLUMNS : COLUMNS}
                      />
                    )}
                  </>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </>
  );
};

export default ManageUser;
