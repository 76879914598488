import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Card, Container } from 'reactstrap';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SettingIcon from '../assets/Images/dashboard-img.png';
import ManageStaticIcon from '../assets/Images/dashboard-img1.png';
import ManageUserIcon from '../assets/Images/dashboard-img2.png';
import ManageUserGuideIcon from '../assets/Images/manage_user_guide_icon.png';
import { isStaffUser, isInsuranceAdmin, isSuperAdmin , getToken } from './common/utils';
import Loader from './common/loader';
import { loginDetailrequest, loginDetailSuccess } from '../redux/login/loginActions';
import { userPermissionsRequest } from '../redux/manage-user/manageUserAction';

import FieldDataIcon from '../assets/Images/fieldIcon.png';
import SurveyConfigurationIcon from '../assets/Images/survey-configuration.png';
import { BsArrowLeft } from 'react-icons/bs';
import { SCOPE, discoveryUrl, CLIENT_ID, API_KEY } from './constants/index';
import jwt_decode from 'jwt-decode';

import ManageSurveyorsIcon from '../assets/Images/manage-surveyor.png';

import _ from 'lodash';

import { showError } from './Notifications';

const SurveyDataSettingsDashboard = () => {
  let params = new URL(document.location).searchParams;
  const [loginDetails, setLoginDetails] = useState({});

  const [signedInUserDetails, setSignedInUserDetails] = useState('');
  const [userDetails, setUserDetails] = useState('');

  let queryParam = params.get('from');
  const cardsInfo = useMemo(
    () => [
      {
        title: 'Survey Configuration',
        icon: SurveyConfigurationIcon,
        is_insurance_admin: true,

        path: '/configuration',
        group_name: 'Manage ODK Data',

        discription:
          'Admin can configure the forms for the selected component and can invite people.',
      },

      {
        title: 'Manage Surveyors',
        icon: ManageSurveyorsIcon,
        is_insurance_admin: true,

        path: '/manageSurveyors',
        group_name: 'Manage ODK Data',

        discription:
          'Admin can view and delete the list of users to whom access has been provided.',
      },

      {
        title: 'Field Data',
        icon: FieldDataIcon,
        is_insurance_admin: true,

        path: '/manageFieldData',
        group_name: 'Manage ODK Data',
        discription:
          'Admin can view the data for different components and it supports the draw farm feature.',
      },
    ],
    [],
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [cardList, setCardList] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [cardsInformation, setCardsInformation] = useState();
  const [tokenClient, setTokenClient] = useState('')
  const [path, setPath] = useState('');

  const nextProps = useSelector((state) => ({
    loginData: state.Login.loginData,
    loginDetailData: state.Login.loginDetailData || null,
    userPermissionsData: state.UserList.userPermissionsData || null,
    isUserPermissionsRequesting: state.UserList.isUserPermissionsRequesting,
    isLoginDetailRequesting: state.UserList.isLoginDetailRequesting,
    insuranceUserUpdateData: state.Login.insuranceUserUpdateData,
  }));

  useEffect(() => {
    if (nextProps.loginDetailData) {
      setUserDetails(nextProps?.loginDetailData?.data?.result?.user_profile);
    }
  }, [nextProps.loginDetailData?.data?.result]);

  const handleClientLoad = () => {
   /* global google */
    window.google.accounts.id.prompt((notification) => {
      if (notification?.isNotDisplayed() || notification?.isSkippedMoment()) {
        document.cookie =  `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        google.accounts.id.prompt()
        if(notification.getNotDisplayedReason()){
          showError(notification.getNotDisplayedReason())
        }
      }
    });
  };

  const handleCallbackResponse = (response) => {
    let userObj = jwt_decode(response.credential);
    setSignedInUserDetails(userObj)
  }

  useEffect(() => {
    if (!_.isEmpty(signedInUserDetails?.email) && (!_.isEmpty(userDetails) || isSuperAdmin())) {
      if (!_.isEmpty(userDetails?.google_email)) {
        if (userDetails?.google_email === signedInUserDetails?.email) {
          // localStorage.setItem('', JSON.stringify(tokenClient))
          localStorage.setItem('googleAccountDetails', JSON.stringify(signedInUserDetails));
          history.push(path);
        } else {
          showError(
            `Email you have authenticated in browser is not related with your organization please authenticate with this email ${userDetails?.google_email}`,
          );
        }
      } else {
        localStorage.setItem('googleAccountDetails', JSON.stringify(signedInUserDetails));
        history.push(path);
      }
    }
  }, [signedInUserDetails, nextProps.loginDetailData]);

  useEffect(() => {
    /* global google */
    const initializeGoogleAccount = window?.google?.accounts?.id.initialize({
      client_id:CLIENT_ID,
      useOneTap: false,
      auto_select: true,
      hint: userDetails?.google_email || '',
      prompt:'',
      callback: handleCallbackResponse
    })
    setTokenClient(initializeGoogleAccount)
    return () => {
      dispatch(loginDetailSuccess({}));
    };
  }, [userDetails]);

  useEffect(() => {
    let getLoginDeatils = JSON.parse(localStorage.getItem('userDetails'));
    setLoginDetails(getLoginDeatils);
    if(getToken()){

    dispatch(loginDetailrequest());

    if (isStaffUser()) {
      dispatch(userPermissionsRequest());
    }
  }
  else {
    history.push("/home")
  }
  }, []);

  useEffect(() => {
    setCardsInformation(cardsInfo);
  }, [nextProps.userPermissionsData, userPermissions, cardsInfo, loginDetails]);

  useEffect(() => {
    if (nextProps.loginDetailData) {
      const {
        data: { result },
      } = nextProps.loginDetailData;
      if (result?.user_permissions) {
        setUserPermissions(result?.user_permissions);
      }
    }
  }, [nextProps.loginDetailData]);

  const handleCards = React.useCallback(() => {
    /* function body */
    let a = [];

    setCardList(cardsInformation);
  }, [userPermissions, loginDetails, cardsInfo, cardsInformation]);

  useEffect(() => {
    if (cardsInformation?.length && userPermissions) {
      handleCards(userPermissions);
    }
  }, [cardsInformation, userPermissions, handleCards]);

  const handleCardClick = (i) => {
    if (i.group_name === 'Manage ODK Data') {
      setPath(i.path);
      handleClientLoad();
    } else {
      history.push(i.path);
    }
  };

  return (
    <>
      <div style={queryParam ? { marginBottom: '29vh' } : {}}>
        <div
          className={
            queryParam
              ? 'global-top1 dashboard-border my-5 mx-4'
              : 'global-top dashboard-border my-5 mx-4 '
          }
          style={{ paddingTOp: '15px' }}
        >
          <div className="dashboard-header">
            {queryParam ? (
              ''
            ) : (
              <div
                className=""
                style={{ paddinTop: '50px', position: 'relative', marginBottom: '15px' }}
              >
                <span
                  style={{
                    float: 'left',
                    borderRadius: '700px',
                    border: '1px solid #ada6a6',
                    marginTop: '10px',
                    height: '43px',
                    width: '43px',
                    fontSize: '25px',
                    color: '#595252',
                    fontWeight: 'bold',
                  }}
                  className="list-tl-st"
                >
                  <BsArrowLeft
                    className="cursor-ponter"
                    onClick={() => history.push('/settingsDashboard?from=login')}
                    style={{ margin: 'auto' }}
                  />
                </span>
              </div>
            )}

            <img src={SettingIcon} alt={'icon-not available'} />
            <p className="dashboard-text">Survey Data Settings</p>
          </div>
          <Container>
            <Row>
              {nextProps?.isUserPermissionsRequesting || nextProps?.isLoginDetailRequesting ? (
                <Loader />
              ) : (
                cardList.map((i, index) => {
                  return (
                    <>
                      <Col lg={4} sm={6} xs={12} className="card-column">
                          <Card
                            className="dashboard-card custom-card-x cb-1 cursor-pointer"
                            onClick={() => handleCardClick(i)}
                          >
                            <div className="">
                              <img src={i.icon} alt={'not-available'} />
                              <p className="icon-text dt-1">{i.title}</p>
                              <p className="icon-small-text mrl-1">{i.discription}</p>
                            </div>
                          </Card>
                      </Col>
                    </>
                  );
                })
              )}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default SurveyDataSettingsDashboard;
