import {LOGIN_CONST, FORGOT_PASSWORD, CHANGE_PASSWORD, RESET_PASSWORD,LOGIN_DETAIL_CONST,PROJECT_FARM_LIST_CONST , INSURANCE_USER_UPDATE_CONST} from './loginConst';

/**
 *  User Login actions
 */
export const loginUser = (data) => ({
  type: LOGIN_CONST.LOGIN_REQUEST,
  payload: data,
});

export const loginUserSuccess = (response) => ({
  type: LOGIN_CONST.LOGIN_SUCCESS,
  payload: response,
});

export const loginUserFailed = (error) => ({
  type: LOGIN_CONST.LOGIN_FAILED,
  payload: error,
});

/**
 *  User carousel actions
 */
export const loginCarousel = () => ({
  type: LOGIN_CONST.LOGIN_CAROUSEL_REQUEST,
  payload: null,
});

export const loginCarouselSuccess = (response) => ({
  type: LOGIN_CONST.LOGIN_CAROUSEL_SUCCESS,
  payload: response,
});

export const loginCarouselFailed = (error) => ({
  type: LOGIN_CONST.LOGIN_CAROUSEL_FAILED,
  payload: error,
});


export const forgotPassword = (data) => ({
  type: FORGOT_PASSWORD.FORGOT_PASSWORD_REQUEST,
  payload: data,
});

export const forgotPasswordSuccess = (response) => ({
  type: FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS,
  payload: response,
});

export const forgotPasswordFailed = (error) => ({
  type: FORGOT_PASSWORD.FORGOT_PASSWORD_FAILED,
  payload: error,
});





export const changePassword = (data) => ({
  type: CHANGE_PASSWORD.CHANGE_PASSWORD_REQUEST,
  payload: data,
});

export const changePasswordSuccess = (response) => ({
  type: CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS,
  payload: response,
});

export const changePasswordFailed = (error) => ({
  type: CHANGE_PASSWORD.CHANGE_PASSWORD_FAILED,
  payload: error,
});





export const resetPassword = (data) => ({
  type: RESET_PASSWORD.RESET_PASSWORD_REQUEST,
  payload: data,
});

export const resetPasswordSuccess = (response) => ({
  type: RESET_PASSWORD.RESET_PASSWORD_SUCCESS,
  payload: response,
});

export const resetPasswordFailed = (error) => ({
  type: RESET_PASSWORD.RESET_PASSWORD_FAILED,
  payload: error,
});


export const loginDetailrequest = (data) => ({
  type: LOGIN_DETAIL_CONST.LOGIN_DETAIL_REQUEST,
  payload: data,
});

export const loginDetailSuccess = (response) => ({
  type: LOGIN_DETAIL_CONST.LOGIN_DETAIL_SUCCESS,
  payload: response,
});

export const loginDetailFailed = (error) => ({
  type: LOGIN_DETAIL_CONST.LOGIN_DETAIL_FAILED,
  payload: error,
});

export const projectFarmListrequest = (data) => ({
  type: PROJECT_FARM_LIST_CONST.PROJECT_FARM_LIST_REQUEST,
  payload: data,
});

export const projectFarmListSuccess = (response) => ({
  type: PROJECT_FARM_LIST_CONST.PROJECT_FARM_LIST_SUCCESS,
  payload: response,
});

export const projectFarmListFailed = (error) => ({
  type: PROJECT_FARM_LIST_CONST.PROJECT_FARM_LIST_FAILED,
  payload: error,
});

export const insuranceUserUpdaterequest = (data) => ({
  type: INSURANCE_USER_UPDATE_CONST.INSURANCE_USER_UPDATE_REQUEST,
  payload: data,
});

export const insuranceUserUpdateSuccess = (response) => ({
  type: INSURANCE_USER_UPDATE_CONST.INSURANCE_USER_UPDATE_SUCCESS,
  payload: response,
});

export const insuranceUserUpdateFailed = (error) => ({
  type: INSURANCE_USER_UPDATE_CONST.INSURANCE_USER_UPDATE_FAILED,
  payload: error,
});
