// @flow
import { combineReducers } from 'redux';
import Login from './login/loginReducer';
import Register from './register/registerReducer';
import Location from './location/reducer';
import UserList from './manage-user/manageUserReducer';
import { reducer as formReducer } from 'redux-form';
import ClimateWeather from './climate-weather/climateWeatherReducer';

import Weather from './weather/weatherReducer';
import Map_Preference from './map-preferences/mapPreferenceReducer';
import Home from './home/homeReducer';
import AccessKey from './accessKey/accessKeyReducer';
import staticResource from './staticResource/staticResourceReducer';
import DroughtMonitor from './droughtMonitor/droughtMonitorReducer';
import ManageCrops from '../redux/manageCrops/manageCropsReducer';
import ContingencyPlan from '../redux/contingencyPlan/contingencyPlanReducer';
import NewsFeed from "../redux/newsFeed/newsFeedReducer";
import FarmTracker from "../redux/farmTracker/farmTrackerReducer";
import CropHealthMonitoring from "../redux/cropHealthMonitoring/cropHealthMonitoringReducer"
import UserGuide from './userGuide/userGuideReducer';
import RiskAnalytics from '../redux/riskAnalytics/riskAnalyticsReducer';

import fieldDataList from "../redux/manageFieldData/manageFieldReducer"


export default combineReducers({
  Login,
  Register,
  Location,
  UserList,
  ClimateWeather,
  FarmTracker,
  AccessKey,
  DroughtMonitor,
  Home,
  Weather,
  Map_Preference,
  staticResource,
  ManageCrops,
  ContingencyPlan,
  NewsFeed,
  UserGuide,
  CropHealthMonitoring,
  RiskAnalytics,
  fieldDataList,
  form: formReducer,
});
