import { REGISTER_CONST,VERIFICATION_CONST } from './registerConst';

const Register = (state = {registerData: false , verifyValidationData:false}, action) => {
  switch (action.type) {
    case REGISTER_CONST.REGISTER_REQUEST:
      return {
        ...state,
        isRegisterRequesting: true,
        registerData: false,
        registerDataError: false,
        registerDataNetworkError: false,
      };
    case REGISTER_CONST.REGISTER_SUCCESS:
      return {
        ...state,
        isRegisterRequesting: false,
        registerData: action.payload.response,
        registerDataError: false,
        registerDataNetworkError: false,
      };
    case REGISTER_CONST.REGISTER_FAILED:
      return {
        ...state,
        isRegisterRequesting: false,
        registerData: false,
        registerDataError: action.payload.error ? false : action.payload.response,
        registerDataNetworkError: action.payload.error ? action.payload.error : false,
      };


      case VERIFICATION_CONST.VERIFICATION_REQUEST:
      return {
        ...state,
        isVerficationRequesting: true,
        verifiedData: false,
        verifiedDataError: false,
        verifiedDataNetworkError: false,
      };
    case VERIFICATION_CONST.VERIFICATION_SUCCESS:
      return {
        ...state,
        isVerficationRequesting: false,
        verifiedData: action.payload.response,
        verifiedDataError: false,
        verifiedDataNetworkError: false,
      };
    case VERIFICATION_CONST.VERIFICATION_FAILED:
      return {
        ...state,
        isVerficationRequesting: false,
        verifiedData: false,
        verifiedDataError: action.payload.error ? false : action.payload.response,
        verifiedDataNetworkError: action.payload.error ? action.payload.error : false,
      };

      case REGISTER_CONST.REGISTER_VALIDATION_REQUEST:
      return {
        ...state,
        isVerfyValidationRequesting: true,
        verifyValidationData: false,
        verifyValidationDataError: false,
        verifyValidationDataNetworkError: false,
      };
    case REGISTER_CONST.REGISTER_VALIDATION_SUCCESS:
      return {
        ...state,
        isVerfyValidationRequesting: false,
        verifyValidationData: action.payload.response,
        verifyValidationDataError: false,
        verifyValidationDataNetworkError: false,
      };
    case REGISTER_CONST.REGISTER_VALIDATION_FAILURE:
      return {
        ...state,
        isVerfyValidationRequesting: false,
        verifyValidationData: false,
        verifyValidationDataError: action.payload.error ? false : action.payload.response,
        verifyValidationDataNetworkError: action.payload.error ? action.payload.error : false,
      };


    default:
      return { ...state };
  }
};

export default Register;
