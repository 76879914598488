import React, { useEffect, useState } from 'react';

import { Container, Row, Col, FormGroup, Label } from 'reactstrap';

// import RightArrow from '../../assets/Images/back-button.svg';
import RightArrow from '../../assets/Images/back-button.svg';

import { useHistory, useLocation } from 'react-router-dom';

import ImagePlaceHolder from '../../assets/Images/imagePlaceholder.png';
import Image1 from '../../assets/Images/camera.png';

import { getFieldDataRequest } from '../../redux/manageFieldData/manageFieldDataActions';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../Components/common/loader';
import { BsArrowLeft } from 'react-icons/bs';
import _ from 'lodash'

const SurveyDetails = () => {
  // return <h1>Survey Details</h1>

  let history = useHistory();
  const paramLocation = useLocation();

  // const ManageFieldData = (props) => {
  const nextProps = useSelector((state) => ({
    isFieldDataListDataRequesting: state.fieldDataList.isFieldDataListDataRequesting,
    fieldDataListData: state?.fieldDataList?.FieldDataListData,
    loginDetailData: state.Login.loginDetailData || null,
  }));

  const dispatch = useDispatch();

  const [newRequestList, setNewRequestList] = useState([
    {
      'Farmer Name': 'test',
      'Farmer ID': '12345',
      'Phone No.': '9876543210',
      District: 'test',
      'DS Division': 'test',
      'GN Division': 'test',
      'Surveyor Name': 'test',
      'Status of Insurance': 'test',
    },
  ]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // dispatch(
    //   getFieldDataRequest({
    //     // url: 'https://docs.google.com/spreadsheets/d/1URjfSwRTsmQFwQdyWbVIHD6uaVNSIaQES6QZUmXABjo/edit?usp=sharing',
    //     year: paramLocation?.state?.selectedDate,
    //     component_id: paramLocation?.state?.id,
    //   }),
    // );
  }, []);
  // useEffect(() => {
  //   if (nextProps.fieldDataListData) {
  //     if (nextProps?.fieldDataListData?.data?.result) {
  //       // setNewRequestList(nextProps?.fieldDataListData?.data?.results);
  //       setNewRequestList(
  //         nextProps?.fieldDataListData?.data?.result?.['farm_odk_data ']?.filter(
  //           (item) => item?.['data-meta-instanceID'] === history.location?.pathname.split('/')[2],
  //         ),
  //       );
  //       // setPageCount(nextProps?.fieldDataListData?.data?.count);
  //       // setRecordsCount(nextProps?.fieldDataListData?.data?.result.length);
  //     } else {
  //       setNewRequestList([]);

  //       // setPageCount(0);
  //       // setRecordsCount(0);
  //     }
  //   } else {
  //     setNewRequestList([]);
  //   }
  // }, [nextProps.fieldDataListData]);

  useEffect(() => {
    if (!_.isEmpty(paramLocation?.state?.newRequestList) && paramLocation?.state?.uuid) {
    setNewRequestList(
      paramLocation?.state?.newRequestList.filter(
      (item) => item?.['data-meta-instanceID'] === paramLocation?.state?.uuid,
    ),
  );    } else {
    setNewRequestList([]);
  }}, [paramLocation?.state?.newRequestList]);

  useEffect(() => {
    if (newRequestList) {
      setTableData(
        newRequestList?.map((item, key) => {
          if (item?.['data-meta-instanceID'] === history.location?.pathname.split('/')[2]) {
            return {
              farmerId:
                item?.['data-farmer-information-farmer-id'] !== ' '
                  ? item?.['data-farmer-information-farmer-id']
                    ? item?.['data-farmer-information-farmer-id']
                    : 'N/A'
                  : ' ',
              // farmerId:item?.[""]
              phoneNo:
                item?.['data-sub_section-Telephone-No'] !== ' '
                  ? item?.['data-sub_section-Telephone-No']
                    ? item?.['data-sub_section-Telephone-No']
                    : 'N/A'
                  : 'N/A',
              district:
                item?.['data-farmer-information-District'] !== ' ' &&
                item?.['data-farmer-information-District']
                  ? item?.['data-farmer-information-District']
                  : ' ',
              dsDivision:
                item?.['data-farmer-information-DS-division'] !== ' '
                  ? item?.['data-farmer-information-DS-division']
                    ? item?.['data-farmer-information-DS-division']
                    : 'N/A'
                  : ' ',
              gnDivision:
                item?.['data-farmer-information-GN-division'] !== ' '
                  ? item?.['data-farmer-information-GN-division']
                    ? item?.['data-farmer-information-GN-division']
                    : 'N/A'
                  : ' ',
              id:
                item?.['data-meta-instanceID'] !== ' '
                  ? item?.['data-meta-instanceID']
                    ? item?.['data-meta-instanceID']
                    : 'N/A'
                  : 'N/A',
              gender: item?.['data-sub_section-Gender'],
              houseHoldGeoPoint: item?.['data-Location-Info-Location-of-household-altitude'],

              croppedAreaOfLand:
                item?.[
                  'data-The-Landholding-and-cultivation-details-during-the-last-12-months-Croppd-area-of-own-land'
                ],
              genderOfTheLandOwener:
                item?.[
                  'data-The-Landholding-and-cultivation-details-during-the-last-12-months-Gender-of-the-landowner'
                ],
              involveAnyCultivation: item?.['data-Do-you-involve-any-form-of-tenant-cultivation'],
              ifYesTotalTenure: item?.['data-If-yes-total-tenure-extent'],
              typeOfTenure: item?.['data-tenure-Type-of-tenure'],
              landSharingReceipt:
                item?.['data-tenure-Do-you-get-any-land-sharing-receipt-for-tenure-land'],
              cultivatedEcroached:
                item?.[
                  'data-Do-you-cultivate-any-encroached-with-permit-or-without-permits-during-the-last-12-months'
                ],
              ifYesTotalExtent: item?.['data-If-yes-extent-acres'],
              shareOutLand: item?.['data-Did-you-lease-rent-mortgage'],
              totalExtentRented: item?.['data-If-yes-total-extent-rented-leased-mortgaged'],
              totalLandAreaCoveredPaddy:
                item?.['data-Total-land-area-covered-by-crop-insurance-Paddy-ac'],
              totalLandAreaCoveredNonPaddy:
                item?.['data-Total-land-area-covered-by-crop-insurance-Non-paddy-ac'],
              awareOfEligibility:
                item?.[
                  'data-Are-you-aware-of-your-eligibility-for-crop-insurance-rolled-out-in-your-village'
                ],
              howYouKnowAboutEnrollment:
                item?.['data-If-yes-How-did-know-about-your-enrolment-for-crop-insurance'],
              doYouhaveadaquate:
                item?.[
                  'data-Do-you-have-adequate-knowledge-about-the-following-details-of-the-crop-insurance-scheme-Coverage-of-crops'
                ],
              premiumAmount:
                item?.[
                  'data-Do-you-have-adequate-knowledge-about-the-following-details-of-the-crop-insurance-scheme-Premium-amount'
                ],
              triggerPoints:
                item?.[
                  'data-Do-you-have-adequate-knowledge-about-the-following-details-of-the-crop-insurance-scheme-Trigger-points-for-payments'
                ],
              sumInsured:
                item?.[
                  'data-Do-you-have-adequate-knowledge-about-the-following-details-of-the-crop-insurance-scheme-Sum-insured'
                ],
              isInsuranceEnrolledPartOfbundled:
                item?.[
                  'data-Is-the-insurance-enrolled-was-a-part-of-a-bundled-solution-including-seeds-advisories'
                ],
              didYouReceiveWeatherAdvisorOnTime:
                item?.['data-weather-advisories-Did-you-receive-weather-and-advisories-on-time'],
              isTheLanguageUsedAdvisorUseful:
                item?.['data-weather-advisories-IS-the-language-used-in-the-advisories-clear'],
              weatherAdvisorUseful: item?.['data-weather-advisories-Are-weather-advisories-useful'],
              didYouChangeTheirrigation:
                item?.[
                  'data-weather-advisories-Did-you-change-irrigation-application-after-receiving-weather-advisories'
                ],
              didYouChangeTheAgronomic:
                item?.[
                  'data-weather-advisories-Did-you-change-agronomic-and-or-crop-management-practices-after-receiving-weather-and-advisories'
                ],
              didYouReceiveSeedsbundled:
                item?.[
                  'data-weather-advisories-Did-you-receive-seeds-bundled-with-insurance-for-cultivation-in-this-season'
                ],
              didCropYieldincrease:
                item?.[
                  'data-weather-advisories-Did-crop-yield-increase-with-the-given-seed-variety-in-bundled-solution'
                ],
              howDidYouutilizeInsurance:
                item?.['data-insurance-pay-out-How-did-you-utilize-your-insurance-compensation'],
              whatAreTheImmediatebenfits:
                item?.[
                  'data-insurance-pay-out-What-were-the-immediate-benefits-of-the-insurance-pay-out-to-your-household'
                ],
              whoWillMakedecision:
                item?.[
                  'data-insurance-pay-out-Who-will-make-decisions-on-utilization-of-compensation'
                ],
              levelOfSatisfaction:
                item?.[
                  'data-insurance-pay-out-Your-level-of-satisfaction-on-the-amount-of-payout-received'
                ],

              mahaSeason1before:
                item?.['data-The-average-cost-of-cultivation-of-paddy-crops-Maha-season'],
              mahaSeason1after:
                item?.['data-The-average-cost-of-cultivation-of-paddy-crops-Maha-seaso-after'],
              yalaSeason1before:
                item?.['data-The-average-cost-of-cultivation-of-paddy-crops-Yala-season'],
              yalaSeason1after:
                item?.['data-The-average-cost-of-cultivation-of-paddy-crops-Yala-seaso-after'],
              mahaSeason2before:
                item?.[
                  'data-Average-cost-of-cultivation-of-non-paddy-crops-Maha-season-non-paddy1'
                ],
              mahaSeason2after:
                item?.[
                  'data-Average-cost-of-cultivation-of-non-paddy-crops-Maha-season-non-paddy2'
                ],
              yalaSeason2before:
                item?.['data-Average-cost-of-cultivation-of-non-paddy-crops-Yala-season-paddy1'],
              yalaSeason2after:
                item?.['data-Average-cost-of-cultivation-of-non-paddy-crops-Yala-season-paddy2'],
              mahaSeason3before: item?.['data-Gross-Income-of-paddy-Maha-season-paddy-icome1'],
              mahaSeason3after: item?.['data-Gross-Income-of-paddy-Maha-season-paddy-income2'],
              yalaSeason3before: item?.['data-Gross-Income-of-paddy-Yala-season-paddy-income'],
              yalaSeason3after: item?.['data-Gross-Income-of-paddy-Yala-season-paddy-income2'],
              mahaSeason4before:
                item?.['data-Gross-Income-from-non-paddy-Maha-season-none-paddy-icome1'],
              mahaSeason4after:
                item?.['data-Gross-Income-from-non-paddy-Maha-season-none-paddy-income2'],
              yalaSeason4before:
                item?.['data-Gross-Income-from-non-paddy-Yala-season-none-paddy-income-1'],
              yalaSeason4after:
                item?.['data-Gross-Income-from-non-paddy-Yala-season-none-paddy-income2'],
              mahaSeason5before:
                item?.['data-Did-rainfall-damaged-your-crops-Maha-season-rainfall1'],
              mahaSeason5after:
                item?.['data-Did-rainfall-damaged-your-crops-Maha-season-rainfall2'],
              yalaSeason5before:
                item?.['data-Did-rainfall-damaged-your-crops-Yala-season-rainfall1'],
              yalaSeason5after:
                item?.['data-Did-rainfall-damaged-your-crops-Yala-season-rainfall2'],
              mahaSeason6before:
                item?.['data-Value-of-crops-damaged-due-to-floods-Maha-seasoncrops-damaged1'],
              mahaSeason6after:
                item?.['data-Value-of-crops-damaged-due-to-floods-Maha-season-crops-damaged2'],
              yalaSeason6before:
                item?.['data-Value-of-crops-damaged-due-to-floods-Yala-season-crops-damaged1'],
              yalaSeason6after:
                item?.['data-Value-of-crops-damaged-due-to-floods-Yala-season-crops-damaged2'],
              mahaSeason7before:
                item?.['data-Value-of-crops-damaged-due-to-droughts-Maha-season-damaged-droughts1'],
              mahaSeason7after:
                item?.['data-Value-of-crops-damaged-due-to-droughts-Maha-season-damaged-droughts2'],
              yalaSeason7before:
                item?.['data-Value-of-crops-damaged-due-to-droughts-Yala-season-damaged-droughts1'],
              yalaSeason7after:
                item?.['data-Value-of-crops-damaged-due-to-droughts-Yala-season-damaged-droughts2'],
              mahaSeason8before:
                item?.[
                  'data-Insurance-compensation-paid-for-crop-damages-Maha-season-insurance-compensation1'
                ],
              mahaSeason8after:
                item?.[
                  'data-Insurance-compensation-paid-for-crop-damages-Maha-season-insurance-compensation2'
                ],
              yalaSeason8before:
                item?.[
                  'data-Insurance-compensation-paid-for-crop-damages-Yala-season-insurance-compensation1'
                ],
              yalaSeason8after:
                item?.[
                  'data-Insurance-compensation-paid-for-crop-damages-Yala-season-insurance-compensation2'
                ],
              mahaSeason9before:
                item?.[
                  'data-Any-other-compensation-paid-by-the-government-Maha-season-other-compensation1'
                ],
              mahaSeason9after:
                item?.[
                  'data-Any-other-compensation-paid-by-the-government-Maha-season-other-compensation2'
                ],
              yalaSeason9before:
                item?.[
                  'data-Any-other-compensation-paid-by-the-government-Yala-season-other-compensation1'
                ],
              yalaSeason9after:
                item?.[
                  'data-Any-other-compensation-paid-by-the-government-Yala-season-other-compensation2'
                ],

              photo1: item?.['data-title-Photo-1'],
              photo2: item?.['data-title-Photo-2'],
              photo3: item?.['data-title-Photo-3'],
            };
          } else return;
        }),
      );
    }
  }, [newRequestList]);

  return (
    <Container fluid>
      {nextProps.isFieldDataListDataRequesting && <Loader />}

      <div className="right-img-wrapper api-key-list">
        <Row>
          {/* <Col>
                <h1>Survey Details</h1>
                </Col> */}
          <Col xs="12" sm="12" md="12" lg="12" className="subheader-manage-user">
            {/* <img
              src={RightArrow}
              alt="back-arrow"
              className="manage-user-header-back-button"
              onClick={() => history.push('/manageFieldData?ImpactEvaluation')}
            /> */}

            <span
              style={{
                float: 'left',
                marginLeft: '10px',
                borderRadius: '700px',
                border: '1px solid #ada6a6',
                height: '43px',
                width: '43px',
                fontSize: '25px',
                color: '#595252',
                fontWeight: 'bold',
                marginTop: '2.5%',
              }}
              className="list-tl-st"
            >
              {/* <img
                      src={RightArrow}
                      className="cursor-ponter"
                      onClick={() => history.goBack()}
                      alt='back-bottom'
                    /> */}
              <BsArrowLeft
                className="cursor-ponter"
                //onClick={() => history.goBack()}
                onClick={() => history.push('/manageFieldData?ImpactEvaluation&fromBack=true' + '&date=' + paramLocation?.state?.selectedDate)}
                style={{ margin: 'auto' }}
              />
            </span>

            <h3 className="heading-manage-user manage-user-heading-mt">
              {/* {t('MANAGE_USER.MANAGE_USERS')} */}
              Impact Evaluation 
            </h3>
          </Col>

          <Col style={{ marginLeft: '4%', marginRight: '4%' }}>
            <form action="/action_page.php">
              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  General Information
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  <Col md={3} style={{ overflowWrap: 'break-word', fontWeight: 'bold' }}>
                    {/* <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Farmer name
                      </Label>
                      <div>
                        <span>Farmer name</span>
                      </div>
                    </FormGroup>
                    <br /> */}
                    {/* </Col> */}

                    {/* <br /> */}
                    <FormGroup style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        {/* Bank account number */}
                        Farmer ID
                      </Label>
                      <div>
                        {/* <span>Bank account number</span> */}
                        <span>
                          {/* 0123456789 */}
                          {tableData[0]?.farmerId!==" " ?tableData[0]?.farmerId ? tableData[0]?.farmerId : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />

                    {/* <Col md={4} style={{ overflowWrap: 'break-word' }}> */}
                    <FormGroup style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        District
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* District */}
                          {tableData[0]?.district!==" " ?tableData[0]?.district ? tableData[0]?.district : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}

                    {/* <br /> */}
                    {/* <FormGroup style={{ marginLeft: '17px' }}> */}
                    {/* <Label className="label" style={{ color: '#7C8EA0', fontWeight:"bold"  }}> */}
                    {/* Account name */}
                    {/* Farmer Number */}
                    {/* </Label> */}
                    {/* <div> */}
                    {/* <span>Bank branch name</span> */}
                    {/* <span>0987</span> */}
                    {/* </div> */}
                    {/* </FormGroup> */}
                    {/* <br /> */}
                  </Col>

                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    {/* <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight:"bold"  }}>
                        Telephone number
                      </Label>
                      <div>
                        <span>Telephone number</span>
                      </div>
                    </FormGroup>
                    <br /> */}
                    {/* </Col> */}
                    {/* <Col md={4} style={{ overflowWrap: 'break-word' }}> */}
                    <FormGroup style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        DS Division
                      </Label>
                      <div>
                        <span>
                          {/* DS Division */}
                          {tableData[0]?.dsDivision!==" " ?tableData[0]?.dsDivision ? tableData[0]?.dsDivision : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}

                    {/* <br /> */}
                    {/* <FormGroup style={{ marginLeft: '17px' , fontWeight:"bold" }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight:"bold"  }}> */}
                    {/* Bank account number */}
                    {/* Farmer ID
                      </Label>
                      <div> */}
                    {/* <span>Bank account number</span> */}
                    {/* <span>0123456789</span>
                      </div>
                    </FormGroup> */}
                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        GN Division
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* GN Division */}
                          {tableData[0]?.gnDivision!==" " ?tableData[0]?.gnDivision ? tableData[0]?.gnDivision : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md={3} style={{ overflowWrap: 'break-word', fontWeight: 'bold' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        {/* Gender */}
                        Household geopoint
                      </Label>
                      <div>
                        {/* <span>Gender</span> */}
                        <span>
                          {/* 0000000, 000000 */}
                          {tableData[0]?.houseHoldGeoPoint
                            ? tableData[0]?.houseHoldGeoPoint
                              ? parseFloat(tableData[0]?.houseHoldGeoPoint.split(',')[0]).toFixed(
                                  2,
                                ) +
                                ',' +
                                parseFloat(tableData[0]?.houseHoldGeoPoint.split(',')[1]).toFixed(2)
                              : ''
                            : <br/>}
                        </span>
                      </div>
                    </FormGroup>
                    {/* <br /> */}
                    {/* </Col> */}
                    {/* <Col md={4} style={{ overflowWrap: 'break-word' }}> */}
                    {/* <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight:"bold"  }}>
                        GN Division
                      </Label>
                      <div>
                        <span>GN Division</span>
                      </div>
                    </FormGroup> */}
                    {/* </Col> */}

                    <br />
                  </Col>

                  <Col lg={3} md={4}>
                    {/* <img src={ImagePlaceHolder} style={{height:"150px"}} /> */}
                    {/* <br/> */}
                    {/* <div style={{ marginLeft: '15px' }}> */}
                    {/* <img src={Image1} /> */}
                    {/* &nbsp; NIC Number */}
                    {/* &nbsp; */}
                    {/* <a href="" target="_blank" style={{ color: '#363636' }}> */}
                    {/* &nbsp; */}
                    {/* Homestead/farm{' '}
                      </a> */}

                    {/* <a href={tableData[0]?.photo2?tableData[0]?.photo2:""} target="_blank" style={{color:"#363636"}}>Homestead/farm</a> */}

                    {/* <br /> */}
                    {/* <img src={Image1} /> */}
                    {/* &nbsp; Homestead/farm */}
                    {/* &nbsp; */}
                    {/* <a href="" target="_blank" style={{ color: '#363636' }}>
                        Homestead/field{' '}
                      </a> */}

                    {/* <a href={tableData[0]?.photo3?tableData[0]?.photo3:""} target="_blank" style={{color:"#363636"}}>Homestead/field</a> */}

                    {/* <br /> */}
                    {/* <img src={Image1} />
                      &nbsp; Homestead/field */}
                    {/* </div> */}
                  </Col>
                </Row>
              </fieldset>

              <br />

              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  {/* Disaster occurrences and risk perception */}
                  The Landholding and cultivation details during the last 12 months
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Cropped area of owned land (acres)
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* 2 acres */}
                          {tableData[0]?.croppedAreaOfLand!==" " ?tableData[0]?.croppedAreaOfLand
                            ? tableData[0]?.croppedAreaOfLand
                            : 'N/A':"N/A"}
                        </span>
                      </p>
                      {/* <p style={{marginLeft:"30px"}}>Flood & Drought</p> */}
                    </div>
                  </Col>

                  <Col md={3}>
                    <div>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Gender of the landowner
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* Male */}
                          {tableData[0]?.genderOfTheLandOwener!==" " ?tableData[0]?.genderOfTheLandOwener
                            ? tableData[0]?.genderOfTheLandOwener
                            : 'N/A':"N/A"}
                        </span>
                      </p>
                      {/* <br/> */}
                      {/* <p style={{marginLeft:"30px"}}>Already experienced disasters</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Involved in any form of tenant cultivation (rent-in, leased-in, mortgaged
                        -in or <br /> sharecropping of any land during the last 12 months
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* Male */}
                          {tableData[0]?.involveAnyCultivation!==" " ?tableData[0]?.involveAnyCultivation
                            ? tableData[0]?.involveAnyCultivation
                            : 'N/A':"N/A"}
                        </span>
                      </p>
                      {/* <p style={{marginLeft:"30px"}}>Every year</p> */}
                    </div>
                  </Col>

                  <Col md={3}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        If yes, total tenure extent (in acres)
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* 2 acres */}
                          {tableData[0]?.ifYesTotalTenure!==" " ?tableData[0]?.ifYesTotalTenure ? tableData[0]?.ifYesTotalTenure : 'N/A':"N/A"}
                        </span>
                      </p>
                      {/* <p>Once in three years</p> */}
                    </div>
                  </Col>

                  <Col md={3}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Type of tenure
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* Mortgage */}
                          {tableData[0]?.typeOfTenure!==" " ?tableData[0]?.typeOfTenure ? tableData[0]?.typeOfTenure : 'N/A':"N/A"}
                        </span>
                      </p>

                      {/* <p>Major crops that are primarily grown in your farm fields</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Get any land-sharing receipt or tenancy documentation for tenure land or{' '}
                        <br /> sharecropping (registered tenant)
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* Mortgage */}
                          {tableData[0]?.landSharingReceipt!== " " ?tableData[0]?.landSharingReceipt
                            ? tableData[0]?.landSharingReceipt
                            : 'N/A':"N/A"}
                        </span>
                      </p>
                      {/* <p>Major crops that are primarily grown in your farm fields</p> */}
                    </div>
                  </Col>

                  {/* </Col> */}
                  <Col md={6} style={{ overflowWrap: 'break-word' }}>
                    <div style={{ display: 'flex' }}>
                      {/* <p>Please rank the two most-serious disasters in terms of damage in your area during <br/> the last 5
years</p> */}
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Cultivated any encroached or government land with permit or without permits{' '}
                        <br /> during the last 12 months
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* Yes */}
                          {tableData[0]?.cultivatedEcroached!==" " ?tableData[0]?.cultivatedEcroached
                            ? tableData[0]?.cultivatedEcroached
                            : 'N/A':"N/A"}
                        </span>
                      </p>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      {/* <p>Please rank the two most-serious disasters in terms of damage in your area during <br/> the last 5
years</p> */}
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        If yes, mention total tenure extent (in acres)
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* 2 acres */}
                          {tableData[0]?.ifYesTotalExtent!==" " ?tableData[0]?.ifYesTotalExtent ? tableData[0]?.ifYesTotalExtent : 'N/A':"N/A"}
                        </span>
                      </p>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      {/* <p>Frequency your farm fields affected by
flood</p> */}
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Mention if you lease, rent, mortgage, or share out any of your land out to
                        other <br />
                        farmers
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* Yes */}
                          {tableData[0]?.shareOutLand!==" " ?tableData[0]?.shareOutLand ? tableData[0]?.shareOutLand : 'N/A':"N/A"}
                        </span>
                      </p>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      {/* <p>Frequency your farm fields affected by
Drought</p> */}
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        If yes, mention total extent rented, leased, mortgaged, or share out in a
                        season <br /> during last 12 months (acres)
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* 2 acres */}
                          {tableData[0]?.totalExtentRented!==" " ?tableData[0]?.totalExtentRented
                            ? tableData[0]?.totalExtentRented
                            : 'N/A':"N/A"}
                        </span>
                      </p>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      {/* <p>Major crops that are primarily grown in your farm fields</p> */}
                      {/* <p>Major crops that are primarily grown in your farm fields</p> */}
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Total land area covered by crop insurance: Paddy (ac)
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* 1 acres */}
                          {tableData[0]?.totalLandAreaCoveredPaddy!==" " ?tableData[0]?.totalLandAreaCoveredPaddy
                            ? tableData[0]?.totalLandAreaCoveredPaddy
                            : 'N/A':"N/A"}
                        </span>
                      </p>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      {/* <p>Major crops that are primarily grown in your farm fields</p> */}
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Total land area covered by crop insurance: Non - Paddy (ac)
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* 1 acres */}
                          {tableData[0]?.totalLandAreaCoveredNonPaddy
                            ? tableData[0]?.totalLandAreaCoveredNonPaddy
                            : 'N/A'}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </fieldset>

              <br />

              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  {/* Drought/ flood effects */}
                  Changes in Yala and Maha seasons
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  {/* <Col md={12} style={{ overflowWrap: 'break-word' }}> */}
                  <Col md={12}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Changes after joining the index-based insurance (IBI) program (please
                        consider the area covered by insurance while answering)
                      </p>
                    </div>
                  </Col>

                  <Col md={12}>
                    <div
                      style={{
                        backgroundColor: '#7C8EA0',
                        height: '36px',
                        display: 'flex',
                        paddingBottom: '10px',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        marginLeft: '17px',
                        marginRight: '17px',
                      }}
                    >
                      <Col md={6}>
                        <p
                          style={{
                            marginLeft: '10px',
                            color: '#FFFFFF',
                            marginTop: '5px',
                            fontWeight: 'bold',
                          }}
                        >
                          Situation
                        </p>
                      </Col>

                      <Col md={3}>
                        <p
                          style={{
                            color: '#FFFFFF',
                            marginTop: '5px',
                            marginLeft: '53px',
                            fontWeight: 'bold',
                          }}
                        >
                          Before joining IBI
                        </p>
                      </Col>

                      <Col md={3}>
                        <p
                          style={{
                            color: '#FFFFFF',
                            marginTop: '5px',
                            marginLeft: '53px',
                            fontWeight: 'bold',
                          }}
                        >
                          After joining IBI
                        </p>
                      </Col>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div
                      style={{
                        backgroundColor: '#D9D9D9',
                        paddingBottom: '1px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        marginRight: '17px',
                        marginLeft: '17px',
                      }}
                    >
                      <Col md={12} style={{ display: 'flex', height: '35px' }}>
                        {/* <div style={{ backgroundColor: '#D9D9D9', height: '36px', display: 'flex' }}> */}
                        <Col md={6}>{/* <p>Situation</p> */}</Col>

                        <Col md={3} style={{ display: 'flex' }}>
                          <Col>
                            <p style={{ marginTop: '10px', color: '#363636', fontWeight: 'bold' }}>
                              Maha
                            </p>
                          </Col>
                          <Col>
                            <p
                              style={{
                                marginLeft: '30px',
                                color: '#363636',
                                marginTop: '10px',
                                fontWeight: 'bold',
                              }}
                            >
                              Yala
                            </p>
                          </Col>
                        </Col>

                        <Col md={3} style={{ display: 'flex' }}>
                          <Col>
                            <p style={{ marginTop: '10px', color: '#363636', fontWeight: 'bold' }}>
                              Maha
                            </p>
                          </Col>
                          <Col>
                            <p
                              style={{
                                marginLeft: '30px',
                                color: '#363636',
                                marginTop: '10px',
                                fontWeight: 'bold',
                              }}
                            >
                              Yala
                            </p>
                          </Col>
                        </Col>
                        {/* </div> */}
                      </Col>

                      <Col md={12}>
                        <div
                          style={{
                            height: '51px',
                            display: 'flex',
                            backgroundColor: '#FFFFFF',
                            marginBottom: '5px',
                            marginBottom: '5px',
                            marginTop: '5px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            borderRadius: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <Col md={6}>
                            {/* <p>Situation</p> */}
                            <span
                              style={{ marginLeft: '10px', fontWeight: 'bold', color: '#363636' }}
                            >
                              1. The average cost of cultivation of paddy crops (Rs/acre)
                            </span>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason1before
                                  ? tableData[0]?.mahaSeason1before
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginLeft: '30px',
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason1before
                                  ? tableData[0]?.yalaSeason1before
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason1after
                                  ? tableData[0]?.mahaSeason1after
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  marginLeft: '30px',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason1after
                                  ? tableData[0]?.yalaSeason1after
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div
                          style={{
                            height: '51px',
                            display: 'flex',
                            backgroundColor: '#FFFFFF',
                            marginBottom: '5px',
                            marginBottom: '5px',
                            marginTop: '5px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            borderRadius: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <Col md={6}>
                            {/* <p>Situation</p> */}
                            <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                              2. Average cost of cultivation of non-paddy crops (specify the crop)
                            </span>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason2before
                                  ? tableData[0]?.mahaSeason2before
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginLeft: '30px',
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason2before
                                  ? tableData[0]?.yalaSeason2before
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason2after
                                  ? tableData[0]?.mahaSeason2after
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  marginLeft: '30px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason2after
                                  ? tableData[0]?.yalaSeason2after
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div
                          style={{
                            height: '51px',
                            display: 'flex',
                            backgroundColor: '#FFFFFF',
                            marginBottom: '5px',
                            marginBottom: '5px',
                            marginTop: '5px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            borderRadius: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <Col md={6}>
                            {/* <p>Situation</p> */}
                            <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                              3. Gross Income of paddy (Rs/acre)
                            </span>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p style={{ marginTop: '15px', wordBreak: 'break-all' }}>
                                {/* 200 */}
                                {tableData[0]?.mahaSeason3before
                                  ? tableData[0]?.mahaSeason3before
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginLeft: '30px',
                                  marginTop: '15px',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason3before
                                  ? tableData[0]?.yalaSeason3before
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason3after
                                  ? tableData[0]?.mahaSeason3after
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  marginLeft: '30px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason3after
                                  ? tableData[0]?.yalaSeason3after
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div
                          style={{
                            height: '51px',
                            display: 'flex',
                            backgroundColor: '#FFFFFF',
                            marginBottom: '5px',
                            marginBottom: '5px',
                            marginTop: '5px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            borderRadius: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <Col md={6}>
                            {/* <p>Situation</p> */}
                            <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                              4. Gross Income from non-paddy (Rs/acre)
                            </span>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason4before
                                  ? tableData[0]?.mahaSeason4before
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginLeft: '30px',
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason4before
                                  ? tableData[0]?.yalaSeason4before
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason4after
                                  ? tableData[0]?.mahaSeason4after
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  marginLeft: '30px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason4after
                                  ? tableData[0]?.yalaSeason4after
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div
                          style={{
                            height: '51px',
                            display: 'flex',
                            backgroundColor: '#FFFFFF',
                            marginBottom: '5px',
                            marginBottom: '5px',
                            marginTop: '5px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            borderRadius: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <Col md={6}>
                            {/* <p>Situation</p> */}
                            <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                              5. Did rainfall (surplus or deficits) damaged your crops
                            </span>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason5before
                                  ? tableData[0]?.mahaSeason5before
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginLeft: '30px',
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason5before
                                  ? tableData[0]?.yalaSeason5before
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p style={{ marginTop: '15px', wordBreak: 'break-all' }}>
                                {/* 200 */}
                                {tableData[0]?.mahaSeason5after
                                  ? tableData[0]?.mahaSeason5after
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  marginLeft: '30px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason5after
                                  ? tableData[0]?.yalaSeason5after
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div
                          style={{
                            height: '51px',
                            display: 'flex',
                            backgroundColor: '#FFFFFF',
                            marginBottom: '5px',
                            marginBottom: '5px',
                            marginTop: '5px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            borderRadius: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <Col md={6}>
                            {/* <p>Situation</p> */}
                            <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                              6. Value of crops damaged due to floods (Rs/ac)
                            </span>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason6before
                                  ? tableData[0]?.mahaSeason6before
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginLeft: '30px',
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason6before
                                  ? tableData[0]?.yalaSeason6before
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason6after
                                  ? tableData[0]?.mahaSeason6after
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  marginLeft: '30px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason6after
                                  ? tableData[0]?.yalaSeason6after
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div
                          style={{
                            height: '51px',
                            display: 'flex',
                            backgroundColor: '#FFFFFF',
                            marginBottom: '5px',
                            marginBottom: '5px',
                            marginTop: '5px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            borderRadius: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <Col md={6}>
                            {/* <p>Situation</p> */}
                            <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                              7. Value of crops damaged due to droughts (Rs/ac)
                            </span>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason7before
                                  ? tableData[0]?.mahaSeason7before
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginLeft: '30px',
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason7before
                                  ? tableData[0]?.yalaSeason7before
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason7after
                                  ? tableData[0]?.mahaSeason7after
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  marginLeft: '30px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason7after
                                  ? tableData[0]?.yalaSeason7after
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div
                          style={{
                            height: '51px',
                            display: 'flex',
                            backgroundColor: '#FFFFFF',
                            marginBottom: '5px',
                            marginBottom: '5px',
                            marginTop: '5px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            borderRadius: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <Col md={6}>
                            {/* <p>Situation</p> */}
                            <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                              8. Insurance compensation paid for crop damages (Rs/ac)
                            </span>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason8before
                                  ? tableData[0]?.mahaSeason8before
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginLeft: '30px',
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason8before
                                  ? tableData[0]?.yalaSeason8before
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason8after
                                  ? tableData[0]?.mahaSeason8after
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  marginLeft: '30px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason8after
                                  ? tableData[0]?.yalaSeason8after
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div
                          style={{
                            height: '51px',
                            display: 'flex',
                            backgroundColor: '#FFFFFF',
                            marginBottom: '5px',
                            marginBottom: '5px',
                            marginTop: '5px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            borderRadius: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <Col md={6}>
                            {/* <p>Situation</p> */}
                            <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                              9. Any other compensation paid by the government? (Rs/ac)
                            </span>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason9before
                                  ? tableData[0]?.mahaSeason9before
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginLeft: '30px',
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason9before
                                  ? tableData[0]?.yalaSeason9before
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>

                          <Col md={3} style={{ display: 'flex' }}>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.mahaSeason9after
                                  ? tableData[0]?.mahaSeason9after
                                  : 'N/A'}
                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{
                                  marginTop: '15px',
                                  marginLeft: '30px',
                                  color: '#363636',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* 200 */}
                                {tableData[0]?.yalaSeason9after
                                  ? tableData[0]?.yalaSeason9after
                                  : 'N/A'}
                              </p>
                            </Col>
                          </Col>
                        </div>
                      </Col>
                    </div>
                  </Col>

                  <Col md={6} style={{ marginTop: '25px' }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Aware of your eligibility for crop insurance rolled out in your village
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6} style={{ marginTop: '25px' }}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Yes */}
                      {tableData[0]?.awareOfEligibility!==" " ?tableData[0]?.awareOfEligibility ? tableData[0]?.awareOfEligibility : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        If yes, mention how you know about your enrollment for crop insurance
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Insurance agent */}
                      {tableData[0]?.howYouKnowAboutEnrollment!==" " ?tableData[0]?.howYouKnowAboutEnrollment
                        ? tableData[0]?.howYouKnowAboutEnrollment.split("-").join(" ")
                        : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Have adequate knowledge about crop coverage
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Not aware */}
                      {tableData[0]?.doYouhaveadaquate!==" " ?tableData[0]?.doYouhaveadaquate ? tableData[0]?.doYouhaveadaquate.split("-").join(" ") : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Have adequate knowledge about premium amount
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Somewhat aware */}
                      {tableData[0]?.premiumAmount!==" " ?tableData[0]?.premiumAmount ? tableData[0]?.premiumAmount.split("-").join(" ") : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Have adequate knowledge about trigger points for payments
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Somewhat aware */}
                      {tableData[0]?.triggerPoints!==" " ?tableData[0]?.triggerPoints ? tableData[0]?.triggerPoints.split("-").join(" ") : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Have adequate knowledge about sum insured
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Fully aware */}
                      {tableData[0]?.sumInsured!==" " ?tableData[0]?.sumInsured ? tableData[0]?.sumInsured.split("-").join(" ") : 'N/A':"N/A"}
                    </p>
                  </Col>
                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        The enrolled insurance was a part of a bundled solution, including seeds/
                        weather/ <br /> agronomic advisories
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Yes */}
                      {tableData[0]?.isInsuranceEnrolledPartOfbundled!==" " ?tableData[0]?.isInsuranceEnrolledPartOfbundled
                        ? tableData[0]?.isInsuranceEnrolledPartOfbundled
                        : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={12}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        If yes,
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          fontWeight: 'bold',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        1. Mention if you receive weather/agronomic advisories on time
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Yes */}
                      {tableData[0]?.didYouReceiveWeatherAdvisorOnTime!==" " ?tableData[0]?.didYouReceiveWeatherAdvisorOnTime
                        ? tableData[0]?.didYouReceiveWeatherAdvisorOnTime
                        : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          fontWeight: 'bold',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        2. Mention if the language used in the advisories clear
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Sometimes */}
                      {tableData[0]?.isTheLanguageUsedAdvisorUseful!==" " ?tableData[0]?.isTheLanguageUsedAdvisorUseful
                        ? tableData[0]?.isTheLanguageUsedAdvisorUseful
                        : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          fontWeight: 'bold',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        3. Mention if the weather advisories useful?
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Very useful */}
                      {tableData[0]?.weatherAdvisorUseful!==" " ?tableData[0]?.weatherAdvisorUseful
                        ? tableData[0]?.weatherAdvisorUseful
                        : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          fontWeight: 'bold',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        4. Mention if you change irrigation application after receiving weather
                        advisories
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Not relevant */}
                      {tableData[0]?.didYouChangeTheirrigation!==" " ?tableData[0]?.didYouChangeTheirrigation
                        ? tableData[0]?.didYouChangeTheirrigation
                        : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          fontWeight: 'bold',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        5. Mention if you change agronomic and or crop management practices after{' '}
                        <br /> receiving weather and/or agronomic advisories?
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Not relevant */}
                      {tableData[0]?.didYouChangeTheAgronomic!==" " ?tableData[0]?.didYouChangeTheAgronomic
                        ? tableData[0]?.didYouChangeTheAgronomic
                        : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          fontWeight: 'bold',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        6. Received seeds bundled with insurance for cultivation in this season
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Yes */}
                      {tableData[0]?.didYouReceiveSeedsbundled!==" " ?tableData[0]?.didYouReceiveSeedsbundled
                        ? tableData[0]?.didYouReceiveSeedsbundled
                        : 'N/A':"N/A"}
                    </p>
                  </Col>

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          color: '#7C8EA0',
                          fontWeight: 'bold',
                          // marginLeft:"10px"

                          marginLeft: '17px',
                        }}
                      >
                        7. Crop yield increase with the given seed variety in bundled solution
                      </p>
                      {/* <p style={{ marginLeft: '91px' }}>2 weeks or less</p> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ marginLeft: '17px', fontWeight: 'bold' }}>
                      {/* Not applicable */}
                      {tableData[0]?.didCropYieldincrease!==" " ?tableData[0]?.didCropYieldincrease
                        ? tableData[0]?.didCropYieldincrease
                        : 'N/A':"N/A"}
                    </p>
                  </Col>

                  {/* </Col> */}
                </Row>
              </fieldset>

              <br />

              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  The Landholding and cultivation details during the last 12 months
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  {/* <Col md={12} style={{ overflowWrap: 'break-word' }}> */}
                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        Mention how you utilize your insurance pay-out/compensation
                        <br />
                        {/* <ol style={{ color: '#000' }}>
                          <li>Payment of debt</li>
                          <li>Purchase of consumer durables (TV, fridge, furniture etc.)</li>
                          <li>Purchase of farm equipment</li>
                        </ol> */}
                        <p style={{ color: '#000' }}>
                          {tableData[0]?.howDidYouutilizeInsurance!==" " ?tableData[0]?.howDidYouutilizeInsurance
                            ? tableData[0]?.howDidYouutilizeInsurance.split("-").join(" ")
                            : 'N/A':"N/A"}
                        </p>
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                      The immediate benefits of the insurance pay-out to your household
                      <br />
                      {/* <ol style={{ color: '#000' }}>
                        <li>Not falling into further debt </li>
                        <li>Not having to reduce household food consumption</li>
                        <li>Repayment of previous season loans</li>
                      </ol> */}
                      <p style={{ color: '#000' }}>
                        {' '}
                        {tableData[0]?.whatAreTheImmediatebenfits!==" " ?tableData[0]?.whatAreTheImmediatebenfits
                          ? tableData[0]?.whatAreTheImmediatebenfits.split("-").join(" ")
                          : 'N/A':"N/A"}
                      </p>
                    </p>
                  </Col>
                  <br />

                  <Col md={6}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                        The person who made/will make decisions on utilization of compensation, if
                        received
                        <br />
                        <span style={{ color: '#000' }}>
                          {/* Female */}
                          {tableData[0]?.whoWillMakedecision!==" " ?tableData[0]?.whoWillMakedecision
                            ? tableData[0]?.whoWillMakedecision.split("-").join(" ")
                            : 'N/A':"N/A"}
                        </span>
                      </p>
                    </div>
                  </Col>

                  <Col md={6}>
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                      Your level of satisfaction on the amount of payout received
                      <br />
                      <span style={{ color: '#000' }}>
                        {/* Satisfied */}
                        {tableData[0]?.levelOfSatisfaction!==" " ?tableData[0]?.levelOfSatisfaction
                          ? tableData[0]?.levelOfSatisfaction.split("-").join(" ")
                          : 'N/A':"N/A"}
                      </span>
                    </p>
                  </Col>

                  <br />
                </Row>
              </fieldset>

              <br />
            </form>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default SurveyDetails;
