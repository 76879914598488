import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import _ from 'lodash';
import { LEGEND_UNIT_CONVERTER } from '../../../Components/common/utils';
import { ERPAS_PARAMETER_SLUG, DATA_SOURCES } from '../../../Components/constants/index';
import moment from 'moment';

function Legend({ map, add, layer, position,activeTabSwitch }) {
  //console.log({layer, position})
  const id = layer?.id + position;
  const handleUnitConverter = () => {
    let legenedEle = document.getElementById(`legend-unit-${id}`);
    let minEle = document.getElementById('minValue');
    let maxEle = document.getElementById('maxValue');
    let unitSelected = '';
    let minVal = '';
    let maxVal = '';
    if (legenedEle) {
      unitSelected = legenedEle.textContent;
    }
    if (minEle) {
      minVal = minEle.textContent;
    }
    if (maxEle) {
      maxVal = maxEle.textContent;
    }
    let converUnit = LEGEND_UNIT_CONVERTER.find((item) => item.unit === unitSelected);
    if (!_.isEmpty(converUnit)) {
      let min = converUnit.convert(minVal);
      let max = converUnit.convert(maxVal);
      if (minEle) {
        minEle.innerHTML = min;
      }
      if (maxEle) {
        maxEle.innerHTML = max;
      }
      let selectedUnit = LEGEND_UNIT_CONVERTER.find((item) => item.unit === converUnit.converter);
      if (!_.isEmpty(selectedUnit)) {
        if (legenedEle) {
          legenedEle.innerHTML = selectedUnit.unit;
        }
      }
    } else {
      return;
    }
  };

  const setUnitTitle = () => {
    let unitsEle = document.getElementById('units-col-div');
    if (unitsEle) {
      let legenedEle = document.getElementById(`legend-unit-${id}`);
      let unitSelected;
      if (legenedEle) {
        unitSelected = legenedEle.textContent;
      }
      let converUnit = LEGEND_UNIT_CONVERTER.find((item) => item.unit === unitSelected);
      if (!_.isEmpty(converUnit)) {
        unitsEle.setAttribute('name', converUnit?.converter);
        let nameEle = unitsEle.getAttribute('name');
        if (!_.isEmpty(nameEle) && nameEle === unitSelected) {
          unitsEle.removeAttribute('title');
        } else {
          let title = `Change units to "${converUnit?.converter}"`;
          unitsEle.setAttribute('title', title);
        }
      }
    }
  };

  const handleLayerLegendParameterName = (data) => {
    if (data?.dataSource_id === DATA_SOURCES.ERPAS.id) {
      let name = '';
      ERPAS_PARAMETER_SLUG?.map((i) => {
        if (i.api_slug === data.parameter_slug) {
          name = i.value;
        }
      });
      return name ? name + (data?.measure ? '(' : '') : '';
    } else if (!_.isEmpty(layer?.sensor_name)) {
      return layer?.parameter;
    } else {
      if (layer.id === 'GLDAS') {
        return layer?.bands
          ? layer.bands + (layer?.measure ? ' (' : '')
          : layer?.measure
          ? ' ('
          : '';
      }
      return layer?.parameter
        ? layer?.parameter + (layer?.measure ? ' (' : '')
        : layer?.measure
        ? ' ('
        : '';
    }
    // else{
    //   if (layer.id==="GLDAS") {
    //    return  layer?.bands?layer.bands + (layer?.measure ? " (" :""):layer?.measure? " (":""
    //   }
    //   return (
    //     layer?.parameter
    //     ? layer?.parameter + (layer?.measure ? ' (' : '')
    //     : layer?.measure
    //     ? ' ('
    //     : ''
    //   )
    // }
  };

  useEffect(() => {
    handleLayerLegendParameterName(layer);
  }, [layer]);

  useEffect(() => {
    if (map) {
      const legend = L?.control({ position: position });
      const percentage = (Math.abs(0) + Math.abs(100)) / layer?.palette?.length;
      let backgroundColor = `linear-gradient(90deg,)`;
      if (layer?.palette?.length === 1) {
        backgroundColor = layer?.palette[0];
      } else {
        for (let i = 0; i < layer?.palette?.length; i++) {
          let colorCode =
            `${layer?.palette[i]} ${Math.round(percentage + i * percentage)}%` +
            `${i === layer?.palette?.length - 1 ? '' : ','}`;
          const index = backgroundColor.lastIndexOf(',');
          const newString =
            backgroundColor.slice(0, index + 1) + colorCode + backgroundColor.slice(index + 1);
          backgroundColor = newString;
        }
      }

      if (add) {
        legend.onAdd = () => {
          let parentDiv = '';
          parentDiv = L.DomUtil.create('div');
          parentDiv.id = id || 'legend_div';
          if (position === 'bottomleft') {
            parentDiv.className = 'left-legend-style';
          }
          else{
            parentDiv.className = 'right-legend-style';
          }

          let datesContainer = L.DomUtil.create('div');
            let spanContainer = L.DomUtil.create('div');
            datesContainer.className = position === 'bottomleft' ? 'datesContainLeftDiv'  : 'datesContainRightDiv' ;
            spanContainer.className = "dates-span-container"
            if (layer?.selectedDates?.startDate && layer?.selectedDates?.endDate){
              //console.log(layer?.startDate, layer?.endDate, position)
              spanContainer.innerHTML = "" + (layer?.selectedDates?.startDate) + " to " + (layer?.selectedDates?.endDate) + " "
              datesContainer.appendChild(spanContainer)
              parentDiv.appendChild(datesContainer)
            }
          let containerDiv = L.DomUtil.create('div');
          containerDiv.className =
            Array.isArray(layer?.palette) === false || layer?.palette?.length !== 0
              ? 'legend-container-custom-div'
              : 'empty-legend-container-div';
          // containerDiv.className =  'legend-container-div';
          let dataSourceDiv = L.DomUtil.create('div');
          dataSourceDiv.className = 'units-legend-style';
          let rowDiv = L.DomUtil.create('div');
          let unitsDiv = L.DomUtil.create('div');          
          let dataSourceText = L.DomUtil.create('p');
          dataSourceText.className = 'legend-units dataSource-style';
          // dataSourceText.innerHTML = layer.dataSource;
          dataSourceText.innerHTML = _.isEmpty(layer?.sensor_name)
            ? layer.dataSource
            : handleLayerLegendParameterName(layer);
          dataSourceDiv.appendChild(dataSourceText);
          let columnOne = L.DomUtil.create('div');
          columnOne.id = 'units-col-div';
          columnOne.className = 'units-legend-style';
          let coloneText = L.DomUtil.create('span');
          coloneText.className = 'legend-units cursor-ponter';
          // coloneText.innerHTML = handleLayerLegendParameterName(layer);
          if (activeTabSwitch==='tab1'){
            coloneText.innerHTML = _.isEmpty(layer?.sensor_name)
            ? handleLayerLegendParameterName(layer)
            : _.isEmpty(layer.measure)
            ? `${layer.sensor_name}-${layer.dataSource}`
            : `${layer.sensor_name}-${layer.dataSource}` + ' (';
          }else{
            coloneText.innerHTML = _.isEmpty(layer?.sensor_name)
            ? handleLayerLegendParameterName(layer)
            : _.isEmpty(layer.measure)
            ? `${layer.dataSource}-${layer.sensor_name}`
            : `${layer.dataSource}-${layer.sensor_name}` + ' (';
          }
            
          let coltwoText = L.DomUtil.create('span');
          coltwoText.className = 'legend-units cursor-ponter';
          coltwoText.id = `legend-unit-${id}`;
          let paramUnits = layer?.measure || '';
          let measure = paramUnits.includes('^') ? paramUnits.split('^') : paramUnits;
          if (Array.isArray(measure)) {
            let superScriptText='';
            coltwoText.innerHTML = measure[0];
            let measure2 = measure[1]?.includes('/') ? measure[1]?.split('/') : '';
            if(measure2){
              superScriptText = L.DomUtil.create('sup');
              superScriptText.innerHTML = measure2[0];
              coltwoText.appendChild(superScriptText);
              let otherUnit = L.DomUtil.create('span');
              otherUnit.innerHTML = `/${measure2[1]}`;
              coltwoText.appendChild(otherUnit)
            }
            else{
              superScriptText = L.DomUtil.create('sup');
              superScriptText.innerHTML = measure[1];
              coltwoText.appendChild(superScriptText);
            }
          } else {
            coltwoText.innerHTML = measure;
          }
          let colThreeText = L.DomUtil.create('span');
          colThreeText.innerHTML = layer?.measure ? ')' : '';
          columnOne.appendChild(coloneText);
          columnOne.appendChild(coltwoText);
          columnOne.appendChild(colThreeText);
          columnOne.onclick = handleUnitConverter;
          columnOne.onmouseover = setUnitTitle;
          unitsDiv.appendChild(columnOne);

          rowDiv.className = 'rowLegend legend-range';
          let columnTwo = L.DomUtil.create('div');
          columnTwo.className = 'columnLegend legend-middle';
          let colTwoText = L.DomUtil.create('p');
          colTwoText.id = 'minValue';
          colTwoText.innerHTML =
            Array.isArray(layer?.palette) === false || layer?.palette?.length !== 0
              ? layer?.min
              : `min : ${layer?.min}`;
          // colTwoText.innerHTML = layer?.min
          colTwoText.className = 'legend-text';
          columnTwo.appendChild(colTwoText);
          rowDiv.appendChild(columnTwo);

          if (Array.isArray(layer?.palette) === false || layer?.palette?.length !== 0) {
            let columnThree = L.DomUtil.create('div');
            columnThree.className = `legend-graphic columnLegend legend-right ${layer?.image}`;
            columnThree.style.background = layer?.palette && backgroundColor;
            rowDiv.appendChild(columnThree);
          }
          let columnFour = L.DomUtil.create('div');
          columnFour.className = 'columnLegend';
          let colFourText = L.DomUtil.create('p');
          colFourText.id = 'maxValue';
          colFourText.innerHTML =
            Array.isArray(layer?.palette) === false || layer?.palette?.length !== 0
              ? layer?.max
              : `max : ${layer?.max}`;
          // colFourText.innerHTML = layer?.max
          colFourText.className = 'legend-text';
          columnFour.appendChild(colFourText);
          rowDiv.appendChild(columnFour);

          containerDiv.appendChild(dataSourceDiv);
          containerDiv.appendChild(rowDiv);
          containerDiv.appendChild(unitsDiv);
          // if (position === 'bottomleft') {
            parentDiv.appendChild(containerDiv);
            return parentDiv;
          // } else {
          //   return containerDiv;
          // }
        };
        if (layer && !_.isEmpty(legend) && !_.isEmpty(map)) {
          if (!_.isEmpty(map?._layers)) {
            legend?.addTo(map);
          }
        }
      } else {
        if (layer) {
          const image = document.getElementById(id || 'legend_div');
          legend.onRemove = () => {
            if (image) {
              L.DomUtil.remove(image);
            }
          };
          legend.onRemove();
        }
      }
    }
  }, [add, map, layer]); //here add map
  return null;
}

export default Legend;
