import React, { useState } from 'react';
import { Carousel as Slider, CarouselItem, CarouselControl, CarouselCaption } from 'reactstrap';
import classnames from 'classnames';
import DownArrow from '../../assets/Images/down-arrow.png';

const Carousel = props => {
  const { carouselData } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === carouselData?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? carouselData?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const scrollToInfo = () => {
    window.scrollTo(0, 320);
  };
  return (
    <>
      {carouselData && carouselData?.length && (
        <>
          <Slider
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            className="carousel-style"
          >
            <ol className="carousel-indicators">
              {carouselData?.length
                ? carouselData?.map((_item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => goToIndex(index)}
                        className={classnames({
                          active: activeIndex === index,
                        })}
                      />
                    );
                  })
                : ''}
            </ol>
            {carouselData && carouselData?.length
              ? carouselData?.map(item => {
                  return (
                    <CarouselItem
                      onExiting={() => setAnimating(true)}
                      onExited={() => setAnimating(false)}
                      key={item.image}
                    >
                      <img src={item.image} alt="image" width="100%" height="100%" />
                      <CarouselCaption captionText={item.title} className="fnt-sze"/>
                    </CarouselItem>
                  );
                })
              : ''}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </Slider>
          <div className="carousel-arrow-wrapper" style={{ position: 'relative' }}>
            <a href="#content">
              <img
                className="carousal-down-arrow"
                onClick={() => scrollToInfo()}
                src={DownArrow}
                alt={'not-available'}
              />
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default Carousel;
