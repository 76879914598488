import { CLIMATE_WEATHER_LOCATION_CONST,DATA_SOURCES_CONST }  from './climateWeatherConst';
const ClimateWeather = (state = {}, action) => {
    switch (action.type) {
      case CLIMATE_WEATHER_LOCATION_CONST.CLIMATE_WEATHER_LOCATION_REQUEST:
        return {
          ...state,
          isLocationDataRequesting: true,
          locationData: false,
          locationDataError: false,
          locationDataNetworkError: false,
        };
      case CLIMATE_WEATHER_LOCATION_CONST.CLIMATE_WEATHER_LOCATION_SUCCESS:
        return {
          ...state,
          isLocationDataRequesting: false,
          locationData: action.payload.response,
          locationDataError: false,
          locationDataNetworkError: false,
        };
      case CLIMATE_WEATHER_LOCATION_CONST.CLIMATE_WEATHER_LOCATION_FAILED:
        return {
          ...state,
          isLocationDataRequesting: false,
          locationData: false,
          locationDataError: action.payload.error ? false : action.payload.response,
          locationDataNetworkError: action.payload.error ? action.payload.error : false,
        };


        case DATA_SOURCES_CONST.DATA_SOURCES_REQUEST:
          return {
            ...state,
            isDataSourcesRequesting: true,
            dataSourcesList: false,
            dataSourcesError: false,
            dataSourcesNetworkError: false,
          };
        case DATA_SOURCES_CONST.DATA_SOURCES_SUCCESS:
          return {
            ...state,
            isDataSourcesRequesting: false,
            dataSourcesList: action.payload.response,
            dataSourcesError: false,
            dataSourcesNetworkError: false,
          };
        case DATA_SOURCES_CONST.DATA_SOURCES_FAILED:
          return {
            ...state,
            isDataSourcesRequesting: false,
            dataSourcesList: false,
            dataSourcesError: action.payload.error ? false : action.payload.response,
            dataSourcesNetworkError: action.payload.error ? action.payload.error : false,
          };

          
        default:
        return { ...state };
    }
  };
  
  export default ClimateWeather;