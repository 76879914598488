import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from 'reactstrap-button-loader';
import { Card, Container, Col, FormGroup, Label, Row, Input } from 'reactstrap';

import { t } from 'react-multi-lang';
import '../../assets/CSS/login.css';
import { showError } from '../../Components/Notifications.js';
import {
  getToken,
  isValidEmail,
  signIn,
  hasPermissions,
  hasStaffUserPermission,
  hasInsuranceAdminUserPermission,
} from '../../Components/common/utils';
import { loginUser, loginUserSuccess } from '../../redux/login/loginActions';
import { renderTextField } from '../../Components/common/renderInputField';
import { Field, reduxForm } from 'redux-form';
import { change } from 'redux-form';

import { SCOPE, discoveryUrl, CLIENT_ID, API_KEY } from '../../Components/constants/index';
import Cookies from 'js-cookie';

const validate = (values) => {
  const errors = {};

  const requiredFields = ['email', 'password'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (!values.email) {
    errors.email = 'Please enter your email.';
  } else if (!isValidEmail(values?.email.trim())) {
    errors.email = t('LOGIN.EMAIL_ERROR_MSG');
  }

  if (!values.password) {
    errors.password = 'Please enter your password.';
  }
  if (values?.password?.includes(' ')) {
    errors.password = t('REGISTER.PASSWORD_SPACE_ERROR');
  }

  //  else if(values?.email.length > 25){
  //   errors.email = t('LOGIN.EMAIL_LENGTH_ERROR_MESSAGE');
  // }

  //  if(values.password && (values.password.length < 8 || values.password.length >16)){
  //   errors.password = 'Password must be between 8 to 16 characters';
  // }
  return errors;
};

const LoginPage = (props) => {
  const { handleSubmit, pristine, submitting, invalid } = props;
  const dispatch = useDispatch();
  let history = useHistory();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [passwordType, setPasswordType] = useState(true);
  const nextProps = useSelector((state) => ({
    loginData: state.Login.loginData || null,
    isLoginRequesting: state.Login.isLoginRequesting,
    loginDataError: state.Login.loginDataError,
  }));

  const [signedInUser, setSignedInUser] = useState('');
  const [signedInUserDetails, setSignedInUserDetails] = useState('');

  
  const [isOptionSelected, setIsOpionSelected] = useState([
    { name: 'Insurance Company', value: 'InsuranceCompany', selected: false },
    { name: 'Others', value: 'Others', selected: true },
  ]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (getToken()) {
      history.push('/home');
    } else {
      history.push('/login');
    }
  }, [history]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps.loginData) {
      let tokenData = {};
      tokenData = {
        token: nextProps.loginData.data.result.token,
        userDetails: nextProps.loginData.data.user_details,
      };
      signIn(tokenData);
      hasPermissions(nextProps.loginData.data.user_details[0].is_superuser);
      hasStaffUserPermission(nextProps.loginData.data.user_details[0].is_staff);
      hasInsuranceAdminUserPermission(nextProps.loginData.data.user_details[1].is_insurance_admin);
      let selectedModule = Cookies.get('selected-module');
      if(selectedModule){
        history.push(`/${selectedModule}`)
        Cookies.remove('selected-module', { path: '/' })
      }
      else if (
        // nextProps.loginData.data.user_details[0].is_staff ||
        // nextProps.loginData.data.user_details[1].is_insurance_admin
        nextProps.loginData.data.user_details[0].is_superuser || nextProps.loginData.data.user_details[0].is_staff
      ) {
        // history.push('/settingsDashboard');
        history.push('/settingsDashboard?from=login');
      } else {
        history.push('/home');
      }
    } else {
      history.push('/login');
    }
  }, [nextProps.loginData, history]);

  useEffect(() => {
    if (
      nextProps.loginDataError &&
      nextProps.loginDataError.data &&
      nextProps.loginDataError.data.response &&
      nextProps.loginDataError.data.response.data &&
      nextProps.loginDataError.data.response.data.code
    ) {
      showError(t('LOGIN.EMAIL_PASSWORD_ERROR_MESSAGE'));
    }
  }, [nextProps.loginDataError]);

  useEffect(() => {
    return () => {
      dispatch(loginUserSuccess({}));
    };
  }, [dispatch]);
  const onFormSubmit = () => {
    let formDetails = {};
    
    formDetails = {
      email: formData.email,
      password: formData.password,
      
    };
    dispatch(loginUser(formDetails));
  };
  const handleChangeEmail = (e) => {
    formData.email = e.target.value;
    setFormData({ ...formData });
  };

  const showPassword = () => {
    setPasswordType(!passwordType);
  };

  const handleChangePassword = (e) => {
    formData.password = e.target.value;
    setFormData({ ...formData });
  };

  const handleForgotPassword = () => {
    history.push('/forgot-password');
  };

  const handleOptionToggle = ({ target }) => {
    

    formData.organization = '';
    setFormData({ ...formData });

    let compareData = isOptionSelected;
    const nextCompare = compareData.map((item) => {
      if (item.value === target.value) {
        return {
          ...item,
          selected: target.checked,
        };
      } else {
        return {
          ...item,
          selected: false,
        };
      }
    });
    setIsOpionSelected(nextCompare);
  };

  const handleClientLoad = () => {
    setLoading(true);
  };

  return (
    <>
      <div className="login-wrapper">
        <Container fluid className="p-0">
          <Row>
            <Col lg={5} sm={7} xs={12} className="mx-auto login-form-wrapper login-zIndex w-100">
              <div className="login-card-wrapper" id="login-card-wrapper">
                <Card className="card-shadow py-2 px-5 login-card">
                  <div className="login-form w-100">
                    <div className="pb-3">
                      <h5 className="login-key-text mt-5">{t('LOGIN.LOGIN_TITLE')}</h5>
                    </div>
                    
                    
                      <form id="LoginPageForm" onSubmit={handleSubmit(() => onFormSubmit())}>
                        <FormGroup className="textOnInput mb-3">
                          <Label for="exampleEmail">{t('LOGIN.EMAIL')}</Label>
                          <Field
                            component={renderTextField}
                            type="text"
                            name="email"
                            onChange={(e) => handleChangeEmail(e)}
                            className="email-input"
                            
                          />
                        </FormGroup>
                        <FormGroup className="textOnInput mb-3">
                          <Label for="exampleEmail">{t('LOGIN.PASSWORD')}</Label>
                          <Field
                            component={renderTextField}
                            className="password-input"
                            name="password"
                            bsSize="sm"
                            type={passwordType ? 'password' : 'text'}
                            onChange={(e) => handleChangePassword(e)}
                          />
                          <span className="reset-password-show-hide" onClick={showPassword}>
                            {passwordType ? t('LOGIN.SHOW') : t('LOGIN.HIDE')}
                          </span>
                        </FormGroup>
                        
                        <FormGroup>
                          <Label className="forgot-password-label">
                            <p
                              className="forgot-password-link"
                              onClick={() => handleForgotPassword()}
                            >
                              {t('LOGIN.FORGOT_PASSWORD')}
                            </p>
                          </Label>
                        </FormGroup>
                        {/* </div> */}
                        <Button
                          type="submit"
                          className="btn login-btn-grn-key btn-block w-100"
                          loading={nextProps.isLoginRequesting}
                          disabled={pristine || submitting}
                        >
                          {t('LOGIN.SIGN_IN')}
                        </Button>
                      </form>
                    {/* )} */}

                    <div className="text-center w-100 mb-3">
                      <p className="m-0 p-0 login-term-text mt-3">
                        {t('LOGIN.HAVE_ACCOUNT')}{' '}
                        <span className="register-link" onClick={() => history.push('/register')}>
                          {t('LOGIN.SIGN_UP')}
                        </span>
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
          <div className="login-footer"></div>
        </Container>
      </div>
    </>
  );
};
export default reduxForm({
  form: 'LoginPageForm',
  validate,
})(LoginPage);
