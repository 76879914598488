import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { HiDotsHorizontal, HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { t } from 'react-multi-lang';
import { ReactComponent as FarmDefaultIcon } from '../../../assets/Images/farm_default_icon.svg';
import { ReactComponent as ProjectListIcon } from '../../../assets/Images/ProjectListIcon.svg';
import { renderProjectDateFormat } from '../../../Components/common/utils';

const ProjectListing = props => {
  const {
    projectList,
    // farmTrackerList,
    // toggle,
    // getSelectedFarm,
    // handleDelete,
    // getFarmData,
    getFarmListContainer,
  } = props;

  const handleDisplayFarmList = (e, item) => {
    // e.stopPropagation();
    //getSelectedFarm(item);
    getFarmListContainer(item);
  };

  return (
    <>
      <div className="farm-list-scroll scrollbar-hidden">
        {projectList?.length ? (
          projectList?.map((item, index) => {
            return (
              <>
                <div>
                  <Row>
                    {/* <Col onClick={e => handleDisplayFarmList(e, item)}> */}
                    <Col>
                      <Card
                        className="farm-track-list-st mb-1 cursor-ponter"
                        //onClick={() => getFarmData(item)}
                      >
                        <CardBody>
                          <Row className="mx-1 listing-st">
                            <Col sm="7" lg="7" md="7">
                              <Row>
                                {' '}
                                <Col>{_.capitalize(item?.name)}</Col>
                              </Row>
                              <Row className="date-st">
                                <Col>{`Location: ${item.state_name}, ${item.district_name}`}</Col>
                              </Row>
                            </Col>
                            <Col
                              style={{
                                fontFamily: 'National',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '13px',
                                color: '#8a8a8a',
                              }}
                              sm="5"
                              lg="5"
                              md="5"
                            >
                              Last Updated {renderProjectDateFormat(item?.updated_date)}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </>
            );
          })
        ) : (
          <div className="mx-1 draw-msg-st">
            <div className="mt-1">{t('FARM_TRACKER.NO_FARMS')}</div>
            <div className="sub-text mt-1"> {t('FARM_TRACKER.DRAW_FARM_TEXT')}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectListing;
