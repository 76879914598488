import {
  ACCESS_KEY_CONST,
  GET_ALL_BY_TYPE_CONST,
  GET_BY_ID_CONST,
  GET_PARAMETERS_TYPE_CONST,
  CHANGE_STATUS_CONST,
  DELETE_CONST,
  GET_INDICES_CONST,
  GET_GLDAS_CONST
} from './accessKeyConsts';

export const getAccessKeyRequest = userDetails => ({
  type: ACCESS_KEY_CONST.GET_ACCESS_KEY_REQUEST,
  payload: userDetails,
});

export const getAccessKeySuccess = response => ({
  type: ACCESS_KEY_CONST.GET_ACCESS_KEY_SUCCESS,
  payload: response,
});

export const getAccessKeyFailed = error => ({
  type: ACCESS_KEY_CONST.GET_ACCESS_KEY_FAILED,
  payload: error,
});

export const getAllByTypeRequest = data => ({
  type: GET_ALL_BY_TYPE_CONST.GET_ALL_BY_TYPE_REQUEST,
  payload: data,
});

export const getAllByTypeSuccess = response => ({
  type: GET_ALL_BY_TYPE_CONST.GET_ALL_BY_TYPE_SUCCESS,
  payload: response,
});

export const getAllByTypeFailed = error => ({
  type: GET_ALL_BY_TYPE_CONST.GET_ALL_BY_TYPE_FAILED,
  payload: error,
});

export const getByIdRequest = id => ({
  type: GET_BY_ID_CONST.GET_BY_ID_REQUEST,
  payload: id,
});

export const getByIdSuccess = response => ({
  type: GET_BY_ID_CONST.GET_BY_ID_SUCCESS,
  payload: response,
});

export const getByIdFailed = error => ({
  type: GET_BY_ID_CONST.GET_BY_ID_FAILED,
  payload: error,
});

export const getParametersRequest = type => ({
  type: GET_PARAMETERS_TYPE_CONST.GET_PARAMETERS_TYPE_REQUEST,
  payload: type,
});

export const getParametersSuccess = response => ({
  type: GET_PARAMETERS_TYPE_CONST.GET_PARAMETERS_TYPE_SUCCESS,
  payload: response,
});

export const getParametersFailed = error => ({
  type: GET_PARAMETERS_TYPE_CONST.GET_PARAMETERS_TYPE_FAILED,
  payload: error,
});

export const changeStatusRequest = data => ({
  type: CHANGE_STATUS_CONST.CHANGE_STATUS_CONST_REQUEST,
  payload: data,
});

export const changeStatusSuccess = response => ({
  type: CHANGE_STATUS_CONST.CHANGE_STATUS_CONST_SUCCESS,
  payload: response,
});

export const changeStatusFailed = error => ({
  type: CHANGE_STATUS_CONST.CHANGE_STATUS_CONST_FAILED,
  payload: error,
});

export const deleteRequest = id => ({
  type: DELETE_CONST.DELETE_CONST_REQUEST,
  payload: id,
});

export const deleteSuccess = response => ({
  type: DELETE_CONST.DELETE_CONST_SUCCESS,
  payload: response,
});

export const deleteFailed = error => ({
  type: DELETE_CONST.DELETE_CONST_FAILED,
  payload: error,
});

export const getIndicesRequest = data => ({
  type: GET_INDICES_CONST.GET_INDICES_REQUEST,
  payload: data,
});

export const getIndicesSuccess = response => ({
  type: GET_INDICES_CONST.GET_INDICES_SUCCESS,
  payload: response,
});

export const getIndicesFailed = error => ({
  type: GET_INDICES_CONST.GET_INDICES_FAILED,
  payload: error,
});



export const getGLDASRequest = data => ({
  type: GET_GLDAS_CONST.GET_GLDAS_REQUEST,
  payload: data,
});

export const getGLDASSuccess = response => ({
  type: GET_GLDAS_CONST.GET_GLDAS_SUCCESS,
  payload: response,
});

export const getGLDASFailed = error => ({
  type: GET_GLDAS_CONST.GET_GLDAS_FAILED,
  payload: error,
});
