import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, USER_URL } from '../api/apiEndPoint';
import {LOGIN_CONST,LOGIN_DETAIL_CONST, FORGOT_PASSWORD, CHANGE_PASSWORD, RESET_PASSWORD,PROJECT_FARM_LIST_CONST , INSURANCE_USER_UPDATE_CONST} from './loginConst';
import { loginUserSuccess, loginUserFailed,loginDetailSuccess,loginDetailFailed,forgotPasswordSuccess,forgotPasswordFailed,changePasswordSuccess, changePasswordFailed, resetPasswordSuccess, resetPasswordFailed,projectFarmListSuccess,projectFarmListFailed , insuranceUserUpdateSuccess , insuranceUserUpdateFailed} from './loginActions';
import baseMethod from '../baseService.js';
import { checkHttpStatus } from '../api/apiUtils';

require('dotenv').config();

function* loginUser(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_URL}/login`, action.payload),
      "",
      true,
      true
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      localStorage.setItem("userDetails",  JSON.stringify(response?.data));
      yield put(loginUserSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(loginUserFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(loginUserFailed(errorData));
  }
}

function* loginDetailUser(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${USER_URL}/loginUserDetail`, action.payload),
      "",
      true,
      true
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      localStorage.setItem("userPermissions",  JSON.stringify(response?.data));
      yield put(loginDetailSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(loginDetailFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(loginDetailFailed(errorData));
  }
}


function* forgotPassword(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_URL}/forgotPassword`, action.payload),
      "",
      false,
      true
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(forgotPasswordSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(forgotPasswordFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(forgotPasswordFailed(errorData));
  }
}

function* changePassword(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(`${USER_URL}/changePassword`,action.payload),
      "",
      true,
      true
    )
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(changePasswordSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(changePasswordFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(changePasswordFailed(errorData));
  }
}


function* resetPassword(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(`${USER_URL}/resetPassword`,action.payload),
      "",
      true,
      true
    )
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(resetPasswordSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(resetPasswordFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(resetPasswordFailed(errorData));
  }
}

function* projectFarmList(action) {
  try {
    const apiResponse = action.payload?.user_id !== undefined ? baseMethod(
      AXIOS_INSTANCE.get(`${USER_URL}/ProjectFarmList?${action.payload.pageKey}=${action.payload.page}&&${action.payload.limitKey}=${action.payload.limit}&&user_id=${action.payload?.user_id}`),
    ) : '';
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(projectFarmListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(projectFarmListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(projectFarmListFailed(errorData));
  }
}


function* insuranceUserUpdaterequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.put(`${USER_URL}/update_odk`, action.payload),
      '',
      false,
      false,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(insuranceUserUpdateSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(insuranceUserUpdateFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(insuranceUserUpdateFailed(errorData));
  }
}

/**
 *  Login carousel
 */

export function* watchLoginUser() {
  yield takeEvery(LOGIN_CONST.LOGIN_REQUEST, loginUser);
  yield takeEvery(LOGIN_DETAIL_CONST.LOGIN_DETAIL_REQUEST, loginDetailUser)
  yield takeEvery(FORGOT_PASSWORD.FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeEvery(CHANGE_PASSWORD.CHANGE_PASSWORD_REQUEST, changePassword);
  yield takeEvery(RESET_PASSWORD.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeEvery(PROJECT_FARM_LIST_CONST.PROJECT_FARM_LIST_REQUEST, projectFarmList);
  yield takeEvery(INSURANCE_USER_UPDATE_CONST.INSURANCE_USER_UPDATE_REQUEST, insuranceUserUpdaterequest);

}

function* loginSaga() {
  yield all([fork(watchLoginUser)]);
}

export default loginSaga;
