import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-multi-lang';
import Button from 'reactstrap-button-loader';
import WeatherMonitoringTransparentIcon from '../../assets/Images/icons/weather_monitoring.png';
// import WeatherMonitoringTransparentIcon from '../../assets/Images/icons/weather-monitoring-transparent-icon.svg';
import WeatherForecastYellowIcon from '../../assets/Images/icons/weather-forecast-icon-yellow.svg';
import WeatherForecastTransparentIcon from '../../assets/Images/icons/weather_forecast.png';
// import WeatherForecastTransparentIcon from '../../assets/Images/icons/weather-forecast-transparent-icon.svg';
import WeatherMonitoringYellowIcon from '../../assets/Images/icons/weather-monitoring-yellow-icon.svg';
import ContinueIcon from '../../assets/Images/icons/continue-icon.svg';

const WeatherForecastModal = (props) => {
  const { modalOpen, setModalOpen } = props;
  const [selectedTab, setSelectedTab] = useState();
  const t = useTranslation();
  const history = useHistory();
  const [modal, setModal] = useState();
  useEffect(() => {
    setModal(modalOpen);
  }, [modalOpen]);

  const viewtoggle = (value) => {
    setModal(false);
  };

  const handleClose = () => {
    setModal(false);
    setModalOpen(false);
  };

  const handleTab = (value, click) => {
    if (value === 'weather-monitoring') {
      setSelectedTab('weather-monitoring');
      history.push(`/weather-monitoring?key=${value}`);
    } else if (value === 'weather-forecast') {
      setSelectedTab('weather-forecast');
      history.push(`/weather-forecast?key=${value}`);
    }
    // if (click === 'doubleClicked') {
    //   if (value === 'agriculture-monitor') {
    //     history.push(`crop-health-monitoring?key=agriculture`);
    //   } else if (value === 'water-monitor') {
    //     history.push(`/crop-health-water?key=water`);
    //   }
    // }
  };

  const handleContinue = () => {
    if (selectedTab === 'weather-forecast') {
      history.push(`/weather-forecast?key=${selectedTab}`);
    } else if (selectedTab === 'weather-monitoring') {
      history.push(`/weather-monitoring?key=${selectedTab}`);
    }
  };

  return (
    <>
      <div className="client-modal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className="weather-forecast-modal modal-lg"
          backdrop="static"
        >
          <ModalHeader className="weather-forecast-modal-title" toggle={handleClose}>
            {/* {t('WEATHER_FORECAST_MODAL.WEATHER_FORECAST_MODAL_TITLE')} */}
            Weather & Water
          </ModalHeader>
          <ModalBody>
            <Row className='constant-height'>
              <Col sm={6} md={6} lg={6}>
               {/* {selectedTab === 'weather-forecast' ? (
                  <img
                    alt="weather-forecast"
                    src={WeatherForecastYellowIcon}
                    onClick={() => handleTab('weather-forecast')}
                    onDoubleClick={() => handleTab('weather-forecast', 'doubleClicked')}
                    className="cursor-pointer right-align"
                  />
                ) : (
                  <img
                    alt="weather-forecast"
                    src={WeatherForecastTransparentIcon}
                    onClick={() => handleTab('weather-forecast')}
                    onDoubleClick={() => handleTab('weather-forecast', 'doubleClicked')}
                    className="cursor-pointer right-align"
                  />
                )} */}
                  <img
                    alt="weather-forecast"
                    src={WeatherForecastTransparentIcon}
                    onClick={() => handleTab('weather-forecast')}
                    // onDoubleClick={() => handleTab('weather-forecast', 'doubleClicked')}
                    className="cursor-pointer right-align"
                    onMouseOver={e => (e.currentTarget.src = WeatherForecastYellowIcon)} 
                    onMouseOut={e => (e.currentTarget.src = WeatherForecastTransparentIcon)}
                  />
              </Col>
              <Col sm={6} md={6} lg={6}>
                {/* {selectedTab === 'weather-monitoring' ? (
                  <img
                    alt="weather-monitoring"
                    src={WeatherMonitoringYellowIcon}
                    onClick={() => handleTab('weather-monitoring')}
                    onDoubleClick={() => handleTab('weather-monitoring', 'doubleClicked')}
                    className="cursor-pointer"
                  />
                ) : (
                  <img
                    alt="weather-monitoring"
                    src={WeatherMonitoringTransparentIcon}
                    onClick={() => handleTab('weather-monitoring')}
                    onDoubleClick={() => handleTab('weather-monitoring', 'doubleClicked')}
                    className="cursor-pointer"
                  />
                )} */}
                  <img
                    alt="weather-monitoring"
                    src={WeatherMonitoringTransparentIcon} 
                    onClick={() => handleTab('weather-monitoring')}
                    // onDoubleClick={() => handleTab('agriculture-monitor', 'doubleClicked')}
                    className="cursor-pointer"
                    onMouseOver={e => (e.currentTarget.src = WeatherMonitoringYellowIcon)} 
                    onMouseOut={e => (e.currentTarget.src = WeatherMonitoringTransparentIcon)}
                  />
              </Col>
            </Row>
            <hr />
            <Row>
              <p className="weather-forecast-modal-desc">
                This tool offers short-term to seasonal weather forecasts as well as historical
                weather data to guide users for drought early warning and management measures. It
                helps to analyze the current and future weather using different data sources.It
                provides the users with a flexibility to take an in-depth view of weather across the
                provinces in the country.
              </p>
            </Row>
          </ModalBody>
          {/* <ModalFooter className="modal-viewdeatil-footer">
            <Row>
              <span>
                <Button
                  className="weather-forecast-continue-button"
                  onClick={() => handleContinue()}
                >
                  Continue
                  <img src={ContinueIcon} alt="continue-icon" />
                </Button>
              </span>
            </Row>
          </ModalFooter> */}
        </Modal>
      </div>
    </>
  );
};
export default WeatherForecastModal;
