import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Card, Container } from 'reactstrap';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SettingIcon from '../assets/Images/dashboard-img.png';
import ManageStaticIcon from '../assets/Images/dashboard-img1.png';
import ManageUserIcon from '../assets/Images/dashboard-img2.png';
import ManageUserGuideIcon from '../assets/Images/manage_user_guide_icon.png';
import { isStaffUser, isInsuranceAdmin, isSuperAdmin , getToken } from './common/utils';
import Loader from '../Components/common/loader';
import { loginDetailrequest, loginDetailSuccess } from '../redux/login/loginActions';
import { userPermissionsRequest } from '../redux/manage-user/manageUserAction';

import FieldDataIcon from '../assets/Images/fieldIcon.png';
import SurveyConfigurationIcon from '../assets/Images/survey-configuration.png';
import { BsArrowLeft } from 'react-icons/bs';
import { SCOPE, discoveryUrl, CLIENT_ID, API_KEY } from '../Components/constants/index';

import ManageSurveyorsIcon from '../assets/Images/manage-surveyor.png';
import { insuranceUserUpdaterequest } from '../redux/login/loginActions';

import _ from 'lodash';

import { showError } from './Notifications';


const SettingsDashboard = () => {
  let params = new URL(document.location).searchParams;
  const [loginDetails, setLoginDetails] = useState({});

  const [signedInUserDetails, setSignedInUserDetails] = useState('');
  const [userDetails, setUserDetails] = useState('');

  let queryParam = params.get('from');
  const cardsInfo = useMemo(
    () => [
      {
        title: 'Manage User',
        icon: ManageUserIcon,
        path: '/manage-user',
        group_name: 'Manage Users',
        is_insurance_admin: false,
        discription:
          'Administrators can manage user access and can approve, reject and delete new requests.',
      },
      {
        title: 'Manage Static Data',
        icon: ManageStaticIcon,
        path: '/manageStaticData',
        group_name: 'Manage Static Resources',
        is_insurance_admin: false,
        // group_name: 'Manage Users',
        discription: 'Administrators can upload static data and related images.',
      },
      {
        title: 'Manage User Guide',
        icon: ManageUserGuideIcon,
        path: '/manage-userguide',
        is_insurance_admin: false,
        group_name: 'Manage User Guide',
        discription: 'Administrators can upload and edit data for user guide.',
      },
    ],
    [],
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [cardList, setCardList] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [cardsInformation, setCardsInformation] = useState();

  const [showSurveyData, setShowSurveyData] = useState(true);

  const [path, setPath] = useState('');

  const nextProps = useSelector((state) => ({
    loginData: state.Login.loginData,
    loginDetailData: state.Login.loginDetailData || null,
    userPermissionsData: state.UserList.userPermissionsData || null,
    isUserPermissionsRequesting: state.UserList.isUserPermissionsRequesting,
    isLoginDetailRequesting: state.UserList.isLoginDetailRequesting,
    insuranceUserUpdateData: state.Login.insuranceUserUpdateData,
  }));

  useEffect(() => {
    if (nextProps.loginDetailData) {
      setUserDetails(nextProps?.loginDetailData?.data?.result?.user_profile);
    }
  }, [nextProps.loginDetailData?.data?.result]);

  const handleClientLoad = () => {
  };

  useEffect(() => {
    // if (!_.isEmpty(userDetails?.google_email)) {
    if (!_.isEmpty(signedInUserDetails) && !_.isEmpty(userDetails)) {
      if (!_.isEmpty(userDetails?.google_email)) {
        if (userDetails?.google_email === signedInUserDetails) {
          history.push(path);
        } else {
          showError(
            `Email you have authenticated in browser is not related with your organization please authenticate with this email ${userDetails?.google_email}`,
          );
        }
      } else {
        history.push(path);
      }
    }
    // }
    // else {
    //   history.push(path);
    // }
  }, [signedInUserDetails, nextProps.loginDetailData]);

  useEffect(() => {
    return () => {
      dispatch(loginDetailSuccess({}));
    };
  }, []);

  useEffect(() => {
    let getLoginDeatils = JSON.parse(localStorage.getItem('userDetails'));
    setLoginDetails(getLoginDeatils);

    // if (!isSuperAdmin()) {
    // if (isStaffUser() || isInsuranceAdmin()) {
    if(getToken()){

    dispatch(loginDetailrequest());
    if (isStaffUser()) {
      dispatch(userPermissionsRequest());
    }
  }
  else {
    history.push("/home")

  }
    // }
    // } else {
    //   history.push('/home');
    // }
    // }
  }, [dispatch, history]);

  useEffect(() => {
    if (nextProps.userPermissionsData) {
      const { data } = nextProps.userPermissionsData;
      if (data) {
        cardsInfo.forEach((i) => {
          let b = [];
          data.forEach((item) => {
            if (i.group_name === item.group_name) {
              b.push(item.code_name);
            }
            i.code_name_list = b;
          });
        });
        setCardsInformation(cardsInfo);
      }
    } else if (
      loginDetails?.user_details?.[1]?.is_insurance_admin === true &&
      loginDetails?.user_details?.[0]?.is_staff === false
    ) {
      if (userPermissions) {
        // const { data } = userPermissions;
        // if (data) {
        cardsInfo.forEach((i) => {
          let b = [];
          userPermissions.forEach((item) => {
            // if (i.group_name === item.group_name) {
            b.push(item.codename);
            // }
            i.code_name_list = b;
          });
        });
        setCardsInformation(cardsInfo);
        // }
      }
    }
  }, [nextProps.userPermissionsData, userPermissions, cardsInfo, loginDetails]);

  useEffect(() => {
    if (nextProps.loginDetailData) {
      const {
        data: { result },
      } = nextProps.loginDetailData;
      if (result?.user_permissions) {
        setUserPermissions(result?.user_permissions);
      }
    }
  }, [nextProps.loginDetailData]);

  useEffect(()=>{
    let B = [];
    let showSurveyDataSettings = false;
    if (nextProps.loginDetailData && nextProps?.userPermissionsData) {
      const { data: { result : permissions }} = nextProps.loginDetailData;
      const { data } = nextProps.userPermissionsData;
      if(permissions.user_permissions && data){
        data.forEach((item) => {
          if (item.group_name === 'Manage ODK Data') {
            B.push(item.code_name);
          }
        });
        permissions.user_permissions?.map((item, key) => {
          if (B.includes(item.codename)) {
            showSurveyDataSettings = true;
          }
        });
        setShowSurveyData(showSurveyDataSettings);
      }

    }
  },[nextProps.loginDetailData,nextProps.userPermissionsData])

  const handleCards = React.useCallback(() => {
    /* function body */
    let a = [];
    if (
      userPermissions?.length &&
      loginDetails?.user_details?.[1]?.is_insurance_admin === true &&
      loginDetails?.user_details?.[0]?.is_staff === false
    ) {
      // INSURANCE ADMIN
      cardsInformation?.forEach((i) => {
        // if (loginDetails?.user_details?.[1]?.is_insurance_admin === i?.is_insurance_admin){
        userPermissions?.forEach((item) => {
          // if (i.code_name_list.includes(item.codename)) {
          if (!a.includes(i)) {
            if (i.is_insurance_admin === true) {
              a.push(i);
            }
          }
          // }
        });
        // }
      });
      setCardList(a);
    } else if (
      loginDetails?.user_details?.[0]?.is_staff &&
      loginDetails?.user_details?.[0]?.is_superuser === false
    ) {
      // STAFF ADMIN
      cardsInformation?.forEach((i) => {
        userPermissions?.forEach((item) => {
          if (i.code_name_list.includes(item.codename)) {
            if (!a.includes(i)) {
              a.push(i);
            }
          }
        });
      });
      setCardList(a);
    } else if (
      loginDetails?.user_details?.[0]?.is_superuser &&
      loginDetails?.user_details?.[0]?.is_staff
    ) {
      //SUPER USER
      if (cardsInformation?.length) {
        cardsInformation?.forEach((i) => {
          // if (loginDetails?.user_details?.[1]?.is_insurance_admin === i?.is_insurance_admin){
          // if (!a.includes(i)) {
          if (
            i?.title !== 'Survey Configuration' &&
            i?.title !== 'Manage Surveyors' &&
            i?.title !== 'Field Data'
          ) {
            a.push(i);
          }
          // }
        });
        setCardList(a);
      }
    }
    // else if (isStaffUser() || isInsuranceAdmin()) {
    //   console.log(cardsInfo,'555555555')
    //   setCardList(cardsInfo);
    // }
  }, [userPermissions, loginDetails, cardsInfo, cardsInformation]);

  // const handleCards = list => {
  //   let a = [];
  //   if (list.length) {
  //     cardsInformation?.forEach(i => {
  //       list?.forEach(item => {
  //         if (i.code_name_list.includes(item.codename)) {
  //           if (!a.includes(i)) {
  //             a.push(i);
  //           }
  //         }
  //       });
  //     });
  //     setCardList(a);
  //   } else if (isSuperAdmin()) {
  //     setCardList(cardsInfo);
  //   }
  // };

  useEffect(() => {
    if (cardsInformation?.length && userPermissions) {
      handleCards(userPermissions);
    }
  }, [cardsInformation, userPermissions, handleCards]);

  const handleCardClick = (i) => {
    if (i.group_name === 'Manage ODK Data') {
      setPath(i.path);
      // handleClientLoad();
    } else {
      history.push(i.path);
    }
  };

  // const cardInfo = [
  //   {discription:''},
  //   {discription:''},
  //   {discription:''},
  //   {discription:'Admin can view the data for different components and it supports the draw farm feature.'},
  //   {discription:'Admin can configure the forms for the selected component and can invite people.'},
  //   {discription:'Admin can view and delete the list of users to whom access has been provided.'},

  // ];

  const staticCards = [
    {
      title: 'Weather & Water',
      url: '/weather-forecast',
      discription:
        'This tool offers short-term to seasonal weather forecasts as well as historical weather data to guide users for drought early warning and management measures. It helps to analyze the current and future weather using different data sources.It provides the users with a flexibility to take an in-depth view of weather across the provinces in the country.',
    },
    {
      title: 'Crop Health Monitor',
      url: '/crop-health-monitoring?key=agriculture',
      discription:
        'The tool allows the users to monitor past and current conditions of crop and available water resources which helps to determine the drought/flood conditions to promote proactive disaster management measures for the insurance.It utilizes modern and latest information to monitor the condition of crops. It enables the farmer/user to implement timely interventions that ensure optimal yields at the end of the season.',
    },
    {
      title: 'Farm Tracker',
      url: '/farms-list',
      discription:
        'Capable of digitizing farm by the user. Users can also add several attributes to the farm (example- type, water resources). ODK tool with in-built survey form will integrate farm- level details in the EO4ARM platform to track the crop health status including the crop production (biomass). Individual users can visualize the portfolio with specific access management system.',
    },
    {
      title: 'Risk Analytics',
      url: '/risk-analytics',
      discription:
        'This space is to various indicators related to rainfall deficit, drought, water scarcity from IWMI and relevant institutions.It helps to disseminate drought/flood risk- related information to guide various users for timely early action and decision-making process.',
    },
    {
      title: 'Settings',
      url: '/settingsDashboard',
      discription:
        'The settings dashboard allows user to perform different operations and manage users, static data, user guide, field data, survey configuration etc.',
    },
    {
      title: 'Survey Data Settings',
      url: '/surveyDataSettingsDashboard',
      discription: 'This tools allows to manage the Survey Configuration, Manage Surveyors, Field Data. To get manage these section, you need to login with gmail account.',
    },
  ];
  return (
    <>
      <div style={queryParam ? { marginBottom: '29vh' } : {}}>
        <div
          className={
            queryParam
              ? 'global-top1 dashboard-border my-5 mx-4'
              : 'global-top dashboard-border my-5 mx-4 '
          }
          style={{ paddingTOp: '15px' }}
        >
          <div className="dashboard-header">
            {queryParam ? (
              ''
            ) : (
              <div
                className=""
                style={{ paddinTop: '50px', position: 'relative', marginBottom: '15px' }}
              >
                <span
                  style={{
                    float: 'left',
                    borderRadius: '700px',
                    border: '1px solid #ada6a6',
                    marginTop: '10px',
                    height: '43px',
                    width: '43px',
                    fontSize: '25px',
                    color: '#595252',
                    fontWeight: 'bold',
                  }}
                  className="list-tl-st"
                >
                  {/* <img
                      src={RightArrow}
                      className="cursor-ponter"
                      onClick={() => history.goBack()}
                      alt='back-bottom'
                    /> */}
                  <BsArrowLeft
                    className="cursor-ponter"
                    onClick={() => history.push('/settingsDashboard?from=login')}
                    style={{ margin: 'auto' }}
                  />
                </span>
              </div>
            )}

            <img src={SettingIcon} alt={'icon-not available'} />
            <p className="dashboard-text">
              {queryParam ? 'Dashboard' : t('SETTING_DASHBOARD.SETTING_DASHBOARD')}
            </p>
          </div>
          <Container>
            <Row>
              {queryParam ? (
                staticCards.map((i, index) => {
                  if (
                    (showSurveyData && i.title === 'Survey Data Settings') ||
                    i.title !== 'Survey Data Settings'
                  ) {
                    if (
                      (loginDetails?.user_details?.[0]?.is_staff ||
                        loginDetails?.user_details?.[0]?.is_superuser) &&
                      cardList?.length !== 0
                    ) {
                      if (nextProps.loginDetailData?.data?.result?.length === 0) {
                        //Super Admin
                        if (i.title !== 'Survey Data Settings') {
                          return (
                            <Col
                              lg={4}
                              sm={6}
                              xs={12}
                              className="card-column"
                              // onClick={() => history.push(i.url)}
                            >
                              <Card
                                className="dashboard-card1 custom-card-x cb-1 cursor-pointer"
                                onClick={() => history.push(i.url)}
                              >
                                <div className="">
                                  {/* <img src={i.icon} alt={'not-available'} /> */}
                                  <p className="icon-text dt-1">{i.title}</p>
                                  <p className="icon-small-text" style={{ marginLeft: '20px' }}>
                                    {i.discription}
                                  </p>
                                </div>
                              </Card>
                            </Col>
                          );
                        }
                      } else {
                        return (
                          <Col
                            lg={4}
                            sm={6}
                            xs={12}
                            className="card-column"
                            // onClick={() => history.push(i.url)}
                          >
                            <Card
                              className="dashboard-card1 custom-card-x cb-1 cursor-pointer"
                              onClick={() => history.push(i.url)}
                            >
                              <div className="">
                                {/* <img src={i.icon} alt={'not-available'} /> */}
                                <p className="icon-text dt-1">{i.title}</p>
                                <p className="icon-small-text" style={{ marginLeft: '20px' }}>
                                  {i.discription}
                                </p>
                              </div>
                            </Card>
                          </Col>
                        );
                      }
                    } else {
                      if (i.title !== 'Settings')
                        return (
                          <Col
                            lg={4}
                            sm={6}
                            xs={12}
                            className="card-column"
                            // onClick={() => history.push(i.url)}
                          >
                            <Card
                              className="dashboard-card1 custom-card-x cb-1 cursor-pointer"
                              onClick={() => history.push(i.url)}
                            >
                              <div className="">
                                {/* <img src={i.icon} alt={'not-available'} /> */}
                                <p className="icon-text dt-1">{i.title}</p>
                                <p className="icon-small-text" style={{ marginLeft: '20px' }}>
                                  {i.discription}
                                </p>
                              </div>
                            </Card>
                          </Col>
                        );
                    }
                  }
                })
              ) : //  !cardList?.length ? (
              //   <Loader />
              // ) :
              nextProps?.isUserPermissionsRequesting || nextProps?.isLoginDetailRequesting ? (
                <Loader />
              ) : (
                // :
                //   !cardList?.length ? (
                //       <div className='empty-cards-bo'>
                //       User don't have any permissions
                //       </div>
                //    )
                cardList.map((i, index) => {
                  return (
                    <>
                      <Col
                        lg={4}
                        sm={6}
                        xs={12}
                        className="card-column"
                        // onClick={() => handleCardClick(i)}
                      >
                        <Card
                          className="dashboard-card custom-card-x cb-1 cursor-pointer"
                          onClick={() => handleCardClick(i)}
                        >
                          <div className="">
                            <img src={i.icon} alt={'not-available'} />
                            <p className="icon-text dt-1">{i.title}</p>
                            <p className="icon-small-text mrl-1">{i.discription}</p>
                          </div>
                        </Card>
                      </Col>
                    </>
                  );
                })
              )}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default SettingsDashboard;
