import { USER_GUIDE_CONSTS } from './userGuideConsts';

const UserGuide = (state = {}, action) => {
  switch (action.type) {
    case USER_GUIDE_CONSTS.ADD_USER_GUIDE_REQUEST:
      return {
        ...state,
        isAddUserGuideRequesting: true,
        addUserGuideData: false,
        addUserGuideDataError: false,
        addUserGuideDataNetworkError: false,
      };

    case USER_GUIDE_CONSTS.ADD_USER_GUIDE_SUCCESS:
      return {
        ...state,
        isAddUserGuideRequesting: false,
        addUserGuideData: action.payload.response,
        addUserGuideDataError: false,
        addUserGuideDataNetworkError: false,
      };

    case USER_GUIDE_CONSTS.ADD_USER_GUIDE_FAILED:
      return {
        ...state,
        isAddUserGuideRequesting: false,
        addUserGuideData: false,
        addUserGuideDataError: action.payload.error ? false : action.payload.response,
        addUserGuideDataNetworkError: action.payload.error ? action.payload.error : false,
      };



      case USER_GUIDE_CONSTS.ADD_TOPIC_REQUEST:
        return {
          ...state,
          isAddTopicRequesting: true,
          addTopicData: false,
          addTopicDataError: false,
          addTopicDataNetworkError: false,
        };
  
      case USER_GUIDE_CONSTS.ADD_TOPIC_SUCCESS:
        return {
          ...state,
          isAddTopicRequesting: false,
          addTopicData: action.payload.response,
          addTopicDataError: false,
          addTopicDataNetworkError: false,
        };
  
      case USER_GUIDE_CONSTS.ADD_TOPIC_FAILED:
        return {
          ...state,
          isAddTopicRequesting: false,
          addTopicData: false,
          addTopicDataError: action.payload.error ? false : action.payload.response,
          addTopicDataNetworkError: action.payload.error ? action.payload.error : false,
        };


      case USER_GUIDE_CONSTS.SUB_TOPIC_ADD_REQUEST:
        return {
          ...state,
          isSubTopicAddRequesting: true,
          subTopicAddData: false,
          subTopicAddDataError: false,
          subTopicAddDataNetworkError: false,
        };
  
      case USER_GUIDE_CONSTS.SUB_TOPIC_ADD_SUCCESS:
        return {
          ...state,
          isSubTopicAddRequesting: false,
          subTopicAddData: action.payload.response,
          subTopicAddDataError: false,
          subTopicAddDataNetworkError: false,
        };
  
      case USER_GUIDE_CONSTS.SUB_TOPIC_ADD_FAILED:
        return {
          ...state,
          isSubTopicAddRequesting: false,
          subTopicAddData: false,
          subTopicAddDataError: action.payload.error ? false : action.payload.response,
          subTopicAddDataNetworkError: action.payload.error ? action.payload.error : false,
        };

    case USER_GUIDE_CONSTS.GET_ALL_TOPICS_REQUEST:
      return {
        ...state,
        isGetAllTopicsRequesting: true,
        getAllTopicData: false,
        getAllTopicDataError: false,
        getAllTopicDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_ALL_TOPICS_SUCCESS:
      return {
        ...state,
        isGetAllTopicsRequesting: false,
        getAllTopicData: action.payload.response,
        getAllTopicDataError: false,
        getAllTopicDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_ALL_TOPICS_FAILED:
      return {
        ...state,
        isGetAllTopicsRequesting: false,
        getAllTopicData: false,
        getAllTopicDataError: action.payload.error ? false : action.payload.response,
        getAllTopicDataNetworkError: action.payload.error ? action.payload.error : false,
      };



      case USER_GUIDE_CONSTS.GET_ALL_SUB_TOPICS_REQUEST:
        return {
          ...state,
          isGetAllSubTopicsRequesting: true,
          getAllSubTopicData: false,
          getAllSubTopicDataError: false,
          getAllSubTopicDataNetworkError: false,
        };
      case USER_GUIDE_CONSTS.GET_ALL_SUB_TOPICS_SUCCESS:
        return {
          ...state,
          isGetAllSubTopicsRequesting: false,
          getAllSubTopicData: action.payload.response,
          getAllSubTopicDataError: false,
          getAllSubTopicDataNetworkError: false,
        };
      case USER_GUIDE_CONSTS.GET_ALL_SUB_TOPICS_FAILED:
        return {
          ...state,
          isGetAllSubTopicsRequesting: false,
          getAllSubTopicData: false,
          getAllSubTopicDataError: action.payload.error ? false : action.payload.response,
          getAllSubTopicDataNetworkError: action.payload.error ? action.payload.error : false,
        };

    
    case USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_REQUEST:
      return {
        ...state,
        isGetAllUserGuideRequesting: true,
        getAllUserGuideData: false,
        getAllUserGuideDataError: false,
        getAllUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_SUCCESS:
      return {
        ...state,
        isGetAllUserGuideRequesting: false,
        getAllUserGuideData: action.payload.response,
        getAllUserGuideDataError: false,
        getAllUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_FAILED:
      return {
        ...state,
        isGetAllUserGuideRequesting: false,
        getAllUserGuideData: false,
        getAllUserGuideDataError: action.payload.error ? false : action.payload.response,
        getAllUserGuideDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_REQUEST:
      return {
        ...state,
        isUpdateUserGuideRequesting: true,
        updateUserGuideData: false,
        updateUserGuideDataError: false,
        updateUserGuideDataNetworkError: false,
      };

    case USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_SUCCESS:
      return {
        ...state,
        isUpdateUserGuideRequesting: false,
        updateUserGuideData: action.payload.response,
        updateUserGuideDataError: false,
        updateUserGuideDataNetworkError: false,
      };

    case USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_FAILED:
      return {
        ...state,
        isUpdateUserGuideRequesting: false,
        updateUserGuideData: false,
        updateUserGuideDataError: action.payload.error ? false : action.payload.response,
        updateUserGuideDataNetworkError: action.payload.error ? action.payload.error : false,
      };


      case USER_GUIDE_CONSTS.UPDATE_TOPIC_REQUEST:
        return {
          ...state,
          isUpdateTopicRequesting: true,
          updateTopicData: false,
          updateTopicDataError: false,
          updateTopicDataNetworkError: false,
        };
  
      case USER_GUIDE_CONSTS.UPDATE_TOPIC_SUCCESS:
        return {
          ...state,
          isUpdateTopicRequesting: false,
          updateTopicData: action.payload.response,
          updateTopicDataError: false,
          updateTopicDataNetworkError: false,
        };
  
      case USER_GUIDE_CONSTS.UPDATE_TOPIC_FAILED:
        return {
          ...state,
          isUpdateTopicRequesting: false,
          updateTopicData: false,
          updateTopicDataError: action.payload.error ? false : action.payload.response,
          updateTopicDataNetworkError: action.payload.error ? action.payload.error : false,
        };




        case USER_GUIDE_CONSTS.UPDATE_SUB_TOPIC_REQUEST:
          return {
            ...state,
            isUpdateSubTopicRequesting: true,
            updateSubTopicData: false,
            updateSubTopicDataError: false,
            updateSubTopicDataNetworkError: false,
          };
    
        case USER_GUIDE_CONSTS.UPDATE_SUB_TOPIC_SUCCESS:
          return {
            ...state,
            isUpdateSubTopicRequesting: false,
            updateSubTopicData: action.payload.response,
            updateSubTopicDataError: false,
            updateSubTopicDataNetworkError: false,
          };
    
        case USER_GUIDE_CONSTS.UPDATE_SUB_TOPIC_FAILED:
          return {
            ...state,
            isUpdateSubTopicRequesting: false,
            updateSubTopicData: false,
            updateSubTopicDataError: action.payload.error ? false : action.payload.response,
            updateSubTopicDataNetworkError: action.payload.error ? action.payload.error : false,
          };
  



    case USER_GUIDE_CONSTS.DELETE_USER_GUIDE_REQUEST:
      return {
        ...state,
        isDeleteUserGuideRequesting: true,
        deleteUserGuideData: false,
        deleteUserGuideDataError: false,
        deleteUserGuideDataNetworkError: false,
      };

    case USER_GUIDE_CONSTS.DELETE_USER_GUIDE_SUCCESS:
      return {
        ...state,
        isDeleteUserGuideRequesting: false,
        deleteUserGuideData: action.payload.response,
        deleteUserGuideDataError: false,
        deleteUserGuideDataNetworkError: false,
      };

    case USER_GUIDE_CONSTS.DELETE_USER_GUIDE_FAILED:
      return {
        ...state,
        isDeleteUserGuideRequesting: false,
        deleteUserGuideData: false,
        deleteUserGuideDataError: action.payload.error ? false : action.payload.response,
        deleteUserGuideDataNetworkError: action.payload.error ? action.payload.error : false,
      };



      case USER_GUIDE_CONSTS.DELETE_SUB_TOPIC_REQUEST:
        return {
          ...state,
          isDeleteSubTopicRequesting: true,
          deleteSubTopicData: false,
          deleteSubTopicDataError: false,
          deleteSubTopicDataNetworkError: false,
        };
  
      case USER_GUIDE_CONSTS.DELETE_SUB_TOPIC_SUCCESS:
        return {
          ...state,
          isDeleteSubTopicRequesting: false,
          deleteSubTopicData: action.payload.response,
          deleteSubTopicDataError: false,
          deleteSubTopicDataNetworkError: false,
        };
  
      case USER_GUIDE_CONSTS.DELETE_SUB_TOPIC_FAILED:
        return {
          ...state,
          isDeleteSubTopicRequesting: false,
          deleteSubTopicData: false,
          deleteSubTopicDataError: action.payload.error ? false : action.payload.response,
          deleteSubTopicDataNetworkError: action.payload.error ? action.payload.error : false,
        };

        case USER_GUIDE_CONSTS.DELETE_TOPIC_REQUEST:
          return {
            ...state,
            isDeleteTopicRequesting: true,
            deleteTopicData: false,
            deleteTopicDataError: false,
            deleteTopicDataNetworkError: false,
          };
    
        case USER_GUIDE_CONSTS.DELETE_TOPIC_SUCCESS:
          return {
            ...state,
            isDeleteTopicRequesting: false,
            deleteTopicData: action.payload.response,
            deleteTopicDataError: false,
            deleteTopicDataNetworkError: false,
          };
    
        case USER_GUIDE_CONSTS.DELETE_TOPIC_FAILED:
          return {
            ...state,
            isDeleteTopicRequesting: false,
            deleteTopicData: false,
            deleteTopicDataError: action.payload.error ? false : action.payload.response,
            deleteTopicDataNetworkError: action.payload.error ? action.payload.error : false,
          };
    
    case USER_GUIDE_CONSTS.CHANGE_STATUS_USER_GUIDE_REQUEST:
      return {
        ...state,
        isChangeStatusUserGuideRequesting: true,
        changeStatusUserGuideData: false,
        changeStatusUserGuideDataError: false,
        changeStatusUserGuideDataNetworkError: false,
      };

    case USER_GUIDE_CONSTS.CHANGE_STATUS_USER_GUIDE_SUCCESS:
      return {
        ...state,
        isChangeStatusUserGuideRequesting: false,
        changeStatusUserGuideData: action.payload.response,
        changeStatusUserGuideDataError: false,
        changeStatusUserGuideDataNetworkError: false,
      };

    case USER_GUIDE_CONSTS.CHANGE_STATUS_USER_GUIDE_FAILED:
      return {
        ...state,
        isChangeStatusUserGuideRequesting: false,
        changeStatusUserGuideData: false,
        changeStatusUserGuideDataError: action.payload.error ? false : action.payload.response,
        changeStatusUserGuideDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_REQUEST:
      return {
        ...state,
        isGetAllTopicsListRequesting: true,
        getAllTopicsListData: false,
        getAllTopicsListDataError: false,
        getAllTopicsListDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_SUCCESS:
      return {
        ...state,
        isGetAllTopicsListRequesting: false,
        getAllTopicsListData: action.payload.response,
        getAllTopicsListDataError: false,
        getAllTopicsListDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_FAILED:
      return {
        ...state,
        isGetAllTopicsListRequesting: false,
        getAllTopicsListData: false,
        getAllTopicsListDataError: action.payload.error ? false : action.payload.response,
        getAllTopicsListDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_REQUEST:
      return {
        ...state,
        isSearchByCriteriaUserGuideRequesting: true,
        searchByCriteriaUserGuideData: false,
        searchByCriteriaUserGuideDataError: false,
        searchByCriteriaUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_SUCCESS:
      return {
        ...state,
        isSearchByCriteriaUserGuideRequesting: false,
        searchByCriteriaUserGuideData: action.payload.response,
        searchByCriteriaUserGuideDataError: false,
        searchByCriteriaUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_FAILED:
      return {
        ...state,
        isSearchByCriteriaUserGuideRequesting: false,
        searchByCriteriaUserGuideData: false,
        searchByCriteriaUserGuideDataError: action.payload.error ? false : action.payload.response,
        searchByCriteriaUserGuideDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };
    case USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_REQUEST:
      return {
        ...state,
        isSearchContentUserGuideRequesting: true,
        searchContentUserGuideData: false,
        searchContentUserGuideDataError: false,
        searchContentUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_SUCCESS:
      return {
        ...state,
        isSearchContentUserGuideRequesting: false,
        searchContentUserGuideData: action.payload.response,
        searchContentUserGuideDataError: false,
        searchContentUserGuideDataNetworkError: false,
      };
    case USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_FAILED:
      return {
        ...state,
        isSearchContentUserGuideRequesting: false,
        searchContentUserGuideData: false,
        searchContentUserGuideDataError: action.payload.error ? false : action.payload.response,
        searchContentUserGuideDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    default:
      return state;
  }
};

export default UserGuide;
