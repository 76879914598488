import React, { useState, useEffect } from 'react';
import { t } from 'react-multi-lang';
import moment from 'moment';
import Select from 'react-select';
import { Col, Row, Collapse, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import _ from 'lodash';
// import { RiArrowDropUpLine, RiArrowDropDownLine } from 'react-icons/ri';
import { BiPlus, BiMinus } from 'react-icons/bi';
import { TEMPORAL_AGGREGATION, CROP_HEALTH_MODULE,CROP_MONITOR_MIN_MAX_HIDE_INDICATORS_SLUG } from '../../../Components/constants';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { AiOutlineCaretUp } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
const Water = (props) => {
  const {
    stateList,
    handleSelectedItem,
    initialRawDataSelectedDate,
    initialCompareRawDataSelectedDate,
    state,
    country,
    districtList,
    selectedDistrict,
    initialSelectedDate,
    handleStartDate,
    setSelectedCompareIndicatorsParam,
    setIsPreviousCompareClick,
    isPreviousCompareClick,
    handleEndDate,
    selectedParam,
    isUserClickedOnPlus,
    // vegetationList,
    // for comparing info
    compareStateList,
    openWaterCollapse: openCollapse,
    setOpenWaterCollapse: setOpenCollapse,
    setCategoryWiseCompareWaterData: setCategoryWiseCompareData,
    categoryWiseCompareWaterData: categoryWiseCompareData,
    compareState,
    compareDistrictList,
    handleCompareSelectedItem,
    temporalAggregation,
    handleTemporalAggregation,
    handleSelectedCropParam,
    handleParams,
    finalSelectedDate,
    handleFinalStartDate,
    setRawDataRenderState,
    setCompareRawDataRenderState,
    setRgbVisualizationRenderState,
    setCompareRgbVisualizationRenderState,
    rawDataRenderState,
    compareRawDataRenderState,
    rgbVisualizationRenderState,
    compareRgbVisualizationRenderState,
    handleFinalEndDate,
    selectedLocation,
    handleCompareToggle,
    isCompare,
    compareHandler,
    setCompareParam,
    handleSelectedCompareParam,
    compareParam,
    selectedCompareDistrict,
    isSelectedRegion,
    //
    activeTabSwitch,
    handleTab1,
    handleTab2,
    waterSensorsList,
    setWaterSensorsList,
    waterSensorsCompareList,
    setWaterSensorsCompareList,
    handleSelectedSensor,
    selectedSensorParam,
    selectedSensor,
    activeTab,
    handleCompareSelectedSensor,
    selectedCompareSensorParam,
    compareSelectedSensor,
    handleSelectedIndicators,
    selectedIndicatorsParam,
    waterIndicatorsList,
    waterIndicatorsCompareList,
    selectedIndicators,
    setSelectedCompareIndicators,
    handleSelectedCompareIndicators,
    selectedCompareIndicatorsParam,
    selectedCompareIndicators,
    polarisation,
    orbitProperties,
    handleSummerStartDate,
    setSelectedCompareSensorParam,
    handleSummerEndDate,
    handleMonSoonStartDate,
    handleMonSoonEndDate,
    handleWinterStartDate,
    handleWinterEndDate,
    comparePolarisation,
    compareOrbitProperties,
    handleCompareSummerStartDate,
    handleCompareSummerEndDate,
    handleCompareMonSoonStartDate,
    handleCompareMonSoonEndDate,
    handleCompareWinterStartDate,
    handleCompareWinterEndDate,
    error,
    errorMessage,
    setInvalidDate,
    previousActiveTab,
    previousTabSwitch,
    compareError,
    isClickedOnPlus,
    previousClickedOnPlus,
    tempState,
    setTempState,
    minError,
    setCompareTempState,
    compareMinError,
    compareTempState,
    summerError,
    summerErrorMessage,
    monSoonError,
    monSoonErrorMessage,
    winterError,
    winterErrorMessage,
    compareSummerError,
    compareSummerErrorMessage,
    compareMonSoonError,
    compareMonSoonErrorMessage,
    compareWinterError,
    compareWinterErrorMessage,
    setInitialSelectedDate,
    setFinalSelectedDate
  } = props;

  // new states
  const [categoryWiseData, setCategoryWiseData] = useState([]);
  const [selectedSensorCategoryList, setSelectedSensorCategoryList] = useState([]);
  // const [rawDataRenderState, setRawDataRenderState] = useState(false);
  // const [compareRawDataRenderState, setCompareRawDataRenderState] = useState(false);
  const [selectedCompareSensorCategoryList, setSelectedCompareSensorCategoryList] = useState([]);

  // RGB Visualization
  // const [rgbVisualizationRenderState, setRgbVisualizationRenderState] = useState(false);
  // const [compareRgbVisualizationRenderState, setCompareRgbVisualizationRenderState] = useState(
  //   false,
  // );

  // indicators to  sensors
  const [selectedIndicatorToSensorList, setSelectedIndicatorToSensorList] = useState([]);
  const [selectedCompareIndicatorToSensorList, setSelectedCompareIndicatorToSensorList] = useState(
    [],
  );
  /// gldas state
  const [gldasRenderState, setGldasRenderState] = useState(false);
  const [gldasCompareRenderState, setGldasCompareRenderState] = useState(false);
  const [value, setValue] = useState('');
  const [compareValue, setCompareValue] = useState('');

  const [timeFrameRemoval, setTimeFrameRemoval] = useState(false);

  useEffect(() => {
    if (activeTab !== previousActiveTab) {
      resetStates();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTabSwitch !== previousTabSwitch) {
      resetStates();
    }
  }, [activeTabSwitch]);

  useEffect(() => {
    if (previousClickedOnPlus !== isClickedOnPlus) {
      if (isUserClickedOnPlus) {
        setCategoryWiseCompareData([]);
        setSelectedCompareIndicatorToSensorList([]);
        setCompareRawDataRenderState(false);
        setCompareRgbVisualizationRenderState(false);
        waterSensorsCompareList?.map((i, index) => {
          i.checked = false;
        });
        setWaterSensorsCompareList([...waterSensorsCompareList]);
      }
    }
  }, [isClickedOnPlus, previousClickedOnPlus, isUserClickedOnPlus]);

  useEffect(() => {
    if (selectedLocation?.region?.length) {
      if (openCollapse?.agriculture_compare) {
        setOpenCollapse((prev) => {
          return { ...prev, agriculture_compare: false };
        });
      }
      setIsPreviousCompareClick(openCollapse?.agriculture_compare);
    } else if (selectedLocation?.region !== null && isPreviousCompareClick) {
      setIsPreviousCompareClick(openCollapse?.agriculture_compare);
      setOpenCollapse((prev) => {
        return { ...prev, agriculture_compare: true };
      });
    }
  }, [selectedLocation?.region]);

  useEffect(() => {
    if (selectedParam?.slug === 'discharge_forecast') {
      setOpenCollapse((prev) => {
        return {
          ...prev,
          agriculture_compare: false,
        };
      });
    }
  }, [selectedParam]);

  useEffect(() => {
    if (!_.isEmpty(selectedSensor)) {
      let category_list = [];
      selectedSensor?.data?.map((i) => {
        i?.water_resource_sensor?.map((a) => {
          if (!category_list.includes(a?.category_name)) {
            category_list.push(a?.category_name);
          }
        });
      });
      setSelectedSensorCategoryList(category_list);
    }
  }, [selectedSensor]);

  useEffect(() => {
    if (selectedSensorCategoryList?.length) {
      let category_wise_data = [];
      // let data =[] ;
      selectedSensor?.data?.map((i) => {
        selectedSensorCategoryList?.map((y) => {
          category_wise_data.push({
            category_name: y,
            data: i?.water_resource_sensor?.filter((x) => x.category_name === y),
            category_collapse: true,
          });
        });
      });
      category_wise_data?.map((z) => {
        z?.data?.map((p) => {
          p.checked = false;
        });
      });
      category_wise_data?.map((i) => {
        i?.data?.map((y, index) => {
          y.paramIndex = index;
          y.sensor_name = selectedSensor?.name;
        });
      });
      setCategoryWiseData(category_wise_data);
      // handleParams(category_wise_data);
    }
  }, [selectedSensorCategoryList]);

  useEffect(() => {
    if (categoryWiseCompareData?.length) {
      categoryWiseCompareData.forEach((list) =>
        list.data.forEach((item) => (item.checked = false)),
      );
    }
  }, [compareSelectedSensor]);

  useEffect(() => {
    if (!_.isEmpty(compareSelectedSensor)) {
      let category_list = [];
      compareSelectedSensor?.data?.map((i) => {
        i?.water_resource_sensor?.map((a) => {
          if (!category_list.includes(a?.category_name)) {
            category_list.push(a?.category_name);
          }
        });
      });
      setSelectedCompareSensorCategoryList(category_list);
    }
  }, [compareSelectedSensor]);

  useEffect(() => {
    if (selectedCompareSensorCategoryList?.length) {
      let category_wise_data = [];
      compareSelectedSensor?.data?.map((i) => {
        selectedCompareSensorCategoryList?.map((y) => {
          category_wise_data.push({
            category_name: y,
            data: i?.water_resource_sensor?.filter((x) => x.category_name === y),
            category_collapse: true,
          });
        });
      });
      category_wise_data?.map((z) => {
        z?.data?.map((p) => {
          p.checked = false;
          p.sensor_name = compareSelectedSensor?.name;
        });
      });
      setCategoryWiseCompareData(category_wise_data);
    }
  }, [selectedCompareSensorCategoryList]);

  // indicators to sensors upper section
  useEffect(() => {
    if (!_.isEmpty(selectedIndicators)) {
      let sensors_list = [];
      selectedIndicators?.data?.map((i) => {
        if (!sensors_list.includes(i?.sensor_name)) {
          sensors_list.push(i);
        }
      });
      sensors_list?.map((i, index) => {
        // i.checked = false;
        i.paramIndex = index;
      });
      setSelectedIndicatorToSensorList(sensors_list);
    }
  }, [selectedIndicators]);

  useEffect(() => {
    //need to uncheck all sensors once select any indicator
    if (selectedIndicatorToSensorList?.length) {
      selectedIndicatorToSensorList.forEach((list) => (list.checked = false));
    }
  }, [selectedIndicatorToSensorList]);

  // indicators to sensors compare section
  useEffect(() => {
    if (!_.isEmpty(selectedCompareIndicators)) {
      let sensors_list = [];
      selectedCompareIndicators?.data?.map((i) => {
        if (!sensors_list.includes(i?.sensor_name)) {
          sensors_list.push(i);
        }
      });
      sensors_list?.map((i, index) => {
        i.paramIndex = index;
      });
      setSelectedCompareIndicatorToSensorList(sensors_list);
    }
  }, [selectedCompareIndicators]);

  // Empty suggestion value when select other radio buttons
  useEffect(() => {
    setValue('');
  }, [gldasRenderState]);

  useEffect(() => {
    setCompareValue('');
  }, [gldasCompareRenderState]);

  const resetStates = () => {
    selectedIndicatorToSensorList?.map((i, index) => {
      i.checked = false;
    });
    setSelectedIndicatorToSensorList([]);
    setSelectedCompareIndicatorToSensorList([]);
    // setSelectedIndicators({});
    setCategoryWiseData([]);
    setCategoryWiseCompareData([]);
    // setSelectedSensor({});
    setGldasRenderState(false);
    setSelectedCompareSensorParam({});
    setSelectedCompareIndicatorsParam({});
    setCompareParam({});
    setGldasCompareRenderState(false);
    setRawDataRenderState(false);
    setCompareRawDataRenderState(false);
    setRgbVisualizationRenderState(false);
    setTimeFrameRemoval(false);
    setCompareRgbVisualizationRenderState(false);
    setSelectedCompareIndicators({});
    setOpenCollapse((prev) => {
      return {
        ...prev,
        agriculture_compare: false,
        water_raw_data: true,
        water_compare_tab1_raw_data: true,
        water_rgb_visualization: true,
        water_compare_rgb_visualization: true,
      };
    });
  };

  const handleCateogryToggle = (item) => {
    if (item === 'agriculture-location') {
      openCollapse.agriculture_location = !openCollapse.agriculture_location;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-datetime') {
      openCollapse.agriculture_datetime = !openCollapse.agriculture_datetime;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-sensors') {
      openCollapse.agriculture_sensors = !openCollapse.agriculture_sensors;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-vegetation') {
      openCollapse.agriculture_vegetation = !openCollapse.agriculture_vegetation;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-compare') {
      if (selectedParam?.slug !== 'discharge_forecast') {
        compareHandler();
      }
      openCollapse.agriculture_compare = !openCollapse.agriculture_compare;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'water-tab1-sensors') {
      openCollapse.water_tab1_sensors = !openCollapse.water_tab1_sensors;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'water-tab1-indicators') {
      openCollapse.water_tab1_indicators = !openCollapse.water_tab1_indicators;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'water-tab2-sensors') {
      openCollapse.water_tab2_sensors = !openCollapse.water_tab2_sensors;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'water-tab2-indicators') {
      openCollapse.water_tab2_indicators = !openCollapse.water_tab2_indicators;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'compare-water-tab1-sensors') {
      openCollapse.compare_water_tab1_sensors = !openCollapse.compare_water_tab1_sensors;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'compare-water-tab1-sensors') {
      openCollapse.compare_water_tab1_sensors = !openCollapse.compare_water_tab1_sensors;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'compare-water-tab1-indicators') {
      openCollapse.compare_water_tab1_indicators = !openCollapse.compare_water_tab1_indicators;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'water-compare-tab2-indicators') {
      openCollapse.water_compare_tab2_indicators = !openCollapse.water_compare_tab2_indicators;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'water-compare-tab2-sensors') {
      openCollapse.water_compare_tab2_sensors = !openCollapse.water_compare_tab2_sensors;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'water-raw-data') {
      openCollapse.water_raw_data = !openCollapse.water_raw_data;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'water-compare-tab1-raw-data') {
      openCollapse.water_compare_tab1_raw_data = !openCollapse.water_compare_tab1_raw_data;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'water-rgb-visualization') {
      openCollapse.water_rgb_visualization = !openCollapse.water_rgb_visualization;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'water-compare-rgb-visualization') {
      openCollapse.water_compare_rgb_visualization = !openCollapse.water_compare_rgb_visualization;
      setOpenCollapse({ ...openCollapse });
    }
  };

  useEffect(() => {
    let isInvalidDate = false;
    let isStartDateInvalid =
      document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true' ? true : false;
    let isEndDateInvalid =
      document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true' ? true : false;
    let isCompareStartDateInvalid =
      document.getElementById('compareStartDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isCompareEndDateInvalid =
      document.getElementById('compareEndDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isSummerStartDateInvalid =
      document.getElementById('summerStartDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isSummerEndDateInvalid =
      document.getElementById('summerEndDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isMonSoonStartDateInvalid =
      document.getElementById('monSoonStartDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isMonSoonEndDateInvalid =
      document.getElementById('monSoonEndDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isWinterStartDateInvalid =
      document.getElementById('winterStartDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isWinterEndDateInvalid =
      document.getElementById('winterEndDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isCompareSummerStartDateInvalid =
      document.getElementById('compareSummerStartDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isCompareSummerEndDateInvalid =
      document.getElementById('compareSummerEndDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isCompareMonSoonStartDateInvalid =
      document.getElementById('compareMonSoonStartDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isCompareMonSoonEndDateInvalid =
      document.getElementById('compareMonSoonEndDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isCompareWinterStartDateInvalid =
      document.getElementById('compareWinterStartDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isCompareWinterEndDateInvalid =
      document.getElementById('compareWinterEndDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;

    if (
      isStartDateInvalid ||
      isEndDateInvalid ||
      isCompareStartDateInvalid ||
      isCompareEndDateInvalid ||
      isSummerStartDateInvalid ||
      isSummerEndDateInvalid ||
      isMonSoonStartDateInvalid ||
      isMonSoonEndDateInvalid ||
      isWinterStartDateInvalid ||
      isWinterEndDateInvalid ||
      isCompareSummerStartDateInvalid ||
      isCompareSummerEndDateInvalid ||
      isCompareMonSoonStartDateInvalid ||
      isCompareMonSoonEndDateInvalid ||
      isCompareWinterStartDateInvalid ||
      isCompareWinterEndDateInvalid
    ) {
      isInvalidDate = true;
    } else {
      isInvalidDate = false;
    }
    // setError(isInvalidDate);
    setInvalidDate(isInvalidDate);
  }, [
    initialSelectedDate,
    finalSelectedDate,
    initialRawDataSelectedDate,
    initialCompareRawDataSelectedDate,
  ]);

  const handleInfo = (e, indices) => {
    e?.stopPropagation();
    window.open(
      `/user-guide-details?topicId=${CROP_HEALTH_MODULE.id}&&sub-topic-name=${indices}&topic_name=Crop Health Monitor`,
    );
  };
  const renderAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map((param) => {
          let data = {
            value: param.name,
            label: `${param.label}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  // for districts
  const renderAPIDistrictParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map((param) => {
          let data = {
            value: param.name,
            // label: `${param.name}`,
            label: `${param.label}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  //// location render
  const handleLocationRender = () => {
    return (
      <div className="advance-search-fields mb-3">
        <div className="mx-3 mt-3 mb-3">
          <Select
            id="multi-select"
            name="country"
            placeholder="Select Country"
            // options={renderAPIParameterOptions(countryList)}
            onChange={handleSelectedItem}
            isMulti={false}
            value={selectedLocation.country}
            defaultValue={selectedLocation.country}
            required={true}
            isDisabled={true}
          />
          {country && (
            <Select
              id="multi-select"
              name="state"
              className="mt-3 mb-2"
              placeholder="Select Province"
              options={renderAPIParameterOptions(stateList)}
              onChange={handleSelectedItem}
              isMulti={false}
              required={true}
              value={state}
            />
          )}
          {state && state.value !== 'Sri Lanka' && (
            <Select
              id="multi-select"
              name="district"
              className="mt-3"
              placeholder="Select District"
              options={renderAPIDistrictParameterOptions(districtList)}
              onChange={handleSelectedItem}
              isMulti={false}
              required={true}
              value={selectedDistrict}
            />
          )}
        </div>
      </div>
    );
  };
  //// date render
  const handleDateTimeRender = () => {
    return (
      <div className="advance-search-fields mb-3">
        <div className="mx-3 mt-2 mb-3">
          <Input
            type="date"
            name="startDate"
            id="startDate"
            placeholder="Start date"
            bsSize="sm"
            className="my-3 input-style"
            value={initialSelectedDate.startDate}
            onChange={(e) => handleStartDate(e)}
            min={initialSelectedDate.minStartDate}
            max={initialSelectedDate.maxEndDate}
            // max={initialSelectedDate.maxStartDate}
            disabled={_.isEmpty(selectedParam)}
            invalid={
              new Date(initialSelectedDate.minStartDate) >
                new Date(initialSelectedDate?.startDate) ||
              new Date(initialSelectedDate.maxStartDate) <
                new Date(initialSelectedDate?.startDate) ||
              initialSelectedDate?.startDate === 'Invalid date'
            }
          />
          {!error &&
            (new Date(initialSelectedDate.minStartDate) >
              new Date(initialSelectedDate?.startDate) ||
              new Date(initialSelectedDate.maxStartDate) <
                new Date(initialSelectedDate?.startDate) ||
              initialSelectedDate?.startDate === 'Invalid date') && (
              // document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true'
              <FormFeedback invalid>Invalid date</FormFeedback>
            )}
          <Input
            type="date"
            name="endDate"
            id="endDate"
            placeholder="End date"
            bsSize="sm"
            className="my-2 input-style"
            value={initialSelectedDate.endDate}
            onChange={(e) => handleEndDate(e)}
            min={initialSelectedDate.startDate}
            max={initialSelectedDate.maxEndDate}
            disabled={_.isEmpty(selectedParam)}
            invalid={
              new Date(initialSelectedDate.minEndDate) > new Date(initialSelectedDate?.endDate) ||
              new Date(initialSelectedDate.maxEndDate) < new Date(initialSelectedDate?.endDate) ||
              initialSelectedDate?.endDate === 'Invalid date' ||
              error
            }
          />
          {(new Date(initialSelectedDate.minEndDate) > new Date(initialSelectedDate?.endDate) ||
            new Date(initialSelectedDate.maxEndDate) < new Date(initialSelectedDate?.endDate) ||
            initialSelectedDate?.endDate === 'Invalid date' ||
            error) && (
            //  document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true'
            <FormFeedback invalid>{errorMessage ? errorMessage : 'Invalid date'}</FormFeedback>
          )}
        </div>
      </div>
    );
  };

  const handleMinMaxRender = () => {
    return (
      <div className="mt-3 temp-style">
        <div className="mt-2 mx-2">
          <Row>
            <Col className="mx-2">
              {' '}
              <Input
                type="number"
                placeholder="Min."
                bsSize="sm"
                className="mt-1 mb-3 p-2 temp-input"
                value={tempState.min || ''}
                disabled={_.isEmpty(selectedParam)}
                onChange={(e) => {
                  setTempState((prev) => {
                    return {
                      ...prev,
                      min: e.target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col className="mx-2">
              <Input
                type="number"
                placeholder="Max."
                bsSize="sm"
                className="mt-1 mb-3 p-2 temp-input"
                value={tempState.max || ''}
                disabled={_.isEmpty(selectedParam)}
                onChange={(e) => {
                  setTempState((prev) => {
                    return {
                      ...prev,
                      max: e.target.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  const handleComapareMinMaxRender = () => {
    return (
      <div className="mt-3 temp-style">
        <div className="mt-2 mb-2 mx-2">
          <Row>
            <Col className="mx-2">
              {' '}
              <Input
                type="number"
                placeholder="Min."
                bsSize="sm"
                className="mt-1 mb-3 p-2 temp-input"
                value={compareTempState.min || ''}
                disabled={_.isEmpty(compareParam)}
                onChange={(e) => {
                  setCompareTempState((prev) => {
                    return {
                      ...prev,
                      min: e.target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col className="mx-2">
              <Input
                type="number"
                placeholder="Max."
                bsSize="sm"
                className="mt-1 mb-3 p-2 temp-input"
                value={compareTempState.max || ''}
                disabled={_.isEmpty(compareParam)}
                onChange={(e) => {
                  setCompareTempState((prev) => {
                    return {
                      ...prev,
                      max: e.target.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  const handleWaterCompare = () => {
    return (
      <div className="advance-search-fields mb-3">
        <div className="mx-3 mt-2 mb-3">
          <FormGroup check>
            <Row className="compare-style">
              {isCompare?.map((item) => {
                return (
                  <>
                    <Col sm={4} lg={4} md={4} className="mt-2">
                      <Label check>
                        <Input
                          type="radio"
                          name="radio2"
                          value={item.value}
                          checked={item.selected}
                          onChange={handleCompareToggle}
                        />{' '}
                        {item.value}
                      </Label>
                    </Col>{' '}
                  </>
                );
              })}
            </Row>
          </FormGroup>
        </div>
      </div>
    );
  };

  // This section displayed in Compare Map part only
  const renderCompareDates = (isCompare) => {
    let isGraphCompare = isCompare?.find((item) => item.name === 'graph');
    if (!isGraphCompare.selected) {
      return (
        <>
          <div className="advance-search-fields mb-3">
            <div className="mx-3 mt-2 mb-3">
              <Input
                type="date"
                placeholder="Start date"
                id="compareStartDate"
                bsSize="sm"
                className="mt-1 my-3 input-style"
                value={finalSelectedDate.startDate}
                onChange={handleFinalStartDate}
                min={compareParam?.min_date}
                max={finalSelectedDate.maxEndDate}
                // max={finalSelectedDate.maxStartDate}
                disabled={_.isEmpty(compareParam)}
                invalid={
                  new Date(compareParam?.min_date) > new Date(finalSelectedDate.startDate) ||
                  new Date(finalSelectedDate.maxStartDate) <
                    new Date(finalSelectedDate.startDate) ||
                  finalSelectedDate.startDate === 'Invalid date'
                }
              />
              {!compareError?.showError &&
                (new Date(compareParam?.min_date) > new Date(finalSelectedDate.startDate) ||
                  new Date(finalSelectedDate.maxStartDate) <
                    new Date(finalSelectedDate.startDate) ||
                  finalSelectedDate.startDate === 'Invalid date') && (
                  // document.getElementById('compareStartDate')?.getAttribute('aria-invalid') ===
                  // 'true'
                  <FormFeedback invalid>Invalid date</FormFeedback>
                )}
              <Input
                type="date"
                placeholder="End date"
                id="compareEndDate"
                bsSize="sm"
                className="mt-1 my-3 input-style"
                value={finalSelectedDate.endDate}
                onChange={handleFinalEndDate}
                min={finalSelectedDate?.minEndDate}
                max={finalSelectedDate.maxEndDate}
                // max={maxEndDate}
                disabled={_.isEmpty(compareParam)}
                invalid={
                  new Date(finalSelectedDate?.minEndDate) > new Date(finalSelectedDate.endDate) ||
                  new Date(finalSelectedDate.maxEndDate) < new Date(finalSelectedDate?.endDate) ||
                  finalSelectedDate?.endDate === 'Invalid date' ||
                  compareError?.showError
                }
              />
              {(new Date(finalSelectedDate?.minEndDate) > new Date(finalSelectedDate.endDate) ||
                new Date(finalSelectedDate.maxEndDate) < new Date(finalSelectedDate?.endDate) ||
                finalSelectedDate?.endDate === 'Invalid date' ||
                compareError?.showError) && (
                // document.getElementById('compareEndDate')?.getAttribute('aria-invalid') === 'true'
                <FormFeedback invalid>
                  {compareError?.msg ? compareError?.msg : 'Invalid date'}
                </FormFeedback>
              )}
            </div>
          </div>
        </>
      );
    }
  };
  //This CompareLocation will displayed only in graph checkbox
  const handleCompareLocationRender = () => {
    let isGraphCompare = isCompare?.find((item) => item.name === 'graph' && item.selected);
    if (isGraphCompare) {
      return (
        <>
          {/* <div className="mx-3 mt-2 mb-3">
          <div className="mb-2"> */}
          <div className="pt-2  mb-3">
            <div className="mb-3 mx-3">
              <Select
                id="multi-select"
                name="country"
                placeholder="Select Country"
                // options={renderAPIParameterOptions(countryList)}
                // onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={selectedLocation.country}
                defaultValue={selectedLocation.country}
                isDisabled={true}
              />
            </div>
            {/* <div className="mt-2 mb-3"> */}
            <div className="mb-3 mx-3">
              <Select
                id="multi-select"
                name="state"
                placeholder="Select Province"
                options={renderAPIParameterOptions(compareStateList)}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={compareState}
              />
            </div>
            {compareState && compareState.value !== 'Sri Lanka' && (
              // <div className="mt-2 mb-3">
              <div className="mt-1 mx-3 mb-3">
                <Select
                  id="multi-select"
                  name="district"
                  placeholder="Select District"
                  options={renderAPIDistrictParameterOptions(compareDistrictList)}
                  onChange={handleCompareSelectedItem}
                  isMulti={false}
                  required={true}
                  value={selectedCompareDistrict}
                />
              </div>
            )}
          </div>
        </>
      );
    }
  };
  const handleSelectedWaterTab1SensorIndices = (value) => {
    if (value?.data[0]?.slug === 'geoglows') {
      setTimeFrameRemoval(true);
    } else {
      setTimeFrameRemoval(false);
    }
    waterSensorsList?.map((i, index) => {
      i.checked = false;
    });
    waterSensorsList?.map((i, index) => {
      if (i.name === value?.name) {
        i.checked = !i.checked;
      }
    });
    setWaterSensorsList([...waterSensorsList]);
  };

  const handleSelectedIndices = (value) => {
      const end_date = moment(value?.max_date, 'YYYY-MM-DD');
    const start_date = end_date.clone().subtract(30, 'days');
    const start_date_str = start_date.format('YYYY-MM-DD');
    setInitialSelectedDate((prevdate) => {
      return {
        ...prevdate,
        startDate: start_date_str,
        endDate: value?.max_date,
      };
    });
    //setSelectedParam(data);
    if (activeTabSwitch === 'tab1') {
      categoryWiseData?.map((i) => {
        i?.data?.map((x) => {
          if (x.slug === value.slug) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      });
      let x = categoryWiseData.filter((item) => item.category_name === value?.category_name);
      handleParams(x);
      setCategoryWiseData([...categoryWiseData]);
      handleSelectedCropParam(value);
    } else if (activeTabSwitch === 'tab2') {
      selectedIndicatorToSensorList?.map((i, index) => {
        i.checked = false;
      });
      selectedIndicatorToSensorList?.map((i, index) => {
        if (i.sensor_name === value?.sensor_name) {
          i.checked = !i.checked;
        }
      });
      let category_wise_data = [];
      category_wise_data.push({
        data: selectedIndicatorToSensorList.map((i) => i),
      });
      let x = waterIndicatorsList.filter((item) => item?.category === value?.category_name);
      handleParams(category_wise_data);
      setSelectedIndicatorToSensorList([...selectedIndicatorToSensorList]);
      handleSelectedCropParam(value);
    }
  };
  const handleSelectedCompareIndices = (value) => {
      const compare_end_date = moment(value?.max_date, 'YYYY-MM-DD');
    const compare_start_date = compare_end_date.clone().subtract(30, 'days');
    const compare_start_date_str = compare_start_date.format('YYYY-MM-DD');

    setFinalSelectedDate((prevdate) => {
      return {
        ...prevdate,
        startDate: compare_start_date_str,
        endDate: value?.max_date,
      };
    });
    if (activeTabSwitch === 'tab1') {
      categoryWiseCompareData?.map((i) => {
        i?.data?.map((x) => {
          if (x.slug === value.slug) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      });
      setCategoryWiseCompareData([...categoryWiseCompareData]);
      handleSelectedCompareParam(value);
    } else if (activeTabSwitch === 'tab2') {
      selectedCompareIndicatorToSensorList?.map((i, index) => {
        i.checked = false;
      });
      selectedCompareIndicatorToSensorList?.map((i, index) => {
        if (i.sensor_name === value?.sensor_name) {
          i.checked = !i.checked;
        }
      });
      setSelectedCompareIndicatorToSensorList([...selectedCompareIndicatorToSensorList]);
      handleSelectedCompareParam(value);
    }
  };
  // Sensors to indicators
  const handleTab1SensorsList = (list) => {
    return (
      <FormGroup check>
        <div className="mb-2">
          {list?.length ? 
          <Row>
            {list?.map((item) => {
              return (
                <>
                  <Col sm={12} lg={12} md={12} className="mt-1 sensors-radios-buttons">
                    <label
                      style={{
                        fontFamily: 'National',
                        fontSize: '15px',
                        color: '#363636',
                      }}
                      for={item.name}
                    >
                      {item.name}
                    </label>
                    <span className="radio-icon-button">
                      <input
                        type="radio"
                        id={item.name}
                        name="radio-button"
                        className=""
                        value={item.name}
                        checked={item.name === selectedSensorParam?.name}
                        onChange={() => {
                          handleSelectedWaterTab1SensorIndices(item);
                        }}
                        onClick={() => {
                          handleSelectedSensor(item);
                          setGldasRenderState(false);
                          setRawDataRenderState(false);
                          setRgbVisualizationRenderState(false);
                        }}
                      />
                      <i
                        className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                        id="info-icon-tool-tip"
                        data-tip
                        data-for="info-icon-tool-tip"
                        onClick={(e) => handleInfo(e, item.name)}
                      />
                      <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                        Click here to know more.
                      </ReactTooltip>
                    </span>
                  </Col>
                </>
              );
            })}
          </Row>
          : ''}
        </div>
      </FormGroup>
    );
  };

  const handleCheckIndices = (value) => {
    if (activeTabSwitch === 'tab2') {
      if (value.data?.[0]?.slug === 'discharge_forecast') {
        setTimeFrameRemoval(true);
      } else {
        setTimeFrameRemoval(false);
      }
      if (value.name === 'GLDAS') {
        setGldasRenderState(true);
        setRawDataRenderState(false);
        setRgbVisualizationRenderState(false);
      } else if (value.name === 'SAR') {
        setRawDataRenderState(true);
        setGldasRenderState(false);
        setRgbVisualizationRenderState(false);
      } else if (value.name === 'SAR RGB') {
        setRgbVisualizationRenderState(true);
        setRawDataRenderState(false);
        setGldasRenderState(false);
      } else {
        setRawDataRenderState(false);
        setGldasRenderState(false);
        setRgbVisualizationRenderState(false);
      }
    } else {
      if (value.name === 'GLDAS') {
        setGldasRenderState(true);
        setRawDataRenderState(false);
        setRgbVisualizationRenderState(false);
      } else if (value.name === 'SAR') {
        setRawDataRenderState(true);
        setGldasRenderState(false);
        setRgbVisualizationRenderState(false);
      } else if (value.name === 'SAR RGB') {
        setRgbVisualizationRenderState(true);
        setRawDataRenderState(false);
        setGldasRenderState(false);
      } else {
        setRawDataRenderState(false);
        setGldasRenderState(false);
        setRgbVisualizationRenderState(false);
      }
    }
  };

  const handleCheckCompareIndices = (value) => {
    if (value.name === 'GLDAS') {
      setGldasCompareRenderState(true);
      setCompareRawDataRenderState(false);
      setCompareRgbVisualizationRenderState(false);
    } else if (value.name === 'SAR') {
      setCompareRawDataRenderState(true);
      setGldasCompareRenderState(false);
      setCompareRgbVisualizationRenderState(false);
    } else if (value.name === 'SAR RGB') {
      setCompareRgbVisualizationRenderState(true);
      setCompareRawDataRenderState(false);
      setGldasCompareRenderState(false);
    } else {
      setCompareRawDataRenderState(false);
      setGldasCompareRenderState(false);
      setCompareRgbVisualizationRenderState(false);
    }
  };

  const renderRawDataPolarisationOptions = () => {
    const options = [
      { value: 'VV', label: 'VV', id: 'VV' },
      { value: 'VH', label: 'VH', id: 'VH' },
      { value: 'Both', label: 'Both', id: 'Both'}
    ];
    return options;
  };

  const renderRawDataOrbitPropertiesOptions = () => {
    const options = [
      // { value: 'Select', label: 'Select', id: 'Select' },
      { value: 'Ascending', label: 'Ascending', id: 'Ascending' },
      { value: 'Descending', label: 'Descending', id: 'Descending' },
      { value: 'Both', label: 'Both', id: 'Both'}
    ];
    return options;
  };

  const handleRawData = () => {
    return (
      <>
        <span onClick={() => handleCateogryToggle('water-raw-data')}>
          <p className="sub-collapse-style  mt-2">
            SAR
            <span className="category-span">
              <span className="category-span">
                {openCollapse.water_raw_data ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="water_raw_data"
          isOpen={openCollapse?.water_raw_data}
          className="collapse-moadal"
        >
          <div className="advance-search-fields mb-3">
            <div className="mt-2 mb-3">
              <Select
                id="polarisation-select"
                name="Polarisation"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Polarisation"
                options={renderRawDataPolarisationOptions()}
                onChange={handleSelectedItem}
                isMulti={false}
                required={true}
                value={polarisation}
              />
              <Select
                id="orbit-properties-select"
                name="Orbit Properties"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Orbit Properties"
                options={renderRawDataOrbitPropertiesOptions()}
                onChange={handleSelectedItem}
                isMulti={false}
                required={true}
                value={orbitProperties}
              />
            </div>
          </div>
        </Collapse>
      </>
    );
  };
  // RGB Visualization
  const handleRGBVisualization = () => {
    return (
      <>
        <span onClick={() => handleCateogryToggle('water-rgb-visualization')}>
          <p className="sub-collapse-style mt-2">
            SAR RGB
            <span className="category-span">
              <span className="category-span">
                {openCollapse.water_rgb_visualization ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="water_rgb_visualization"
          isOpen={openCollapse?.water_rgb_visualization}
          className="collapse-moadal"
        >
          <div className="advance-search-fields mb-3">
            <div className="mt-2 mb-3">
              <Select
                id="polarisation-select"
                name="Polarisation"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Polarisation"
                options={renderRawDataPolarisationOptions()}
                onChange={handleSelectedItem}
                isMulti={false}
                required={true}
                value={polarisation}
              />
              <Select
                id="orbit-properties-select"
                name="Orbit Properties"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Orbit Properties"
                options={renderRawDataOrbitPropertiesOptions()}
                onChange={handleSelectedItem}
                isMulti={false}
                required={true}
                value={orbitProperties}
              />
              <span className="raw-data-date-title">Summer Date Selection</span>
              <Input
                type="date"
                placeholder="Start date"
                id="summerStartDate"
                bsSize="sm"
                className="sub-collapse-dropdown my-1 sub-collapse-date"
                onChange={(e) => handleSummerStartDate(e)}
                value={initialRawDataSelectedDate?.summerStartDate}
                min={initialRawDataSelectedDate.summerMinStartDate}
                max={initialRawDataSelectedDate.summerMaxEndDate}
                invalid={
                  new Date(initialRawDataSelectedDate.summerMinStartDate) >
                    new Date(initialRawDataSelectedDate?.summerStartDate) ||
                  new Date(initialRawDataSelectedDate.summerMaxStartDate) <
                    new Date(initialRawDataSelectedDate?.summerStartDate) ||
                  initialRawDataSelectedDate?.summerStartDate === 'Invalid date'
                }
              />
              {!summerError &&
                (new Date(initialRawDataSelectedDate.summerMinStartDate) >
                  new Date(initialRawDataSelectedDate?.summerStartDate) ||
                  new Date(initialRawDataSelectedDate.summerMaxStartDate) <
                    new Date(initialRawDataSelectedDate?.summerStartDate) ||
                  initialRawDataSelectedDate?.summerStartDate === 'Invalid date') && (
                  // document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true'
                  <FormFeedback invalid>Invalid date</FormFeedback>
                )}
              <Input
                type="date"
                placeholder="End date"
                bsSize="sm"
                className="sub-collapse-dropdown mt-2 sub-collapse-date"
                id="summerEndDate"
                onChange={(e) => handleSummerEndDate(e)}
                value={initialRawDataSelectedDate?.summerEndDate}
                min={initialRawDataSelectedDate.summerStartDate}
                max={initialRawDataSelectedDate.summerMaxEndDate}
                invalid={
                  new Date(initialRawDataSelectedDate.summerMinEndDate) >
                    new Date(initialRawDataSelectedDate?.summerEndDate) ||
                  new Date(initialRawDataSelectedDate.summerMaxEndDate) <
                    new Date(initialRawDataSelectedDate?.summerEndDate) ||
                  initialRawDataSelectedDate?.summerEndDate === 'Invalid date' ||
                  summerError
                }
              />
              {(new Date(initialRawDataSelectedDate.summerMinEndDate) >
                new Date(initialRawDataSelectedDate?.summerEndDate) ||
                new Date(initialRawDataSelectedDate.summerMaxEndDate) <
                  new Date(initialRawDataSelectedDate?.summerEndDate) ||
                initialRawDataSelectedDate?.summerEndDate === 'Invalid date' ||
                summerError) && (
                //  document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true'
                <FormFeedback invalid>
                  {summerErrorMessage ? summerErrorMessage : 'Invalid date'}
                </FormFeedback>
              )}
              <hr className="horizontal-line mt-2 mb-2" />
              <span className="raw-data-date-title">Monsoon Date Selection</span>
              <Input
                type="date"
                placeholder="Start date"
                bsSize="sm"
                id="monSoonStartDate"
                className="sub-collapse-dropdown my-1 sub-collapse-date"
                onChange={(e) => handleMonSoonStartDate(e)}
                value={initialRawDataSelectedDate?.monSoonStartDate}
                min={initialRawDataSelectedDate.monSoonMinStartDate}
                max={initialRawDataSelectedDate.monSoonMaxEndDate}
                invalid={
                  new Date(initialRawDataSelectedDate.monSoonMinStartDate) >
                    new Date(initialRawDataSelectedDate?.monSoonStartDate) ||
                  new Date(initialRawDataSelectedDate.monSoonMaxStartDate) <
                    new Date(initialRawDataSelectedDate?.monSoonStartDate) ||
                  initialRawDataSelectedDate?.monSoonStartDate === 'Invalid date'
                }
              />
              {!monSoonError &&
                (new Date(initialRawDataSelectedDate.monSoonMinStartDate) >
                  new Date(initialRawDataSelectedDate?.monSoonStartDate) ||
                  new Date(initialRawDataSelectedDate.monSoonMaxStartDate) <
                    new Date(initialRawDataSelectedDate?.monSoonStartDate) ||
                  initialRawDataSelectedDate?.monSoonStartDate === 'Invalid date') && (
                  // document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true'
                  <FormFeedback invalid>Invalid date</FormFeedback>
                )}
              <Input
                type="date"
                placeholder="End date"
                id="monSoonEndDate"
                bsSize="sm"
                className="sub-collapse-dropdown mt-2 sub-collapse-date"
                onChange={(e) => handleMonSoonEndDate(e)}
                value={initialRawDataSelectedDate?.monSoonEndDate}
                min={initialRawDataSelectedDate.monSoonStartDate}
                max={initialRawDataSelectedDate.monSoonMaxEndDate}
                invalid={
                  new Date(initialRawDataSelectedDate.monSoonMinEndDate) >
                    new Date(initialRawDataSelectedDate?.monSoonEndDate) ||
                  new Date(initialRawDataSelectedDate.monSoonMaxEndDate) <
                    new Date(initialRawDataSelectedDate?.monSoonEndDate) ||
                  initialRawDataSelectedDate?.monSoonEndDate === 'Invalid date' ||
                  monSoonError
                }
              />
              {(new Date(initialRawDataSelectedDate.monSoonMinEndDate) >
                new Date(initialRawDataSelectedDate?.monSoonEndDate) ||
                new Date(initialRawDataSelectedDate.monSoonMaxEndDate) <
                  new Date(initialRawDataSelectedDate?.monSoonEndDate) ||
                initialRawDataSelectedDate?.monSoonEndDate === 'Invalid date' ||
                monSoonError) && (
                //  document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true'
                <FormFeedback invalid>
                  {monSoonErrorMessage ? monSoonErrorMessage : 'Invalid date'}
                </FormFeedback>
              )}
              <hr className="horizontal-line mt-2 mb-2" />
              <span className="raw-data-date-title">Winter Date Selection</span>
              <Input
                type="date"
                placeholder="Start date"
                id="winterStartDate"
                bsSize="sm"
                className="sub-collapse-dropdown my-1 sub-collapse-date"
                onChange={(e) => handleWinterStartDate(e)}
                value={initialRawDataSelectedDate?.winterStartDate}
                min={initialRawDataSelectedDate.winterMinStartDate}
                max={initialRawDataSelectedDate.winterMaxEndDate}
                invalid={
                  new Date(initialRawDataSelectedDate.winterMinStartDate) >
                    new Date(initialRawDataSelectedDate?.winterStartDate) ||
                  new Date(initialRawDataSelectedDate.winterMaxStartDate) <
                    new Date(initialRawDataSelectedDate?.winterStartDate) ||
                  initialRawDataSelectedDate?.winterStartDate === 'Invalid date'
                }
              />

              {!winterError &&
                (new Date(initialRawDataSelectedDate.winterMinStartDate) >
                  new Date(initialRawDataSelectedDate?.winterStartDate) ||
                  new Date(initialRawDataSelectedDate.winterMaxStartDate) <
                    new Date(initialRawDataSelectedDate?.winterStartDate) ||
                  initialRawDataSelectedDate?.winterStartDate === 'Invalid date') && (
                  // document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true'
                  <FormFeedback invalid>Invalid date</FormFeedback>
                )}
              <Input
                type="date"
                placeholder="End date"
                id="winterEndDate"
                bsSize="sm"
                className="sub-collapse-dropdown mt-2 sub-collapse-date"
                onChange={(e) => handleWinterEndDate(e)}
                value={initialRawDataSelectedDate?.winterEndDate}
                min={initialRawDataSelectedDate.winterStartDate}
                max={initialRawDataSelectedDate.winterMaxEndDate}
                invalid={
                  new Date(initialRawDataSelectedDate.winterMinEndDate) >
                    new Date(initialRawDataSelectedDate?.winterEndDate) ||
                  new Date(initialRawDataSelectedDate.winterMaxEndDate) <
                    new Date(initialRawDataSelectedDate?.winterEndDate) ||
                  initialRawDataSelectedDate?.winterEndDate === 'Invalid date' ||
                  winterError
                }
              />
              {(new Date(initialRawDataSelectedDate.winterMinEndDate) >
                new Date(initialRawDataSelectedDate?.winterEndDate) ||
                new Date(initialRawDataSelectedDate.winterMaxEndDate) <
                  new Date(initialRawDataSelectedDate?.winterEndDate) ||
                initialRawDataSelectedDate?.winterEndDate === 'Invalid date' ||
                winterError) && (
                //  document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true'
                <FormFeedback invalid>
                  {winterErrorMessage ? winterErrorMessage : 'Invalid date'}
                </FormFeedback>
              )}
            </div>
          </div>
        </Collapse>
      </>
    );
  };

  const handleRenderCategoryWiseData = () => {
    return (
      <>
        {categoryWiseData?.map((i) => {
          return (
            <>
              <p className="indicators-headers">{i?.category_name}</p>
              <FormGroup check className="tab1-indicator-list">
                <Row>
                  <>
                    {i?.data?.map((item) => {
                      return (
                        <>
                          <Col sm={12} lg={12} md={12} className="radio-button-alignment mt-1">
                            <label
                              style={{
                                fontFamily: 'National',
                                fontSize: '15px',
                                color: '#363636',
                              }}
                              for={item.name}
                            >
                              {item.name}
                            </label>
                            <span className="radio-icon-button">
                              <input
                                type="radio"
                                id={item.name}
                                name={'indicies'}
                                value={item.slug}
                                checked={item.slug === selectedParam?.slug}
                                onChange={() => handleSelectedIndices(item)}
                                onClick={() => {
                                  handleCheckIndices(item);
                                }}
                              />
                              <i
                                className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                                id="info-icon-tool-tip"
                                data-tip
                                data-for="info-icon-tool-tip"
                                onClick={(e) => handleInfo(e, item.name)}
                              />
                              <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                                Click here to know more.
                              </ReactTooltip>
                            </span>
                          </Col>
                          {rawDataRenderState &&
                            item?.slug === 'sentinel1_raw_data' &&
                            handleRawData()}
                          {rgbVisualizationRenderState &&
                            item?.slug === 'sentinel1_rgb_visualization' &&
                            handleRGBVisualization()}
                        </>
                      );
                    })}
                  </>
                </Row>
              </FormGroup>
            </>
          );
        })}
      </>
    );
  };
  const FirstTab = () => {
    return (
      <div className="FirstTab mt-3">
        <span onClick={() => handleCateogryToggle('water-tab1-sensors')}>
          <p className="agriculture-headings-lable">
            Sensors
            <span className="category-span">
              <span className="category-span">
                {openCollapse.water_tab1_sensors ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="water_tab1_sensors"
          isOpen={openCollapse.water_tab1_sensors}
          className="collapse-moadal"
        >
          {handleTab1SensorsList(waterSensorsList)}
        </Collapse>
        {categoryWiseData?.length ? (
          <>
            <span onClick={() => handleCateogryToggle('water-tab1-indicators')}>
              <p className="agriculture-headings-lable">
                Indicators
                <span className="category-span">
                  <span className="category-span">
                    {openCollapse.water_tab1_indicators ? (
                      <AiOutlineCaretUp size={13} />
                    ) : (
                      <AiOutlineCaretDown size={13} />
                    )}
                  </span>
                </span>
              </p>
            </span>
            <Collapse
              id="water_tab1_indicators"
              isOpen={openCollapse.water_tab1_indicators}
              className="collapse-moadal"
            >
              {handleRenderCategoryWiseData()}
            </Collapse>
          </>
        ) : (
          ''
        )}
      </div>
    );
  };
  const handleTab2IndicatorsList = (waterIndicatorsList) => {
    return (
      <div className="mb-3">
        {waterIndicatorsList?.length &&
          waterIndicatorsList?.map((i) => {
            return (
              <>
                <p className="indicators-headers">{i?.category}</p>
                <FormGroup check>
                  <Row>
                    {i?.data?.map((item) => {
                      return (
                        <>
                          <Col sm={12} lg={12} md={12} className="mt-1 radio-button-alignment">
                            <label
                              style={{
                                fontFamily: 'National',
                                fontSize: '15px',
                                color: '#363636',
                              }}
                              for={item.name}
                            >
                              {item.name}
                            </label>
                            <span className="radio-icon-button">
                              <input
                                type="radio"
                                id={item.name}
                                name={'indicies'}
                                value={item.slug}
                                checked={item?.name === selectedIndicatorsParam?.name}
                                onChange={() => handleSelectedIndicators(item)}
                                onClick={() => {
                                  handleCheckIndices(item);
                                }}
                              />
                              <i
                                className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                                id="info-icon-tool-tip"
                                data-tip
                                data-for="info-icon-tool-tip"
                                onClick={(e) => handleInfo(e, item.name)}
                              />
                              <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                                Click here to know more.
                              </ReactTooltip>
                            </span>
                          </Col>
                          {rawDataRenderState && item?.name === 'SAR' && handleRawData()}
                          {rgbVisualizationRenderState &&
                            item?.name === 'SAR RGB' &&
                            handleRGBVisualization()}
                        </>
                      );
                    })}
                  </Row>
                </FormGroup>
              </>
            );
          })}
      </div>
    );
  };
  const handleTab2SensorsList = (list) => {
    return (
      <FormGroup check>
        <div className="mb-2">
          <Row>
            {list?.length &&
              list?.map((item) => {
                return (
                  <>
                    <Col sm={12} lg={12} md={12} className="mt-1 sensors-radios-buttons">
                      <label
                        style={{
                          fontFamily: 'National',
                          fontSize: '15px',
                          color: '#363636',
                        }}
                        for={item.sensor_name}
                      >
                        {item.sensor_name}
                      </label>
                      <span className="radio-icon-button">
                        <input
                          type="radio"
                          id={item.sensor_name}
                          name="radio-button"
                          className=""
                          value={item.sensor_name}
                          checked={item?.slug === selectedParam?.slug}
                          onChange={() => {
                            handleSelectedIndices(item);
                          }}
                        />
                        <i
                          className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                          id="info-icon-tool-tip"
                          data-tip
                          data-for="info-icon-tool-tip"
                          onClick={(e) => handleInfo(e, item.name)}
                        />
                        <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                          Click here to know more.
                        </ReactTooltip>
                      </span>
                    </Col>
                  </>
                );
              })}
          </Row>
        </div>
      </FormGroup>
    );
  };
  const SecondTab = () => {
    return (
      <div className="FirstTab mt-3">
        <span onClick={() => handleCateogryToggle('water-tab2-indicators')}>
          <p className="agriculture-headings-lable">
            Indicators
            <span className="category-span">
              <span className="category-span">
                {openCollapse.water_tab2_indicators ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="water_tab2_indicators"
          isOpen={openCollapse.water_tab2_indicators}
          className="collapse-moadal"
        >
          {handleTab2IndicatorsList(waterIndicatorsList)}
        </Collapse>
        {selectedIndicatorToSensorList?.length ? (
          <>
            <span onClick={() => handleCateogryToggle('water-tab2-sensors')}>
              <p className="agriculture-headings-lable">
                Sensors
                <span className="category-span">
                  <span className="category-span">
                    {openCollapse.water_tab2_sensors ? (
                      <AiOutlineCaretUp size={13} />
                    ) : (
                      <AiOutlineCaretDown size={13} />
                    )}
                  </span>
                </span>
              </p>
            </span>
            <Collapse
              id="water_tab2_sensors"
              isOpen={openCollapse.water_tab2_sensors}
              className="collapse-moadal"
            >
              {handleTab2SensorsList(selectedIndicatorToSensorList)}
            </Collapse>
          </>
        ) : (
          ''
        )}
      </div>
    );
  };
  const handleSelectedCompareSensorIndices = (value) => {
    //setSelectedParam(data)
    waterSensorsCompareList?.map((i, index) => {
      i.checked = false;
    });
    waterSensorsCompareList?.map((i, index) => {
      if (i.name === value?.name) {
        i.checked = !i.checked;
      }
    });
    setWaterSensorsCompareList([...waterSensorsCompareList]);
    // handleSelectedCompareParam(value);
  };
  const handleCompareTab1Sensors = (list) => {
    return (
      <FormGroup check>
        <div className="mb-2">
          <Row>
            {list?.map((item, index) => {
              return (
                <>
                  <Col sm={12} lg={12} md={12} className="mt-1 sensors-radios-buttons">
                    <label
                      style={{
                        fontFamily: 'National',
                        fontSize: '15px',
                        color: '#363636',
                      }}
                      for={item?.data[0]?.id}
                    >
                      {item.name}
                    </label>
                    <span className="radio-icon-button">
                      <input
                        type="radio"
                        id={item?.data[0]?.id}
                        name={'radio-compare-button'}
                        value={item.name}
                        checked={item.name === selectedCompareSensorParam?.name}
                        // checked={item.checked}
                        onChange={() => {
                          handleSelectedCompareSensorIndices(item);
                        }}
                        onClick={() => {
                          handleCompareSelectedSensor(item);
                          setGldasCompareRenderState(false);
                          setCompareRawDataRenderState(false);
                          setCompareRgbVisualizationRenderState(false);
                        }}
                      />
                      <i
                        className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                        id="info-icon-tool-tip"
                        data-tip
                        data-for="info-icon-tool-tip"
                        onClick={(e) => handleInfo(e, item.name)}
                      />
                      <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                        Click here to know more.
                      </ReactTooltip>
                    </span>
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      </FormGroup>
    );
  };
  const handleCompareRawData = () => {
    return (
      <>
        <span onClick={() => handleCateogryToggle('water-compare-tab1-raw-data')}>
          <p className="sub-collapse-style mt-2">
            SAR
            <span className="category-span">
              <span className="category-span">
                {openCollapse.water_compare_tab1_raw_data ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="water_compare_tab1_raw_data"
          isOpen={openCollapse?.water_compare_tab1_raw_data}
          className="collapse-moadal"
        >
          <div className="advance-search-fields mb-3">
            <div className="mt-2 mb-3">
              <Select
                id="compare-polarisation-select"
                name="Polarisation"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Polarisation"
                options={renderRawDataPolarisationOptions()}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={comparePolarisation}
              />
              <Select
                id="compare-orbit-properties-select"
                name="Orbit Properties"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Orbit Properties"
                options={renderRawDataOrbitPropertiesOptions()}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={compareOrbitProperties}
              />
            </div>
          </div>
        </Collapse>
      </>
    );
  };
  //Compare RGB Visualization
  const handleCompareRGBVisualization = () => {
    return (
      <>
        <span onClick={() => handleCateogryToggle('water-compare-rgb-visualization')}>
          <p className="sub-collapse-style mt-2">
            SAR RGB
            <span className="category-span">
              <span className="category-span">
                {openCollapse.water_compare_rgb_visualization ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="water_compare_rgb_visualization"
          isOpen={openCollapse?.water_compare_rgb_visualization}
          className="collapse-moadal"
        >
          <div className="advance-search-fields mb-3">
            <div className="mt-2 mb-3">
              <Select
                id="compare-polarisation-select"
                name="Polarisation"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Polarisation"
                options={renderRawDataPolarisationOptions()}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={comparePolarisation}
              />
              <Select
                id="compare-orbit-properties-select"
                name="Orbit Properties"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Orbit Properties"
                options={renderRawDataOrbitPropertiesOptions()}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={compareOrbitProperties}
              />
              <span className="raw-data-date-title">Summer Date Selection</span>
              <Input
                type="date"
                placeholder="Start date"
                id="compareSummerStartDate"
                bsSize="sm"
                className="sub-collapse-dropdown my-1 sub-collapse-date"
                onChange={(e) => handleCompareSummerStartDate(e)}
                value={initialCompareRawDataSelectedDate?.compareSummerStartDate}
                min={initialCompareRawDataSelectedDate.compareSummerMinStartDate}
                max={initialCompareRawDataSelectedDate.compareSummerMaxEndDate}
                invalid={
                  new Date(initialCompareRawDataSelectedDate.compareSummerMinStartDate) >
                    new Date(initialCompareRawDataSelectedDate?.compareSummerStartDate) ||
                  new Date(initialCompareRawDataSelectedDate.compareSummerMaxStartDate) <
                    new Date(initialCompareRawDataSelectedDate?.compareSummerStartDate) ||
                  initialCompareRawDataSelectedDate?.compareSummerStartDate === 'Invalid date'
                }
              />
              {!compareSummerError &&
                (new Date(initialCompareRawDataSelectedDate.compareSummerMinStartDate) >
                  new Date(initialCompareRawDataSelectedDate?.compareSummerStartDate) ||
                  new Date(initialCompareRawDataSelectedDate.compareSummerMaxStartDate) <
                    new Date(initialCompareRawDataSelectedDate?.compareSummerStartDate) ||
                  initialCompareRawDataSelectedDate?.compareSummerStartDate === 'Invalid date') && (
                  // document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true'
                  <FormFeedback invalid>Invalid date</FormFeedback>
                )}
              <Input
                type="date"
                placeholder="End date"
                id="compareSummerEndDate"
                bsSize="sm"
                className="sub-collapse-dropdown mt-2 sub-collapse-date"
                onChange={(e) => handleCompareSummerEndDate(e)}
                value={initialCompareRawDataSelectedDate.compareSummerEndDate}
                min={initialCompareRawDataSelectedDate.compareSummerStartDate}
                max={initialCompareRawDataSelectedDate.compareSummerMaxEndDate}
                invalid={
                  new Date(initialCompareRawDataSelectedDate.compareSummerMinEndDate) >
                    new Date(initialCompareRawDataSelectedDate?.compareSummerEndDate) ||
                  new Date(initialCompareRawDataSelectedDate.compareSummerMaxEndDate) <
                    new Date(initialCompareRawDataSelectedDate?.compareSummerEndDate) ||
                  initialCompareRawDataSelectedDate?.compareSummerEndDate === 'Invalid date' ||
                  compareSummerError
                }
              />
              {(new Date(initialCompareRawDataSelectedDate.compareSummerMinEndDate) >
                new Date(initialCompareRawDataSelectedDate?.compareSummerEndDate) ||
                new Date(initialCompareRawDataSelectedDate.compareSummerMaxEndDate) <
                  new Date(initialCompareRawDataSelectedDate?.compareSummerEndDate) ||
                initialCompareRawDataSelectedDate?.compareSummerEndDate === 'Invalid date' ||
                compareSummerError) && (
                //  document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true'
                <FormFeedback invalid>
                  {compareSummerErrorMessage ? compareSummerErrorMessage : 'Invalid date'}
                </FormFeedback>
              )}

              <hr className="horizontal-line mt-2 mb-2" />
              <span className="raw-data-date-title">Monsoon Date Selection</span>
              <Input
                type="date"
                placeholder="Start date"
                bsSize="sm"
                id="compareMonSoonStartDate"
                className="sub-collapse-dropdown my-1 sub-collapse-date"
                onChange={(e) => handleCompareMonSoonStartDate(e)}
                value={initialCompareRawDataSelectedDate?.compareMonSoonStartDate}
                min={initialCompareRawDataSelectedDate.compareMonSoonMinStartDate}
                max={initialCompareRawDataSelectedDate.compareMonSoonMaxEndDate}
                invalid={
                  new Date(initialCompareRawDataSelectedDate.compareMonSoonMinStartDate) >
                    new Date(initialCompareRawDataSelectedDate?.compareMonSoonStartDate) ||
                  new Date(initialCompareRawDataSelectedDate.compareMonSoonMaxStartDate) <
                    new Date(initialCompareRawDataSelectedDate?.compareMonSoonStartDate) ||
                  initialCompareRawDataSelectedDate?.compareMonSoonStartDate === 'Invalid date'
                }
              />
              {!compareMonSoonError &&
                (new Date(initialCompareRawDataSelectedDate.compareMonSoonMinStartDate) >
                  new Date(initialCompareRawDataSelectedDate?.compareMonSoonStartDate) ||
                  new Date(initialCompareRawDataSelectedDate.compareMonSoonMaxStartDate) <
                    new Date(initialCompareRawDataSelectedDate?.compareMonSoonStartDate) ||
                  initialCompareRawDataSelectedDate?.compareMonSoonStartDate ===
                    'Invalid date') && (
                  // document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true'
                  <FormFeedback invalid>Invalid date</FormFeedback>
                )}
              <Input
                type="date"
                placeholder="End date"
                id="compareMonSoonEndDate"
                bsSize="sm"
                className="sub-collapse-dropdown mt-2 sub-collapse-date"
                onChange={(e) => handleCompareMonSoonEndDate(e)}
                value={initialCompareRawDataSelectedDate.compareMonSoonEndDate}
                min={initialCompareRawDataSelectedDate.compareMonSoonStartDate}
                max={initialCompareRawDataSelectedDate.compareMonSoonMaxEndDate}
                invalid={
                  new Date(initialCompareRawDataSelectedDate.compareMonSoonMinEndDate) >
                    new Date(initialCompareRawDataSelectedDate?.compareMonSoonEndDate) ||
                  new Date(initialCompareRawDataSelectedDate.compareMonSoonMaxEndDate) <
                    new Date(initialCompareRawDataSelectedDate?.compareMonSoonEndDate) ||
                  initialCompareRawDataSelectedDate?.compareMonSoonEndDate === 'Invalid date' ||
                  compareMonSoonError
                }
              />
              {(new Date(initialCompareRawDataSelectedDate.compareMonSoonMinEndDate) >
                new Date(initialCompareRawDataSelectedDate?.compareMonSoonEndDate) ||
                new Date(initialCompareRawDataSelectedDate.compareMonSoonMaxEndDate) <
                  new Date(initialCompareRawDataSelectedDate?.compareMonSoonEndDate) ||
                initialCompareRawDataSelectedDate?.compareMonSoonEndDate === 'Invalid date' ||
                compareMonSoonError) && (
                //  document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true'
                <FormFeedback invalid>
                  {compareMonSoonErrorMessage ? compareMonSoonErrorMessage : 'Invalid date'}
                </FormFeedback>
              )}

              <hr className="horizontal-line mt-2 mb-2" />
              <span className="raw-data-date-title">Winter Date Selection</span>
              <Input
                type="date"
                placeholder="Start date"
                id="compareWinterStartDate"
                bsSize="sm"
                className="sub-collapse-dropdown my-1 sub-collapse-date"
                onChange={(e) => handleCompareWinterStartDate(e)}
                value={initialCompareRawDataSelectedDate?.compareWinterStartDate}
                min={initialCompareRawDataSelectedDate.compareWinterMinStartDate}
                max={initialCompareRawDataSelectedDate.compareWinterMaxEndDate}
                invalid={
                  new Date(initialCompareRawDataSelectedDate.compareWinterMinStartDate) >
                    new Date(initialCompareRawDataSelectedDate?.compareWinterStartDate) ||
                  new Date(initialCompareRawDataSelectedDate.compareWinterMaxStartDate) <
                    new Date(initialCompareRawDataSelectedDate?.compareWinterStartDate) ||
                  initialCompareRawDataSelectedDate?.compareWinterStartDate === 'Invalid date'
                }
              />
              {!compareWinterError &&
                (new Date(initialCompareRawDataSelectedDate.compareWinterMinStartDate) >
                  new Date(initialCompareRawDataSelectedDate?.compareWinterStartDate) ||
                  new Date(initialCompareRawDataSelectedDate.compareWinterMaxStartDate) <
                    new Date(initialCompareRawDataSelectedDate?.compareWinterStartDate) ||
                  initialCompareRawDataSelectedDate?.compareWinterStartDate === 'Invalid date') && (
                  // document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true'
                  <FormFeedback invalid>Invalid date</FormFeedback>
                )}

              <Input
                type="date"
                placeholder="End date"
                id="compareWinterEndDate"
                bsSize="sm"
                className="sub-collapse-dropdown mt-2 sub-collapse-date"
                onChange={(e) => handleCompareWinterEndDate(e)}
                value={initialCompareRawDataSelectedDate.compareWinterEndDate}
                min={initialCompareRawDataSelectedDate.compareWinterStartDate}
                max={initialCompareRawDataSelectedDate.compareWinterMaxEndDate}
                invalid={
                  new Date(initialCompareRawDataSelectedDate.compareWinterMinEndDate) >
                    new Date(initialCompareRawDataSelectedDate?.compareWinterEndDate) ||
                  new Date(initialCompareRawDataSelectedDate.compareWinterMaxEndDate) <
                    new Date(initialCompareRawDataSelectedDate?.compareWinterEndDate) ||
                  initialCompareRawDataSelectedDate?.compareWinterEndDate === 'Invalid date' ||
                  compareWinterError
                }
              />
              {(new Date(initialCompareRawDataSelectedDate.compareWinterMinEndDate) >
                new Date(initialCompareRawDataSelectedDate?.compareWinterEndDate) ||
                new Date(initialCompareRawDataSelectedDate.compareWinterMaxEndDate) <
                  new Date(initialCompareRawDataSelectedDate?.compareWinterEndDate) ||
                initialCompareRawDataSelectedDate?.compareWinterEndDate === 'Invalid date' ||
                compareWinterError) && (
                //  document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true'
                <FormFeedback invalid>
                  {compareWinterErrorMessage ? compareWinterErrorMessage : 'Invalid date'}
                </FormFeedback>
              )}
            </div>
          </div>
        </Collapse>
      </>
    );
  };
  const handleRenderCategoryWiseTab1CompareData = () => {
    return (
      <>
        {categoryWiseCompareData?.map((i) => {
          return (
            <>
              <p className="indicators-headers">{i?.category_name}</p>
              <FormGroup check>
                <Row>
                  <>
                    {i?.data?.map((item) => {
                      return (
                        <>
                          <Label check>
                            <Col sm={12} lg={12} md={12} className="mt-1 radio-button-alignment">
                              <label
                                style={{
                                  fontFamily: 'National',
                                  fontSize: '15px',
                                  color: '#363636',
                                }}
                                for={item.id}
                              >
                                {item.name}
                              </label>
                              <span className="radio-icon-button">
                                <input
                                  type="radio"
                                  id={item?.id}
                                  name={'compare-indicies'}
                                  value={item?.slug}
                                  checked={item?.slug === compareParam?.slug}
                                  onChange={() => handleSelectedCompareIndices(item)}
                                  onClick={() => {
                                    handleCheckCompareIndices(item);
                                  }}
                                />
                                <i
                                  className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                                  id="info-icon-tool-tip"
                                  data-tip
                                  data-for="info-icon-tool-tip"
                                  onClick={(e) => handleInfo(e, item.name)}
                                />
                                <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                                  Click here to know more.
                                </ReactTooltip>
                              </span>
                            </Col>
                          </Label>
                          {compareRawDataRenderState &&
                            item?.slug === 'sentinel1_raw_data' &&
                            handleCompareRawData()}
                          {compareRgbVisualizationRenderState &&
                            item?.slug === 'sentinel1_rgb_visualization' &&
                            handleCompareRGBVisualization()}
                        </>
                      );
                    })}
                  </>
                </Row>
              </FormGroup>
            </>
          );
        })}
      </>
    );
  };
  const handleCompareTab1Indices = () => {
    return (
      <>
        <span onClick={() => handleCateogryToggle('compare-water-tab1-sensors')}>
          <p className="agriculture-headings-lable">
            Sensors
            <span className="category-span">
              <span className="category-span">
                {openCollapse.compare_water_tab1_sensors ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="compare_water_tab1_sensors"
          isOpen={openCollapse.compare_water_tab1_sensors}
          className="collapse-moadal"
        >
          {handleCompareTab1Sensors(waterSensorsCompareList)}
        </Collapse>
        {categoryWiseCompareData?.length ? (
          <>
            <span onClick={() => handleCateogryToggle('compare-water-tab1-indicators')}>
              <p className="agriculture-headings-lable">
                Indicators
                <span className="category-span">
                  <span className="category-span">
                    {openCollapse.compare_water_tab1_indicators ? (
                      <AiOutlineCaretUp size={13} />
                    ) : (
                      <AiOutlineCaretDown size={13} />
                    )}
                  </span>
                </span>
              </p>
            </span>
            <Collapse
              id="compare_water_tab1_indicators"
              isOpen={openCollapse.compare_water_tab1_indicators}
              className="collapse-moadal"
            >
              {handleRenderCategoryWiseTab1CompareData()}
            </Collapse>
          </>
        ) : (
          ''
        )}
      </>
    );
  };
  const handleRenderCategoryWiseTab2CompareData = () => {
    return (
      <div className="mb-2">
        {waterIndicatorsCompareList?.length &&
          waterIndicatorsCompareList?.map((i) => {
            return (
              <>
                <p className="indicators-headers">{i?.category}</p>
                <FormGroup check>
                  <Row>
                    {i?.data?.map((item) => {
                      return (
                        <>
                          <Col sm={12} lg={12} md={12} className="mt-1 radio-button-alignment">
                            <label
                              style={{
                                fontFamily: 'National',
                                fontSize: '15px',
                                color: '#363636',
                              }}
                              for={`${item.name}-compare`}
                            >
                              {item.name}
                            </label>
                            <span className="radio-icon-button">
                              <input
                                type="radio"
                                id={`${item.name}-compare`}
                                name={'selected_tab2_indicator'}
                                value={item.slug}
                                checked={item?.name === selectedCompareIndicatorsParam?.name}
                                onChange={() => handleSelectedCompareIndicators(item)}
                                onClick={() => {
                                  handleCheckCompareIndices(item);
                                }}
                              />

                              <i
                                className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                                id="info-icon-tool-tip"
                                data-tip
                                data-for="info-icon-tool-tip"
                                onClick={(e) => handleInfo(e, item.name)}
                              />
                              <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                                Click here to know more.
                              </ReactTooltip>
                            </span>
                          </Col>
                          {compareRawDataRenderState &&
                            item?.name === 'SAR' &&
                            handleCompareRawData()}
                          {compareRgbVisualizationRenderState &&
                            item?.name === 'SAR RGB' &&
                            handleCompareRGBVisualization()}
                        </>
                      );
                    })}
                  </Row>
                </FormGroup>
              </>
            );
          })}
      </div>
    );
  };
  const handleTab2CompareSensorsList = (list) => {
    return (
      <FormGroup check>
        <div className="mb-2">
          <Row>
            {list?.length &&
              list?.map((item, index) => {
                return (
                  <>
                    <Col sm={12} lg={12} md={12} className="mt-1 sensors-radios-buttons">
                      <label
                        style={{
                          fontFamily: 'National',
                          fontSize: '15px',
                          color: '#363636',
                        }}
                        for={index}
                      >
                        {item.sensor_name}
                      </label>
                      <span className="radio-icon-button">
                        <input
                          type="radio"
                          id={index}
                          name="radio-tab2-comapare-button"
                          className=""
                          value={item.sensor_name}
                          checked={item?.slug === compareParam?.slug}
                          onChange={() => handleSelectedCompareIndices(item)}
                        />
                        <i
                          className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                          id="info-icon-tool-tip"
                          data-tip
                          data-for="info-icon-tool-tip"
                          onClick={(e) => handleInfo(e, item.name)}
                        />
                        <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                          Click here to know more.
                        </ReactTooltip>
                      </span>
                    </Col>
                  </>
                );
              })}
          </Row>
        </div>
      </FormGroup>
    );
  };
  const handleCompareTab2Indices = () => {
    return (
      <>
        <span onClick={() => handleCateogryToggle('water-compare-tab2-indicators')}>
          <p className="agriculture-headings-lable">
            Indicators
            <span className="category-span">
              <span className="category-span">
                {openCollapse.water_compare_tab2_indicators ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="water_compare_tab2_indicators"
          isOpen={openCollapse.water_compare_tab2_indicators}
          className="collapse-moadal"
        >
          {handleRenderCategoryWiseTab2CompareData()}
        </Collapse>
        {selectedCompareIndicatorToSensorList?.length ? (
          <>
            <span onClick={() => handleCateogryToggle('water-compare-tab2-sensors')}>
              <p className="agriculture-headings-lable">
                Sensors
                <span className="category-span">
                  <span className="category-span">
                    {openCollapse.water_compare_tab2_sensors ? (
                      <AiOutlineCaretUp size={13} />
                    ) : (
                      <AiOutlineCaretDown size={13} />
                    )}
                  </span>
                </span>
              </p>
            </span>
            <Collapse
              id="water_compare_tab2_sensors"
              isOpen={openCollapse.water_compare_tab2_sensors}
              className="collapse-moadal"
            >
              {handleTab2CompareSensorsList(selectedCompareIndicatorToSensorList)}
            </Collapse>
          </>
        ) : (
          ''
        )}
      </>
    );
  };
  return (
    <>
      <div className="forecast-monitoring">
        <>
          <div className="advance-search-fields">
            <div className="data-source-field-one">
              <span onClick={() => handleCateogryToggle('agriculture-location')}>
                <p className="map-preferences-labels">
                  Location
                  <span className="category-span">
                    <span className="category-span">
                      {openCollapse.agriculture_location ? (
                        <AiOutlineCaretUp size={13} />
                      ) : (
                        <AiOutlineCaretDown size={13} />
                      )}
                    </span>
                  </span>
                </p>
              </span>
              <Collapse
                id="agriculture_location"
                isOpen={openCollapse.agriculture_location}
                className="collapse-moadal"
              >
                {handleLocationRender()}
              </Collapse>
              <ul className="navtabs-switch">
                <li
                  className={activeTabSwitch === 'tab1' ? 'active tabButton1' : 'tabButton2'}
                  onClick={handleTab1}
                >
                  Sensors
                </li>
                <li
                  className={activeTabSwitch === 'tab2' ? 'active tabButton1' : 'tabButton2'}
                  onClick={handleTab2}
                >
                  Indicators
                </li>
              </ul>
              <div className="outlet">{activeTabSwitch === 'tab1' ? FirstTab() : SecondTab()}</div>
              {rgbVisualizationRenderState || timeFrameRemoval ? (
                ''
              ) : (
                <>
                  <span onClick={() => handleCateogryToggle('agriculture-datetime')}>
                    <p
                      className="map-preferences-labels"
                      //style={checkboxStyle}
                    >
                      Timeframe & Range
                      <span className="category-span">
                        <span className="category-span">
                          {openCollapse.agriculture_datetime ? (
                            <AiOutlineCaretUp size={13} />
                          ) : (
                            <AiOutlineCaretDown size={13} />
                          )}
                        </span>
                      </span>
                    </p>
                  </span>
                  <Collapse
                    id="agriculture_datetime"
                    isOpen={openCollapse.agriculture_datetime}
                    className="collapse-moadal"
                  >
                    {handleDateTimeRender()}
                    {!CROP_MONITOR_MIN_MAX_HIDE_INDICATORS_SLUG?.includes(selectedParam?.slug) &&
                    handleMinMaxRender()}
                    {/* {handleMinMaxRender()} */}
                    <div className="mx-3 mb-2">
                      {minError ? (
                        <FormFeedback invalid>
                          {t('DROUGHT_MONITORING.DROUGHT_MIN_MAX_ERROR_MESSAGE')}
                        </FormFeedback>
                      ) : (
                        ''
                      )}
                    </div>
                  </Collapse>
                </>
              )}
              <>
                <div
                  className={
                    isSelectedRegion?.region?.length || selectedParam?.slug === 'discharge_forecast'
                      ? 'disable-compare'
                      : 'enable-compare'
                  }
                >
                  <span onClick={() => handleCateogryToggle('agriculture-compare')}>
                    <p
                      className={
                        isSelectedRegion?.region?.length ||
                        selectedParam?.slug === 'discharge_forecast'
                          ? 'compare-disabled map-preferences-labels'
                          : 'map-preferences-labels'
                      }
                    >
                      Compare
                      <span className="category-span">
                        <span className="category-span">
                          {openCollapse.agriculture_compare &&
                          !isSelectedRegion?.region?.length &&
                          selectedParam?.slug !== 'discharge_forecast' ? (
                            <BiMinus size={25} />
                          ) : (
                            <BiPlus size={25} />
                          )}
                        </span>
                      </span>
                    </p>
                  </span>
                  <Collapse
                    id="agriculture_compare"
                    isOpen={
                      openCollapse.agriculture_compare &&
                      !isSelectedRegion?.region?.length &&
                      selectedParam?.slug !== 'discharge_forecast'
                    }
                    className="collapse-moadal pb-2"
                    style={{ background: 'rgba(221, 145, 3, 0.2)' }}
                  >
                    {/* map and graph checkbox in compare section*/}
                    {handleWaterCompare()}
                    {handleCompareLocationRender()}
                    {activeTabSwitch === 'tab1'
                      ? handleCompareTab1Indices()
                      : handleCompareTab2Indices()}
                    {/* This CompareLocation will displayed only in graph checkbox*/}
                    {compareRgbVisualizationRenderState ? '' : renderCompareDates(isCompare)}
                    {!CROP_MONITOR_MIN_MAX_HIDE_INDICATORS_SLUG?.includes(compareParam?.slug) &&
                    handleComapareMinMaxRender()}
                    {/* {handleComapareMinMaxRender()} */}
                    <div className="mx-3 mb-2">
                      {compareMinError ? (
                        <FormFeedback invalid>
                          {t('DROUGHT_MONITORING.DROUGHT_MIN_MAX_ERROR_MESSAGE')}
                        </FormFeedback>
                      ) : (
                        ''
                      )}
                    </div>
                  </Collapse>
                </div>
              </>
              {/* Temporal aggregationa and precipitation Threshold */}
              {selectedParam?.slug === 'discharge_forecast' ? (
                ' '
              ) : (
                <div className="mt-3 temp-style">
                  <div className="mt-2 mb-3 mx-3">
                    <Select
                      id="multi-select"
                      name="aggregation"
                      placeholder="Temporal Aggregation"
                      maxMenuHeight={100}
                      menuPlacement="auto"
                      options={TEMPORAL_AGGREGATION}
                      value={temporalAggregation}
                      onChange={handleTemporalAggregation}
                      isMulti={false}
                      required={true}
                    />
                    {/* <Input
                    type="number"
                    placeholder="Precipitation Threshold"
                    bsSize="sm"
                    className="mt-3 mb-3 p-2 temp-input"
                    value={precipitationThreshold || ''}
                    onChange={e => handlePrecipitationThreshold(e)}
                  /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
};
export default Water;