import { checkHttpStatus } from '../api/apiUtils';
import { showError, showSuccess } from '../../Components/Notifications';


const baseMethod = async (
  serviceMethod,
  successMsg,
  showSuccessNotification,
  showErrorNotification
) => {
  const requestData = await serviceMethod
    .then(checkHttpStatus)
    .then((response) => {
      if (response) {
        if(showSuccessNotification){
          if(successMsg !== ""){
            showSuccess(successMsg);
          }
          else{
            if(typeof response?.data?.result === "string"){
              showSuccess(response?.data?.result)
            }
          }
        }
      }
      return response;
    })
    .catch((err) => {
      if (err.response) {
        if(showErrorNotification){
          if(err.response.data.message){
            showError(err.response.data.message);
          }
          else if(err.response.data.exception){
            showError("Something went wrong")
          }
        }
      }
      return err.response;
    });
  return requestData;
};

export default baseMethod;
