import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { HiDotsHorizontal, HiPencil } from 'react-icons/hi';
import { IoNavigate } from 'react-icons/io5'
import { MdDelete } from 'react-icons/md';
import { t } from 'react-multi-lang';
import { ReactComponent as FarmDefaultIcon } from '../../../assets/Images/farm_default_icon.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAllByCriteriaFarmListRequest } from '../../../redux/actions';

const FarmListing = (props) => {
  const {
    farmTrackerList,
    toggle,
    getSelectedFarm,
    handleDelete,
    getFarmData,
    setShowPolygonDraw,
    projectDetails,
    selectedFarmIndex,
    setFarmLayersData,
  } = props;
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setCount] = useState();

  const handleSelectedItem = (item,index) =>{
    /** need to show farm details collapse also by default when user selected any farm so calling edit method  */
    // getSelectedFarm(item);
    // setShowPolygonDraw(false);
    /** else call getFarm data method */
    setShowPolygonDraw(false);
    getFarmData(item,index)
  }
  const handleEditItem = (e, item) => {
    e.stopPropagation();
    getSelectedFarm(item);
    setShowPolygonDraw(false);
  };

  const handleDeleteFarm = (e, item) => {
    e.stopPropagation();
    handleDelete(item);
  };

  const NavigateToRiskAnalytics = (e, item) =>{
    e.stopPropagation();
    props?.NavigateToRiskAnalytics(item);
  }

  useEffect(() => {
    setCount(Number(projectDetails?.farm_count));
  }, [projectDetails]);

  const fetchNextStaticResources = () => {
    let reqData = {
      page: pageNumber + 1,
      farm_tracker_project_id: projectDetails?.project_id,
    };
    setPageNumber(pageNumber + 1);
    setFarmLayersData([])
    dispatch(getAllByCriteriaFarmListRequest(reqData));
  };
   return (
    <>
      <div id="scrollableDiv" className="farm-list-scroll">
        <InfiniteScroll
          dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
          next={fetchNextStaticResources}
          hasMore={pageNumber * 10 < pageCount ? true : false}
          loader={<h4>Loading ... </h4>}
          style={{ overflow: 'hidden', paddingBottom: '48px' }}
          scrollableTarget="scrollableDiv"
        >
          {farmTrackerList?.length ? (
            farmTrackerList?.map((item, index) => {
              return (
                <>
                  <div>
                    <Row>
                      <Col>
                        <Card
                          className="farm-track-list-st mb-1 cursor-ponter"
                          onClick={() => handleSelectedItem(item, index)}
                        >
                          <CardBody
                            key={index}
                            style={
                              selectedFarmIndex === index ? { backgroundColor: '#E7F1FD' } : {}
                            }
                          >
                            <Row className="mx-1 listing-st">
                              <Col sm="4" lg="4" md="4">
                                {' '}
                                {item?.profile_icon ? (
                                  <img
                                    src={item.profile_icon}
                                    alt="profile icon"
                                    width={50}
                                    height={40}
                                  />
                                ) : (
                                  <FarmDefaultIcon width={50} height={40} />
                                )}
                              </Col>
                              <Col sm="5" lg="5" md="5">
                                <Row>
                                  {' '}
                                  {/* <Col>{item?.name}</Col> */}
                                  <Col
                                    style={
                                      selectedFarmIndex === index ? { fontWeight: 'bold' } : {}
                                    }
                                  >
                                    {_.capitalize(item?.name)}
                                  </Col>
                                </Row>
                                <Row className="date-st">
                                  <Col
                                    style={
                                      selectedFarmIndex === index ? { fontWeight: 'bold' } : {}
                                    }
                                  >
                                    {moment(new Date(item?.updated_date))
                                      .local()
                                      .format('Do MMM YYYY')}
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm="3" lg="3" md="3">
                                <Dropdown
                                  isOpen={item.isOpenAction}
                                  toggle={() => toggle(index, farmTrackerList)}
                                  className="farm-dropdown-option-icon"
                                >
                                  <DropdownToggle
                                    className="toggle-bt"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <HiDotsHorizontal />
                                  </DropdownToggle>
                                  <DropdownMenu className="details-dropdown-menu">
                                    <DropdownItem
                                      className="details-dropdown-items"
                                      onClick={(e) => handleEditItem(e, item)}
                                      style={{borderBottom:"0.5px solid #e8e7e7"}}
                                    >
                                      {' '}
                                      <HiPencil />
                                      {/* {t('FARM_TRACKER.EDIT_FARM')} */}
                                      {t('Edit')}
                                    </DropdownItem>
                                    <DropdownItem
                                      className="details-dropdown-items"
                                      onClick={(e) => handleDeleteFarm(e, item)}
                                      style={{borderBottom:"0.5px solid #e8e7e7"}}

                                    >
                                      <MdDelete /> {t('FARM_TRACKER.DELETE')}
                                    </DropdownItem>
                                    <DropdownItem
                                      className="details-dropdown-items"
                                      onClick={(e) => NavigateToRiskAnalytics(e, item)}
                                      style={{borderBottom:"0.5px solid #e8e7e7"}}

                                    >
                                      <IoNavigate /> {t('FARM_TRACKER.VIEW_IN_RISK_ANALYTICS')}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </>
              );
            })
          ) : (
            <div className="mx-1 draw-msg-st">
              <div className="mt-1">{t('FARM_TRACKER.NO_FARMS')}</div>
              <div className="sub-text mt-1"> {t('FARM_TRACKER.DRAW_FARM_TEXT')}</div>
            </div>
          )}
        </InfiniteScroll>
        {/* <div className="add-farm-div">
        <Dropdown
          isOpen={isOpenAddFarm}
          toggle={() => setIsOpenAddFarm(!isOpenAddFarm)}
          className="farm-add-cls"
          direction="up"
        >
          <DropdownToggle className="toggle-bt mx-4 px-5 py-2">
            <span> {t('FARM_TRACKER.ADD_FARM')} </span>
            <MdArrowDropDown />
          </DropdownToggle>
          <DropdownMenu className="add-details-dropdown-menu">
            <DropdownItem
              className="details-dropdown-item"
              onClick={() => handleSelectedFarmOption('upload_farm')}
            >
              {' '}
              <span style={{ width: 20, height: 20 }}>
                <UploadFarm />
              </span>
              {t('FARM_TRACKER.UPLOAD_FILE')}
            </DropdownItem>
            <DropdownItem
              className="details-dropdown-item"
              onClick={() => handleSelectedFarmOption('draw_farm')}
              title={'Choosing draw farm option will allow you to draw polygon on the map'}
            >
              <TbPolygon size={18} /> {t('FARM_TRACKER.DRAW_FARM')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div> */}
      </div>
    </>
  );
};
export default FarmListing;
