import FARM_TRACKER_CONST from './farmTrackerConsts';

/** Farm actions */
export const getFarmDetailsByIdRequest = id => ({
  type: FARM_TRACKER_CONST.GET_FARM_DETAILS_BY_ID_REQUEST,
  payload: id,
});

export const getFarmDetailsByIdSuccess = response => ({
  type: FARM_TRACKER_CONST.GET_FARM_DETAILS_BY_ID_SUCCESS,
  payload: response,
});

export const getFarmDetailsByIdFailed = error => ({
  type: FARM_TRACKER_CONST.GET_FARM_DETAILS_BY_ID_FAILED,
  payload: error,
});

export const getAllByCriteriaFarmListRequest = payload => ({
  type: FARM_TRACKER_CONST.GET_FARM_LIST_REQUEST,
  payload,
});

export const getAllByCriteriaFarmListSuccess = response => ({
  type: FARM_TRACKER_CONST.GET_FARM_LIST_SUCCESS,
  payload: response,
});

export const getAllByCriteriaFarmListFailed = error => ({
  type: FARM_TRACKER_CONST.GET_FARM_LIST_FAILED,
  payload: error,
});

export const addFarmTrackerRequest = data => ({
  type: FARM_TRACKER_CONST.ADD_FARM_TRACKER_REQUEST,
  payload: data,
});

export const addFarmTrackerSuccess = response => ({
  type: FARM_TRACKER_CONST.ADD_FARM_TRACKER_SUCCESS,
  payload: response,
});

export const addFarmTrackerFailed = error => ({
  type: FARM_TRACKER_CONST.ADD_FARM_TRACKER_FAILED,
  payload: error,
});

export const editFarmTrackerRequest = data => ({
  type: FARM_TRACKER_CONST.EDIT_FARM_TRACKER_REQUEST,
  payload: data,
});

export const editFarmTrackerSuccess = response => ({
  type: FARM_TRACKER_CONST.EDIT_FARM_TRACKER_SUCCESS,
  payload: response,
});

export const editFarmTrackerFailed = error => ({
  type: FARM_TRACKER_CONST.EDIT_FARM_TRACKER_FAILED,
  payload: error,
});

export const deleteFarmTrackerRequest = data => ({
  type: FARM_TRACKER_CONST.DELETE_FARM_TRACKER_REQUEST,
  payload: data,
});

export const deleteFarmTrackerSuccess = response => ({
  type: FARM_TRACKER_CONST.DELETE_FARM_TRACKER_SUCCESS,
  payload: response,
});

export const deleteFarmTrackerFailed = error => ({
  type: FARM_TRACKER_CONST.DELETE_FARM_TRACKER_FAILED,
  payload: error,
});

/** project actions */
export const getProjectDetailsByIdRequest = id => ({
  type: FARM_TRACKER_CONST.GET_PROJECT_DETAILS_BY_ID_REQUEST,
  payload: id,
});

export const getProjectDetailsByIdSuccess = response => ({
  type: FARM_TRACKER_CONST.GET_PROJECT_DETAILS_BY_ID_SUCCESS,
  payload: response,
});

export const getProjectDetailsByIdFailed = error => ({
  type: FARM_TRACKER_CONST.GET_PROJECT_DETAILS_BY_ID_FAILED,
  payload: error,
});

export const getAllByCriteriaProjectListRequest = (payload) => ({
  type: FARM_TRACKER_CONST.GET_PROJECT_LIST_REQUEST,
  payload,
});

export const getAllByCriteriaProjectListSuccess = response => ({
  type: FARM_TRACKER_CONST.GET_PROJECT_LIST_SUCCESS,
  payload: response,
});

export const getAllByCriteriaProjectListFailed = error => ({
  type: FARM_TRACKER_CONST.GET_PROJECT_LIST_FAILED,
  payload: error,
});

export const addProjectRequest = data => ({
  type: FARM_TRACKER_CONST.ADD_PROJECT_REQUEST,
  payload: data,
});

export const addProjectSuccess = response => ({
  type: FARM_TRACKER_CONST.ADD_PROJECT_SUCCESS,
  payload: response,
});

export const addProjectFailed = error => ({
  type: FARM_TRACKER_CONST.ADD_PROJECT_FAILED,
  payload: error,
});

export const editProjectRequest = data => ({
  type: FARM_TRACKER_CONST.EDIT_PROJECT_REQUEST,
  payload: data,
});

export const editProjectSuccess = response => ({
  type: FARM_TRACKER_CONST.EDIT_PROJECT_SUCCESS,
  payload: response,
});

export const editProjectFailed = error => ({
  type: FARM_TRACKER_CONST.EDIT_PROJECT_FAILED,
  payload: error,
});


export const deleteProjectRequest = data => ({
  type: FARM_TRACKER_CONST.DELETE_PROJECT_LIST_REQUEST,
  payload: data,
});

export const deleteProjectSuccess = response => ({
  type: FARM_TRACKER_CONST.DELETE_PROJECT_LIST_SUCCESS,
  payload: response,
});

export const deleteProjectFailed = error => ({
  type: FARM_TRACKER_CONST.DELETE_PROJECT_LIST_FAILED,
  payload: error,
});

