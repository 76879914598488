import {
  CROP_HEALTH_MONITORING_CONST,
  CROP_HEALTH_MONITORING_MAP_CONST,
  CROP_HEALTH_CONST_GET_ALL_INDICES,
  CROP_HEALTH_WATER_MONITORING_MAP_CONST,
  CROP_HEALTH_MONITORING_TIME_SERIES_CONST,
  CROP_HEALTH_MONITORING_WATER_TIME_SERIES_CONST,
  CROP_HEALTH_MONITORING_GLDAS_CONST,
  CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_CONST,
  CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_CONST,
  AGRICULTURE_CONST_INDICATOR_TO_SENSOR,
  WATER_CONST_INDICATOR_TO_SENSOR,
} from './cropHealthMonitoringConsts';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  getCropIndicesListSuccess,
  getCropIndicesListFailed,
  getAgricultureMapSuccess,
  getAgricultureMapFailed,
  getAllIndicesSuccess,
  getAllIndicesFailed,
  getWaterMapFailed,
  getWaterMapSuccess,
  getAgricultureTimeSeriesSuccess,
  getAgricultureTimeSeriesFailed,
  getWaterTimeSeriesFailed,
  getWaterTimeSeriesSuccess,
  getCropGldasIndicesListSuccess,
  getCropGldasIndicesListFailed,
  getWaterResourceDrainageSuccess,
  getWaterResourceDrainageFailed,
  getWaterResourceCatchmentFailed,
  getWaterResourceCatchmentSuccess,
  getAgricultureIndicatorToSensorListSuccess,
  getAgricultureIndicatorToSensorListFailed,
  getWaterIndicatorToSensorListSuccess,
  getWaterIndicatorToSensorListFailed,
} from './cropHealthMonitoringAction';

import {
  CROP_MONITOR_URL,
  AXIOS_INSTANCE,
  API_BASE_URL,
  WATER_RESOURCE_URL,
} from '../api/apiEndPoint';
import baseMethod from '../baseService.js';
import { checkHttpStatus } from '../api/apiUtils';
import { showError } from '../../Components/Notifications';

function* getCropIndicesListRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${CROP_MONITOR_URL}/getSensorToIndicesList`, action.payload),
      '',
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(getCropIndicesListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(getCropIndicesListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCropIndicesListFailed(errorData));
  }
}
//AGRICULTURE INDICATOR TO SENSOR
function* getAgricultureIndicatorToSensorListRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${CROP_MONITOR_URL}/getIndicesToSensorList`, action.payload),
      '',
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(getAgricultureIndicatorToSensorListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(getAgricultureIndicatorToSensorListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAgricultureIndicatorToSensorListFailed(errorData));
  }
}
function* getAgricultureMapRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${API_BASE_URL}/${action?.payload?.selectedIndicator}/map`,
        action.payload.requestObj,
      ),
      '',
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
          activetab:action.payload.activetab,
        },
      };
      yield put(getAgricultureMapSuccess(responseData));
    } else if (response.status === 400) {
      const responseData = {
        response: {
          statusCode: 400,
          data: response?.data,
          activetab:action.payload.activetab,
        },
      };
      yield put(getAgricultureMapFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAgricultureMapFailed(errorData));
  }
}

function* getAgricultureTimeSeriesRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${API_BASE_URL}/${action?.payload?.selectedIndicator}/timeseries`,
        action.payload.timeSeriesObj,
      ),
      '',
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
          location:action.payload.location,
        },
      };
      yield put(getAgricultureTimeSeriesSuccess(responseData));
    } else if (response.status === 400) {
      const responseData = {
        response: {
          statusCode: 400,
          data: response?.data?.message,
        },
      };
      yield put(getAgricultureTimeSeriesFailed(responseData));
    }else{
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAgricultureTimeSeriesFailed(errorData));
  }
}
// for water resource

function* getAllIndicesRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${WATER_RESOURCE_URL}/getSensorToIndicesList`, action.payload),
      // '',
      // true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(getAllIndicesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(getAllIndicesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllIndicesFailed(errorData));
  }
}

function* getWaterMapRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${API_BASE_URL}/${action?.payload?.selectedIndicator}/map`,
        action.payload.requestObj,
      ),
      '',
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
          activetab: action.payload.activetab,
        },
      };
      yield put(getWaterMapSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 400,
          data: response?.data?.message,
          activetab: action.payload.activetab,
        },
      };
      yield put(getWaterMapFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getWaterMapFailed(errorData));
  }
}

// water graph part
function* getWaterTimeSeriesRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.post(
        `${API_BASE_URL}/${action?.payload?.selectedIndicator}/timeseries`,
        action.payload.timeSeriesObj,
      ),
      '',
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
          location:action.payload.location
        },
      };
      yield put(getWaterTimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 400,
          data: response.data.message,
        },
      };
      yield put(getWaterTimeSeriesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getWaterTimeSeriesFailed(errorData));
  }
}
//gldas

function* getCropGldasIndicesListRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${CROP_MONITOR_URL}/gldas-bands-list/${action.payload.module_name}`, action.payload),
      '',
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(getCropGldasIndicesListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(getCropGldasIndicesListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCropGldasIndicesListFailed(errorData));
  }
}
// geoglows drainage
function* getWaterResourceDrainageRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${WATER_RESOURCE_URL}/geeoglows/wms_drainage`, action.payload),
      // '',
      // true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(getWaterResourceDrainageSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(getWaterResourceDrainageFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getWaterResourceDrainageFailed(errorData));
  }
}
//geoglows catchment
function* getWaterResourceCatchmentRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${WATER_RESOURCE_URL}/geeoglows/wms_catchment`, action.payload),
      // '',
      // true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(getWaterResourceCatchmentSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(getWaterResourceCatchmentFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getWaterResourceCatchmentFailed(errorData));
  }
}
//WATER INDICATOR TO SENSOR
function* getWaterIndicatorToSensorListRequest(action) {
  try {
    const apiResponse = baseMethod(
      AXIOS_INSTANCE.get(`${WATER_RESOURCE_URL}/getIndicesToSesnorList`, action.payload),
      '',
      true,
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.result,
        },
      };
      yield put(getWaterIndicatorToSensorListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(getWaterIndicatorToSensorListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getWaterIndicatorToSensorListFailed(errorData));
  }
}
export function* watchUserList() {
  yield takeEvery(
    CROP_HEALTH_MONITORING_CONST.CROP_HEALTH_MONITORING_REQUEST,
    getCropIndicesListRequest,
  );
  //AGRICULTURE INDICATOR TO SENSOR
  yield takeEvery(
    AGRICULTURE_CONST_INDICATOR_TO_SENSOR.AGRICULTURE_CONST_INDICATOR_TO_SENSOR_REQUEST,
    getAgricultureIndicatorToSensorListRequest,
  );
  yield takeEvery(
    CROP_HEALTH_MONITORING_MAP_CONST.CROP_HEALTH_MONITORING_MAP_REQUEST,
    getAgricultureMapRequest,
  );
  yield takeEvery(
    CROP_HEALTH_CONST_GET_ALL_INDICES.CROP_HEALTH_CONST_GET_ALL_INDICES_REQUEST,
    getAllIndicesRequest,
  );
  yield takeEvery(
    CROP_HEALTH_WATER_MONITORING_MAP_CONST.CROP_HEALTH_WATER_MONITORING_MAP_REQUEST,
    getWaterMapRequest,
  );
  yield takeEvery(
    CROP_HEALTH_MONITORING_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_TIME_SERIES_REQUEST,
    getAgricultureTimeSeriesRequest,
  );
  yield takeEvery(
    CROP_HEALTH_MONITORING_WATER_TIME_SERIES_CONST.CROP_HEALTH_MONITORING_WATER_TIME_SERIES_REQUEST,
    getWaterTimeSeriesRequest,
  );
  //gldas
  yield takeEvery(
    CROP_HEALTH_MONITORING_GLDAS_CONST.CROP_HEALTH_MONITORING_GLDAS_REQUEST,
    getCropGldasIndicesListRequest,
  );
  // geoglows drainage
  yield takeEvery(
    CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_DRAINAGE_REQUEST,
    getWaterResourceDrainageRequest,
  );
  //geoglows catchment
  yield takeEvery(
    CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_CONST.CROP_HEALTH_MONITORING_GEOGLOWS_CATCHMENT_REQUEST,
    getWaterResourceCatchmentRequest,
  );
  //WATER INDICATOR TO SENSOR
  yield takeEvery(
    WATER_CONST_INDICATOR_TO_SENSOR.WATER_CONST_INDICATOR_TO_SENSOR_REQUEST,
    getWaterIndicatorToSensorListRequest,
  );
}

function* cropHealthMonitoringSaga() {
  yield all([fork(watchUserList)]);
}

export default cropHealthMonitoringSaga;
