export const CLIMATE_WEATHER_LOCATION_CONST = {
    CLIMATE_WEATHER_LOCATION_REQUEST: 'CLIMATE_WEATHER_LOCATION_REQUEST',
    CLIMATE_WEATHER_LOCATION_SUCCESS: 'CLIMATE_WEATHER_LOCATION_SUCCESS',
    CLIMATE_WEATHER_LOCATION_FAILED: 'CLIMATE_WEATHER_LOCATION_FAILED',
  }

export const DATA_SOURCES_CONST = {
  DATA_SOURCES_REQUEST:"DATA_SOURCES_REQUEST",
  DATA_SOURCES_SUCCESS:"DATA_SOURCES_SUCCESS",
  DATA_SOURCES_FAILED: "DATA_SOURCES_FAILED"
}