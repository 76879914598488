import axios from 'axios';
import RISK_ANALYTICS_CONST from './riskAnalyticsConsts';
export const getRiskScoreTimeSeries = (requestObj, riskScoreDataObj) => ({
  type: RISK_ANALYTICS_CONST.GET_RISK_SCORE_REQUEST,
  payload: { requestObj, isCompare: riskScoreDataObj ? riskScoreDataObj?.isCompare : false, id: riskScoreDataObj?.id, compareId: riskScoreDataObj?.compareId},
});

export const getRiskScoreTimeSeriesSuccess = response => ({
  type: RISK_ANALYTICS_CONST.GET_RISK_SCORE_SUCCESS,
  payload: response,
});

export const getRiskScoreTimeSeriesError = response => ({
  type: RISK_ANALYTICS_CONST.GET_RISK_SCORE_FAILED,
  payload: response,
});

export const getIndices = payload => ({
  type: RISK_ANALYTICS_CONST.GET_RISK_INDICES_REQUEST,
  payload: payload,
});

export const getIndicesSuccess = response => ({
  type: RISK_ANALYTICS_CONST.GET_RISK_INDICES_SUCCESS,
  payload: response,
});

export const getIndicesError = response => ({
  type: RISK_ANALYTICS_CONST.GET_RISK_INDICES_FAILED,
  payload: response,
});


export const getIndicesChangeRequest = (payload) => ({
  type: RISK_ANALYTICS_CONST.GET_INDICES_CHANGE_REQUEST,
  payload: payload,
});

export const getIndicesChangeSuccess = response => ({
  type: RISK_ANALYTICS_CONST.GET_INDICES_CHANGE_SUCCESS,
  payload: response,
});

export const getIndicesChangeError = response => ({
  type: RISK_ANALYTICS_CONST.GET_INDICES_CHANGE_FAILED,
  payload: response,
});

export const sendEmailRequest = (payload) => ({
  type: RISK_ANALYTICS_CONST.SEND_EMAIL_REQUEST,
  payload: payload,
});

export const sendEmailSuccess = response => ({
  type: RISK_ANALYTICS_CONST.SEND_EMAIL_SUCCESS,
  payload: response,
});

export const sendEmailError = response => ({
  type: RISK_ANALYTICS_CONST.SEND_EMAIL_FAILED,
  payload: response,
});

export const getCWPCropsRequest = (payload) => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_CROPS_LIST_REQUEST,
  payload: payload,
});

export const getCWPCropsSuccess = response => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_CROPS_LIST_SUCCESS,
  payload: response,
});

export const getCWPCropsFailed = response => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_CROPS_LIST_FAILED,
  payload: response,
});

export const getCWPSeasonRequest = (payload) => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_SEASONS_LIST_REQUEST,
  payload: payload,
});

export const getCWPSeasonSuccess = response => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_SEASONS_LIST_SUCCESS,
  payload: response,
});

export const getCWPSeasonFailed = response => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_SEASONS_LIST_FAILED,
  payload: response,
});

export const getCWPPractiseRequest = (payload) => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_PRACTISES_LIST_REQUEST,
  payload: payload,
});

export const getCWPPractiseSuccess = response => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_PRACTISES_LIST_SUCCESS,
  payload: response,
});

export const getCWPPractiseFailed = response => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_PRACTISES_LIST_FAILED,
  payload: response,
});

export const getCWPGraphDataRequest = (requestObj, cropType) => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_GRAPH_DATA_REQUEST,
  payload: { requestObj, crop_type: cropType},
});

export const getCWPGraphDataSuccess = response => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_GRAPH_DATA_SUCCESS,
  payload: response,
});

export const getCWPGraphDataFailed = response => ({
  type: RISK_ANALYTICS_CONST.GET_CWP_GRAPH_DATA_FAILED,
  payload: response,
});
