import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '../../assets/Images/icons/close.png';
import Button from 'reactstrap-button-loader';
import { Field, reduxForm, change } from 'redux-form';
import { userListRequest } from '../../redux/manage-user/manageUserAction';
import PropTypes from 'prop-types';
import { t } from 'react-multi-lang';
import { renderTextField } from '../../Components/common/renderInputField';

const validate = (values) => {
  return {};
};

const RightSideBar = (props) => {
  const dispatch = useDispatch();
  const { closed, handleClose, reset, closeRightBar, activeTab, pageLimit, pageNumber } = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  
  useEffect(() => {
    if (closed) {
      handleClearFilters();
    }
  }, [closed]);

  useEffect(() => {
    if (activeTab === 'new') {
      dispatch(
        userListRequest({
          key: 'is_pending',
          bool_value: 'True',
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: pageLimit,
          name: name ? name : '',
          mobile: phoneNumber ? phoneNumber : '',
          email: email ? email : '',
        }),
      );
    } else if (activeTab === 'approve') {
      dispatch(
        userListRequest({
          key: 'is_approve',
          bool_value: 'True',
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: pageLimit,
          name: name ? name : '',
          mobile: phoneNumber ? phoneNumber : '',
          email: email ? email : '',
        }),
      );
    } else if (activeTab === 'reject') {
      dispatch(
        userListRequest({
          key: 'is_reject',
          bool_value: 'True',
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: pageLimit,
          name: name ? name : '',
          mobile: phoneNumber ? phoneNumber : '',
          email: email ? email : '',
        }),
      );
    } else if (activeTab === 'delete') {
      dispatch(
        userListRequest({
          key: 'is_delete',
          bool_value: 'True',
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: pageLimit,
          name: name ? name : '',
          mobile: phoneNumber ? phoneNumber : '',
          email: email ? email : '',
        }),
      );
    }
  }, [name, email, phoneNumber, dispatch]);

  const handleClearFilters = (e) => {
    reset('filter-form');
    dispatch(change('filter-form', 'name', ''));
    dispatch(change('filter-form', 'email', ''));
    dispatch(change('filter-form', 'phone_number', ''));
    setName('');
    setEmail('');
    setPhoneNumber('');
    if (activeTab === 'new') {
      dispatch(
        userListRequest({
          key: 'is_pending',
          bool_value: 'True',
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: pageLimit,
          name: '',
          mobile: '',
          email: '',
        }),
      );
    } else if (activeTab === 'approve') {
      dispatch(
        userListRequest({
          key: 'is_approve',
          bool_value: 'True',
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: pageLimit,
          name: '',
          mobile: '',
          email: '',
        }),
      );
    } else if (activeTab === 'reject') {
      dispatch(
        userListRequest({
          key: 'is_reject',
          bool_value: 'True',
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: pageLimit,
          name: '',
          mobile: '',
          email: '',
        }),
      );
    } else if (activeTab === 'delete') {
      dispatch(
        userListRequest({
          key: 'is_delete',
          bool_value: 'True',
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: pageLimit,
          name: '',
          mobile: '',
          email: '',
        }),
      );
    }
  };

  const handleSearch = (e, type) => {
    if (type === 'Name') {
      setName(e.target.value);
    } else if (type === 'Email') {
      setEmail(e.target.value);
    } else if (type === 'Phone') {
      setPhoneNumber(e.target.value);
    }
  };

  return (
    <div className="st-right-sidebar-wrapper">
      <div className="d-flex st-filter-header">
        <h5 className="st-txt-primary">{t('FILTER.FILTER')}</h5>
        <div className="st-close ml-auto">
          <img
            className="st-close-img"
            alt={'not-available'}
            src={CloseIcon}
            onClick={() => {
              handleClose();
              closeRightBar();
            }}
          />
        </div>
      </div>
      <form id="filter-form">
        <Field
          component={renderTextField}
          name="name"
          bsSize="sm"
          className="mt-1 my-3 mx-2 input-style filter-select-placeholder"
          placeholder="Name"
          type="text"
          onChange={(e) => handleSearch(e, 'Name')}
          isMulti={false}
          required={false}
        ></Field>
        <Field
          component={renderTextField}
          name="email"
          bsSize="sm"
          className="mt-1 my-3 mx-2 input-style filter-select-placeholder"
          placeholder="Email"
          type="text"
          onChange={(e) => handleSearch(e, 'Email')}
          required={false}
          isMulti={false}
        ></Field>
        <Field
          component={renderTextField}
          name="phone_number"
          bsSize="sm"
          className="mt-1 my-3 mx-2 input-style filter-select-placeholder"
          placeholder="Phone"
          type="text"
          onChange={(e) => handleSearch(e, 'Phone')}
          required={false}
          isMulti={false}
        ></Field>
        <hr />
        <div className=" filter-buttons">
          <Button
            type="reset"
            className="btn st-btn-custom-ot clear-filter-btn"
            onClick={() => handleClearFilters()}
          >
            {t('FILTER.CLEAR_FILTERS')}
          </Button>
        </div>
        <hr />
        <div style={{ height: '200px' }} />
      </form>
    </div>
  );
};
RightSideBar.propTypes = {
  noRecordsErrorHandler: PropTypes.func,
  handleFilterFormData: PropTypes.func,
  formFilterData: PropTypes.object,
};
export default reduxForm({
  form: 'filter-form',
  validate,
})(RightSideBar);
