import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from 'react-multi-lang';
import moment from 'moment';
import _, { defaults, isEmpty, isNumber } from 'lodash';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Card, CardBody } from 'reactstrap';
import { Field, change } from 'redux-form';
import ForecastMonitoring from './Forecast-Monitoring';
import {
  getCountryRequest,
  getStateByCountryRequest,
  getDistrictByStateRequest,
  getWeatherForecastSources,
  getMapPreferenceRequest,
} from '../../../redux/actions';
import {
  DEFAULT_COUNTRY,
  SRILANKA_COORDINATE,
  DATE_FORMAT,
  LOCATION,
  TEMPORAL_AGGREGATION,
  DATA_SOURCES,
  DISPLAY_DATE_FORMAT,
  weatherForecastIndices,
  OPEN_WEATHER_CHART_PARAMS,
} from '../../constants/index';
import { dataSourcesRequest } from '../../../redux/climate-weather/climateWeatherAction';
import { FORECAST_LEAD_TIME_LIST } from '../../constants/index';

const Filter = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    clearDatesHandler,
    setBar,
    showCharts,
    filterActiveTab,
    selectedForecastYearMonth,
    selectedForecastCompareYearMonth,
    clearForecastData,
    //selectedFinalYearMonth,
    selectedFinalForecastYearMonth,
    forecastYearMonthHandler,
    forecastCompareYearMonthHandler,
    forecastLeadTimeHandler,
    forecastCompareLeadTimeHandler,
    forecastLeadTimeList,
    forecastCompareLeadTimeList,
    selectedForecastLeadTime,
    selectedFinalForercastLeadTime,
    IRIAvailableYearList,
    compareIRIAvailableYearList,
    forecastTimeYearHandler,
    compareForecastTimeYearHandler,
    IRIAvailableMonthList,
    compareIRIAvailableMonthList,
    IRIForecastTime,
    compareIRIForecastTime,
    // handleCompareLocation,
    IRIAvailableLeadTimeList,
    compareIRIAvailableLeadTimeList,
    applyLayersDisable,
    isGraphLoading,
    forecastTimeMonthHandler,
    compareForecastTimeMonthHandler,
    clearIRIDataSourceData,
    initialValues: {
      isHideBar,
      staticResourceSelectedLocation,
      fromStaticResources,
      dataSourceId,
      years,
      dates,
      isDefaultSelectedSourceId,
      region,
      mapPreferencesData,
      handleSelectedYear,
      selectedParam,
      compareParam,
    },
  } = props;
  const autoCompleteRef = useRef(null);
  const [defaultCountrySelected, setDefaultCountrySelected] = useState(true);
  const [defaultSelectedOption, setDefaultSelectedOption] = useState({});
  const [country, setCountry] = useState('');
  const [isClickedOnPlus, setIsClickedOnPlus] = useState(false);
  const [state, setState] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [location, setLocation] = useState('');
  const [isUpdateGraph, setIsUpdateGraph] = useState(true);
  const [compareData, setCompareData] = useState('Map');
  const [firstDataSourceSelected, setFirstDataSourceSelected] = useState({});

  const [isOpenWeather, setIsOpenWeather] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [isIri, setIsIri] = useState(false);
  const [compareDistrictList, setCompareDistrictList] = useState();
  const [initialSelectedDate, setSelectedDate] = useState({
    graph: {
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().add(6, 'days').format(DATE_FORMAT),
      minStartDate: '',
      maxStartDate: '',
      maxEndDate: '',
    },
    map: {
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().add(6, 'days').format(DATE_FORMAT),
      minStartDate: '',
      maxStartDate: '',
      maxEndDate: '',
    },
  });
  const [finalSelectedDate, setFinalSelectedDate] = useState({
    startDate: moment().format(DATE_FORMAT),
    endDate: moment().add(6, 'days').format(DATE_FORMAT),
    minStartDate: '',
    maxStartDate: '',
    maxEndDate: '',
  });
  const [updatedSelectedCompareDate, setUpdatedSelectedCompareDate] = useState({
    startDate: moment().format(DATE_FORMAT),
    endDate: moment().add(6, 'days').format(DATE_FORMAT),
    minStartDate: '',
    maxStartDate: '',
    maxEndDate: '',
  });
  // const [finalForecastYearMonth, setFinalForecastYearMonth] = useState({
  //   forecastYearMonth:moment().format("YYYY-MM"),
  // });
  const [selectedCountry, setSelectedCountry] = useState({
    name: DEFAULT_COUNTRY.name,
    label: DEFAULT_COUNTRY.name,
    value: DEFAULT_COUNTRY.id,
  });
  const [finalNoaaEndDate, setFinalNoaaEndDate] = useState('');
  const [isPreviousClick, setIsPreviousClick] = useState('');
  const [isManualClick,setIsManualClick] = useState(false)
  const [finalNoaaMaxEndDate, setFinalNoaaMaxEndDate] = useState('');
  const [noaaMaxEndDate, setNoaaMaxEndDate] = useState(false);

  const [noaaCompareMaxEndDate, setNoaaCompareMaxEndDate] = useState(false);
  const [finalNoaaCompareEndDate, setFinalNoaaCompareEndDate] = useState('');
  const [finalNoaaCompareMaxEndDate, setFinalNoaaCompareMaxEndDate] = useState('');

  const [compareCountry, setCompareCountry] = useState('');
  const [selectedOption, setSelectedOption] = useState({});
  const [compareSelectedOption, setCompareSelectedOption] = useState({});
  const [defaultCompareSelectedOption, setDefaultCompareSelectedOption] = useState({});
  const [hasSelectedParamChart, setHasSelectedParamChart] = useState(true);
  const [dataSources, setDataSources] = useState({
    firstDataSources: [],
    secondDataSources: [],
  });
  const [dataSourcesConst,setDataSourcesConst] = useState({
    firstDataSources:[],
    secondDataSources:[]
  })
  const [selectedDefaultDateRange, setSelectedDefaultDateRange] = useState({});
  const [compareState, setCompareState] = useState();
  const [temporalAggregation, setTemporalAggregation] = useState(TEMPORAL_AGGREGATION);
  const [isDefaultDataSource, setIsDefaultDataSource] = useState('');
  const [selectedDisctrict, setSelectedDistrict] = useState(null);
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState();
  const [districtList, setDistrictList] = useState();
  const [showMapPreferenceTooltip, setShowMapPreferenceTooltip] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [range, setRange] = useState({
    min: null,
    max: null,
  });
  const [openCollapse, setOpenCollapse] = useState({
    forecast_location: true,
    dataSources: true,
    forecast_datetime: true,
    monitoring_timeframe: false,
    monitoring_compare: false,
    forecast_compare: false,
    monitoring_indicators: false,
  });
  const [isCompare, setIsCompare] = useState([
    { name: 'map', value: 'Map', selected: true },
    { name: 'graph', value: 'Graph', selected: false },
  ]);
  const [selectedYearList, setSelectedYearList] = useState([]);
  const [selectedDefaultYear, setSelectedDefaultYear] = useState(null);
  const [compareStateList, setCompareStateList] = useState([]);
  const [selectedCompareDistrict, setSelectedCompareDistrict] = useState(null);
  const [selectedDataSource, setSelectedDataSource] = useState({
    firstDataSource: [],
    secondDataSource: [],
  });
  const [selectedLocation, setSelectedLocation] = useState({
    country: '',
    state: '',
    District: '',
  });
  const [compareLocation, setCompareLocation] = useState({
    country: '',
    state: '',
    district: '',
  });
  const { Location, Weather } = useSelector((state) => ({
    Location: state.Location,
    Weather: state.Weather,
  }));

  const [basin, setBasin] = useState('');

  const [isApplyLayer, setIsApplyLayer] = useState(true);

  const [updatedSelectedDate, setUpdatedSelectedDate] = useState({
    startDate: moment().format(DATE_FORMAT),
    endDate: moment().add(6, 'days').format(DATE_FORMAT),
    minStartDate: '',
    maxStartDate: '',
    maxEndDate: '',
  });
  const [distrcitSelectValue, setDistrcitSelectValue] = useState({});
  const [districtStateValue, setDistrictStateValue] = useState({});

  function updateDate(dataSource) {
    if (dataSource?.id === DATA_SOURCES?.OPEN_WEATHER?.id) {
      return DATA_SOURCES?.OPEN_WEATHER?.dateRange;
    } else if (dataSource?.id === DATA_SOURCES?.GFS_NOAA?.id) {
      return DATA_SOURCES.GFS_NOAA.dateRange;
    } else if (dataSource?.id === DATA_SOURCES?.ERPAS?.id) {
      return DATA_SOURCES.ERPAS.dateRange;
    } else {
      return {
        startDate: moment().format(DATE_FORMAT),
        endDate: moment().add(6, 'days').format(DATE_FORMAT),
        minStartDate: '',
        maxStartDate: '',
        maxEndDate: '',
      };
    }
  }
  useEffect(() => {
    if (selectedLocation) props.handleSelectedLocation(selectedLocation);
    // else if (compareLocation) {
    //   props.handleCompareLocation(compareLocation)
    // }
  }, [selectedLocation]);

  useEffect(() => {
    props.handleCompareLocation(compareCountry, compareState, selectedCompareDistrict);
  }, [compareCountry, compareState, selectedCompareDistrict]);

  //for getting country
  useEffect(() => {
    if (!fromStaticResources) {
      dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    } else {
      dispatch(getWeatherForecastSources({ country_id: selectedLocation?.country?.id }));
    }
  }, [fromStaticResources]);

  //global function to compare props
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (firstDataSourceSelected?.length) {
      let secondDataSourceList = dataSources?.firstDataSources?.filter(
        (i) => i.type === 'non-static' || i.value === 'IRI',
      );
      let filteredList = secondDataSourceList;
     
      if (compareData === 'Graph') {
        filteredList =
          firstDataSourceSelected[0].id === DATA_SOURCES.IRI.id
            ? secondDataSourceList?.filter((i) => i.id === DATA_SOURCES.IRI.id)
            : secondDataSourceList?.filter((i) => i.id !== DATA_SOURCES.IRI.id);
        if(firstDataSourceSelected[0]?.id === DATA_SOURCES.OPEN_WEATHER.id ){
          filteredList = filteredList?.filter((i)=> i.id !== DATA_SOURCES.OPEN_WEATHER.id);
        }            
        setSelectedDataSource((prev) => ({
          ...prev,
          secondDataSource: [],
        }));
        setDataSources((prev) => ({
          ...prev,
          secondDataSources: filteredList,
        }));
      } else if (compareData === 'Map') {
        let secondDataSourceFilteredList = dataSources?.firstDataSources?.filter(
          (i) => i.type === 'non-static' || i.value === 'IRI',
        );
        if(firstDataSourceSelected[0]?.id === DATA_SOURCES.OPEN_WEATHER.id ){
          secondDataSourceFilteredList = secondDataSourceFilteredList?.filter((i)=> i.id !== DATA_SOURCES.OPEN_WEATHER.id);
       }
        setDataSources((prev) => ({
          ...prev,
          secondDataSources: secondDataSourceFilteredList,
        }));
        //  setSelectedDataSource(prev => ({
        //    ...prev,
        //    secondDataSource: [],
        //  }));
        clearForecastData();
      }
    }
  }, [compareData, firstDataSourceSelected]);

  useEffect(() => {
    props.handleDefaultDataSource(isDefaultDataSource);
  }, [isDefaultDataSource]);

  useEffect(() => {
    if (!_.isEmpty(selectedDefaultYear)) {
      props.handleSelectedYear(selectedDefaultYear?.label);
    } else {
      props.handleSelectedYear(null);
    }
  }, [selectedDefaultYear]);

  useEffect(() => {
    if (!_.isEmpty(location)) {
      setIsPreviousClick(isClickedOnPlus);
      setIsClickedOnPlus(false);
    }
  }, [location]);
  // setSelectedDataSource((prev)=> console.log(prev))
  // useEffect(()=> {
  //   if (isHideBar) {
  //     // if (isClickedOnPlus===true) {

  //     // setIsClickedOnPlus(!isClickedOnPlus)

  //     // }
  //     setSelectedDataSource((prev)=> ({
  //       ...prev ,
  //       secondDataSource:prev.secondDataSource,

  //     }))
  //   }
  //     } , [isHideBar])
  useEffect(() => {
    setSelectedDateRange([]);
    setSelectedDefaultDateRange(null);
    if (dates && dates?.length) {
      let arr = [];
      if (selectedDataSource) {
        if (selectedDataSource?.firstDataSource[0]?.name) {
          dates?.map((item) => {
            arr.push({
              value: item.group_date
                ? selectedDataSource?.firstDataSource[0]?.name === 'ERPAS (Static)'
                  ? moment(item.group_date).format(DISPLAY_DATE_FORMAT)
                  : item.group_date
                : '',
              label: item.group_date
                ? selectedDataSource?.firstDataSource[0]?.name === 'ERPAS (Static)'
                  ? moment(item.group_date).format(DISPLAY_DATE_FORMAT)
                  : item.group_date
                : '',
            });
          });
        }
      }
      setSelectedDefaultDateRange(arr[0]);
      setSelectedDateRange(arr);
      props.handleSelectedDate(arr[0]?.label);
    }
  }, [dates, selectedDataSource.firstDataSource]);

  useEffect(() => {
    let defaultSelectedAggregation = TEMPORAL_AGGREGATION?.find((item) => item.name === 'Mean');
    if (!_.isEmpty(defaultSelectedAggregation)) {
      setTemporalAggregation(defaultSelectedAggregation);
    }
    if (autoCompleteRef && !_.isEmpty(autoCompleteRef?.current)) {
      autoCompleteRef.current.autocomplete = false;
    }
  }, []);

  useEffect(() => {
    props.handleIsOpenWeather(isOpenWeather);
  }, [isOpenWeather]);

  useEffect(() => {
    if (!isDefaultSelectedSourceId) {
      if (dataSources?.firstDataSources?.length) {
        const filterdefaultSource = dataSources?.firstDataSources?.filter(
          (data) => data.id === DATA_SOURCES.OPEN_WEATHER.id,
        );
        if (!selectedDataSource?.firstDataSource?.length && filterdefaultSource.length) {
          filterdefaultSource[0].parameterData.forEach((value) => {
            value.type = filterdefaultSource[0].type;
            value.dataSource = filterdefaultSource[0].name;
          });
          // props.handleParamData(filterdefaultSource[0]);
          props.handleParamData({ values: filterdefaultSource[0], fromCompare: false });
          setSelectedDataSource((prev) => ({
            ...prev,
            firstDataSource: filterdefaultSource,
          }));
          setFirstDataSourceSelected(filterdefaultSource);
          if (!isDefaultDataSource) {
            setIsDefaultDataSource(true);
          }
          let params = new URLSearchParams(window.location.search);
          let keyValue;
          keyValue = params.get('key');
          history.push(
            `/weather-forecast?key=${keyValue}&&dataSourceId=${filterdefaultSource[0].id}`,
          );
        }
      }
    }
  }, [dataSources.firstDataSources]);

  useEffect(() => {
    if (mapPreferencesData?.length === 2) {
      setShowMapPreferenceTooltip(true);
    } else setShowMapPreferenceTooltip(false);
  }, [mapPreferencesData]);

  //for getting weather forecast data sources
  useEffect(() => {
    if (country) {
      props.handleCountry(country);
    }
  }, [country]);
  const changeorder = (dynamicIndices) => {
    let indices = [];
    for (let i = 0; i < weatherForecastIndices.length; i++) {
      let a = weatherForecastIndices[i];
      for (let j = 0; j < dynamicIndices.length; j++) {
        if (a == dynamicIndices[j].name) {
          indices.push(dynamicIndices[j]);
        }
      }
    }
    return indices;
  };
  useEffect(() => {
    if (Weather?.weatherForecastSourcesData) {
      const { result } = Weather.weatherForecastSourcesData.data;
      let options = [];
      if (result) {
        result?.map((item) => {
          item.data.map((data_source) => {
            const {
              weather_forecast_source: {
                id,
                name,
                type,
                weather_forecast_source_with_parameter: parameter,
              },
            } = data_source;
            if (name !== 'ERPAS'){
              return options.push({
                id,
                name,
                value: name,
                label: name,
                type,
                parameterData: parameter,
                category: item.category,
              });
            }
          });
        });

        let ordered_list = options.sort(function (a, b) {
          var textA = a.value.toUpperCase();
          var textB = b.value.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        let secondDataSourceList = ordered_list?.filter((i) => i.type === 'non-static');
        ordered_list.map((i) => {
          if (i?.id === 'e2f886a7-3299-4c82-af2e-123fe92faf84') {
            secondDataSourceList.push(i);
          }
        });
        let optionsCopy = options;
        options = changeorder(optionsCopy);
   
        let second_ordered_list = secondDataSourceList.sort(function (a, b) {
          var textA = a.value.toUpperCase();
          var textB = b.value.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        setDataSources((prev) => ({
          ...prev,
          // firstDataSources: ordered_list,
          firstDataSources: options,
          secondDataSources: second_ordered_list,
        }));
        setDataSourcesConst((prev)=>({
          ...prev,
          // firstDataSources: ordered_list,
          firstDataSources: options,
          secondDataSources: second_ordered_list,
        }))
      } else {
        setDataSources((prev) => ({
          ...prev,
          firstDataSources: [],
          secondDataSources: [],
        }));
        setDataSourcesConst((prev)=>({
          ...prev,
          // firstDataSources: ordered_list,
          firstDataSources: [],
          secondDataSources: [],
        }))
      }
    }
  }, [Weather.weatherForecastSourcesData]);

  useEffect(() => {
    if (dataSources?.firstDataSources?.length) {
      let selectedDataSourceParamList = dataSources?.firstDataSources?.filter(
        (i) => i.id === dataSourceId,
      );
      if (selectedDataSourceParamList[0]) {
        setSelectedDataSource((prev) => ({
          ...prev,
          firstDataSource: selectedDataSourceParamList,
        }));
        setFirstDataSourceSelected(selectedDataSourceParamList);
        if (selectedDataSourceParamList[0].id !== DATA_SOURCES.OPEN_WEATHER.id) {
          if (isDefaultDataSource) setIsDefaultDataSource(false);
        }
        // props.handleParamData(selectedDataSourceParamList[0]);
        props.handleParamData({ values: selectedDataSourceParamList[0], fromCompare: false });
      }
    }
  }, [dataSources.firstDataSources, dataSourceId, isDefaultSelectedSourceId]);

  useEffect(() => {
    if (Location.countryList) {
      const { result } = Location.countryList;
      if (result) {
        let defaultCountry = result?.find((data) => data.id === DEFAULT_COUNTRY.id);
        if (defaultCountry) {
          defaultCountry.label = defaultCountry.name;
          defaultCountry.value = defaultCountry.name;
          setSelectedOption(defaultCountry);
          setDefaultSelectedOption(defaultCountry);
          props.handleCompareSearch({ country: defaultCountry.value });

          setCompareSelectedOption(defaultCountry);
          setDefaultCompareSelectedOption(defaultCountry);

          setCountry(defaultCountry.name);
          setCompareCountry(defaultCountry.name);
          setSelectedLocation((prev) => {
            return {
              ...prev,
              country: defaultCountry,
            };
          });
          setCompareLocation((prev) => {
            return {
              ...prev,
              country: defaultCountry,
            };
          });
        }
      }
      if (result?.[0]) {
        let arr = [];
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            region_id: i.region_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
        setCountryList(arr);
      }
    }
  }, [Location.countryList]);

  useEffect(() => {
    if (years) {
      setSelectedYearList(years);
      setSelectedDefaultYear(years[0]);
    }
  }, [years]);

  useEffect(() => {
    if (selectedLocation.country?.id && selectedLocation.country?.id !== 'select') {
      dispatch(
        getStateByCountryRequest({
          requestObj: {
            location_type: LOCATION.State,
            parent_id: selectedLocation?.country?.id,
          },
          isCompareLocation: false,
        }),
      );
      dispatch(getWeatherForecastSources({ country_id: selectedLocation.country?.id }));
      //dispatch(getWeatherForecastSources({ country_id: selectedLocation.country }));
      dispatch(
        getMapPreferenceRequest({
          country_id: selectedLocation.country?.id,
          module_name: 'weather',
        }),
      );
    }
  }, [selectedLocation.country]);

  useEffect(() => {
    if (selectedLocation.state && selectedLocation?.state?.id !== DEFAULT_COUNTRY.id) {
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: selectedLocation.state?.id,
          },
          isCompareLocation: false,
        }),
      );
    }
  }, [selectedLocation.state]);

  useEffect(() => {
    let arr = [];
    if (Location.stateList) {
      let result = '';
      let config = Location?.stateList?.config;
      if (Location?.stateList?.data) result = Location?.stateList?.data?.result;
      if (result) {
        // countryList?.map((item) => {
        //   if(item.id === DEFAULT_COUNTRY.id){
        //     arr.push({
        //     id: item.id,
        //     name: item.name,
        //     value: item.name,
        //     label: item.name,
        //     country_id: item.country_id,
        //     latitude: item.latitude,
        //     longitude: item.longitude,
        //     zoom_level: item.zoom_level,
        // })
        //   }
        // })
        arr.push({
          id: DEFAULT_COUNTRY.name,
          name: '',
          value: DEFAULT_COUNTRY.name,
          label: 'Select Province',
        });
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            country_id: i.country_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      setState({
        id: DEFAULT_COUNTRY.name,
        value: DEFAULT_COUNTRY.name,
        label: 'Select Province',
      });
      setCompareState({
        id: DEFAULT_COUNTRY.name,
        value: DEFAULT_COUNTRY.name,
        label: 'Select Province',
      });
      if (!config?.isCompareLocation) {
        setStateList(arr);
      } else {
        setCompareStateList(arr);
      }
    }
  }, [Location.stateList]);

  useEffect(() => {
    if (compareLocation.country?.id) {
      dispatch(
        getStateByCountryRequest({
          requestObj: {
            location_type: LOCATION.State,
            parent_id: compareLocation.country?.id,
          },
          isCompareLocation: true,
        }),
      );
    }
  }, [compareLocation.country]);

  useEffect(() => {
    if (compareLocation.state?.id && compareLocation.state?.id !== DEFAULT_COUNTRY.id) {
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: compareLocation.state?.id,
          },
          isCompareLocation: true,
        }),
      );
    }
  }, [compareLocation.state]);

  useEffect(() => {
    let arr = [];
    if (Location.districtList) {
      let result = '';
      let config = Location?.districtList?.config;
      if (Location?.districtList?.data) result = Location?.districtList?.data?.result;
      if (result) {
        arr.push({
          id: 'Select',
          name: 'Select',
          value: 'Select',
          label: 'Select',
        });
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        setDistrictList(arr);
      } else {
        setCompareDistrictList(arr);
      }
    }
  }, [Location.districtList]);

  useEffect(() => {
    if (Object.keys(selectedOption).length) {
      const { latitude, longitude, zoom_level } = selectedOption;
      return props.handleCordinates(
        [
          latitude ? latitude : SRILANKA_COORDINATE.lat,
          longitude ? longitude : SRILANKA_COORDINATE.long,
        ],
        zoom_level ? zoom_level : 4.4,
      );
    } else {
      return props.handleCordinates([SRILANKA_COORDINATE.lat, SRILANKA_COORDINATE.long], 4.4);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedDataSource?.firstDataSource?.length) {
      let dataSource = selectedDataSource?.firstDataSource[0];
      let date = updateDate(dataSource);
      setSelectedDate((prev) => {
        return {
          ...prev,
          map: date,
          graph: date,
        };
      });
      setUpdatedSelectedDate(date);
    }
  }, [selectedDataSource?.firstDataSource]);

  useEffect(() => {
    if (selectedDataSource?.secondDataSource?.length) {
      let dataSource = selectedDataSource?.secondDataSource[0];
      let date = updateDate(dataSource);
      setFinalSelectedDate({ ...date });
      setUpdatedSelectedCompareDate(date);
    }
  }, [selectedDataSource?.secondDataSource]);

  useEffect(() => {
    if (_.isEmpty(country)) {
      props.handleSelectedCountry(selectedCountry?.value);
    } else {
      props.handleSelectedCountry('');
    }
  }, [location, selectedCountry, country]);

  useEffect(() => {
    if (selectedDataSource?.firstDataSource?.length) {
      const selectedItem = selectedDataSource?.firstDataSource?.some(
        (i) => i.id === DATA_SOURCES.OPEN_WEATHER.id,
      );
      const selectedItem1 = selectedDataSource?.firstDataSource?.some(
        (i) => i.id === DATA_SOURCES.IRI.id,
      );
      setIsOpenWeather(selectedItem);
      setIsIri(selectedItem1);
      props.handleProps({
        dataSources: selectedDataSource?.firstDataSource,
        initialSelectedDate,
        temporalAggregation,
        range,
      });
      if (isClickedOnPlus) {
        props.handleProps({
          compareDataSource: selectedDataSource?.secondDataSource,
          initialSelectedDate,
          finalSelectedDate: finalSelectedDate,
          selectedFinalForecastYearMonth: selectedFinalForecastYearMonth,

          temporalAggregation,
          range,
        });
      }
    }
  }, [
    initialSelectedDate,
    selectedDataSource.firstDataSource,
    selectedDataSource.secondDataSource,
    isClickedOnPlus,
    finalSelectedDate,
    selectedFinalForecastYearMonth,
    temporalAggregation,
    range,
  ]);

  useEffect(() => {
    if (!isClickedOnPlus) {
      let compareData = JSON.parse(JSON.stringify(isCompare));
      const nextCompare = compareData.map((item) => {
        if (item.name === 'map') {
          return {
            ...item,
            selected: true,
          };
        } else {
          return {
            ...item,
            selected: false,
          };
        }
      });
      setIsCompare(nextCompare);
      setCompareData('Map');
      const { secondDataSource } = selectedDataSource;
      if (secondDataSource?.length) {
        setSelectedDataSource((prev) => ({
          ...prev,
          secondDataSource: [],
        }));
        setFinalSelectedDate({
          startDate: moment().format(DATE_FORMAT),
          endDate: moment().add(6, 'days').format(DATE_FORMAT),
          minStartDate: '',
          maxStartDate: '',
          maxEndDate: '',
        });
        props.handleProps({
          compareDataSource: [],
          initialSelectedDate,
          finalSelectedDate: '',
        });
        props.handleCompareParamsData('', selectedDataSource?.firstDataSource[0]);
        clearForecastData();
      }
      if (compareStateList?.length && isPreviousClick) {
        let defaultCountry = countryList?.find((i) => i.id === DEFAULT_COUNTRY.id);
        if (defaultCountry) {
          setCompareSelectedOption(defaultCountry);
        }
        setCompareDistrictList([]);
        setSelectedCompareDistrict(null);
        let defaultState = compareStateList?.find((i) => i.id === DEFAULT_COUNTRY.id);
        setCompareState({
          id: DEFAULT_COUNTRY.id,
          value: DEFAULT_COUNTRY.name,
          label: 'Select Province',
        });
        props.handleCompareSearch({ state: defaultState?.value, district: null });
        setCompareLocation((prev) => {
          return {
            ...prev,
            state: '',
            district: '',
          };
        });
      }
    }
    props.handleIsCompare(isClickedOnPlus);
  }, [isClickedOnPlus]);

  useEffect(() => {
    if (selectedLocation) props.handleSelectedLocation(selectedLocation);
  }, [selectedLocation]);

  useEffect(() => {
    props.handleLocation1(country, state, selectedDisctrict, basin, region);
  }, [country, state, basin, region, selectedDisctrict]);

  useEffect(() => {
    if (applyLayersDisable === true) {
      setIsApplyLayer(true);
    }
  }, [applyLayersDisable]);

  useEffect(() => {
    let isMap = '';
    let isGraph = '';
    let mapPreferencesCount = mapPreferencesData?.length;
    let hasSelectedParamChart = true;
    if (!_.isEmpty(selectedParam) && isOpenWeather) {
      hasSelectedParamChart = OPEN_WEATHER_CHART_PARAMS.find(
        (x) => x.name === selectedParam?.weather_forecast_parameter?.name,
      )
        ? true
        : false;
    }
    setHasSelectedParamChart(hasSelectedParamChart);
    if (isClickedOnPlus) {
      isMap = isCompare.some((item) => item.name === 'map' && item.selected);
      isGraph = isCompare.some((item) => item.name === 'graph' && item.selected);
      if (
        (!_.isEmpty(temporalAggregation) && temporalAggregation?.value === 'median') ||
        isMap ||
        !selectedDataSource.secondDataSource?.length ||
        _.isEmpty(initialSelectedDate.graph.startDate) ||
        _.isEmpty(initialSelectedDate.graph.endDate) ||
        _.isEmpty(selectedParam) ||
        invalidDate
      ) {
        if (selectedParam?.weather_forecast_source_id === DATA_SOURCES.IRI.id) {
          if (
            _.isEmpty(IRIForecastTime?.year) ||
            _.isEmpty(IRIForecastTime?.month) ||
            _.isEmpty(IRIForecastTime?.lead_time) ||
            invalidDate
          ) {
            if (mapPreferencesCount === 2) setIsApplyLayer(false);
            setIsUpdateGraph(false);
          } else {
            setIsUpdateGraph(false);
            setIsApplyLayer(true);
          }
        } else if (mapPreferencesCount < 2 && !invalidDate) {
          setIsUpdateGraph(false);
          setIsApplyLayer(true);
        } else {
          setIsUpdateGraph(false);
          setIsApplyLayer(false);
        }
      } else {
        if (selectedDataSource?.secondDataSource[0]?.id === DATA_SOURCES.IRI.id) {
          if (
            _.isEmpty(IRIForecastTime?.year) ||
            _.isEmpty(IRIForecastTime?.month) ||
            _.isEmpty(IRIForecastTime?.lead_time) ||
            invalidDate ||
            !hasSelectedParamChart
          ) {
            setIsUpdateGraph(false);
          } else if (
            !invalidDate &&
            hasSelectedParamChart &&
            isGraph &&
            selectedDataSource?.secondDataSource?.length
          ) {
            setIsUpdateGraph(true);
          }
        } else if (
          !invalidDate &&
          hasSelectedParamChart & isGraph &&
          selectedDataSource?.secondDataSource?.length
        ) {
          setIsUpdateGraph(true);
        }
      }
      if (
        isGraph ||
        !selectedDataSource?.secondDataSource?.length ||
        _.isEmpty(initialSelectedDate.map.startDate) ||
        _.isEmpty(initialSelectedDate.map.endDate) ||
        _.isEmpty(selectedParam) ||
        mapPreferencesCount === 2 ||
        invalidDate
      ) {
        setIsApplyLayer(false);
        if (!hasSelectedParamChart) setIsUpdateGraph(false);
      } else {
        if (selectedDataSource?.secondDataSource[0]?.id === DATA_SOURCES.IRI.id) {
          if (
            _.isEmpty(compareIRIForecastTime?.year) ||
            _.isEmpty(compareIRIForecastTime?.month) ||
            _.isEmpty(compareIRIForecastTime?.lead_time) ||
            mapPreferencesCount === 2 ||
            invalidDate
          ) {
            setIsApplyLayer(false);
          } else {
            if (selectedParam?.weather_forecast_source_id === DATA_SOURCES.IRI.id) {
              if (
                _.isEmpty(IRIForecastTime?.year) ||
                _.isEmpty(IRIForecastTime?.month) ||
                _.isEmpty(IRIForecastTime?.lead_time) ||
                mapPreferencesCount === 2 ||
                invalidDate
              ) {
                setIsApplyLayer(false);
              } else {
                setIsApplyLayer(true);
              }
            } else if (mapPreferencesCount < 2 && !invalidDate) {
              setIsApplyLayer(true);
            } else {
              setIsApplyLayer(false);
            }
          }
        } else {
          if (selectedDataSource?.firstDataSource[0]?.id === DATA_SOURCES.IRI.id) {
            if (
              _.isEmpty(IRIForecastTime?.year) ||
              _.isEmpty(IRIForecastTime?.month) ||
              _.isEmpty(IRIForecastTime?.lead_time) ||
              mapPreferencesCount === 2 ||
              invalidDate
            ) {
              setIsApplyLayer(false);
            } else {
              setIsApplyLayer(true);
            }
          } else if (mapPreferencesCount < 2 && !invalidDate) {
            if (!hasSelectedParamChart) setIsUpdateGraph(false);
            setIsApplyLayer(true);
          } else {
            if (!hasSelectedParamChart) setIsUpdateGraph(false);
            setIsApplyLayer(false);
          }
        }
      }
    } else {
      if (region?.length) setShowToolTip(true);
      else setShowToolTip(false);
      if (
        _.isEmpty(selectedParam) ||
        applyLayersDisable === true ||
        mapPreferencesCount === 2 ||
        invalidDate
      ) {
        setIsApplyLayer(false);
      } else {
        if (selectedParam?.weather_forecast_source_id === DATA_SOURCES.IRI.id) {
          if (
            _.isEmpty(IRIForecastTime?.year) ||
            _.isEmpty(IRIForecastTime?.month) ||
            _.isEmpty(IRIForecastTime?.lead_time) ||
            mapPreferencesCount === 2 ||
            invalidDate
          ) {
            setIsApplyLayer(false);
          } else {
            setIsApplyLayer(true);
          }
        } else if (mapPreferencesCount < 2 && !invalidDate) {
          setIsApplyLayer(true);
        } else {
          setIsApplyLayer(false);
        }
      }
      let isUpdate;
      if (
        (temporalAggregation?.value === 'median' &&
          !isOpenWeather &&
          (_.isEmpty(selectedParam) ||
            selectedParam?.weather_forecast_source_id !== DATA_SOURCES.IRI.id)) ||
        invalidDate ||
        !hasSelectedParamChart
      ) {
        isUpdate = true;
      } else {
        if (selectedParam?.weather_forecast_source_id === DATA_SOURCES.IRI.id) {
          if (
            _.isEmpty(IRIForecastTime?.year) ||
            _.isEmpty(IRIForecastTime?.month) ||
            _.isEmpty(IRIForecastTime?.lead_time) ||
            invalidDate ||
            !hasSelectedParamChart
          ) {
            isUpdate = true;
          } else {
            isUpdate = false;
          }
        } else if (!invalidDate && hasSelectedParamChart) {
          isUpdate = false;
        }
      }
      setIsUpdateGraph(!isUpdate);
    }
  }, [
    isCompare,
    isClickedOnPlus,
    temporalAggregation,
    selectedDataSource.secondDataSource,
    mapPreferencesData,
    region,
    IRIForecastTime,
    compareIRIForecastTime,
    initialSelectedDate,
    finalSelectedDate,
    selectedParam,
    invalidDate,
  ]);

  useEffect(() => {
    if (region?.length) {
      setIsPreviousClick(isClickedOnPlus);
      if (isClickedOnPlus) setIsClickedOnPlus(false);
    } else if (region !== null && isManualClick) {
      setIsPreviousClick(isClickedOnPlus);
      setIsClickedOnPlus(true);
    }
  }, [region]);

  useEffect(() => {
    if (openCollapse?.monitoring_compare) {
      if (isClickedOnPlus !== openCollapse?.monitoring_compare) setIsPreviousClick(isClickedOnPlus);
      setIsClickedOnPlus(openCollapse?.monitoring_compare);
    }
  }, [openCollapse?.monitoring_compare]);

  useEffect(() => {
    if (Object.keys(compareSelectedOption).length) {
      const { latitude, longitude, zoom_level } = compareSelectedOption;
      return props.handleCompareCordinates(
        [
          latitude ? latitude : DEFAULT_COUNTRY.latitude,
          longitude ? longitude : DEFAULT_COUNTRY.longitude,
        ],
        zoom_level ? zoom_level : 4.4,
      );
    } else {
      return props.handleCompareCordinates(
        [DEFAULT_COUNTRY.latitude, DEFAULT_COUNTRY.longitude],
        4.4,
      );
    }
  }, [compareSelectedOption]);

  const setCompareToggle = () => {
    if (!region?.length && _.isEmpty(location)) {
      setIsPreviousClick(isClickedOnPlus);
      setIsManualClick(!isClickedOnPlus)
      setIsClickedOnPlus(!isClickedOnPlus);
    }
  };

  const handleCompareDataSourceChange = (selectedOptions) => {
    // setNoaaCompareMaxEndDate(false);

    const { category, id, label, name, parameterData, type, value } = Array.isArray(selectedOptions)
      ? selectedOptions[0]
      : selectedOptions;
    let selectedDataArray = [];
    const selectedObj = {
      category,
      id,
      label,
      name,
      parameterData,
      type,
      value,
    };
    selectedDataArray.push(selectedObj);
    setSelectedDataSource((prev) => ({
      ...prev,
      secondDataSource: selectedDataArray,
    }));
    if(selectedObj?.id === DATA_SOURCES.OPEN_WEATHER.id){
      let updatedFirstDataSource = dataSources?.firstDataSources?.filter(item=> item.id !== selectedObj?.id);
      if(updatedFirstDataSource?.length) 
      setDataSources({
        ...dataSources,
        firstDataSources: updatedFirstDataSource
      })
    }
    else {
      setDataSources({
        ...dataSources,
        firstDataSources: dataSourcesConst.firstDataSources
      })
    }
    props.handleProps({
      compareDataSource: selectedDataArray,
      finalSelectedDate,
    });
    props.handleCompareParamsData(selectedDataArray[0], selectedDataSource?.firstDataSource[0]);
  };

  const displayChart = () => {
    props.handleTimeSeries();
    props.showCharts(true);
  };

  const handleDateSelectionChange = (selectedItem) => {
    setSelectedDefaultDateRange(selectedItem);
    props.handleSelectedDate(selectedItem.label);
  };

  const handleYearSelectionChange = (selectedItem) => {
    setSelectedDefaultYear(selectedItem);
  };

  const handleOnChange = (selectedOptions) => {
    setNoaaMaxEndDate(false);

    clearIRIDataSourceData();
    clearForecastData();
    const { category, id, label, name, parameterData, type, value } = Array.isArray(selectedOptions)
      ? selectedOptions[0]
      : selectedOptions;
    let selectedDataArray = [];
    const selectedObj = {
      category,
      id,
      label,
      name,
      parameterData,
      type,
      value,
    };
    selectedDataArray.push(selectedObj);
    if (id) {
      let params = new URLSearchParams(window.location.search);
      let keyValue;
      keyValue = params.get('key');
      history.push(`/weather-forecast?key=${keyValue}&&dataSourceId=${id}`);
    }

    if (isDefaultDataSource) {
      setIsDefaultDataSource(false);
    }
    let isNonStaticDataSource = selectedObj.type === 'non-static';
    if (isNonStaticDataSource) {
      let secondDataSourceList = dataSources?.firstDataSources?.filter(
        (i) => i.type === 'non-static',
      );
      dataSources?.firstDataSources.map((i) => {
        if (i?.id === 'e2f886a7-3299-4c82-af2e-123fe92faf84') {
          secondDataSourceList.push(i);
        }
      });
      let ordered_list = secondDataSourceList.sort(function (a, b) {
        var textA = a.value.toUpperCase();
        var textB = b.value.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      setDataSources((prev) => ({
        ...prev,
        secondDataSources: ordered_list,
      }));
    } else {
      let isNonStaticDataSource = selectedObj.type === 'non-static';
      if (isNonStaticDataSource) {
        let secondDataSourceList = dataSources?.firstDataSources?.filter(
          (i) => i.type === 'non-static',
        );
        dataSources?.firstDataSources.map((i) => {
          if (i?.id === 'e2f886a7-3299-4c82-af2e-123fe92faf84') {
            secondDataSourceList.push(i);
          }
        });
        let ordered_list = secondDataSourceList.sort(function (a, b) {
          var textA = a.value.toUpperCase();
          var textB = b.value.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        setDataSources((prev) => ({
          ...prev,
          secondDataSources: ordered_list,
        }));
      }
    }
    setSelectedDataSource((prev) => ({
      ...prev,
      firstDataSource: selectedDataArray,
    }));
    setFirstDataSourceSelected(selectedDataArray);
    // props.handleParamData(selectedDataArray[0]);
    props.handleParamData({ values: selectedDataArray[0], fromCompare: false });
    props.handleProps({
      dataSources: selectedDataArray,
      initialSelectedDate,
    });
    setSelectedDefaultYear(null);
    clearDatesHandler();
  };

  const handleCompareToggle = ({ target }) => {
    let compareData = isCompare;
    const nextCompare = compareData.map((item) => {
      if (item.value === target.value) {
        return {
          ...item,
          selected: target.checked,
        };
      } else {
        return {
          ...item,
          selected: false,
        };
      }
    });
    setIsCompare(nextCompare);
    setCompareData(target.value);
  };

  // const handleOptions = (options, newValue, action) => {
  //   let selectedOption;
  //   if (options && options.length) {
  //     if (action === 'country') {
  //       selectedOption = options.find((item) => item.name === newValue);
  //     } else {
  //       selectedOption = options.find((item) => item.name === newValue?.value);
  //     }
  //     if (selectedOption) {
  //       // if (action === 'country') {
  //       //   setDefaultSelectedOption(selectedOption);
  //       // }
  //       // if (action.name === 'country') {
  //       //   setDefaultSelectedOption(selectedOption);
  //       // }
  //       setSelectedOption(selectedOption);
  //     } else {
  //       // if (action.name === 'country') {
  //       //   setDefaultSelectedOption('');
  //       // }
  //       setSelectedOption('');
  //     }
  //     if (action === 'country') {
  //       setSelectedLocation((prev) => {
  //         return {
  //           ...prev,
  //           [action]: selectedOption || '',
  //         };
  //       });
  //     } else {
  //       setSelectedLocation((prev) => {
  //         return {
  //           ...prev,
  //           [action.name]: selectedOption || '',
  //         };
  //       });
  //     }
  //   }
  // };

  const handleOptions = (options, newValue, action) => {
    if (options && options.length) {
      let selectedOption = options.find((item) => item.name === newValue?.value);
      if (selectedOption) {
        if (selectedOption?.name !== 'Select') {
          setSelectedOption(selectedOption);
        } else {
          setSelectedOption(distrcitSelectValue);
        }
        if (action.name === 'state') {
          setDistrictStateValue(newValue);
          setDistrcitSelectValue(selectedOption);
        }
      } else {
        setSelectedOption('');
      }
      if (selectedOption?.name !== 'Select') {
        setSelectedLocation((prev) => {
          return { ...prev, [action.name]: newValue || '' };
        });
      } else {
        setState(districtStateValue);
      }
    }
  };

  useEffect(() => {
    if (isHideBar) {
      setIsPreviousClick(isClickedOnPlus);
      setIsClickedOnPlus(isClickedOnPlus);
    }
  }, [isHideBar]);

  const compareHandler = () => {
    setIsPreviousClick(isClickedOnPlus);
    setIsManualClick(!isClickedOnPlus)
    setIsClickedOnPlus(!isClickedOnPlus);
  };

  const handleInitialDate = (type) => {
    return moment(initialSelectedDate?.map[type]).format(DATE_FORMAT);
    // if (isCompare?.length && isCompare.some(item => item.name === 'graph' && item.selected)) {
    //   return initialSelectedDate?.graph[type];
    // } else {
    //   return initialSelectedDate?.map[type];
    // }
  };

  const handleFinalDate = (type) => {
    return moment(finalSelectedDate?.[type]).format(DATE_FORMAT);
  };

  const handleInitialForecastYearMonth = (type) => {
    return moment(selectedForecastYearMonth?.map[type]).format('YYYY-MM');
  };

  const handleFinalForecastYearMonth = (type) => {
    return moment(selectedForecastCompareYearMonth?.map[type]).format('YYYY-MM');
  };

  const handleSelectedItem = (newValue, action) => {
    if (action.name === 'country') {
      props.handleCountry(newValue.value);
      setCountry(newValue.value);
      handleOptions(countryList, newValue, action);
      setState(null);
      setSelectedDistrict(null);
      if (newValue.value === '') setStateList([]);
    } else if (action.name === 'state' && newValue.value !== 'Sri Lanka') {
      setSelectedLocation((prev) => {
        return {
          ...prev,
          state: newValue.value,
          district: '',
        };
      });
      setState(newValue);
      setSelectedDistrict(null);
      props.handleAdvancedSearch({ state: newValue.value });
      handleOptions(stateList, newValue, action);
      if (newValue.value === '') setDistrictList([]);
    } else if (newValue.value === 'Sri Lanka' && action.name === 'state') {
      props.handleAdvancedSearch({ state: '' });
      setState(newValue);
      setSelectedOption({
        label: 'Sri Lanka',
        latitude: SRILANKA_COORDINATE.lat,
        longitude: SRILANKA_COORDINATE.long,
        name: 'Sri Lanka',
        value: 'Sri Lanka',
        zoom_level: 8,
      });
      setSelectedLocation((prev) => {
        return {
          ...prev,
          state: '',
          district: '',
        };
      });
      setSelectedDistrict(null);
      setDistrictList([]);
    }
    // if (action.name === 'country') {
    //   props.handleCountry(newValue.value);
    //   setCountry(newValue.value);
    //   handleOptions(countryList, newValue, action);
    //   setState(null);
    //   if (newValue.value === '') setStateList([]);
    // } else if (action.name === 'state') {
    //   if (newValue.value === DEFAULT_COUNTRY.name) {
    //     setSelectedLocation((...prev) => ({ ...prev, country: DEFAULT_COUNTRY, state: {} }));
    //     handleOptions(countryList, country, 'country');
    //     props.handleAdvancedSearch({ state: newValue.value });
    //     setDistrictList([]);
    //   } else {
    //     props.handleAdvancedSearch({ state: newValue.value });
    //     handleOptions(stateList, newValue, action);
    //   }
    //   setState(newValue);
    //   setSelectedDistrict(null);
    //   if (newValue.value === '') setDistrictList([]);
    // }
    else if (action.name === 'district' && newValue.value !== 'Select') {
      props.handleAdvancedSearch({ district: newValue.value });
      handleOptions(districtList, newValue, action);
      setSelectedDistrict(newValue);
    } else if (action.name === 'district' && newValue.value === 'Select') {
      props.handleAdvancedSearch({ district: '' });
      setSelectedLocation((prev) => {
        return {
          ...prev,
          district: '',
        };
      });
      handleOptions(districtList, newValue, action);
      setSelectedDistrict(newValue);
    }
    //  else if (action.name === 'district') {
    //   props.handleAdvancedSearch({ district: newValue.value });
    //   handleOptions(districtList, newValue, action);
    //   setSelectedDistrict(newValue);
    // }
    // handleCompareSelectedItem(newValue, action);
  };
  const handleCompareSelectedItem = (newValue, action) => {
    if (action?.name === 'country') {
      setCompareCountry(newValue.value);
      handleCompareOptions(countryList, newValue, action);
      setCompareState(null);
      if (newValue.value === '') setCompareStateList([]);
    } else if (action?.name === 'state' && newValue?.value !== 'Sri Lanka') {
      setCompareState(newValue);
      setSelectedCompareDistrict(null);
      props.handleCompareSearch({ state: newValue.value });
      if (newValue?.value === '') setCompareDistrictList([]);
      handleCompareOptions(compareStateList, newValue, action);
    } else if (newValue?.value === 'Sri Lanka' && action?.name === 'state') {
      handleCompareOptions(countryList, compareCountry, 'country');
      // props.stateValue(newValue);
      props.handleCompareSearch({ state: '' });
      setCompareState(newValue);
      setSelectedCompareDistrict(null);
      setCompareDistrictList([]);
    } else if (action.name === 'district' && newValue.value !== 'Select') {
      props.handleCompareSearch({ district: newValue.value });
      handleCompareOptions(compareDistrictList, newValue, action);
      setSelectedCompareDistrict(newValue);
    } else if (action?.name === 'district' && newValue?.value === 'Select') {
      //   handleCompareOptions(compareDistrictList, newValue, action);
      props.handleCompareSearch({ district: '' });
      // setCompareLocation((prev) => {
      //   return {
      //     ...prev,
      //     district: '',
      //   };
      // });
      setSelectedCompareDistrict(newValue);
    }
    // if (action.name === 'country') {
    //   props.handleCompareSearch({ country: newValue.value });
    //   setCompareCountry(newValue.value);
    //   handleCompareOptions(countryList, newValue, action);
    //   setCompareState(null);
    //   if (newValue.value === '') setCompareStateList([]);
    // } else if (action.name === 'state') {
    //   if (newValue.value === DEFAULT_COUNTRY.name) {
    //     props.handleCompareSearch({ state: newValue.value });
    //     handleCompareOptions(countryList, compareCountry, 'country');
    //     setCompareDistrictList([]);
    //     // props.stateValue(newValue);
    //   } else {
    //     props.handleCompareSearch({ state: newValue.value });
    //     handleCompareOptions(compareStateList, newValue, action);
    //   }
    //   setCompareState(newValue);
    //   setSelectedCompareDistrict(null);
    //   if (newValue.value === '') setCompareDistrictList([]);
    // } else if (action.name === 'district') {
    //   props.handleCompareSearch({ district: newValue.value });
    //   handleCompareOptions(compareDistrictList, newValue, action);
    //   setSelectedCompareDistrict(newValue);
    // }
  };

  const handleCompareOptions = (options, newValue, action) => {
    let selectedOption;
    if (options && options.length) {
      if (action === 'country') {
        selectedOption = options.find((item) => item.name === newValue);
        if (selectedOption) {
          if (action === 'country') {
            setDefaultCompareSelectedOption(selectedOption);
          }
          setCompareSelectedOption(selectedOption);
        }
        setCompareLocation((prev) => {
          return {
            ...prev,
            [action]: selectedOption,
          };
        });
      } else {
        if (newValue.value) {
          selectedOption = options.find((item) => item.name === newValue.value);
          if (selectedOption) {
            if (action.name === 'country') {
              setDefaultCompareSelectedOption(selectedOption);
            }
            setCompareSelectedOption(selectedOption);
          }
          setCompareLocation((prev) => {
            return {
              ...prev,
              [action.name]: selectedOption,
            };
          });
        } else {
          if (action.name === 'country') {
            setDefaultCompareSelectedOption('');
          }
          setCompareSelectedOption('');
          setCompareLocation((prev) => {
            return {
              ...prev,
              [action.name]: '',
            };
          });
        }
      }
    }
  };
  // const handleCompareOptions = (options, newValue, action) => {
  //   if (options && options.length) {
  //     if (newValue.value) {
  //       let selectedOption = options.find((item) => item.name === newValue.value);
  //       if (selectedOption) {
  //         if (action.name === 'country') {
  //           setDefaultCompareSelectedOption(selectedOption);
  //           setCompareLocation((prev) => {
  //             return {
  //               [action.name]: selectedOption,
  //             };
  //           });
  //         } else {
  //           setCompareLocation((prev) => {
  //             return {
  //               ...prev,
  //               [action.name]: selectedOption,
  //             };
  //           });
  //         }
  //         setCompareSelectedOption(selectedOption);
  //       }
  //     } else {
  //       setDefaultCompareSelectedOption('');
  //       setCompareLocation((prev) => {
  //         return {
  //           [action.name]: '',
  //         };
  //       });
  //       setCompareSelectedOption('');
  //     }
  //   }
  // };
  const finalDateHandler = (e, value) => {
    if (value === 'start_date') {
      if (selectedDataSource?.secondDataSource[0]?.label === 'GFS - NOAA') {
        setNoaaCompareMaxEndDate(true);
      }
      setFinalSelectedDate((prevdate) => {
        return {
          ...prevdate,
          startDate: e.target.value,
        };
      });
      setUpdatedSelectedCompareDate((prevdate) => {
        return {
          ...prevdate,
          startDate: e.target.value,
        };
      });
    } else if (value === 'end_date') {
      setFinalNoaaCompareEndDate(e.target.value);

      setFinalSelectedDate((prevdate) => {
        return {
          ...prevdate,
          endDate: e.target.value,
        };
      });
      if (selectedDataSource?.secondDataSource[0]?.label !== 'GFS - NOAA') {
        setUpdatedSelectedCompareDate((prevdate) => {
          return {
            ...prevdate,
            endDate: e.target.value,
          };
        });
      }
    }
  };
  useEffect(() => {
    if (selectedDataSource?.secondDataSource[0]?.label === 'GFS - NOAA') {
      const nwdate = new Date(updatedSelectedCompareDate?.startDate);
      nwdate.setDate(nwdate.getDate() + 15);
      const x = moment(nwdate).format(DATE_FORMAT);
      if (new Date(x).getTime() < new Date(updatedSelectedCompareDate?.maxEndDate).getTime()) {
        setFinalSelectedDate((prev) => {
          return {
            ...prev,
            endDate: x,
          };
        });
        setFinalNoaaCompareEndDate(x);
        setFinalNoaaCompareMaxEndDate(x);
      } else if (
        new Date(x).getTime() > new Date(updatedSelectedCompareDate?.maxEndDate).getTime()
      ) {
        setFinalSelectedDate((prev) => {
          return {
            ...prev,
            endDate: updatedSelectedCompareDate?.maxEndDate,
          };
        });
        setFinalNoaaCompareEndDate(updatedSelectedCompareDate?.maxEndDate);
        setFinalNoaaCompareMaxEndDate(updatedSelectedCompareDate?.maxEndDate);
      } else {
        setFinalSelectedDate((prev) => {
          return {
            ...prev,
            endDate: updatedSelectedCompareDate['endDate'],
          };
        });
        setFinalNoaaCompareEndDate(updatedSelectedCompareDate['endDate']);
        setFinalNoaaCompareMaxEndDate(updatedSelectedCompareDate['endDate']);
      }
    }
  }, [updatedSelectedCompareDate]);

  useEffect(() => {
    if (isOpenWeather || isIri) {
      let meanData = TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean');
      setTemporalAggregation(meanData);
    }
  }, [isOpenWeather, isIri]);

  const finalForecastYearMonthHandler = (value) => {
    selectedFinalForecastYearMonth(value);
  };

  const handleTemporalAggregation = (selectedOption) => {
    setTemporalAggregation(selectedOption);
  };
  useEffect(() => {
    if (selectedDataSource?.firstDataSource[0]?.label === 'GFS - NOAA') {
      const nwdate = new Date(updatedSelectedDate?.startDate);
      nwdate.setDate(nwdate.getDate() + 15);
      const x = moment(nwdate).format(DATE_FORMAT);
      if (new Date(x).getTime() < new Date(updatedSelectedDate?.maxEndDate).getTime()) {
        setSelectedDate((prev) => {
          return {
            ...prev,
            map: {
              ...initialSelectedDate.map,
              // startDate: e.target.value,
              endDate: x,
            },
            graph: {
              ...initialSelectedDate.graph,
              // startDate: e.target.value,
              endDate: x,
            },
          };
        });
        setFinalNoaaEndDate(x);
        setFinalNoaaMaxEndDate(x);
      } else if (new Date(x).getTime() > new Date(updatedSelectedDate?.maxEndDate).getTime()) {
        setSelectedDate((prev) => {
          return {
            ...prev,
            map: {
              ...initialSelectedDate.map,
              // startDate: e.target.value,
              endDate: updatedSelectedDate?.maxEndDate,
            },
            graph: {
              ...initialSelectedDate.graph,
              // startDate: e.target.value,
              endDate: updatedSelectedDate?.maxEndDate,
            },
          };
        });
        setFinalNoaaEndDate(updatedSelectedDate?.maxEndDate);
        setFinalNoaaMaxEndDate(updatedSelectedDate?.maxEndDate);
      } else {
        setSelectedDate((prev) => {
          return {
            ...prev,
            map: {
              ...initialSelectedDate.map,
              // startDate: e.target.value,
              endDate: updatedSelectedDate['endDate'],
            },
            graph: {
              ...initialSelectedDate.graph,
              // startDate: e.target.value,
              endDate: updatedSelectedDate['endDate'],
            },
          };
        });
        setFinalNoaaEndDate(updatedSelectedDate['endDate']);
        setFinalNoaaMaxEndDate(updatedSelectedDate['endDate']);
      }
    }
  }, [updatedSelectedDate]);
  const handleOnChangeDates = (action, e) => {
    if (action === 'startDate') {
      if (selectedDataSource?.firstDataSource[0]?.label === 'GFS - NOAA') {
        setNoaaMaxEndDate(true);
        // handleSetNoaaEndDate();
      }
      setSelectedDate((prev) => {
        return {
          // ...prev,
          map: {
            ...initialSelectedDate.map,
            startDate: e.target.value,
          },
          graph: {
            ...initialSelectedDate.graph,
            startDate: e.target.value,
          },
        };
      });
      setUpdatedSelectedDate((prevdate) => {
        return {
          ...prevdate,
          startDate: e.target.value,
        };
      });
    } else if (action === 'endDate') {
      setFinalNoaaEndDate(e.target.value);
      setSelectedDate((prev) => {
        return {
          ...prev,
          map: {
            ...initialSelectedDate.map,
            endDate: e.target.value,
          },
          graph: {
            ...initialSelectedDate.graph,
            endDate: e.target.value,
          },
        };
      });
      if (selectedDataSource?.firstDataSource[0]?.label !== 'GFS - NOAA') {
        setUpdatedSelectedDate((prevdate) => {
          return {
            ...prevdate,
            endDate: e.target.value,
          };
        });
      }

      //   setFinalNoaaEndDate(e.target.value)

      // setSelectedDate(prev => {
      //   return {
      //     ...prev,
      //     map: {
      //       ...initialSelectedDate.map,
      //       endDate: e.target.value,
      //     },
      //     graph: {
      //       ...initialSelectedDate.graph,
      //       endDate: e.target.value,
      //     },
      //   };
      // });
      // setUpdatedSelectedDate(prevdate => {
      //   return {
      //     ...prevdate,
      //     endDate: e.target.value,
      //   };
      // });
    }
  };

  return (
    <>
      {isHideBar ? (
        <div className="expand-bar">
          <span className="cursor-pointer navyBlueColor" onClick={() => setBar(!isHideBar)}>
            {t('CLIMATE_WEATHER_FILTER.EXPAND_BAR')}
            <span>
              <MdKeyboardArrowLeft
                className="hide-icon expand-margin"
                size={25}
              />
            </span>
          </span>
        </div>
      ) : (
        <div className="filter-sidebar-section">
          <div className="filter-div">
            <Card className="card-style">
              <CardBody>
                <form
                  id="climate-weather-filter-form"
                  // onSubmit={handleSubmit(onFormSubmit)}
                  className="filter-control-dp"
                >
                  {' '}
                  <div className="search-container">
                    <div className="d-flex justify-content-end navyBlueColor mb-3">
                      {' '}
                        <span onClick={() => setBar(!isHideBar)} className="cursor-ponter">
                          <span className="hidebar-show">
                            {t('CLIMATE_WEATHER_FILTER.HIDE_BAR')}
                          </span>{' '}
                          <span>
                            <MdKeyboardArrowRight
                              className="hide-icon hide-margin"
                              size={25}
                            />
                          </span>
                        </span>
                    </div>{' '}
                    <div className="mt-2 mb-3 ">
                      <ForecastMonitoring
                        setCompareToggle={setCompareToggle}
                        region={region}
                        showToolTip={showToolTip}
                        isCompare={isCompare}
                        filterActiveTab={filterActiveTab}
                        countryList={countryList}
                        showMapPreferenceTooltip={showMapPreferenceTooltip}
                        stateList={stateList}
                        districtList={districtList}
                        showCharts={showCharts}
                        displayChart={displayChart}
                        isApplyLayer={isApplyLayer}
                        isUpdateGraph={isUpdateGraph}
                        //initialValues={initialValues}
                        dataSources={dataSources}
                        invalidDate={invalidDate}
                        selectedYearList={selectedYearList}
                        selectedDefaultYear={selectedDefaultYear}
                        selectedDateRange={selectedDateRange}
                        selectedDefaultDateRange={selectedDefaultDateRange}
                        openCollapse={openCollapse}
                        setOpenCollapse={setOpenCollapse}
                        setInvalidDate={setInvalidDate}
                        handleOnChange={handleOnChange}
                        handleDateSelectionChange={handleDateSelectionChange}
                        handleYearSelectionChange={handleYearSelectionChange}
                        defaultCountrySelected={defaultCountrySelected}
                        handleCountry={props.handleCountry}
                        handleState={props.handleState}
                        handleDistrict={props.handleDistrict}
                        handleCompareDataSourceChange={handleCompareDataSourceChange}
                        finalSelectedDate={finalSelectedDate}
                        handleFinalForecastYearMonth={handleFinalForecastYearMonth}
                        selectedFinalForecastYearMonth={selectedFinalForecastYearMonth}
                        temporalAggregation={temporalAggregation}
                        defaultSelectedOption={defaultSelectedOption}
                        finalDateHandler={finalDateHandler}
                        finalForecastYearMonthHandler={finalForecastYearMonthHandler}
                        country={country}
                        state={state}
                        TEMPORAL_AGGREGATION={TEMPORAL_AGGREGATION}
                        selectedDisctrict={selectedDisctrict}
                        selectedDataSource={selectedDataSource}
                        handleSelectedItem={handleSelectedItem}
                        handleInitialDate={handleInitialDate}
                        handleFinalDate={handleFinalDate}
                        handleInitialForecastYearMonth={handleInitialForecastYearMonth}
                        isOpenWeather={isOpenWeather}
                        initialSelectedDate={initialSelectedDate}
                        isIri={isIri}
                        handleTemporalAggregation={handleTemporalAggregation}
                        fromStaticResources={fromStaticResources}
                        handleApplyLayers={props.handleApplyLayers}
                        handleOnChangeDates={handleOnChangeDates}
                        forecastYearMonthHandler={forecastYearMonthHandler}
                        forecastCompareYearMonthHandler={forecastCompareYearMonthHandler}
                        forecastLeadTimeHandler={forecastLeadTimeHandler}
                        forecastCompareLeadTimeHandler={forecastCompareLeadTimeHandler}
                        compareHandler={compareHandler}
                        handleCompareSelectedItem={handleCompareSelectedItem}
                        compareStateList={compareStateList}
                        compareDistrictList={compareDistrictList}
                        selectedCompareDistrict={selectedCompareDistrict}
                        compareCountry={compareCountry}
                        compareState={compareState}
                        defaultCompareSelectedOption={defaultCompareSelectedOption}
                        handleCompareToggle={handleCompareToggle}
                        forecastLeadTimeList={forecastLeadTimeList}
                        hasSelectedParamChart={hasSelectedParamChart}
                        forecastCompareLeadTimeList={forecastCompareLeadTimeList}
                        selectedForecastLeadTime={selectedForecastLeadTime}
                        selectedFinalForercastLeadTime={selectedFinalForercastLeadTime}
                        isClickedOnPlus={isClickedOnPlus}
                        IRIAvailableYearList={IRIAvailableYearList}
                        compareIRIAvailableYearList={compareIRIAvailableYearList}
                        forecastTimeYearHandler={forecastTimeYearHandler}
                        compareForecastTimeYearHandler={compareForecastTimeYearHandler}
                        IRIAvailableMonthList={IRIAvailableMonthList}
                        compareIRIAvailableMonthList={compareIRIAvailableMonthList}
                        IRIForecastTime={IRIForecastTime}
                        selectedParam={selectedParam}
                        compareParam={compareParam}
                        compareIRIForecastTime={compareIRIForecastTime}
                        forecastTimeMonthHandler={forecastTimeMonthHandler}
                        compareForecastTimeMonthHandler={compareForecastTimeMonthHandler}
                        IRIAvailableLeadTimeList={IRIAvailableLeadTimeList}
                        compareIRIAvailableLeadTimeList={compareIRIAvailableLeadTimeList}
                        isHideBar={isHideBar}
                        finalNoaaEndDate={finalNoaaEndDate}
                        noaaMaxEndDate={noaaMaxEndDate}
                        finalNoaaMaxEndDate={finalNoaaMaxEndDate}
                        noaaCompareMaxEndDate={noaaCompareMaxEndDate}
                        finalNoaaCompareEndDate={finalNoaaCompareEndDate}
                        finalNoaaCompareMaxEndDate={finalNoaaCompareMaxEndDate}
                        isGraphLoading={isGraphLoading}
                        selectedLocation={selectedLocation}
                      />
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default Filter;
