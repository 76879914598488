import '../../assets/CSS/register.css';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AutoSuggest from 'react-autosuggest';
import Button from 'reactstrap-button-loader';
import Loader from '../../Components/common/loader';
import { Card, Col, Container, FormGroup, FormFeedback, Label, Row, Input } from 'reactstrap';
import { t } from 'react-multi-lang';
import { Field, reduxForm } from 'redux-form';

import Select from 'react-select';

import { LOCATION } from '../../Components/constants';
import { isValidEmail, getToken } from '../../Components/common/utils';
import { getCountryRequest } from '../../redux/location/actions';
import {
  COUNTRIES_JSON_LIST,
  SCOPE,
  discoveryUrl,
  CLIENT_ID,
  API_KEY,
} from '../../Components/constants/index';
import SuccessModal from './Success';
import { renderTextField, renderRadioButton } from '../../Components/common/renderInputField';
import {
  verificationRequest,
  registerRequest,
  
} from '../../redux/register/registerAction';
import { getInsuranceCompanyListRequest } from '../../redux/manageFieldData/manageFieldDataActions';

import Recaptcha from './Recaptcha';

import { change } from 'redux-form';
import { reset } from 'redux-form';

import Cookies from 'js-cookie';

const alphaNumeric = (value) =>
  value && /[^a-zA-Z ]/i.test(value) ? 'Please enter alphabetic characters' : undefined;

const passwordValidate = (password) =>
  // password && /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(password)
  password && /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(password)
    ? ''
    : t('REGISTER.PASSWORD_ERROR_MSG');
const validate = (values) => {
  const errors = {};
  const requiredFields = [
    'name',
    'email',
    'password',
    'organization',
    'organization_name',
    'purpose',
    'recaptcha',
    'designation',
    // 'mobile_number',
  ];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required*';
    }
  });

  if (!values.name) {
    errors.name = 'Please enter name.';
  } else if (values?.name?.length > 20) {
    errors.name = 'Name must be below 20 characters.';
  }
  if (values?.name?.startsWith(' ')) {
    errors.name = t('REGISTER.PASSWORD_SPACE_ERROR');
  }

  // if (values?.mobile_number?.length > 10) {
  //   errors.mobile_number = 'Please enter valid mobile number.';
  // } else if (values?.mobile_number?.length < 10) {
  //   errors.mobile_number = 'Phone number should contain 10 digits.';
  // }

  if (!isValidEmail(values?.email)) {
    // errors.email = t('REGISTER.EMAIL_ERROR_MSG');
    errors.email = 'Invalid email address.';
  } else if (values?.email?.includes(' ')) {
    // errors.email = t('REGISTER.EMAIL_ERROR_MSG');
    errors.email = 'Invalid email address.';
  }

  if (!values.password) {
    errors.password = 'Please enter password.';
  } else if (values?.password?.length < 8 || values?.password?.length > 16) {
    // errors.password = t('REGISTER.PASSWORD_LEANGTH_ERROR');
    errors.password = 'Password should be 8 to 16 characters.';
  }
  if (values?.password?.includes(' ')) {
    errors.password = t('REGISTER.PASSWORD_SPACE_ERROR');
  }

  if (!values.organization) {
    errors.organization = 'Please enter organisation.';
  }
  if (values?.organization?.length > 160) {
    // errors.organization = t('REGISTER.ORGANISATION_ERROR_MSG');
    errors.organization = 'Organization name must be below 160 characters.';
  }
  if (values?.organization?.startsWith(' ')) {
    errors.organization = t('REGISTER.PASSWORD_SPACE_ERROR');
  }

  if (!values.organization_name) {
    errors.organization_name = 'Please enter organization name.';
  }
  if (values?.organization_name?.length > 160) {
    errors.organization_name = 'Organization name must be below 160 characters.';
  }
  if (values?.organization_name?.startsWith(' ')) {
    errors.organization_name = t('REGISTER.PASSWORD_SPACE_ERROR');
  }

  if (!values.designation) {
    errors.designation = 'Please enter designation.';
  }
  if (values?.designation?.length > 160) {
    errors.designation = t('REGISTER.DESIGNATION_ERROR_MESSAGE');
  }
  if (values?.designation?.startsWith(' ')) {
    errors.designation = t('REGISTER.PASSWORD_SPACE_ERROR');
  }

  if (!values.purpose) {
    errors.purpose = 'Please enter purpose.';
  } else if (values?.purpose?.length > 200) {
    errors.purpose = t('REGISTER.PURPOSE_ERROR_MESSAGE');
  }
  if (values?.purpose?.startsWith(' ')) {
    errors.purpose = t('REGISTER.PASSWORD_SPACE_ERROR');
  }
  return errors;
};

const Register = (props) => {
  const { handleSubmit, pristine, submitting, invalid } = props;
  let history = useHistory();
  const dispatch = useDispatch();
  let defaultValues = {
    name: '',
    mobile: '',
    email: '',
    password: '',
    organization: '',
    organization_name: '',
    designation: '',
    country: '',
    purpose: '',
  };
  const nextProps = useSelector((state) => ({
    countryData: state.Location || null,
    registerDataError: state.Register.registerDataError,
    registerData: state.Register.registerData || null,
    isRegisterRequesting: state.Register.isRegisterRequesting,
    verificationData: state.Register.verifiedData || null,
    isVerificationLoading: state.Register.isRegisterRequesting,
    verifiedDataError: state.Register.verifiedDataError,
    insuranceCompanyList: state.fieldDataList?.insuranceCompanyListData,

    verifyValidation: state.Register.verifyValidationData || null,

    
  }));
  const [countryData, setCountryData] = useState();
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [passwortType, setPasswordType] = useState(true);
  const [countryRequired, setCountryRequired] = useState(false);
  const [formData, setFormData] = useState(defaultValues);
  const [registerButtonDisable, setRegisterButtonDisable] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [fromVerification, setFromVerification] = useState(false);
  const [modalHeaderMessage, setModalHeaderMessage] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const recaptchaRef = React.createRef();

  const [googleAuth, setGoogleAuth] = useState('');
  const [signedInUser, setSignedInUser] = useState('');
  const [signedInUserDetails, setSignedInUserDetails] = useState('');
  const [fetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] = useState('');
  const [listDocumentsVisibility, setListDocumentsVisibility] = useState('');
  const [documents, setDocuments] = useState('');
  const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState('');
  const [isVerify, setIsVerify] = useState(false);

  const [folderId, setFolderId] = useState('');

  const [Loading, setLoading] = useState(false);
  
  // const [options, setOptions] = useState([
  //   { name: 'Insurance Company', checked: 'false', id: '1', value: '1' },
  //   { name: 'Others', checked: 'true', id: '2', value: '2' },
  // ]);

  const [isOptionSelected, setIsOpionSelected] = useState([
    { name: 'Insurance Company', value: 'InsuranceCompany', selected: false },
    { name: 'Others', value: 'Others', selected: true },
  ]);

  const [options, setOptions] = useState([
    { name: 'Insurance Company1', id: '1', value: '1' },
    { name: 'Insurance Company2', id: '2', value: '2' },
    { name: 'Insurance Company3', id: '3', value: '3' },
    { name: 'Insurance Company4', id: '4', value: '4' },
    { name: 'Insurance Company5', id: '5', value: '5' },
  ]);
  const [othersOption, setOthersOption] = useState('');
  useEffect(() => {
    window.scroll(0, 0);
    let lowerCasedCompanies = [];
    if (getToken()) {
      history.push('/home');
    } else {
      let params = new URLSearchParams(window.location.search);
      let keyValue;
      keyValue = params.get('key');
      if (window.location.href.includes('key')) {
        dispatch(
          verificationRequest({
            verification_code: keyValue,
          }),
        );
      } else {
        history.push('/register');
        dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
      }
    }
    lowerCasedCompanies = COUNTRIES_JSON_LIST.map((company) => {
      return {
        id: company.code,
        name: company.name.charAt(0).toUpperCase() + company.name.slice(1),
      };
    });
    let a = lowerCasedCompanies.filter((data) => data.name.includes('Sri Lanka'));
    setValue(a[0]?.name?.trim().charAt(0).toUpperCase() + a[0].name.slice(1));
    formData.country = a[0]?.name;
    setFormData({ ...formData });
    setFormData((prevState) => ({ ...prevState, country: a[0]?.name }));
    setCountryRequired(false);
    setCountryData(lowerCasedCompanies);
  }, [dispatch, history]);

  const isVerificationFirstRun = useRef(true);
  useEffect(() => {
    if (isVerificationFirstRun.current) {
      isVerificationFirstRun.current = false;
      return;
    }
    if (
      nextProps.verificationData &&
      nextProps.verificationData.data &&
      nextProps.verificationData.data.result
    ) {
      setModalHeaderMessage(nextProps.verificationData.data.title);
      setSuccessModal(true);
      setFromVerification(true);
      setModalMessage(nextProps.verificationData.data.result);
    }
  }, [nextProps.verificationData]);

  useEffect(() => {
    dispatch(reset('RegisterPageForm'));
  }, []);

  useEffect(() => {
    if (nextProps.verifiedDataError) {
      setSuccessModal(false);
      history.push('/register');
    }
  }, [nextProps.verifiedDataError, history]);

  const isRegisterFirstRun = useRef(true);
  useEffect(() => {
    if (isRegisterFirstRun.current) {
      isRegisterFirstRun.current = false;
      return;
    }
    if (
      nextProps.registerData &&
      nextProps.registerData.data &&
      nextProps.registerData.data.result
    ) {
      window.scroll(0, 0);
      setModalHeaderMessage(nextProps.registerData.data.title);
      setSuccessModal(true);
      setModalMessage(nextProps.registerData.data.result);
      dispatch(reset('RegisterPageForm'));
    }
  }, [nextProps.registerData]);

  

  useEffect(() => {
    if (nextProps.registerDataError) {
      setRegisterButtonDisable(true);
    }
  }, [nextProps.registerDataError]);

  const getSuggestions = (suggestedValue) => {
    return countryData.filter((data) =>
      data.name.includes(
        suggestedValue?.trim()?.charAt(0)?.toUpperCase() + suggestedValue?.slice(1),
      ),
    );
  };
  const handleOkayButtonClick = (bool_value) => {
    if (bool_value === true && fromVerification) {
      setSuccessModal(false);
      history.push('/login');
    } else {
      setSuccessModal(false);
      history.push('/home');
    }
  };

  const handleNameChange = (e) => {
    formData.name = e.target.value.trim();
    setFormData({ ...formData });
  };

  const handleMobileChange = (e) => {
    formData.mobile = e.target.value;
    setFormData({ ...formData });

    if (e.target.value?.length > 10 || e.target.value?.length < 10) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };
  const handleChangeEmail = (e) => {
    formData.email = e.target.value.trim().toLowerCase();
    setFormData({ ...formData });
  };
  const handleChangePassword = (e) => {
    formData.password = e.target.value.trim();
    setFormData({ ...formData });
  };
  const showPassword = () => {
    setPasswordType(!passwortType);
  };
  const handleChangeOrganization = (e) => {
    formData.organization = e.target.value.trim();
    setFormData({ ...formData });
  };

  const handleChangeOrganizationName = (e) => {
    formData.organization_name = e.target.value.trim();
    setFormData({ ...formData });
  };

  const handleChangeDesignation = (e) => {
    formData.designation = e.target.value.trim();
    setFormData({ ...formData });
  };

  const handleChangePurpose = (e) => {
    formData.purpose = e.target.value.trim();
    setFormData({ ...formData });
  };

  const onFormSubmit = (e) => {
    formData.organization = '';
    setFormData({ ...formData });

    if (
      e.organization !== '' &&
      isOptionSelected?.map((item) => item.value === 'InsuranceCompany' && item.selected)
    ) {
      formData.organization = e.organization;

      // formData.drive_folder_id = folderId;

      setFormData({ ...formData });
    } else {
      formData.organization = e.organization;
    }
    if (
      e.organization_name !== '' &&
      isOptionSelected?.map((item) => item.value === 'InsuranceCompany' && item.selected)
    ) {
      formData.organization_name = e.organization_name;

      setFormData({ ...formData });
    } else {
      formData.organization_name = e.organization_name;
    }

    
        dispatch(registerRequest(formData));
    

    
  };

  useEffect(() => {
    dispatch(getInsuranceCompanyListRequest());
  }, []);

  // const handleOptions = (item) => {
  //   console.log("handleOptions" , item);
  //   setActiveOption(item)
  // };

  

  const handleOptionToggle = ({ target }) => {
    
    //   formData.organization=""
    // setFormData({...formData})
    dispatch(change('RegisterPageForm', 'organization', ''));
    formData.organization = '';
    setFormData({ ...formData });

    let compareData = isOptionSelected;
    const nextCompare = compareData.map((item) => {
      if (item.value === target.value) {
        return {
          ...item,
          selected: target.checked,
        };
      } else {
        return {
          ...item,
          selected: false,
        };
      }
    });
    setIsOpionSelected(nextCompare);
  };
  // const handleOptions = (options, newValue, action) => {
  //   if (options && options.length) {
  //     if (newValue?.value) {
  //       let selectedOption = options.find(item => item.name === newValue.value);
  //       if (selectedOption) {
  //         setSelectedOption(selectedOption);
  //       } else {
  //         setSelectedOption('');
  //       }
  //     }
  //   }
  // };

  const renderAPIParameterOptions = (list) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map((param) => {
          if (param?.name) {
            let data = {
              value: param.value,
              label: `${param.name}`,
              id: param.id,
              api_url: param.api_url,
            };
            options.push(data);
          }
          return true;
        });
        return options;
      }
    }
  };

  useEffect(() => {
    if (nextProps.verifyValidation?.data?.result === true && isVerify && signedInUser) {
      // handleClientLoad();
      // createFolder(signedInUser);
    }
  }, [nextProps.verifyValidation, signedInUser]);

  const handleClientLoad = () => {
    setLoading(true)
  };

  const fileName = (i) => {
    if (i === 1) {
      return 'Farmer Information' + '_' + new Date().getFullYear();
    } else if (i === 2) {
      return 'Enrollment' + '_' + new Date().getFullYear();
    } else if (i === 3) {
      return 'Understanding The Insurance Contex' + '_' + new Date().getFullYear();
    } else if (i === 4) {
      return 'Impact Evalution' + '_' + new Date().getFullYear();
    } else if (i === 5) {
      return 'Claim Settlement' + '_' + new Date().getFullYear();
    }
  };

  const handleOptions = (item) => {
    // formData.organization =item?.label
    // setFormData({ ...formData });
    // setSelectedOption(item)
  };

  const handleChangeOrg = (e) => {
    formData.organization_name = '';
    setFormData({ ...formData });
    let isOthers = nextProps?.insuranceCompanyList?.data?.result?.filter((item , key)=> item.id===e.target.value)

    dispatch(change('RegisterPageForm', 'organization_name', ''));
    // if (e.target.value === 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e15') {
    if (isOthers?.[0].name==="Others") {
      setOthersOption('Others');
    } else {
      setOthersOption('');
    }
  };
  return (
    <>
      <div
        className={
          !successModal
            
              ? 'register-wrapper'
              : ''
            
        }
      >
        <Container fluid>
          <Row>
            <Col
              lg={5}
              sm={7}
              xs={12}
              className="mx-auto register-form-wrapper register-zIndex w-100"
            >
              <div className="register-card-wrapper" id="register-card-wrapper">
                <Card className="register-card" style={{ height: 'max-content' }}>
                  {!successModal ? (
                    <>
                      {!window.location.href.includes('key') ? (
                        <div className="register-form w-100">
                          <div className="pb-3">
                            {/* <img src={SADMS} alt="logo" className="d-none"/> */}
                            <h5 className="register-key-text">
                              {' '}
                              {t('REGISTER.REGISTER_FORM_TITLE')}
                            </h5>
                            <small className="register-small-key-text">
                              {t('REGISTER.REGISTER_FORM_CAPTION')}
                            </small>
                          </div>
                          {/* <div className="d-i-f">
                            <span>
                              <FormGroup check>
                                <Row className="compare-style">
                                  {isOptionSelected.map((item) => {
                                    return (
                                      <>
                                        <Col sm={4} lg={4} md={4} className="mt-2">
                                          <Label
                                            check
                                            style={{ fontSize: '15px', color: '#363636' }}
                                          >
                                            <Input
                                              type="radio"
                                              name="radio1"
                                              value={item.value}
                                              defaultChecked={item.selected}
                                              onChange={handleOptionToggle}
                                            />{' '}
                                            {item.name}
                                          </Label>
                                        </Col>{' '}
                                      </>
                                    );
                                  })}
                                </Row>
                              </FormGroup>
                            </span>
                          </div> */}
                          
                            <form
                              id="RegisterPageForm"
                              onSubmit={handleSubmit((e) => onFormSubmit(e))}
                            >
                              
                              

                              <FormGroup className="registertextOnInput mb-3">
                                <Label for="exampleEmail">{t('REGISTER.NAME')}</Label>
                                <Field
                                  component={renderTextField}
                                  type="text"
                                  name="name"
                                  className="register-input"
                                  onChange={handleNameChange}
                                  warn={alphaNumeric}
                                  maxLength={20}
                                />
                              </FormGroup>
                              <FormGroup className="registertextOnInput mb-3">
                                <Label for="exampleEmail">{t('REGISTER.MOBILE')}</Label>
                                <Field
                                  id="mobile"
                                  name="mobile_number"
                                  component={renderTextField}
                                  className="register-input"
                                  // className={
                                  //   formData?.mobile?.length > 10
                                  //     ? 'register-input is-invalid form-control'
                                  //     : 'register-input'
                                  // }
                                  onChange={handleMobileChange}
                                  type="number"
                                />
                                {/* {phoneNumberError && (
                                <span invalid className="invalid-feedback">
                                  Phone number should contain 10 digits.
                                </span>
                              )} */}
                              </FormGroup>
                              <FormGroup className="registertextOnInput mb-3" autocomplete="off">
                                <Label for="exampleEmail">{t('REGISTER.EMAIL')}</Label>
                                <Field
                                  type="text"
                                  name="email"
                                  component={renderTextField}
                                  className="register-input"
                                  onChange={handleChangeEmail}
                                  
                                />
                              </FormGroup>
                              <FormGroup className="registertextOnInput mb-3">
                                <Label for="exampleEmail">{t('REGISTER.PASSWORD')}</Label>
                                <Field
                                  name="password"
                                  className="password-input"
                                  type={passwortType ? 'password' : 'text'}
                                  onChange={handleChangePassword}
                                  component={renderTextField}
                                  warn={passwordValidate}
                                />
                                <span className="register-show-hide" onClick={showPassword}>
                                  {passwortType ? t('REGISTER.SHOW') : t('REGISTER.HIDE')}
                                </span>
                              </FormGroup>
                              {isOptionSelected?.map(
                                (item) =>
                                  item.value === 'Others' &&
                                  item.selected && (
                                    <FormGroup className="registertextOnInput mb-3">
                                      <Label for="exampleEmail">{t('REGISTER.ORGANIZATION')}</Label>
                                      <Field
                                        type="text"
                                        name="organization"
                                        className="register-input"
                                        onChange={handleChangeOrganization}
                                        component={renderTextField}
                                        // warn={alphaNumeric}
                                      />
                                    </FormGroup>
                                  ),
                              )}

                              {/* <FormGroup className="registertextOnInput mb-3"> */}

                              {isOptionSelected?.map(
                                (item) =>
                                  item.value === 'InsuranceCompany' &&
                                  item.selected && (
                                    <>
                                      <FormGroup className="registertextOnInput mb-3 txt1">
                                        <Label for="exampleEmail">
                                          {t('REGISTER.ORGANIZATION')}
                                        </Label>

                                        {/* <Select
                                      id="multi-select"
                                      name="state"
                                      placeholder=""
                                      options={renderAPIParameterOptions(options)}
                                      onChange={handleOptions}
                                      isMulti={false}
                                      required={true}
                                      style={{height:"48px"}}
                                      // value={compareState}
                                    /> */}
                                        {/* </div> */}

                                        <Field
                                          component="select"
                                          name="organization"
                                          style={{
                                            height: '48px',
                                            width: '100%',
                                            borderRadius: '3px',
                                            borderColor: '#3870af ',
                                          }}
                                          onChange={handleChangeOrg}
                                          className="text1 "
                                        >
                                          <option value="" disabled>
                                            Select Insurance Company...
                                          </option>

                                          {nextProps?.insuranceCompanyList?.data?.result?.map(
                                            (item, key) => {
                                              return (
                                                <>
                                                  {/* <option value="" disabled>Select Insurance Company...</option> */}
                                                  <option
                                                    value={item.id}
                                                    key={item.id}
                                                    className="optn1"
                                                    style={{
                                                      bacgroundColor: '#fff',
                                                      cursor: 'pointer',
                                                    }}
                                                  >
                                                    {item.name}
                                                  </option>
                                                </>
                                              );
                                            },
                                          )}
                                        </Field>
                                      </FormGroup>

                                      {/* {othersOption === 'Others' ? 
                                     <>
                                    <FormGroup className="registertextOnInput mb-3 ">
                                     <Label for="organization_name">Organization name</Label>
                                      <Field
                                        type="text"
                                        name="organization_name"
                                        component={renderTextField}
                                        className="register-input"
                                        onChange={handleChangeOrganizationName}
                                      />
                                      </FormGroup>
                                      </>
                                       : "" } */}
                                    </>
                                  ),
                              )}
                              {/* </FormGroup> */}

                              {isOptionSelected?.map(
                                (item) =>
                                  item.value === 'InsuranceCompany' &&
                                  item.selected &&
                                  (othersOption === 'Others' ? (
                                    <>
                                      <FormGroup className="registertextOnInput mb-3 ">
                                        <Label for="organization_name">Organization name</Label>
                                        <Field
                                          type="text"
                                          name="organization_name"
                                          component={renderTextField}
                                          className="register-input"
                                          onChange={handleChangeOrganizationName}
                                        />
                                      </FormGroup>
                                    </>
                                  ) : (
                                    ''
                                  )),
                              )}

                              <FormGroup
                                className="registertextOnInput mb-3 "
                                style={othersOption === 'Others' ? { width: '94%' } : {}}
                              >
                                <Label for="exampleEmail">{t('REGISTER.DESIGNATION')}</Label>
                                <Field
                                  type="text"
                                  name="designation"
                                  className="register-input"
                                  onChange={handleChangeDesignation}
                                  component={renderTextField}
                                  warn={alphaNumeric}
                                  maxLength={160}
                                />
                              </FormGroup>

                              <FormGroup className="mb-3 register-country-field">
                                <Label for="exampleEmail">{t('REGISTER.COUNTRY')}</Label>
                                <AutoSuggest
                                  className="register-input"
                                  suggestions={suggestions}
                                  onSuggestionsClearRequested={() => {
                                    setSuggestions([]);
                                  }}
                                  onSuggestionsFetchRequested={({ value }) => {
                                    setValue(value);
                                    formData.country = value;
                                    setFormData({ ...formData });
                                    setSuggestions(getSuggestions(value));
                                  }}
                                  onSuggestionSelected={(_, { suggestionValue }) => {
                                    setCountryRequired(false);
                                  }}
                                  getSuggestionValue={(suggestion) => suggestion.name}
                                  renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
                                  inputProps={{
                                    value: value,
                                    autoComplete: 'none',
                                    onChange: (_, { newValue, _method }) => {
                                      formData.country = newValue;
                                      setCountryRequired(true);
                                      setFormData({ ...formData });
                                      setValue(newValue);
                                    },
                                  }}
                                  highlightFirstSuggestion={true}
                                  invalid={countryRequired}
                                  disabled={true}
                                />
                                {countryRequired && (
                                  <FormFeedback invalid={countryRequired}>
                                    This field is required.
                                  </FormFeedback>
                                )}
                              </FormGroup>
                              <FormGroup className="mb-3 register-purpose-field">
                                <Label for="exampleEmail">{t('REGISTER.PURPOSE')}</Label>
                                <Field
                                  type="textarea"
                                  name="purpose"
                                  className="register-input register-purpose-input"
                                  onChange={handleChangePurpose}
                                  component={renderTextField}
                                  maxLength={200}
                                />
                              </FormGroup>
                              <FormGroup className="registertextOnInput mb-3 recaptcha">
                                <Field
                                  name="recaptcha"
                                  recaptchaRef={recaptchaRef}
                                  component={Recaptcha}
                                />
                              </FormGroup>
                              <Button
                                type="submit"
                                // style={{marginTop: "-290px"}}
                                loading={
                                  nextProps.isRegisterRequesting 
                                  
                                }
                                className="btn register-btn-grn-key btn-block w-100"
                                disabled={
                                  pristine ||
                                  submitting ||
                                  countryRequired ||
                                  invalid
                                  // phoneNumberError
                                }
                              >
                                {t('REGISTER.REGISTER')}
                              </Button>
                              
                              
                            </form>
                          {/* )} */}
                          <div className="register-text-center w-100 mb-3">
                            <p className="m-0 p-0 register-term-text mt-3">
                              {t('REGISTER.AGREE')}{' '}
                              <a href="/terms-conditions" target="_blank" className="register-text">
                                {t('REGISTER.TERMS_CONDITIONS')}
                              </a>
                            </p>
                            <p className="m-0 p-0 register-term-text mt-3">
                              {t('REGISTER.HAVE_ACCOUNT')}{' '}
                              <span
                                className="register-text"
                                onClick={() => history.push('/login')}
                              >
                                {t('REGISTER.SIGN_IN')}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <span className="register-spinner">
                          <Loader style={{ right: '30%' }} />
                        </span>
                      )}{' '}
                    </>
                  ) : (
                    <SuccessModal
                      fromVerification={fromVerification}
                      modalHeaderMessage={modalHeaderMessage}
                      okayButtonClickHandler={handleOkayButtonClick}
                      modalSuccessMessage={modalMessage}
                    />
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default reduxForm({
  form: 'RegisterPageForm',
  destroyOnUnmount: false,
  touchOnChange: true,
  touchOnBlur: true,
  validate,
})(Register);
