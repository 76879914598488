import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import moment from 'moment';
import {
  DISPLAY_CHART_DATE,
  DATE_FORMAT_ONE,
  DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
  DATA_SOURCES,
  OPEN_WEATHER_PARAMETER_SLUG,
  OPEN_WEATHER_PARAMETERS_CONST,
} from '../constants';
import myConstClass from '../../Constants';
import {
  AXIOS_INSTANCE,
  ERPAS_TIMESERIES,
  IRI_TIMESERIES,
  NOAA_TIMESERIES,
  WEATHER_FORECAST_DATA_URL,
} from '../../redux/api/apiEndPoint';

export const renderCurrentYear = () => {
  return new Date().getFullYear();
};

/**
 * Url pattern
 */
export const urlRegex =
  '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)';

/**
 * Url valid or not
 */
export const isValidUrl = url => {
  return urlRegex.test(
    String(url)
      .trim()
      .toLowerCase(),
  );
};

/**
 * Email pattern
 */
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Email valid or not
 */
export const isValidEmail = email => {
  return emailRegex.test(
    String(email)
      .trim()
      .toLowerCase(),
  );
};

/*for Mobile Number*/

// export const numberRegex = /^[789]\d{9}$/;

/**
 * Number valid or not
 */
// export const isValidMobileNumber = number => {
//   return numberRegex.test(number);
// };
// export const passwordValidate = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$";
// export const isPasswordValid = password => {
//   return passwordValidate.test(password);
// }

// gloabal Utility for handling logout action
export const handleLogoutRedirect = () => {
  Cookies.remove('user', { path: '/' });
  Cookies.remove('user_details', { path: '/' });
  Cookies.remove('token', { path: '/' });
  Cookies.remove('super-admin', { path: '/' });
  Cookies.remove('is_staff', { path: '/' });
  Cookies.remove('loggedin_user_details', { path: '/' });
  Cookies.remove('is_insurance_admin', { path: '/' });
};

export const getToken = () => {
  let userLoggedInData = [];
  userLoggedInData = Cookies.get('user');
  if (userLoggedInData) {
    return userLoggedInData;
  } else {
    return false;
  }
};

/**
 * Checks if user is authenticated
 */
export const isSuperAdmin = () => {
  let isUserDetails = [];
  isUserDetails = Cookies.get('super-admin');
  return isUserDetails === 'true' ? true : false;
};

export const hasPermissions = data => {
  Cookies.set('super-admin', data);
};

export const hasStaffUserPermission = data => {
  Cookies.set('is_staff', data);
};

export const setODKAccessToken = token => {
  Cookies.set('odk-access-token', token);
}

export const getODKAccessToken = () => {
  return Cookies.get('odk-access-token')
}

export const isStaffUser = () => {
  return Cookies.get('is_staff') === 'true' ? true : false;
};

export const hasInsuranceAdminUserPermission = data => {
  Cookies.set('is_insurance_admin', data);
};
export const isInsuranceAdmin = () => {
  return Cookies.get('is_insurance_admin') === 'true' ? true : false;
};

export const signIn = loginData => {
  if (loginData) {
    Cookies.set('user', loginData.token, { expires: 1 });
    Cookies.set(
      'user_details',
      loginData.userDetails &&
        loginData.userDetails.length &&
        loginData.userDetails[0].is_superuser,
    );
    Cookies.set('loggedin_user_details', loginData.userDetails[0].email);
  }
};

export const setPermissions = data => {
  return data;
};

//function for displaying Column Names in Pascal Case
export const convertToPascalCase = data => {
  return data
    .split('_')
    .map(word => word[0].toUpperCase().concat(word.slice(1)))
    .join(' ');
};
export const convertTimeFormat = time => {
  if (!_.isEmpty(time) && parseInt(time?.slice(0, 2)) > 12) {
    return `${time.slice(0, 2) - 12} : ${time.slice(3, 5)} PM`;
  } else if (!_.isEmpty(time) && parseInt(time?.slice(0, 2)) < 12) {
    return `${time} AM`;
  } else if (!_.isEmpty(time) && parseInt(time?.slice(0, 2)) === 12) {
    return `${time.slice(0, 2)} : ${time.slice(3, 5)} PM`;
  } else {
    return null;
  }
};

export const renderParamList = paramList => {
  if (paramList?.length) {
    let obj = paramList[0];
    if (!_.isEmpty(obj)) {
      let paramItems = [];
      obj.parameterData?.map(item => {
        if (item && item.weather_forecast_parameter) {
          item.weather_forecast_parameter.map(param => {
            param.dataSource = obj.name;
            param.category = obj.category;
            param.type = obj.type;
            paramItems.push(param);
          });
        }
      });
      return paramItems;
    }
  }
};

export const isOpenWeatherDataSource = dataSources => {
  if (dataSources?.length) {
    if (dataSources?.some(item => item.id === DATA_SOURCES.OPEN_WEATHER.id)) {
      return true;
    }
  }
  return false;
};

export const setWeatherForecastDefaultParam = () => {
  Cookies.set('weather-forecast-default-param', true);
};

export const setWaterMonitoringDefaultParam = (bool) => {
  Cookies.set('water-monitor-default-param', bool);
}

export const NavigateToModuleAfterLogin = (module_name) => {
  Cookies.set('selected-module', module_name)
}

export const handleOpenWeatherCompareRes = layerData => {
  const item = {};
  const result = [];
  result[0] = {
    Open_Weather: [
      {
        map_data: {
          ...layerData,
          code: 200,
          map_url: myConstClass.OPEN_WEATHER_TILE_LAYER_URL.replace(
            '{layername}',
            layerData.layerName,
          ),
          max: layerData.max,
          min: layerData.min,
          units: layerData.measure,
          palette: layerData?.palette || '',
        },
      },
    ],
  };
  item.data = {
    code: 200,
    result,
  };
  return item;
};

export const LEGEND_UNIT_CONVERTER = [
  {
    unit: '°C',
    converter: '°F',
    convert: c => {
      let x = c ? ((9 * c) / 5 + 32).toFixed(1) : c;
      return x;
    },
  },
  {
    unit: '°F',
    converter: '°C',
    convert: f => {
      let x = f ? ((f - 32) * (5 / 9)).toFixed(1) : f;
      return x;
    },
  },
  {
    unit: 'inch/h',
    converter: 'mm/h',
    convert: inch => {
      return inch ? Math.round((25.4 * inch).toFixed(2)) : inch;
    },
  },
  {
    unit: 'mm/h',
    converter: 'inch/h',
    convert: mm => {
      return mm ? (0.0393701 * mm).toFixed(2) : mm;
    },
  },
  {
    unit: 'km/h',
    converter: 'm/s',
    convert: km => {
      return km ? ((5 / 18) * km).toFixed(2) : km;
    },
  },
  {
    unit: 'm/s',
    converter: 'km/h',
    convert: m => {
      return m ? ((18 / 5) * m).toFixed(2) : m;
    },
  },
  {
    unit: 'hPa',
    converter: 'inHg',
    convert: hPa => {
      return hPa ? (0.02953 * hPa).toFixed(1) : hPa;
    },
  },
  {
    unit: 'inHg',
    converter: 'hPa',
    convert: inHg => {
      return inHg ? Math.floor((33.863886666667 * inHg).toFixed(1)) : inHg;
    },
  },
];

//function for displaying date in charts
export const renderGenericDateFormat = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format(DISPLAY_DATE_FORMAT);
  return formatedDate;
};

export const renderGenericDateFormatWithTime = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    // .format(DISPLAY_DATE_FORMAT);
    .format('DD-MM-YYYY @ hA');
  return formatedDate;
};

export const renderProjectDateFormat = (date) => {
  let dateFormat = new Date(date);
  let formattedDate = moment(dateFormat).fromNow();
  return formattedDate;
}

export const renderGeometricTimeSeries = async (
  requestObj,
  region,
  dataSources,
  isOpenWeather,
  selectedParam,
) => {
  let API_URL = [];
  let API_CALL = [];

  region.map(item => {
    if (isOpenWeather === false) {
      requestObj.geometry = item.layerType === 'marker' ? [item.latlngs] : item.latlngs;
      API_CALL =
        dataSources[0].id === DATA_SOURCES.GFS_NOAA.id ? NOAA_TIMESERIES : dataSources[0].id === DATA_SOURCES.ERPAS.id ? ERPAS_TIMESERIES : dataSources[0].id === DATA_SOURCES.IRI.id ? IRI_TIMESERIES : '';
    } else {
      requestObj.latitude = item.latlngs[1];
      requestObj.longitude = item.latlngs[0];
      API_CALL = WEATHER_FORECAST_DATA_URL;
    }
    let config = {
      id: dataSources[0].id,
      layer: item.layerType,
      latlng: item.layerType === 'marker' ? item.latlngs : '',
      polygonCount: item.layerType === 'polygon' ? item.polygonCount : '',
    };
    if(API_CALL) API_URL.push(AXIOS_INSTANCE.post(API_CALL, requestObj, config));
  });

  let resolved = await Promise.all(API_URL)
    .then(response => {
      let res = JSON.parse(JSON.stringify(response));
      let noaaResponse = [];
      res.map(item => {
        if (Array.isArray(item?.data?.result)) {
          let {
            Open_Weather: { hourly },
          } = item?.data?.result[0];
          let data = [];
          let Timestamp = [];
          let parameter = OPEN_WEATHER_PARAMETER_SLUG.find(
            (item) => item.value === selectedParam.weather_forecast_parameter.name,
          );
          let Units = [];
          let layerType = '';
          let latlng = '';
          let polygonCount = '';
          Units[0] = parameter.measure;
          if (parameter?.value) {
            data = hourly.map(item => {
              if (parameter.value === 'Precipitation') {
                return item?.[parameter.api_slug]?.['1h'] || 0;
              } else {
                return item?.[parameter.api_slug] || 0;
              }
            });
            Timestamp = hourly?.map(item => item.dt_txt);
            layerType = item.config.layer;
            polygonCount = item.config.polygonCount;
            latlng = item.config.latlng;
          }
          noaaResponse.push({ Timestamp, data, Units, layerType, latlng, polygonCount });
        } else {
          if(dataSources[0]?.id === DATA_SOURCES.IRI.id){
             let val = _.cloneDeep(item?.data?.result);
             val.layerType = item.config.layer;
             val.latlng = item.config.latlng;
             val.polygonCount = item.config.polygonCount;
             noaaResponse.push(val);
          }
          else{
            for (let [key, value] of Object.entries(item?.data?.result)) {
              let val = _.cloneDeep(value);
              val.layerType = item.config.layer;
              val.latlng = item.config.latlng;
              val.polygonCount = item.config.polygonCount;
              noaaResponse.push(val);
            }
          }
        }
      });
      return {
        response: true,
        noaaResponse,
      };
    })
    .catch(error => {
      let response = error?.response;
      let errorData = '';
      if(response?.data?.message){
        errorData = { showError: true, data: response?.data?.message};
      }
      else{
        errorData = { showError: true, data: 'No records found. please try again' };
      }
      return { response: false, errorData };
    });
  return resolved;
};

export const renderOpenWeatherResponse = (result, config, selectedParam) => {
  let {
    Open_Weather: { daily },
  } = result[0];
  let data = [];
  let Timestamp = [];
  let units = '';
  let parameter = OPEN_WEATHER_PARAMETER_SLUG.find(
    (item) => item.value === selectedParam.weather_forecast_parameter.name,
  );
  let { measure } = OPEN_WEATHER_PARAMETERS_CONST.find(item => item.name === parameter?.value);
  let Units = [];
  let layerType = '';
  let location = '';
  Units[0] = parameter.measure;
  if (!_.isEmpty(parameter)) {
    data = daily?.map(item => {
      if (parameter.value === 'Temperature') {
        return item[parameter.api_slug]['day'] || 0;
      } else {
        return item[parameter.api_slug] || 0;
      }
    });
    Timestamp = daily?.map(item => item.dt_txt);
    layerType = DATA_SOURCES.OPEN_WEATHER.name;
    location = config?.location || '';
    Units[0] = measure;
  }
  return {
    data,
    Timestamp,
    layerType,
    location,
    Units,
  };
};

export const chartDateLabelsDay = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format('M/DD');
  return formatedDate;
};

//function for displaying date in charts
export const renderChartTime = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format(DISPLAY_CHART_DATE);
  return formatedDate;
};

let regionData = [];
export const setRegionData = data => {
  regionData = data;
};

export const getRegionData = () => {
  return regionData;
};

export const removeRegionTooltip = (layers, elements) => {
  if (!_.isEmpty(layers)) {
    Object.values(layers).map(({ _leaflet_id }) => {
      let regionLayerData = getRegionData()?.length
        ? getRegionData()?.filter(l => l.id === _leaflet_id)
        : [];
      let regionText = '';
      if (regionLayerData?.length) {
        if (regionLayerData[0]?.layerType === 'polygon') {
          regionText = `polygon-${regionLayerData[0]?.polygonCount}`;
        } else {
          regionText = `(${regionLayerData[0].latlngs[0].toFixed(
            4,
          )},${regionLayerData[0].latlngs[1].toFixed(4)})`;
        }
        for (var i = 0; i < elements.length; i++) {
          // if (regionText === elements[i].innerHTML) {
          // elements[i].style.display = 'none';
          elements[i].parentNode.removeChild(elements[i]);
          // }
        }
      }
    });
  }
};

export function htmlToText(html) {
  var temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
}

//function for date
export const chartDateLabels = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format('M/DD @ hA');
  return formatedDate;
};

export const getERPASValidDates = () => {
  const t =
    new Date().getDate() + (6 - new Date().getDay() - 1) - (new Date().getDay() === 6 ? 0 : 7);
  const lastFriday = new Date();
  lastFriday.setDate(t);
  let endDate = moment(lastFriday)
    .add(28, 'days')
    .format('YYYY-MM-DD');
  return endDate;
};

export const renderCWPYearList = (start_year,end_year) => {
  let yearList =[];
  let startYear = start_year;
  let length =  end_year - start_year;
  
  for(let i=0;i <= length;i++){
    yearList?.push({ id: startYear?.toString(), label:startYear?.toString(), value: startYear })
    startYear+=1 ;
  }
  return yearList;
}


export const iriDate = (selectedDate, dateListing) => {
  const date = {
    startDate: '',
    endDate: '',
  };

  const endIriDate = selectedDate?.month?.id + '-' + selectedDate?.year?.value;
  const addValue = dateListing.find(each => each?.id === selectedDate?.lead_time?.id);
  const endIriEnd12 = moment(endIriDate, 'MM-YYYY')
    .add(addValue?.indexValue, 'months')
    .format('MM-YYYY');
  let iriStartDate = moment(endIriEnd12, 'MM-YYYY')
    .startOf('month')
    .format('DD-MM-YYYY');
  let iriEndDate = moment(endIriEnd12, 'MM-YYYY')
    .endOf('month')
    .format('DD-MM-YYYY');
  date.startDate = iriStartDate
  date.endDate = iriEndDate
  return date;
};