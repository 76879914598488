import React, { useMemo } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import PropTypes from 'prop-types';
import DisabledLeftArrowIcon from '../../../assets/Images/disable_previous_image.svg';
import DisabledRightArrowIcon from '../../../assets/Images/disable_next_image.svg';
import EnabledLeftArrowIcon from '../../../assets/Images/enable_previous_image.svg';
import EnabledRightArrowIcon from '../../../assets/Images/enable_next_image.svg';
import { useTranslation } from 'react-multi-lang';
import { Row, Col, Table } from 'reactstrap';

export const ManageFarmsListTable = props => {
  const { loading = true, req_data,activeTab, handleLatestData, handleLatestDataForDelete,recordsCount } = props;
  const t = useTranslation();

  const COLUMNS = [
    {
      Header: t('MANAGE_USER.PROJECT_NAME'),
      accessor: row => (row?.project_name ? row?.project_name : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('MANAGE_USER.FARM_NAME'),
      accessor: row => (row?.farm_name ? row?.farm_name : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('MANAGE_USER.FARM_CREATED_DATE'),
      accessor: row => (row?.farm_created_date ? new Date(row?.farm_created_date).toDateString().split(' ').slice(1).join(' ') : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('MANAGE_USER.LOCATION'),
      accessor: row => (row?.state_name ? `${row?.state_name},${row?.district_name},${row?.sub_district_name},${row?.block_name}` : 'N/A'),
      sticky: 'top',
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => req_data, [req_data]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const pageSizeHandler = e => {
    setPageSize(Number(e.target.value));
    props.handlePageSize(Number(e.target.value));
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    setPageSize,
    prepareRow,
  } = tableInstance;

  const { pageSize } = state;
  const page_range = [10, 15, 20, 25];

  const handlepreviousPage = value => {
    props.handlePageNumber(props.pageNumber - 1);
  };
  const handlenextPage = value => {
    props.handlePageNumber(props.pageNumber + 1);
  };
  const handleGoToPage = e => {
    props.handlePageNumber(Number(e.target.value));
  };

  return (
    <>    
      <div className="">
        <Table {...getTableProps()} className="table-list">
          <thead className="table-list-th">
            {headerGroups.map(headerGroup => (
              <>
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="user-list-keys"
                  style={{ marginBottom: '10px', marginTop: '10px' }}
                >
                  {headerGroup.headers.map(column => {
                    return (
                      <>
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className="name-list"
                          style={{ width: '23.3%' , textAlign:"center" ,  }}
                        >
                          {column.render('Header')}
                          <span className="sort-icons">
                            {!column.notShowSortingDisplay ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <>
                                    <i class="fa fa-sort-down"></i>
                                  </>
                                ) : (
                                  <>
                                    <i class="fa fa-sort-up"></i>
                                  </>
                                )
                              ) : (
                                <i class="fa fa-sort"></i>
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </th>
                      </>
                    );
                  })}
                  {/* <th style={{ width: '20%' }}>&nbsp;</th> */}
                </tr>
              </>
            ))}
          </thead>
          {loading ? (
            <tbody className="table-body">
              <tr>
                <td colSpan="10000" className="text-center">
                  <img src="" alt="Loading..." />
                </td>
              </tr>
            </tbody>
          ) : (
            <>
              {page.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan="4" style={{ textAlign: 'center', width: '100%' }}>
                      No record found .
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody
                  {...getTableBodyProps()}
                  className="body table-wrapper-scroll-y my-custom-scrollbar"
                >
                  {page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <>
                        <tr
                          {...row.getRowProps()}
                          className="user-list-details"
                          style={{ marginBottom: '20px' }}
                        >
                          {row.cells.map(cell => {
                            return (
                              <>
                                {cell.column.Header === 'Location' ? (
                                  <>
                                    <td
                                      {...cell.getCellProps()}
                                      style={{ width: '30%' , textAlign:"center" }}
                                      className="name-list-designation-details tbl-md"
                                    >
                                      {cell.render('Cell')}
                                    </td>
                                  </>
                                ) : (
                                  <td
                                    {...cell.getCellProps()}
                                    className="name-list-details tbl-md"
                                    style={{ width: '23%' , textAlign:"center" }}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </>
          )}
        </Table>
        {recordsCount >= 10 && <div className="pagination-section">
          <Row>
            <Col md="6" lg="6">
              <span className="item-text">{t('TABLE.ITEMS_PER_PAGE')}</span>
              <select value={pageSize} onChange={e => pageSizeHandler(e)} className="page-count">
                {page_range.map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              <span className="num-of-items">
                {(props.pageNumber - 1) * pageSize + 1}-
                {props.pageCount < props.pageNumber * pageSize
                  ? props.pageCount
                  : props.pageNumber * pageSize}{' '}
                of {props.pageCount ? props.pageCount : ''} items
              </span>
            </Col>
            <Col md="6" lg="6">
              <div className="pagination-right-col">
                <span>
                  <input
                    type="number"
                    value={props.pageNumber}
                    onChange={e => {
                      handleGoToPage(e);
                    }}
                    disabled={
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === 0
                        ? true
                        : false
                    }
                    style={{ width: '50px', border: 'none' }}
                  />
                </span>
                <span>
                  of{' '}
                  {props.pageCount % pageSize
                    ? Math.trunc(props.pageCount / pageSize + 1)
                    : Math.trunc(props.pageCount / pageSize)}{' '}
                  Pages
                </span>
                <span>
                  <button
                    onClick={() => handlepreviousPage()}
                    className="previous-btn"
                    disabled={props.pageNumber <= 1 ? true : false}
                  >
                    {props.pageNumber <= 1 ? (
                      <img src={DisabledLeftArrowIcon} alt={'not-available'} />
                    ) : (
                      <img src={EnabledLeftArrowIcon} alt={'not-available'} />
                    )}
                  </button>
                  <button
                    onClick={() => handlenextPage()}
                    className="next-btn"
                    disabled={
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === 0
                        ? true
                        : false
                    }
                  >
                    {(props.pageCount % pageSize
                      ? Math.trunc(props.pageCount / pageSize + 1)
                      : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                    (props.pageCount % pageSize
                      ? Math.trunc(props.pageCount / pageSize + 1)
                      : Math.trunc(props.pageCount / pageSize)) === 0 ? (
                      <img src={DisabledRightArrowIcon} alt={'not-available'} />
                    ) : (
                      <img src={EnabledRightArrowIcon} alt={'not-available'} />
                    )}
                  </button>
                </span>
              </div>
            </Col>
          </Row>
        </div>}
      </div>
    </>
  );
};
ManageFarmsListTable.propTypes = {
  req_data: PropTypes.array,
  handleLatestData: PropTypes.func,
  selectedTab: PropTypes.string,
  handleLatestDataForDelete: PropTypes.func,
  handlePageSize: PropTypes.func,
  pageCount: PropTypes.number,
  handlePageNumber: PropTypes.func,
  pageNumber: PropTypes.number,
  recordsCount: PropTypes.number,
//   activeTab: PropTypes.string,
};
