import React, {useState, useEffect} from 'react';
import { Collapse, Button, Row, Col } from 'reactstrap';
import '../assets/CSS/LeafletWrapper.css';
import { ReactComponent as ArrowUp } from '../assets/Images/upArrow.svg';
import { ReactComponent as ArrowDown } from '../assets/Images/downArrow.svg';
import { t } from 'react-multi-lang';
import { renderParamList } from './common/utils';
import {DATA_SOURCES, DEFAULT_PARAMETERS_CONST,ERPAS_PARAMETER_SLUG,ERPAS_STATIC_CONST,ERPAS_STATIC_PARAMETER_SLUG} from "../Components/constants/index"

const Parameters = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [params, setParams] = useState([]);
    const [selectedSourceId, setSelectedSourceId] = useState();
    const [selectedParent, setSelectedParent] = useState();
    const [isParameterOpen, setIsParameterOpen] = useState(false);
    const [categoryParams, setCategoryParams] = useState([]);
    const [selectedParameter, setSelectedParameter] = useState();
    const [selectedChild, setSelectedChild] = useState();
    const [selectedParams, setSelectedParams] = useState();

    useEffect(() => {
      if (props.initialParams) {
        const categoredParamsList = renderParamList(props.initialParams);
        setCategoryParams(categoredParamsList);
        setParams(props.initialParams[0]);
      }
    }, [props.initialParams]);

    useEffect(() => {
      // props.handleParameters(selectedParameter, selectedSourceId);
      props.handleParameters(selectedParameter);
    }, [selectedParameter]);

    useEffect(()=>{
      props.handleDataSourceId(selectedSourceId)
    },[selectedSourceId])

    useEffect(() => {
      if (params) {
        let defaultValue = [];
        defaultValue.push(DEFAULT_PARAMETERS_CONST.CUMULATIVE_ANAMOLY);
  
        defaultValue.push(DEFAULT_PARAMETERS_CONST.PRECIPITATION);
  
        params?.parameterData?.map((item) => {
          item?.weather_forecast_parameter?.map((i) => {
            if (defaultValue.includes(i.weather_forecast_parameter.name)) {
              if (i.parameter_type !== '') {
                setIsParameterOpen(true);
                setSelectedParent(item.type);
              }
              setSelectedSourceId(i.weather_forecast_parameter_id);
              if (categoryParams?.length) {
                const selectedParamIndex = categoryParams?.findIndex(
                  (item) => item.weather_forecast_parameter.name === i.weather_forecast_parameter.name,
                );
                if (selectedParamIndex >= 0) {
                  i.weather_forecast_parameter.paramIndex = selectedParamIndex;
                }
                setSelectedParameter(i);
              }
  
              setSelectedParams(item);
              setIsOpen(true);
            }
          });
        });
      }
    }, [params]);

    
  
    const toggle = () => setIsOpen(!isOpen);

    const handleParamsList = (item) => {
        if (item.type === selectedParent) {
          setSelectedParent('');
          setSelectedSourceId('');
          setIsParameterOpen(false);
        } else {
          setSelectedParent(item.type);
          setIsParameterOpen(true);
          setSelectedSourceId('');
          setSelectedParameter('')
        }
      };



    const handleParentClick = (data) => {
        setSelectedParent('');
        const selectedParamIndex = categoryParams?.findIndex(
          (item) => item.weather_forecast_parameter.name === data.weather_forecast_parameter.name,
        );
        if (selectedParamIndex >= 0) {
          data.weather_forecast_parameter.paramIndex = selectedParamIndex;
        }
        setSelectedParameter(data);
        setSelectedSourceId(data.weather_forecast_parameter_id);

        setTimeout(()=>{
          const ele = document.getElementsByClassName("submitBtnClass");
         if(ele.length){
          ele[0].click()
         }
         },500)

      };

      
  const handleChildParameter = (data) => {
    const selectedParamIndex = categoryParams?.findIndex(
      (item) => item.weather_forecast_parameter.name === data.weather_forecast_parameter.name,
    );
    if (selectedParamIndex >= 0) {
      data.weather_forecast_parameter.paramIndex = selectedParamIndex;
    }
    setSelectedParameter(data);
    setSelectedSourceId(data.weather_forecast_parameter_id);
    setSelectedChild(data.weather_forecast_parameter_id);


    
    setTimeout(()=>{
      const ele = document.getElementsByClassName("submitBtnClass");
     if(ele.length){
      ele[0].click()
     }
     },500)



  };

  const handleChildParameterName = (data) => {
    if(data.weather_forecast_source_id === DATA_SOURCES.ERPAS.id){
      let name = ""
      ERPAS_PARAMETER_SLUG?.map((i) => {
        if(i.api_slug === data.weather_forecast_parameter.slug){
           name = i.value
        }
      })
    return  name;
    }
    else if(data.weather_forecast_source_id === ERPAS_STATIC_CONST.id){
      let name = ""
      ERPAS_STATIC_PARAMETER_SLUG?.map((i) => {
        if(i.api_slug === data.weather_forecast_parameter.slug){
           name = i.value
        }
      })
    return  name;
    }
  }

    return (
        <>
          <div className="params-list">
            <Button
              className="param-button"
              onClick={toggle}
              style={{
                backgroundColor: '#363636' ,
                borderColor: '#363636',
                borderRadius: "10px",
                opacity: 0.9,
              }}
            >
              {t('CLIMATE_WEATHER_FILTER.PARAMETERS')}
              <span className="collapse-style">{isOpen ? <ArrowUp /> : <ArrowDown />}</span>
            </Button>
            <Collapse isOpen={isOpen}>
              {params?.parameterData?.map((item) => {
                const { weather_forecast_parameter, type } = item;
                if (type === '' || type === 'non-static') {
                  return weather_forecast_parameter?.map((p) => {
                    return (
                      <Button
                        className={
                          selectedSourceId === p.weather_forecast_parameter.id
                            ? 'selected-parent-parameter'
                            : 'param-options'
                        }
                        onClick={() => handleParentClick(p)}
                      >
                        <span className="icon-style">
                          <img
                            src={
                              selectedSourceId === p.weather_forecast_source_id
                                ? p.weather_forecast_parameter.icon_selected
                                : p.weather_forecast_parameter.icon
                            }
                            alt="img"
                            width={20}
                            height={20}
                          />
                        </span>
                        {p.weather_forecast_parameter.name}
                      </Button>
                    );
                  });
                } else {
                  return (
                    <>
                      <Button
                        className={
                          selectedParent === item.type ? 'selected-parent-parameter' : 'param-options'
                        }
                        onClick={() => handleParamsList(item)}
                      >
                        {item.type}
                        <span className={selectedParent === item.type ? 'blackStyle' : 
                        // 'whiteStyle'
                        "blackStyle"
                        }>
                          {selectedParent === item.type ? <ArrowUp /> : <ArrowDown />}
                        </span>
                      </Button>
                      {isParameterOpen && selectedParent === item.type && (
                        <Collapse isOpen={isParameterOpen}>
                          <Row className="child-row">
                            <Col sm="2" lg="2" md="2"></Col>
                            <Col sm="10" lg="10" md="10" style={{zIndex:100}}>
                              {item?.weather_forecast_parameter?.map((data) => {
                                return (
                                  <Button
                                    className={
                                      selectedSourceId === data?.weather_forecast_parameter_id
                                        ? 'selected-child-parameter'
                                        : 'child-parameter'
                                    }
                                    onClick={() => handleChildParameter(data)}
                                  >
                                    <span className="icon-style">
                                      <img
                                        src={
                                          selectedSourceId === data.weather_forecast_parameter_id
                                            ? data.weather_forecast_parameter.icon_selected
                                            : data.weather_forecast_parameter.icon
                                        }
                                        alt="img"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                    {handleChildParameterName(data)}
                                  </Button>
                                );
                              })}
                            </Col>
                          </Row>
                        </Collapse>
                      )}
                    </>
                  );
                }
              })}
            </Collapse>
          </div>
        </>
      );
}

export default Parameters;