import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from 'reactstrap';
import axios from 'axios';
import TabOne from './TabOne';
import TabTwo from './TabTwo';
import TabThree from './TabThree';
import TabFour from './TabFour';
import './streamflowmodal.css';
import Loader from '../../../Components/common/loader';
import { showError } from '../../../Components/Notifications.js';

const endpoint = 'https://geoglows.ecmwf.int/api/';

export const StreamflowModal = (props) => {
  const { event, modal, toggle } = props;
  const [reachId, updateReachId] = useState(null);
  const [dailyAverageData, setDailyAverageData] = useState(null);
  const [returnPeriodsData, setReturnPeriodsData] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [error, upadteError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, updateLoading] = useState(true); 
  const [isFirstLoading, setIsFirstLoading] = useState(false);

  setTimeout(() => {
    if (document.getElementsByTagName('body')) {
      if (document.getElementsByTagName('body')[0].children) {
        Object.values(document.getElementsByTagName('body')[0].children).forEach((data) => {
          if (data.toString().includes('HTMLIFrameElement')) {
            data.style = 'display: none !important;';
          }
        });
      }
    }
  }, 2000);

  useEffect(() => {
    setIsFirstLoading(true);
    if (event) {
      axios
        .get(endpoint + 'GetReachID/?lat=' + event?.lat + '&lon=' + event?.lng)
        .then((res) => {
          axios
            .get(endpoint + 'DailyAverages/?return_format=json&reach_id=' + res.data?.reach_id)
            .then((dailyAverageRes) => {
              if (res.status === 200) {
                setDailyAverageData(dailyAverageRes?.data);
                updateReachId(res.data?.reach_id);
                setCoordinates({
                  lat: event?.lat.toFixed(2),
                  lng: event?.lng.toFixed(2),
                });
              }
            });
          axios
            .get(endpoint + `ReturnPeriods?return_format=json&reach_id=${res.data?.reach_id}&lat=${event?.lat}&lon=${event?.lng}`)
            .then((returnPeriodsRes) => {
              updateLoading(false);
              setIsFirstLoading(false);
              if (res.status === 200) {
                const data = returnPeriodsRes?.data;
                let returnPeriodData = Object.keys(data.return_periods)
                  .map((key) => {
                    let yearValue = key.split('_')[2];
                    return {
                      year: Number(yearValue),
                      label: `${yearValue} Years`,
                      value: Math.trunc(data.return_periods[key]),
                    };
                  })
                  .sort((a, b) => a.year - b.year);
                setReturnPeriodsData(returnPeriodData);
              }
            })
            .catch(function (error) {
              updateLoading(false);
              setIsFirstLoading(false);
              showError("Due to server issue unable to load Return Periods, Please try again.");
            });
        })
        .catch(function (error) {
          // handle error
          updateLoading(false);
          setIsFirstLoading(false);
          upadteError(error?.response?.data?.error);
        });
    }
  }, [event]);

  const items = ['Forecasted Flow', 'Ensembles Forecast', 'Historical Flow', 'Average Flow'];

  return (
    <Modal isOpen={modal} toggle={() => toggle()} size={'lg'} className={'streamFlowModal'}>
      <ModalHeader className="weather-forecast-modal-title" toggle={() => toggle()}>
        {`Streamflow${!error && isLoading ? ' (Getting Reach ID)' : ''}`}
      </ModalHeader>
      <ModalBody className="stream-modal-body w-100">
        {!reachId && !error && <Loader />}
        {isFirstLoading && <Loader />}
        <Row style={{ marginRight: 0 }}>
          <Col lg={2} className="tab-links" style={{ marginBottom: '15px' }}>
            <Nav pills fill>
              {items.map((data, key) => (
                <NavItem
                  className={activeTab === key ? 'active' : ''}
                  style={{ cursor: 'pointer' }}
                >
                  <NavLink
                    active={activeTab === key}
                    onClick={() => setActiveTab(key)}
                    style={{ padding: '0.5rem 0.5rem' }}
                    disabled={reachId ? false : true}
                  >
                    {data}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col lg={10} className="bg-blue text-position" style={{ paddingBottom: '15px' }}>
            {reachId ? (
              <TabContent activeTab={activeTab}>
                <TabPane tabId={0}>
                  {/* {returnPeriodsData ? ( */}
                    <TabOne
                      reachId={reachId}
                      dailyAverageData={dailyAverageData}
                      returnPeriodsData={returnPeriodsData}
                      latitude={coordinates?.lat}
                      longitude={coordinates?.lng}
                      isLoading={isLoading}
                      updateLoading={updateLoading}
                    />
                  {/* ) 
                  : isFirstLoading ? "" : <span>We're sorry! Server is not responding. Please try again.</span>
                } */}
                </TabPane>
                <TabPane tabId={1}>
                  <TabTwo
                    reachId={reachId}
                    latitude={coordinates?.lat}
                    longitude={coordinates?.lng}
                  />
                </TabPane>
                <TabPane tabId={2}>
                  <TabThree
                    reachId={reachId}
                    returnPeriodsData={returnPeriodsData}
                    latitude={coordinates?.lat}
                    longitude={coordinates?.lng}
                  />
                </TabPane>
                <TabPane tabId={3}>
                  <TabFour
                    reachId={reachId}
                    dailyAverageData={dailyAverageData}
                    latitude={coordinates?.lat}
                    longitude={coordinates?.lng}
                  />
                </TabPane>
              </TabContent>
            ) : (
              error
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
