import React, { useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  useMap,
  useMapEvents,
  ZoomControl,
  GeoJSON,
  LayerGroup,
  LayersControl,
  Marker,
  Popup
} from 'react-leaflet';
import { change } from 'redux-form'
import { useHistory, useLocation } from 'react-router-dom';
import L from 'leaflet';
import 'leaflet-styleeditor';
import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.css';
import _ from 'lodash';
import 'leaflet-side-by-side';
import { Button, Col, Row } from 'reactstrap';
import 'leaflet/dist/leaflet.css';
import 'leaflet.control.opacity/dist/L.Control.Opacity.css';
import 'leaflet.control.opacity';
import 'react-splitter-layout/lib/index.css';
import '../../assets/CSS/LeafletWrapper.css';
import '../../assets/CSS/Header.css';
import myConstClass from '../../Constants';
import mapPreferenceIcon from '../../assets/Images/mapPreferenceIcon.svg';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import ReactLeafletKml from 'react-leaflet-kml';
import FarmTrackerLocationModal from './selectLocationModal';
import 'leaflet-kml';
import CustomLegend from '../WeatherForecast/CustomLayerControl/CustomLegend';
import DiscreteLegend from '../WeatherForecast/CustomLayerControl/DiscreteLegend';
import {
  getDroughtMapSuccess,
  getMapPreferenceSuccess,
  getMapPreferenceBoundariesSuccess,
  addProjectSuccess,
  addFarmTrackerRequest,
  getAllByCriteriaFarmListRequest,
  addFarmTrackerSuccess,
  getFarmDetailsByIdRequest,
  editFarmTrackerRequest,
  deleteFarmTrackerRequest,
  getSubDistrictByDistrictRequest,
  getBlockBySubDistrictRequest,
} from '../../redux/actions';
import DrawShape from './draw-shape';
import Filter from './Filter';
import FarmCollapse from './FarmCollapse';
import Loader from '../../Components/common/loader';
import MapPreferences from './MapPreferences';
import {
  DEFAULT_COUNTRY,
  FARM_TRACKER_OPTIONS,
  FILE_EXTENSION,
  MAP_PREFERENCES_lABELS,
  POLYGON_STYLING_COLORS,
  GEOJSON_COUNTRY_TOOLTIP_DATA,
  LOCATION,
  DEFAULT_ATTRIBUTES_FARM_TRACKER,
} from '../../Components/constants';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
import { showError, showSuccess } from '../../Components/Notifications';
import axios from 'axios';
import { AXIOS_INSTANCE } from '../../redux/api/apiEndPoint';
import Cookies from 'js-cookie';
import { getToken } from '../../Components/common/utils';

const FarmTrackerMap = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { Overlay } = LayersControl;
  const drawFarmRef = useRef('');
  const paramLocation = useLocation();
  const [isOpenMapModel, setIsOpenMapModel] = useState(false);
  const [map, setMap] = useState(false);
  const [stylingEditor, setStylingEditor] = useState('');
  const [isHideFarmCollapse, setIsHideFarmCollapse] = useState(true);
  const [showFarmInputFields, setShowFarmInputFields] = useState(false);
  const [isCreateFarm, setIsCreateFarm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [region, setRegion] = useState('');
  const [mapPreferRefs, setMapPreferRefs] = useState([]);
  const [isHideBar, setBar] = useState(false);
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [geoJsonAllData, setGeoJsonAllData] = useState([]);
  const [geoJsonLayersData, setGeoJsonLayerData] = useState([]);
  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [activeTab, setActiveTab] = useState('FARM_TRACKING');
  const [selectedMapPreferLegendData, setSelectedMapPreferLegendData] = useState([]);
  const mapRef = useRef(null);
  const [mapLayerOpacity, setMapLayerOpacity] = useState({ layer: '' });
  const [uploadedFiles, setUploadedFiles] = useState('');
  const [mapPreferenceGeoJsonloading, setMapPreferenceGeoJsonloading] = useState(false);
  const [selectedRegionId, setSelectedRegionId] = useState('');
  const [isUploadFarm, setIsUploadFarm] = useState(false);
  const [createFarmData, setCreateFarmData] = useState('');
  const [opacityControl, setOpacityControl] = useState('');
  const [LocationList, setLocationList] = useState('');
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isGeoTaggedImagesUpdating, setIsGeoTaggedImagesUpdating] = useState(false);
  const [geoTaggedImages, setGeoTaggedImages] = useState([]);
  const [geoTaggedImagesFiles, setGeoTaggedImagesFiles] = useState([]);
  const [mapPreferences, setMapPreferences] = useState({
    country: { layer: useRef(), color: '', data: '' },
    states_or_provinces: { layer: useRef(), color: '', data: '' },
    districts_or_cities: { layer: useRef(), color: '', data: '' },
    meteorological_locations: { layer: useRef(), color: '', data: '' },
    basins: { layer: useRef(), color: '', data: '' },
    rivers_or_reservoirs: { layer: useRef(), color: '', data: '' },
    talukas: { layer: useRef(), color: '', data: '' },
  });
  const [isPolygonClicked, setIsPolygonClicked] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    country: '',
    state: '',
    district: '',
    basin: '',
    region: '',
    markerPointData: '',
  });

  const [cordinateAndZoom, setCordinateAndZoom] = useState({
    cordinates: [7.6237, 80.6966],
    locationCordinates: [7.6237, 80.6966],
    markerCordinates: [7.6237, 80.6966],
    zoom: 8,
  });

  const [projectDetails, setProjectDetails] = useState({
    id: '',
    title: '',
    description: '',
    farmsList: [],
  });
  const [odkImages,setOdkImages] = useState('')
  let form = new FormData();

  const [farmLayersData,setFarmLayersData] = useState([]);
  const [kmlData,setKmlData] = useState('')
  const [farmOptions, setFarmOptions] = useState(FARM_TRACKER_OPTIONS);
  const [farmAttributes, setFarmAttributes] = useState([]);
  const [attributeLimit, setAttributeLimit] = useState(5);
  const [isOpenDeleteFarmModal, setIsOpenDeleteFarmModal] = useState(false);
  const [farmTitle, setFarmTitle] = useState('');
  const [farmDescription, setFarmDescription] = useState('');
  const [isRemovePolygon, setIsRemovePolygon] = useState(false);
  const [changePolygonOptions, setChangePolygonOptions] = useState(FARM_TRACKER_OPTIONS);
  const [isShowListing, setIsShowListing] = useState(false);
  const [fromListing, setFromListing] = useState(false);
  const [subDistrictList,setSubDistrictList] = useState([]);
  const [blockList,setBlockList] = useState([]);
  const [selectedSubDistrict,setSelectedSubDistrict] = useState({});
  const [selectedBlock,setSelectedBlock] = useState({});
  const [openSelectLocationModal,setOpenSelectLocationModal] = useState(false);
  const [measureData, setMeasureData] = useState('');
  const [resultantMeasureData, setResultantMeasureData] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [profileIcon, setProfileIcon] = useState('');
  const [defaultPolygonProps, setDefaultPolygonProps] = useState([]);
  const [styledPolygonOptions, setStyledPolygonOptions] = useState({ options: {} });

  const [kml, setkml] = useState({ document: '', layer: {}, area: null });
  const [updatedLatLonList, setUpdatedLatLonList] = useState([]);
  const [showMeasureOnMap, setShowMeasureOnMap] = useState(false);

  const { boundries, FarmTracker, Location } = useSelector((state) => ({
    Weather: state.Weather,
    Location: state.Location,
    DroughtMonitor: state.DroughtMonitor,
    boundries: state.Map_Preference,
    FarmTracker: state.FarmTracker,
  }));
  const [showPolygonDraw, setShowPolygonDraw] = useState(false);
  const [showStyleEditorSideBar, setShowStyleEditorSideBar] = useState(false);
  const [selectedFarmIndex, setSelectedFarmIndex] = useState('');
  const [farmId, setFarmId] = useState('');
  const [getByIdFarmInfo, setGetByIdFarmInfo] = useState({});

  const [latValues, setLatValues] = useState();
  const [lonValues, setLonValues] = useState();
  const [districtUrl, setDistrictUrl] = useState();
  const [isPolygonShow , setIsPolygonShow] = useState(true)

  //useeffect for getting map layers data from redu
  useEffect(() => {
    if (getToken()) {
    let search = window.location.search;
    let URLParams = new URLSearchParams(search);
    let latValue = URLParams.get('lat');
    let lonValue = URLParams.get('lon');
    let district = URLParams.get('district');
    if(!district || !latValue || !lonValue){
      Cookies.remove('odk-images')
    }
    setLatValues(latValue);
    setLonValues(lonValue);
    setDistrictUrl(district);
    dispatch(getDroughtMapSuccess(''));
    dispatch(getMapPreferenceSuccess(''));
    dispatch(addProjectSuccess({}));
    dispatch(addFarmTrackerSuccess({}));
    dispatch(getMapPreferenceBoundariesSuccess({}));
    setMapPreferRefs([]);
    setMapPreferencesData([]);
    return () => {
      // dispatch(addFarmTrackerSuccess({}));
      dispatch(getMapPreferenceBoundariesSuccess(''));
    };
  }
  else{
    history.push('/home')
  }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(paramLocation?.state)) {
      let {
        projectData: {
          province,
          district,
          // sub_district,
          // block,
          title,
          description,
          farmsList,
          project_id,
          odk_images
        },
        provinceList,
        districtList,
        // subDistrictList,
        // blockList,
        cordinateAndZoom: farmCordinates,
      } = paramLocation?.state;
      let obj = {
        province,
        district,
        // sub_district,
        // block,
      };
      if(odk_images?.length){
        setOdkImages(odk_images)
      }
      if (farmsList?.length) {
        setIsShowListing(true);
      }
      if (project_id) {
        dispatch(getAllByCriteriaFarmListRequest({ farm_tracker_project_id: project_id }));
      }
      setProjectDetails((prev) => {
        return {
          ...prev,
          id: project_id,
          title: title,
          description: description,
          province,
          district,
          // sub_district,
          // block,
          farmsList: farmsList,
        };
      });
      // if (!_.isEmpty(latValues)&&!_.isEmpty(lonValues)){
      //   setCordinateAndZoom((prev) => {
      //     return {
      //       ...prev,
      //       locationCordinates: [JSON.parse(latValues),JSON.parse(lonValues)],
      //       cordinates: [JSON.parse(latValues),JSON.parse(lonValues)],
      //       zoom: farmCordinates?.zoom || cordinateAndZoom?.zoom,
      //     };
      //   });
      // }else{
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            locationCordinates: farmCordinates?.locationCordinates?.length ? farmCordinates?.locationCordinates : [DEFAULT_COUNTRY.latitude,DEFAULT_COUNTRY.longitude],
            cordinates: farmCordinates?.locationCordinates?.length ? farmCordinates?.locationCordinates : [DEFAULT_COUNTRY.latitude,DEFAULT_COUNTRY.longitude],
            zoom: farmCordinates?.zoom ?  parseInt(farmCordinates?.zoom) + 2 : cordinateAndZoom?.zoom,
          };
        });
      // }
      setCreateFarmData((prev) => {
        return {
          ...prev,
          ...obj,
          zoom_level: [18],
        };
      });
      setLocationList({
        provinceList,
        districtList,
        // subDistrictList,
        // blockList,
      });
    }
  }, [paramLocation]);

  useEffect(() => {
  if (!_.isEmpty(latValues)&&!_.isEmpty(lonValues)){
    setCordinateAndZoom((prev) => {
      return {
        ...prev,
        locationCordinates: [JSON.parse(latValues),JSON.parse(lonValues)],
        cordinates: [JSON.parse(latValues),JSON.parse(lonValues)],
        zoom: 14
      };
    });
  }
}, [latValues,lonValues]);

  // //initially selecting first farm
  // useEffect(() => {
  //   if (farmId !== '') {
  //     setFromListing(true);
  //     setSelectedFarmIndex(0);
  //     const longitude = farmId?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[0];
  //     const latitude = farmId?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[1];
  //     // if (farmId?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.length !== 0) {
  //       setCordinateAndZoom(prev => {
  //         return {
  //           ...prev,
  //           locationCordinates: [latitude,longitude],
  //           cordinates: [latitude, longitude],
  //           zoom: 18
  //         }
  //       })
  //     // }
  //     dispatch(getFarmDetailsByIdRequest({ id: farmId?.id }));
  //   }
  // }, [farmId]);

  useEffect(() => {
    let arr = [];
    if (Location.subDistrictList) {
      let result = '';
      let config = Location?.subDistrictList?.config;
      if (Location?.subDistrictList?.data) result = Location?.subDistrictList?.data?.result;
      if (result) {
        result.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        setSubDistrictList(arr);
        setLocationList(prev => {
          return {
            ...prev,
            subDistrictList: arr
          }
        });
      }
    }
  }, [Location.subDistrictList]);

  useEffect(() => {
    let arr = [];
    if (Location.blockList) {
      let result = '';
      let config = Location?.blockList?.config;
      if (Location?.blockList?.data) result = Location?.blockList?.data?.result;
      if (result) {
        result.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        setBlockList(arr);
        setLocationList(prev => {
          return {
            ...prev,
            blockList: arr
          }
        });
      }
    }
  }, [Location.blockList]);

  useEffect(() => {
    if (FarmTracker.getAllByCriteriaRequesting) {
      setIsLoading(true);
    } else if (FarmTracker?.getAllByCriteriaData) {
      setIsLoading(false);
      if (FarmTracker?.getAllByCriteriaData?.data) {
        let response = FarmTracker?.getAllByCriteriaData?.data;
        if (response?.result) {
          // if (response?.result?.results?.length) {
          //   setFarmId(response?.result?.results[0]);
          // }
          setProjectDetails((prev) => {
            if (prev.farmsList?.length && response?.result?.results?.length) {
              return {
                ...prev,
                farmsList: [...prev.farmsList, ...response?.result?.results],
              };
            } else {
              return {
                ...prev,
                farmsList: response?.result?.results,
              };
            }
          });
        }
      }
    } else if (FarmTracker?.getAllByCriteriaDataError) {
      if (FarmTracker?.getAllByCriteriaDataError?.data) {
        let errorData = FarmTracker?.getAllByCriteriaDataError?.data;
        let data = errorData?.response?.data;
        if (data?.message && data?.code === 400) {
          showError(data?.message);
        }
        setIsLoading(false);
        setProjectDetails((prev) => {
          return {
            ...prev,
            farmsList: [],
          };
        });
      }
    }
  }, [FarmTracker.getAllByCriteriaData, FarmTracker.getAllByCriteriaDataError]);

  useEffect(()=>{
    if(projectDetails.farmsList?.length){
      let arr =[];
      projectDetails.farmsList?.map(item => {
        let obj= {};
        let farmCordinates = item?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0];
        obj.styles = {};
        obj.data = item?.draw_farm;
        obj.name = item?.name;
        obj.id = item.id;
        if(item?.polygon_style){
          if(typeof item?.polygon_style === "string"){
            if(isJsonString(item?.polygon_style)){
              obj.styles = JSON.parse(item?.polygon_style)
            }
          }
          else{
            obj.styles = item?.polygon_style
          }
        }
        let latlngPoints = farmCordinates?.map((dataPoint) => {
          // console.log(dataPoint); // It might be useful to inspect these values
          return L.latLng(dataPoint?.[1], dataPoint?.[0]);
        });
        let area = latlngPoints?.length ? L.GeometryUtil?.geodesicArea(latlngPoints) : '';
        obj.area = (area * 0.0001)?.toFixed(2); // converting square metres into hectares
        arr.push(obj);
      })
      if(arr.length){
        setFarmLayersData(arr);
      }
    }
  },[projectDetails.farmsList,isHideFarmCollapse])

  useEffect(() => {
    if (FarmTracker.deleteFarmTrackerRequesting) {
      setIsLoading(true);
    } else if (FarmTracker.deleteFarmTrackerData) {
      setIsLoading(false);
      if (FarmTracker.deleteFarmTrackerData.data) {
        let data = FarmTracker.deleteFarmTrackerData.data;
        showSuccess(data?.result);
        setIsOpenDeleteFarmModal(false);
        // if(farmLayersData?.length){
        //   let updatedData = farmLayersData?.map(item =>  {
        //     if(item.data){ 
        //       return {...item, data: { ...item.data, features: ''}}
        //     }
        //     else return item;
        //   })

        //   if(updatedData) setFarmLayersData(updatedData)
        // }
        setProjectDetails(prev => {
          return{
            ...prev,
            farmsList:[]
          }
        })
        setFarmLayersData([])

        if(!_.isEmpty(kmlData)) map.removeLayer(kmlData)
        setKmlData('')
        setFromListing(false);
        setShowPolygonDraw(false);
        setIsHideFarmCollapse(true);
        setCreateFarmData((prev) =>{
          return {
            ...prev,
            sub_district:'',
            block:''
          }
        })
        dispatch(getAllByCriteriaFarmListRequest({ farm_tracker_project_id: projectDetails?.id }));
      }
    } else if (FarmTracker.deleteFarmTrackerDataError) {
      if (FarmTracker?.deleteFarmTrackerDataError?.data) {
        let {
          response: { data },
        } = FarmTracker?.deleteFarmTrackerDataError?.data;
        if (data?.message && data?.code === 400) {
          showError(data?.message);
        }
        setIsLoading(false);
        dispatch(getAllByCriteriaFarmListRequest({ farm_tracker_project_id: projectDetails?.id }));
      }
    }
  }, [
    FarmTracker.deleteFarmTrackerRequesting,
    FarmTracker.deleteFarmTrackerData,
    FarmTracker.deleteFarmTrackerDataError,
  ]);

  useEffect(() => {
    if (FarmTracker.addFarmTrackerRequesting) {
      setIsLoading(true);
    } else if (FarmTracker.addFarmTrackerData) {
      if (FarmTracker?.addFarmTrackerData?.data) {
        let successData = FarmTracker?.addFarmTrackerData?.data;
        showSuccess(successData?.result);
        // if(farmLayersData?.length){
        //   let updatedData = farmLayersData?.map(item =>  {
        //     if(item.data){ 
        //       return {...item, data:{ ...item.data, features: ''}}
        //     }
        //     else return item;
        //   })
        //   if(updatedData) setFarmLayersData(updatedData)
        // }
        setProjectDetails(prev => {
          return{
            ...prev,
            farmsList:[]
          }
        })
        setFarmLayersData([])

        Cookies.remove('odk-images');
        setSelectedSubDistrict('');
        setSelectedBlock('')
        if(!_.isEmpty(kmlData)) map.removeLayer(kmlData)
        setKmlData('')
        setCreateFarmData((prev) =>{
          return {
            ...prev,
            sub_district:'',
            block:''
          }
        })
        setOdkImages([])
        setIsLoading(false);
        setShowPolygonDraw(false);
        dispatch(getAllByCriteriaFarmListRequest({ farm_tracker_project_id: projectDetails?.id }));
      }
    } else if (FarmTracker.addFarmTrackerDataError) {
      if (FarmTracker?.addFarmTrackerDataError?.data) {
        let resData = FarmTracker?.addFarmTrackerDataError?.data;
        if (resData?.response?.data) {
          if (resData?.response?.data?.message && resData?.response?.data?.code === 400) {
            removeDrawnFarm(drawFarmRef);
            if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
            if(!_.isEmpty(kmlData)) map.removeLayer(kmlData)
            setKmlData('')
            setkml({ document: '', layer: {}, area: null });
            setUploadedFiles('');
            setIsShowListing(true);
            showError(resData?.response?.data?.message);
          }
          if (resData?.response?.data?.exception) {
            showError(resData?.response?.data?.exception);
          }
        }
        setIsLoading(false);
        dispatch(getAllByCriteriaFarmListRequest({ farm_tracker_project_id: projectDetails?.id }));
      }
    }
  }, [
    FarmTracker.addFarmTrackerRequesting,
    FarmTracker.addFarmTrackerData,
    FarmTracker.addFarmTrackerDataError,
  ]);

  useEffect(() => {
    if (FarmTracker.editFarmTrackerRequesting) {
      setIsLoading(true);
    } else if (FarmTracker.editFarmTrackerData) {
      if (FarmTracker?.editFarmTrackerData?.data) {
        let successData = FarmTracker?.editFarmTrackerData?.data;
        showSuccess(successData?.result);
        setIsLoading(false);
        // if(farmLayersData?.length){
        //   let updatedData = farmLayersData?.map(item =>  {
        //     if(item.data){ 
        //       return {...item, data: {...item.data, features:''}}
        //     }
        //     else return item;
        //   })
        //   if(updatedData) setFarmLayersData(updatedData)
        // }
        setProjectDetails(prev => {
          return{
            ...prev,
            farmsList:[]
          }
        })
        setFarmLayersData([])
        setChangePolygonOptions(FARM_TRACKER_OPTIONS);
        setIsUploadFarm(false);
        setIsImageUploaded(false);
        setShowPolygonDraw(false);
        setIsShowListing(true);
        if(!_.isEmpty(kmlData)) map.removeLayer(kmlData)
        setKmlData('')
        setCreateFarmData((prev) =>{
          return {
            ...prev,
            sub_district:'',
            block:''
          }
        })
        dispatch(getAllByCriteriaFarmListRequest({ farm_tracker_project_id: projectDetails?.id }));
      }
    } else if (FarmTracker.editFarmTrackerDataError) {
      if (FarmTracker?.editFarmTrackerDataError?.data) {
        let { response } = FarmTracker?.editFarmTrackerDataError?.data;
        if (response?.data?.message && response?.data?.code === 400) {
          removeDrawnFarm(drawFarmRef);
          if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
          if(!_.isEmpty(kmlData)) map.removeLayer(kmlData)
          setKmlData('')
          setkml({ document: '', layer: {}, area: null });
          setUploadedFiles('');
          setIsShowListing(true);
          showError(response?.data?.message);
        }
        if (response?.data?.exception) {
          showError(response?.data?.exception);
        }
        setIsLoading(false);
        dispatch(getAllByCriteriaFarmListRequest({ farm_tracker_project_id: projectDetails?.id }));
      }
    }
  }, [
    FarmTracker.editFarmTrackerRequesting,
    FarmTracker.editFarmTrackerData,
    FarmTracker.editFarmTrackerDataError,
  ]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          let data = JSON.parse(JSON.stringify(item.data));
          // if (item.config.name !== MAP_PREFERENCES_lABELS.DISTRICTS && item.config.name !== MAP_PREFERENCES_lABELS.TALUKAS) {
          selectedLayer.country = DEFAULT_COUNTRY;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(data);
          selectedLayer.color = item.config.color;
          setMapPreferences((prev) => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
        // }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (FarmTracker?.getFarmDetailsByIdRequesting) {
      // setIsLoading(true);
    } else if (FarmTracker?.getFarmDetailsByIdData) {
      if (FarmTracker?.getFarmDetailsByIdData?.data) {
        setIsLoading(false);
        let data = FarmTracker?.getFarmDetailsByIdData?.data;
        if (data?.code === 200) {
          let {
            result: { farm, farm_attributes, geotag_images },
          } = data;
          setGetByIdFarmInfo(data?.result);  
          setSelectedBlock({ id: farm[0]?.block_id, label: farm[0]?.block_name })
          setSelectedSubDistrict({ id:farm[0]?.sub_district_id, label:farm[0]?.sub_district_name })
          setCreateFarmData(prev=>{
            return {
              ...prev,
              sub_district: { id:farm[0]?.sub_district_id, label:farm[0]?.sub_district_name },
              block:{ id: farm[0]?.block_id, label: farm[0]?.block_name }
            }
           })
          if (!farm[0]?.is_upload) {
            if (drawFarmRef && drawFarmRef?.current) {
              let data = !_.isEmpty(farm[0]?.draw_farm) ? farm[0]?.draw_farm : '';
              if (data) {
                const longitude = data?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[0];
                const latitude = data?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[1];
                // map.flyTo([latitude, longitude],18,{
                //   animate: true,
                //   duration: 2 // in seconds
                // })
                const selectedGeojson = L.geoJSON(farm?.[0]?.draw_farm)
                if(selectedGeojson && !_.isEmpty(map))
                map?.fitBounds(selectedGeojson?.getBounds(), { padding: [150,150]})
                // setCordinateAndZoom(prev => {
                //   return {
                //     ...prev,
                //     zoom: 18
                //   }
                // })
              }
            }
            if (!_.isEmpty(farm[0]?.polygon_style)) {
              let styleObj;
              if(typeof farm[0]?.polygon_style === "string"){
                if(isJsonString(farm[0]?.polygon_style)){
                  styleObj = JSON.parse(farm[0]?.polygon_style)
                }
              }
              else{
                styleObj = farm[0]?.polygon_style
              }
              setStyledPolygonOptions({ options: styleObj });
            } else {
              setStyledPolygonOptions({ options: {} });
            }
          } else {
            let Kml_Api_url = farm[0]?.upload_file;
            axios
              .get(Kml_Api_url)
              .then((res) => {
                if (res && res?.data) {
                  let parser = new DOMParser();
                  let xmlDoc = parser.parseFromString(res?.data, 'text/xml');
                  const kmlData = new L.KML(xmlDoc);
                  let center = null;
                  if (!_.isEmpty(kmlData)) {
                    setCordinateAndZoom(prev => {
                      return {
                        ...prev,
                        zoom: 18
                      }
                    })
                    // map?.addLayer(kmlData);
                    // // Adjust map to show the kml
                    // const bounds = kmlData.getBounds();
                    // map.fitBounds(bounds);
                    kmlData?.eachLayer((mapLayer) => {
                      center = mapLayer.getBounds().getCenter();
                      map.fitBounds(mapLayer.getBounds(), { padding: [100,100]});
                      // map.flyTo([center?.lat, center?.lng],18,{
                      //   animate: true,
                      //   duration: 2, // in seconds
                      // })

                      // handleCordinates(
                      //   [
                      //     center?.lat ? center?.lat : cordinateAndZoom?.locationCordinates[0],
                      //     center?.lng ? center?.lng : cordinateAndZoom?.locationCordinates[1],
                      //   ],
                      //   18,
                      // );
                    });
                    setkml({ document: xmlDoc, layer: kmlData });
                  }
                }
              })

              .catch((err) => {
                console.log('error');
              });
          }
          if (!fromListing) {
            if (farm_attributes?.length) {
              let updatedArr = farm_attributes.filter(
                (val) => val.name === DEFAULT_ATTRIBUTES_FARM_TRACKER.AREA || val.name === DEFAULT_ATTRIBUTES_FARM_TRACKER.LONGITUDE || val.name === DEFAULT_ATTRIBUTES_FARM_TRACKER.LATITUDE,
              );
              if (updatedArr) {
                let defaultProps = updatedArr?.map(item => {
                  return {
                    ...item,
                    value: parseFloat(item.value).toFixed(2)
                  }
                })
                if(defaultProps)
                setDefaultPolygonProps(defaultProps);
              }
            }
            let geoTaggedUrlArr = [];
            geotag_images?.map((item) => {
              let arr = item?.geo_tagged_images?.split('/');
              let name = arr[arr.length - 1];
              geoTaggedUrlArr.push({ name: name, url: item?.geo_tagged_images });
            });

            var promises = geotag_images?.map((item) => {
              return convertImagePathToFile(item?.geo_tagged_images)?.then((res) => {
                if (res) {
                  return res;
                }
              });
            });
            Promise.all(promises).then((response) => setGeoTaggedImages(response));
            if (geoTaggedUrlArr?.length) {
              setGeoTaggedImagesFiles(geoTaggedUrlArr);
            }
            setFarmAttributes(farm_attributes);
            setIsGeoTaggedImagesUpdating(false);
            setFarmTitle(farm[0]?.name);
            setFarmDescription(farm[0]?.description);
            setIsCreateFarm(false);
            setIsUploadFarm(farm[0]?.is_upload);
            setIsHideFarmCollapse(false);
            setShowFarmInputFields(true);
            setIsPolygonClicked(true);
            setProfileIcon(farm[0]?.profile_icon);
            setImageFile(farm[0]?.profile_icon);
            setCreateFarmData((prev) => {
              return {
                ...prev,
                sub_district: { id:farm[0]?.sub_district_id, label:farm[0]?.sub_district_name },
                block:{ id: farm[0]?.block_id, label: farm[0]?.block_name },
                farmData: farm[0],
              };
            });
          }
        }
      }
    } else if (FarmTracker?.getFarmDetailsByIdDataError) {
      if (FarmTracker?.getFarmDetailsByIdDataError?.data) {
        let errorData = FarmTracker?.getFarmDetailsByIdDataError?.data;
        let data = errorData?.response?.data;
        if (data?.message && data?.code === 400) {
          showError(data?.message);
        }
        setIsLoading(false);
      }
    }
  }, [
    FarmTracker?.getFarmDetailsByIdRequesting,
    FarmTracker?.getFarmDetailsByIdData,
    FarmTracker?.getFarmDetailsByIdDataError,
  ]);

  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (boundries?.mapPreferenceData) {
      setGetBoundariesData(boundries?.mapPreferenceData?.data?.result);
    }
  }, [boundries?.mapPreferenceData]);

  useEffect(() => {
    if (getBoundariesData && getBoundariesData?.length) {
      let API_URL = [];
      mapPreferRefs.current = [];
      // setIsLoading(true);
      getBoundariesData?.map((item) => {
        if (item?.data && item?.data.length) {
          item.data.map((boundary) => {
            if (boundary?.json_url?.length) {
              setGeoJsonData([]);
              setGeoJsonAllData([]);
              let mapPreferKeys = Object.keys(mapPreferences);
              if (mapPreferKeys) {
                mapPreferKeys.map((item) => {
                  let selectedLayer = mapPreferences[item];
                  if (selectedLayer?.layer && selectedLayer?.layer?.current)
                    selectedLayer?.layer?.current?.clearLayers();
                  selectedLayer.color = '';
                  setMapPreferences((prev) => {
                    return {
                      ...prev,
                      [item]: selectedLayer,
                    };
                  });
                });
              }
              setMapPreferenceGeoJsonloading(true);
              let config = {
                name: boundary?.slug,
                id: boundary?.id,
                color: boundary?.color_code,
                category: boundary?.map_preference_category?.name,
                country_id: DEFAULT_COUNTRY.id,
              };
              API_URL.push(AXIOS_INSTANCE.get(boundary?.json_url, config));
            } else {
              let ref = React.createRef();
              ref.current = {
                boundary_id: boundary?.id,
                layer: React.createRef(),
              };
              mapPreferRefs.current.push(ref);
            }
          });
        }
      });
      Promise.all(API_URL).then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        setMapPreferenceGeoJsonloading(false);
        setGeoJsonLayerData(res);
        // setIsLoading(false);
      });
    }
  }, [getBoundariesData]);

  useEffect(() => {
    let selectedGeoJsonData = [];
    if (geoJsonLayersData?.length && geoJsonData?.length) {
      geoJsonData?.map((item) => {
        let filteredObj = geoJsonLayersData?.find(
          (layer) => layer.config.id === item.id && layer.config.country_id === item.country_id,
        );
        if (!_.isEmpty(filteredObj)) {
          selectedGeoJsonData.push(filteredObj);
        }
      });
    } else {
      selectedGeoJsonData = [];
    }
    setGeoJsonAllData(selectedGeoJsonData);
  }, [geoJsonLayersData, geoJsonData]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      let MapPrefereArr = [];
      mapPreferencesData?.map((item) => {
        let legendItem = JSON.parse(JSON.stringify(item.result));
        legendItem.id = item?.dataSource;
        // legendItem.parameter =
        //   item.slug === MAP_PREFERENCES_lABELS.JRC
        //     ? t('WEATHER_FORECAST.MAP_PREFERENCES.WATER_OCCURRENCE')
        //     : item.slug === MAP_PREFERENCES_lABELS.MOD44
        //     ? t('WEATHER_FORECAST.MAP_PREFERENCES.PERMANENT_WATER')
        //     : item?.category;
        legendItem.dataSource =
          item.slug === MAP_PREFERENCES_lABELS.JRC
            ? t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.WATER_OCCURRENCE')
            : item.slug === MAP_PREFERENCES_lABELS.MOD44
            ? t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.PERMANENT_WATER')
            : item?.category;
        // legendItem.dataSource = item?.dataSource;
        legendItem.parameter = item?.legendName;
        legendItem.add = true;
        legendItem.measure = legendItem.units;
        legendItem.isCompare = false;
        legendItem.legendId = item.id;
        if (selectedMapPreferLegendData?.length) {
          let isDuplicate = selectedMapPreferLegendData?.find(
            (x) => x.legendId === item.id && item.add === true,
          );
          if (_.isEmpty(isDuplicate)) {
            MapPrefereArr.push(legendItem);
          }
        } else {
          MapPrefereArr.push(legendItem);
        }
        // let legendItem = JSON.parse(JSON.stringify(item.result));
        // legendItem.parameter =
        //   item.slug === MAP_PREFERENCES_lABELS.JRC
        //     ? t('WEATHER_FORECAST.MAP_PREFERENCES.WATER_OCCURRENCE')
        //     : item.slug === MAP_PREFERENCES_lABELS.MOD44
        //     ? t('WEATHER_FORECAST.MAP_PREFERENCES.PERMANENT_WATER')
        //     : item?.category;
        // legendItem.dataSource = item?.dataSource;
        // legendItem.add = true;
        // legendItem.measure = legendItem.units;
        // legendItem.id = item?.dataSource;
        // legendItem.legendId = item.id;
        // if (selectedMapPreferLegendData?.length) {
        //   let isDuplicate = selectedMapPreferLegendData?.find(
        //     (x) => x.legendId === item.id && item.add === true,
        //   );
        //   if (_.isEmpty(isDuplicate)) {
        //     MapPrefereArr.push(legendItem);
        //   }
        // } else {
        //   MapPrefereArr.push(legendItem);
        // }
      });
      setSelectedMapPreferLegendData(MapPrefereArr);
    }
  }, [mapPreferencesData]);

  useEffect(() => {
    if (
      !_.isEmpty(mapLayerOpacity) &&
      !_.isEmpty(mapLayerOpacity?.layer) &&
      !_.isEmpty(mapPreferencesData)
    ) {
      if (!_.isEmpty(opacityControl) && !_.isEmpty(mapPreferencesData))
        map?.removeControl(opacityControl);
      let customOpacityControl = '';
      customOpacityControl = L?.control?.opacity(mapLayerOpacity, {
        collapsed: false,
        position: 'topright',
      });
      setOpacityControl(customOpacityControl);
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) {
        if (!_.isEmpty(customOpacityControl)) {
          customOpacityControl?.addTo(map);
        }
      }
    } else if (!_.isEmpty(mapLayerOpacity) && !_.isEmpty(mapLayerOpacity?.layer)) {
      // console.log("test5")
      // let customOpacityControl = '';
      // customOpacityControl = L?.control?.opacity(mapLayerOpacity, {
      //   collapsed: true,
      //   position: 'topright',
      // });
      // setOpacityControl(customOpacityControl);
      // if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) {
      //   if (!_.isEmpty(customOpacityControl)) {
      //     customOpacityControl?.remove(map);
      //   }
      // }

      // let    document.getElementsByClassName("leaflet-control-layers-expanded")
      const StyleleafletEle1 = document.getElementsByClassName('leaflet-control-layers-expanded');
      if (StyleleafletEle1?.length) {
        //  StyleleafletEle1[0].style.visibility = 'hidden';
        StyleleafletEle1[0].style.display = 'none';
        StyleleafletEle1[0].classList.add('leaflet-styleeditor-disable');
      }
    }
  }, [mapLayerOpacity, mapPreferencesData]);

  useEffect(() => {
    if (mapPreferencesData.length >= 1) {
      let transperenacy = document.getElementsByClassName('leaflet-control-layers-expanded');
    }
  }, [mapPreferencesData]);

  const returnFilteredGeoJsonData = (
    selectedLayer,
    item,
    location,
    propertyName,
    locationName,
    countryData,
  ) => {
    if (selectedLayer?.layer && selectedLayer?.layer?.current) {
      let data = JSON.parse(JSON.stringify(item.data));
      let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
        (item) => item.selectedCountry === selectedLocation?.country?.name,
      );
      let labelprop = selectedCountryData ? selectedCountryData[propertyName] : '';
      if (item.config.name === locationName && !_.isEmpty(location)) {
        data.features = item.data?.features.filter((x) => {
          if (_.startCase(_.lowerCase(x?.properties[labelprop])) === location?.name) {
            selectedLayer.country = selectedLocation?.country;
            selectedLayer?.layer?.current?.clearLayers();
            return x;
          }
        });
        selectedLayer.layer.current.addData(data);
        selectedLayer.color = item.config.color;
      }
      return selectedLayer;
    }
  };
  // useEffect(() => {
  //   if (geoJsonAllData?.length) {
  //     geoJsonAllData.map((item) => {
  //       let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
  //       let selectedLayer = mapPreferences[mapGeojsonItem];
  //       if (selectedLayer?.layer && selectedLayer?.layer?.current) {
  //         if (
  //           !_.isEmpty(selectedLocation?.district) ||
  //           selectedLocation?.district?.name !== 'select'
  //         ) {
  //           let filteredLayer = returnFilteredGeoJsonData(
  //             selectedLayer,
  //             item,
  //             selectedLocation?.district,
  //             MAP_PREFERENCES_lABELS.DISTRICTS,
  //             MAP_PREFERENCES_lABELS.TALUKAS,
  //             selectedLocation?.country,
  //           );
  //           if (filteredLayer) {
  //             setMapPreferences((prev) => {
  //               return {
  //                 ...prev,
  //                 [mapGeojsonItem]: filteredLayer,
  //               };
  //             });
  //           }
  //         }
  //         if (!_.isEmpty(selectedLocation?.state) || selectedLocation?.state?.name !== 'select') {
  //           let filteredLayer = returnFilteredGeoJsonData(
  //             selectedLayer,
  //             item,
  //             selectedLocation?.state,
  //             MAP_PREFERENCES_lABELS.STATES,
  //             MAP_PREFERENCES_lABELS.DISTRICTS,
  //             selectedLocation?.country,
  //           );
  //           if (filteredLayer) {
  //             setMapPreferences((prev) => {
  //               return {
  //                 ...prev,
  //                 [mapGeojsonItem]: filteredLayer,
  //               };
  //             });
  //           }
  //         }
  //       }
  //     });
  //   }
  // }, [geoJsonAllData]);

  // useEffect(() => {
  //   if (geoJsonAllData?.length) {
  //     geoJsonAllData.map((item) => {
  //       let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
  //       let selectedLayer = mapPreferences[mapGeojsonItem];
  //       if (
  //         selectedLayer?.layer &&
  //         selectedLayer?.layer?.current &&
  //         item.fromMapPreferences &&
  //         (_.isEmpty(selectedLocation?.state) || selectedLocation?.state === '') &&
  //         item.config.name === MAP_PREFERENCES_lABELS.DISTRICTS
  //       ) {
  //         selectedLayer.country = selectedLocation?.country;
  //         selectedLayer?.layer?.current?.clearLayers();
  //         selectedLayer.layer.current.addData(item.data);
  //         selectedLayer.color = item.config.color;
  //         setMapPreferences((prev) => {
  //           return {
  //             ...prev,
  //             [mapGeojsonItem]: selectedLayer,
  //           };
  //         });
  //       }
  //     });
  //   }
  // }, [geoJsonAllData]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      if (mapPreferRefs?.current?.length) {
        mapPreferencesData?.map((item) => {
          let selectedMapPreferences = mapPreferRefs.current.find(
            (x) => x.current.boundary_id === item.id,
          );
          let currentParamRef = selectedMapPreferences.current?.layer;
          currentParamRef?.current?.eachLayer((mapLayer) => {
            mapLayer.setUrl(item?.result?.map_url);
            setMapLayerOpacity({ layer: mapLayer });
          });
          if (currentParamRef && currentParamRef?.current) {
            addLayers(currentParamRef);
          }
        });
      }
    }
  }, [mapPreferencesData, mapPreferRefs.current]);

  //   useEffect(()=> {
  // if (mapPreferencesData?.length===0) {
  //   setMapLayerOpacity({layer:""})
  // }
  //   } , [mapPreferencesData])

  useEffect(() => {
    if (region?.length) {
      let data = region[region.length - 1];
      if (data?.layerType === 'polygon') {
        setIsPolygonClicked(true);
      }
      //for removing description in style-editor
      const styleEditorDescriptionELe = document.getElementsByClassName(
        'leaflet-styleeditor-uiElement',
      );
      if (styleEditorDescriptionELe?.length) {
        // styleEditorDescriptionELe[styleEditorDescriptionELe?.length - 1].style.visibility = 'hidden';
        styleEditorDescriptionELe[styleEditorDescriptionELe?.length - 1].style.display = 'none';
      }
      //for removing style-editor when user selcts other than polygon
      const styleEditorOptionsEle = document.getElementsByClassName('editor-enabled');
      if (styleEditorOptionsEle?.length && showStyleEditorSideBar) {
        styleEditorOptionsEle[0]?.classList.add('disable-style-editor');
      } else {
        styleEditorOptionsEle[0]?.classList.remove('disable-style-editor');
      }

      const StyleLeafletToolTipEle = document.getElementsByClassName(
        'leaflet-styleeditor-tooltip-wrapper',
      );
      if (StyleLeafletToolTipEle?.length) {
        // StyleLeafletToolTipEle[0].style.visibility = 'visible';
        StyleLeafletToolTipEle[0].style.display = 'block';
      }
      const StyleleafletEle = document.getElementsByClassName('leaflet-control-styleeditor');
      if (StyleleafletEle?.length) {
        StyleleafletEle[0].classList.remove('leaflet-styleeditor-disable');
        // StyleleafletEle[0].style.visibility = 'visible';
        StyleleafletEle[0].style.display = 'block';
      }
    } else {
      const StyleleafletEle = document.getElementsByClassName('leaflet-control-styleeditor');
      const StyleLeafletToolTipEle = document.getElementsByClassName(
        'leaflet-styleeditor-tooltip-wrapper',
      );
      if (StyleLeafletToolTipEle?.length) {
        // StyleLeafletToolTipEle[0].style.visibility = 'hidden';
        StyleLeafletToolTipEle[0].style.display = 'none';
      }
      if (StyleleafletEle?.length) {
        // StyleleafletEle[0].style.visibility = 'hidden';
        StyleleafletEle[0].style.display = 'none';
        StyleleafletEle[0].classList.add('leaflet-styleeditor-disable');
      }
    }
    let style1 =  document.getElementsByClassName("leaflet-styleeditor-button styleeditor-nextBtn")
    if (style1?.length) {
      style1[0].title=""
      
    }
    let style2 =  document.getElementsByClassName("leaflet-styleeditor")
    // console.log(style1?.[0]?.title)
    if (style2.length) {
      style2[0].style.marginRight="11px"
      
    }
  }, [region, stylingEditor, showStyleEditorSideBar]);

  useEffect(() => {
    if (region?.length || !_.isEmpty(drawFarmRef?.current?._layers)) {
      let data = region[region.length - 1];
      if (data?.layerType === 'polygon') {
        if (farmOptions?.some((i) => i.id === t('FARM_TRACKER.DRAW_FARM_KEY') && i.selected)) {
          setShowFarmInputFields(true);
          setIsHideFarmCollapse(false);
          setIsUploadFarm(false);
          if (projectDetails?.farmsList?.length) {
            setIsShowListing(true);
          } else {
            setIsShowListing(false);
          }
        } else if (
          farmOptions?.some((i) => i.id === t('FARM_TRACKER.UPLOAD_FARM_KEY') && i.selected)
        ) {
          setFarmAttributes(FARM_TRACKER_OPTIONS);
          setIsUploadFarm(true);
          setShowFarmInputFields(false);
          setIsHideFarmCollapse(true);
          setIsShowListing(false);
        }
      }
    } else if (!_.isEmpty(kml?.document) && !fromListing) {
      setShowFarmInputFields(true);
      setIsUploadFarm(true);
      setIsHideFarmCollapse(false);
      setIsShowListing(true);
    }
  }, [region, farmOptions, projectDetails, kml, fromListing]);

  useEffect(()=>{
    if(projectDetails?.district?.id){
      let requestObj = { location_type: LOCATION.Sub_District, parent_id: projectDetails?.district?.district_id || projectDetails?.district?.id };
      dispatch(getSubDistrictByDistrictRequest({ requestObj, isCompareLocation: false }));
    }
  },[projectDetails?.district]);

  useEffect(() => {
    if (farmOptions?.some((i) => i.selected) && changePolygonOptions?.every((i) => !i.selected)) {
      handleResetState();
    } else if (
      farmOptions?.some((i) => i.selected) &&
      changePolygonOptions?.some((i) => i.selected)
    ) {
      removeDrawnFarm(drawFarmRef);
      if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
      if(!_.isEmpty(kmlData)) map.removeLayer(kmlData);
      setKmlData('')
      setkml({ document: '', layer: {}, area: null });
      setUploadedFiles('');
    }
  }, [farmOptions, changePolygonOptions]);

  const handleEmptyKmlFile = () => {
    setkml({ document: '', layer: {}, area: null });
    if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
    if(!_.isEmpty(kmlData)) map.removeLayer(kmlData)
    setKmlData('')
    setUploadedFiles('');
    setIsShowListing(true);
    setIsHideFarmCollapse(!props.isHideFarmCollapse);
  };

  useEffect(() => {
    if (region?.length) {
      let data = region[region.length - 1];
      if (data?.layerType === 'polygon') {
        let center = data?.center;
        if (center?.length) {
          handleAddMoreAttributes(
            // { name: 'Latitude', value: center[0]?.toFixed(2) },
            { name: DEFAULT_ATTRIBUTES_FARM_TRACKER.LATITUDE, value: center[0] },
            { isDefaultProps: true },
          );
          handleAddMoreAttributes(
            // { name: 'Longitude', value: center[1]?.toFixed(2) },
            { name: DEFAULT_ATTRIBUTES_FARM_TRACKER.LONGITUDE, value: center[1] },
            { isDefaultProps: true },
          );
          handleAddMoreAttributes(
            { name: DEFAULT_ATTRIBUTES_FARM_TRACKER.AREA, value: data?.area?.toFixed(2) },
            { isDefaultProps: true },
          );
        }
      }
    }
  }, [region]);

  useEffect(() => {
    if (isHideFarmCollapse) {
      if (!isCreateFarm) {
        setIsCreateFarm(true);
        setFarmTitle('');
        setProfileIcon('');
        setImageFile('');
        setGeoTaggedImages([]);
        setGeoTaggedImagesFiles([]);
        setIsGeoTaggedImagesUpdating(false);
        setFarmDescription('');
        setFarmAttributes([]);
        setDefaultPolygonProps([])
      }
    }
  }, [isHideFarmCollapse]);

  useEffect(() => {
    if (isHideFarmCollapse || changePolygonOptions?.some((i) => i.selected === true)) {
      removeDrawnFarm(drawFarmRef);
      if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
      if(!_.isEmpty(kmlData)) map.removeLayer(kmlData)
      setKmlData('')
      setkml({ document: '', layer: {}, area: null });
      setUploadedFiles('');
    }
  }, [isHideFarmCollapse, changePolygonOptions]);

  useEffect(() => {
    if (isHideBar) {
      setIsHideFarmCollapse(true);
    }
  }, [isHideBar]);

  useEffect(() => {
    if (changePolygonOptions?.some((item) => item.id === 'upload_farm' && item.selected)) {
      let data = [...farmOptions];
      let updatedData = data?.map((item) =>
        item.id === 'upload_farm' ? { ...item, selected: true } : { ...item, selected: false },
      );
      setFarmOptions(updatedData);
      setIsUploadFarm(true);
    } else {
      setIsUploadFarm(false);
    }

    if (changePolygonOptions?.some((i) => i.selected)) {
      let polygonData = defaultPolygonProps;
      let data = JSON.parse(JSON.stringify(farmAttributes));
      let updatedData = data?.filter((val) => {
        let dataExist = polygonData?.find(
          (item) => item?.name === val?.name && item?.value === val?.value,
        );
        if (!dataExist) return val;
      });
      setFarmAttributes([]);
      setDefaultPolygonProps([])
      // setFarmAttributes(updatedData);
    }
  }, [changePolygonOptions]);

  useEffect(() => {
    if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) {
      //Initialize the StyleEditor
      if (!_.isEmpty(stylingEditor)) {
        map?.removeControl(stylingEditor);
      }
      const styleEditorOnMap = L.control.styleEditor({
        position: 'topright',
        colorRamp: POLYGON_STYLING_COLORS,
        openOnLeafletDraw: false,
        markers: ['circle-stroked', 'circle', 'square-stroked', 'square'],
        useGrouping: false,
        // showTooltip:false,
        // strings:false
      });
      // map.addControl(styleEditor)
      if (styleEditorOnMap) {
        styleEditorOnMap.addTo(map);
        // map.addControl(styleEditorOnMap);
        setStylingEditor(styleEditorOnMap);
      }
    }
  }, [map, region]);

  useEffect(() => {
    if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) {
      map.on('baselayerchange', function (element) {
        element?.layer?.bringToBack();
      });
    //   map.on('styleeditor:changed', function(element){
    //     console.log(element);
    //     setStyledPolygonOptions({options:element?.options})
    // });
    map.on('styleeditor:changed', function(element){
      setStyledPolygonOptions((prev)=> {
        return {
          ...prev,
          options: {
            ...prev.options,
            ...element.options,
          }
        }
      })

    });
    map.on('styleeditor:editing', function(element){
      setShowStyleEditorSideBar(element?.defaultOptions ? true : false)
    });
    }
  }, [map]);

  // useEffect(() => {
  //   if (
  //     !_.isEmpty(measureData) &&
  //     !_.isEmpty(measureData?._layer) &&
  //     !_.isEmpty(resultantMeasureData)
  //   ) {
  //     [measureData?._layer].forEach((layer, index) => {
  //       if (layer) {
  //         let { area } = resultantMeasureData;
  //         handleAddMoreAttributes(
  //           { name: 'area', value: area?.toFixed() },
  //           { isDefaultProps: true },
  //         );
  //       }
  //     });
  //   }
  // }, [resultantMeasureData, measureData]);

  const removeDrawnFarm = (drawFarmRef) => {
    if (drawFarmRef && drawFarmRef?.current) {
      drawFarmRef.current.clearLayers();
    }
  };

  const addLeafletMeasureControl = (map) => {
    L.Control.Measure.include({
      // set icon on the capture marker
      _setCaptureMarkerIcon: function () {
        // disable autopan
        this._captureMarker.options.autoPanOnFocus = false;

        // default function
        this._captureMarker.setIcon(
          L.divIcon({
            iconSize: this._map.getSize().multiplyBy(2),
          }),
        );
      },
    });
    var measure = L.control
      .measure({
        position: 'topright',
        lineColor: 'blue',
        primaryAreaUnit: 'acres',
        secondaryAreaUnit: 'meters',
        activeColor: '#84bff0',
        completedColor: '#47a2ed',
        captureZIndex: 20000,
      })
      .addTo(map);
    setMeasureData(measure);
  };

  //for removing measurement on map
  useEffect(() => {
    if (showMeasureOnMap) {
      let getLeafletMeasureEle = document.getElementsByClassName('layer-measure-resultarea');
      let getLeafletPopEle = document.getElementsByClassName('leaflet-popup');
      if (getLeafletMeasureEle && getLeafletMeasureEle?.length) {
        for (let index = 0; index < getLeafletMeasureEle?.length; index++) {
          getLeafletMeasureEle[index]?.classList.add('dds-leaflet-measure-css');
        }
      }
      if (getLeafletPopEle && getLeafletPopEle?.length) {
        for (let index = 0; index < getLeafletPopEle?.length; index++) {
          getLeafletPopEle[index]?.classList.add('dds-leaflet-measure-css');
        }
      }
    } else {
      let getLeafletMeasureEle = document.getElementsByClassName('layer-measure-resultarea');
      let getLeafletPopEle = document.getElementsByClassName('leaflet-popup');
      if (getLeafletMeasureEle && getLeafletMeasureEle?.length) {
        for (let index = 0; index < getLeafletMeasureEle?.length; index++) {
          getLeafletMeasureEle[index]?.classList.remove('dds-leaflet-measure-css');
        }
      }
      if (getLeafletPopEle && getLeafletPopEle?.length) {
        for (let index = 0; index < getLeafletPopEle?.length; index++) {
          getLeafletPopEle[index]?.classList.remove('dds-leaflet-measure-css');
        }
      }
    }
  }, [measureData, showMeasureOnMap, resultantMeasureData]);


  useEffect(()=>{
    if(farmOptions?.some(i => i.selected) && isCreateFarm){
       setOpenSelectLocationModal(true);
       setSelectedSubDistrict('')
       setSelectedBlock('');
       setCreateFarmData(prev=>{
        return {
          ...prev,
          sub_district: '',
          block:''
        }
       })
    }
    else if(farmOptions?.every(i => !i.selected)){
      setOpenSelectLocationModal(false)
    }
  },[farmOptions,isCreateFarm])

  useEffect(()=>{
    if (!farmOptions?.some((i) => i.id === t('FARM_TRACKER.DRAW_FARM_KEY') && i.selected)) {
      setShowPolygonDraw(false)
    }
  },[farmOptions])

  const removeGeoJsonLayer = (mapPreferences) => {
    let mapPreferKeys = Object.keys(mapPreferences);
    if (mapPreferKeys) {
      mapPreferKeys.map((item) => {
        let selectedLayer = mapPreferences[item];
        if (selectedLayer?.layer && selectedLayer?.layer?.current)
          selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.color = '';
        setMapPreferences((prev) => {
          return {
            ...prev,
            [item]: selectedLayer,
          };
        });
      });
    }
  };

  const getMapPreferencesGeojsonData = (geoJsonList) => {
    // let data = JSON.parse(JSON.stringify(geoJsonList));
    // let mapPreferKeys = Object.keys(mapPreferences);
    // if (mapPreferKeys) {
    //   mapPreferKeys.map((item) => {
    //     let selectedLayer = mapPreferences[item];
    //     if (selectedLayer?.layer && selectedLayer?.layer?.current)
    //       selectedLayer?.layer?.current?.clearLayers();
    //     selectedLayer.color = '';
    //     setMapPreferences((prev) => {
    //       return {
    //         ...prev,
    //         [item]: selectedLayer,
    //       };
    //     });
    //   });
    // }
    // setGeoJsonData(data);
    let data = JSON.parse(JSON.stringify(geoJsonList));
    removeGeoJsonLayer(mapPreferences);
    setGeoJsonData(data);
  };

  const handleSelectedRegion = (reg) => {
    let mapLayerData = [];
    let polyCount = 1;
    let mapData = JSON.parse(JSON.stringify(reg));

    if (mapData?.length) {
      mapData.map((item) => {
        let x = Object.assign(item, {});
        if (item.layerType === 'polygon') {
          x.latlngs = item.latlngs.map((v) => [v[1], v[0]]);
          x?.latlngs?.push(item.latlngs[0]);
          if (!x.hasOwnProperty('polygonCount')) {
            x.polygonCount = polyCount;
            polyCount += 1;
          }
          mapLayerData.push(x);
        } else {
          x.latlngs = [item.latlngs[1], item.latlngs[0]];
          mapLayerData.push(x);
        }
      });
    }
    if (reg?.length) {
      if (reg?.length === 1 && reg[0].layerType === 'marker') {
        let cordinates = reg[0]?.latlngs;
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            markerCordinates: cordinates,
          };
        });
      } else {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            markerCordinates: [],
          };
        });
      }
    } else {
      setCordinateAndZoom((prev) => {
        return {
          ...prev,
          zoom: 18,
          markerCordinates: [],
        };
      });
    }
    setRegion(mapLayerData);
  };

  // function to change the map attributes inside the map container
  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            cordinates: mapEvents.getCenter(),
            zoom: mapEvents.getZoom(),
          };
        });
      },
      dragend: () => {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            zoom: mapEvents.getZoom(),
            cordinates: mapEvents.getCenter(),
          };
        });
      },
    });

    const map = useMap();
    setInterval(function () {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 500);
    // map.setView(latlag, zoomin);
    // if (!_.isEmpty(center) && !_.isEmpty(zoom)) {
    map.setView(center, zoom);
    // }
    return null;
  }

  function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  //function to handle map preferences model toggling
  const handleMapModel = () => {
    setIsOpenMapModel(!isOpenMapModel);
  };

  //function to toggle tab
  const handleToggle = (tab) => {
    setActiveTab(tab);
  };

  const addLegend = (paramLegendData) => {
    if (paramLegendData?.length) {
      return paramLegendData?.map((item) => {
        return (
          <>
            {item?.descret_legend ? (
              <DiscreteLegend map={map} add={item.add} layer={item} position="bottomleft" />
            ) : (
              <CustomLegend
                map={map}
                add={item.add}
                layer={item}
                position={item.isCompare ? 'bottomleft' : 'bottomright'}
              />
            )}
          </>
        );
      });
    }
  };

  //function to set map reference
  const setMapReference = (mapInstance) => {
    mapRef.current = mapInstance;
    /** leaflet area measurement plugin code commented */
    // addLeafletMeasureControl(mapInstance);
    setMap(mapInstance);
  };

  //function to add layers in the map
  const addLayers = (selectedParamRef) => {
    if (mapRef.current && selectedParamRef.current) {
      const leafletMapRef = mapRef.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer, index) => {
        leafletMapRef.addLayer(layer);
      });
    }
    return false;
  };

  //function to remove layers from map
  const removeLayers = (selectedParamRef) => {
    if (mapRef && mapRef?.current && selectedParamRef && selectedParamRef?.current) {
      const map = mapRef.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => map.removeLayer(layer));
    }
  };

  const removeMapPreferenceLayer = (mapPreferRefs, mapPreferencesData) => {
    if (mapPreferRefs?.current?.length && mapPreferencesData?.length) {
      mapPreferencesData?.map((item) => {
        let selectedMapIndex = _.findIndex(
          mapPreferRefs.current,
          (list) => list.current.boundary_id === item.id,
        );
        let previousParamRef = mapPreferRefs?.current[selectedMapIndex].current.layer;
        if (previousParamRef && previousParamRef?.current) {
          removeLayers(previousParamRef);
        }
      });
    }
  };

  const handleSubDistrictChange = data => {
    let requestObj = { location_type: LOCATION.Block, parent_id: data?.id };
    setSelectedSubDistrict(data);
    setSelectedBlock('')
    setBlockList([]);
    dispatch(change('FarmTrackerLocationModal','block',''))
    setCreateFarmData(prev => {
      return {
        ...prev,
        sub_district: data,
        block:'',
        zoom_level: [18],
      }
    })
   
    dispatch(getBlockBySubDistrictRequest({ requestObj, isCompareLocation: false }));
  };

  const handleBlockChange = data => {
    if(!isCreateFarm){
      setCreateFarmData((prev) => {
        return {
          ...prev,
          block: data,
          zoom_level: [18],
        };
        });
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            locationCordinates: [data.latitude, data.longitude],
            cordinates: [data.latitude, data.longitude],
            zoom: 18,
          };
        });
    }
   
    setSelectedBlock(data);
  }

  const handleZoomToSelectedLocation = () => {
    setCordinateAndZoom((prev) => {
      return {
        ...prev,
        locationCordinates: [selectedBlock?.latitude || selectedSubDistrict?.latitude, selectedBlock?.longitude || selectedSubDistrict?.longitude],
        cordinates: [selectedBlock?.latitude || selectedSubDistrict?.latitude, selectedBlock?.longitude || selectedSubDistrict?.longitude],
        zoom: selectedBlock ? 18 : 14,
      };
    });
    // }
    if (farmOptions?.some((i) => i.id === t('FARM_TRACKER.DRAW_FARM_KEY') && i.selected)) {
      setShowPolygonDraw(true)
  }
    else setShowPolygonDraw(false)
    setCreateFarmData((prev) => {
      return {
        ...prev,
        sub_district: selectedSubDistrict,
        block: selectedBlock,
        zoom_level: [selectedBlock ? 18 : 14],
      };
    });
  }

  const getMapPreferencesData = (mapPreferencesLayerData) => {
    /** removing previously added map preferences layers before setting the state */
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    /** setting map preferences state*/
    let arr = JSON.parse(JSON.stringify(mapPreferencesLayerData));
    let paramData = '';
    let paramArr = '';
    if (selectedMapPreferLegendData?.length) {
      paramData = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      paramData?.map((item, index) => {
        if (item.add) {
          let legendItem = { ...item };
          legendItem.add = false;
          paramData[index] = legendItem;
        }
      });
      paramArr = [...paramData];
    }
    setSelectedMapPreferLegendData(paramArr);
    setMapPreferencesData(arr);
  };

  const handleCordinates = (cordinates, zoom) => {
    let zoom_level = !_.isEmpty(zoom) ? zoom : cordinateAndZoom.zoom;
    setCordinateAndZoom((prev) => {
      return {
        ...prev,
        locationCordinates: cordinates,
        cordinates,
        zoom: zoom_level,
      };
    });
  };

  const handleAddMoreAttributes = (obj, { isDefaultProps }) => {
    let attributesList = JSON.parse(JSON.stringify(farmAttributes));
    if (attributesList?.length < attributeLimit) {
      let updatedList = [];
      // if (obj?.name === 'Latitude' || obj?.name === 'Longitude' || obj.name === 'Area') {
      if (obj.name === DEFAULT_ATTRIBUTES_FARM_TRACKER.AREA || obj.name === DEFAULT_ATTRIBUTES_FARM_TRACKER.LATITUDE || obj.name === DEFAULT_ATTRIBUTES_FARM_TRACKER.LONGITUDE) {
        updatedList.push({ name: obj.name, value: parseFloat(obj.value).toFixed(2) });
        // updatedList.push({ name: obj.name, value: obj.value });
      } else {
        updatedList.push({ name: obj.name, value: obj.value });
      }
      if (isDefaultProps) {
        setDefaultPolygonProps((prev) => prev.concat(updatedList));
      }
      setFarmAttributes((prev) => prev.concat(updatedList));
    }
  };

  const handleDeleteAttribute = () => {
    let attributesList = JSON.parse(JSON.stringify(farmAttributes));
    attributesList.pop();
    setFarmAttributes(attributesList);
  };

  const handleRemoveAttributes = (isPolygonDeleted) => {
    if (isPolygonDeleted) {
      setRegion([]);
      setFarmAttributes([]);
      setDefaultPolygonProps([])
      if (
        _.isEmpty(farmTitle) &&
        _.isEmpty(farmDescription) &&
        _.isEmpty(profileIcon) &&
        !geoTaggedImages?.length
      ) {
        setIsHideFarmCollapse(true);
      }
    }
  };

  const getFarmData = (data, index) => {
    setSelectedFarmIndex(index);
    /** need to show edit option by default when farm is selected so setting from listing to false*/
    setFromListing(false);
    /** else setFromlisting(true) */
    removeDrawnFarm(drawFarmRef);
    if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
    if(!_.isEmpty(kmlData)) map.removeLayer(kmlData)
    setKmlData('')
    setkml({ document: '', layer: {}, area: null });
    setUploadedFiles('');
    setIsShowListing(true);
    setStyledPolygonOptions({ options: {} });
    setFarmOptions(FARM_TRACKER_OPTIONS);
    setChangePolygonOptions(FARM_TRACKER_OPTIONS);
    dispatch(getFarmDetailsByIdRequest({ id: data?.id }));
  };

  const getSelectedFarm = (data) => {
    if (!_.isEmpty(data)) {
      setFromListing(false);
      removeDrawnFarm(drawFarmRef);
      if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
      if(!_.isEmpty(kmlData)) map.removeLayer(kmlData)
      setKmlData('')
      setkml({ document: '', layer: {}, area: null });
      setUploadedFiles('');
      setIsShowListing(true);
      setDefaultPolygonProps('');
      setStyledPolygonOptions({ options: {} });
      dispatch(getFarmDetailsByIdRequest({ id: data?.id }));
    }
  };

  const handleOpenDeleteFarmModal = (item) => {
    if (item?.id) {
      setCreateFarmData((prev) => {
        return {
          ...prev,
          farmData: item,
        };
      });
    }
  };

  const handleGeoTaggedImages = (image) => {
    if (image) {
      /** array for storing geotagged images list */
      let geoTaggedList = [...geoTaggedImages];
      image.map((item) => geoTaggedList?.push(item));
      setGeoTaggedImages(geoTaggedList);
      setIsGeoTaggedImagesUpdating(true);

      /**array for storing geotagged image urls */
      let geoTaggedImagesUrl = [...geoTaggedImagesFiles];
      image?.map((item) =>
        geoTaggedImagesUrl.push({ name: item?.name, url: URL.createObjectURL(item) }),
      );
      setGeoTaggedImagesFiles(geoTaggedImagesUrl);
    }
  };

  const handleGeoTaggedImageRemoval = (item) => {
    if (item) {
      let geoTaggedList = [...geoTaggedImages];
      let updatedGeoTaggedList = geoTaggedList?.filter((gt) => gt.name !== item?.name);
      setGeoTaggedImages(updatedGeoTaggedList);
      setIsGeoTaggedImagesUpdating(true);

      let geoTaggedImagesUrl = [...geoTaggedImagesFiles];
      let updatedGeoTaggedImagesUrlList = geoTaggedImagesUrl?.filter(
        (gt) => gt.name !== item?.name,
      );
      setGeoTaggedImagesFiles(updatedGeoTaggedImagesUrlList);
    }
  };

  const fileHandler = async (value) => {
    await getBase64(value);
    setIsImageUploaded(true);
    setImageFile(URL.createObjectURL(value));
  };


  async function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      setProfileIcon(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }
 

  const handleDeleteFarm = (id) => {
    dispatch(deleteFarmTrackerRequest({ id: id }));
    removeDrawnFarm(drawFarmRef);
  };

  const handleUploadedFiles = (files) => {
    setUploadedFiles(files);
    setIsShowListing(true);
  };

  const handleFarmAttributeChange = (e, action, index) => {
    let attributesList = JSON.parse(JSON.stringify(farmAttributes));
    let data = e.target.value;
    if (action === 'name') {
      data = data.replace(/[^A-Za-z]/gi, '');
    }
    let updatedAttributeList = attributesList?.map((item, itemIndex) => {
      let x = { ...item };
      if (index === itemIndex) {
        x[action] = data;
      }
      return x;
    });
    setFarmAttributes(updatedAttributeList);
  };

  const handleSelectedLocationModal = (isOpen) => {
    setOpenSelectLocationModal(!isOpen)
    setIsPolygonShow(isOpen)
  }

  const handleCloseLocationModal = (isOpen) => {
    setOpenSelectLocationModal(!isOpen)
    setIsPolygonShow(isOpen)
    let isSetFarmOption = selectedSubDistrict?.id  ? ( blockList?.length ? (selectedBlock?.id  ? false : true) : false) : true 
    if(isSetFarmOption){
       setFarmOptions(FARM_TRACKER_OPTIONS)
    }
  }

  const renderFarmAttributes = (attributes) => {
    if (attributes?.length) {
      let data = [...attributes];
      data = attributes?.map((item) => {
        let obj = {};
        obj[item.name] = item.value;
        let updatedObj = obj;
        return updatedObj;
      });
      return data;
    }
  };

  const convertImagePathToFile = async (imgUrl) => {
    const response = await fetch(imgUrl);
    const blob = await response.blob();
    const fileNameArr = imgUrl?.split('/');
    const file = new File([blob], fileNameArr[fileNameArr?.length - 1], {
      type: blob.type,
    });
    return file;
  };

  const handleFormFieldData = () => {
    let data = {
      title: farmTitle,
      description: farmDescription,
      farmAttributes: farmAttributes,
    };
    data.isOpenAction = false;
    let farmsList = JSON.parse(JSON.stringify(projectDetails?.farmsList));
    farmsList.push(data);

    let farm_data = [];
    let payload = {};
    payload.name = farmTitle;
    payload.sub_district_id = selectedSubDistrict?.id;
    payload.block_id = selectedBlock?.id || '';
    payload.description = farmDescription

    if (profileIcon && isImageUploaded) {
      payload.profile_icon = profileIcon;
    }

    if (farmAttributes?.length && farmAttributes?.every((item) => item.name && item.value)) {
      payload.farm_attributes = renderFarmAttributes(farmAttributes);
    }

    if(odkImages?.length){
      let parsedOdkImages = JSON.parse(odkImages)


      payload.odk_image1 = parsedOdkImages[0]
      payload.odk_image2 = parsedOdkImages[1]
      payload.odk_image3 = parsedOdkImages[2]
    }

    if (!_.isEmpty(styledPolygonOptions?.options)) {
      payload.polygon_style = styledPolygonOptions.options;
      let obj = styledPolygonOptions.options;
      let newObj ={};
      for (const [key,value] of Object.entries(obj)) {
        let newKey = key.toString()
        newObj[newKey] = value.toString()
        payload.polygon_style = newObj;
      }
    } else {
      if(isCreateFarm)
      payload.polygon_style = "{}"
      else{
        payload.polygon_style = {}
      }
    }

    let is_upload = isUploadFarm;
    if (uploadedFiles || region?.length) {
      payload.is_upload = _.capitalize(is_upload)
    }

    if (is_upload && uploadedFiles) {
      // form.append('upload_file', uploadedFiles);
    } else if (region?.length) {
      // form.append(
      //   'draw_farm',
      //   region?.length ? JSON.stringify(region[region?.length - 1]?.latlngs) : '',
      // );
      payload.draw_farm = region?.length ? region[region?.length - 1]?.latlngs : ''
    }
    if (isCreateFarm) {
      payload.farm_tracker_project_id = projectDetails?.id;
      setIsRemovePolygon(true);
      farm_data.push(payload)
      let obj = { farm_data: farm_data }
      setProjectDetails((prev) => {
        return {
          ...prev,
          farmsList: [],
        };
      });
      dispatch(addFarmTrackerRequest(obj));
      handleResetState()
    } else {
      payload.farmtracker_id = createFarmData?.farmData?.id
      setProjectDetails((prev) => {
        return {
          ...prev,
          farmsList: [],
        };
      });
      dispatch(editFarmTrackerRequest(payload));
      handleResetState()
    }
    setActiveTab('FARM_TRACKING');

    if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
    if(!_.isEmpty(kmlData)) map.removeLayer(kmlData)
    setKmlData('')
    setkml({ document: '', layer: {}, area: null });
    setUploadedFiles('');
    setIsShowListing(true);
    removeDrawnFarm(drawFarmRef);
    setFarmOptions(FARM_TRACKER_OPTIONS);
    setShowMeasureOnMap(true);
  };
 
  const handleGoBack = () => {
    // history.goBack();
    if (!_.isEmpty(latValues)&&!_.isEmpty(lonValues) && !_.isEmpty(districtUrl)){
      history.push(`/farms-list?lat=${latValues}&lon=${lonValues}&district=${districtUrl}`);
    }else{
      history.push('/farms-list');
    }
  };

  const handleSelectedFarmOption = (key) => {
    setIsPolygonShow(true)
    
    let data = [...farmOptions];
    let updatedData = data?.map((item) => {
      if (item?.id === key) {
        if (key === 'draw_farm' && projectDetails?.farmsList?.length) {
          setIsShowListing(true);
        } else {
          setIsShowListing(false);
        }
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    });
    if (updatedData) setFarmOptions(updatedData);
    setIsRemovePolygon(false);

    if (key === 'draw_farm') {
      // setShowMapPreferences(!showMapPreferences);
      setStyledPolygonOptions({ options: {} });
    }
  };

  const handleChangePolygonOptions = (key) => {
    let data = [...changePolygonOptions];
    let updatedData = data?.map((item) =>
      item?.id === key ? { ...item, selected: true } : { ...item, selected: false },
    );
    let updatedFarmData = [...farmLayersData];
    updatedFarmData = farmLayersData?.map(item => {
      if(item.id === getByIdFarmInfo?.farm?.[0]?.id){
         return{
          ...item,
          data:{ ...item.data, features:''}
         }
      }
      else return item;
    })
    if(updatedFarmData) setFarmLayersData(updatedFarmData)

    if (updatedData) setChangePolygonOptions(updatedData);
    if (key === 'draw_farm') {
      setShowPolygonDraw(!showPolygonDraw);
      setStyledPolygonOptions({ options: {} });
      setIsShowListing(true);
      let data = [...changePolygonOptions];
      let updatedData = data?.map((item) =>
        item?.id === 'draw_farm' ? { ...item, selected: true } : { ...item, selected: false },
      );
      if (updatedData) setFarmOptions(updatedData);
    }
    if (key === 'draw_farm' && projectDetails?.farmsList?.length) {
      setIsShowListing(true);
    } else {
      setIsShowListing(false);
    }
    setUploadedFiles('');
    if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
    if(!_.isEmpty(kmlData)) map.removeLayer(kmlData)
    setKmlData('')
    setkml({ document: '', layer: {}, area: null });
  };

  const handleResetState = () => {
    setFarmAttributes([]);
    setDefaultPolygonProps([])
    setFarmTitle('');
    setFarmDescription('');
    setProfileIcon('');
    setImageFile('');
    setGeoTaggedImages([]);
    setGeoTaggedImagesFiles([]);
    setIsGeoTaggedImagesUpdating(false);
    setIsImageUploaded(false);
    setUploadedFiles('');
    setIsUploadFarm(false);
    setIsCreateFarm(true);
    setFromListing(false);
    setIsPolygonClicked(false);
    setIsHideFarmCollapse(true);
    setShowFarmInputFields(false);
    setStyledPolygonOptions({ options: {} });
  };
  const handleResetCollapseFarm = () => {
    setFarmTitle('');
    setFarmDescription('');
    setGeoTaggedImages([]);
    setGeoTaggedImagesFiles([]);
    setIsGeoTaggedImagesUpdating(false);
    setProfileIcon('');
    setImageFile('');
    //setFarmAttributes([]);
  };

  const handleEachFeatureChange = (countryData, locationType, feat, layer) => {
    let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
      (item) => item.selectedCountry === countryData?.name,
    );
    let labelprop = selectedCountryData ? selectedCountryData[locationType] : '';
    if (labelprop && feat.properties[labelprop])
      layer.bindTooltip(feat.properties[labelprop] + '', { sticky: true, direction: 'auto' });

    /** TODO: alternative solution */

    // if(!_.isEmpty(layer) && !_.isEmpty(feat?.properties)){
    //   layer?.on({
    //     'mousemove': (e) => {
    //       const name = e.target.feature.properties['MJ_BASIN__'] ? e.target.feature.properties['MJ_BASIN__'] : '';
    //       layer?.bindTooltip(name)
    //       layer?.openTooltip(e?.latlng);
    //     },
    //     // 'mouseout': () => {
    //     //   layer?.unbindTooltip();
    //     //   layer?.closeTooltip();
    //     // },
    //   });
    // }
  };

  const handlePolygonHover = (data, feat, layer) => {
    if (!_.isEmpty(layer) && !_.isEmpty(feat?.properties)) {
      layer?.on({
        mousemove: (e) => {
          const name = e.latlng
            ? `<span>Latitude: ${e.latlng.lat.toFixed(2)} <br /> Longitude: ${e.latlng.lng.toFixed(
                2,
              )} </span>`
            : '';
          layer?.bindTooltip(name);
          layer?.openTooltip(e?.latlng);
        },
        mouseout: () => {
          layer?.unbindTooltip();
          layer?.closeTooltip();
        },
      });
    }
  };


  const handleFarmHoverText = (farmName, farmArea,feat, layer) => {
    if (!_.isEmpty(layer) && !_.isEmpty(feat?.properties)) {
      layer?.on({
        mousemove: (e) => {
          const name = e.latlng
            ? `<span> Name: ${farmName} <br />Latitude: ${e.latlng.lat.toFixed(2)} <br /> Longitude: ${e.latlng.lng.toFixed(
                2,
              )} <br /> Area: ${farmArea} ha</span>`
            : '';
          layer?.bindTooltip(name);
          layer?.openTooltip(e?.latlng);
        },
        mouseout: () => {
          layer?.unbindTooltip();
          layer?.closeTooltip();
        },
      });
    }
  };

  const handleFarmOptions = () => {
    let data = [...farmOptions];
    let updatedData = data?.map((item) => (item?.selected ? { ...item, selected: false } : item));
    if (updatedData) setFarmOptions(updatedData);
    setIsShowListing(false);
    setFarmOptions(FARM_TRACKER_OPTIONS);
    setChangePolygonOptions(FARM_TRACKER_OPTIONS);
    setIsHideFarmCollapse(true);
    if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
    setKmlData('')
    setkml({ document: '', layer: {}, area: null });
    setUploadedFiles('');
    removeDrawnFarm(drawFarmRef);
    setShowPolygonDraw(false);
    if(!_.isEmpty(kmlData)) map?.removeLayer(kmlData)
  };

  const showFarmList = () => {
    setIsShowListing(true);
    setShowPolygonDraw(false);
  };

  const handleCreateFarm = () => {
    let payloadList = [];
    if (!_.isEmpty(kmlData)) {
      let payload = {};
      let farmAttributes = [];
      kmlData?.eachLayer((mapLayer) => {
        if (mapLayer?.hasOwnProperty('_latlngs')) {
          let center = mapLayer?._bounds ? mapLayer?.getBounds()?.getCenter() : '';
          let area = mapLayer?._latlngs ? L.GeometryUtil?.geodesicArea(mapLayer?.getLatLngs()) : '';
          farmAttributes.push({ [DEFAULT_ATTRIBUTES_FARM_TRACKER.LATITUDE]: center?.lat?.toString() });
          farmAttributes.push({ [DEFAULT_ATTRIBUTES_FARM_TRACKER.LONGITUDE]: center?.lng?.toString() });
          farmAttributes.push({ [DEFAULT_ATTRIBUTES_FARM_TRACKER.AREA]: parseFloat(area * 0.0001).toFixed(2) });
          payload.farm_tracker_project_id = projectDetails?.id;
          payload.name = 'Farm 1';
          // payload.description = '';
          payload.sub_district_id = selectedSubDistrict?.id;
          payload.block_id = selectedBlock?.id;
          payload.farm_attributes = farmAttributes;
          let layerLatLng = [];
          const cordinatesArray = mapLayer.getLatLngs();
          cordinatesArray?.map(({ lat, lng }) => {
            layerLatLng.push([lng, lat]);
          });
          if (layerLatLng?.length) {
            payload.draw_farm = layerLatLng;
            payload.polygon_style = "{}"
          }
          //  layerLatLng.push([cordinatesArray[0].lng,cordinatesArray[0]?.lat])
          if (odkImages?.length) {
            payload['odk_image1'] = odkImages[0];
            payload['odk_image2'] = odkImages[1];
            payload['odk_image3'] = odkImages[2];
          }
          payload.is_upload = _.capitalize(false);
          payloadList.push(payload)
          let obj ={ farm_data: payloadList }
          dispatch(addFarmTrackerRequest(obj));
        } else {
          kmlData?.eachLayer((mapLayer) => {
            let count = 1;
            mapLayer?.eachLayer((layer) => {
              let payload = {};
              let farmAttributes = [];
              let center = layer?._bounds ? layer?.getBounds()?.getCenter() : '';
              let area = layer?._latlngs[0] ? L.GeometryUtil?.geodesicArea(layer?._latlngs[0]) : '';
              farmAttributes.push({ [DEFAULT_ATTRIBUTES_FARM_TRACKER.LATITUDE]: center?.lat?.toString() });
              farmAttributes.push({ [DEFAULT_ATTRIBUTES_FARM_TRACKER.LONGITUDE]: center?.lng?.toString() });
              farmAttributes.push({ [DEFAULT_ATTRIBUTES_FARM_TRACKER.AREA]: parseFloat(area * 0.0001).toFixed(2) });
              payload.farm_tracker_project_id = projectDetails?.id;
              payload.name = `Farm ${count}`;
              // payload.description = '';
              payload.sub_district_id = selectedSubDistrict?.id;
              payload.block_id = selectedBlock?.id;
              payload.farm_attributes = farmAttributes;
              let layerLatLng = [];
              const cordinatesArray = layer.getLatLngs()[0];
              cordinatesArray?.map(({ lat, lng }) => {
                layerLatLng.push([lng, lat]);
              });
              if (layerLatLng?.length){
                payload.draw_farm = layerLatLng;
                payload.polygon_style = "{}"
              }
              //  layerLatLng.push([cordinatesArray[0].lng,cordinatesArray[0]?.lat])
              payload.is_upload =  _.capitalize(false);
              payloadList.push(payload);
              count = count + 1;
            });
          });
          let obj ={ farm_data: payloadList }
          dispatch(addFarmTrackerRequest(obj));
        }
      });
    }
  };

  const handleKml = (kml) => {
    if(!_.isEmpty(kmlData)) map.removeLayer(kmlData);
    const kmlDataLayer = new L.KML(kml);
    let isSinglePolygon = false;
    kmlDataLayer?.setStyle({
      fill: '#000',
      opacity: 1,
    });
    let area = null;
    let center = [];
    if (!_.isEmpty(kmlDataLayer)) {
      kmlDataLayer?.eachLayer((mapLayer) => {
        if (mapLayer?.hasOwnProperty('_latlngs')) {
          isSinglePolygon = true;
        } else {
          isSinglePolygon = false;
        }
      });
      setKmlData(kmlDataLayer);
      if (isSinglePolygon) {
        map?.addLayer(kmlDataLayer);
        kmlDataLayer?.eachLayer((mapLayer) => {
          center = mapLayer?._bounds ? mapLayer?.getBounds()?.getCenter() : '';
          area = mapLayer?._latlngs ? L.GeometryUtil?.geodesicArea(mapLayer?.getLatLngs()) : '';
          if (area)
            mapLayer?.bindTooltip(`<span> Latitude: ${center?.lat?.toFixed(2)} <br /> Longitude: ${center?.lng?.toFixed(2)} <br /> Area : ${(area* 0.0001)?.toFixed(2)} ha</span>`, {
              className: 'draw-polygon',
              sticky: true,
            });
        });
        if (!_.isElement(center)) {
          // handleAddMoreAttributes(
          //   { name: 'Latitude', value: center?.lat },
          //   { isDefaultProps: true },
          // );
          // handleAddMoreAttributes(
          //   { name: 'Longitude', value: center?.lng },
          //   { isDefaultProps: true },
          // );
          handleCordinates(
            [
              center?.lat ? center?.lat : cordinateAndZoom?.locationCordinates[0],
              center?.lng ? center?.lng : cordinateAndZoom?.locationCordinates[1],
            ],
            18,
          );
        }
        // if (area) {
        //   handleAddMoreAttributes({ name: 'Area', value: area }, { isDefaultProps: true });
        // }
        // setkml({ document: kml, layer: kmlData, area: area, center: [center?.lat, center?.lng] });
      } else {
        // setIsShowListing(false);
        // setUploadedFiles('');
        // showError('Please upload a file of single polygon data.');
        kmlDataLayer?.eachLayer((mapLayer)=>{
          mapLayer?.eachLayer((layer)=>{
            center = layer?._bounds ? layer?.getBounds()?.getCenter() : '';
            area = layer?._latlngs[0] ? L.GeometryUtil?.geodesicArea(layer?._latlngs[0]) : '';
            if (area)
              layer?.bindTooltip(`<span> Latitude: ${center?.lat?.toFixed(2)} <br /> Longitude: ${center?.lng?.toFixed(2)} <br /> Area : ${(area* 0.0001)?.toFixed(2)} ha</span>`, {
                className: 'draw-polygon',
                sticky: true,
              });
          })
        })
          map?.addLayer(kmlDataLayer);
          handleCordinates(
            [
              center?.lat ? center?.lat : cordinateAndZoom?.locationCordinates[0],
              center?.lng ? center?.lng : cordinateAndZoom?.locationCordinates[1],
            ],
            18,
          );
            // setkml({ document: kml, layer: kmlData, area: area, center: [center?.lat, center?.lng] });
      }
    }
  };


  //function to render map
  const renderMapContainer = () => {
    let getCss = document.getElementsByClassName("leaflet-control-layers");
    const parent = document.getElementsByClassName('map-preferences-button');
    if (getCss?.length){
      getCss[1]?.addEventListener(
        "mouseover", over);     
        getCss[1]?.addEventListener(
        "mouseout", out);
    function over() {
      // parent[0].style.marginTop = "23px"
      parent[0].style.marginTop = "1.7%"
    }
    function out() {
      parent[0].style.marginTop = "0%"
    }
    }
    return (
      <>
        <MapContainer
          center={cordinateAndZoom.cordinates}
          id="farm-tracker-map"
          zoom={cordinateAndZoom.zoom}
          className="farm-tracker-map-container"
          zoomControl={false}
          scrollWheelZoom={true}
          whenCreated={setMapReference}
          // maxZoom={24}
        >
          {(!_.isEmpty(latValues) && !_.isEmpty(lonValues)) &&
            <Marker position={[latValues,lonValues]}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
            }
          {cordinateAndZoom?.cordinates && cordinateAndZoom.zoom ? (
            <ChangeView center={cordinateAndZoom?.cordinates} zoom={cordinateAndZoom?.zoom} />
          ) : (
            ''
          )}

          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Satellite">
              <TileLayer
                url={myConstClass.SATELLITE_TILE_LAYER_URL}
                options={{ tileSize: 256 }}
                attribution={`© <a href='https://www.mapbox.com/about/maps/' target='_blank'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a> ©<a href="https://www.maxar.com/" target='_blank'> Maxar</a>`}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Streets">
              <TileLayer
                url={myConstClass.TITLE_LAYER_URL}
                options={{ tileSize: 256 }}
                attribution={`© <a href='https://www.mapbox.com/about/maps/' target='_blank'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a>`}
              />
            </LayersControl.BaseLayer>
          </LayersControl>

          <ZoomControl position="topright" className="map-zoom-control" />
          {/* <TileLayer
            // attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            // url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            url={myConstClass.TITLE_LAYER_URL}
          /> */}

          <LayersControl position="topleft">
            <div className="logoContainer1">
              <Button
                // className="map-preferences-button farm-map-prefer-st"
                className="map-preferences-button"
                onClick={handleMapModel}
              >
                <img src={mapPreferenceIcon} alt="map preference" width={15} />
              </Button>
            </div>
          </LayersControl>

          {/* {!_.isEmpty(kml) ? <ReactLeafletKml kml={kml} /> : ''} */}
          <DrawShape
            handleSelectedRegion={handleSelectedRegion}
            isRemovePolygon={isRemovePolygon}
            setActiveTab={setActiveTab}
            map={map}
            isHideFarmCollapse={isHideFarmCollapse}
            farmsList={projectDetails?.farmsList}
            changePolygonOptions={changePolygonOptions}
            farmOptions={farmOptions}
            drawFarmRef={drawFarmRef}
            setFarmAttributes={setFarmAttributes}
            farmAttributes={farmAttributes}
            setUpdatedLatLonList={setUpdatedLatLonList}
            showPolygonDraw={showPolygonDraw}
            handleRemoveAttributes={handleRemoveAttributes}
            createFarmData={createFarmData}
            openSelectLocationModal={openSelectLocationModal}
            polygon={isPolygonShow}
          />

          {farmLayersData?.length ? 
            farmLayersData?.map(item => {
            if(item?.data?.features){
              return (
                <GeoJSON
                  key={item.name}
                  data={item?.data?.features}
                  style={item.styles}
                  onEachFeature={(feat, layer) => handleFarmHoverText(item?.name, item?.area, feat, layer)}
                />
              )
            }
          }):<></>}

          <GeoJSON
            ref={drawFarmRef}
            style={styledPolygonOptions?.options}
            onEachFeature={(feat, layer) => handlePolygonHover(getByIdFarmInfo, feat, layer)}
          />

          <GeoJSON
            ref={mapPreferences['country'].layer}
            style={{ color: mapPreferences['country'].color, fillColor: 'none', weight: 1 }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['country'].country, 'country', feat, layer)
            }
          />

          <GeoJSON
            ref={mapPreferences['states_or_provinces'].layer}
            style={{
              color: mapPreferences['states_or_provinces'].color,
              fillColor: '#0000',
              weight: 1.6,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['states_or_provinces'].country,
                'states_or_provinces',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            // ref={mapPreferences['districts_or_cities'].layer}
            // style={{
            //   color: mapPreferences['districts_or_cities'].color,
            //   weight: 1,
            //   fillColor: 'none',
            // }}
            // onEachFeature={(feat, layer) =>
            //   handleEachFeatureChange(
            //     mapPreferences['districts_or_cities'].country,
            //     'districts_or_cities',
            //     feat,
            //     layer,
            //   )
            // }
            key={DEFAULT_COUNTRY + MAP_PREFERENCES_lABELS.DISTRICTS}
            ref={mapPreferences['districts_or_cities'].layer}
            style={{
              color: mapPreferences['districts_or_cities'].color,
              weight: 1,
              // fillColor: 'none',
              fillColor: '#0000',
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['districts_or_cities'].country,
                'districts_or_cities',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            ref={mapPreferences['meteorological_locations'].layer}
            style={{
              color: mapPreferences['meteorological_locations'].color,
              fillColor: 'none',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['meteorological_locations'].country,
                'meteorological_locations',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            // ref={mapPreferences['basins'].layer}
            // key={DEFAULT_COUNTRY.name + MAP_PREFERENCES_lABELS.BASINS}

            // ref={mapPreferences['basins'].layer}

            // style={{
            //   color: mapPreferences['basins'].color,
            //   fillColor: 'none',
            //   weight: 1,
            // }}
            // onEachFeature={(feat, layer) =>
            //   handleEachFeatureChange(mapPreferences['basins'].country, 'basins', feat, layer)
            // }

            key={DEFAULT_COUNTRY?.name + MAP_PREFERENCES_lABELS.BASINS}
            ref={mapPreferences['basins'].layer}
            style={{
              color: mapPreferences['basins'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['basins'].country, 'basins', feat, layer)
            }
          />

          <GeoJSON
            ref={mapPreferences['rivers_or_reservoirs'].layer}
            style={{
              color: mapPreferences['rivers_or_reservoirs'].color,
              fillColor: 'none',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['rivers_or_reservoirs'].country,
                'rivers_or_reservoirs',
                feat,
                layer,
              )
            }
          />
          <GeoJSON
            ref={mapPreferences['talukas'].layer}
            style={{
              color: mapPreferences['talukas'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['talukas'].country, 'talukas', feat, layer)
            }
          />

          <LayersControl position="bottomleft">
            {mapPreferRefs?.current?.map((mapLayerRef, index) => {
              if (!_.isEmpty(mapLayerRef)) {
                return (
                  <>
                    <Overlay key={index}>
                      <LayerGroup ref={mapLayerRef.current.layer}>
                        <TileLayer
                          attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
                          url=""
                        />
                      </LayerGroup>
                    </Overlay>
                  </>
                );
              }
            })}
          </LayersControl>

          {selectedMapPreferLegendData?.length ? (
            <>
              <LayersControl position="bottomright">
                {addLegend(selectedMapPreferLegendData)}
              </LayersControl>
            </>
          ) : (
            ''
          )}
        </MapContainer>
      </>
    );
  };

  return (
    <>
      {openSelectLocationModal ? (
        <FarmTrackerLocationModal
          modalOpen={openSelectLocationModal}
          setModalOpen={handleSelectedLocationModal}
          handleModalClose={handleCloseLocationModal}
          subDistrictList={subDistrictList}
          blockList={blockList}
          selectedSubDistrict={selectedSubDistrict}
          selectedBlock={selectedBlock}
          handleZoomToSelectedLocation={handleZoomToSelectedLocation}
          handleSubDistrictChange={handleSubDistrictChange}
          handleBlockChange={handleBlockChange}
        />
      ) : (
        <></>
      )}
      <Row className="bg-white farm-title-row m-0">
        <Col className="farm-title-style ">
          <span className="cursor-ponter">
            <HiOutlineArrowSmLeft className="farms-back-icon" onClick={handleGoBack} />
            <span className="mx-2">{projectDetails?.title}</span>
          </span>
        </Col>
      </Row>
      <div>
        <div className="w-100">
          {isLoading ? <Loader /> : ''}
          {/* <Row> */}
          <div className="farmSideBar col-lg-2">
            <Filter
              isHideBar={isHideBar}
              activeTab={activeTab}
              locationList={LocationList}
              initialValues={createFarmData}
              cordinateAndZoom={cordinateAndZoom}
              farmList={projectDetails?.farmsList}
              setFarmLayersData={setFarmLayersData}
              farmOptions={farmOptions}
              changePolygonOptions={changePolygonOptions}
              isShowListing={isShowListing}
              uploadedFiles={uploadedFiles}
              isCreateFarm={isCreateFarm}
              selectedSubDistrict={selectedSubDistrict}
              selectedBlock={selectedBlock}
              kmlData={kmlData}
              getFarmData={getFarmData}
              setUploadedFiles={setUploadedFiles}
              kml={kml}
              setkml={handleKml}
              isOpenDeleteFarmModal={isOpenDeleteFarmModal}
              showFarmList={showFarmList}
              handleFarmOptions={handleFarmOptions}
              setIsShowListing={setIsShowListing}
              handleSelectedFarmOption={handleSelectedFarmOption}
              getSelectedFarm={getSelectedFarm}
              handleOpenDeleteFarmModal={handleOpenDeleteFarmModal}
              handleDeleteFarm={handleDeleteFarm}
              setIsOpenDeleteFarmModal={setIsOpenDeleteFarmModal}
              setBar={setBar}
              setActiveTab={setActiveTab}
              handleToggle={handleToggle}
              handleCordinates={handleCordinates}
              setCordinateAndZoom={setCordinateAndZoom}
              cordinatesAndZoom={cordinateAndZoom}
              handleCreateFarm={handleCreateFarm}
              setShowPolygonDraw={setShowPolygonDraw}
              isHideFarmCollapse={isHideFarmCollapse}
              projectDetails={paramLocation?.state?.projectData}
              selectedFarmIndex={selectedFarmIndex}
              setSelectedFarmIndex={setSelectedFarmIndex}
            />
          </div>
          {(isPolygonClicked || kml?.document) && !isHideFarmCollapse ? (
            <div className="collapse-farm-side-bar col-lg-2 mr-2">
              <FarmCollapse
                isHideBar={isHideBar}
                showFarmInputFields={showFarmInputFields}
                isHideFarmCollapse={isHideFarmCollapse}
                createFarmData={createFarmData}
                isCreateFarm={isCreateFarm}
                isUploadFarm={isUploadFarm}
                farmAttributes={farmAttributes}
                defaultPolygonProps={defaultPolygonProps}
                farmTitle={farmTitle}
                farmDescription={farmDescription}
                attributeLimit={attributeLimit}
                imageFile={imageFile}
                isFarmLoading={FarmTracker.addFarmTrackerRequesting}
                geoTaggedImages={geoTaggedImages}
                geoTaggedImagesFiles={geoTaggedImagesFiles}
                subDistrictList={subDistrictList}
                blockList={blockList}
                handleSubDistrictChange={handleSubDistrictChange}
                handleBlockChange={handleBlockChange}
                handleGeoTaggedImageRemoval={handleGeoTaggedImageRemoval}
                fileHandler={fileHandler}
                handleGeoTaggedImages={handleGeoTaggedImages}
                handleChangePolygonOptions={handleChangePolygonOptions}
                handleResetCollapseFarm={handleResetCollapseFarm}
                setFarmTitle={setFarmTitle}
                setFarmDescription={setFarmDescription}
                handleFarmAttributeChange={handleFarmAttributeChange}
                handleUploadedFiles={handleUploadedFiles}
                handleFormFieldData={handleFormFieldData}
                handleDeleteAttribute={handleDeleteAttribute}
                handleAddMoreAttributes={handleAddMoreAttributes}
                setIsHideFarmCollapse={setIsHideFarmCollapse}
                showPolygonDraw={showPolygonDraw}
                setShowPolygonDraw={setShowPolygonDraw}
                changePolygonOptions={changePolygonOptions}
                farmOptions={farmOptions}
                setFarmOptions={setFarmOptions}
                setChangePolygonOptions={setChangePolygonOptions}
                handleEmptyKmlFile={handleEmptyKmlFile}
                setSelectedFarmIndex={setSelectedFarmIndex}
              />
            </div>
          ) : (
            ''
          )}
          <Row>
            <Col
              lg={
                !isHideBar && !isHideFarmCollapse
                  ? 8
                  : (!isHideBar && isHideFarmCollapse) || (isHideBar && !isHideFarmCollapse)
                  ? 10
                  : 12
              }
              className={
                isHideBar && isHideFarmCollapse
                  ? 'right-map farm-map-position manage-legend2'
                  : !isHideFarmCollapse
                  ? 'right-map farm-map-position manage-legend'
                  : 'right-map farm-map-position'
              }
            >
              <div
                style={{
                  height: '75vh',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <>{renderMapContainer()}</>
              </div>
            </Col>
          </Row>
          {getBoundariesData?.length ? (
            <MapPreferences
              isToggle={isOpenMapModel}
              handleToggle={handleMapModel}
              loc={selectedLocation?.country?.name}
              regionId={selectedRegionId}
              isMapPerferGeoJsonLoading={mapPreferenceGeoJsonloading}
              getMapPreferencesData={getMapPreferencesData}
              getMapPreferencesGeojsonData={getMapPreferencesGeojsonData}
              geoJsonData={geoJsonData}
              mapPreferencesAllData={mapPreferencesData}
              fromFarmTrackerMonitor={true}
              cordinatesAndZoom={cordinateAndZoom}
              createFarmData={createFarmData}
            />
          ) : ''}
        </div>
      </div>
    </>
  );
};

export default FarmTrackerMap;
