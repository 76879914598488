import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js';
import _ from 'lodash';
import { GRAPH_YAXIS_COLORS } from '../../Components/constants';
import { PlotlyPNGLogo, PlotlyCSVLogo } from '../../Components/constants';
import ReactExport from 'react-export-excel';
import { renderGenericDateFormat } from '../../Components/common/utils';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const RiskAnalyticsPlotChart = (props) => {
  const { chartData, selectedParam, initialSelectedDate } = props;
  const [finalSelectedIndicators, setFinalSelectedIndicators] = useState([]);
  const [dates, setDates] = useState([]);
  const [yOptions, setYOptions] = useState([]);
  const ButtonClick = useRef('');
  const [newFileName, setFileName] = useState('');
  const [novaGfsParams, setNovaGfsParams] = useState([
    {
      name: 'NDVI',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: 'lines+markers',
    },
    {
      name: 'SMCI',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: 'lines+markers',
    },
    {
      name: 'VCI',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: 'lines+markers',
    },
    {
      name: 'Accumulated Rainfall',
      visible: 'legendonly',
      dash: 'dash',
      type: 'scatter',
      mode: 'lines+markers',
    },
    {
      name: 'Dry Spell',
      visible: 'legendonly',
      dash: 'dash',
      type: 'bar',
      mode: 'lines+markers',
    },
    {
      name: 'SPI',
      visible: 'legendonly',
      dash: 'dash',
      type: 'bar',
      mode: 'lines+markers',
    },
    {
      name: 'IDSI',
      visible: 'legendonly',
      dash: 'dash',
      type: 'scatter',
      mode: 'lines+markers',
    },
    {
      name: 'Risk Score',
      visible: 'legendonly',
      dash: 'dash',
      type: 'bar',
      mode: 'lines+markers',
    },
  ]);
  const [csvFileData, setCsvFileData] = useState([]);
  const [objKeysData, setObjKeysData] = useState([]);
  const [isFirstYAxisDeselected, setIsFirstYAxisDeselected] = useState(false);

  const [layout, updateLayout] = useState({
    title: {
      // text: `<b> Data Source : ${dataSource[0]?.name} ${dataSource[0]?.id === DATA_SOURCES.IRI.id ? '' : `(${temporalAggregation?.name})`}  </b>`,
    },
    bargap: 0.9,
    barmode: 'group',
    responsive: true,
    modebardisplay: true,
    // width: 1600,
    font: {
      size: 12,
      color: '#000',
    },
    xaxis: {
      // domain: [0, 1],
      type: 'date',
      showline: true,
      tickangle: 45,
      tickfont: {
        size: 12,
        color: '#000',
        family: 'National',
      },
      tickformat:"%d-%m-%Y",
      // tickmode: 'linear',
      showgrid: false,
      // autorange: true,
    },
    showlegend: true,
    // margin: 80,
    legend: {
      orientation: 'h',
      x: 0.5,
      y: -0.5,
      xanchor: 'center',
      font: {
        // family: 'sans-serif',
        size: 15,
        color: '#000',
        family: 'National',
      },
    },
    margin: {
      t: 50,
      b: 50,
    },
  });
  const getTickAngle = (xaxisData) => {
    let tickangle;
    if (xaxisData.length < 75 && xaxisData.length > 50) {
      tickangle = 60;
    } else if (xaxisData.length < 100 && xaxisData.length > 75) {
      tickangle = 40;
    } else {
      tickangle = 50;
    }
    return tickangle;
  };

  const handleBarGraph = (param, indicator) => {
    let gap;
    if(indicator === 1 ) {
      gap = 0.95;
    } else if(indicator === 2) {
      gap = 0.85;
    } else if(indicator === 3) {
      gap = 0.75;
    } else if(indicator === 4) {
      gap = 0.65;
    } else if(indicator === 5) {
      gap = 0.45;
    }
    return gap;
  }

  const getTickDifference = (startDate,endDate) => {
    let tickDifference;
    let months;
    let dayInMillis = 86400000;
    months = (endDate?.getFullYear() - startDate?.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
    if(months <= 2){
      tickDifference = dayInMillis
    }
    else if(months <= 12)  {
      tickDifference = 10 * dayInMillis;
    }
    else{
      tickDifference = 'M1'
    }
    return tickDifference
  }

  useEffect(() => {
    if (selectedParam?.length && chartData?.graph_data?.length) {
      let selectedIndicator = [];
      let selectedIndicatorNames = []; //for activating multiple legends at a time
      for (let indicator of novaGfsParams) {
        let filteredIndicators = chartData?.graph_data?.filter(
          (i) => i?.layerType === indicator?.name,
        );
        if (!_.isEmpty(filteredIndicators?.[0])) {
          selectedIndicator.push(filteredIndicators?.[0]);
          selectedIndicatorNames.push(filteredIndicators?.[0]?.layerType);
        }
      }
      let reOrderedParams = [];
      selectedIndicator.forEach((param, index) => {
        // if (param.layerType === selectedParameter) {
        let yaxisName = index === 0 ? 'yaxis' : `yaxis${index + 1}`;
        let layoutData = { ...layout };
        //TODO: need to check this later
        if(chartData?.graph_data?.length > 1){
          let startdate =  new Date(chartData?.graph_data[0]?.millis?.[0]) > new Date(chartData?.graph_data[1]?.millis?.[0]) ? new Date(chartData?.graph_data[1]?.millis?.[0]) : new Date(chartData?.graph_data[0]?.millis?.[0]);
          layoutData.xaxis["tick0"] = startdate
        }
        else {
          let startdate =  new Date(chartData?.graph_data[0]?.millis?.[0])
          layoutData.xaxis["tick0"] = startdate
        }
        layoutData.xaxis.dtick = getTickDifference(new Date(initialSelectedDate?.startDate), new Date(initialSelectedDate?.endDate))
        if (selectedIndicatorNames.includes(param.layerType)) {
          param.visible = true;
          reOrderedParams.push(param);
          updateLayout((preValues) => ({
            ...preValues,
            // bargap: param?.layerType === 'Accumulated Rainfall' ? '0.5' : param?.millis?.length > 1 ? '0.7' : '0.9',
            bargap: handleBarGraph(param, selectedIndicator?.length),
            xaxis: {
              ...layoutData.xaxis,
              // autorange: true,
              type: 'date',
              domain: getLayoutWidths.find((item) => item.yaxisCount === index + 1)?.domain, //for maintaing width of x-axis
              showline: true,
              tickangle: 50,
              // tickformat: '%b %d %Y',
              tickformat:"%d-%m-%Y",
              showgrid: false
            },
            [yaxisName]: {
              // title: `${param?.layerType}`, //for multiple y-axis------
              title: {
                text: handleTitle1(param?.layerType),
                plot_bgcolor: '#E5ECF6',
                font: {
                  family: 'National',
                  color: GRAPH_YAXIS_COLORS[index],
                  size: 15,
                },
              }, //for multiple y-axis------
              // titlefont: { color: '#1f77b4' },
              // tickfont: { color: '#1f77b4' },
              titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
              tickfont: { color: GRAPH_YAXIS_COLORS[index] },
              showline: true,
              side: index === 0 ? 'left' : 'right',
              position: index === 0 || index === 1 ? undefined : handlePosition(index + 1), //for positioning right in y-axis
              anchor: index === 0 ? '' : index === 1 ? 'x' : 'free',
              overlaying: index === 0 ? '' : 'y',
              range: param.layerType === 'NDVI' ? [0, 0.9] : [],
              rangemode: 'tozero',
              showgrid: false,
              zeroline: false,
            },
          }));
        }
      });
      if (reOrderedParams.length) {
        selectedIndicator.forEach((param) => {
          // if (param.layerType !== selectedParameter) {
          if (!selectedIndicatorNames.includes(param.layerType)) {
            param.visible = 'legendonly';
            reOrderedParams.push(param);
          }
        });
      }
      setFinalSelectedIndicators(reOrderedParams);
    }
  }, [selectedParam, chartData]);

  const handleGraphType = (type) => {
    if(type === 'Dry Spell' || type === 'SPI' || type === 'Risk Score') {
      return 'bar'
    } else {
      return 'scatter'
    }
  }
  
  const handleTraces = (chart_data, i, index) => {
    let datesList = i?.millis;
    let formattedDates;
    if (!_.isEmpty(datesList)) {
      formattedDates = datesList?.length
        ? datesList.map((item) => {
          return new Date(item)?.getTime();
        })
        : '';
    }
    let yAxisData = i?.data?.map((item) => item.toFixed(2)) || [];
    const ydata = {
      type: handleGraphType(i?.layerType),
      // type: 'scatter',
      mode: i?.data?.length > 1 ? 'lines' : 'markers',
      name: `${i?.layerType}`,
      x: formattedDates,
      // units: i?.measure,
      // y: chart_data?.graph_data?.[index]?.data,
      y: yAxisData,
      visible: i?.visible,
      line: { color: GRAPH_YAXIS_COLORS[index], dash: i?.dash, width: 3 },
      position: undefined,
      overlaying: index === 0 ? '' : 'y',
    };
    if(ydata?.type === 'bar') {
      ydata.offsetgroup = index + 1
      // ydata.width = i?.data?.length === 1 ? [0.05]: []
      ydata.marker = { color: GRAPH_YAXIS_COLORS[index] }
    }
    const allIndicators = [
      'NDVI',
      'SMCI',
      'VCI',
      'Accumulated Rainfall',
      'Dry Spell',
      'SPI',
      'IDSI',
      'Risk Score',
    ];
    allIndicators.forEach((indicator) => {
      if (i?.layerType === indicator) {
        if (index === 0) {
          ydata.yaxis = `y1`;
        } else {
          ydata.yaxis = `y${index + 1}`;
        }
      }
    });
    setDates(formattedDates);
    return ydata;
  };

  useEffect(() => {
    if (chartData) {
      let options = [];
      finalSelectedIndicators?.map((i, index) => {
        if (index < chartData?.graph_data?.length) {
          let returned_values = handleTraces(chartData, i, index);
          options.push(returned_values);
        }
      });
      // //setting same dates for all indicators
      // let tempDates = [];
      // //sorting and removing duplicate millies from chartData and converting into string dates
      // tempDates = [...new Set([].concat(...chartData?.graph_data.map((o) => o.millis)))].sort();
      // let sortedDates = tempDates?.map((item) => {
      //   let dateFormat = new Date(item);
      //   return renderGenericDateFormat(dateFormat);
      // });
      //updating those string dates to yOptions
      let updatedOptions = options?.map((item) => {
        let x = { ...item };
        // x.x = sortedDates?.length ? [...sortedDates] : [null];
        return x;
      });
      setYOptions(updatedOptions);
    }
  }, [chartData, finalSelectedIndicators]);

  const getActiveLegends = (isDownloadCSV, graphData) => {
    if (document.getElementById('riskGraphId')?.data?.length) {
      const activeLegends = graphData.data?.filter((item) => item.visible === true);
      if (activeLegends.map((data) => data.name)?.length) {
        //setting file name based on active legends
        setFileName(
          `Analyze_Risk-${activeLegends.map((data) => data.name.split(' ')[0]).join(' ')}`,
        );
      }
      let mappedDataList = [];
      let graphDataList = [...graphData?.data];
      graphDataList?.forEach((i,key)=>{
          mappedDataList[key] = []
          i?.x?.forEach((k,index)=>{
            let filteredData = chartData?.graph_data?.[key]
            let obj = {};
            obj.name = i?.name
            obj.date = k;
            obj.value = filteredData?.data?.[index]?.toFixed(4)
            mappedDataList[key]?.push(obj)
          })
        })
      let getAllDates = [];
      activeLegends?.forEach((i) => {
        i?.x?.forEach((k) => {
          getAllDates.push(k);
        });
      });
      let xaxisSortedDates = getAllDates.sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
      function removeDuplicates(arr) {
        let unique = [];
        for(let i=0; i < arr.length; i++){ 
            if(unique.indexOf(arr[i]) === -1) { 
                unique.push(arr[i]); 
            } 
        }
        return unique;
      }
      let xaxisValue = removeDuplicates(xaxisSortedDates);
      let tempDates = [];
      //sorting the millies from chartData and converting into string dates
      tempDates = [...new Set([].concat(...xaxisValue))].sort();
      let sortedDates = tempDates?.map((item) => {
        let dateFormat = new Date(item);
        return renderGenericDateFormat(dateFormat);
      });
      if (activeLegends?.length) {
        //formatting data for csv file
        const xaixsValue = activeLegends[0].x;
        let data = [];
        let getKey = '';
        sortedDates.forEach((date, key) => {
          activeLegends.forEach((item, index) => {
            let mappedList = mappedDataList?.find(x=> x?.find(i=> i.name === item?.name));
              let dateObj= mappedList?.find(item=> renderGenericDateFormat(item?.date) === date);
              getKey = dateObj?.value || ' -';
            if (index === 0) {
              data.push({ Date: date, [item.name]: getKey});
            } else {
              data[key][item.name] = getKey;
            }
          });
        });
        setCsvFileData(data);
        let objKeys = Object.keys(data[0]);
        if (objKeys) {
          setObjKeysData(objKeys);
        }
        if (isDownloadCSV) {
          //downloading csv file
          ButtonClick.current.click();
        } else {
          //downloading png file
          Plotly.downloadImage(graphData, {
            filename: `Analyze_Risk-${activeLegends.map((data) => data.name).join(' ')}`,
            format: 'png',
            width: graphData._fullLayout.width,
            height: graphData._fullLayout.height,
          });
        }
      }
    }
  };
  let config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true, //by default we are showing
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        click: function (gd) {
          getActiveLegends(false, gd);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        click: function (gd) {
          getActiveLegends(true, gd);
        },
      },
    ],
    showTips: false,
  };

  //for multiple y-axis------------------------
  const getLayoutWidths = [
    { yaxisCount: 1, domain: [0, 0] },
    { yaxisCount: 2, domain: [0, 0] },
    { yaxisCount: 3, domain: [0, 0.9] },
    { yaxisCount: 4, domain: [0, 0.8] },
    { yaxisCount: 5, domain: [0, 0.73] },
  ];

  const handlePosition = (axisCount, key, index) => {
    let positionValue;
    if (axisCount) {
      if (axisCount === 3) {
        if (index === 0 || isFirstYAxisDeselected) {
          if (key === 3) positionValue = 0.9;
          else if (key === 2) positionValue = 0.99;
        } else if (key === 1) positionValue = 0.93;
        else positionValue = 1;
      } else if (axisCount === 4) {
        if (index === 0 || isFirstYAxisDeselected) {
          if (key === 3) {
            positionValue = 0.89;
          } else if (key === 2) {
            positionValue = 1;
          } else if (key === 4) {
            positionValue = 0.8;
          }
        } else {
          if (index === undefined) {
            if (key === 3) {
              positionValue = 1;
            } else if (key === 2) {
              positionValue = 0.89;
            } else if (key === 4) {
              positionValue = 0.84;
            } else {
              positionValue = 0.89;
            }
          } else {
            if (axisCount === 4) {
              if (key === 3) {
                positionValue = 0.98;
              } else if (key === 2) {
                positionValue = 0.89;
              }
            } else {
              positionValue = 0.86;
            }
          }
        }
      } else if (axisCount === 5) {
        if (index === undefined) {
          if (key === undefined) {
            positionValue = 0.82;
          } else if (key === 4) {
            positionValue = 0.82;
          } else if (key === 5) {
            positionValue = 0.84;
          } else if (key === 2) {
            positionValue = 0.89;
          } else {
            positionValue = 1;
          }
        }
      } else {
        positionValue = 1;
      }
      return positionValue;
    }
  };

  //function to order multiple y axes
  const getOrderedYAxes = (layoutData, YoptionsLength, index) => {
    let yaxisArray = [];
    Object.keys(layoutData).forEach((element) => {
      if (element.includes('yaxis')) {
        yaxisArray.push(element);
      }
    });
    // let position = handlePosition(YoptionsLength);
    if (!yaxisArray.find((e) => e === 'yaxis')) {
      yaxisArray.push('yaxis');
    }
    let order = ['yaxis', 'yaxis2', 'yaxis3', 'yaxis4', 'yaxis5'];
    yaxisArray.sort((a, b) => order.indexOf(a) - order.indexOf(b));
    yaxisArray.forEach((item, key) => {
      let position = handlePosition(YoptionsLength, key, index);

      if (key === 0) {
        if (layoutData[item]) {
          layoutData[item].side = 'left';
          layoutData[item].position = undefined;
        }
      } else if (key === 1) {
        layoutData[item].anchor = 'x';
        layoutData[item].side = 'right';
        layoutData[item].position = undefined;
      } else {
        layoutData[item].anchor = 'free';
        layoutData[item].side = 'right';
        layoutData[item].position = position;
        position += 0.1;
      }
    });
    return layoutData;
  };

  const handleTitle1 = (title) => {
    if (title === 'Accumulated Rainfall') {
      return `<b>${title} (mm)</b>`;
    } else if (title === 'Dry Spell') {
      return `<b>Dry Spell (Days)</b>`;
    } else if (title === 'NDVI') {
      return `<b>${title}-(S2)</b>`;
    } else {
      return `<b>${title}</b>`;
    }
  };

  const handleLegends = (graphData) => {
    let index = graphData.expandedIndex;
    let data = graphData.data[index];
    let newYOptions = [...yOptions];
    if (data.visible !== true) {
      newYOptions[index]['yaxis'] = `y${index + 1}`;
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      if (yaxisCount > 2) {
        newYOptions[yaxisCount - 1]['position'] =
          yaxisCount === newYOptions.length ? 1 : handlePosition(yaxisCount);
      }
      setYOptions(newYOptions);
      layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      if (index !== 0) {
        if (isFirstYAxisDeselected)
          layout.xaxis.domain = getLayoutWidths.find(
            (item) => item.yaxisCount === yaxisCount + 1,
          )?.domain;
        else
          layout.xaxis.domain = getLayoutWidths.find(
            (item) => item.yaxisCount === yaxisCount,
          )?.domain;
        let yaxisName = `yaxis${index + 1}`;
        layout[yaxisName] = {
          title: {
            text: handleTitle1(data?.name),
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          anchor: yaxisCount >= 2 ? 'x' : 'free',
          overlaying: 'y',
          side: 'right',
          position: newYOptions[yaxisCount - 1]['position'],
          showline: true,
          // range: data?.name === 'NDVI' ? [0, 0.9] : [],
          rangemode: 'tozero',
          showgrid: false,
          automargin: true,
          zeroline: false,
        };
      } else if (index === 0) {
        setIsFirstYAxisDeselected(false);
        layout.xaxis.domain = getLayoutWidths.find(
          (item) => item.yaxisCount === yaxisCount,
        )?.domain;
        layout.yaxis = {
          title: {
            text: handleTitle1(data?.name),
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          side: 'left',
          showline: true,
          range: data?.name === 'NDVI' ? [0, 0.9] : [],
          rangemode: 'tozero',
          showgrid: false,
          automargin: true,
          zeroline: false,
          overlaying: '',
        };
      }
      let orderedLayoutData = getOrderedYAxes(layout, yaxisCount);
      updateLayout(orderedLayoutData);
    } else {
      delete newYOptions[index]['yaxis'];
      setYOptions(newYOptions);
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      let yaxisName = index === 0 ? `yaxis` : `yaxis${index + 1}`;
      let layoutData = { ...layout };
      delete layoutData[yaxisName];
      let axisCount;
      newYOptions?.forEach(item => {
        if(item?.type === 'bar') {
          if(item?.visible === true) {
            axisCount += 1
          }
        }
      })
      if(axisCount >=1) {
        layoutData['bargap'] = '0.9'
      }
      if (yaxisName === "yaxis") {
        layoutData[yaxisName] = {showgrid: false, tickfont: {color:"#fff"}, zeroline: false}
      }
      if (index === 0) setIsFirstYAxisDeselected(true);
      let updatedYAxisCount = index === 0 || isFirstYAxisDeselected ? yaxisCount + 1 : yaxisCount;
      layout.xaxis.domain = getLayoutWidths.find(
        (item) => item.yaxisCount === updatedYAxisCount,
      )?.domain;
      // layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      let orderedLayoutData = getOrderedYAxes(layoutData, yaxisCount, index);
      updateLayout(orderedLayoutData);
    }
  };

  return (
    <>
     {!_.isEmpty(chartData?.graph_data) ? (
      <>
      <Plot
        data={yOptions}
        layout={layout}
        config={config}
        onLegendClick={(data) => handleLegends(data)}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        divId="riskGraphId"
      />
      <ExcelFile
        filename={newFileName}
        element={
          <button
            ref={ButtonClick}
            className="btn btn-download ml-auto"
            style={{ display: 'none' }}
          >
            Download
          </button>
        }
      >
        <ExcelSheet data={csvFileData} name="Risk_Analytics">
          {objKeysData?.map((item) => (
            <ExcelColumn label={item} value={item} />
          ))}
        </ExcelSheet>
      </ExcelFile>
      </>
     ) : <></> }
    </>
  );
};
export default RiskAnalyticsPlotChart;
