export const LOGIN_CONST = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGIN_CAROUSEL_REQUEST: 'LOGIN_CAROUSEL_REQUEST',
  LOGIN_CAROUSEL_SUCCESS: 'LOGIN_CAROUSEL_SUCCESS',
  LOGIN_CAROUSEL_FAILED: 'LOGIN_CAROUSEL_FAILED',
};

export const FORGOT_PASSWORD = {
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILED: 'FORGOT_PASSWORD_FAILED'
}

export const CHANGE_PASSWORD = {
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED'
}

export const RESET_PASSWORD = {
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED'
}

export const LOGIN_DETAIL_CONST = {
  LOGIN_DETAIL_REQUEST:'LOGIN_DETAIL_REQUEST',
  LOGIN_DETAIL_SUCCESS: 'LOGIN_DETAIL_SUCCESS',
  LOGIN_DETAIL_FAILED: 'LOGIN_DETAIL_FAILED'
}

export const PROJECT_FARM_LIST_CONST = {
  PROJECT_FARM_LIST_REQUEST:'PROJECT_FARM_LIST_REQUEST',
  PROJECT_FARM_LIST_SUCCESS: 'PROJECT_FARM_LIST_SUCCESS',
  PROJECT_FARM_LIST_FAILED: 'PROJECT_FARM_LIST_FAILED'
}


export const INSURANCE_USER_UPDATE_CONST = {
  INSURANCE_USER_UPDATE_REQUEST:'INSURANCE_USER_UPDATE_REQUEST',
  INSURANCE_USER_UPDATE_SUCCESS: 'INSURANCE_USER_UPDATE_SUCCESS',
  INSURANCE_USER_UPDATE_FAILED: 'INSURANCE_USER_UPDATE_FAILED'
}
