import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import Plotly from 'plotly.js';
import Plot from 'react-plotly.js';
import {
  PlotlyPNGLogo,
  PlotlyCSVLogo,
  CHART_LABEL_COLORS,
  CWP_COLORS,
  CWP_PERENNIAL_COLORS,
} from '../../../Components/constants';
import { Alert } from 'reactstrap';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const CropGraphData = (props) => {
  const {
    graphList,
    finalGraphList,
    isCWPGraphSingleCase,
    formData,
    fileName,
    showErrorMsg,
    isLoading,
    errorMessage,
  } = props;
  const [yOptions, setYOptions] = useState([]);
  const [dates, setDates] = useState('');
  const [finalSelectedIndicators, setFinalSelectedIndicators] = useState('');
  const [layout, updateLayout] = useState({
    title: {
      // text: `<b> Data Source : ${dataSource[0]?.name} ${dataSource[0]?.id === DATA_SOURCES.IRI.id ? '' : `(${temporalAggregation?.name})`}  </b>`,
    },
    bargap: 0.15,
    barmode: 'group',
    modebardisplay: true,
    font: {
      size: 12,
      color: '#000',
    },
    xaxis: {
      domain: [0, 1],
      autorange: true,
      range: [-0.5, 0.5],
      showline: true,
      showgrid: false,
      tickangle: 90,
      tickfont: {
        size: 12,
        color: '#000',
        family: 'National',
      },
    },
    yaxis: {
      showline: true,
      titlefont: { color: '#008631' },
      tickfont: { color: '#008631' },
      showgrid: false,
    },
    yaxis2: {
      showline: true,
      titlefont: { color: '#1f77b4' },
      tickfont: { color: '#1f77b4' },
      side: 'right',
      overlaying: 'y',
      anchor: 'x',
      showgrid: false,
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      x: 0.5,
      y: 0,
      xanchor: 'center',
      font: {
        // family: 'sans-serif',
        size: 15,
        color: '#000',
        family: 'National',
      },
    },
  });

  const ButtonClick = useRef('');
  const [csvFileData, setCsvFileData] = useState([]);
  const [cwpParams, setCwpParams] = useState([
    {
      name: 'Sown Area (ACRES)',
      visible: true,
      dash: '',
      type: 'bar',
      mode: 'lines',
    },
    {
      name: 'Harvested Area (ACRES)',
      visible: true,
      dash: '',
      type: 'bar',
      mode: 'lines',
    },
    {
      name: 'Yield (BUSHELS PER NETT ACRE)',
      visible: true,
      dash: '',
      type: 'scatter',
      mode: 'lines',
    },
  ]);

  const getTickAngle = (start, end, season, isSingleCase) => {
    let tickangle = null;
    let maxRange = season?.length === 2 || season?.some((i) => i.slug === 'maha') ? 3 : 10;
    if (end - start >= maxRange || !isSingleCase) {
      tickangle = 90;
    } else {
      tickangle = 0;
    }
    return tickangle;
  };

  const getYSpace = (season, isSingleCase) => {
    let space;
    if (season?.length === 2 || season?.some((i) => i.slug === 'maha') || !isSingleCase) {
      space = -0.9;
    } else {
      space = -0.2;
    }
    return space;
  };

  useEffect(() => {
    if (finalGraphList?.length && isCWPGraphSingleCase) {
      let finalLayout = _.cloneDeep(layout);
      let tickangle =
        getTickAngle(
          formData?.startYear?.value,
          formData?.endYear?.value,
          formData?.season,
          isCWPGraphSingleCase,
        ) || '';
      let legendspaceToGraph = getYSpace(formData?.season, isCWPGraphSingleCase);
      finalLayout = {
        ...layout,
        bargap: 0.8,
        xaxis: {
          domain: [0, 0.9],
          showline: true,
          autorange: true,
          range: [-0.5, 0.5],
          showgrid: false,
          tickangle,
        },
        yaxis: {
          // title: `Area(Acre)`, //for multiple y-axis------
          title: {
            text: `<b>Area(Acre)</b>`,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: '#008631',
              size: 15,
            },
          },
          titlefont: { color: '#008631', family: 'National' },
          tickfont: { color: '#008631' },
          showline: true,
          showgrid: false,
          zeroline: false,
          rangemode: 'tozero',
        },
        yaxis2: {
          title: {
            text: `<b>Yield(Kg)</b>`,
            standoff: 10,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: '#1f77b4',
              size: 15,
            },
          }, //for multiple y-axis------
          titlefont: { color: '#1f77b4', family: 'National' },
          tickfont: { color: '#1f77b4' },
          showline: true,
          anchor: 'x',
          overlaying: 'y',
          side: 'right',
          showgrid: false,
          zeroline: false,
          rangemode: 'tozero',
        },
        legend: { orientation: 'h', x: 0.5, xanchor: 'center', y: legendspaceToGraph },
      };
      updateLayout(finalLayout);
      setFinalSelectedIndicators(finalGraphList);
    }
  }, [finalGraphList]);

  useEffect(() => {
    let finalLayout = {
      ...JSON.parse(JSON.stringify(layout)),
      bargap: yOptions.length > 9 ? 0 : 0.5,
    };
    updateLayout(finalLayout);
  }, [yOptions]);

  useEffect(() => {
    if (finalGraphList?.length && !isCWPGraphSingleCase) {
      let finalLayout = _.cloneDeep(layout);
      let tickangle = getTickAngle(
        formData?.startYear?.value,
        formData?.endYear?.value,
        formData?.season,
        false,
      );
      let legendspaceToGraph = getYSpace(formData?.season, isCWPGraphSingleCase);
      finalLayout = {
        ...layout,
        legend: {
          orientation: 'h',
          x: 0.5,
          xanchor: 'center',
          y: legendspaceToGraph,
        },
        xaxis: {
          ...layout.xaxis,
          tickangle: tickangle,
          showgrid: false,
        },
      };
      finalGraphList?.map((param, index) => {
        if (index === 0 && param?.showlegend) {
          finalLayout['yaxis'] = {
            // title: `Area(Acre)`, //for multiple y-axis------
            title: {
              text: `<b>Area(Acre)</b>`,
              plot_bgcolor: '#E5ECF6',
              font: {
                family: 'National',
                color: '#008631',
                size: 15,
              },
            },
            titlefont: { color: '#008631', family: 'National' },
            tickfont: { color: '#008631' },
            showline: true,
            showgrid: false,
            zeroline: false,
            rangemode: 'tozero',
          };
        }
        if (param?.crop_area === cwpParams[2]?.name && param?.showlegend) {
          finalLayout['yaxis2'] = {
            // title: `Yield(Kg)`, //for multiple y-axis------
            title: {
              text: `<b>Yield(Kg)</b>`,
              plot_bgcolor: '#E5ECF6',
              font: {
                family: 'National',
                color: '#1f77b4',
                size: 15,
              },
            },
            titlefont: { color: '#1f77b4', family: 'National' },
            tickfont: { color: '#1f77b4' },
            showline: true,
            anchor: 'x',
            overlaying: 'y',
            side: 'right',
            showgrid: false,
            rangemode: 'tozero',
            zeroline: false,
          };
        }
      });
      updateLayout(finalLayout);
      setFinalSelectedIndicators(finalGraphList);
    }
  }, [finalGraphList, isCWPGraphSingleCase]);

  useEffect(() => {
    if (finalSelectedIndicators?.length) {
      let options = [];
      finalSelectedIndicators?.map((i, index) => {
        let returned_values = handleNormalCaseTraces(
          finalSelectedIndicators,
          i,
          index,
          isCWPGraphSingleCase,
        );
        options.push(returned_values);
      });
      if (options[0].name == 'Yield(Kg)') {
        let ele = options.splice(0, 1);
        options.push(...ele);
      }
      setYOptions(options);
    }
  }, [finalSelectedIndicators, isCWPGraphSingleCase]);

  const handleNormalCaseTraces = (chart_data, i, index, singleCase) => {
    let xaxisDates = [];
    xaxisDates = chart_data[index]?.xaxisData?.length ? chart_data[index]?.xaxisData : [];
    const colorsForPractice = CWP_COLORS[i.legendgroup];
    const ydata = {
      type: chart_data[index]?.crop_area === cwpParams[2]?.name ? 'scatter' : 'bar',
      // mode: 'lines',
      mode: i?.data?.length > 1 ? 'lines' : 'markers',
      // name: chart_data[index]?.crop_area === cwpParams[2]?.name ? 'Yield (Kg)' : `${i?.crop_area}`,
      x: xaxisDates,
      y:
        chart_data[index]?.crop_area === cwpParams[2]?.name
          ? chart_data[index]?.data?.map((data) => data * 36872)
          : chart_data[index]?.data,
      visible: i?.visible,
      position: undefined,
      showgrid: false,
      showlegend: chart_data[index]?.showlegend,
    };
    if (ydata.type === 'scatter') {
      ydata.line = { color: colorsForPractice[i?.crop_area] };
    } else {
      ydata.marker = { color: colorsForPractice[i?.crop_area] };
    }
    if (!singleCase) {
      ydata.legendgroup = i?.legendgroup;
      if (ydata.showlegend) ydata.legendgrouptitle = i?.legendgrouptitle;
    }

    if (i?.crop_area === 'Sown Area (ACRES)') {
      ydata.yaxis = `y1`;
      ydata.name = 'Sown Area(Acre)';
    } else if (i?.crop_area === 'Harvested Area (ACRES)') {
      ydata.yaxis = `y1`;
      ydata.name = 'Harvested Area(Acre)';
    } else if (i?.crop_area === 'Yield (BUSHELS PER NETT ACRE)') {
      ydata.yaxis = 'y2';
      ydata.name = 'Yield(Kg)';
    }

    setDates(xaxisDates);
    return ydata;
  };
  const handleLegends = (graphData) => {
    let index = graphData.expandedIndex;
    let data = graphData.data[index];
    let newYOptions = [...yOptions];
    if (data && data.visible !== true) {
      if (index !== 0 && data?.name === cwpParams[2]?.name) {
        if (newYOptions[index]) newYOptions[index]['yaxis'] = `y2`;
        setYOptions(newYOptions);
        let yaxisName = `yaxis2`;
        layout[yaxisName] = {
          // title: `Yield(Kg)`,
          title: {
            text: `<b>Yield(Kg)</b>`,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: '#1f77b4',
              size: 15,
            },
          },
          titlefont: { color: '#1f77b4', family: 'National' },
          tickfont: { color: '#1f77b4' },
          anchor: 'x',
          overlaying: 'y',
          side: 'right',
          showgrid: false,
          showline: true,
          zeroline: false,
          rangemode: 'tozero',
        };
      } else if (index === 0) {
        newYOptions[index]['yaxis'] = `y1`;
        setYOptions(newYOptions);
        layout.yaxis = {
          // title: `Area(Acre)`,
          title: {
            text: `<b>Area(Acre)</b>`,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: '#008631',
              size: 15,
            },
          },
          titlefont: { color: '#008631', family: 'National' },
          tickfont: { color: '#008631' },
          side: 'left',
          showline: true,
          showgrid: false,
          zeroline: false,
          rangemode: 'tozero',
        };
      }
      updateLayout(layout);
    }
  };

  const getActiveLegends = (isDownloadCSV, graphData, graphList) => {
    if (document.getElementById(fileName)?.data?.length) {
      if (graphList.length) {
        let tempData = [...graphList];
        graphList?.forEach((item, index) => {
          if (item.crop_area === 'Sown Area (ACRES)') {
            tempData[index].crop_area = 'Sown Area(Acre)';
          } else if (item.crop_area === 'Harvested Area (ACRES)') {
            tempData[index].crop_area = 'Harvested Area(Acre)';
          } else if (item.crop_area === 'Yield (BUSHELS PER NETT ACRE)') {
            tempData[index].crop_area = 'Yield(Kg)';
            tempData[index].production_data = Number(item?.production_data) * 36872;
          }
        });
        setCsvFileData(tempData);
      }
      if (isDownloadCSV) {
        //downloading csv file
        ButtonClick.current.click();
      } else {
        //downloading png file
        Plotly.downloadImage(graphData, {
          filename: fileName,
          format: 'png',
          width: graphData._fullLayout.width,
          height: graphData._fullLayout.height,
        });
      }
    }
  };

  let config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true, //by default we are showing
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        click: function (gd) {
          getActiveLegends(false, gd, graphList);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        click: function (gd) {
          getActiveLegends(true, gd, graphList);
        },
      },
    ],
    showTips: false,
  };

  return (
    <div
      className="crop-prod-graph-div mx-2
     px-4 py-4"
      //  my-2
      style={{ height: '75vh', marginBottom: '15px' }}
    >
      <>
        {!_.isEmpty(finalGraphList) ? (
          <>
            {yOptions.find((data) => data.name === 'Yield(Kg)')?.y?.length === 1 && (
              <Plot
                data={yOptions}
                layout={layout}
                config={config}
                onLegendClick={(data) => handleLegends(data)}
                useResizeHandler={true}
                style={{ width: '100%', height: '100%' }}
                divId={fileName}
              />
            )}
            {yOptions.find((data) => data.name === 'Yield(Kg)')?.y?.length !== 1 && (
              <Plot
                data={yOptions}
                layout={layout}
                config={config}
                onLegendClick={(data) => handleLegends(data)}
                useResizeHandler={true}
                style={{ width: '100%', height: '100%' }}
                divId={fileName}
              />
            )}
            <ExcelFile
              filename={fileName}
              element={
                <button
                  ref={ButtonClick}
                  className="btn btn-download ml-auto"
                  style={{ display: 'none' }}
                >
                  Download
                </button>
              }
            >
              <ExcelSheet data={csvFileData} name="Risk_Analytics">
                <ExcelColumn label="Year" value="year" />
                <ExcelColumn label="District" value="district_name" />
                <ExcelColumn label="Crop" value="crop_name" />
                <ExcelColumn label="Season" value="season_name" />
                <ExcelColumn label="Practise" value="practice_name" />
                <ExcelColumn label="Crop area" value="crop_area" />
                <ExcelColumn label="Production Data" value="production_data" />
              </ExcelSheet>
            </ExcelFile>
          </>
        ) : !isLoading ? (
          <div
            className="display-flex align-items-center justify-content-center text-align-center"
            style={{ marginTop: '25%', textAlign: 'center' }}
          >
            {showErrorMsg ? (
              <h3 style={{ fontSize: '20px' }}>Data is not available for the selected year.</h3>
            ) : (
              // <h3 style={{ fontSize: '20px' }}>
              //   Please select the filters from left side panel, to view the graph data.
              // </h3>
              errorMessage ? (
                <div className="drought-error-container">
                <div className="drought-error-style">
                  <Alert color="warning">{errorMessage}</Alert>
                </div>
              </div>
              ):''
            )}
          </div>
        ) : (
          ''
        )}
      </>
    </div>
  );
};

CropGraphData.propTypes = {};

export default CropGraphData;
