import { CROP_PRODUCTION_FILTER_TABS } from '../../Components/constants';
import RISK_ANALYTICS_CONST from './riskAnalyticsConsts';

const RISK_ANALYTICS = (state = {riskScoreTimeSeriesDataError: false}, action) => {
  switch (action.type) {
    case RISK_ANALYTICS_CONST.GET_RISK_SCORE_REQUEST:
      return {
        ...state,
        riskScoreTimeSeriesDataRequesting: true,
        riskScoreTimeSeriesData: false,
        riskScoreTimeSeriesDataError: false,
      };
    case RISK_ANALYTICS_CONST.GET_RISK_SCORE_SUCCESS:
      return {
        ...state,
        riskScoreTimeSeriesDataRequesting: false,
        riskScoreTimeSeriesData: action.payload.response,
        riskScoreTimeSeriesDataError: false,
      };
    case RISK_ANALYTICS_CONST.GET_RISK_SCORE_FAILED:
      return {
        ...state,
        riskScoreTimeSeriesDataRequesting: false,
        riskScoreTimeSeriesData: false,
        // riskScoreTimeSeriesDataError: action.payload.error ? false : action.payload.response,
        riskScoreTimeSeriesDataError: action.payload?.response,
      };

    case RISK_ANALYTICS_CONST.GET_RISK_INDICES_REQUEST:
      return {
        ...state,
        indicesDataRequesting: true,
        indicesData: false,
        indicesDataError: false,
      };
    case RISK_ANALYTICS_CONST.GET_RISK_INDICES_SUCCESS:
      return {
        ...state,
        indicesDataRequesting: false,
        indicesData: action.payload.response,
        indicesDataError: false,
      };
    case RISK_ANALYTICS_CONST.GET_RISK_INDICES_FAILED:
      return {
        ...state,
        indicesDataRequesting: false,
        indicesData: false,
        indicesDataError: action.payload.error ? false : action.payload.response,
      };
    case RISK_ANALYTICS_CONST.GET_INDICES_CHANGE_REQUEST:
      return {
        ...state,
        indicesNewDataRequesting: true,
        indicesNewData: false,
        indicesNewDataError: false,
      };
    case RISK_ANALYTICS_CONST.GET_INDICES_CHANGE_SUCCESS:
      return {
        ...state,
        indicesNewDataRequesting: false,
        indicesNewData: action.payload.response,
        indicesNewDataError: false,
      };
    case RISK_ANALYTICS_CONST.GET_INDICES_CHANGE_FAILED:
      return {
        ...state,
        indicesNewDataRequesting: false,
        indicesNewData: false,
        indicesNewDataError: action.payload.error ? false : action.payload.response,
      };

    case RISK_ANALYTICS_CONST.SEND_EMAIL_REQUEST:
      return {
        ...state,
        sendEmailDataRequesting: true,
        sendEmailData: false,
        sendEmailDataError: false,
      };
    case RISK_ANALYTICS_CONST.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailDataRequesting: false,
        sendEmailData: action.payload.response,
        sendEmailDataError: false,
      };
    case RISK_ANALYTICS_CONST.SEND_EMAIL_FAILED:
      return {
        ...state,
        sendEmailDataRequesting: false,
        sendEmailData: false,
        sendEmailDataError: action.payload.error ? false : action.payload.response,
      };
    case RISK_ANALYTICS_CONST.GET_CWP_CROPS_LIST_REQUEST:
      return {
        ...state,
        getCWPCropsListRequesting: true,
        getCWPSeasonalCropsList: false,
        getCWPPerennialCropsList: false,
        getCWPCropsListError: false,
      };
    case RISK_ANALYTICS_CONST.GET_CWP_CROPS_LIST_SUCCESS:
      return {
        ...state,
        getCWPCropsListRequesting: false,
        getCWPSeasonalCropsList: action.payload.response?.crop_type === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value ? action.payload.response : state.getCWPSeasonalCropsList,
        getCWPPerennialCropsList: action.payload.response?.crop_type === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value ? action.payload.response : state.getCWPPerennialCropsList,
        getCWPCropsListError: false,
      };
    case RISK_ANALYTICS_CONST.GET_CWP_CROPS_LIST_FAILED:
      return {
        ...state,
        getCWPCropsListRequesting: false,
        getCWPSeasonalCropsList: false,
        getCWPPerennialCropsList: false,
        getCWPCropsListError: action.payload.error ? false : action.payload.response,
      };
    case RISK_ANALYTICS_CONST.GET_CWP_SEASONS_LIST_REQUEST:
      return {
        ...state,
        getCWPSeasonsListRequesting: true,
        getCWPSeasonsList: false,
        getCWPSeasonsListError: false,
      };
    case RISK_ANALYTICS_CONST.GET_CWP_SEASONS_LIST_SUCCESS:
      return {
        ...state,
        getCWPSeasonsListRequesting: false,
        getCWPSeasonsList: action.payload.response,
        getCWPSeasonsListError: false,
      };
    case RISK_ANALYTICS_CONST.GET_CWP_SEASONS_LIST_FAILED:
      return {
        ...state,
        getCWPSeasonsListRequesting: false,
        getCWPSeasonsList: false,
        getCWPSeasonsListError: action.payload.error ? false : action.payload.response,
      };
    case RISK_ANALYTICS_CONST.GET_CWP_PRACTISES_LIST_REQUEST:
      return {
        ...state,
        getCWPPractisesListRequesting: true,
        getCWPPractisesList: false,
        getCWPPractisesListError: false,
      };
    case RISK_ANALYTICS_CONST.GET_CWP_PRACTISES_LIST_SUCCESS:
      return {
        ...state,
        getCWPPractisesListRequesting: false,
        getCWPPractisesList: action.payload.response,
        getCWPPractisesListError: false,
      };
    case RISK_ANALYTICS_CONST.GET_CWP_PRACTISES_LIST_FAILED:
      return {
        ...state,
        getCWPPractisesListRequesting: false,
        getCWPPractisesList: false,
        getCWPPractisesListError: action.payload.error ? false : action.payload.response,
      };
    case RISK_ANALYTICS_CONST.GET_CWP_GRAPH_DATA_REQUEST:
        return {
          ...state,
          getCWPGraphDataRequesting: true,
          getCWPGraphData: false,
          getCWPGraphDataError: false,
        };
    case RISK_ANALYTICS_CONST.GET_CWP_GRAPH_DATA_SUCCESS:
        return {
          ...state,
          getCWPGraphDataRequesting: false,
          getCWPGraphData : action.payload.response,
          getCWPGraphDataError: false,
        };
    case RISK_ANALYTICS_CONST.GET_CWP_GRAPH_DATA_FAILED:
        return {
          ...state,
          getCWPGraphDataRequesting: false,
          getCWPGraphData: false,
          getCWPGraphDataError: action.payload.error ? false : action.payload.response,
        };
    default:
      return state;
  }
};

export default RISK_ANALYTICS;
