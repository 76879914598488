import React from 'react';
import PropTypes from 'prop-types';

function SuccessModal(props) {
  const { okayButtonClickHandler, modalSuccessMessage, modalHeaderMessage } = props;
  const handleButtonClick = () => {
    okayButtonClickHandler(true);
  }
  return (
    <div className="reg-success">
      {/* <img src={RgisterCheckmark}  alt={"not-available"} className="reg-check" /> */}
      <h3>{modalHeaderMessage}</h3>
      <p>
        {modalSuccessMessage}
      </p>
      <button onClick={() => handleButtonClick()}>OK</button>
    </div>
  );
}
export default SuccessModal;
SuccessModal.propTypes = {
  okayButtonClickHandler: PropTypes.func,
  modalSuccessMessage: PropTypes.string,
  modalHeaderMessage:PropTypes.string,
};