import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card } from 'reactstrap';
import CommonPlotly from './CommonPlotly';
import ReactExport from 'react-export-excel';
import Loader from '../../../Components/common/loader';
const endpoint = 'https://geoglows.ecmwf.int/api/';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export default function TabFour(props) {
  const { reachId, dailyAverageData, latitude, longitude } = props;
  const [traces, updateTraces] = useState([]);
  const [isLoading, updateLoading] = useState(true);
  const [dailyAverageDataStructure, setDailyAverageDataStructure] = useState();
  const [monthlyAverageDataStructure, setMonthlyAverageDataStructure] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [error, upadteError] = useState(false);
  const [toggleDownloads, setToggleDownloads] = useState({
    isShowMonth: true,
    isShowDaily: true,
  });
  dailyAverageData.time_series.datetime.map((date) => `${date}/${new Date().getFullYear()}`);
  const monthsArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  useEffect(() => {
    if (reachId) {
      axios
        .get(endpoint + 'MonthlyAverages/?return_format=json&reach_id=' + reachId)
        .then((res) => {
          if (res.data && res.status === 200) {
            const data = res.data;

            let structureForDailyAverage = dailyAverageData.time_series.datetime.map(
              (item, index) => {
                return {
                  date: `${item}/${new Date().getFullYear()}`,
                  flow: dailyAverageData.time_series.flow[index],
                };
              },
            );
            setDailyAverageDataStructure(structureForDailyAverage);
            let structureForMonthlyAverage = data.time_series.datetime.map((item, index) => {
              return {
                month: `${item}/01/${new Date().getFullYear()}`,
                flow: data.time_series.flow[index],
              };
            });
            setMonthlyAverageDataStructure(structureForMonthlyAverage);

            let traces = [
              {
                name: 'Daily',
                x: dailyAverageData.time_series.datetime.map(
                  (date) => new Date(`${date}/${new Date().getFullYear()}`),
                ),
                y: dailyAverageData['time_series']['flow'],
                mode: 'lines',
                type: 'scatter',
                fill: 'none',
                xhoverformat: '%b %d\n%Y',
              },
              {
                name: 'Monthly',
                x: data.time_series.datetime.map(
                  (date, index) => new Date(`${monthsArray[index]} ${new Date().getFullYear()}`),
                ),
                y: data['time_series']['flow'],
                type: 'bar',
              },
            ];
            updateTraces(traces);
            updateLoading(false);
          }
        })
        .catch(function (error) {
          // handle error
          upadteError(true);
        });
    }
  }, [reachId]);

  let layout = {
    // title: {
    //   text: `Simulated Average :`,
    //   xref: "paper",
    //   x: 0,
    // },
    width: 900,
    xaxis: {
      tickangle: 60,
      tickformat: '%b',
      dtick: 'M1',
      showgrid: false,
    },
    yaxis: {
      title: 'Flow (cub m)',
      showline: true,
      showgrid: false,
    },
    font: {
      size: 12,
      color: '#3870AF',
      weight: 'bold',
    },
    // legend: { orientation: 'h' },
  };
  const handleLegends = (graphData) => {
    const selectedData = graphData.data[graphData.expandedIndex];
    if (selectedData.name === 'Daily') {
      setToggleDownloads((pre) => ({
        ...pre,
        isShowDaily: selectedData.visible === 'legendonly',
      }));
    } else if (selectedData.name === 'Monthly') {
      setToggleDownloads((pre) => ({
        ...pre,
        isShowMonth: selectedData.visible === 'legendonly',
      }));
    }
  };
  return (
    <>
      {!isLoading ? (
        <>
          <div className="d-flex py-3">
            <div className=" blue-text">
              <h5>
                Reach ID : <b>{reachId}</b> (Lat : {latitude}, Log : {longitude})
              </h5>
            </div>
          </div>
          <Card>
            <CommonPlotly
              traces={traces}
              layout={layout}
              handleLegends={handleLegends}
              customFileName={`${reachId}_AverageFlow`}
            />
          </Card>
          <div className="mt-2">
            {traces?.length && (toggleDownloads.isShowMonth || toggleDownloads.isShowDaily) ? (
              <Dropdown
                direction="up"
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
                style={{ float: 'right' }}
                
              >
                <DropdownToggle caret color="primary" className='btn btn-download'>
                  Download
                </DropdownToggle>
                <DropdownMenu>
                  {toggleDownloads.isShowMonth && (
                    <DropdownItem>
                      <a
                        role="button"
                        id="download-monthly-btn"
                        // target="_blank"
                        href={`${endpoint}MonthlyAverages/?reach_id=${reachId}`}
                        rel="noreferrer"
                      >
                        Monthly
                      </a>
                    </DropdownItem>
                  )}
                  {toggleDownloads.isShowMonth && toggleDownloads.isShowDaily && (
                    <DropdownItem divider />
                  )}
                  {toggleDownloads.isShowDaily && (
                    <DropdownItem>
                      {' '}
                      <a
                        role="button"
                        id="download-daily-btn"
                        // target="_blank"
                        href={`${endpoint}DailyAverages/?reach_id=${reachId}`}
                        rel="noreferrer"
                      >
                        Daily
                      </a>
                    </DropdownItem>
                  )}
                  {toggleDownloads.isShowMonth && toggleDownloads.isShowDaily && (
                    <>
                      <DropdownItem divider />
                      <DropdownItem>
                        {dailyAverageDataStructure && monthlyAverageDataStructure && (
                          <ExcelFile
                            filename={`${reachId}_AverageFlow`}
                            // element={<button className="linkbtn">Download Both Averages</button>}
                            element={
                              <a
                                role="button"
                                id="download-daily-btn"
                                href={`javascript:void(0)`}
                                rel="noreferrer"
                              >
                                Both
                              </a>
                            }
                          >
                            <ExcelSheet data={dailyAverageDataStructure} name="DailyAverageData">
                              <ExcelColumn label="Date" value="date" />
                              <ExcelColumn label="value" value="flow" />
                            </ExcelSheet>
                            <ExcelSheet
                              data={monthlyAverageDataStructure}
                              name="MonthlyAverageData"
                            >
                              <ExcelColumn label="Month" value="month" />
                              <ExcelColumn label="value" value="flow" />
                            </ExcelSheet>
                          </ExcelFile>
                        )}
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </Dropdown>
            ) : (
              ''
            )}
          </div>
        </>
      ) : error ? (
        <span>We're sorry! Server is not responding. Please try again.</span>
      ) : (
        <Loader />
      )}
    </>
  );
}
