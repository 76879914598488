import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, API_BASE_URL, WEATHER_MONITOR_URL } from '../api/apiEndPoint';
import DROUGHT_CONST from './droughtMonitorConsts';
import {
  getDroughtMapSuccess,
  getDroughtMapFailed,
  getDroughtTimeSeriesSuccess,
  getDroughtTimeSeriesFailed,
} from './droughtMonitorActions';

const getDroughtMapApiRequest = async (payload) => {
  let response = {};
  try {
    const config = {
      headers: {
        Authorization: `Api-Key ${process.env.REACT_APP_API_TOKEN}`,
      },
    };
    const responseData = await AXIOS_INSTANCE.post(
      `${WEATHER_MONITOR_URL}/${payload.apiURL.selectedIndicator}/map`,
      payload.requestObj,
      config,
    )
    response = {
      data: responseData.data,
      isDroughtModule: payload.isDroughtModule,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      isDroughtModule: payload.isDroughtModule,
      status: 400,
    };
  }
  return response;
};

function* getDroughtMap(action) {
  try {
    const apiResponse = yield call(getDroughtMapApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
          isDroughtModule: apiResponse.isDroughtModule
        },
      };
      yield put(getDroughtMapSuccess(responseData));
    }
    else if(apiResponse.status === 400){
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getDroughtMapFailed(responseData));
    }
  } catch (error) {
    if (error.response.status === 400) {
      const responseData = {
        data: error.response.data,
      };
      yield put(getDroughtMapFailed(responseData));
    } else {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(getDroughtMapFailed(errorData));
    }
  }
}

const getDroughtTimeseriesApiRequest = async (payload) => {
  let response = {};
  try {
    const config = {
      headers: {
        Authorization: `Api-Key ${process.env.REACT_APP_API_TOKEN}`,
      },
    };
    const data = await AXIOS_INSTANCE.post(
      `${WEATHER_MONITOR_URL}/${payload.apiURL}/timeseries`,
      payload.requestObj,
      config,
    );
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getDroughtTimeseriesData(action) {
  try {
    const apiResponse = yield call(getDroughtTimeseriesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDroughtTimeSeriesSuccess(responseData));
    }
    else if(apiResponse.status === 400){
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getDroughtTimeSeriesFailed(responseData));
    }
  } catch (error) {
    if (error.response.status === 400) {
      const responseData = {
        data: error.response.data,
      };
      yield put(getDroughtTimeSeriesFailed(responseData));
    } else {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(getDroughtTimeSeriesFailed(errorData));
    }
  }
}

export function* watchGetDroughtMap() {
  yield takeEvery(DROUGHT_CONST.GET_DROUGHT_MAP_REQUEST, getDroughtMap);
}

export function* watchGetDroughtTimeseries() {
  yield takeEvery(DROUGHT_CONST.GET_DROUGHT_TIMESERIES_REQUEST, getDroughtTimeseriesData);
}

function* DroughtMonitorSaga() {
  yield all([fork(watchGetDroughtMap), fork(watchGetDroughtTimeseries)]);
}

export default DroughtMonitorSaga;
