import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Container,
  Input,
  FormGroup, Modal, ModalHeader, ModalBody, ModalFooter,
  Button
} from 'reactstrap';
import classnames from 'classnames';
import { useTranslation, t } from 'react-multi-lang';
// import { userListRequest, userListSuccess } from '../../redux/manage-user/manageUserAction';
import {
  getFieldDataRequest,
  componentsListRequest,
  componentsListSuccess,
  componentsByIdRequest,
  submitConfigurationRequest,
  submitConfigurationSuccess,
} from '../../redux/manageFieldData/manageFieldDataActions';
import _, { lowerCase } from 'lodash';
import { ManageUserTable } from './Table.js';
import { ManageUserTable1 } from './EvaluationTable.js';
import { loginDetailrequest, insuranceUserUpdaterequest, insuranceUserUpdateSuccess } from '../../redux/login/loginActions';

import Loader from '../../Components/common/loader';

import { Field, reduxForm, change } from 'redux-form';

import compose from 'compose-function';

import { renderTextField, renderDateField } from '../../Components/common/renderInputField';

import ConfigurationIcon from '../../assets/Images/configuration.png';

import FilterIcon from '../../assets/Images/filter.png';
import SolidFilterIcon from '../../assets/Images/solidFilter.png';

import moment from 'moment';


import { DATE_FORMAT } from '../../Components/constants/index';
import { showSuccess } from '../../Components/Notifications';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import Loader from '../../Components/common/loader';
import ReactTooltip from 'react-tooltip';

import { API_BASE_URL, AXIOS_INSTANCE, RISK_ANALYTICS } from '../../redux/api/apiEndPoint';
import RightArrow from '../../assets/Images/back-button.svg';

import jwt_decode from 'jwt-decode';


import {
  COUNTRIES_JSON_LIST,
  SCOPE,
  discoveryUrl,
  CLIENT_ID,
  API_KEY,
  SHEETS_SCOPE,
  SHEETS_DISCOVERY_URL
} from '../../Components/constants/index';
import { exec } from 'apexcharts';


import { showError } from '../../Components/Notifications';

import { getODKAccessToken, getToken, setODKAccessToken } from '../../Components/common/utils';
import UploadSurveyDataModal from './UploadSurveyDataModal';


const ManageFieldData = (props) => {
  const [configurationSelectedDate, setConfigurationSelectedDate] = useState(new Date());
  const [farmerCount, setFarmerCount] = useState('');
  const [enrollmentCount, setEnrollmentCount] = useState('');
  const [insurenceContentCount, setInsurenceContentCount] = useState('');
  const [impactCount, setImpactCount] = useState('');
  const [claimCount, setClaimCount] = useState('');
  const [farmerList, setFarmerList] = useState([]);
  const [enrollmentList, setEnrollmentList] = useState([]);
  const [insurenceContentList, setInsurenceContentList] = useState([]);
  const [impactList, setImpactList] = useState([]);
  const [claimList, setClaimList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [signedInUser, setSignedInUser] = useState('');
  const [signedInUserDetails, setSignedInUserDetails] = useState('');
  const [isModalClosed, setIsModalClosed] = useState(false)
  const [openUploadSurveyDataModal, setOpenUploadSurveyDataModal] = useState(false);
  const [folderId, setFolderId] = useState(false);
  const [tokenClient, setTokenClient] = useState(false);

  const nextProps = useSelector((state) => ({
    isFieldDataListDataRequesting: state.fieldDataList.isFieldDataListDataRequesting,
    fieldDataListData: state?.fieldDataList?.FieldDataListData,
    inviteConfigurationData: state?.fieldDataList?.inviteConfigurationData,
    loginDetailData: state.Login.loginDetailData || null,
    componentsList: state?.fieldDataList?.componentsListData,
    componentsListRequest: state?.fieldDataList?.componentsListRequesting,
    componentsByIdRequesting: state?.fieldDataList?.componentsByIdRequesting,
    componentsByIdData: state?.fieldDataList?.componentsByIdData,
    farmerFieldData: state?.fieldDataList?.FarmerFieldData || null,
    enrollmentFieldData: state?.fieldDataList?.EnrollmentFieldData || null,
    insuranceFieldData: state?.fieldDataList?.InsuranceFieldData || null,
    impactFieldData: state?.fieldDataList?.ImpactFieldData || null,
    claimFieldData: state?.fieldDataList?.ClaimFieldData || null,
    isFarmerFieldDataRequesting: state?.fieldDataList?.isFarmerFieldDataRequesting,
    isEnrollmentFieldDataRequesting: state?.fieldDataList?.isEnrollmentFieldDataRequesting,
    isInsuranceFieldDataRequesting: state?.fieldDataList?.isInsuranceFieldDataRequesting,
    isImpactFieldDataRequesting: state?.fieldDataList?.isImpactFieldDataRequesting,
    isClaimFieldDataRequesting: state?.fieldDataList?.isClaimFieldDataRequesting,
    loginDetailData: state.Login.loginDetailData || null,
    submitConfiguration: state?.fieldDataList?.submitConfiguration,
    isSubmitConfigurationRequesting: state?.fieldDataList?.isSubmitConfigurationRequesting,
    insuranceUserUpdateRequesting: state.Login.insuranceUserUpdateRequesting,
    insuranceUserUpdateData: state.Login.insuranceUserUpdateData
  }));

  const dispatch = useDispatch();
  let history = useHistory();
  const [activeTab, setActiveTab] = useState('');
  const [newRequestList, setNewRequestList] = useState([
    {
      'Farmer Name': 'test',
      'Farmer ID': '12345',
      'Phone No.': '9876543210',
      District: 'test',
      'DS Division': 'test',
      'GN Division': 'test',
      'Surveyor Name': 'test',
      'Status of Insurance': 'test',
    },
  ]);
  const [pageLimit, setPageLimit] = useState(10);
  const [userPermissions, setUserPermissions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [recordsCount, setRecordsCount] = useState();
  const [allowedPermission, setAllowedPermission] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [fieldDataId, setFieldDataId] = useState([]);
  const [component1Count, setComponent1Count] = useState();
  const [finalResponse, setFinalResponse] = useState();
  const [sheetValues, setSheetValues] = useState('')
  const [modal, setModal] = useState(false);
  const [nameFilterField, setNameFilterField] = useState('')
  const [districtFilterField, setDistrictFilterField] = useState('')
  const [pageSize, setPageSize] = useState(10)
  const [allcomponentId, setAllComponentId] = useState([])
  const [fromBack, setFromBack] = useState(false)
  const [filteredRequest, setFilteredRequest] = useState([])
  const [filterTurnedOn, setFilterTurnedOn] = useState(false)
  const [userDetails, setUserDetails] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [isTokenExpired, setIsTokenExpired] = useState(false);


  const [closeModal, setCloseModal] = useState(false)

  let search = window.location.search;
  let URLParams = new URLSearchParams(search);

  const localUserPermission = localStorage.getItem('userPermissions');

  const userId = JSON.parse(localUserPermission)?.result?.user?.id;

  useEffect(() => {
    if (getToken()) {

      dispatch(loginDetailrequest({}));

      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('fromBack');
      const dateParam = urlParams.get('date')
      if (dateParam) {
        setConfigurationSelectedDate(new Date(dateParam))
      }
      setFromBack(myParam)
      if (_.isEmpty(nextProps?.componentsList)) {
        // setIsLoading(true);
        dispatch(componentsListRequest());
      }

      let signedInUser = localStorage.getItem('googleAccountDetails');
      if(signedInUser){
        signedInUser = JSON.parse(signedInUser);
        setSignedInUser(signedInUser)
        setSignedInUserDetails(signedInUser?.email)
      } else history.push('/surveyDataSettingsDashboard')

    }
    else {
      history.push("/home")
    }
    return () => {
      dispatch(componentsListSuccess({}));
    };
  }, []);

  useEffect(()=>{
    (async () => {
      /* global google */
      // await window?.google?.accounts?.id.initialize({
      //   client_id: CLIENT_ID,
      //   apiKey: API_KEY,
      //   useOneTap: false,
      //   auto_select: true,
      //   hint: userDetails?.google_email,
      //   prompt:'',
      //   callback: async function (response) {
      //     let userObj = jwt_decode(response.credential);
      //     setSignedInUser(userObj)
      //     setSignedInUserDetails(userObj?.email)
      //   }
      // })
      // window?.google?.accounts?.id.prompt();

      //initializing token token Client
      const tokenClient = window?.google?.accounts?.oauth2?.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPE,
        discoveryDocs: discoveryUrl,
        hint: userDetails?.google_email || '',
        prompt: userDetails?.google_email ? '' : 'consent',
        callback: (tokenResponse) => {
          setIsTokenExpired(false);
          if (tokenResponse && tokenResponse?.access_token) {
            setAccessToken(tokenResponse?.access_token)
            setODKAccessToken(tokenResponse?.access_token)
          }
          //we now have access to a live token to use for any google api
        },
        error_callback: (err) => {
          if (err.type == 'popup_failed_to_open') {
            // The popup window is failed to open
            if (err?.message) {
              showError(`${err.message}. Please unblock the popup in browser settings if it is blocked.`)
              history.push("/surveyDataSettingsDashboard")
            }
          } else if (err.type == 'popup_closed') {
            // The popup window is closed before an OAuth response is returned
          }
        }
      });
      setTokenClient(tokenClient)
    })()
  },[userDetails])

  useEffect(() => {
    if (nextProps.loginDetailData) {
      setFolderId(nextProps?.loginDetailData?.data?.result?.user_profile?.drive_folder_id);

      setUserDetails(nextProps?.loginDetailData?.data?.result?.user_profile);
    }
  }, [nextProps.loginDetailData?.data?.result]);

  useEffect(() => {
    if (signedInUserDetails && userDetails) {
      let accessToken = getODKAccessToken();
      if (accessToken) {
        if (folderId === null) {
          createFolder(
            signedInUser,
            signedInUserDetails,
            accessToken
          );
        }
      }
      else {
        if(userDetails?.google_email)
        tokenClient?.requestAccessToken({ prompt:'', hint: userDetails?.google_email || '' })
        else tokenClient?.requestAccessToken({ prompt: 'consent' })
        tokenClient.callback = (res) => {
          setAccessToken(res?.access_token)
          setODKAccessToken(res?.access_token)
          if (folderId === null) {
            createFolder(
              signedInUser,
              signedInUserDetails,
              res?.access_token
            );
          }
        }
      }
    }
  }, [signedInUserDetails, userDetails]);

  useEffect(() => {
    if (nextProps.insuranceUserUpdateData) {
      dispatch(loginDetailrequest({}));
    }
  }, [nextProps.insuranceUserUpdateData]);

  useEffect(() => {
    if (nextProps.isSubmitConfigurationRequesting) {
      setIsModalLoading(true);
    }
    else if (nextProps.submitConfiguration) {
      setIsModalLoading(false)
      dispatch(submitConfigurationSuccess({}));
      // showSuccess(nextProps.submitConfiguration?.data?.message);
    }
    else {
      setIsModalLoading(false)
    }
  }, [
    nextProps.submitConfiguration,
    nextProps.isSubmitConfigurationRequesting,
  ]);

  useEffect(() => {
    if (nextProps?.componentsList && (isModalClosed || fromBack)) {
      let x = nextProps?.componentsList?.data?.result;
      setFieldDataId(x);
      if (search.substring(1)) {
        if (_.isArray(search.split('&'))) {
          toggle(search.split('&')[0].substring(1), x);
        } else {
          toggle(search.substring(1), x);
        }
      } else {
        toggle('Component1', x);
      }
      setIsLoading(true);
      setNewRequestList([])
      setFilteredRequest([])
      setLoading(true);
      setFinalResponse()
      let First = {
        component_id: x?.[0]?.id,
        year: JSON.stringify(configurationSelectedDate?.getFullYear()),
      };
      let Second = {
        component_id: x?.[1]?.id,
        year: JSON.stringify(configurationSelectedDate?.getFullYear()),
      };
      let Third = {
        component_id: x?.[2]?.id,
        year: JSON.stringify(configurationSelectedDate?.getFullYear()),
      };
      let Forth = {
        component_id: x?.[3]?.id,
        year: JSON.stringify(configurationSelectedDate?.getFullYear()),
      };
      let Fifth = {
        component_id: x?.[4]?.id,
        year: JSON.stringify(configurationSelectedDate?.getFullYear()),
      };

      let first;
      let second;
      let third;
      let fouth;
      let fifth;
      first = AXIOS_INSTANCE.post(`${API_BASE_URL}/odk/getSurveyComponentDetails`, First);
      second = AXIOS_INSTANCE.post(`${API_BASE_URL}/odk/getSurveyComponentDetails`, Second);
      third = AXIOS_INSTANCE.post(`${API_BASE_URL}/odk/getSurveyComponentDetails`, Third);
      fouth = AXIOS_INSTANCE.post(`${API_BASE_URL}/odk/getSurveyComponentDetails`, Forth);
      fifth = AXIOS_INSTANCE.post(`${API_BASE_URL}/odk/getSurveyComponentDetails`, Fifth);

      Promise.allSettled([first, second, third, fouth, fifth])
        .then((responses) => {
          let responsesData = [];
          setIsLoading(false);
          // setLoading(false);
          responses?.map((item) => {
            if (item?.status === 'fulfilled') {
              responsesData.push(item?.value?.data?.result);
            } else {
            }
          });
          let sheetsValuesArray = responsesData.map(async (item) => {
            if (item.length) {
              let fileUrlId = item[0].excel_url.split('/')[5]
              return fileUrlId
            }
            else {

              return ''
            }
          })
          Promise.allSettled(sheetsValuesArray).then((responses) => { setAllComponentId(responses) })
        })
        .catch((error) => {
          setIsLoading(false);
          setLoading(false);
          if (error?.response) {
            let errorRes = error.response;
            if (!_.isEmpty(errorRes?.data?.errors)) {
              // showError(Object.values(errorRes?.data?.errors)[0]);
            } else {
              // showError(errorRes?.data?.message);
            }
          } else {
            // showError('No records found. please try again');
          }
          return;
        });
    }

  }, [nextProps?.componentsList, modal, configurationSelectedDate]);

  useEffect(()=>{
    let promiseArray = allcomponentId.map((item)=>{
      if (item?.value)
       return valuesLength(item?.value)
       else
       return ''
    })
    Promise.allSettled(promiseArray).then((AllSheetsResponses) =>{
      setFinalResponse(AllSheetsResponses)
      //setIsLoading(false);
      setLoading(false);
    })
  },[allcomponentId])


  useEffect(() => {
    if (nextProps?.isFieldDataListDataRequesting) {
      //setLoading(true);
    } else {
      //setLoading(false);
    }
  }, [nextProps?.isFieldDataListDataRequesting]);


  useEffect(() => {
    if (finalResponse?.length) {
      if (_.isArray(finalResponse[0]?.value)) {
        setFarmerCount(finalResponse[0]?.value?.length - 1);
      } else { setFarmerCount(0) }
      if (_.isArray(finalResponse[1]?.value)) {
        setEnrollmentCount(finalResponse[1]?.value?.length - 1);
      } else { setEnrollmentCount(0) }
      if (_.isArray(finalResponse[2]?.value)) {
        setInsurenceContentCount(finalResponse[2]?.value?.length - 1);
      } else { setInsurenceContentCount(0) }
      if (_.isArray(finalResponse[3]?.value)) {
        setImpactCount(finalResponse[3]?.value?.length - 1);
      } else { setImpactCount(0) }
      if (_.isArray(finalResponse[4]?.value)) {
        setClaimCount(finalResponse[4]?.value?.length - 1);
      } else { setClaimCount(0) }
    }
  }, [finalResponse]);

  // useEffect(() => {
  //   if (finalResponse?.length) {
  //     // setNewRequestList(nextProps?.fieldDataListData?.data?.results);
  //     finalResponse?.map(i => {
  //       if(i?.config?.data.includes('Farmer Information')){
  //         setFarmerCount(i?.data?.result?.farm_count);
  //       }
  //       if(i?.config?.data.includes('Enrollment')){
  //         setEnrollmentCount(i?.data?.result?.farm_count);
  //       }
  //       if(i?.config?.data.includes('Understanding the Insurance Context')){
  //         setInsurenceContentCount(i?.data?.result?.farm_count);
  //       }
  //       if(i?.config?.data.includes('Impact Evaluation')){
  //         setImpactCount(i?.data?.result?.farm_count);
  //       }
  //       if(i?.config?.data.includes('Claim Settlement')){
  //         setClaimCount(i?.data?.result?.farm_count);
  //       }
  //     })
  //     // setNewRequestList(nextProps?.fieldDataListData?.data?.result?.['farm_odk_data ']);
  //     // setComponent1Count(nextProps?.fieldDataListData?.data?.result?.farm_count);
  //     // setPageCount(nextProps?.fieldDataListData?.data?.count);
  //     // setRecordsCount(nextProps?.fieldDataListData?.data?.result.length);
  //   }
  // }, [finalResponse]);



  // useEffect(() => {
  //   if (nextProps.fieldDataListData) {
  //     if (nextProps?.fieldDataListData?.data?.result) {
  //       // setNewRequestList(nextProps?.fieldDataListData?.data?.results);
  //       if (nextProps?.fieldDataListData?.id === 'Farmer Information') {
  //         setFarmerCount(nextProps?.fieldDataListData?.data?.result?.farm_count);
  //       } else if (nextProps?.fieldDataListData?.id === 'Enrollment') {
  //         setEnrollmentCount(nextProps?.fieldDataListData?.data?.result?.farm_count);
  //       } else if (nextProps?.fieldDataListData?.id === 'Understanding the Insurance context') {
  //         setInsurenceContentCount(nextProps?.fieldDataListData?.data?.result?.farm_count);
  //       } else if (nextProps?.fieldDataListData?.id === 'Impact Evaluation') {
  //         setImpactCount(nextProps?.fieldDataListData?.data?.result?.farm_count);
  //       } else if (nextProps?.fieldDataListData?.id === 'Claim settlement') {
  //         setClaimCount(nextProps?.fieldDataListData?.data?.result?.farm_count);
  //       }
  //       // setNewRequestList(nextProps?.fieldDataListData?.data?.result?.['farm_odk_data ']);
  //       setComponent1Count(nextProps?.fieldDataListData?.data?.result?.farm_count);
  //       setPageCount(nextProps?.fieldDataListData?.data?.count);
  //       setRecordsCount(nextProps?.fieldDataListData?.data?.result.length);
  //     } else {
  //       // setNewRequestList([]);
  //       setPageCount(0);
  //       setRecordsCount(0);
  //     }
  //   } else {
  //     // setNewRequestList([]);
  //   }
  // }, [nextProps.fieldDataListData]);

  useEffect(() => {
    if (nextProps.farmerFieldData) {
      if (nextProps?.farmerFieldData?.data?.result) {
        setFarmerList(nextProps?.farmerFieldData?.data?.result?.['farm_odk_data ']);
        // setComponent1Count(nextProps?.fieldDataListData?.data?.result?.farm_count)
        // setPageCount(nextProps?.fieldDataListData?.data?.count);
        // setRecordsCount(nextProps?.fieldDataListData?.data?.result.length);
      } else {
        // setNewRequestList([]);
        // setPageCount(0);
        // setRecordsCount(0);
      }
    } else {
      setFarmerList([]);
      // setNewRequestList([]);
    }
  }, [nextProps.farmerFieldData]);

  useEffect(() => {
    if (nextProps.enrollmentFieldData) {
      if (nextProps?.enrollmentFieldData?.data?.result) {
        setEnrollmentList(nextProps?.enrollmentFieldData?.data?.result?.['farm_odk_data ']);
        // setComponent1Count(nextProps?.fieldDataListData?.data?.result?.farm_count)
        // setPageCount(nextProps?.fieldDataListData?.data?.count);
        // setRecordsCount(nextProps?.fieldDataListData?.data?.result.length);
      } else {
        // setNewRequestList([]);
        // setPageCount(0);
        // setRecordsCount(0);
      }
    } else {
      setEnrollmentList([]);
      // setNewRequestList([]);
    }
  }, [nextProps.enrollmentFieldData]);

  useEffect(() => {
    if (nextProps.insuranceFieldData) {
      if (nextProps?.insuranceFieldData?.data?.result) {
        setInsurenceContentList(nextProps?.insuranceFieldData?.data?.result?.['farm_odk_data ']);
        // setComponent1Count(nextProps?.fieldDataListData?.data?.result?.farm_count)
        // setPageCount(nextProps?.fieldDataListData?.data?.count);
        // setRecordsCount(nextProps?.fieldDataListData?.data?.result.length);
      } else {
        // setNewRequestList([]);
        // setPageCount(0);
        // setRecordsCount(0);
      }
    } else {
      setInsurenceContentList([]);
      // setNewRequestList([]);
    }
  }, [nextProps.insuranceFieldData]);

  useEffect(() => {
    if (nextProps.impactFieldData) {
      if (nextProps?.impactFieldData?.data?.result) {
        setImpactList(nextProps?.impactFieldData?.data?.result?.['farm_odk_data ']);
        // setComponent1Count(nextProps?.fieldDataListData?.data?.result?.farm_count)
        // setPageCount(nextProps?.fieldDataListData?.data?.count);
        // setRecordsCount(nextProps?.fieldDataListData?.data?.result.length);
      } else {
        // setNewRequestList([]);
        // setPageCount(0);
        // setRecordsCount(0);
      }
    } else {
      setImpactList([]);
      // setNewRequestList([]);
    }
  }, [nextProps.impactFieldData]);

  useEffect(() => {
    if (nextProps.claimFieldData) {
      if (nextProps?.claimFieldData?.data?.result) {
        setClaimList(nextProps?.claimFieldData?.data?.result?.['farm_odk_data ']);
        // setComponent1Count(nextProps?.fieldDataListData?.data?.result?.farm_count)
        // setPageCount(nextProps?.fieldDataListData?.data?.count);
        // setRecordsCount(nextProps?.fieldDataListData?.data?.result.length);
      } else {
        // setNewRequestList([]);
        // setPageCount(0);
        // setRecordsCount(0);
      }
    } else {
      setClaimList([]);
      // setNewRequestList([]);
    }
  }, [nextProps.claimFieldData]);

  useEffect(() => {
    if (activeTab === 'Component1') {
      setNewRequestList(farmerList);
    } else if (activeTab === 'Enrollment') {
      setNewRequestList(enrollmentList);
    } else if (activeTab === 'UnderstandingTheInsuranceContext') {
      setNewRequestList(insurenceContentList);
    } else if (activeTab === 'ImpactEvaluation') {
      setNewRequestList(impactList);
    } else if (activeTab === 'Component5') {
      setNewRequestList(claimList);
    }
  }, [activeTab, farmerList, enrollmentList, insurenceContentList, impactList, claimList]);

  useEffect(() => {
    if (!_.isEmpty(sheetValues)) {
      setNewRequestList(sheetValues.slice(0, pageSize))
      setLoading(false)
    }
    else {
    }
  }, [sheetValues])

  useEffect(()=>{
    if(getODKAccessToken() && isTokenExpired){
      setLoading(false);
      setIsLoading(false);
      // showError('Unable to proceed due to expired access token. please authorize and continue')
      if(userDetails?.google_email)
      tokenClient?.requestAccessToken({ prompt:'', hint: userDetails?.google_email || ''})
      else tokenClient?.requestAccessToken({ prompt: 'consent' })    }
  },[isTokenExpired])

  useEffect(() => {
    if (nextProps.insuranceUserUpdateRequesting) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [nextProps.insuranceUserUpdateRequesting]);

  const t = useTranslation();
  const toggle = (tab, data) => {
    setOpenFilter(false);
    setActiveTab(tab);
    setNameFilterField('')
    setDistrictFilterField('')
    dispatch(change('ManageFieldDataForm', "FarmerName", ''));
    dispatch(change('ManageFieldDataForm', "District", ''));
    // history.push("/manageFieldData?FieldDataDetails1")
    setPageNumber(1);
    setPageSize(10)
    setIsLoading(true);
    setLoading(true);
    setFilteredRequest([])
    if (tab === 'Component1') {
      // if (!_.isEmpty(fieldDataId)) {
      // dispatch(
      //   getFieldDataRequest({
      //     component_id: data ? data?.[0]?.id : fieldDataId?.[0]?.id,
      //     year: JSON.stringify(configurationSelectedDate?.getFullYear()),
      //     id: 'Farmer Information',
      //   }),
      // );
      // }

      dispatch(componentsByIdRequest({
        "component_id": data ? data?.[0]?.id : fieldDataId?.[0]?.id,
        "year": JSON.stringify(configurationSelectedDate?.getFullYear())
      }
      ))

      history.push('/manageFieldData?Component1');
    } else if (tab === 'Enrollment') {
      // dispatch(
      //   getFieldDataRequest({
      //     component_id: data ? data?.[1]?.id : fieldDataId?.[1]?.id,
      //     year: JSON.stringify(configurationSelectedDate?.getFullYear()),
      //     id: 'Enrollment',
      //   }),
      // );
      dispatch(componentsByIdRequest({
        "component_id": data ? data?.[1]?.id : fieldDataId?.[1]?.id,
        "year": JSON.stringify(configurationSelectedDate?.getFullYear())
      }
      ))
      history.push('/manageFieldData?Enrollment');
    } else if (tab === 'UnderstandingTheInsuranceContext') {
      // dispatch(
      // getFieldDataRequest({
      //   component_id: data ? data?.[2]?.id : fieldDataId?.[2]?.id,
      //   year: JSON.stringify(configurationSelectedDate?.getFullYear()),
      //   id: 'Understanding the Insurance context',
      // }),
      dispatch(componentsByIdRequest({
        "component_id": data ? data?.[2]?.id : fieldDataId?.[2]?.id,
        "year": JSON.stringify(configurationSelectedDate?.getFullYear())
      }
      ))

      history.push('/manageFieldData?UnderstandingTheInsuranceContext')
      // );
    } else if (tab === 'ImpactEvaluation') {
      // dispatch(
      //   getFieldDataRequest({
      //     component_id: data ? data?.[3]?.id : fieldDataId?.[3]?.id,
      //     year: JSON.stringify(configurationSelectedDate?.getFullYear()),
      //     id: 'Impact Evaluation',
      //   }),
      // );
      dispatch(componentsByIdRequest({
        "component_id": data ? data?.[3]?.id : fieldDataId?.[3]?.id,
        "year": JSON.stringify(configurationSelectedDate?.getFullYear())
      }
      ))
      history.push('/manageFieldData?ImpactEvaluation');
    } else if (tab === 'Component5') {
      // dispatch(
      //   getFieldDataRequest({
      //     component_id: data ? data?.[4]?.id : fieldDataId?.[4]?.id,
      //     year: JSON.stringify(configurationSelectedDate?.getFullYear()),
      //     id: 'Claim settlement',
      //   }),
      // );
      dispatch(componentsByIdRequest({
        "component_id": data ? data?.[4]?.id : fieldDataId?.[4]?.id,
        "year": JSON.stringify(configurationSelectedDate?.getFullYear())
      }
      ))
      history.push('/manageFieldData?Component5');
    }
  };
  useEffect(() => {
    if (newRequestList && activeTab === 'Enrollment') {
      setTableData(
        newRequestList?.map((item, key) => {
          return {
            name:
              item?.['data-sub_section-Name-of-the-farmer'] !== ' '
                ? item?.['data-sub_section-Name-of-the-farmer']
                  ? item?.['data-sub_section-Name-of-the-farmer']
                  : 'N/A'
                : 'N/A',
            // farmerId:item?.[""]
            phoneNo:
              item?.['data-sub_section-Telephone-No'] !== ' '
                ? item?.['data-sub_section-Telephone-No']
                  ? item?.['data-sub_section-Telephone-No']
                  : 'N/A'
                : 'N/A',
            district:
              item?.['data-sub_section-District'] !== ' ' && item?.['data-sub_section-District']
                ? item?.['data-sub_section-District'].split("-").join(" ")
                : 'N/A',
            dsDivision:
              item?.['data-sub_section-DS-division'] !== ' '
                ? item?.['data-sub_section-DS-division']
                  ? item?.['data-sub_section-DS-division']
                  : 'N/A'
                : 'N/A',
            gnDivision:
              item?.['data-sub_section-GN-division'] !== ' '
                ? item?.['data-sub_section-GN-division']
                  ? item?.['data-sub_section-GN-division']
                  : 'N/A'
                : 'N/A',
            id:
              item?.['data-meta-instanceID'] !== ' '
                ? item?.['data-meta-instanceID']
                  ? item?.['data-meta-instanceID']
                  : 'N/A'
                : 'N/A',
          };
        }),
      );
    }

    if (newRequestList && activeTab === 'UnderstandingTheInsuranceContext') {
      setTableData(
        newRequestList?.map((item, key) => {
          return {
            name:
              item?.['data-Setting-farmer-ID-Name-of-the-farmer'] !== ' '
                ? item?.['data-Setting-farmer-ID-Name-of-the-farmer']
                  ? item?.['data-Setting-farmer-ID-Name-of-the-farmer']
                  : 'N/A'
                : 'N/A',
            farmerId:
              item?.['data-Setting-farmer-ID-Farmer-ID'] !== ' '
                ? item?.['data-Setting-farmer-ID-Farmer-ID']
                  ? item?.['data-Setting-farmer-ID-Farmer-ID']
                  : 'N/A'
                : 'N/A',
            phoneNo:
              item?.['data-Setting-farmer-ID-Mobile-No-for-future-communication'] !== ' '
                ? item?.['data-Setting-farmer-ID-Mobile-No-for-future-communication']
                  ? item?.['data-Setting-farmer-ID-Mobile-No-for-future-communication']
                  : 'N/A'
                : 'N/A',
            district: item?.['data-Setting-farmer-ID-District'] !== " " ? item?.['data-Setting-farmer-ID-District']
              ? item?.['data-Setting-farmer-ID-District'].split("-").join(" ")
              : 'N/A' : "N/A",
            dsDivision:
              item?.['data-Setting-farmer-ID-DS-division'] !== ' '
                ? item?.['data-Setting-farmer-ID-DS-division']
                  ? item?.['data-Setting-farmer-ID-DS-division']
                  : 'N/A'
                : 'N/A',
            gnDivision:
              item?.['data-Setting-farmer-ID-GN-division'] !== ' '
                ? item?.['data-Setting-farmer-ID-GN-division']
                  ? item?.['data-Setting-farmer-ID-GN-division']
                  : 'N/A'
                : 'N/A',
            id:
              item?.['data-meta-instanceID'] !== ' '
                ? item?.['data-meta-instanceID']
                  ? item?.['data-meta-instanceID']
                  : 'N/A'
                : 'N/A',
          };
        }),
      );
    }

    if (newRequestList && activeTab === 'ImpactEvaluation') {
      setTableData(
        newRequestList?.map((item, key) => {
          return {
            // name: item?.["data-Setting-farmer-ID-Name-of-the-farmer"],
            farmerId:
              item?.['data-farmer-information-Farmer-ID'] !== ' '
                ? item?.['data-farmer-information-Farmer-ID']
                  ? item?.['data-farmer-information-Farmer-ID']
                  : 'N/A'
                : 'N/A',
            phoneNo:
              item?.['data-farmer-information-Farmer-number'] !== ' '
                ? item?.['data-farmer-information-Farmer-number']
                  ? item?.['data-farmer-information-Farmer-number']
                  : 'N/A'
                : 'N/A',
            district:
              item?.['data-farmer-information-District'] !== ' '
                ? item?.['data-farmer-information-District']
                  ? item?.['data-farmer-information-District'].split("-").join(" ")
                  : 'N/A'
                : 'N/A',
            dsDivision:
              item?.['data-farmer-information-DS-division'] !== ' '
                ? item?.['data-farmer-information-DS-division']
                  ? item?.['data-farmer-information-DS-division']
                  : 'N/A'
                : 'N/A',
            gnDivision:
              item?.['data-farmer-information-GN-division'] !== ' '
                ? item?.['data-farmer-information-GN-division']
                  ? item?.['data-farmer-information-GN-division']
                  : 'N/A'
                : 'N/A',
            id:
              item?.['data-meta-instanceID'] !== ' '
                ? item?.['data-meta-instanceID']
                  ? item?.['data-meta-instanceID']
                  : 'N/A'
                : 'N/A',
          };
        }),
      );
      // setTableData([])
    }
    if (activeTab === 'Component1') {
      setTableData(
        newRequestList?.map((item, key) => {
          return {
            // name: item?.["data-Setting-farmer-ID-Name-of-the-farmer"],
            name:
              item?.['data-farmer-informatiom-farmer-name'] !== ' '
                ? item?.['data-farmer-informatiom-farmer-name']
                  ? item?.['data-farmer-informatiom-farmer-name']
                  : 'N/A'
                : 'N/A',

            // farmerId: item?.['data-farmer-informatiom-farmer-name']!==" " ? item?.['data-farmer-informatiom-farmer-name']?item?.['data-farmer-informatiom-farmer-name']:"N/A":"N/A",
            phoneNo:
              item?.['data-farmer-informatiom-telephone-number'] !== ' '
                ? item?.['data-farmer-informatiom-telephone-number']
                  ? item?.['data-farmer-informatiom-telephone-number']
                  : 'N/A'
                : 'N/A',
            district:
              item?.['data-farmer-informatiom-District'] !== ' '
                ? item?.['data-farmer-informatiom-District']
                  ? item?.['data-farmer-informatiom-District'].split("-").join(" ")
                  : 'N/A'
                : 'N/A',
            dsDivision:
              item?.['data-farmer-informatiom-DS-division'] !== ' '
                ? item?.['data-farmer-informatiom-DS-division']
                  ? item?.['data-farmer-informatiom-DS-division']
                  : 'N/A'
                : 'N/A',
            gnDivision:
              item?.['data-farmer-informatiom-GN-division'] !== ' '
                ? item?.['data-farmer-informatiom-GN-division']
                  ? item?.['data-farmer-informatiom-GN-division']
                  : 'N/A'
                : 'N/A',
            id:
              item?.['data-meta-instanceID'] !== ' '
                ? item?.['data-meta-instanceID']
                  ? item?.['data-meta-instanceID']
                  : 'N/A'
                : 'N/A',
          };
        }),
      );
      // setTableData([])
      // }
    }

    if (activeTab === 'Component5') {
      setTableData(
        newRequestList?.map((item, key) => {
          return {
            // name: item?.["data-Setting-farmer-ID-Name-of-the-farmer"],
            // name: item?.['data-farmer-informatiom-farmer-name']!==" " ? item?.['data-farmer-informatiom-farmer-name']?item?.['data-farmer-informatiom-farmer-name']:"N/A":"N/A",

            farmerId:
              item?.['data-basic-informations-farmer-id'] !== ' '
                ? item?.['data-basic-informations-farmer-id']
                  ? item?.['data-basic-informations-farmer-id']
                  : 'N/A'
                : 'N/A',
            phoneNo:
              item?.['data-basic-informations-farmer-number'] !== ' '
                ? item?.['data-basic-informations-farmer-number']
                  ? item?.['data-basic-informations-farmer-number']
                  : 'N/A'
                : 'N/A',
            district:
              item?.['data-basic-informations-district'] !== ' '
                ? item?.['data-basic-informations-district']
                  ? item?.['data-basic-informations-district'].split("-").join(" ")
                  : 'N/A'
                : 'N/A',
            dsDivision:
              item?.['data-basic-informations-ds-division'] !== ' '
                ? item?.['data-basic-informations-ds-division']
                  ? item?.['data-basic-informations-ds-division']
                  : 'N/A'
                : 'N/A',
            gnDivision:
              item?.['data-basic-informations-gn-division'] !== ' '
                ? item?.['data-basic-informations-gn-division']
                  ? item?.['data-basic-informations-gn-division']
                  : 'N/A'
                : 'N/A',
            id:
              item?.['data-meta-instanceID'] !== ' '
                ? item?.['data-meta-instanceID']
                  ? item?.['data-meta-instanceID']
                  : 'N/A'
                : 'N/A',
          };
        }),
      );

      // setTableData([]);
    }
  }, [newRequestList, activeTab]);

  const handleCallbackResponse = (response) => {
    // window.google.accounts.id.prompt();
    let userObj = jwt_decode(response.credential);
    setSignedInUser(userObj)
    setSignedInUserDetails(userObj?.email)
  }

  const handlePageNumber = (value) => {
    setPageNumber(value);
    let endIndex = value * pageSize;
    let startIndex = endIndex - pageSize

    if (!_.isEmpty(filteredRequest)) {
      setNewRequestList(filteredRequest?.slice(startIndex, endIndex))
    }
    else {
      setNewRequestList(sheetValues?.slice(startIndex, endIndex))
    }
  };
  const handlePageSize = (value) => {
    setPageSize(value)
    if (!_.isEmpty(filteredRequest)) {
      setNewRequestList(filteredRequest.slice(0, value))
    } else {
      setNewRequestList(sheetValues.slice(0, value))
    }
  };

  const createFolder = async (signedInUserData, email, accessToken) => {
    const access_token = accessToken;

    await fetch("https://www.googleapis.com/drive/v3/files", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      body: JSON.stringify({
        name: formTitle(),
        // title: formTitle(),
        mimeType: 'application/vnd.google-apps.folder',
      }),
    }).then(res => res.json()).then((data) => {
      if (email && _.isEmpty(folderId)) {
        dispatch(
          insuranceUserUpdaterequest({
            user_id: userId.toString(),
            drive_folder_id: data.id,
            google_email: email,
          }),
        );
      }
    })
      .catch(err => console.log(err))
  };

  const formTitle = () => {
    let driveFolderName = JSON.parse(localUserPermission)?.result?.user_profile?.organization
    if (driveFolderName) {
      return driveFolderName;
    }
  };


  const handleFilter = (e) => {
    setOpenFilter(true);
  };
  const handleFilter1 = (e) => {
    setOpenFilter(false);
  };

  const { handleSubmit, pristine, submitting, invalid } = props;

  const onSubmit = (e) => {
    // dispatch(registerRequest(formData));
  };

  const handleConfiguration = (e) => {
    // let data = {activeTab: 0}
    let data;
    let configDate = JSON?.stringify(configurationSelectedDate?.getFullYear())
    if (activeTab === 'Component1') {
      data = { activeTab: 0, date: configDate, component: search };
    } else if (activeTab === 'Enrollment') {
      data = { activeTab: 1, date: configDate, component: search };
    } else if (activeTab === 'UnderstandingTheInsuranceContext') {
      data = { activeTab: 2, date: configDate, component: search };
    } else if (activeTab === 'ImpactEvaluation') {
      data = { activeTab: 3, date: configDate, component: search };
    } else if (activeTab === 'Component5') {
      data = { activeTab: 4, date: configDate, component: search };
    }
    // history.push('/configuration');
    history.push({
      pathname: '/configuration',
      state: data,
    });
  };

  const handleStartDate = (e) => { };


  const [initialSelectedDate, setSelectedDate] = useState({
    date: {
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().add(6, 'days').format(DATE_FORMAT),
      minStartDate: '',
      minEndDate: '',
      maxEndDate: moment().format(DATE_FORMAT),
      maxStartDate: moment().format(DATE_FORMAT),
    },
  });

  const [updatedSelectedDate, setUpdatedSelectedDate] = useState({
    startDate: moment(new Date(2022, 0, 1)).format(DATE_FORMAT),
    endDate: moment().add(6, 'days').format(DATE_FORMAT),
    minStartDate: '',
    maxStartDate: '',
    maxEndDate: '',
  });

  const handleSelectYear = (e) => {
    let date = new Date(e);
    setConfigurationSelectedDate(date);
  };

  const handleUploadSurveyData = (component, year, excelFile) => {
    listOfExcelFiles(component, year, excelFile, getODKAccessToken())
  }


  //get list of files in folder

  const listOfExcelFiles = async (component, year, excelFile, accessToken) => {
    let extension = excelFile?.[0]?.name.split('.').pop();
    // if (excelFile?.[0]?.name.split('.').pop() === 'x' || data?.[0]?.name.split('.').pop() === 'XML') {
    if (getTitle1(component, year, extension, excelFile)) {
      setIsModalLoading(true);
      let query = "name='" + excelFile?.[0]?.name + "' and '" + folderId + "' in parents"
      await fetch(`https://www.googleapis.com/drive/v3/files?key=${API_KEY}&q=${query}&fields=files(name,id)`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        }
      }).then(res => res.json())
        .then((response) => {
          if(response?.error) throw response?.error;
          setIsModalLoading(false);
          if (response?.files.length === 0) {
            uploadExcelFile(component, year, excelFile, accessToken);
          } else {
            showError('Excel Sheet is already exists for selected Year.');
          }
        })
        .catch(function (err) {
          if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
            setIsTokenExpired(true)
          } else {
            setIsTokenExpired(false)
          }
         });
    } else {
      showError(
        `Excel file name should be ${component?.name}_${new Date(
          year,
        ).getFullYear()}.${extension}.`,
      );
    }
    // } else {
    //   showError('Please upload XML format file.');
    // }
  };

  const getTitle1 = (component, year, extension, excelFile) => {
    return excelFile?.[0]?.name ===
      `${component?.name}_${new Date(year).getFullYear().toString()}.${extension}`
      ? true
      : false;
  };

  //upload xml files
  const uploadExcelFile = (component, year, data, accessToken) => {
    setIsModalLoading(true);
    var file = new Blob([data?.[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var metadata = {
      name: data?.[0] && data?.[0].name, // Filename at Google Drive
      mimeType: 'application/vnd.google-apps.spreadsheet', //  mimeType at google drive
      parents: [folderId], // Folder ID at Google Drive
    };

    var form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    form.append('file', file);

    fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id,name', {
      method: 'POST',
      headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
      body: form,
    })
      .then((res) => {
        return res.json();
      })
      .then(function (val) {
        if (val?.error) throw val?.error

        setIsLoading(false);
        createPermissions(val.name, val.id, accessToken);
        updateDatabase(component, year, val.id)
      })
      .catch(err => {
        if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
          setIsTokenExpired(true)
        } else setIsTokenExpired(false)
      })
  };


  const createPermissions = async (name, fileId, accessToken) => {
    await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/permissions?key=${API_KEY}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify({
        type: 'anyone',
        role: 'writer',
      })
    }).then(res => res.json())
      .then(
        function (response) {
          if (response?.error) throw response?.error
          // Handle the results here (response.result has the parsed body).
        },
        function (err) { },
      )
      .catch(err=>{
        if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
          setIsTokenExpired(true)
        } else setIsTokenExpired(false)
      })
  };

  const updateDatabase = (component, year, fileId) => {
    // need to update in database
    let componentId = component?.id;
    let excelFileUrl = `https://docs.google.com/spreadsheets/d/${fileId}/edit#gid=0`

    let finalPayload = {
      component_id: componentId,
      year: new Date(year)?.getFullYear()?.toString(),
      excel_url: excelFileUrl,
      is_survey_configuration: "False",
    };
    if (
      finalPayload.component_id &&
      finalPayload.year &&
      finalPayload.excel_url
    ) {
      dispatch(submitConfigurationRequest(finalPayload));
      setOpenUploadSurveyDataModal(false)
    }
  };

  const handleOnChangeDates = (action, e) => {
    if (action === 'startDate') {
      setSelectedDate((prev) => {
        return {
          // ...prev,

          date: {
            ...initialSelectedDate.date,
            startDate: e.target.value,
          },
        };
      });
      setUpdatedSelectedDate((prevdate) => {
        return {
          ...prevdate,
          startDate: e.target.value,
        };
      });
    } else if (action === 'endDate') {
      setSelectedDate((prev) => {
        return {
          ...prev,

          date: {
            ...initialSelectedDate.date,
            endDate: e.target.value,
          },
        };
      });
      setUpdatedSelectedDate((prevdate) => {
        return {
          ...prevdate,
          endDate: e.target.value,
        };
      });
    }
  };

  function handleDownloadTemplate(url, filename) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }

  async function execute(spreadsheetId, accessToken) {
    await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/Sheet1`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    }).then(res => res.json()).then((response) => {
      if (response?.error) throw response?.error

      setIsLoading(false);
      setLoading(false);
      // Handle the results here (response.result has the parsed body).
      if (!_.isEmpty(response?.values)) {
        let res = []
        let data = response?.values
        data?.forEach((innerList, i) => {
          if (i === 0) return;  // skip the first inner list
          const obj = {};
          innerList.forEach((item, j) => {
            obj[data[0][j]] = item;
          });
          res.push(obj);
        });
        setSheetValues(res)
        //  setLoading(false)
        //  setIsLoading(false)
      }
    })
      .catch(err => {
        setIsLoading(false);
        setLoading(false);
        console.error("Execute error", err);
        if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
          setIsTokenExpired(true)
        } else {
          if(err?.result?.error?.message)
          showError(err?.result?.error?.message)
          setIsTokenExpired(false)
        }
      })
  }


   async function valuesLength(spreadsheetId) {
    let value = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/Sheet1`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getODKAccessToken(),
      },
    }).then(res => res.json()).then((response) => {
      if(response?.error) throw response?.error
      if (!_.isEmpty(response?.values)) {
        return response?.values
      }
    })
      .catch(err => {
        console.error("Execute error", err);
        if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
          setIsTokenExpired(true)
        } else setIsTokenExpired(false)
      })

    return value;
  }

  useEffect(() => {
    if (signedInUserDetails && userDetails && !_.isEmpty(userDetails?.google_email)) {
      if (signedInUserDetails === userDetails?.google_email) {
        if (!_.isEmpty(nextProps.componentsByIdData) && !_.isEmpty(signedInUser)) {
          setSheetValues('')
          const spreadsheetId = nextProps?.componentsByIdData?.data?.result[0]?.excel_url?.split('/')[5]
          if (spreadsheetId) { 
            let accessToken = getODKAccessToken();
            execute(spreadsheetId, accessToken) 
          }
          else {
            setIsLoading(false);
            setLoading(false);
          }
        }
      }
      else {
        setCloseModal(true)
      }
    }

    if (nextProps.componentsByIdData) {
      setLoading(false)
      setIsLoading(false);

    }

  }, [nextProps.componentsByIdData, signedInUser, userDetails, signedInUser, signedInUserDetails])





  useEffect(() => {
    if (closeModal === true) {

      setModal(!modal)
      handleSignOutClick()
    }
    else { setModal(true) }
  }, [closeModal])



  const handleSignOutClick = () => {
    showError(`Email you have authenticated is not related with your organization please authenticate with this email ${userDetails?.google_email}`)
    history.push("/surveyDataSettingsDashboard")

  };

  const handleOnUploadSurveyData = () => {
    setOpenUploadSurveyDataModal(!openUploadSurveyDataModal)
  }




  useEffect(() => {
    if (!_.isEmpty(filteredRequest)) {
      setPageCount(filteredRequest?.length)
      setLoading(false)
    } else if (!_.isEmpty(sheetValues)) {
      setPageCount(sheetValues?.length)
      setLoading(false)
    }
    else {
      setPageCount(0)
      setLoading(false)
    }
  }, [newRequestList])


  const toggleModal = () => {
    setIsModalClosed(true);
    setModal(!modal);
  }

  const handleFilterName = (e) => {
    setNameFilterField(e.target.value)
  }
  const handleFilterDistrict = (e) => {
    setDistrictFilterField(e.target.value)
  }


  const filterWrtNameAndDistrict = () => {
    let requiredValuesArray = []
    if (nameFilterField) requiredValuesArray.push(nameFilterField)
    if (districtFilterField) requiredValuesArray.push(districtFilterField)
    let filteredArray = []
    if (sheetValues && sheetValues.length) {
      filteredArray = sheetValues?.filter(obj => {
        // Get the values of the object using Object.values()
        const values = Object?.values(obj);
        const lowerCaseValues = values.map(value => value.toLowerCase());
        // Check if all of the valuesToKeep are contained in the values array
        return requiredValuesArray?.every(value => lowerCaseValues?.includes(value.toLowerCase()));
      });
    }
    setPageNumber(1)
    setNewRequestList(filteredArray)
    setFilteredRequest(filteredArray)
  }

  const clearFilter = () => {
    setNameFilterField('')
    setDistrictFilterField('')
    dispatch(change('ManageFieldDataForm', "FarmerName", ''));
    dispatch(change('ManageFieldDataForm', "District", ''));
    if (sheetValues?.length && sheetValues) {
      setNewRequestList(sheetValues.slice(0, pageSize))
    } else {
      setNewRequestList([])
    }
    setFilteredRequest([])
    setPageNumber(1)
  }


  return (
    <>
      {!fromBack &&
        <Modal
          isOpen={modal}
          toggle={toggleModal}
          className="shareinformationmodal"
          backdrop="static"
          style={{ width: '75%', marginBottom: "300px" }}
        >
          <ModalHeader
            toggle={toggleModal}
            className="reason-modal-header">
            {"Please select the year"}
          </ModalHeader>
          <ModalBody
            // style={{marginBottom:"300px",}}
            className='field-data-year-modal'
          >
            <FormGroup className="registertextOnInput " >
              <DatePicker
                id="startDate"
                name="startDate"
                className="input-style configuration mt-0 form-control select-field-zindex"
                showYearPicker={true}
                shouldCloseOnSelect={true}
                dateFormat="yyyy"
                minDate={new Date('2000-01-01T00:00:00Z')}
                maxDate={new Date()}
                placeholderText={'Select Year'}
                onChange={(e) => handleSelectYear(e)}
                // startDate={selectedYear}
                value={configurationSelectedDate ? configurationSelectedDate : null}
                selected={configurationSelectedDate ? configurationSelectedDate : null}
                style={{ zIndex: 100 }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter className="reason-modal">
            <button
              style={{
                backgroundColor: '#3870AF',
                color: '#FFF',
                marginRight: '10px',
                borderRadius: '5px',
                borderStyle: 'none',
                padding: '7px',
                width: "30%"
              }}
              onClick={toggleModal}
            >
              Enter
            </button>
          </ModalFooter>
        </Modal>
      }
      {openUploadSurveyDataModal && (
        <UploadSurveyDataModal modalOpen={openUploadSurveyDataModal} setModalOpen={handleOnUploadSurveyData} componentList={fieldDataId} handleUploadSurveyData={handleUploadSurveyData} handleDownloadTemplate={handleDownloadTemplate} isLoading={isModalLoading} />
      )}
      <Container fluid>
        <form
          onSubmit={handleSubmit(onSubmit)}>
          {/* <form onSubmit={handleSubmit}> */}
          <div className="right-img-wrapper api-key-list" style={{ marginTop: '10px' }}>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '35px',
              }}
              className="mb-3"
            >
              <Col
                lg={openFilter ? '12' : '6'}
                md={openFilter ? '12' : '4'}
                xl={openFilter ? '2' : '4'}
              >
                <Col
                  xs="12"
                  sm="12"
                  md={openFilter ? '3' : '6'}
                  lg="12"
                  className="subheader-manage-user"
                >
                  <img
                    src={RightArrow}
                    alt="back-arrow"
                    className="manage-user-header-back-button"
                    onClick={() => history.push('/surveyDataSettingsDashboard')}
                  />
                  <h3
                    className="heading-manage-user manage-user-heading-mt"
                    style={{ marginTop: '10px' }}
                  >
                    {t('MANAGE_FIELD.MANAGE_FIELD')}
                  </h3>
                </Col>
              </Col>
              {!openFilter ? (
                <Col lg={openFilter ? '12' : '3'} xl={3} md={openFilter ? '12' : '6'}>
                  <div style={{ display: 'flex', float: 'right' }}>
                    <button
                      className="upload-survey-btn1"
                      onClick={handleOnUploadSurveyData}
                      style={{ marginRight: '10px' }}
                    >
                      Upload Survey Data
                    </button>
                  </div>
                </Col>
              ) : <></>}

              <Col lg={openFilter ? '12' : '2'} xl={openFilter ? '9' : '4'} md={12}>
                {/* <Col xs="12" sm="12" md="2" lg="2" className="">
                {!openFilter ? (
                  <button className="filter-btn1" onClick={(e) => handleFilter(e)}>
                    <span className="text-left">
                      
                      Filter
                    </span>
                    <img
                      src={FilterIcon}
                      alt="back-arrow"
                      className="manage-user-header-back-button"
                      
                      onClick={(e) => handleFilter(e)}
                    />
                  </button>
                ) : (
                  <button
                    className="filter-btn1"
                    onClick={(e) => handleFilter(e)}
                    style={{ backgroundColor: '#3870AF', color: '#FFF' }}
                  >
                    <span className="text-left">
                      
                      Filter
                    </span>
                    <img
                      src={SolidFilterIcon}
                      alt="back-arrow"
                      className="manage-user-header-back-button"
                      
                      onClick={(e) => handleFilter(e)}
                    />
                    </button>
                  )}
                </Col>  */}
                {!openFilter && (
                  <Col xs={3} lg={12} md={12}>
                    <div></div>
                    <div
                      style={{ display: 'flex', float: 'right' }}
                    // className="left-spce1"
                    >
                      {/* {!openFilter && ( */}
                      <>
                        <FormGroup className="registertextOnInput " style={{ marginRight: '10px' }}>
                          <DatePicker
                            id="startDate"
                            name="startDate"
                            className="input-style configuration mt-0 form-control select-field-zindex"
                            showYearPicker={true}
                            shouldCloseOnSelect={true}
                            dateFormat="yyyy"
                            minDate={new Date('2000-01-01T00:00:00Z')}
                            maxDate={new Date()}
                            placeholderText={'Select Year'}
                            onChange={(e) => handleSelectYear(e)}
                            // startDate={selectedYear}
                            value={configurationSelectedDate ? configurationSelectedDate : null}
                            selected={configurationSelectedDate ? configurationSelectedDate : null}
                            style={{ zIndex: 100 }}
                          />
                        </FormGroup>

                        <button
                          className="filter-btn1"
                          onClick={(e) => handleFilter(e)}
                          style={{ marginRight: '10px' }}
                        >
                          <span className="text-left">Filter</span>
                          <img
                            src={FilterIcon}
                            alt="back-arrow"
                            className="manage-user-header-back-button"
                            onClick={(e) => handleFilter(e)}
                          />
                        </button>

                        {/* <img
                          src={ConfigurationIcon}
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => handleConfiguration(e)}
                        /> */}
                        <img
                          id="info-icon-tool-tip"
                          data-tip
                          data-for="info-icon-tool-tip"
                          src={ConfigurationIcon}
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => handleConfiguration(e)}
                        />
                        <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                          {t('ODK.CONFIGURATION_HOVER')}
                        </ReactTooltip>
                      </>
                      {/* )} */}
                    </div>
                  </Col>
                )}

                {openFilter && (
                  <Row style={{ display: 'flex' }} className="left-spce3">
                    <Col
                      lg={3}
                      md={3}
                      // className=" mt-1 "
                      style={{ padding: '0px' }}
                    >
                      {' '}
                      <FormGroup className="registertextOnInput " style={{ marginRight: '10px' }}>
                        <Field
                          type="text"
                          name="FarmerName"
                          placeholder="Farmer name"
                          component={renderTextField}
                          className="register-input"
                          onChange={handleFilterName}
                          value={nameFilterField}
                        />
                      </FormGroup>
                    </Col>

                    <Col
                      lg={3}
                      md={3}
                      // className=" mt-1"
                      style={{ padding: '0px' }}
                    >
                      {' '}
                      <FormGroup className="registertextOnInput " style={{ marginRight: '10px' }}>
                        <Field
                          type="text"
                          name="District"
                          placeholder="District"
                          component={renderTextField}
                          className="register-input"
                          onChange={handleFilterDistrict}
                          value={districtFilterField}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col
                      lg={2}
                      md={2}
                      // className=" mt-1"
                      style={{ padding: '0px' }}
                    >
                    <FormGroup className="registertextOnInput " >
                          <DatePicker
                            id="startDate"
                            name="startDate"
                            className="input-style configuration mt-0 form-control select-field-zindex"
                            showYearPicker={true}
                            shouldCloseOnSelect={true}
                            dateFormat="yyyy"
                            minDate={new Date('2000-01-01T00:00:00Z')}
                            maxDate={new Date()}
                            placeholderText={'Select Year'}
                            onChange={(e) => handleSelectYear(e)}
                            // startDate={selectedYear}
                            value={configurationSelectedDate ? configurationSelectedDate : null}
                            selected={configurationSelectedDate ? configurationSelectedDate : null}
                            style={{ zIndex: 100 }}
                          />
                        </FormGroup>
                        </Col> */}
                    {/* <Col
                      lg={3}
                      md={3}
                      // className=" mt-1"
                      style={{ padding: '0px' }}
                    > */}
                    {' '}
                    {/* <FormGroup
                        className="registertextOnInput "
                        // style={{ marginRight: '10px' }}
                      >
                        <Field
                          type="text"
                          name="SurveyorName"
                          placeholder="Surveyor Name"
                          component={renderTextField}
                          className="register-input"
                        />
                      </FormGroup> */}
                    {/* </Col> */}
                    <Col
                      lg={2}
                      md={2}
                    // style={{ padding: '0px' }}
                    >
                      <Button
                        style={{
                          backgroundColor: '#3870AF',
                          color: '#FFF',
                          marginRight: '10px',
                          borderRadius: '5px',
                          borderStyle: 'none',
                          padding: '7px',
                          width: "100%"
                        }}
                        className='search-button-disabled'
                        disabled={!(nameFilterField?.length || districtFilterField?.length)}
                        onClick={filterWrtNameAndDistrict}
                      >
                        Search{' '}
                      </Button>
                      {/* <FormGroup className="registertextOnInput " style={{ marginRight: '10px' }}> */}
                      {/* <Field
                        type="date"
                        name="SurveyStartDate"
                        placeholder="Survey Start Date"
                        component={renderDateField}
                        className="register-input"
                        onChange={(e) => handleStartDate(e)}
                      /> */}
                      {/* <Input
                          type="date"
                          name="startDate"
                          placeholder="Start date"
                          id="startDate"
                          bsSize="sm"
                          className={
                            document.getElementById('startDate')?.getAttribute('aria-invalid') ===
                            'true'
                              ? // ? 'input-style invalid-filter-date analytics-input-st mt-0'
                                ' register-input input-style  mt-0'
                              : 'register-input input-style '
                          }
                          value={initialSelectedDate?.map?.startDate}
                          onChange={(e) => handleOnChangeDates('startDate', e)}
                          style={{ width: '100%', height: '39px' }}
                          // disabled={isDateDisable}
                        />
                      </FormGroup> */}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                    // style={{ padding: '0px' }}
                    >
                      <Button
                        style={{
                          backgroundColor: '#3870AF',
                          color: '#FFF',
                          marginRight: '10px',
                          borderRadius: '5px',
                          borderStyle: 'none',
                          padding: '7px',
                          width: "100%"
                        }}
                        className='search-button-disabled'
                        disabled={!(nameFilterField.length || districtFilterField.length || !_.isEmpty(filteredRequest))}
                        onClick={clearFilter}
                      >
                        Clear Filter
                      </Button>
                    </Col>

                    {/* <Col lg={2} md={2} style={{ padding: '0px' }}> */}
                    {/* <FormGroup className="registertextOnInput " style={{ marginRight: '10px' }}> */}
                    {/* <Field
                        type="date"
                        name="SurveyEndDate"
                        placeholder="Survey End Date"
                        component={renderDateField}
                        className="register-input"
                      /> */}

                    {/* <Input
                          type="date"
                          name="endDate"
                          placeholder="End date"
                          id="endDate"
                          bsSize="sm"
                          className={
                            document.getElementById('endDate')?.getAttribute('aria-invalid') ===
                            'true'
                              ? // ? 'input-style invalid-filter-date analytics-input-st mt-0'
                                'register-input input-style  mt-0'
                              : 'register-input input-style '
                          }
                          style={{ width: '100%', height: '39px' }}
                          // disabled={isDateDisable}
                        />
                      </FormGroup> */}
                    {/* </Col> */}
                    <Col lg={2} style={{ paddingLeft: '0px' }}>
                      <div style={{ display: 'flex' }}>
                        <button
                          className="filter-btn1"
                          onClick={(e) => handleFilter1(e)}
                          style={{ backgroundColor: '#3870AF', color: '#FFF', marginRight: '10px' }}
                        >
                          <span className="text-left">Filter</span>
                          <img
                            src={SolidFilterIcon}
                            alt="back-arrow"
                            className="manage-user-header-back-button"
                            onClick={(e) => handleFilter1(e)}
                          />
                        </button>
                        {/* )} */}
                        <img
                          id="info-icon-tool-tip"
                          data-tip
                          data-for="info-icon-tool-tip"
                          src={ConfigurationIcon}
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => handleConfiguration(e)}
                        />
                        <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                          {t('ODK.CONFIGURATION_HOVER')}
                        </ReactTooltip>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Nav tabs className="manage-user-navtabs tbs1">
              <NavItem style={{ height: '55px', width: '18%' }} className="madtabs1">
                <NavLink
                  className={classnames({ active: activeTab === 'Component1' })}
                  onClick={() => {
                    toggle('Component1');
                  }}
                  style={
                    activeTab === 'Component1'
                      ? {
                        height: '55px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#5088C6',
                        color: '#fff',
                      }
                      : {
                        height: '52px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                  }
                >
                  {/* {t('MANAGE_FIELD.COMPONENT1')}  */}
                  Farmer Information &nbsp;
                  {/* &nbsp;&nbsp; */}
                  {/* {component1Count ? <span style={activeTab === 'Component1'?{backgroundColor:"#fff" , color:"#5088C6" , padding:"2px" , borderRadius:"3px"}:{backgroundColor:"#888888" , color:"#fff" , padding:"2px" , borderRadius:"3px"}}>{component1Count}</span> : ''} */}
                  {farmerCount ? (
                    <span
                      className={
                        activeTab === 'Component1' ? 'badge bg-light' : 'badge bg-secondary'
                      }
                      style={activeTab === 'Component1' ? { color: '#5088C6' } : { color: '#fff' }}
                    >
                      {farmerCount}
                    </span>
                  ) : (
                    <span
                      className={
                        activeTab === 'Component1' ? 'badge bg-light' : 'badge bg-secondary'
                      }
                      style={activeTab === 'Component1' ? { color: '#5088C6' } : { color: '#fff' }}
                    >
                      0
                    </span>
                  )}
                </NavLink>
              </NavItem>
              <NavItem style={{ height: '55px', width: '18%' }} className="madtabs1">
                <NavLink
                  className={classnames({ active: activeTab === 'Enrollment' })}
                  onClick={() => {
                    toggle('Enrollment');
                  }}
                  style={
                    activeTab === 'Enrollment'
                      ? {
                        height: '55px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#5088C6',
                        color: '#fff',
                      }
                      : {
                        height: '52px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                  }
                >
                  Enrollment &nbsp;&nbsp;&nbsp;
                  {/* <span style={activeTab === 'Enrollment'?{backgroundColor:"#fff" , color:"#5088C6" , padding:"2px" , borderRadius:"3px"}:{backgroundColor:"#888888" , color:"#fff" , padding:"2px" , borderRadius:"3px"}}>20</span> */}
                  {enrollmentCount ? (
                    <span
                      className={
                        activeTab === 'Enrollment' ? 'badge bg-light' : 'badge bg-secondary'
                      }
                      style={activeTab === 'Enrollment' ? { color: '#5088C6' } : { color: '#fff' }}
                    >
                      {enrollmentCount}
                    </span>
                  ) : (
                    <span
                      className={
                        activeTab === 'Enrollment' ? 'badge bg-light' : 'badge bg-secondary'
                      }
                      style={activeTab === 'Enrollment' ? { color: '#5088C6' } : { color: '#fff' }}
                    >
                      0
                    </span>
                  )}
                </NavLink>
              </NavItem>
              <NavItem style={{ height: '55px', width: '19%' }} className="madtabs1">
                <NavLink
                  // className={classnames({
                  //   active: activeTab === 'UnderstandingTheInsuranceContext',
                  // })}
                  className="tbs1"
                  onClick={() => {
                    toggle('UnderstandingTheInsuranceContext');
                  }}
                  style={
                    activeTab === 'UnderstandingTheInsuranceContext'
                      ? {
                        height: '55px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#5088C6',
                        color: '#fff',
                        borderRadius: '5px',
                      }
                      : {
                        height: '52px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '5px',
                      }
                  }
                >
                  Understanding the &nbsp;&nbsp;&nbsp; <br />
                  Insurance Context &nbsp;&nbsp;&nbsp;
                  {/* <span style={activeTab === 'UnderstandingTheInsuranceContext'?{backgroundColor:"#fff" , color:"#5088C6" , padding:"2px" , borderRadius:"3px" , marginLeft:"19px" }:{backgroundColor:"#888888" , color:"#fff" , padding:"2px" , borderRadius:"3px" , marginLeft:"19px" }}>20</span> */}
                  {insurenceContentCount ? (
                    <span
                      className={
                        activeTab === 'UnderstandingTheInsuranceContext'
                          ? 'badge bg-light'
                          : 'badge bg-secondary'
                      }
                      style={
                        activeTab === 'UnderstandingTheInsuranceContext'
                          ? { color: '#5088C6' }
                          : { color: '#fff' }
                      }
                    >
                      {insurenceContentCount}
                    </span>
                  ) : (
                    <span
                      className={
                        activeTab === 'UnderstandingTheInsuranceContext'
                          ? 'badge bg-light'
                          : 'badge bg-secondary'
                      }
                      style={
                        activeTab === 'UnderstandingTheInsuranceContext'
                          ? { color: '#5088C6' }
                          : { color: '#fff' }
                      }
                    >
                      0
                    </span>
                  )}
                </NavLink>
              </NavItem>
              <NavItem style={{ height: '55px', width: '18%' }} className="madtabs1">
                <NavLink
                  className={classnames({ active: activeTab === 'ImpactEvaluation' })}
                  onClick={() => {
                    toggle('ImpactEvaluation');
                  }}
                  style={
                    activeTab === 'ImpactEvaluation'
                      ? {
                        height: '55px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#5088C6',
                        color: '#fff',
                      }
                      : {
                        height: '52px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                  }
                >
                  {t('MANAGE_FIELD.IMPACT_EVALUTION')}
                  &nbsp;&nbsp;&nbsp;
                  {/* <span style={activeTab === 'ImpactEvaluation' ?{backgroundColor:"#fff" , color:"#5088C6" , padding:"2px" , borderRadius:"3px"}:{backgroundColor:"#888888" , color:"#fff" , padding:"2px" , borderRadius:"3px"}}>20</span> */}
                  {impactCount ? (
                    <span
                      className={
                        activeTab === 'ImpactEvaluation' ? 'badge bg-light' : 'badge bg-secondary'
                      }
                      style={
                        activeTab === 'ImpactEvaluation' ? { color: '#5088C6' } : { color: '#fff' }
                      }
                    >
                      {impactCount}
                    </span>
                  ) : (
                    <span
                      className={
                        activeTab === 'ImpactEvaluation' ? 'badge bg-light' : 'badge bg-secondary'
                      }
                      style={
                        activeTab === 'ImpactEvaluation' ? { color: '#5088C6' } : { color: '#fff' }
                      }
                    >
                      0
                    </span>
                  )}
                </NavLink>
              </NavItem>
              <NavItem style={{ height: '55px', width: '18%' }} className="madtabs1">
                <NavLink
                  className={classnames({ active: activeTab === 'Component5' })}
                  onClick={() => {
                    toggle('Component5');
                  }}
                  style={
                    activeTab === 'Component5'
                      ? {
                        height: '55px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#5088C6',
                        color: '#fff',
                      }
                      : {
                        height: '52px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                  }
                >
                  {/* {t('MANAGE_FIELD.COMPONENT5')} */}
                  Claim Settlement &nbsp;&nbsp;&nbsp;
                  {/* <span style={activeTab === 'Component5'?{backgroundColor:"#fff" , color:"#5088C6" , padding:"2px" , borderRadius:"3px"}:{backgroundColor:"#888888" , color:"#fff" , padding:"2px" , borderRadius:"3px"}}>20</span> */}
                  {claimCount ? (
                    <span
                      className={
                        activeTab === 'Component5' ? 'badge bg-light' : 'badge bg-secondary'
                      }
                      style={activeTab === 'Component5' ? { color: '#5088C6' } : { color: '#fff' }}
                    >
                      {claimCount}
                    </span>
                  ) : (
                    <span
                      className={
                        activeTab === 'Component5' ? 'badge bg-light' : 'badge bg-secondary'
                      }
                      style={activeTab === 'Component5' ? { color: '#5088C6' } : { color: '#fff' }}
                    >
                      0
                    </span>
                  )}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>
                <Row>
                  <Col lg="12" md="12" className="table-col">
                    <>
                      {(nextProps?.isFarmerFieldDataRequesting ||
                        nextProps?.isEnrollmentFieldDataRequesting ||
                        nextProps?.isInsuranceFieldDataRequesting ||
                        nextProps?.isImpactFieldDataRequesting ||
                        nextProps?.isClaimFieldDataRequesting ||
                        isLoading) && <Loader />}
                      {/* {isLoading && <Loader />} */}
                      {activeTab !== 'ImpactEvaluation' && activeTab !== 'Component5'
                        ? newRequestList && (
                          <ManageUserTable
                            loading={false}
                            req_data={tableData}
                            selectedTab={activeTab}
                            handlePageSize={handlePageSize}
                            pageCount={pageCount}
                            handlePageNumber={handlePageNumber}
                            pageNumber={pageNumber}
                            recordsCount={recordsCount}
                            activeTab={activeTab}
                            loggedUserData={userPermissions}
                            userDetails={userDetails}
                            fieldDataListData={nextProps?.fieldDataListData}
                            fieldDataId={fieldDataId}
                            isLoading={loading}
                            selectedDate={JSON?.stringify(
                              configurationSelectedDate?.getFullYear(),
                            )}
                            newRequestList={newRequestList}
                            initPageSize={pageSize}
                            search={search}
                            loaderEnabled={isLoading}
                          />
                        )
                        : newRequestList && (
                          <ManageUserTable1
                            loading={false}
                            req_data={tableData}
                            selectedTab={activeTab}
                            handlePageSize={handlePageSize}
                            pageCount={pageCount}
                            handlePageNumber={handlePageNumber}
                            pageNumber={pageNumber}
                            recordsCount={recordsCount}
                            activeTab={activeTab}
                            loggedUserData={userPermissions}
                            userDetails={userDetails}
                            fieldDataId={fieldDataId}
                            isLoading={loading}
                            selectedDate={JSON?.stringify(
                              configurationSelectedDate?.getFullYear(),
                            )}
                            newRequestList={newRequestList}
                            initPageSize={pageSize}
                            search={search}
                            loaderEnabled={isLoading}
                          />
                        )}
                    </>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </form>
      </Container>
    </>
  );
};

// export default ManageFieldData;

// export default reduxForm({
//   form: 'ManageFieldDataForm',
//   // touchOnChange: true,
//   // touchOnBlur: true,
//   // validate,
// })(ManageFieldData);

export default compose(
  reduxForm({
    form: 'ManageFieldDataForm',
  }),
)(ManageFieldData);
