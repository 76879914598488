import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-multi-lang';
import Button from 'reactstrap-button-loader';

// import FilterTabs from './filter-tabs';

import { renderTextField } from '../../Components/common/renderInputField';
import { Field, reduxForm } from 'redux-form';
import { Card, Container, FormGroup, FormFeedback, Label, Input } from 'reactstrap';

import { change } from 'redux-form';

import CopyLinkImg from '../../assets/Images/copy-link.png';

import { isValidEmail, getToken, getODKAccessToken } from '../../Components/common/utils';

import {
  inviteConfigurationRequest,
  inviteConfigurationSuccess,
} from '../../redux/manageFieldData/manageFieldDataActions';
import { dispatch } from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/common/loader';

import { showError, showSuccess } from '../../Components/Notifications';
import { RiContactsBookLine } from 'react-icons/ri';
import jwt_decode from 'jwt-decode';

import { SCOPE, discoveryUrl, CLIENT_ID, API_KEY } from '../../Components/constants/index';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['email'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (!values.name) {
    errors.name = 'Please enter name.';
  } else if (values?.name?.length > 20) {
    errors.name = 'Name must be below 20 characters.';
  }
  if (values?.name?.startsWith(' ')) {
    errors.name = 'Please enter valid email';
  }
  if (!isValidEmail(values?.email)) {
    // errors.email = t('REGISTER.EMAIL_ERROR_MSG');
    errors.email = 'Invalid email address.';
  } else if (values?.email?.includes(' ')) {
    // errors.email = t('REGISTER.EMAIL_ERROR_MSG');
    errors.email = 'Invalid email address.';
  }

  return errors;
};

const InvitePeopleModal = (props) => {
  const { modalOpen, setModalOpen, signedInUser, tokenClient, isTokenExpired, setIsTokenExpired } = props;
  const [selectedTab, setSelectedTab] = useState();
  const [email, setEmail] = useState('');

  const t = useTranslation();
  const history = useHistory();

  const [modal, setModal] = useState();
  const [accessToken,setAccessToken] = useState('');

  useEffect(() => {
    setModal(modalOpen);
  }, [modalOpen]);

  const viewtoggle = (value) => {
    setModal(false);
  };

  const nextProps = useSelector((state) => ({
    isFieldDataListDataRequesting: state.fieldDataList.isFieldDataListDataRequesting,
    fieldDataListData: state?.fieldDataList?.FieldDataListData,
    loginDetailData: state.Login.loginDetailData || null,
    inviteConfigurationData: state.fieldDataList?.inviteConfigurationData,
    inviteConfigurationRequestingData: state.fieldDataList?.isInviteConfigurationRequesting,
    inviteConfigurationErrorData: state.fieldDataList?.inviteConfigurationError,
    inviteConfigurationError: state?.fieldDataList?.inviteConfigurationError,
  }));

  const dispatch = useDispatch();

  const handleChangeEmail = (e) => {
    // if
  };

  const [error, setError] = useState(null);
  const [successmsg, setSuccessmsg] = useState(null);

  useEffect(() => {
    if (nextProps.inviteConfigurationData) {
      // setSuccessmsg(nextProps.inviteConfigurationData?.data?.message);
      showSuccess(nextProps.inviteConfigurationData?.data?.message);
      history.push('/manageSurveyors');
    }
    if (nextProps.inviteConfigurationErrorData) {
      showError(nextProps.inviteConfigurationErrorData?.data?.response?.data?.message);
    }
  }, [nextProps.inviteConfigurationData, nextProps.inviteConfigurationErrorData]);

  useEffect(() => {
    return () => {
      dispatch(inviteConfigurationSuccess({}));
    };
  }, []);

  const localUserPermission = localStorage.getItem('userPermissions');
  let driveFolderId = JSON.parse(localUserPermission)?.result?.user_profile?.drive_folder_id;

  useEffect(() => {
  }, []);

  //create permissions
  async function createPermissions(driveFolderId, email, accessToken, email_id) {
    await fetch(`https://www.googleapis.com/drive/v3/files/${driveFolderId}/permissions?key=${API_KEY}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify({
        role: 'writer',
        type: 'user',
        emailAddress: email_id,
      })
    }).then(res => res.json())
    .then(function (response) {
      if(response?.error) throw response?.error;
      // Handle the results here (response.result has the parsed body).
      dispatch(inviteConfigurationRequest({ email_id: email_id }));
      // showSuccess("Success.")
      },
    )
    .catch(err => {
      if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
        setIsTokenExpired(true)
      } else {
        if(err.code === 400) showError(err?.message)
        setIsTokenExpired(false)
      }
    })
  }

  useEffect(() => {
    if (nextProps.inviteConfigurationData && email) {
      handleClose()
    }
  }, [nextProps.inviteConfigurationData]);

  const onFormSubmit = (e) => {
    // dispatch(inviteConfigurationRequest({ email_id: e?.email }));
    let accessToken = getODKAccessToken();
      if(accessToken && !isTokenExpired){
            createPermissions(driveFolderId, email, accessToken, e?.email);
      }
      else {
        tokenClient?.requestAccessToken({ prompt: 'consent' })
        tokenClient.callback = (res) => {
          if(res && res?.access_token){
            createPermissions(driveFolderId, email, res?.access_token, e?.email);
          }
        }
    }
    setEmail(e?.email);
  };

  const handleClose = () => {
    dispatch(change('InvitePeopleForm', 'email', ''));

    setModal(false);
    setModalOpen(false);
  };

  const { handleSubmit, pristine, submitting, invalid } = props;

  return (
    <>
      <div className="client-modal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className="weather-forecast-modal modal-lg"
          backdrop="static"
          style={{ width: '518px' }}
        >
          <ModalHeader
            className="weather-forecast-modal-title invitepeople1"
            toggle={handleClose}
            style={{ backgroundColor: '#DCE7F4', color: '#3870AF' }}
          >
            Invite
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Row>
                <Col sm={6} md={6} lg={8}>
                  <FormGroup className="registertextOnInput mb-3" autocomplete="off">
                    <Field
                      type="text"
                      name="email"
                      component={renderTextField}
                      className="register-input"
                      onChange={(e) => handleChangeEmail(e)}
                      placeholder="Enter email address"
                    />
                  </FormGroup>
                </Col>
                <Col sm={6} md={6} lg={4}>
                  <Button
                    style={
                      pristine || submitting || invalid
                        ? { backgroundColor: '#3870AF', height: '42px', width: '150px'}
                        : { backgroundColor: '#3870AF', height: '42px', width: '150px' }
                    }
                    type="submit"
                    disabled={pristine || submitting || invalid}
                    loading={nextProps.inviteConfigurationRequestingData}
                  >
                    Send invite
                  </Button>
                </Col>
              </Row>
            </form>

            <hr />

            <Row></Row>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'InvitePeopleForm',
  touchOnChange: true,
  touchOnBlur: true,
  validate,
})(InvitePeopleModal);
