import React from 'react';
import { t } from 'react-multi-lang';
import { TbPolygon } from 'react-icons/tb';
import DrawFarmGif from '../../../assets/Images/draw-farm-img.gif';
import { ReactComponent as UploadFarm } from '../../../assets/Images/Upload_Farm.svg';

const FarmOptions = props => {
  const { handleSelectedFarmOption } = props;
  return (
    <>
      <div className="draw-bt-st">
        <div className="poly-st my-1">
          <TbPolygon size={30} /> {t('FARM_TRACKER.DRAW_YOUR_FARMS')}
        </div>
        <div className="mr-1">
          <img src={DrawFarmGif} alt={'gif img'} width={220} height={180} />
        </div>
        <div className="sub-text my-2"> {t('FARM_TRACKER.DRAW_FARM_TEXT')}</div>
        <button
          type="submit"
          className="btn upload-farm-bt"
          onClick={() => handleSelectedFarmOption(t('FARM_TRACKER.DRAW_FARM_KEY'))}
        >
          {t('FARM_TRACKER.DRAW_FARM')}
        </button>
      </div>
      <div className="draw-bt-st my-2">
        <div className="poly-st my-1">
          <UploadFarm size={30} /> {t('FARM_TRACKER.UPLOAD_FARMS')}
        </div>
        <div className="sub-text my-2">{t('FARM_TRACKER.UPLOAD_FARM_MSG')} </div>
        <button
          type="submit"
          className="btn upload-farm-bt"
          onClick={() => handleSelectedFarmOption(t('FARM_TRACKER.UPLOAD_FARM_KEY'))}
        >
          {t('FARM_TRACKER.UPLOAD_FARM')}
        </button>
      </div>
    </>
  );
};

export default FarmOptions;
