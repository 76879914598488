import React from 'react';
import CommonToolTip from './CommonToolTip';
import { getToken } from './utils';
import { Navbar, Nav } from 'reactstrap';
import { useHistory } from 'react-router-dom';

export default function CommonModulesHeader({ subHeadingList, path }) {
  const history = useHistory();
  return (
    <div className="headernav-div">
      <Navbar className="public-api-navbar p-0 nav2" expand="md">
        <Nav className="mx-auto mobi-scroll" navbar>
          <ul className="nav-menu-items mobi-scroll">
            <>
              <li
                key={0}
                className={
                  path.includes(subHeadingList?.[0].path)
                    ? 'nav-text active cursor-pointer'
                    : 'cursor-pointer'
                }
                onClick={() => history.push('/home')}
              >
                <span className="icon-color">{subHeadingList?.[0].icon}</span>&nbsp; &nbsp;
                {subHeadingList?.[0].name}
              </li>
              {getToken() === false ? (
                <CommonToolTip moduleName={'Weather & Water'}>
                  <li
                    key={1}
                    className={
                      // 
                      path.includes(subHeadingList?.[1].path) ||
                      path.includes(subHeadingList?.[2].path)
                        ? 'nav-text active cursor-pointer'
                        : 'cursor-pointer'
                    }
                    onClick={() => (getToken() ? history.push('/weather-forecast') : '')}
                  >
                    <span className="icon-color">{subHeadingList?.[1].icon}</span>&nbsp; &nbsp;
                    {subHeadingList?.[1].name}
                  </li>
                </CommonToolTip>
              ) : (
                <li
                  key={1}
                  className={
                    path.includes(subHeadingList?.[1].path) ||
                      path.includes(subHeadingList?.[2].path)
                      ? 'nav-text active cursor-pointer'
                      : 'cursor-pointer'
                  }
                  onClick={() => (getToken() ? history.push('/weather-forecast') : '')}
                >
                  <span className="icon-color">{subHeadingList?.[1].icon}</span>&nbsp; &nbsp;
                  {subHeadingList?.[1].name}
                </li>
              )}
              {getToken() === false ? (
                <CommonToolTip moduleName={'Crop Health Monitor'}>
                  <li
                    key={3}
                    className={
                      path.includes(subHeadingList?.[3].path) ||
                      path.includes(subHeadingList?.[4].path)
                        ? 'nav-text active cursor-pointer'
                        : 'cursor-pointer'
                    }
                    onClick={() =>
                      getToken() ? history.push('/crop-health-monitoring?key=agriculture') : ''
                    }
                  >
                    <span className="icon-color">{subHeadingList?.[3].icon}</span>&nbsp; &nbsp;
                    {subHeadingList?.[3].name}
                  </li>
                </CommonToolTip>
              ) : (
                <li
                  key={3}
                  className={
                    path.includes(subHeadingList?.[3].path) ||
                    path.includes(subHeadingList?.[4].path)
                      ? 'nav-text active cursor-pointer'
                      : 'cursor-pointer'
                  }
                  onClick={() =>
                    getToken() ? history.push('/crop-health-monitoring?key=agriculture') : ''
                  }
                >
                  <span className="icon-color">{subHeadingList?.[3].icon}</span>&nbsp; &nbsp;
                  {subHeadingList?.[3].name}
                </li>
              )}
              {getToken() === false ? (
                <CommonToolTip moduleName={'Farm Tracker'}>
                  <li
                    key={4}
                    className={
                      path.includes(subHeadingList?.[5].path) ||
                      path.includes(subHeadingList?.[6].path) 
                        ? 'nav-text active cursor-pointer'
                        : 'cursor-pointer'
                    }
                    onClick={() => (getToken() ? history.push('/farms-list') : '')}
                  >
                    <span className="icon-color">{subHeadingList?.[5].icon}</span>&nbsp; &nbsp;
                    {subHeadingList?.[6].name}
                  </li>
                </CommonToolTip>
              ) : (
                <li
                  key={4}
                  className={
                    path.includes(subHeadingList?.[5].path) ||
                    path.includes(subHeadingList?.[6].path) 
                      ? 'nav-text active cursor-pointer'
                      : 'cursor-pointer'
                  }
                  onClick={() => (getToken() ? history.push('/farms-list') : '')}
                >
                  <span className="icon-color">{subHeadingList?.[5].icon}</span>&nbsp; &nbsp;
                  {subHeadingList?.[6].name}
                </li>
              )}

              {getToken() === false ? (
                <CommonToolTip moduleName={'Risk Analytics'}>
                  <li
                    key={5}
                    className={
                      path.includes(subHeadingList?.[7].path)
                        ? 'nav-text active cursor-pointer'
                        : 'cursor-pointer'
                    }
                    onClick={() => (getToken() ? history.push('/risk-analytics') : '')}
                  >
                    <span className="icon-color">{subHeadingList?.[7].icon}</span>&nbsp; &nbsp;
                    {subHeadingList?.[7].name}
                  </li>
                </CommonToolTip>
              ) : (
                <li
                  key={5}
                  className={
                    path.includes(subHeadingList?.[7].path)
                      ? 'nav-text active cursor-pointer'
                      : 'cursor-pointer'
                  }
                  onClick={() => (getToken() ? history.push('/risk-analytics') : '')}
                >
                  <span className="icon-color">{subHeadingList?.[7].icon}</span>&nbsp; &nbsp;
                  {subHeadingList?.[7].name}
                </li>
              )}
              <li
                key={6}
                className={
                  path.includes(subHeadingList?.[8].path)
                    ? 'nav-text active cursor-pointer'
                    : 'cursor-pointer'
                }
                onClick={() => history.push('/user-guide')}
              >
                <span className="icon-color">{subHeadingList?.[8].icon}</span>&nbsp; &nbsp;
                {subHeadingList?.[8].name}
              </li>
            </>
          </ul>
        </Nav>
      </Navbar>
    </div>
  );
}
