import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Container } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-multi-lang';
import { userListRequest, userListSuccess } from '../../../redux/manage-user/manageUserAction';
import { loginDetailrequest } from '../../../redux/login/loginActions';
import { isStaffUser } from '../../../Components/common/utils';
import { ManageFarmsListTable } from './manageFarmsListTable.js';
import RightArrow from '../../../assets/Images/back-button.svg';
import Loader from '../../../Components/common/loader';
import { projectFarmListrequest } from '../../../redux/login/loginActions';
import _ from 'lodash';

const ManageFarmsList = () => {
  const nextProps = useSelector((state) => ({
    isUserListDataRequesting: state.UserList.isUserListDataRequesting,
    userListData: state.UserList.userListData || null,
    loginDetailData: state.Login.loginDetailData || null,
    projectFarmListData: state.Login.projectFarmListData || null,
    isProjectFarmListRequesting:state.Login.isProjectFarmListRequesting || null,
  }));

  const dispatch = useDispatch();
  let history = useHistory();
  const paramData = useLocation();
  const [activeTab, setActiveTab] = useState('new');
  const [newManageFarmList, setNewManageFarmList] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [userPermissions, setUserPermissions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [recordsCount, setRecordsCount] = useState();
  const [allowedPermission, setAllowedPermission] = useState();

  const t = useTranslation();
  useEffect(() => {
    if (paramData?.state) {
      dispatch(
        projectFarmListrequest({
          pageKey: 'page',
          page: 1,
          limitKey: 'limit',
          limit: pageLimit,
          user_id: paramData?.state,
        }),
      );
    }
  }, [paramData]);

  useEffect(() => {
    if (nextProps.projectFarmListData) {
      if (nextProps?.projectFarmListData?.data?.result?.results?.length) {
        setNewManageFarmList(nextProps?.projectFarmListData?.data?.result?.results);
        setPageCount(nextProps?.projectFarmListData?.data?.result?.count);
        setRecordsCount(nextProps?.projectFarmListData?.data?.result?.results.length);
      } else {
        setNewManageFarmList([]);

        setPageCount(0);
        setRecordsCount(0);
      }
    } else {
      setNewManageFarmList([]);
    }
  }, [nextProps.projectFarmListData]);


  const handlePageNumber = (value) => {
    setPageNumber(value);
    dispatch(
        projectFarmListrequest({
          pageKey: 'page',
          page: value,
          limitKey: 'limit',
          limit: pageLimit,
          user_id: paramData?.state,
        }),
      );
  };

  const handlePageSize = (value) => {
    setPageLimit(value);
    setPageNumber(1);
    dispatch(
        projectFarmListrequest({
            pageKey: 'page',
            page: pageNumber,
            limitKey: 'limit',
            limit: value,
          user_id: paramData?.state,
        }),
      );
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <Container fluid>
        <div className="right-img-wrapper api-key-list">
          <Row>
            <Col xs="12" sm="12" md="12" lg="12" className="manage-projects">
              <img
                src={RightArrow}
                alt="back-arrow"
                className="manage-user-header-back-button"
                onClick={() => history.push('/manage-user')}
              />
              <h3 className="heading-manage-user manage-user-heading-mt">
                {t('MANAGE_USER.MANAGE_FARMS_LIST')}
              </h3>
            </Col>
          </Row>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <Row>
                <Col lg="12" md="12" className="table-col">
                  <>
                    {nextProps.isProjectFarmListRequesting && <Loader />}
                    {newManageFarmList && (
                      <ManageFarmsListTable
                        loading={false}
                        req_data={newManageFarmList}
                        // handleLatestData={handleLatestData}
                        selectedTab={activeTab}
                        // handleLatestDataForDelete={handleLatestDataForDelete}
                        handlePageSize={handlePageSize}
                        pageCount={pageCount}
                        handlePageNumber={handlePageNumber}
                        pageNumber={pageNumber}
                        recordsCount={recordsCount}
                        // activeTab={activeTab}
                        // loggedUserData={userPermissions}
                      />
                    )}
                  </>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </>
  );
};

export default ManageFarmsList;
