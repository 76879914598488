/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import Plotly from 'plotly.js';
import { chartDateLabels, renderGenericDateFormat } from './utils';
import {
  CHART_LABEL_COLORS,
  DATA_SOURCES,
  DISPLAY_CHART_DATE,
  GRAPH_YAXIS_COLORS,
} from '../../Components/constants/index';
import { OPEN_WEATHER_CHART_PARAMS } from '../constants/index';
import { NOAA_PARAMETER_SLUG } from '../constants/index';
import { PlotlyPNGLogo, PlotlyCSVLogo, PlotlyCloseLogo } from '../constants';
import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const PlotGEENOAACharts = (props) => {
  const {
    chartData,
    parametersData,
    renderCharts,
    dataSource,
    isOpenWeather,
    fileName,
    temporalAggregation,
    selectedParams,
    location,
    region,
    initialSelectedDate,
  } = props;
  const [dates, setDates] = useState([]);
  const [isFirstYAxisDeselected, setIsFirstYAxisDeselected] = useState(false);
  const [yOptions, setYOptions] = useState([]);
  const [finalChartData, setFinalChartData] = useState({});
  const [objKeysData, setObjKeysData] = useState([]);
  const ButtonClick = useRef('');
  const [newFileName, setFileName] = useState(
    `${fileName.split('-')[0]}-${fileName.split('-')[1]}`,
  );
  const [novaGfsParams, setNovaGfsParams] = useState([
    {
      name: 'Precipitation',
      measure: 'kg/m^2',
      visible: true,
      dash: '',
      type: 'bar',
      mode: '',
    },
    {
      name: 'Temperature',
      measure: '°C',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: 'lines',
    },
    {
      name: 'Wind_speed',
      measure: 'm/s',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: 'lines',
    },
    {
      name: 'Humidity',
      measure: '%',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: 'lines',
    },
  ]);
  const [csvFileData, setCsvFileData] = useState([]);
  const [layout, updateLayout] = useState({
    bargap: 0.7,
    responsive: true,
    title: {
      text: `Data Source : ${dataSource[0]?.name} ${
        dataSource[0]?.id === DATA_SOURCES.IRI.id ? '' : `(${temporalAggregation?.name})`
      }`,
      plot_bgcolor: '#E5ECF6',
      font: {
        family: 'National',
        color: '#28609e',
        size: 15,
      },
      x: 0.5,
      xanchor: 'center',
    },
    // plot_bgcolor: "#E5ECF6",
    // paper_bgcolor: "#E5ECF6",
    // title: {
    //   text: `<b> Data Source : ${dataSource[0]?.name} ${dataSource[0]?.id === DATA_SOURCES.IRI.id ? '' : `(${temporalAggregation?.name})`}  </b>`,
    // },
    modebardisplay: true,
    // width: 1600,
    font: {
      size: 12,
      color: '#000',
    },
    xaxis: {
      // domain: [0, 0.8],
      showline: true,
      tickangle: 50,
      tickfont: {
        size: 12,
        color: '#000',
        family: 'National',
      },
    },
    showgrid: false,
    showlegend: true,
    // margin: 80,
    legend: {
      orientation: 'h',
      // x: 0.3,
      x: 0.5,
      xanchor: 'center',
      y: `${region?.length ? -0.8 : -0.5}`,
      font: {
        // family: 'sans-serif',
        size: 15,
        color: '#000',
        family: 'National',
      },
    },
    margin: {
      t: 55,
      b: 50,
    },
  });

  useEffect(() => {
    let x = novaGfsParams?.map((i, index) => {
      setFinalChartData((prev) => {
        return {
          ...prev,
          [i.name]: chartData[i.name],
        };
      });
    });
  }, [chartData]);
  useEffect(() => {
    if (selectedParams && selectedParams?.weather_forecast_parameter) {
      let selectedParameter = selectedParams?.weather_forecast_parameter.name;
      selectedParameter = selectedParameter.replace(' ', '_');
      let reOrderedParams = [];
      novaGfsParams.forEach((param, index) => {
        if (param.name === selectedParameter) {
          param.visible = true;
          reOrderedParams.push(param);
          updateLayout((preValues) => ({
            ...preValues,
            yaxis: {
              title: getTitle(param?.name,param?.measure,location,region), //for multiple y-axis------
              titlefont: { color: GRAPH_YAXIS_COLORS[0], family: 'National' },
              tickfont: { color: GRAPH_YAXIS_COLORS[0] },
              showline: true,
              showgrid: false,
              zeroline: false,
              rangemode: 'tozero',
            },
          }));
        }
      });
      if (reOrderedParams.length) {
        novaGfsParams.forEach((param) => {
          if (param.name !== selectedParameter) {
            param.visible = 'legendonly';
            reOrderedParams.push(param);
          }
        });
      }
      setNovaGfsParams(reOrderedParams);
    }
  }, [selectedParams]);

  const handleTraces = (chart_data, i, index) => {
    let b = [];
    chart_data[i?.name]?.Timestamp?.map((a) => {
      b.push(renderGenericDateFormat(a));
    });
    const ydata = {
      type: i?.type,
      mode: i?.mode,
      name: i?.name === 'Wind_speed' ? 'Wind speed' : `${i?.name}`,
      x: b,
      hovertemplate: `%{x} : ${
        i?.name === 'Wind_speed' ? 'Wind speed' : i.name
      } : %{y}<extra></extra>`,
      units: i?.measure,
      y: chart_data[i?.name]?.data?.map((i) => i.toFixed(2)),
      customData:chart_data[i?.name]?.data?.map((i) => i.toFixed(4)),
      visible: i?.visible,
      line: { color: GRAPH_YAXIS_COLORS[index], dash: i?.dash },
      position: undefined,
    };
    if (i.type === 'scatter') {
      ydata.line = { color: GRAPH_YAXIS_COLORS[index], dash: i.dash, width: 3 };
    } else {
      ydata.marker = { color: GRAPH_YAXIS_COLORS[index], dash: i.dash };
    }

    if (i?.name === 'Precipitation') {
      if (index === 0) {
        ydata.yaxis = `y`;
      }
      // ydata.y.push(i?.temp?.day);
    } else if (i?.name === 'Temperature') {
      if (index === 0) {
        ydata.yaxis = `y`;
      }
      // ydata.y.push(i?.wind_speed);
    } else if (i?.name === 'Wind_speed') {
      if (index === 0) {
        ydata.yaxis = `y`;
      }
      // ydata.y.push(i?.rain);
    } else if (i?.name === 'Humidity') {
      if (index === 0) {
        ydata.yaxis = `y`;
      }
      // ydata.y.push(i?.pressure);
    }
    setDates(b);
    //for multiple y-axis------------------------
    //  if(index !== 0){
    //   ydata.yaxis = `y${index+1}`
    //  }
    //for multiple y-axis------------------------
    return ydata;
  };
  useEffect(() => {
    if (finalChartData && !isOpenWeather) {
      let options = [];
      novaGfsParams.map((i, index) => {
        let returned_values = handleTraces(finalChartData, i, index);
        options.push(returned_values);
      });
      // let x=[]
      // options.map((o) => {
      //   o.x = x;
      //   o.hovertemplate = `%{x} : ${o.name} - %{y}<extra></extra>`
      // });
      setYOptions(options);
    }
  }, [finalChartData]);

  const handleTitle = (i) => {
    if (chartData) {
      return Object.keys(chartData)[i];
    }
  };

  const getActiveLegends = (isDownloadCSV, graphData) => {
    if (document.getElementById('gfsnoaaId')?.data?.length) {
      const activeLegends = graphData.data?.filter((item) => item.visible === true);
      if (activeLegends.map((data) => data.name)?.length) {
        //setting file name based on active legends
        setFileName(
          `${fileName.split('-')[0]}${fileName.split('-')[1] ? `-${fileName.split('-')[1]}` : ''}${
            location ? `-(${location})` : ''
          }${
            region?.length
              ? `-Point(${region[0]?.latlngs[1].toFixed(2)}-${region[0]?.latlngs[0].toFixed(2)})`
              : ''
          }-${activeLegends.map((data) => data.name).join(' ')}`,
        );
      }
      if (activeLegends?.length) {
        //formatting data for csv file
        const xaixsValue = activeLegends[0].x;
        let data = [];
        xaixsValue.forEach((date, key) => {
          activeLegends.forEach((item, index) => {
            if (index === 0) {
              data.push({ Date: date, [item.name]: item?.customData[key] });
            } else {
              data[key][item.name] = item?.customData[key];
            }
          });
        });
        setCsvFileData(data);
        let objKeys = Object.keys(data[0]);
        if (objKeys) {
          setObjKeysData(objKeys);
        }
        if (isDownloadCSV) {
          //downloading csv file
          ButtonClick.current.click();
        } else {
          //downloading png file
          Plotly.downloadImage(graphData, {
            filename: `${fileName.split('-')[0]}${
              fileName.split('-')[1] ? `-${fileName.split('-')[1]}` : ''
            }${location ? `-(${location})` : ''}${
              region?.length
                ? `-Point(${region[0]?.latlngs[1].toFixed(2)}-${region[0]?.latlngs[0].toFixed(2)})`
                : ''
            }-${activeLegends.map((data) => data.name).join(' ')}`,
            format: 'png',
            width: graphData._fullLayout.width,
            height: graphData._fullLayout.height,
          });
        }
      }
    }
  };

  let config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true, //by default we are showing
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        click: function (gd) {
          getActiveLegends(false, gd);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        click: function (gd) {
          getActiveLegends(true, gd);
        },
      },
      {
        name: 'Close',
        icon: PlotlyCloseLogo,
        click: function () {
          renderCharts();
        },
      },
    ],
    showTips: false,
  };

  //for multiple y-axis------------------------
  const getLayoutWidths = [
    { yaxisCount: 1, domain: [0, 0] },
    { yaxisCount: 2, domain: [0, 0] },
    { yaxisCount: 3, domain: [0, 0.93] },
    { yaxisCount: 4, domain: [0, 0.87] },
  ];

  const getTitle = (name, measure, location, region) => {
    if (region?.length) {
      return name === 'Wind_speed'
      ? `<b>Wind speed (${measure})</b>`
      : `<b>${name} (${measure})</b>`
    }
    else {
      return name === 'Wind_speed'
      ? `<b>Wind speed (${measure})<br>${location ? `(${location})` : ''}</b>`
      : `<b>${name} (${measure})<br>${
          location ? `(${location})` : ''
        }</b>`
    }
  }

  const handlePosition = (axisCount, sortedListLength, key, index) => {
    let positionValue;
    if (axisCount) {
      if (axisCount === 3) {
        if (sortedListLength === 4) {
          if (key === 3) positionValue = 1;
          else if (key === 2) positionValue = 0.93;
        } else {
          if (key === 2) positionValue = 1;
          else positionValue = 0.93;
        }
      } else if (axisCount === 4) {
        if (key === 3) {
          positionValue = 1;
        } else {
          positionValue = 0.93;
        }
      } else if (axisCount === 5) {
        positionValue = 0.85;
      } else {
        positionValue = 1;
      }
      return positionValue;
    }
  };

  //function to order multiple y axes
  const getOrderedYAxes = (layoutData, YoptionsLength, index) => {
    let yaxisArray = [];
    Object.keys(layoutData).forEach((element) => {
      if (element.includes('yaxis')) {
        yaxisArray.push(element);
      }
    });
    // let position = 1 - Number(`0.${YoptionsLength - 3}`);
    if (!yaxisArray.find((e) => e === 'yaxis')) {
      yaxisArray.push('yaxis');
    }
    let order = ['yaxis', 'yaxis2', 'yaxis3', 'yaxis4'];
    yaxisArray.sort((a, b) => order.indexOf(a) - order.indexOf(b));
    yaxisArray.forEach((item, key) => {
      let position = handlePosition(YoptionsLength, yaxisArray?.length, key, index);
      if (key === 0) {
        if (layoutData[item]) {
          layoutData[item].side = 'left';
          layoutData[item].position = undefined;
        }
      } else if (key === 1) {
        layoutData[item].anchor = 'x';
        layoutData[item].side = 'right';
        layoutData[item].position = undefined;
      } else {
        layoutData[item].anchor = 'free';
        layoutData[item].side = 'right';
        layoutData[item].position = position;
        position += 0.1;
      }
    });
    return layoutData;
  };
  const handleLegends = (graphData) => {
    let index = graphData.expandedIndex;
    let data = graphData.data[index];
    let newYOptions = [...yOptions];
    if (data.visible !== true) {
      newYOptions[index]['yaxis'] = `y${index + 1}`;
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      if (yaxisCount > 2) {
        newYOptions[yaxisCount - 1]['position'] =
          yaxisCount === newYOptions.length ? 1 : handlePosition(yaxisCount);
        // newYOptions[yaxisCount - 1]['position'] = yaxisCount === newYOptions.length ? 1 : 0.9;
      }
      setYOptions(newYOptions);
      layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      if (index !== 0) {
        if (isFirstYAxisDeselected)
          layout.xaxis.domain = getLayoutWidths.find(
            (item) => item.yaxisCount === yaxisCount + 1,
          )?.domain;
        else
          layout.xaxis.domain = getLayoutWidths.find(
            (item) => item.yaxisCount === yaxisCount,
          )?.domain;
        let yaxisName = `yaxis${index + 1}`;
        layout[yaxisName] = {
          title: getTitle(data?.name,data?.units,location,region),
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          anchor: yaxisCount === 2 ? 'x' : 'free',
          overlaying: 'y',
          side: 'right',
          position: newYOptions[yaxisCount - 1]['position'],
          showline: true,
          showgrid: false,
          zeroline: false,
          rangemode: 'tozero',
        };
      } else if (index === 0) {
        setIsFirstYAxisDeselected(false);
        // layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
        layout.xaxis.domain = getLayoutWidths.find(
          (item) => item.yaxisCount === yaxisCount,
        )?.domain;
        layout.yaxis = {
          title: getTitle(data?.name,data?.units,location,region),
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          side: 'left',
          showline: true,
          showgrid: false,
          zeroline: false,
          rangemode: 'tozero',
        };
      }
      // let orderedLayoutData = getOrderedYAxes(layout, yOptions.length);
      let orderedLayoutData = getOrderedYAxes(layout, yaxisCount);
      updateLayout(orderedLayoutData);
    } else {
      delete newYOptions[index]['yaxis'];
      setYOptions(newYOptions);
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      let yaxisName = index === 0 ? `yaxis` : `yaxis${index + 1}`;
      let layoutData = { ...layout };
      delete layoutData[yaxisName];
      if(yaxisName === "yaxis"){
        layoutData[yaxisName] = { showgrid: false, zeroline:false, tickfont : { color: '#fff'}}
      }
      if (index === 0) setIsFirstYAxisDeselected(true);
      let updatedYAxisCount = index === 0 || isFirstYAxisDeselected ? yaxisCount + 1 : yaxisCount;
      layout.xaxis.domain = getLayoutWidths.find(
        (item) => item.yaxisCount === updatedYAxisCount,
      )?.domain;
      // let orderedLayoutData = getOrderedYAxes(layoutData, yOptions.length);
      let orderedLayoutData = getOrderedYAxes(layoutData, yaxisCount, index);
      updateLayout(orderedLayoutData);
    }
  };
  return (
    <>
      <span
        style={{
          color: '#28609e',
          position: 'absolute',
          left: '5px',
          top: '2%',
          family: 'National',
          fontSize: '15px',
          zIndex: 100,
        }}
      >
        Date : {moment(initialSelectedDate?.graph?.startDate).format(DISPLAY_CHART_DATE)} to{' '}
        {moment(initialSelectedDate?.graph?.endDate).format(DISPLAY_CHART_DATE)}
      </span>
      <Plot
        data={yOptions}
        layout={layout}
        config={config}
        onLegendClick={(data) => handleLegends(data)}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        divId="gfsnoaaId"
      />
      <ExcelFile
        filename={newFileName}
        element={
          <button
            ref={ButtonClick}
            className="btn btn-download ml-auto"
            style={{ display: 'none' }}
          >
            Download
          </button>
        }
      >
        <ExcelSheet data={csvFileData} name="Weather_and_Water">
          {/* <ExcelColumn label="Date" value="Date" />
          <ExcelColumn label="Precipitation" value="Precipitation" />
          <ExcelColumn label="Temperature" value="Temperature" />
          <ExcelColumn label="Wind speed" value="Wind_speed" />
          <ExcelColumn label="Humidity" value="Humidity" /> */}
          {objKeysData?.map((item) => (
            <ExcelColumn label={item} value={item} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
export default PlotGEENOAACharts;
