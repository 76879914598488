import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Card, CardBody, Col, Row, Container } from 'reactstrap';
import { t } from 'react-multi-lang';
import _ from 'lodash';
import { RiPencilFill } from 'react-icons/ri';
import RightArrow from '../../assets/Images/back-button.svg';
import '../../assets/CSS/FarmTracker.css';
import { ReactComponent as FarmListIcon } from '../../assets/Images/FarmListIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../Components/common/loader';
import { useLocation } from 'react-router-dom';
import {
  addFarmTrackerSuccess,
  deleteFarmTrackerSuccess,
  editFarmTrackerSuccess,
  editProjectSuccess,
  getAllByCriteriaFarmListSuccess,
  getAllByCriteriaProjectListRequest,
  getFarmDetailsByIdSuccess,
  deleteProjectRequest,
} from '../../redux/actions';
import { renderProjectDateFormat, getToken } from '../../Components/common/utils';
import ReactTooltip from 'react-tooltip';
import { DEFAULT_COUNTRY, FARM_TRACKER_MODULE } from '../../Components/constants';

// import {HiTrash} from "react-icons/hi"

import projectDelete from '../../assets/Images/projectDelete.png';

import ProjectDeleteModal from './deleteProjectModal';

import { showError, showSuccess } from '../../Components/Notifications';
import DisabledLeftArrowIcon from '../../assets/Images/disable_previous_image.svg';
import DisabledRightArrowIcon from '../../assets/Images/disable_next_image.svg';
import EnabledLeftArrowIcon from '../../assets/Images/enable_previous_image.svg';
import EnabledRightArrowIcon from '../../assets/Images/enable_next_image.svg';
import Cookies from 'js-cookie';

const FarmTrackerList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [recordsCount, setRecordsCount] = useState();
  const [pageSize, setPageSize] = useState(10);

  const [latValues, setLatValues] = useState();
  const [lonValues, setLonValues] = useState();
  const [filterDistrict,setFilterDistrict] = useState();
  const [odkImages,setOdkImages] = useState([]);

  const page_range = [10, 15, 20, 25];

  const { FarmTracker } = useSelector((state) => ({
    FarmTracker: state.FarmTracker,
  }));
  useEffect(() => {
      if (getToken()) {
      let search = window.location.search;
      let URLParams = new URLSearchParams(search);
      let latValue = URLParams.get('lat');
      let lonValue = URLParams.get('lon');
      let district = URLParams.get('district');
      let images = Cookies.get('odk-images');
      if(images)
      setOdkImages(images);
      setLatValues(latValue);
      setLonValues(lonValue);
      setFilterDistrict(district)
      if(district){
        dispatch(
          getAllByCriteriaProjectListRequest({
            pageKey: 'page',
            page: 1,
            limitKey: 'limit',
            limit: 10,
            districtKey: 'district_name',
            district: district,
          }),
        );
      }
      else{
        dispatch(
          getAllByCriteriaProjectListRequest({
            pageKey: 'page',
            page: 1,
            limitKey: 'limit',
            limit: 10,
          }),
        );
      }
      dispatch(editProjectSuccess({}));
      dispatch(getAllByCriteriaFarmListSuccess({}));
      dispatch(getFarmDetailsByIdSuccess({}));
      dispatch(deleteFarmTrackerSuccess({}));
      dispatch(addFarmTrackerSuccess({}));
      dispatch(editFarmTrackerSuccess({}));
      return () => {
        dispatch(deleteProjectRequest(projectId));
      };
    }
    else {
      history.push('/home');
    }
  }, []);

  useEffect(() => {
    if (FarmTracker.getAllByCriteriaProjectListRequesting) {
      setLoading(true);
    } else if (FarmTracker.getAllByCriteriaProjectListData) {
      if (FarmTracker?.getAllByCriteriaProjectListData?.data) {
        let response = FarmTracker?.getAllByCriteriaProjectListData?.data;
        if (response?.result) {
          setProjectList(response?.result?.results);
          setPageCount(response?.result?.count);
          setRecordsCount(response?.result?.results?.length);
        } else {
          setProjectList([]);
          setPageCount(0);
          setRecordsCount(0);
        }
      }
      setLoading(false);
    } else if (FarmTracker.getAllByCriteriaProjectListDataError) {
      setLoading(false);
    }
  }, [
    FarmTracker.getAllByCriteriaProjectListRequesting,
    FarmTracker.getAllByCriteriaProjectListData,
    FarmTracker.getAllByCriteriaProjectListDataError,
  ]);

  useEffect(() => {
    if (FarmTracker.deleteProjectListData !== false) {
      dispatch(getAllByCriteriaProjectListRequest());
    }
    if (FarmTracker.deleteProjectListRequesting) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [FarmTracker.deleteProjectListData, FarmTracker.deleteProjectListRequesting]);

  
  const handleSelectedItem = (item) => {
    let data = {};
    let projectData = {
      project_id: item?.id,
      title: item?.name,
      description: item?.description,
      province: { id: item?.state_id, label: item?.state_name },
      district: { id: item?.district_id, label: item?.district_name, district_id: item.district_id,...item },
      // sub_district: { id: item?.sub_district_id, label: item?.sub_district_name },
      // block: { id: item?.block_id, label: item?.block_name },
      farmsList: [],
      farm_count: item?.farm_count,
      odk_images: odkImages
    };
    data.projectData = projectData;
    data.cordinateAndZoom = {
      locationCordinates: [item?.district_latitude || DEFAULT_COUNTRY.latitude , item?.district_longitude || DEFAULT_COUNTRY.longitude],
      zoom: [item?.district_zoom_level || DEFAULT_COUNTRY.zoom_level],
    };
    if (!_.isEmpty(latValues)&&!_.isEmpty(lonValues) && !_.isEmpty(filterDistrict)){
      history.push({
          pathname: '/farm-tracker',
          search: `lat=${latValues}&lon=${lonValues}&district=${filterDistrict}`,
          state: data,
        });
    }else{
      history.push({
        pathname: '/farm-tracker',
        state: data,
      });
    }
    // history.push({
    //   pathname: '/farm-tracker',
    //   state: data,
    // });
  };
  const handleEditProject = (e, projectData) => {
    e.stopPropagation();
    localStorage.removeItem('CreateProjectData');
    if (!_.isEmpty(latValues)&&!_.isEmpty(lonValues) && !_.isEmpty(filterDistrict)){
      history.push({
        pathname: '/create-project/edit',
        search: `lat=${latValues}&lon=${lonValues}&district=${filterDistrict}`,
        state: projectData,
      });
    }else{
      history.push({
        pathname: '/create-project/edit',
        state: projectData,
      });
    }
  };

  const handleCreateProject = () => {
    localStorage.removeItem('CreateProjectData');
    if (!_.isEmpty(latValues)&&!_.isEmpty(lonValues) && !_.isEmpty(filterDistrict)){
      history.push(`/create-project/add?lat=${latValues}&lon=${lonValues}&district=${filterDistrict}`);
    }else{
      history.push('/create-project/add');
    }
  };

  //handleConfirmation

  const handleOpenConfirmation = (id) => {
    setShowModal(true);
    setProjectId(id);
  };

  useEffect(() => {
    if (FarmTracker?.deleteProjectListData) {
      if (FarmTracker?.deleteProjectListData?.data) {
        let response = FarmTracker?.deleteProjectListData?.data;
        if (response?.result) {
          let data = FarmTracker.deleteProjectListData?.data;
          showSuccess(data?.result);
          // setIsOpenDeleteFarmModal(false);
          // setFromListing(false);
          if(filterDistrict)
          dispatch(
            getAllByCriteriaProjectListRequest({
              pageKey: 'page',
              page: 1,
              limitKey: 'limit',
              limit: 10,
              districtKey: 'district_name',
              district: filterDistrict
            }),
          );
          else
          dispatch(
            getAllByCriteriaProjectListRequest({
              pageKey: 'page',
              page: 1,
              limitKey: 'limit',
              limit: 10,
            }),
          );
        }
      }
    } else if (FarmTracker?.deleteProjectListDataError) {
      if (FarmTracker?.deleteProjectListDataError?.data) {
        let errorData = FarmTracker?.deleteProjectListDataError?.data;
        let data = errorData?.response?.data;
        if (data?.message && data?.code === 400) {
          showError(data?.message);
        }
        if (data?.exception) {
          showError(data?.exception);
        }
      }
    }
  }, [FarmTracker.deleteProjectListData, FarmTracker.deleteProjectListDataError]);

  const handleInfo = (e, indices) => {
    e?.stopPropagation();
    window.open(
      `/user-guide-details?topicId=${FARM_TRACKER_MODULE.id}&&sub-topic-name=${indices}&topic_name=${indices}`,
    );
  };

  const handlepreviousPage = () => {
    setPageNumber(pageNumber - 1);
    if (filterDistrict) {
      dispatch(
        getAllByCriteriaProjectListRequest({
          pageKey: 'page',
          page: pageNumber - 1,
          limitKey: 'limit',
          limit: pageLimit,
          districtKey: 'district_name',
          district: filterDistrict
        }),
      );
    } else {
      dispatch(
        getAllByCriteriaProjectListRequest({
          pageKey: 'page',
          page: pageNumber - 1,
          limitKey: 'limit',
          limit: pageLimit,
        }),
      );
    }
  };

  const handlenextPage = () => {
    setPageNumber(pageNumber + 1);
    if(filterDistrict)
    dispatch(
      getAllByCriteriaProjectListRequest({
        pageKey: 'page',
        page: pageNumber + 1,
        limitKey: 'limit',
        limit: pageLimit,
        districtKey: 'district_name',
        district: filterDistrict
      }),
    );
    else
    dispatch(
      getAllByCriteriaProjectListRequest({
        pageKey: 'page',
        page: pageNumber + 1,
        limitKey: 'limit',
        limit: pageLimit,
      }),
    );
  };

  const pageSizeHandler = (e) => {
    setPageSize(Number(e.target.value));
    setPageLimit(Number(e.target.value));
    if(filterDistrict){
      dispatch(
        getAllByCriteriaProjectListRequest({
          pageKey: 'page',
          page: pageNumber,
          limitKey: 'limit',
          limit: Number(e.target.value),
          districtKey: 'district_name',
          district: filterDistrict
        }),
      );
    }
    else{
    dispatch(
      getAllByCriteriaProjectListRequest({
        pageKey: 'page',
        page: pageNumber,
        limitKey: 'limit',
        limit: Number(e.target.value),
      }),
    );
    }
  };

  const handleGoToPage = (e) => {
    setPageNumber(Number(e.target.value));
    if(filterDistrict){
      dispatch(
        getAllByCriteriaProjectListRequest({
          pageKey: 'page',
          page: Number(e.target.value),
          limitKey: 'limit',
          limit: pageLimit,
          districtKey: 'district_name',
          district: filterDistrict
        }),
      );
    }
    else{
    dispatch(
      getAllByCriteriaProjectListRequest({
        pageKey: 'page',
        page: Number(e.target.value),
        limitKey: 'limit',
        limit: pageLimit,
      }),
    );
    }
  };

  const handleNavigate = () => {
    if (!_.isEmpty(latValues)&&!_.isEmpty(lonValues)){
      history.push('/manageFieldData?Enrollment&fromBack=true');
    }else{
      history.push('/home')
    }
  }
  return (
    <>
      <ProjectDeleteModal modalOpen={showModal} setModalOpen={setShowModal} projectId={projectId} />
      <div className="farm-global-top">
        <div className="w-100">
          {loading ? <Loader /> : ''}
          <Container>
            <Row className="my-4">
              <Col sm="10" lg="10" md="10" className="d-flex align-items-center">
                <span className="col-lg-5 list-tl-st">
                  <img
                    src={RightArrow}
                    className="cursor-ponter"
                    onClick={() => handleNavigate()}
                    alt={''}
                  />
                  <h5 className="farm-list-st farms-list-st">
                    {t('FARM_TRACKER.LIST_OF_PROJECTS')}
                  </h5>
                  <span className="p-2 pb-2">
                    <i
                      className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                      id="list-of-project-tool-tip"
                      data-tip
                      data-for="list-of-project-tool-tip"
                      onClick={(e) => handleInfo(e, t('FARM_TRACKER.LIST_OF_PROJECTS'))}
                    />
                    <ReactTooltip id="list-of-project-tool-tip" effect="solid" place="top">
                      Click here to know more.
                    </ReactTooltip>
                  </span>
                </span>
              </Col>
              <Col>
                {!getToken() ? (
                  ''
                ) : (
                  <button
                    type="submit"
                    className="btn mr-3 create-farm-bt px-2 py-3"
                    onClick={handleCreateProject}
                  >
                    {t('FARM_TRACKER.CREATE_PROJECT')}
                  </button>
                )}
              </Col>
            </Row>
            <div>
              {getToken() ? (
                projectList?.length ? (
                  projectList?.map((item) => {
                    return (
                      <Card className="mb-3 px-4 list-card-st text-align-center cursor-ponter project-delete1">
                        <CardBody>
                          <Row>
                            <Col
                              sm="6"
                              lg="5"
                              md="5"
                              className="text-align-center"
                              onClick={() => handleSelectedItem(item)}
                            >
                              <Row className="farm-list-title">
                                <Col>
                                  {item?.name}
                                  <span
                                    className="px-2 edit-project-icon"
                                    onClick={(e) => handleEditProject(e, item)}
                                  >
                                    <RiPencilFill size={16} />
                                  </span>
                                </Col>
                              </Row>
                              <Row className="farm-location-st">
                                <Col>{`Location: ${item.state_name}, ${item.district_name}`}</Col>
                              </Row>
                            </Col>
                            <Col sm="3" lg="3" md="3">
                              <span className="farm-count-st">
                                {' '}
                                <FarmListIcon />{' '}
                                <span className="farm-text">{`${item?.farm_count} farms`}</span>
                              </span>
                            </Col>
                            <Col sm="2" lg="2" md="2" className="farm-date-st text-align-center">
                              Last updated {renderProjectDateFormat(item?.updated_date)}
                            </Col>
                            <Col
                              sm="2"
                              lg="2"
                              md="2"
                              onClick={(e) => handleOpenConfirmation(item.id)}
                            >
                              <div className="project-delete">
                                <img
                                  src={projectDelete}
                                  className="project-delete-image1"
                                  alt="Not-avaliable"
                                />{' '}
                                Delete
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    );
                  })
                ) : (
                  <>
                    <div className="no-data-st">
                      {!getToken() ? (
                        <Card className="mb-3 px-4 list-card-st text-align-center cursor-ponter">
                          <CardBody>
                            Please login to the application to explore the farm tracker
                          </CardBody>
                        </Card>
                      ) : (
                        <Card className="mb-3 px-4 list-card-st text-align-center cursor-ponter">
                          <CardBody>
                            {filterDistrict ? 'No project available for selected district. please create new project' : 'No projects available' }
                          </CardBody>
                        </Card>
                      )}
                    </div>
                  </>
                )
              ) : (
                <div className="no-data-st">
                  {!getToken() ? (
                    <Card className="mb-3 px-4 list-card-st text-align-center cursor-ponter">
                      <CardBody>
                        Please login to the application to explore the farm tracker
                      </CardBody>
                    </Card>
                  ) : (
                    <Card className="mb-3 px-4 list-card-st text-align-center cursor-ponter">
                      <CardBody>No projects available</CardBody>
                    </Card>
                  )}
                </div>
              )}
            </div>
            <div className="farm-pagination-section mb-5">
              <Row className='align-items-center'>
                <Col md="4" lg="4">
                  <span className="item-text">{t('TABLE.ITEMS_PER_PAGE')}</span>
                  <select
                    value={pageSize}
                    onChange={(e) => pageSizeHandler(e)}
                    className="page-count"
                  >
                    {page_range.map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col lg="4" md="4" className="farm-num-of-items">
                  <span>
                    {(pageNumber - 1) * pageSize + 1}-
                    {pageCount < pageNumber * pageSize ? pageCount : pageNumber * pageSize} of{' '}
                    {pageCount ? pageCount : ''} items
                  </span>
                </Col>
                <Col md="4" lg="4">
                  <div className="farm-pagination-right-col">
                    <span>
                      <input
                        type="number"
                        value={pageNumber}
                        onChange={(e) => {
                          handleGoToPage(e);
                        }}
                        disabled={
                          (pageCount % pageSize
                            ? Math.trunc(pageCount / pageSize + 1)
                            : Math.trunc(pageCount / pageSize)) === pageNumber ||
                          (pageCount % pageSize
                            ? Math.trunc(pageCount / pageSize + 1)
                            : Math.trunc(pageCount / pageSize)) === 0
                            ? true
                            : false
                        }
                        style={{ width: '50px', border: 'none',textAlign:'center' }}
                      />
                    </span>
                    <span>
                      of{' '}
                      {pageCount % pageSize
                        ? Math.trunc(pageCount / pageSize + 1)
                        : Math.trunc(pageCount / pageSize)}{' '}
                      Pages
                    </span>
                    <span>
                      <button
                        onClick={() => handlepreviousPage()}
                        className="previous-btn"
                        disabled={pageNumber <= 1 ? true : false}
                      >
                        {pageNumber <= 1 ? (
                          <img src={DisabledLeftArrowIcon} alt={'not-available'} />
                        ) : (
                          <img src={EnabledLeftArrowIcon} alt={'not-available'} />
                        )}
                      </button>
                      <button
                        onClick={() => handlenextPage()}
                        className="next-btn"
                        disabled={
                          (pageCount % pageSize
                            ? Math.trunc(pageCount / pageSize + 1)
                            : Math.trunc(pageCount / pageSize)) === pageNumber ||
                          (pageCount % pageSize
                            ? Math.trunc(pageCount / pageSize + 1)
                            : Math.trunc(pageCount / pageSize)) === 0
                            ? true
                            : false
                        }
                      >
                        {(pageCount % pageSize
                          ? Math.trunc(pageCount / pageSize + 1)
                          : Math.trunc(pageCount / pageSize)) === pageNumber ||
                        (pageCount % pageSize
                          ? Math.trunc(pageCount / pageSize + 1)
                          : Math.trunc(pageCount / pageSize)) === 0 ? (
                          <img src={DisabledRightArrowIcon} alt={'not-available'} />
                        ) : (
                          <img src={EnabledRightArrowIcon} alt={'not-available'} />
                        )}
                      </button>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default FarmTrackerList;
