import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'react-multi-lang';
import Button from 'reactstrap-button-loader';
import { Field, reduxForm } from 'redux-form';
import { Card, Container, Col, FormGroup, Label, Row } from 'reactstrap';
import { renderTextField } from '../../Components/common/renderInputField';
import {
  forgotPassword,
  resetPassword,
  forgotPasswordSuccess,
  resetPasswordSuccess,
  changePassword,
  changePasswordSuccess,
} from '../../redux/login/loginActions';
import SuccessModal from './Success';
import { isValidEmail } from '../../Components/common/utils';

const validate = values => {
  const errors = {};

  const requiredFields = ['email', 'old_password', 'new_password', 'confirm_password'];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  if (!values?.email) {
    errors.email = '';
  } else if (values?.email && !isValidEmail(values?.email?.trim())) {
    errors.email = 'Email is not valid.';
  }

  if (values?.old_password?.includes(' ')) {
    errors.old_password = t('REGISTER.PASSWORD_SPACE_ERROR');
  } else if (values?.old_password?.length < 8 || values?.old_password?.length > 16) {
    errors.old_password = 'Password must be between 8 to 16 characters';
  }

  if (values?.new_password?.length < 8 || values?.new_password?.length > 16) {
    errors.new_password = 'Password must be between 8 to 16 characters';
  } else if (values?.new_password?.includes(' ')) {
    errors.new_password = t('REGISTER.PASSWORD_SPACE_ERROR');
  }

  if (values?.confirm_password?.length < 8 || values?.confirm_password?.length > 16) {
    errors.confirm_password = 'Password must be between 8 to 16 characters';
  } else if (values?.confirm_password?.includes(' ')) {
    errors.confirm_password = t('REGISTER.PASSWORD_SPACE_ERROR');
  }

  if (values?.new_password?.length && values?.confirm_password?.length) {
    if (values?.confirm_password !== values?.new_password) {
      errors.confirm_password = 'New Password and Confirm Password must be same';
    }
  }
  return errors;
};

const ResetPassword = props => {
  const { handleSubmit, pristine, submitting, invalid } = props;
  const nextProps = useSelector(state => ({
    isForgotPasswordRequesting: state.Login.isForgotPasswordRequesting,
    isResetPasswordRequesting: state.Login.isResetPasswordRequesting,
    isChangePasswordRequesting: state.Login.isChangePasswordRequesting,
    forgotPasswordData: state.Login.forgotPasswordData,
    resetPasswordData: state.Login.resetPasswordData,
    changePasswordData: state.Login.changePasswordData,
  }));

  let req_data = {
    email: '',
  };
  const [formData, setFormData] = useState(req_data);
  const [successModal, setSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [uniqueCode, setUniqueCode] = useState();
  const [passwordTypes, setPasswordTypes] = useState({
    old_password: true,
    new_password: true,
    confirm_password: true,
  });

  let history = useHistory();
  let hasChangePassword = window.location.href.includes('change-password');
  const dispatch = useDispatch();

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let keyValue;
    keyValue = params.get('key');
    if (window.location.href.includes('key')) {
      setUniqueCode(keyValue);
    }
  }, []);

  useEffect(() => {
    if (nextProps.resetPasswordData) {
      const {
        data: { result },
      } = nextProps.resetPasswordData;
      if (result) {
        history.push('/login');
      }
    }
  }, [nextProps.resetPasswordData, history]);

  useEffect(() => {
    if (nextProps.forgotPasswordData) {
      const {
        data: { result },
      } = nextProps.forgotPasswordData;
      if (result) {
        setSuccessModal(true);
        setModalMessage(result);
      }
    }
  }, [nextProps.forgotPasswordData]);

  useEffect(() => {
    if (nextProps.changePasswordData) {
      const {
        data: { result },
      } = nextProps.changePasswordData;
      if (result) {
        history.push('/login');
      }
    }
  }, [nextProps.changePasswordData, history]);

  useEffect(() => {
    return () => {
      dispatch(forgotPasswordSuccess({}));
      dispatch(resetPasswordSuccess({}));
      dispatch(changePasswordSuccess({}));
    };
  }, [dispatch]);

  const onFormSubmit = () => {
    if (uniqueCode) {
      delete formData.email;
      formData.unique_code = uniqueCode;
      dispatch(resetPassword(formData));
    } else if (hasChangePassword) {
      delete formData.email;
      dispatch(changePassword(formData));
    } else {
      dispatch(forgotPassword(formData));
    }
  };

  const handleEmailChange = e => {
    formData.email = e.target.value;
    setFormData({ ...formData });
  };

  const handleOkayButtonClick = () => {
    setSuccessModal(false);
    history.push('/home');
  };

  const handleNewPasswordChange = e => {
    formData.new_password = e.target.value;
    setFormData({ ...formData });
  };

  const showOldPassword = () => {
    passwordTypes.old_password = !passwordTypes.old_password;
    setPasswordTypes({ ...passwordTypes });
  };
  const showNewPassword = () => {
    passwordTypes.new_password = !passwordTypes.new_password;
    setPasswordTypes({ ...passwordTypes });
  };

  const showConfirmPassword = () => {
    passwordTypes.confirm_password = !passwordTypes.confirm_password;
    setPasswordTypes({ ...passwordTypes });
  };

  const handleConfirmPasswordChange = e => {
    formData.confirm_password = e.target.value;
    setFormData({ ...formData });
  };

  const handleOldPasswordChange = e => {
    formData.old_password = e.target.value;
    setFormData({ ...formData });
  };

  const handleResetDescription = () => {
    if (!uniqueCode && !hasChangePassword) {
      return <p className="reset-desc">{t('LOGIN.RESET_DESC_ONE')}</p>;
    } else {
      return <p className="reset-desc">{t('LOGIN.RESET_DESC_TWO')}</p>;
    }
  };

  const handleInputFields = () => {
    if (!uniqueCode && !hasChangePassword) {
      return (
        <>
          <FormGroup className="textOnInput mb-3">
            <Label for="exampleEmail">{t('LOGIN.EMAIL')}</Label>
            <Field
              component={renderTextField}
              name="email"
              bsSize="sm"
              className="email-input"
              type="text"
              onChange={e => handleEmailChange(e)}
            ></Field>
          </FormGroup>
        </>
      );
    } else {
      return (
        <>
          {hasChangePassword && (
            <FormGroup className="textOnInput mb-3">
              <Label for="old_password">{t('LOGIN.OLD_PASSWORD')}</Label>
              <Field
                component={renderTextField}
                name="old_password"
                bsSize="sm"
                className="email-input"
                type={passwordTypes.old_password ? 'password' : 'text'}
                onChange={e => handleOldPasswordChange(e)}
              ></Field>
              <span className="reset-password-show-hide" onClick={showOldPassword}>
                {passwordTypes.old_password ? t('LOGIN.SHOW') : t('LOGIN.HIDE')}
              </span>
            </FormGroup>
          )}

          <FormGroup className="textOnInput mb-3">
            <Label for="new_password">{t('LOGIN.NEW_PASSWORD')}</Label>
            <Field
              component={renderTextField}
              name="new_password"
              bsSize="sm"
              className="email-input"
              type={passwordTypes.new_password ? 'password' : 'text'}
              onChange={e => handleNewPasswordChange(e)}
            ></Field>

            <span className="reset-password-show-hide" onClick={showNewPassword}>
              {passwordTypes.new_password ? t('LOGIN.SHOW') : t('LOGIN.HIDE')}
            </span>
          </FormGroup>

          <FormGroup className="textOnInput mb-3">
            <Label for="confirm_password">{t('LOGIN.CONFIRM_PASSWORD')}</Label>
            <Field
              component={renderTextField}
              name="confirm_password"
              bsSize="sm"
              className="email-input"
              type={passwordTypes.confirm_password ? 'password' : 'text'}
              onChange={e => handleConfirmPasswordChange(e)}
            ></Field>
            <span className="reset-password-show-hide" onClick={showConfirmPassword}>
              {passwordTypes.confirm_password ? t('LOGIN.SHOW') : t('LOGIN.HIDE')}
            </span>
          </FormGroup>
        </>
      );
    }
  };

  const handleSendResetButtons = () => {
    if (!uniqueCode && !hasChangePassword) {
      return (
        <Button
          type="submit"
          className="btn login-btn-grn-key btn-block w-100"
          disabled={pristine || submitting}
          loading={nextProps.isForgotPasswordRequesting}
        >
          {t('LOGIN.SEND')}
        </Button>
      );
    } else {
      return (
        <Button
          type="submit"
          className="btn login-btn-grn-key btn-block w-100"
          disabled={pristine || submitting}
          loading={nextProps.isResetPasswordRequesting || nextProps.isChangePasswordRequesting}
        >
          {t('LOGIN.RESET')}
        </Button>
      );
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <Container fluid className="p-0">
          <Row>
            <Col lg={5} sm={7} xs={12} className= {!successModal ? "mx-auto login-form-wrapper login-zIndex w-100" : "w-100 mx-auto margin-top-verification-sent"}
            >
              <div className="login-card-wrapper" id="login-card-wrapper">
                <Card className="card-shadow py-4 px-5 login-card">
                  {!successModal ? (
                    <div className="login-form w-100">
                      <div className="pb-3">
                        {window.location.href.includes('forgot-password') ? (
                          <h5 className="login-key-text mt-5">
                            {t('LOGIN.FORGOT_PASSWORD_TITLE')}
                          </h5>
                        ) : window.location.href.includes('change-password') ? (
                          <h5 className="login-key-text mt-5">Change Password</h5>
                        ) : (
                          window.location.href.includes('reset-password') && (
                            <h5 className="login-key-text mt-5">Reset Password</h5>
                          )
                        )}
                        {handleResetDescription()}
                      </div>
                      <form id="reset-password-form" onSubmit={handleSubmit(() => onFormSubmit())}>
                        {handleInputFields()}
                        <div></div>
                        {handleSendResetButtons()}
                      </form>
                      <div className="text-center w-100 mb-3">
                        <p className="m-0 p-0 login-term-text mt-3">
                          {t('LOGIN.BACK_TO')}{' '}
                          <span
                            className="register-link"
                            onClick={() =>
                              window.location.href.includes('forgot-password')
                                ? history.push('/login')
                                : history.push('/home')
                            }
                          >
                            {window.location.href.includes('forgot-password')
                              ? t('LOGIN.SIGN_IN')
                              : t('HOME_PAGE.HOME_SCREEN')}
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <SuccessModal
                      modalHeaderMessage="Sent verification link"
                      okayButtonClickHandler={handleOkayButtonClick}
                      modalSuccessMessage={modalMessage}
                    />
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default reduxForm({
  form: 'reset-password-form',
  touchOnChange: true,
  touchOnBlur: true,
  validate,
})(ResetPassword);
