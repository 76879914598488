import { CLIMATE_WEATHER_LOCATION_CONST, DATA_SOURCES_CONST }  from './climateWeatherConst';
export const locationRequest = (data) => ({
    type: CLIMATE_WEATHER_LOCATION_CONST.CLIMATE_WEATHER_LOCATION_REQUEST,
    payload: data,
  });
  
  export const locationSuccess = (response) => ({
    type: CLIMATE_WEATHER_LOCATION_CONST.CLIMATE_WEATHER_LOCATION_SUCCESS,
    payload: response,
  });
  
  export const locationFailed = (error) => ({
    type: CLIMATE_WEATHER_LOCATION_CONST.CLIMATE_WEATHER_LOCATION_FAILED,
    payload: error,
  });

  export const dataSourcesRequest = (data) => ({
    type: DATA_SOURCES_CONST.DATA_SOURCES_REQUEST,
    payload: data,
  });
  
  export const dataSourcesSuccess = (response) => ({
    type: DATA_SOURCES_CONST.DATA_SOURCES_SUCCESS,
    payload: response,
  });
  
  export const dataSourcesFailed = (error) => ({
    type: DATA_SOURCES_CONST.DATA_SOURCES_FAILED,
    payload: error,
  });