import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Row, Col } from 'reactstrap';
import CommonPlotly from './CommonPlotly';
import Loader from '../../../Components/common/loader';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import { renderGenericDateFormat } from '../../../Components/common/utils';
import { REACT_APP_API_URL } from '../../../Components/constants';
const endpoint2 = REACT_APP_API_URL;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function TabTwo(props) {
  const { reachId, latitude, longitude } = props;
  const [traces, updateTraces] = useState([]);
  const [isLoading, updateLoading] = useState(true);
  const [ensemblesData, setEnsemblesData] = useState(null);
  const [error, upadteError] = useState(false);
  const [date, setDate] = useState(null);
  const maxDate = new Date(new Date().setDate(new Date().getDate() - 1));
  const maxDateFormat = moment(maxDate).format('YYYY-MM-DD');
  useEffect(() => {
    if (reachId) {
      plotEnsemblesStats()
    }
  }, [reachId]);

  const updateGraph = () => {
    plotEnsemblesStats();
  }

  const plotEnsemblesStats = () => {
    updateLoading(true);
    let apiDate = date
    ? `${new Date(date).getFullYear()}${String(new Date(date).getMonth() + 1).padStart(
        2,
        '0',
      )}${String(new Date(date).getDate()).padStart(2, '0')}.00`
    : `${new Date(maxDateFormat).getFullYear()}${String(
        new Date(maxDateFormat).getMonth() + 1,
      ).padStart(2, '0')}${String(new Date(maxDateFormat).getDate()).padStart(2, '0')}.00`;
    axios
      .post(endpoint2 + '/geoglows/ensembles-average', { reach_id: reachId.toString(), date: apiDate })
      .then(res => {
        if (res.data && res.status === 200 && Object.keys(res?.data?.result).length) {
          const data = res?.data?.result;
          //for download data formatting api response.
          let formattedEnsembles = [];
          let dateTime = data['datetime'];
          let ensemble1Average = data['ensemble_01_to_11_m^3/s_average'];
          let ensemble2Average = data['ensemble_01_to_21_m^3/s_average'];
          let ensemble3Average = data['ensemble_01_to_31_m^3/s_average'];
          let ensemble4Average = data['ensemble_01_to_41_m^3/s_average'];
          let ensemble5Average = data['ensemble_01_to_51_m^3/s_average'];
          let high_res_data = data['ensemble_52_m^3/s'];
          formattedEnsembles = data.datetime_high_res.map((item, index) => {
            return {
              datetime: dateTime[index] ? renderGenericDateFormat(dateTime[index]) : '',
              ensemble_01_to_11_Average: ensemble1Average[index],
              ensemble_01_to_21_Average: ensemble2Average[index],
              ensemble_01_to_31_Average: ensemble3Average[index],
              ensemble_01_to_41_Average: ensemble4Average[index],
              ensemble_01_to_51_Average: ensemble5Average[index],
              datetime_high_res: renderGenericDateFormat(item),
              high_resolution: high_res_data[index],
            };
          });
          setEnsemblesData(formattedEnsembles);

          let dt_rv = data['datetime'].concat(data['datetime'].slice().reverse());
          let high_res = data['datetime_high_res'].concat(
            data['datetime_high_res'].slice().reverse(),
          );
          let traces = [
            {
              name: 'Mean Ensemble (01-11 members)',
              x: dt_rv,
              y: data['ensemble_01_to_11_m^3/s_average'].concat(
                data['ensemble_01_to_11_m^3/s_average'].slice().reverse(),
              ),
              mode: 'lines',
              type: 'scatter',
              fill: 'none',
              line: { color: 'darkblue' },
            },
            {
              name: 'Mean Ensemble (01-21 members)',
              x: dt_rv,
              y: data['ensemble_01_to_21_m^3/s_average'].concat(
                data['ensemble_01_to_21_m^3/s_average'].slice().reverse(),
              ),
              mode: 'lines',
              type: 'scatter',
              fill: 'none',
              line: { color: 'darkgreen' },
            },
            {
              name: 'Mean Ensemble (01-31 members)',
              x: dt_rv,
              y: data['ensemble_01_to_31_m^3/s_average'].concat(
                data['ensemble_01_to_31_m^3/s_average'].slice().reverse(),
              ),
              mode: 'lines',
              type: 'scatter',
              fill: 'none',
              line: { color: 'red' },
            },
            {
              name: 'Mean Ensemble (01-41 members)',
              x: dt_rv,
              y: data['ensemble_01_to_41_m^3/s_average'].concat(
                data['ensemble_01_to_41_m^3/s_average'].slice().reverse(),
              ),
              mode: 'lines',
              type: 'scatter',
              fill: 'none',
              line: { color: 'gray' },
            },
            {
              name: 'Mean Ensemble (01-51 members)',
              x: dt_rv,
              y: data['ensemble_01_to_51_m^3/s_average'].concat(
                data['ensemble_01_to_51_m^3/s_average'].slice().reverse(),
              ),
              mode: 'lines',
              type: 'scatter',
              fill: 'none',
              line: { color: 'darkblue' },
            },
            {
              name: 'High Resolution (52 member)',
              x: high_res,
              y: data['ensemble_52_m^3/s'].concat(data['ensemble_52_m^3/s'].slice().reverse()),
              mode: 'lines',
              type: 'scatter',
              fill: 'none',
              line: { color: 'black', dash: 'dash' },
            },
          ];
          updateTraces(traces);
          updateLoading(false);
          upadteError(false);
        } else {
          upadteError(500);
          updateLoading(false);
        }
      })
      .catch(function(error) {
        // handle error
        upadteError(error?.response?.status);
        updateLoading(false);
      });
  }
  const currentData = date ? moment(new Date(date)).format('DD-MM-YYYY') : moment(new Date(maxDateFormat)).format('DD-MM-YYYY');
  const minDate = new Date().setMonth(new Date().getMonth() - 1);
  // console.log(ensemblesData)
  return (
    <>
      {!isLoading ? (
        <>
          <div className="py-3">
          <Row>
            <Col lg={6}>
                <div className="blue-text">
                <h5>
                Reach ID : <b>{reachId}</b> (Lat : {latitude}, Log : {longitude})
              </h5>
                </div>
              </Col>
              <Col lg={6}>
                <div style={{ float: 'right' }}>
                  <span style={{ color: '#3870af' }}>Forecast Date : </span>
                  <input
                    type="date"
                    id="datepicker"
                    max={maxDateFormat}
                    min={moment(new Date(minDate)).format('YYYY-MM-DD')}
                    value={date ? moment(new Date(date)).format('YYYY-MM-DD') : maxDateFormat}
                    onChange={e => setDate(e.target.value)}
                    onBlur={updateGraph}
                  />
                </div>
              </Col>
            
            </Row>
          </div>
          {error === 500 ? (
            <span>No Records Found</span>
          ) : (
          <>
          <Card>
            <CommonPlotly
              traces={traces}
              layout={{
                width: 900,
                yaxis: {
                  title: 'Flow (cub m)',
                  showline: true,
                  showgrid: false,
                },
                font: {
                  size: 12,
                  color: '#3870AF',
                  weight: 'bold',
                },
              }}
              customFileName={`${reachId}_${currentData}_EnsemblesData`}
            />
          </Card>
          <div style={{ float: 'right', marginTop: "10px" }}>
              {ensemblesData && ensemblesData.length && (
                <ExcelFile
                  filename={`${reachId}_${currentData}_EnsemblesData`}
                  element={
                    <button className="btn btn-download ml-auto" style={{ color: 'white' }}>
                      Download
                    </button>
                  }
                >
                  <ExcelSheet data={ensemblesData} name="ensemblesData">
                    <ExcelColumn label="Date_time" value="datetime" />
                    <ExcelColumn
                      label="Ensemble_01_to_11_Average"
                      value="ensemble_01_to_11_Average"
                    />
                    <ExcelColumn
                      label="Ensemble_01_to_21_Average"
                      value="ensemble_01_to_21_Average"
                    />
                    <ExcelColumn
                      label="Ensemble_01_to_31_Average"
                      value="ensemble_01_to_31_Average"
                    />
                    <ExcelColumn
                      label="Ensemble_01_to_41_Average"
                      value="ensemble_01_to_41_Average"
                    />
                    <ExcelColumn
                      label="Ensemble_01_to_51_Average"
                      value="ensemble_01_to_51_Average"
                    />
                    <ExcelColumn label="Date_time_high_res" value="datetime_high_res" />
                    <ExcelColumn label="High_resolution" value="high_resolution" />
                  </ExcelSheet>
                </ExcelFile>
              )}
            </div>
            </>
            )}
        </>
      ) : error !== false && !isLoading ? (
        <span>We're sorry! Server is not responding. Please try again.</span>
      ) : (
        <Loader />
      )}
    </>
  );
}
