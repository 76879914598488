import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-multi-lang';
import Button from 'reactstrap-button-loader';

import { Field, reduxForm } from 'redux-form';
import {
  renderReactSelectInputField,
} from '../../Components/common/renderInputField';
import { useDispatch, useSelector } from 'react-redux';

import {} from '../../redux/actions';

import 'react-multi-email/style.css';

const validate = (values) => {
  const errors = {};

  const requiredFields = ['subject', 'email', 'description'];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required.';
    }
  });

  return errors;
};

const FarmTrackerLocationModal = (props) => {
  const {
    modalOpen,
    setModalOpen,
    subDistrictList,
    handleModalClose,
    blockList,
    handleBlockChange,
    selectedSubDistrict,
    selectedBlock,
    handleZoomToSelectedLocation,
  } = props;
  const [selectedTab, setSelectedTab] = useState();
  const t = useTranslation();
  const [modal, setModal] = useState();
  const [noOptions,setNoOptions] = useState(false);

  const { RiskAnalytics, Location } = useSelector((state) => ({
    RiskAnalytics: state.RiskAnalytics,
    Location: state.Location,
  }));

  useEffect(() => {
    setModal(modalOpen);
  }, [modalOpen]);

  useEffect(() => {
    let arr = [];
    if (Location.blockList) {
      let result = '';
      let config = Location?.blockList?.config;
      if (Location?.blockList?.data) result = Location?.blockList?.data?.result;
      if (!result?.length) {
        setNoOptions(true)
      }
      else setNoOptions(false)
    }
  }, [Location.blockList]);

  const handleSubDistrictChange = (value) => {
    setNoOptions(false);
    props.handleSubDistrictChange(value)
  }

  const viewtoggle = (value) => {
    setModal(false);
  };

  const dispatch = useDispatch();

  const handleClose = () => {
    setModal(false);
    props.handleModalClose(false);
  };

  const onSubmit1 = async (e) => {
    handleZoomToSelectedLocation()
    handleClose();
  };

  const { handleSubmit } = props;
  return (
    <>
      <div className="shareinformationmodal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className="shareinformationmodal"
          backdrop="static"
          style={{ width: '75%' }}
        >
          <form onSubmit={handleSubmit(onSubmit1)}>
            <ModalHeader className="weather-forecast-modal-title"
            toggle={handleClose}

            >
              Select DSD and GND for your farm
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm={6} md={6} lg={12} style={{ marginBottom: '10px' }}>
                  <FormGroup className="textOnInput">
                    <Label for="sub_district" className="choose_label">
                      {t('FARM_TRACKER.SUB_DISTRICTS')}
                    </Label>
                    <Field
                      name="sub_district"
                      component={renderReactSelectInputField}
                      className="farm-input-style-css"
                      id="sub_district"
                      options={subDistrictList}
                      value={selectedSubDistrict}
                      onChange={handleSubDistrictChange}
                      placeholder=""
                      required
                      isMulti={false}
                    />
                  </FormGroup>
                </Col>
                  <Col sm={6} md={6} lg={12} style={{ marginBottom: '10px' }}>
                  <FormGroup className="textOnInput">
                    <Label for="block" className="choose_label">
                      {t('FARM_TRACKER.BLOCKS')}
                    </Label>
                    <Field
                      name="block"
                      component={renderReactSelectInputField}
                      className="farm-input-style-css"
                      id="block"
                      options={blockList}
                      value={selectedBlock}
                      onChange={handleBlockChange}
                      placeholder=""
                      required
                      isMulti={false}
                      menuHeight={200}
                      noOptionsMessage={() =>  noOptions ? "There is no GND for selected  DSD" : "No options"}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="modal-viewdeatil-footer" style={{ width: '100%' }}>
              <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Col lg={9}> </Col>
                <Col lg={3}>
                  <Button
                    className="weather-forecast-continue-button"
                    // disabled={selectedBlock?.id && selectedSubDistrict?.id ? false : true}
                    disabled={selectedSubDistrict?.id  ? ( blockList?.length ? (selectedBlock?.id  ? false : true) : false) : true}
                    style={{ width: '100px' }}
                    type="submit"
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'FarmTrackerLocationModal',
  validate,
})(FarmTrackerLocationModal);
