import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { CROP_PRODUCTION_FILTER_TABS } from '../../Components/constants';
import { AXIOS_INSTANCE, RISK_SCORE_TIMESERIES  , RISK_ANALYTICS, RISK_ANALYTICS_SEASONAL_URL, RISK_ANALYTICS_PERENNIAL_URL} from '../api/apiEndPoint';
import { getRiskScoreTimeSeriesSuccess, getRiskScoreTimeSeriesError , getIndicesSuccess , getIndicesError , getIndicesChangeSuccess , getIndicesChangeError , sendEmailSuccess , sendEmailError, getCWPCropsSuccess, getCWPCropsError, getCWPCropsFailed, getCWPSeasonSuccess, getCWPSeasonFailed, getCWPPractiseSuccess, getCWPPractiseFailed, getCWPGraphDataSuccess, getCWPGraphDataFailed } from './riskAnalyticsActions';
import RISK_ANALYTICS_CONST from './riskAnalyticsConsts';

require('dotenv').config();

const getRiskScoreTimeseriesApiRequest = async payload => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(RISK_SCORE_TIMESERIES, payload?.requestObj);
    response = {
      data: data.data,
      isCompare: payload?.isCompare,
      farmId: payload?.id,
      farmCompareId: payload?.compareId,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      isCompare: payload?.isCompare,
      farmId: payload?.id,
      farmCompareId: payload?.compareId,
      status: 400,
    };
  }
  return response;
};

function* getRiskScoreTimeSeriesData(action) {
  try {
    const apiResponse = yield call(getRiskScoreTimeseriesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
          isCompare: apiResponse?.isCompare,
          selectedFarmId: apiResponse?.farmId,
          selectedCompareFarmId: apiResponse?.farmCompareId,
        },
      };
      yield put(getRiskScoreTimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
          isCompare: apiResponse?.isCompare,
          selectedFarmId: apiResponse?.farmId,
          selectedCompareFarmId: apiResponse?.farmCompareId,
        },
      };
      yield put(getRiskScoreTimeSeriesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getRiskScoreTimeSeriesError(errorData));
  }
}
const getIndicesApiRequest = async payload => {
  let response = {};
  try {
    const data =  await AXIOS_INSTANCE.get(`${RISK_ANALYTICS}/getAllIndices` , payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getIndicesData(action) {
  try {
    const apiResponse = yield call(getIndicesApiRequest , action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getIndicesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getIndicesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getIndicesError(errorData));
  }
}
const getIndicesChangeRequest = async (payload) => {
  const {country_name ,
    api_url ,
    spatial_aggregation ,
    start_date ,
    end_date,}= payload
  let response = {};
  try {
    const data =  await AXIOS_INSTANCE.post(`${api_url}/timeseries` , {country_name ,
    
      spatial_aggregation ,
      start_date ,
      end_date});
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getIndicesChangeData(action) {
  try {
    const apiResponse = yield call(getIndicesChangeRequest , action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getIndicesChangeSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getIndicesChangeError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getIndicesChangeError(errorData));
  }
}

const sendEmailRequest = async (payload) => {
  let response = {};
  try {
    const data =  await AXIOS_INSTANCE.post(`${RISK_ANALYTICS}/send-email` , payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* sendEmailData(action) {
  try {
    const apiResponse = yield call(sendEmailRequest , action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(sendEmailSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(sendEmailError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(sendEmailError(errorData));
  }
}

const getCWPCropListRequest = async (payload) => {
  let response = {};
  try {
    const data =  await AXIOS_INSTANCE.get(`${RISK_ANALYTICS}/cwp/crops/${[payload?.crop_type]}`);
    response = {
      data: data.data,
      crop_type: payload?.crop_type,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getCWPCropList(action) {
  try {
    const apiResponse = yield call(getCWPCropListRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
          crop_type: apiResponse.crop_type
        },
      };
      yield put(getCWPCropsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getCWPCropsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCWPCropsFailed(errorData));
  }
}

const getCWPSeasonListRequest = async () => {
  let response = {};
  try {
    const data =  await AXIOS_INSTANCE.get(`${RISK_ANALYTICS}/cwp/seasons`);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getCWPSeasonList() {
  try {
    const apiResponse = yield call(getCWPSeasonListRequest);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getCWPSeasonSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getCWPSeasonFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCWPSeasonFailed(errorData));
  }
}

const getCWPPractisesListRequest = async () => {
  let response = {};
  try {
    const data =  await AXIOS_INSTANCE.get(`${RISK_ANALYTICS}/cwp/practices`);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getCWPPractisesList() {
  try {
    const apiResponse = yield call(getCWPPractisesListRequest);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getCWPPractiseSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getCWPPractiseFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCWPPractiseFailed(errorData));
  }
}

const getCWPGraphDataListRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(
      payload?.crop_type === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value
        ? RISK_ANALYTICS_SEASONAL_URL
        : RISK_ANALYTICS_PERENNIAL_URL,
      payload?.requestObj,
    );
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getCWPGraphDataList(action) {
  try {
    const apiResponse = yield call(getCWPGraphDataListRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getCWPGraphDataSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getCWPGraphDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCWPGraphDataFailed(errorData));
  }
}

export function* watchRiskScoreTimeSeries() {
  yield takeEvery(RISK_ANALYTICS_CONST.GET_RISK_SCORE_REQUEST, getRiskScoreTimeSeriesData);
}

export function* watchIndices() {
  yield takeEvery(RISK_ANALYTICS_CONST.GET_RISK_INDICES_REQUEST, getIndicesData);
}

export function* watchIndicesChange() {
  yield takeEvery(RISK_ANALYTICS_CONST.GET_INDICES_CHANGE_REQUEST, getIndicesChangeData);
}

export function* watchSendEmail() {
  yield takeEvery(RISK_ANALYTICS_CONST.SEND_EMAIL_REQUEST, sendEmailData);
}

export function* watchCWPCropList() {
  yield takeEvery(RISK_ANALYTICS_CONST.GET_CWP_CROPS_LIST_REQUEST, getCWPCropList);
}

export function* watchCWPSeasonsList() {
  yield takeEvery(RISK_ANALYTICS_CONST.GET_CWP_SEASONS_LIST_REQUEST, getCWPSeasonList);
}

export function* watchCWPPractisesList() {
  yield takeEvery(RISK_ANALYTICS_CONST.GET_CWP_PRACTISES_LIST_REQUEST, getCWPPractisesList);
}

export function* watchCWPGraphData() {
  yield takeEvery(RISK_ANALYTICS_CONST.GET_CWP_GRAPH_DATA_REQUEST, getCWPGraphDataList);
}

function* riskAnalyticsSaga() {
  yield all([fork(watchRiskScoreTimeSeries)]);
  yield all([fork(watchIndices)]);
  yield all([fork(watchIndicesChange)]);
  yield all([fork(watchSendEmail)]);
  yield all([fork(watchCWPCropList)]);
  yield all([fork(watchCWPSeasonsList)]);
  yield all([fork(watchCWPPractisesList)]);
  yield all([fork(watchCWPGraphData)])
}
export default riskAnalyticsSaga;

