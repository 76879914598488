import React from 'react';
import Footer from './footer';
import { t } from 'react-multi-lang';

// const TermsConditions = () => {
//   return (
//     <div className="right-img-wrapper right-img-wrapper-terms">
//         <h2>Terms and Conditions</h2>
//         <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lectus</p>
//     </div>
//   );
// }
// export default TermsConditions;

const TermsConditions = () => {
  return (
    <>
       <div className="right-img-wrapper right-img-wrapper-terms">

      {/* <div className="right-img-wrapper">
        <div className="terms-conditions"> */}
          <h2><b>{t('ABOUT_US.TERMS_AND_CONDITIONS')}</b></h2>
          <p className="terms-conditions-body">{t('ABOUT_US.TERMS_AND_CONDITIONS_ONE')}</p>
          <p className="terms-conditions-body">{t('ABOUT_US.TERMS_AND_CONDITIONS_TWO')}</p>
          <br />
          <h4><b>{t('ABOUT_US.EO4ARM_INFO_COLLECTION')}</b></h4>
          <br />
          <h5><b>{t('ABOUT_US.PERSONAL_INFO')}</b></h5>
          <p className="terms-conditions-body">{t('ABOUT_US.PERSONAL_INFO_DESC')}</p>
          <h5><b>{t('ABOUT_US.USE_OF_COOKIES')}</b></h5>
          <p className="terms-conditions-body">{t('ABOUT_US.USE_OF_COOKIES_DESC_ONE')}</p>
          <p className="terms-conditions-body">{t('ABOUT_US.USE_OF_COOKIES_DESC_TWO')}</p>
          <h5><b>{t('ABOUT_US.IP_ADDRESSES')}</b></h5>
          <p className="terms-conditions-body">{t('ABOUT_US.IP_ADDRESSES_DESC')}</p>
          <h5><b>{t('ABOUT_US.THIRD_PARTY_SITES')}</b></h5>
          <p className="terms-conditions-body">The site may feature aggregated content from third party websites (including websites of CGIAR centers and CGIAR research) and/or include links to such websites. This <b>Privacy Policy</b> does not apply to such aggregated content or to such third-party websites, which are governed by their own privacy policies.</p>
          <h5><b>{t('ABOUT_US.HOW_TO_CONTACT_US')}</b></h5>
          <p className="terms-conditions-body">If you have any questions or concerns about our <b>Privacy Policy</b>, please contact us.</p>
          <h5><b>{t('ABOUT_US.CHANGES')}</b></h5>
          <p className="terms-conditions-body">EO4ARM reserves the right to modify this <b>Privacy Policy</b> in its discretion at any time, such modifications being effective when posted.</p>
        {/* </div> */}
      </div>
      <div className="terms-conditions-footer">
        <Footer publicFooter={false} />
      </div>
    </>
  );
};
export default TermsConditions;

