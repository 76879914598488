import React, { useState, useEffect, useRef } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import moment from 'moment';
import _, { findLast } from 'lodash';
import {
  Card,
  Col,
  Input,
  Row,
  Button,
  Collapse,
  FormGroup,
  Label,
  CardBody,
  Form,
  CardText,
  CardTitle,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  Alert,
  Modal,
  FormFeedback,
} from 'reactstrap';
import Select from 'react-select';
import { BiMinus, BiPlus } from 'react-icons/bi';
import InActiveForecastIcon from '../../assets/Images/InactiveForecastIcon.svg';
import InActiveMonitoringIcon from '../../assets/Images/InactiveMonitoringIcon.svg';
import { RiArrowDropUpLine, RiArrowDropDownLine } from 'react-icons/ri';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import TRANSLATIONS from '../../translations/en.json';
import {
  DATE_FORMAT,
  DEFAULT_COUNTRY,
  DROUGHT_MODULES_CONST,
  DROUGHT_MODULE_INDICATORS,
  DROUGHT_TEMPORAL_AGGREGATION,
  SRILANKA_COORDINATE,
  //INDICES_MODULE,
  LOCATION,
  WEATHER_FORECAST_MODULE,
  SOUTH_ASIA_CONST,
  WEATHER_MONITOR_CONSTS,
  WEATHER_MONITORING_GLDAS_BANDS,
} from '../../Components/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCountryRequest,
  getStateByCountryRequest,
  getDistrictByStateRequest,
  getIndicesRequest,
  getMapPreferenceRequest,
  getIndicesSuccess,
  getGLDASRequest,
} from '../../redux/actions';
import ForecastIcon from '../../assets/Images/forecast.svg';
import MonitoringIcon from '../../assets/Images/monitoring.svg';
import { t } from 'react-multi-lang';
import ReactTooltip from 'react-tooltip';
import DroughtIndicatorsCollapse from './DroughtIndicators';
import DroughtMonitorOtherIndicesCollapse from './OtherIndicesIndicators';
import Cookies from 'js-cookie';
import { setWaterMonitoringDefaultParam } from '../../Components/common/utils';

function DroughtSidebar(props) {
  const {
    popUpSelectedModule,
    selectedModule,
    selectedGLDAS,
    activeTab,
    compareSelectedGLDAS,
    setUpdateGraph,
    updateGraph,
    handleToggle,
    mapPreferencesData,
    precipitationThreshold,
    applyLayersDisable,
    handlePrecipitationThreshold,
    comparePrecipitationThreshold,
    handleComparePrecipitationThreshold,
  } = props;
  const dispatch = useDispatch();
  const [temporalAggregation, setTemporalAggregation] = useState(DROUGHT_TEMPORAL_AGGREGATION);
  const [isClickedOnPlus, setIsClickedOnPlus] = useState(false);
  const [droughtIndices, setDroughtIndices] = useState();
  const [showMapPreferenceTooltip, setShowMapPreferenceTooltip] = useState(false);
  const [compareIndices, setCompareIndices] = useState();
  const [isCompare, setIsCompare] = useState([
    { name: 'map', value: 'Map', selected: true },
    { name: 'graph', value: 'Graph', selected: false },
  ]);
  const [indicators, setIndicators] = useState([
    { name: 'rainfall', value: 'Rainfall', selected: true },
    { name: 'rainy-days', value: 'No. of Rainy days', selected: false },
    { name: 'dry-spell', value: 'Dry Spell', selected: false },
    { name: 'temperature', value: 'Temperature', selected: false },
    { name: 'humidity', value: 'Humidity', selected: false },
  ]);
  const [openCollapse, setOpenCollapse] = useState({
    forecast_location: true,
    monitoring_timeframe: true,
    forecast_compare: false,
    monitoring_indicators: true,
    monitoring_compare: false,
    monitoring_compare_indicators: true,
  });
  const [rangeState, setRangeState] = useState({
    min: null,
    max: null,
  });
  const [compareRangeState, setCompareRangeState] = useState({
    min: null,
    max: null,
  });
  const [initialSelectedDate, setInitialSelectedDate] = useState({
    startDate: moment().subtract(30, 'days').format(DATE_FORMAT),
    endDate: moment().format(DATE_FORMAT),
    maxEndDate: moment().format(DATE_FORMAT),
    maxStartDate: moment().format(DATE_FORMAT),
    minStartDate: '',
    minEndDate: '',
  });
  const [finalSelectedDate, setFinalSelectedDate] = useState({
    startDate: moment().subtract(30, 'days').format(DATE_FORMAT),
    endDate: moment().format(DATE_FORMAT),
    minStartDate: '',
    maxStartDate: moment().format(DATE_FORMAT),
    maxEndDate: moment().format(DATE_FORMAT),
    minEndDate: '',
  });
  const [regionOptions, setRegionOptions] = useState([
    { name: 'select', label: 'select', value: '' },
    { name: 'South Asia', label: 'South Asia', value: SOUTH_ASIA_CONST.id },
  ]);
  const [selectedRegion, setSelectedRegion] = useState({
    name: 'South Asia',
    label: 'South Asia',
    value: SOUTH_ASIA_CONST.id,
  });
  const [selectedParam, setSelectedParam] = useState({});
  const [showToolTip, setShowToolTip] = useState(false);
  const [compareParam, setCompareParam] = useState({});
  const [invalidDate, setInvalidDate] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    country: {},
    state: {},
    district: {},
  });
  const [compareLocation, setCompareLocation] = useState({
    country: {},
    state: {},
    district: {},
  });
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState([]);
  const [compareStateList, setCompareStateList] = useState([]);
  const [country, setCountry] = useState('');
  const [compareCountry, setCompareCountry] = useState('');
  const [state, setState] = useState();
  const [isPreviousClick, setIsPreviousClick] = useState(false);
  const [compareState, setCompareState] = useState();
  const [selectedOption, setSelectedOption] = useState({});
  const [compareSelectedOption, setCompareSelectedOption] = useState({});
  const [defaultCompareSelectedOption, setDefaultCompareSelectedOption] = useState({});
  const [districtList, setDistrictList] = useState();
  const [compareDistrictList, setCompareDistrictList] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCompareDistrict, setSelectedCompareDistrict] = useState(null);
  const [bandsList, setBandsList] = useState([]);
  const [selectedBand, setSelectedBand] = useState(null);
  const [selectedComapareBand, setCompareselectedBand] = useState(null);

  const [basin, setBasin] = useState('');
  const [region, setRegion] = useState(null);
  const [minStartDate, setMinStartDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [minEndDate, setMinEndDate] = useState();
  const [maxEndDate, setMaxEndDate] = useState();
  const [applyLayers, setApplyLayers] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [compareError, setCompareError] = useState({ showError: false, msg: '' });
  const [minError, setMinError] = useState(false);
  const [compareMinError, setCompareMinError] = useState(false);
  const [moduleOptions, setModuleOptions] = useState(DROUGHT_MODULES_CONST);
  //getting all country ,state ,district from store
  const { DroughtIndicesData, Location, BandsListData } = useSelector((state) => ({
    Location: state.Location,
    DroughtIndicesData: state.AccessKey.getIndicesData,
    BandsListData: state.AccessKey.getGLDASData,
  }));

  const [distrcitSelectValue, setDistrcitSelectValue] = useState({});
  const [districtStateValue, setDistrictStateValue] = useState({});

  useEffect(() => {
    if (popUpSelectedModule) {
      props.handleSelectedModule(popUpSelectedModule);
    }
  }, [popUpSelectedModule]);
  const [defaultSelectedOption, setDefaultSelectedOption] = useState({});

  useEffect(() => {
    if (BandsListData?.data?.result?.length) {
      let arr = [];
      BandsListData?.data?.result?.map((i, index) => {
        let label = i?.name?.split(' ').map((name, index) => {
          if (index !== 0) {
            return name?.toLowerCase();
          }
          return name;
        });
        arr.push({
          id: i?.id,
          name: i?.name,
          value: i?.name,
          label: label.join(' '),
          unit: i?.unit || WEATHER_MONITORING_GLDAS_BANDS.find((it) => it?.id === i?.id)?.unit,
        });
      });
      // props.handleSelectedCompareGlDASIndicator()
      setBandsList(arr);
      if (arr && arr.length) {
        setSelectedBand(arr[0]);
        setCompareselectedBand(arr[0]);
        props.handleSelectedGlDASIndicator(arr[0]);
        props.handleSelectedCompareGlDASIndicator(arr[0]);
      }
    }
  }, [BandsListData]);

  useEffect(() => {
    updateDroughtTemporalAggregation(selectedParam);
  }, [selectedParam?.slug]);

  useEffect(() => {
    if (!_.isEmpty(DroughtIndicesData)) {
      const { data } = DroughtIndicesData;
      const end_date = moment(data?.result?.[0]?.max_date, 'YYYY-MM-DD');
      const start_date = end_date.clone().subtract(30, 'days');
      const start_date_str = start_date.format('YYYY-MM-DD');
      setInitialSelectedDate((prevdate) => {
        return {
          ...prevdate,
          startDate: start_date_str,
          endDate: data?.result?.[0]?.max_date,
        };
      });
      let parameterArr = [];
      let selectedParamArr = [];
      let count = 0;
      data?.result?.map((item) => {
        item.checked = false;
        item.paramIndex = count;
        count = count + 1;
        parameterArr.push(item);
      });
      selectedParamArr = JSON.parse(JSON.stringify(parameterArr));
      selectedParamArr?.map((i, index) => {
        // if(index === 0){
        //   i.checked = true;
        // }
        if (index === 0) {
          i.checked = true;
        }
      });

      setDroughtIndices(selectedParamArr);
      setCompareIndices(parameterArr);
      props.handleParams(parameterArr);
    } else {
      props.handleParams([]);
    }
  }, [DroughtIndicesData]);

  useEffect(() => {
    if (droughtIndices?.length) {
      // setSelectedParam(droughtIndices[0])
      let paramCookie = Cookies.get('water-monitor-default-param');
      if (paramCookie === undefined) {
        droughtIndices?.map((item, index) => {
          if (index === 0) {
            if (droughtIndices[index]?.slug === 'rain_fall') {
              if (DROUGHT_TEMPORAL_AGGREGATION?.findIndex((i) => i.name === 'Sum') < 0)
                DROUGHT_TEMPORAL_AGGREGATION.push({
                  id: 4,
                  name: 'Sum',
                  value: 'sum',
                  label: 'Sum',
                });
            }
            let defaultSelectedAggregation;
            if (droughtIndices[index]?.slug === 'rain_fall') {
              defaultSelectedAggregation = DROUGHT_TEMPORAL_AGGREGATION?.find(
                (item) => item.name === 'Sum',
              );
            } else {
              defaultSelectedAggregation = DROUGHT_TEMPORAL_AGGREGATION?.find(
                (item) => item.name === 'Mean',
              );
            }
            if (!_.isEmpty(defaultSelectedAggregation)) {
              setTemporalAggregation(defaultSelectedAggregation);
            }
            handleSelectedDroughtIndicator(droughtIndices[index]);
            // setParamAttributes(droughtIndices[index]);
          }
        });
        setWaterMonitoringDefaultParam(true);
      }
    }
  }, [droughtIndices]);

  useEffect(() => {
    if (!_.isEmpty(selectedModule)) {
      if (selectedModule.value === 'drought prediction') {
        setInitialSelectedDate((prev) => {
          return {
            ...prev,
            // startDate: moment().format(DATE_FORMAT),
            minStartDate: moment('2000-01-01T00:00:00Z').format(DATE_FORMAT),
            // endDate: moment().add(10, 'days').format(DATE_FORMAT),
          };
        });
        setFinalSelectedDate((prev) => {
          return {
            ...prev,
            startDate: moment().format(DATE_FORMAT),
            minStartDate: moment('2000-01-01T00:00:00Z').format(DATE_FORMAT),
            endDate: moment().add(10, 'days').format(DATE_FORMAT),
          };
        });
      } else {
        // setInitialSelectedDate((prev) => {
        //   return {
        //     ...prev,
        //     startDate: moment().subtract(30, 'days').format(DATE_FORMAT),
        //     endDate: moment().format(DATE_FORMAT),
        //   };
        // });
        setFinalSelectedDate((prev) => {
          return {
            ...prev,
            startDate: moment().subtract(30, 'days').format(DATE_FORMAT),
            endDate: moment().format(DATE_FORMAT),
          };
        });
      }
    }
  }, [selectedModule]);

  //for getting country
  useEffect(() => {
    dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    dispatch(getMapPreferenceRequest({ country_id: DEFAULT_COUNTRY.id, module_name: 'weather' }));

    let defaultSelectedAggregation = DROUGHT_TEMPORAL_AGGREGATION?.find(
      (item) => item.name === 'Mean',
    );
    if (!_.isEmpty(defaultSelectedAggregation)) {
      setTemporalAggregation(defaultSelectedAggregation);
    }
    setMaxStartDate(moment(new Date()).format('YYYY-MM-DD'));
    setMaxEndDate(moment(new Date()).format('YYYY-MM-DD'));
    setMinEndDate(moment(new Date()).format('YYYY-MM-DD'));
  }, []);

  useEffect(() => {
    if (Location.countryList) {
      const { result } = Location.countryList;
      if (result && !countryList?.length) {
        let defaultCountry = result?.find((data) => data.id === DEFAULT_COUNTRY.id);
        if (defaultCountry) {
          defaultCountry.label = defaultCountry.name;
          defaultCountry.value = defaultCountry.name;
          setSelectedOption(defaultCountry);
          setDefaultSelectedOption(defaultCountry);
          // props.handleCompareSearch({ country: defaultCountry.value });

          setCompareSelectedOption(defaultCountry);
          setDefaultCompareSelectedOption(defaultCountry);
          setCountry(defaultCountry);
          setCompareCountry(defaultCountry);
          setState(null);
          setCompareState(null);
          setSelectedDistrict(null);
          setSelectedCompareDistrict(null);
          setSelectedLocation((prev) => {
            return {
              ...prev,
              country: defaultCountry,
            };
          });
          setCompareLocation((prev) => {
            return {
              ...prev,
              country: defaultCountry,
            };
          });
          dispatch(getIndicesSuccess(''));
          if (!stateList?.length)
            dispatch(
              getStateByCountryRequest({
                requestObj: {
                  location_type: LOCATION.State,
                  parent_id: defaultCountry?.id,
                },
                isCompareLocation: false,
              }),
            );
          // if(_.isEmpty(DroughtIndicesData))
          // dispatch(
          //   getIndicesRequest({
          //     requestObj: {
          //       type: 'country',
          //       type_id: defaultCountry?.id,
          //     },
          //     module: selectedModule.api_slug,
          //   }),
          // );
          dispatch(
            getIndicesRequest({
              requestObj: {
                type: 'country',
                type_id: defaultCountry?.id,
              },
              module: selectedModule.api_slug,
            }),
          );
        }
      }
      if (result?.[0]) {
        let arr = [];
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            region_id: i.region_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
        setCountryList(arr);
      }
    }
  }, [Location.countryList]);

  // useEffect(() => {
  //   if (
  //     !_.isEmpty(selectedLocation.country) &&
  //     selectedLocation.country.id !== 'select' &&
  //     !_.isEmpty(selectedModule) &&
  //     selectedModule.api_slug.length !== 0
  //   ) {
  //     console.log("calling here 3")

  //     dispatch(getIndicesRequest());
  //   }
  // }, [selectedLocation.country, selectedLocation.district]);

  useEffect(() => {
    if (
      !_.isEmpty(selectedLocation?.state?.id) &&
      selectedLocation.state.id !== 'select' &&
      selectedLocation.state.name !== ''
    ) {
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: selectedLocation.state?.id,
          },
          isCompareLocation: false,
        }),
      );
    }
  }, [selectedLocation.state]);

  useEffect(() => {
    if (rangeState?.max && rangeState?.min) {
      if (Number(rangeState?.max) < Number(rangeState.min)) {
        setMinError(true);
        return;
      }
    }

    setMinError(false);
  }, [rangeState]);

  useEffect(() => {
    if (compareRangeState?.max && compareRangeState?.min) {
      if (Number(compareRangeState?.max) < Number(compareRangeState.min)) {
        setCompareMinError(true);
        return;
      }
    }
    setCompareMinError(false);
  }, [compareRangeState]);

  useEffect(() => {
    if (
      !_.isEmpty(compareLocation.state?.id) &&
      compareLocation.state.id !== 'select' &&
      compareLocation.state.id !== DEFAULT_COUNTRY.name
    ) {
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: compareLocation.state?.id,
          },
          isCompareLocation: true,
        }),
      );
    }
  }, [compareLocation.state]);

  useEffect(() => {
    if (!_.isEmpty(selectedLocation?.district)) {
      /* TODO: commented this: because state based indicators will be added in future from api side*/
      //dispatch(getIndicesSuccess(''));
      // dispatch(
      //     ({
      //     type: 'district',
      //     type_id: selectedLocation.district?.id,
      //   }),
      // );
    }
  }, [selectedLocation.district]);

  // useEffect(() => {
  //   if (countryList && countryList?.length) {
  //     const defaultCountry = countryList?.find(item => item.id === DEFAULT_COUNTRY.id);
  //     if (!_.isEmpty(defaultCountry)) {
  //       setCountry(defaultCountry);
  //       setCompareCountry(defaultCountry);
  //       setState(null);
  //       setCompareState(null);
  //       setSelectedDistrict(null);
  //       setSelectedCompareDistrict(null);
  //     }
  //   }
  // }, [countryList]);

  useEffect(() => {
    let arr = [];
    if (Location.stateList) {
      let result = '';
      let config = Location?.stateList?.config;
      if (Location?.stateList?.data) result = Location?.stateList?.data?.result;
      if (result) {
        arr.push({
          id: DEFAULT_COUNTRY.name,
          name: '',
          value: DEFAULT_COUNTRY.name,
          label: 'Select Province',
        });
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            country_id: i.country_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      setState({
        id: DEFAULT_COUNTRY.name,
        value: DEFAULT_COUNTRY.name,
        label: 'Select Province',
      });
      setCompareState({
        id: DEFAULT_COUNTRY.name,
        value: DEFAULT_COUNTRY.name,
        label: 'Select Province',
      });
      // if (!config?.isCompareLocation) {
      setStateList(arr);
      // } else {
      setCompareStateList(arr);
      // }
    }
  }, [Location.stateList]);

  useEffect(() => {
    let arr = [];
    if (Location.districtList) {
      let result = '';
      let config = Location?.districtList?.config;
      if (Location?.districtList?.data) result = Location?.districtList?.data?.result;
      if (result) {
        arr.push({
          id: 'Select',
          name: 'Select',
          value: 'Select',
          label: 'Select',
        });
        result?.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        setDistrictList(arr);
      } else {
        setCompareDistrictList(arr);
      }
    }
  }, [Location.districtList]);

  useEffect(() => {
    props.handleCompareView(isCompare);
  }, [isCompare]);

  useEffect(() => {
    if (Object.keys(selectedOption).length && selectedOption?.name !== '') {
      const { latitude, longitude, zoom_level } = selectedOption;
      return props.handleCordinates(
        [
          latitude ? latitude : SRILANKA_COORDINATE.lat,
          longitude ? longitude : SRILANKA_COORDINATE.long,
        ],
        zoom_level ? zoom_level : DEFAULT_COUNTRY.zoom_level,
      );
    } else {
      return props.handleCordinates(
        [SRILANKA_COORDINATE.lat, SRILANKA_COORDINATE.long],
        DEFAULT_COUNTRY.zoom_level,
      );
    }
  }, [selectedOption]);

  useEffect(() => {
    if (Object.keys(compareSelectedOption).length) {
      const { latitude, longitude, zoom_level } = compareSelectedOption;
      return props.handleCompareCordinates(
        [
          latitude ? latitude : SRILANKA_COORDINATE.lat,
          longitude ? longitude : SRILANKA_COORDINATE.long,
        ],
        zoom_level ? zoom_level : 4.4,
      );
    }
  }, [compareSelectedOption]);

  useEffect(() => {
    if (mapPreferencesData?.length === 2) setShowMapPreferenceTooltip(true);
    else setShowMapPreferenceTooltip(false);
  }, [mapPreferencesData]);

  useEffect(() => {
    if (!_.isEmpty(temporalAggregation)) {
      props.handleProps({
        temporalAggregation,
        rangeState: rangeState,
        compareRangeState: compareRangeState,
        initialSelectedDate,
      });
    }
  }, [temporalAggregation, rangeState, initialSelectedDate, compareParam, compareRangeState]);

  useEffect(() => {
    if (!_.isEmpty(selectedRegion) && selectedLocation.country?.value === '') {
      props.handleSelectedRegion(selectedRegion?.value);
    } else {
      props.handleSelectedRegion('');
    }
  }, [selectedRegion, selectedLocation.country]);

  useEffect(() => {
    let compareArr = [];
    if (!_.isEmpty(compareParam)) {
      compareArr.push(compareParam);
    }
    props.handleCompareProps({
      compareParams: compareArr,
      compareSelectedParam: compareParam,
      finalSelectedDate,
    });
  }, [compareParam, finalSelectedDate]);

  useEffect(() => {
    setParamAttributes(selectedParam);
  }, [selectedParam]);

  useEffect(() => {
    if (!_.isEmpty(compareParam)) {
      if (selectedParam?.slug === 'rain_fall' && compareParam?.slug === 'rain_fall') {
        if (DROUGHT_TEMPORAL_AGGREGATION?.findIndex((i) => i.name === 'Sum') < 0) {
          DROUGHT_TEMPORAL_AGGREGATION.push({ id: 4, name: 'Sum', value: 'sum', label: 'Sum' });
        }
        setTemporalAggregation({ id: 4, name: 'Sum', value: 'sum', label: 'Sum' });
      } else {
        DROUGHT_TEMPORAL_AGGREGATION?.map((item, index) => {
          if (item.name === 'Sum') {
            DROUGHT_TEMPORAL_AGGREGATION.pop();
          }
        });
        if (temporalAggregation?.name === 'Sum') {
          setTemporalAggregation(DROUGHT_TEMPORAL_AGGREGATION?.find((i) => i.name === 'Mean'));
        }
      }
    }
  }, [compareParam, selectedParam]);

  useEffect(() => {
    setCompareRangeState({ min: null, max: null });
  }, [compareParam]);

  useEffect(() => {
    if (selectedParam?.slug === 'gldas' && !BandsListData?.data?.result?.length) {
      dispatch(getGLDASRequest());
    }
  }, [selectedParam, BandsListData]);

  useEffect(() => {
    if (compareParam?.slug === 'gldas' && !BandsListData?.data?.result?.length) {
      dispatch(getGLDASRequest());
    }
  }, [compareParam, BandsListData]);

  useEffect(() => {
    let isGraph = isCompare?.some((item) => item.name === 'graph' && item.selected);

    if (!_.isEmpty(selectedParam)) {
      setInitialSelectedDate((prev) => {
        return {
          ...prev,
          minEndDate: initialSelectedDate.startDate,
          minStartDate: selectedParam?.min_date,
        };
      });
    }
    if (isClickedOnPlus) {
      if (isGraph && !_.isEmpty(compareParam) && !_.isEmpty(selectedParam)) {
        let d1 = new Date(selectedParam?.min_date);
        let d2 = new Date(compareParam?.min_date);
        if (d1 >= d2) {
          setInitialSelectedDate((prev) => {
            return {
              ...prev,
              minStartDate: selectedParam?.min_date,
            };
          });
        } else {
          setInitialSelectedDate((prev) => {
            return {
              ...prev,
              minStartDate: compareParam?.min_date,
            };
          });
        }
      }
    }
  }, [isCompare, compareParam, selectedParam, isClickedOnPlus]);

  useEffect(() => {
    props.handleLocation(country, state, selectedDistrict, basin, region);
  }, [country, state, basin, region, selectedDistrict]);

  useEffect(() => {
    if (selectedLocation) props.handleSelectedLocation(selectedLocation);
  }, [selectedLocation]);

  useEffect(() => {
    props.handleCompareLocation(compareCountry, compareState, selectedCompareDistrict);
  }, [compareCountry, compareState, selectedCompareDistrict]);

  useEffect(() => {
    let isInvalidDate = false;
    let isStartDateInvalid =
      document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true' ? true : false;
    let isEndDateInvalid =
      document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true' ? true : false;
    let isCompareStartDateInvalid =
      document.getElementById('compareStartDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isCompareEndDateInvalid =
      document.getElementById('compareEndDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    if (
      isStartDateInvalid ||
      isEndDateInvalid ||
      isCompareStartDateInvalid ||
      isCompareEndDateInvalid
    ) {
      isInvalidDate = true;
    } else {
      isInvalidDate = false;
    }
    setInvalidDate(isInvalidDate);
  }, [initialSelectedDate, finalSelectedDate]);

  const updateDroughtTemporalAggregation = (selectedParam) => {
    if (selectedParam?.slug === 'rain_fall') {
      if (DROUGHT_TEMPORAL_AGGREGATION?.findIndex((i) => i.name === 'Sum') < 0)
        DROUGHT_TEMPORAL_AGGREGATION.push({ id: 4, name: 'Sum', value: 'sum', label: 'Sum' });
    } else {
      DROUGHT_TEMPORAL_AGGREGATION?.map((item, index) => {
        if (item.name === 'Sum') {
          DROUGHT_TEMPORAL_AGGREGATION.pop();
        }
      });
    }
  };

  const setParamAttributes = (selectedParam) => {
    let arr = [];
    arr.push(selectedParam);
    let defaultSelectedAggregation;
    if (selectedParam?.slug === 'rain_fall') {
      if (temporalAggregation?.name !== 'Sum')
        defaultSelectedAggregation = DROUGHT_TEMPORAL_AGGREGATION?.find(
          (item) => item.name === 'Sum',
        );
    } else {
      defaultSelectedAggregation = DROUGHT_TEMPORAL_AGGREGATION?.find(
        (item) => item.name === 'Mean',
      );
    }
    if (!_.isEmpty(defaultSelectedAggregation)) {
      setTemporalAggregation(defaultSelectedAggregation);
    }
    props.handleSelectedParam(selectedParam);
    setRangeState({ min: null, max: null });
  };

  const handleTemporalAggregation = (selectedOption) => {
    setTemporalAggregation(selectedOption);
  };

  // const handleSelectedItem = (newValue, action) => {
  //   if (action.name === 'country') {
  //     handleOptions(countryList, newValue, action);
  //     setCountry(newValue);
  //     setCompareCountry(newValue);
  //     setState(null);
  //     setSelectedCompareDistrict(null);
  //     setSelectedDistrict(null);
  //     setCompareState(null);
  //     if (newValue.name === SOUTH_ASIA_CONST.name) {
  //       setSelectedRegion(regionOptions);
  //       setStateList([]);
  //     }
  //   } else if (action.name === 'state') {
  //     setState(newValue);
  //     setSelectedDistrict(null);
  //     if (newValue.name === '' || newValue.value === '') {
  //       setDistrictList([]);
  //       setSelectedOption(selectedLocation?.country);
  //     }
  //     handleOptions(stateList, newValue, action);
  //   } else if (action.name === 'district') {
  //     handleOptions(districtList, newValue, action);
  //     setSelectedDistrict(newValue);
  //     if (newValue.value === '') setSelectedOption(selectedLocation?.state);
  //     // if (compareCountry?.name === country?.name && compareState?.name === state?.name) {
  //     //   setSelectedCompareDistrict(newValue);
  //     // }
  //     // setSelectedCompareDistrict(newValue);
  //   }
  //   setSelectedLocation(prev => {
  //     return {
  //       ...prev,
  //       [action.name]: newValue,
  //     };
  //   });
  //   if (country?.name === compareCountry?.name) {
  //     setCompareLocation(prev => {
  //       return {
  //         ...prev,
  //         [action.name]: newValue,
  //       };
  //     });
  //   }
  // };
  const handleSelectedItem = (newValue, action) => {
    if (action.name === 'country') {
      //handleCountry(newValue.value);
      //setCountry(newValue.value);
      setCountry(newValue);
      handleOptions(countryList, newValue, action);
      setState(null);
      setSelectedDistrict(null);
      if (newValue.value === '') setStateList([]);
    } else if (action.name === 'state' && newValue.value !== 'Sri Lanka') {
      setSelectedLocation((prev) => {
        return {
          ...prev,
          state: newValue.value,
          district: '',
        };
      });
      setState(newValue);
      setSelectedDistrict(null);
      // handleAdvancedSearch({ state: newValue.value });
      handleOptions(stateList, newValue, action);
      if (newValue.value === '') setDistrictList([]);
    } else if (newValue.value === 'Sri Lanka' && action.name === 'state') {
      setState(newValue);
      setSelectedOption({
        label: 'Sri Lanka',
        latitude: SRILANKA_COORDINATE.lat,
        longitude: SRILANKA_COORDINATE.long,
        name: 'Sri Lanka',
        value: 'Sri Lanka',
        zoom_level: 8,
      });
      setSelectedLocation((prev) => {
        return {
          ...prev,
          state: '',
          district: '',
        };
      });
      setSelectedDistrict(null);
      setDistrictList([]);
    } else if (action.name === 'district' && newValue.value !== 'Select') {
      // handleAdvancedSearch({ district: newValue.value });
      handleOptions(districtList, newValue, action);
      setSelectedDistrict(newValue);
    } else if (action.name === 'district' && newValue.value === 'Select') {
      setSelectedLocation((prev) => {
        return {
          ...prev,
          district: '',
        };
      });
      // handleAdvancedSearch({ district: newValue.value });
      handleOptions(districtList, newValue, action);
      setSelectedDistrict(newValue);
    }
  };

  //handling selected location for compare
  const handleCompareSelectedItem = (newValue, action) => {
    if (action.name === 'country') {
      // props.handleCompareSearch({ country: newValue.value });

      setCompareCountry(newValue);
      handleCompareOptions(countryList, newValue, action);
      setCompareState(null);
      if (newValue.value === '') setCompareStateList([]);
    } else if (action.name === 'state') {
      setCompareState(newValue);
      setSelectedCompareDistrict(null);
      if (newValue.name === '') {
        // props.handleCompareSearch({ state: newValue.value });

        handleCompareOptions(compareStateList, country, 'country');
        setCompareDistrictList([]);
      } else {
        // props.handleCompareSearch({ state: newValue.value });

        handleCompareOptions(compareStateList, newValue, action);
      }
      if (newValue.value === '') setCompareDistrictList([]);
    } else if (action.name === 'district') {
      // props.handleCompareSearch({ district: newValue.value });

      handleCompareOptions(compareDistrictList, newValue, action);
      setSelectedCompareDistrict(newValue);
    }
  };

  // useEffect(() => {
  //   let arr = [];
  //   if (Location.districtList) {
  //     let result = '';
  //     let config = Location?.districtList?.config;
  //     if (Location?.districtList?.data) result = Location?.districtList?.data?.result;
  //     if (result) {
  //       result?.map(i => {
  //         return arr.push({
  //           id: i.id,
  //           name: i.name,
  //           value: i.name,
  //           label: i.name,
  //           state_id: i.state_id,
  //           latitude: i.latitude,
  //           longitude: i.longitude,
  //           zoom_level: i.zoom_level,
  //         });
  //       });
  //     }
  //     if (!config?.isCompareLocation) {
  //       setDistrictList(arr);
  //     } else {
  //       setCompareDistrictList(arr);
  //     }
  //   }
  // }, [Location.districtList]);
  const handleStartDate = (e) => {
    let d1 = new Date(e.target.value);
    setInitialSelectedDate((prevdate) => {
      return {
        ...prevdate,
        startDate: e.target.value,
        minEndDate: moment(new Date(e.target.value)).add(1, 'days').format(DATE_FORMAT),
      };
    });
    /**TODO: Need to check dates validation later */
    if (d1.getTime() === new Date(initialSelectedDate.endDate).getTime()) {
      setError(true);
      setErrorMessage('start date and end date should not be equal ');
    } else {
      setError(false);
      setErrorMessage('');
    }
  };

  const handleEndDate = (e) => {
    setInitialSelectedDate((prevdate) => {
      return {
        ...prevdate,
        maxStartDate: moment(new Date(e.target.value)).subtract(1, 'days').format(DATE_FORMAT),
        endDate: e.target.value,
      };
    });
    let d1 = new Date(initialSelectedDate.startDate);
    let d2 = new Date(e.target.value);
    /**TODO: Need to check dates validation later */
    if (d1.getTime() === d2.getTime()) {
      setError(true);
      setErrorMessage(t('DROUGHT_MONITORING.DROUGHT_DATE_COMPARISON'));
    } else {
      setError(false);
      setErrorMessage('');
    }
  };

  // const handleOptions = (options, newValue, action) => {
  //   if (options && options.length) {
  //     if (newValue?.value) {
  //       let selectedOption = options.find(item => item.name === newValue.value);
  //       if (selectedOption) {
  //         setSelectedOption(selectedOption);
  //       } else {
  //         setSelectedOption('');
  //       }
  //     }
  //   }
  // };
  const handleOptions = (options, newValue, action) => {
    if (options && options.length) {
      let selectedOption = options.find((item) => item.name === newValue?.value);
      if (selectedOption) {
        if (selectedOption?.name !== 'Select') {
          setSelectedOption(selectedOption);
        } else {
          setSelectedOption(distrcitSelectValue);
        }
        if (action.name === 'state') {
          setDistrictStateValue(newValue);
          setDistrcitSelectValue(selectedOption);
          // setSelectedDistrictMapCoordinate(selectedOption);
          // setSelectedStateValue({ lable: newValue.value });
        }
      } else {
        setSelectedOption('');
      }
      if (selectedOption?.name !== 'Select') {
        setSelectedLocation((prev) => {
          return { ...prev, [action.name]: newValue || '' };
        });
      } else {
        setState(districtStateValue);
      }
    }
  };

  // const handleOptions = (options, newValue, action) => {
  //   let selectedOption;
  //   if (options && options.length) {
  //     if(action === "country"){
  //       selectedOption = options.find(item => item.name === newValue);
  //     }
  //     else{
  //       selectedOption = options.find(item => item.name === newValue?.value);
  //     }
  //     if (selectedOption) {

  //     if(action === 'country'){
  //       setDefaultSelectedOption(selectedOption);
  //     }
  //       if (action.name === 'country') {
  //         setDefaultSelectedOption(selectedOption);
  //       }
  //       setSelectedOption(selectedOption);
  //     } else {
  //       if (action.name === 'country') {
  //         setDefaultSelectedOption('');
  //       }
  //       setSelectedOption('');
  //     }
  //     if(action === "country"){
  //       setSelectedLocation(prev => {
  //         return {
  //           ...prev,
  //         [action]: selectedOption || '',
  //         };
  //       });
  //     }
  //     else{
  //       setSelectedLocation(prev => {
  //        return {
  //         ...prev,
  //         [action.name]: selectedOption || '',
  //        };
  //       });
  //     }

  //   }
  // };

  // const handleOptions = (options, newValue, action) => {
  //   if (options && options.length) {
  //     if (newValue?.value) {
  //       let selectedOption = options.find(item => item.name === newValue.value);
  //       if (selectedOption) {
  //         setSelectedOption(selectedOption);
  //       } else {
  //         setSelectedOption('');
  //       }
  //     } else {
  //       setSelectedOption('');
  //     }
  //   }
  // };

  const handleApplyLayers = () => {
    props.handleApplyLayers(true);
  };

  const setCompareToggle = () => {
    if (!props?.isSelectedRegion?.region?.length) {
      setIsPreviousClick(isClickedOnPlus);
      setIsClickedOnPlus(!isClickedOnPlus);
    }
  };

  const handleFinalStartDate = (e) => {
    let d1 = new Date(e.target.value);
    /**TODO: Need to check dates validation later */
    if (d1.getTime() === new Date(finalSelectedDate.endDate).getTime()) {
      setCompareError({ showError: true, msg: 'start date and end date should not be equal ' });
    } else {
      setCompareError({ showError: false, msg: '' });
    }
    setFinalSelectedDate((prevdate) => {
      return {
        ...prevdate,
        startDate: e.target.value,
        minEndDate: moment(e.target.value).add(1, 'days').format(DATE_FORMAT),
      };
    });
  };

  const handleFinalEndDate = (e) => {
    let d1 = new Date(e.target.value);
    /**TODO: Need to check dates validation later */
    if (d1.getTime() === new Date(finalSelectedDate.startDate).getTime()) {
      setCompareError({ showError: true, msg: 'start date and end date should not be equal ' });
    } else {
      setCompareError({ showError: false, msg: '' });
    }
    setFinalSelectedDate((prevdate) => {
      return {
        ...prevdate,
        maxStartDate: moment(e.target.value).subtract(1, 'days').format(DATE_FORMAT),
        endDate: e.target.value,
      };
    });
  };

  const handleCateogryToggle = (item) => {
    if (item === 'forecast-location') {
      openCollapse.forecast_location = !openCollapse.forecast_location;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'monitoring_timeframe') {
      openCollapse.monitoring_timeframe = !openCollapse.monitoring_timeframe;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'monitoring_compare') {
      openCollapse.monitoring_compare = !openCollapse.monitoring_compare;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'monitoring_indicators') {
      openCollapse.monitoring_indicators = !openCollapse.monitoring_indicators;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'monitoring_compare_indicators') {
      openCollapse.monitoring_compare_indicators = !openCollapse.monitoring_compare_indicators;
      setOpenCollapse({ ...openCollapse });
    }
  };

  const handleCompareCategoryToggle = (item) => {
    if (item === 'monitoring_compare') {
      openCollapse.monitoring_compare = !openCollapse.monitoring_compare;
      setOpenCollapse({ ...openCollapse });
      //if(openCollapse.monitoring_compare === false){
      setCompareToggle();
      // }
      // if (openCollapse.monitoring_compare) {
      //   setIsClickedOnPlus(!isClickedOnPlus);
      // }
    }
  };

  const renderCompareDates = (isCompare) => {
    let isGraphCompare = isCompare.find((item) => item.name === 'graph');
    if (!isGraphCompare.selected) {
      return (
        <Col className="mx-3">
          <Input
            type="date"
            placeholder="Start date"
            bsSize="sm"
            id="compareStartDate"
            className="mt-3 my-3 input-style"
            value={finalSelectedDate.startDate}
            onChange={handleFinalStartDate}
            min={compareParam?.min_date}
            max={finalSelectedDate.maxStartDate}
            invalid={
              new Date(compareParam?.min_date) > new Date(finalSelectedDate.startDate) ||
              new Date(finalSelectedDate.maxStartDate) < new Date(finalSelectedDate.startDate) ||
              finalSelectedDate.startDate === 'Invalid date'
            }
            disabled={_.isEmpty(compareParam)}
          />
          {!compareError?.showError &&
            document.getElementById('compareStartDate')?.getAttribute('aria-invalid') ===
              'true' && <FormFeedback invalid>Invalid date</FormFeedback>}
          <Input
            type="date"
            placeholder="End date"
            id="compareEndDate"
            bsSize="sm"
            className="mt-1 my-3 input-style"
            value={finalSelectedDate.endDate}
            onChange={handleFinalEndDate}
            min={finalSelectedDate?.minEndDate}
            max={maxEndDate}
            disabled={_.isEmpty(compareParam)}
            invalid={
              new Date(finalSelectedDate?.minEndDate) > new Date(finalSelectedDate.endDate) ||
              new Date(maxEndDate) < new Date(finalSelectedDate?.endDate) ||
              finalSelectedDate?.endDate === 'Invalid date' ||
              compareError?.showError
            }
          />
          {document.getElementById('compareEndDate')?.getAttribute('aria-invalid') === 'true' && (
            <FormFeedback invalid>
              {compareError?.msg ? compareError?.msg : 'Invalid date'}
            </FormFeedback>
          )}
        </Col>
      );
    }
  };

  const handleInfo = (e, indices) => {
    e?.stopPropagation();
    window.open(
      `/user-guide-details?topicId=${WEATHER_FORECAST_MODULE.id}&&sub-topic-name=${indices}&topic_name=Weather  Water`,
    );
  };
  const handleCompareOptions = (options, newValue, action) => {
    if (options && options.length) {
      if (newValue.value) {
        let selected_Option = options.find((item) => item.name === newValue.value);
        if (selected_Option) {
          if ((action || action.name) === 'country') {
            setDefaultCompareSelectedOption(selected_Option);
            if (action === 'country') {
              setCompareLocation((prev) => {
                return {
                  [action]: selected_Option,
                };
              });
            } else {
              setCompareLocation((prev) => {
                return {
                  [action.name]: selected_Option,
                };
              });
            }
          } else {
            setCompareLocation((prev) => {
              return {
                ...prev,
                [action.name]: selected_Option,
              };
            });
          }
          setCompareSelectedOption(selected_Option);
        } else {
          setDefaultCompareSelectedOption('');
          if (action === 'country') {
            setCompareLocation((prev) => {
              return {
                [action]: selected_Option,
              };
            });
          }
        }
      } else {
        if (action === 'country') {
          setDefaultCompareSelectedOption('');
          setCompareLocation((prev) => {
            return {
              [action]: '',
            };
          });
          setCompareSelectedOption('');
        }
      }
    }
  };
  const handleCompareToggle = ({ target }) => {
    let compareData = isCompare;
    const nextCompare = compareData.map((item) => {
      if (item.value === target.value) {
        return {
          ...item,
          selected: target.checked,
        };
      } else {
        return {
          ...item,
          selected: false,
        };
      }
    });
    setIsCompare(nextCompare);
  };

  const handleCompareLocationRender = () => {
    let isGraphCompare = isCompare.find((item) => item.name === 'graph' && item.selected);
    if (isGraphCompare) {
      return (
        <>
          <div className="pt-2  mb-3 mx-3">
            {/* <span className="orangeColor mt-2">Select Region</span> */}
            <div className="mt-2 mb-3">
              <Select
                id="multi-select"
                name="country"
                placeholder="SELECT_COUNTRY"
                options={renderAPIParameterOptions(countryList)}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={compareCountry}
                defaultValue={compareCountry}
                isDisabled={true}
              />
            </div>
            {compareCountry && (
              <div className="mt-2 mb-3">
                <Select
                  id="multi-select"
                  name="state"
                  placeholder="Province"
                  options={renderAPIParameterOptions(compareStateList, 'state')}
                  onChange={handleCompareSelectedItem}
                  isMulti={false}
                  required={true}
                  value={compareState}
                />
              </div>
            )}
            {compareState && compareState?.id !== DEFAULT_COUNTRY.name && (
              <div className="mt-2 mb-3">
                <Select
                  id="multi-select"
                  name="district"
                  placeholder="Select District"
                  options={renderAPIParameterOptions(compareDistrictList)}
                  onChange={handleCompareSelectedItem}
                  isMulti={false}
                  required={true}
                  value={selectedCompareDistrict}
                />
              </div>
            )}
          </div>
        </>
      );
    }
  };

  //used to add more date field on click of + icon
  const addMoreDateFieldWhenClickedOnPlus = () => {
    return (
      <>
        {/* <span
          className="cursor-ponter orangeColor date-style"
          onClick={() => setIsClickedOnPlus(!isClickedOnPlus)}
        >
          <BiMinus size={20} />
          <span className="mx-2">Compare</span>
        </span> */}
        <span>
          <FormGroup check>
            <Row className="compare-style mx-1 mb-2">
              {isCompare.map((item) => {
                return (
                  <>
                    <Col sm={4} lg={4} md={4} className="mt-2">
                      <Label check>
                        <Input
                          type="radio"
                          name="radio1"
                          value={item.value}
                          defaultChecked={item.selected}
                          onChange={handleCompareToggle}
                        />{' '}
                        {item.value}
                      </Label>
                    </Col>{' '}
                  </>
                );
              })}
            </Row>
          </FormGroup>
        </span>

        {handleCompareLocationRender()}
        {/* <p className="orangeColor mt-3"> Indicators</p> */}
        {/* <p className="orangeColor mt-3"> Indicators</p> */}
        <span onClick={() => handleCateogryToggle('monitoring_compare_indicators')}>
          <p
            className="map-preferences-labels"
            //style={checkboxStyle}
          >
            {/* {item.category} */}
            Indicators
            <span className="category-span">
              <span className="category-span">
                {openCollapse.monitoring_compare_indicators ? (
                  <RiArrowDropUpLine size={25} />
                ) : (
                  <RiArrowDropDownLine size={25} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="forecast-location"
          isOpen={openCollapse.monitoring_compare_indicators}
          className="collapse-moadal"
        >
          {handleCompareIndicators()}
        </Collapse>

        {/* {selectedModule.api_slug === 'drought-monitor' ? (
          <DroughtIndicatorsCollapse
            DroughtIndicesData={DroughtIndicesData}
            setMinStartDate={setMinStartDate}
            setSelectedParam={setSelectedParam}
            setCompareParam={setCompareParam}
            isSubCategory={false}
            isCategory={true}
            isCompare={true}
          />
        ) : selectedModule.api_slug === 'drought-monitor-v2' ||
            selectedModule.api_slug === 'drought-prediction' ? (
          <DroughtMonitorOtherIndicesCollapse
            DroughtIndicesData={DroughtIndicesData}
            selectedModule={selectedModule}
            setMinStartDate={setMinStartDate}
            setSelectedParam={setSelectedParam}
            setCompareParam={setCompareParam}
            isCompare={true}
          />
        ) : (
          <div>
            <p>No Indicators available</p>
          </div>
        )} */}
        {renderCompareDates(isCompare)}
        {compareParam?.slug !== WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL &&
        compareParam?.slug !== WEATHER_MONITOR_CONSTS.DRYSPELL ? (
          <Row>
            <Col className="mx-3">
              {' '}
              <Input
                type="number"
                placeholder="Min."
                bsSize="sm"
                className="mt-1 mb-3 p-2 temp-input"
                value={compareRangeState.min || ''}
                onChange={(e) => {
                  setCompareRangeState((prev) => {
                    return {
                      ...prev,
                      min: e.target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col className="mx-3">
              <Input
                type="number"
                placeholder="Max."
                bsSize="sm"
                className="mt-1 mb-3 p-2 temp-input"
                value={compareRangeState.max || ''}
                onChange={(e) => {
                  setCompareRangeState((prev) => {
                    return {
                      ...prev,
                      max: e.target.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        ) : (
          ''
        )}

        {compareMinError ? minmaxError() : ''}
      </>
    );
  };

  useEffect(() => {
    let isMapSubmit, isGraphSubmit;
    let mapPreferenceCount = mapPreferencesData?.length;
    if (!_.isEmpty(selectedParam) && !error & !minError && !compareMinError) {
      if (props?.isSelectedRegion?.region?.length) {
        //need condition
        // if (selectedParam?.slug === 'dryspell' || selectedParam?.slug === 'no_of_rainy_days') {
        //   if (mapPreferenceCount === 2) {
        //     isMapSubmit = false;
        //   } else isMapSubmit = true;
        //   isGraphSubmit = false;
        // } else {
        if (mapPreferenceCount === 2 || invalidDate) {
          isMapSubmit = false;
        } else isMapSubmit = true;
        if (!invalidDate) isGraphSubmit = true;
        else {
          isGraphSubmit = false;
        }
        // }
        setShowToolTip(true);
      } else {
        setShowToolTip(false);
        if (!_.isEmpty(selectedLocation?.country)) {
          /// need condition
          // if (selectedParam?.slug === 'dryspell' || selectedParam?.slug === 'no_of_rainy_days') {
          //   if (mapPreferenceCount === 2) {
          //     isMapSubmit = false;
          //   } else isMapSubmit = true;
          //   isGraphSubmit = false;
          // } else {
          if (mapPreferenceCount === 2 || invalidDate) {
            isMapSubmit = false;
          } else isMapSubmit = true;
          if (!invalidDate) isGraphSubmit = true;
          else {
            isGraphSubmit = false;
          }
          // }
        } else {
          isMapSubmit = false;
          isGraphSubmit = false;
        }
      }
      if (isClickedOnPlus) {
        let isMap = isCompare?.some((item) => item.name === 'map' && item.selected);
        let isGraph = isCompare?.some((item) => item.name === 'graph' && item.selected);
        if (isMap) {
          isGraphSubmit = false;
          if (
            _.isEmpty(compareParam) ||
            compareError?.showError ||
            mapPreferenceCount === 2 ||
            invalidDate
          ) {
            isMapSubmit = false;
          } else isMapSubmit = true;
        }
        if (isGraph) {
          isMapSubmit = false;
          if (_.isEmpty(compareParam) || _.isEmpty(selectedParam) || invalidDate) {
            isGraphSubmit = false;
          } else {
            // if (
            //   (selectedParam?.slug === 'dryspell' || selectedParam?.slug === 'no_of_rainy_days') &&
            //   (compareParam?.slug === 'dryspell' || compareParam?.slug === 'no_of_rainy_days')
            // ) {
            //   isGraphSubmit = false;
            // } else if (
            //   (selectedParam?.slug !== 'dryspell' || selectedParam?.slug !== 'no_of_rainy_days') &&
            //   (compareParam?.slug === 'dryspell' || compareParam?.slug === 'no_of_rainy_days')
            // ) {
            //   isGraphSubmit = false;
            // } else if (
            //   (selectedParam?.slug === 'dryspell' || selectedParam?.slug === 'no_of_rainy_days') &&
            //   (compareParam?.slug !== 'dryspell' || compareParam?.slug !== 'no_of_rainy_days')
            // ) {
            //   isGraphSubmit = false;
            // } else {
            isGraphSubmit = true;
            // }
          }
        }
      }
    } else if (!minError && !compareMinError) {
      isMapSubmit = false;
      isGraphSubmit = false;
    }
    setApplyLayers(isMapSubmit);
    if (applyLayersDisable === true) {
      setApplyLayers(false);
    }
    setUpdateGraph(isGraphSubmit);
  }, [
    selectedParam,
    temporalAggregation,
    props?.isSelectedRegion?.region,
    mapPreferencesData,
    selectedLocation.country,
    isCompare,
    compareParam,
    isClickedOnPlus,
    error,
    compareError.showError,
    invalidDate,
    minError,
    compareMinError,
  ]);

  useEffect(() => {
    props.handleIsCompare(isClickedOnPlus);
    if (!isClickedOnPlus) {
      let compareData = JSON.parse(JSON.stringify(isCompare));
      const nextCompare = compareData?.map((item) => {
        if (item.name === 'map') {
          return {
            ...item,
            selected: true,
          };
        } else {
          return {
            ...item,
            selected: false,
          };
        }
      });
      setIsCompare(nextCompare);
      if (compareIndices?.length) {
        let data = _.cloneDeep(compareIndices);
        let updatedData = data?.map((i) => {
          return { ...i, checked: false };
        });
        if (updatedData?.length) setCompareIndices(updatedData);
        setCompareParam('');
        props.setComparePrecipitationThreshold('2.5');
        props.setCompareExtremeRainfallThreshold('124');
        if (compareStateList?.length && isPreviousClick) {
          let defaultCountry = countryList?.find((i) => i.id === DEFAULT_COUNTRY.id);
          if (defaultCountry) {
            setCompareSelectedOption(defaultCountry);
          }
          setCompareDistrictList([]);
          setSelectedCompareDistrict(null);
          let defaultState = compareStateList?.find((i) => i.id === DEFAULT_COUNTRY.id);
          setCompareState({
            id: DEFAULT_COUNTRY.name,
            value: DEFAULT_COUNTRY.name,
            label: 'Select Province',
          });
          setCompareLocation((prev) => {
            return {
              ...prev,
              state: '',
              district: '',
            };
          });
        }
      }
    }
  }, [isClickedOnPlus]);

  useEffect(() => {
    if (props?.isSelectedRegion?.region?.length) {
      if (isClickedOnPlus) {
        setIsClickedOnPlus(false);
      }
      setIsPreviousClick(isClickedOnPlus);
    } else if (props?.isSelectedRegion?.region !== null && isPreviousClick) {
      setIsPreviousClick(isClickedOnPlus);
      setIsClickedOnPlus(true);
    }
  }, [props.isSelectedRegion]);

  useEffect(() => {
    if (openCollapse?.monitoring_compare) {
      if (isClickedOnPlus !== openCollapse?.monitoring_compare) setIsPreviousClick(isClickedOnPlus);
      setIsClickedOnPlus(openCollapse?.monitoring_compare);
    }
  }, [openCollapse?.monitoring_compare]);

  // useEffect(()=>{
  //   if (props?.isSelectedRegion?.region?.length) {
  //     console.log("setting clicked on plus")
  //     setIsPreviousClick(isClickedOnPlus)
  //   }
  // },[props.isSelectedRegion, isClickedOnPlus])

  useEffect(() => {
    props.handleSelectedRegion(selectedRegion.value);
  }, [selectedRegion]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
  };

  const renderAPIParameterOptions = (list, action) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        let default_option =
          // action === 'country' ? regionOptions : { name: 'select', label: 'Select', value: '' };
          // options.push(default_option);
          params.map((param) => {
            options.push(param);
            return true;
          });
        return options;
      }
    }
  };

  const handleChartDisplay = () => {
    props.renderCharts(true, selectedParam);
  };

  const minmaxError = () => {
    return (
      <p className="min-max-error text-danger">
        {t('DROUGHT_MONITORING.DROUGHT_MIN_MAX_ERROR_MESSAGE')}
      </p>
    );
  };

  const handleSelectedModule = (selectedOption) => {
    setSelectedParam('');
    setCompareParam('');
    props.handleSelectedModule(selectedOption);
  };

  const handleSelectedDroughtIndicator = (data) => {
    const end_date = moment(data?.max_date, 'YYYY-MM-DD');
    const start_date = end_date.clone().subtract(30, 'days');
    const start_date_str = start_date.format('YYYY-MM-DD');
    setInitialSelectedDate((prevdate) => {
      return {
        ...prevdate,
        startDate: start_date_str,
        endDate: data?.max_date,
      };
    });
 
    let updatedData = _.cloneDeep(droughtIndices);
    let finalData = updatedData?.map((i) =>
      i?.slug === data?.slug ? { ...i, checked: true } : { ...i, checked: false },
    );
    if (finalData?.length) setDroughtIndices(finalData);
    setSelectedParam(data);
    props.renderCharts(false);
  };

  const handleSelectedCompareDroughtIndicator = (data) => {
    const compare_end_date = moment(data?.max_date, 'YYYY-MM-DD');
    const compare_start_date = compare_end_date.clone().subtract(30, 'days');
    const compare_start_date_str = compare_start_date.format('YYYY-MM-DD');
    setFinalSelectedDate((prevdate) => {
      return {
        ...prevdate,
        startDate: compare_start_date_str,
        endDate: data?.max_date,
      };
    });
    setCompareParam(data);
    let updatedData = _.cloneDeep(compareIndices);
    let finalData = updatedData?.map((i) =>
      i?.slug === data?.slug ? { ...i, checked: true } : { ...i, checked: false },
    );
    if (finalData?.length) setCompareIndices(finalData);
  };

  const handleComparePrecipitationThresholdField = () => {
    return (
      <>
        {compareParam?.slug === WEATHER_MONITOR_CONSTS.DRYSPELL ? (
          <div className="mx-0 mt-3 mb-3">
            <label for="quantity">Precipitation Threshold (in mm)</label>
            <Input
              type="number"
              id="quantity"
              name="quantity"
              min="2.5"
              //max="10"
              value={props.comparePrecipitationThreshold}
              onChange={(e) => props.handleComparePrecipitationThreshold(e.target.value)}
            />
          </div>
        ) : (
          ''
        )}
        {compareParam?.slug === WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL ? (
          <div className="mx-0 mt-3 mb-3">
            <label for="quantity">Precipitation Threshold (in mm)</label>
            <Input
              type="number"
              id="quantity"
              name="quantity"
              min="0"
              //max="10"
              value={props.compareExtremeRainfallThreshold}
              onChange={(e) => props.handleCompareExtremePrecipitationThreshold(e.target.value)}
            />
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  const handleCompareGLDASIndicator = () => {
    if (compareParam?.slug === 'gldas') {
      return (
        <div className="mx-0 mt-3 mb-3">
          {/* <label for="bands_list">Bands</label> */}
          <Select
            id="bands_list"
            name="bands_list"
            onChange={(e) => props.handleSelectedCompareGlDASIndicator(e)}
            options={bandsList}
            defaultValue={selectedComapareBand}
          />
        </div>
      );
    }
  };

  const handlePrecipitationThresholdField = () => {
    return (
      <>
        {selectedParam?.slug === WEATHER_MONITOR_CONSTS.DRYSPELL ? (
          <div className="mx-3 mt-3 mb-3">
            <label for="quantity">Precipitation Threshold (in mm)</label>
            <Input
              type="number"
              id="quantity"
              name="quantity"
              min="0"
              //max="10"
              value={props.precipitationThreshold}
              onChange={(e) => props.handlePrecipitationThreshold(e.target.value)}
            />
          </div>
        ) : (
          ''
        )}
        {selectedParam?.slug === WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL ? (
          <div className="mx-3 mt-3 mb-3">
            <label for="quantity">Precipitation Threshold (in mm)</label>
            <Input
              type="number"
              id="quantity"
              name="quantity"
              min="0"
              //max="10"
              value={props.extremeRainfallThreshold}
              onChange={(e) => props.handleExtremePrecipitationThreshold(e.target.value)}
            />
          </div>
        ) : (
          ''
        )}
      </>
    );
  };
  const handleGLDASIndicator = () => {
    if (selectedParam?.slug === 'gldas') {
      return (
        <div className="mx-3 mt-3 mb-3">
          {/* <label for="bands_list">Bands</label> */}
          {bandsList && (
            <Select
              id="bands_list"
              name="bands_list"
              //value={selectedGLDAS}
              onChange={(e) => props.handleSelectedGlDASIndicator(e)}
              options={bandsList}
              defaultValue={selectedBand}
              //placeholder={Select}
            />
          )}
        </div>
      );
    }
  };

  const handleIndicators = () => {
    return (
      <div className="advance-search-fields data-source-field-one  mb-3">
        <div className="mx-3 mt-2 mb-3">
          <FormGroup check>
            {droughtIndices?.map((item) => {
              return (
                <Row>
                  <div className="icon-dsg">
                    <Label check className="">
                      <Input
                        type="radio"
                        name="radio2"
                        value={item.name}
                        defaultChecked={item.checked}
                        onChange={() => handleSelectedDroughtIndicator(item)}
                        //onChange={handleCompareToggle}
                      />{' '}
                      {item.name}
                    </Label>
                    <span className="info-style">
                      <i
                        className="cursor-ponter fa fa-info info-icon-edit-btn "
                        id="info-icon-tool-tip"
                        data-tip
                        data-for="info-icon-tool-tip"
                        onClick={(e) => handleInfo(e, item.name)}
                      />
                      <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                        Click here to know more.
                      </ReactTooltip>
                    </span>
                  </div>
                </Row>
              );
            })}
          </FormGroup>
        </div>
        {handlePrecipitationThresholdField()}
        {bandsList && bandsList.length ? handleGLDASIndicator() : ''}
      </div>
    );
  };
  const handleCompareIndicators = () => {
    return (
      <div className="advance-search-fields mb-3 mx-3">
        <div className="mt-2 mb-3">
          <FormGroup check>
            {compareIndices?.map((item) => {
              return (
                <>
                  <Row>
                    <div className="icon-dsg">
                      <Label check>
                        <Input
                          type="radio"
                          name="radio3"
                          value={item.name}
                          defaultChecked={item.checked}
                          onChange={() => handleSelectedCompareDroughtIndicator(item)}
                          //onChange={handleCompareToggle}
                        />{' '}
                        {item.name}
                      </Label>
                      <span className="info-style">
                        <i
                          className="cursor-ponter fa fa-info info-icon-edit-btn "
                          id="info-icon-tool-tip"
                          data-tip
                          data-for="info-icon-tool-tip"
                          onClick={(e) => handleInfo(e, item.name)}
                        />
                        <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                          Click here to know more.
                        </ReactTooltip>
                      </span>
                    </div>
                  </Row>
                </>
              );
            })}
          </FormGroup>
        </div>
        {handleComparePrecipitationThresholdField()}
        {bandsList && bandsList.length ? handleCompareGLDASIndicator() : ''}
      </div>
    );
  };
  const handleLocationRender = () => {
    return (
      <div className="advance-search-fields mb-3">
        <div className="mx-3 mb-3">
          <Select
            id="multi-select"
            name="country"
            placeholder="select country"
            options={renderAPIParameterOptions(countryList)}
            onChange={handleSelectedItem}
            isMulti={false}
            value={selectedLocation.country}
            defaultValue={selectedLocation.country}
            required={true}
            isDisabled={true}
          />
        </div>
        {country && state && (
          <div className="mx-3 mt-2 mb-3">
            <Select
              id="multi-select"
              name="state"
              placeholder="Select Province"
              options={renderAPIParameterOptions(stateList, 'state')}
              onChange={handleSelectedItem}
              value={state}
              isMulti={false}
              required={true}
            />
          </div>
        )}
        {state && state?.value !== 'Select All' && state?.id !== DEFAULT_COUNTRY.name && (
          <div className="mx-3 mt-2 mb-3">
            <Select
              id="multi-select"
              name="district"
              placeholder="Select District"
              options={renderAPIParameterOptions(districtList)}
              onChange={handleSelectedItem}
              isMulti={false}
              required={true}
              value={selectedDistrict}
            />
          </div>
        )}
      </div>
    );
  };

  const handleTimeFrameRender = () => {
    return (
      <div className="advance-search-fields mb-2">
        <div className="mx-3 mt-2 mb-3">
          <div className="date-style">
            <Input
              type="date"
              name="startDate"
              placeholder="Start date"
              id="startDate"
              bsSize="sm"
              min={initialSelectedDate.minStartDate}
              max={initialSelectedDate.maxStartDate}
              className="my-3 input-style"
              value={initialSelectedDate?.startDate}
              onChange={(e) => handleStartDate(e)}
              invalid={
                new Date(initialSelectedDate.minStartDate) >
                  new Date(initialSelectedDate?.startDate) ||
                new Date(initialSelectedDate.maxStartDate) <
                  new Date(initialSelectedDate?.startDate) ||
                initialSelectedDate?.startDate === 'Invalid date'
              }
              disabled={_.isEmpty(selectedParam)}
            />
            {!error &&
              document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true' && (
                <FormFeedback invalid>Invalid date</FormFeedback>
              )}
            <Input
              type="date"
              name="endDate"
              placeholder="End date"
              id="endDate"
              bsSize="sm"
              className="my-2 input-style"
              value={initialSelectedDate?.endDate}
              onChange={(e) => handleEndDate(e)}
              min={initialSelectedDate.minEndDate}
              max={maxEndDate}
              invalid={
                new Date(initialSelectedDate.minEndDate) > new Date(initialSelectedDate?.endDate) ||
                new Date(maxEndDate) < new Date(initialSelectedDate?.endDate) ||
                initialSelectedDate?.endDate === 'Invalid date' ||
                error
              }
              disabled={_.isEmpty(selectedParam)}
            />
            {document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true' && (
              <FormFeedback invalid>{errorMessage ? errorMessage : 'Invalid date'}</FormFeedback>
            )}
            {selectedParam?.slug !== WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL &&
            selectedParam?.slug !== WEATHER_MONITOR_CONSTS.DRYSPELL ? (
              <Row>
                <Col className="mx-1 mt-1">
                  {' '}
                  <Input
                    type="number"
                    placeholder="Min."
                    bsSize="sm"
                    className="mt-1 mb-3 p-2 temp-input"
                    value={rangeState.min || ''}
                    onChange={(e) => {
                      setRangeState((prev) => {
                        return {
                          ...prev,
                          min: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
                <Col className="mx-1 mt-1">
                  <Input
                    type="number"
                    placeholder="Max."
                    bsSize="sm"
                    className="mt-1 mb-3 p-2 temp-input"
                    value={rangeState.max || ''}
                    onChange={(e) => {
                      setRangeState((prev) => {
                        return {
                          ...prev,
                          max: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
            ) : (
              ''
            )}

            {minError ? minmaxError() : ''}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {props?.isHideBar ? (
        <div className="expand-bar">
          <span
            className="cursor-ponter navyBlueColor"
            onClick={() => props.setBar(!props.isHideBar)}
          >
            {t('CLIMATE_WEATHER_FILTER.EXPAND_BAR')}
            <span>
              <MdKeyboardArrowLeft className="hide-icon expand-margin" size={25} />
            </span>
          </span>
        </div>
      ) : (
        <div>
          <div className="filter-sidebar-section filter-sidebar-section-drought-monitor drought-filter-section">
            <div className="filter-div">
              <Card className="card-style">
                <CardBody>
                  <div className="d-flex justify-content-end navyBlueColor mb-3">
                    {' '}
                    <span className="cursor-ponter" onClick={() => props.setBar(!props.isHideBar)}>
                      <span className="hidebar-show"> {t('CLIMATE_WEATHER_FILTER.HIDE_BAR')}</span>{' '}
                      <span>
                        <MdKeyboardArrowRight className="hide-icon hide-margin" size={25} />
                      </span>
                    </span>
                  </div>{' '}
                  <div className="forecast-monitoring">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === t('CLIMATE_WEATHER_FILTER.FORECAST') ? 'active' : ''
                          }
                          onClick={() => handleToggle(t('CLIMATE_WEATHER_FILTER.FORECAST'))}
                        >
                          {activeTab === t('CLIMATE_WEATHER_FILTER.FORECAST') ? (
                            <img src={ForecastIcon} />
                          ) : (
                            <img src={InActiveForecastIcon} />
                          )}

                          <span className="tab_title">{t('CLIMATE_WEATHER_FILTER.FORECAST')}</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === t('CLIMATE_WEATHER_FILTER.MONITORING') ? 'active' : ''
                          }
                          onClick={() => handleToggle(t('CLIMATE_WEATHER_FILTER.MONITORING'))}
                        >
                          {activeTab === t('CLIMATE_WEATHER_FILTER.MONITORING') ? (
                            <img src={MonitoringIcon} />
                          ) : (
                            <img src={InActiveMonitoringIcon} />
                          )}

                          <span className="tab_title">
                            {t('CLIMATE_WEATHER_FILTER.MONITORING')}
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <>
                        <TabPane tabId={t('CLIMATE_WEATHER_FILTER.MONITORING')}>
                          <Form onSubmit={onSubmitHandler} className="filter-control-dp">
                            <span onClick={() => handleCateogryToggle('forecast-location')}>
                              <p
                                className="map-preferences-labels"
                                //style={checkboxStyle}
                              >
                                {/* {item.category} */}
                                Location
                                <span className="category-span">
                                  <span className="category-span">
                                    {openCollapse.forecast_location ? (
                                      <RiArrowDropUpLine size={25} />
                                    ) : (
                                      <RiArrowDropDownLine size={25} />
                                    )}
                                  </span>
                                </span>
                              </p>
                            </span>
                            <Collapse
                              id="forecast-location"
                              isOpen={openCollapse.forecast_location}
                              className="collapse-moadal"
                            >
                              {handleLocationRender()}
                            </Collapse>
                            {/* <div className="advance-search-fields mb-3">
                      <div className="mx-3 mb-3">
                        <Select
                          id="multi-select"
                          name="country"
                          placeholder="select country"
                          options={renderAPIParameterOptions(countryList)}
                          onChange={handleSelectedItem}
                          isMulti={false}
                          value={selectedLocation.country}
                          defaultValue={selectedLocation.country}
                          required={true}
                        />
                      </div>
                      {country && (
                        <div className="mx-3 mt-2 mb-3">
                          <Select
                            id="multi-select"
                            name="state"
                            placeholder="select state"
                            options={renderAPIParameterOptions(stateList)}
                            onChange={handleSelectedItem}
                            value={state}
                            isMulti={false}
                            required={true}
                          />
                        </div>
                      )}
                      {state && (
                        <div className="mx-3 mt-2 mb-3">
                          <Select
                            id="multi-select"
                            name="district"
                            placeholder="district"
                            options={renderAPIParameterOptions(districtList)}
                            onChange={handleSelectedItem}
                            isMulti={false}
                            required={true}
                            value={selectedDistrict}
                          />
                        </div>
                      )}
                    </div> */}
                            {/* <p className="sidebar-indicator">Select Indicators</p> */}
                            <span onClick={() => handleCateogryToggle('monitoring_indicators')}>
                              <p
                                className="map-preferences-labels"
                                //style={checkboxStyle}
                              >
                                {/* {item.category} */}
                                Indicators
                                <span className="category-span">
                                  <span className="category-span">
                                    {openCollapse.monitoring_indicators ? (
                                      <RiArrowDropUpLine size={25} />
                                    ) : (
                                      <RiArrowDropDownLine size={25} />
                                    )}
                                  </span>
                                </span>
                              </p>
                            </span>
                            <Collapse
                              id="forecast-location"
                              isOpen={openCollapse.monitoring_indicators}
                              className="collapse-moadal"
                            >
                              {handleIndicators()}
                            </Collapse>
                            <span onClick={() => handleCateogryToggle('monitoring_timeframe')}>
                              <p className="map-preferences-labels">
                                Timeframe and Range
                                <span className="category-span">
                                  <span className="category-span">
                                    {openCollapse.monitoring_timeframe ? (
                                      <RiArrowDropUpLine size={25} />
                                    ) : (
                                      <RiArrowDropDownLine size={25} />
                                    )}
                                  </span>
                                </span>
                              </p>
                            </span>
                            <Collapse
                              id="forecast-location"
                              isOpen={openCollapse.monitoring_timeframe}
                              className="collapse-moadal"
                            >
                              {handleTimeFrameRender()}
                            </Collapse>
                            {/* <div className="drought-monitor-error-msg">
                              <span>{errorMessage}</span>
                            </div> */}
                            {!props.isSelectedRegion?.region?.length ? (
                              <>
                                <span
                                  onClick={() => handleCompareCategoryToggle('monitoring_compare')}
                                >
                                  <p
                                    className="map-preferences-labels"
                                    //style={checkboxStyle}
                                  >
                                    {/* {item.category} */}
                                    Compare
                                    <span className="category-span">
                                      <span className="category-span">
                                        {openCollapse.monitoring_compare ? (
                                          <BiMinus size={25} />
                                        ) : (
                                          <BiPlus size={25} />
                                        )}
                                      </span>
                                    </span>
                                  </p>
                                </span>

                                <Collapse
                                  id="forecast-location"
                                  isOpen={openCollapse.monitoring_compare}
                                  className="collapse-moadal"
                                >
                                  {/* <div className="mx-3 mb-3"> */}
                                  <div className=" mb-3">
                                    {isClickedOnPlus &&
                                      !props.isSelectedRegion?.region?.length &&
                                      addMoreDateFieldWhenClickedOnPlus()}
                                  </div>
                                  {/* <div className="mx-3 mb-3">
                              {isClickedOnPlus && !props.isSelectedRegion?.region?.length ? (
                                addMoreDateFieldWhenClickedOnPlus()
                              ) : (
                                <span
                                  className={
                                    props.isSelectedRegion?.region?.length
                                      ? 'orangeColor orangeFadeColor'
                                      : 'blue-compare cursor-ponter orangeColor'
                                  }
                                  onClick={setCompareToggle}
                                >
                                  <BiPlus size={20} />
                                  <span className="mx-2">Compare</span>
                                </span>
                              )}
                            </div> */}
                                </Collapse>
                              </>
                            ) : (
                              ''
                            )}
                            <div className="mt-3 temp-style">
                              {selectedParam?.slug !== WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL &&
                              selectedParam?.slug !== WEATHER_MONITOR_CONSTS.DRYSPELL ? (
                                <div className="mx-3 mt-2 mb-3">
                                  <Select
                                    id="multi-select"
                                    name="aggregation"
                                    placeholder="SELECT TEMPORAL AGGREGATION"
                                    options={DROUGHT_TEMPORAL_AGGREGATION}
                                    value={temporalAggregation}
                                    onChange={handleTemporalAggregation}
                                    isMulti={false}
                                    required={true}
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="mx-3 mt-2 filter-submit">
                              <div
                                className="apply-layers"
                                id="apply_layers"
                                data-tip
                                data-for="apply_layers"
                              >
                                <Button
                                  className="navyBlueBgColor m-auto"
                                  onClick={handleApplyLayers}
                                  disabled={
                                    !applyLayers ||
                                    (selectedParam?.name === 'GLDAS' &&
                                    selectedGLDAS?.id === undefined &&
                                    invalidDate
                                      ? true
                                      : false) ||
                                    (isClickedOnPlus &&
                                    compareParam?.name === 'GLDAS' &&
                                    compareSelectedGLDAS?.id === undefined &&
                                    invalidDate
                                      ? true
                                      : false)
                                  }
                                >
                                  Apply Layers
                                </Button>
                              </div>
                              {showToolTip && props?.isSelectedRegion?.region?.length ? (
                                <ReactTooltip id="apply_layers" effect="solid" place="top">
                                  {t('FILTER.PLEASE_REMOVE_MARKERS')}
                                </ReactTooltip>
                              ) : (
                                ''
                              )}
                              {showMapPreferenceTooltip && !showToolTip ? (
                                <ReactTooltip
                                  id="apply_layers"
                                  effect="solid"
                                  place="top"
                                  type="info"
                                  className="apply-layer-tooltip-st"
                                >
                                  {t('FILTER.PLEASE_REMOVE_MAP_PREFERENCE_LAYER')}
                                </ReactTooltip>
                              ) : (
                                ''
                              )}
                              <div
                                className="update-graph mt-3"
                                id="view_graph"
                                data-tip
                                data-for="view_graph"
                              >
                                {/* {updateGraph ? ( */}
                                <Button
                                  outline
                                  className="navyBlueBgColor-outline m-auto"
                                  onClick={() => handleChartDisplay()}
                                  disabled={
                                    !updateGraph ||
                                    (selectedParam?.name === 'GLDAS' &&
                                      selectedGLDAS?.id === undefined)
                                      ? true
                                      : false
                                  }
                                >
                                  View Graph
                                </Button>
                                {/* ) : (
                                  ''
                                )} */}
                              </div>
                              {!updateGraph &&
                              isCompare?.some((i) => i.name === 'graph' && i.selected) ? (
                                <ReactTooltip
                                  id="view_graph"
                                  type="info"
                                  effect="solid"
                                  getContent={() => t('FILTER.NO_CHART_FOR_PARAM')}
                                  multiline={true}
                                  place={'top'}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </Form>
                        </TabPane>
                      </>
                    </TabContent>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DroughtSidebar;