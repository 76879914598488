import React, { useEffect, useState } from 'react';

import { Container, Row, Col, FormGroup, Label } from 'reactstrap';

// import RightArrow from '../../assets/Images/back-button.svg';
import RightArrow from '../../assets/Images/back-button.svg';

import { useHistory, useLocation } from 'react-router-dom';

import ImagePlaceHolder from '../../assets/Images/imagePlaceholder.png';
import Image1 from '../../assets/Images/camera.png';

import { useDispatch, useSelector } from 'react-redux';

import { getFieldDataRequest } from '../../redux/manageFieldData/manageFieldDataActions';
import Loader from '../../Components/common/loader';
import { BsArrowLeft } from 'react-icons/bs';
import _ from 'lodash';

const SurveyDetails = () => {
  // return <h1>Survey Details</h1>

  let history = useHistory();

  let dispatch = useDispatch();
  const paramLocation = useLocation();

  const nextProps = useSelector((state) => ({
    isFieldDataListDataRequesting: state.fieldDataList.isFieldDataListDataRequesting,
    fieldDataListData: state?.fieldDataList?.FieldDataListData,
    loginDetailData: state.Login.loginDetailData || null,
  }));

  const [newRequestList, setNewRequestList] = useState([
    // {
    //   'Farmer Name': 'test',
    //   'Farmer ID': '12345',
    //   'Phone No.': '9876543210',
    //   District: 'test',
    //   'DS Division': 'test',
    //   'GN Division': 'test',
    //   'Surveyor Name': 'test',
    //   'Status of Insurance': 'test',
    // },
  ]);

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    // dispatch(
    //   getFieldDataRequest({
    //     // url: 'https://docs.google.com/spreadsheets/d/1PEhNaXwDJBTSGeNI5k19FxCRZMZFb8aG1HtNzqFVxXg/edit?usp=sharing',
    //     // url: 'https://docs.google.com/spreadsheets/d/1UYTpGCbvu7l1XkylHB4AuqKVPBOk2MAGqPxsd-a86i4/edit?usp=sharing',
    //     year: paramLocation?.state?.selectedDate,
    //     component_id: paramLocation?.state?.id,
    //   }),
    // );
  }, []);

  // useEffect(() => {
  //   if (nextProps.fieldDataListData) {
  //     if (nextProps?.fieldDataListData?.data?.result) {
  //       setNewRequestList(
  //         nextProps?.fieldDataListData?.data?.result?.['farm_odk_data ']?.filter(
  //           (item) => item?.['data-meta-instanceID'] === history.location?.pathname.split('/')[2],
  //         ),
  //       );
  //     } else {
  //       setNewRequestList([]);
  //     }
  //   } else {
  //     setNewRequestList([]);
  //   }
  // }, [nextProps.fieldDataListData]);
  useEffect(() => {
    if (!_.isEmpty(paramLocation?.state?.newRequestList) && paramLocation?.state?.uuid) {
    setNewRequestList(
      paramLocation?.state?.newRequestList.filter(
      (item) => item?.['data-meta-instanceID'] === paramLocation?.state?.uuid,
    ),
  );    } else {
    setNewRequestList([]);
  }}, [paramLocation?.state?.newRequestList]);

  useEffect(() => {
    if (newRequestList) {
      setTableData(
        newRequestList?.map((item, key) => {
          if (item?.['data-meta-instanceID'] === history.location?.pathname.split('/')[2]) {
            return {
              name:
                item?.['data-farmer-informatiom-farmer-name'] !== ' '
                  ? item?.['data-farmer-informatiom-farmer-name']
                    ? item?.['data-farmer-informatiom-farmer-name']
                    : 'N/A'
                  : 'N/A',
              // farmerId:item?.[""]
              phoneNo:
                item?.['data-sub_section-Telephone-No'] !== ' '
                  ? item?.['data-sub_section-Telephone-No']
                    ? item?.['data-sub_section-Telephone-No']
                    : 'N/A'
                  : 'N/A',
              district:
                item?.['data-farmer-informatiom-District'] !== ' ' &&
                item?.['data-farmer-informatiom-District']
                  ? item?.['data-farmer-informatiom-District']
                  : ' ',
              dsDivision:
                item?.['data-farmer-informatiom-DS-division'] !== ' '
                  ? item?.['data-farmer-informatiom-DS-division']
                    ? item?.['data-farmer-informatiom-DS-division']
                    : 'N/A'
                  : ' ',
              gnDivision:
                item?.['data-farmer-informatiom-GN-division'] !== ' '
                  ? item?.['data-farmer-informatiom-GN-division']
                    ? item?.['data-farmer-informatiom-GN-division']
                    : 'N/A'
                  : 'N/A',
              id:
                item?.['data-meta-instanceID'] !== ' '
                  ? item?.['data-meta-instanceID']
                    ? item?.['data-meta-instanceID']
                    : 'N/A'
                  : 'N/A',
              gender: item?.['data-farmer-informatiom1-Gender-of-farmer'],
              houseHoldGeoPoint: item?.['data-land-crops-information-Location-of-household'],
              schoolEducation: item?.['data-farmer-informatiom1-School-education'],
              numberOfMembersInHouseHold:
                item?.['data-household-farming-No-of-members-in-the-household'],
              relationshipToTheHouseHold: item?.['data-If-no-relationship-to-the-household-head'],
              typeOfFarmer: item?.['data-household-farming-Type-of-farmer'],
              age: item?.['data-farmer-informatiom1-Age'],
              numberOfMembersInvolvedInfarming:
                item?.['data-household-farming-No-of-members-involved-in-farming'],
              farmerIsTheHouseHoldHead: item?.['data-relationship-to-the-household-head'],
              ethnicity: item?.['data-farmer-informatiom1-Ethnicity'],
              numberOfDependentMembers:
                item?.['data-household-farming-No-of-dependent-family-members'],
              cultivatedLandProperty:
                item?.['data-cultivatable-land-with-property-rights-certificate'],
              landPropertyRights:
                item?.['data-If-yes-what-is-the-area-of-land-with-property-rights'],
              landWithOutLegalProperty:
                item?.[
                  'data-land-crops-information-What-is-the-land-area-cultivated-without-a-legal-ownership-documents'
                ],
              typesOfLandCultivating:
                item?.['data-land-crops-information-What-types-of-crops-do-you-cultivating'],
              sourceOfWaterCultivating:
                item?.['data-land-crops-information-What-are-the-sources-of-water-for-cultivation'],
              importantRiskFactorsinagriculture:
                item?.[
                  'data-land-crops-information-What-are-the-three-most-important-risk-factors-that-you-face-in-agriculture'
                ],

              photo1: item?.['data-farmer-informatiom-NIC-number'],
              photo2: item?.['data-sub-topic-information-Photo-1'],
              photo3: item?.['data-sub-topic-information-Photo-2'],
              photo4: item?.['data-sub-topic-information-Photo-3'],
            };
          } else return;
        }),
      );
    }
  }, [newRequestList]);
  


  return (
    <Container fluid>
      <div className="right-img-wrapper api-key-list">
        <Row>
          {/* <Col>
                <h1>Survey Details</h1>
                </Col> */}
          <Col xs="12" sm="12" md="12" lg="12" className="subheader-manage-user">
            {/* <img
              src={RightArrow}
              alt="back-arrow"
              className="manage-user-header-back-button"
              onClick={() => history.push('/manageFieldData?Component1')}
            /> */}

            <span
              style={{
                float: 'left',
                marginLeft: '10px',
                borderRadius: '700px',
                border: '1px solid #ada6a6',
                height: '43px',
                width: '43px',
                fontSize: '25px',
                color: '#595252',
                fontWeight: 'bold',
                marginTop: '2.5%',
              }}
              className="list-tl-st"
            >
              {/* <img
                      src={RightArrow}
                      className="cursor-ponter"
                      onClick={() => history.goBack()}
                      alt='back-bottom'
                    /> */}
              <BsArrowLeft
                className="cursor-ponter"
                //onClick={() => history.goBack()}
                onClick={() => history.push('/manageFieldData?Component1&fromBack=true' + '&date=' + paramLocation?.state?.selectedDate)}
                style={{ margin: 'auto' }}
              />
            </span>

            <h3 className="heading-manage-user manage-user-heading-mt">
              {/* {t('MANAGE_USER.MANAGE_USERS')} */}
              Farmer Information
            </h3>
          </Col>
          {nextProps?.isFieldDataListDataRequesting && <Loader />}

          <Col style={{ marginLeft: '4%', marginRight: '4%' }}>
            <form action="/action_page.php">
              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  General Information
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Farmer name
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Farmer name */}
                          {tableData[0]?.name!==" " ?tableData[0]?.name ? tableData[0]?.name : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    {/* </Col> */}
                    {/* <Col md={4} style={{ overflowWrap: 'break-word' }}> */}
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        District
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* District */}
                          {tableData[0]?.district!==" " ?tableData[0]?.district ? tableData[0]?.district : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}

                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        School education
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* 11 Years */}
                          {tableData[0]?.schoolEducation!==" " ?tableData[0]?.schoolEducation ? tableData[0]?.schoolEducation : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Relation to the household head
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Brother */}
                          {tableData[0]?.relationshipToTheHouseHold
                            ? tableData[0]?.relationshipToTheHouseHold !== ' '
                              ? tableData[0]?.relationshipToTheHouseHold.split("-").join(" ")
                              : "N/A"
                            : "N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        No. of members involved in farming
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* 03 */}
                          {tableData[0]?.numberOfMembersInvolvedInfarming!==" " ?tableData[0]?.numberOfMembersInvolvedInfarming
                            ? tableData[0]?.numberOfMembersInvolvedInfarming
                            : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        No. of dependent family members
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* 03 */}
                          {tableData[0]?.numberOfDependentMembers!==" " ?tableData[0]?.numberOfDependentMembers
                            ? tableData[0]?.numberOfDependentMembers
                            : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        DS Division
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* DS Division */}
                          {tableData[0]?.dsDivision!==" " ?tableData[0]?.dsDivision ? tableData[0]?.dsDivision : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}

                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Household geopoint
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Household geopoint */}
                          {tableData[0]?.houseHoldGeoPoint
                            ? tableData[0]?.houseHoldGeoPoint
                              ? parseFloat(tableData[0]?.houseHoldGeoPoint.split(',')[0]).toFixed(
                                  2,
                                ) +
                                ',' +
                                parseFloat(tableData[0]?.houseHoldGeoPoint.split(',')[1]).toFixed(2)
                              : ''
                            : <br/>}
                        </span>
                      </div>
                    </FormGroup>

                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        No. of members in the household
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* 05 */}
                          {tableData[0]?.numberOfMembersInHouseHold!==" " ?tableData[0]?.numberOfMembersInHouseHold
                            ? tableData[0]?.numberOfMembersInHouseHold
                            : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Type of farmer
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Full time */}
                          {tableData[0]?.typeOfFarmer!==" " ?tableData[0]?.typeOfFarmer ? tableData[0]?.typeOfFarmer : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Responding farmer is the household head
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* No */}
                          {tableData[0]?.farmerIsTheHouseHoldHead!==" " ?tableData[0]?.farmerIsTheHouseHoldHead
                            ? tableData[0]?.farmerIsTheHouseHoldHead
                            : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Gender
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Gender */}
                          {tableData[0]?.gender!==" " ?tableData[0]?.gender ? tableData[0]?.gender : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    {/* </Col> */}
                    {/* <Col md={4} style={{ overflowWrap: 'break-word' }}> */}
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        GN Division
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* GN Division */}
                          {tableData[0]?.gnDivision!==" " ?tableData[0]?.gnDivision ? tableData[0]?.gnDivision : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}

                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Age
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* 43 */}
                          {tableData[0]?.age!==" " ? tableData[0]?.age ? tableData[0]?.age : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Ethnicity
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Tamil */}
                          {tableData[0]?.ethnicity!==" " ?tableData[0]?.ethnicity ? tableData[0]?.ethnicity : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                  </Col>

                  <Col xs={3}>
                    <div style={{ marginLeft: '15px' }}>
                      <img src={Image1} />
                      &nbsp;
                      {tableData[0]?.photo1 != ' ' ?
                      (<a
                        href={tableData[0]?.photo1 ? tableData[0]?.photo1 : ''}
                        target="_blank"
                        style={{ color: '#363636' }}
                      >
                        NIC Number
                        {console.log(tableData[0]?.photo1 != '')}
                      </a>) :("NIC Number")}
                      <br />

                      <img src={Image1} />
                      &nbsp;{tableData[0]?.photo2 != ' '?
                      <a
                        href={tableData[0]?.photo2 ? tableData[0]?.photo2 : ''}
                        target="_blank"
                        style={{ color: '#363636' }}
                      >
                        Homestead
                      </a> : "Homestead" }
                      <br />

                      <img src={Image1} />
                      &nbsp;{tableData[0]?.photo3 != ' '?
                      <a
                        href={tableData[0]?.photo3 ? tableData[0]?.photo3 : ''}
                        target="_blank"
                        style={{ color: '#363636' }}
                      >
                        Homestead/farm
                        {console.log(tableData[0]?.photo3 != '')}
                      </a> : "Homestead/farm" }
                      <br />

                      <img src={Image1} />
                      &nbsp;
                      {tableData[0]?.photo4 != ' ' ?
                      <a
                        href={tableData[0]?.photo4 ? tableData[0]?.photo4 : ''}
                        target="_blank"
                        style={{ color: '#363636' }}
                      >
                        Homestead/field
                      </a> : "Homestead/field"}
                    </div>
                  </Col>
                </Row>
              </fieldset>

              <br />

              <fieldset className="border rounded-3 p-3" style={{ marginBottom: '30px' }}>
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  Crop Land Information
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  <Col md={6} style={{ overflowWrap: 'break-word' }}>
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      Have cultivatable land with property rights certificate/permit/legal documents{' '}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      If yes, mention the area of property rights{' '}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      If yes, mention the area of property rights{' '}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      If yes, mention the area of property rights
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      The sources of water for cultivation
                    </p>
                    <p style={{ color: '#7C8EA0', fontWeight: 'bold', marginLeft: '17px' }}>
                      The three most important risk factors that you face in agriculture during the
                      last 5 years
                    </p>
                  </Col>

                  <Col md={6} style={{ overflowWrap: 'break-word' }}>
                    <p style={{ color: '#363636', fontWeight: 'bold', marginLeft: '17px' }}>
                      {/* Yes */}
                      {tableData[0]?.cultivatedLandProperty!==" " ?tableData[0]?.cultivatedLandProperty
                        ? tableData[0]?.cultivatedLandProperty
                        : 'N/A':"N/A"}
                    </p>

                    <p style={{ color: '#363636', fontWeight: 'bold', marginLeft: '17px' }}>
                      {/* Yes */}
                      {tableData[0]?.landPropertyRights!==" " ?tableData[0]?.landPropertyRights ? tableData[0]?.landPropertyRights : 'N/A':"N/A"}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#363636', fontWeight: 'bold', marginLeft: '17px' }}>
                      {/* 2 acres{' '} */}
                      {tableData[0]?.landWithOutLegalProperty!==" " ?tableData[0]?.landWithOutLegalProperty
                        ? tableData[0]?.landWithOutLegalProperty
                        : 'N/A':"N/A"}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#363636', fontWeight: 'bold', marginLeft: '17px' }}>
                      {/* 1 acres{' '} */}
                      {tableData[0]?.typesOfLandCultivating!==" " ?tableData[0]?.typesOfLandCultivating
                        ? tableData[0]?.typesOfLandCultivating
                        : 'N/A':"N/A"}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#363636', fontWeight: 'bold', marginLeft: '17px' }}>
                      {/* Paddy */}
                      {tableData[0]?.sourceOfWaterCultivating!==" " ?tableData[0]?.sourceOfWaterCultivating
                        ? tableData[0]?.sourceOfWaterCultivating
                        : 'N/A':"N/A"}
                    </p>

                    {/* <br/> */}
                    <p style={{ color: '#363636', fontWeight: 'bold', marginLeft: '17px' }}>
                      {/* Irrigated by major irrigation */}
                      {tableData[0]?.importantRiskFactorsinagriculture!==" " ?tableData[0]?.importantRiskFactorsinagriculture
                        ? tableData[0]?.importantRiskFactorsinagriculture.split("-").join(" ")
                        : 'N/A':"N/A"}
                    </p>

                    {/* {tableData[0]?.importantRiskFactorsinagriculture?tableData[0]?.importantRiskFactorsinagriculture:""} */}
                  </Col>
                </Row>
              </fieldset>
            </form>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default SurveyDetails;
