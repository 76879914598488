import React, { useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  LayersControl,
  LayerGroup,
  Marker,
  Popup,
  useMap,
  useMapEvents,
  ZoomControl,
} from 'react-leaflet';
import L from 'leaflet';
import _ from 'lodash';
import axios from 'axios';
import myConstClass from '../../../Constants';
import { StreamflowModal } from './StreamflowModal';
// import './streamflowmodal.css';
// L.Icon.Default.imagePath = 'https://unpkg.com/leaflet@1.5.0/dist/images/';
import DrawShape from './DrawShape';
import { REACT_APP_API_URL } from '../../../Components/constants';

const center = [7.8731, 80.7718];
const zoom = 8;
const endpoint2 = `${REACT_APP_API_URL}/water-resource`;

function AddMarkerToClick() {
  const [markers, setMarkers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const map = useMapEvents({
    click(e) {
      const newMarker = e?.latlng;
      setMarkers([newMarker]);
      setShowModal(true);
    },
  });
  const latestData = markers?.length && markers[markers.length - 1];
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      {showModal && latestData && (
        <StreamflowModal event={latestData} modal={showModal} toggle={toggleModal} />
      )}
      {markers.map((marker) => (
        <Marker position={marker}>
          <Popup>Marker is at {marker}</Popup>
        </Marker>
      ))}
    </>
  );
}

export default function Maps() {
  const [mapRefState, setMapRefState] = useState(null);
  const [layersArray, setLayersArray] = useState([]);
  const mapRef = useRef(null);
  const layerRef = useRef([]);
  const setMapRef = (mapInstance) => {
    mapRef.current = mapInstance;
    setMapRefState(mapRef);
  };
  
  setTimeout(() => {
    if (document.getElementsByTagName('body')) {
      if (document.getElementsByTagName('body')[0].children) {
        Object.values(document.getElementsByTagName('body')[0].children).forEach(data => {
          if (data.toString().includes('HTMLIFrameElement')) {
            data.style = 'display: none !important;';
          }
        });
      }
    }
  }, 4000);

  useEffect(() => {
    axios
      .get(`${endpoint2}/geoglows/wms_drainage`)
      .then(function (response) {
        layerRef.current.push({
          layer: response.data.result.map_url,
          name: 'Drainage',
        });
        setLayersArray((preArray) => [
          ...preArray,
          {
            layer: response.data.result.map_url,
            name: 'Drainage',
          },
        ]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });

    axios
      .get(`${endpoint2}/geoglows/wms_catchment`)
      .then(function (response) {
        layerRef.current.push({
          layer: response.data.result.map_url,
          name: 'Catchment',
        });
        setLayersArray((preArray) => [
          ...preArray,
          {
            layer: response.data.result.map_url,
            name: 'Catchment',
          },
        ]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  // const handleLayers = () => {
  //   return (
  //     <>
  //       {/* {layerRef.current.length && (
  //         <LayersControl position='topright'>
  //           {layerRef.current.map((ref, index) => {
  //             return (
  //               <LayersControl.Overlay checked={index === 0} name={ref.name}>
  //                 <LayerGroup ref={ref}>
  //                   <TileLayer url={ref.layer} id={ref.name} />
  //                 </LayerGroup>
  //               </LayersControl.Overlay>
  //             );
  //           })}
  //         </LayersControl>
  //       )} */}

  //     </>
  //   );
  // };

  const [cordinateAndZoom, setCordinateAndZoom] = useState({
    cordinates: [7.6237, 80.6966],
    zoom: 8,
  });
  // function to change the map attributes inside the map container
  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            cordinates: mapEvents.getCenter(),
            zoom: mapEvents.getZoom(),
          };
        });
      },
      dragend: () => {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            zoom: mapEvents.getZoom(),
            cordinates: mapEvents.getCenter(),
          };
        });
      },
    });

    const map = useMap();
    if(!_.isEmpty(map) && !_.isEmpty(map?._layers)){
      setInterval(function () {
        map?.invalidateSize();
      }, 500);
    }
    map?.setView(center, zoom);
    return null;
  }

  return (
      <MapContainer
        center={cordinateAndZoom.cordinates}
        id="drought-leaflet-map"
        zoom={zoom}
        className="geoglows-map-container"
        zoomControl={false}
        scrollWheelZoom={true}
        whenCreated={setMapRef}
      >
        <ChangeView center={cordinateAndZoom.cordinates} zoom={cordinateAndZoom.zoom} />
        <ZoomControl position="topright" className="map-zoom-control" />
        <TileLayer
          // attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          // url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          url={myConstClass.TITLE_LAYER_URL}
        />
        {/* <AddMarkerToClick /> */}
        <DrawShape />
        {layersArray && layersArray.length && layersArray.length && (
          <LayersControl position="topright">
            {layersArray.map((ref, index) => {
              return (
                <LayersControl.Overlay checked={index === 0} name={ref.name}>
                  <LayerGroup>
                    <TileLayer url={ref.layer} id={ref.name} />
                  </LayerGroup>
                </LayersControl.Overlay>
              );
            })}
          </LayersControl>
        )}
      </MapContainer>
  );
}
