import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Row,
  Col,
  Input,
  Label,
  Collapse,
} from 'reactstrap';
import moment from 'moment';
import Button from 'reactstrap-button-loader';
import { useTranslation } from 'react-multi-lang';
import { isSuperAdmin } from '../../Components/common/utils';
import PropTypes from 'prop-types';
import {
  userPermissionsRequest,
  userPermissionsByIdRequest,
  updatePermissionsRequest,
  userPermissionsSuccess,
  userPermissionsByIdSuccess,
  updatePermissionsSuccess,
} from '../../redux/manage-user/manageUserAction';
import { loginDetailrequest } from '../../redux/login/loginActions';
import ReasonModal from './ReasonModal';
import ConfirmationModal from './Confirmation';
import { DISPLAY_DATE_FORMAT } from '../../Components/constants/index';
import Loader from '../../Components/common/loader';

const ClientDetailmodal = (props) => {
  const {
    modalOpen,
    handleModalToggle,
    req_data,
    handleLatestData,
    loggedUserData,
    activeTab,
    isUserByIdRequesting,
  } = props;
  const status_options = ['select', 'approve', 'reject'];
  const status_options1 = ['select', 're-Activate', 'delete'];
  const [subArray, setSubArray] = useState([]);
  const [modal, setModal] = useState(modalOpen);
  const [permissionsList, setPermissionsList] = useState();
  const [selectedParentCategoryName, setSelectedParentCategoryName] = useState();
  const [groupNamesList, setGroupNamesList] = useState([]);
  const [newList, setNewList] = useState([]);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [isApproveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalData, setRejectModalData] = useState();
  const [approveModalData, setApproveModalData] = useState();
  const [selectedAll, setSelectedAll] = useState(false);
  const [allowedPermission, setAllowedPermission] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [preSelectedPermissions, setPreSelectedPermissions] = useState([]);
  const [isViewChecked, setIsViewChecked] = useState(false);
  const [status, setStatus] = useState();
  const [status1, setStatus1] = useState();
  // const [username, setUsername] = useState('');
  const [reactivateClicked, setReactivateClicked] = useState(false);
  const [permissionUpdated, setPermissionsUpdated] = useState(true);

  const dispatch = useDispatch();
  const t = useTranslation();
  const nextProps = useSelector((state) => ({
    permissionsData: state.UserList.userPermissionsData || null,
    userPermissionsByIdData: state.UserList.userPermissionsByIdData || null,
    updatePermissionsData: state.UserList.updatePermissionsData,
    isUpdatePermissionsRequesting: state.UserList.isUpdatePermissionsRequesting,
    loginDetailData: state.Login.loginDetailData || null,
  }));

  const handleReactivateModalToggle = () => {
    setReactivateClicked(false);
  };
  useEffect(() => {
    if (nextProps.updatePermissionsData) {
      const {
        data: { result },
      } = nextProps.updatePermissionsData;
      if (result) {
        setModal(false);
        handleModalToggle(false);
        //handleReset();
        dispatch(loginDetailrequest());
        setTimeout(() => {
          handleLatestData(activeTab);
        }, 1000);
      }
    }
  }, [nextProps.updatePermissionsData]);

  useEffect(() => {
    if (nextProps.loginDetailData) {
      const {
        data: { result },
      } = nextProps.loginDetailData;

      // if (result?.user) {
      //   setUsername(result?.user?.email);
      // }

      let a = [];
      if (result?.user_permissions) {
        result?.user_permissions?.map((i) => {
          return a.push(i.codename);
        });
        setAllowedPermission(a);
      }
    }
  }, [nextProps.loginDetailData]);

  useEffect(() => {
    if (loggedUserData?.length) {
      let a = [];
      loggedUserData.map((i) => {
        return a.push(i.codename);
      });
      setAllowedPermission(a);
    }
  }, [loggedUserData]);

  useEffect(() => {
    return () => {
      dispatch(userPermissionsSuccess({}));
      dispatch(userPermissionsByIdSuccess({}));
      dispatch(updatePermissionsSuccess({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (nextProps.userPermissionsByIdData) {
      const {
        data: { result },
      } = nextProps.userPermissionsByIdData;
      if (result) {
        setPreSelectedPermissions(result);
      }
    }
  }, [nextProps.userPermissionsByIdData]);

  useEffect(() => {
    let isUpdated = false;
    if (preSelectedPermissions?.length !== selectedPermissions?.length) {
      isUpdated = true;
    } else {
      let arr = [];
      selectedPermissions?.map((item) => {
        if (preSelectedPermissions?.findIndex((x) => x.id === item) >= 0) {
          arr.push(true);
        } else arr.push(false);
      });
      if (arr.every((i) => i === true)) isUpdated = false;
      else isUpdated = true;
    }
    setPermissionsUpdated(!isUpdated);
  }, [selectedPermissions, preSelectedPermissions]);

  useEffect(() => {
    if (modalOpen) {
      dispatch(userPermissionsRequest());
    }
  }, [dispatch, modalOpen]);

  useEffect(() => {
    if (req_data?.user) {
      if (req_data?.user_profile?.is_pending === true) {
        setStatus('select');
        setStatus1('select');
      } else if (req_data?.user_profile?.is_approve === true) {
        setStatus('approve');
        setStatus1('approve');
      } else if (req_data?.user_profile?.is_reject === true) {
        setStatus('reject');
        setStatus1('reject');
      } else if (req_data?.user_profile?.is_delete === true) {
        setStatus('select');
        setStatus1('delete');
      }
      dispatch(userPermissionsByIdRequest({ id: req_data?.user?.id }));
    }
  }, [req_data, dispatch]);

  useEffect(() => {
    if (nextProps.permissionsData) {
      let a = [];
      if (nextProps.permissionsData?.data) {
        nextProps.permissionsData?.data?.forEach((i) => {
          if (!a.includes(i.group_name)) {
            a.push(i.group_name);
          }
        });
        setGroupNamesList(a);
        setPermissionsList(nextProps.permissionsData?.data);
      }
    }
  }, [nextProps.permissionsData]);

  useEffect(() => {
    let y = [];
    if (groupNamesList && preSelectedPermissions) {
      let a = [];
      preSelectedPermissions?.forEach((i) => {
        a.push(i.id);
      });
      setSelectedPermissions(a);
      groupNamesList?.forEach((i) => {
        let filterList = permissionsList?.filter((item) => item.group_name === i);
        y.push({
          category_name: i,
          list: filterList,
          checked: false,
          dropdownClicked: true,
        });
      });
      y.forEach((i) => {
        let b = [];
        i.list.forEach((item) => {
          if (a.includes(item.permission_id)) {
            b.push(item.permission_id);
          }
        });
        i.parentList = b;
        if (i.parentList.length === i.list.length) {
          i.checked = true;
        }
      });
      setNewList(y);
    }
  }, [groupNamesList, preSelectedPermissions]);

  useEffect(() => {
    let a = [];
    let y = [];
    if (selectedPermissions.length) {
      if (selectedParentCategoryName) {
        let c = newList;
        c = newList?.map((i) => {
          if (selectedParentCategoryName === i.category_name) {
            i?.list?.forEach((x) => {
              y.push(x.permission_id);
              selectedPermissions.forEach((item) => {
                if (x.permission_id === item) {
                  a.push(item);
                }
              });
            });
            let b = a.sort().join(',') === y.sort().join(',');
            i.checked = b;
          }
          return i;
        });
        setNewList(c);
      }
    }
  }, [selectedPermissions, selectedParentCategoryName]);

  const handleSelectAllPermissions = (e) => {
    let a = [];
    let b = [];
    if (!e.target.checked) {
      b = newList.map((data) => {
        data.checked = false;
        return data;
      });
    } else {
      permissionsList.map((i) => {
        a.push(i.permission_id);
      });
      b = newList.map((data) => {
        data.checked = true;
        return data;
      });
    }
    setNewList(b);
    setSelectedPermissions(a);
    setSelectedAll(true);
  };

  useEffect(() => {
    if (selectedPermissions && permissionsList) {
      let a = [];
      let sub_arr = [];
      permissionsList?.forEach((i) => {
        a.push(i.permission_id);
      });
      sub_arr = permissionsList.filter((i) => i.permission_name !== 'View User');
      setSubArray(sub_arr);
      let b = selectedPermissions?.sort().join(',') === a.sort().join(',');
      if (b === true) {
        setSelectedAll(true);
      } else {
        setSelectedAll(false);
      }
    }
  }, [selectedPermissions, permissionsList]);

  const handleReactivate = () => {
    let data;
    data = {
      id: req_data?.user?.id,
      re_activate: 'True',
    };
    setApproveModalData(data);
    setApproveModalOpen(true);
    setReactivateClicked(true);
  };

  const viewtoggle = (value) => {
    if (value === 'update') {
      let data = {
        user_id: '',
        permission_ids: '',
      };
      data.permission_ids = selectedPermissions;
      data.user_id = req_data?.user?.id;
      dispatch(updatePermissionsRequest(data));
    } else {
      setModal(false);
      handleModalToggle(false);
    }
  };

  const handleStatusChange = (value) => {
    setStatus(value);

    if (value === 'approve') {
      let data = { id: req_data?.user?.id, is_approve: 'True' };
      setApproveModalOpen(true);
      setApproveModalData(data);
    } else if (value === 'reject') {
      setRejectModalOpen(true);
      setRejectModalData({ id: req_data?.user?.id, is_reject: 'True' });
    }
  };

  const handleStatusReactivateChange = (value) => {
    setStatus1(value);
    if (value === 're-Activate') {
      setApproveModalOpen(true);
      let data = { id: req_data?.user?.id, re_activate: 'True' };

      setApproveModalData(data);
      setReactivateClicked(true);
    }
  };

  const handleApprovedModalToggle = (value) => {
    setApproveModalOpen(value);
    setModal(false);
    handleModalToggle(false);
  };

  const handleRejectModal = (value) => {
    setRejectModalOpen(value);
  };

  const latestDataHandler = (value) => {
    handleLatestData(value);
  };

  const handleRejectFromViewModal = () => {
    setRejectModalOpen(true);
    setRejectModalData({ id: req_data?.user?.id, is_reject: 'True' });
  };

  const handleApproveFromViewModal = () => {
    let data = { id: req_data?.user?.id, is_approve: 'True' };
    setApproveModalOpen(true);
    setApproveModalData(data);
    // if(selectedPermissions.length){
    //   let data = {
    //     user_id: "",
    //     permission_ids: ""
    //   };
    //   data.permission_ids = selectedPermissions;
    //   data.user_id = req_data?.user?.id;
    //   dispatch(updatePermissionsRequest(data))
    // }
  };

  const handleParentOnchangePermissions = (e, i) => {
    let a = selectedPermissions;
    let b;
    if (e.target.checked) {
      i.list.forEach((item) => {
        if (!selectedPermissions.includes(item.permission_id)) {
          a.push(item.permission_id);
        }
      });
      b = newList.map((item) => {
        if (item.category_name === i.category_name) {
          // i.dropdownClicked = i.dropdownClicked;
          i.checked = true;
        }
        return item;
      });
    } else {
      b = newList.map((item) => {
        if (item.category_name === i.category_name) {
          a = selectedPermissions;
          i.list.map((data) => {
            a = a.filter((y) => {
              if (y !== data.permission_id) {
                return y;
              }
            });
          });
          //i.dropdownClicked = i.dropdownClicked;
          i.checked = false;
        }
        return item;
      });
    }
    setNewList(b);
    setSelectedPermissions(JSON.parse(JSON.stringify(a)));
  };

  const handleChildOnchangePermissions = (e, item) => {
    let a = [];
    a = selectedPermissions;
    setSelectedParentCategoryName(item.group_name);
    if (e.target.checked) {
      if (selectedPermissions.includes(item.permission_id)) {
        let b = [];
        b = selectedPermissions;
        setSelectedPermissions(b.filter((i) => item.permission_id !== i));
      } else {
        // let p = permissionsList.filter((j) => j.code_name === "view_user");
        // p.forEach((x) => {
        //   if(!selectedPermissions.includes(x.permission_id)){
        //     a.push(x.permission_id)
        //   }
        // })
        //permissionsList.filter((j) => )
        //subArrayif
        if (subArray.some((r) => r.permission_id === item.permission_id)) {
          a.push(item.permission_id);
          let p = permissionsList.filter((j) => j.code_name === 'view_user');
          p.forEach((x) => {
            if (!selectedPermissions.includes(x.permission_id)) {
              a.push(x.permission_id);
            }
          });
        } else {
          a.push(item.permission_id);
        }
        setSelectedPermissions(JSON.parse(JSON.stringify(a)));
      }
    } else {
      if (selectedPermissions.includes(item.permission_id)) {
        let b = [];
        b = selectedPermissions;
        setSelectedPermissions(b.filter((i) => item.permission_id !== i));
        let x = newList;
        x.forEach((data) => {
          if (item.group_name === data.category_name) {
            data.checked = false;
          }
        });
        setNewList(JSON.parse(JSON.stringify(x)));
      }
    }
  };

  const handleDropdownClick = (item) => {
    let a = newList.map((i) => {
      if (i.category_name === item.category_name) {
        if (i.dropdownClicked) {
          i.dropdownClicked = false;
        } else {
          i.dropdownClicked = true;
        }
      }
      return i;
    });
    setNewList(a);
  };

  const isAny = () => {
    return subArray.some((r) => selectedPermissions.includes(r.permission_id));
  };

  // const handleSameUser = () => {
  //   let cookies = document.cookie.split(';');
  //   let array = cookies.map(item => item.split('='));
  //   let object = Object.assign(...array.map(([key, value]) => ({ [key]: value })));

  //   if (object[' loggedin_user_details'] === username) {
  //     return true;
  //   }
  // };

  return (
    <>
      <div className="client-modal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className={
            activeTab === 'new' ? 'new-modal-view-details modal-xl' : 'modal-view-details modal-xl'
          }
          backdrop="static"
        >
          <ModalHeader className="client-detail-header" toggle={viewtoggle}>
            {t('MANAGE_USER.USER_DETAILS')}
          </ModalHeader>
          {(isUserByIdRequesting ||
            nextProps.isUserPermissionsRequesting ||
            nextProps.isUserPermissionsByIdDataRequesting) && <Loader />}
          <ModalBody>
            <Table>
              <tr className="col-lg-12">
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('MANAGE_USER.NAME')}</h4>
                  <p>
                    {req_data && req_data?.user_profile?.name
                      ? req_data?.user_profile?.name
                      : 'N/A'}
                  </p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('MANAGE_USER.EMAIL')}</h4>
                  <p>{req_data && req_data?.user?.email ? req_data?.user?.email : 'N/A'}</p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('MANAGE_USER.MOBILE')}</h4>
                  <p>
                    {req_data && req_data?.user_profile?.mobile_number
                      ? req_data?.user_profile?.mobile_number
                      : 'N/A'}
                  </p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('MANAGE_USER.ORGANIZATION')}</h4>
                  {/* //// */}
                  <p style={{ wordBreak: 'break-all' }}>
                    {req_data && req_data?.user_profile?.organization
                      ? req_data?.user_profile?.organization
                      : 'N/A'}
                  </p>
                </td>
              </tr>
              <tr className="col-lg-12">
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('MANAGE_USER.DESIGNATION')}</h4>
                  <p style={{ wordBreak: 'break-all' }}>
                    {req_data && req_data?.user_profile?.designation
                      ? req_data?.user_profile?.designation
                      : 'N/A'}
                  </p>
                </td>
                {/* <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('MANAGE_USER.DATE_OF_REGISTRATION')}</h4>
                  <p>
                    {req_data && req_data?.user_profile?.created_date
                      ? moment(req_data?.user_profile?.created_date).format(DISPLAY_DATE_FORMAT)
                      : 'N/A'}
                  </p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('MANAGE_USER.LAST_LOGIN')}</h4>
                  <p>{req_data && req_data.last_login ? req_data.last_login : 'N/A'}</p>
                </td> */}
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('MANAGE_USER.COUNTRY')}</h4>
                  <p>
                    {req_data && req_data?.user_profile?.country
                      ? req_data?.user_profile?.country
                      : 'N/A'}
                  </p>
                </td>
              </tr>
            </Table>
            <div className="col-lg-12 col-md-12 col-sm-12 client-deatils purpose-section">
              <h4>{t('MANAGE_USER.PURPOSE')}</h4>
              <p style={{ whiteSpace: 'initial', wordWrap: 'break-word' }}>
                {req_data && req_data?.user_profile?.purpose
                  ? req_data?.user_profile?.purpose
                  : 'N/A'}
              </p>
            </div>

            {activeTab !== 'new' && (
              <>
                <Row lg="12" className="view-detail-border" />
                <div className="mt-4 view-detail-select p-3 pb-4">
                  <Row className="col-lg-12 view-row">
                    <Col lg="6">
                      <h4 className="view-row-heading mb-4">{t('MANAGE_USER.PERMISSIONS')}</h4>
                    </Col>
                    <Col lg="6">
                      <div>
                        <Label check className="view-select-option">
                          <Input
                            type="checkbox"
                            disabled={
                              activeTab === 'reject' ||
                              activeTab === 'delete' ||
                              !(allowedPermission?.includes('assign_permission') || isSuperAdmin())
                                ? // (isSuperAdmin() ? false :  true) :
                                  true
                                : false
                            }
                            onClick={(e) => handleSelectAllPermissions(e)}
                            checked={selectedAll}
                          ></Input>
                          {t('MANAGE_USER.SELECT_ALL')}
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="col-lg-12 view-row">
                    {newList &&
                      newList.map((i) => {
                        return (
                          <Col lg="4">
                            <Label className="md-filter-option dropdown-category-name">
                              <Input
                                type="checkbox"
                                checked={i?.checked}
                                disabled={
                                  activeTab === 'reject' ||
                                  activeTab === 'delete' ||
                                  !(
                                    allowedPermission?.includes('assign_permission') ||
                                    isSuperAdmin()
                                  )
                                    ? // (isSuperAdmin() ? false :  true) :
                                      true
                                    : false
                                }
                                onClick={(e) => handleParentOnchangePermissions(e, i)}
                              ></Input>
                              {i?.category_name}
                            </Label>
                            <i
                              class="fa fa-sort-down manage-user-dropdown-icon"
                              onClick={() => handleDropdownClick(i)}
                            ></i>
                            <Collapse isOpen={i?.dropdownClicked}>
                              {i.list.map((item) => {
                                return (
                                  <div className="sub-permission-section">
                                    <Label className="md-filter-option sub-permission-name checkbox-container">
                                      <Input
                                        type="checkbox"
                                        checked={selectedPermissions.includes(item.permission_id)}
                                        onClick={(e) => handleChildOnchangePermissions(e, item)}
                                        disabled={
                                          activeTab === 'reject' ||
                                          activeTab === 'delete' ||
                                          !(
                                            allowedPermission?.includes('assign_permission') ||
                                            isSuperAdmin()
                                          )
                                            ? // (isSuperAdmin() ?
                                              // //selectedPermissions.includes()
                                              // false :  true) :
                                              true
                                            : isAny()
                                            ? item.permission_name === 'View User'
                                              ? true
                                              : false
                                            : false
                                        }
                                      ></Input>
                                      {item.permission_name}
                                    </Label>
                                  </div>
                                );
                              })}
                            </Collapse>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter className="modal-viewdeatil-footer">
            <Row>
              <Col sm="6" md="6" lg="6" className="d-grid">
              </Col>
              <Col sm="6" md="6" lg="6" className="d-flex justify-content-end align-items-end">
                {/* {!fromEditDetails ? (
              <>
                 <button onClick={() => viewtoggle('cancel')} className="cancel-button right-align">
                  {t('MANAGE_USER.CANCEL')}
                </button>{' '}
              </>)
                :
                (allowedPermission?.includes('assign_permission') || isSuperAdmin()) &&
                <>
                <span className='text-center'>
                 <button onClick={() => viewtoggle('cancel')} className="cancel-button">
                  {t('MANAGE_USER.CANCEL')}
                </button>{' '} 
                 <button  className="update-button" onClick={() => viewtoggle('update')} loading={nextProps.isUpdatePermissionsRequesting} >
                {t('MANAGE_USER.UPDATE')}
              </button>
              </span>
              </>
              }  */}
                {activeTab === 'new' &&
                (allowedPermission?.includes('change_user') || isSuperAdmin()) ? (
                  <>
                    <span className="text-center">
                      <button
                        onClick={() => handleRejectFromViewModal()}
                        className="reject-button"
                        handleRejectFromViewModal
                      >
                        {t('MANAGE_USER.REJECT')}
                      </button>{' '}
                      {req_data?.user?.is_verified ? (
                        <button
                          className="approve-button"
                          onClick={() => handleApproveFromViewModal()}
                          loading={nextProps.isUpdatePermissionsRequesting}
                        >
                          {t('MANAGE_USER.APPROVE')}
                        </button>
                      ) : (
                        ''
                      )}
                    </span>
                  </>
                ) : (allowedPermission?.includes('assign_permission') || isSuperAdmin()) &&
                  activeTab !== 'delete' ? (
                  <>
                    <span className="text-center">
                      <Button onClick={() => viewtoggle('cancel')} className="cancel-button">
                        {t('MANAGE_USER.CANCEL')}
                      </Button>{' '}
                      {(allowedPermission?.includes('assign_permission') || isSuperAdmin()) &&
                        activeTab !== 'new' && (
                          <Button
                            className="update-button"
                            onClick={() => viewtoggle('update')}
                            loading={nextProps.isUpdatePermissionsRequesting}
                            disabled={activeTab === 'reject' || permissionUpdated}
                          >
                            {t('MANAGE_USER.UPDATE')}
                          </Button>
                        )}
                    </span>
                  </>
                ) : (
                  <Button
                    onClick={() => viewtoggle('cancel')}
                    className="cancel-button right-align"
                  >
                    {t('MANAGE_USER.CANCEL')}
                  </Button>
                )}
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
      {isRejectModalOpen && (
        <ReasonModal
          modalData={rejectModalData}
          isOpen={isRejectModalOpen}
          latestDataHandler={latestDataHandler}
          handleRejectModal={handleRejectModal}
          handleModalToggle={handleModalToggle}
        />
      )}
      {isApproveModalOpen && (
        <ConfirmationModal
          fromModal={true}
          activeTab={activeTab}
          modalData={approveModalData}
          isOpen={isApproveModalOpen}
          handleApprovedModalToggle={handleApprovedModalToggle}
          latestDataHandler={latestDataHandler}
          handleReactivateModalToggle={handleReactivateModalToggle}
          reactivateClicked={reactivateClicked}
        />
      )}
    </>
  );
};

export default ClientDetailmodal;
ClientDetailmodal.propTypes = {
  modalOpen: PropTypes.bool,
  handleModalToggle: PropTypes.func,
  req_data: PropTypes.object,
  handleLatestData: PropTypes.func,
  //fromEditDetails: PropTypes.bool,
  activeTab: PropTypes.string,
};
