export const ACCESS_KEY_CONST = {
    GET_ACCESS_KEY_REQUEST: 'GET_ACCESS_KEY_REQUEST',
    GET_ACCESS_KEY_SUCCESS: 'GET_ACCESS_KEY_SUCCESS',
    GET_ACCESS_KEY_FAILED: 'GET_ACCESS_KEY_FAILED',
}

export const GET_ALL_BY_TYPE_CONST = {
    GET_ALL_BY_TYPE_REQUEST: 'GET_ALL_BY_TYPE_REQUEST',
    GET_ALL_BY_TYPE_SUCCESS: 'GET_ALL_BY_TYPE_SUCCESS',
    GET_ALL_BY_TYPE_FAILED: 'GET_ALL_BY_TYPE_FAILED',
}

export const GET_BY_ID_CONST = {
    GET_BY_ID_REQUEST: 'GET_BY_ID_REQUEST',
    GET_BY_ID_SUCCESS: 'GET_BY_ID_SUCCESS',
    GET_BY_ID_FAILED: 'GET_BY_ID_FAILED',
}

export const GET_PARAMETERS_TYPE_CONST = {
    GET_PARAMETERS_TYPE_REQUEST: 'GET_PARAMETERS_TYPE_REQUEST',
    GET_PARAMETERS_TYPE_SUCCESS: 'GET_PARAMETERS_TYPE_SUCCESS',
    GET_PARAMETERS_TYPE_FAILED: 'GET_PARAMETERS_TYPE_FAILED',
}

export const CHANGE_STATUS_CONST = {
    CHANGE_STATUS_CONST_REQUEST: 'CHANGE_STATUS_CONST_REQUEST',
    CHANGE_STATUS_CONST_SUCCESS: 'CHANGE_STATUS_CONST_SUCCESS',
    CHANGE_STATUS_CONST_FAILED: 'CHANGE_STATUS_CONST_FAILED',
}

export const DELETE_CONST = {
    DELETE_CONST_REQUEST: 'DELETE_CONST_REQUEST',
    DELETE_CONST_SUCCESS: 'DELETE_CONST_SUCCESS',
    DELETE_CONST_FAILED: 'DELETE_CONST_FAILED',
};

export const GET_INDICES_CONST = {
    GET_INDICES_REQUEST: 'GET_INDICES_REQUEST',
    GET_INDICES_SUCCESS: 'GET_INDICES_SUCCESS',
    GET_INDICES_FAILED: 'GET_INDICES_FAILED',
};

export const GET_GLDAS_CONST = {
    GET_GLDAS_REQUEST: 'GET_GLDAS_REQUEST',
    GET_GLDAS_SUCCESS: 'GET_GLDAS_SUCCESS',
    GET_GLDAS_FAILED: 'GET_GLDAS_FAILED',
};