import React, { useState, useEffect } from 'react';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import '../../assets/CSS/Common.css';
import '../../assets/CSS/UserGuideSearchContent.css';
import { useDispatch, useSelector } from 'react-redux';
import { searchContentUserGuideRequest, searchContentUserGuideSuccess } from '../../redux/actions';
import _ from 'lodash';
import Loader from '../../Components/common/loader';
import RightArrow from '../../assets/Images/back-button.svg';
import { Row, Col, Card } from 'reactstrap';
import { htmlToText } from '../../Components/common/utils';
import '../../assets/CSS/StaticSidebar.css';
import Footer from '../landing-page/footer';

function UserSearchContentPage() {
  const [searchContent, setSearchContent] = useState([]);
  const dispatch = useDispatch();
  let history = useHistory();
  const {
    searchContentUserGuideData,
    isGetAllUserGuideRequesting,
    isSearchContentUserGuideRequesting,
  } = useSelector(state => ({
    searchContentUserGuideData: state.UserGuide.searchContentUserGuideData,
    isSearchContentUserGuideRequesting: state.UserGuide.isSearchContentUserGuideRequesting,
    isGetAllUserGuideRequesting: state.UserGuide.isGetAllUserGuideRequesting,
  }));
  let search = window.location.search;
  let URLParams = new URLSearchParams(search);
  let searchWord = URLParams.get('q');
  useEffect(() => {
    if (searchWord?.length !== 0) {
      dispatch(searchContentUserGuideRequest({ content: searchWord }));
    }
  }, []);
  useEffect(() => {
    let serchedResults = [];
    setSearchContent([]);
    if (searchContentUserGuideData?.data) {
      const { data } = searchContentUserGuideData?.data;
      if (!_.isEmpty(data) && !_.isEmpty(data?.result)) {
        data.result.map(item => {
          serchedResults.push(item);
        });
        setSearchContent(serchedResults);
      }
    }
  }, [searchContentUserGuideData]);
  // const handleSubTopicListItem = (Id, topicId, subtopicId) => {
  //   if (!_.isEmpty(subtopicId) && !_.isEmpty(topicId)) {
  //     history.push(
  //       `/user-guide-detail-search-content?unique=${Id}&&category=${topicId}&&sub-category=${subtopicId}`,
  //     );
  //   } else {
  //     history.push(`/user-guide-detail-search-content?unique=${Id}&&category=${topicId}`);
  //   }
  // };
  const handleSubTopicListItem = (Id, topicId, subtopicId,subtopic_name, topic_name) => {
    console.log(subtopic_name,'subtopic_name123')
    history.push(`/user-guide-details?topicId=${topicId}&&sub-topic-name=${subtopic_name}&topic_name=${topic_name}`)
};
  const handleManageCropsBackButton = () => {
    history.goBack();
  };

  // return (
  //   <>
  //     <div className="search-content-header">
  //       {isGetAllUserGuideRequesting && <Loader />}
  //       <div className="rendered-container pt-4">
  //         <img
  //           src={RightArrow}
  //           className="right-arrow-icon"
  //           onClick={() => handleManageCropsBackButton()}
  //         />
  //         <span className="searched-results">
  //           {t('USER_GUIDE.SEARCHED_RESULTS')} "{searchWord}"
  //         </span>
  //       </div>

  //       <div className="rendered-container">
  //         {isSearchContentUserGuideRequesting && (
  //           <div className="loader-div">
  //             <Loader />{' '}
  //           </div>
  //         )}
  //         {!isSearchContentUserGuideRequesting &&
  //           _.isEmpty(searchContent) &&
  //           searchContentUserGuideData && (
  //             <center>
  //               <h2> {t('USER_GUIDE.NO_RESULTS')}</h2>
  //             </center>
  //           )}
  //         <Row className="search-content-container">
  //           {searchContent?.map(item => {
  //             return (
  //               <Col lg={3} sm={6} xs={12} className="card-column">
  //                 <div>
  //                   <Card
  //                     className="custom-card-x cb-1 cursor-ponter"
  //                     onClick={() =>
  //                       handleSubTopicListItem(item.id, item.topic_id, item.subtopic_id)
  //                     }
  //                   >
  //                     <div className="content-exceeded">
  //                       <p className="user-guide-icon-text">
  //                         {item?.subtopic_name?.length > 21
  //                           ? item?.subtopic_name?.substring(0, 22) + '...'
  //                           : item?.subtopic_name}
  //                       </p>
  //                       {htmlToText(item.content)?.length > 240 ? (
  //                         <div id="card-description">
  //                           <div className="news-feed-raw-data-section discription-text">
  //                             {htmlToText(item.content).substring(0, 237) + '...'}
  //                           </div>
  //                         </div>
  //                       ) : (
  //                         <div
  //                           className="user-guide-search-content  user-guide-card-content"
  //                           dangerouslySetInnerHTML={{ __html: item.content }}
  //                         />
  //                       )}
  //                     </div>
  //                   </Card>
  //                 </div>
  //               </Col>
  //             );
  //           })}
  //         </Row>
  //       </div>
  //     </div>
  //     <div className="search-content-page-footer">
  //       <Footer publicFooter={false} />
  //     </div>
  //   </>
  // );
  return (
    <>
      <div className="search-content-header">
        {isGetAllUserGuideRequesting && <Loader />}
        <div className="rendered-container pt-4">
          <img
            src={RightArrow}
            className="right-arrow-icon"
            onClick={() => handleManageCropsBackButton()}
          />
          <span className="searched-results">
            {t('USER_GUIDE.SEARCHED_RESULTS')} "{<b>{searchWord.toUpperCase()}</b>}"
          </span>
        </div>

        <div className="rendered-container">
          {isSearchContentUserGuideRequesting && (
            <div className="loader-div">
              <Loader />{' '}
            </div>
          )}
          {!isSearchContentUserGuideRequesting &&
            _.isEmpty(searchContent) &&
            searchContentUserGuideData && (
              <center className="text-result">
                <h2> {t('USER_GUIDE.NO_DATA')}</h2>
              </center>
            )}
          <Row className="search-content-container">
            {searchContent?.map(item => {
              return (
                <Col lg={3} sm={6} xs={12} className="card-column">
                  <div>
                    <Card
                      className="custom-card-x cb-1 cursor-ponter"
                      onClick={() =>
                        handleSubTopicListItem(item.id, item.topic_id, item.subtopic_id,item.subtopic_name ,item.topic_name)
                      }
                    >
                      <div className="content-exceeded">
                        <p className="user-guide-icon-text">
                          {item?.subtopic_name?.length > 21
                            ? item?.subtopic_name?.substring(0, 22) + '...'
                            : item?.subtopic_name}
                        </p>
                        {htmlToText(item.content)?.length > 120 ? (
                          <div id="card-description">
                            <div className="user-guide-raw-data-section discription-text">
                              {htmlToText(item.content).substring(0, 237) + '...'}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="user-guide-search-content  user-guide-card-content"
                            dangerouslySetInnerHTML={{ __html: item.content }}
                          />
                        )}
                      </div>
                    </Card>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <div 
      className={_.isEmpty(searchContent) && 'Footer-search-content'}
        // style={{position:"absolute",bottom:"0",width:"100%"}}
      >
        <Footer publicFooter={false} />
      </div>
    </>
  );

}

export default UserSearchContentPage;
