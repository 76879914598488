/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js';
import _ from 'lodash';
import { chartDateLabels, renderGenericDateFormat } from './utils';
import {
  CHART_LABEL_COLORS,
  DATA_SOURCES,
  ERPAS_PARAMETER_SLUG,
} from '../../Components/constants/index';
import { PlotlyPNGLogo, PlotlyCSVLogo, PlotlyCloseLogo } from '../constants';
import ReactExport from 'react-export-excel';

var icon2 = {
  width: 300,
  height: 500,
  path: 'M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z',
};
var icon3 = {
  name: 'newplotlylogo',
  svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"/></svg>',
};

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const PlotGEEERPASCharts = (props) => {
  const {
    chartData,
    renderCharts,
    dataSource,
    isOpenWeather,
    fileName,
    temporalAggregation,
    selectedParams,
    location,
    region,
  } = props;
  const [dates, setDates] = useState([]);
  const [yOptions, setYOptions] = useState([]);
  const [finalChartData, setFinalChartData] = useState({});
  const ButtonClick = useRef('');
  const [objKeysData, setObjKeysData] = useState([]);
  const [newFileName, setFileName] = useState(
    `${fileName.split('-')[0]}-${fileName.split('-')[1]}`,
  );
  const [csvFileData, setCsvFileData] = useState([]);
  const [erpasParams, setErpasParams] = useState([
    {
      name: 'Precipitation',
      measure: 'kg/m^2',
      visible: true,
      dash: '',
      type: 'bar',
      mode: '',
      value: 'Precipitation',
    },
    {
      name: 'Precipitation climatology',
      measure: 'kg/m^2',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: '',
      value: 'Precipitation climatology',
    },
    {
      name: 'Relative Humidity',
      measure: '%',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: '',
      value: 'Relative',
    },
    {
      name: 'Relative Humidity climatology',
      measure: '%',
      visible: 'legendonly',
      dash: '',
      type: 'scatter',
      mode: '',
      value: 'Relative climatology',
    },
    {
      name: 'Minimum Temperature',
      measure: '°C',
      visible: 'legendonly',
      dash: 'dash',
      type: 'scatter',
      mode: '',
      value: 'Minimum',
    },
    {
      name: 'Maximum Temperature',
      measure: '°C',
      visible: 'legendonly',
      dash: 'dash',
      type: 'scatter',
      mode: '',
      value: 'Maximum',
    },
    {
      name: 'Minimum Temperature climatology',
      measure: '°C',
      visible: 'legendonly',
      dash: 'dash',
      type: 'scatter',
      mode: '',
      value: 'Minimum climatology',
    },
    {
      name: 'Maximum Temperature climatology',
      measure: '°C',
      visible: 'legendonly',
      dash: 'dash',
      type: 'scatter',
      mode: '',
      value: 'Maximum climatology',
    },
  ]);
  useEffect(() => {
    let x = erpasParams?.map((i, index) => {
      let value = i.value.replace(' ', '_');
      if (value === 'Precipitation_climatology') {
        value = 'Rainfall_climatology';
      }
      setFinalChartData((prev) => {
        return {
          ...prev,
          [i.value]: chartData[value],
        };
      });
    });
  }, [chartData]);

  const [layout, updateLayout] = useState({
    title: {
      text: `<b> Data Source : ${dataSource[0]?.name} ${
        dataSource[0]?.id === DATA_SOURCES.IRI.id ? '' : `(${temporalAggregation?.name})`
      } </b>`,
      x: 0.5,
      xanchor: 'center',
    },
    modebardisplay: true,
    width: 1400,
    bargap: 0.9,
    font: {
      size: 12,
      color: 'grey',
    },
    xaxis: {
      domain: [0, 0],
      showline: true,
      // tickangle: 30,
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      // x: 0.3,
      x: 0.5,
      xanchor: 'center',
      y: `${region?.length ? -0.8 : -0.7}`,
    },
  });
  // useEffect(() => {
  //   updateLayout(preValues => ({
  //     ...preValues,
  //     yaxis1: {
  //       title: `${ERPAS_PARAMETER_SLUG[0]?.name} (kg/m^2)`, //for multiple y-axis------
  //       titlefont: { color: '#1f77b4' },
  //       tickfont: { color: '#1f77b4' },
  //       showline: true,
  //     },
  //   }));
  // }, []);
  useEffect(() => {
    if (selectedParams && selectedParams?.weather_forecast_parameter) {
      let reOrderedParams = [];
      erpasParams.forEach((param) => {
        if (param.value === selectedParams?.weather_forecast_parameter.name) {
          param.visible = true;
          reOrderedParams.push(param);
          updateLayout((preValues) => ({
            ...preValues,
            yaxis1: {
              title: `${param?.name} (${param.measure}) ${location ? `(${location})` : ''}`, //for multiple y-axis------
              titlefont: { color: '#1f77b4' },
              tickfont: { color: '#1f77b4' },
              showline: true,
            },
          }));
        }
      });
      if (reOrderedParams.length) {
        erpasParams.forEach((param) => {
          if (param.value !== selectedParams?.weather_forecast_parameter.name) {
            param.visible = 'legendonly';
            reOrderedParams.push(param);
          }
        });
      }
      setErpasParams(reOrderedParams);
    }
  }, [selectedParams]);

  const handleTraces = (chart_data, i, index) => {
    let b = [];
    chart_data[i?.value]?.Timestamp?.map((a) => {
      b.push(renderGenericDateFormat(a));
    });
    const ydata = {
      type: i?.type,
      mode: i?.mode,
      // name: i,
      name: `${i?.name}`,
      x: b,
      units: i?.measure,
      y: chart_data[i?.value]?.data?.map((i) => i.toFixed(2)),
      // fill: 'toself',
      visible: i?.visible,
      // visible: i !== 'Precipitation' ? view[index]?.visible : '',
      line: { color: CHART_LABEL_COLORS[index], dash: i?.dash },
      //
      position: undefined,
    };

    if (i?.value === 'Precipitation') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    } else if (i?.value === 'Precipitation climatology') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    } else if (i?.value === 'Relative') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    } else if (i?.value === 'Relative climatology') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    } else if (i?.value === 'Minimum') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    } else if (i?.value === 'Maximum') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    } else if (i?.value === 'Minimum climatology') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    } else if (i?.value === 'Maximum climatology') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    }
    setDates(b);
    return ydata;
  };
  useEffect(() => {
    if (finalChartData && !isOpenWeather) {
      let options = [];
      erpasParams.map((i, index) => {
        let returned_values = handleTraces(finalChartData, i, index);
        options.push(returned_values);
      });
      setYOptions(options);
    }
  }, [finalChartData]);

  const handleTitle = (i) => {
    if (chartData) {
      return Object.keys(chartData)[i];
    }
  };

  //function for getting active legends for graph then download png/csv format
  const getActiveLegends = (isDownloadCSV, graphData) => {
    if (document.getElementById('erpasId')?.data?.length) {
      const activeLegends = graphData.data?.filter((item) => item.visible === true);
      if (activeLegends.map((data) => data.name)?.length) {
        //setting file name based on active legends
        let renamedFileName = `${fileName.split('-')[0]}${
          fileName.split('-')[1] ? `-${fileName.split('-')[1]}` : ''
        }${
          region?.length
            ? `-point(${region[0]?.latlngs[1].toFixed(2)}-${region[0]?.latlngs[0].toFixed(2)})`
            : ''
        }-${activeLegends.map((data) => data.name).join(' ')}`;
        renamedFileName = renamedFileName.replace(/\s/g, '');
        renamedFileName = renamedFileName.replace(/Maximum/g, 'Max');
        renamedFileName = renamedFileName.replace(/Minimum/g, 'Min');
        setFileName(renamedFileName);
      }
      if (activeLegends?.length) {
        //formatting data for csv file
        const xaixsValue = activeLegends[0].x;
        let data = [];
        xaixsValue.forEach((date, key) => {
          activeLegends.forEach((item, index) => {
            if (index === 0) {
              data.push({ Date: date, [item.name]: item.y[key] });
            } else {
              data[key][item.name] = item.y[key];
            }
          });
        });
        setCsvFileData(data);
        let objKeys = Object.keys(data[0]);
        if (objKeys) {
          setObjKeysData(objKeys);
        }
        if (isDownloadCSV) {
          //downloading csv file
          ButtonClick.current.click();
        } else {
          //downloading png file
          Plotly.downloadImage(graphData, {
            filename: `${fileName.split('-')[0]}${
              fileName.split('-')[1] ? `-${fileName.split('-')[1]}` : ''
            }${
              region?.length
                ? `-point(${region[0]?.latlngs[1].toFixed(2)}-${region[0]?.latlngs[0].toFixed(2)})`
                : ''
            }-${activeLegends.map((data) => data.name).join(' ')}`,
            format: 'png',
            width: graphData._fullLayout.width,
            height: graphData._fullLayout.height,
          });
        }
      }
    }
  };

  let config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true, //by default we are showing
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        click: function (gd) {
          getActiveLegends(false, gd);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        click: function (gd) {
          getActiveLegends(true, gd);
        },
      },
      {
        name: 'Close',
        icon: PlotlyCloseLogo,
        click: function () {
          renderCharts();
        },
      },
    ],
    showTips: false,
  };

  //for multiple y-axis------------------------
  // let cv = 0.15
  // yOptions?.map((a,index) => {
  //    cv = cv+ 0.10
  //   if(index !== 0){
  //     let v = `yaxis${index+1}`
  //     layout[v] = {
  //       title: handleTitle(index),
  //       titlefont: {color: CHART_LABEL_COLORS[index]},
  //       tickfont: {color: CHART_LABEL_COLORS[index]},
  //       overlaying: 'y',
  //       side: 'right',
  //       position: cv
  //     }
  //   }
  // })
  //for multiple y-axis------------------------
  const getLayoutWidths = [
    { yaxisCount: 1, width: 1400, domain: [0, 0] },
    { yaxisCount: 2, width: 1300, domain: [0, 0.8] },
    { yaxisCount: 3, width: 1200, domain: [0, 0.6] },
    { yaxisCount: 4, width: 1100, domain: [0, 0.6] },
    { yaxisCount: 5, width: 1000, domain: [0, 0.6] },
    { yaxisCount: 6, width: 900, domain: [0, 0.6] },
    { yaxisCount: 7, width: 800, domain: [0, 0.6] },
    { yaxisCount: 8, width: 700, domain: [0, 0.6] },
    { yaxisCount: 9, width: 700, domain: [0, 0.6] },
  ];

  //function to order multiple y axes
  const getOrderedYAxes = (layoutData, YoptionsLength) => {
    let yaxisArray = [];
    Object.keys(layoutData).forEach((element) => {
      if (element.includes('yaxis')) {
        yaxisArray.push(element);
      }
    });
    let position = 0.65;
    // let positionR = 0.90;
    // let positionL = 0.10;
    if (!yaxisArray.find((e) => e === 'yaxis')) {
      yaxisArray.push('yaxis');
    }
    let order = ['yaxis', 'yaxis2', 'yaxis3', 'yaxis4', 'yaxis5', 'yaxis6', 'yaxis7', 'yaxis8'];
    yaxisArray.sort((a, b) => order.indexOf(a) - order.indexOf(b));

    yaxisArray.forEach((item, key) => {
      if (key === 0) {
        if (layoutData[item]) {
          layoutData[item].side = 'left';
          layoutData[item].position = undefined;
        }
      } else if (key === 1) {
        layoutData[item].anchor = 'x';
        layoutData[item].side = 'right';
        layoutData[item].position = undefined;
      } else {
        layoutData[item].anchor = 'free';
        layoutData[item].side = 'right';
        layoutData[item].position = position;
        position += 0.06;
      }
    });
    return layoutData;
  };
  const handleLegends = (graphData) => {
    let index = graphData.expandedIndex;
    let data = graphData.data[index];
    let newYOptions = [...yOptions];
    if (data.visible !== true) {
      newYOptions[index]['yaxis'] = `y${index + 1}`;
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      if (yaxisCount > 2) {
        newYOptions[yaxisCount - 1]['position'] = yaxisCount === newYOptions.length ? 1 : 0.3;
      }
      setYOptions(newYOptions);
      // layout.width = getLayoutWidths.find(item => item.yaxisCount === yaxisCount + 1)?.width;
      layout.width = 1400;
      layout.xaxis.domain = getLayoutWidths.find(
        (item) => item.yaxisCount === Number(yaxisCount) + 1,
      )?.domain;
      if (index !== 0) {
        let yaxisName = `yaxis${index + 1}`;
        layout[yaxisName] = {
          title: `${data?.name} (${data?.units}) ${location ? `(${location})` : ''}`,
          titlefont: { color: CHART_LABEL_COLORS[index] },
          tickfont: { color: CHART_LABEL_COLORS[index] },
          anchor: yaxisCount === 2 ? 'x' : 'free',
          overlaying: 'y',
          side: 'right',
          position: newYOptions[yaxisCount - 1]['position'],
          showline: true,
          autorange: true,
        };
      } else if (index === 0) {
        layout.yaxis = {
          title: `${data?.name} (${data?.units}) ${location ? `(${location})` : ''}`,
          titlefont: { color: CHART_LABEL_COLORS[index] },
          tickfont: { color: CHART_LABEL_COLORS[index] },
          side: 'left',
          showline: true,
          autorange: true,
        };
      }
      let orderedLayoutData = getOrderedYAxes(layout, newYOptions.length);
      updateLayout(orderedLayoutData);
    } else {
      delete newYOptions[index]['yaxis'];
      setYOptions(newYOptions);
      // let yaxisCount = newYOptions.filter(item => item.yaxis)?.length;
      let yaxisName = index === 0 ? `yaxis` : `yaxis${index + 1}`;
      let layoutData = { ...layout };
      delete layoutData[yaxisName];
      if(yaxisName === "yaxis"){
        layoutData[yaxisName] = { showgrid: false, zeroline:false, tickfont : { color: '#fff'}}
      }
      // layoutData.width = getLayoutWidths.find(item => item.yaxisCount === yaxisCount)?.width;
      layout.width = 1400;
      let orderedLayoutData = getOrderedYAxes(layoutData, yOptions.length);
      updateLayout(orderedLayoutData);
    }
  };
  return (
    <>
      <Plot
        data={yOptions}
        layout={layout}
        config={config}
        onLegendClick={(data) => handleLegends(data)}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        divId="erpasId"
      />
      <ExcelFile
        filename={newFileName}
        element={
          <button
            ref={ButtonClick}
            className="btn btn-download ml-auto"
            style={{ display: 'none' }}
          >
            Download
          </button>
        }
      >
        <ExcelSheet data={csvFileData} name="Weather_and_Water">
          {/* <ExcelColumn label="Date" value="Date" />
      <ExcelColumn label="Precipitation" value="Precipitation" />
      <ExcelColumn label="Precipitation climatology" value="Precipitation climatology" />
      <ExcelColumn label="Relative Humidity" value="Relative Humidity" />
      <ExcelColumn label="Relative Humidity climatology" value="Relative Humidity climatology" />
      <ExcelColumn label="Minimum Temperature" value="Minimum Temperature" />
      <ExcelColumn label="Maximum Temperature" value="Maximum Temperature" />
      <ExcelColumn label="Minimum Temperature climatology" value="Minimum Temperature climatology" />
      <ExcelColumn label="Maximum Temperature climatology" value="Maximum Temperature climatology" /> */}
          {objKeysData?.map((item) => (
            <ExcelColumn label={item} value={item} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
export default PlotGEEERPASCharts;
