import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Container } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import { useHistory } from 'react-router-dom';
import RightArrow from '../../assets/Images/back-button.svg';
import _ from 'lodash';
import Select from 'react-select';
import {
  renderReactSelectInputField,
  renderReactUserInputField,
} from '../../Components/common/renderInputField';
import Button from 'reactstrap-button-loader';
import { t } from 'react-multi-lang';
import DraftEditor from './DraftEditor';
import {
  addUserGuideRequest,
  addUserGuideSuccess,
  getAllTopicsRequest,
  getAllUserGuideRequest,
  updateUserGuideRequest,
  updateUserGuideSuccess,
  addUserGuideFailed,
  updateUserGuideFailed,
} from '../../redux/userGuide/userGuideActions';
import { showSuccess, showError } from '../../Components/Notifications';

import { BsArrowLeft } from 'react-icons/bs';

const place = [
  { id: 1, label: 'Before', value: 'before' },
  { id: 2, label: 'After', value: 'after' },
];

const CreateUserGuidePage = (props) => {
  const { reset, formUserGuideData, fromListing, categoryList, pristine } = props;
  let history = useHistory();
  const dispatch = useDispatch();
  let formSubmissionDataHandler = new FormData();

  const [selectedOption, setSelectedOption] = useState();
  const [subTopicOptions, setSubTopicOptions] = useState();
  const [content, setContent] = useState();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [formReset, setFormReset] = useState(false);
  const [topic, setTopic] = useState(false);
  const [subtopic, setSubtopic] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const [updateButtonDisable, setUpdateButtonDisable] = useState(false);
  const [isSubTopicOptionsEmpty, setIsSubTopicOptionsEmpty] = useState(true);
  // const [uploadedFile,setUploadedFile] = useState('');
  // const [isImageError,setIsImageError] = useState(false);
  const [formData, setFormData] = useState({
    topic: '',
    topic_id: '',
    sub_topic: '',
    level: '',
    before_sub_topic: '',
    before_sub_topic_id: '',
    title: '',
    description: '',
    publish_status: false,
  });

  const { UserGuide } = useSelector((state) => ({
    UserGuide: state.UserGuide,
  }));

  useEffect(() => {
    setFormReset(false);
    dispatch(getAllTopicsRequest());
    return () => {
      dispatch(updateUserGuideSuccess({}));
      dispatch(addUserGuideSuccess({}));
      dispatch(updateUserGuideFailed({}));
      dispatch(addUserGuideFailed({}));
    };
  }, []);

  useEffect(() => {
    if (UserGuide?.addUserGuideDataError) {
      let {
        data: { response },
      } = UserGuide?.addUserGuideDataError;
      // showError(UserGuide?.addUserGuideDataError?.data?.response?.data?.message?.[0]);
      if (UserGuide?.addUserGuideDataError?.data?.response?.data?.exception) {
        showError(UserGuide?.addUserGuideDataError?.data?.response?.data?.exception);
      } else {
        showError(UserGuide?.addUserGuideDataError?.data?.response?.data?.message?.[0]);
      }
    }
  }, [UserGuide.addUserGuideDataError]);

  useEffect(() => {
    if (UserGuide?.updateUserGuideDataError) {
      let {
        data: { response },
      } = UserGuide?.updateUserGuideDataError;
      if (UserGuide?.updateUserGuideDataError?.data?.response?.data?.exception) {
        showError(UserGuide?.updateUserGuideDataError?.data?.response?.data?.exception);
      } else {
        showError(UserGuide?.updateUserGuideDataError?.data?.response?.data?.message?.[0]);
      }
    }
  }, [UserGuide.updateUserGuideDataError]);

  useEffect(() => {
    if (formUserGuideData) {
      setFormData(formUserGuideData);
    }
  }, [formUserGuideData]);

  useEffect(() => {
    renderAPISubTopicOptions(categoryList, selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if (_.isEmpty(subTopicOptions)) {
      setIsSubTopicOptionsEmpty(true);
    } else {
      setIsSubTopicOptionsEmpty(false);
    }
  }, [subTopicOptions]);

  /**
   * changed by : sai krishna thalla
   * reason: enable button not working properly in add
   * date: 13-sep-2022
   */
  useEffect(() => {
    if (!_.isEmpty(subTopicOptions)) {
      if (
        formData?.topic_id &&
        formData?.description &&
        formData?.sub_topic &&
        formData?.before_sub_topic_id &&
        formData?.level
      ) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    } else {
      if (formData?.topic_id && formData?.description && formData?.sub_topic) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    }
  }, [formData]);

  useEffect(() => {
    if (!formData?.description && _.isEmpty(selectedOption)) {
      setUpdateButtonDisable(true);
    } else {
      setUpdateButtonDisable(false);
    }
  }, [formData]);

  useEffect(() => {
    if (formReset) {
      reset();
    }
  }, [formReset]);

  useEffect(() => {
    if (UserGuide?.addUserGuideData?.data?.data?.result) {
      setFormData({
        id: '',
        topic: '',
        topic_id: '',
        sub_topic: '',
        level: '',
        before_sub_topic: '',
        before_sub_topic_id: '',
        title: '',
        description: '',
        publish_status: false,
      });
      setFormReset(true);
      dispatch(
        getAllUserGuideRequest({
          page: 1,
          data: { is_delete: 'False', is_backoffice: 'True', is_backoffice: 'True' },
        }),
      );
      history.goBack();
    } else if (UserGuide?.addUserGuideDataError) {
      let {
        data: { response },
      } = UserGuide?.addUserGuideDataError;
      //showError(UserGuide?.addUserGuideDataError?.data?.response?.data?.errors?.data[0]);
    }
  }, [UserGuide.addUserGuideData, UserGuide.addUserGuideDataError]);

  useEffect(() => {
    if (UserGuide?.updateUserGuideData?.data?.data?.result) {
      setFormData({
        id: '',
        topic: '',
        topic_id: '',
        sub_topic: '',
        level: '',
        before_sub_topic: '',
        before_sub_topic_id: '',
        title: '',
        description: '',
        publish_status: false,
      });
      setFormReset(true);
      dispatch(
        getAllUserGuideRequest({ page: 1, data: { is_delete: 'False', is_backoffice: 'True' } }),
      );
      props.handleFromManageUserGuide(false);
      history.goBack();
    }
    // else if (UserGuide?.updateUserGuideDataError) {
    //   if (UserGuide?.updateUserGuideDataError.data.response.data.message);
    // }
    else if (UserGuide?.updateUserGuideData?.data?.status === 400) {
      // showError('User guide subtopic name already exist');
      // history.goBack();
    }
  }, [UserGuide.updateUserGuideData, UserGuide.updateUserGuideDataError]);

  useEffect(() => {
    if (!_.isEmpty(props?.initialValues)) {
      if (!_.isEmpty(categoryList)) {
        renderAPISubTopicOptions(categoryList, props.initialValues.topic.value);
      }
      setSubtopic(true);
      setTopic(true);
    }
  }, [props.initialValues]);

  useEffect(() => {
    if (props?.initialValues?.level) {
      setDefaultValue(props.initialValues.level == 1 ? place[0] : place[1]);
    }
  }, [props.initialValues.level]);

  const renderAPITopicOptions = (list) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params?.length) {
        params.map((param) => {
          let data = {
            value: param.name,
            label: `${param.name}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const renderAPISubTopicOptions = (list, option) => {
    if (list) {
      let params = [...list];
      let options = [];
      let requiredTopic = params.filter((param) => param.name === option);
      if (requiredTopic?.length) {
        let requiredSubTopic = requiredTopic[0].sub_topic;

        if (requiredSubTopic?.length) {
          requiredSubTopic.forEach((element) => {
            let data = {
              value: element.name,
              label: `${element.name}`,
              id: element.id,
              order: element.order,
            };
            options.push(data);
          });
          setSubTopicOptions(options);
        } else {
          setSubTopicOptions([]);
        }
      }
    }
  };

  const topicHandler = (data) => {
    if (data?.value) {
      setSelectedOption(data.value);
      formData.topic = data.value;
      formData.topic_id = data.id;
      // formData.sub_topic_id = ''; //
      //setFormData({ ...formData });
      setTopic(true);
      dispatch(change('user-guide-add-form', 'Beforesubtopic', ''));
      formData.before_sub_topic = '';
      formData.before_sub_topic_id = '';
      formData.before_sub_topic_order = '';
      setFormData({ ...formData });
    }
  };

  const subTopicHandler = (data) => {
    const value = data.currentTarget.value;
    if (value) {
      formData.sub_topic = value;
      setFormData({ ...formData });
      setSubtopic(true);

      // formData.title = '';
      // setFormData({ ...formData });
      setSubtopic(true);
      setTopic(true);
    }
  };
  const level = (data) => {
    if (data) {
      formData.level = data.id;
      setDefaultValue(data.id == 1 ? place[0] : place[1]);
      // setFormData({...formData});
      // dispatch(change('user-guide-add-form','Beforesubtopic',''))
      formData.before_sub_topic = props.initialValues?.Beforesubtopic?.value;
      formData.before_sub_topic_id = props.initialValues?.Beforesubtopic?.id;
      formData.before_sub_topic_order = props.initialValues?.Beforesubtopic?.order;
      setFormData({ ...formData });
    }
  };
  const beforeSubTopicHandler = (data) => {
    if (data?.value) {
      formData.before_sub_topic = data.value;
      formData.before_sub_topic_id = data.id;
      formData.before_sub_topic_order = data.order;
      setFormData({ ...formData });

      // formData.title = data.value;
      // setFormData({ ...formData });
    }
  };

  const handleDescription = (data) => {
    formData.description = data;
    setFormData({ ...formData });
  };

  // const handleImageFileData = (file)=>{
  //   let MAX_SIZE_FILE = 719 // in kb
  //   let fileSize = file?.size / 1000;
  //   if(!file) setIsImageError(false)
  //   if(fileSize > MAX_SIZE_FILE){
  //     showError('File size is greater than specified limit. please choose another file')
  //     document.getElemtById('img_element').src=''
  //     setIsImageError(true)
  //   }
  //   else {
  //     setIsImageError(false)
  //   }
  //   setUploadedFile(file);
  // }

  const handlePublishStatus = (e) => {
    formData.publish_status = e.target.checked;
    setFormData({ ...formData });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    formSubmissionDataHandler.append('topic_id', formData?.topic_id);
    formSubmissionDataHandler.append('subtopic_name', formData?.sub_topic);

    if (formData?.level) {
      formSubmissionDataHandler.append('level', formData?.level);
    }
    if (formData?.before_sub_topic_order) {
      formSubmissionDataHandler.append('order', formData?.before_sub_topic_order);
    }

    formSubmissionDataHandler.append('content', formData?.description);
    formSubmissionDataHandler.append('status', `${_.capitalize(formData?.publish_status)}`);
    formSubmissionDataHandler.append('order_subtopic_id', formData?.before_sub_topic_id);
    if (formData?.title) {
      formSubmissionDataHandler.append('title', formData?.title);
    }
    setFormReset(false);
    dispatch(addUserGuideRequest(formSubmissionDataHandler));
  };

  const handleUpdate = () => {
    if (!updateButtonDisable) {
      formSubmissionDataHandler.append('id', formData?.id);
      formSubmissionDataHandler.append('topic_id', formData?.topic_id);
      if(formData?.sub_topic_id) {
        formSubmissionDataHandler.append('subtopic_id', formData?.sub_topic_id);
      }
      formSubmissionDataHandler.append('subtopic_name', formData?.sub_topic);
      formSubmissionDataHandler.append('level', formData?.level);
      if (formData?.before_sub_topic_order) {
        formSubmissionDataHandler.append('order', formData?.before_sub_topic_order);
      }
      formSubmissionDataHandler.append('content', formData?.description);
      formSubmissionDataHandler.append('status', `${_.capitalize(formData?.publish_status)}`);
      formSubmissionDataHandler.append('title', formData?.title);
      formSubmissionDataHandler.append('order_subtopic_id', formData?.before_sub_topic_id);
      setFormReset(false);
      dispatch(updateUserGuideRequest(formSubmissionDataHandler));
    }
  };
  return (
    <>
      <div className="login-wrapper global-top-user-guide">
        <Container fluid className="p-0">
          <Row>
            <Col lg={5} sm={7} xs={12} className="mx-auto login-form-wrapper login-zIndex w-100">
              <div className="create-user-guide-page mb-3">
                <Card className="user-guide-card-shadow py-4 px-5 user-guide-login-card card">
                  <div className="position-relative create-user-guide-title">
                    <span
                      className="col-lg-1 position-absolute list-tl-st"
                      style={{
                        float: 'left',
                        marginLeft: '10px',
                        borderRadius: '700px',
                        border: '1px solid #ada6a6',
                        height: '43px',
                        width: '43px',
                        fontSize: '25px',
                        color: '#595252',
                        fontWeight: 'bold',
                      }}
                    >
                      {/* <img
                        src={RightArrow}
                        className="manage-header-back-button"
                        onClick={() => history.goBack()}
                      /> */}

                      <BsArrowLeft
                        className="cursor-ponter"
                        onClick={() => history.goBack()}
                        style={{ margin: 'auto' }}
                      />
                    </span>
                    {fromListing ? (
                      <h5 className="st-txt-primary manage-user-guide-page-title">
                        {t('USER_GUIDE.MODIFY_USER_GUIDE')}
                      </h5>
                    ) : (
                      <h5 className="st-txt-primary manage-user-guide-page-title">
                        {t('USER_GUIDE.ADD_USER_GUIDE')}
                      </h5>
                    )}
                  </div>
                  <div className="">
                    <form
                      id="news-add-form"
                      onSubmit={(e) => {
                        onFormSubmit(e);
                      }}
                    >
                      <div className="static-resource-filter-field add-news-scroll">
                        <Row>
                          <Col lg="6">
                            <Field
                              component={renderReactSelectInputField}
                              name="topic"
                              isDisabled={fromListing}
                              placeholder={t('NEWS_FEED.SELECT_CATEGORY')}
                              className="my-4 filter-select-placeholder"
                              onChange={(e) => {
                                topicHandler(e);
                              }}
                              required={true}
                              isMulti={false}
                              options={renderAPITopicOptions(categoryList)}
                            ></Field>
                          </Col>
                          <Col lg="6">
                            <Field
                              component={renderReactUserInputField}
                              name="subtopic"
                              id="subtopic"
                              type="text"
                              placeholder={t('Enter Sub Category')}
                              className="my-4 filter-select-placeholder"
                              onChange={(e) => {
                                subTopicHandler(e);
                              }}
                              required={true}
                              isMulti={false}
                              isDisabled={!topic}
                            ></Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            {!isSubTopicOptionsEmpty && (
                              <Select
                                options={place}
                                className="filter-select-placeholder-1"
                                onChange={(e) => {
                                  level(e);
                                }}
                                value={defaultValue}
                                isDisabled={!subTopicOptions?.length}
                              />
                            )}
                          </Col>
                          <Col lg="6">
                            {!isSubTopicOptionsEmpty && (
                              <Field
                                component={renderReactSelectInputField}
                                name="Beforesubtopic"
                                placeholder={t('Choose Sub Category')}
                                className="filter-select-placeholder-1"
                                onChange={(e) => {
                                  beforeSubTopicHandler(e);
                                }}
                                required={true}
                                isMulti={false}
                                options={subTopicOptions}
                                isDisabled={!topic || !subtopic || !subTopicOptions?.length}
                              ></Field>
                            )}
                          </Col>
                        </Row>
                        <Field
                          component={DraftEditor}
                          name="content"
                          className="mt-1 my-2 filter-select-placeholder "
                          onEditorChange={handleDescription}
                          // handleImageFileData={handleImageFileData}
                          required={false}
                          type="textarea"
                          isMulti={false}
                          content={formData.description}
                          placeholder={t('USER_GUIDE.ADD_DESCRIPTION')}
                        ></Field>

                        <div className="publish-div">
                          <Field
                            component={'input'}
                            type="checkbox"
                            name="status"
                            className="my-2 filter-select-placeholder mr-1 user-guide-publish-checkbox "
                            onChange={(e) => handlePublishStatus(e)}
                            required={false}
                          />
                          &nbsp;
                          <label
                            htmlFor="publish_status"
                            className="publish_style_user_guide"
                            style={{ fontWeight: 'bold', color: '#363636' }}
                          >
                            {t('USER_GUIDE.PUBLISH')}
                          </label>
                        </div>
                      </div>

                      <div className="text-center1 mb-3">
                        {!fromListing ? (
                          <Button
                            className="btn btn-block st-btn-custom user-guide-submit-btn"
                            disabled={saveButtonDisabled}
                            type="submit"
                            loading={UserGuide?.isAddUserGuideRequesting}
                            id="save"
                          >
                            {t('USER_GUIDE.SAVE')}
                          </Button>
                        ) : (
                          <Button
                            className="btn btn-block st-btn-custom user-guide-submit-btn"
                            //disabled={!(formData?.sub_topic && formData?.before_sub_topic_order && formData?.level) && !_.isEmpty(subTopicOptions)}
                            disabled={updateButtonDisable}
                            type="button"
                            loading={UserGuide?.isUpdateUserGuideRequesting}
                            onClick={handleUpdate}
                            id="update"
                          >
                            {t('USER_GUIDE.UPDATE')}
                          </Button>
                        )}
                      </div>
                    </form>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default reduxForm({
  form: 'user-guide-add-form',
  enableReinitialize: true,
})(CreateUserGuidePage);
