import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js';
import _ from 'lodash';
import {
  renderGenericDateFormat,
  getChartShapeName,
  renderChartTime,
} from '../../Components/common/utils';
import {
  CHART_LABEL_COLORS,
  DISPLAY_CHART_DATE,
  GRAPH_YAXIS_COLORS,
  NOAA_PARAMETER_SLUG,
} from '../../Components/constants';
import { PlotlyPNGLogo, PlotlyCSVLogo, PlotlyCloseLogo } from '../constants';
import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var icon2 = {
  width: 300,
  height: 500,
  path: 'M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z',
};
var icon3 = {
  name: 'newplotlylogo',
  svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"/></svg>',
};

const PlotIRIChart = (props) => {
  const {
    chartData,
    parametersData,
    renderCharts,
    dataSource,
    isOpenWeather,
    IRIForecastTime,
    fileName,
    location,
    region,
  } = props;
  const [dates, setDates] = useState([]);
  const [yOptions, setYOptions] = useState([]);
  const ButtonClick = useRef('');
  const [csvFileData, setCsvFileData] = useState([]);
  const [newFileName, setFileName] = useState(
    `${fileName.split('-')[0]}-${fileName.split('-')[1]}`,
  );
  const [layout, updateLayout] = useState({
    bargap: 0.9,
    responsive: true,
    title: {
      text: `Data Source : ${dataSource[0]?.name}`,
      plot_bgcolor: '#E5ECF6',
      font: {
        family: 'National',
        color: '#28609e',
        size: 15,
      },
      x: 0.5,
      xanchor: 'center',
    },
    // plot_bgcolor: "#E5ECF6",
    // paper_bgcolor: "#E5ECF6",
    modebardisplay: true,
    // width: 1300,
    font: {
      size: 12,
      color: '#000',
    },
    xaxis: {
      showline: true,
      autorange: true,
      range: dates,
      tickfont: {
        size: 12,
        color: '#000',
        family: 'National',
      },
      showgrid: false,
    },

    showlegend: false,
    // margin: 80,
    legend: {
      orientation: 'h',
      // x: 0.3,
      x: 0.5,
      xanchor: 'center',
      font: {
        // family: 'sans-serif',
        size: 15,
        color: '#000',
        family: 'National',
      },
    },
    margin: {
      t: 55,
      b: 50,
    },
  });
  const getTitle = () => {
    let title;
    if(region?.length){
      title = `<b>${NOAA_PARAMETER_SLUG[0]?.name} (%)</b>`;
    }
    else  title = `<b>${NOAA_PARAMETER_SLUG[0]?.name} (%)<br>${location ? `(${location})` : ''}</b>`;

    // if(region && region?.length) {
    //   region.map((i, index) => {
    //     if (i?.layerType !== 'marker' && i?.layerType !== 'polygon') {
    //       title = `${NOAA_PARAMETER_SLUG[0]?.name} (%)<br>${location ? `(${location})` : ''}`;
    //     } else {
    //       title =
    //         i?.layerType === 'marker'
    //           ? 'point' + '(' + i?.latlngs?.[1].toFixed(2) + ',' + i?.latlngs?.[0].toFixed(2) + ')'
    //           : i?.layerType === 'polygon' && `${i?.layerType}-${i?.polygonCount}`;
    //     }
    //   })
    // } else {
    //   title = `${NOAA_PARAMETER_SLUG[0]?.name} (%)<br>${location ? `(${location})` : ''}`;
    // }
    return title;
  };

  useEffect(() => {
    updateLayout((preValues) => ({
      ...preValues,
      yaxis1: {
        title: getTitle(), //for multiple y-axis------
        // title: `${NOAA_PARAMETER_SLUG[0]?.name}(%) (${location})` , //for multiple y-axis------
        titlefont: { color: GRAPH_YAXIS_COLORS[0], family: 'National' },
        tickfont: { color: GRAPH_YAXIS_COLORS[0] },
        showline: true,
        showgrid: false,
        rangemode: 'tozero',
      },
    }));
  }, []);

  useEffect(() => {
    let formattedDates;
    let datesList;
    let options = [];
    if (chartData) {
      datesList = chartData?.millis;
      datesList =
        chartData?.millis?.length > chartData?.millis?.length
          ? chartData?.millis
          : chartData?.millis;
      // }
      if (!_.isEmpty(datesList)) {
        formattedDates = datesList?.length
          ? datesList.map((item) => {
              let dateFormat = new Date(item);
              return renderGenericDateFormat(dateFormat);
            })
          : '';
      }
      setDates(formattedDates);
      let view = [{ visible: '', dash: 'dash', type: 'scatter', mode: '' }];
      let ab = {
        y: [],
      };
      ab.y = chartData.data?.map((i) => i?.toFixed(2));
      ab.customData = chartData.data?.map((i) => i?.toFixed(4));
      ab.type = 'bar';
      ab.mode = '';
      ab.name = 'Precipitation';
      ab.x = formattedDates;
      ab.width = [0.05];
      ab.marker = { color: GRAPH_YAXIS_COLORS[0], dash: '' };
      ab.hovertemplate = `%{x} : ${ab.name} : %{y}<extra></extra>`;
      // ab.fill='toself';
      ab.visible = '';
      ab.line = { color: GRAPH_YAXIS_COLORS[0] };
      options.push(ab);
    }
    setYOptions(options);
  }, [chartData]);

  var icon1 = {
    width: 300,
    height: 500,
    path: 'M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z',
  };
  const getActiveLegends = (isDownloadCSV, graphData) => {
    if (document.getElementById('iriId')?.data?.length) {
      const activeLegends = document.getElementById('iriId')?.data;
      if (activeLegends.map((data) => data.name)?.length) {
        //setting file name based on active legends
        setFileName(
          `${fileName.split('-')[0]}${fileName.split('-')[1] ? `-${fileName.split('-')[1]}` : ''}${
            region?.length
              ? `-Point(${region[0]?.latlngs[1].toFixed(2)}-${region[0]?.latlngs[0].toFixed(2)})`
              : ''
          }-${activeLegends.map((data) => data.name).join(' ')}`,
        );
      }
      if (activeLegends?.length) {
        //formatting data for csv file
        const xaixsValue = activeLegends[0].x;
        let data = [];
        xaixsValue.forEach((date, key) => {
          activeLegends.forEach((item, index) => {
            if (index === 0) {
              data.push({ Date: date, [item.name]: item?.customData[key] });
            } else {
              data[key][item.name] = item?.customData[key];
            }
          });
        });
        setCsvFileData(data);
        if (isDownloadCSV) {
          //downloading csv file
          ButtonClick.current.click();
        } else {
          //downloading png file
          Plotly.downloadImage(graphData, {
            filename: `${fileName.split('-')[0]}${
              fileName.split('-')[1] ? `-${fileName.split('-')[1]}` : ''
            }${
              region?.length
                ? `-Point(${region[0]?.latlngs[1].toFixed(2)}-${region[0]?.latlngs[0].toFixed(2)})`
                : ''
            }-${activeLegends.map((data) => data.name).join(' ')}`,
            format: 'png',
            width: graphData._fullLayout.width,
            height: graphData._fullLayout.height,
          });
        }
      }
    }
  };

  let config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true, //by default we are showing
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        click: function (gd) {
          getActiveLegends(false, gd);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        click: function (gd) {
          getActiveLegends(true, gd);
        },
      },
      {
        name: 'Close',
        icon: PlotlyCloseLogo,
        click: function () {
          renderCharts();
        },
      },
    ],
    showTips: false,
  };

  return (
    <>
      <span
        style={{
          color: '#28609e',
          position: 'absolute',
          left: '5px',
          top: '2%',
          family: 'National',
          fontSize: '15px',
          zIndex: 100,
        }}
      >
        {/* Date : {'01' + '-' + IRIForecastTime?.lead_time?.id + '-' + IRIForecastTime?.year?.value} to{' '}
        {moment(
          `${IRIForecastTime?.year?.value}-${0 + IRIForecastTime?.lead_time?.id}`,
          'YYYY-M',
        ).daysInMonth() +
          '-' +
          IRIForecastTime?.lead_time?.id +
          '-' +
          IRIForecastTime?.year?.value} */}
        Forecast Lead Time : {`01-${IRIForecastTime?.lead_time?.id}-${IRIForecastTime?.year?.value}`}
      </span>
      <Plot
        data={yOptions}
        layout={layout}
        config={config}
        // onLegendClick={data => handleLegends(data)}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        divId="iriId"
      />
      <ExcelFile
        filename={newFileName}
        element={
          <button
            ref={ButtonClick}
            className="btn btn-download ml-auto"
            style={{ display: 'none' }}
          >
            Download
          </button>
        }
      >
        <ExcelSheet data={csvFileData} name="Weather_and_Water">
          <ExcelColumn label="Date" value="Date" />
          <ExcelColumn label="Precipitation" value="Precipitation" />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
export default PlotIRIChart;
