import HOME_CONST from './homeConsts';

export const getBannersRequest = (data) => ({
  type: HOME_CONST.GET_BANNERS_REQUEST,
  payload: data,
});

export const getBannersSuccess = (response) => ({
  type: HOME_CONST.GET_BANNERS_SUCCESS,
  payload: response,
});

export const getBannersFailed = (error) => ({
  type: HOME_CONST.GET_BANNERS_FAILED,
  payload: error,
});

export const getParametersRequest = (type) => ({
  type: HOME_CONST.GET_PARAMETERS_REQUEST,
  payload: type,
});

export const getParametersSuccess = (response) => ({
  type: HOME_CONST.GET_PARAMETERS_SUCCESS,
  payload: response,
});

export const getParametersFailed = (error) => ({
  type: HOME_CONST.GET_PARAMETERS_FAILED,
  payload: error,
});
