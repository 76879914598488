import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { getToken, handleLogoutRedirect, isStaffUser,isInsuranceAdmin } from '../../Components/common/utils';
import Cookies from 'js-cookie';
import ProfileImage from '../../assets/Images/profile-placeholder.png';
import Routes from '../../routes/routes';
import IWMI from '../../assets/Images/iwmi.png';
import CommonModulesHeader from './CommonModulesHeader';
import ReactTooltip from 'react-tooltip';

const Header = () => {
  const routeOptions = useMemo(
    () => [
      {
        id: 1,
        headerName: 'Earth Observation for Agricultural Risk Management',
        routeName: 'home',
      },
      {
        id: 2,
        headerName: 'Climate Weather',
        routeName: 'climate-weather',
      },
    ],
    [],
  );

  // const routeOptions = [
  //   {
  //     id: 1,
  //     headerName: 'Satellite-Based Risk Analytics for Agricultural Insurance',
  //     routeName: 'home',
  //   },
  //   {
  //     id: 2,
  //     headerName: 'Weather Forecast',
  //     routeName: 'weather-forecast',
  //   },
  //   {
  //     id: 3,
  //     headerName: 'Public API',
  //     routeName: 'public-api',
  //   },
  //   {
  //     id: 4,
  //     headerName: 'Drought Monitor',
  //     routeName: 'drought-monitor',
  //   },
  //   {
  //     id: 5,
  //     headerName: 'Contingency Plan',
  //     routeName: 'contingency-plan',
  //   },
  //   {
  //     id: 6,
  //     headerName: 'Online Bulletin',
  //     routeName: 'bulletin',
  //   },
  // ];

  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [headerSubtitle, setHeaderSubtitle] = useState('');
  const [subHeadingList, setSubHeadingList] = useState();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  let url = window.location.href;
  useEffect(() => {
    let title;
    if (url) {
      routeOptions.forEach((item) => {
        if (url.includes(item.routeName)) {
          title = item.headerName;
        }
      });
    } else {
      title = t('NAVBAR.HEADER.TITLE');
    }
    setHeaderSubtitle(title);
  }, [url, routeOptions]);

  useEffect(() => {
    if (Routes) {
      let a = [];
      Routes.map((i) => {
        if (i.visible) {
          a.push(i);
        }
      });
      setSubHeadingList(a);
    }
  }, []);

  const checkPages = () => {
    return (
      window.location.href.indexOf('home') === -1 &&
      window.location.href.indexOf('register') === -1 &&
      window.location.href.indexOf('login') === -1 &&
      window.location.href.indexOf('forgot-password') === -1 &&
      window.location.href.indexOf('reset-password') === -1 &&
      window.location.href.indexOf('change-password') === -1
    );
  };

  const handleLogout = () => {
    history.push('/login');
    handleLogoutRedirect();
  };

  const handleChangePassword = () => {
    history.push('/change-password');
  };

  const handleHeaderButtons = () => {
    if (
      window.location.href.includes('login') ||
      window.location.href.includes('forgot-password') ||
      window.location.href.includes('reset-password')
    ) {
      return (
        <NavLink>
          <button className="btn btn-white mvb-button" onClick={() => history.push('/register')}>
            {t('HEADER.SIGN_UP')}
          </button>
        </NavLink>
      );
    } else {
      return (
        <NavLink>
          <button className="btn btn-white mvb-button" onClick={() => history.push('/login')}>
            {t('HEADER.SIGN_IN')}
          </button>
        </NavLink>
      );
    }
  };

  const redirectToDashBoard = () => {
    // console.log('AAAAAAAAAA')
    history.push('/settingsDashboard?from=login');
  };

  return (
    <>
      <div className="header-div">
        <Navbar color="light" light expand="md" className="navbar-header nav-main">
          <a
            className="nav-logo"
            href="https://www.iwmi.cgiar.org/"
            target="_blank"
            rel="noreferrer"
          >
            <NavbarBrand>
              <img className="sadams-iwmi-logo ml-auto" src={IWMI} alt="IWMI LOGO" />
            </NavbarBrand>
          </a>
          <div className="vl"></div>
          <NavbarBrand onClick={() => history.push('/home')}>
            <span className="iwmi-title">
              {headerSubtitle ? headerSubtitle : t('NAVBAR.HEADER.TITLE')}
            </span>
          </NavbarBrand>
          {/* <NavbarToggler onClick={handleToggle} className="navtoogle-1" /> */}
          {window.location.href.indexOf('home') === -1 ? (
            <NavbarToggler onClick={handleToggle} className="navtoogle-1" />
          ) : (
            ''
          )}
          <Nav className="nav-header-right" navbar>
            {!Cookies.get('user') ? (
              <>
                <NavItem>{handleHeaderButtons()}</NavItem>
                <span className="dev-version-st"> {t('HEADER.DEVELOPMENT_VERSION')} </span>
              </>
            ) : (
              <>
                <NavItem className="profile-item">
                  <span className="profile">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="profile-dropdown">
                      <DropdownToggle>
                        <NavLink className="profile-image">
                          <img src={ProfileImage} alt={'not-available'} />
                        </NavLink>
                        {t('HEADER.ACCOUNT')}
                      </DropdownToggle>
                      <DropdownMenu className="profile-dropdown-menu">
                        {/* {(isStaffUser() || isInsuranceAdmin()) &&  ( */}
                          <DropdownItem
                            className="profile-dropdown-item"
                            onClick={redirectToDashBoard}
                          >
                            Settings
                          </DropdownItem>
                        {/* )} */}
                        <DropdownItem
                          className="profile-dropdown-item"
                          onClick={handleChangePassword}
                        >
                          Change Password
                        </DropdownItem>
                        <DropdownItem className="profile-dropdown-item" onClick={handleLogout}>
                          {t('HEADER.LOGOUT')}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </span>
                </NavItem>
              </>
            )}
          </Nav>
        </Navbar>
      </div>
      <div className="headernav-div">
      {checkPages()  && <CommonModulesHeader subHeadingList={subHeadingList} path={window.location.href}/>}
      </div>
      <div
      className={window.location.href.indexOf('home') !== -1?"hme-hd-p1":""}
        style={
          window.location.href.indexOf('home') !== -1
            ? { paddingTop: '80px' }
            : { paddingTop: 'unset' }
        }
      />
    </>
  );
};

export default Header;
Header.propTypes = {
  showProfile: PropTypes.bool,
};
Navbar.propTypes = {
  light: PropTypes.bool,
  dark: PropTypes.bool,
  fixed: PropTypes.string,
  color: PropTypes.string,
  role: PropTypes.string,
  expand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

NavbarBrand.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

NavbarText.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};
