const RISK_ANALYTICS_CONST = {
  GET_RISK_SCORE_REQUEST: 'GET_RISK_SCORE_REQUEST',
  GET_RISK_SCORE_SUCCESS: 'GET_RISK_SCORE_SUCCESS',
  GET_RISK_SCORE_FAILED: 'GET_RISK_SCORE_FAILED',
  
  GET_RISK_INDICES_REQUEST: 'GET_RISK_INDICES_REQUEST',
  GET_RISK_INDICES_SUCCESS: 'GET_RISK_INDICES_SUCCESS',
  GET_RISK_INDICES_FAILED: 'GET_RISK_INDICES_FAILED',

  GET_INDICES_CHANGE_REQUEST: 'GET_INDICES_CHANGE_REQUEST',
  GET_INDICES_CHANGE_SUCCESS: 'GET_INDICES_CHANGE_SUCCESS',
  GET_INDICES_CHANGE_FAILED: 'GET_INDICES_CHANGE_FAILED',


  SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILED: 'SEND_EMAIL_FAILED',

  GET_CWP_CROPS_LIST_REQUEST: 'GET_CWP_CROPS_LIST_REQUEST',
  GET_CWP_CROPS_LIST_SUCCESS: 'GET_CWP_CROPS_LIST_SUCCESS',
  GET_CWP_CROPS_LIST_FAILED: 'GET_CWP_CROPS_LIST_FAILED',

  GET_CWP_SEASONS_LIST_REQUEST: 'GET_CWP_SEASONS_LIST_REQUEST',
  GET_CWP_SEASONS_LIST_SUCCESS: 'GET_CWP_SEASONS_LIST_SUCCESS',
  GET_CWP_SEASONS_LIST_FAILED: 'GET_CWP_SEASONS_LIST_FAILED',

  GET_CWP_PRACTISES_LIST_REQUEST: 'GET_CWP_PRACTISES_LIST_REQUEST',
  GET_CWP_PRACTISES_LIST_SUCCESS: 'GET_CWP_PRACTISES_LIST_SUCCESS',
  GET_CWP_PRACTISES_LIST_FAILED: 'GET_CWP_PRACTISES_LIST_FAILED',

  GET_CWP_GRAPH_DATA_REQUEST: 'GET_CWP_GRAPH_DATA_REQUEST',
  GET_CWP_GRAPH_DATA_SUCCESS: 'GET_CWP_GRAPH_DATA_SUCCESS',
  GET_CWP_GRAPH_DATA_FAILED: 'GET_CWP_GRAPH_DATA_FAILED'
};

export default RISK_ANALYTICS_CONST;
