import React, { useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  useMap,
  useMapEvents,
  ZoomControl,
  LayersControl,
  LayerGroup,
} from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import L from 'leaflet';
import _ from 'lodash';
import 'leaflet-side-by-side';
import {
  Col,
  Button,
  CardTitle,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  Row,
  Collapse,
  FormGroup,
  Alert,
  Modal,
  Label,
  Input,
} from 'reactstrap';
import ForecastIcon from '../../assets/Images/forecast.svg';
import MonitoringIcon from '../../assets/Images/monitoring.svg';
import 'leaflet/dist/leaflet.css';
import 'leaflet.control.opacity/dist/L.Control.Opacity.css';
import 'leaflet.control.opacity';
import DraggableMarker from './Marker';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import '../../assets/CSS/LeafletWrapper.css';
import '../../assets/CSS/Header.css';
import myConstClass from '../../Constants';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDroughtMapRequest,
  getDroughtTimeSeriesRequest,
  getDroughtMapSuccess,
  getMapPreferenceSuccess,
  getDroughtMapFailed,
  getMapPreferenceBoundariesSuccess,
} from '../../redux/actions';
import DroughtSidebar from './sidebar';
import Loader from '../../Components/common/loader';
import { showError } from '../../Components/Notifications.js';
import CustomLegend from '../WeatherForecast/CustomLayerControl/CustomLegend';
import DiscreteLegend from '../WeatherForecast/CustomLayerControl/DiscreteLegend';
import MapPreferences from '../WeatherForecast/MapPreferences';
import DroughtPlotChart from './DroughtPlotChart';
import DrawShape from './DrawShape';
import { API_BASE_URL, AXIOS_INSTANCE } from '../../redux/api/apiEndPoint';
import { CgCloseO } from 'react-icons/cg';
import mapPreferenceIcon from '../../assets/Images/mapPreferenceIcon.svg';
import {
  SOUTH_ASIA_CONST,
  MAP_PREFERENCES_lABELS,
  GEOJSON_COUNTRY_TOOLTIP_DATA,
  DEFAULT_COUNTRY,
  DROUGHT_TEMPORAL_AGGREGATION,
  WEATHER_MONITOR_CONSTS,
} from '../../Components/constants';
import { WEATHER_MONITOR_URL } from '../../redux/api/apiEndPoint';
import Cookies from 'js-cookie';
import { setWaterMonitoringDefaultParam, getToken } from '../../Components/common/utils';
import moment from 'moment';

const DroughtMap = (props) => {
  const { popUpSelectedModule } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { Overlay } = LayersControl;
  const [isOpenMapModel, setIsOpenMapModel] = useState(false);
  const [activeTab, setActiveTab] = useState(t('CLIMATE_WEATHER_FILTER.MONITORING'));
  const [map, setMap] = useState(false);
  const [mapPrefereTileLayerRef, setMapPrefereTileLayerRef] = useState([]);
  const [precipitationThreshold, setPrecipitationThreshold] = useState();
  const [comparePrecipitationThreshold, setComparePrecipitationThreshold] = useState();
  const [extremeRainfallThreshold, setExtremeRainfallThreshold] = useState();
  const [compareExtremeRainfallThreshold, setCompareExtremeRainfallThreshold] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const layerRef = useRef([]);
  const splitterRef = useRef(null);
  const [mapPreferRefs, setMapPreferRefs] = useState([]);
  const [isHideBar, setBar] = useState(false);
  const [params, setParams] = useState('');
  const [selectedParam, setSelectedParam] = useState('');
  const [fileName, setFileName] = useState('');
  const [previousParam, setPreviousParam] = useState('');
  const [compareSelectedParam, setCompareSelectedParam] = useState('');
  const [initialSelectedDate, setInitialSelectedDate] = useState([]);
  const [finalSelectedDate, setFinalSelectedDate] = useState([]);
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [geoJsonAllData, setGeoJsonAllData] = useState([]);
  const [geoJsonLayersData, setGeoJsonLayerData] = useState([]);
  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [selectedParamLegendData, setSelectedParamLegendData] = useState([]);
  const [compareParamLegendData, setCompareParamLegendData] = useState([]);
  const [selectedMapPreferLegendData, setSelectedMapPreferLegendData] = useState([]);
  const [showCharts, setShowCharts] = useState(false);
  const [graphData, setGraphData] = useState('');
  const [updateGraph, setUpdateGraph] = useState(true);
  const mapRef = useRef(null);
  const [mapControl, setMapControl] = useState({});
  const [mapLayerOpacity, setMapLayerOpacity] = useState({ layer: '' });
  const [opacityControl, setOpacityControl] = useState('');
  const [compareView, setCompareView] = useState([]);
  const [isCompareClicked, setIsCompareClicked] = useState(false);
  const [mapPreferenceGeoJsonloading, setMapPreferenceGeoJsonloading] = useState(false);
  const [errorData, setErrorData] = useState({ showError: false, data: '' });
  const [isGraphLoading, setIsGraphLoading] = useState();
  const [selectedRegionId, setSelectedRegionId] = useState('');
  const [selectedGLDAS, setSelectedGLDAS] = useState({});
  const [compareSelectedGLDAS, setCompareSelectedGLDAS] = useState({});
  const [selectedModule, setSelectedModule] = useState({
    value: 'drought monitor',
    label: 'Drought Monitor',
    api_slug: 'drought-monitor',
  });
  const [mapPreferences, setMapPreferences] = useState({
    country: { layer: useRef(), color: '', data: '' },
    states_or_provinces: { layer: useRef(), color: '', data: '' },
    districts_or_cities: { layer: useRef(), color: '', data: '' },
    meteorological_locations: { layer: useRef(), color: '', data: '' },
    basins: { layer: useRef(), color: '', data: '' },
    rivers_or_reservoirs: { layer: useRef(), color: '', data: '' },
    talukas: { layer: useRef(), color: '', data: '' },
  });
  const [selectedLocation, setSelectedLocation] = useState({
    country: '',
    state: '',
    district: '',
    basin: '',
    region: null,
    markerPointData: '',
  });
  const [selectedLocation1, setSelectedLocation1] = useState('');

  const [compareLocation, setCompareLocation] = useState({
    country: '',
    state: '',
    district: '',
    basin: '',
    region: '',
    markerPointData: '',
  });
  const [cordinateAndZoom, setCordinateAndZoom] = useState({
    cordinates: [23.4073, 79.446],
    locationCordinates: [23.4073, 79.446],
    markerCordinates: [23.4073, 79.446],
    zoom: 5.5,
  });
  const [mapPreferUrl, setMapPreferUrl] = useState('');

  const [indexLayers, setIndexLayers] = useState([]);
  const [isThresholdDataLoading, setIsThresholdDataLoading] = useState(false);
  const [compareCordinates, setCompareCordinates] = useState([]);

  const [mapData, setMapData] = useState('');
  const [mapUrl, setMapUrl] = useState({
    name: '',
    url: '',
  });
  const [applyLayersDisable, setApplyLayerDisable] = useState(false);

  const [rangeState, setRangeState] = useState({
    min: 0,
    max: 0,
  });

  const [compareRangeState, setCompareRangeState] = useState({
    min: 0,
    max: 0,
  });

  const [layerControl, setLayerControl] = useState({
    firstLayer: {},
    secondLayer: {},
  });

  const [temporalAggregation, setTemporalAggregation] = useState('');
  const [applyLayers, setApplyLayers] = useState(false);

  const { Weather, DroughtMonitor, boundries } = useSelector((state) => ({
    Weather: state.Weather,
    DroughtMonitor: state.DroughtMonitor,
    boundries: state.Map_Preference,
  }));

  //useeffect for getting map layers data from redux
  useEffect(() => {
    if (getToken()) {
      dispatch(getDroughtMapSuccess(''));
      dispatch(getMapPreferenceSuccess(''));
      setMapData('');
      return () => {
        setSelectedParamLegendData('');
        setCompareParamLegendData('');
        dispatch(getMapPreferenceBoundariesSuccess(''));
        dispatch(getDroughtMapFailed(''));
        Cookies.remove('water-monitor-default-param', { path: '/' });
      };
    } else {
      history.push('/home');
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedParam)) {
      if (selectedParam?.slug === 'dryspell') {
        setPrecipitationThreshold('2.5');
      } else if (selectedParam?.slug === 'no_of_rainy_days') {
        setExtremeRainfallThreshold('124');
      }
    }
    if (!_.isEmpty(compareSelectedParam)) {
      if (compareSelectedParam?.slug === 'dryspell') {
        setComparePrecipitationThreshold('2.5');
      } else if (compareSelectedParam?.slug === 'no_of_rainy_days') {
        setCompareExtremeRainfallThreshold('124');
      }
      // }
      // else if(compareSelectedParam?.slug === "no_of_rainy_days"){
      //   setComparePrecipitationThreshold(124.4)
      // }
    }
  }, [selectedParam, compareSelectedParam]);

  function addOpacitySlider() {
    // if (!window.map) console.log('not ready');
    if (indexLayers?.length) {
      L.Control.OpacitySlider = L.Control.extend({
        sliding: false,
        updateLayerOpacity: function (opacity) {
          // indexLayers.forEach((layer, i) => {
          //   layer.setOpacity(opacity / 100);
          // });
          let lastIndex = indexLayers?.length - 1;
          if (indexLayers?.length >= 2) {
            let secondLastIndex = indexLayers?.length - 2;
            if (indexLayers[lastIndex]?.isCompare && indexLayers[secondLastIndex]?.isCompare) {
              indexLayers.forEach((item, i) => {
                if (i === lastIndex || i === secondLastIndex) item?.layer.setOpacity(opacity / 100);
              });
            } else {
              indexLayers.forEach((item, i) => {
                if (i === lastIndex) item?.layer.setOpacity(opacity / 100);
              });
            }
          } else {
            indexLayers.forEach((item, i) => {
              if (i === lastIndex) item?.layer.setOpacity(opacity / 100);
            });
          }
        },
        onAdd: function (map) {
          if (opacityControl) {
            map?.removeControl(opacityControl);
          }
          var container = L.DomUtil.create('div', 'opacityContainer');
          container.innerHTML =
            '<div id=layer-opacity-control><input type="range" min="1" max="100" value="100" defaultValue="100" class="opacityslider"></div>';
          if (!L.Browser.touch) {
            L.DomEvent.disableClickPropagation(container);
            L.DomEvent.on(container, 'mousewheel', L?.DomEvent?.stopPropagation);
          } else {
            L.DomEvent.on(container, 'mousedrag click', L?.DomEvent?.stopPropagation);
          }
          let slider = container.querySelector('.opacityslider');
          slider.onclick = (e) => {
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousedown = (e) => {
            this.sliding = true;
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousemove = (e) => {
            e?.stopPropagation();
            if (this.sliding) this.updateLayerOpacity(e.target.value);
          };
          slider.onmouseup = (e) => {
            this.sliding = false;
          };
          return container;
        },
      });
      L.control.opacitySlider = function (opts) {
        return new L.Control.OpacitySlider(opts);
      };
      let customOpacityControl = L.control.opacitySlider({ position: 'topright' });
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) {
        customOpacityControl?.addTo(map);
      }
      setOpacityControl(customOpacityControl);
    }
  }
  useEffect(() => {
    if (!_.isEmpty(indexLayers)) {
      addOpacitySlider();
      if (indexLayers?.length) {
        indexLayers.map((l) => l?.layer?.bringToFront());
      }
    }
  }, [indexLayers]);

  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (boundries?.mapPreferenceData) {
      setGetBoundariesData(boundries?.mapPreferenceData?.data?.result);
    }
  }, [boundries?.mapPreferenceData]);

  useEffect(() => {
    if (getBoundariesData && getBoundariesData?.length && !geoJsonLayersData?.length) {
      let API_URL = [];
      mapPreferRefs.current = [];
      mapPrefereTileLayerRef.current = [];
      // setIsLoading(true);
      getBoundariesData?.map((item) => {
        if (item?.data && item?.data.length) {
          item.data.map((boundary) => {
            // boundary?.map(each => {
            if (boundary?.json_url?.length) {
              setGeoJsonData([]);
              setGeoJsonAllData([]);
              let mapPreferKeys = Object.keys(mapPreferences);
              if (mapPreferKeys) {
                mapPreferKeys.map((item) => {
                  let selectedLayer = mapPreferences[item];
                  if (selectedLayer?.layer && selectedLayer?.layer?.current)
                    selectedLayer?.layer?.current?.clearLayers();
                  selectedLayer.color = '';
                  setMapPreferences((prev) => {
                    return {
                      ...prev,
                      [item]: selectedLayer,
                    };
                  });
                });
              }
              setMapPreferenceGeoJsonloading(true);
              let config = {
                name: boundary?.slug,
                id: boundary?.id,
                color: boundary?.color_code,
                category: boundary?.map_preference_category?.name,
                country_id: 'e2f886a7-3299-4c82-af2e-987fe92fab40',
              };
              API_URL.push(AXIOS_INSTANCE.get(boundary?.json_url, config));
            } else {
              let ref = React.createRef();
              ref.current = {
                boundary_id: boundary?.id,
                name: boundary.boundarytype,
                layer: React.createRef(),
              };
              mapPreferRefs.current.push(ref);
            }
            // });
          });
        }
      });
      Promise.all(API_URL)
        .then((response) => {
          // setIsLoading(false);
          let res = JSON.parse(JSON.stringify(response));
          setMapPreferenceGeoJsonloading(false);
          setGeoJsonLayerData(res);
        })
        .catch((error) => {
          // setIsLoading(false);
          setMapPreferenceGeoJsonloading(false);
        });
    }
  }, [getBoundariesData]);

  useEffect(() => {
    let selectedGeoJsonData = [];
    if (geoJsonLayersData?.length && geoJsonData?.length) {
      geoJsonData?.map((item) => {
        let filteredObj = geoJsonLayersData?.find(
          (layer) => layer.config.id === item.id && layer.config.country_id === item.country_id,
        );
        if (!_.isEmpty(filteredObj)) {
          filteredObj.fromMapPreferences = item.fromMapPreferences;
          selectedGeoJsonData.push(filteredObj);
        }
      });
    } else {
      selectedGeoJsonData = [];
    }
    setGeoJsonAllData(selectedGeoJsonData);
  }, [geoJsonLayersData, geoJsonData]);

  useEffect(() => {
    if (splitterRef && splitterRef.current) {
      if (errorData?.showError) {
        splitterRef.current.setState({ secondaryPaneSize: '30%' });
      } else {
        splitterRef.current.setState({ secondaryPaneSize: '70%' });
      }
    }
  }, [errorData]);
  useEffect(() => {
    if (DroughtMonitor.isGetDroughtMapRequesting) {
      setIsLoading(true);
    }
    if (DroughtMonitor.getDroughtMapData) {
      removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
      const { response } = DroughtMonitor.getDroughtMapData;
      if (Array.isArray(response?.data)) {
        if (response?.isDroughtModule) {
          setMapData(response?.data);
        }
      } else {
        const { result } = response?.isDroughtModule ? response?.data : { result: '' };
        setMapData(result);
      }
      if (!mapPreferenceGeoJsonloading) {
        setIsLoading(false);
      }
      setIsLoading(false);
    } else if (DroughtMonitor.getDroughtMapDataError) {
      const {
        data: { response },
      } = DroughtMonitor.getDroughtMapDataError?.response;
      if (response?.data?.code === 400) {
        removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
        if (!_.isEmpty(response?.data?.errors)) {
          let errorsObj = response?.data?.errors;
          if (!_.isEmpty(errorsObj)) {
            const values = Object.values(errorsObj);
            showError(values[0]);
          }
        } else {
          // setErrorData({ showError: true, data: response?.data?.message });
          showError(response?.data?.message);
        }
      } else {
        if (response?.data?.response === undefined) {
          showError(t('ERROR.API_FAILURE_ERROR'));
        }
      }
      setIsLoading(false);
    } else if (DroughtMonitor.getDroughtMapDataNetworkError) {
      setIsLoading(false);
    }
  }, [
    DroughtMonitor.getDroughtMapData,
    DroughtMonitor.isGetDroughtMapRequesting,
    DroughtMonitor.getDroughtMapDataError,
    DroughtMonitor.getDroughtMapDataNetworkError,
  ]);

  useEffect(() => {
    if (!_.isEmpty(mapControl)) {
      mapControl?.on('dividermove', map.dragging.enable());
    } else if (!_.isEmpty(map)) {
      map?.dragging?.enable();
    }
  }, [mapControl]);

  //useeffect for getting graph data from redux
  useEffect(() => {
    if (DroughtMonitor.isGetDroughtTimeseriesRequesting) {
      setIsGraphLoading(true);
      setGraphData([]);
    }
    if (DroughtMonitor.getDroughtTimeseriesData) {
      const { result } = DroughtMonitor.getDroughtTimeseriesData.data;
      let mapSeriesdata = {};
      let temporaryArrayData = [];
      temporaryArrayData.push(result.graph_data);
      mapSeriesdata.graph_data = temporaryArrayData;
      setGraphData(mapSeriesdata);
      setIsGraphLoading(false);
    } else if (DroughtMonitor.getDroughtTimeseriesDataError) {
      setGraphData([]);
      const {
        data: { response },
      } = DroughtMonitor.getDroughtTimeseriesDataError?.response;
      if (response?.data?.code === 400) {
        if (!_.isEmpty(response?.data?.errors)) {
          let errorsObj = response?.data?.errors;
          if (!_.isEmpty(errorsObj)) {
            const values = Object.values(errorsObj);
            setErrorData({ showError: true, data: values[0] });
          }
        } else {
          setErrorData({ showError: true, data: response?.data?.message });
        }
      } else {
        if (response?.data?.response === undefined) {
          setErrorData({ showError: true, data: t('ERROR.API_FAILURE_ERROR') });
        }
      }
      setIsGraphLoading(false);
    } else if (DroughtMonitor.getDroughtTimeseriesDataNetworkError) {
      setIsGraphLoading(false);
    }
  }, [
    DroughtMonitor.isGetDroughtTimeseriesRequesting,
    DroughtMonitor.getDroughtTimeseriesData,
    DroughtMonitor.getDroughtTimeseriesDataError,
    DroughtMonitor.getDroughtTimeseriesDataNetworkError,
  ]);

  //useeffect to reset all the map preferences states data when country gets changed
  useEffect(() => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
    let updatedLegend = '';

    if (selectedLegend.length) {
      updatedLegend = selectedLegend?.map(function (value, i) {
        if (value.add) {
          value.add = false;
        }
        return value;
      });
      setSelectedMapPreferLegendData(updatedLegend);
    }
    removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
  }, [selectedRegionId]);

  useEffect(() => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
    let updatedLegend = '';

    if (selectedLegend.length) {
      updatedLegend = selectedLegend?.map(function (value, i) {
        if (value.add) {
          value.add = false;
        }
        return value;
      });
      setSelectedMapPreferLegendData(updatedLegend);
    }
  }, [selectedLocation?.country]);

  //useeffect for adding drought Indices layers and legend into the map
  useEffect(() => {
    if (!_.isEmpty(mapData)) {
      let currentParamRef = layerRef.current[selectedParam?.paramIndex];
      let map_data = '';
      let opacityControl = '';
      let legendArray = [...selectedParamLegendData];
      let compareLegendArray = [...compareParamLegendData];
      let legendObj = {};
      let compareLegendObj = {};

      if (layerRef?.current?.length) {
        layerRef.current.map((i, index) => {
          let previousParamRef = layerRef.current[index];
          removeLayers(previousParamRef);
          previousParamRef?.current?.eachLayer((layer) => {
            removeTransparencyToolLayers(indexLayers, layer?._url);
          });
        });
      }

      if (Array.isArray(mapData)) {
        let data1 = mapData[0]?.map_data;
        let data2 = mapData[1]?.map_data;

        if (data1?.code === 400 || data2?.code === 400) {
          const filteredMapData = mapData.find((i) => i?.map_data?.code === 400);
          showError(filteredMapData?.map_data?.message);
          return false;
        }
        compareLegendObj = { ...data1 };
        compareLegendObj.id = selectedParam.name;
        compareLegendObj.add = true;
        compareLegendObj.dataSource = selectedParam.name;
        compareLegendObj.parameter = selectedParam.drought_indices_category_name;
        compareLegendObj.measure = data1?.units || '';
        compareLegendObj.bands = selectedGLDAS?.label || '';
        compareLegendObj.descret_legend = data1?.descret_legend || false;
        // compareLegendObj.startDate = initialSelectedDate ? initialSelectedDate?.startDate : null;
        // compareLegendObj.endDate = initialSelectedDate ? initialSelectedDate?.endDate : null;

        compareLegendObj.selectedDates = {
          startDate:moment(initialSelectedDate && initialSelectedDate?.startDate).format('DD-MM-YYYY'),
          endDate:moment(initialSelectedDate && initialSelectedDate?.endDate).format('DD-MM-YYYY')
        }
        

        legendObj = { ...data2 };
        legendObj.id = compareSelectedParam.name;
        legendObj.add = true;
        legendObj.dataSource = compareSelectedParam.name;
        legendObj.parameter = compareSelectedParam.drought_indices_category_name;
        legendObj.measure = data2?.units || '';
        legendObj.bands = compareSelectedGLDAS?.label || '';
        legendObj.descret_legend = data2?.descret_legend || false;
        // legendObj.startDate = finalSelectedDate ? finalSelectedDate?.startDate : null;
        // legendObj.endDate = finalSelectedDate ? finalSelectedDate?.endDate : null;

        legendObj.selectedDates = {
          startDate:moment(finalSelectedDate && finalSelectedDate?.startDate).format('DD-MM-YYYY'),
          endDate:moment(finalSelectedDate && finalSelectedDate?.endDate).format('DD-MM-YYYY')
        }

        if (currentParamRef?.current) {
          currentParamRef.current._url = data1.map_url;
          currentParamRef.current._url1 = data2.map_url;
          removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);

          if (currentParamRef?.current?._url1) {
            const firstLayer = L.tileLayer(currentParamRef.current._url);
            firstLayer?.addTo(map);
            const secondLayer = L.tileLayer(currentParamRef.current._url1);
            secondLayer?.addTo(map);
            let updatedIndexLayer = _.cloneDeep(indexLayers);
            updatedIndexLayer.push({ isCompare: true, layer: firstLayer });
            updatedIndexLayer.push({ isCompare: true, layer: secondLayer });
            setIndexLayers(updatedIndexLayer);
            const control = L.control.sideBySide(firstLayer, secondLayer);
            setLayerControl({ firstLayer, secondLayer });
            setMapControl(control);
            control?.addTo(map);
          }
        }
        compareLegendArray.push(compareLegendObj);
        setCompareParamLegendData(compareLegendArray);
      } else {
        if (mapData?.map_data && !_.isEmpty(mapData.map_data)) {
          map_data = mapData?.map_data;
        }
        if (map_data.code === 400) {
          showError('No record found . Please select different date range');
          return false;
        }
        legendObj = { ...map_data };
        legendObj.id = selectedParam.name;
        legendObj.add = true;
        legendObj.dataSource = selectedParam.name;
        legendObj.parameter = selectedParam.drought_indices_category_name;
        legendObj.measure = map_data?.units || '';
        legendObj.bands = selectedGLDAS?.label || '';
        legendObj.descret_legend = map_data?.descret_legend || false;
        // legendObj.startDate = initialSelectedDate ? initialSelectedDate?.startDate : null;
        // legendObj.endDate = initialSelectedDate ? initialSelectedDate?.endDate : null;
        legendObj.selectedDates = {
          startDate:moment(initialSelectedDate && initialSelectedDate?.startDate).format('DD-MM-YYYY'),
          // endDate:moment(initialSelectedDate && initialSelectedDate?.endDate).format('DD-MM-YYYY')
          endDate:moment(selectedParam && selectedParam?.max_date).format('DD-MM-YYYY')
        }

        // TODO: need to change looping through layers
        if (currentParamRef && currentParamRef.current) {
          currentParamRef.current?.eachLayer((layer) => {
            if (map_data) {
              layer.setUrl(map_data?.map_url);
              setMapUrl({ name: selectedParam.name, url: map_data?.map_url, layer });
              let updatedIndexLayer = _.cloneDeep(indexLayers);
              updatedIndexLayer.push({ isCompare: false, layer: layer });
              setIndexLayers(updatedIndexLayer);
              setMapLayerOpacity({
                layer,
              });
            }
          });
        }
        if (currentParamRef && currentParamRef.current !== null) {
          addLayers(currentParamRef);
        }
      }

      if (selectedParamLegendData.length) {
        legendArray.map((value, i) => {
          if (value.add) {
            value.add = false;
          }
          return value;
        });
      }

      if (map?.opacityControl) {
        map.removeControl(opacityControl);
      }
      legendArray.push(legendObj);
      setSelectedParamLegendData(legendArray);
    }
  }, [mapData]);

  //use effect for adding the opacity control slider in map
  // useEffect(() => {
  //   if (!_.isEmpty(mapLayerOpacity) && !_.isEmpty(mapLayerOpacity?.layer)) {
  //     if (!_.isEmpty(opacityControl)) map.removeControl(opacityControl);
  //     let customOpacityControl = '';
  //     customOpacityControl = L.control.opacity(mapLayerOpacity, {
  //       collapsed: false,
  //       position: 'topright',
  //     });
  //     setOpacityControl(customOpacityControl);
  //     customOpacityControl?.addTo(map);
  //   }
  // }, [mapLayerOpacity]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      let MapPrefereArr = [];
      mapPreferencesData?.map((item) => {
        let legendItem = JSON.parse(JSON.stringify(item.result));
        legendItem.parameter = item?.legendName ? item?.legendName : item?.dataSource;
        legendItem.slug = item.slug;
        legendItem.dataSource =
          item.slug === MAP_PREFERENCES_lABELS.JRC
            ? t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.WATER_OCCURRENCE')
            : item.slug === MAP_PREFERENCES_lABELS.MOD44
            ? t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.PERMANENT_WATER')
            : item?.category;
        legendItem.add = true;
        legendItem.measure = legendItem.units;
        legendItem.id = item?.dataSource;
        legendItem.legendId = item.id;
        legendItem.isCompare = false;
        legendItem.fromMapPreferences = true;
        if (selectedMapPreferLegendData?.length) {
          let isDuplicate = selectedMapPreferLegendData?.find(
            (x) => x.legendId === item.id && item.add === true,
          );
          if (_.isEmpty(isDuplicate)) {
            MapPrefereArr.push(legendItem);
          }
        } else {
          MapPrefereArr.push(legendItem);
        }
      });
      setSelectedMapPreferLegendData(MapPrefereArr);
    }
  }, [mapPreferencesData]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          let data = JSON.parse(JSON.stringify(item.data));
          if (
            item.config.name !== MAP_PREFERENCES_lABELS.DISTRICTS &&
            item.config.name !== MAP_PREFERENCES_lABELS.TALUKAS
          ) {
            selectedLayer.country = selectedLocation?.country;
            selectedLayer?.layer?.current?.clearLayers();
            selectedLayer.layer.current.addData(data);
            selectedLayer.color = item.config.color;
            setMapPreferences((prev) => {
              return {
                ...prev,
                [mapGeojsonItem]: selectedLayer,
              };
            });
          }
        }
      });
    }
  }, [geoJsonAllData, mapPreferences?.state?.layer?.current, selectedLocation?.country]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          if (
            !_.isEmpty(selectedLocation?.district) ||
            selectedLocation?.district?.name !== 'select'
          ) {
            let filteredLayer = returnFilteredGeoJsonData(
              selectedLayer,
              item,
              selectedLocation?.district,
              MAP_PREFERENCES_lABELS.DISTRICTS,
              MAP_PREFERENCES_lABELS.TALUKAS,
              selectedLocation?.country,
            );
            if (filteredLayer) {
              setMapPreferences((prev) => {
                return {
                  ...prev,
                  [mapGeojsonItem]: filteredLayer,
                };
              });
            }
          }

          if (
            !_.isEmpty(selectedLocation?.state) ||
            selectedLocation?.state?.id !== DEFAULT_COUNTRY.name
          ) {
            let filteredLayer = returnFilteredGeoJsonData(
              selectedLayer,
              item,
              selectedLocation?.state,
              MAP_PREFERENCES_lABELS.STATES,
              MAP_PREFERENCES_lABELS.DISTRICTS,
              selectedLocation?.country,
            );
            if (filteredLayer) {
              setMapPreferences((prev) => {
                return {
                  ...prev,
                  [mapGeojsonItem]: filteredLayer,
                };
              });
            }
          }
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    //bhanu
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          item.fromMapPreferences &&
          (_.isEmpty(selectedLocation?.state) ||
            selectedLocation?.state?.id === DEFAULT_COUNTRY.name) &&
          item.config.name === MAP_PREFERENCES_lABELS.DISTRICTS
        ) {
          selectedLayer.country = selectedLocation?.country;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(item.data);
          selectedLayer.color = item.config.color;
          setMapPreferences((prev) => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          item.fromMapPreferences &&
          (_.isEmpty(selectedLocation?.district) ||
            selectedLocation?.district?.name === 'select') &&
          item.config.name === MAP_PREFERENCES_lABELS.TALUKAS
        ) {
          selectedLayer.country = selectedLocation?.country;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(item.data);
          selectedLayer.color = item.config.color;
          setMapPreferences((prev) => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      if (mapPreferRefs?.current?.length) {
        mapPreferencesData?.map((item) => {
          let selectedMapPreferences = mapPreferRefs.current.find(
            (x) => x.current.boundary_id === item.id,
          );
          let currentParamRef = selectedMapPreferences.current?.layer;
          currentParamRef?.current?.eachLayer((mapLayer) => {
            let updatedIndexLayer = _.cloneDeep(indexLayers);
            updatedIndexLayer.push({ isCompare: false, layer: mapLayer });
            setIndexLayers(updatedIndexLayer);
            mapLayer.setUrl(item?.result?.map_url);
            setMapLayerOpacity({ layer: mapLayer });
          });
          if (currentParamRef && currentParamRef?.current) {
            addLayers(currentParamRef);
          }
        });
      }
    }
  }, [mapPreferencesData, mapPreferRefs.current]);

  useEffect(() => {
    if (!_.isEmpty(selectedParam)) {
      let paramCookie = Cookies.get('water-monitor-default-param');
      if (paramCookie === 'true') {
        setWaterMonitoringDefaultParam(false);
        handleApplyLayers(true);
      } else {
        return;
      }
    }
  }, [selectedParam]);

  window.onunload = function () {
    Cookies.remove('water-monitor-default-param', { path: '/' });
  };

  const returnFilteredGeoJsonData = (
    selectedLayer,
    item,
    location,
    propertyName,
    locationName,
    countryData,
  ) => {
    // let updatedSelectedLayer = _.cloneDeep(selectedLayer);
    if (selectedLayer?.layer && selectedLayer?.layer?.current) {
      let data = JSON.parse(JSON.stringify(item.data));
      let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
        (item) => item.selectedCountry === selectedLocation?.country?.name,
      );
      let labelprop = selectedCountryData ? selectedCountryData[propertyName] : '';
      if (item.config.name === locationName && !_.isEmpty(location)) {
        data.features = item.data?.features.filter((x) => {
          if (_.startCase(_.lowerCase(x?.properties[labelprop])) === location?.name) {
            selectedLayer.country = selectedLocation?.country;
            selectedLayer?.layer?.current?.clearLayers();
            return x;
          }
        });
        selectedLayer.layer.current.addData(data);
        selectedLayer.color = item.config.color;
      }
      return selectedLayer;
    }
  };

  const getMapPreferencesGeojsonData = (geoJsonList) => {
    let data = JSON.parse(JSON.stringify(geoJsonList));
    let mapPreferKeys = Object.keys(mapPreferences);
    if (mapPreferKeys) {
      mapPreferKeys.map((item) => {
        let selectedLayer = mapPreferences[item];
        if (selectedLayer?.layer && selectedLayer?.layer?.current)
          selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.color = '';
        setMapPreferences((prev) => {
          return {
            ...prev,
            [item]: selectedLayer,
          };
        });
      });
    }
    setGeoJsonData(data);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      if (tab === 'Forecast') {
        history.push(`/weather-forecast?key=weather-forecast`);
      } else if (tab === 'Monitoring') {
        history.push(`/weather-monitoring?key=weather-monitoring`);
      }
      //props.handleTab(tab)
    }
  };

  const removeMapLayers = (selectedParamLegendData, compareParamLegendData, layerControl) => {
    let previousParamRef = '';
    if (layerRef?.current?.length) {
      layerRef.current.map((i, index) => {
        previousParamRef = layerRef.current[index];
        previousParamRef?.current?.eachLayer((layer) => {
          removeTransparencyToolLayers(indexLayers, layer?._url);
        });
        removeLayers(previousParamRef);
      });
    }
    let legendArray = [...selectedParamLegendData];
    let compareLegendArray = [...compareParamLegendData];
    if (selectedParamLegendData.length) {
      legendArray.map((value, i) => {
        if (value.add) {
          value.add = false;
        }
        return value;
      });
    }
    if (compareParamLegendData.length) {
      compareLegendArray.map((value, i) => {
        if (value.add) {
          value.add = false;
        }
        return value;
      });
    }
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      removeTransparencyToolLayers(
        indexLayers,
        layerControl?.firstLayer?._url,
        layerControl?.secondLayer?._url,
      );
      map?.removeControl(mapControl);
    }
    setMapControl('');
    setLayerControl({
      firstLayer: {},
      secondLayer: {},
    });
    setSelectedParamLegendData(legendArray);
    setCompareParamLegendData(compareLegendArray);
  };

  // function to change the map attributes inside the map container
  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            cordinates: mapEvents.getCenter(),
            zoom: mapEvents.getZoom(),
          };
        });
      },
      dragend: () => {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            zoom: mapEvents.getZoom(),
            cordinates: mapEvents.getCenter(),
          };
        });
      },
    });

    const map = useMap();
    setInterval(function () {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 500);
    map.setView(center, zoom);
    return null;
  }

  //function to handle map preferences model toggling
  const handleMapModel = () => {
    setIsOpenMapModel(!isOpenMapModel);
  };

  //function to set map reference
  const setMapReference = (mapInstance) => {
    mapRef.current = mapInstance;
    setMap(mapInstance);
  };

  const handleEachFeatureChange = (countryData, locationType, feat, layer) => {
    let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
      (item) => item.selectedCountry === countryData?.name,
    );
    let labelprop = selectedCountryData ? selectedCountryData[locationType] : '';
    if (labelprop && feat.properties[labelprop]) {
      layer.bindTooltip(feat.properties[labelprop] + '', { sticky: true, direction: 'auto' });
    }
    /** TODO: alternative solution */

    // if(!_.isEmpty(layer) && !_.isEmpty(feat?.properties)){
    //   layer?.on({
    //     'mousemove': (e) => {
    //       const name = e.target.feature.properties['MJ_BASIN__'] ? e.target.feature.properties['MJ_BASIN__'] : '';
    //       layer?.bindTooltip(name)
    //       layer?.openTooltip(e?.latlng);
    //     },
    //     // 'mouseout': () => {
    //     //   layer?.unbindTooltip();
    //     //   layer?.closeTooltip();
    //     // },
    //   });
    // }
  };

  //function to set cordinates from side filter file
  const handleCordinates = (cordinates, zoom) => {
    if (applyLayers) {
      setApplyLayers(false);
    }
    setCordinateAndZoom((prev) => {
      return {
        ...prev,
        locationCordinates: cordinates,
        cordinates,
        zoom,
      };
    });
  };

  const handleIsCompare = (isClicked) => {
    setIsCompareClicked(isClicked);
  };

  //function to add layers in the map
  const addLayers = (selectedParamRef) => {
    if (mapRef.current && selectedParamRef.current) {
      const leafletMapRef = mapRef.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer, index) => {
        leafletMapRef.addLayer(layer);
      });
    }
    return false;
  };

  //function to remove layers from map
  const removeLayers = (selectedParamRef) => {
    if (mapRef && mapRef?.current && selectedParamRef && selectedParamRef?.current) {
      const map = mapRef.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => map.removeLayer(layer));
    }
  };

  const removeMapPreferenceLayer = (mapPreferRefs, mapPreferencesData) => {
    if (mapPreferRefs?.current?.length && mapPreferencesData?.length) {
      mapPreferencesData?.map((item) => {
        let selectedMapIndex = _.findIndex(
          mapPreferRefs.current,
          (list) => list.current.boundary_id === item.id,
        );
        let previousParamRef = mapPreferRefs?.current[selectedMapIndex].current.layer;
        if (previousParamRef && previousParamRef?.current) {
          previousParamRef?.current?.eachLayer((layer) => {
            removeTransparencyToolLayers(indexLayers, layer?._url);
          });
          removeLayers(previousParamRef);
        }
      });
    }
  };

  const getMapPreferencesData = (mapPreferencesLayerData) => {
    /** removing previously added map preferences layers before setting the state */
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    /** setting map preferences state*/
    let arr = JSON.parse(JSON.stringify(mapPreferencesLayerData));
    let paramData = '';
    let paramArr = '';
    if (selectedMapPreferLegendData?.length) {
      paramData = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      paramData?.map((item, index) => {
        if (item.add) {
          let legendItem = { ...item };
          legendItem.add = false;
          paramData[index] = legendItem;
        }
        return item;
      });
      paramArr = [...paramData];
    }
    setSelectedMapPreferLegendData(paramArr);
    setMapPreferencesData(arr);
  };

  const handleSelectedFilterRegion = (id) => {
    removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
    setSelectedRegionId(id);
  };

  const handleProps = (props) => {
    if (applyLayers) {
      setApplyLayers(false);
    }
    setTemporalAggregation(props.temporalAggregation);
    setRangeState(props?.rangeState);
    setCompareRangeState(props?.compareRangeState);
    setInitialSelectedDate(props.initialSelectedDate);
    // setSelectedParam(props.selectedParam);
  };

  //function for compare props
  const handleCompareProps = (props) => {
    setFinalSelectedDate(props.finalSelectedDate);
    setCompareSelectedParam(props.compareSelectedParam);
  };

  //function for removing transparency tool layers
  const removeTransparencyToolLayers = (indexLayers, firstUrl, secondUrl) => {
    let data = _.cloneDeep(indexLayers);
    let updatedData = data?.filter((i) => {
      if (secondUrl) {
        if (i?.layer?._url !== firstUrl && i?.layer?._url !== secondUrl) {
          return i;
        }
      } else {
        if (i?.layer?._url !== firstUrl) {
          return i;
        }
      }
    });
    setIndexLayers(updatedData);
  };

  //function to set all indices
  const handleParams = (param) => {
    let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
    let updatedLegend = '';

    if (selectedLegend.length) {
      updatedLegend = selectedLegend?.map(function (value, i) {
        if (value.add) {
          value.add = false;
        }
        return value;
      });
      setSelectedMapPreferLegendData(updatedLegend);
    }
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
    layerRef.current = [];
    if (param?.length) {
      param?.map((item) => {
        const ref = React.createRef();
        layerRef.current.push(ref);
      });
      setParams(param);
    }
  };

  //function to set selected indices
  const handleSelectedParam = (param) => {
    if (applyLayers) {
      setApplyLayers(false);
    }
    if (!_.isEmpty(selectedParam)) setPreviousParam(selectedParam);
    setSelectedParam(param);
    if (_.isEmpty(param)) {
      let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      let updatedLegend = '';

      if (selectedLegend.length) {
        updatedLegend = selectedLegend?.map(function (value, i) {
          if (value.add) {
            value.add = false;
          }
          return value;
        });
        setSelectedMapPreferLegendData(updatedLegend);
      }
      removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
      setMapPreferencesData([]);
      removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
    }
  };

  //function to set location
  const handleLocation = (country, state, district, basin, region) => {
    if (applyLayers) {
      setApplyLayers(false);
    }
    setSelectedLocation((prev) => {
      return {
        ...prev,
        country,
        state: !_.isEmpty(state) && state?.value !== 'select' ? state : '',
        district: !_.isEmpty(district) ? district : '',
        basin,
        region: selectedLocation?.region || null,
      };
    });
  };
  const handleSelectedLocation = (location) => {
    setSelectedLocation1(location);
  };
  //function to set compare location
  const handleCompareLocation = (country, state, district) => {
    setCompareLocation((prev) => {
      return {
        ...prev,
        country,
        state: !_.isEmpty(state) && state?.id !== DEFAULT_COUNTRY.name ? state : '',
        district: !_.isEmpty(district) ? district : '',
      };
    });
  };

  //function to set region
  const handleSelectedRegion = (region) => {
    let mapLayerData = [];
    let polyCount = 1;
    let mapData = JSON.parse(JSON.stringify(region));
    if (mapData?.length) {
      mapData.map((item) => {
        let x = Object.assign(item, {});
        if (item.layerType === 'polygon') {
          x.latlngs = item.latlngs.map((v) => [v[1], v[0]]);
          x?.latlngs?.push(item.latlngs[0]);
          if (!x.hasOwnProperty('polygonCount')) {
            x.polygonCount = polyCount;
            polyCount += 1;
          }
          mapLayerData.push(x);
        } else {
          x.latlngs = [item.latlngs[1], item.latlngs[0]];
          mapLayerData.push(x);
        }
      });
    }
    if ((!mapLayerData?.length && selectedLocation?.region !== null) || mapLayerData?.length) {
      setSelectedLocation((prev) => {
        return {
          ...prev,
          region: mapLayerData,
        };
      });
    }
  };

  const handleCompareView = (compareView) => {
    setCompareView(compareView);
  };

  const handleCompareCordinates = (cordinates) => {
    setCompareCordinates(cordinates);
  };

  const handleSelectedGlDASIndicator = (data) => {
    setSelectedGLDAS(data);
    // handleChartDisplay(false);
    setShowCharts(false);
  };

  const handleSelectedCompareGlDASIndicator = (data) => {
    setCompareSelectedGLDAS(data);
  };

  useEffect(() => {
    if (
      selectedParamLegendData?.length &&
      selectedParamLegendData?.some((i) => i.add && i.descret_legend) &&
      selectedMapPreferLegendData?.length &&
      selectedMapPreferLegendData?.some((i) => i.add && !i.descret_legend)
    ) {
      const leafletEle = document.getElementsByClassName('right-legend-style');
      if (leafletEle && leafletEle[0]) {
        leafletEle[0].classList.add('right-legend-pos-st');
      }
      const discreteLeafletEle = document.getElementsByClassName('right-discrete-legend-style');
      if (discreteLeafletEle && discreteLeafletEle[0]) {
        discreteLeafletEle[0].classList.add('right-discrete-legend-pos-st');
      }
    } else {
      const leafletEle = document.getElementsByClassName('right-legend-style');
      const rightPosLeafletEle = document.getElementsByClassName('right-legend-pos-st');
      const discreteLeafletEle = document.getElementsByClassName('right-discrete-legend-style');
      const rightPosdiscreteLeafletEle = document.getElementsByClassName(
        'right-discrete-legend-pos-st',
      );
      if (leafletEle && leafletEle[0] && rightPosLeafletEle) {
        leafletEle[0].classList.remove('right-legend-pos-st');
      }
      if (discreteLeafletEle && discreteLeafletEle[0] && rightPosdiscreteLeafletEle) {
        discreteLeafletEle[0].classList.remove('right-discrete-legend-pos-st');
      }
    }
  }, [selectedParamLegendData, selectedMapPreferLegendData]);

  useEffect(() => {
    if (
      isCompareClicked &&
      !_.isEmpty(compareSelectedParam) &&
      compareParamLegendData?.length &&
      compareParamLegendData?.some((i) => i.add && !i.descret_legend) &&
      selectedMapPreferLegendData?.length &&
      selectedMapPreferLegendData?.some((i) => i.add && i.descret_legend)
    ) {
      const leafletEle = document.getElementsByClassName('left-legend-style');

      if (leafletEle && leafletEle[0]) {
        leafletEle[0].classList.add('left-legend-pos-st');
      }

      const discreteLeafletEle = document.getElementsByClassName('left-discrete-legend-style');
      if (discreteLeafletEle && discreteLeafletEle[0]) {
        discreteLeafletEle[0].classList.add('left-discrete-legend-pos-st');
      }
    } else if (
      compareParamLegendData?.length &&
      compareParamLegendData?.findIndex((i) => i.add && !i.descret_legend) < 0
    ) {
      const leafletEle = document.getElementsByClassName('left-legend-style');
      const leftPosleafletEle = document.getElementsByClassName('left-legend-pos-st');

      const discreteLeafletEle = document.getElementsByClassName('left-discrete-legend-style');
      const leftPosdiscreteLeafletEle = document.getElementsByClassName(
        'left-discrete-legend-pos-st',
      );

      if (leafletEle && leafletEle[0] && leftPosleafletEle) {
        leafletEle[0].classList.remove('left-legend-pos-st');
      }
      if (discreteLeafletEle && discreteLeafletEle[0] && leftPosdiscreteLeafletEle) {
        discreteLeafletEle[0].classList.remove('left-discrete-legend-pos-st');
      }
    }
  }, [compareParamLegendData, selectedMapPreferLegendData, isCompareClicked, compareSelectedParam]);

  // useEffect(()=>{
  //   if(!updateGraph){
  //     const getLeafletDrawEle = document.getElementsByClassName('leaflet-draw');
  //     if(getLeafletDrawEle && getLeafletDrawEle[0]){
  //       getLeafletDrawEle[0]?.classList.add('dds-leaflet-draw-css');
  //     }
  //   }
  //   else if(updateGraph){
  //     const getLeafletDrawEle = document.getElementsByClassName('leaflet-draw');
  //     if(getLeafletDrawEle && getLeafletDrawEle[0]){
  //       getLeafletDrawEle[0]?.classList.remove('dds-leaflet-draw-css');
  //     }
  //   }
  // },[selectedModule, !updateGraph])

  // useEffect(() => {
  //   if (
  //     isCompareClicked &&
  //     !_.isEmpty(compareSelectedParam) &&
  //     compareView?.some(item => item.name === 'map' && item.selected)
  //   ) {
  //     const leafletEle = document.getElementsByClassName('leaflet-control-layers-expanded');
  //     if (leafletEle && leafletEle[0]) {
  //       leafletEle[0].classList.add('opacity-slider-style');
  //     }
  //   } else if (_.isEmpty(mapControl)) {
  //     const leafletEle = document.getElementsByClassName('leaflet-control-layers-expanded');
  //     if (leafletEle && leafletEle[0]) {
  //       leafletEle[0].classList.remove('opacity-slider-style');
  //     }
  //   }
  // }, [isCompareClicked, compareView, compareSelectedParam, mapControl]);

  //function to call map and time series api
  const handleApplyLayers = (applyLayers) => {
    if (applyLayers) {
      if (!_.isEmpty(temporalAggregation) && !_.isEmpty(selectedParam)) {
        setIsLoading(true);
        const requestObj = {};
        let paramSelected = Object.assign({}, selectedParam);
        if (
          selectedRegionId &&
          _.isEmpty(selectedLocation.country?.value) &&
          _.isEmpty(selectedLocation?.state?.name) &&
          _.isEmpty(selectedLocation?.district?.name)
        )
          requestObj.region_id = selectedRegionId;
        if (selectedModule.api_slug === 'drought-prediction') {
          requestObj.parameter_id = selectedParam?.id;
        }

        if (selectedLocation.country) {
          requestObj.country_name = selectedLocation?.country?.name || '';
        }
        if (selectedLocation?.state?.name) {
          requestObj.state_name = selectedLocation?.state?.name || '';
        }
        if (
          !_.isEmpty(selectedLocation?.district) &&
          selectedLocation?.district?.name !== 'Select'
        ) {
          requestObj.district_name = selectedLocation?.district?.name || '';
        } else {
          delete requestObj.district_name;
        }
        if (selectedLocation?.basin) {
          requestObj.basin_name = selectedLocation?.basin || '';
        }

        if (
          selectedParam?.slug === WEATHER_MONITOR_CONSTS.DRYSPELL ||
          selectedParam?.slug === WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
        ) {
          requestObj.temporal_aggregation =
            DROUGHT_TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
            temporalAggregation?.value;
        } else {
          requestObj.temporal_aggregation = temporalAggregation.value;
        }
        

        if (isCompareClicked) {
          let initialSelectedObj = JSON.parse(JSON.stringify(requestObj));
          let finalSelectedObj = JSON.parse(JSON.stringify(requestObj));
          let initialRequestResponse, finalRequestResponse;

          let initialSelectedParam = Object.assign({}, selectedParam);
          let compareParamSelected = Object.assign({}, compareSelectedParam);

          initialSelectedObj.start_date = initialSelectedDate.startDate;
          initialSelectedObj.end_date = initialSelectedDate.endDate;
          if (selectedParam?.slug === 'dryspell') {
            initialSelectedObj.precipitation_threshold = precipitationThreshold;
            initialSelectedObj.temporal_aggregation =
              DROUGHT_TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
              temporalAggregation?.value;
          }
          if (selectedParam?.slug === 'no_of_rainy_days') {
            initialSelectedObj.precipitation_threshold = extremeRainfallThreshold;
            initialSelectedObj.temporal_aggregation =
              DROUGHT_TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
              temporalAggregation?.value;
          }
          if (selectedParam.slug === 'gldas') {
            initialSelectedObj.band_id = selectedGLDAS?.id;
          }

          if (
            selectedParam?.slug !== WEATHER_MONITOR_CONSTS.DRYSPELL &&
            selectedParam?.slug !== WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
          ) {
            if (rangeState.min) initialSelectedObj.min = Number(rangeState.min);
            if (rangeState.max) initialSelectedObj.max = Number(rangeState.max);
          }

          finalSelectedObj.start_date = finalSelectedDate.startDate;
          finalSelectedObj.end_date = finalSelectedDate.endDate;
          if (
            compareParamSelected?.slug !== WEATHER_MONITOR_CONSTS.DRYSPELL &&
            compareParamSelected?.slug !== WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
          ) {
            if (compareRangeState.min) finalSelectedObj.min = Number(compareRangeState.min);
            if (compareRangeState.max) finalSelectedObj.max = Number(compareRangeState.max);
          }
          if (compareSelectedParam?.slug === 'dryspell') {
            finalSelectedObj.precipitation_threshold = comparePrecipitationThreshold;
            finalSelectedObj.temporal_aggregation =
              DROUGHT_TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
              temporalAggregation?.value;
          }
          
          finalSelectedObj.temporal_aggregation = temporalAggregation?.value ||
              DROUGHT_TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value
        
          if (compareSelectedParam?.slug === 'no_of_rainy_days') {
            finalSelectedObj.precipitation_threshold = compareExtremeRainfallThreshold;
            finalSelectedObj.temporal_aggregation =
              DROUGHT_TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
              temporalAggregation?.value;
          }
          if (compareSelectedParam.slug === 'gldas') {
            finalSelectedObj.band_id = compareSelectedGLDAS?.id;
          }
          initialRequestResponse = AXIOS_INSTANCE.post(
            `${WEATHER_MONITOR_URL}/${initialSelectedParam?.api_url?.split('/')[1]}/map`,
            initialSelectedObj,
          );

          finalRequestResponse = AXIOS_INSTANCE.post(
            `${WEATHER_MONITOR_URL}/${compareParamSelected?.api_url?.split('/')[1]}/map`,
            finalSelectedObj,
          );
          //}
          Promise.all([initialRequestResponse, finalRequestResponse])
            .then((responses) => {
              let responsesData = [];
              setIsLoading(false);
              responses?.map((item) => {
                const { data } = item;
                if (data.code === 200) {
                  responsesData.push(data.result);
                } else {
                  showError(data?.message);
                }
              });
              dispatch(
                getDroughtMapSuccess({ response: { data: responsesData, isDroughtModule: true } }),
              );
            })
            .catch((error) => {
              setIsLoading(false);
              if (error?.response) {
                let errorRes = error.response;
                if (!_.isEmpty(errorRes?.data?.errors)) {
                  showError(Object.values(errorRes?.data?.errors)[0]);
                } else {
                  showError(errorRes?.data?.message);
                }
              } else {
                showError('No records found. please try again');
              }
              removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
              return;
            });
        } else {
          if (
            selectedParam?.slug !== WEATHER_MONITOR_CONSTS.DRYSPELL &&
            selectedParam?.slug !== WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
          ) {
            if (rangeState.min) requestObj.min = Number(rangeState.min);
            if (rangeState.max) requestObj.max = Number(rangeState.max);
          }
          requestObj.start_date = initialSelectedDate.startDate;
          requestObj.end_date = initialSelectedDate.endDate;
          if (selectedParam.slug === 'dryspell') {
            requestObj.precipitation_threshold = precipitationThreshold;
          }
          if (selectedParam.slug === 'no_of_rainy_days') {
            requestObj.precipitation_threshold = extremeRainfallThreshold;
          }
          if (selectedParam.slug === 'gldas') {
            requestObj.band_id = selectedGLDAS?.id;
          }
          let isDroughtModule = true;
          let selectedIndices = selectedParam?.api_url?.split('/')[1];
          dispatch(
            getDroughtMapRequest(
              { selectedIndicator: selectedIndices },
              requestObj,
              isDroughtModule,
            ),
          );
        }
        // }
      }
      setApplyLayers(true);
      setShowCharts(false);
    }
  };

  //function to create overlays for the selected indices/params
  const renderParamLayer = (paramList) => {
    if (paramList && paramList?.length) {
      return paramList?.map((item, index) => {
        return (
          <>
            <Overlay name={item.name}>
              <LayerGroup ref={layerRef.current[item.paramIndex]}>
                <TileLayer
                  // attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url={''}
                  id={item.name}
                />
              </LayerGroup>
            </Overlay>
          </>
        );
      });
    }
  };

  const handleChartDisplay = () => {
    setShowCharts(!showCharts);
  };

  const handlePrecipitationThreshold = (value) => {
    setPrecipitationThreshold(value);
  };

  const handleCompareExtremePrecipitationThreshold = (value) => {
    setCompareExtremeRainfallThreshold(value);
  };

  const handleExtremePrecipitationThreshold = (value) => {
    setExtremeRainfallThreshold(value);
  };

  const handleComparePrecipitationThreshold = (value) => {
    setComparePrecipitationThreshold(value);
  };

  const handleSelectedModule = (module) => {
    setSelectedModule(module);
  };
  //function to toggle the chart at the bottom of map
  const handleUpdateGraph = (status, paramData) => {
    setShowCharts(status);
    if (!_.isEmpty(temporalAggregation) && !_.isEmpty(selectedParam)) {
      setIsGraphLoading(true);
      setErrorData({ showError: false, data: '' });
      setGraphData('');
      const requestObj = {};
      let paramSelected = Object.assign({}, selectedParam);

      if (
        selectedParam?.slug === WEATHER_MONITOR_CONSTS.DRYSPELL ||
        selectedParam?.slug === WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
      ) {
        requestObj.spatial_aggregation =
          DROUGHT_TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
          temporalAggregation?.value;
      } else {
        requestObj.spatial_aggregation = temporalAggregation.value;
      }
      requestObj.start_date = initialSelectedDate.startDate;
      requestObj.end_date = initialSelectedDate.endDate;

      let compareInitialObj = JSON.parse(JSON.stringify(requestObj));
      let compareFinalObj = JSON.parse(JSON.stringify(requestObj));

      if (
        selectedRegionId &&
        _.isEmpty(selectedLocation.country?.value) &&
        _.isEmpty(selectedLocation?.state?.name) &&
        _.isEmpty(selectedLocation?.district?.name)
      )
        requestObj.region_id = selectedRegionId;

      if (selectedModule.api_slug === 'drought-prediction') {
        requestObj.parameter_id = selectedParam?.id;
      }
      if (selectedLocation.country) {
        requestObj.country_name = selectedLocation?.country?.name || '';
      }
      if (selectedLocation?.state?.name) {
        requestObj.state_name = selectedLocation?.state?.name || '';
      }
      if (!_.isEmpty(selectedLocation?.district) && selectedLocation?.district?.name !== 'Select') {
        requestObj.district_name = selectedLocation?.district?.name || '';
      } else {
        delete requestObj.district_name;
      }
      // if (selectedLocation?.district?.name) {
      //   requestObj.district_name = selectedLocation?.district?.name || '';
      // }
      if (selectedLocation?.basin) {
        requestObj.basin_name = selectedLocation?.basin || '';
      }

      if (isCompareClicked) {
        let initialSelectedParam = Object.assign({}, selectedParam);
        let compareParamSelected = Object.assign({}, compareSelectedParam);

        compareInitialObj = { ...requestObj };
        if (initialSelectedParam?.slug === 'dryspell') {
          compareInitialObj.precipitation_threshold = precipitationThreshold;
          compareInitialObj.spatial_aggregation =
            DROUGHT_TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
            temporalAggregation?.value;
        }
        if (initialSelectedParam?.slug === 'no_of_rainy_days') {
          compareInitialObj.precipitation_threshold = extremeRainfallThreshold;
          compareInitialObj.spatial_aggregation =
            DROUGHT_TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value ||
            temporalAggregation?.value;
        }
        if (initialSelectedParam.slug === 'gldas') {
          compareInitialObj.band_id = selectedGLDAS?.id;
        }

        if (
          selectedParam?.slug !== WEATHER_MONITOR_CONSTS.DRYSPELL &&
          selectedParam?.slug !== WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
        ) {
          if (rangeState.min) compareInitialObj.min = Number(rangeState.min);
          if (rangeState.max) compareInitialObj.max = Number(rangeState.max);
        }
        if (
          selectedRegionId &&
          _.isEmpty(compareLocation.country?.value) &&
          _.isEmpty(compareLocation?.state?.name) &&
          _.isEmpty(compareLocation?.district?.name)
        )
          compareFinalObj.region_id = selectedRegionId;

        // if (compareLocation.country) {
        //   compareFinalObj.country_name = compareLocation?.country?.name || '';
        // }
        // if (compareLocation?.state?.name) {
        //   compareFinalObj.state_name = compareLocation?.state?.name || '';
        // }
        // if (compareLocation?.district?.name) {
        //   compareFinalObj.district_name = compareLocation?.district?.name || '';
        // }

        if (compareLocation.country) {
          compareFinalObj.country_name = compareLocation?.country?.name || '';
        } else {
          delete compareFinalObj.country_name;
        }
        if (compareLocation?.state?.name) {
          compareFinalObj.state_name = compareLocation?.state?.name || '';
        } else {
          delete compareFinalObj.state_name;
        }
        if (!_.isEmpty(compareLocation?.district) && compareLocation?.district?.name !== 'Select') {
          compareFinalObj.district_name = compareLocation?.district?.name || '';
        } else {
          delete compareFinalObj.district_name;
        }

        if (
          compareParamSelected?.slug !== WEATHER_MONITOR_CONSTS.DRYSPELL &&
          compareParamSelected?.slug !== WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
        ) {
          if (compareRangeState.min) compareFinalObj.min = Number(compareRangeState.min);
          if (compareRangeState.max) compareFinalObj.max = Number(compareRangeState.max);
        }

        compareFinalObj.spatial_aggregation = temporalAggregation?.value ||
        DROUGHT_TEMPORAL_AGGREGATION?.find((i) => i.value === 'mean')?.value 
        if (compareParamSelected?.slug === 'dryspell') {
          compareFinalObj.precipitation_threshold = comparePrecipitationThreshold;
        }
        if (compareParamSelected?.slug === 'no_of_rainy_days') {
          compareFinalObj.precipitation_threshold = compareExtremeRainfallThreshold;
        }
        if (compareParamSelected?.slug === 'gldas') {
          compareFinalObj.band_id = compareSelectedGLDAS?.id;
        }

        let initialRequestResponse, finalRequestResponse;

        let initalConfig = {};
        initalConfig.layerType = initialSelectedParam?.name;
        initalConfig.location =
          compareInitialObj?.district_name ||
          compareInitialObj?.state_name ||
          compareInitialObj?.country_name ||
          SOUTH_ASIA_CONST.name;

        let finalConfig = {};
        finalConfig.layerType = compareParamSelected?.name;
        finalConfig.location =
          compareFinalObj?.district_name ||
          compareFinalObj?.state_name ||
          compareFinalObj?.country_name ||
          SOUTH_ASIA_CONST.name;

        initialRequestResponse = AXIOS_INSTANCE.post(
          `${WEATHER_MONITOR_URL}/${initialSelectedParam?.api_url?.split('/')[1]}/timeseries`,
          compareInitialObj,
          initalConfig,
        );
        //}

        finalRequestResponse = AXIOS_INSTANCE.post(
          `${WEATHER_MONITOR_URL}/${compareParamSelected?.api_url?.split('/')[1]}/timeseries`,
          compareFinalObj,
          finalConfig,
        );
        // }

        Promise.all([initialRequestResponse, finalRequestResponse])
          .then((responses) => {
            setIsGraphLoading(false);
            let tempData = {};
            tempData.graph_data = responses.map((item) => {
              let graph_data = item?.data?.result?.graph_data;
              graph_data.layerType = item?.config?.layerType;
              graph_data.location = item?.config?.location;
              return graph_data;
            });
            setGraphData(JSON.parse(JSON.stringify(tempData)));
          })
          .catch((error) => {
            setIsGraphLoading(false);
            if (error?.response) {
              let errorRes = error.response;
              if (!_.isEmpty(errorRes?.data?.errors)) {
                setErrorData({ showError: true, data: Object.values(errorRes?.data?.errors)[0] });
              } else {
                setErrorData({ showError: true, data: errorRes?.data?.message });
              }
            } else {
              setErrorData({ showError: true, data: 'No records found. please try again' });
            }
          });
      } else {
        let timeSeriesObj = Object.assign({}, requestObj);
        if (
          selectedParam?.slug !== WEATHER_MONITOR_CONSTS.DRYSPELL &&
          selectedParam?.slug !== WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
        ) {
          if (rangeState.min) timeSeriesObj.min = Number(rangeState.min);
          if (rangeState.max) timeSeriesObj.max = Number(rangeState.max);
        }
        if (selectedParam.slug === 'dryspell') {
          timeSeriesObj.precipitation_threshold = precipitationThreshold;
        }
        if (selectedParam.slug === 'no_of_rainy_days') {
          timeSeriesObj.precipitation_threshold = extremeRainfallThreshold;
        }
        if (selectedParam.slug === 'gldas') {
          timeSeriesObj.band_id = selectedGLDAS?.id;
        }
        if (selectedLocation?.region && selectedLocation?.region?.length) {
          let API_URL = [];
          setIsGraphLoading(true);
          selectedLocation?.region.map((item) => {
            let arr = [];
            arr.push(item?.latlngs);
            timeSeriesObj.geometry = item.layerType === 'marker' ? arr : arr[0];
            let config = {
              layerType: item.layerType,
              latlng: item.layerType === 'marker' ? item.latlngs : '',
              polygonCount: item.layerType === 'polygon' ? item.polygonCount : '',
            };
            API_URL.push(
              AXIOS_INSTANCE.post(
                `${WEATHER_MONITOR_URL}/${paramSelected?.api_url?.split('/')[1]}/timeseries`,
                timeSeriesObj,
                config,
              ),
            );
          });
          setApplyLayers(true);
          Promise.all(API_URL)
            .then((response) => {
              setIsGraphLoading(false);
              let tempData = {};
              tempData.graph_data = response.map((item) => {
                let graph_data = item?.data?.result?.graph_data;
                graph_data.layerType = item?.config?.layerType;
                graph_data.latlng = item?.config?.latlng;
                graph_data.polygonCount = item?.config?.polygonCount;
                return graph_data;
              });
              setGraphData(JSON.parse(JSON.stringify(tempData)));
            })
            .catch((error) => {
              setIsGraphLoading(false);
              if (error?.response) {
                let errorRes = error.response;
                if (!_.isEmpty(errorRes?.data?.errors)) {
                  setErrorData({ showError: true, data: Object.values(errorRes?.data?.errors)[0] });
                } else {
                  setErrorData({ showError: true, data: errorRes?.data?.message });
                }
              } else {
                setErrorData({ showError: true, data: 'No records found. please try again' });
              }
            });

          return;
        }
        if (!selectedLocation?.region?.length) {
          let indicator_slug = paramSelected?.api_url?.split('/')[1];
          if (paramSelected?.slug === paramData?.slug) {
            dispatch(getDroughtTimeSeriesRequest(indicator_slug, timeSeriesObj));
          }
        }
      }
      // }
    }
  };
  const location =
    selectedLocation?.state?.name &&
    selectedLocation?.district?.name !== 'Select' &&
    selectedLocation?.district !== ''
      ? `${selectedLocation?.state?.name}_${selectedLocation?.district?.name}`
      : selectedLocation?.state?.name
      ? `${selectedLocation?.state?.name}`
      : '';
  const comparedLocation =
    compareLocation?.state?.name &&
    compareLocation?.district?.name !== 'Select' &&
    compareLocation?.district !== ''
      ? // compareLocation?.state?.name && compareLocation?.district?.name
        `${compareLocation?.state?.name}_${compareLocation?.district?.name}`
      : compareLocation?.state?.name
      ? `${compareLocation?.state?.name}`
      : '';

  useEffect(() => {
    const fileName =
      isCompareClicked && compareSelectedParam
        ? (selectedParam?.slug === 'gldas'
            ? selectedParam?.name + '-' + selectedGLDAS?.label
            : selectedParam?.name) +
          '-' +
          location +
          '_' +
          (compareSelectedParam?.slug === 'gldas'
            ? compareSelectedParam?.name + '-' + compareSelectedGLDAS?.label
            : compareSelectedParam?.name) +
          '-' +
          comparedLocation
        : (selectedParam?.slug === 'gldas'
            ? selectedParam?.name + '-' + selectedGLDAS?.label
            : selectedParam?.name) +
          '-' +
          location;
    if (fileName) setFileName(fileName);
  }, [
    isCompareClicked,
    selectedParam,
    compareSelectedParam,
    location,
    comparedLocation,
    selectedGLDAS,
    compareSelectedGLDAS,
  ]);

  //function to call drought chart map
  const renderChartsUI = () => {
    if (showCharts) {
      return (
        <>
          <div className="footer panel">
            <div className="footerArea  chart-design" style={ selectedParam?.slug === 'gldas' || compareSelectedParam?.slug === 'gldas' ? {flexGrow: 1, minHeight: '350px'} : { flexGrow: 1 }}>
              <FormGroup row>
                {errorData?.showError && (
                  <Col sm={12} md={12} lg={12}>
                    <div className="error-close-icon" onClick={handleChartDisplay}>
                      <CgCloseO size={25} color="#2A547C" />
                    </div>
                  </Col>
                )}
              </FormGroup>
              {!_.isEmpty(graphData) ? (
                <>
                  {/* <DroughtChart
                  renderCharts={handleChartDisplay}
                  chartData={graphData}
                  selectedParam={selectedParam}
                  compareParam={compareSelectedParam}
                  isCompareClicked={isCompareClicked}
                  temporalAggregation={temporalAggregation}
                  region={selectedLocation?.region}
                  initialSelectedDate={initialSelectedDate}
                /> */}
                  <DroughtPlotChart
                    fileName={fileName}
                    renderCharts={handleChartDisplay}
                    chartData={graphData}
                    selectedParam={selectedParam}
                    compareParam={compareSelectedParam}
                    isCompareClicked={isCompareClicked}
                    temporalAggregation={temporalAggregation}
                    region={selectedLocation?.region}
                    initialSelectedDate={initialSelectedDate}
                    selectedGLDASBand={selectedGLDAS}
                    compareSelectedGLDAS={compareSelectedGLDAS}
                    location={location}
                    comparedLocation={comparedLocation}
                  />
                </>
              ) : (
                <>
                  {isGraphLoading && (
                    <div className="drought-loader-container">
                      <div className="drought-loader">
                        <Loader />
                      </div>
                    </div>
                  )}
                  {errorData?.showError && (
                    <div className="drought-error-container">
                      <div className="drought-error-style">
                        <Alert color="warning">{errorData?.data}</Alert>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  };

  const handleSelectedItem = (value) => {
    setApplyLayerDisable(value);
  };

  //function to render map
  const renderMapContainer = () => {
    return (
      <>
        <MapContainer
          center={cordinateAndZoom.cordinates}
          id="drought-leaflet-map"
          zoom={cordinateAndZoom.zoom}
          className="drought-map-container"
          zoomControl={false}
          scrollWheelZoom={true}
          whenCreated={setMapReference}
        >
          <ChangeView center={cordinateAndZoom.cordinates} zoom={cordinateAndZoom.zoom} />
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer
                url={myConstClass.SATELLITE_TILE_LAYER_URL}
                options={{ tileSize: 256 }}
                attribution={`© <a href='https://www.mapbox.com/about/maps/' target='_blank'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a> ©<a href="https://www.maxar.com/" target='_blank'> Maxar</a>`}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked name="Streets">
              <TileLayer
                url={myConstClass.TITLE_LAYER_URL}
                options={{ tileSize: 256 }}
                attribution={`© <a href='https://www.mapbox.com/about/maps/' target='_blank'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a>`}
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          <ZoomControl position="topright" className="map-zoom-control" />
          {/* <DraggableMarker
            renderCharts={handleChartDisplay}
            cordinates={cordinateAndZoom.locationCordinates}
            handleCordinates={getMarkerPosition}
            forecastData={graphData}
            applyLayers={applyLayers}
          /> */}

          <DrawShape
            handleSelectedRegion={handleSelectedRegion}
            applyLayers={applyLayers}
            country={selectedLocation?.country}
            state={selectedLocation?.state}
            district={selectedLocation?.district}
            handleSelectedItem={handleSelectedItem}
          />
          <GeoJSON
            //  key={selectedLocation.country}
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.COUNTRY}
            ref={mapPreferences['country'].layer}
            style={{ color: mapPreferences['country'].color, fillColor: '#0000', weight: 1 }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['country'].country, 'country', feat, layer)
            }
          />
          <GeoJSON
            //  key={selectedLocation.country}
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.STATES}
            ref={mapPreferences['states_or_provinces'].layer}
            style={{
              color: mapPreferences['states_or_provinces'].color,
              fillColor: '#0000',
              weight: 1.6,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['states_or_provinces'].country,
                'states_or_provinces',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            //  key={selectedLocation.country}
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.DISTRICTS}
            ref={mapPreferences['districts_or_cities'].layer}
            style={{
              color: mapPreferences['districts_or_cities'].color,
              weight: 1,
              fillColor: '#0000',
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['districts_or_cities'].country,
                'districts_or_cities',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            //  key={selectedLocation.country}
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.METEOROLOGICAL_LOCATIONS}
            ref={mapPreferences['meteorological_locations'].layer}
            style={{
              color: mapPreferences['meteorological_locations'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['meteorological_locations'].country,
                'meteorological_locations',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            //  key={selectedLocation.country}
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.BASINS}
            ref={mapPreferences['basins'].layer}
            style={{
              color: mapPreferences['basins'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['basins'].country, 'basins', feat, layer)
            }
          />

          <GeoJSON
            //  key={selectedLocation.country}
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.RIVERS}
            ref={mapPreferences['rivers_or_reservoirs'].layer}
            style={{
              color: mapPreferences['rivers_or_reservoirs'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['rivers_or_reservoirs'].country,
                'rivers_or_reservoirs',
                feat,
                layer,
              )
            }
          />
          <GeoJSON
            //  key={selectedLocation.country}
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.TALUKAS}
            ref={mapPreferences['talukas'].layer}
            style={{
              color: mapPreferences['talukas'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['talukas'].country, 'talukas', feat, layer)
            }
          />

          {/* <LayersControl position="topleft">
            <TileLayer
              //attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
              url={myConstClass.TITLE_LAYER_URL}
            />
          </LayersControl> */}

          <LayersControl position="topleft">
            {mapPreferRefs?.current?.map((mapLayerRef, index) => {
              if (!_.isEmpty(mapLayerRef)) {
                return (
                  <>
                    <Overlay>
                      <LayerGroup ref={mapLayerRef.current.layer}>
                        <TileLayer
                          // attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
                          url=""
                        />
                      </LayerGroup>
                    </Overlay>
                  </>
                );
              }
            })}
          </LayersControl>
          <LayersControl position="topleft">{renderParamLayer(params)}</LayersControl>
          {selectedParamLegendData.length
            ? selectedParamLegendData.map((item) => {
                return (
                  <>
                    <LayersControl position="topleft">
                      {item?.descret_legend ? (
                        <DiscreteLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      ) : (
                        <CustomLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      )}
                    </LayersControl>
                  </>
                );
              })
            : ''}
          {compareParamLegendData.length
            ? compareParamLegendData.map((item) => {
                return (
                  <>
                    <LayersControl position="topleft">
                      {item?.descret_legend ? (
                        <DiscreteLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomleft"
                        />
                      ) : (
                        <CustomLegend map={map} add={item.add} layer={item} position="bottomleft" />
                      )}
                    </LayersControl>
                  </>
                );
              })
            : ''}

          {selectedMapPreferLegendData.length
            ? selectedMapPreferLegendData.map((item) => {
                return (
                  <>
                    <LayersControl position="topleft">
                      {item?.descret_legend ? (
                        <DiscreteLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomleft"
                        />
                      ) : (
                        <CustomLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      )}
                    </LayersControl>
                  </>
                );
              })
            : ''}
          <LayersControl position="topleft">
            <div className="logoContainer1">
              <Button className="map-preferences-button" onClick={handleMapModel}>
                <img src={mapPreferenceIcon} alt="map preference" width={15} />
              </Button>
            </div>
          </LayersControl>
        </MapContainer>
      </>
    );
  };

  return (
    <>
      <div className="global-top">
        <div className="w-100">
          {isLoading && <Loader />}
          <div className="sideBar col-lg-2">
            {/* <div className="forecast-monitoring">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === t('CLIMATE_WEATHER_FILTER.FORECAST') ? 'active' : ''}
            onClick={() => toggle(t('CLIMATE_WEATHER_FILTER.FORECAST'))}
          >
            <img src={ForecastIcon} />
            {t('CLIMATE_WEATHER_FILTER.FORECAST')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === t('CLIMATE_WEATHER_FILTER.MONITORING') ? 'active' : ''}
            onClick={() => toggle(t('CLIMATE_WEATHER_FILTER.MONITORING'))}
          >
            <img src={MonitoringIcon} />
            {t('CLIMATE_WEATHER_FILTER.MONITORING')}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <>
          <TabPane tabId={t('CLIMATE_WEATHER_FILTER.MONITORING')}>
          <DroughtSidebar
              popUpSelectedModule={popUpSelectedModule}
              isHideBar={isHideBar}
              setBar={setBar}
              selectedParam={selectedParam}
              selectedModule={selectedModule}
              handleCordinates={handleCordinates}
              handleProps={handleProps}
              handleCompareProps={handleCompareProps}
              handleSelectedParam={handleSelectedParam}
              handleParams={handleParams}
              handleLocation={handleLocation}
              handleCompareLocation={handleCompareLocation}
              handleApplyLayers={handleApplyLayers}
              isSelectedRegion={selectedLocation}
              renderCharts={handleUpdateGraph}
              handleIsCompare={handleIsCompare}
              handleCompareView={handleCompareView}
              handleCompareCordinates={handleCompareCordinates}
              handleSelectedRegion={handleSelectedFilterRegion}
              handleSelectedModule={handleSelectedModule}
            />
              </TabPane>
              </>
              </TabContent>
              </div> */}
            <DroughtSidebar
              popUpSelectedModule={popUpSelectedModule}
              activeTab={activeTab}
              isHideBar={isHideBar}
              precipitationThreshold={precipitationThreshold}
              handlePrecipitationThreshold={handlePrecipitationThreshold}
              comparePrecipitationThreshold={comparePrecipitationThreshold}
              extremeRainfallThreshold={extremeRainfallThreshold}
              compareExtremeRainfallThreshold={compareExtremeRainfallThreshold}
              handleComparePrecipitationThreshold={handleComparePrecipitationThreshold}
              handleExtremePrecipitationThreshold={handleExtremePrecipitationThreshold}
              handleCompareExtremePrecipitationThreshold={
                handleCompareExtremePrecipitationThreshold
              }
              setBar={setBar}
              handleToggle={toggle}
              selectedParam={selectedParam}
              selectedModule={selectedModule}
              handleCordinates={handleCordinates}
              handleProps={handleProps}
              handleCompareProps={handleCompareProps}
              handleSelectedParam={handleSelectedParam}
              handleParams={handleParams}
              handleSelectedGlDASIndicator={handleSelectedGlDASIndicator}
              selectedGLDAS={selectedGLDAS}
              handleLocation={handleLocation}
              handleCompareLocation={handleCompareLocation}
              handleSelectedLocation={handleSelectedLocation}
              handleApplyLayers={handleApplyLayers}
              isSelectedRegion={selectedLocation}
              updateGraph={updateGraph}
              setUpdateGraph={setUpdateGraph}
              renderCharts={handleUpdateGraph}
              handleIsCompare={handleIsCompare}
              handleCompareView={handleCompareView}
              setComparePrecipitationThreshold={setComparePrecipitationThreshold}
              setCompareExtremeRainfallThreshold={setCompareExtremeRainfallThreshold}
              handleCompareCordinates={handleCompareCordinates}
              handleSelectedRegion={handleSelectedFilterRegion}
              handleSelectedModule={handleSelectedModule}
              handleSelectedCompareGlDASIndicator={handleSelectedCompareGlDASIndicator}
              mapPreferencesData={mapPreferencesData}
              applyLayersDisable={applyLayersDisable}
              handleChartDisplay={handleChartDisplay}
              compareSelectedGLDAS={compareSelectedGLDAS}
            />
          </div>
          <Row>
            <Col lg={isHideBar ? 12 : 10} className="right-map map-position">
              <div
                style={{
                  height: '79.4vh',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <>
                  <SplitterLayout
                    ref={splitterRef}
                    primaryIndex={0}
                    vertical
                    percentage
                    primaryMinSize="30%"
                    secondaryInitialSize="70%"
                  >
                    <div style={{ height: '100%' }}>{renderMapContainer()}</div>
                    {renderChartsUI()}
                  </SplitterLayout>
                </>
              </div>
            </Col>
          </Row>
          {geoJsonLayersData?.length && getBoundariesData?.length ? ( 
            <MapPreferences
              isToggle={isOpenMapModel}
              handleToggle={handleMapModel}
              loc={selectedLocation?.country?.name}
              regionId={selectedRegionId}
              isMapPerferGeoJsonLoading={mapPreferenceGeoJsonloading}
              getMapPreferencesData={getMapPreferencesData}
              getMapPreferencesGeojsonData={getMapPreferencesGeojsonData}
              geoJsonData={geoJsonData}
              mapPreferencesAllData={mapPreferencesData}
              fromContingencyPlan={false}
              selectedParamLegendData={selectedParamLegendData}
              // stateData={selectedLocation?.state}
              staticData={selectedLocation1?.state}
              districtData={selectedLocation1?.district}
              countryData={selectedLocation1?.country}
              fromDroughtMonitor={true}
              cordinateAndZoom={cordinateAndZoom}
              selectedLocation1={selectedLocation}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default DroughtMap;