import React, { useState, useEffect } from 'react';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Card,
  CardText,
  Col,
  Button,
  CardTitle,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  Row,
  Collapse,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import moment from 'moment';
import ForecastIcon from '../../../assets/Images/forecast.svg';
import MonitoringIcon from '../../../assets/Images/monitoring.svg';
import InActiveForecastIcon from '../../../assets/Images/InactiveForecastIcon.svg';
import InActiveMonitoringIcon from '../../../assets/Images/InactiveMonitoringIcon.svg';
import { RiArrowDropUpLine, RiArrowDropDownLine } from 'react-icons/ri';
import { BiCurrentLocation, BiPlus, BiMinus } from 'react-icons/bi';
import { Field, reduxForm, change } from 'redux-form';
import { renderTextField, renderReactSelectInputField, renderDateField } from '../renderInputField';
import { isOpenWeatherDataSource } from '../../common/utils';
import {
  FORECAST_LEAD_TIME_LIST,
  DATA_SOURCES,
  WEATHER_FORECAST_MODULE,
  DATE_FORMAT,
  DEFAULT_COUNTRY,
} from '../../constants/index';
import ReactTooltip from 'react-tooltip';

const ForecastMonitoring = (props) => {
  const {
    region,
    isCompare,
    showToolTip,
    setCompareToggle,
    handleCompareToggle,
    isApplyLayer,
    temporalAggregation,
    setInvalidDate,
    countryList,
    defaultCountrySelected,
    hasSelectedParamChart,
    finalSelectedDate,
    handleCompareDataSourceChange,
    handleCountry,
    displayChart,
    stateList,
    districtList,
    showMapPreferenceTooltip,
    dataSources,
    defaultSelectedOption,
    isUpdateGraph,
    country,
    state,
    selectedFinalForecastYearMonth,
    handleFinalForecastYearMonth,
    defaultCompareSelectedOption,
    selectedDisctrict,
    selectedDataSource,
    handleSelectedItem,
    handleInitialDate,
    handleFinalDate,
    handleInitialForecastYearMonth,
    handleApplyLayers,
    handleOnChange,
    TEMPORAL_AGGREGATION,
    isOpenWeather,
    isIri,
    fromStaticResources,
    handleTemporalAggregation,
    handleOnChangeDates,
    forecastYearMonthHandler,
    forecastCompareYearMonthHandler,
    forecastLeadTimeHandler,
    forecastCompareLeadTimeHandler,
    selectedYearList,
    selectedDefaultYear,
    selectedDateRange,
    selectedDefaultDateRange,
    handleDateSelectionChange,
    handleYearSelectionChange,
    finalDateHandler,
    finalForecastYearMonthHandler,
    compareHandler,
    compareStateList,
    handleCompareSelectedItem,
    compareDistrictList,
    selectedCompareDistrict,
    compareCountry,
    compareState,
    forecastLeadTimeList,
    forecastCompareLeadTimeList,
    selectedForecastLeadTime,
    selectedFinalForercastLeadTime,
    isClickedOnPlus,
    IRIAvailableYearList,
    compareIRIAvailableYearList,
    IRIAvailableMonthList,
    compareIRIAvailableMonthList,
    forecastTimeYearHandler,
    compareForecastTimeYearHandler,
    finalNoaaEndDate,
    noaaMaxEndDate,
    finalNoaaMaxEndDate,
    noaaCompareMaxEndDate,
    finalNoaaCompareEndDate,
    finalNoaaCompareMaxEndDate,
    initialSelectedDate,
    IRIForecastTime,
    compareIRIForecastTime,
    forecastTimeMonthHandler,
    compareForecastTimeMonthHandler,
    IRIAvailableLeadTimeList,
    compareIRIAvailableLeadTimeList,
    isGraphLoading,
    openCollapse,
    setOpenCollapse,
  } = props;
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(t('CLIMATE_WEATHER_FILTER.FORECAST'));

  // const [isCompare, setIsCompare] = useState([
  //   { name: 'map', value: 'Map', selected: true },
  //   { name: 'graph', value: 'Graph', selected: false },
  // ]);
  const [indicators, setIndicators] = useState([
    { name: 'rainfall', value: 'Rainfall', selected: true },
    { name: 'rainy-days', value: 'No. of Rainy days', selected: false },
    { name: 'dry-spell', value: 'Dry Spell', selected: false },
    { name: 'temperature', value: 'Temperature', selected: false },
    { name: 'humidity', value: 'Humidity', selected: false },
  ]);
  const req_data = {
    country: '',
    state: '',
    district: '',
  };
  const [formData, setFormData] = useState(req_data);

  useEffect(() => {
    if (props.initialValues) {
      if (props.initialValues.country.id) {
        handleCountry(props.initialValues.country);
      }
    }
  }, [props.initialValues]);

  useEffect(() => {
    let isInvalidDate = false;
    let isStartDateInvalid =
      document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true' ? true : false;
    let isEndDateInvalid =
      document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true' ? true : false;
    let isCompareStartDateInvalid =
      document.getElementById('compareStartDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isCompareEndDateInvalid =
      document.getElementById('compareEndDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    if (
      isStartDateInvalid ||
      isEndDateInvalid ||
      isCompareStartDateInvalid ||
      isCompareEndDateInvalid
    ) {
      isInvalidDate = true;
    } else {
      isInvalidDate = false;
    }
    setInvalidDate(isInvalidDate);
  }, [initialSelectedDate, finalSelectedDate]);

  // const renderAPIParameterOptions = (list, action_name) => {
  //   console.log("list" , list)
  //   if (list) {
  //     let params = [...list];
  //     let options = [];
  //     if (params.length) {
  //       params.map(param => {
  //         let data = {
  //           value: param.name,
  //           label: `${param.name}`,
  //           id: param.id,
  //         };
  //         options.push(data);
  //         return true;
  //       });
  //       return options;
  //     }
  //   }
  // };

  const renderAPIParameterOptions = (list, action) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        // let default_option =
          // action === 'country' ? regionOptions : { name: 'select', label: 'Select', value: '' };
          // options.push(default_option);
          if (action==="forecast_lead_time_list") {
            params.map(param => {
              let data = {
                value: param.name,
                label: `${param.name}`,
                id: param.id,
              };
              options.push(data);
            return true;
          });
          }
          else {
            params.map((param) => {
              options.push(param);
              return true;
            });
          }
          // params.map((param) => {
          //   options.push(param);
          //   return true;
          // });
          
        return options;
      }
    }
  };

  const handleInfo = (e) => {
    e?.stopPropagation();
    window.open(
      `/user-guide-details?topicId=${WEATHER_FORECAST_MODULE.id}&&sub-topic-name=${selectedDataSource.firstDataSource[0].name}&topic_name=Weather  Water`,
    );
  };
  // //getting all country ,state ,district from store
  // const { Location, Weather } = useSelector(state => ({
  //   Location: state.Location,
  //   Weather: state.Weather,
  // }));
  const renderIRIParameterOptions = (list, action) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map((param) => {
          let data = {
            value: param.name,
            label: `${param.name}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  useEffect(() => {
    if (props.filterActiveTab) {
      setActiveTab(props.filterActiveTab);
    }
  }, [props.filterActiveTab]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      if (tab === 'Forecast') {
        history.push(`/weather-forecast?key=weather-forecast`);
      } else if (tab === 'Monitoring') {
        history.push(`/weather-monitoring?key=weather-monitoring`);
      }
    }
  };

  const handleCateogryToggle = (item) => {
    if (item === 'forecast-location') {
      openCollapse.forecast_location = !openCollapse.forecast_location;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'forecast-datetime') {
      openCollapse.forecast_datetime = !openCollapse.forecast_datetime;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'monitoring_timeframe') {
      openCollapse.monitoring_timeframe = !openCollapse.monitoring_timeframe;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'monitoring_compare') {
      openCollapse.monitoring_compare = !openCollapse.monitoring_compare;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'forecast_compare') {
      compareHandler();
      if (!region?.length) {
        setCompareToggle();
        openCollapse.forecast_compare = !openCollapse.forecast_compare;
        setOpenCollapse({ ...openCollapse });
      } else {
        if (isClickedOnPlus && !region?.length) {
          openCollapse.forecast_compare = !openCollapse.forecast_compare;
          setOpenCollapse({ ...openCollapse });
        }
      }
    } else if (item === 'monitoring_indicators') {
      openCollapse.monitoring_indicators = !openCollapse.monitoring_indicators;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'dataSources') {
      openCollapse.dataSources = !openCollapse.dataSources;
      setOpenCollapse({ ...openCollapse });
    }
  };

  const handleStateChange = (value) => {
    if (value.id === 'select') {
      formData.state = '';
      formData.district = '';
      formData.risk = '';
      formData.monsoon = '';
    } else {
      formData.state = value.id;
    }
    props.handleState(value);
  };

  const handleDistrictChange = (value) => {
    props.handleDistrict(value);
  };

  const handleforecastYearMonth = (e) => {
    formData.forecast_year_month = e.target.value;
    setFormData({ ...formData });
    forecastYearMonthHandler(e.target.value);
  };

  const handleForecastCompareYearMonth = (e) => {
    forecastCompareYearMonthHandler(e.target.value);
  };

  const handleForecastTimeYear = (e) => {
    forecastTimeYearHandler(e);
  };

  const handleForecastTimeMonth = (e) => {
    forecastTimeMonthHandler(e);
  };

  const handleFormFeedBackMsg = (msg) => {
    return <FormFeedback invalid>{msg}</FormFeedback>;
  };

  const renderToolTip = () => {
    return (
      <ReactTooltip id="view_graph" effect="solid" place="right" type="info" className="bg-dark">
        {t('FILTER.MEDIAN_VIEW_GRAPH_DISABLE')}
      </ReactTooltip>
    );
  };

  const handleCompareForecastTimeYear = (e) => {
    compareForecastTimeYearHandler(e);
  };

  const handleCompareForecastTimeMonth = (e) => {
    compareForecastTimeMonthHandler(e);
  };

  const handleForecastLeadTimeChange = (e) => {
    formData.forecast_lead_time = e;
    setFormData({ ...formData });
    forecastLeadTimeHandler(e);
  };

  const handleCompareForecastLeadTimeChange = (e) => {
    forecastCompareLeadTimeHandler(e);
  };

  const handleLocationRender = () => {
    return (
      <div className="advance-search-fields mb-3">
        <div className="mx-3 mt-2 mb-3">
          <Select
            id="multi-select"
            name="country"
            placeholder="Select Country"
            options={renderAPIParameterOptions(countryList)}
            onChange={handleSelectedItem}
            isMulti={false}
            value={props.selectedLocation?.country}
            defaultValue={props.selectedLocation?.country}
            required={true}
            isDisabled={true}
            className="mt-2 mb-3"
          />
          {country && state && (
            <Select
              id="multi-select"
              name="state"
              placeholder="Province"
              options={renderAPIParameterOptions(stateList)}
              onChange={handleSelectedItem}
              isMulti={false}
              required={true}
              value={state}
              component={renderReactSelectInputField}
              className="mt-2 mb-3"
            />
          )}

          {state && state?.value !== DEFAULT_COUNTRY.name && (
            <Select
              id="multi-select"
              name="district"
              placeholder="Select District"
              options={renderAPIParameterOptions(districtList)}
              onChange={handleSelectedItem}
              isMulti={false}
              required={true}
              value={selectedDisctrict}
              className="mt-2 mb-3"
            />
          )}
        </div>
      </div>
    );
  };

  const handleDataSourcesRender = () => {
    return (
      <div
        className="advance-search-fields 
      data-source-field-one 
      icon-dsg
      mb-3 "
        // data-source-field-one
        // icon-dsg
      >
        {/* <div className="mx-3 mt-2 mb-3"> */}
        <Select
          id="multi-select"
          name="data-sources"
          placeholder="Select data source"
          defaultValue={selectedDataSource.firstDataSource[0]}
          options={dataSources.firstDataSources || []}
          value={selectedDataSource.firstDataSource[0] || []}
          onChange={handleOnChange}
          isMulti={false}
          required={true}
          className="ow-width"
          // className="my-3"
        />
        <span className="info-style">
          <i
            className="cursor-ponter fa fa-info info-icon-edit-btn"
            id="info-icon-tool-tip"
            data-tip
            data-for="info-icon-tool-tip"
            onClick={handleInfo}
          />
          <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
            Click here to know more.
          </ReactTooltip>
        </span>
        {/* </div> */}
      </div>
    );
  };

  const handleDateTimeRender = () => {
    return (
      <div className="advance-search-fields mb-3">
        <div className="mx-3 mt-2 mb-3">
          {/* <Select
            id="multi-select"
            name="data-sources"
            placeholder="Select data source"
            defaultValue={selectedDataSource.firstDataSource[0]}
            options={dataSources.firstDataSources || []}
            value={selectedDataSource.firstDataSource[0]}
            onChange={handleOnChange}
            isMulti={false}
            required={true}
            className="my-3"
          /> */}
          {!fromStaticResources ? (
            selectedDataSource?.firstDataSource[0]?.id ===
            'e2f886a7-3299-4c82-af2e-123fe92faf84' ? (
              <>
                <Select
                  placeholder="Select Forecast Time Year"
                  className="placeholder-style my-3"
                  type="select"
                  onChange={handleForecastTimeYear}
                  required={true}
                  isMulti={false}
                  value={IRIForecastTime?.year}
                  options={renderAPIParameterOptions(IRIAvailableYearList)}
                  id="forecast_category"
                />
                <Select
                  placeholder="Select Forecast Time Month"
                  className="placeholder-style my-3"
                  type="select"
                  onChange={handleForecastTimeMonth}
                  required={true}
                  isMulti={false}
                  value={IRIForecastTime?.month}
                  options={renderAPIParameterOptions(IRIAvailableMonthList)}
                  id="forecast_category"
                />
                <Select
                  placeholder="Select Forecast Lead Time"
                  className="placeholder-style my-3"
                  type="select"
                  onChange={handleForecastLeadTimeChange}
                  required={true}
                  isMulti={false}
                  value={IRIForecastTime?.lead_time}
                  options={renderAPIParameterOptions(IRIAvailableLeadTimeList , "forecast_lead_time_list")}
                  id="forecast_category"
                />
              </>
            ) : (
              <>
                {/* <> */}
                <Input
                  type="date"
                  name="startDate"
                  placeholder="Start date"
                  id="startDate"
                  bsSize="sm"
                  className={
                    document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true'
                      ? 'input-style invalid-filter-date'
                      : 'my-3 input-style'
                  }
                  // value={handleInitialDate('startDate')}
                  value={initialSelectedDate?.map?.startDate}
                  onChange={(e) => handleOnChangeDates('startDate', e)}
                  min={handleInitialDate('minStartDate')}
                  max={handleInitialDate('maxStartDate')}
                  disabled={isOpenWeatherDataSource(selectedDataSource?.firstDataSource)}
                  invalid={
                    new Date(handleInitialDate('minStartDate')) >
                      new Date(handleInitialDate('startDate')) ||
                    new Date(handleInitialDate('maxStartDate')) <
                      new Date(handleInitialDate('startDate'))
                    // || handleInitialDate('startDate') === "Invalid date"
                  }
                />
                {document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true'
                  ? handleFormFeedBackMsg('Invalid date')
                  : ''}
                <Input
                  type="date"
                  name="endDate"
                  id="endDate"
                  placeholder="End date"
                  bsSize="sm"
                  className="my-2 input-style"
                  // value={noaaMaxEndDate ? finalNoaaEndDate : handleInitialDate('endDate')}
                  value={noaaMaxEndDate ? finalNoaaEndDate : initialSelectedDate?.map?.endDate}
                  onChange={(e) => handleOnChangeDates('endDate', e)}
                  min={handleInitialDate('startDate')}
                  max={noaaMaxEndDate ? finalNoaaMaxEndDate : handleInitialDate('maxEndDate')}
                  disabled={isOpenWeatherDataSource(selectedDataSource?.firstDataSource)}
                  invalid={
                    new Date(handleInitialDate('startDate')) >
                      new Date(handleInitialDate('endDate')) ||
                    new Date(handleInitialDate('maxEndDate')) <
                      new Date(handleInitialDate('endDate')) ||
                    // || handleInitialDate('endDate') === "Invalid date"
                    new Date(handleInitialDate('startDate')).getTime() ===
                      new Date(handleInitialDate('endDate')).getTime()
                    // || finalNoaaEndDate > finalNoaaMaxEndDate
                  }
                />
                {document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true'
                  ? new Date(handleInitialDate('startDate')).getTime() ===
                    new Date(handleInitialDate('endDate')).getTime()
                    ? handleFormFeedBackMsg('start date and  end date should not be equal')
                    : handleFormFeedBackMsg('Invalid date')
                  : ''}
              </>
            )
          ) : (
            <>
              <div className="mt-2 mb-3">
                <Select
                  id="multi-select1"
                  name="static-resource-year"
                  options={selectedYearList || []}
                  defaultValue={selectedDefaultYear}
                  value={selectedDefaultYear}
                  onChange={(e) => handleYearSelectionChange(e)}
                  isMulti={false}
                  required={true}
                />
              </div>
              <div className="mt-2 mb-3">
                <Select
                  id="multi-select1"
                  name="date-range"
                  options={selectedDateRange || []}
                  defaultValue={selectedDefaultDateRange}
                  value={selectedDefaultDateRange}
                  onChange={(e) => handleDateSelectionChange(e)}
                  isMulti={false}
                  required={true}
                />
              </div>
            </>
          )}
          {/* {!fromStaticResources && !isOpenWeather && !isIri ? (
            <div className="mt-3 temp-style">
              <div className="mt-2 mb-3">
                <Select
                  id="multi-select"
                  name="aggregation"
                  placeholder="Temporal Aggregation"
                  options={TEMPORAL_AGGREGATION}
                  value={temporalAggregation}
                  onChange={handleTemporalAggregation}
                  isMulti={false}
                  required={true}
                />
              </div>
            </div>
          ) : (
            ''
          )} */}
        </div>
      </div>
    );
  };

  const handleCompareLocationRender = () => {
    let isGraphCompare = isCompare.find((item) => item.name === 'graph' && item.selected);
    if (isGraphCompare) {
      return (
        <>
          <div className="pt-2 pb-2">
            <div className="mt-2 mb-3">
              <Select
                id="multi-select"
                name="country"
                placeholder="SELECT COUNTRY"
                options={renderAPIParameterOptions(countryList)}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={defaultCompareSelectedOption}
                defaultValue={defaultCompareSelectedOption}
                isDisabled={true}
              />
            </div>
            {compareCountry && (
              <div className="mt-2">
                <Select
                  id="multi-select"
                  name="state"
                  placeholder="Province"
                  options={renderAPIParameterOptions(compareStateList)}
                  onChange={handleCompareSelectedItem}
                  isMulti={false}
                  required={true}
                  value={compareState}
                />
              </div>
            )}
            {compareState && compareState?.value !== DEFAULT_COUNTRY.name && (
              <div className="mt-2">
                <Select
                  id="multi-select"
                  name="district"
                  placeholder="Select district"
                  options={renderAPIParameterOptions(compareDistrictList)}
                  onChange={handleCompareSelectedItem}
                  isMulti={false}
                  required={true}
                  value={selectedCompareDistrict}
                />
              </div>
            )}
          </div>
        </>
      );
    }
  };

  const renderCompareDates = (isCompare) => {
    let isGraphCompare = isCompare.find((item) => item.name === 'graph');
    if (!isGraphCompare.selected) {
      return selectedDataSource?.secondDataSource[0]?.id !== DATA_SOURCES.IRI.id ? (
        <>
          <div className="mx-3 mb-3">
            <Input
              type="date"
              placeholder="Start date"
              bsSize="sm"
              id="compareStartDate"
              className={
                document.getElementById('compareStartDate')?.getAttribute('aria-invalid') === 'true'
                  ? 'mt-1 input-style invalid-filter-date'
                  : 'mt-1 my-3 input-style'
              }
              // value={handleFinalDate('startDate')}
              value={finalSelectedDate?.startDate}
              min={handleFinalDate('minStartDate')}
              max={handleFinalDate('maxStartDate')}
              onChange={(e) => finalDateHandler(e, 'start_date')}
              invalid={
                new Date(handleFinalDate('minStartDate')) >
                  new Date(handleFinalDate('startDate')) ||
                new Date(handleFinalDate('maxStartDate')) <
                  new Date(handleFinalDate('startDate')) ||
                handleFinalDate('startDate') === 'Invalid date'
              }
              disabled={isOpenWeatherDataSource(selectedDataSource?.secondDataSource)}
            />
            {document.getElementById('compareStartDate')?.getAttribute('aria-invalid') === 'true'
              ? handleFormFeedBackMsg('Invalid date')
              : ''}
            <Input
              type="date"
              placeholder="End date"
              bsSize="sm"
              id="compareEndDate"
              className={
                document.getElementById('compareEndDate')?.getAttribute('aria-invalid') === 'true'
                  ? 'mt-1 input-style invalid-filter-date'
                  : 'mt-1 my-3 input-style'
              }
              // value={noaaCompareMaxEndDate ? finalNoaaCompareEndDate : handleFinalDate('endDate')}
              value={noaaCompareMaxEndDate ? finalNoaaCompareEndDate : finalSelectedDate.endDate}
              // value={finalSelectedDate.endDate}
              min={handleFinalDate('startDate')}
              max={
                noaaCompareMaxEndDate ? finalNoaaCompareMaxEndDate : handleFinalDate('maxEndDate')
              }
              invalid={
                new Date(handleFinalDate('startDate')) > new Date(handleFinalDate('endDate')) ||
                new Date(handleFinalDate('maxEndDate')) < new Date(handleFinalDate('endDate')) ||
                handleFinalDate('endDate') === 'Invalid date' ||
                new Date(handleFinalDate('startDate')).getTime() ===
                  new Date(handleFinalDate('endDate')).getTime()
              }
              onChange={(e) => finalDateHandler(e, 'end_date')}
              disabled={isOpenWeatherDataSource(selectedDataSource?.secondDataSource)}
            />
            {document.getElementById('compareEndDate')?.getAttribute('aria-invalid') === 'true'
              ? new Date(handleFinalDate('startDate')).getTime() ===
                new Date(handleFinalDate('endDate')).getTime()
                ? handleFormFeedBackMsg('start date and  end date should not be equal')
                : handleFormFeedBackMsg('Invalid date')
              : ''}
          </div>
        </>
      ) : (
        <>
          <div className="mt-1 my-3 mx-3"></div>
          <Select
            placeholder="Select Forecast Year"
            className="placeholder-style mx-3 my-3"
            type="select"
            onChange={handleCompareForecastTimeYear}
            required={true}
            isMulti={false}
            options={renderAPIParameterOptions(compareIRIAvailableYearList)}
            id="forecast_category"
            value={compareIRIForecastTime?.year}
          />

          <Select
            placeholder="Select Forecast Month"
            className="placeholder-style mx-3 my-3"
            type="select"
            onChange={handleCompareForecastTimeMonth}
            required={true}
            isMulti={false}
            value={compareIRIForecastTime?.month}
            options={renderAPIParameterOptions(compareIRIAvailableMonthList)}
            id="forecast_category"
          />

          <Select
            placeholder="Select Forecast Lead Time"
            className="placeholder-style mx-3 my-3"
            type="select"
            onChange={handleCompareForecastLeadTimeChange}
            required={true}
            isMulti={false}
            value={compareIRIForecastTime?.lead_time}
            options={renderAPIParameterOptions(compareIRIAvailableLeadTimeList , "forecast_lead_time_list")}
            id="forecast_category"
          />
        </>
      );
    } else {
      if (selectedDataSource?.secondDataSource[0]?.id === DATA_SOURCES.IRI.id) {
        if (
          selectedDataSource?.firstDataSource[0]?.id !== selectedDataSource?.secondDataSource[0]?.id
        ) {
          return (
            <>
              <Select
                placeholder="Select Forecast Year"
                className="placeholder-style my-3"
                type="select"
                onChange={handleCompareForecastTimeYear}
                required={true}
                isMulti={false}
                options={renderIRIParameterOptions(
                  compareIRIAvailableYearList,
                  'compare_forecast_year_list',
                )}
                value={compareIRIForecastTime?.year}
                id="forecast_category"
              />

              <Select
                placeholder="Select Forecast Month"
                className="placeholder-style my-3"
                type="select"
                onChange={handleCompareForecastTimeMonth}
                required={true}
                isMulti={false}
                value={compareIRIForecastTime?.month}
                options={renderIRIParameterOptions(
                  compareIRIAvailableMonthList,
                  'compare_forecast_month_list',
                )}
                id="forecast_category"
              />
              <Select
                placeholder="Select Forecast Lead Time"
                className="placeholder-style my-3"
                type="select"
                onChange={handleCompareForecastLeadTimeChange}
                required={true}
                isMulti={false}
                value={compareIRIForecastTime?.lead_time}
                options={renderIRIParameterOptions(
                  compareIRIAvailableLeadTimeList,
                  'compare_forecast_lead_time_list',
                )}
                id="forecast_category"
              />
            </>
          );
        }
      }
    }
  };

  const handleForecastCompare = (isCompare) => {
    return (
      <div className="advance-search-fields">
        <div className="mx-3 mt-2">
          <FormGroup check>
            <Row className="compare-style">
              {isCompare.map((item) => {
                return (
                  <>
                    <Col sm={4} lg={4} md={4} className="mt-2">
                      <Label check>
                        <Input
                          type="radio"
                          name="radio1"
                          value={item.value}
                          defaultChecked={item.selected}
                          checked={item.selected}
                          onChange={handleCompareToggle}
                        />{' '}
                        {item.value}
                      </Label>
                    </Col>{' '}
                  </>
                );
              })}
            </Row>
          </FormGroup>
          {handleCompareLocationRender()}
          <div className="mx-3 data-source-field"></div>
        </div>
      </div>
    );
  };

  const handleIndicators = () => {
    return (
      <div className="advance-search-fields mb-3">
        <div className="mx-3 mt-2 mb-3">
          <FormGroup check>
            {indicators.map((item) => {
              return (
                <Row>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      value={item.value}
                      defaultChecked={item.selected}
                      //onChange={handleCompareToggle}
                    />{' '}
                    {item.value}
                  </Label>
                </Row>
              );
            })}
          </FormGroup>
        </div>
      </div>
    );
  };

  return (
    <div className="forecast-monitoring">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === t('CLIMATE_WEATHER_FILTER.FORECAST') ? 'active' : ''}
            onClick={() => toggle(t('CLIMATE_WEATHER_FILTER.FORECAST'))}
          >
            {activeTab === t('CLIMATE_WEATHER_FILTER.FORECAST') ? (
              <img src={ForecastIcon} />
            ) : (
              <img src={InActiveForecastIcon} />
            )}

            <span className="tab_title">{t('CLIMATE_WEATHER_FILTER.FORECAST')}</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === t('CLIMATE_WEATHER_FILTER.MONITORING') ? 'active' : ''}
            onClick={() => toggle(t('CLIMATE_WEATHER_FILTER.MONITORING'))}
          >
            {activeTab === t('CLIMATE_WEATHER_FILTER.MONITORING') ? (
              <img src={MonitoringIcon} />
            ) : (
              <img src={InActiveMonitoringIcon} />
            )}
            <span className="tab_title">{t('CLIMATE_WEATHER_FILTER.MONITORING')}</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <>
          <TabPane tabId={t('CLIMATE_WEATHER_FILTER.FORECAST')}>
            {/* {props?.stageList?.length !== 0 && ( */}
            <div className="advance-search-fields">
              <div className="data-source-field-one">
                {!fromStaticResources && (
                  <>
                    <span onClick={() => handleCateogryToggle('forecast-location')}>
                      <p
                        className="map-preferences-labels"
                        //style={checkboxStyle}
                      >
                        {/* {item.category} */}
                        Location
                        <span className="category-span">
                          <span className="category-span">
                            {openCollapse.forecast_location ? (
                              <RiArrowDropUpLine size={25} />
                            ) : (
                              <RiArrowDropDownLine size={25} />
                            )}
                          </span>
                        </span>
                      </p>
                    </span>
                    <Collapse
                      id="forecast-location"
                      isOpen={openCollapse.forecast_location}
                      className="collapse-moadal"
                    >
                      {handleLocationRender()}
                    </Collapse>
                  </>
                )}
                <span onClick={() => handleCateogryToggle('dataSources')}>
                  <p
                    className="map-preferences-labels"
                    //style={checkboxStyle}
                  >
                    {/* {item.category} */}
                    Data Sources
                    <span className="category-span">
                      <span className="category-span">
                        {openCollapse.dataSources ? (
                          <RiArrowDropUpLine size={25} />
                        ) : (
                          <RiArrowDropDownLine size={25} />
                        )}
                      </span>
                    </span>
                  </p>
                </span>
                <Collapse
                  id="dataSources"
                  isOpen={openCollapse.dataSources}
                  className="collapse-moadal"
                >
                  {handleDataSourcesRender()}
                  {/* {returnCheckbox(item, item.data, index)} */}
                </Collapse>
                <span onClick={() => handleCateogryToggle('forecast-datetime')}>
                  <p
                    className="map-preferences-labels"
                    //style={checkboxStyle}
                  >
                    {/* {item.category} */}
                    Date & Timeframe
                    <span className="category-span">
                      <span className="category-span">
                        {openCollapse.forecast_datetime ? (
                          <RiArrowDropUpLine size={25} />
                        ) : (
                          <RiArrowDropDownLine size={25} />
                        )}
                      </span>
                    </span>
                  </p>
                </span>
                <Collapse
                  id="forecast_datetime"
                  isOpen={openCollapse.forecast_datetime}
                  className="collapse-moadal"
                >
                  {handleDateTimeRender()}
                  {/* {returnCheckbox(item, item.data, index)} */}
                </Collapse>
                {!fromStaticResources && !region?.length && (
                  <>
                    <span onClick={() => handleCateogryToggle('forecast_compare')}>
                      <p
                        className="map-preferences-labels"
                        //style={checkboxStyle}
                      >
                        {/* {item.category} */}
                        Compare
                        <span className="category-span">
                          {openCollapse.forecast_compare ? (
                            <BiMinus size={25} />
                          ) : (
                            <BiPlus size={25} />
                          )}
                        </span>
                      </p>
                    </span>
                    <Collapse
                      id="forecast_compare"
                      // isOpen={openCollapse.forecast_compare}
                      isOpen={isClickedOnPlus}
                      className="collapse-moadal"
                    >
                      {handleForecastCompare(isCompare)}
                      {/* {returnCheckbox(item, item.data, index)} */}

                      <div className="mt-2 mb-3 mx-3 data-source-field">
                        <Select
                          id="multi-select"
                          name="data-sources"
                          placeholder="Select data source"
                          defaultValue={selectedDataSource.secondDataSource[0]}
                          options={dataSources.secondDataSources || []}
                          value={selectedDataSource.secondDataSource[0] || []}
                          onChange={handleCompareDataSourceChange}
                          isMulti={false}
                          required={true}
                        />
                      </div>
                      {renderCompareDates(isCompare)}
                    </Collapse>
                  </>
                )}
                   {!fromStaticResources && !isOpenWeather && !isIri ? (
            <div className="mt-3 mx-3 temp-style">
              <div className="mt-2 mb-3">
                <Select
                  id="multi-select"
                  name="aggregation"
                  placeholder="Temporal Aggregation"
                  options={TEMPORAL_AGGREGATION}
                  value={temporalAggregation}
                  onChange={handleTemporalAggregation}
                  isMulti={false}
                  required={true}
                />
              </div>
            </div>
          ) : (
            ''
          )}
                {/* <span className="mx-2">Compare</span>
            <BiPlus size={20} /> */}
              </div>
            </div>
            {/* )} */}
          </TabPane>
        </>
        {!fromStaticResources && (
          <>
            <div className="mx-3 mt-2 filter-submit">
              <div className="apply-layers" id="apply_layers" data-tip data-for="apply_layers">
                <Button
                  className="navyBlueBgColor m-auto submitBtnClass"
                  onClick={() => handleApplyLayers(true)}
                  disabled={!isApplyLayer || isGraphLoading}
                >
                  Apply Layers
                </Button>
              </div>
              {showToolTip && region?.length ? (
                <ReactTooltip id="apply_layers" effect="solid" place="top">
                  {t('FILTER.PLEASE_REMOVE_MARKERS')}
                </ReactTooltip>
              ) : (
                ''
              )}
              {showMapPreferenceTooltip && !showToolTip ? (
                <ReactTooltip
                  id="apply_layers"
                  effect="solid"
                  place="top"
                  type="info"
                  className="apply-layer-tooltip-st"
                >
                  {t('FILTER.PLEASE_REMOVE_MAP_PREFERENCE_LAYER')}
                </ReactTooltip>
              ) : (
                ''
              )}
              <div className="update-graph mt-3" id="view_graph" data-tip data-for="view_graph">
                <Button
                  outline
                  className="navyBlueBgColor-outline m-auto"
                  onClick={displayChart}
                  disabled={!isUpdateGraph}
                >
                  View Graph
                </Button>
              </div>
              {!hasSelectedParamChart &&
              (!isClickedOnPlus ||
                (isClickedOnPlus && isCompare?.some((i) => i?.name === 'graph' && i?.selected))) ? (
                <ReactTooltip id="view_graph" effect="solid" place="top" type="info">
                  {t('FILTER.FILTER.NO_CHART_FOR_PARAM')}
                </ReactTooltip>
              ) : (
                ''
              )}
              {!isUpdateGraph && !isIri && !isOpenWeather && temporalAggregation.value === 'median'
                ? !isClickedOnPlus &&
                  props?.selectedParam?.weather_forecast_parameter?.slug !== 'cloud'
                  ? renderToolTip()
                  : isClickedOnPlus &&
                    isCompare?.some((i) => i.name === 'graph' && i.selected) &&
                    !isOpenWeather
                  ? selectedDataSource?.secondDataSource[0] && !isUpdateGraph
                    ? renderToolTip()
                    : ''
                  : ''
                : ''}
            </div>
          </>
        )}

        {/* )} */}
      </TabContent>
    </div>
  );
};

export default ForecastMonitoring;
