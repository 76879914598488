import React, { useEffect, useState } from 'react';

import { Container, Row, Col, FormGroup, Label } from 'reactstrap';

// import RightArrow from '../../assets/Images/back-button.svg';
import RightArrow from '../../assets/Images/back-button.svg';

import { useHistory, useLocation } from 'react-router-dom';

import ImagePlaceHolder from '../../assets/Images/imagePlaceholder.png';
import Image1 from '../../assets/Images/camera.png';
import { useDispatch, useSelector } from 'react-redux';

import { getFieldDataRequest } from '../../redux/manageFieldData/manageFieldDataActions';

import Loader from '../../Components/common/loader';
import {BsArrowLeft} from "react-icons/bs"
import _ from 'lodash';

const SurveyDetails = () => {
  // return <h1>Survey Details</h1>

  let history = useHistory();
  const paramLocation = useLocation();

  let dispatch = useDispatch();

  // const ManageFieldData = (props) => {
  const nextProps = useSelector((state) => ({
    isFieldDataListDataRequesting: state.fieldDataList.isFieldDataListDataRequesting,
    fieldDataListData: state?.fieldDataList?.FieldDataListData,
    loginDetailData: state.Login.loginDetailData || null,
  }));

  const [newRequestList, setNewRequestList] = useState([
    // {
    //   'Farmer Name': 'test',
    //   'Farmer ID': '12345',
    //   'Phone No.': '9876543210',
    //   District: 'test',
    //   'DS Division': 'test',
    //   'GN Division': 'test',
    //   'Surveyor Name': 'test',
    //   'Status of Insurance': 'test',
    // },
  ]);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // dispatch(
    //   getFieldDataRequest({
    //     // url: 'https://docs.google.com/spreadsheets/d/14-7qtq5maCsIylAU6F7rDxnKC-6K9qsMCpiLV9pzmzU/edit?usp=sharing',
    //     // url: 'https://docs.google.com/spreadsheets/d/14-7qtq5maCsIylAU6F7rDxnKC-6K9qsMCpiLV9pzmzU/edit?usp=sharing',
    //     year: paramLocation?.state?.selectedDate,
    //     component_id: paramLocation?.state?.id,
    //   }),
    // );
  }, []);

  // useEffect(() => {
  //   if (nextProps.fieldDataListData) {
  //     if (nextProps?.fieldDataListData?.data?.result) {
  //       setNewRequestList(
  //         nextProps?.fieldDataListData?.data?.result?.['farm_odk_data ']?.filter(
  //           (item) => item?.['data-meta-instanceID'] === history.location?.pathname.split('/')[2],
  //         ),
  //       );
  //     } else {
  //       setNewRequestList([]);
  //     }
  //   } else {
  //     setNewRequestList([]);
  //   }
  // }, [nextProps.fieldDataListData]);

  useEffect(() => {
    if (!_.isEmpty(paramLocation?.state?.newRequestList) && paramLocation?.state?.uuid) {
    setNewRequestList(
      paramLocation?.state?.newRequestList.filter(
      (item) => item?.['data-meta-instanceID'] === paramLocation?.state?.uuid,
    ),
  );    } else {
    setNewRequestList([]);
  }}, [paramLocation?.state?.newRequestList]);

  useEffect(() => {
    if (newRequestList) {
      setTableData(
        newRequestList?.map((item, key) => {
          if (item?.['data-meta-instanceID'] === history.location?.pathname.split('/')[2]) {
            return {
              name:
                item?.['data-sub_section-Name-of-the-farmer'] !== ' '
                  ? item?.['data-sub_section-Name-of-the-farmer']
                    ? item?.['data-sub_section-Name-of-the-farmer']
                    : 'N/A'
                  : 'N/A',
              // farmerId:item?.[""]
              phoneNo:
                item?.['data-sub_section-Telephone-No'] !== ' '
                  ? item?.['data-sub_section-Telephone-No']
                    ? item?.['data-sub_section-Telephone-No']
                    : 'N/A'
                  : 'N/A',
              district:
                item?.['data-sub_section-District'] !== ' ' && item?.['data-sub_section-District']
                  ? item?.['data-sub_section-District']
                  : 'N/A',
              dsDivision:
                item?.['data-sub_section-DS-division'] !== ' '
                  ? item?.['data-sub_section-DS-division']
                    ? item?.['data-sub_section-DS-division']
                    : 'N/A'
                  : 'N/A',
              gnDivision:
                item?.['data-sub_section-GN-division'] !== ' '
                  ? item?.['data-sub_section-GN-division']
                    ? item?.['data-sub_section-GN-division']
                    : 'N/A'
                  : 'N/A',
              id:
                item?.['data-meta-instanceID'] !== ' '
                  ? item?.['data-meta-instanceID']
                    ? item?.['data-meta-instanceID']
                    : 'N/A'
                  : 'N/A',
              gender: item?.['data-sub_section-Gender'],
              houseHoldGeoPoint: item?.['data-Location-Info-Location-of-household-altitude'],
              expectedCropToInsured: item?.['data-Crop-information-Expected-crop-to-be-insured'],
              typeOfLandToInsured: item?.['data-Crop-information-Type-of-land-willing-to-insure'],
              typeOfLandOwenshipWillingToInsured:
                item?.[
                  'data-Crop-information-Type-of-land-ownership-willing-to-insure-tick-all-relevant'
                ],
              extendOfCropTOInsured:
                item?.['data-Crop-information-Extent-of-crop-area-to-be-insured-in-acres'],
              // typeOfLandWillingToInsured:item?.["data-Crop-information-Type-of-land-ownership-willing-to-insure-tick-all-relevant"],
              sumInsuredAcreExpectation:
                item?.[
                  'data-Insurance-premium-and-Loan-information-Sum-insured-per-Acre-LKR-expectation'
                ],
              sumOfPremiumWillingTOPayFarmer:
                item?.[
                  'data-Insurance-premium-and-Loan-information-Amount-of-Premium-willing-to-pay-by-farmer'
                ],
              anyAgryLoanTaken: item?.['data-Have-you-taken-any-agri-loan'],
              specifyTheSourceOfCredit: item?.['data-If-yes-then-specify-the-source-of-credit'],
              intrestedInReceivingClimateAndAgronomick:
                item?.['data-Are-you-interesting-in-receiving-climatic-and-agronomic-advisories'],
              adveysorNeedtoFrequency:
                item?.['data-If-yes-in-which-frequency-do-you-need-the-advisory'],
              intrestedInreceivingTheAdveysor:
                item?.[
                  'data-Advisory-and-market-information-Are-you-interested-in-receiving-market-price-information'
                ],
              instrestedInReceivingTheTradeInformation:
                item?.[
                  'data-Advisory-and-market-information-Are-you-interested-in-receiving-buy-back-trade-information'
                ],
              photoFarm:item?.["data-title-Photo-1"],
              photo1: item?.['data-sub_section-NIC-number'],
              photo2: item?.['data-title-Photo-2'],
              photo3: item?.['data-title-Photo-3'],
            };
          } else return;
        }),
      );
    }
  }, [newRequestList]);

  return (
    <Container fluid>
      {nextProps.isFieldDataListDataRequesting && <Loader />}

      <div className="right-img-wrapper api-key-list">
        <Row>
          {/* <Col>
                <h1>Survey Details</h1>
                </Col> */}
          <Col xs="12" sm="12" md="12" lg="12" className="subheader-manage-user">
            {/* <img
              src={RightArrow}
              alt="back-arrow"
              className="manage-user-header-back-button"
              onClick={() => history.push('/manageFieldData?Enrollment')}
            /> */}
            <span style={{ float: 'left',marginLeft: '10px'  , borderRadius: "700px",
                        border: "1px solid #ada6a6" ,
                        height: "43px" , width:"43px" , fontSize:"25px" , color:"#595252" , fontWeight:"bold" , marginTop:"2.5%"}} className="enrolment-detials-list-tl-st">
                    {/* <img
                      src={RightArrow}
                      className="cursor-ponter"
                      onClick={() => history.goBack()}
                      alt='back-bottom'
                    /> */}
                    <BsArrowLeft  className="cursor-ponter"
                     // onClick={() => history.goBack()} style={{margin:"auto" ,}} 
                     onClick={() => history.push('/manageFieldData?Enrollment&fromBack=true' + '&date=' + paramLocation?.state?.selectedDate)}

                     />

                      
                  </span>
            <h3 className="heading-manage-user manage-user-heading-mt">
              {/* {t('MANAGE_USER.MANAGE_USERS')} */}
              Enrollment            </h3>
          </Col>

          <Col style={{ marginLeft: '4%', marginRight: '4%' }}>
            <form action="/action_page.php">
              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  General Information
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Farmer name
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Farmer name */}
                          {tableData[0]?.name!==" " ?tableData[0]?.name ? tableData[0]?.name : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    {/* </Col> */}
                    {/* <Col md={4} style={{ overflowWrap: 'break-word' }}> */}
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        District
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* District */}
                          {tableData[0]?.district!==" " ?tableData[0]?.district ? tableData[0]?.district : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        DS Division
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* DS Division */}
                          {tableData[0]?.dsDivision!==" " ?tableData[0]?.dsDivision ? tableData[0]?.dsDivision : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}

                    <br />

                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Household geopoint
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Household geopoint */}
                          {tableData[0]?.houseHoldGeoPoint
                            ? tableData[0]?.houseHoldGeoPoint?parseFloat( tableData[0]?.houseHoldGeoPoint.split(",")[0]).toFixed(2)  +','+ parseFloat( tableData[0]?.houseHoldGeoPoint.split(",")[1]).toFixed(2):""
                            : <br/>}
                        </span>
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Gender
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Gender */}
                          {tableData[0]?.gender!==" " ?tableData[0]?.gender ? tableData[0]?.gender : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    <br />
                    {/* </Col> */}
                    {/* <Col md={4} style={{ overflowWrap: 'break-word' }}> */}
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        GN Division
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* GN Division */}
                          {tableData[0]?.gnDivision!==" " ?tableData[0]?.gnDivision ? tableData[0]?.gnDivision : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    {/* </Col> */}
                  </Col>

                  <Col xs={3}>
                    <div style={{ marginLeft: '15px' }}>
                      <img src={Image1} />
                      &nbsp;
                      {tableData[0]?.photo1 != ' ' ?
                      <a
                        href={tableData[0]?.photo1 ? tableData[0]?.photo1 : ''}
                        target="_blank"
                        style={{ color: '#363636' }}
                      >
                        NIC Number
                      </a>: 'NIC Number'}
                      <br />
                      <img src={Image1} />
                      &nbsp;
                      {tableData[0]?.photoFarm  != ' ' ?
                      <a
                        href={tableData[0]?.photoFarm ? tableData[0]?.photoFarm : ''}
                        target="_blank"
                        style={{ color: '#363636' }}
                      >
                        Homestead
                      </a>: 'Homestead'}
                      <br />
                      <img src={Image1} />
                      &nbsp;
                      {tableData[0]?.photo2  != ' ' ?
                      <a
                        href={tableData[0]?.photo2 ? tableData[0]?.photo2 : ''}
                        target="_blank"
                        style={{ color: '#363636' }}
                      >
                        Homestead/farm
                      </a> : 'Homestead/farm'}
                      <br />
                      <img src={Image1} />
                      &nbsp;
                      {tableData[0]?.photo3 != ' ' ?
                      <a
                        href={tableData[0]?.photo3 ? tableData[0]?.photo3 : ''}
                        target="_blank"
                        style={{ color: '#363636' }}
                      >
                        Homestead/field
                      </a>: 'Homestead/field'}
                    </div>
                  </Col>
                </Row>
              </fieldset>

              <br />

              <fieldset className="border rounded-3 p-3">
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  Land & Crops
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  <Col md={3} style={{ overflowWrap: 'break-word' }}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Expected crop to be insured
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Paddy */}
                          {tableData[0]?.expectedCropToInsured!==" " ?tableData[0]?.expectedCropToInsured
                            ? tableData[0]?.expectedCropToInsured
                            : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    

                    <br/>
                    

<FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Type of land ownership willing to insure
                      </Label>

                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {tableData[0]?.typeOfLandOwenshipWillingToInsured!==" " ?tableData[0]?.typeOfLandOwenshipWillingToInsured
                            ? tableData[0]?.typeOfLandOwenshipWillingToInsured.split("-").join(" ")
                            : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>


                  </Col>

                  {/* <br/> */}
                  <Col md={3}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Type of land willing to insure
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Male */}
                          {tableData[0]?.typeOfLandToInsured!==" " ?tableData[0]?.typeOfLandToInsured
                            ? tableData[0]?.typeOfLandToInsured.split("-").join(" ")
                            : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>
                    


<br/>

                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Amount of Premium willing to pay by farmer
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {tableData[0]?.sumOfPremiumWillingTOPayFarmer!==" " ?tableData[0]?.sumOfPremiumWillingTOPayFarmer
                            ? tableData[0]?.sumOfPremiumWillingTOPayFarmer
                            : 'N/A':"N/A"}
                          Rs/ Acres
                        </span>
                      </div>
                    </FormGroup>

                  </Col>
                  {/* <Col md={3}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Type of land ownership willing to insure
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          Type of land ownership willing to insure
                          {tableData[0]?.typeOfLandOwenshipWillingToInsured
                            ? tableData[0]?.typeOfLandOwenshipWillingToInsured
                            : 'N/A'}
                        </span>
                      </div>
                    </FormGroup>
                  </Col> */}

                  <Col md={3}>
                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Extent of crop area to be insured
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* 2 acres */}
                          {tableData[0]?.extendOfCropTOInsured!==" " ?tableData[0]?.extendOfCropTOInsured
                            ? tableData[0]?.extendOfCropTOInsured
                            : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>


<br/>

                    

<FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Sum insured per Acre (LKR, expectation)
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {tableData[0]?.sumInsuredAcreExpectation!==" " ?tableData[0]?.sumInsuredAcreExpectation
                            ? tableData[0]?.sumInsuredAcreExpectation + `Rs/ Acres`
                            : 'N/A':"N/A"}
                          {/* Rs/ Acres */}
                        </span>
                      </div>
                    </FormGroup>
                      

                  </Col>
                  {/* </Col> */}
                  {/* <Col md={4} style={{ overflowWrap: 'break-word' }}> */}
                  <Col md={3} 
                  // style={{ marginTop: '30px' }}
                  >
                    
                    

                    <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Any agri loan taken before
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Yes */}
                          {tableData[0]?.anyAgryLoanTaken!==" " ?tableData[0]?.anyAgryLoanTaken
                            ? tableData[0]?.anyAgryLoanTaken
                            : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>




<br/>



<FormGroup 
                    style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        If yes, specify the source of credit
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {/* Commercial bank */}
                          {tableData[0]?.specifyTheSourceOfCredit!==" " ?tableData[0]?.specifyTheSourceOfCredit
                            ? tableData[0]?.specifyTheSourceOfCredit.split("-").join(" ")
                            : 'N/A':"N/A"}
                        </span>
                      </div>
                    </FormGroup>

                    {/* </Col> */}
                  </Col>


<br/>

                  <Col md={3}>
                    <br />

                    {/* <FormGroup style={{ marginLeft: '17px' }}>
                      <Label className="label" style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                        Any agri loan taken before
                      </Label>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          Yes
                          {tableData[0]?.anyAgryLoanTaken
                            ? tableData[0]?.anyAgryLoanTaken
                            : 'N/A'}
                        </span>
                      </div>
                    </FormGroup> */}
                  </Col>

                  {/* <br /> */}
                  
                </Row>
              </fieldset>

              <br />

              <fieldset className="border rounded-3 p-3" style={{ marginBottom: '30px' }}>
                <legend
                  className="float-none w-auto px-3"
                  style={{ color: '#3870AF', fontSize: '16px', fontWeight: 'bold' }}
                >
                  Notifications
                </legend>
                <Row>
                  {/* <Col>
    <p>Farmer name</p>
    </Col> */}
                  <Col md={12} style={{ overflowWrap: 'break-word' }}>
                    {/* <ol> */}
                      <p style={{ color: '#7C8EA0' }}>
                        <span style={{ display: 'flex' }}>
                          <p style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                            {' '}
                            Interested in receiving climatic and agronomic advisories{' '}
                          </p>{' '}
                          <p style={{ marginLeft: '150px', fontWeight: 'bold', color: '#000' }}>
                            {/* Yes */}
                            {tableData[0]?.intrestedInReceivingClimateAndAgronomick!==" " ?tableData[0]?.intrestedInReceivingClimateAndAgronomick
                              ? tableData[0]?.intrestedInReceivingClimateAndAgronomick
                              : 'N/A':"N/A"}
                          </p>
                        </span>
                      </p>

                      {/* <br/> */}
                      <p style={{ display: 'flex',  }}>
                        <p style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                   

                          {/* . */}
                   
                   
                           {/* If yes, the advisory are needed in frequency of{' '} */}
                           If yes, in which frequency, do you need the advisory{' '}
                        </p>{' '}
                        <p style={{ marginLeft: '185.9px', fontWeight: 'bold' }}>
                          {/* Once a week */}
                          {tableData[0]?.adveysorNeedtoFrequency!==" " ?tableData[0]?.adveysorNeedtoFrequency
                            ? tableData[0]?.adveysorNeedtoFrequency.split("-").join(" ")
                            : 'N/A':"N/A"}
                        </p>
                      </p>

                      <p style={{ color: '#7C8EA0' }}>
                        <span style={{ display: 'flex' }}>
                          {' '}
                          <p style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                            Interested in receiving market price information
                          </p>{' '}
                          <p style={{ marginLeft: '213px', fontWeight: 'bold', color: '#000' }}>
                            {/* No */}
                            {tableData[0]?.intrestedInreceivingTheAdveysor!==" " ?tableData[0]?.intrestedInreceivingTheAdveysor
                              ? tableData[0]?.intrestedInreceivingTheAdveysor
                              : 'N/A':"N/A"}
                          </p>
                        </span>
                      </p>

                      {/* <br/> */}
                      <p style={{ color: '#7C8EA0' }}>
                        <span style={{ display: 'flex' }}>
                          {' '}
                          <p style={{ color: '#7C8EA0', fontWeight: 'bold' }}>
                            {' '}
                            Interested in receiving buy back trade information
                          </p>{' '}
                          <p style={{ marginLeft: '199px', fontWeight: 'bold', color: '#000' }}>
                            {/* Yes */}
                            {tableData[0]?.instrestedInReceivingTheTradeInformation!==" " ?tableData[0]?.instrestedInReceivingTheTradeInformation
                              ? tableData[0]?.instrestedInReceivingTheTradeInformation
                              : 'N/A':"N/A"}
                          </p>
                        </span>
                      </p>
                    {/* </ol> */}
                  </Col>
                </Row>
              </fieldset>
            </form>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default SurveyDetails;
