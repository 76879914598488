import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  renderReactSelectInputField,
  renderTextField,
  renderImageField,
} from '../../Components/common/renderInputField';
import Button from 'reactstrap-button-loader';
import { t } from 'react-multi-lang';
const Filter = props => {
  const {
    categoryList,
    subCategoryList,
    typeList,
    fileHandler,
    selectedFilterCategory,
    filterDateHandler,
    filterTitleChange,
  } = props;

  const [subTopicOptions, setSubTopicOptions] = useState();
  const [filterButtonDisabled, setFilterButtonDisabled] = useState(true);
  const [isSubTopicOptionsDisabled, setIsSubTopicOptionsDisabled] = useState(true);

  const dispatch = useDispatch();

  const { UserGuide } = useSelector(state => ({
    UserGuide: state.UserGuide,
  }));

  const renderAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        if (action_name === 'category') {
          let default_option = { value: 'select', label: 'select', id: 'select' };
          options.push(default_option);
        }
        params.map(param => {
          let data = {
            value: param.name,
            label: `${param.name}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });

        return options;
      }
    }
  };

  const renderAPISubTopicOptions = (list, option) => {
    if (list) {
      let params = [...list];
      let options = [];
      let default_option = { value: 'select', label: 'select', id: 'select' };
      options.push(default_option);
      let requiredTopic = params.filter(param => param.name === option);
      if (requiredTopic?.length) {
        let requiredSubTopic = requiredTopic[0].sub_topic;
        if (requiredSubTopic?.length) {
          requiredSubTopic.forEach(element => {
            let data = {
              value: element.name,
              label: `${element.name}`,
              id: element.id,
            };
            options.push(data);
          });
          setIsSubTopicOptionsDisabled(false);
          setSubTopicOptions(options);
        } else {
          setIsSubTopicOptionsDisabled(true);
          setSubTopicOptions([]);
        }
      } else {
        setIsSubTopicOptionsDisabled(true);
        setSubTopicOptions([]);
      }
    }
  };

  const handleFilterCategoryChange = data => {
    dispatch(change('userGuideFilterForm', 'filter_sub_category', ''));
    props.handleFilterCategoryChange(data);
    renderAPISubTopicOptions(categoryList, data.value);
    if (data.value !== 'select') {
      setFilterButtonDisabled(false);
    } else {
      setFilterButtonDisabled(true);
    }
  };

  const handleFilterSubCategoryChange = data => {
    props.handleFilterSubCategoryChange(data);
  };

  const onFormSubmit = e => {
    e.preventDefault();
    props.handleFilterSubmit();
  };

  return (
    <>
      <Card className="card-style">
        <CardBody className="add_static_resource">
          <form
            id="FilterForm"
            onSubmit={e => {
              onFormSubmit(e);
            }}
          >
            <div className="static-resource-filter-field">
              <Field
                component={renderReactSelectInputField}
                name="filter_category"
                placeholder={t('USER_GUIDE.CATEGORY')}
                className="my-3 filter-select-placeholder"
                onChange={e => handleFilterCategoryChange(e)}
                required={true}
                isMulti={false}
                options={renderAPIParameterOptions(categoryList, 'category')}
              ></Field>
              {subTopicOptions?.length ? (
                <Field
                  component={renderReactSelectInputField}
                  name="filter_sub_category"
                  placeholder={t('USER_GUIDE.SUB_CATEGORY')}
                  className="my-3 filter-select-placeholder"
                  onChange={e => handleFilterSubCategoryChange(e)}
                  required={true}
                  isMulti={false}
                  options={subTopicOptions}
                  isDisabled={isSubTopicOptionsDisabled}
                ></Field>
              ) : (
                ''
              )}
            <div className="btn-filter">
              <Button
                className="btn st-btn-custom submit-btn mx-4"
                type="submit"
                // loading={UserGuide?.isGetAllUserGuideRequesting}
                disabled={filterButtonDisabled}
                id="save"
              >
                {t('USER_GUIDE.FILTER')}
              </Button>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
};
export default reduxForm({
  form: 'userGuideFilterForm',
  enableReinitialize: true,
})(Filter);
