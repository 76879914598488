import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, Label, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-multi-lang';
import Button from 'reactstrap-button-loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import UploadImg from '../../assets/Images/cloud-upload.png';
import UploadFormImg from '../../assets/Images/upload-form.png';
import CrossImg from '../../assets/Images/remove-form.png';

import 'react-multi-email/style.css';
import Loader from '../../Components/common/loader';


const UploadSurveyDataForm = (props) => {
  const { modalOpen, setModalOpen, componentList, handleUploadSurveyData, handleDownloadTemplate, isLoading } = props;
  const t = useTranslation();
  const history = useHistory();
  const [modal, setModal] = useState();
  const [componentOptions, setComponentOptions] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState('');
  const [selectedYear, setSelectedYear] = useState()
  const [uploadedFile, setUploadedFile] = useState('')

  useEffect(() => {
    setModal(modalOpen);
  }, [modalOpen]);

  useEffect(() => {
    if (componentList?.length) {
      let arr = []
      arr = componentList.map(item => {
        let obj = { ...item };
        obj.value = item.name;
        obj.label = item.name;
        return obj;
      })
      if (arr?.length) setComponentOptions(arr)
    }
  }, [componentList])

  const viewtoggle = (value) => {
    setModal(false);
    // handleModalToggle();
  };

  const UpdateComponent = (item) => {
    setSelectedComponent(item)
  }

  const handleSelectYear = (e) => {
    let date = new Date(e)
    setSelectedYear(date)
  }

  const handleClose = () => {
    setModal(false);
    setModalOpen(false);
  };

  return (
    <>
      <div className="uploadSurveyModal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className="uploadSurveyModal"
          backdrop="static"
        // style={{ width: '75%' }}
        >
          {isLoading && <Loader isODKUploadSurvey={true}/>}
          <ModalHeader toggle={() => handleClose()} className="upload-modal-header">
            Upload Survey Data
          </ModalHeader>
          <ModalBody style={{ textAlign: 'center' }}>
            <div className='upload-survey-form'>
              {/* <Row> */}
              {/* <Col lg={8} md={8} xl={8} xs={8}> */}
              <FormGroup className='registertextOnInput' style={{ marginBottom: '20px', textAlign: 'left' }}>
                <Label>Component</Label>
                <Select
                  id="multi-select"
                  name="country"
                  placeholder="Select Component"
                  options={componentOptions}
                  onChange={UpdateComponent}
                  isMulti={false}
                  required={true}
                  value={selectedComponent}
                />
              </FormGroup>
              {/* </Col> */}
              {/* <Col lg={4} md={4} xl={4} xs={4} style={{ display: 'flex', alignItems:'center'}}>
                <div style={{ display: 'flex', float: 'left'}}>
                  <a
                    style={{
                      color: '#5088C6',
                      textDecoration: 'underline',
                      fontSize: '16px',
                      cursor: 'pointer',
                    }}
                    // onClick={(e) => handleClick(xmlLink, xmlname)}
                    target="_blank"
                    download
                  >
                    Download Excel template
                  </a>
                  </div>
                </Col>
              </Row> */}

              <FormGroup className={selectedYear ? `registertextOnInput selected-year-css` : 'registertextOnInput'} style={{ marginBottom: '15px' }} >
                <Label>Year</Label>
                <DatePicker
                  // showIcon
                  id="startDate"
                  name="startDate"
                  className="input-style upload-survey-configuration mt-0 form-control select-field-zindex style-year-css"
                  showYearPicker={true}
                  shouldCloseOnSelect={true}
                  dateFormat="yyyy"
                  minDate={new Date('2000-01-01T00:00:00Z')}
                  maxDate={new Date()}
                  placeholderText={'Select Year'}
                  onChange={(e) => handleSelectYear(e)}
                  value={selectedYear ? selectedYear : null}
                  selected={selectedYear ? selectedYear : null}
                  style={{ zIndex: 100 }}
                  // customInput={<ExampleCustomInput />}
                  customStyles={{
                    placeholderText: {
                      opacity: 0.7
                    }
                  }}
                />
              </FormGroup>
              <div className='my-2'>
                <fieldset
                  className=" rounded-3 p-2"
                  style={{ width: '95%', border: '1px solid rgba(80, 135, 198,0.5)', marginBottom: '30px', display: 'inline-block', textAlign: 'left' }}
                >
                  <legend
                    className="float-none w-auto px-1"
                    style={{ color: '#717D88', fontSize: '14px', marginBottom: '0px' }}
                  >
                    Upload Excel File
                  </legend>
                  {uploadedFile && uploadedFile[0]?.name ? (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div
                          style={{
                            backgroundColor: '#EDEDED',
                            borderRadius: '5px',
                            width: 'max-content',
                          }}
                        >
                          <p style={{ margin: 'auto', padding: '5px' }}>
                            {' '}
                            <img src={UploadFormImg} /> {uploadedFile[0].name}
                          </p>
                        </div>
                        <div>
                          <img
                            src={CrossImg}
                            style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '10px' }}
                            onClick={(e) => setUploadedFile('')}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <Dropzone onDrop={(file) => setUploadedFile(file)} accept={{
                      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx', '.xlsm', '.xlsb', '.xltx', '.xltm', '.xlt', '.xml', '.xlam', '.xla', '.xlw', '.xlr'],
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
                    }}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />

                          <>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                              }}
                            >
                              <img src={UploadImg} style={{ cursor: 'pointer' }} />
                            </span>
                            <p style={{ textAlign: 'center', fontSize: '14px', opacity: '0.7' }}>
                              Drag and drop here or{' '}
                              <span
                                style={{
                                  color: '#3870AF',
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                              >
                                <a>Browse files</a>
                              </span>
                            </p>
                            {/* </span> */}
                          </>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </fieldset>
              </div>
            </div>
          </ModalBody>

          <ModalFooter className="reason-modal">
            <Row>
              <Col lg={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <a
                    style={{
                      color: '#5088C6',
                      textDecoration: 'underline',
                      fontSize: '13px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => handleDownloadTemplate(selectedComponent?.excel_file_template, selectedComponent?.name)}
                    target="_blank"
                    download
                    className={selectedComponent ? '' :`download-template-disabled`}
                  >
                    Download Excel template
                  </a>

                  <br />
                  {/* <a style={{ color: '#5088C6', fontSize: '13px' }}>
                      Need help?{' '}
                      <a
                        // onClick={() => history.push('/user-guide')}
                        style={{ textDecoration: 'underline', fontSize: '13px', cursor: 'pointer' }}
                        target="_blank"
                        // href={`/user-guide-details?topicId=${FIELD_DATA_MODULE.id}&&sub-topic-name=Field Data Integration`}
                      >
                        View user guide
                      </a>
                    </a> */}
                </div>


                <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
                  <Button color="primary" onClick={() => handleUploadSurveyData(selectedComponent, selectedYear, uploadedFile)} className="modal-reject-button" disabled={selectedComponent && selectedYear && uploadedFile ? false : true}>
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default UploadSurveyDataForm;
