import React from 'react';
import { CgCloseO } from 'react-icons/cg';
import { t } from 'react-multi-lang';
import _ from 'lodash';
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
import FarmRiskDetails from './farm-risk-analytics';

const RiskAnalyticsDetailsTab = (props) => {
  const {
    handleCloseRiskRightSideBar,
    toggle,
    rightBarActiveTab,
    handleOpenCurrentWeatherSplitter,
    farmTrackerList,
    getFarmData,
    getByIdFarmData,
    getByIdCompareFarmInfo,
    forecastRightSideBarInfo,
    compareForecastRightSideBarInfo,
    riskScoreAnalyticsInfo,
    handleOpenAnalyzeRiskSplitter,
    projectDetails,
    handleOpenModal,
    selectedFarmsList,
    compareFarm,
    selectedFarm,
    isApplyLayer,
    handleOpen,
    handleOpenFullScreen,
    handleOpenCarousalModal,
    setSelectedGeotaggedImage,
    openWeatherChartSeriesData,
    geoTaggedImages,
    setGeoTaggedImages,
    riskPercentage,
    riskLevel,
    compareRiskPercentage,
    compareRiskLevel,
  } = props;
  return (
    <>
      <div>
        <div className="filter-sidebar-section-drought-monitor risk-score-collapse-drought-filter-section">
          <div className="filter-div ">
            <Card className="card-style">
              <CardBody className="flt-rsk-div1">
                <div className="d-flex cursor-ponter navyBlueColor   justify-content-between my-2">
                  {' '}
                  <span className="mt-2 m-2">{t('Farm Details')}</span>
                  <CgCloseO size={23} onClick={(e) => handleCloseRiskRightSideBar({ fromHandleOpen: false })} />{' '}
                </div>
                <div className="agriculture-water">
                  <Nav tabs>
                    {!_.isEmpty(getByIdFarmData) && !_.isEmpty(getByIdCompareFarmInfo) ? (
                      <>
                        <NavItem className="mx-2">
                          <NavLink
                            className={
                              rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? 'active' : ''
                            }
                            onClick={() => toggle(t('RISK_ANALYTICS.FARM_1'))}
                          >
                            {!_.isEmpty(getByIdFarmData)
                              ? _.capitalize(getByIdFarmData?.farm?.[0]?.name)
                              : t('RISK_ANALYTICS.FARM_1')}
                          </NavLink>
                        </NavItem>

                        <NavItem className="mx-2">
                          <NavLink
                            className={
                              rightBarActiveTab === t('RISK_ANALYTICS.FARM_2') ? 'active' : ''
                            }
                            onClick={() => toggle(t('RISK_ANALYTICS.FARM_2'))}
                          >
                            {!_.isEmpty(getByIdCompareFarmInfo)
                              ? _.capitalize(getByIdCompareFarmInfo?.farm?.[0]?.name)
                              : t('RISK_ANALYTICS.FARM_2')}
                          </NavLink>
                        </NavItem>
                      </>
                    ) : (
                      ''
                    )}
                  </Nav>
                </div>
                <div>
                  {rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') || rightBarActiveTab === getByIdFarmData?.farm?.[0]?.name ? (
                    <>
                      <FarmRiskDetails
                        handleCloseRiskRightSideBar={handleCloseRiskRightSideBar}
                        handleOpenCurrentWeatherSplitter={handleOpenCurrentWeatherSplitter}
                        farmTrackerList={farmTrackerList}
                        toggle={toggle}
                        getFarmData={getFarmData}
                        getByIdFarmData={getByIdFarmData}
                        forecastRightSideBarInfo={forecastRightSideBarInfo}
                        riskScoreAnalyticsInfo={riskScoreAnalyticsInfo}
                        handleOpenAnalyzeRiskSplitter={handleOpenAnalyzeRiskSplitter}
                        projectDetails={projectDetails}
                        handleOpenModal={handleOpenModal}
                        handleOpen={handleOpen}
                        handleOpenFullScreen={handleOpenFullScreen}
                        setSelectedGeotaggedImage={setSelectedGeotaggedImage}
                        handleOpenCarousalModal={handleOpenCarousalModal}
                        openWeatherChartSeriesData={openWeatherChartSeriesData}
                        geoTaggedImages={geoTaggedImages}
                        setGeoTaggedImages={setGeoTaggedImages}
                        riskPercentage={riskPercentage}
                        riskLevel={riskLevel}
                      />
                    </>
                  ) : (
                    <>
                      {!_.isEmpty(getByIdCompareFarmInfo) && rightBarActiveTab === t('RISK_ANALYTICS.FARM_2') ? (
                        <FarmRiskDetails
                          handleCloseRiskRightSideBar={handleCloseRiskRightSideBar}
                          handleOpenCurrentWeatherSplitter={handleOpenCurrentWeatherSplitter}
                          farmTrackerList={farmTrackerList}
                          toggle={toggle}
                          getFarmData={getFarmData}
                          getByIdFarmData={getByIdCompareFarmInfo}
                          forecastRightSideBarInfo={compareForecastRightSideBarInfo}
                          riskScoreAnalyticsInfo={riskScoreAnalyticsInfo}
                          handleOpenAnalyzeRiskSplitter={handleOpenAnalyzeRiskSplitter}
                          projectDetails={projectDetails}
                          handleOpenModal={handleOpenModal}
                          handleOpen={handleOpen}
                          setSelectedGeotaggedImage={setSelectedGeotaggedImage}
                          handleOpenFullScreen={handleOpenFullScreen}
                          handleOpenCarousalModal={handleOpenCarousalModal}
                          openWeatherChartSeriesData={openWeatherChartSeriesData}
                          geoTaggedImages={geoTaggedImages}
                          setGeoTaggedImages={setGeoTaggedImages}
                          riskPercentage={compareRiskPercentage}
                          riskLevel={compareRiskLevel}
                        />
                      ) : (
                        <div className="mx-4" style={{ marginTop: '200px' }}>
                          please select another farm to view the details
                        </div>
                      )}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
            {/* </div> */}

            {/* </CardBody> */}
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default RiskAnalyticsDetailsTab;
