import React from 'react';
import { Row, Col, Card, CardBody, Button, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import { t } from 'react-multi-lang';
import { useDispatch } from 'react-redux';
import {
  CROP_PRODUCTION_FILTER_SLUG_CONSTS,
  CROP_PRODUCTION_FILTER_TABS,
} from '../../../Components/constants';
import { reduxForm, Field } from 'redux-form';
import {
  renderCheckBoxField,
  renderReactSelectInputField,
} from '../../../Components/common/renderInputField';
import { getCWPGraphDataRequest } from '../../../redux/actions';
import Select from 'react-select';

const validate = (values) => {
  const errors = {};

  const requiredFields = [
    'province',
    'district',
    'crop',
    'season',
    'practise',
    'start_year',
    'end_year',
  ];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required.';
    }
  });

  if (values.start_year && values.end_year && values.start_year?.value > values.end_year?.value) {
    errors.start_year = 'start year must be  less than end year';
  }

  return errors;
};

const CropProductionFilter = (props) => {
  const {
    activeTab,
    handleActiveTab,
    selectedLocation,
    handleSelectedItem,
    handleProvinceChange,
    handleDistrictChange,
    handleOnSeasonChange,
    handleOnPractisesChange,
    projectDetails,
    startYearList,
    graphList,
    isViewGraph,
    endYearList,
    seasonList,
    practisesList,
    handleStartDate,
    handleEndDate,
    handleOnCropChange,
    countryList,
    provinceList,
    districtList,
    handleSubmit,
    minMaxError,
    handleFormSubmit,
    setMinMaxError,
    pristine,
    reset,
    submitting,
    invalid,
    formData,
    cropList,
    initialValues,
    // clearGraphData,
        setGraphList,
    setFinalGraphList,
  } = props;
  const dispatch = useDispatch();

  const renderAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        let default_option = {
          id: 'Select All',
          name: 'Select All',
          label: 'Select All',
          value: '',
        };
        options.push(default_option);
        params.map((param) => {
          let data = {
            value: param.name,
            label: `${param.label}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const onFormSubmit = () => {
    handleFormSubmit()
  }

  const handleDistrictOptionsDisable = (option, list) => {
    let maxCount = 3;
    if (list?.length >= maxCount) return true;
    else {
      return false;
    }
  };

  return (
    <>
      <div>
        <Card className="crop-prod-card-st">
          <CardBody>
            <ul className="crop-prod-navtabs-switch">
              <li
                className={
                  activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value
                    ? 'active crop-prod-tabButton1'
                    : 'crop-prod-tabButton2'
                }
                onClick={() => handleActiveTab(CROP_PRODUCTION_FILTER_TABS.SEASONAL.value)}
              >
                {CROP_PRODUCTION_FILTER_TABS.SEASONAL.label}
              </li>
              <li
                className={
                  activeTab === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value
                    ? 'active crop-prod-tabButton1'
                    : 'crop-prod-tabButton2'
                }
                onClick={() => handleActiveTab(CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value)}
              >
                {CROP_PRODUCTION_FILTER_TABS.PERENNIAL.label}
              </li>
            </ul>
            <Form onSubmit={handleSubmit(onFormSubmit)} className="projects-inp mx-2">
              <div
                className={
                  minMaxError
                    ? 'mr-3 ml-2 crop-filter-scroll crop-filter-scroll1'
                    : 'mr-3 ml-2 crop-filter-scroll '
                }
              >
                {/* <div style={{marginRight:"2px"}}> */}
                <FormGroup className="crop-prod-form-grp-st  pt-2">
                  <Label for="country" style={{ fontWeight: 'bold' }}>
                    Country
                  </Label>
                  <Field
                    component={renderReactSelectInputField}
                    name="country"
                    className="crp-prod-filter-text"
                    placeholder="country"
                    type="select"
                    onChange={handleSelectedItem}
                    isMulti={false}
                    isSearchable={true}
                    isDisabled={true}
                    // value={selectedLocation?.country}
                    // defaultValue={selectedLocation?.country}
                    options={renderAPIParameterOptions(countryList)}
                  ></Field>
                </FormGroup>
                {activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value ? (
                  <>
                    <FormGroup className="crop-prod-form-grp-st ">
                      <Label for="province" style={{ fontWeight: 'bold' }}>
                        {t('FARM_TRACKER.PROVINCE')}
                      </Label>
                      <Field
                        component={renderReactSelectInputField}
                        name="province"
                        placeholder="Select Province"
                        className="crp-prod-filter-text"
                        type="select"
                        onChange={(val) =>handleProvinceChange(val, true)}
                        isMulti={false}
                        isSearchable={true}
                        defaultValue={selectedLocation?.state}
                        value={selectedLocation.state}
                        // isDisabled={projectDetails?.id ? true : false}
                        // options={renderAPIParameterOptions(provinceList)}
                        options={provinceList}
                      ></Field>
                    </FormGroup>
                    <FormGroup className="crop-prod-form-grp-st  pt-2">
                      <Label for="district" style={{ fontWeight: 'bold' }}>
                        {t('FARM_TRACKER.DISTRICTS')}
                      </Label>
                      <Field
                        component={renderReactSelectInputField}
                        name="district"
                        placeholder="Select District"
                        className="crp-prod-filter-text"
                        type="select"
                        onChange={(val) =>handleDistrictChange(val, true)}
                        isMulti={projectDetails?.id ? false : true}
                        isSearchable={true}
                        defaultValue={selectedLocation?.district}
                        value={selectedLocation.district}
                        // isDisabled={projectDetails?.id ? true : false}
                        isOptionDisabled={(option) =>
                          handleDistrictOptionsDisable(option, formData?.district)
                        }
                        // options={renderAPIParameterOptions(districtList)}
                        options={districtList}
                      ></Field>
                    </FormGroup>
                  </>
                ) : (
                  ''
                )}
                {/* <Label for="date" className="label-st mb-1" style={{fontWeight:"bold"}}>
                  Year
                </Label>
                <FormGroup className="crop-prod-form-grp-st " style={{marginBottom:"0px"}}>
                 <Field
                    component={renderReactSelectInputField}
                    name="start_year"
                    bsSize="sm"
                    className="mt-1 crp-prod-filter-text"
                    placeholder="Start year"
                    type="select"
                    maxMenuHeight={100}
                    menuPlacement="top"
                    onChange={handleStartDate}
                    isMulti={false}
                    options={startYearList}
                  /> 
                </FormGroup>
                <FormGroup className="crop-prod-form-grp-st" style={{marginBottom:"0px"}}>
                   <Field
                    component={renderReactSelectInputField}
                    name="end_year"
                    bsSize="sm"
                     maxMenuHeight={100}
                     menuPlacement="top"
                    className="mt-1  crp-prod-filter-text"
                    placeholder="End year"
                    type="select"
                    onChange={handleEndDate}
                    isMulti={false}
                    options={endYearList}
                  ></Field> 
                </FormGroup> */}

                <Label for="crop" className="label-st" style={{ fontWeight: 'bold' }}>
                  Crop
                </Label>
                <div className="crop-prod-list-st mb-1">
                  {cropList?.length ? (
                    cropList?.map((item) => {
                      return (
                        <>
                          <FormGroup>
                            <Row className="crop-prod-list-row py-2">
                              <Col lg={9} md={9} sm={9}>
                                <Label check>{item.label}</Label>
                              </Col>
                              <Col lg={3} md={3} sm={3}>
                                {/* <Input type="checkbox" /> */}
                                <Field
                                  component={renderCheckBoxField}
                                  name="crop"
                                  placeholder="crop"
                                  type="checkbox"
                                  defaultChecked={item.checked}
                                  checked={item.checked}
                                  onChange={(e) => handleOnCropChange(e, item)}
                                ></Field>
                              </Col>
                            </Row>
                          </FormGroup>
                        </>
                      );
                    })
                  ) : (
                    <span>No crops available</span>
                  )}
                </div>

                {activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value ? (
                  <>
                    <Label for="Season" className="label-st" style={{ fontWeight: 'bold' }}>
                      Season
                    </Label>
                    <div className="crop-prod-list-st mb-1">
                      {seasonList?.length ? (
                        seasonList?.map((item) => {
                          return (
                            <>
                              <FormGroup>
                                <Row className="crop-prod-list-row py-2">
                                  <Col lg={9} md={9} sm={9}>
                                    <Label check>{item?.label}</Label>
                                  </Col>
                                  <Col lg={3} md={3} sm={3}>
                                    <Field
                                      component={renderCheckBoxField}
                                      name="season"
                                      placeholder="season"
                                      type="checkbox"
                                      defaultChecked={item.checked}
                                      checked={item.checked}
                                      onChange={(e) => handleOnSeasonChange(e, item)}
                                    ></Field>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </>
                          );
                        })
                      ) : (
                        <span>No seasons available</span>
                      )}
                    </div>
                    <Label for="Practise" className="label-st" style={{ fontWeight: 'bold' }}>
                      Practise
                    </Label>
                    <div className="crop-prod-list-st mb-1">
                      {practisesList?.length ? (
                        practisesList?.map((item) => {
                          return (
                            <>
                              {item?.hasSubCategory ? (
                                <>
                                  <FormGroup>
                                    <Label check className="py-1 px-2">
                                      {item?.label}{' '}
                                    </Label>
                                    {item?.subCategoryList?.length
                                      ? item?.subCategoryList?.map((subItem) => {
                                          return (
                                            <FormGroup>
                                              <Row className="">
                                                <Col lg={8} md={8} sm={8} className="py-1 px-4">
                                                  <Label check>{subItem?.label}</Label>
                                                </Col>
                                                <Col lg={4} md={3} sm={3} className="practises-st">
                                                  <Field
                                                    component={renderCheckBoxField}
                                                    name="practise"
                                                    placeholder="practise"
                                                    type="checkbox"
                                                    checked={subItem?.checked}
                                                    defaultChecked={subItem?.checked}
                                                    onChange={(value) =>
                                                      handleOnPractisesChange(value, subItem)
                                                    }
                                                  ></Field>
                                                </Col>
                                              </Row>
                                            </FormGroup>
                                          );
                                        })
                                      : ''}
                                  </FormGroup>
                                </>
                              ) : (
                                <FormGroup>
                                  <Row className="crop-prod-list-row py-2">
                                    <Col lg={9} md={9} sm={9} style={{paddingLeft: '3%'}}>
                                      <Label check>{item?.label}</Label>
                                    </Col>
                                    <Col lg={3} md={3} sm={3}>
                                      <Field
                                        component={renderCheckBoxField}
                                        name="practise"
                                        placeholder="practise"
                                        type="checkbox"
                                        checked={item?.checked}
                                        defaultChecked={item?.checked}
                                        onChange={(value) => handleOnPractisesChange(value, item)}
                                      ></Field>
                                    </Col>
                                  </Row>
                                </FormGroup>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <span>No practices available</span>
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}

                <Label for="date" className="label-st mb-1" style={{ fontWeight: 'bold' }}>
                  Year
                </Label>
                <FormGroup className="crop-prod-form-grp-st " style={{ marginBottom: '0px' }}>
                  <Field
                    component={renderReactSelectInputField}
                    name="start_year"
                    bsSize="sm"
                    className="mt-1 crp-prod-filter-text"
                    placeholder="Start year"
                    type="select"
                    maxMenuHeight={100}
                    menuPlacement="top"
                    onChange={handleStartDate}
                    isMulti={false}
                    options={startYearList}
                  />
                </FormGroup>
                
                <FormGroup className="crop-prod-form-grp-st" style={{ marginBottom: '0px' }}>
                  <Field
                    component={renderReactSelectInputField}
                    name="end_year"
                    bsSize="sm"
                    maxMenuHeight={100}
                    menuPlacement="top"
                    className="mt-1  crp-prod-filter-text"
                    placeholder="End year"
                    type="select"
                    onChange={handleEndDate}
                    isMulti={false}
                    options={endYearList}
                    // options={endYearList.filter(item => item?.value <= formData?.startYear?.value + 2)}
                  ></Field>
                </FormGroup>
                {minMaxError ? (
                  <div className="">
                    {/* {minMaxError ? ( */}
                    <FormFeedback invalid>
                      {t('DROUGHT_MONITORING.DROUGHT_MIN_MAX_ERROR_MESSAGE')}
                    </FormFeedback>
                    {/* ) : (
                    ''
                  )} */}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div
                className="pt-3 d-flex justify-content-center align-items-center"
                id="view_graph"
                data-tip
                data-for="view_graph"
              >
                <Button
                  className="crop-prod-view-graph-st"
                  onClick={''}
                  disabled={!isViewGraph}
                  block
                >
                  View Graph
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'CropProductionFilter',
  enableReinitialize: true,
  // validate,
})(CropProductionFilter);
