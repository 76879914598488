const WEATHER_CONST = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILED: 'FETCH_FAILED',

  //get forest data sources
  GET_FORECAST_DATA_SOURCES_REQUEST: 'GET_FORECAST_DATA_SOURCES_REQUEST',
  GET_FORECAST_DATA_SOURCES_SUCCESS: 'GET_FORECAST_DATA_SOURCES_SUCCESS',
  GET_FORECAST_DATA_SOURCES_FAILED: 'GET_FORECAST_DATA_SOURCES_FAILED',

  GET_FORECAST_DATA_REQUEST: 'GET_FORECAST_DATA_REQUEST',
  GET_FORECAST_DATA_SUCCESS: 'GET_FORECAST_DATA_SUCCESS',
  GET_FORECAST_DATA_FAILED: 'GET_FORECAST_DATA_FAILED',

  // NOAA TIMESERIES
  NOAA_TIMESERIES_REQUEST: 'NOAA_TIMESERIES_REQUEST',
  NOAA_TIMESERIES_SUCCESS: 'NOAA_TIMESERIES_SUCCESS',
  NOAA_TIMESERIES_FAILED: 'NOAA_TIMESERIES_FAILED',

  // ERPAS TIMESERIES
  ERPAS_TIMESERIES_REQUEST: 'ERPAS_TIMESERIES_REQUEST',
  ERPAS_TIMESERIES_SUCCESS: 'ERPAS_TIMESERIES_SUCCESS',
  ERPAS_TIMESERIES_FAILED: 'ERPAS_TIMESERIES_FAILED',

  // IRI TIMESERIES
  IRI_TIMESERIES_REQUEST: 'IRI_TIMESERIES_REQUEST',
  IRI_TIMESERIES_SUCCESS: 'IRI_TIMESERIES_SUCCESS',
  IRI_TIMESERIES_FAILED: 'IRI_TIMESERIES_FAILED',


// IRI AVAILABLE_DATES
IRI_AVAILABLE_DATES_REQUEST: 'IRI_AVAILABLE_DATES_REQUEST',
IRI_AVAILABLE_DATES_SUCCESS: 'IRI_AVAILABLE_DATES_SUCCESS',
IRI_AVAILABLE_DATES_FAILED: 'IRI_AVAILABLE_DATES_FAILED',

};

export default WEATHER_CONST;
