import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import '../../assets/CSS/Common.css';
import '../../assets/CSS/bulletin.css';
import '../../assets/CSS/static-page.css';
import '../../assets/CSS/UserGuideSearchContent.css';
import { Collapse, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/common/loader';
import { AiOutlineCaretUp, AiOutlineCaretDown } from 'react-icons/ai';
import _ from 'lodash';
import RightArrow from '../../assets/Images/back-button.svg';
import Footer from '../landing-page/footer';
import {
  getAllUserGuideRequest,
  searchByCriteriaUserGuideRequest,
} from '../../redux/actions';

const UserSearchDetailPage = () => {
  const [searchContent, setSearchContent] = useState([]);
  const [TopicHeading, setTopicHeading] = useState('');
  const [subTopicHeading, setSubTopicHeading] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  let history = useHistory();
  const { getAllUserGuideData, isGetAllUserGuideRequesting,searchByCriteriaUserGuideData } = useSelector(state => ({
    getAllUserGuideData: state.UserGuide.getAllUserGuideData,
    isGetAllUserGuideRequesting: state.UserGuide.isGetAllUserGuideRequesting,
    searchByCriteriaUserGuideData: state.UserGuide.searchByCriteriaUserGuideData,

  }));

  useEffect(() => {
    let search = window.location.search;
    let URLParams = new URLSearchParams(search);
    // let Id = URLParams.get('unique');
    // let topicId = URLParams.get('category');
    // let subTopicId = URLParams.get('sub-category');
    let topic = URLParams.get('topic');
    let sub_topic= URLParams.get('sub_topic');
    if (!_.isEmpty(topic) && !_.isEmpty(sub_topic)) {
      dispatch(searchByCriteriaUserGuideRequest({ topic_name: topic, name: sub_topic }));
    }
    // if (!_.isEmpty(subTopicId) && !_.isEmpty(topicId)) {
    //   dispatch(
    //     getAllUserGuideRequest({
    //       data: { id: Id, topic_id: topicId, subtopic_id: subTopicId, is_delete: 'False' },
    //     }),
    //   );
    // } else {
    //   dispatch(getAllUserGuideRequest({ data: { id: Id, topic_id: topicId, is_delete: 'False' } }));
    // }
  }, []);


  useEffect(()=>{
    if (!_.isEmpty(searchByCriteriaUserGuideData)){
      let id = searchByCriteriaUserGuideData?.data?.data?.result[0]?.id
      let topicId = searchByCriteriaUserGuideData?.data?.data?.result[0]?.topic_id
      if (!_.isEmpty(id) && !_.isEmpty(topicId)) {
        dispatch(
          getAllUserGuideRequest({
            fromBackOffice: false,
            data: { subtopic_id: id, topic_id: topicId, is_delete: 'False',is_backoffice: "False" },
          }),
        );
      } 
  }
  },[searchByCriteriaUserGuideData])


  useEffect(() => {
    let serchedResults = [];
    if (getAllUserGuideData?.data) {
      const { result } = getAllUserGuideData?.data;
      if (!_.isEmpty(result) && !_.isEmpty(result?.results)) {
        result.results.map(item => {
          serchedResults.push(item);
        });
        setSearchContent(serchedResults);
        setTopicHeading(result?.results?.[0].topic_name);
        setSubTopicHeading(result?.results?.[0].subtopic_name);
      }
    }
  }, [getAllUserGuideData]);
  const handleManageCropsBackButton = () => {
    history.goBack();
  };

 

  return (
    <>
      <div className="detail-page search-content-header">
        {isGetAllUserGuideRequesting && <Loader />}
        <div className="sidebar-user-guide">
          <div className="sidebarContainer">
            <div
              className="drought-category-style"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <div className="sidebar-header-container">
                <span>
                  {!isOpen ? (
                    <AiOutlineCaretDown size={15} className="collapse-icon-down" />
                  ) : (
                    <AiOutlineCaretUp size={15} className="collapse-icon-up" />
                  )}
                  <span className="topic-heading-text">
                    {TopicHeading?.length ? TopicHeading : ''}
                  </span>
                </span>
              </div>
            </div>
            <Collapse isOpen={isOpen}>
              <ul className="un-order-item">
                <a className={'selected-item'}>{subTopicHeading?.length ? subTopicHeading : ''}</a>
              </ul>
            </Collapse>
          </div>
        </div>
        <div className="detail-main-page">
          <Row>
            <Col lg={1} sm={3} xs={3}>
              <img
                src={RightArrow}
                className="manage-static-header-back-button mt-4"
                onClick={() => handleManageCropsBackButton()}
              />
            </Col>
          </Row>
          {searchContent
            ? searchContent.map((i, id) => {
                return (
                  <div className="m-3" id={`${i.subtopic_name}`}>
                    <p className="user-guide-detailed-heading-text">{i.title}</p>
                    <div
                      className="search-content-img discription-text"
                      dangerouslySetInnerHTML={{ __html: i?.content }}
                    ></div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
      <div className="">
        <Footer publicFooter={false} />
      </div>
    </>
  );
};

export default UserSearchDetailPage;
