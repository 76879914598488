import MAP_PREFERENCE_CONST from './mapPreferenceConst';

export const getMapPreferenceRequest = data => ({
  type: MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_REQUEST,
  payload: data,
});

export const getMapPreferenceSuccess = response => ({
  type: MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_SUCCESS,
  payload: response,
});

export const getMapPreferenceFailed = error => ({
  type: MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_FAILED,
  payload: error,
});

export const getMapPreferenceBoundariesRequest = (boundaryId, country, category, name, slug , legendName) => ({
  type: MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_BOUNDARIES_REQUEST,
  payload: {
    boundaryId,
    country,
    category,
    name,
    slug,
    legendName,
  },
});

export const getMapPreferenceBoundariesSuccess = response => ({
  type: MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_BOUNDARIES_SUCCESS,
  payload: response,
});

export const getMapPreferenceBoundariesFailed = error => ({
  type: MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_BOUNDARIES_FAILED,
  payload: error,
});

/*
 * Weather Map Preference
 */

// export const getMapPreferencesRequest = payload => ({
//   type: MAP_PREFERENCE_CONST.GET_MAP_PREFERENCES_REQUEST,
//   payload: payload || null,
// });

// export const getMapPreferencesSuccess = response => ({
//   type: MAP_PREFERENCE_CONST.GET_MAP_PREFERENCES_SUCCESS,
//   payload: response,
// });

// export const getMapPreferencesFailed = error => ({
//   type: MAP_PREFERENCE_CONST.GET_MAP_PREFERENCES_FAILED,
//   payload: error,
// });
