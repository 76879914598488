import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { change, Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import {
  Card,
  FormGroup,
  Label,
  CardBody,
  Form,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  Button
} from 'reactstrap';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { DEFAULT_COUNTRY, LOCATION, REACT_APP_API_URL } from '../../../Components/constants';
import { useDispatch, useSelector } from 'react-redux';
import DeleteFarmModal from './delete_farm';
import {
  getStateByCountryRequest,
  getDistrictByStateRequest,
  getSubDistrictByDistrictRequest,
  getBlockBySubDistrictRequest,
  getMapPreferenceRequest,
} from '../../../redux/actions';
import FarmIcon from '../../../assets/Images/FarmIcon.js';
import { t } from 'react-multi-lang';
import { renderReactSelectInputField } from '../../../Components/common/renderInputField';
import DropzoneUploadImg from '../../../assets/Images/dropzone-upload-img.png';
import FarmOptions from './farm-options';
import FarmListing from './farm-listing';
import Dropzone from 'react-dropzone';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { FARM_TRACKER_MODULE } from '../../../Components/constants';
import { showError } from '../../../Components/Notifications';

function Filter(props) {
  const {
    activeTab,
    handleToggle,
    handleSubmit,
    LocationList,
    cordinateAndZoom,
    farmList,
    handleSelectedFarmOption,
    handleFarmOptions,
    showFarmList,
    farmOptions,
    isShowListing,
    setIsShowListing,
    isOpenDeleteFarmModal,
    setIsOpenDeleteFarmModal,
    uploadedFiles,
    setUploadedFiles,
    setkml,
    isCreateFarm,
    changePolygonOptions,
    setShowPolygonDraw,
    isHideFarmCollapse,
    projectDetails,
    selectedFarmIndex,
    initialValues,
    handleCreateFarm
  } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [farmTrackerList, setFarmTrackerList] = useState([]);
  const [farmData, setFarmData] = useState('');

  const { Location } = useSelector((state) => ({
    Location: state.Location,
  }));
  useEffect(() => {
    if (farmList?.length) {
      const uniqueIds = [];
      const filteredArray = farmList.filter((element) => {
        const isDuplicate = uniqueIds.includes(element.id);

        if (!isDuplicate) {
          uniqueIds.push(element.id);

          return true;
        }

        return false;
      });
      setFarmTrackerList(filteredArray);
      setIsShowListing(true);
    } else {
      setFarmTrackerList([]);
      setIsShowListing(false);
    }
  }, [farmList]);

  useEffect(() => {
    let requestObj = { location_type: LOCATION.State, parent_id: DEFAULT_COUNTRY.id };
    dispatch(getMapPreferenceRequest({ country_id: DEFAULT_COUNTRY.id, module_name: 'farm' }));
    dispatch(getStateByCountryRequest({ requestObj, isCompareLocation: false }));
  }, []);

  useEffect(() => {
    let requestObj = {
      location_type: LOCATION.District,
      parent_id: location?.state?.projectData?.province?.id,
    };
    if (!_.isEmpty(requestObj?.parent_id)) {
      dispatch(getDistrictByStateRequest({ requestObj, isCompareLocation: false }));
    }
  }, [location?.state?.projectData?.province?.id]);

  // useEffect(() => {
  //   let requestObj = {
  //     location_type: LOCATION?.Sub_District,
  //     parent_id: location?.state?.projectData?.district?.id,
  //   };
  //   if (!_.isEmpty(requestObj?.parent_id)) {
  //     dispatch(getSubDistrictByDistrictRequest({ requestObj, isCompareLocation: false }));
  //   }
  // }, [location?.state?.projectData?.district?.id]);
  /////
  
  // useEffect(() => {
  //   let requestObj = {
  //     location_type: LOCATION?.Block,
  //     parent_id: location?.state?.projectData?.sub_district?.id,
  //   };
  //   if (!_.isEmpty(requestObj?.parent_id)) {
  //     dispatch(getBlockBySubDistrictRequest({ requestObj, isCompareLocation: false }));
  //   }
  // }, [location?.state?.projectData?.sub_district?.id]);

  useEffect(() => {
    if (LocationList) {
      if (LocationList.provinceList) setProvinceList(LocationList.provinceList);
      if (LocationList.districtList) setDistrictList(LocationList.districtList);
      if (LocationList.subDistrictList) {
        setSubDistrictList(LocationList.subDistrictList);
      }
      if (LocationList.blockList) setBlockList(LocationList.blockList);
    }
  }, [LocationList]);

  useEffect(() => {
    if (Location.stateList) {
      let arr = [];
      let result = '';
      let config = Location?.stateList?.config;
      if (Location?.stateList?.data) result = Location?.stateList?.data?.result;
      if (result) {
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            country_id: i.country_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        // setProvinceList(arr);
      }
    }
  }, [Location.stateList]);

  useEffect(() => {
    let arr = [];
    if (Location.districtList) {
      let result = '';
      let config = Location?.districtList?.config;
      if (Location?.districtList?.data) result = Location?.districtList?.data?.result;
      if (result) {
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        // dispatch(change('FilterFarmTracker', 'district', ''));
        // dispatch(change('FilterFarmTracker', 'sub_district', ''));
        // dispatch(change('FilterFarmTracker', 'block', ''));
        // setDistrictList(arr);
      }
    }
  }, [Location.districtList]);

  useEffect(() => {
    let arr = [];
    if (Location.subDistrictList) {
      let result = '';
      let config = Location?.subDistrictList?.config;
      if (Location?.subDistrictList?.data) result = Location?.subDistrictList?.data?.result;
      if (result) {
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        // dispatch(change('FilterFarmTracker', 'sub_district', ''));
        // dispatch(change('FilterFarmTracker', 'block', ''));
        setSubDistrictList(arr);
      }
    }
  }, [Location.subDistrictList]);

  useEffect(() => {
    let arr = [];
    if (Location.blockList) {
      let result = '';
      let config = Location?.blockList?.config;
      if (Location?.blockList?.data) result = Location?.blockList?.data?.result;
      if (result) {
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        // dispatch(change('FilterFarmTracker', 'block', ''));
        setBlockList(arr);
      }
    }
  }, [Location.blockList]);

  useEffect(() => {
    if (Object.keys(selectedOption).length) {
      const { latitude, longitude, zoom_level } = selectedOption;
      return props.handleCordinates(
        [
          latitude ? latitude : cordinateAndZoom?.locationCordinates.latitude,
          longitude ? longitude : cordinateAndZoom?.locationCordinates.longitude,
        ],
        zoom_level ? zoom_level : cordinateAndZoom?.zoom,
      );
    } else {
      return props.handleCordinates(
        [DEFAULT_COUNTRY.latitude, DEFAULT_COUNTRY.longitude],
        DEFAULT_COUNTRY.zoom_level,
      );
    }
  }, [selectedOption]);

  const handleProvinceChange = (data) => {
    // let requestObj = { location_type: LOCATION.District, parent_id: data?.id };
    setSelectedOption(data);
    // dispatch(getDistrictByStateRequest({ requestObj, isCompareLocation: false }));
  };

  const handleDistrictChange = (data) => {
    // let requestObj = { location_type: LOCATION.Sub_District, parent_id: data?.id };
    setSelectedOption(data);
    // dispatch(getSubDistrictByDistrictRequest({ requestObj, isCompareLocation: false }));
  };

  const handleSubDistrictChange = (data) => {
    dispatch(change('FilterFarmTracker', 'block', ''));
    let requestObj = { location_type: LOCATION.Block, parent_id: data?.id };
    setSelectedOption(data);
    dispatch(getBlockBySubDistrictRequest({ requestObj, isCompareLocation: false }));
  };

  const toggle = (index, trackerList) => {
    if (trackerList?.length) {
      let list = JSON.parse(JSON.stringify(trackerList));
      let updatedList = list?.map((item, i) =>
        i === index ? { ...item, isOpenAction: !item.isOpenAction } : item,
      );
      setFarmTrackerList(updatedList);
    }
  };

  const getSelectedFarm = (data) => {
    props.getSelectedFarm(data);
    const longitude = data?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[0];
    const latitude = data?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[1];
    if (data?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.length !== 0) {
      return props.handleCordinates(
        [
          latitude ? latitude : cordinateAndZoom?.locationCordinates[0],
          longitude ? longitude : cordinateAndZoom?.locationCordinates[1],
        ],
        18,
      );
    }
  };

  const getFarmData = (data, index) => {
    props.getFarmData(data, index);
    const longitude = data?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[0];
    const latitude = data?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[1];
    if (data?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.length !== 0) {
      return props.handleCordinates(
        [
          latitude ? latitude : cordinateAndZoom?.locationCordinates[0],
          longitude ? longitude : cordinateAndZoom?.locationCordinates[1],
        ],
        18,
      );
    }
  };

  const handleDelete = (item) => {
    setFarmData(item);
    setIsOpenDeleteFarmModal(!isOpenDeleteFarmModal);
  };

  const NavigateToRiskAnalytics = (item) => {
    window.open(`/risk-analytics?project_id=${projectDetails?.project_id}&farm_id=${item?.id}`)
  }

  const parseDocument = (file) => {
    let fileReader = new FileReader();
    fileReader.onload = async (e) => {
      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(e.target.result, 'text/xml');
      setkml(xmlDoc);
    };
    fileReader.onloadend = async (e) => {
      setUploadedFiles(file);
    };
    fileReader.readAsText(file);
  };

  const handleDeleteToggle = () => setIsOpenDeleteFarmModal(!isOpenDeleteFarmModal);

  const onFormSubmit = () => {};

  const renderAPIParameterOptions = (list, value) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map((param) => {
          let data = {
            ...param,
            value: param.name,
            label: `${param.name}`,
            id: value === 'country' ? param.name : param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleDropFiles = (files) => {
    if(files.length > 1){
      showError('please upload a single file')
    }
    else{
      parseDocument(files[0]);
    }
  };

  const renderDrawFarmMsg = () => {
    return (
      <>
        <div className="mx-1 draw-msg-st">
          <div className="mt-1">{t('FARM_TRACKER.NO_FARMS')}</div>
          <div className="sub-text mt-1"> {t('FARM_TRACKER.DRAW_FARM_TEXT')}</div>
        </div>
      </>
    );
  };

  const updateZoomLevel = (data) => {
    setSelectedOption(data);
    props.setCordinateAndZoom((prev) => {
      return {
        ...prev,
        zoom: props.initialValues.zoom_level,
      };
    });
  };

  const handleInfo = (e, indices) => {
    e?.stopPropagation();
    window.open(
      `/user-guide-details?topicId=${FARM_TRACKER_MODULE.id}&&sub-topic-name=${indices}&topic_name=${indices}`,
    );
  };

  return (
    <>
      {isOpenDeleteFarmModal ? (
        <DeleteFarmModal
          isOpen={isOpenDeleteFarmModal}
          toggle={handleDeleteToggle}
          farmData={farmData}
          handleDeleteFarm={props.handleDeleteFarm}
        />
      ) : (
        ''
      )}
      {props?.isHideBar ? (
        <div className="expand-bar">
          <span className="cursor-ponter navyBlueColor" onClick={() => props.setBar(!props.isHideBar)}>
            {t('CLIMATE_WEATHER_FILTER.EXPAND_BAR')}
            <span>
              <MdKeyboardArrowLeft
                className="hide-icon expand-margin"
                size={25}
              />
            </span>
          </span>
        </div>
      ) : (
        <div>
          <div className="filter-sidebar-section-drought-monitor farm-filter-section mt-1">
            <div className="filter-div">
              <Card className="card-style">
                <CardBody>
                  <div
                    className="d-flex justify-content-end  hide-bar-div my-2 mx-3"
                    title={
                      isHideFarmCollapse ? '' : 'Please close farm details to hide this collapse.'
                    }
                  >
                      <span  className='cursor-ponter' onClick={() => props.setBar(!props.isHideBar)}>
                        <span style={isHideFarmCollapse ? {} : { pointerEvents: 'none' }}>
                          <MdKeyboardArrowRight
                            className="hide-icon hide-margin"
                            size={25}
                          />
                        </span>
                        <span
                          className="hidebar-show"
                          style={isHideFarmCollapse ? {} : { pointerEvents: 'none' }}
                        >
                          {t('CLIMATE_WEATHER_FILTER.HIDE_BAR')}
                        </span>
                      </span>
                  </div>
                  <div className="forecast-monitoring farm-nav">
                    <Nav tabs>
                      <NavItem className="farm-cl-back-st mx-2">
                        <NavLink
                          className={activeTab === 'FARM_TRACKING' ? 'active' : ''}
                          onClick={() => handleToggle('FARM_TRACKING')}
                        >
                          {activeTab === 'FARM_TRACKING' ? (
                            <span>
                              <FarmIcon fill="#DD9103" />{' '}
                            </span>
                          ) : (
                            <span>
                              <FarmIcon fill="#363636" />{' '}
                            </span>
                          )}
                          {t('FARM_TRACKER.FARMS')}
                        </NavLink>
                      </NavItem>
                      <NavItem className="farm-cl-back-st mx-2">
                        <NavLink
                          className={activeTab === 'LOCATION' ? 'active' : ''}
                          onClick={() => handleToggle('LOCATION')}
                        >
                          {activeTab === 'LOCATION' ? (
                            <span>
                              <HiOutlineLocationMarker size={23} color={'#DD9103'} />{' '}
                            </span>
                          ) : (
                            <span>
                              <HiOutlineLocationMarker size={23} />{' '}
                            </span>
                          )}
                          <span className="pt-2 mt-2">{t('FARM_TRACKER.LOCATION')}</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <>
                        <TabPane tabId={'LOCATION'}>
                          <div className="farm-tracker-map-filter">
                            <Form onSubmit={handleSubmit(onFormSubmit)} className="my-2 mx-2">
                              <FormGroup className="textOnInput mb-2">
                                <Label for="province">{t('FARM_TRACKER.PROVINCE')}</Label>
                                <Field
                                  component={renderReactSelectInputField}
                                  name="province"
                                  className="map-input-style-css"
                                  placeholder=""
                                  type="select"
                                  onChange={handleProvinceChange}
                                  required={true}
                                  isMulti={false}
                                  isDisabled={true}
                                  // isDisabled={props.cordinatesAndZoom?.zoom < 10 ? false : true}
                                  options={renderAPIParameterOptions(provinceList)}
                                ></Field>
                              </FormGroup>
                              <FormGroup className="textOnInput mb-2">
                                <Label for="district">{t('FARM_TRACKER.DISTRICTS')}</Label>
                                <Field
                                  component={renderReactSelectInputField}
                                  name="district"
                                  className="map-input-style-css"
                                  placeholder=""
                                  type="select"
                                  onChange={handleDistrictChange}
                                  required={true}
                                  isMulti={false}
                                  isDisabled={true}
                                  // isDisabled={props.cordinatesAndZoom?.zoom < 10 ? false : true}
                                  options={renderAPIParameterOptions(districtList)}
                                ></Field>
                              </FormGroup>
                              {initialValues?.sub_district?.id ? (
                                <FormGroup className="textOnInput mb-2">
                                  <Label for="sub_district" className="choose_label">
                                    {t('FARM_TRACKER.SUB_DISTRICTS')}
                                  </Label>
                                  <Field
                                    name="sub_district"
                                    component={renderReactSelectInputField}
                                    className="map-input-style-css"
                                    id="sub_district"
                                    options={subDistrictList || []}
                                    onChange={handleSubDistrictChange}
                                    placeholder=""
                                    // isDisabled={props.cordinatesAndZoom?.zoom < 10 ? false : true}
                                    isMulti={false}
                                    isDisabled={true}
                                  />
                                </FormGroup>
                              ) : (
                                <></>
                              )}
                              {initialValues?.block?.id ? (
                                <FormGroup className="textOnInput mb-2">
                                  <Label for="block" className="choose_label">
                                    {t('FARM_TRACKER.BLOCKS')}
                                  </Label>
                                  <Field
                                    name="block"
                                    component={renderReactSelectInputField}
                                    className="map-input-style-css"
                                    id="block"
                                    onChange={updateZoomLevel}
                                    options={blockList || []}
                                    placeholder=""
                                    // isDisabled={props.cordinatesAndZoom?.zoom < 10 ? false : true}
                                    isMulti={false}
                                    isDisabled={true}
                                  />
                                </FormGroup>
                              ) : (
                                <></>
                              )}
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={'FARM_TRACKING'}>
                          <div className="">
                            <div className="farm-tab-msg">
                              <div className="mb-1">
                                {!isShowListing && farmOptions?.every((i) => !i.selected) ? (
                                  <div className="farm-list-txt-st">
                                    <span
                                      className="farm-cl-back-st cursor-ponter"
                                      onClick={showFarmList}
                                    >
                                      <span>
                                        <MdKeyboardArrowRight size={25} />
                                      </span>
                                      <span className="hidebar-show">
                                        {' '}
                                        {t('FARM_TRACKER.FARM_LIST')}
                                      </span>{' '}
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <span
                                      className="farm-cl-back-st farm-cl-text-st cursor-ponter"
                                      onClick={handleFarmOptions}
                                    >
                                      <span>
                                        <MdKeyboardArrowLeft size={25} />
                                      </span>
                                      <span className="hidebar-show">
                                        {' '}
                                        {t('FARM_TRACKER.ADD_FARM')}
                                      </span>{' '}
                                      <i
                                        className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                                        id="add-farm-tool-tip"
                                        data-tip
                                        data-for="add-farm-tool-tip"
                                        onClick={(e) => handleInfo(e, t('FARM_TRACKER.ADD_FARM'))}
                                      />
                                      <ReactTooltip
                                        id="add-farm-tool-tip"
                                        effect="solid"
                                        place="top"
                                      >
                                        Click here to know more.
                                      </ReactTooltip>
                                    </span>
                                  </div>
                                )}
                              </div>
                              {!isShowListing && farmOptions?.every((i) => i.selected === false) ? (
                                <FarmOptions handleSelectedFarmOption={handleSelectedFarmOption} />
                              ) : (
                                <>
                                  <div>
                                    {isShowListing &&
                                    (changePolygonOptions?.some(
                                      (i) => i.id === 'upload_farm' && !i.selected,
                                    ) ||
                                      !_.isEmpty(uploadedFiles)) ? (
                                      <div className="farm-list-div">
                                        <FarmListing
                                          farmTrackerList={farmTrackerList}
                                          handleSelectedFarmOption={handleSelectedFarmOption}
                                          toggle={toggle}
                                          getFarmData={getFarmData}
                                          getSelectedFarm={getSelectedFarm}
                                          handleDelete={handleDelete}
                                          NavigateToRiskAnalytics={NavigateToRiskAnalytics}
                                          setFarmLayersData={props.setFarmLayersData}
                                          setShowPolygonDraw={setShowPolygonDraw}
                                          projectDetails={projectDetails}
                                          selectedFarmIndex={selectedFarmIndex}
                                        />
                                      </div>
                                    ) : (
                                      <>
                                        {farmOptions?.some(
                                          (item) => item.id === 'draw_farm' && item.selected,
                                        ) ? (
                                          renderDrawFarmMsg()
                                        ) : (
                                          <>
                                            {farmOptions?.some(
                                              (item) => item.id === 'upload_farm' && item.selected,
                                            ) ? (
                                              <>
                                                <div className="mx-2 cursor-ponter">
                                                  <Dropzone
                                                    onDrop={(acceptedFiles) =>
                                                      handleDropFiles(acceptedFiles)
                                                    }
                                                    {...props.handleUploadedFiles}
                                                    accept={{
                                                      // "application/vnd.google-earth.kml+xml" : ['.kml'],
                                                      'application/inkml+xml': ['.kml', '.geojson'],
                                                      // "application/geo+json" : ['.geojson'],
                                                      'text/plain': ['.wkt'],
                                                      'text/xml': ['.geojson'],
                                                    }}
                                                  >
                                                    {({ getRootProps, getInputProps }) => (
                                                      <div
                                                        {...getRootProps({ className: 'dropzone' })}
                                                      >
                                                        <input {...getInputProps()} />
                                                        <div>
                                                          <img
                                                            src={DropzoneUploadImg}
                                                            width={50}
                                                            height={50}
                                                            alt=""
                                                          />
                                                          <div className="text-dark upload-text mt-2">
                                                            {t('FARM_TRACKER.UPLOAD_FILE')}
                                                          </div>
                                                          <div className="sub-text mt-2">
                                                            {t('FARM_TRACKER.DROPZONE_MSG')}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Dropzone>
                                                </div>
                                                {/* <Button className='mx-2 my-2' color='primary' disabled={!_.isEmpty(props.kmlData) ? false : true} onClick={handleCreateFarm}>Create Farm</Button> */}
                                                {isCreateFarm ? (
                                                  <>
                                                    <div className="mx-3 my-5 filter-submit">
                                                      <div
                                                        className="apply-layers"
                                                        id="apply_layers"
                                                        data-tip
                                                        data-for="apply_layers"
                                                      >
                                                        <Button
                                                          className="navyBlueBgColor m-auto px-3 py-2"
                                                          onClick={handleCreateFarm}
                                                          disabled={
                                                            !_.isEmpty(props.kmlData) ? false : true
                                                          }
                                                        >
                                                          Create Farm
                                                        </Button>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              ''
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </TabPane>
                      </>
                    </TabContent>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default reduxForm({
  form: 'FilterFarmTracker',
  enableReinitialize: true,
})(Filter);
