import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import '../../assets/CSS/LeafletWrapper.css';

const CarousalModal = (props) => {
  const { setModalOpen, modalOpen, getByIdFarmData, selectedGeotaggedImage } = props;
  const [modal, setModal] = useState();
  const [geoTagImages, setgeotagImages] = useState();
  const [activeIndex,setActiveIndex] = useState(0)

  const viewtoggle = (value) => {
    setModal(false);
  };

  const handleClose = () => {
    setModal(false);
    setModalOpen(false);
  };

  useEffect(() => {
    setModal(modalOpen);
  }, [modalOpen]);

  useEffect(()=>{
    setActiveIndex(selectedGeotaggedImage)
  },[selectedGeotaggedImage])

  useEffect(() => {
    if (getByIdFarmData) {
      let arr =[]      
      let image1 = returnImageUrl(getByIdFarmData?.farm[0]?.odk_image1)
      if (image1) arr.push({ index: 1, src: image1 || getByIdFarmData?.farm[0]?.odk_image1 })
  
      let image2 = returnImageUrl(getByIdFarmData?.farm[0]?.odk_image2)
      if (image2) arr.push({ index: 2, src: image2 || getByIdFarmData?.farm[0]?.odk_image2 })
  
      let image3 = returnImageUrl(getByIdFarmData?.farm[0]?.odk_image3)
      if (image3) arr.push({ index: 3, src: image3 || getByIdFarmData?.farm[0]?.odk_image3 })
      if(arr?.length) setgeotagImages(arr)
    }
  }, [getByIdFarmData]);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  const returnImageUrl = (image) => {
    if (
      image.trim().length !== 0
    ){
      if(image.includes('id'))
        return image.replace('open', 'uc') 
      else{
        const myArray = image.split("/");
        const imageId = myArray?.find((x,index)=> { 
        if(myArray[index-1] === "d" && myArray[index+1].includes("view")) 
         return x 
        })
        if(imageId) {
          let staticUrl = "https://drive.google.com/uc?export=view&id=imageId"
          return staticUrl.replace('imageId',imageId) 
        }
        else return image;
      }
    }
  }

  return (
    <>
      <div className="shareinformationmodal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className="shareinformationmodal"
          backdrop="static"
          style={{ width: '75%' }}
        >
          <ModalHeader className="weather-forecast-modal-title" toggle={handleClose}>
            Geotagged images
          </ModalHeader>
          <ModalBody>
            {geoTagImages?.length ? (
              <Carousel activeIndex={activeIndex} onSelect={handleSelect}>
                {geoTagImages?.map((item, key) => {
                  return (
                    <Carousel.Item key={key}>
                      <img className="d-block w-100" src={item.src} alt="Not_available" height='360px' />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            ) : (
              <p style={{ marginLeft: '13px' }}>No geotagged images available.</p>
            )}
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default CarousalModal;
