/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from 'react-multi-lang';
import UserGuide from '../../assets/Images/User_Guide.svg';
import ClimateWeather from '../../assets/Images/Climate_Weather.svg';
import CropHealthMonitoring from '../../assets/Images/Crop_Health_Monitoring.svg';
import Projects from '../../assets/Images/Projects.svg';
import RiskAnalytics from '../../assets/Images/Risk_Analytics.svg';
import Carousel from './carousal';
import Footer from './footer';
import { getToken } from '../../Components/common/utils';
import HomeCard from '../../Components/common/HomeCard';
import carouselTopWaterlets from '../../assets/Images/carouselTopWaterlets.png';
import climateAndWeather from '../../assets/Images/ClimateAndWeather_New.png';
import cropAndHealth from '../../assets/Images/CropAndHealthNew.png';
import farmTracker from '../../assets/Images/farmTrackerNew.png';
import riskAnalytics from '../../assets/Images/RiskAnalyticsNew.png';
import userGuide from '../../assets/Images/UserGuideNew.png';
import cardBackImage from '../../assets/Images/cardBackImage.png';
import lightBlueBackImage from '../../assets/Images/lightBlueImage.png';
import lightYellowWaterlet from '../../assets/Images/lightYellowWaterlet.png';
import { Row, Col, Card, Button } from 'reactstrap';
import { AiOutlineArrowRight } from 'react-icons/ai';
import WeatherForecastModal from './weather-forecast-modal';
import CropHealthModal from '../CropHealthMonitoring/crop-health-modal';
import { getBannersRequest } from '../../redux/home/homeActions';
import Routes from '../../routes/routes';
import { Navbar, Nav } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import CommonToolTip from '../../Components/common/CommonToolTip';
import CommonModulesHeader from '../../Components/common/CommonModulesHeader';
import Cookies from 'js-cookie';

const HomePage = () => {
  ///
  const dispatch = useDispatch();
  const [BannersData, setBannersData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [subHeadingList, setSubHeadingList] = useState();
  const [showTopBtn, setShowTopBtn] = useState(false);
  // const [cropHealthmodalOpen, setCropHealthmodalOpen] = useState(false);
  // const { Banners } = useSelector(state => ({
  //   Banners: state.Home,
  // }));
  // useEffect(() => {
  //   dispatch(getBannersRequest());
  // }, []);

  // useEffect(() => {
  //   if (Banners.getBannersData) {
  //     const { result } = Banners.getBannersData.data;
  //     setBannersData(result);
  //   }
  // }, [Banners.getBannersData]);

  // const buttonClick = item => {
  //   if (item === 'Climate & Weather') {
  //     setModalOpen(true);
  //   }
  //   if (item === 'Crop Health Monitoring') {
  //     setCropHealthmodalOpen(true);
  //   }
  // };

  // const handleModalToggle = () => {
  //   setModalOpen(false);
  // };

  // ///

  // let history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const history = useHistory();
  const [cropHealthmodalOpen, setCropHealthmodalOpen] = useState(false);

  const cardsData = [
    {
      cardTitle: 'Weather & Water',
      cardDesc:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
      cardIcon: ClimateWeather,
      cardPath: '/weather-forecast',
    },
    {
      cardTitle: 'Crop Health Monitoring',
      cardDesc:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
      cardIcon: CropHealthMonitoring,
      cardPath: '/crop-health-monitoring',
    },
    {
      cardTitle: 'Farm Tracker',
      cardDesc:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
      cardIcon: CropHealthMonitoring,
      cardPath: '/farms-list',
    },
    {
      cardTitle: 'Projects',
      cardDesc:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
      cardIcon: Projects,
      cardPath: '',
    },
    {
      cardTitle: 'Risk Analytics',
      cardDesc:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
      cardIcon: RiskAnalytics,
      cardPath: '',
    },
    {
      cardTitle: 'Climate & Weather',
      cardDesc:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
      cardIcon: ClimateWeather,
      cardPath: '',
    },
    {
      cardTitle: 'Crop Health Monitoring',
      cardDesc:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
      cardIcon: CropHealthMonitoring,
      cardPath: '',
    },
    {
      cardTitle: 'Projects',
      cardDesc:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
      cardIcon: Projects,
      cardPath: '',
    },
    {
      cardTitle: 'User Guide',
      cardDesc:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
      cardIcon: UserGuide,
      cardPath: '/user-guide',
    },
  ];

  const { Banners } = useSelector((state) => ({
    Banners: state.Home,
  }));

  useEffect(() => {
    dispatch(getBannersRequest());
    if (Routes) {
      let a = [];
      Routes.map((i) => {
        if (i.visible) {
          a.push(i);
        }
      });
      setSubHeadingList(a);
    }

    window.addEventListener('scroll', () => {
      if (window.scrollY > 350) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  
  useEffect(() => {
    if (Banners.getBannersData) {
      const { result } = Banners.getBannersData.data;
      setBannersData(result);
    }
  }, [Banners.getBannersData]);

  const buttonClick = (item) => {
    if (item === 'Weather & Water') {
      setModalOpen(true);
    }
    if (item === 'Crop Health Monitoring') {
      setCropHealthmodalOpen(true);
    }
    if (item === 'Farm Tracker') {
      history.push('/farms-list');
    }
    if (item === 'Risk Analytics') {
      history.push('/risk-analytics');
    }
    if (item === 'User Guide') {
      history.push('/user-guide');
    }
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleModalToggle = () => {
    setModalOpen(false);
  };
  return (
    <>
      <img src={carouselTopWaterlets} className="carousel-waterlet" />
      <Carousel className="carousel-contaier" carouselData={BannersData} />
      {showTopBtn && (
            <CommonModulesHeader subHeadingList={subHeadingList} path={window.location.href}/>
        // <div className="headernav-div">
        //   <Navbar className="public-api-navbar p-0 nav2" expand="md">
        //     <Nav className="mx-auto mobi-scroll" navbar>
        //       <ul className="nav-menu-items mobi-scroll">
        //         <>
        //           <li
        //             key={0}
        //             className={
        //               window.location.href.includes(subHeadingList?.[0].path)
        //                 ? 'nav-text active cursor-pointer'
        //                 : 'cursor-pointer'
        //             }
        //             onClick={() => history.push('/home')}
        //           >
        //             <span className="icon-color">{subHeadingList?.[0].icon}</span>&nbsp; &nbsp;
        //             {subHeadingList?.[0].name}
        //           </li>
        //           {(window.location.href.includes('weather-forecast') ||
        //             !window.location.href.includes('weather-monitoring') ||
        //             !window.location.href.includes('weather-forecast')) &&
        //             !window.location.href.includes('weather-monitoring') && (
        //               <li
        //                 key={1}
        //                 className={
        //                   window.location.href.includes(subHeadingList?.[1]?.path)
        //                     ? 'nav-text active cursor-pointer'
        //                     : 'cursor-pointer'
        //                 }
        //                 onClick={() => history.push('/weather-forecast')}
        //               >
        //                 <span className="icon-color">{subHeadingList?.[1].icon}</span>&nbsp; &nbsp;
        //                 {subHeadingList?.[1].name}
        //               </li>
        //             )}
        //           {window.location.href.includes('weather-monitoring') && (
        //             <li
        //               key={2}
        //               className={
        //                 window.location.href.includes(subHeadingList?.[2]?.path)
        //                   ? 'nav-text active cursor-pointer'
        //                   : 'cursor-pointer'
        //               }
        //               onClick={() => history.push('/weather-monitoring')}
        //             >
        //               <span className="icon-color">{subHeadingList?.[2].icon}</span>&nbsp; &nbsp;
        //               {subHeadingList?.[2].name}
        //             </li>
        //           )}
        //           <li
        //             key={3}
        //             className={
        //               window.location.href.includes(subHeadingList?.[3].path) ||
        //               window.location.href.includes(subHeadingList?.[4].path)
        //                 ? 'nav-text active cursor-pointer'
        //                 : 'cursor-pointer'
        //             }
        //             onClick={() => history.push('/crop-health-monitoring?key=agriculture')}
        //           >
        //             <span className="icon-color">{subHeadingList?.[3].icon}</span>&nbsp; &nbsp;
        //             {subHeadingList?.[3].name}
        //           </li>
        //           {getToken() === false ? (
        //             <CommonToolTip moduleName={'Farm Tracker'}>
        //               <li
        //                 key={4}
        //                 className={
        //                   window.location.href.includes(subHeadingList?.[5].path)
        //                     ? 'nav-text active cursor-pointer'
        //                     : 'cursor-pointer'
        //                 }
        //                 onClick={() => (getToken() ? history.push('/farms-list') : '')}
        //               >
        //                 <span className="icon-color">{subHeadingList?.[5].icon}</span>&nbsp; &nbsp;
        //                 {subHeadingList?.[5].name}
        //               </li>
        //             </CommonToolTip>
        //           ) : (
        //             <li
        //               key={4}
        //               className={
        //                 window.location.href.includes(subHeadingList?.[5].path)
        //                   ? 'nav-text active cursor-pointer'
        //                   : 'cursor-pointer'
        //               }
        //               onClick={() => (getToken() ? history.push('/farms-list') : '')}
        //             >
        //               <span className="icon-color">{subHeadingList?.[5].icon}</span>&nbsp; &nbsp;
        //               {subHeadingList?.[5].name}
        //             </li>
        //           )}

        //           {getToken() === false ? (
        //             <CommonToolTip moduleName={'Risk Analytics'}>
        //               <li
        //                 key={5}
        //                 className={
        //                   window.location.href.includes(subHeadingList?.[6].path)
        //                     ? 'nav-text active cursor-pointer'
        //                     : 'cursor-pointer'
        //                 }
        //                 onClick={() => (getToken() ? history.push('/risk-analytics') : '')}
        //               >
        //                 <span className="icon-color">{subHeadingList?.[6].icon}</span>&nbsp; &nbsp;
        //                 {subHeadingList?.[6].name}
        //               </li>
        //             </CommonToolTip>
        //           ) : (
        //             <li
        //               key={5}
        //               className={
        //                 window.location.href.includes(subHeadingList?.[6].path)
        //                   ? 'nav-text active cursor-pointer'
        //                   : 'cursor-pointer'
        //               }
        //               onClick={() => (getToken() ? history.push('/risk-analytics') : '')}
        //             >
        //               <span className="icon-color">{subHeadingList?.[6].icon}</span>&nbsp; &nbsp;
        //               {subHeadingList?.[6].name}
        //             </li>
        //           )}
        //           <li
        //             key={6}
        //             className={
        //               window.location.href.includes(subHeadingList?.[7].path)
        //                 ? 'nav-text active cursor-pointer'
        //                 : 'cursor-pointer'
        //             }
        //             onClick={() => history.push('/user-guide')}
        //           >
        //             <span className="icon-color">{subHeadingList?.[7].icon}</span>&nbsp; &nbsp;
        //             {subHeadingList?.[7].name}
        //           </li>
        //         </>
        //       </ul>
        //     </Nav>
        //   </Navbar>
        // </div>
      )}

      <div className="about-container">
        <h1 className="about-text">About Portal</h1>
        <p className="about-paragraph">
          Earth observation for Agricultural Risk Management (EO4ARM) platform is to support various
          institutions including insurance companies to strengthen agricultural insurance program to
          enhance agricultural resilience among smallholder farmers in Sri Lanka. The platform
          provides a complete stack of solutions ranging from weather forecast, monitoring of floods
          and drought, crop health status and integrate farm-level updates for assessing climate
          risks among agencies for timely compensation and mitigation measures. The platform is
          developed using cloud-based spatial analytical framework to have seamless access to near
          real-time publicly available satellite data namely NASA MODIS, ESA Sentinel 1 & 2 for
          fine-scale agricultural insurance monitoring. The platform has capacity to access
          historical data to assist various partners to promote new insurance products, disaster
          preparedness program including climate resilience strategies.
        </p>
      </div>
      <Row className="card-position-info">
        <Col sm={12} lg={6}>
          <h1 className="about-text">{cardsData[0].cardTitle}</h1>
          <p className="card-paragraph">
            This tool offers short-term to seasonal weather forecasts as well as historical weather
            data to guide users for drought early warning and management measures. It helps to
            analyze the current and future weather using different data sources.It provides the
            users with a flexibility to take an in-depth view of weather across the provinces in the
            country.
          </p>
          <Col sm={6} lg={3}>
            {getToken() ? (
              <Button
                type="button"
                className="card-button"
                onClick={() => buttonClick(cardsData[0].cardTitle)}
                style={{ pointerEvents: 'auto' }}
              >
                Explore{' '}
                <span className="right-arrow">
                  <AiOutlineArrowRight />
                </span>
              </Button>
            ) : (
              <CommonToolTip moduleName={"Weather & Water"} routeName={'weather-forecast'}>
                <Button
                  type="button"
                  className="card-button disabled-card-button"
                  // onClick={() => buttonClick(cardsData[0].cardTitle)}
                  // disabled={getToken() ? false : true}
                  style={{ pointerEvents: 'auto' }}
                >
                  Explore{' '}
                  <span className="right-arrow">
                    <AiOutlineArrowRight />
                  </span>
                </Button>
              </CommonToolTip>
            )}
          </Col>
          {/* <button className="card-button" onClick={() => buttonClick(cardsData[0].cardTitle)}>
            Explore{' '}
            <span className="right-arrow">
              <AiOutlineArrowRight />
            </span>
          </button> */}
        </Col>
        <Col sm={12} lg={6} className="waterlet-position-relative">
          <img className="waterlet-position" src={cardBackImage} />
          <img className="image-position image-in-medium" src={climateAndWeather} />
        </Col>
      </Row>
      <Row className="card-content">
        <Col sm={12} lg={6}>
          <img className="left-sided-image" src={cropAndHealth} />
        </Col>
        <Col sm={12} lg={6}>
          <h1 className="about-text">Crop Health Monitor</h1>
          <p className="card-paragraph">
            The tool allows the users to monitor past and current conditions of crop and available
            water resources which helps to determine the drought/flood conditions to promote
            proactive disaster management measures for the insurance.It utilizes modern and latest
            information to monitor the condition of crops. It enables the farmer/user to implement
            timely interventions that ensure optimal yields at the end of the season.
          </p>
          <Col sm={6} lg={3}>
            {getToken() ? (
              <Button
                type="button"
                className="card-button"
                onClick={() => buttonClick(cardsData[1].cardTitle)}
                style={{ pointerEvents: 'auto' }}
              >
                Explore{' '}
                <span className="right-arrow">
                  <AiOutlineArrowRight />
                </span>
              </Button>
            ) : (
              <CommonToolTip moduleName={"Crop Health Monitor"} routeName={'crop-health-monitoring'}>
                <Button
                  type="button"
                  className="card-button disabled-card-button"
                  // onClick={() => buttonClick(cardsData[1].cardTitle)}
                  // disabled={getToken() ? false : true}
                  style={{ pointerEvents: 'auto' }}
                >
                  Explore{' '}
                  <span className="right-arrow">
                    <AiOutlineArrowRight />
                  </span>
                </Button>
              </CommonToolTip>
            )}
          </Col>
        </Col>
      </Row>
      <Row className="left-card-content">
        <Col sm={12} lg={6}>
          <h1 className="about-text">{cardsData[2].cardTitle}</h1>
          <p className="card-paragraph">
            Capable of digitizing farm by the user. Users can also add several attributes to the
            farm (example- type, water resources). ODK tool with in-built survey form will integrate
            farm- level details in the EO4ARM platform to track the crop health status including the
            crop production (biomass). Individual users can visualize the portfolio with specific
            access management system.
          </p>
          <Col sm={6} lg={3}>
            {getToken() ? (
              <Button
                type="button"
                className="card-button"
                onClick={() => buttonClick(cardsData[2].cardTitle)}
                style={{ pointerEvents: 'auto' }}
              >
                Explore{' '}
                <span className="right-arrow">
                  <AiOutlineArrowRight />
                </span>
              </Button>
            ) : (
              <CommonToolTip moduleName={"Farm Tracker"} routeName={'farms-list'}>
                <Button
                  type="button"
                  className="card-button disabled-card-button"
                  // onClick={() => buttonClick(cardsData[2].cardTitle)}
                  // disabled={getToken() ? false : true}
                  style={{ pointerEvents: 'auto' }}
                >
                  Explore{' '}
                  <span className="right-arrow">
                    <AiOutlineArrowRight />
                  </span>
                </Button>
              </CommonToolTip>
            )}
          </Col>
        </Col>
        <Col sm={12} lg={6} className="waterlet-position-relative">
          <img className="left-sided-waterlet-position" src={lightBlueBackImage} />
          <img className="image-container image-in-medium" src={farmTracker} />
        </Col>
      </Row>
      <div className="double-background-image">
        <Row className="row-container">
          <Col sm={12} lg={6}>
            <img className="left-sided-image" src={riskAnalytics} />
          </Col>
          <Col sm={12} lg={6}>
            <h1 className="about-text">Risk Analytics</h1>
            <p className="card-paragraph">
              This space is to various indicators related to rainfall deficit, drought, water
              scarcity from IWMI and relevant institutions.It helps to disseminate drought/flood
              risk- related information to guide various users for timely early action and
              decision-making process.
            </p>
            {getToken() ? (
              <Button
                type="button"
                className="card-button"
                // onClick={() => buttonClick(cardsData[2].cardTitle)}
                onClick={() => buttonClick(cardsData[4].cardTitle)}
                style={{ pointerEvents: 'auto' }}
              >
                Explore{' '}
                <span className="right-arrow">
                  <AiOutlineArrowRight />
                </span>
              </Button>
            ) : (
              <CommonToolTip moduleName={'Risk Analytics'} routeName={'risk-analytics'}>
                <Button
                  type="button"
                  className="card-button disabled-card-button"
                  // onClick={() => buttonClick(cardsData[2].cardTitle)}
                  // onClick={() => buttonClick(cardsData[4].cardTitle)}
                  // disabled={getToken() ? false : true}
                  style={{ pointerEvents: 'auto' }}
                >
                  Explore{' '}
                  <span className="right-arrow">
                    <AiOutlineArrowRight />
                  </span>
                </Button>
                </CommonToolTip>
            )}
          </Col>
        </Row>
        <Row className="card-content">
          <Col sm={12} lg={6}>
            <h1 className="about-text">User Guide</h1>
            <p className="card-paragraph">
              User guide is intended to assist users in using the EO4ARM application, it provides a
              step-wise procedure on the various knowledge products of EO4ARM for quick access and
              information sharing. It helps to ensure the users actually learn how to get the most
              out of the Application.
            </p>
            <Button
              type="button"
              className="card-button"
              onClick={() => buttonClick(cardsData[8].cardTitle)}
              style={{ pointerEvents: 'auto' }}
            >
              Explore{' '}
              <span className="right-arrow">
                <AiOutlineArrowRight />
              </span>
            </Button>
          </Col>
          <Col sm={12} lg={6}>
            <img className="image-container image-in-medium" src={userGuide} />
          </Col>
        </Row>
      </div>

      {/* <HomeCard showMore={showMore} handleShowMore={handleShowMore} cardsData={cardsData} /> */}
      <div className="pre-footer mt-3 pre-footer-md">
        <div className="pre-footer-content">
          <p className="contact-text">{t('HOME_PAGE.KEEP_IN_TOUCH')}</p>
          <span>
            {t('HOME_PAGE.REGISTER')}
            {!getToken() ? (
              <button className="pre-footer-register-btn" onClick={() => history.push('/register')}>
                {t('HOME_PAGE.REGISTER_NOW')}
              </button>
            ) : (
              ''
            )}
          </span>
        </div>
      </div>
      {}
      <WeatherForecastModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <CropHealthModal
        modalOpen={cropHealthmodalOpen}
        setCropHealthmodalOpen={setCropHealthmodalOpen}
      />
      <Footer publicFooter={true} />
    </>
  );
};
export default HomePage;
