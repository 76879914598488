import React, { useEffect, useMemo } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import PropTypes from 'prop-types';
// import DetailsDropdown from './DetailsDropdown';
import DisabledLeftArrowIcon from '../../assets/Images/disable_previous_image.svg';
import DisabledRightArrowIcon from '../../assets/Images/disable_next_image.svg';
import EnabledLeftArrowIcon from '../../assets/Images/enable_previous_image.svg';
import EnabledRightArrowIcon from '../../assets/Images/enable_next_image.svg';

import { useTranslation } from 'react-multi-lang';
import { Row, Col, Table } from 'reactstrap';

import { useHistory } from 'react-router-dom';
import { handleLogoutRedirect } from '../../Components/common/utils';

export const ManageUserTable1 = (props) => {
  const { loading = true, activeTab, handleLatestData, handleLatestDataForDelete, selectedDate, fieldDataId,isLoading,newRequestList, userDetails  } = props;
  const t = useTranslation();

  const COLUMNS = [
    // {
    //   Header: t('MANAGE_FIELD.FARMER_NAME'),
    //   accessor: row => (row.name ? row.name : 'N/A'),
    //   sticky: 'top',
    // },
    {
      Header: t('MANAGE_FIELD.FARMER_ID'),
      accessor: (row) => (row?.farmerId ? row?.farmerId : 'N/A'),
      sticky: 'top',
    },
    // {
    //   Header: t('MANAGE_FIELD.PHONE_NO'),
    //   accessor: row => (row.phoneNo ? row.phoneNo : 'N/A'),
    //   sticky: 'top',
    // },
    {
      Header: t('MANAGE_FIELD.DISTRICT'),
      accessor: (row) => (row.district ? row.district : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('MANAGE_FIELD.DS_DIVISION'),
      accessor: (row) => (row.dsDivision ? row.dsDivision : 'N/A'),
      sticky: 'top',
    },
    {
      Header: t('MANAGE_FIELD.GN_DIVISION'),
      accessor: (row) => (row.gnDivision ? row.gnDivision : 'N/A'),
      sticky: 'top',
    },
    // {
    //     Header: t('MANAGE_FIELD.SURVEYOR_NAME'),
    //     accessor: row => (row.designation ? row.designation : 'N/A'),
    //     sticky: 'top',
    //   },
    // {
    //     Header: t('MANAGE_FIELD.STATUS_OF_INSURANCE'),
    //     accessor: row => (row.designation ? row.designation : 'N/A'),
    //     sticky: 'top',
    //   },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => props.req_data, [props.req_data]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  let history = useHistory();

  const pageSizeHandler = (e) => {
    setPageSize(Number(e.target.value));
    props.handlePageSize(Number(e.target.value));
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    setPageSize,
    prepareRow,
    rows,
  } = tableInstance;
  const { pageSize } = state;
  const page_range = [10, 15, 20, 25];

  const handlepreviousPage = (value) => {
    props.handlePageNumber(props.pageNumber - 1);
  };
  const handlenextPage = (value) => {
    props.handlePageNumber(props.pageNumber + 1);
  };
  const handleGoToPage = (e) => {
    props.handlePageNumber(Number(e.target.value));
  };

  const handleRedirectComponent = (item) => {
    let data;
    if(activeTab === 'ImpactEvaluation') {
      data = {id: fieldDataId?.[3]?.id, selectedDate  ,'newRequestList': newRequestList ,"uuid": item }
      history.push(
        {pathname: `/viewDetails4/${item}`, state: data}
      )
    } else {
      data = {id: fieldDataId?.[4]?.id, selectedDate  ,'newRequestList': newRequestList ,"uuid": item}
      history.push(
        {pathname: `/viewDetails5/${item}`, state: data}
      )
    }
  }

  useEffect(()=>{
    setPageSize(props?.initPageSize)
  },[props?.initPageSize])

  return (
    <>
      <div className="">
        <Table {...getTableProps()} className="table-list">
          <thead
            className="table-list-th tbl1"
            style={{ height: '53px', backgroundColor: '#EEF6FF', display: 'block' }}
          >
            {headerGroups.map((headerGroup) => (
              <>
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="user-list-keys"
                  style={{ marginBottom: '10px', marginTop: '10px' }}
                >
                  {headerGroup.headers.map((column) => {
                    return (
                      <>
                        <th
                          // {...column.getHeaderProps(column.getSortByToggleProps())}
                          // className="name-list"
                          // style={{ width: '11.3%' , textAlign:"center" , fontSize:"13px" , fontWeight:"bold" }}
                          // style={{
                          //   width: '19.3%',
                          //   textAlign: 'center',
                          //   fontSize: '13px',
                          //   fontWeight: 'bold',
                          //   marginTop: '5px',
                          //   marginLeft: '5px',
                          // }}
                          // className="mdtbl1"
                          className="name-list name-list1 field-list1 field-list2"
                          // field-list1"
                          style={{
                            width: '23%',
                            textAlign: 'center',
                            marginTop: '5px',
                            fontSize: '15px',
                          }}
                        >
                          {column.render('Header')}
                          {/* <span className="sort-icons">
                            {!column.notShowSortingDisplay ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <>
                                    <i class="fa fa-sort-down"></i>
                                  </>
                                ) : (
                                  <>
                                    <i class="fa fa-sort-up"></i>
                                  </>
                                )
                              ) : (
                                <i class="fa fa-sort"></i>
                              )
                            ) : (
                              ''
                            )}
                          </span> */}
                        </th>
                      </>
                    );
                  })}
                  {/* <th style={{ width: '20%' }}>&nbsp;</th> */}
                </tr>
              </>
            ))}
          </thead>
          {loading ? (
            <tbody className="table-body">
              <tr>
                <td colSpan="10000" className="text-center">
                  <img src="" alt="Loading..." />
                </td>
              </tr>
            </tbody>
          ) : (
            <>
              {page.length === 0 ? (
                <tbody>
                {(isLoading  || props?.loaderEnabled) ? (
                    ''
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: 'center', width: '100%' }}>
                        No record found.
                      </td>
                    </tr>
                  )}  
                </tbody>
              ) : (
                <tbody
                  {...getTableBodyProps()}
                  className="body table-wrapper-scroll-y my-custom-scrollbar"
                >
                  {/* {page.map((row, index) => { */}
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <>
                        <tr
                          {...row.getRowProps()}
                          className="user-list-details"
                          style={{ marginBottom: '20px' }}
                        >
                          {row.cells.map((cell) => {
                            

                            return (
                              <>
                                {/* {cell.column.Header === 'Status of Insurance' ? ( */}
                                {cell.column.Header === 'GN Division' ? (
                                  <>
                                    <td
                                      {...cell.getCellProps()}
                                      // style={{ width: '9%' , textAlign:"center" ,  }}
                                      style={{ width: '23%', textAlign: 'center' }}
                                      className="name-list-designation-details  name-list1 field-dcp1 field-dcp2"
                                      // tbl-md"
                                      // className="name-list-designation-details tbl-md mdtbl1 mdtbl1-wdth1"
                                    >
                                      <span style={{ marginLeft: '25px' }} className="mdtbl-mrgin">
                                        {' '}
                                        {cell.render('Cell')}{' '}
                                      </span>
                                    </td>

                                    <td
                                      {...cell.getCellProps()}
                                      // className="name-list-details tbl-md"
                                      // className=" tbl-md md-view1"
                                      // style={{ width: '11.3%' , textAlign:"center" ,  }}
                                      // style={{ width: '18.3%' , textAlign:"center" ,  }}
                                      style={{ width: '8%' , textAlign:"center" }}
                                      className="view-dts1 view-dts2 name-list-details"
                                    >
                                      {/* {cell.render('Cell')} */}
                                      {/* <span><a className='link-txt1 mdtbl1-vewmrgin' onClick={()=> activeTab==="Enrollment" ? history.push(`/viewDetails/${cell?.row?.original?.id}`):activeTab==="Component1"?history.push(`/FieldDataDetails1/${cell?.row?.original?.id}`):history.push(`/viewDetails3/${cell?.row?.original?.id}`)}>view details</a></span> */}

                                      <span>
                                        <a
                                          className="link-txt1 "
                                          // onClick={(e) => handleClick(cell?.row?.original?.id)}

                                          onClick={() => handleRedirectComponent(cell?.row?.original?.id, activeTab)}
                                        >
                                          View details
                                        </a>
                                      </span>
                                    </td>
                                  </>
                                ) : (
                                  <td
                                    {...cell.getCellProps()}
                                    className="field-dcp1 field-dcp2 name-list-details"
                                    style={{ width: '23%', textAlign: 'center' }}
                                    // className="name-list-details name-list-details1 tbl-md mdtbl1"
                                    // className=" tbl-md"
                                    // style={{ width: '11.3%' , textAlign:"center" ,  }}
                                    // style={{
                                    //   width: '19.3%',
                                    //   textAlign: 'center',
                                    //   paddingLeft: '0%',
                                    // }}
                                    // className="field-dcp1"
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </>
          )}
        </Table>
        <div className="pagination-section">
          <Row>
            <Col md="6" lg="6">
              <span className="item-text">{t('TABLE.ITEMS_PER_PAGE')}</span>
              <select value={pageSize} onChange={e => pageSizeHandler(e)} className="page-count">
                {page_range.map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              <span className="num-of-items">
                {(props.pageNumber - 1) * pageSize + 1}-
                {props.pageCount < props.pageNumber * pageSize
                  ? props.pageCount
                  : props.pageNumber * pageSize}{' '}
                of {props.pageCount ? props.pageCount : ''} items
              </span>
            </Col>
            <Col md="6" lg="6">
              <div className="pagination-right-col">
                <span>
                  <input
                    type="number"
                    value={props.pageNumber}
                    onChange={e => {
                      handleGoToPage(e);
                    }}
                    disabled={
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === 0
                        ? true
                        : false
                    }
                    style={{ width: '50px', border: 'none' }}
                  />
                </span>
                <span>
                  of{' '}
                  {props.pageCount % pageSize
                    ? Math.trunc(props.pageCount / pageSize + 1)
                    : Math.trunc(props.pageCount / pageSize)}{' '}
                  Pages
                </span>
                <span>
                  <button
                    onClick={() => handlepreviousPage()}
                    className="previous-btn"
                    disabled={props.pageNumber <= 1 ? true : false}
                  >
                    {props.pageNumber <= 1 ? (
                      <img src={DisabledLeftArrowIcon} alt={'not-available'} />
                    ) : (
                      <img src={EnabledLeftArrowIcon} alt={'not-available'} />
                    )}
                  </button>
                  <button
                    onClick={() => handlenextPage()}
                    className="next-btn"
                    disabled={
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === 0
                        ? true
                        : false
                    }
                  >
                    {(props.pageCount % pageSize
                      ? Math.trunc(props.pageCount / pageSize + 1)
                      : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                    (props.pageCount % pageSize
                      ? Math.trunc(props.pageCount / pageSize + 1)
                      : Math.trunc(props.pageCount / pageSize)) === 0 ? (
                      <img src={DisabledRightArrowIcon} alt={'not-available'} />
                    ) : (
                      <img src={EnabledRightArrowIcon} alt={'not-available'} />
                    )}
                  </button>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
ManageUserTable1.propTypes = {
  req_data: PropTypes.array,

  selectedTab: PropTypes.string,

  handlePageSize: PropTypes.func,
  pageCount: PropTypes.number,
  handlePageNumber: PropTypes.func,
  pageNumber: PropTypes.number,
  recordsCount: PropTypes.number,
  activeTab: PropTypes.string,
};
