import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-multi-lang';
import Button from 'reactstrap-button-loader';
import WeatherMonitoringTransparentIcon from '../../assets/Images/icons/water-monitoring-transparent-icon.svg';
import WeatherForecastYellowIcon from '../../assets/Images/icons/agriculture-icon-yellow.svg';
import WeatherForecastTransparentIcon from '../../assets/Images/icons/agriculture-transparent-icon.svg';
import WeatherMonitoringYellowIcon from '../../assets/Images/icons/water-icon-yellow.svg';
import ContinueIcon from '../../assets/Images/icons/continue-icon.svg';

const CropHealthModal = (props) => {
  const { modalOpen, setCropHealthmodalOpen } = props;
  const [selectedTab, setSelectedTab] = useState();
  const t = useTranslation();
  const history = useHistory();
  const [modal, setModal] = useState();
  useEffect(() => {
    setModal(modalOpen);
    // setSelectedTab('agriculture-monitor');
  }, [modalOpen]);

  const viewtoggle = (value) => {
    setModal(false);
  };

  const handleClose = () => {
    setModal(false);
    setCropHealthmodalOpen(false);
  };

  const handleTab = (value, click) => {
    if (value === 'agriculture-monitor') {
      setSelectedTab('agriculture-monitor');
        history.push(`crop-health-monitoring?key=agriculture`);

    } else if (value === 'water-monitor') {
      setSelectedTab('water-monitor');
        history.push(`/crop-health-water?key=water`);

    }
    // if (click === 'doubleClicked') {
    //   if (value === 'agriculture-monitor') {
    //     history.push(`crop-health-monitoring?key=agriculture`);
    //   } else if (value === 'water-monitor') {
    //     history.push(`/crop-health-water?key=water`);
    //   }
    // }
  };

  const handleContinue = () => {
    if (selectedTab === 'agriculture-monitor') {
      history.push(`/crop-health-monitoring?key=agriculture`);
    } else if (selectedTab === 'water-monitor') {
      history.push(`/crop-health-water?key=water`);
    }
  };

  return (
    <>
      <div className="client-modal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className="weather-forecast-modal modal-lg"
          backdrop="static"
        >
          <ModalHeader className="weather-forecast-modal-title" toggle={handleClose}>
            {/* {t('WEATHER_FORECAST_MODAL.WEATHER_FORECAST_MODAL_TITLE')} */}
            Crop Health Monitor
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={6} md={6} lg={6}>
                {/* {selectedTab === 'agriculture-monitor' ? (
                  <img
                    alt="agriculture-monitor"
                    src={WeatherForecastYellowIcon}
                    onClick={() => handleTab('agriculture-monitor')}
                    onDoubleClick={() => handleTab('agriculture-monitor', 'doubleClicked')}
                    className="cursor-pointer right-align"
                  />
                ) : (
                  <img
                    alt="agriculture-monitor"
                    src={WeatherForecastTransparentIcon}
                    onClick={() => handleTab('agriculture-monitor')}
                    onDoubleClick={() => handleTab('agriculture-monitor', 'doubleClicked')}
                    className="cursor-pointer right-align"
                  />
                )} */}
                  <img
                    alt="agriculture-monitor"
                    src={WeatherForecastTransparentIcon}
                    onClick={() => handleTab('agriculture-monitor')}
                    // onDoubleClick={() => handleTab('agriculture-monitor', 'doubleClicked')}
                    className="cursor-pointer right-align"
                    onMouseOver={e => (e.currentTarget.src = WeatherForecastYellowIcon)} 
                    onMouseOut={e => (e.currentTarget.src = WeatherForecastTransparentIcon)}
                  />
              </Col>
              <Col sm={6} md={6} lg={6}>
                {/* {selectedTab === 'water-monitor' ? (
                  <img
                    alt="water-monitor"
                    src={WeatherMonitoringYellowIcon}
                    onClick={() => handleTab('water-monitor')}
                    onDoubleClick={() => handleTab('water-monitor', 'doubleClicked')}
                    className="cursor-pointer"
                  />
                ) : (
                  <img
                    alt="water-monitor"
                    src={WeatherMonitoringTransparentIcon}
                    onClick={() => handleTab('water-monitor')}
                    onDoubleClick={() => handleTab('water-monitor', 'doubleClicked')}
                    className="cursor-pointer"
                  />
                )} */}
                  <img
                    alt="agriculture-monitor"
                    src={WeatherMonitoringTransparentIcon} 
                    onClick={() => handleTab('water-monitor')}
                    // onDoubleClick={() => handleTab('agriculture-monitor', 'doubleClicked')}
                    className="cursor-pointer"
                    onMouseOver={e => (e.currentTarget.src = WeatherMonitoringYellowIcon)} 
                    onMouseOut={e => (e.currentTarget.src = WeatherMonitoringTransparentIcon)}
                  />
              </Col>
            </Row>
            <hr />
            <Row>
              <p className="weather-forecast-modal-desc">
                The tool allows the users to monitor past and current conditions of crop and
                available water resources which helps to determine the drought/flood conditions to
                promote proactive disaster management measures for the insurance.It utilizes modern
                and latest information to monitor the condition of crops. It enables the farmer/user
                to implement timely interventions that ensure optimal yields at the end of the
                season.
              </p>
            </Row>
          </ModalBody>
          {/* <ModalFooter className="modal-viewdeatil-footer">
            <Row>
              <span>
                <Button
                  className="weather-forecast-continue-button"
                  onClick={() => handleContinue()}
                >
                  Continue
                  <img src={ContinueIcon} alt="continue-icon" />
                </Button>
              </span>
            </Row>
          </ModalFooter> */}
        </Modal>
      </div>
    </>
  );
};
export default CropHealthModal;
