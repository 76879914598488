import axios from 'axios';
import Cookies from 'js-cookie';
import { REACT_APP_API_URL } from '../../Components/constants';

require('dotenv').config();

export const AXIOS_INSTANCE = axios.create({
  headers: {
    Accept: 'application/json',
  },
});

AXIOS_INSTANCE.interceptors.request.use(function(config) {
  if (Cookies.get('user')) {
    const token = `Bearer  ${Cookies.get('user')}`;
    config.headers.Authorization = token;
    // config.headers.ContentType = 'multipart/form-data'
  }
  return config;
});

//API URL
//export const API_BASE_URL = `${process.env.REACT_APP_API_URL}`;
export const API_BASE_URL = REACT_APP_API_URL;

export const MAP_ACCESS_TOKEN =
  'pk.eyJ1IjoiYW5qYWxpLWlubm9taWNrIiwiYSI6ImNsZDl0YXAxaTAxZzkzcG56cDhzbHdkZW0ifQ.FIzaEFAC7FTUDmXsPR5gKQ'; //from Anjali account(username: anjali-innomick, password: Innomick@123)

export const OPEN_WEATHER_API_KEY = '42170930ba0a32525707af376632a32f';
export const LOCATION_URL = `${API_BASE_URL}/location/get-location`;
export const LOCATION_CONVENTION = `${API_BASE_URL}/location/location-convention`;
export const PERMISSIONS_URL = `${API_BASE_URL}/permission`;
export const WEATHER_FORECAST_URL = `${API_BASE_URL}/weather-forecast`;
export const CROP_MONITOR_URL = `${API_BASE_URL}/crop-monitor`;
export const WEATHER_FORECAST_DATA_URL = `${API_BASE_URL}/weather-forecast/getData`;
export const STATIC_RESOURCE_URL = `${API_BASE_URL}/weather-forecast/content`;

export const WEATHER_MONITOR_URL = `${API_BASE_URL}/weather-monitor`;
export const ACCESS_KEY_URL = `${API_BASE_URL}/accesskey`;
export const PUBLIC_API_URL = `${API_BASE_URL}/public-api`;
export const FARM_TRACKER_URL = `${API_BASE_URL}/farm-tracker`;
export const HOME_URL = `${API_BASE_URL}/home`;
export const USER_GUIDE_URL = `${API_BASE_URL}/user-guide`;

export const USER_URL = `${API_BASE_URL}/user`;
// export const BASE_LAYER = `${API_BASE_URL}/weather/get-boundaries`;
export const BASE_LAYER = `${API_BASE_URL}/map-preference/list`;
export const NOAA_TIMESERIES = `${API_BASE_URL}/weather-forecast/noaa/timeseries`;
export const ERPAS_TIMESERIES = `${API_BASE_URL}/weather-forecast/erpas/timeseries`;
export const WATER_RESOURCE_URL = `${API_BASE_URL}/water-resource`;
export const IRI_TIMESERIES = `${API_BASE_URL}/weather-forecast/iri/timeseries`;
export const WEATHER_MAP_PREFERENCE = `${API_BASE_URL}/map-preference/getData`;
export const RISK_SCORE_TIMESERIES = `${API_BASE_URL}/risk-analytics/risk-score/timeseries`;
export const RISK_ANALYTICS = `${API_BASE_URL}/risk-analytics`;
export const RISK_ANALYTICS_PERENNIAL_URL = `${API_BASE_URL}/risk-analytics/cwp/perennial/data`
export const RISK_ANALYTICS_SEASONAL_URL = `${API_BASE_URL}/risk-analytics/cwp/data`
export const ODK_URL = `${API_BASE_URL}/odk`
