import React, { useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  useMap,
  useMapEvents,
  ZoomControl,
  GeoJSON,
  LayerGroup,
  LayersControl,
} from 'react-leaflet';
import { useHistory, useLocation } from 'react-router-dom';
import L, { polygon } from 'leaflet';
import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.css';
import _ from 'lodash';
import 'leaflet-side-by-side';
import { Button, Col, Row } from 'reactstrap';
import 'leaflet/dist/leaflet.css';
import 'leaflet.control.opacity/dist/L.Control.Opacity.css';
import 'leaflet.control.opacity';
import 'react-splitter-layout/lib/index.css';
import '../../assets/CSS/LeafletWrapper.css';
import '../../assets/CSS/Header.css';
import '../../assets/CSS/RiskAnalytics.css';
import myConstClass from '../../Constants';
import mapPreferenceIcon from '../../assets/Images/mapPreferenceIcon.svg';

import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import ReactLeafletKml from 'react-leaflet-kml';
import 'leaflet-kml';

import CustomLegend from '../WeatherForecast/CustomLayerControl/CustomLegend';
import DiscreteLegend from '../WeatherForecast/CustomLayerControl/DiscreteLegend';
import {
  getWeatherForecastData,
  getDroughtMapSuccess,
  getMapPreferenceSuccess,
  getMapPreferenceBoundariesSuccess,
  addProjectSuccess,
  addFarmTrackerRequest,
  getAllByCriteriaFarmListRequest,
  addFarmTrackerSuccess,
  getFarmDetailsByIdRequest,
  editFarmTrackerRequest,
  deleteFarmTrackerRequest,
  sendEmailSuccess,
  getAllByCriteriaProjectListRequest,
} from '../../redux/actions';
import DrawShape from './draw-shape';
import Filter from './Filter';

import Loader from '../../Components/common/loader';
import MapPreferences from './MapPreferences';
import {
  DEFAULT_COUNTRY,
  FARM_TRACKER_OPTIONS,
  MAP_PREFERENCES_lABELS,
  GEOJSON_COUNTRY_TOOLTIP_DATA,
  RISK_ANALYTICS_GRAPH_INDICATORS_CATEGORY,
  DATASOURCE_PARAMETER,
} from '../../Components/constants';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
import { showError, showSuccess } from '../../Components/Notifications';
import axios from 'axios';
import { API_BASE_URL, AXIOS_INSTANCE, FARM_TRACKER_URL } from '../../redux/api/apiEndPoint';
import Modal from 'react-bootstrap/Modal';
import { getNoaaTimeSeries, getRiskScoreTimeSeries } from '../../redux/actions';
import moment from 'moment';
import {
  DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
  TEMPORAL_AGGREGATION,
} from '../../Components/constants/index';
import { getWeatherForecastSources } from '../../redux/weather/weatherActions';
import SplitterLayout from 'react-splitter-layout';
import Analytics from './riskAnalytics';
import CurrentWatherForecast from './currentWeatherForecast';
import CropProductionModal from './CropProduction';
import ShareInformationModal from './shareInformationModal';
import CarousalModal from './carousalModal';
import { change } from 'redux-form';
import carousalModal from './carousalModal';
import RiskAnalyticsDetailsTab from './FarmCollapse/farm-risk-analytics-tabs';
import { getToken } from '../../Components/common/utils';

const FarmTrackerMap = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const splitterRef = useRef(null);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { Overlay } = LayersControl;
  const drawFarmRef = useRef('');
  const compareDrawFarmRef = useRef('');
  const [isOpenMapModel, setIsOpenMapModel] = useState(false);
  const [map, setMap] = useState(false);
  const [isHideFarmCollapse, setIsHideFarmCollapse] = useState(true);
  const [showFarmInputFields, setShowFarmInputFields] = useState(false);
  const [isCreateFarm, setIsCreateFarm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [region, setRegion] = useState('');
  const [mapPreferRefs, setMapPreferRefs] = useState([]);
  const [isHideBar, setBar] = useState(false);
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [geoJsonAllData, setGeoJsonAllData] = useState([]);
  const [geoJsonLayersData, setGeoJsonLayerData] = useState([]);
  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [activeTab, setActiveTab] = useState('LOCATION');
  const [graphScreenshotImage, setGraphScreenshotImage] = useState('');
  const [isOpenFarmListingCollapse, setIsOpenFarmListingCollapse] = useState(false);
  const [isOpenFarmCompareCollapse, setIsOpenFarmCompareCollapse] = useState(false);
  const [isOpenLocationTab, setIsOpenLocationTab] = useState(true);
  const [selectedMapPreferLegendData, setSelectedMapPreferLegendData] = useState([]);
  const mapRef = useRef(null);
  const [mapLayerOpacity, setMapLayerOpacity] = useState({ layer: '' });
  const [uploadedFiles, setUploadedFiles] = useState('');
  const [mapPreferenceGeoJsonloading, setMapPreferenceGeoJsonloading] = useState(false);
  const [selectedRegionId, setSelectedRegionId] = useState('');
  const [isUploadFarm, setIsUploadFarm] = useState(false);
  const [createFarmData, setCreateFarmData] = useState('');
  const [opacityControl, setOpacityControl] = useState('');
  const [LocationList, setLocationList] = useState('');
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isOpenCropProductionModal, setIsOpenCropProductionModal] = useState(false);
  const [mapPreferences, setMapPreferences] = useState({
    country: { layer: useRef(), color: '', data: '' },
    states_or_provinces: { layer: useRef(), color: '', data: '' },
    districts_or_cities: { layer: useRef(), color: '', data: '' },
    meteorological_locations: { layer: useRef(), color: '', data: '' },
    basins: { layer: useRef(), color: '', data: '' },
    rivers_or_reservoirs: { layer: useRef(), color: '', data: '' },
    talukas: { layer: useRef(), color: '', data: '' },
  });
  const [isPolygonClicked, setIsPolygonClicked] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState({});
  const [layersData, setLayersData] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState('');
  const [compareFarm, setCompareFarm] = useState('');
  const [layerControl, setLayerControl] = useState({
    firstLayer: {},
    secondLayer: {},
  });
  const [mapControl, setMapControl] = useState({});
  const [showRiskScoreError, setShowRiskScoreError] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    country: '',
    state: '',
    district: '',
    basin: '',
    region: '',
    markerPointData: '',
  });

  const [styledPolygonOptions, setStyledPolygonOptions] = useState({ options: {} });
  const [compareStyledPolygonOptions, setCompareStyledPolygonOptions] = useState({ options: {} });
  const [farmsList, setFarmsList] = useState([]);
  const [compareFarmsList, setCompareFarmsList] = useState([]);
  const [selectedFarmsList, setSelectedFarmsList] = useState([]);

  const [cordinateAndZoom, setCordinateAndZoom] = useState({
    cordinates: [7.6237, 80.6966],
    locationCordinates: [7.6237, 80.6966],
    markerCordinates: [7.6237, 80.6966],
    zoom: 8,
  });
  const [projectDetails, setProjectDetails] = useState({
    id: '',
    title: '',
    description: '',
    farmsList: [],
  });
  let form = new FormData();

  const [farmOptions, setFarmOptions] = useState(FARM_TRACKER_OPTIONS);
  const [farmAttributes, setFarmAttributes] = useState([]);
  const [attributeLimit, setAttributeLimit] = useState(5);
  const [isOpenDeleteFarmModal, setIsOpenDeleteFarmModal] = useState(false);
  const [farmTitle, setFarmTitle] = useState('');
  const [farmDescription, setFarmDescription] = useState('');
  const [isRemovePolygon, setIsRemovePolygon] = useState(false);
  const [changePolygonOptions, setChangePolygonOptions] = useState(FARM_TRACKER_OPTIONS);
  const [isShowListing, setIsShowListing] = useState(false);
  const [fromListing, setFromListing] = useState(false);
  const [measureData, setMeasureData] = useState('');
  const [resultantMeasureData, setResultantMeasureData] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [profileIcon, setProfileIcon] = useState('');
  const [isApplyLayer, setIsApplyLayer] = useState(false);
  const [defaultPolygonProps, setDefaultPolygonProps] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);

  // const [kml, setkml] = useState('');
  const [kml, setkml] = useState({ document: '', layer: {} });
  const [compareKml, setCompareKml] = useState({ document: '', layer: {} });

  // listing farms states
  const [farmListingContainer, setFarmListingContainer] = useState(false);
  const [projectIndividualData, setProjectIndividualData] = useState({});
  const [farmListState, setFarmListState] = useState(false);
  const { boundries, FarmTracker, Weather, RiskAnalytics, Location } = useSelector((state) => ({
    Weather: state.Weather,
    DroughtMonitor: state.DroughtMonitor,
    boundries: state.Map_Preference,
    FarmTracker: state.FarmTracker,
    RiskAnalytics: state.RiskAnalytics,
    Location: state.Location,
  }));

  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [isRightCollapseOpen, setIsRightCollapseOpen] = useState(false);
  const [getByIdFarmInfo, setGetByIdFarmInfo] = useState({});
  const [getByIdCompareFarmInfo, setGetByIdCompareFarmInfo] = useState({});
  const [polygonRegionValues, setPolygonRegionValues] = useState([]);
  const [comparePolygonRegionValues, setComparePolygonRegionValues] = useState([]);
  const [categoryParams, setCategoryParams] = useState([]);
  const [forecastRightSideBar, setForecastRightSideBar] = useState([]);
  const [compareForecastRightSideBar, setCompareForecastRightSideBar] = useState([]);
  const [riskScoreInfo, setRiskScoreInfo] = useState([]);
  const [compareriskScoreInfo, setCompareRiskScoreInfo] = useState([]);
  const [farmOnClickState, setFarmOnClickState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [state, setState] = useState({
    min: 0,
    max: 0,
  });
  const [analyzeRiskmodalShow, setAnalyzeRiskModalShow] = React.useState(false);
  const [temporalAggregation, setTemporalAggregation] = useState(TEMPORAL_AGGREGATION);
  const [updatedSelectedDate, setUpdatedSelectedDate] = useState({
    startDate: moment(new Date(2022, 0, 1)).format(DATE_FORMAT),
    endDate: moment().add(6, 'days').format(DATE_FORMAT),
    minStartDate: '',
    maxStartDate: '',
    maxEndDate: '',
  });
  const [indicatorsvalue, setIndicatorsValue] = useState('');
  const [newRiskGraphData, setNewRiskGrapgData] = useState('');
  const [newDataRquest, setNewDataRequest] = useState(false);
  const [showCharts, setShowCharts] = useState(true);
  const [kmlCenter, setKmlCenter] = useState([]);
  const [compareKmlCenter, setCompareKmlCenter] = useState([]);
  const [indicatorsCategoryList, setIndicatorsCategoryList] = useState(
    RISK_ANALYTICS_GRAPH_INDICATORS_CATEGORY,
  );

  const [updatedGraphValues, setUpdateGraphValues] = useState({});
  const [selectedIndicatorParam, setSelectedIndicatorParam] = useState([]);
  const [selectedIndicatorRiskParam, setSelectedIndicatorRiskParam] = useState([]);
  const [spiSelectedDate, setSpiSelectedDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedFrequency, setSelectedFrequency] = useState('');

  const [errorData, setErrorData] = useState({ showError: false, data: '' });
  const [showLoadingText, setShowLoadingText] = useState(false);
  const [rightBarActiveTab, setRightBarActiveTab] = useState(t('RISK_ANALYTICS.FARM_1'));

  const [initialSelectedDate, setSelectedDate] = useState({
    graph: {
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().add(6, 'days').format(DATE_FORMAT),
      minStartDate: '',
      minEndDate: '',
      maxEndDate: moment().format(DATE_FORMAT),
      maxStartDate: moment().format(DATE_FORMAT),
    },
    map: {
      startDate: moment().subtract(30, 'days').format(DATE_FORMAT),
      endDate: moment(new Date()).format(DATE_FORMAT),
      minStartDate: moment('2015-07-01T00:00:00Z').format(DATE_FORMAT),
      minEndDate: '',
      maxEndDate: moment().format(DATE_FORMAT),
      maxStartDate: moment().format(DATE_FORMAT),
    },
  });

  const [chartSeriesData, setChartSeriesData] = useState([]);
  const [compareChartSeriesData, setCompareChartSeriesData] = useState([]);
  const [dailyChartSeriesData, setDailyChartSeriesData] = useState([]);
  const [compareDailyChartSeriesData, setCompareDailyChartSeriesData] = useState([]);
  const [graphData, setGraphData] = useState({});
  const [carousalModalOpen, setCarousalModalOpen] = useState(false);
  const [selectedGeotaggedImage,setSelectedGeotaggedImage] = useState(0)
  const [selectedFarmIndex, setSelectedFarmIndex] = useState('');
  const [farmId, setFarmId] = useState('');

  const [geoTaggedImages, setGeoTaggedImages] = useState([]);
  const [riskPercentage, setRiskPercentage] = useState(0);
  const [riskLevel, setRiskLevel] = useState('');
  const [compareRiskPercentage, setCompareRiskPercentage] = useState(0);
  const [compareRiskLevel, setCompareRiskLevel] = useState('');
  const [filterProject,setFilterProject] = useState('');
  const [filterFarm,setFilterFarm] = useState('')

  //useeffect for getting map layers data from redux
  useEffect(() => {
    if (getToken()) {
      let search = window.location.search;
      let URLParams = new URLSearchParams(search);
      let projectId = URLParams.get('project_id');
      let farmId = URLParams.get('farm_id');
      setFilterProject(projectId);
      setFilterFarm(farmId);
      dispatch(getDroughtMapSuccess(''));
      dispatch(getMapPreferenceSuccess(''));
      dispatch(addProjectSuccess({}));
      dispatch(addFarmTrackerSuccess({}));
      dispatch(getMapPreferenceBoundariesSuccess({}));
      dispatch(getWeatherForecastSources({ country_id: DEFAULT_COUNTRY.id }));
      setMapPreferRefs([]);
      setMapPreferencesData([]);
      return () => {
        dispatch(getMapPreferenceBoundariesSuccess(''));
        dispatch(sendEmailSuccess({}));
      };
    } else {
      history.push('/home');
    }
  }, []);

  useEffect(() => {
    let getSelectedIndicators = [];
    if (indicatorsCategoryList?.length) {
      let y = indicatorsCategoryList?.map((i) => {
        if (i?.selectedIndicators?.length) {
          let selectedIndicator = i?.selectedIndicators?.map((item) => {
            item.visible = 'legendonly';
            item.type = 'scatter';
            item.mode = 'lines+markers';
            getSelectedIndicators.push(item);
          });
        }
      });
    }
    setSelectedIndicatorParam(getSelectedIndicators);
  }, [indicatorsCategoryList]);

  useEffect(() => {
    if (Weather?.weatherForecastSourcesData) {
      const { result } = Weather.weatherForecastSourcesData.data;
      let options = [];
      if (result) {
        result?.map((item) => {
          item.data.map((data_source) => {
            const {
              weather_forecast_source: {
                id,
                name,
                type,
                weather_forecast_source_with_parameter: parameter,
              },
            } = data_source;
            return options.push({
              id,
              name,
              value: name,
              label: name,
              type,
              parameterData: parameter,
              category: item.category,
            });
          });
        });
        let noaaList = options?.filter((item) => item?.name === 'GFS - NOAA');
        if (!_.isEmpty(noaaList)) {
          let weather_forecast_noaa_list =
            noaaList[0]?.parameterData[0]?.weather_forecast_parameter;
          setCategoryParams(weather_forecast_noaa_list);
        }
      }
    }
  }, [Weather.weatherForecastSourcesData]);

  useEffect(() => {
    if (Weather.weatherForecastData) {
      if (Weather.weatherForecastData.data) {
        setIsLoading(false);
        const { result } = Weather.weatherForecastData.data;
        const isCompare = Weather.weatherForecastData.isCompare;
        // if (!isOpenWeather) {
        //   setForecastData(result);
        // }
        // setCompareParamLegendData([]);
        if(result?.length){
          result?.map((item) => {
            if (item.Open_Weather) {
              const OpenWeather = item.Open_Weather;
              if (Array.isArray(OpenWeather)) {
                if (OpenWeather.some((x) => x.cod === '400')) {
                  const errorCode = OpenWeather.find((x) => x.cod === '400');
                  // setIsGraphLoading(false);
                  //showError(`No record found.${errorCode.message}`);
                  setErrorData({ showError: true, data: errorCode.message });
                  setGraphData({
                    ...graphData,
                    OPEN_WEATHER_API: [],
                  });
                  if (isCompare) {
                    setDailyChartSeriesData([]);
                    setCompareChartSeriesData([]);
                  } else {
                    setChartSeriesData([]);
                    setCompareChartSeriesData([]);
                  }
                  return false;
                }
              } else {
                setErrorData({ showError: false, data: '' });
                // setIsGraphLoading(false);
                setGraphData({
                  ...graphData,
                  OPEN_WEATHER_API: OpenWeather,
                });
                // setChartSeriesData(item.Open_Weather?.daily);
                if (isCompare) {
                  setCompareChartSeriesData(item.Open_Weather?.hourly);
                  setCompareDailyChartSeriesData(item.Open_Weather?.daily);
                } else {
                  setDailyChartSeriesData(item.Open_Weather?.daily);
                  setChartSeriesData(item.Open_Weather?.hourly);
                }
              }
            }
          });
        }
        
      }
    } else if (Weather.weatherForecastDataError) {
      if (Weather.weatherForecastDataError) {
        let {
          data: { response },
        } = Weather.weatherForecastDataError;
        if (!_.isEmpty(response?.data?.errors)) {
          showError(Object.values(response?.data?.errors)[0]);
        } else {
          showError(response?.data?.message);
        }
      } else {
        showError('No records found. please try again');
      }
      setIsLoading(false);
    } else if (Weather.weatherForecastDataNetworkError) {
      setIsLoading(false);
    }
  }, [
    Weather.weatherForecastData,
    Weather.isWeatherForecastRequesting,
    Weather.weatherForecastDataError,
    Weather.weatherForecastDataNetworkError,
  ]);
  useEffect(() => {
    if (!_.isEmpty(projectIndividualData)) {
      let {
        projectData: {
          province,
          district,
          // sub_district,
          // block,
          title,
          description,
          farmsList,
          project_id,
        },
        provinceList,
        districtList,
        subDistrictList,
        blockList,
        cordinateAndZoom: farmCordinates,
      } = projectIndividualData;
      let obj = {
        province,
        district,
        // sub_district,
        // block,
      };
      if (farmsList?.length) {
        setIsShowListing(true);
      }
      if (project_id) {
        if(filterFarm){
          dispatch(getAllByCriteriaFarmListRequest({ farm_tracker_project_id: project_id, farm_tracker_id: filterFarm}))
        }
        else dispatch(getAllByCriteriaFarmListRequest({ farm_tracker_project_id: project_id }));
      }
      setProjectDetails((prev) => {
        return {
          ...prev,
          id: project_id,
          title: title,
          description: description,
          province,
          district,
          // sub_district,
          // block,
          farmsList: farmsList,
        };
      });
      setFarmsList(farmsList);
      setCompareFarmsList(farmsList);
      setCordinateAndZoom((prev) => {
        return {
          ...prev,
          locationCordinates: farmCordinates?.locationCordinates,
          cordinates: farmCordinates?.locationCordinates,
          zoom: farmCordinates?.zoom || cordinateAndZoom?.zoom,
        };
      });
      setCreateFarmData((prev) => {
        return {
          ...prev,
          ...obj,
        };
      });
      setLocationList({
        provinceList,
        districtList,
        subDistrictList,
        blockList,
      });
    }
  }, [projectIndividualData]);

  useEffect(() => {
    if (LocationList) {
      if (LocationList.provinceList) setProvinceList(LocationList.provinceList);
      if (LocationList.districtList) setDistrictList(LocationList.districtList);
      if (LocationList.subDistrictList) setSubDistrictList(LocationList.subDistrictList);
      if (LocationList.blockList) setBlockList(LocationList.blockList);
    }
  }, [LocationList]);
  useEffect(() => {
    if (Location.countryList) {
      const { result } = Location.countryList;
      if (result?.[0]) {
        let arr = [];
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            region_id: i.region_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
        const defaultCountry = arr?.find((item) => item.id === DEFAULT_COUNTRY.id);
        if (defaultCountry) setDefaultCountry(defaultCountry);
        setCountryList(arr);
      }
    }
  }, [Location.countryList]);

  useEffect(() => {
    if (Location.stateList) {
      let arr = [];
      let result = '';
      let config = Location?.stateList?.config;
      if (Location?.stateList?.data) result = Location?.stateList?.data?.result;
      if (result) {
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            country_id: i.country_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        setProvinceList(arr);
      }
    }
  }, [Location.stateList]);

  useEffect(() => {
    let arr = [];
    if (Location.districtList) {
      let result = '';
      let config = Location?.districtList?.config;
      if (Location?.districtList?.data) result = Location?.districtList?.data?.result;
      if (result) {
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        //dispatch(change('FilterFarmTracker', 'district', ''));   // while opening crop prod filter district field emptying.
        dispatch(change('FilterFarmTracker', 'sub_district', ''));
        dispatch(change('FilterFarmTracker', 'block', ''));
        setDistrictList(arr);
      }
    }
  }, [Location.districtList]);

  useEffect(() => {
    let arr = [];
    if (Location.subDistrictList) {
      let result = '';
      let config = Location?.subDistrictList?.config;
      if (Location?.subDistrictList?.data) result = Location?.subDistrictList?.data?.result;
      if (result) {
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        dispatch(change('FilterFarmTracker', 'sub_district', ''));
        dispatch(change('FilterFarmTracker', 'block', ''));
        setSubDistrictList(arr);
      }
    }
  }, [Location.subDistrictList]);

  useEffect(() => {
    let arr = [];
    if (Location.blockList) {
      let result = '';
      let config = Location?.blockList?.config;
      if (Location?.blockList?.data) result = Location?.blockList?.data?.result;
      if (result) {
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        dispatch(change('FilterFarmTracker', 'block', ''));
        setBlockList(arr);
      }
    }
  }, [Location.blockList]);
  //initially selecting first farm
  useEffect(() => {
    if (farmId !== '') {
      // setFromListing(true);
      setSelectedFarmIndex(0);
      dispatch(getFarmDetailsByIdRequest({ id: farmId }));
    }
  }, [farmId, dispatch]);

  useEffect(() => {
    if (FarmTracker.getAllByCriteriaRequesting) {
      setIsLoading(true);
    } else if (FarmTracker?.getAllByCriteriaData) {
      setIsLoading(false);
      if (FarmTracker?.getAllByCriteriaData?.data) {
        let response = FarmTracker?.getAllByCriteriaData?.data;
        if (response?.result) {
          if (response?.result?.results?.length && response?.result?.previous === null) {
            setFarmId(response?.result?.results[0]?.id);
            setSelectedFarm(response?.result?.results[0]);
          }
          // setIsRightCollapseOpen(false);
          setFarmListState(true);
          setProjectDetails((prev) => {
            if (prev.farmsList?.length) {
              return {
                ...prev,
                farmsList: [...prev.farmsList, ...response?.result?.results],
              };
            } else {
              return {
                ...prev,
                farmsList: response?.result?.results,
              };
            }
          });
          // setFarmsList(response?.result?.results);
          setFarmsList((prev) => {
            if (prev.farmsList?.length) {
              return {
                ...prev,
                farmsList: [...prev.farmsList, ...response?.result?.results],
              };
            } else {
              return {
                ...prev,
                farmsList: response?.result?.results,
              };
            }
          });
          // setCompareFarmsList(response?.result?.results);
          setCompareFarmsList((prev) => {
            if (prev.farmsList) {
              return {
                ...prev,
                farmsList: [...prev.farmsList, ...response?.result?.results],
              };
            } else {
              return {
                ...prev,
                farmsList: response?.result?.results,
              };
            }
          });
        }
      }
    } else if (FarmTracker?.getAllByCriteriaDataError) {
      if (FarmTracker?.getAllByCriteriaDataError?.data) {
        let errorData = FarmTracker?.getAllByCriteriaDataError?.data;
        let data = errorData?.response?.data;
        if (data?.message && data?.code === 400) showError(data?.message);
        setFarmListState(false);
        setIsLoading(false);
        setProjectDetails((prev) => {
          return {
            ...prev,
            farmsList: [],
          };
        });
        setFarmsList([]);
        setCompareFarmsList([]);
      }
    }
  }, [FarmTracker.getAllByCriteriaData, FarmTracker.getAllByCriteriaDataError]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map((item) => {
        let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          let data = JSON.parse(JSON.stringify(item.data));
          selectedLayer.country = DEFAULT_COUNTRY;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(data);
          selectedLayer.color = item.config.color;
          setMapPreferences((prev) => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (FarmTracker?.getFarmDetailsByIdData) {
      if (FarmTracker?.getFarmDetailsByIdData?.data) {
        setIsLoading(false);
        let data = FarmTracker?.getFarmDetailsByIdData?.data;
        setLayersData([FarmTracker?.getFarmDetailsByIdData]);
        if (data?.code === 200) {
          let {
            result: { farm, farm_attributes },
          } = data;
          let obj;
          setGeoTaggedImages({});
          setGetByIdFarmInfo(data?.result);
          setGetByIdCompareFarmInfo({});
          setCompareRiskScoreInfo([]);
          if (farm?.length) {
            obj = {
              sub_district: {
                id: farm[0]?.sub_district_id,
                label: farm[0]?.sub_district_name,
                value: farm[0]?.sub_district_name,
                name: farm[0]?.sub_district_name,
              },
              block: {
                id: farm[0]?.block_id,
                label: farm[0]?.block_name,
                value: farm[0]?.block_name,
                name: farm[0]?.block_name,
              },
            };
            setIsRightCollapseOpen(true);
          } else {
            setIsRightCollapseOpen(false);
          }
          if (!farm?.[0]?.is_upload) {
            if (drawFarmRef && drawFarmRef?.current) {
              let data = !_.isEmpty(farm?.[0]?.draw_farm) ? farm?.[0]?.draw_farm : '';
              setPolygonRegionValues(data?.features?.[0]?.geometry?.coordinates?.[0]);
              if (data) {
                drawFarmRef?.current?.addData(data);
              }
            }
            if (!_.isEmpty(farm?.[0]?.polygon_style)) {
              let stringifiedData = JSON.stringify(farm?.[0]?.polygon_style);
              let styleObj = JSON.parse(stringifiedData);
              setStyledPolygonOptions({ options: styleObj });
            } else {
              setStyledPolygonOptions({ options: {} });
            }
            //for setting co-ordinates of farm
            if (farm?.length) {
              const longitude =
                farm?.[0]?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[0];
              const latitude =
                farm?.[0]?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[1];
              if (
                farm?.[0]?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.length !== 0
              ) {
                // setTimeout(() => {
                //   handleCordinates(
                //     [
                //       latitude ? latitude : cordinateAndZoom?.locationCordinates[0],
                //       longitude ? longitude : cordinateAndZoom?.locationCordinates[1],
                //     ],
                //     18,
                //   );
                // }, [500]);
                const selectedGeojson = L.geoJSON(farm?.[0]?.draw_farm)
                if(selectedGeojson && !_.isEmpty(map))
                map?.fitBounds(selectedGeojson?.getBounds(), { padding: [150,150]})
              }
            }
            //for setting block and sub_district
            setCreateFarmData((prev) => {
              return {
                ...prev,
                ...obj,
              };
            });
          } else {
            let Kml_Api_url = farm?.[0]?.upload_file;
            axios
              .get(Kml_Api_url)
              .then((res) => {
                if (res && res?.data) {
                  let parser = new DOMParser();
                  let xmlDoc = parser.parseFromString(res?.data, 'text/xml');
                  const kmlData = new L.KML(xmlDoc);
                  if (!_.isEmpty(kmlData)) {
                    let center = null;
                    map?.addLayer(kmlData);
                    // // Adjust map to show the kml
                    // const bounds = kmlData.getBounds();
                    // map.fitBounds(bounds);
                    kmlData?.eachLayer((mapLayer) => {
                      center = mapLayer.getBounds().getCenter();
                      map.fitBounds(mapLayer.getBounds(), { padding: [100,100]});
                      // setPolygonRegionValues([center]);
                      // if (center) {
                      setTimeout(() => {
                        handleCordinates(
                          [
                            center?.lat ? center?.lat : cordinateAndZoom?.locationCordinates[0],
                            center?.lng ? center?.lng : cordinateAndZoom?.locationCordinates[1],
                          ],
                          18,
                        );
                      }, 500);
                      setKmlCenter([center]);
                      // }
                    });
                    setkml({ document: xmlDoc, layer: kmlData });
                  }
                }
              })
              .catch((err) => {
                console.log('error');
              });
          }
          if (!fromListing) {
            if (farm_attributes?.length) {
              let updatedArr = farm_attributes.filter(
                (val) => val.name === 'area' || val.name === 'longitude' || val.name === 'latitude',
              );
              if (updatedArr) {
                setDefaultPolygonProps(updatedArr);
              }
            }
            setFarmAttributes(farm_attributes);
            setFarmTitle(farm?.[0]?.name);
            setFarmDescription(farm?.[0]?.description);
            setIsCreateFarm(false);
            setIsUploadFarm(farm?.[0]?.is_upload);
            // setIsHideFarmCollapse(false);
            setShowFarmInputFields(true);
            setIsPolygonClicked(true);
            setProfileIcon(farm?.[0]?.profile_icon);
            setImageFile(farm?.[0]?.profile_icon);
            // setIsRightCollapseOpen(true);
            setCreateFarmData((prev) => {
              return {
                ...prev,
                farmData: farm?.[0],
              };
            });
          }
        }
      }
    } else if (FarmTracker?.getFarmDetailsByIdDataError) {
      if (FarmTracker?.getFarmDetailsByIdDataError?.data) {
        let errorData = FarmTracker?.getFarmDetailsByIdDataError?.data;
        let data = errorData?.response?.data;
        if (data?.message && data?.code === 400) showError(data?.message);
        setIsLoading(false);
      }
    }
  }, [
    FarmTracker?.getFarmDetailsByIdRequesting,
    FarmTracker?.getFarmDetailsByIdData,
    FarmTracker?.getFarmDetailsByIdDataError,
  ]);
  useEffect(() => {
    if (layersData?.length) {
      let firstLayer, secondLayer;
      let firstParam, secondParam;
      const addKMLData = async (url, index) => {
        let kmlLayerData = await axios
          .get(url)
          .then((res) => {
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(res?.data, 'text/xml');
            const kmlData = new L.KML(xmlDoc);
            if (!_.isEmpty(kmlData)) {
              let center = null;
              map?.addLayer(kmlData);
              if (index === 0) {
                setkml({ document: xmlDoc, layer: kmlData });
                kmlData?.eachLayer((mapLayer) => {
                  center = mapLayer.getBounds().getCenter();
                  // setPolygonRegionValues([center]);
                  // if (center) {
                  setTimeout(() => {
                    handleCordinates(
                      [
                        center?.lat ? center?.lat : cordinateAndZoom?.locationCordinates[0],
                        center?.lng ? center?.lng : cordinateAndZoom?.locationCordinates[1],
                      ],
                      18,
                    );
                  }, 500);
                  setKmlCenter([center]);
                  // }
                });
              } else if (index === 1) setCompareKml({ document: xmlDoc, layer: kmlData });
              kmlData?.eachLayer((mapLayer) => {
                center = mapLayer.getBounds().getCenter();
                // setPolygonRegionValues([center]);
                // if (center) {
                setTimeout(() => {
                  handleCordinates(
                    [
                      center?.lat ? center?.lat : cordinateAndZoom?.locationCordinates[0],
                      center?.lng ? center?.lng : cordinateAndZoom?.locationCordinates[1],
                    ],
                    18,
                  );
                }, 500);
                setCompareKmlCenter([center]);
                // }
              });
            }
          })
          .catch((error) => console.log('error'));
        return kmlLayerData;
      };

      (async () => {
        await Promise.all(
          layersData?.map(async (item, index) => {
            if (item?.data) {
              let { result } = item?.data;
              const farm = result?.farm;
              if (farm?.[0]?.is_upload) {
                let Kml_Api_url = farm?.[0]?.upload_file;
                if (index === 0) {
                  await addKMLData(Kml_Api_url, index);
                  firstParam = result;
                }
                if (index === 1) {
                  await addKMLData(Kml_Api_url, index);
                  secondParam = result;
                }
              } else {
                let drawnFarm = farm?.[0]?.draw_farm;
                let stringifiedData = farm?.[0]?.polygon_style
                  ? JSON.stringify(farm?.[0]?.polygon_style)
                  : {};
                let style = farm?.[0]?.polygon_style ? JSON.parse(stringifiedData) : {};
                if (index === 0) {
                  firstParam = result;
                  if (style) setStyledPolygonOptions({ options: style });
                  else setStyledPolygonOptions({ options: {} });
                  setPolygonRegionValues(drawnFarm?.features?.[0]?.geometry?.coordinates?.[0]);
                  if (drawnFarm) {
                    let firstLayer = L.geoJSON(drawnFarm, {
                      style: style,
                      onEachFeature: (feat, layer) => {
                        handleCompareEachFeature(_.capitalize(farm?.[0]?.name), feat, layer);
                      },
                    });
                    // drawFarmRef?.current?.addData(drawnFarm);
                    // if(firstLayer) {
                    firstLayer?.addTo(map);
                    // }
                    setLayerControl((prev) => {
                      return {
                        ...prev,
                        firstLayer,
                      };
                    });
                  }
                }
                if (index === 1) {
                  secondParam = result;
                  if (style) setCompareStyledPolygonOptions({ options: style });
                  else setCompareStyledPolygonOptions({ options: {} });
                  setComparePolygonRegionValues(
                    drawnFarm?.features?.[0]?.geometry?.coordinates?.[0],
                  );
                  if (drawnFarm) {
                    // compareDrawFarmRef?.current?.addData(drawnFarm);
                    let secondLayer = L.geoJSON(drawnFarm, {
                      style: style,
                      onEachFeature: (feat, layer) => {
                        handleCompareEachFeature(_.capitalize(farm?.[0]?.name), feat, layer);
                      },
                    });
                    // drawFarmRef?.current?.addData(drawnFarm);
                    // if(secondLayer) {
                    secondLayer?.addTo(map);
                    // }
                    setLayerControl((prev) => {
                      return {
                        ...prev,
                        secondLayer,
                      };
                    });
                  }
                }
              }
            }
          }),
        );
      })();
    }
  }, [layersData]);

  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (boundries?.mapPreferenceData) {
      setGetBoundariesData(boundries?.mapPreferenceData?.data?.result);
    }
  }, [boundries?.mapPreferenceData]);

  useEffect(() => {
    if (getBoundariesData && getBoundariesData?.length) {
      let API_URL = [];
      mapPreferRefs.current = [];
      // setIsLoading(true);
      getBoundariesData?.map((item) => {
        if (item?.data && item?.data.length) {
          item.data.map((boundary) => {
            if (boundary?.json_url?.length) {
              setGeoJsonData([]);
              setGeoJsonAllData([]);
              let mapPreferKeys = Object.keys(mapPreferences);
              if (mapPreferKeys) {
                mapPreferKeys.map((item) => {
                  let selectedLayer = mapPreferences[item];
                  if (selectedLayer?.layer && selectedLayer?.layer?.current)
                    selectedLayer?.layer?.current?.clearLayers();
                  selectedLayer.color = '';
                  setMapPreferences((prev) => {
                    return {
                      ...prev,
                      [item]: selectedLayer,
                    };
                  });
                });
              }
              setMapPreferenceGeoJsonloading(true);
              let config = {
                name: boundary?.slug,
                id: boundary?.id,
                color: boundary?.color_code,
                category: boundary?.map_preference_category?.name,
                country_id: DEFAULT_COUNTRY.id,
              };
              API_URL.push(AXIOS_INSTANCE.get(boundary?.json_url, config));
            } else {
              let ref = React.createRef();
              ref.current = {
                boundary_id: boundary?.id,
                layer: React.createRef(),
              };
              mapPreferRefs.current.push(ref);
            }
            // });
          });
        }
      });
      Promise.all(API_URL).then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        setMapPreferenceGeoJsonloading(false);
        setGeoJsonLayerData(res);
        // setIsLoading(false);
      });
    }
  }, [getBoundariesData]);

  useEffect(() => {
    let selectedGeoJsonData = [];
    if (geoJsonLayersData?.length && geoJsonData?.length) {
      geoJsonData?.map((item) => {
        let filteredObj = geoJsonLayersData?.find(
          (layer) => layer.config.id === item.id && layer.config.country_id === item.country_id,
        );
        if (!_.isEmpty(filteredObj)) {
          filteredObj.fromMapPreferences = item.fromMapPreferences;
          selectedGeoJsonData.push(filteredObj);
        }
      });
    } else {
      selectedGeoJsonData = [];
    }
    setGeoJsonAllData(selectedGeoJsonData);
  }, [geoJsonLayersData, geoJsonData]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      let MapPrefereArr = [];
      mapPreferencesData?.map((item) => {
        let legendItem = JSON.parse(JSON.stringify(item.result));
        legendItem.id = item?.dataSource;
        legendItem.slug = item.slug;
        legendItem.dataSource =
          item.slug === MAP_PREFERENCES_lABELS.JRC
            ? t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.WATER_OCCURRENCE')
            : item.slug === MAP_PREFERENCES_lABELS.MOD44
            ? t('CLIMATE_WEATHER_FORECAST.MAP_PREFERENCES.PERMANENT_WATER')
            : item.category;
        // legendItem.dataSource = item?.dataSource;
        legendItem.parameter = item?.legendName ? item?.legendName : item?.dataSource;
        // legendItem.parameter = item?.legendName;
        legendItem.add = true;
        legendItem.measure = legendItem.units;

        legendItem.fromMapPreferences = true;

        legendItem.legendId = item.id;
        // legendItem.fromMapPreferences = true;
        if (selectedMapPreferLegendData?.length) {
          let isDuplicate = selectedMapPreferLegendData?.find(
            (x) => x.legendId === item.id && item.add === true,
          );
          if (_.isEmpty(isDuplicate)) {
            MapPrefereArr.push(legendItem);
          }
        } else {
          MapPrefereArr.push(legendItem);
        }
      });
      setSelectedMapPreferLegendData(MapPrefereArr);
    }
  }, [mapPreferencesData]);

  useEffect(() => {
    if (
      !_.isEmpty(mapLayerOpacity) &&
      !_.isEmpty(mapLayerOpacity?.layer) &&
      !_.isEmpty(mapPreferencesData)
    ) {
      // if (!_.isEmpty(opacityControl)) map?.removeControl(opacityControl);
      if (!_.isEmpty(opacityControl) && !_.isEmpty(mapPreferencesData))
        map?.removeControl(opacityControl);

      let customOpacityControl = '';
      customOpacityControl = L?.control?.opacity(mapLayerOpacity, {
        collapsed: false,
        position: 'topright',
      });
      setOpacityControl(customOpacityControl);
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) {
        if (!_.isEmpty(customOpacityControl)) {
          customOpacityControl?.addTo(map);
        }
      }
    } else if (!_.isEmpty(mapLayerOpacity) && !_.isEmpty(mapLayerOpacity?.layer)) {
      const StyleleafletEle1 = document.getElementsByClassName('leaflet-control-layers-expanded');
      if (StyleleafletEle1?.length) {
        StyleleafletEle1[0].style.visibility = 'hidden';
        StyleleafletEle1[0].classList.add('leaflet-styleeditor-disable');
      }
    }
  }, [mapLayerOpacity, mapPreferencesData]);

  // useEffect(() => {
  //   if (geoJsonAllData?.length) {
  //     geoJsonAllData.map((item) => {
  //       let mapGeojsonItem = Object.keys(mapPreferences).find((x) => x === item.config.name);
  //       let selectedLayer = mapPreferences[mapGeojsonItem];
  //       if (selectedLayer?.layer && selectedLayer?.layer?.current) {
  //         if (
  //           !_.isEmpty(selectedLocation?.district) ||
  //           selectedLocation?.district?.name !== 'select'
  //         ) {
  //           let filteredLayer = returnFilteredGeoJsonData(
  //             selectedLayer,
  //             item,
  //             selectedLocation?.district,
  //             MAP_PREFERENCES_lABELS.DISTRICTS,
  //             MAP_PREFERENCES_lABELS.TALUKAS,
  //             selectedLocation?.country,
  //           );
  //           if (filteredLayer) {
  //             setMapPreferences((prev) => {
  //               return {
  //                 ...prev,
  //                 [mapGeojsonItem]: filteredLayer,
  //               };
  //             });
  //           }
  //         }
  //         if (!_.isEmpty(selectedLocation?.state) || selectedLocation?.state?.name !== 'select') {
  //           let filteredLayer = returnFilteredGeoJsonData(
  //             selectedLayer,
  //             item,
  //             selectedLocation?.state,
  //             MAP_PREFERENCES_lABELS.STATES,
  //             MAP_PREFERENCES_lABELS.DISTRICTS,
  //             selectedLocation?.country,
  //           );
  //           if (filteredLayer) {
  //             setMapPreferences((prev) => {
  //               return {
  //                 ...prev,
  //                 [mapGeojsonItem]: filteredLayer,
  //               };
  //             });
  //           }
  //         }
  //       }
  //     });
  //   }
  // }, [geoJsonAllData]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      if (mapPreferRefs?.current?.length) {
        mapPreferencesData?.map((item) => {
          let selectedMapPreferences = mapPreferRefs.current.find(
            (x) => x.current.boundary_id === item.id,
          );
          let currentParamRef = selectedMapPreferences.current?.layer;
          currentParamRef?.current?.eachLayer((mapLayer) => {
            mapLayer.setUrl(item?.result?.map_url);
            setMapLayerOpacity({ layer: mapLayer });
          });
          if (currentParamRef && currentParamRef?.current) {
            addLayers(currentParamRef);
          }
        });
      }
    }
  }, [mapPreferencesData, mapPreferRefs.current]);

  useEffect(() => {
    if (region?.length) {
      let data = region[region.length - 1];
      if (data?.layerType === 'polygon') {
        setIsPolygonClicked(true);
      }
    }
  }, [region]);

  useEffect(() => {
    if (region?.length) {
      let data = region[region.length - 1];
      if (data?.layerType === 'polygon') {
        let center = data?.center;
        if (center?.length) {
          handleAddMoreAttributes(
            { name: 'latitude', value: center[0]?.toFixed(4) },
            { isDefaultProps: true },
          );
          handleAddMoreAttributes(
            { name: 'longitude', value: center[1]?.toFixed(4) },
            { isDefaultProps: true },
          );
        }
      }
    }
  }, [region]);

  useEffect(() => {
    if (isHideFarmCollapse) {
      if (!isCreateFarm) {
        setIsCreateFarm(true);
        setFarmTitle('');
        setProfileIcon('');
        setImageFile('');
        setFarmDescription('');

        setFarmAttributes([]);
      }
    }
  }, [isHideFarmCollapse]);

  useEffect(() => {
    if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) {
      map.on('measurefinish', function (evt) {
        setResultantMeasureData(evt);
      });
    }
  }, [map]);

  useEffect(() => {
    if (!_.isEmpty(selectedFarm)) {
      let updatedCompareList = [...farmsList?.farmsList];
      if (updatedCompareList?.length) {
        updatedCompareList = updatedCompareList?.filter((i) => i.id !== selectedFarm?.id);
        setCompareFarm('');
        // setCompareFarmsList(updatedCompareList);
        setCompareFarmsList((prev) => {
          return {
            ...prev,
            farmsList: updatedCompareList,
          };
        });
      }
    }
  }, [selectedFarm]);

  // noaa times series
  useEffect(() => {
    let centeroidPoints = [];
    if (!_.isEmpty(polygonRegionValues)) {
      centeroidPoints = handleCentroid(polygonRegionValues);
    }
    let isCompare =
      isOpenFarmCompareCollapse && !_.isEmpty(selectedFarm) && !_.isEmpty(compareFarm);
    if (!_.isEmpty(getByIdFarmInfo) && (!_.isEmpty(centeroidPoints) || !_.isEmpty(kmlCenter))) {
      handleCurrentWeatherTimeSeries(getByIdFarmInfo, centeroidPoints, kmlCenter, isCompare);
      handleForecastTimeseries(getByIdFarmInfo, centeroidPoints, kmlCenter, isCompare);
      handleRiskScoreTimeseries(getByIdFarmInfo, polygonRegionValues, kmlCenter, isCompare);
    }
  }, [polygonRegionValues, kmlCenter, getByIdFarmInfo]);
  // noaa times series
  useEffect(() => {
    let centeroidPoints = [];
    if (!_.isEmpty(comparePolygonRegionValues)) {
      centeroidPoints = handleCentroid(comparePolygonRegionValues);
    }
    let isCompare =
      isOpenFarmCompareCollapse && !_.isEmpty(selectedFarm) && !_.isEmpty(compareFarm);
    if (
      !_.isEmpty(getByIdCompareFarmInfo) &&
      (!_.isEmpty(centeroidPoints) || !_.isEmpty(compareKmlCenter))
    ) {
      handleCurrentWeatherTimeSeries(
        getByIdCompareFarmInfo,
        centeroidPoints,
        compareKmlCenter,
        isCompare,
      );
      handleForecastTimeseries(
        getByIdCompareFarmInfo,
        centeroidPoints,
        compareKmlCenter,
        isCompare,
      );
      handleRiskScoreTimeseries(
        getByIdCompareFarmInfo,
        comparePolygonRegionValues,
        compareKmlCenter,
        isCompare,
      );
    }
  }, [comparePolygonRegionValues, compareKmlCenter, getByIdCompareFarmInfo]);

  // Handle timeseries data for NOAA GFS
  useEffect(() => {
    if (Weather.noaaTimeSeriesDataRequesting) {
      // setIsGraphLoading(true);
      setIsLoading(true);
      // setChartSeriesData([]);
    } else if (Weather.noaaTimeSeriesData) {
      setIsLoading(false);
      if (Weather.noaaTimeSeriesData.data) {
        let { code } = Weather.noaaTimeSeriesData?.data;
        if (code === 200) {
          let { result } = Weather.noaaTimeSeriesData?.data;
          let isCompare = Weather.noaaTimeSeriesData?.isCompare;
          if (isCompare) compareForecastRightSideBar?.push(result);
          else forecastRightSideBar?.push(result);
        }
      }
    } else if (Weather.noaaTimeSeriesDataError) {
      if (Weather?.noaaTimeSeriesDataError?.data) {
        let noaaData = Weather.noaaTimeSeriesDataError?.data || '';
        if (noaaData?.response) {
          if (noaaData?.response?.data) {
            // setErrorData({ showError: true, data: noaaData?.response?.data.message });
            showError(noaaData?.response?.data.message);
            setIsLoading(false);
          }
        }
      }
      // setIsGraphLoading(false);
    }
  }, [
    Weather.noaaTimeSeriesDataRequesting,
    Weather.noaaTimeSeriesData,
    Weather.noaaTimeSeriesDataError,
  ]);

  //handle time series data for riskAnalytics
  useEffect(() => {
    if (RiskAnalytics.riskScoreTimeSeriesDataRequesting) {
      setIsLoading(true);
      // setRiskScoreInfo([]);
    } else if (RiskAnalytics.riskScoreTimeSeriesData) {
      setIsLoading(false);
      if (RiskAnalytics.riskScoreTimeSeriesData?.data) {
        let { code } = RiskAnalytics.riskScoreTimeSeriesData?.data;
        let isCompare = RiskAnalytics.riskScoreTimeSeriesData?.isCompare;
        let selectedFarmId = RiskAnalytics.riskScoreTimeSeriesData?.selectedFarmId;
        let selectedCompareFarmId = RiskAnalytics.riskScoreTimeSeriesData?.selectedCompareFarmId;
        if (code === 200) {
          let { result } = RiskAnalytics.riskScoreTimeSeriesData?.data;
          if (selectedCompareFarmId === getByIdCompareFarmInfo?.farm?.[0]?.id && isCompare) {
            setCompareRiskScoreInfo(result);
            setCompareRiskPercentage(result?.graph_data?.data[0]);
            setCompareRiskLevel(result?.graph_data ? result?.graph_data?.level[0] : 'N/A');
          } else if (selectedFarmId === getByIdFarmInfo?.farm?.[0]?.id) {
            setRiskScoreInfo(result);
            setCompareRiskScoreInfo('');
            setRiskPercentage(result?.graph_data?.data[0]);
            setRiskLevel(result?.graph_data ? result?.graph_data?.level[0] : 'N/A');
            // setRiskLevel('N/A');
          }
        }
      }
    } else if (RiskAnalytics.riskScoreTimeSeriesDataError) {
      if (RiskAnalytics.riskScoreTimeSeriesDataError?.data) {
        let { code } = RiskAnalytics.riskScoreTimeSeriesDataError?.data;
        let selectedFarmId = RiskAnalytics.riskScoreTimeSeriesDataError?.selectedFarmId;
        let selectedCompareFarmId =
          RiskAnalytics.riskScoreTimeSeriesDataError?.selectedCompareFarmId;
        if (!isOpenCropProductionModal) { //show error when only cropwise production modal is closed
          if (
            RiskAnalytics.riskScoreTimeSeriesDataError &&
            selectedFarmId === getByIdCompareFarmInfo?.farm?.[0]?.id
          ) {
            setIsLoading(false);
            // setErrorData({ showError: true, data: RiskAnalytics.riskScoreTimeSeriesDataError?.data?.response?.data?.message });
            if (!showRiskScoreError) {
              if (
                RiskAnalytics.riskScoreTimeSeriesDataError?.data?.response?.data?.message &&
                selectedFarmId === getByIdCompareFarmInfo?.farm?.[0]?.id
              ) {
                showError(
                  RiskAnalytics.riskScoreTimeSeriesDataError?.data?.response?.data?.message,
                );
              }
              setShowRiskScoreError(true);
            }
          } else if (
            RiskAnalytics.riskScoreTimeSeriesDataError &&
            selectedFarmId === getByIdFarmInfo?.farm?.[0]?.id
          ) {
            setIsLoading(false);
            if (!showRiskScoreError) {
              if (
                RiskAnalytics.riskScoreTimeSeriesDataError?.data?.response?.data?.message &&
                selectedFarmId === getByIdFarmInfo?.farm?.[0]?.id
              ) {
                showError(
                  RiskAnalytics.riskScoreTimeSeriesDataError?.data?.response?.data?.message,
                );
              }
              setShowRiskScoreError(true);
            }
          }
        }
      }
    }
  }, [
    RiskAnalytics.riskScoreTimeSeriesDataRequesting,
    RiskAnalytics.riskScoreTimeSeriesData,
    RiskAnalytics.riskScoreTimeSeriesDataError,
  ]);
  const removeDrawnFarm = (drawFarmRef) => {
    if (drawFarmRef && drawFarmRef?.current) {
      drawFarmRef.current.clearLayers();
    }
  };

  const handleEachFeatureChange = (countryData, locationType, feat, layer) => {
    let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
      (item) => item.selectedCountry === countryData?.name,
    );
    let labelprop = selectedCountryData ? selectedCountryData[locationType] : '';
    if (labelprop && feat.properties[labelprop])
      layer.bindTooltip(feat.properties[labelprop] + '', { sticky: true, direction: 'auto' });

    /** TODO: alternative solution */

    // if(!_.isEmpty(layer) && !_.isEmpty(feat?.properties)){
    //   layer?.on({
    //     'mousemove': (e) => {
    //       const name = e.target.feature.properties['MJ_BASIN__'] ? e.target.feature.properties['MJ_BASIN__'] : '';
    //       layer?.bindTooltip(name)
    //       layer?.openTooltip(e?.latlng);
    //     },
    //     // 'mouseout': () => {
    //     //   layer?.unbindTooltip();
    //     //   layer?.closeTooltip();
    //     // },
    //   });
    // }
  };

  const handleCentroid = function (arr) {
    return arr.reduce(
      function (x, y) {
        return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
      },
      [0, 0],
    );
  };

  const handleCompareEachFeature = (labelprop, feat, layer) => {
    // if (labelprop && feat.properties[labelprop])
    // layer.bindTooltip(labelprop + '', { sticky: true, direction: 'auto' });
    if (!_.isEmpty(layer) && !_.isEmpty(feat?.properties)) {
      layer?.on({
        mousemove: (e) => {
          // const name = labelprop;
          layer.bindTooltip(labelprop + '', { sticky: true, direction: 'auto' });
          layer?.openTooltip(e?.latlng);
        },
        mouseout: () => {
          layer?.unbindTooltip();
          layer?.closeTooltip();
        },
      });
    }
  };

  const returnFilteredGeoJsonData = (
    selectedLayer,
    item,
    location,
    propertyName,
    locationName,
    countryData,
  ) => {
    if (selectedLayer?.layer && selectedLayer?.layer?.current) {
      let data = JSON.parse(JSON.stringify(item.data));
      let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
        (item) => item.selectedCountry === selectedLocation?.country?.name,
      );
      let labelprop = selectedCountryData ? selectedCountryData[propertyName] : '';
      if (item.config.name === locationName && !_.isEmpty(location)) {
        data.features = item.data?.features.filter((x) => {
          if (_.startCase(_.lowerCase(x?.properties[labelprop])) === location?.name) {
            selectedLayer.country = selectedLocation?.country;
            selectedLayer?.layer?.current?.clearLayers();
            return x;
          }
        });
        selectedLayer.layer.current.addData(data);
        selectedLayer.color = item.config.color;
      }
      return selectedLayer;
    }
  };

  // const addLeafletMeasureControl = (map) => {
  //   L.Control.Measure.include({
  //     // set icon on the capture marker
  //     _setCaptureMarkerIcon: function () {
  //       // disable autopan
  //       this._captureMarker.options.autoPanOnFocus = false;

  //       // default function
  //       this._captureMarker.setIcon(
  //         L.divIcon({
  //           iconSize: this._map.getSize().multiplyBy(2),
  //         }),
  //       );
  //     },
  //   });
  //   var measure = L.control
  //     .measure({
  //       position: 'topright',
  //       lineColor: 'blue',
  //       primaryAreaUnit: 'acres',
  //       secondaryAreaUnit: 'meters',
  //       activeColor: '#84bff0',
  //       completedColor: '#47a2ed',
  //       captureZIndex: 20000,
  //     })
  //     .addTo(map);

  //   setMeasureData(measure);
  // };

  const handleCurrentWeatherTimeSeries = (farmInfo, centeroidPoints, kmlCenter, isCompare) => {
    let openWeatherObj = {};
    openWeatherObj.start_date = moment().format(DATE_FORMAT);
    openWeatherObj.end_date = moment().format(DATE_FORMAT);
    openWeatherObj.weather_forecast_parameter_id = 'e2f886a7-3299-4c82-af2e-123fe92fab02';
    openWeatherObj.weather_forecast_source_id = DATASOURCE_PARAMETER[0]?.id;

    if (
      farmInfo?.farm?.[0]?.is_upload !== undefined ||
      (centeroidPoints?.[0] !== 0 && centeroidPoints?.[1] !== 0)
    ) {
      if (farmInfo?.farm?.[0]?.is_upload) {
        openWeatherObj.latitude = kmlCenter?.[0]?.lng;
        openWeatherObj.longitude = kmlCenter?.[0]?.lat;
      } else {
        openWeatherObj.longitude = centeroidPoints?.[0];
        openWeatherObj.latitude = centeroidPoints?.[1];
        // openWeatherObj.longitude = centeroidPoints?.[0]
        // openWeatherObj.latitude = centeroidPoints?.[1]
      }
      dispatch(getWeatherForecastData(openWeatherObj, { isCompare }));
    }
  };

  const handleForecastTimeseries = (farmInfo, centeroidPoints, kmlCenter, isCompare) => {
    for (let param of categoryParams) {
      let requestObj = {};
      requestObj.start_date = moment().format(DATE_FORMAT);
      requestObj.end_date = moment().add(15, 'days').format(DATE_FORMAT);
      if (
        farmInfo?.farm?.[0]?.is_upload !== undefined ||
        (centeroidPoints?.[0] !== 0 && centeroidPoints?.[1] !== 0)
      ) {
        // requestObj.geometry = polygonRegionValues;
        if (farmInfo?.farm?.[0]?.is_upload) {
          let longitude = kmlCenter?.[0]?.lng;
          let latitude = kmlCenter?.[0]?.lat;
          requestObj.geometry = [[longitude, latitude]];
        } else {
          let longitude = centeroidPoints?.[0];
          let latitude = centeroidPoints?.[1];
          requestObj.geometry = [[longitude, latitude]];
          // openWeatherObj.longitude = centeroidPoints?.[0]
          // openWeatherObj.latitude = centeroidPoints?.[1]
        }
        requestObj.spatial_aggregation = 'mean';
        requestObj.country_name = DEFAULT_COUNTRY.name;
        requestObj.state_name = projectIndividualData?.projectData?.province?.label;
        requestObj.district_name = projectIndividualData?.projectData?.district?.label;
        requestObj.sub_district_name = getByIdFarmInfo?.farm?.[0]?.name === rightBarActiveTab || rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? getByIdFarmInfo?.farm?.[0]?.sub_district_name : getByIdCompareFarmInfo?.farm?.[0]?.sub_district_name;
        if(getByIdFarmInfo?.farm?.[0]?.name === rightBarActiveTab || rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? getByIdFarmInfo?.farm?.[0]?.block_name : getByIdCompareFarmInfo?.farm?.[0]?.block_name)
        requestObj.block_name =getByIdFarmInfo?.farm?.[0]?.name === rightBarActiveTab || rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? getByIdFarmInfo?.farm?.[0]?.block_name : getByIdCompareFarmInfo?.farm?.[0]?.block_name;
        // requestObj.sub_district_name = projectIndividualData?.projectData?.sub_district?.label;
        // requestObj.block_name = projectIndividualData?.projectData?.block?.label;
        requestObj.weather_forecast_parameter_id = param.weather_forecast_parameter?.id;
        //passing requestobj
        dispatch(getNoaaTimeSeries(requestObj, { isCompare }));
      }
    }
  };

  const handleRiskScoreTimeseries = (farmInfo, centeroidPoints, kmlCenter, isCompare) => {
    let riskScoreObj = {};
    riskScoreObj.start_date = farmInfo
      ? moment(new Date(farmInfo?.risk_score_dataset_end_date))
          .subtract(31, 'days')
          .format(DATE_FORMAT)
      : '';
    riskScoreObj.end_date = farmInfo ? farmInfo?.risk_score_dataset_end_date : '';
    riskScoreObj.spatial_aggregation = 'mean';
    // riskScoreObj.country_name = DEFAULT_COUNTRY.name;
    // riskScoreObj.state_name = projectIndividualData?.projectData?.province?.label;
    // riskScoreObj.district_name = projectIndividualData?.projectData?.district?.label;
    // riskScoreObj.sub_district_name = projectIndividualData?.projectData?.sub_district?.label;
    if (
      farmInfo?.farm?.[0]?.is_upload !== undefined ||
      (centeroidPoints?.[0] !== 0 && centeroidPoints?.[1] !== 0)
    ) {
      if (farmInfo?.farm?.[0]?.is_upload) {
        let longitude = kmlCenter?.[0]?.lng;
        let latitude = kmlCenter?.[0]?.lat;
        riskScoreObj.geometry = [[longitude, latitude]];
      } else {
        // let longitude = centeroidPoints?.[0];
        // let latitude = centeroidPoints?.[1];
        riskScoreObj.geometry = centeroidPoints;
      }
      dispatch(
        getRiskScoreTimeSeries(riskScoreObj, {
          isCompare,
          id: getByIdFarmInfo?.farm?.[0]?.id,
          compareId: getByIdCompareFarmInfo?.farm?.[0]?.id,
        }),
      );
    }
  };

  const getMapPreferencesGeojsonData = (geoJsonList) => {
    let data = JSON.parse(JSON.stringify(geoJsonList));
    removeGeoJsonLayer(mapPreferences);
    setGeoJsonData(data);
  };

  const removeGeoJsonLayer = (mapPreferences) => {
    let mapPreferKeys = Object.keys(mapPreferences);
    if (mapPreferKeys) {
      mapPreferKeys.map((item) => {
        let selectedLayer = mapPreferences[item];
        if (selectedLayer?.layer && selectedLayer?.layer?.current)
          selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.color = '';
        setMapPreferences((prev) => {
          return {
            ...prev,
            [item]: selectedLayer,
          };
        });
      });
    }
  };
  const handleSelectedRegion = (reg) => {
    let mapLayerData = [];
    let polyCount = 1;
    let mapData = JSON.parse(JSON.stringify(reg));
    if (mapData?.length) {
      mapData.map((item) => {
        let x = Object.assign(item, {});
        if (item.layerType === 'polygon') {
          x.latlngs = item.latlngs.map((v) => [v[1], v[0]]);
          x?.latlngs?.push(item.latlngs[0]);
          if (!x.hasOwnProperty('polygonCount')) {
            x.polygonCount = polyCount;
            polyCount += 1;
          }
          mapLayerData.push(x);
        } else {
          x.latlngs = [item.latlngs[1], item.latlngs[0]];
          mapLayerData.push(x);
        }
      });
    }
    if (reg?.length) {
      if (reg?.length === 1 && reg[0].layerType === 'marker') {
        let cordinates = reg[0]?.latlngs;
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            markerCordinates: cordinates,
          };
        });
      } else {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            markerCordinates: [],
          };
        });
      }
    } else {
      setCordinateAndZoom((prev) => {
        return {
          ...prev,
          zoom: 18,
          markerCordinates: [],
        };
      });
    }
    setRegion(mapLayerData);
  };

  // function to change the map attributes inside the map container
  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            cordinates: mapEvents.getCenter(),
            zoom: mapEvents.getZoom(),
            // zoom:18
          };
        });
      },
      dragend: () => {
        setCordinateAndZoom((prev) => {
          return {
            ...prev,
            zoom: mapEvents.getZoom(),
            cordinates: mapEvents.getCenter(),
          };
        });
      },
    });

    const map = useMap();
    setInterval(function () {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 500);
    map.setView(center, zoom);
    return null;
  }

  //function to handle map preferences model toggling
  const handleMapModel = () => {
    setIsOpenMapModel(!isOpenMapModel);
  };

  //function to toggle tab
  const handleToggle = (tab) => {
    setActiveTab(tab);
  };

  const addLegend = (paramLegendData) => {
    if (paramLegendData?.length) {
      return paramLegendData?.map((item) => {
        return (
          <>
            {item?.descret_legend ? (
              <DiscreteLegend map={map} add={item.add} layer={item} position="bottomleft" />
            ) : (
              <CustomLegend
                map={map}
                add={item.add}
                layer={item}
                position={item.isCompare ? 'bottomleft' : 'bottomright'}
              />
            )}
          </>
        );
      });
    }
  };

  //function to set map reference
  const setMapReference = (mapInstance) => {
    mapRef.current = mapInstance;
    // addLeafletMeasureControl(mapInstance);
    setMap(mapInstance);
  };

  //function to add layers in the map
  const addLayers = (selectedParamRef) => {
    if (mapRef.current && selectedParamRef.current) {
      const leafletMapRef = mapRef.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer, index) => {
        leafletMapRef.addLayer(layer);
      });
    }
    return false;
  };

  //function to remove layers from map
  const removeLayers = (selectedParamRef) => {
    if (mapRef && mapRef?.current && selectedParamRef && selectedParamRef?.current) {
      const map = mapRef.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer) => map.removeLayer(layer));
    }
  };

  const removeMapPreferenceLayer = (mapPreferRefs, mapPreferencesData) => {
    if (mapPreferRefs?.current?.length && mapPreferencesData?.length) {
      mapPreferencesData?.map((item) => {
        let selectedMapIndex = _.findIndex(
          mapPreferRefs.current,
          (list) => list.current.boundary_id === item.id,
        );
        let previousParamRef = mapPreferRefs?.current[selectedMapIndex].current.layer;
        if (previousParamRef && previousParamRef?.current) {
          removeLayers(previousParamRef);
        }
      });
    }
  };

  const getMapPreferencesData = (mapPreferencesLayerData) => {
    /** removing previously added map preferences layers before setting the state */
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    /** setting map preferences state*/
    let arr = JSON.parse(JSON.stringify(mapPreferencesLayerData));
    let paramData = '';
    let paramArr = '';
    if (selectedMapPreferLegendData?.length) {
      paramData = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      paramData?.map((item, index) => {
        if (item.add) {
          let legendItem = { ...item };
          legendItem.add = false;
          paramData[index] = legendItem;
        }
      });
      paramArr = [...paramData];
    }
    setSelectedMapPreferLegendData(paramArr);
    setMapPreferencesData(arr);
  };

  const handleCordinates = (cordinates, zoom) => {
    let zoom_level = zoom ? zoom : cordinateAndZoom?.zoom;
    setCordinateAndZoom((prev) => {
      return {
        ...prev,
        locationCordinates: cordinates,
        cordinates,
        zoom: zoom_level,
      };
    });
  };

  const handleAddMoreAttributes = (obj, { isDefaultProps }) => {
    let attributesList = JSON.parse(JSON.stringify(farmAttributes));
    if (attributesList?.length < attributeLimit) {
      let updatedList = [];
      updatedList.push({ name: obj.name, value: obj.value });
      if (isDefaultProps) {
        setDefaultPolygonProps((prev) => prev.concat(updatedList));
      }
      setFarmAttributes((prev) => prev.concat(updatedList));
    }
  };

  const handleDeleteAttribute = () => {
    let attributesList = JSON.parse(JSON.stringify(farmAttributes));
    attributesList.pop();
    setFarmAttributes(attributesList);
  };

  const handleSelectedFarms = (data) => {
    let updatedFarmList = [...selectedFarmsList];
    if (selectedFarmsList?.length) {
      let isSelected = selectedFarmsList?.some((i) => i.id === data.id);
      if (isSelected) {
        updatedFarmList = selectedFarmsList?.filter((i) => i.id !== data?.id);
      } else updatedFarmList?.push(data);
    } else {
      updatedFarmList?.push(data);
    }
    return updatedFarmList;
  };

  const getFarmData = (data, index) => {
    setSelectedFarmIndex(index);
    setIsApplyLayer(false);
    setSelectedFarm(data);
  };

  const getCompareFarmData = (data) => {
    setCompareFarm(data);
    setIsApplyLayer(false);
  };

  const handleRemovePolygonOnProjectChange = () => {
    setLayerControl({
      firstLayer: {},
      secondLayer: {},
    });
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    removeDrawnFarm(drawFarmRef);
    removeDrawnFarm(compareDrawFarmRef);
    //re-setting the risk score
    setRiskLevel('');
    setRiskPercentage(0);
    setCompareRiskLevel('');
    setCompareRiskPercentage(0);
    setUpdateGraphValues({});
  };

  const getSelectedFarm = (data) => {
    if (!_.isEmpty(data)) {
      setFromListing(false);
      removeDrawnFarm(drawFarmRef);
      removeDrawnFarm(compareDrawFarmRef);
      if (!_.isEmpty(layerControl?.firstLayer)) {
        map?.removeControl(layerControl?.firstLayer);
      }
      if (!_.isEmpty(layerControl?.secondLayer)) {
        map?.removeControl(layerControl?.secondLayer);
      }
      if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
      setkml({ document: '', layer: {} });

      if (!_.isEmpty(compareKml?.layer)) map?.removeLayer(compareKml?.layer);
      setCompareKml({ document: '', layer: {} });

      setDefaultPolygonProps('');
      setStyledPolygonOptions({ options: {} });
      dispatch(getFarmDetailsByIdRequest({ id: data?.id }));
    }
  };

  const handleCloseFarmList = () => {
    setFarmListingContainer(false);
    setIsHideFarmCollapse(true);
    setIsRightCollapseOpen(false);
  };

  const getFarmListContainer = (item) => {
    // handleCloseRiskRightSideBar()
    // setIsHideFarmCollapse(false);
    // setIsRightCollapseOpen(false);
    handleOpenCurrentWeatherSplitter(false);
    let data = {};
    let projectData = {
      project_id: item?.id,
      title: item?.name,
      description: item?.description,
      province: {
        id: item?.state_id,
        label: item?.state_name,
        value: item?.state_name,
        name: item?.state_name,
      },
      district: {
        id: item?.district_id,
        label: item?.district_name,
        value: item?.district_name,
        name: item?.district_name,
      },
      sub_district: {
        id: item?.sub_district_id,
        label: item?.sub_district_name,
        value: item?.sub_district_name,
        name: item?.sub_district_name,
      },
      block: {
        id: item?.block_id,
        label: item?.block_name,
        value: item?.block_name,
        name: item?.block_name,
      },
      farmsList: [],
    };
    data.projectData = projectData;
    setProjectIndividualData(data);
    setFarmListingContainer(true);
    setIsHideFarmCollapse(false);
  };

  const handleProjectUpdate = (item) => {
    if(item){
      if(filterProject && filterFarm){
        const queryParams = new URLSearchParams(location.search)
        queryParams.delete('farm_id')
        queryParams.delete('project_id');
        history.replace({
          search: queryParams.toString(),
        })
        setFilterProject('')
        setFilterFarm('')
        setFarmId('')
      }
    }
  }

  // function to close rightside bar
  const handleCloseRiskRightSideBar = ({ fromHandleOpen }) => {
    handleResetState();
    setIsRightCollapseOpen(!isRightCollapseOpen);
    if(!fromHandleOpen){
    if(filterProject && filterFarm){
      const queryParams = new URLSearchParams(location.search)
      queryParams.delete('farm_id')
      queryParams.delete('project_id');
      history.replace({
        search: queryParams.toString(),
      })
      dispatch(getAllByCriteriaProjectListRequest({ module_name: 'risk_analytics' }));
      setProjectIndividualData([])
      setProjectDetails({})
      setFarmListingContainer(false);
      setFilterProject('')
      setFilterFarm('')
      setFarmListState(false)
      setFarmId('')
    }
    }
  };

  const handleOpenDeleteFarmModal = (item) => {
    if (item?.id) {
      setCreateFarmData((prev) => {
        return {
          ...prev,
          farmData: item,
        };
      });
    }
  };

  const handleDeleteFarm = (id) => {
    dispatch(deleteFarmTrackerRequest({ id: id }));
  };

  const renderFarmAttributes = (attributes) => {
    if (attributes?.length) {
      let data = [...attributes];
      data = attributes?.map((item) => {
        let obj = {};
        obj[item.name] = item.value;
        let updatedObj = obj;
        return updatedObj;
      });
      return data;
    }
  };

  const handleGoBack = () => {
    history.push('/home');
  };

  const handleResetState = () => {
    setFarmAttributes([]);
    setFarmTitle('');
    setFarmDescription('');
    setProfileIcon('');
    setImageFile('');
    setIsImageUploaded(false);
    setUploadedFiles('');
    setIsUploadFarm(false);
    setIsCreateFarm(true);
    setFromListing(false);
    setIsPolygonClicked(false);
    setIsHideFarmCollapse(true);
    setLayersData([]);
    setModalShow(false);
    setStyledPolygonOptions({ options: {} });
    setLayerControl({
      firstLayer: {},
      secondLayer: {},
    });
    if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
    setkml({ document: '', layer: {} });
    if (!_.isEmpty(compareKml?.layer)) map?.removeLayer(compareKml?.layer);
    setCompareKml({ document: '', layer: {} });
    removeDrawnFarm(drawFarmRef);
    removeDrawnFarm(compareDrawFarmRef);
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    setStyledPolygonOptions({ options: {} });
    setShowFarmInputFields(false);
    setOpenFullScreen(false);
    setIsRightCollapseOpen(false);
    setGetByIdFarmInfo({});
    setGetByIdCompareFarmInfo({});
    setForecastRightSideBar([]);
    setCompareForecastRightSideBar([]);
    setRiskScoreInfo([]);
    setCompareRiskScoreInfo([]);
    setChartSeriesData([]);
    setCompareChartSeriesData([]);
    setPolygonRegionValues([]);
    setAnalyzeRiskModalShow(false);
    setRightBarActiveTab(t('RISK_ANALYTICS.FARM_1'));
  };

  const handleResetCollapseFarm = () => {
    setFarmTitle('');
    setFarmDescription('');
    setProfileIcon('');
    setImageFile('');

    setFarmAttributes([]);
  };
  useEffect(() => {
    if (RiskAnalytics.sendEmailDataRequesting) {
      setIsLoading(true);
    } else if (RiskAnalytics.sendEmailData) {
      setIsLoading(false);
      if (RiskAnalytics.sendEmailData) {
        let data = RiskAnalytics.sendEmailData.data;
        showSuccess(data?.result);
      }
    } else if (RiskAnalytics.sendEmailDataError) {
      if (RiskAnalytics?.sendEmailDataError?.data) {
        let {
          response: { data },
        } = RiskAnalytics?.sendEmailDataError?.data;
        if (data?.message && data?.code === 400) showError(data?.message);
        setIsLoading(false);
      }
    }
  }, [
    RiskAnalytics.sendEmailData,
    RiskAnalytics.sendEmailDataRequesting,
    RiskAnalytics.sendEmailDataError,
  ]);

  useEffect(() => {
    if (splitterRef && splitterRef.current) {
      if (openFullScreen) {
        splitterRef.current.setState({ secondaryPaneSize: 100, primaryMinSize: 0 });
      } else if (analyzeRiskmodalShow) {
        splitterRef.current.setState({ secondaryPaneSize: 75 });
      } else {
        splitterRef.current.setState({ secondaryPaneSize: 75 });
      }
    }
  }, [openFullScreen, analyzeRiskmodalShow]);

  useEffect(() => {
    if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) {
      map.on('baselayerchange', function (element) {
        element?.layer?.bringToBack();
      });
    }
  }, [map]);

  //function to render map
  const renderMapContainer = () => {
    let getCss = document.getElementsByClassName('leaflet-control-layers');
    const parent = document.getElementsByClassName('map-preferences-button');
    if (getCss?.length) {
      getCss[1]?.addEventListener('mouseover', over);
      getCss[1]?.addEventListener('mouseout', out);
      function over() {
        // parent[0].style.marginTop = "23px"
        parent[0].style.marginTop = '1.7%';
      }
      function out() {
        parent[0].style.marginTop = '0%';
      }
    }
    return (
      <>
        <MapContainer
          center={cordinateAndZoom.cordinates}
          id="farm-tracker-map"
          zoom={cordinateAndZoom.zoom}
          className="farm-tracker-map-container"
          zoomControl={false}
          scrollWheelZoom={true}
          whenCreated={setMapReference}
        >
          {cordinateAndZoom?.cordinates && cordinateAndZoom.zoom ? (
            <ChangeView center={cordinateAndZoom.cordinates} zoom={cordinateAndZoom.zoom} />
          ) : (
            ''
          )}

          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Satellite">
              <TileLayer
                url={myConstClass.SATELLITE_TILE_LAYER_URL}
                options={{ tileSize: 256 }}
                attribution={`© <a href='https://www.mapbox.com/about/maps/' target='_blank'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a> ©<a href="https://www.maxar.com/" target='_blank'> Maxar</a>`}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Streets">
              <TileLayer
                url={myConstClass.TITLE_LAYER_URL}
                options={{ tileSize: 256 }}
                attribution={`© <a href='https://www.mapbox.com/about/maps/' target='_blank'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a>`}
              />
            </LayersControl.BaseLayer>
          </LayersControl>

          <ZoomControl position="topright" className="map-zoom-control" />

          <LayersControl position="topleft">
            <div className="logoContainer1">
              <Button
                // className="map-preferences-button farm-map-prefer-st"
                className="map-preferences-button "
                onClick={handleMapModel}
              >
                <img src={mapPreferenceIcon} alt="map preference" width={15} />
              </Button>
            </div>
          </LayersControl>

          {/* {kml ? <ReactLeafletKml kml={kml} /> : ''} */}

          <DrawShape
            handleSelectedRegion={handleSelectedRegion}
            isRemovePolygon={isRemovePolygon}
            setActiveTab={setActiveTab}
            isHideFarmCollapse={isHideFarmCollapse}
            farmsList={projectDetails?.farmsList}
            changePolygonOptions={changePolygonOptions}
            farmOptions={farmOptions}
            drawFarmRef={drawFarmRef}
            layerControl={layerControl?.firstLayer}
          />

          <GeoJSON ref={drawFarmRef} style={styledPolygonOptions?.options} />

          <GeoJSON
            ref={mapPreferences['country'].layer}
            style={{ color: mapPreferences['country'].color, fillColor: 'none', weight: 1 }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['country'].country, 'country', feat, layer)
            }
          />

          <GeoJSON
            ref={mapPreferences['states_or_provinces'].layer}
            style={{
              color: mapPreferences['states_or_provinces'].color,
              fillColor: '#0000',
              weight: 1.6,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['states_or_provinces'].country,
                'states_or_provinces',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={DEFAULT_COUNTRY + MAP_PREFERENCES_lABELS.DISTRICTS}
            ref={mapPreferences['districts_or_cities'].layer}
            style={{
              color: mapPreferences['districts_or_cities'].color,
              weight: 1,
              // fillColor: 'none',
              fillColor: '#0000',
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['districts_or_cities'].country,
                'districts_or_cities',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            ref={mapPreferences['meteorological_locations'].layer}
            // style={{
            //   color: mapPreferences['meteorological_locations'].color,
            //   fillColor: 'none',
            //   weight: 1,
            // }}
            style={{
              color: mapPreferences['meteorological_locations'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['meteorological_locations'].country,
                'meteorological_locations',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={DEFAULT_COUNTRY?.name + MAP_PREFERENCES_lABELS.BASINS}
            ref={mapPreferences['basins'].layer}
            style={{
              color: mapPreferences['basins'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['basins'].country, 'basins', feat, layer)
            }
          />

          <GeoJSON
            ref={mapPreferences['rivers_or_reservoirs'].layer}
            style={{
              color: mapPreferences['rivers_or_reservoirs'].color,
              fillColor: 'none',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['rivers_or_reservoirs'].country,
                'rivers_or_reservoirs',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            ref={mapPreferences['talukas'].layer}
            style={{
              color: mapPreferences['talukas'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['talukas'].country, 'talukas', feat, layer)
            }
          />

          <LayersControl position="bottomleft">
            {mapPreferRefs?.current?.map((mapLayerRef, index) => {
              if (!_.isEmpty(mapLayerRef)) {
                return (
                  <>
                    <Overlay>
                      <LayerGroup ref={mapLayerRef.current.layer}>
                        <TileLayer
                          // attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
                          url=""
                        />
                      </LayerGroup>
                    </Overlay>
                  </>
                );
              }
            })}
          </LayersControl>

          {selectedMapPreferLegendData?.length ? (
            <>
              <LayersControl position="bottomright">
                {addLegend(selectedMapPreferLegendData)}
              </LayersControl>
            </>
          ) : (
            ''
          )}
        </MapContainer>
      </>
    );
  };
  // toggle function
  const toggle = (index, trackerList) => {
    if (trackerList?.length) {
      let list = JSON.parse(JSON.stringify(trackerList));
      let updatedList = list?.map((item, i) =>
        i === index ? { ...item, isOpenAction: !item.isOpenAction } : item,
      );
      // setFarmTrackerList(updatedList);
      setProjectDetails((prev) => {
        return {
          ...prev,
          farmsList: updatedList,
        };
      });
    }
  };

  const handleOpen = (value) => {
    setOpenFullScreen(value);
    setIsRightCollapseOpen(false);
    // handleCloseRiskRightSideBar();
  };

  const handleOpenFullScreen = (value) => {
    setModalShow(value);

    // setOpenFullScreen(value)
    // setModalShow(value)
    handleOpen(value);
  };

  useEffect(() => {
    if (modalShow === true) {
      // handleOpenAnalyzeRiskSplitter(false)
      setAnalyzeRiskModalShow(false);
      setModalShow(true);
    }
    if (analyzeRiskmodalShow === true) {
      // handleOpenCurrentWeatherSplitter(false)
      setModalShow(false);
      setAnalyzeRiskModalShow(true);
    }
  }, [modalShow, analyzeRiskmodalShow]);

  useEffect(() => {
    if (rightBarActiveTab) {
      setAnalyzeRiskModalShow(false);
      setModalShow(false);
    }
  }, [rightBarActiveTab]);

  const handleOpenCurrentWeatherSplitter = (value) => {
    setModalShow(value);
    setAnalyzeRiskModalShow(false);
    setOpenFullScreen(false);
  };
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    let defaultSelectedAggregation = TEMPORAL_AGGREGATION?.find((item) => item.name === 'Mean');
    if (!_.isEmpty(defaultSelectedAggregation)) {
      setTemporalAggregation(defaultSelectedAggregation);
    }
    if (autoCompleteRef && !_.isEmpty(autoCompleteRef?.current)) {
      autoCompleteRef.current.autocomplete = false;
    }
  }, []);

  const handleOpenAnalyzeRiskSplitter = (value) => {
    setAnalyzeRiskModalShow(value);
  };
  const handleTemporalAggregation = (selectedOption) => {
    setTemporalAggregation(selectedOption);
  };
  const handleInitialDate = (type) => {
    return moment(initialSelectedDate?.map[type]).format(DATE_FORMAT);
  };
  const handleOnChangeDates = (action, e) => {
    if (action === 'startDate') {
      setSelectedDate((prev) => {
        return {
          // ...prev,
          map: {
            ...initialSelectedDate.map,
            startDate: e.target.value,
          },
          graph: {
            ...initialSelectedDate.graph,
            startDate: e.target.value,
          },
        };
      });
      setUpdatedSelectedDate((prevdate) => {
        return {
          ...prevdate,
          startDate: e.target.value,
        };
      });
    } else if (action === 'endDate') {
      setSelectedDate((prev) => {
        return {
          ...prev,
          map: {
            ...initialSelectedDate.map,
            endDate: e.target.value,
          },
          graph: {
            ...initialSelectedDate.graph,
            endDate: e.target.value,
          },
        };
      });
      setUpdatedSelectedDate((prevdate) => {
        return {
          ...prevdate,
          endDate: e.target.value,
        };
      });
    }
  };

  const handleIndicatorsValue = (value) => {
    setIndicatorsValue(value);
  };
  const handleViewRiskGraph = ({ isDefaultParam, requestObj }) => {
    if (selectedIndicatorParam?.length) {
      setSelectedIndicatorRiskParam(selectedIndicatorParam);
      selectedIndicatorParam?.map((param) => {
        if (param.value === 'IDSI' || param.value === 'Risk Score') {
          setShowLoadingText(true);
        }
      });
    }
    setIsLoading(true);
    // (true);
    setErrorData({});
    const requestObj1 = {
      country_name: DEFAULT_COUNTRY.name,
      state_name: projectIndividualData?.projectData?.province?.label,
      district_name: projectIndividualData?.projectData?.district?.label,
      sub_district_name: getByIdFarmInfo?.farm?.[0]?.name === rightBarActiveTab || rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? getByIdFarmInfo?.farm?.[0]?.sub_district_name : getByIdCompareFarmInfo?.farm?.[0]?.sub_district_name,
      spatial_aggregation: 'mean',
      start_date: initialSelectedDate?.map?.startDate,
      end_date: initialSelectedDate?.map?.endDate,
    };
    
    //for SPI obj
    let spiMonth = new Date(initialSelectedDate?.map?.endDate)?.getMonth() + 1;
    const spiRequestObj = {
      country_name: DEFAULT_COUNTRY.name,
      state_name: projectIndividualData?.projectData?.province?.label,
      district_name: projectIndividualData?.projectData?.district?.label,
      sub_district_name: getByIdFarmInfo?.farm?.[0]?.name === rightBarActiveTab|| rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? getByIdFarmInfo?.farm?.[0]?.sub_district_name : getByIdCompareFarmInfo?.farm?.[0]?.sub_district_name,
      spatial_aggregation: 'mean',
      spi_year: new Date(initialSelectedDate?.map?.endDate)?.getFullYear()?.toString(),
      spi_month: spiMonth?.toString(),
      spi_month_frequency: '03',
    };
    if(getByIdFarmInfo?.farm?.[0]?.name === rightBarActiveTab || rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? getByIdFarmInfo?.farm?.[0]?.block_name : getByIdCompareFarmInfo?.farm?.[0]?.block_name){
      requestObj1.block_name = getByIdFarmInfo?.farm?.[0]?.name === rightBarActiveTab || rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? getByIdFarmInfo?.farm?.[0]?.block_name : getByIdCompareFarmInfo?.farm?.[0]?.block_name;
      spiRequestObj.block_name = getByIdFarmInfo?.farm?.[0]?.name === rightBarActiveTab || rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? getByIdFarmInfo?.farm?.[0]?.block_name : getByIdCompareFarmInfo?.farm?.[0]?.block_name;
    }
    //for SPI obj
    //for risk score obj
    let geometry;
    if (rightBarActiveTab === getByIdFarmInfo?.farm?.[0]?.name || rightBarActiveTab ===t('RISK_ANALYTICS.FARM_1')) {
      geometry = polygonRegionValues?.length ? polygonRegionValues : [];
    } else {
      geometry = comparePolygonRegionValues?.length ? comparePolygonRegionValues : [];
    }
    const riskRequestObj = {
      geometry: geometry,
      spatial_aggregation: 'mean',
      start_date: initialSelectedDate?.map?.startDate,
      end_date: initialSelectedDate?.map?.endDate,
    };
    let API_URL = [];
    if (!isDefaultParam) {
      indicatorsCategoryList?.map((item) => {
        if (item?.selectedIndicators?.length) {
          item?.selectedIndicators?.map((data) => {
            let config = { ...data };
            if (data.label === 'SPI') {
              API_URL.push(
                AXIOS_INSTANCE.post(
                  `${API_BASE_URL}/${data?.api_url}/timeseries`,
                  spiRequestObj,
                  config,
                ),
              );
            } else if (data.label === 'Risk Score') {
              API_URL.push(
                AXIOS_INSTANCE.post(
                  `${API_BASE_URL}/${data?.api_url}/timeseries`,
                  riskRequestObj,
                  config,
                ),
              );
            } else {
              API_URL.push(
                AXIOS_INSTANCE.post(
                  `${API_BASE_URL}/${data?.api_url}/timeseries`,
                  requestObj1,
                  config,
                ),
              );
            }
          });
        }
      });
    } else {
      let config = {
        api_url: 'crop-monitor/sentinel2/ndvi',
        id: '75afba5d-fc08-4ed0-81b5-a604639923d3',
        label: 'NDVI',
        mode: 'lines+markers',
        name: 'NDVI',
        slug: 'ndvi',
        type: 'scatter',
        value: 'NDVI',
        visible: 'legendonly',
      };
      API_URL.push(AXIOS_INSTANCE.post(`${requestObj.api_url}/timeseries`, requestObj, config));
    }
    if (!_.isEmpty(API_URL)) {
      let errorMsg = [];
      Promise.allSettled(API_URL).then((response) => {
        setIsLoading(false);
        let updatedResponse = [...response];
        let finalResponse = updatedResponse?.map((item) => {
          if (item?.status === 'fulfilled') {
            setShowLoadingText(false);
            return { ...item };
          } else if (item?.status === 'rejected') {
            let errorResponse = item?.reason?.response;
            if (errorResponse?.status === 400) {
              if (errorResponse?.data?.code === 400) {
                errorMsg.push(errorResponse?.data?.message);
              }
              // showError(errorResponse?.data?.message)
            }
          }
        });
        let x = finalResponse.filter((i) => i !== undefined);
        if(finalResponse?.length === 1){
          if(finalResponse[0]?.value?.data?.code === 200){
            let result= finalResponse[0]?.value?.data?.result
            if(!result?.graph_data?.data?.length) {
              x=[];
              indicatorsCategoryList?.map(item => {
                if(item?.selectedIndicators?.length){
                  item?.selectedIndicators?.map(indicator => {
                      if(indicator?.label){
                        if(!errorMsg?.length)
                        errorMsg.push(`${indicator?.label} dataset is not available for selected date range or location`);
                      }
                  })
                }
              })
            }
          }
        }
        let tempData = {};
        tempData.graph_data = x?.map((item) => {
          let graph_data = item?.value?.data?.result?.graph_data;
          graph_data.layerType = item?.value?.config?.value;
          return graph_data;
        });
        if (errorMsg?.length) {
          // setErrorData({ showError: true, data: errorMsg[0]});
          showError(errorMsg[0]);
        }
        setUpdateGraphValues(JSON.parse(JSON.stringify(tempData)));
      });
    }
  };

  const handleOpenCropProductionModal = () => {
    setIsOpenCropProductionModal(!isOpenCropProductionModal);
    if (analyzeRiskmodalShow) setAnalyzeRiskModalShow(false);
    if (openFullScreen) setOpenFullScreen(false);
    if (modalShow) setModalShow(false);
    indicatorsCategoryList.map((item) => (item.selectedIndicators = []));
    if (openFullScreen) {
      setIsRightCollapseOpen(true);
    }
    setSelectedIndicatorParam([]);
    setSelectedIndicatorRiskParam([]);
  };

  useEffect(() => {
    let arr = [];
    if (RiskAnalytics?.indicesData) {
      let result = '';
      // let config = Location?.stateList?.config;
      if (RiskAnalytics?.indicesData?.data)
        if (RiskAnalytics?.indicesData?.data?.result) {
          result = RiskAnalytics?.indicesData?.data?.result
            ? RiskAnalytics?.indicesData?.data?.result
            : [];
          if (result) {
            result?.map((i) => {
              return arr.push({
                id: i.id,
                name: i.name,
                value: i.name,
                label: i.name,
                api_url: i.api_url,
                slug: i.slug,
              });
            });
          }

          const requestObj = {
            country_name: DEFAULT_COUNTRY.name,
            state_name: projectIndividualData?.projectData?.province?.label,
            district_name: projectIndividualData?.projectData?.district?.label,
            sub_district_name: getByIdFarmInfo?.farm?.[0]?.name === rightBarActiveTab|| rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? getByIdFarmInfo?.farm?.[0]?.sub_district_name : getByIdCompareFarmInfo?.farm?.[0]?.sub_district_name,
            spatial_aggregation: 'mean',
            start_date: moment(initialSelectedDate?.map['startDate']).format(DATE_FORMAT),
            end_date: moment(initialSelectedDate?.map['endDate']).format(DATE_FORMAT),
          };
          if(getByIdFarmInfo?.farm?.[0]?.name === rightBarActiveTab|| rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? getByIdFarmInfo?.farm?.[0]?.block_name : getByIdCompareFarmInfo?.farm?.[0]?.block_name)
          requestObj.block_name =getByIdFarmInfo?.farm?.[0]?.name === rightBarActiveTab|| rightBarActiveTab === t('RISK_ANALYTICS.FARM_1') ? getByIdFarmInfo?.farm?.[0]?.block_name : getByIdCompareFarmInfo?.farm?.[0]?.block_name
          if (arr?.length) {
            let indicatorsData = [...indicatorsCategoryList];
            indicatorsData?.map((item) => {
              let itemData = _.cloneDeep(item);
              let slugList = RISK_ANALYTICS_GRAPH_INDICATORS_CATEGORY?.find(
                (i) => i.id === itemData?.id && i.id === 'crop-indicators',
              )?.slug_list;
              if (slugList?.length) {
                arr?.map((m) => {
                  if (slugList[0] === m?.slug) {
                    requestObj.api_url = `${API_BASE_URL}/${m?.api_url}`;
                  }
                });
              }
            });
          }
          handleViewRiskGraph({ isDefaultParam: true, requestObj });
        }
    }
  }, [
    RiskAnalytics.indicesData,
    RiskAnalytics.indicesDataError,
    RiskAnalytics.indicesDataRequesting,
  ]);

  useEffect(() => {
    if (RiskAnalytics?.indicesNewDataRequesting === true) {
      // setNewDataRequest(true);
      setIsLoading(true);
    } else if (RiskAnalytics?.indicesNewDataRequesting === false) {
      // setNewDataRequest(false);
      setIsLoading(false);
    }
  }, [RiskAnalytics?.indicesNewDataRequesting]);

  useEffect(() => {
    if (RiskAnalytics?.indicesNewDataRequesting) {
      setNewRiskGrapgData([]);
    }
  }, [RiskAnalytics?.indicesNewDataRequesting]);

  useEffect(() => {
    if (RiskAnalytics?.indicesNewDataError) {
      let errorData = RiskAnalytics?.indicesNewDataError?.data;
      let data = errorData?.response?.data;

      if (data?.message && data?.code === 400) {
        showError(data?.message);
      }
    }
  }, [RiskAnalytics?.indicesNewDataError]);

  useEffect(() => {
    if (RiskAnalytics?.indicesNewData) {
      setNewRiskGrapgData(RiskAnalytics?.indicesNewData);
    }
  }, [RiskAnalytics?.indicesNewData]);

  useEffect(() => {
    setCompareFarm('');
    setIsApplyLayer(false);
  }, [isOpenFarmCompareCollapse]);

  const handleChartDisplay = () => {
    setShowCharts(!showCharts);
  };

  const handleOpenModal = (value) => {
    setModalOpen(value);
  };

  const handleOpenCarousalModal = (value) => {
    setCarousalModalOpen(value);
  };

  const handleApplyFarm = () => {
    setShowRiskScoreError(false);
    if (isOpenFarmCompareCollapse) {
      if (!_.isEmpty(selectedFarm) && !_.isEmpty(compareFarm)) {
        setRiskPercentage(0);
        setRiskLevel('');
        setCompareRiskPercentage(0);
        setCompareRiskLevel('');
        setIsLoading(true);
        setIsRightCollapseOpen(true);
        removeDrawnFarm(drawFarmRef);
        removeDrawnFarm(compareDrawFarmRef);
        if (!_.isEmpty(layerControl?.firstLayer)) {
          map?.removeControl(layerControl?.firstLayer);
        }
        if (!_.isEmpty(layerControl?.secondLayer)) {
          map?.removeControl(layerControl?.secondLayer);
        }

        if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
        setkml({ document: '', layer: {} });
        if (!_.isEmpty(compareKml?.layer)) map?.removeLayer(compareKml?.layer);
        setCompareKml({ document: '', layer: {} });

        let initialRequestResponse, finalRequestResponse;
        initialRequestResponse = AXIOS_INSTANCE.get(
          `${FARM_TRACKER_URL}/getById?id=${selectedFarm?.id}`,
        );

        finalRequestResponse = AXIOS_INSTANCE.get(
          `${FARM_TRACKER_URL}/getById?id=${compareFarm?.id}`,
        );

        Promise.all([initialRequestResponse, finalRequestResponse])
          .then((responses) => {
            setIsLoading(false);
            setLayersData(responses);
            let polygonList = []
            responses?.map((item, index) => {
              if (index === 0) {
                const { result } = responses[index]?.data;
                let geojson = L.geoJSON(result?.farm?.[0]?.draw_farm)
                polygonList.push(geojson)
                setGetByIdFarmInfo(result);
              }
              if (index === 1) {
                const { result } = responses[index]?.data;
                let geojson = L.geoJSON(result?.farm?.[0]?.draw_farm)
                polygonList.push(geojson)
                setGetByIdCompareFarmInfo(result);
              }
            });
            if(polygonList?.length){
              var group = L.featureGroup(polygonList)
              map?.fitBounds(group?.getBounds());
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
    } else if (!_.isEmpty(selectedFarm)) {
      setRightBarActiveTab(t('RISK_ANALYTICS.FARM_1'));
      if (selectedFarm) {
        setStyledPolygonOptions({ options: {} });
        handleOpenCurrentWeatherSplitter(false);
        setFarmOnClickState(!farmOnClickState);
        setForecastRightSideBar([]);
        setCompareForecastRightSideBar([]);
        setRiskScoreInfo([]);
        setRiskPercentage(0);
        setRiskLevel('');
        setCompareRiskPercentage(0);
        setCompareRiskLevel('');
        setCompareRiskScoreInfo([]);
        setChartSeriesData([]);
        setCompareChartSeriesData([]);
        setIsRightCollapseOpen(true);
        setFromListing(true);
        removeDrawnFarm(drawFarmRef);
        removeDrawnFarm(compareDrawFarmRef);
        if (!_.isEmpty(layerControl?.firstLayer)) {
          map?.removeControl(layerControl?.firstLayer);
        }
        if (!_.isEmpty(layerControl?.secondLayer)) {
          map?.removeControl(layerControl?.secondLayer);
        }
        if (!_.isEmpty(kml?.layer)) map?.removeLayer(kml?.layer);
        setkml({ document: '', layer: {} });
        if (!_.isEmpty(compareKml?.layer)) map?.removeLayer(compareKml?.layer);
        setCompareKml({ document: '', layer: {} });

        setFarmOptions(FARM_TRACKER_OPTIONS);
        setChangePolygonOptions(FARM_TRACKER_OPTIONS);
        dispatch(getFarmDetailsByIdRequest({ id: selectedFarm?.id }));
      }
    } else {
      handleResetState();
    }
  };

  const handleRightBarActiveTab = (tab) => {
    setRightBarActiveTab(tab);
  };

  const handleOpenFarmCollapse = (isOpen) => {
    setIsOpenFarmCompareCollapse(isOpen);
  };

  return (
    <>
      {carousalModalOpen ? (
        <CarousalModal
          modalOpen={carousalModalOpen}
          setModalOpen={setCarousalModalOpen}
          selectedGeotaggedImage={selectedGeotaggedImage}
          getByIdFarmData={getByIdFarmInfo}
        />
      ) : (
        ''
      )}
      <ShareInformationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        graphScreenshotImage={graphScreenshotImage}
        setGraphScreenshotImage={setGraphScreenshotImage}
        selectedIndicatorRiskParam={selectedIndicatorRiskParam}
      />

      {isOpenCropProductionModal ? (
        <CropProductionModal
          modalOpen={isOpenCropProductionModal}
          setModalOpen={handleOpenCropProductionModal}
          createFarmData={createFarmData}
          provinceList={provinceList}
          districtsList={districtList}
          countryList={countryList}
          defaultCountry={defaultCountry}
          projectDetails={projectDetails}
        />
      ) : (
        ''
      )}

      <Row className="bg-white farm-title-row m-0 ">
        <Col className="farm-title-style" lg="10" md="10" sm="10">
          <span className="cursor-ponter">
            <HiOutlineArrowSmLeft className="farms-back-icon" onClick={handleGoBack} />
            <span className="mx-2">Back</span>
          </span>
        </Col>
        <Col
          className="float-right"
          lg="2"
          md="2"
          sm="2"
          style={{ display: 'flex', justifyContent: 'end', marginBottom: '10px' }}
        >
          <Button
            type="button"
            className="crop-production-st"
            onClick={handleOpenCropProductionModal}
            style={{ marginRight: '5px' }}
          >
            Crop Production{' '}
          </Button>
        </Col>
      </Row>

      <div className="w-100 ">
        {isLoading ? <Loader showText={showLoadingText} /> : ''}
        {/* {newDataRquest ? <Loader  /> : ''} */}
        <Row className="">
          <Col
            className={
              isHideBar ? `farmSideBar-collapse col-lg-2` : `farmSideBar1 farmSideBar col-lg-2`
            }
            lg={2}
          >
            <Filter
              isHideBar={isHideBar}
              activeTab={activeTab}
              isOpenFarmListingCollapse={isOpenFarmListingCollapse}
              isOpenLocationTab={isOpenLocationTab}
              locationList={LocationList}
              filterProject={filterProject}
              filterFarm={filterFarm}
              initialValues={createFarmData}
              cordinateAndZoom={cordinateAndZoom}
              farmList={farmsList?.farmsList}
              compareFarmsList={compareFarmsList?.farmsList}
              farmOptions={farmOptions}
              changePolygonOptions={changePolygonOptions}
              isShowListing={isShowListing}
              uploadedFiles={uploadedFiles}
              showFarmInputFields={showFarmInputFields}
              isHideFarmCollapse={isHideFarmCollapse}
              createFarmData={createFarmData}
              isCreateFarm={isCreateFarm}
              isUploadFarm={isUploadFarm}
              farmAttributes={farmAttributes}
              farmTitle={farmTitle}
              farmDescription={farmDescription}
              attributeLimit={attributeLimit}
              imageFile={imageFile}
              isFarmLoading={FarmTracker.addFarmTrackerRequesting}
              farmListState={farmListState}
              projectDetails={projectDetails}
              getByIdFarmData={getByIdFarmInfo}
              farmListingContainer={farmListingContainer}
              setIsOpenLocationTab={setIsOpenLocationTab}
              handleCordinates={handleCordinates}
              handleResetCollapseFarm={handleResetCollapseFarm}
              provinceList={provinceList}
              districtList={districtList}
              subDistrictList={subDistrictList}
              blockList={blockList}
              selectedFarmsList={selectedFarmsList}
              setSelectedFarmsList={setSelectedFarmsList}
              compareFarm={compareFarm}
              selectedFarm={selectedFarm}
              setCompareFarm={setCompareFarm}
              setSelectedFarm={setSelectedFarm}
              setIsOpenFarmListingCollapse={setIsOpenFarmListingCollapse}
              setIsOpenFarmCompareCollapse={handleOpenFarmCollapse}
              isOpenFarmCompareCollapse={isOpenFarmCompareCollapse}
              kml={kml}
              handleApplyFarm={handleApplyFarm}
              setIsApplyLayer={setIsApplyLayer}
              isOpenDeleteFarmModal={isOpenDeleteFarmModal}
              setIsShowListing={setIsShowListing}
              handleDeleteFarm={handleDeleteFarm}
              setIsOpenDeleteFarmModal={setIsOpenDeleteFarmModal}
              setBar={setBar}
              handleProjectUpdate={handleProjectUpdate}
              setActiveTab={setActiveTab}
              handleToggle={handleToggle}
              //
              getFarmListContainer={getFarmListContainer}
              getFarmData={getFarmData}
              getCompareFarmData={getCompareFarmData}
              toggle={toggle}
              setCordinateAndZoom={setCordinateAndZoom}
              selectedFarmIndex={selectedFarmIndex}
              setSelectedFarmIndex={setSelectedFarmIndex}
              setRiskScoreInfo={setRiskScoreInfo}
              setCompareRiskScoreInfo={setCompareRiskScoreInfo}
              setFarmId={setFarmId}
              setIsRightCollapseOpen={setIsRightCollapseOpen}
              handleRemovePolygonOnProjectChange={handleRemovePolygonOnProjectChange}
            />
          </Col>
          <Col
            lg={
              !isHideBar && isRightCollapseOpen
                ? 8
                : !isHideBar && !isRightCollapseOpen
                ? 10
                : isHideBar && isHideFarmCollapse
                ? 12
                : isHideBar && isRightCollapseOpen
                ? 10
                : isHideBar && !isRightCollapseOpen
                ? 12
                : 10
            }
            // className="right-map farm-map-position"
            className={
              openFullScreen & isHideBar
                ? 'right-map farm-map-position'
                : openFullScreen & !isHideBar
                ? 'right-map farm-map-position10'
                : !isHideBar && isRightCollapseOpen
                ? 'right-map farm-map-position8'
                : !isHideBar && !isRightCollapseOpen
                ? 'right-map farm-map-position10'
                : isHideBar && isHideFarmCollapse
                ? 'right-map farm-map-position10'
                : isHideBar
                ? 'right-map farm-map-position1'
                : 'right-map farm-map-position'
            }
          >
            <div
              style={{
                height: '75vh',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '0px',
              }}
            >
              <>
                <SplitterLayout
                  ref={splitterRef}
                  primaryIndex={0}
                  vertical
                  percentage={true}
                  primaryMinSize={10}
                  secondaryInitialSize={90}
                >
                  <div style={{ height: '100%' }}>{renderMapContainer()}</div>
                  {modalShow && (
                    <>
                      {openFullScreen ? (
                        <CurrentWatherForecast
                          forecastRightSideBar={
                            rightBarActiveTab === t('RISK_ANALYTICS.FARM_1')
                              ? forecastRightSideBar
                              : compareForecastRightSideBar
                          }
                          handleOpenCurrentWeatherSplitter={handleOpenCurrentWeatherSplitter}
                          handleOpen={handleOpen}
                          openFullScreen={openFullScreen}
                          marginTop={'50%'}
                          setIsRightCollapseOpen={setIsRightCollapseOpen}
                          openWeatherChartSeriesData={
                            rightBarActiveTab === t('RISK_ANALYTICS.FARM_1')
                              ? chartSeriesData
                              : compareChartSeriesData
                          }
                          dailyChartSeriesData={
                            rightBarActiveTab === t('RISK_ANALYTICS.FARM_1')
                              ? dailyChartSeriesData
                              : compareDailyChartSeriesData
                          }
                        />
                      ) : (
                        <CurrentWatherForecast
                          forecastRightSideBar={
                            rightBarActiveTab === t('RISK_ANALYTICS.FARM_1')
                              ? forecastRightSideBar
                              : compareForecastRightSideBar
                          }
                          handleOpenCurrentWeatherSplitter={handleOpenCurrentWeatherSplitter}
                          handleOpen={handleOpen}
                          openFullScreen={openFullScreen}
                          isHideFarmCollapse={isHideFarmCollapse}
                          isHideBar={isHideBar}
                          getByIdFarmInfo={
                            rightBarActiveTab === t('RISK_ANALYTICS.FARM_1')
                              ? getByIdFarmInfo
                              : getByIdCompareFarmInfo
                          }
                          openWeatherChartSeriesData={
                            rightBarActiveTab === t('RISK_ANALYTICS.FARM_1')
                              ? chartSeriesData
                              : compareChartSeriesData
                          }
                          dailyChartSeriesData={
                            rightBarActiveTab === t('RISK_ANALYTICS.FARM_1')
                              ? dailyChartSeriesData
                              : compareDailyChartSeriesData
                          }
                        />
                      )}
                    </>
                  )}
                  {analyzeRiskmodalShow && (
                    <>
                      {openFullScreen ? (
                        <Analytics
                          forecastRightSideBar={
                            rightBarActiveTab === t('RISK_ANALYTICS.FARM_1')
                              ? forecastRightSideBar
                              : compareForecastRightSideBar
                          }
                          handleOpenAnalyzeRiskSplitter={handleOpenAnalyzeRiskSplitter}
                          handleIndicatorsValue={handleIndicatorsValue}
                          indicatorsValue={indicatorsvalue}
                          handleInitialDate={handleInitialDate}
                          handleOnChangeDates={handleOnChangeDates}
                          handleTemporalAggregation={handleTemporalAggregation}
                          rightBarActiveTab={rightBarActiveTab}
                          initialSelectedDate={initialSelectedDate}
                          isShareInfomodalOpen={modalOpen}
                          updatedGraphValues={updatedGraphValues}
                          isApplyLayer={isApplyLayer}
                          setUpdateGraphValues={setUpdateGraphValues}
                          TEMPORAL_AGGREGATION={TEMPORAL_AGGREGATION}
                          temporalAggregation={temporalAggregation}
                          indicatorsCategoryList={indicatorsCategoryList}
                          setGraphScreenshotImage={setGraphScreenshotImage}
                          handleViewRiskGraph={handleViewRiskGraph}
                          setIndicatorsCategoryList={setIndicatorsCategoryList}
                          setSelectedIndicatorParam={setSelectedIndicatorParam}
                          selectedIndicatorParam={selectedIndicatorParam}
                          newRiskGraphData={newRiskGraphData}
                          renderCharts={handleChartDisplay}
                          handleOpenCurrentWeatherSplitter={handleOpenCurrentWeatherSplitter}
                          selectedIndicatorRiskParam={selectedIndicatorRiskParam}
                          errorData={errorData}
                          setErrorData={setErrorData}
                          openFullScreen={openFullScreen}
                          setIsRightCollapseOpen={setIsRightCollapseOpen}
                          selectedFrequency={selectedFrequency}
                          selectedMonth={selectedMonth}
                          setSelectedFrequency={setSelectedFrequency}
                          setSelectedMonth={setSelectedMonth}
                          spiSelectedDate={spiSelectedDate}
                          setSpiSelectedDate={setSpiSelectedDate}
                          handleOpen={handleOpen}
                          handleOpenModal={handleOpenModal}
                          setFarmId={setFarmId}
                          setSelectedDate={setSelectedDate}
                        />
                      ) : (
                        <Analytics
                          forecastRightSideBar={
                            rightBarActiveTab === t('RISK_ANALYTICS.FARM_1')
                              ? forecastRightSideBar
                              : compareForecastRightSideBar
                          }
                          handleOpenAnalyzeRiskSplitter={handleOpenAnalyzeRiskSplitter}
                          handleIndicatorsValue={handleIndicatorsValue}
                          indicatorsValue={indicatorsvalue}
                          rightBarActiveTab={rightBarActiveTab}
                          handleInitialDate={handleInitialDate}
                          handleOnChangeDates={handleOnChangeDates}
                          handleTemporalAggregation={handleTemporalAggregation}
                          setGraphScreenshotImage={setGraphScreenshotImage}
                          isShareInfomodalOpen={modalOpen}
                          updatedGraphValues={updatedGraphValues}
                          setUpdateGraphValues={setUpdateGraphValues}
                          initialSelectedDate={initialSelectedDate}
                          isApplyLayer={isApplyLayer}
                          TEMPORAL_AGGREGATION={TEMPORAL_AGGREGATION}
                          temporalAggregation={temporalAggregation}
                          indicatorsCategoryList={indicatorsCategoryList}
                          setIndicatorsCategoryList={setIndicatorsCategoryList}
                          setSelectedIndicatorParam={setSelectedIndicatorParam}
                          selectedIndicatorParam={selectedIndicatorParam}
                          handleViewRiskGraph={handleViewRiskGraph}
                          newRiskGraphData={newRiskGraphData}
                          renderCharts={handleChartDisplay}
                          handleOpenCurrentWeatherSplitter={handleOpenCurrentWeatherSplitter}
                          isHideBar={isHideBar}
                          handleOpenModal={handleOpenModal}
                          isHideFarmCollapse={isHideFarmCollapse}
                          selectedIndicatorRiskParam={selectedIndicatorRiskParam}
                          errorData={errorData}
                          setErrorData={setErrorData}
                          openFullScreen={openFullScreen}
                          handleOpen={handleOpen}
                          selectedFrequency={selectedFrequency}
                          selectedMonth={selectedMonth}
                          setSelectedFrequency={setSelectedFrequency}
                          setSelectedMonth={setSelectedMonth}
                          spiSelectedDate={spiSelectedDate}
                          setSpiSelectedDate={setSpiSelectedDate}
                          setSelectedDate={setSelectedDate}
                        />
                      )}
                    </>
                  )}
                </SplitterLayout>
              </>
            </div>
          </Col>
          {isRightCollapseOpen && (
            // !isHideBar &&
            <Col
              lg={2}
              style={{
                position: 'relative',
                top: '25.15vh',
                height: '75.4vh',
                marginLeft: '0px',
                paddingLeft: '0px',
              }}
            >
              <RiskAnalyticsDetailsTab
                handleCloseRiskRightSideBar={handleCloseRiskRightSideBar}
                handleOpenCurrentWeatherSplitter={handleOpenCurrentWeatherSplitter}
                toggle={handleRightBarActiveTab}
                rightBarActiveTab={rightBarActiveTab}
                farmTrackerList={projectDetails?.farmsList}
                getFarmData={getFarmData}
                isOpenFarmCompareCollapse={isOpenFarmCompareCollapse}
                getByIdFarmData={getByIdFarmInfo}
                getByIdCompareFarmInfo={getByIdCompareFarmInfo}
                forecastRightSideBarInfo={forecastRightSideBar}
                compareForecastRightSideBarInfo={compareForecastRightSideBar}
                riskScoreAnalyticsInfo={
                  rightBarActiveTab === t('RISK_ANALYTICS.FARM_1')
                    ? riskScoreInfo
                    : compareriskScoreInfo
                }
                selectedFarmsList={selectedFarmsList}
                compareFarm={compareFarm}
                selectedFarm={selectedFarm}
                isApplyLayer={isApplyLayer}
                handleOpenAnalyzeRiskSplitter={handleOpenAnalyzeRiskSplitter}
                projectDetails={projectDetails}
                handleOpenModal={handleOpenModal}
                handleOpen={handleOpen}
                handleOpenFullScreen={handleOpenFullScreen}
                handleOpenCarousalModal={handleOpenCarousalModal}
                setSelectedGeotaggedImage={setSelectedGeotaggedImage}
                openWeatherChartSeriesData={
                  rightBarActiveTab === t('RISK_ANALYTICS.FARM_1')
                    ? dailyChartSeriesData
                    : compareDailyChartSeriesData
                }
                geoTaggedImages={geoTaggedImages}
                setGeoTaggedImages={setGeoTaggedImages}
                riskPercentage={riskPercentage}
                compareRiskPercentage={compareRiskPercentage}
                compareRiskLevel={compareRiskLevel}
                riskLevel={riskLevel}
              />
            </Col>
          )}
        </Row>
        {getBoundariesData?.length ? (
          <MapPreferences
            isToggle={isOpenMapModel}
            handleToggle={handleMapModel}
            loc={selectedLocation?.country?.name}
            regionId={selectedRegionId}
            isMapPerferGeoJsonLoading={mapPreferenceGeoJsonloading}
            getMapPreferencesData={getMapPreferencesData}
            getMapPreferencesGeojsonData={getMapPreferencesGeojsonData}
            geoJsonData={geoJsonData}
            mapPreferencesAllData={mapPreferencesData}
            fromContingencyPlan={false}
            countryData={createFarmData?.country}
            staticData={createFarmData?.sub_district}
            districtData={createFarmData?.district}
            cordinateAndZoom={cordinateAndZoom}
            fromDroughtMonitor={true}
            createFarmData={createFarmData}
          />
        ) : (
          ''
        )}
      </div>
      {/* </div> */}
    </>
  );
};

export default FarmTrackerMap;
