// @flow
import { all } from 'redux-saga/effects';
import HomeSaga from './home/homeSaga';
import AccessKeySaga from './accessKey/accessKeySaga';
import loginSaga from './login/loginSaga';
import locationSaga from './location/saga';
import registerSaga from './register/registerSaga';
import userListSaga from './manage-user/manageUserSaga';
import weatherSaga from './weather/weatherSaga';
import climateWeatherSaga from './climate-weather/climateWeatherSaga';
import staticResourceSaga from './staticResource/staticResourceSaga';
import DroughtMonitorSaga from './droughtMonitor/droughtMonitorSaga';
import mapPreferenceSaga from './map-preferences/mapPreferenceSaga';
import FarmTrackerSaga from './farmTracker/farmTrackerSaga';
import RiskAnalyticsSaga from './riskAnalytics/riskAnalyticsSaga';
import cropHealthMonitoringSaga from './cropHealthMonitoring/cropHealthMonitoringSaga';
import userGuideSaga from './userGuide/userGuideSaga';

import fieldDataListSaga from "./manageFieldData/manageFieldDataSaga"


export default function* rootSaga() {
  yield all([
    HomeSaga(),
    AccessKeySaga(),
    loginSaga(),
    registerSaga(),
    locationSaga(),
    userListSaga(),
    climateWeatherSaga(),
    weatherSaga(),
    staticResourceSaga(),
    DroughtMonitorSaga(),
    mapPreferenceSaga(),
    cropHealthMonitoringSaga(),
    FarmTrackerSaga(),
    userGuideSaga(),
    RiskAnalyticsSaga(),
    fieldDataListSaga(),
  ]);
}
