import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { change, Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import _ from 'lodash';
import {
  Card,
  FormGroup,
  Label,
  CardBody,
  Form,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  Col,
  Row,
  Collapse,
  Button,
} from 'reactstrap';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import {
  CROP_PRODUCTION_FILTER_TABS,
  DEFAULT_COUNTRY,
  LOCATION,
} from '../../../Components/constants';
import { useDispatch, useSelector } from 'react-redux';
import DeleteFarmModal from './delete_farm';
import {
  getStateByCountryRequest,
  getDistrictByStateRequest,
  getSubDistrictByDistrictRequest,
  getBlockBySubDistrictRequest,
  getMapPreferenceRequest,
  getAllByCriteriaProjectListRequest,
  getCountryRequest,
  getCWPCropsRequest,
  getCWPSeasonRequest,
  getCWPPractiseRequest,
  // getAllByCriteriaFarmListRequest,
  getAllByCriteriaProjectListSuccess,
  getFarmDetailsByIdSuccess
} from '../../../redux/actions';
import FarmIcon from '../../../assets/Images/FarmIcon.js';
import { t } from 'react-multi-lang';
import { renderReactSelectInputField } from '../../../Components/common/renderInputField';
import ProjectListing from './project-listing';
import ProjectIcon from '../../../assets/Images/projectIcon.svg';
import RiskLocationIcon from '../../../assets/Images/riskLocationIcon.svg';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import FarmListing from '../FarmCollapse/farm-listing';
import { BiMinus, BiPlus } from 'react-icons/bi';

function Filter(props) {
  const {
    activeTab,
    handleSubmit,
    cordinateAndZoom,
    selectedFarmsList,
    selectedFarm,
    compareFarm,
    setSelectedFarm,
    setCompareFarm,
    filterProject,
    filterFarm,
    farmList,
    compareFarmsList,
    isOpenFarmListingCollapse,
    setIsOpenFarmListingCollapse,
    setIsOpenFarmCompareCollapse,
    isOpenFarmCompareCollapse,
    setIsOpenLocationTab,
    isOpenLocationTab,
    setIsApplyLayer,
    setIsShowListing,
    isOpenDeleteFarmModal,
    handleProjectUpdate,
    setIsOpenDeleteFarmModal,
    //
    getFarmListContainer,
    provinceList,
    districtList,
    subDistrictList,
    blockList,
    initialValues,
    farmListState,
    projectDetails,
    // getFarmData,
    // handleCordinates,
    setSelectedFarmIndex,
    selectedFarmIndex,
    setCompareRiskScoreInfo,
    setRiskScoreInfo,
    setFarmId,
    setIsRightCollapseOpen,
    handleRemovePolygonOnProjectChange,
  } = props;
  const dispatch = useDispatch();
  // console.log(initialValues)

  const [selectedOption, setSelectedOption] = useState('');
  const [farmTrackerList, setFarmTrackerList] = useState([]);
  const [farmData, setFarmData] = useState('');
  const [projectList, setProjectList] = useState([]);

  const [project, setProject] = useState('');
  // const [selectedFarmIndex, setSelectedFarmIndex] = useState('');
  const [compareSelectedFarmIndex, setCompareSelectedFarmIndex] = useState('');

  // Added project list useEffect  --------
  const { Location, FarmTracker } = useSelector((state) => ({
    Location: state.Location,
    FarmTracker: state.FarmTracker,
  }));

  useEffect(() => {
    if (FarmTracker.getAllByCriteriaProjectListRequesting) {
      // setLoading(true);
    } else if (FarmTracker.getAllByCriteriaProjectListData) {
      if (FarmTracker?.getAllByCriteriaProjectListData?.data) {
        let response = FarmTracker?.getAllByCriteriaProjectListData?.data;
        if (response?.result?.length) {
          if (response?.result[0]?.id) {  
            // dispatch(getAllByCriteriaFarmListRequest({ farm_tracker_project_id: response?.result[0]?.id }));
            if(filterProject){
              let selectedProject = response?.result?.find(item=> item.id === filterProject)
              setProject(selectedProject)
              getFarmListContainer(selectedProject);
            }
            else {
              setProject(response?.result[0])
              getFarmListContainer(response?.result[0]);
            }
            setIsOpenFarmListingCollapse(true);
          }
          setProjectList(response?.result);
        }
      }
      //  setLoading(false);
    } else if (FarmTracker.getAllByCriteriaProjectListDataError) {
      // setLoading(false);
    }
  }, [
    FarmTracker.getAllByCriteriaProjectListRequesting,
    FarmTracker.getAllByCriteriaProjectListData,
    FarmTracker.getAllByCriteriaProjectListDataError,
  ]);
  //  --------

  useEffect(() => {
    if (farmList?.length) {
      setFarmTrackerList(farmList);
      setIsShowListing(true);
    } else {
      setFarmTrackerList([]);
      setIsShowListing(false);
    }
  }, [farmList]);


  useEffect(() => {
    dispatch(getAllByCriteriaProjectListRequest({ module_name: 'risk_analytics' }));
    let requestObj = { location_type: LOCATION.State, parent_id: DEFAULT_COUNTRY.id };
    dispatch(getMapPreferenceRequest({ country_id: DEFAULT_COUNTRY.id, module_name: 'risk' }));
    dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    dispatch(getStateByCountryRequest({ requestObj, isCompareLocation: false }));
    // dispatch(getCWPCropsRequest())
    dispatch(getCWPCropsRequest({ crop_type: CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value }));
    dispatch(getCWPCropsRequest({ crop_type: CROP_PRODUCTION_FILTER_TABS.SEASONAL.value }));
    dispatch(getCWPSeasonRequest());
    dispatch(getCWPPractiseRequest());
    setProjectList([]);
    setProject('');
    setFarmTrackerList('')
    setSelectedOption('')
    return () => {
      dispatch(getAllByCriteriaProjectListSuccess({}));
      dispatch(getFarmDetailsByIdSuccess({}));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(selectedOption).length) {
      const { latitude, longitude, zoom_level } = selectedOption;
      return props.handleCordinates(
        [
          latitude ? latitude : cordinateAndZoom?.locationCordinates.latitude,
          longitude ? longitude : cordinateAndZoom?.locationCordinates.longitude,
        ],
        zoom_level ? zoom_level : cordinateAndZoom?.zoom,
      );
    } else {
      return props.handleCordinates(
        [DEFAULT_COUNTRY.latitude, DEFAULT_COUNTRY.longitude],
        DEFAULT_COUNTRY.zoom_level,
      );
    }
  }, [selectedOption]);

  const handleProvinceChange = (data) => {
    let requestObj = { location_type: LOCATION.District, parent_id: data?.id };
    setSelectedOption(data);
    dispatch(getDistrictByStateRequest({ requestObj, isCompareLocation: false }));
  };

  const handleDistrictChange = (data) => {
    let requestObj = { location_type: LOCATION.Sub_District, parent_id: data?.id };
    setSelectedOption(data);
    dispatch(getSubDistrictByDistrictRequest({ requestObj, isCompareLocation: false }));
  };

  const handleSubDistrictChange = (data) => {
    let requestObj = { location_type: LOCATION.Block, parent_id: data?.id };
    setSelectedOption(data);
    dispatch(getBlockBySubDistrictRequest({ requestObj, isCompareLocation: false }));
  };

  // const toggle = (index, trackerList) => {
  //   if (trackerList?.length) {
  //     let list = JSON.parse(JSON.stringify(trackerList));
  //     let updatedList = list?.map((item, i) =>
  //       i === index ? { ...item, isOpenAction: !item.isOpenAction } : item,
  //     );
  //     setFarmTrackerList(updatedList);
  //   }
  // };

  const getFarmData = (data, index) => {
    setSelectedFarmIndex(index)
    props.getFarmData(data, index);
    const longitude = data?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[0];
    const latitude = data?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[1];
    if (data?.draw_farm?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.length !== 0) {
      return props.handleCordinates(
        [
          latitude ? latitude : cordinateAndZoom?.locationCordinates[0],
          longitude ? longitude : cordinateAndZoom?.locationCordinates[1],
        ],
        18,
      );
    }
  };

  const handleApplyFarm = () => {
    setIsApplyLayer(true);
    props.handleApplyFarm();
  };

  const getCompareFarmData = (data, index) => {
    setCompareSelectedFarmIndex(index);
    props.getCompareFarmData(data);
  };

  // const handleDelete = (item) => {
  //   setFarmData(item);
  //   setIsOpenDeleteFarmModal(!isOpenDeleteFarmModal);
  // };

  const handleCateogryToggle = (isOpen, tab) => {
    if (tab === 'farm_listing') setIsOpenFarmListingCollapse(!isOpen);
    else if (tab === 'farm_compare') setIsOpenFarmCompareCollapse(!isOpen);
  };

  const handleDeleteToggle = () => setIsOpenDeleteFarmModal(!isOpenDeleteFarmModal);

  const onFormSubmit = () => {};

  const renderAPIParameterOptions = (list, value) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map((param) => {
          let data = {
            ...param,
            value: param.name,
            label: `${param.name}`,
            id: value === 'country' ? param.name : param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleProjectSelect = (item) => {
    setSelectedFarmIndex('');
    setCompareSelectedFarmIndex('');
    handleProjectUpdate(item);
    setProject(item);
    setIsOpenFarmListingCollapse(true);
    getFarmListContainer(item);
    // setSelectedFarmsList([])
    setSelectedFarm('');
    setCompareFarm('');
    setIsApplyLayer(false);
    setIsOpenFarmCompareCollapse(false)
    setRiskScoreInfo([]);
    setCompareRiskScoreInfo([]);
    setFarmId('')
    setIsRightCollapseOpen(false)
    handleRemovePolygonOnProjectChange()
  };
  return (
    <>
      {isOpenDeleteFarmModal ? (
        <DeleteFarmModal
          isOpen={isOpenDeleteFarmModal}
          toggle={handleDeleteToggle}
          farmData={farmData}
          handleDeleteFarm={props.handleDeleteFarm}
        />
      ) : (
        ''
      )}
      {props?.isHideBar ? (
        <div className="expand-bar">
          <span className="cursor-ponter navyBlueColor" onClick={() => props.setBar(!props.isHideBar)}>
            {t('CLIMATE_WEATHER_FILTER.EXPAND_BAR')}
            <span>
              <MdKeyboardArrowLeft
                className="hide-icon expand-margin"
                size={25}
              />
            </span>
          </span>
        </div>
      ) : (
        <div>
          <div
            className="filter-sidebar-section-drought-monitor risk-farm-sidebar1 risk-analytics-filter-section"
            // mt-1"
          >
            <div className="risk-filter-div">
              <Card className="risk-card-style">
                <CardBody>
                  <div className="d-flex justify-content-end hide-bar-div my-2 mx-3">
                    <span className='cursor-ponter' onClick={() => props.setBar(!props.isHideBar)}>
                    <span>
                      <MdKeyboardArrowRight
                        className="hide-icon hide-margin"
                        size={25}
                      />
                    </span>
                    <span className="hidebar-show">{t('CLIMATE_WEATHER_FILTER.HIDE_BAR')}</span>
                    </span>
                  </div>
                  <div className="frm-bcg1">
                    {/* <span>  <ProjectIcon /> Projects</span> */}
                    <div
                      style={{
                        backgroundColor: 'rgba(221, 145, 3, 0.2)',
                        height: '47px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span className="pl-3">
                        <img
                          src={ProjectIcon}
                          style={{ paddingRight: '8px', paddingLeft: '26px' }}
                        />
                        Projects
                      </span>
                    </div>
                    <div
                      className="farm-tracker-map-filter"
                      // my-2"
                      style={{ marginBottom: '0px' }}
                    >
                      <FormGroup className="textOnInput pb-3 pt-2">
                        <Select
                          id="multi-select"
                          name="projects"
                          className="map-input-style-css mt-2 projects-inp"
                          placeholder="Select Project"
                          options={renderAPIParameterOptions(
                            projectList?.length ? projectList : [],
                          )}
                          onChange={handleProjectSelect}
                          isMulti={false}
                          required={true}
                          // value={projectList.find(item => item === project)}
                          // defaultValue={renderAPIParameterOptions(projectList?.length ? projectList : [])?.find(item => item.name === project.name)}
                          value={projectList?.length  ? ( project?.name ? renderAPIParameterOptions(projectList)?.find(item => item?.name === project?.name) : renderAPIParameterOptions(projectList)) : []}
                        />
                      </FormGroup>
                    </div>
                    <TabContent
                      activeTab={activeTab}
                      style={{ marginTop: '0px' }}
                      className="tab-con1"
                    >
                      <>
                        <TabPane tabId={'FARM_TRACKING'}>
                          <div className="">
                            <div className="farm-tab-msg">
                              {projectList?.length ? (
                                <ProjectListing
                                  projectList={projectList}
                                  getFarmListContainer={getFarmListContainer}
                                />
                              ) : (
                                <>
                                  <div className="no-project-data-st">No Projects Available</div>
                                </>
                              )}
                            </div>
                          </div>
                        </TabPane>
                      </>
                    </TabContent>
                    <span onClick={() => setIsOpenLocationTab(!isOpenLocationTab)}>
                      <p className="agriculture-headings-lable">
                        <span className="px-2">
                          <img
                            src={RiskLocationIcon}
                            style={{ paddingRight: '8px' }}
                            alt="riskIcon"
                          />
                        </span>
                        <span>Location</span>
                        <span className="category-span">
                          <span className="category-span">
                            {isOpenLocationTab ? (
                              <AiOutlineCaretUp size={13} />
                            ) : (
                              <AiOutlineCaretDown size={13} />
                            )}
                          </span>
                        </span>
                      </p>
                    </span>
                    <Collapse
                      id="agriculture_datetime"
                      isOpen={isOpenLocationTab}
                      className="collapse-moadal"
                    >
                      <div className="farm-tracker-map-filter">
                        <Form onSubmit={handleSubmit(onFormSubmit)} className="projects-inp">
                          <FormGroup className="textOnInput pb-4 pt-3">
                            <Field
                              component={renderReactSelectInputField}
                              name="province"
                              className="map-input-style-css"
                              placeholder="Province"
                              type="select"
                              onChange={handleProvinceChange}
                              required={true}
                              isMulti={false}
                              isSearchable={false}
                              menuIsOpen={false}
                              options={renderAPIParameterOptions(provinceList)}
                              isDisabled={initialValues?.province?.id ? true : false}
                            ></Field>
                          </FormGroup>
                          <FormGroup className="textOnInput pb-4 pt-1">
                            <Field
                              component={renderReactSelectInputField}
                              name="district"
                              className="map-input-style-css"
                              placeholder="District"
                              type="select"
                              onChange={handleDistrictChange}
                              required={true}
                              isMulti={false}
                              isSearchable={false}
                              menuIsOpen={false}
                              options={renderAPIParameterOptions(districtList)}
                              isDisabled={initialValues?.district?.id ? true : false}
                            ></Field>
                          </FormGroup>
                          {/* <FormGroup className="textOnInput pb-4 pt-1">
                            <Field
                              name="sub_district"
                              component={renderReactSelectInputField}
                              className="map-input-style-css"
                              id="sub_district"
                              options={renderAPIParameterOptions(subDistrictList)}
                              onChange={handleSubDistrictChange}
                              placeholder="DSD"
                              isSearchable={false}
                              menuIsOpen={false}
                              isMulti={false}
                              isDisabled={initialValues?.sub_district?.id ? true : false}
                            />
                          </FormGroup> */}
                          {/* <FormGroup className="textOnInput pb-3 pt-1">
                            <Field
                              name="block"
                              component={renderReactSelectInputField}
                              className="map-input-style-css"
                              id="block"
                              options={renderAPIParameterOptions(blockList)}
                              placeholder="GND"
                              isSearchable={false}
                              menuIsOpen={false}
                              isMulti={false}
                              isDisabled={initialValues?.block?.id ? true : false}
                            />
                          </FormGroup> */}
                        </Form>
                      </div>
                    </Collapse>
                    {!_.isEmpty(project) ? (
                      <>
                        <span
                          onClick={() =>
                            handleCateogryToggle(isOpenFarmListingCollapse, 'farm_listing')
                          }
                        >
                          <p className="agriculture-headings-lable pl-3">
                            <span className="px-2">
                              <FarmIcon fill="#3870AF" />{' '}
                            </span>
                            <span>Farms</span>
                            <span className="category-span">
                              <span className="category-span">
                                {isOpenFarmListingCollapse ? (
                                  <AiOutlineCaretUp size={13} />
                                ) : (
                                  <AiOutlineCaretDown size={13} />
                                )}
                              </span>
                            </span>
                          </p>
                        </span>
                        <Collapse
                          id="agriculture_datetime"
                          isOpen={isOpenFarmListingCollapse}
                          className="collapse-moadal"
                        >
                          {props?.farmListingContainer && (
                            <>
                              {farmListState && !_.isEmpty(farmList) ? (
                                <FarmListing
                                  farmTrackerList={farmList}
                                  getFarmData={getFarmData}
                                  selectedFarmsList={selectedFarmsList}
                                  selectedFarm={selectedFarm}
                                  projectDetails={project}
                                  selectedFarmIndex={selectedFarmIndex}
                                />
                              ) : (
                                <>
                                  <div
                                    className="forecast-monitoring"
                                    style={{ textAlign: 'center', width: '100%' }}
                                  >
                                    <span> {t('FARM_TRACKER.NO_FARMS')}</span>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </Collapse>

                        {!_.isEmpty(project) && farmList?.length > 1 && isOpenFarmListingCollapse ? (
                          <>
                            <span
                              onClick={() =>
                                handleCateogryToggle(isOpenFarmCompareCollapse, 'farm_compare')
                              }
                            >
                              <p className="map-preferences-labels">
                                Compare
                                <span className="category-span">
                                  {isOpenFarmCompareCollapse ? (
                                    <BiMinus size={25} />
                                  ) : (
                                    <BiPlus size={25} />
                                  )}
                                </span>
                              </p>
                            </span>
                            <Collapse
                              id="forecast_compare"
                              isOpen={isOpenFarmCompareCollapse}
                              className="collapse-moadal"
                            >
                              {props?.farmListingContainer && (
                                <>
                                  {farmListState && !_.isEmpty(compareFarmsList) ? (
                                    <FarmListing
                                      farmTrackerList={compareFarmsList}
                                      getFarmData={getCompareFarmData}
                                      projectDetails={project}
                                      selectedFarm={compareFarm}
                                      selectedFarmIndex={compareSelectedFarmIndex}
                                    />
                                  ) : (
                                    <>
                                      <div
                                        className="forecast-monitoring"
                                        style={{ textAlign: 'center', width: '100%' }}
                                      >
                                        <span> {t('FARM_TRACKER.NO_FARMS')}</span>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </Collapse>
                            <div className="mx-3 mt-2 filter-submit">
                              <div
                                className="apply-layers"
                                id="apply_layers"
                                data-tip
                                data-for="apply_layers"
                              >
                                <Button
                                  className="navyBlueBgColor m-auto px-3 py-2"
                                  onClick={handleApplyFarm}
                                  disabled={
                                    isOpenFarmCompareCollapse
                                      ? !_.isEmpty(compareFarm) && !_.isEmpty(selectedFarm)
                                        ? false
                                        : true
                                      : !_.isEmpty(selectedFarm)
                                      ? false
                                      : true
                                  }
                                >
                                  Apply Layers
                                </Button>
                              </div>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default reduxForm({
  form: 'FilterFarmTracker',
  enableReinitialize: true,
})(Filter);
