import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js';
import _ from 'lodash';
import {
  renderGenericDateFormat,
  getChartShapeName,
  renderChartTime,
} from '../../Components/common/utils';
import {
  CHART_LABEL_COLORS,
  DISPLAY_CHART_DATE,
  DROUGHT_TEMPORAL_AGGREGATION,
  GRAPH_YAXIS_COLORS,
  MAX_NO_OF_XAXIS_TICKS,
  WEATHER_MONITOR_CONSTS,
} from '../../Components/constants';
import { PlotlyPNGLogo, PlotlyCSVLogo, PlotlyCloseLogo } from '../../Components/constants';
import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DroughtPlotChart = (props) => {
  const {
    chartData,
    renderCharts,
    temporalAggregation,
    selectedParam,
    compareParam,
    isCompareClicked,
    region,
    initialSelectedDate,
    selectedGLDASBand,
    location,
    comparedLocation,
    fileName,
    compareSelectedGLDAS,
  } = props;
  const [dates, setDates] = useState([]);
  const [yOptions, setYOptions] = useState([]);
  const ButtonClick = useRef('');
  const [csvFileData, setCsvFileData] = useState([]);
  const [layout, updateLayout] = useState({
    bargap: 0.5,
    responsive: true,
    title: {
      text: !isCompareClicked
        ? selectedParam?.slug === 'gldas'
          ? `Indicator: ${selectedParam?.name}-${selectedGLDASBand?.label} ${
              selectedParam?.slug === WEATHER_MONITOR_CONSTS.DRYSPELL ||
              selectedParam?.slug === WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
                ? ''
                : `(${temporalAggregation?.name})`
            }`
          : `Indicator: ${selectedParam?.name} ${
              selectedParam?.slug === WEATHER_MONITOR_CONSTS.DRYSPELL ||
              selectedParam?.slug === WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
                ? ''
                : `(${temporalAggregation?.name})`
            }`
        :  `${selectedParam?.name} ${selectedParam?.slug === WEATHER_MONITOR_CONSTS.DRYSPELL || selectedParam?.slug === WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL ? '' :`(${temporalAggregation?.name})` } - ${compareParam?.name} ${
            selectedParam?.slug === WEATHER_MONITOR_CONSTS.DRYSPELL ||
            selectedParam?.slug === WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
              ? ''
              : `(${temporalAggregation?.name})`
          }`,
      plot_bgcolor: '#E5ECF6',
      font: {
        family: 'National',
        color: '#28609e',
        size: 15,
      },
      x: 0.5,
      xanchor: 'center',
    },
    modebardisplay: true,
    font: {
      size: 12,
      color: '#000',
    },
    margin: {
      // t: region?.length ? 60 : selectedParam?.slug === 'gldas' || compareParam?.slug === 'gldas' ? 60 : 60,
      t: 60,
      b: 50,
    },
    xaxis: {
      type: 'date',
      title: { standoff: 10 },
      showline: true,
      // tickangle: 45,
      tickangle: 20,
      tickfont: {
        size: 12,
        color: '#000',
        family: 'National',
      },
      tickformat:"%d-%m-%Y",
      showgrid: false,
    },
    yaxis: {
      // tickformatstops: {
      //   dtickrange: [0, 0.5]
      // }
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      // x: 0.4,
      x: 0.5,
      xanchor: 'center',
      y: -0.7,
      font: {
        // family: 'sans-serif',
        size: 15,
        color: '#000',
        family: 'National',
      },
    },
  });
  const [newFileName, setFileName] = useState(
    `${fileName.split('-')[0]}-${fileName.split('-')[1]}`,
  );
  useEffect(() => {
    if (!_.isEmpty(temporalAggregation) && !_.isEmpty(selectedParam)) {
      let title = `${selectedParam.name} ${
        selectedParam?.slug === WEATHER_MONITOR_CONSTS.DRYSPELL ||
        selectedParam?.slug === WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
          ? ''
          : `(${_.capitalize(temporalAggregation?.value)})`
      }`;
      if (!_.isEmpty(compareParam) && isCompareClicked) {
        title = `${selectedParam.name}-${compareParam.name} ${
          selectedParam?.slug === WEATHER_MONITOR_CONSTS.DRYSPELL ||
          selectedParam?.slug === WEATHER_MONITOR_CONSTS.EXTREME_RAINFALL
            ? ''
            : `(${_.capitalize(temporalAggregation?.value)})`
        }`;
      }
      let fileName =
        selectedParam.name +
        '(' +
        renderChartTime(initialSelectedDate?.startDate) +
        '-' +
        renderChartTime(initialSelectedDate?.endDate) +
        ')';
    }
  }, [temporalAggregation, selectedParam, compareParam, isCompareClicked, initialSelectedDate]);

  const getTickAngle = (xaxisData) => {
    let tickangle;
    if (xaxisData.length < 100 && xaxisData.length > 50) {
      tickangle = 40;
    } else {
      tickangle = 50;
    }
    return tickangle;
  };

  const getTickDifference = (startDate,endDate) => {
    let tickDifference;
    let months;
    let dayInMillis = 86400000;
    months = (endDate?.getFullYear() - startDate?.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
    if(months <= 2){
      tickDifference = dayInMillis
    }
    else if(months <= 12)  {
      tickDifference = 10 * dayInMillis;
    }
    else{
      tickDifference = 'M1'
    }
    return tickDifference
  }

  useEffect(() => {
    let formattedDates = [];
    let datesList;
    let options = [];
    if (chartData && chartData?.graph_data) {
      datesList = chartData?.graph_data[0]?.millis;
      if (chartData?.graph_data?.length === 2) {
        datesList =
          chartData?.graph_data[0]?.millis.length > chartData?.graph_data[1]?.millis.length
            ? chartData?.graph_data[0]?.millis
            : chartData?.graph_data[1]?.millis;
      }
      if (!_.isEmpty(datesList)) {
        formattedDates = datesList?.length
          ? datesList.map((item) => {
              let dateFormat = new Date(item);
              return renderGenericDateFormat(dateFormat);
            })
          : '';
      }
       //setting same dates for all indicators
       let tempDates = [];
       //sorting the millies from chartData and converting into string dates
       tempDates = [...new Set([].concat(...chartData?.graph_data.map((o) => o.millis)))].sort();
      setDates(formattedDates);
      let layoutData = { ...layout };
      layoutData.xaxis.tickangle = getTickAngle(formattedDates);
      if(tempDates?.length > 70){
        layoutData.xaxis.nticks = MAX_NO_OF_XAXIS_TICKS
        layoutData.xaxis.tickmode = "auto"
      }
      else
      layoutData.xaxis.tickvals = tempDates 
      updateLayout(layoutData);
      let view = [
        { visible: true, dash: '', type: 'scatter', mode: 'lines' },
        { visible: true, dash: '', type: 'scatter', mode: 'lines' },
        { visible: true, dash: '', type: 'scatter', mode: 'lines' },
        { visible: true, dash: '', type: 'scatter', mode: 'lines' },
        { visible: true, dash: '', type: 'scatter', mode: 'lines' },
        { visible: true, dash: '', type: 'bar', mode: 'lines' },
      ];
      chartData?.graph_data?.map((i, index) => {
        if (i?.layerType) {
          let ab = {
            y: [],
          };
          ab.y = i?.data?.map((i) => i?.toFixed(2));
          ab.visible = view[index].visible;
          ab.type = 'scatter';
          // ab.type = i?.data?.length === 1 ? 'bar' : 'scatter';
          if (index > 0) {
            ab.yaxis = `y${index + 1}`;
          }
          if (ab.type === 'bar') {
            ab.width = [0.05];
          }
          ab.mode = i?.millis?.length > 1 ? 'lines' : 'markers';
          // ab.name =
          //   i?.layerType === 'marker'
          //     ? 'point' + '(' + i?.latlng?.[0]?.toFixed(2) + ',' + +i?.latlng?.[1]?.toFixed(2) + ')'
          //     : i?.layerType === 'polygon' && `${i?.layerType}-${i?.polygonCount}`;
          if (i?.layerType !== 'marker' && i?.layerType !== 'polygon') {
            ab.name = i?.layerType;
          } else {
            ab.name =
              i?.layerType === 'marker'
                ? `Point(${i?.latlng?.[1].toFixed(2)},${i?.latlng?.[0].toFixed(2)})`
                : i?.layerType === 'polygon' && `${_.capitalize(i?.layerType)}-${i?.polygonCount}`;
          }
          ab.layerType = i?.layerType;
          ab.latlng = i?.latlng;
          // ab.x = i?.millis?.map((item) => {
          //   let dateFormat = new Date(item);
          //   return renderGenericDateFormat(dateFormat);
          // });
          datesList = i?.millis;
          let formattedDates;
          if (!_.isEmpty(datesList)) {
          formattedDates = datesList?.length
              ? datesList.map((item) => {
                  return new Date(item)?.getTime();
                })
              : '';
          }
          ab.x = formattedDates;
          ab.line = { color: GRAPH_YAXIS_COLORS[index], dash: view[index]?.dash, width: 3 };
          ab.position = index === 0 ? undefined : 0.8;
          ab.overlaying = index === 0 ? '' : 'y';
          ab.anchor = index === 0 ? '' : 'x';
          options.push(ab);
        } else {
          let ab = {
            y: [],
          };
          i?.data?.map((item) => {
            ab.y.push(item?.toFixed(2));
          });
          // ab.type = i?.data?.length === 1 ? 'bar' : 'scatter';
          ab.visible = view[index].visible;
          ab.type = 'scatter';
          ab.mode = i?.millis?.length > 1 ? 'lines' : 'markers';
          if (ab.type === 'bar') {
            ab.width = [0.05];
          }
          ab.name = selectedParam?.name;
          if (index > 0) {
            ab.yaxis = `y${index + 1}`;
          }
          datesList = i?.millis;
          let formattedDates;
          if (!_.isEmpty(datesList)) {
          formattedDates = datesList?.length
              ? datesList.map((item) => {
                  return new Date(item)?.getTime();
                })
              : '';
          }
          ab.x = formattedDates;
          ab.line = { color: GRAPH_YAXIS_COLORS[index], dash: view[index]?.dash, width: 3 };
          ab.position = index === 0 ? undefined : 1;
          ab.overlaying = index === 0 ? '' : 'y';
          ab.anchor = index === 0 ? '' : 1;
          ab.hovertemplate = `%{x} : ${selectedParam?.name} : %{y}<extra></extra>`;
          options.push(ab);
        }
        
      });
    }
    //  //setting same dates for all indicators
    //  let tempDates = [];
    //  //sorting the millies from chartData and converting into string dates
    //  tempDates = [...new Set([].concat(...chartData?.graph_data.map((o) => o.millis)))].sort();
    //  let sortedDates = tempDates?.map((item) => {
    //    let dateFormat = new Date(item);
    //    return renderGenericDateFormat(dateFormat);
    //  });
    let updatedOptions= options.map(item=>{
      let x = {...item};
      x.hovertemplate = `%{x} : ${item.name} : %{y}<extra></extra>`
      // x.x = sortedDates?.length ? [...sortedDates] : [null];
      return x;
    });
    if (updatedOptions) setYOptions(updatedOptions);
  }, [chartData]);

  const handleTitle = (i) => {
    let axis_title;
    yOptions.map((item) => {
      if (item.name === Object.keys(chartData)[i]) {
        axis_title = item.name;
      }
    });
    return axis_title;
  };
  let trace = yOptions;
  let chartsData = trace;

  const getTitle = (data, location, gldasBand) => {
    let title = '';
    if (data?.layerType === 'marker') {
      title = `<b>Point(${data?.latlng?.[1].toFixed(2)},${data?.latlng?.[0].toFixed(2)})</b>`;
    } else if (data?.layerType === 'polygon') {
      title = `<b>${_.capitalize(data?.name)}</b>`;
    } else if (data?.name === 'GLDAS') {
      title = `<b>${data?.name} <br> ${gldasBand?.label} <br> (${gldasBand?.unit})<br>${
        location ? `(${location})</b>` : ''
      }`;
    } else if (data?.name === 'Temperature') {
      title = `<b>${data?.name} (°C)<br>${location ? `(${location})</b>` : ''}`;
    } else if (data?.name === 'Accumulated Rainfall') {
      title = `<b>${data?.name} (mm)<br>${location ? `(${location})</b>` : ''}`;
    } else if (data?.name === 'Extreme Rainfall' || data?.name === 'Dry Spell') {
      title = `<b>${data?.name} (Days)<br>${location ? `(${location})<b>` : ''}`;
    } else {
      title = `<b>${data?.name}<br>${location ? `(${location})` : ''}</b>`;
    }
    return title;
  };

  const loadedOnlyOnce = useRef(false);
  useEffect(() => {
    if (chartsData && chartsData.length && !loadedOnlyOnce.current) {
      let layoutData = { ...layout };
      chartsData.forEach((data, index) => {
        let yaxisName = index === 0 ? 'yaxis' : `yaxis${index + 1}`;
        layoutData[yaxisName] = {
          showline: true,
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          side: index === 0 ? 'left' : 'right',
          overlaying: index === 0 ? '' : 'y',
          exponentformat: 'none',
          zeroline: false,
          showgrid: false,
          rangemode: 'tozero',
          // automargin:index === 0 ? false: true,
          automargin: true,
          title: {
            // standoff: index === 0 ? 0 : 1,
            text:
              index === 0
                ? getTitle(data, location, selectedGLDASBand)
                : getTitle(data, comparedLocation, compareSelectedGLDAS),
          },
        };
      });
      updateLayout(layoutData);
      loadedOnlyOnce.current = true;
    }
  }, [chartsData]);

  var icon1 = {
    width: 300,
    height: 500,
    path: 'M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z',
  };

  var icon3 = {
    name: 'newplotlylogo',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"/></svg>',
  };

  const getLayoutWidths = [
    // { yaxisCount: 1, width: 1600, domain: [0, 0] },
    // { yaxisCount: 2, width: 1500, domain: [0, 0.9] },
    // { yaxisCount: 3, width: 1400, domain: [0, 0.8] },
    // { yaxisCount: 4, width: 1300, domain: [0, 0.8] },
    { yaxisCount: 1, width: 1600, domain: [0, 0] },
    { yaxisCount: 2, width: 1500, domain: [0, 0] },
    { yaxisCount: 3, width: 1400, domain: [0, 0.95] },
    { yaxisCount: 4, width: 1300, domain: [0, 0.9] },
  ];
  //function to order multiple y axes
  const getOrderedYAxes = (layoutData, YoptionsLength) => {
    let yaxisArray = [];
    Object.keys(layoutData).forEach((element) => {
      if (element.includes('yaxis')) {
        yaxisArray.push(element);
      }
    });
    let position = 1 - Number(`0.${YoptionsLength - 3}`);
    if (!yaxisArray.find((e) => e === 'yaxis')) {
      yaxisArray.push('yaxis');
    }
    let order = ['yaxis', 'yaxis2', 'yaxis3', 'yaxis4'];
    yaxisArray.sort((a, b) => order.indexOf(a) - order.indexOf(b));

    yaxisArray.forEach((item, key) => {
      if (key === 0) {
        if (layoutData[item]) {
          layoutData[item].side = 'left';
          layoutData[item].position = undefined;
        }
      } else if (key === 1) {
        layoutData[item].anchor = 'x';
        layoutData[item].side = 'right';
        layoutData[item].position = undefined;
      } else {
        layoutData[item].anchor = 'free';
        layoutData[item].side = 'right';
        layoutData[item].position = position;
        position += 0.1;
      }
    });
    return layoutData;
  };

  const handleLegends = (graphData) => {
    let index = graphData.expandedIndex;
    let data = graphData.data[index];
    let newYOptions = [...yOptions];
    if (data.visible === 'legendonly') {
      newYOptions[index]['yaxis'] = `y${index + 1}`;
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      if (yaxisCount > 2) {
        newYOptions[yaxisCount - 1]['position'] = yaxisCount === newYOptions.length ? 1 : 0.9;
      }
      setYOptions(newYOptions);
      // layout.width = getLayoutWidths.find(item => item.yaxisCount === yaxisCount)?.width;
      layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      if (index !== 0) {
        let yaxisName = `yaxis${index + 1}`;
        layout[yaxisName] = {
          // title: data?.name + location,
          title: { text: getTitle(data, comparedLocation, compareSelectedGLDAS) },
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          anchor: yaxisCount === 2 ? 'x' : 'free',
          overlaying: 'y',
          side: 'right',
          position: newYOptions[yaxisCount - 1]['position'],
          showline: true,
          showgrid: false,
          zeroline: false,
          automargin: true,
          rangemode: 'tozero',
        };
      } else if (index === 0) {
        layout.yaxis = {
          // title: data?.name + location,
          title: { text: getTitle(data, comparedLocation, compareSelectedGLDAS) },
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          side: 'left',
          overlaying: '',
          showline: true,
          showgrid: false,
          zeroline: false,
          automargin: true,
          rangemode: 'tozero',
        };
      }
      let orderedLayoutData = getOrderedYAxes(layout, yOptions.length);
      updateLayout(orderedLayoutData);
    } else {
      delete newYOptions[index]['yaxis'];
      setYOptions(newYOptions);
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      let yaxisName = index === 0 ? `yaxis` : `yaxis${index + 1}`;
      let layoutData = { ...layout };
      delete layoutData[yaxisName];
      if(yaxisName === "yaxis"){
        layoutData[yaxisName] = { showgrid: false, zeroline:false, tickfont : { color: '#fff'}}
      }
      layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      // layoutData.width = getLayoutWidths.find(item => item.yaxisCount === yaxisCount)?.width;
      let orderedLayoutData = getOrderedYAxes(layoutData, yOptions.length);
      updateLayout(orderedLayoutData);
    }
  };
  const getFileName = () => {
    let fileName;
    if (selectedParam?.name === 'GLDAS' && compareParam?.name !== 'GLDAS') {
      fileName = `${selectedParam?.name}${
        selectedGLDASBand?.label ? `-${selectedGLDASBand?.label}` : ''
      }${location ? `(${location})` : ''}-${compareParam?.name}${
        comparedLocation ? `(${comparedLocation})` : ''
      }`;
    } else if (selectedParam?.name !== 'GLDAS' && compareParam?.name === 'GLDAS') {
      fileName = `${selectedParam?.name}${location ? `(${location})` : ''}-${compareParam?.name}${
        compareSelectedGLDAS?.label ? `-${compareSelectedGLDAS?.label}` : ''
      }${comparedLocation ? `(${comparedLocation})` : ''}`;
    } else if (selectedParam?.name === 'GLDAS' && compareParam?.name === 'GLDAS') {
      fileName = `${selectedParam?.name}${
        selectedGLDASBand?.label ? `-${selectedGLDASBand?.label}` : ''
      }${location ? `(${location})` : ''}-${compareParam?.name}${
        compareSelectedGLDAS?.label ? `-${compareSelectedGLDAS?.label}` : ''
      }${comparedLocation ? `(${comparedLocation})` : ''}`;
    } else {
      fileName = `${selectedParam?.name}${location ? `(${location})` : ''}-${compareParam?.name}${
        comparedLocation ? `(${comparedLocation})` : ''
      }`;
    }
    return fileName;
  };
  const getPngFileName = (activeLegends, graphData) => {
    let name = `${selectedParam?.dataSource}${location ? `-(${location})` : ''}`;
    if (activeLegends.map((data) => data.name)?.length) {
      let checkForRegion = graphData?.data?.every(
        (i) => i.layerType !== 'marker' && i.layerType !== 'polygon',
      );
      if (checkForRegion && fileName.split('-')[0] !== 'GLDAS') {
        if (graphData?.data?.length === 2) {
          name = `${graphData?.data?.[0]?.name}-${graphData?.data?.[1]?.name}${
            location ? `-(${location})` : ''
          }`;
        } else {
          name = `${graphData?.data?.[0]?.name}${location ? `-(${location})` : ''}`;
        }
      } else if (fileName.split('-')[0] !== 'GLDAS') {
        if (graphData?.data?.length === 2) {
          name = `${fileName.split('-')[0]}-${graphData?.data?.[0]?.name}-${
            graphData?.data?.[1]?.name
          }${location ? `-(${location})` : ''}`;
        } else {
          name = `${fileName.split('-')[0]}-${graphData?.data?.[0]?.name}${
            location ? `-(${location})` : ''
          }`;
        }
      } else {
        if (checkForRegion) {
          name = `${fileName.split('-')[0]}-${fileName.split('-')[1]}-${
            location ? `-(${location})` : ''
          }`;
        } else {
          if (graphData?.data?.length === 2) {
            name = `${fileName.split('-')[0]}-${fileName.split('-')[1]}-${
              graphData?.data?.[0]?.name
            }-${graphData?.data?.[1]?.name}${location ? `-(${location})` : ''}`;
          } else {
            name = `${fileName.split('-')[0]}-${fileName.split('-')[1]}-${
              graphData?.data?.[0]?.name
            }${location ? `-(${location})` : ''}`;
          }
        }
      }
    }
    return name;
  };
  //function for getting active legends for graph then download png/csv format
  const getActiveLegends = (isDownloadCSV, graphData, chartData) => {
    if (document.getElementById('droughtGraphId')?.data?.length) {
      let mappedDataList = [];
      let graphDataList = [...graphData?.data];
      graphDataList?.forEach((i,key)=>{
          mappedDataList[key] = []
          i?.x?.forEach((k,index)=>{
            let filteredData = chartData?.graph_data?.[key]
            let obj = {};
            obj.name = i?.name
            obj.date = k;
            obj.value = filteredData?.data?.[index]?.toFixed(4)
            mappedDataList[key]?.push(obj)
          })
        })
      const activeLegends = graphData?.data?.filter((item) => item.visible === true);
      let getAllDates = [];
      activeLegends?.forEach((i) => {
        i?.x?.forEach((k) => {
          getAllDates.push(k);
        });
      });
      let xaxisSortedDates = getAllDates.sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
      function removeDuplicates(arr) {
        let unique = [];
        for(let i=0; i < arr.length; i++){ 
            if(unique.indexOf(arr[i]) === -1) { 
                unique.push(arr[i]); 
            } 
        }
        return unique;
      }
      let xaxisValue = removeDuplicates(xaxisSortedDates);
      let tempDates = [];
      //sorting the millies from chartData and converting into string dates
      tempDates = [...new Set([].concat(...xaxisValue))].sort();
      let sortedDates = tempDates?.map((item) => {
        let dateFormat = new Date(item);
        return renderGenericDateFormat(dateFormat);
      });
      if (!_.isEmpty(compareParam)) {
        let name;
        name = `${activeLegends[0]?.name}${location ? `-(${location})` : ''}-${
          activeLegends[1]?.name
        }${comparedLocation ? `-(${comparedLocation})` : ''}`;
        if (activeLegends.map((data) => data.name)?.length) {
          //setting file name based on active legends
          if (selectedParam?.name === 'GLDAS' && compareParam?.name !== 'GLDAS') {
            setFileName(
              `${selectedParam?.name}${
                selectedGLDASBand?.label ? `-${selectedGLDASBand?.label}` : ''
              }${location ? `(${location})` : ''}-${compareParam?.name}${
                comparedLocation ? `(${comparedLocation})` : ''
              }`,
            );
          } else if (selectedParam?.name !== 'GLDAS' && compareParam?.name === 'GLDAS') {
            setFileName(
              `${selectedParam?.name}${location ? `(${location})` : ''}-${compareParam?.name}${
                compareSelectedGLDAS?.label ? `-${compareSelectedGLDAS?.label}` : ''
              }${comparedLocation ? `(${comparedLocation})` : ''}`,
            );
          } else if (selectedParam?.name === 'GLDAS' && compareParam?.name === 'GLDAS') {
            setFileName(
              `${selectedParam?.name}${
                selectedGLDASBand?.label ? `-${selectedGLDASBand?.label}` : ''
              }${location ? `(${location})` : ''}-${compareParam?.name}${
                compareSelectedGLDAS?.label ? `-${compareSelectedGLDAS?.label}` : ''
              }${comparedLocation ? `(${comparedLocation})` : ''}`,
            );
          } else {
            setFileName(
              `${selectedParam?.name}${location ? `(${location})` : ''}-${compareParam?.name}${
                comparedLocation ? `(${comparedLocation})` : ''
              }`,
            );
          }
          // setFileName(`${fileName.split('-')[0]}-${fileName.split('-')[1]}`);
        }
        if (activeLegends?.length) {
          let data = [];
          let getKey = '';
          sortedDates?.forEach((date, key) => {
            activeLegends.forEach((item, index) => {
              let mappedList = mappedDataList?.find(x=> x?.find(i=> i.name === item?.name));
              let dateObj= mappedList?.find(item=> renderGenericDateFormat(item?.date) === date);
              getKey = dateObj?.value || ' -';
              if (index === 0) {
                data.push({ Date: date, [item.name]: getKey});
              } else {
                data[key][item.name] = getKey;
              }
            });
          });
          setCsvFileData(data);
          if (isDownloadCSV) {
            //downloading csv file
            ButtonClick.current.click();
          } else {
            //downloading png file
            Plotly.downloadImage(graphData, {
              // filename: `${fileName.split('-')[0]}-${fileName.split('-')[1]}`,
              filename: getFileName(),
              format: 'png',
              width: graphData._fullLayout.width,
              height: graphData._fullLayout.height,
            });
          }
        }
      } else if (_.isEmpty(compareParam)) {
        let name = `${selectedParam?.dataSource}${location ? `-(${location})` : ''}`;
        if (activeLegends.map((data) => data.name)?.length) {
          let checkForRegion = graphData?.data?.every(
            (i) => i.layerType !== 'marker' && i.layerType !== 'polygon',
          );
          if (checkForRegion && fileName.split('-')[0] !== 'GLDAS') {
            if (graphData?.data?.length === 2) {
              name = `${graphData?.data?.[0]?.name}-${graphData?.data?.[1]?.name}${
                location ? `-(${location})` : ''
              }`;
            } else {
              name = `${graphData?.data?.[0]?.name}${location ? `-(${location})` : ''}`;
            }
          } else if (fileName.split('-')[0] !== 'GLDAS') {
            if (graphData?.data?.length === 2) {
              name = `${fileName.split('-')[0]}-${graphData?.data?.[0]?.name}-${
                graphData?.data?.[1]?.name
              }${location ? `-(${location})` : ''}`;
            } else {
              name = `${fileName.split('-')[0]}-${graphData?.data?.[0]?.name}${
                location ? `-(${location})` : ''
              }`;
            }
          } else {
            if (checkForRegion) {
              name = `${fileName.split('-')[0]}-${fileName.split('-')[1]}-${
                location ? `-(${location})` : ''
              }`;
            } else {
              if (graphData?.data?.length === 2) {
                name = `${fileName.split('-')[0]}-${fileName.split('-')[1]}-${
                  graphData?.data?.[0]?.name
                }-${graphData?.data?.[1]?.name}${location ? `-(${location})` : ''}`;
              } else {
                name = `${fileName.split('-')[0]}-${fileName.split('-')[1]}-${
                  graphData?.data?.[0]?.name
                }${location ? `-(${location})` : ''}`;
              }
            }
          }
          //setting file name based on active legends
          setFileName(name);
        }
        if (activeLegends?.length) {         
          let data = [];
          sortedDates.forEach((date, key) => {
            activeLegends.forEach((item, index) => {
              let getKey;
              let mappedList = mappedDataList[index];
              let dateObj= mappedList?.find(item=> renderGenericDateFormat(item?.date) === date);
              getKey = dateObj?.value || ' -';
              if (index === 0) {
                data.push({ Date: date, [item.name]: getKey });
              } else {
                data[key][item.name] = getKey;
              }
            });
          });
          setCsvFileData(data);
          if (isDownloadCSV) {
            //downloading csv file
            ButtonClick.current.click();
          } else {
            Plotly.downloadImage(graphData, {
              // filename: `${graphData?.data?.[0]?.name}${location ? `-(${location})` : ''}-${
              //   graphData?.data?.length === 2 ? `${graphData?.data?.[1]?.name}` : ''
              // }`,
              filename: getPngFileName(activeLegends, graphData),
              format: 'png',
              width: graphData._fullLayout.width,
              height: graphData._fullLayout.height,
            });
          }
        }
      }
    }
  };
  let config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true,
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        click: function (gd) {
          getActiveLegends(false, gd, chartData);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        click: function (gd) {
          getActiveLegends(true, gd, chartData);
        },
      },
      {
        name: 'Close',
        icon: PlotlyCloseLogo,
        click: function () {
          renderCharts();
        },
      },
    ],
    showTips: false,
  };
  return (
    <>
      <span
        style={{
          color: '#28609e',
          position: 'absolute',
          left: '5px',
          top: '2%',
          family: 'National',
          fontSize: '15px',
          zIndex: 100,
        }}
      >
        Date : {moment(initialSelectedDate?.startDate).format(DISPLAY_CHART_DATE)} to{' '}
        {moment(initialSelectedDate?.endDate).format(DISPLAY_CHART_DATE)}
      </span>
      <Plot
        data={chartsData}
        layout={layout}
        config={config}
        onLegendClick={(data) => handleLegends(data)}
        useResizeHandler={true}
        // style={{ width: '95%', height: '100%' }}
        style={{ width: compareParam?.name === 'GLDAS' ? '99%' : '100%', height: '100%' }}
        divId="droughtGraphId"
      />

      <ExcelFile
        filename={newFileName}
        element={
          <button
            ref={ButtonClick}
            className="btn btn-download ml-auto"
            style={{ display: 'none' }}
          >
            Download
          </button>
        }
      >
        <ExcelSheet data={csvFileData} name="Weather_and_Water">
          <ExcelColumn label="Date" value="Date" />
          {chartsData.map((item) => (
            <ExcelColumn label={item?.name} value={item?.name} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
export default DroughtPlotChart;
