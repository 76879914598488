import React, { useEffect, useState, useRef } from 'react';
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js';
import { chartDateLabels, renderGenericDateFormat } from '../../Components/common/utils';
import {
  PlotlyPNGLogo,
  PlotlyCSVLogo,
  kgMUnits,
  NOAA_PARAMETER_SLUG,
  CHART_LABEL_COLORS,
  GRAPH_YAXIS_COLORS,
} from '../../Components/constants';
import ReactExport from 'react-export-excel';
import { data } from 'jquery';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let icon2 = {
  width: 300,
  height: 500,
  path: 'M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z',
};

const PlotGEENOAACharts = (props) => {
  const { chartData, isOpenWeather, getByIdFarmInfo } = props;
  const ButtonClick = useRef('');
  const [csvFileData, setCsvFileData] = useState([]);
  const [dates, setDates] = useState([]);
  const [yOptions, setYOptions] = useState([]);
  const [finalChartData, setFinalChartData] = useState({});
  const [newFileName, setFileName] = useState('');
  const [getState, setGetState] = useState(false);
  const [objKeysData, setObjKeysData] = useState([]);
  const [isFirstYAxisDeselected, setIsFirstYAxisDeselected] = useState(false);
  useEffect(() => {
    if (Object.keys(chartData)?.length === 4) {
      let x = NOAA_PARAMETER_SLUG?.map((i, index) => {
        setFinalChartData((prev) => {
          return {
            ...prev,
            [i.name]: chartData[i.name],
          };
        });
      });
    }
  }, [chartData]);

  const [layout, updateLayout] = useState({
    // title: {
    //   text: `<b> DataSource : ${dataSource[0]?.name} </b>`,
    // },
    bargap: 0.9,
    modebardisplay: true,
    responsive: true,
    // width: 1600,
    font: {
      size: 12,
      color: '#000',
    },
    xaxis: {
      // domain: [0, 0.9],
      showline: true,
      tickangle: 45,
      tickfont: {
        size: 12,
        color: '#000',
        family: 'National',
      },
      showgrid: false,
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      // x: 0.2,
      x: 0.5,
      xanchor: 'center',
      y: -0.6,
      font: {
        // family: 'sans-serif',
        size: 15,
        color: '#000',
        family: 'National',
      },
    },
    margin: {
      t: 50,
      b: 50,
    },
  });

  useEffect(() => {
    updateLayout((preValues) => ({
      ...preValues,
      yaxis: {
        // title: `${NOAA_PARAMETER_SLUG[0]?.name} (${kgMUnits})`, //for multiple y-axis------
        title: {
          text: `<b>${NOAA_PARAMETER_SLUG[0]?.name} (${kgMUnits})</b>`,
          plot_bgcolor: '#E5ECF6',
          font: {
            family: 'National',
            color: GRAPH_YAXIS_COLORS[0],
            size: 15,
          },
        },
        titlefont: { color: GRAPH_YAXIS_COLORS[0], family: 'National' },
        tickfont: { color: GRAPH_YAXIS_COLORS[0] },
        showline: true,
        showgrid: false,
        zeroline: false,
        rangemode: 'tozero',
      },
    }));
  }, []);

  const handleTraces = (chart_data, i, index) => {
    // console.log(chart_data, i, index)
    let view = [
      {
        name: 'Precipitation',
        measure: kgMUnits,
        visible: true,
        dash: '',
        type: 'bar',
        mode: '',
      },
      {
        name: 'Temperature',
        measure: '°C',
        visible: 'legendonly',
        dash: '',
        type: 'scatter',
        mode: 'lines',
      },
      {
        name: 'Wind_speed',
        measure: 'm/s',
        visible: 'legendonly',
        dash: '',
        type: 'scatter',
        mode: 'lines',
      },
      {
        name: 'Humidity',
        measure: '%',
        visible: 'legendonly',
        dash: '',
        type: 'scatter',
        mode: 'lines',
      },
    ];
    let b = [];
    function formatDate() {
      let d = new Date();
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month?.length < 2) month = '0' + month;
      if (day?.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    }
    let currentDate = formatDate();
    let filteredDates = chart_data[i][0]?.Timestamp?.filter((item) => item !== currentDate);
    filteredDates?.map((a) => {
      b.push(renderGenericDateFormat(a));
    });
    let yAxisData = chart_data[i][0]?.data?.map((item) => item.toFixed(2)) || [];
    const ydata = {
      type: view[index]?.type,
      mode: view[index]?.mode,
      // name: i,
      name:
        view[index]?.name === 'Wind_speed'
          ? `Wind speed (${view[index]?.measure})`
          : `${view[index]?.name} (${view[index]?.measure})`,
      x: b,
      // y: chart_data[i][0]?.data,
      y: yAxisData,
      customData: chart_data[i][0]?.data?.map((item) => item.toFixed(4)) || [],
      // fill: 'toself',
      // visible: i === 'Precipitation' ? '' : view[index]?.visible,
      visible: view[index]?.visible,
      line: { color: GRAPH_YAXIS_COLORS[index], dash: view[index]?.dash },
      //
      position: undefined,
    };
    // view?.map((p) => {
    if (i === 'Precipitation') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    } else if (i === 'Temperature') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    } else if (i === 'Wind_speed') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    } else if (i === 'Humidity') {
      if (index === 0) {
        ydata.yaxis = `y1`;
      }
    }
    // });
    setDates(b);
    //for multiple y-axis------------------------
    //  if(index !== 0){
    //   ydata.yaxis = `y${index+1}`
    //  }
    //for multiple y-axis------------------------
    return ydata;
  };
  useEffect(() => {
    if (finalChartData && !isOpenWeather && !getState) {
      let options = [];
      let arr = Object.keys(finalChartData);

      arr.map((i, index) => {
        let returned_values = handleTraces(finalChartData, i, index);
        options.push(returned_values);
      });
      options.map((o) => {
        o.hovertemplate = `%{x} : ${o.name} : %{y}<extra></extra>`;
      });
      setYOptions(options);
    }
  }, [finalChartData]);

  const handleTitle = (i) => {
    if (chartData) {
      return Object.keys(chartData)[i];
    }
  };

  var icon1 = {
    width: 300,
    height: 500,
    path: 'M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z',
  };
  const getActiveLegends = (isDownload, graphData) => {
    if (document.getElementById('currentWeatherId')?.data?.length) {
      const activeLegends = graphData.data?.filter((item) => item.visible === true);
      if (activeLegends.map((data) => data.name)?.length) {
        //setting file name based on active legends
        setFileName(
          `Forecast-${activeLegends
            .map((data) =>
              data?.name === 'Wind speed (m/s)' ? 'Wind speed' : data.name.split(' ')[0],
            )
            .join(' ')}`,
        );
      }
      if (activeLegends?.length) {
        //formatting data for csv file
        const xaixsValue = activeLegends?.[0].x;
        let data = [];
        xaixsValue.forEach((date, key) => {
          activeLegends.forEach((item, index) => {
            if (index === 0) {
              data.push({ Date: date, [item.name?.split(' ')?.[0]]: item?.customData?.[key] });
            } else {
              data[key][item.name?.split(' ')?.[0]] = item.customData[key];
            }
          });
        });
        setCsvFileData(data);
        let objKeys = Object.keys(data?.[0]);
        if (objKeys) {
          setObjKeysData(objKeys);
        }
        if (isDownload) {
          //downloading csv file
          ButtonClick.current.click();
        } else {
          //downloading png file
          Plotly.downloadImage(graphData, {
            // filename: `${getByIdFarmInfo?.farm[0]?.name}-${activeLegends.map((data) => data.name.split(' ')[0]).join(' ')}`,
            filename: `Forecast-${activeLegends.map((data) => data.name.split(' ')[0]).join(' ')}`,
            format: 'png',
            width: graphData._fullLayout.width,
            height: graphData._fullLayout.height,
          });
        }
      }
    }
  };
  let config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true, //by default we are showing
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        direction: 'up',
        click: function (gd) {
          getActiveLegends(false, gd);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        direction: 'up',
        click: function (gd) {
          getActiveLegends(true, gd);
        },
      },

      // {
      //   name: 'close',
      //   icon: icon2,
      //   direction: 'up',
      //   click: function() {
      //     renderCharts();
      //   },
      // },
    ],
    showTips: false,
  };

  //for multiple y-axis------------------------
  const getLayoutWidths = [
    { yaxisCount: 1, domain: [0, 0] },
    { yaxisCount: 2, domain: [0, 0] },
    { yaxisCount: 3, domain: [0, 0.93] },
    { yaxisCount: 4, domain: [0, 0.86] },
  ];
  const handlePosition = (axisCount, key, index) => {
    let positionValue;
    if (axisCount) {
      if (axisCount === 3) {
        if (index === 0 || isFirstYAxisDeselected) {
          if (key === 3) positionValue = 1;
          else if (key === 2) positionValue = 0.93;
        } else if (key === 1) positionValue = 0.93;
        else positionValue = 1;
      } else if (axisCount === 4) {
        if (key === 3) {
          positionValue = 1;
        } else {
          positionValue = 0.93;
        }
      } else if (axisCount === 5) {
        positionValue = 0.85;
      } else {
        positionValue = 1;
      }
      return positionValue;
    }
  };
  //function to order multiple y axes
  const getOrderedYAxes = (layoutData, YoptionsLength, index) => {
    let yaxisArray = [];
    Object.keys(layoutData).forEach((element) => {
      if (element.includes('yaxis')) {
        yaxisArray.push(element);
      }
    });
    // let position = 1 - Number(`0.${YoptionsLength - 3}`);
    // let position = handlePosition(YoptionsLength);
    if (!yaxisArray.find((e) => e === 'yaxis')) {
      yaxisArray.push('yaxis');
    }
    let order = ['yaxis', 'yaxis2', 'yaxis3', 'yaxis4'];
    yaxisArray.sort((a, b) => order.indexOf(a) - order.indexOf(b));

    yaxisArray.forEach((item, key) => {
      let position = handlePosition(YoptionsLength, key, index);
      if (key === 0) {
        if (layoutData[item]) {
          layoutData[item].side = 'left';
          layoutData[item].position = undefined;
        }
      } else if (key === 1) {
        layoutData[item].anchor = 'x';
        layoutData[item].side = 'right';
        layoutData[item].position = undefined;
      } else {
        layoutData[item].anchor = 'free';
        layoutData[item].side = 'right';
        layoutData[item].position = position;
        position += 0.1;
      }
    });
    return layoutData;
  };
  const handleLegends = (graphData) => {
    setGetState(true);
    let index = graphData.expandedIndex;
    let data = graphData.data[index];
    let newYOptions = [...yOptions];
    if (data.visible !== true) {
      newYOptions[index]['yaxis'] = `y${index + 1}`;
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      if (yaxisCount > 2) {
        newYOptions[yaxisCount - 1]['position'] =
          yaxisCount === newYOptions.length ? 1 : handlePosition(yaxisCount);
      }
      setYOptions(newYOptions);
      layout.xaxis.domain = getLayoutWidths.find((item) => item.yaxisCount === yaxisCount)?.domain;
      if (index !== 0) {
        if (isFirstYAxisDeselected)
          layout.xaxis.domain = getLayoutWidths.find(
            (item) => item.yaxisCount === yaxisCount + 1,
          )?.domain;
        else
          layout.xaxis.domain = getLayoutWidths.find(
            (item) => item.yaxisCount === yaxisCount,
          )?.domain;
        let yaxisName = `yaxis${index + 1}`;
        layout[yaxisName] = {
          title: {
            text: `<b>${data?.name}</b>`,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          anchor: yaxisCount >= 2 ? 'x' : 'free',
          overlaying: 'y',
          side: 'right',
          position: newYOptions[yaxisCount - 1]['position'],
          showline: true,
          showgrid: false,
          rangemode: 'tozero',
          zeroline: false,
        };
      } else if (index === 0) {
        setIsFirstYAxisDeselected(false);
        layout.xaxis.domain = getLayoutWidths.find(
          (item) => item.yaxisCount === yaxisCount,
        )?.domain;
        layout.yaxis = {
          title: {
            text: `<b>${data?.name}</b>`,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: GRAPH_YAXIS_COLORS[index],
              size: 15,
            },
          },
          titlefont: { color: GRAPH_YAXIS_COLORS[index], family: 'National' },
          tickfont: { color: GRAPH_YAXIS_COLORS[index] },
          side: 'left',
          showline: true,
          showgrid: false,
          rangemode: 'tozero',
          zeroline: false,
        };
      }
      let orderedLayoutData = getOrderedYAxes(layout, yaxisCount);
      updateLayout(orderedLayoutData);
    } else {
      delete newYOptions[index]['yaxis'];
      setYOptions(newYOptions);
      let yaxisCount = newYOptions.filter((item) => item.yaxis)?.length;
      let yaxisName = index === 0 ? `yaxis` : `yaxis${index + 1}`;
      let layoutData = { ...layout };
      delete layoutData[yaxisName];
      if (index === 0) setIsFirstYAxisDeselected(true);
      let updatedYAxisCount = index === 0 || isFirstYAxisDeselected ? yaxisCount + 1 : yaxisCount;
      layout.xaxis.domain = getLayoutWidths.find(
        (item) => item.yaxisCount === updatedYAxisCount,
      )?.domain;
      // let orderedLayoutData = getOrderedYAxes(layoutData, yaxisCount);
      let orderedLayoutData = getOrderedYAxes(layoutData, yaxisCount, index);
      updateLayout(orderedLayoutData);
    }
  };

  return (
    <>
      <Plot
        data={yOptions}
        layout={layout}
        config={config}
        onLegendClick={(data) => handleLegends(data)}
        useResizeHandler={true}
        style={{ width: '100%' }}
        divId="currentWeatherId"
      />
      <ExcelFile
        filename={newFileName}
        element={
          <button
            ref={ButtonClick}
            className="btn btn-download ml-auto"
            style={{ display: 'none' }}
          >
            Download
          </button>
        }
      >
        <ExcelSheet data={csvFileData} name="Forecast">
          {objKeysData?.map((item) => (
            <ExcelColumn label={item} value={item} />
          ))}
          {/* <ExcelColumn label="Date Time" value="DateTime" />
          <ExcelColumn label="Precipitation" value="Precipitation" />
          <ExcelColumn label="Temperature" value="Temperature" />
          <ExcelColumn label="Wind speed" value="Wind_speed" />
          <ExcelColumn label="Humidity" value="Humidity" /> */}
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
export default PlotGEENOAACharts;
