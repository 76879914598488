import { USER_GUIDE_CONSTS } from './userGuideConsts';

//add user guide api request
export const addUserGuideRequest = formData => ({
  type: USER_GUIDE_CONSTS.ADD_USER_GUIDE_REQUEST,
  payload: formData,
});

export const addUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.ADD_USER_GUIDE_SUCCESS,
  payload: response,
});

export const addUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.ADD_USER_GUIDE_FAILED,
  payload: error,
});



export const addTopicRequest = formData => ({
  type: USER_GUIDE_CONSTS.ADD_TOPIC_REQUEST,
  payload: formData,
});

export const addTopicSuccess = response => ({
  type: USER_GUIDE_CONSTS.ADD_TOPIC_SUCCESS,
  payload: response,
});

export const addTopicFailed = error => ({
  type: USER_GUIDE_CONSTS.ADD_TOPIC_FAILED,
  payload: error,
});



export const addSubTopicRequest = formData => ({
  type: USER_GUIDE_CONSTS.SUB_TOPIC_ADD_REQUEST,
  payload: formData,
});

export const addSubTopicSuccess = response => ({
  type: USER_GUIDE_CONSTS.SUB_TOPIC_ADD_SUCCESS,
  payload: response,
});

export const addSubTopicFailed = error => ({
  type: USER_GUIDE_CONSTS.SUB_TOPIC_ADD_FAILED,
  payload: error,
});


//get all topic and subtopic api request
export const getAllTopicsRequest = data => ({
  type: USER_GUIDE_CONSTS.GET_ALL_TOPICS_REQUEST,
  payload: data,
});

export const getAllTopicSuccess = response => ({
  type: USER_GUIDE_CONSTS.GET_ALL_TOPICS_SUCCESS,
  payload: response,
});

export const getAllTopicFailed = error => ({
  type: USER_GUIDE_CONSTS.GET_ALL_TOPICS_FAILED,
  payload: error,
});

//get all by criteria api request
export const getAllUserGuideRequest = data => ({
  type: USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_REQUEST,
  payload: data,
});

export const getAllUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_SUCCESS,
  payload: response,
});

export const getAllUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_FAILED,
  payload: error,
});

//get all topics user guide request
export const getAllTopicsListRequest = data => ({
  type: USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_REQUEST,
  payload: data,
});

export const getAllTopicsListSuccess = response => ({
  type: USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_SUCCESS,
  payload: response,
});

export const getAllTopicsListFailed = error => ({
  type: USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_FAILED,
  payload: error,
});



export const getAllSubTopicsListRequest = data => ({
  type: USER_GUIDE_CONSTS.GET_ALL_SUB_TOPICS_REQUEST,
  payload: data,
});

export const getAllSubTopicsListSuccess = response => ({
  type: USER_GUIDE_CONSTS.GET_ALL_SUB_TOPICS_SUCCESS,
  payload: response,
});

export const getAllSubTopicsListFailed = error => ({
  type: USER_GUIDE_CONSTS.GET_ALL_SUB_TOPICS_FAILED,
  payload: error,
});



//update user guide request
export const updateUserGuideRequest = data => ({
  type: USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_REQUEST,
  payload: data,
});

export const updateUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_SUCCESS,
  payload: response,
});

export const updateUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_FAILED,
  payload: error,
});


export const updateSubTopicRequest = data => ({
  type: USER_GUIDE_CONSTS.UPDATE_SUB_TOPIC_REQUEST,
  payload: data,
});

export const updateSubTopicSuccess = response => ({
  type: USER_GUIDE_CONSTS.UPDATE_SUB_TOPIC_SUCCESS,
  payload: response,
});

export const updateSubTopicFailed = error => ({
  type: USER_GUIDE_CONSTS.UPDATE_SUB_TOPIC_FAILED,
  payload: error,
});



export const updateTopicRequest = data => ({
  type: USER_GUIDE_CONSTS.UPDATE_TOPIC_REQUEST,
  payload: data,
});

export const updateTopicSuccess = response => ({
  type: USER_GUIDE_CONSTS.UPDATE_TOPIC_SUCCESS,
  payload: response,
});

export const updateTopicFailed = error => ({
  type: USER_GUIDE_CONSTS.UPDATE_TOPIC_FAILED,
  payload: error,
});


//delete user guide request
export const deleteUserGuideRequest = id => ({
  type: USER_GUIDE_CONSTS.DELETE_USER_GUIDE_REQUEST,
  payload: id,
});

export const deleteUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.DELETE_USER_GUIDE_SUCCESS,
  payload: response,
});

export const deleteUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.DELETE_USER_GUIDE_FAILED,
  payload: error,
});


export const deleteSubTopicRequest = id => ({
  type: USER_GUIDE_CONSTS.DELETE_SUB_TOPIC_REQUEST,
  payload: id,
});

export const deleteSubTopicSuccess = response => ({
  type: USER_GUIDE_CONSTS.DELETE_SUB_TOPIC_SUCCESS,
  payload: response,
});

export const deleteSubTopicFailed = error => ({
  type: USER_GUIDE_CONSTS.DELETE_SUB_TOPIC_FAILED,
  payload: error,
});


export const deleteTopicRequest = id => ({
  type: USER_GUIDE_CONSTS.DELETE_TOPIC_REQUEST,
  payload: id,
});

export const deleteTopicSuccess = response => ({
  type: USER_GUIDE_CONSTS.DELETE_TOPIC_SUCCESS,
  payload: response,
});

export const deleteTopicFailed = error => ({
  type: USER_GUIDE_CONSTS.DELETE_TOPIC_FAILED,
  payload: error,
});



//change status user guide request
export const changeStatusUserGuideRequest = data => ({
  type: USER_GUIDE_CONSTS.CHANGE_STATUS_USER_GUIDE_REQUEST,
  payload: data,
});

export const changeStatusUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.CHANGE_STATUS_USER_GUIDE_SUCCESS,
  payload: response,
});

export const changeStatusUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.CHANGE_STATUS_USER_GUIDE_FAILED,
  payload: error,
});

//search by criteria api request
export const searchByCriteriaUserGuideRequest = data => ({
  type: USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_REQUEST,
  payload: data,
});

export const searchByCriteriaUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_SUCCESS,
  payload: response,
});

export const searchByCriteriaUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_FAILED,
  payload: error,
});
//search by content api request
export const searchContentUserGuideRequest = data => ({
  type: USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_REQUEST,
  payload: data,
});

export const searchContentUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_SUCCESS,
  payload: response,
});

export const searchContentUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_FAILED,
  payload: error,
});
