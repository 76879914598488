import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import Plotly from 'plotly.js';
import Plot from 'react-plotly.js';
import {
  PlotlyPNGLogo,
  PlotlyCSVLogo,
  CHART_LABEL_COLORS,
  CWP_COLORS,
  CWP_PERENNIAL_COLORS,
  CWP_PERENNIAL_CROP_AREAS,
} from '../../../Components/constants';
import ReactExport from 'react-export-excel';
import { Alert } from 'reactstrap';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const CropGraphData = (props) => {
  const {
    graphList,
    finalGraphList,
    isCWPGraphSingleCase,
    formData,
    fileName,
    isCOPKgSelected,
    isLoading,
    errorMessage,
  } = props;
  const [yOptions, setYOptions] = useState([]);
  const [dates, setDates] = useState('');
  const [finalSelectedIndicators, setFinalSelectedIndicators] = useState('');
  const [layout, updateLayout] = useState({
    title: {
      // text: `<b> Data Source : ${dataSource[0]?.name} ${dataSource[0]?.id === DATA_SOURCES.IRI.id ? '' : `(${temporalAggregation?.name})`}  </b>`,
    },
    bargap: 0.5,
    barmode: 'group',
    modebardisplay: true,
    font: {
      size: 12,
      color: '#000',
    },
    xaxis: {
      domain: [0, 0.8],
      autorange: true,
      range: [-0.5, 0.5],
      showline: true,
      showgrid: false,
      type: 'multicategory',
      tickangle: 50,
      tickfont: {
        size: 12,
        color: '#000',
        family: 'National',
      },
    },
    yaxis: {
      showline: true,
      titlefont: { color: '#1f77b4' },
      tickfont: { color: '#1f77b4' },
      zeroline: false,
      showgrid: false,
      hoverformat: '.2r',
    },
    yaxis2: {
      showline: true,
      titlefont: { color: '#ff7f0e' },
      tickfont: { color: '#ff7f0e' },
      anchor: 'x',
      overlaying: 'y',
      side: 'right',
      zeroline: false,
      showgrid: false,
      hoverformat: '.2r',
    },
    yaxis3: {
      showline: true,
      titlefont: { color: '#9467bd' },
      tickfont: { color: '#9467bd' },
      anchor: 'free',
      overlaying: 'y',
      side: 'right',
      position: 0.9,
      zeroline: false,
      showgrid: false,
      hoverformat: '.2r',
    },
    yaxis4: {
      showline: true,
      // title: 'COP (Rs/1000 nuts)',
      title: {
        text: `<b>COP (Rs/1000 nuts)</b>`,
        plot_bgcolor: '#E5ECF6',
        font: {
          family: 'National',
          color: '#A0AD51',
          size: 15,
        },
      },
      titlefont: { color: '#A0AD51' },
      tickfont: { color: '#A0AD51' },
      anchor: 'free',
      overlaying: 'y',
      side: 'right',
      position: 1,
      zeroline: false,
      showgrid: false,
      hoverformat: '.2r',
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      x: 0.5,
      xanchor: 'center',
      y: -0.6,
      trackorder: 'normal',
      font: {
        // family: 'sans-serif',
        size: 15,
        color: '#000',
        family: 'National',
      },
    },
  });
  const ButtonClick = useRef('');
  const [csvFileData, setCsvFileData] = useState([]);

  const [cwpParams, setCwpParams] = useState([
    {
      name: 'Extent (Hectares)',
      visible: true,
      dash: '',
      type: 'bar',
      mode: 'lines+markers',
    },
    {
      name: 'Production (MT)',
      visible: true,
      dash: '',
      type: 'scatter',
      mode: 'lines+markers',
    },
    {
      name: 'COP (Rs/Kg)',
      visible: true,
      dash: '',
      type: 'scatter',
      mode: 'lines+markers',
    },
  ]);

  const getTickAngle = (start, end, season, isSingleCase) => {
    let tickangle = null;
    let maxRange = season?.length === 2 || season?.some((i) => i.slug === 'maha') ? 3 : 10;
    if (end - start >= maxRange || !isSingleCase) {
      tickangle = 50;
    } else {
      tickangle = 0;
    }
    return tickangle;
  };

  const getYSpace = (season, isSingleCase) => {
    let space;
    if (season?.length === 2 || season?.some((i) => i.slug === 'maha') || !isSingleCase) {
      space = -0.7;
    } else {
      space = -0.3;
    }
    return space;
  };

  useEffect(() => {
    if (finalGraphList?.length) {
      setFinalSelectedIndicators(finalGraphList);
    }
  }, [finalGraphList, isCOPKgSelected]);

  useEffect(() => {
    if (finalSelectedIndicators?.length) {
      let options = [];
      finalSelectedIndicators?.map((i, index) => {
        let returned_values = handleNormalCaseTraces(
          finalSelectedIndicators,
          i,
          index,
          isCWPGraphSingleCase,
          isCOPKgSelected,
        );
        options.push(returned_values);
      });

      let finalLayout = _.cloneDeep(layout);
      finalLayout = {
        ...layout,
        xaxis: {
          domain: [0, 0.8],
          showline: true,
          showgrid: false,
          autorange: true,
          range: [-0.5, 0.5],
          type: isCWPGraphSingleCase ? 'category' : 'multicategory',
        },
        legend: {
          orientation: 'h',
          x: 0.5,
          xanchor: 'center',
          y: -0.3,
        },
      };

      finalSelectedIndicators?.map((param, index) => {
        if (
          CWP_PERENNIAL_CROP_AREAS['Extent (Hectares)'] === param?.crop_area &&
          param?.showlegend
        ) {
          finalLayout['yaxis'] = {
            // title: param?.crop_area, //for multiple y-axis------
            title: {
              text: `<b>${param?.crop_area}</b>`,
              plot_bgcolor: '#E5ECF6',
              font: {
                family: 'National',
                color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area],
                size: 15,
              },
            },
            titlefont: {
              color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area],
              family: 'National',
            },
            tickfont: { color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area] },
            marker: { color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area] },
            showline: true,
            showgrid: false,
            rangemode: 'tozero',
            zeroline: false,
          };
        } else if (
          CWP_PERENNIAL_CROP_AREAS['Production (MT)'] === param?.crop_area &&
          param?.showlegend
        ) {
          finalLayout['yaxis2'] = {
            // title: param?.crop_area, //for multiple y-axis------
            title: {
              text: `<b>${param?.crop_area}</b>`,
              plot_bgcolor: '#E5ECF6',
              font: {
                family: 'National',
                color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area],
                size: 15,
              },
            },
            titlefont: {
              color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area],
              family: 'National',
            },
            tickfont: { color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area] },
            line: { color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area] },
            showline: true,
            anchor: 'x',
            overlaying: 'y',
            side: 'right',
            showgrid: false,
            rangemode: 'tozero',
            zeroline: false,
          };
        } else if (
          CWP_PERENNIAL_CROP_AREAS['COP (Rs/Kg)'] === param?.crop_area &&
          param?.showlegend
        ) {
          finalLayout['yaxis3'] = {
            // title: param?.crop_area, //for multiple y-axis------
            title: {
              text: `<b>${param?.crop_area}</b>`,
              plot_bgcolor: '#E5ECF6',
              font: {
                family: 'National',
                color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area],
                size: 15,
              },
            },
            titlefont: {
              color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area],
              family: 'National',
            },
            tickfont: { color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area] },
            line: { color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area] },
            showline: true,
            anchor: 'free',
            overlaying: 'y',
            side: 'right',
            position: 0.9,
            zeroline: false,
            rangemode: 'tozero',
            showgrid: false,
          };
        } else if (
          CWP_PERENNIAL_CROP_AREAS['COP (Rs/1000 nuts)'] === param?.crop_area &&
          param?.showlegend
        ) {
          finalLayout['yaxis4'] = {
            // title: param?.crop_area, //for multiple y-axis------
            title: {
              text: `<b>${param?.crop_area}</b>`,
              plot_bgcolor: '#E5ECF6',
              font: {
                family: 'National',
                color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area],
                size: 15,
              },
            },
            titlefont: {
              color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area],
              family: 'National',
            },
            tickfont: { color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area] },
            line: { color: CWP_PERENNIAL_COLORS['perennial'][param?.crop_area] },
            showline: true,
            anchor: 'free',
            overlaying: 'y',
            side: 'right',
            zeroline: false,
            showgrid: false,
            rangemode: 'tozero',
            position: isCOPKgSelected ? 1 : 0.9,
          };
        }
      });
      setYOptions(options);
      updateLayout(finalLayout);
    }
  }, [finalSelectedIndicators, isCWPGraphSingleCase, isCOPKgSelected]);

  const handleNormalCaseTraces = (chart_data, i, index, singleCase, isCOPKgSelected) => {
    let xaxisDates = [];
    xaxisDates = chart_data[index]?.xaxisData?.length ? chart_data[index]?.xaxisData : [];
    const ydata = {
      mode: 'lines+markers',
      name: `${i?.crop_area}`,
      x: xaxisDates,
      y: chart_data[index]?.data,
      visible: i?.visible,
      overlaying: 'y',
      yaxis: i?.yaxis,
      showgrid: false,
      showlegend: chart_data[index]?.showlegend,
    };

    if (i?.crop_area === 'Extent (Hectares)') {
      ydata.type = 'bar';
      ydata.yaxis = `y`;
      ydata.offsetgroup = 1;
    } else if (i?.crop_area === 'Production (MT)') {
      ydata.type = 'bar';
      ydata.yaxis = `y2`;
      ydata.offsetgroup = 2;
    } else if (i?.crop_area === 'COP (Rs/Kg)') {
      ydata.type = 'bar';
      ydata.yaxis = 'y3';
      ydata.position = 0.9; //for positioning right in y-axis
      ydata.offsetgroup = 3;
    } else if (i?.crop_area === 'COP (Rs/1000 nuts)') {
      ydata.type = 'bar';
      ydata.yaxis = `y4`;
      ydata.position = isCOPKgSelected ? 1 : 0.9;
      ydata.offsetgroup = 4;
      // ydata.showlegend = true;
    }

    if (ydata.type === 'scatter') {
      ydata.line = { color: CWP_PERENNIAL_COLORS['perennial'][i?.crop_area] };
    } else {
      ydata.marker = { color: CWP_PERENNIAL_COLORS['perennial'][i?.crop_area] };
    }
    if (!singleCase) {
      ydata.legendgroup = i?.crop_area;
      // if (ydata.showlegend) ydata.legendgrouptitle = i?.legendgrouptitle;
    }

    setDates(xaxisDates);
    return ydata;
  };

  const handleLegends = (graphData, isCOPKgSelected) => {
    let index = graphData.expandedIndex;
    let data = graphData.data[index];
    let newYOptions = [...yOptions];
    let newLayout = { ...layout };
    if (data.visible !== true) {
      if (CWP_PERENNIAL_CROP_AREAS['Production (MT)'] === data?.name) {
        if (newYOptions[index]) newYOptions[index]['yaxis'] = `y2`;
        let yaxisName = `yaxis2`;
        newLayout[yaxisName] = {
          // title: data?.name,
          title: {
            text: `<b>${data?.name}</b>`,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: CWP_PERENNIAL_COLORS['perennial'][data?.name],
              size: 15,
            },
          },
          titlefont: { color: CWP_PERENNIAL_COLORS['perennial'][data?.name], family: 'National' },
          tickfont: { color: CWP_PERENNIAL_COLORS['perennial'][data?.name] },
          anchor: 'x',
          overlaying: 'y',
          side: 'right',
          showline: true,
          showgrid: false,
          zeroline: false,
          rangemode: 'tozero',
        };
      } else if (CWP_PERENNIAL_CROP_AREAS['COP (Rs/1000 nuts)'] === data?.name) {
        if (newYOptions[index]) newYOptions[index]['yaxis'] = `y4`;
        let yaxisName = `yaxis4`;
        newLayout[yaxisName] = {
          // title: data?.name,
          title: {
            text: `<b>${data?.name}</b>`,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: CWP_PERENNIAL_COLORS['perennial'][data?.name],
              size: 15,
            },
          },
          titlefont: { color: CWP_PERENNIAL_COLORS['perennial'][data?.name], family: 'National' },
          tickfont: { color: CWP_PERENNIAL_COLORS['perennial'][data?.name] },
          overlaying: 'y',
          side: 'right',
          showline: true,
          showgrid: false,
          position: isCOPKgSelected ? 1 : 0.9,
          anchor: 'free',
          zeroline: false,
          rangemode: 'tozero',
        };
      } else if (CWP_PERENNIAL_CROP_AREAS['COP (Rs/Kg)'] === data?.name) {
        if (newYOptions[index]) newYOptions[index]['yaxis'] = `y3`;
        let yaxisName = `yaxis3`;
        newLayout[yaxisName] = {
          // title: data?.name,
          title: {
            text: `<b>${data?.name}</b>`,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: CWP_PERENNIAL_COLORS['perennial'][data?.name],
              size: 15,
            },
          },
          titlefont: { color: CWP_PERENNIAL_COLORS['perennial'][data?.name], family: 'National' },
          tickfont: { color: CWP_PERENNIAL_COLORS['perennial'][data?.name] },
          anchor: 'free',
          overlaying: 'y',
          side: 'right',
          position: 0.9,
          showline: true,
          showgrid: false,
          zeroline: false,
          rangemode: 'tozero',
        };
      } else if (CWP_PERENNIAL_CROP_AREAS['Extent (Hectares)'] === data?.name) {
        newYOptions[index]['yaxis'] = `y`;
        newLayout.yaxis = {
          // title: data?.name,
          title: {
            text: `<b>${data?.name}</b>`,
            plot_bgcolor: '#E5ECF6',
            font: {
              family: 'National',
              color: '#1f77b4',
              size: 15,
            },
          },
          titlefont: { color: '#1f77b4', family: 'National' },
          tickfont: { color: '#1f77b4' },
          side: 'left',
          showline: true,
          showgrid: false,
          zeroline: false,
          rangemode: 'tozero',
        };
      }
      setYOptions(newYOptions);

      updateLayout(newLayout);
    }
  };

  const getActiveLegends = (isDownloadCSV, graphData, graphList) => {
    if (document.getElementById(fileName)?.data?.length) {
      if (graphList.length) {
        setCsvFileData(graphList);
      }
      if (isDownloadCSV) {
        //downloading csv file
        ButtonClick.current.click();
      } else {
        //downloading png file
        Plotly.downloadImage(graphData, {
          filename: fileName,
          format: 'png',
          width: graphData._fullLayout.width,
          height: graphData._fullLayout.height,
        });
      }
    }
  };

  let config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true, //by default we are showing
    modeBarButtonsToRemove: ['toImage', 'lasso', 'autoscale', 'select'],
    modeBarButtonsToAdd: [
      {
        name: 'Download PNG',
        icon: PlotlyPNGLogo,
        click: function (gd) {
          getActiveLegends(false, gd, graphList);
        },
      },
      {
        name: 'Download CSV',
        icon: PlotlyCSVLogo,
        click: function (gd) {
          getActiveLegends(true, gd, graphList);
        },
      },
    ],
    showTips: false,
  };
  return (
    <div
      className="crop-prod-graph-div mx-2 px-4 py-4"
      style={{ height: '75vh', marginBottom: '15px' }}
    >
      <>
        {!_.isEmpty(finalGraphList) ? (
          <>
            {yOptions.find((data) => data.name === 'Production (MT)')?.y?.length === 1 && (
              <Plot
                data={yOptions}
                layout={layout}
                config={config}
                onLegendClick={(data) => handleLegends(data, isCOPKgSelected)}
                useResizeHandler={true}
                style={{ width: '100%', height: '100%' }}
                divId={fileName}
              />
            )}
            {/* {yOptions.find((data) => data.name === 'COP (Rs/Kg)')?.y?.length !== 1 && ( */}
            {yOptions.find((data) => data.name === 'Production (MT)')?.y?.length !== 1 && (
              <Plot
                data={yOptions}
                layout={layout}
                config={config}
                onLegendClick={(data) => handleLegends(data, isCOPKgSelected)}
                useResizeHandler={true}
                style={{ width: '100%', height: '100%' }}
                divId={fileName}
              />
            )}
            <ExcelFile
              filename={fileName}
              element={
                <button
                  ref={ButtonClick}
                  className="btn btn-download ml-auto"
                  style={{ display: 'none' }}
                >
                  Download
                </button>
              }
            >
              <ExcelSheet data={graphList} name="Risk_Analytics">
                <ExcelColumn label="Year" value="year" />
                <ExcelColumn label="Crop" value="crop_name" />
                <ExcelColumn label="Crop area" value="crop_area" />
                <ExcelColumn label="Production Data" value="production_data" />
              </ExcelSheet>
            </ExcelFile>
          </>
        ) : !isLoading ? (
          <div
            className="display-flex align-items-center justify-content-center text-align-center"
            // style={{ marginTop: '25%', marginLeft: '30%' }}
            style={{ marginTop: '25%', textAlign: 'center' }}
          >
            {/* <h3 style={{ fontSize: '20px' }}>
              Please select the filters from left side panel, to view the graph data.
            </h3> */}
            <div className="drought-error-container">
              <div className="drought-error-style">
                <Alert color="warning">{errorMessage}</Alert>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    </div>
  );
};

CropGraphData.propTypes = {};

export default CropGraphData;
