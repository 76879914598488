import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { AiFillCloseCircle } from 'react-icons/ai';
import { t } from 'react-multi-lang';
import Select from 'react-select';
import { TbPolygon } from 'react-icons/tb';
import { MdArrowDropDown } from 'react-icons/md';
import UploadIcon from '../../../assets/Images/upload-image.svg';
import { ReactComponent as UploadFarm } from '../../../assets/Images/upload_farm_icon.svg';
import { DEFAULT_ATTRIBUTES_FARM_TRACKER } from '../../../Components/constants';

const CreateFarm = (props) => {
  const {
    handleAddMoreAttributes,
    handleDeleteAttribute,
    handleFormFieldData,
    setFarmTitle,
    setFarmDescription,
    farmTitle,
    farmDescription,
    farmAttributes,
    fileHandler,
    imageFile,
    handleResetCollapseFarm,
    handleFarmAttributeChange,
    isCreateFarm,
    handleSubDistrictChange,
    handleBlockChange,
    subDistrictList,
    blockList,
    handleChangePolygonOptions,
    handleGeoTaggedImages,
    defaultPolygonProps,
    geoTaggedImages,
    geoTaggedImagesFiles,
    handleGeoTaggedImageRemoval,
    showPolygonDraw,
    changePolygonOptions,
    initialValues,
    farmOptions,
  } = props;
  const [isCreateFarmDisabled, setIsCreateFarmDisabled] = useState(true);
  const [isOpenChangePolygon, setIsOpenChangePolygon] = useState(false);
  const [geoTagFileUploadError, setGeoTagFileUploadError] = useState('');
  const [geotaggedUploadDisable, setGeotaggedUploadDisable] = useState(false);
  const [isHideDelete, setIsHideDelete] = useState(false);

  useEffect(() => {
    if (farmTitle && farmDescription) {
      if (farmAttributes?.length) {
        let isAttributesExists = farmAttributes?.every((item) => item?.name && item?.value);
        let isLogiAndLatiExists =
          changePolygonOptions[1]?.selected || farmOptions[1]?.selected
            ? true
            : farmAttributes?.some(
                (item) => item?.name === 'Latitude' || item?.name === 'Longitude',
              );
        if (isAttributesExists && isLogiAndLatiExists) {
          setIsCreateFarmDisabled(false);
        } else {
          setIsCreateFarmDisabled(true);
        }
      } else {
        setIsCreateFarmDisabled(false);
      }
    } else {
      setIsCreateFarmDisabled(true);
    }
  }, [farmTitle, farmDescription, farmAttributes]);

  useEffect(() => {
    if (geoTaggedImages?.length === 5) {
      setGeotaggedUploadDisable(true);
      setGeoTagFileUploadError('');
      setIsCreateFarmDisabled(false);
    } else if (geoTaggedImages?.length > 5) {
      setGeotaggedUploadDisable(true);
      setIsCreateFarmDisabled(true);
      setGeoTagFileUploadError('Maximum 5 files can be uploaded.');
    } else {
      setGeoTagFileUploadError('');
      setGeotaggedUploadDisable(false);
      // setIsCreateFarmDisabled(false);
    }
  }, [geoTaggedImages]);

  useEffect(() => {
    if (
      farmAttributes[0]?.name === 'Latitude' &&
      farmAttributes[1]?.name === 'Longitude' &&
      (farmAttributes?.length === 2 ||
        (farmAttributes[2]?.name === 'Area' && farmAttributes?.length === 3))
    ) {
      setIsHideDelete(true);
    } else {
      setIsHideDelete(false);
    }
  }, [farmAttributes]);

  const handleSave = () => {
    handleFormFieldData();
  };

  const handleResetForm = () => {
    handleResetCollapseFarm();
  };

  const handleFarmTitleChange = (e) => {
    setFarmTitle(e.target.value);
  };
  // const [farmDescription, setFarmDescription] = useState('');

  let defaultValues = {
    purpose: '',
  };

  const [formData, setFormData] = useState('');

  const handleFarmDescriptionChange = (e) => {
    // setFarmDescription(e.target.value);
    const limit = 500;

    // 👇️ only take first N characters
    setFarmDescription(e.target.value.slice(0, limit));
  };

  const handleFileChange = (e) => {
    if (e?.target?.value) {
      fileHandler(e.target.files[0]);
    }
  };

  const handleGeoTaggedImageChange = (e) => {
    if (!geotaggedUploadDisable) {
      if (e?.target?.files?.length <= 5) {
        let filesList = [...e?.target?.files];
        handleGeoTaggedImages(filesList);
        setGeoTagFileUploadError('');
      } else {
        setGeoTagFileUploadError('Maximum 5 files can be uploaded.');
      }
    }
  };

  return (
    <>
      <form className="mx-4 mt-2 farm-api-form">
        <FormGroup className="textOnInput text-align-center">
          <Label for="title" className="label-st">
            {t('FARM_TRACKER.NAME') + '*'}
          </Label>
          <Input
            name="title"
            type="text"
            className="form-control farm-input-style-css"
            onChange={handleFarmTitleChange}
            value={farmTitle}
            required
          />
        </FormGroup>
        <FormGroup className="textOnInput text-align-center ">
          <Label for="description" className="label-st">
            {t('FARM_TRACKER.DESCRIPTION') + '*'}
          </Label>
          <Input
            name="description"
            type="textarea"
            className="farm-input-style-css"
            onChange={handleFarmDescriptionChange}
            value={farmDescription}
            rows={5}
            // cols={5}
            required={true}
            style={{ minHeight: '100px' }}
          />
        </FormGroup>
        <FormGroup className="textOnInput text-align-center filter-select-placeholder mb-3">
          {/* <Label for="upload_image">Upload Profile Icon</Label> */}
          <span className="farm-upload-image">
            <label for={'upload_image'} className="farm-upload-image-label form-control">
              Upload profile image
              <img src={UploadIcon} alt={'not-available'} className="upload-icon" />
            </label>
          </span>
          <Input
            name="upload_image"
            id="upload_image"
            type="file"
            // accept="image/*"
            accept=".png, .jpg, .jpeg"
            className="my-3 mt-2 farm-upload-image-field farm-farm-input-style-css"
            onChange={(e) => handleFileChange(e)}
            isMulti={false}
            placeholder={t('NEWS_FEED.UPLOAD_IMAGE')}
            style={{ display: 'none' }}
          ></Input>
        </FormGroup>
        {imageFile && (
          <div>
            <Row style={{ marginTop: '10px' }}>
              <Col lg="9" className="mx-auto">
                <div className={imageFile === undefined ? '' : 'mb-3 news-image'}>
                  {imageFile ? (
                    <img className="img-fluid" src={imageFile} alt={'not-available'} />
                  ) : (
                    imageFile !== undefined && (
                      <p className="new-upload-no-data">{t('NEWS_FEED.NO_DATA')}</p>
                    )
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}
        {/* {imageFile ? <hr /> : ''} */}
        {/* <FormGroup
          className="textOnInput text-align-center filter-select-placeholder mb-3"
          style={geotaggedUploadDisable ? { pointerEvents: 'none' } : {}}
        > */}
        {/* <Label for="upload_image">Upload Profile Icon</Label> */}
        {/* <span className="farm-upload-image">
            <label for={'upload_geotagged'} className="farm-upload-image-label form-control">
              {t('FARM_TRACKER.GEOTAGGED_IMAGE')}
              <img src={UploadIcon} alt={'not-available'} className="upload-icon" />
            </label>
          </span>
          <Input
            name="upload_geotagged"
            id="upload_geotagged"
            type="file"
            accept=".png, .jpg, .jpeg"
            className="my-3 mt-2 farm-upload-image-field farm-farm-input-style-css"
            onChange={(e) => handleGeoTaggedImageChange(e)}
            isMulti={true}
            multiple
            placeholder={t('FARM_TRACKER.GEOTAGGED_IMAGE')}
            style={{ display: 'none' }}
          ></Input>
          {geoTagFileUploadError ? (
            <span invalid className="invalid-feedback">
              {geoTagFileUploadError}
            </span>
          ) : (
            ''
          )}
        </FormGroup> */}
        {geoTaggedImagesFiles
          ? geoTaggedImagesFiles?.map((item) => {
              return (
                <div className="image-geotagged-container">
                  <Row style={{ marginTop: '10px' }}>
                    <Col lg="9" className="mx-auto">
                      <div
                        class="geotagged-close-button"
                        title="Close Button"
                        onClick={() => handleGeoTaggedImageRemoval(item)}
                      >
                        <AiFillCloseCircle size={17} />
                      </div>
                      <div
                        className={item === undefined ? '' : 'mb-1 news-image geotagged-img-style'}
                      >
                        {item?.url ? (
                          <img className="img-fluid" src={item?.url} alt={'not-available'} />
                        ) : (
                          item?.url !== undefined && (
                            <p className="new-upload-no-data">{t('NEWS_FEED.NO_DATA')}</p>
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })
          : ''}
        {geoTaggedImages?.length ? <hr /> : ''}
        {!isCreateFarm ? (
          <>
            <FormGroup 
            className="textOnInput text-align-center mb-3 filter-select-placeholder float-style"
            >
              <Label for="sub_district" className="choose_label">
                {t('FARM_TRACKER.SUB_DISTRICTS')}
              </Label>
              <Select
                name="sub_district"
                className="farm-input-style-css"
                id="sub_district"
                options={subDistrictList || []}
                onChange={handleSubDistrictChange}
                placeholder=""
                value={initialValues?.sub_district}
                // isDisabled={props.cordinatesAndZoom?.zoom < 10 ? false : true}
                isMulti={false}
              />
            </FormGroup>
            <FormGroup className="textOnInput text-align-center filter-select-placeholder float-style mb-3">
              <Label for="block" className="choose_label">
                {t('FARM_TRACKER.BLOCKS')}
              </Label>
              <Select
                name="block"
                className="farm-input-style-css"
                id="block"
                onChange={handleBlockChange}
                options={blockList || []}
                placeholder=""
                value={initialValues?.block}
                // isDisabled={props.cordinatesAndZoom?.zoom < 10 ? false : true}
                isMulti={false}
              />
            </FormGroup>
          </>
        ):<></>}

        {defaultPolygonProps?.length ? defaultPolygonProps?.map((item)=>{
          return (
            <FormGroup className="textOnInput text-align-center">
            <Label for={item.name} className="label-st" style={{ color: 'hsl(0, 0%, 20%)'}}>
              {item?.name === DEFAULT_ATTRIBUTES_FARM_TRACKER.AREA ? (
                `${item.name} (in ha)`
              ): (
                item?.name
              )}
            </Label>
            <Input
              name={item.name}
              type="number"
              className="form-control farm-input-style-css"
              value={item?.value}
              disabled
              required
            />
          </FormGroup>
          )
        }):<></>}
       
        {farmAttributes?.length && farmAttributes && farmAttributes?.some(i => i?.name !== DEFAULT_ATTRIBUTES_FARM_TRACKER.LATITUDE && i?.name !== DEFAULT_ATTRIBUTES_FARM_TRACKER.LONGITUDE && i?.name !== DEFAULT_ATTRIBUTES_FARM_TRACKER.AREA) ? (
          <div className="mt-2" style={{ overflowY: 'auto', maxHeight: '25vh' }}>
            {farmAttributes?.length
              ? farmAttributes?.map((item, index) => {
                if (item.name !== DEFAULT_ATTRIBUTES_FARM_TRACKER.LATITUDE && item.name !== DEFAULT_ATTRIBUTES_FARM_TRACKER.LONGITUDE && item.name !== DEFAULT_ATTRIBUTES_FARM_TRACKER.AREA) {
                  return (
                    <>
                      <FormGroup className="textOnInput text-align-center mb-2">
                        <Input
                          name="attr"
                          type="text"
                          className="form-control farm-input-style-css"
                          onChange={(e) => handleFarmAttributeChange(e, 'name', index)}
                          value={item?.name}
                          placeholder="Name"
                          required
                        />
                        <Input
                          name="value"
                          type="number"
                          className="form-control farm-input-style-css disable-icons"
                          onChange={(e) => handleFarmAttributeChange(e, 'value', index)}
                          value={
                            item?.name === 'Latitude' || item?.name === 'Longitude'
                              ? parseFloat(item?.value).toFixed(2)
                              : item?.value
                          }
                          placeholder="Value"
                          disabled={
                            item?.name === 'Latitude' ||
                              item?.name === 'Longitude' ||
                              item?.name === 'Area'
                              ? true
                              : false
                          }
                          required
                        />
                      </FormGroup>
                    </>
                  );
                }
              })
              : ''}
          </div>
        ) : (
          ''
        )}

        <div
          className={
            imageFile
              ? 'd-flex justify-content-between add-att-img-margin'
              : 'd-flex justify-content-between add-att-margin'
          }
        >
          {farmAttributes?.length <= props.attributeLimit - 1 ? (
            <span
              className="add-at-st cursor-ponter"
              onClick={() =>
                handleAddMoreAttributes({ name: '', value: '' }, { isDefaultProps: false })
              }
            >
              <HiOutlinePlusCircle size={18} /> <span>{t('FARM_TRACKER.ADD_ATTRIBUTES')}</span>
            </span>
          ) : (
            ''
          )}

          {farmAttributes?.length && !isHideDelete ? (
            <span
              className={'text-danger cursor-ponter float-right delete-at-st'}
              onClick={handleDeleteAttribute}
            >
              {t('FARM_TRACKER.DELETE')}
            </span>
          ) : (
            ''
          )}
        </div>

        <div className="collapse-farm-bt mx-2 mb-3 d-flex justify-content-between">
          {isCreateFarm ? (
            <button
              type="button"
              className="btn create-farm-bt-outline col-lg-5 py-2 px-1 rounded-1"
              onClick={() => handleResetForm()}
            >
              {t('FARM_TRACKER.CANCEL')}
            </button>
          ) : (
            <>
              {/* <ReactTooltip
                id="main"
                type="info"
                getContent={() =>
                  'To change the Polygon, please remove the existing polygon from Map'
                }
                multiline={true}
                place={'top'}
              /> */}

              <Dropdown
                isOpen={isOpenChangePolygon}
                toggle={() => setIsOpenChangePolygon(!isOpenChangePolygon)}
                className="farm-add-cls"
                direction="down"
              >
                <DropdownToggle className="toggle-bt mx-1 px-2 py-2">
                  <span
                  // data-for="main"
                  // data-tip="Hello<br />multiline<br />tooltip"
                  // data-iscapture="true"
                  >
                    {' '}
                    {/* Change Polygon{' '} */}
                    Edit Farm{' '}
                  </span>
                  <MdArrowDropDown />
                </DropdownToggle>
                <DropdownMenu className="add-details-dropdown-menu">
                  {/* <DropdownItem
                    className="farm-details-dropdown-item"
                    onClick={() => handleChangePolygonOptions('upload_farm')}
                    data-for="farmTracker1"
                    data-tip="Hello<br />multiline<br />tooltip"
                    data-iscapture="true"
                    // title={'Choosing upload farm will allow you to upload new farm image.'}
                  >
                    {' '}
                    <span>
                      <UploadFarm />
                    </span>
                    <span className="mx-1">{t('FARM_TRACKER.UPLOAD_FILE')}</span>
                    <ReactTooltip
                      id="farmTracker1"
                      effect="solid"
                      type="info"
                      place="top"
                      style={{ marginLeft: '100px', left: '10px' }}
                    > 
                      Choosing upload farm option will <br /> allow you to upload the new
                      farm. <br />(Supported file format KML, WKT)
                    </ReactTooltip>
                  </DropdownItem> */}

                  <DropdownItem
                    className="farm-details-dropdown-item"
                    onClick={() => handleChangePolygonOptions('draw_farm')}
                    // title={'choosing draw farm will allow you to draw another polygon.'}
                    disabled={showPolygonDraw}
                    data-for="farmTracker2"
                    data-tip="Hello<br />multiline<br />tooltip"
                    data-iscapture="true"
                    style={{ zIndex: 1000 }}
                  >
                    {/* <span data-for="main1"
                    data-tip="Hello<br />multiline<br />tooltip"
                    data-iscapture="true"> */}
                    <TbPolygon size={18} /> {t('FARM_TRACKER.DRAW_FARM')}
                    {/* </span> */}
                    <ReactTooltip
                      id="farmTracker2"
                      effect="solid"
                      place="top"
                      type="info"
                      style={{ marginLeft: '100px', left: '10px' }}
                    >
                      {/* Click here to Know more. */}
                      Choosing draw farm option will <br /> delete the existing farm <br /> and
                      allow you to draw new <br /> farm.
                    </ReactTooltip>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          )}

          <button
            type="submit"
            className="btn create-farm-bt-st px-3 rounded-1"
            disabled={isCreateFarmDisabled}
            onClick={() => handleSave()}
          >
            {isCreateFarm ? t('FARM_TRACKER.SAVE') : t('FARM_TRACKER.UPDATE')}
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateFarm;
