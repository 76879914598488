import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, FARM_TRACKER_URL } from '../api/apiEndPoint';
import FARM_TRACKER_CONST from './farmTrackerConsts';
import _ from 'lodash';
import {
  getFarmDetailsByIdSuccess,
  getFarmDetailsByIdFailed,
  getAllByCriteriaFarmListSuccess,
  getAllByCriteriaFarmListFailed,
  addFarmTrackerSuccess,
  addFarmTrackerFailed,
  editFarmTrackerSuccess,
  editFarmTrackerFailed,
  deleteFarmTrackerSuccess,
  deleteFarmTrackerFailed,
  getProjectDetailsByIdSuccess,
  getProjectDetailsByIdFailed,
  getAllByCriteriaProjectListSuccess,
  getAllByCriteriaProjectListFailed,
  addProjectSuccess,
  addProjectFailed,
  editProjectSuccess,
  editProjectFailed,
  deleteProjectSuccess,
  deleteProjectFailed,
  addMultipleFarmsSuccess,
  addMultipleFarmsFailed,
} from './farmTrackerActions';

const addFarmDataApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${FARM_TRACKER_URL}/add`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* addFarmData(action) {
  try {
    const apiResponse = yield call(addFarmDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(addFarmTrackerSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(addFarmTrackerFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addFarmTrackerFailed(errorData));
  }
}

const editFarmDataApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.put(`${FARM_TRACKER_URL}/edit`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* editFarmData(action) {
  try {
    const apiResponse = yield call(editFarmDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(editFarmTrackerSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(editFarmTrackerFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(editFarmTrackerFailed(errorData));
  }
}

const deleteFarmDataApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.delete(`${FARM_TRACKER_URL}/delete/${payload?.id}`);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* deleteFarmData(action) {
  try {
    const apiResponse = yield call(deleteFarmDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(deleteFarmTrackerSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(deleteFarmTrackerFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteFarmTrackerFailed(errorData));
  }
}

const getFarmDetailsDataApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${FARM_TRACKER_URL}/getById?id=${payload?.id}`);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getFarmDetailsData(action) {
  try {
    const apiResponse = yield call(getFarmDetailsDataApiRequest, action.payload);
    if (!_.isEmpty(apiResponse) && apiResponse?.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getFarmDetailsByIdSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getFarmDetailsByIdFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getFarmDetailsByIdFailed(errorData));
  }
}

const getFarmListDataApiRequest = async (payload) => {
  let response = {};
  try {
    const data = payload.page
      ? await AXIOS_INSTANCE.get(
          `${FARM_TRACKER_URL}/getAllByCriteria?page=${payload.page}&&farm_tracker_project_id=${payload.farm_tracker_project_id}`,
        )
      :  payload?.farm_tracker_id ? await AXIOS_INSTANCE.get(
          `${FARM_TRACKER_URL}/getAllByCriteria?farm_tracker_project_id=${payload.farm_tracker_project_id}&&farm_tracker_id=${payload.farm_tracker_id}`,
        ) : await AXIOS_INSTANCE.get(
          `${FARM_TRACKER_URL}/getAllByCriteria?farm_tracker_project_id=${payload.farm_tracker_project_id}`,
        );
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getFarmListData(action) {
  try {
    const apiResponse = yield call(getFarmListDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getAllByCriteriaFarmListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getAllByCriteriaFarmListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllByCriteriaFarmListFailed(errorData));
  }
}

const getProjectDetailsDataApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${FARM_TRACKER_URL}/project/getById`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getProjectDetailsData(action) {
  try {
    const apiResponse = yield call(getProjectDetailsDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getProjectDetailsByIdSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getProjectDetailsByIdFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getProjectDetailsByIdFailed(errorData));
  }
}

const getProjectListDataApiRequest = async (action) => {
  let response = {};
  try {
    const data = action?.module_name
        ? await AXIOS_INSTANCE.get(
            `${FARM_TRACKER_URL}/project/list?module_name=${action.module_name}`,
          )
        : (action?.district
        ? await AXIOS_INSTANCE.get(
            `${FARM_TRACKER_URL}/project/list?${action.pageKey}=${action.page}&&${action.limitKey}=${action.limit}&&${action.districtKey}=${action.district}`,
          )
        : await AXIOS_INSTANCE.get(
            `${FARM_TRACKER_URL}/project/list?${action.pageKey}=${action.page}&&${action.limitKey}=${action.limit}`,
          ));
      response = {
        data: data.data,
        status: 200,
      };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getProjectListData(action) {
  try {
    const apiResponse = yield call(getProjectListDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getAllByCriteriaProjectListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getAllByCriteriaProjectListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllByCriteriaProjectListFailed(errorData));
  }
}

const addFarmProjectDataApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${FARM_TRACKER_URL}/project/add`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* addFarmProjectData(action) {
  try {
    const apiResponse = yield call(addFarmProjectDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(addProjectSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(addProjectFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addProjectFailed(errorData));
  }
}

const editFarmProjectDataApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.put(`${FARM_TRACKER_URL}/project/edit`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* editFarmProjectData(action) {
  try {
    const apiResponse = yield call(editFarmProjectDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(editProjectSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(editProjectFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(editProjectFailed(errorData));
  }
}

const deleteFarmProjectDataApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.delete(`${FARM_TRACKER_URL}/project/delete/${payload}`);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* deleteFarmProjectData(action) {
  try {
    const apiResponse = yield call(deleteFarmProjectDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(deleteProjectSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(deleteProjectFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteProjectFailed(errorData));
  }
}

export function* watchFarmDetails() {
  yield takeEvery(FARM_TRACKER_CONST.GET_FARM_DETAILS_BY_ID_REQUEST, getFarmDetailsData);
}

export function* watchFarmList() {
  yield takeEvery(FARM_TRACKER_CONST.GET_FARM_LIST_REQUEST, getFarmListData);
}

export function* watchAddFarm() {
  yield takeEvery(FARM_TRACKER_CONST.ADD_FARM_TRACKER_REQUEST, addFarmData);
}

export function* watchDeleteFarm() {
  yield takeEvery(FARM_TRACKER_CONST.DELETE_FARM_TRACKER_REQUEST, deleteFarmData);
}

export function* watchEditFarm() {
  yield takeEvery(FARM_TRACKER_CONST.EDIT_FARM_TRACKER_REQUEST, editFarmData);
}

export function* watchProjectDetails() {
  yield takeEvery(FARM_TRACKER_CONST.GET_PROJECT_DETAILS_BY_ID_REQUEST, getProjectDetailsData);
}

export function* watchProjectList() {
  yield takeEvery(FARM_TRACKER_CONST.GET_PROJECT_LIST_REQUEST, getProjectListData);
}

export function* watchAddProject() {
  yield takeEvery(FARM_TRACKER_CONST.ADD_PROJECT_REQUEST, addFarmProjectData);
}

export function* watchEditProject() {
  yield takeEvery(FARM_TRACKER_CONST.EDIT_PROJECT_REQUEST, editFarmProjectData);
}

export function* watchDeleteProject() {
  yield takeEvery(FARM_TRACKER_CONST.DELETE_PROJECT_LIST_REQUEST, deleteFarmProjectData);
}

function* FarmTrackerSaga() {
  yield all([
    fork(watchEditFarm),
    fork(watchAddFarm),
    fork(watchDeleteFarm),
    fork(watchFarmList),
    fork(watchFarmDetails),
    fork(watchAddProject),
    fork(watchEditProject),
    fork(watchProjectList),
    fork(watchProjectDetails),
    fork(watchDeleteProject),
  ]);
}

export default FarmTrackerSaga;
