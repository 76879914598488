import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavLink,
} from 'reactstrap';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/common/loader';
import CropProductionFilter from './filter';
import SeasonalGraphData from './seasonalGraphData';
import PerennialGraphData from './perennialGraphData';
import { showError } from '../../../Components/Notifications';
import {
  CROP_PRODUCTION_FILTER_SLUG_CONSTS,
  CROP_PRODUCTION_FILTER_TABS,
  CWP_PERENNIAL_CROP_AREAS,
  LOCATION,
  RISK_ANALYTICS_MODULE,
} from '../../../Components/constants';
import _, { filter, update } from 'lodash';
import {
  getCWPCropsRequest,
  getCWPGraphDataRequest,
  getCWPGraphDataSuccess,
  getDistrictByStateRequest,
} from '../../../redux/actions';
import { change } from 'redux-form';
import { renderCWPYearList } from '../../../Components/common/utils';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { active } from 'd3';

const CropProductionModal = (props) => {
  const { modalOpen, setModalOpen, countryList, provinceList, defaultCountry, createFarmData,projectDetails } =
    props;
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(CROP_PRODUCTION_FILTER_TABS.SEASONAL.value);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [cropList, setCropList] = useState([]);
  const [seasonList, setSeasonList] = useState([]);
  const [startYearList, setStartYearList] = useState([]);
  const [perennialStartYearList, setPerennialStartYearList] = useState([])
  const [endYearList, setEndYearList] = useState([]);
  const [perennialEndYearList, setPerennialEndYearList] = useState([])
  const [practisesList, setPractisesList] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const [fileName, setFileName] = useState('');
  const [isViewGraph, setIsViewGraph] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCOPKgSelected, setIsCOPKgSelected] = useState(false);
  const [graphList, setGraphList] = useState('');
  const [minMaxError, setMinMaxError] = useState(false);
  const [isCWPGraphSingleCase, setIsCWPGraphSingleCase] = useState(true);
  const [finalGraphList, setFinalGraphList] = useState([]);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [isDefaultSelection,setIsDefaultSelection] = useState(true);
  const [formData, setFormData] = useState({
    country: {},
    state: {},
    district: {},
    crop: [],
    perennialCrops:[],
    season: [],
    practise: [],
    startYear: '',
    endYear: '',
    perennialStartYear:'',
    perennialEndYear:'',
  });
  const [farmData, setFarmData] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const { Location, RiskAnalytics } = useSelector((state) => ({
    Location: state.Location,
    FarmTracker: state.FarmTracker,
    RiskAnalytics: state.RiskAnalytics,
  }));

  useEffect(() => {
    return () => {
      dispatch(getCWPGraphDataSuccess(''));
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(createFarmData)) {
      setFilterData((prev) => {
        return {
          ...prev,
          province: createFarmData?.province,
          district: createFarmData?.district,
        };
      });
      setFarmData({...createFarmData});
      if (
        !_.isEmpty(createFarmData?.province) &&
        !_.isEmpty(createFarmData?.province?.value)
      ) {
        let requestObj = { location_type: LOCATION.District, parent_id: createFarmData?.province?.id };
        dispatch(getDistrictByStateRequest({ requestObj, isCompareLocation: false }));
      }
    }
  }, [createFarmData]);

  useEffect(()=>{
    if(provinceList?.length && _.isEmpty(createFarmData)){
      if(isDefaultSelection){
        if(formData?.state?.id){
          setFilterData(prev => {
            return {
              ...prev, 
              province: formData?.state
            }
          })
        }
        else{
          setFilterData(prev => {
            return {
              ...prev, 
              province: provinceList[0]
            }
          })
        }
      }
    }
  },[provinceList,createFarmData, isDefaultSelection])

  useEffect(()=>{
    if(districtList?.length && _.isEmpty(createFarmData)){
      if(isDefaultSelection){
        if(formData?.district?.length){
          setFilterData(prev => {
            return {
              ...prev, 
              district: formData?.district
            }
          })
        }
        else{
          setFilterData(prev => {
            return {
              ...prev, 
              district: [districtList[0]]
            }
          })
        }
      }
    }
  },[districtList,createFarmData,isDefaultSelection])

  useEffect(() => {
    if (!_.isEmpty(filterData?.province) && provinceList?.length && isDefaultSelection) {
      if(activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value){
        if (filterData?.province.value === '') {
          setSelectedLocation((...prev) => ({ ...prev, state: '', district: '' }));
          setDistrictList([]);
          // dispatch(change('CropProductionFilter', 'district', ''));
          setFormData({ ...formData, state: '', district: '' });
        } else {
          formData.state = filterData?.province;
          setFormData({ ...formData });
          setSelectedLocation((prev) => {
            return {
              ...prev,
              state: filterData?.province,
            };
          });
        }  
      }
        
    }
  }, [filterData?.province, provinceList, isDefaultSelection,activeTab]);

  useEffect(() => {
    if (!_.isEmpty(filterData?.district) && !_.isEmpty(formData?.state) && isDefaultSelection) {
      if (activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value) {
        if (filterData?.district.value === '') {
          formData.district = '';
          setFormData({ ...formData });
          setSelectedLocation((...prev) => ({ ...prev, district: '' }));
        } else {
          formData.district = filterData?.district;
          setFormData({ ...formData });
          setSelectedLocation((prev) => {
            return {
              ...prev,
              district: filterData?.district,
            };
          });
        }
      }
    }
  }, [filterData?.district, formData?.state, isDefaultSelection, activeTab]);
  
  useEffect(() => {
    let arr = [];
    if (Location.districtList) {
      let result = '';
      let config = Location?.districtList?.config;
      if (Location?.districtList?.data) result = Location?.districtList?.data?.result;
      if (result) {
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
            disabled: false,
          });
        });
      }
      if (!config?.isCompareLocation) {
        // dispatch(change('CropProductionFilter', 'district', ''));
        setDistrictList(arr);
      }
    }
  }, [Location.districtList]);

  useEffect(() => {
    if (!_.isEmpty(defaultCountry)) {
      setSelectedLocation((prev) => {
        return {
          ...prev,
          country: defaultCountry,
        };
      });
      formData.country = defaultCountry;
      setFormData({ ...formData });
      setFilterData((prev) => {
        return {
          ...prev,
          country: defaultCountry,
        };
      });
    }
  }, [defaultCountry]);

  useEffect(() => {
    if (RiskAnalytics?.getCWPGraphDataRequesting) {
      setIsLoading(true);
    }
    if (RiskAnalytics?.getCWPGraphData) {
      setIsLoading(false);
      if (RiskAnalytics?.getCWPGraphData?.data) {
        let { result } = RiskAnalytics?.getCWPGraphData?.data;
        if (result) {
          if (result[0]?.length === 1) {
            if (result[0]?.[0]?.length === 0) {
              setShowErrorMsg(true);
            } else {
              setShowErrorMsg(false);
            }
          }
          let finalResult = [];
          if (activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value) {
            let updatedResult = result?.map((i) => {
              let updatedFilteredData = i
                ?.filter((data) => data.length)
                .map((item, index) => {
                  let updatedItem = item?.map((it, i) => {
                    let obj = { ...it };
                    obj.name = it?.crop_area;
                    obj.legendgroup = `${it?.district_name}-${it?.crop_name}-${it?.season_name}-${it?.practice_name}-${it?.year}`;
                    obj.visible = true;
                    return obj;
                  });
                  return updatedItem;
                });
              return updatedFilteredData;
            });
            if (updatedResult?.length) {
              updatedResult?.map((item, index) => {
                item?.map((x) => {
                  x?.map((it) => {
                    finalResult?.push(it);
                  });
                });
              });
            }
          } else if (activeTab === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value) {
            let updatedResult = result?.map((item, index) => {
              let updatedItem = item?.map((it, i) => {
                let obj = { ...it };
                obj.name = it?.crop_area;
                obj.legendgroup = `${it?.crop_name}-${it?.year}`;
                obj.visible = true;
                return obj;
              });
              return updatedItem;
            });
            if (updatedResult?.length) {
              updatedResult?.map((item, index) => {
                item?.map((it) => {
                  finalResult?.push(it);
                });
              });
            }
          }
          setGraphList(finalResult);
        }
      }
    }

    if (RiskAnalytics?.getCWPGraphDataError) {
      if (RiskAnalytics?.getCWPGraphDataError?.data?.response) {
        let { data } = RiskAnalytics?.getCWPGraphDataError?.data?.response;
        if (data?.exception) {
          // showError(data?.exception);
          setErrorMessage(data?.exception)
        }
        if (data?.message) {
          // showError(data?.message);
          setErrorMessage(data?.message)
          
        }
      }
      setIsLoading(false);
    }
  }, [
    RiskAnalytics?.getCWPGraphDataRequesting,
    RiskAnalytics?.getCWPGraphData,
    RiskAnalytics?.getCWPGraphDataError,
  ]);

  //  getting crop wise production crops list
  useEffect(() => {
    if (RiskAnalytics.getCWPSeasonalCropsList || RiskAnalytics.getCWPPerennialCropsList) {
      let cropsListData =
        activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value
          ? RiskAnalytics.getCWPSeasonalCropsList
          : RiskAnalytics.getCWPPerennialCropsList;
      if (cropsListData?.data) {
        let { result } = cropsListData?.data;
        let arr = [];
        if (result?.length) {
          result?.map((item,index) => {
            return arr.push({
              ...item,
              id: item?.id,
              name: item?.name,
              label: item?.name,
              checked: false
            });
          });
          if (arr?.length) {
            if (activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value) {
              // let defaultCrop = [];
              //   defaultCrop.push({ ...arr[0], checked: true})
              //   setFilterData((prev) => {
              //   return { ...prev, crop: defaultCrop };
              // });
              let updatedArr = [...arr];
              updatedArr = arr.map((item,index)=> index === 0 ? { ...item,checked: true} : {...item})
              setCropList(updatedArr)            }
            else{
              let updatedArr = [...arr];
              if(formData?.perennialCrops?.length){
                updatedArr = arr.map(item=> formData?.perennialCrops?.some(i=>i.id === item.id) ? { ...item,checked:true} : {...item})
                setCropList(updatedArr)
              } else{
                updatedArr = arr.map((item,index)=> index === 0 ? { ...item,checked: true} : {...item})
                setCropList(updatedArr)
              }
            }
          }
        }
      }
    } else if (RiskAnalytics.getCWPCropsListError) {
      //error case
    }
  }, [
    RiskAnalytics.getCWPSeasonalCropsList,
    RiskAnalytics.getCWPCropsListError,
    RiskAnalytics.getCWPPerennialCropsList,
    activeTab
  ]);

  //getting crop wise production seasons list
  useEffect(() => {
    if (RiskAnalytics.getCWPSeasonsList) {
      if (RiskAnalytics.getCWPSeasonsList?.data) {
        let { result } = RiskAnalytics.getCWPSeasonsList?.data;
        let arr = [];
        if (result?.length) {
          result?.map((item,index) => {
            return arr.push({
              ...item,
              id: item?.id,
              name: item?.name,
              label: item?.name,
              checked: index === 0 ? true : false,
            });
          });
          if (arr?.length) {
            setSeasonList(arr);
            let defaultSeason = arr[0];
            setFilterData((prev) => {
              return { ...prev, season: defaultSeason };
            });
          }
        }
      }
    } else if (RiskAnalytics.getCWPSeasonsListError) {
      //error case
    }
  }, [RiskAnalytics.getCWPSeasonsList, RiskAnalytics.getCWPSeasonsListError]);

  //getting crop wise production practises list
  useEffect(() => {
    if (RiskAnalytics.getCWPPractisesList) {
      if (RiskAnalytics.getCWPPractisesList?.data) {
        let { result } = RiskAnalytics.getCWPPractisesList?.data;
        let arr = [];
        if (result?.length) {
          let subCategoryList = [];
          let irrigatedCategory = {
            id: 'irrigated',
            name: 'Irrigated',
            label: 'Irrigated',
            hasSubCategory: true,
            isSubCategory: false,
            subCategoryList: [],
          };
          result?.map((item,index) => {
            let obj = {
              ...item,
              id: item?.id,
              name: item?.name,
              label: item?.name,
              checked: index === 0 ? true : false,
            };
            if (
              item?.slug === CROP_PRODUCTION_FILTER_SLUG_CONSTS.MAJOR_SLUG ||
              item?.slug === CROP_PRODUCTION_FILTER_SLUG_CONSTS.MINOR_SLUG
            ) {
              subCategoryList?.push({ ...obj, isSubCategory: true, categoryId: 'irrigated' });
            } else {
              obj.hasSubCategory = false;
              obj.isSubCategory = false;

              return arr.push(obj);
            }
          });
          if (subCategoryList?.length) irrigatedCategory.subCategoryList = subCategoryList;
          arr?.push(irrigatedCategory);
          if (arr?.length) {
            setPractisesList(arr);
            let defaultPractise = arr[0];
            setFilterData((prev) => {
              return { ...prev, practise: defaultPractise };
            });
          }
        }
      }
    } else if (RiskAnalytics.getCWPPractisesListError) {
      //error case
    }
  }, [RiskAnalytics.getCWPPractisesList, RiskAnalytics.getCWPPractisesListError]);

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      if (activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value) {
        if (
          !_.isEmpty(formData?.country) &&
          !_.isEmpty(formData?.state) &&
          !_.isEmpty(formData?.district) &&
          formData?.crop?.length &&
          formData?.season?.length &&
          formData?.practise?.length &&
          !_.isEmpty(formData?.startYear) &&
          !_.isEmpty(formData?.endYear) &&
          formData?.startYear?.value <= formData?.endYear?.value
        ) {
          setIsViewGraph(true);
        } else {
          setIsViewGraph(false);
        }
        if (formData?.startYear?.value > formData?.endYear?.value) {
          setMinMaxError(true);
        } else {
          setMinMaxError(false);
        }
      } else if (activeTab === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value) {
        if (
          !_.isEmpty(formData?.country) &&
          formData?.perennialCrops?.length &&
          !_.isEmpty(formData?.perennialStartYear) &&
          !_.isEmpty(formData?.perennialEndYear) &&
          formData?.perennialStartYear?.value <= formData?.perennialEndYear?.value
        ) {
          setIsViewGraph(true);
        } else {
          setIsViewGraph(false);
        }
        if (formData?.perennialStartYear?.value > formData?.perennialEndYear?.value) {
          setMinMaxError(true);
        } else {
          setMinMaxError(false);
        }
      }
    } else {
      setIsViewGraph(false);
    }
   
  }, [formData]);

  useEffect(() => {
    if (cropList?.length) {
      let selectedCrops = [];
      cropList?.map((item) => {
        if (item?.checked) {
          selectedCrops?.push(item);
        }
      });
      if (activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value) {
        formData.crop = selectedCrops;
        setFormData({ ...formData });
        setFilterData((prev) => {
          return {
            ...prev,
            crop: selectedCrops,
          };
        });
      } else {
        setFormData((prev)=>{
          return {
            ...prev,
            perennialCrops: selectedCrops
          }
        });
        setFilterData((prev) => {
          return {
            ...prev,
            crop: selectedCrops,
          };
        });
      }
    }
  }, [cropList]);

  useEffect(() => {
    if (seasonList?.length) {
      let selectedSeasons = [];
      seasonList?.map((item) => {
        if (item?.checked) {
          selectedSeasons?.push(item);
        }
      });
      formData.season = selectedSeasons;
      setFormData({ ...formData });
    }
  }, [seasonList]);

  useEffect(() => {
    if (practisesList?.length) {
      let selectedPractise = [];
      practisesList?.map((item) => {
        if (item?.checked) {
          selectedPractise?.push(item);
        }
        if (item?.subCategoryList?.length) {
          item?.subCategoryList?.map((subItem) => {
            if (subItem.checked) selectedPractise?.push(subItem);
          });
        }
      });
      formData.practise = selectedPractise;
      setFormData({ ...formData });
    }
  }, [practisesList]);

  // yala --> start year --> 2005 and end year --> 2022
  // maha --> start year --> (2004-2005) and end year --> (2021-2022)
  // but now for both showing start year -2004 till current year
  useEffect(() => {
    let START_YEAR = { MIN_YEAR: 2004, MAX_YEAR: new Date()?.getFullYear() };
    if(activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value){
      if (formData?.season?.length) {
      let is_yala = formData?.season?.some(
        (i) => i.slug === CROP_PRODUCTION_FILTER_SLUG_CONSTS.YALA_SLUG && i.checked,
      );
      let is_maha = formData?.season?.some(
        (i) => i.slug === CROP_PRODUCTION_FILTER_SLUG_CONSTS.MAHA_SLUG && i.checked,
      );
      if ((is_yala && is_maha) || is_maha) {
        if (!_.isEmpty(formData?.startYear)) {
          dispatch(change('CropProductionFilter', 'end_year', ''));
          setFormData({ ...formData, endYear: '' });
          let endYearList = renderCWPYearList(formData?.startYear?.value + 1, START_YEAR.MAX_YEAR);
          let updatedEndYearList = [...endYearList]?.filter(item => item?.value <= formData?.startYear?.value + 2)
          setEndYearList(updatedEndYearList);
        } else {
          let startYearList = renderCWPYearList(START_YEAR?.MIN_YEAR, START_YEAR?.MAX_YEAR);
          let endYearList = renderCWPYearList(START_YEAR?.MIN_YEAR + 1, START_YEAR?.MAX_YEAR);
          setStartYearList(startYearList);
          setEndYearList(endYearList);
        }
      } else if (is_yala) {
        let yearList = renderCWPYearList(START_YEAR?.MIN_YEAR, START_YEAR?.MAX_YEAR);
        let updatedEndYearList = yearList;
        if(formData?.startYear?.value){
          // updatedEndYearList = [...yearList]?.filter(item => item?.value <= formData?.startYear?.value + 2)
          let index = yearList.findIndex(function (element) {
            return element?.value === formData?.startYear?.value;
          });
          setEndYearList(updatedEndYearList.slice(index, index+3)); //slicing the array from selected year and removing previous years
        }
        else{
          setStartYearList(yearList);
          setEndYearList(yearList)
        }
      } else {
        let startYearList = renderCWPYearList(START_YEAR?.MIN_YEAR, START_YEAR?.MAX_YEAR);
        let endYearList = renderCWPYearList(START_YEAR?.MIN_YEAR + 1, START_YEAR?.MAX_YEAR);
        setStartYearList(startYearList);
        setEndYearList(endYearList);
      }
    } else {
      let startYearList = renderCWPYearList(START_YEAR?.MIN_YEAR, START_YEAR?.MAX_YEAR);
      let endYearList = renderCWPYearList(START_YEAR?.MIN_YEAR + 1, START_YEAR?.MAX_YEAR);
      let updatedEndYearList = endYearList;
      if(!_.isEmpty(formData?.startYear?.value)){
        updatedEndYearList = [...endYearList]?.filter(item => item?.value <= formData?.startYear?.value + 2)
        setEndYearList(updatedEndYearList);
      }
      else {
        setStartYearList(startYearList);
        setEndYearList(endYearList)
      }
     }
    }
  }, [formData?.season, formData?.startYear,activeTab]);

  useEffect(()=>{
    if(activeTab === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value){
      let START_YEAR = { MIN_YEAR: 2004, MAX_YEAR: new Date()?.getFullYear() };
      let startYearList = renderCWPYearList(START_YEAR?.MIN_YEAR, START_YEAR?.MAX_YEAR);
      let endYearList = renderCWPYearList(START_YEAR?.MIN_YEAR + 1, START_YEAR?.MAX_YEAR);
      // let updatedEndYearList = endYearList;
      let updatedEndYearList = startYearList;
      if(formData?.perennialStartYear?.value){
        // updatedEndYearList = [...endYearList]?.filter(item => item?.value <= formData?.perennialStartYear?.value + 2)
        let index = startYearList.findIndex(function (element) {
          return element?.value === formData?.perennialStartYear?.value;
        });
        setPerennialEndYearList(updatedEndYearList.slice(index + 1, index + 3)); //slicing the array from selected year and removing previous years
      }
      else {
        setPerennialStartYearList(startYearList);
        setPerennialEndYearList(endYearList)
      }
    }
  },[activeTab,formData?.perennialStartYear])

  useEffect(() => {
    if (
      !_.isEmpty(selectedLocation.state) &&
      !_.isEmpty(selectedLocation.state.value) &&
      _.isEmpty(farmData?.district)
    ) {
      let requestObj = { location_type: LOCATION.District, parent_id: selectedLocation.state?.id };
      dispatch(getDistrictByStateRequest({ requestObj, isCompareLocation: false }));
    }
  }, [selectedLocation.state]);

  useEffect(()=>{
    if(startYearList?.length){
      if(isDefaultSelection && activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value){
        //checking from 2005, Since 2004 we are not getting data
        setFormData(prev =>{
          return {
            ...prev,
            startYear: formData?.startYear?.value  ? formData?.startYear : startYearList[1]
          }
        });
        setFilterData(prev => {
          return {
            ...prev,
            start_year: formData?.startYear?.value  ? formData?.startYear : startYearList[1]
          }
        })
      }
    }
  },[startYearList,isDefaultSelection,activeTab])

  useEffect(()=>{
    if(endYearList?.length){
      if(isDefaultSelection && activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value){
        setFormData(prev =>{
          return {
            ...prev,
            endYear: formData?.endYear || endYearList[1]
          }
        });
        setFilterData(prev => {
          return {
            ...prev,
            end_year: formData?.endYear || endYearList?.[1]
          }
        })
      }
    }
  },[endYearList, isDefaultSelection,activeTab])

  useEffect(()=>{
    if(perennialStartYearList?.length){
      if(isDefaultSelection && activeTab === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value){
        setFormData(prev =>{
          return {
            ...prev,
            perennialStartYear: formData?.perennialStartYear || perennialStartYearList[0]
          }
        });
        setFilterData(prev => {
          return {
            ...prev,
            start_year: formData?.perennialStartYear || perennialStartYearList[0]
          }
        })
      }
    }
  },[perennialStartYearList,isDefaultSelection,activeTab])

  useEffect(()=>{
    if(perennialEndYearList?.length){
      if(isDefaultSelection && activeTab === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value){
        setFormData(prev =>{
          return {
            ...prev,
            perennialEndYear: formData?.perennialEndYear || perennialEndYearList[1]
          }
        });
        setFilterData(prev => {
          return {
            ...prev,
            end_year: formData?.perennialEndYear || perennialEndYearList?.[1]
          }
        })
      }
    }
  },[perennialEndYearList, isDefaultSelection,activeTab])

  useEffect(() => {
    if (activeTab) {
      // setSelectedLocation((prev) => {
      //   return {
      //     ...prev,
      //     state: '',
      //     district: '',
      //   };
      // });
      // setIsCWPGraphSingleCase(true);
      // setDistrictList([]);
    }
  }, [activeTab]);

  useEffect(() => {
    if (graphList?.length) {
      let fileName = '';
      if (activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value) {
        fileName = `Crop_Production(${formData.state.name}-${
          formData?.district?.name
            ? formData?.district?.name
            : formData?.district?.length
            ? formData?.district?.map((data) => data.name).toString()
            : ''
        }-${formData.crop.map((data) => data.name).toString()}-${formData.season
          .map((data) => data.name)
          .toString()}-${formData.practise.map((data) => data.name).toString()}-${
          formData.startYear.label
        }-${formData.endYear.label})`;
      } else if (activeTab === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value) {
        fileName = `Crop_Production(${formData.perennialCrops.map((data) => data.name).toString()}-${
          formData.perennialStartYear.label
        }-${formData.perennialEndYear.label})`;
      }
      setFileName(fileName);
    }
  }, [graphList]);


  useEffect(() => {
    if(activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value){
      if (
        isDefaultSelection &&
        isViewGraph
      ) {
        handleFormSubmit();
      }
    }
    else if(activeTab === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value){
      if (
        isDefaultSelection &&
        isViewGraph && 
        filterData?.crop?.length &&
        filterData?.start_year?.value &&
        filterData?.end_year?.value
      ) {
        handleFormSubmit();
      }
    }
  }, [
    // isDefaultSelection,
    filterData,
    // isViewGraph,
  ]);

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
    setCropList([]);
    setGraphList([]);
    setFinalGraphList([]);
    setIsDefaultSelection(true);
  };

  const handleProvinceChange = (newValue) => {
    setIsDefaultSelection(false);
    if (newValue.value === '') {
      setSelectedLocation((...prev) => ({ ...prev, state: '', district: '' }));
      setDistrictList([]);
      dispatch(change('CropProductionFilter', 'district', ''));
      setFormData({ ...formData, state: '', district: '' });
      setFilterData({ ...filterData, province: '', district: '' })
    } else {
      dispatch(change('CropProductionFilter', 'district', ''));
      setFormData({ ...formData, state: newValue, district: '' });
      setFilterData({ ...filterData, province: newValue, district: '' })
      setFarmData({ ...farmData, province: newValue, district: '' })
      setSelectedLocation((prev) => {
        return {
          ...prev,
          state: newValue,
          district: ''
        };
      });
    }
  };

  const handleDistrictChange = (newValue) => {
    setIsDefaultSelection(false);
    if (newValue.value === '') {
      formData.district = '';
      setFormData({ ...formData });
      setSelectedLocation((...prev) => ({ ...prev, district: '' }));
    } else {
      formData.district = newValue;
      setFormData({ ...formData });
      setFarmData((prev) => {
        return {
          ...prev,
          district: newValue
        };
      });
      setSelectedLocation((prev) => {
        return {
          ...prev,
          district: newValue,
        };
      });
    }
  };

  const handleOnCropChange = (value, selectedCrop) => {
    setIsDefaultSelection(false);
    let defaultCropList = [...cropList];
    let updatedCropList = defaultCropList?.map((item) => {
      let itemData = _.cloneDeep(item);
      if (itemData?.id === selectedCrop?.id) {
        itemData.checked = value?.target?.checked;
      }
      return itemData;
    });
    if (updatedCropList?.length) setCropList(updatedCropList);
  };

  const handleOnSeasonChange = (value, selectedSeason) => {
    setIsDefaultSelection(false);
    let defaultSeasonList = [...seasonList];
    let updatedSeasonList = defaultSeasonList?.map((item) => {
      let itemData = _.cloneDeep(item);
      if (itemData?.id === selectedSeason?.id) {
        itemData.checked = value?.target?.checked;
      }
      return itemData;
    });
    if (updatedSeasonList?.length) setSeasonList(updatedSeasonList);
  };

  const handleOnPractisesChange = (value, selectedPractise) => {
    setIsDefaultSelection(false);
    let defaultPractiseList = [...practisesList];
    let updatedPractiseList = defaultPractiseList?.map((item) => {
      let itemData = _.cloneDeep(item);
      if (itemData?.id === selectedPractise?.id) {
        itemData.checked = value?.target?.checked;
      } else if (selectedPractise?.isSubCategory) {
        if (selectedPractise?.categoryId === itemData?.id) {
          let subList = [...itemData?.subCategoryList];
          let updatedSubList = subList?.map((subItem) => {
            if (subItem.id === selectedPractise?.id) {
              subItem.checked = value?.target?.checked;
            }
            return subItem;
          });
          if (updatedSubList?.length) itemData.subCategoryList = updatedSubList;
        }
      }
      return itemData;
    });
    if (updatedPractiseList?.length) setPractisesList(updatedPractiseList);
  };
  const handleStartDate = (value) => {
    setIsDefaultSelection(false);
    dispatch(change('CropProductionFilter', 'end_year', ''));
    if(activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value){
      formData.startYear = value;
      setFormData({ ...formData, endYear:'' });
    }
    else {
      formData.perennialStartYear = value;
      setFormData({ ...formData,perennialEndYear:'' });
    }
    
  };

  const handleEndDate = (value) => {
    setIsDefaultSelection(false);
    
    if(activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value){
      formData.endYear = value;
      setFormData({ ...formData });
    }
    else {
      formData.perennialEndYear = value;
      setFormData({ ...formData });
    }
  };
  const handleFormSubmit = () => {
    clearGraphData();
    let requestObj = {};
    if (activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value) {
      requestObj = {
        country_id: formData?.country?.id ? formData?.country?.id : '', 
        state_id: formData?.state?.id ? [formData?.state?.id] : '', 
        district_id: formData?.district?.id
          ? [formData?.district?.id]
          : formData?.district?.map((i) => i.id),
        crop_id: formData?.crop?.map((i) => i.id),
        season_id: formData?.season?.map((i) => i.id),
        practice_id: formData?.practise?.map((i) => i.id),
        start_year: formData?.startYear?.value,
        end_year: formData?.endYear?.value,
      };
      dispatch(getCWPGraphDataRequest(requestObj, activeTab));
    } else if (activeTab === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value) {
      if(formData?.perennialCrops?.length && formData?.perennialStartYear?.value && formData?.perennialEndYear?.value){
        requestObj = {
          crop_id: formData?.perennialCrops?.map((i) => i.id),
          start_year: formData?.perennialStartYear?.value,
          end_year: formData?.perennialEndYear?.value,
        };
        dispatch(getCWPGraphDataRequest(requestObj, activeTab));
      }
    }
  };

  const clearGraphData = () => {
    setGraphList('')
    setFinalGraphList([])
  };

  const handleSelectedItem = (newValue, action) => {
    if (action.name === 'country') {
      //default country
    } else if (action.name === 'state') {
      if (newValue.value === '') {
        setSelectedLocation((...prev) => ({ ...prev, state: {}, district: {} }));
        setDistrictList([]);
      } else {
        setSelectedLocation((prev) => {
          return {
            ...prev,
            state: newValue,
            district: {},
          };
        });
      }
    } else if (action.name === 'district') {
      if (newValue.value === '') setSelectedLocation((...prev) => ({ ...prev, district: {} }));
      else
        setSelectedLocation((prev) => {
          return {
            ...prev,
            district: newValue,
          };
        });
    }
  };

  useEffect(() => {
    if (graphList && graphList.length) {
      let finalGraphList = [];
      if (activeTab === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value) {
        let updatedGraphList = [];
        let cropCount = formData?.perennialCrops?.length;
        let cropAreaData;
        if (cropCount === 1) {
          cropAreaData = _.mapValues(_.groupBy(graphList, 'crop_area'), (clist) =>
            clist.map((car) => _.omit(car, 'legendgroup')),
          );
          Object.keys(cropAreaData)?.forEach((crop, index) => {
            let dataList = cropAreaData[crop];
            let obj = {};
            let data = [];
            let xaxisData = [];
            dataList?.map((item, dataIndex) => {
              if (item?.production_data) {
                data?.push(item?.production_data);
              }
              if (item?.year) {
                xaxisData.push(item?.year);
              }
            });
            if (CWP_PERENNIAL_CROP_AREAS['Extent (Hectares)'] === crop) {
              obj.legendPosition = 1;
            } else if (CWP_PERENNIAL_CROP_AREAS['COP (Rs/Kg)'] === crop) {
              obj.legendPosition = 3;
            } else if (CWP_PERENNIAL_CROP_AREAS['Production (MT)'] === crop) {
              obj.legendPosition = 2;
            } else if (CWP_PERENNIAL_CROP_AREAS['COP (Rs/1000 nuts)'] === crop) {
              obj.legendPosition = 4;
            }
            obj.showlegend = true;
            obj.visible = true;
            obj.crop_area = crop;
            obj.data = data;
            obj.xaxisData = xaxisData;
            updatedGraphList?.push(obj);
          });
          setIsCWPGraphSingleCase(true);
        } else if (cropCount > 1) {
          let cropAreaData = { perennial: {} };
          let showLegendCount = -1;
          let showLegendCropAreas = {
            [CWP_PERENNIAL_CROP_AREAS['Extent (Hectares)']] : false,
            [CWP_PERENNIAL_CROP_AREAS['Production (MT)']] : false,
            [CWP_PERENNIAL_CROP_AREAS['COP (Rs/Kg)']] : false,
            [CWP_PERENNIAL_CROP_AREAS['COP (Rs/1000 nuts)']] : false
          }
          cropAreaData['perennial'] = _.mapValues(_.groupBy(graphList, 'crop_name'));
          Object.keys(cropAreaData['perennial']).forEach((key) => {
            cropAreaData['perennial'][key] = _.mapValues(
              _.groupBy(cropAreaData['perennial'][key], 'crop_area'),
            );
          });
          Object.keys(cropAreaData)?.forEach((item, index) => {
            showLegendCount = showLegendCount + 1;
            let perennialParams = cropAreaData[item];
            Object.keys(perennialParams)?.forEach((crop, cropIndex) => {
              let cropParam = perennialParams[crop];
              Object.keys(cropParam)?.forEach((areaCrop, areaIndex) => {
                let dataList = cropParam[areaCrop];
                let obj = {};
                let data = [];
                let xaxisData = [];
                let modellist = [];
                let yearList = [];
                dataList?.map((item, dataIndex) => {
                  if (item?.production_data) {
                    data?.push(item?.production_data);
                  }
                  if (item?.year) {
                    let xaxislabel = `${item?.year}`;
                    modellist?.push(crop);
                    yearList?.push(xaxislabel);
                  }
                });
                if (index === showLegendCount) {
                  if (!showLegendCropAreas[areaCrop]) {
                    showLegendCropAreas[areaCrop] = true;
                    obj.showlegend = true;
                  } else {
                    obj.showlegend = false;
                  }
                } else {
                  obj.showlegend = false;
                }
                obj.legendgroup = item;
                if (CWP_PERENNIAL_CROP_AREAS['Extent (Hectares)'] === areaCrop) {
                  obj.legendPosition = 1;
                } else if (CWP_PERENNIAL_CROP_AREAS['COP (Rs/Kg)'] === areaCrop) {
                  obj.legendPosition = 3;
                } else if (CWP_PERENNIAL_CROP_AREAS['Production (MT)'] === areaCrop) {
                  obj.legendPosition = 2;
                } else if (CWP_PERENNIAL_CROP_AREAS['COP (Rs/1000 nuts)'] === areaCrop) {
                  obj.legendPosition = 4;
                }
                obj.visible = true;
                obj.crop_area = areaCrop;
                obj.data = data;
                xaxisData.push(modellist);
                xaxisData.push(yearList);
                obj.xaxisData = xaxisData;
                updatedGraphList?.push(obj);
              });
            });
          });
          setIsCWPGraphSingleCase(false);
        }
        if (updatedGraphList?.length) {
          finalGraphList = _.orderBy(updatedGraphList, ['legendPosition'], ['asc']);
        }
      } else if (activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value) {
        let isSingleUseCase = true;
        let showLegendCount = -1;
        if (
          (formData?.district?.id || formData?.district?.length < 2) &&
          formData?.crop?.length < 2 &&
          formData?.season?.length < 2 &&
          formData?.practise?.length < 2
        ) {
          isSingleUseCase = true;
        } else isSingleUseCase = false;
        setIsCWPGraphSingleCase(isSingleUseCase);
        // for multiple cases
        let practiceData = _.mapValues(_.groupBy(graphList, 'practice_name'));
        Object.keys(practiceData).forEach((key) => {
          practiceData[key] = _.mapValues(_.groupBy(practiceData[key], 'district_name'), (clist) =>
            _.groupBy(clist, 'crop_area'),
          );
        });
        let groupedList = { practiceData };
        Object.keys(groupedList?.practiceData)?.forEach((practice, practiceIndex) => {
          showLegendCount = showLegendCount + 1;
          let practiceParams = practiceData[practice];
          Object.keys(practiceParams)?.forEach((district, districtIndex) => {
            let districtParams = practiceParams[district];
            let cropAreaList = Object.keys(districtParams);
            for (let areaCrop of cropAreaList) {
              let dataList = districtParams[areaCrop];
              let obj = {};
              let data = [];
              let xaxisData = [];
              let modellist = [];
              let yearList = [];
              dataList?.map((item, dataIndex) => {
                if (item?.production_data) {
                  data?.push(item?.production_data);
                }
                if (item?.year) {
                  let xaxislabel = `${item?.season_name}(${item?.year})`;
                  modellist?.push(district);
                  yearList?.push(xaxislabel);
                }
              });
             
              if (practiceIndex === showLegendCount) {
                if (districtIndex === 0) {
                  obj.showlegend = true;
                } else {
                  obj.showlegend = false;
                }
              } else {
                obj.showlegend = false;
              }
              obj.legendgroup = practice;
              obj.legendgrouptitle = {
                text: practice,
              };
              obj.visible = true;
              obj.crop_area = areaCrop;
              obj.data = data;
              xaxisData.push(modellist);
              xaxisData.push(yearList);
              obj.xaxisData = xaxisData;
              finalGraphList?.push(obj);
            }
          });
        });
      }
      setFinalGraphList(finalGraphList);
    }
  }, [graphList]);

  useEffect(() => {
    if (graphList?.length) {
      let isCOPKgSelected = graphList?.some(
        (i) => i.crop_area === CWP_PERENNIAL_CROP_AREAS['COP (Rs/Kg)'],
      );
      setIsCOPKgSelected(isCOPKgSelected);
    }
  }, [graphList]);

  const handleInfo = (e) => {
    e?.stopPropagation();
    window.open(
      `/user-guide-details?topicId=${RISK_ANALYTICS_MODULE.id}&&sub-topic-name=Crop Production`,
    );
  };

  return (
    <>
      <Modal isOpen={modalOpen} toggle={setModalOpen} size={'lg'} className={'cropProductionModal'}>
        {isLoading ? <Loader /> : ''}
        <ModalHeader className="weather-forecast-modal-title" toggle={setModalOpen}>
          Crop Production
          <span className="info-style">
            <i
              className="cursor-ponter fa fa-info cwp-info-icon-edit-btn"
              id="info-icon-tool-tip"
              data-tip
              data-for="info-icon-tool-tip"
              onClick={handleInfo}
            />
            <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
              Click here to know more.
            </ReactTooltip>
          </span>
        </ModalHeader>
        <ModalBody className="stream-modal-body w-100">
          <Row>
            <Col lg="3" md="3" sm="3" className="crop-filter-col-st">
              <CropProductionFilter
                activeTab={activeTab}
                selectedLocation={selectedLocation}
                countryList={countryList}
                provinceList={provinceList}
                districtList={districtList}
                cropList={cropList}
                seasonList={seasonList}
                practisesList={practisesList}
                startYearList={activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value ? startYearList : perennialStartYearList}
                endYearList={activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value ? endYearList : perennialEndYearList}
                isViewGraph={isViewGraph}
                minMaxError={minMaxError}
                projectDetails={projectDetails}
                handleFormSubmit={handleFormSubmit}
                setMinMaxError={setMinMaxError}
                formData={formData}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                handleOnPractisesChange={handleOnPractisesChange}
                handleOnSeasonChange={handleOnSeasonChange}
                handleOnCropChange={handleOnCropChange}
                handleActiveTab={handleActiveTab}
                handleSelectedItem={handleSelectedItem}
                handleProvinceChange={handleProvinceChange}
                handleDistrictChange={handleDistrictChange}
                initialValues={filterData}
                // clearGraphData={clearGraphData}
                setGraphList={setGraphList}
                setFinalGraphList={setFinalGraphList}
              />
            </Col>
            <Col lg="9" md="9" sm="9" className="crop-prod-graph-col mt-3">
              {activeTab === CROP_PRODUCTION_FILTER_TABS.SEASONAL.value && (
                <SeasonalGraphData
                  graphList={graphList}
                  finalGraphList={finalGraphList}
                  startYear={formData?.startYear?.value}
                  endYear={formData?.endYear?.value}
                  fileName={fileName}
                  isCWPGraphSingleCase={isCWPGraphSingleCase}
                  formData={formData}
                  showErrorMsg={showErrorMsg}
                  isLoading={isLoading}
                  errorMessage={errorMessage}
                />
              )}
              {activeTab === CROP_PRODUCTION_FILTER_TABS.PERENNIAL.value && (
                <PerennialGraphData
                  graphList={graphList}
                  finalGraphList={finalGraphList}
                  startYear={formData?.perennialStartYear?.value}
                  fileName={fileName}
                  endYear={formData?.perennialEndYear?.value}
                  isCWPGraphSingleCase={isCWPGraphSingleCase}
                  isCOPKgSelected={isCOPKgSelected}
                  formData={formData}
                  isLoading={isLoading}
                  errorMessage={errorMessage}
                />
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CropProductionModal;