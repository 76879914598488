import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import compose from 'compose-function';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import { Row, Col, Container, FormGroup, Label, Button } from 'reactstrap';

import RightArrow from '../../assets/Images/back-button.svg';

import { renderReactSelectInputField } from '../../Components/common/renderInputField';

import InvitePeopleModal from './invitePeople';

import jwt_decode from 'jwt-decode';

import {
  SCOPE,
  CLIENT_ID,
  API_KEY,
  discoveryUrl,
} from '../../Components/constants/index';

import {
  componentsListRequest,
  submitConfigurationRequest,
  submitConfigurationSuccess,
  surveyConfigurationRequest,
  surveyConfigurationSuccess,
} from '../../redux/manageFieldData/manageFieldDataActions';

import { loginDetailrequest, insuranceUserUpdaterequest } from '../../redux/login/loginActions';

import Loader from '../../Components/common/loader';

import { showError, showSuccess } from '../../Components/Notifications';

import ConfirmationModal from './confirmationModal';


import { getODKAccessToken, getToken, setODKAccessToken } from '../../Components/common/utils';


const ConfigurationPage = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const paramLocation = useLocation();

  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState(null);
  const [data4, setData4] = useState(null);
  const [data5, setData5] = useState(null);

  const [successmsg, setSuccessmsg] = useState(null);
  const [successmsg1, setSuccessmsg1] = useState(null);

  const [xmlLink, setXmlLink] = useState('');
  const [xmlname, setXmlname] = useState('');
  const [showLoadingText, setShowLoadingText] = useState(false);
  const [copy, setCopy] = useState(false);
  const [signedInUser, setSignedInUser] = useState('');
  const [tokenClient, setTokenClient] = useState({});
  const [accessToken, setAccessToken] = useState('')
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const [loading, setLoading] = useState(false);
  const [excelUrl, setExcelUrl] = useState('');
  const [xmlfileUrl, setXmlFileUrl] = useState('');

  const [url1, setUrl1] = useState('');
  const [url2, setUrl2] = useState('');
  const [url3, setUrl3] = useState('');
  const [url4, setUrl4] = useState('');
  const [url5, setUrl5] = useState('');

  const [showModal, setShowModal] = useState(false);

  const [eachConfigId, setEachConfigId] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [folderId, setFolderId] = useState(false);
  const [signedInUserDetails, setSignedInUserDetails] = useState('');

  const [userDetails, setUserDetails] = useState('');
  const [componentOptions, setComponentOptions] = useState('')
  const [selectedComponent, setSelectedComponent] = useState('')
  const [toolTip, setToolTip] = useState(false);
  const [isConfigure, setIsConfigure] = useState(false);
  let history = useHistory();

  const [title, setTitle] = useState('');
  const [xmlId, setXmlId] = useState('');

  const [showModal1, setShowModal1] = useState(false);
  const [date, setDate] = useState(false);

  const [configurationSelectedDate, setConfigurationSelectedDate] = useState();
  const { handleSubmit } = props;
  const dispatch = useDispatch();

  const nextProps = useSelector((state) => ({
    componentsList: state?.fieldDataList?.componentsListData,
    componentsListRequest: state?.fieldDataList?.componentsListRequesting,
    componentsByIdRequesting: state?.fieldDataList?.componentsByIdRequesting,
    componentsByIdData: state?.fieldDataList?.componentsByIdData,
    submitConfiguration: state?.fieldDataList?.submitConfiguration,
    isSubmitConfigurationRequesting: state?.fieldDataList?.isSubmitConfigurationRequesting,
    isSurveyConfigurationRequesting: state?.fieldDataList?.isSurveyConfigurationRequesting,
    surveyConfigurationData: state?.fieldDataList?.surveyConfigurationData,
    surveyConfigurationDataError: state?.fieldDataList?.surveyConfigurationDataError,
    loginDetailData: state.Login.loginDetailData || null,
    updateStatusData: state.UserList.updateStatusData || '',
    insuranceUserUpdateData: state.Login.insuranceUserUpdateData,
    insuranceUserUpdateErrorData: state.Login.insuranceUserUpdateDataError,
    insuranceUserUpdateRequesting: state.Login.insuranceUserUpdateRequesting,
  }));

  const localUserPermission = localStorage.getItem('userPermissions');

  const userId = JSON.parse(localUserPermission)?.result?.user?.id;

  useEffect(() => {
    if (paramLocation?.state?.date) {
      setConfigurationSelectedDate(new Date(paramLocation?.state?.date));
    }
  }, [paramLocation?.state])

  useEffect(() => {
    setLoading(false);
    setIsLoading(false);
    dispatch(componentsListRequest());
    if (getToken()) {
      //google authorization
      dispatch(loginDetailrequest({}));
      // setError(null);
      setSuccessmsg(null);
      setSuccessmsg1(null);

      let signedInUser = localStorage.getItem('googleAccountDetails');
      if(signedInUser){
        signedInUser = JSON.parse(signedInUser);
        setSignedInUser(signedInUser)
        setSignedInUserDetails(signedInUser?.email)
      } else history.push('/surveyDataSettingsDashboard')
    }
    else {
      history.push("/home")
    }
    return (() => {
      dispatch(surveyConfigurationSuccess({}))
    })
  }, []);

  useEffect(()=>{
    if (userDetails) handleClientLoad();
  },[userDetails])


  useEffect(() => {
    if (folderId && !_.isEmpty(signedInUserDetails) && !_.isEmpty(userDetails?.google_email) &&
      nextProps?.componentsList && configurationSelectedDate
    ) {
      if (signedInUserDetails === userDetails?.google_email) {
        let odkAccessToken = getODKAccessToken()
        if (isConfigure)
          createFiles(
            folderId,
            selectedComponent?.name,
            odkAccessToken
          );
      } else {
        showError(
          `Email you have authenticated in browser is not related with your organization please authenticate with this email ${userDetails?.google_email}`,
        );

        handleSignOutClick();
      }
    }
  }, [folderId, signedInUserDetails, nextProps.componentsList, configurationSelectedDate, isConfigure]);

  useEffect(() => {
    if (nextProps.loginDetailData) {
      setFolderId(nextProps?.loginDetailData?.data?.result?.user_profile?.drive_folder_id);

      setUserDetails(nextProps?.loginDetailData?.data?.result?.user_profile);
    }
  }, [nextProps.loginDetailData?.data?.result]);

  useEffect(() => {
    if (nextProps?.componentsList?.data?.result?.length) {
      let arr = []
      arr = nextProps?.componentsList?.data?.result?.map(item => {
        let obj = { ...item };
        obj.value = item.name;
        obj.label = item.name;
        return obj;
      })
      if (arr?.length) setComponentOptions(arr)
    }
  }, [nextProps?.componentsList])

  useEffect(() => {
    if (excelUrl && userId && selectedComponent && configurationSelectedDate) {
      let finalPayload = {
        user_id: userId?.toString(),
        component_id: selectedComponent?.id,
        year: new Date(configurationSelectedDate)?.getFullYear(),
        excel_url: excelUrl
      }
      dispatch(surveyConfigurationRequest(finalPayload))

    }
  }, [excelUrl, userId, selectedComponent, configurationSelectedDate])

  useEffect(() => {
    if (nextProps.insuranceUserUpdateData) {
      dispatch(loginDetailrequest({}));
    }
    else if (nextProps.insuranceUserUpdateErrorData) {
      setLoading(false);
      //showError()
    }
  }, [nextProps.insuranceUserUpdateData, nextProps.insuranceUserUpdateErrorData]);

  useEffect(() => {
    if (nextProps.submitConfiguration) {
      dispatch(submitConfigurationSuccess({}));

      setSuccessmsg1(nextProps.submitConfiguration?.data?.message);
    }
    if (nextProps.componentsByIdData && !nextProps.componentsByIdData?.data?.result?.webViewLink) {
      setSuccessmsg(nextProps.componentsByIdData?.data?.result);
    }

    if (nextProps.isSubmitConfigurationRequesting) {
      setSuccessmsg(null);
    }

    if (nextProps.isFieldDataByIdRequesting) {
      setSuccessmsg(null);
    }
  }, [
    nextProps.submitConfiguration,
    nextProps.componentsByIdData,
    nextProps.isSubmitConfigurationRequesting,
    nextProps.isFieldDataByIdRequesting,
  ]);

  useEffect(() => {
    if (nextProps.isSurveyConfigurationRequesting) {
      // setIsLoading(true)
      setLoading(true)
      setSuccessmsg(null);
    }
    else if (nextProps.surveyConfigurationData) {
      // setIsLoading(false);
      setExcelUrl('')
      if (nextProps.surveyConfigurationData?.data) {
        setXmlFileUrl(nextProps.surveyConfigurationData.data)
      }
    }
    else if (nextProps.surveyConfigurationDataError) {
      setLoading(false);
      setShowLoadingText(false)
      setIsLoading(false)
    }
  }, [nextProps.isSurveyConfigurationRequesting, nextProps.surveyConfigurationData, nextProps.surveyConfigurationDataError])


  useEffect(() => {
    if (xmlfileUrl) {
      (async () => {
        const xmlData = await fetch(xmlfileUrl).then((e) => {
          return e.blob()
        })
        if (xmlData) {
          let odkAccessToken = getODKAccessToken();
          uploadXMLFiles(xmlData, odkAccessToken);
        }
        else {
          setLoading(false);
          setShowLoadingText(false)
          showError('Unable to get XML data from the server')
        }
      })()
    }
  }, [xmlfileUrl])

  useEffect(()=>{
    if(getODKAccessToken() && isTokenExpired){
      setLoading(false);
      setShowLoadingText(false);
      // showError('Unable to proceed due to expired access token. please authorize and continue')
      if(userDetails?.google_email)
      tokenClient?.requestAccessToken({ prompt:'', hint: userDetails?.google_email || '' })
      else tokenClient?.requestAccessToken({ prompt: 'consent' })
    }
  },[isTokenExpired])

  const handleClientLoad = async () => {
    // /* global google */
    // await window?.google?.accounts?.id.initialize({
    //   client_id: CLIENT_ID,
    //   apiKey: API_KEY,
    //   useOneTap: true,
    //   auto_select: true,
    //   callback: async function (response) {
    //     let userObj = jwt_decode(response.credential);
    //     setSignedInUser(userObj)
    //     setSignedInUserDetails(userObj?.email)
    //   },
    //   auto_prompt: false,
    // })
    // window?.google?.accounts?.id.prompt();

    //initializing token token Client
    const tokenClient = window?.google?.accounts?.oauth2?.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPE,
      discoveryDocs: discoveryUrl,
      hint: userDetails?.google_email || '',
      prompt: userDetails?.google_email ? '' : 'consent',
      callback: (tokenResponse) => {
        setIsTokenExpired(false);
        if (tokenResponse && tokenResponse?.access_token) {
          setAccessToken(tokenResponse?.access_token)
          setODKAccessToken(tokenResponse?.access_token)
        }
        //we now have access to a live token to use for any google api
      },
      error_callback: (err) => {
        if (err.type == 'popup_failed_to_open') {
          // The popup window is failed to open
          if (err?.message) {
            showError(`${err.message}. Please unblock the popup in browser settings if it is blocked.`)
            history.push("/surveyDataSettingsDashboard")
          }
        } else if (err.type == 'popup_closed') {
          // The popup window is closed before an OAuth response is returned
        }
      }
    });
    setTokenClient(tokenClient)
  };

  const handleCallbackResponse = (response) => {
    let userObj = jwt_decode(response.credential);
    setSignedInUser(userObj)
    setSignedInUserDetails(userObj?.email)
  }

  const formTitle = () => {
    let driveFolderName = JSON.parse(localUserPermission)?.result?.user_profile?.organization;
    if (driveFolderName) {
      return driveFolderName;
    }
  };

  const createFolder = async (signedInUser, email, access_token) => {
    setLoading(true);
    setShowLoadingText(true);
    await fetch("https://www.googleapis.com/drive/v3/files", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      body: JSON.stringify({
        // title: formTitle(),
        name: formTitle(),
        mimeType: 'application/vnd.google-apps.folder',
      }),
    }).then(res => res.json()).then((data) => {
      if (data?.error) throw data?.error
      if (email && _.isEmpty(folderId)) {
        dispatch(
          insuranceUserUpdaterequest({
            user_id: userId.toString(),
            drive_folder_id: data.id,
            google_email: email,
          }),
        );
      }
    })
      .catch(err => {
        if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
          setIsTokenExpired(true)
        } else setIsTokenExpired(false)
      })
  };

  //filter files
  const filerFilesList = (responseData, searchTerm, name) => {
    responseData?.filter((item, key) => {
      if (item.name === searchTerm) {
        createFileUrl(name, item.id);
      }
    });
  };

  const createPermissions = async (name, fileId, accessToken) => {
    await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/permissions?key=${API_KEY}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify({
        type: 'anyone',
        role: 'writer',
      })
    }).then(res => res.json())
      .then(
        function (response) {
          if (response?.error) {
            if (response?.error.code === 401 && response?.error?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
              setIsTokenExpired(true)
            } else setIsTokenExpired(false)
          }
          else{
          }
          // Handle the results here (response.result has the parsed body).
        },
        // function (err) { },
      )
  };

  //create excel sheets
  const createExcelFiles = (searchTerm, folderId, name, accessToken) => {
    var fileMetadata = {
      name: `${searchTerm}`,
      mimeType: 'application/vnd.google-apps.spreadsheet',
      // 'parents': [folderId],//some folderId of a folder under which to create the new folder
      // 'allowFileDiscovery': true,
      parents: [folderId], //some folderId of a folder under which to create the new folder
    };
    fetch("https://www.googleapis.com/drive/v3/files", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify(fileMetadata)
    }).then(res => res.json())
      .then(response => {
        if (response?.error) throw response?.error
        let file = response.result;
        // setLoading(false);
        setIsConfigure(false);
        createFileUrl(name, response.id);
        createPermissions(name, response.id, accessToken);
      })
      .catch(function (err) {
        setLoading(false);
        setShowLoadingText(false);
        if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
          setIsTokenExpired(true)
        } else {
          setIsTokenExpired(false)
          showError('File not found.');
        }
      });
  };

  const handleSignOutClick = () => {
    history.goBack();
  };


  //get list of files in folder
  const listFiles = async (searchTerm, folderId, name, accessToken) => {
    let query = "name='" + searchTerm + "' and '" + folderId + "' in parents"
    await fetch(`https://www.googleapis.com/drive/v3/files?key=${API_KEY}&q=${query}&fields=files(name,id)`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      }
    }).then(res => res.json())
      .then((response) => {
        if (response?.error) {
          throw response?.error
        }
        if (response?.files.length === 0) {
          setLoading(true);
          setShowLoadingText(true);
          createExcelFiles(searchTerm, folderId, name, accessToken);
        } else {
          setLoading(true);
          setShowLoadingText(true);
          filerFilesList(response.result.files, searchTerm, name, accessToken);
        }
      })
      .catch(function (err) {
        let errorData = err?.response;
        setLoading(false);
        setShowLoadingText(false);
        if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
          setIsTokenExpired(true)
        } else {
          setIsTokenExpired(false)
          if (err?.response)
          showError(errorData?.error.message)
          else showError("Survey is already configured related to selected component and year. Please choose another options.")
        }
       
      });
  };

  //get list of files in folder
  const listOfXmlFiles = async (data, component, year, accessToken) => {
    const name = `${component?.name}_${new Date(year)?.getFullYear()?.toString()}.xml`
    let query = "name='" + name + "' and '" + folderId + "' in parents"
    if (name.split('.').pop() === 'xml' || name.split('.').pop() === 'XML') {
      // if (getTitle1(tab, data)) {
      await fetch(`https://www.googleapis.com/drive/v3/files?key=${API_KEY}&q=${query}&fields=files(name,id)`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        }
      }).then(res => res.json()).then((response) => {
        if (response.result.files.length === 0) {
          uploadXMLFiles(data, accessToken);
        } else {
          // setIsLoading(false);
          setLoading(false);
          setShowLoadingText(false);
          showError('XML File already exists.');
        }
      })
        .catch(function (err) {
          setLoading(false);
          setShowLoadingText(false);
        });
    } else {
      showError('Please upload XML format file.');
    }
  };

  //create files
  const createFiles = (folderId, name, accessToken) => {
    listFiles(
      `${name}_${new Date(configurationSelectedDate).getFullYear().toString()}`,
      folderId,
      name,
      accessToken
    );
  };

  //create file url
  const createFileUrl = (name, files) => {
    setExcelUrl(`https://docs.google.com/spreadsheets/d/${files}/edit#gid=0`)
  };

  useEffect(() => {
    if (nextProps.componentsByIdData) {
      setXmlId(nextProps.componentsByIdData?.data?.result?.[0]?.id);
    }
  }, [nextProps.componentsByIdData]);

  //upload xml files
  const uploadXMLFiles = (xmlData, accessToken) => {
    if (xmlData) {
      const fileMetadata = { name: `${selectedComponent?.name}_${new Date(configurationSelectedDate)?.getFullYear()?.toString()}.xml`, parents: [folderId], mimeType: 'text/xml' }; // Please set filename
      const form = new FormData();
      form.append('metadata', new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' }));
      form.append('file', xmlData);
      fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id,name', {
        method: 'POST',
        headers: new Headers({ 'Authorization': 'Bearer ' + accessToken }),
        body: form
      }).then(res => res.json()).then(async res => {
        if (res) {
          if (res?.error) throw res?.error
          // setIsLoading(false)
          await createPermissions(res.name, res.id, accessToken);
          setLoading(false);
          setShowLoadingText(false)
          let date = JSON?.stringify(configurationSelectedDate?.getFullYear());
          history.push('/surveyDataSettingsDashboard')
          showSuccess(`Congrats! Configuration of Survey related to ${selectedComponent?.name} for ${date} has been done successfully.`)
        }
      })
        .catch(err => {
          setLoading(false);
          setShowLoadingText(false)
          console.log(err)
          if (err.code === 401 && err?.status === "UNAUTHENTICATED" && getODKAccessToken()) {
            setIsTokenExpired(true)
          } else setIsTokenExpired(false)
        })
    }
  }

  const handleInvitePeople = () => {
    setShowModal(true);
  };

  const handleComponent = (data) => {
    setSelectedComponent(data)
  }

  const handleConfigure = (props) => {
    if (signedInUserDetails) {
      let accessToken = getODKAccessToken()
      if (accessToken) {
        if (folderId === null) {
          setIsConfigure(true);
          createFolder(
            signedInUser,
            signedInUserDetails,
            accessToken
          );
        }
        else {
          if (
            folderId &&
            userDetails?.google_email &&
            signedInUserDetails === userDetails?.google_email
          ) {
            createFiles(folderId, selectedComponent?.name, accessToken);
          }
        }
      }
      else {
        setLoading(false);
        setShowLoadingText(false)
        if(userDetails?.google_email)
        tokenClient?.requestAccessToken({ prompt:'', hint: userDetails?.google_email || '' })
        else tokenClient?.requestAccessToken({ prompt: 'consent' })
        tokenClient.callback = (res) => {
          setIsTokenExpired(false);
          if (res && res?.access_token) {
            setAccessToken(res?.access_token)
            setODKAccessToken(res?.access_token)
            if (folderId === null) {
              setIsConfigure(true);
              createFolder(
                signedInUser,
                signedInUserDetails,
                res?.access_token
              );
            }
            else {
              if (
                folderId &&
                userDetails?.google_email &&
                signedInUserDetails === userDetails?.google_email
              ) {
                createFiles(folderId, selectedComponent?.name, res?.access_token);
              }
            }
          }
        }
      }
    }
    else{
      showError('Please authenticate to configure the survey')
    }
  }

  const handleDate = (date) => {
    setDate(date);
    /** added this because design changed */
    setShowModal1(false);
    setConfigurationSelectedDate(date);
    setExcelUrl('')
    setXmlFileUrl('')
  };


  useEffect(() => {
    if (nextProps.insuranceUserUpdateRequesting) {
      // setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [nextProps.insuranceUserUpdateRequesting]);

  const handleConfiguration = () => {
    setShowModal1(false);
    setConfigurationSelectedDate(date);
  };

  const goBack = () => {
    let date = JSON?.stringify(configurationSelectedDate?.getFullYear());
    if (paramLocation?.state?.component && paramLocation?.state?.date && date) {
      if (activeTab == 1) {
        history.push('/manageFieldData?Component1&fromBack=true' + '&date=' + date);
      } else if (activeTab == 2) {
        history.push('/manageFieldData?Enrollment&fromBack=true' + '&date=' + date);
      } else if (activeTab == 3) {
        history.push(
          '/manageFieldData?UnderstandingTheInsuranceContext&fromBack=true' + '&date=' + date,
        );
      } else if (activeTab == 4) {
        history.push('/manageFieldData?ImpactEvaluation&fromBack=true' + '&date=' + date);
      } else if (activeTab == 5) {
        history.push('/manageFieldData?Component5&fromBack=true' + '&date=' + date);
      }
    } else {
      history.goBack();
    }
  };

  return (
    <Container fluid>
      {(loading && !isLoading) && <Loader showLoadingText={showLoadingText} />}
      {(isLoading && !loading) && <Loader />}

      <InvitePeopleModal modalOpen={showModal} setModalOpen={setShowModal} tokenClient={tokenClient} signedInUser={signedInUser} signedInUserDetails={signedInUserDetails} isTokenExpired={isTokenExpired} setIsTokenExpired={setIsTokenExpired}/>

      <ConfirmationModal
        modalOpen={showModal1}
        setModalOpen={setShowModal1}
        handleConfiguration={handleConfiguration}
      />

      <form autoComplete="off">
        <div className="right-img-wrapper api-key-list" style={{ marginTop: '35px' }}>
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="3"
              xl="3"
              // className="subheader-manage-user"
              // style={{paddingLeft:"4%"}}
              style={{ marginLeft: '3.7%', marginBottom: '1%' }}
            >
              <img
                src={RightArrow}
                alt="back-arrow"
                className="manage-user-header-back-button"
                onClick={() => goBack()}
              />
              &nbsp;&nbsp;&nbsp;
              <h3 className="heading-manage-user manage-user-heading-mt">Survey Configuration</h3>
            </Col>

            {/* <Col lg={2} xl={2} md={3} className="md-dtepkr1" style={{ paddingLeft: '0px' }}>
              {activeTab !== 0 && (
                <FormGroup className="registertextOnInput " style={{ marginRight: '10px' }}>
                  <DatePicker
                    selected={configurationSelectedDate ? configurationSelectedDate : null}
                    value={configurationSelectedDate ? configurationSelectedDate : null}
                    className="input-style configuration mt-0 form-control select-field-zindex"
                    onChange={(date) => handleDate(date)}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={9}
                    autoComplete="off"
                    minDate={new Date('2000-01-01T00:00:00Z')}
                    maxDate={new Date()}
                    style={{ zIndex: 100 }}
                    name="startDate"
                    shouldCloseOnSelect={true}
                    placeholderText={'Select Year'}
                    id="startDate"
                    onBlur={handleOnBlur}
                    onSelect={handleSelect}
                  />
                </FormGroup>
              )}
            </Col> */}

            <Col
              lg={8}
              xl={8}
              md={3}
              style={{ display: 'flex', justifyContent: 'flex-end',paddingRight: '0px' }}
            >
              <Button
                // style={{
                //   backgroundColor: 'rgb(56, 112, 175)',
                //   borderStyle: 'none',
                //   padding: '5px',

                //   color: '#FFF',
                //   fontSize: '14px',
                //   borderRadius: '5px',
                // }}
                // className="invite-peope-css"
                style={{
                  height: '40px',
                  backgroundColor: '#3870AF',
                  marginLeft: '10px',
                  // width: '109px'
                }}
                onClick={handleInvitePeople}
              >
                Invite people
              </Button>
            </Col>
          </Row>

          <Row style={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '2%', textAlign: 'center' }}>
            <Col className='form-col-survey-css'>
              <Col lg={6} xl={6} md={4} className="config-col">
                <FormGroup>
                  <Label for="startDate">Year</Label>
                  <DatePicker
                    selected={configurationSelectedDate ? configurationSelectedDate : null}
                    value={configurationSelectedDate ? configurationSelectedDate : null}
                    className="input-style configuration mt-0 form-control select-field-zindex"
                    onChange={(date) => handleDate(date)}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={9}
                    autoComplete="off"
                    minDate={new Date('2000-01-01T00:00:00Z')}
                    maxDate={new Date()}
                    style={{ zIndex: 100 }}
                    name="startDate"
                    shouldCloseOnSelect={true}
                    placeholderText={'Select Year'}
                    id="startDate"
                  // onBlur={handleOnBlur}
                  // onSelect={handleSelect}
                  />
                </FormGroup>
                <FormGroup className='mt-3'>
                  <Label for="component">Component</Label>
                  <Field
                    component={renderReactSelectInputField}
                    id="multi-select"
                    name="component"
                    placeholder="Select Component"
                    options={componentOptions}
                    onChange={handleComponent}
                    isMulti={false}
                    required={true}
                    value={''}
                  />
                </FormGroup>
                <Col lg={12} md={12} style={{ textAlign: 'center' }} className="mt-5">
                  <Button
                    onClick={() => handleConfigure(selectedComponent, configurationSelectedDate, folderId)}
                    style={{
                      height: '50px',
                      backgroundColor: '#3870AF',
                      marginLeft: '10px',
                      width: '109px'
                    }}
                    disabled={selectedComponent && configurationSelectedDate ? false : true}
                  >
                    Configure
                  </Button>
                </Col>
              </Col>
            </Col>
            {/* <Col lg={3} md={6}>
              <Nav tabs className="" style={{ borderBottom: '0px' }}>
                <div style={{ backgroundColor: '#F3F9FF', borderRadius: '10px', padding: '' }}>
                  {nextProps?.componentsList?.data?.result?.map((item, key) => {
                    return (
                      <NavItem style={{ height: '55px', width: '100%' }} className="">
                        <NavLink
                          className={classnames({ active: activeTab === key + 1 })}
                          onClick={() => {
                            toggle(key + 1, item?.id, item?.name);
                          }}
                          style={
                            activeTab === key + 1
                              ? {
                                height: '52px',
                                display: 'flex',

                                alignItems: 'center',
                                backgroundColor: '#DCE7F4',
                                color: '#363636',
                                borderRadius: '7px',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                              }
                              : {
                                height: '52px',
                                display: 'flex',

                                alignItems: 'center',
                                color: '#7C8EA0',
                                cursor: 'pointer',
                                backgroundColor: '#F3F9FF',
                              }
                          }
                        >
                          {item?.name}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </div>
              </Nav>
            </Col>
            <Col lg={9} md={6}>
              <Col lg={12}>
                <div className="register-form1 regstrform1 w-100" style={{ width: '95%' }}>
                  {activeTab === 1 && (
                    <>
                      <FormGroup className="textOnInput mb-3">
                        <Label for="exampleEmail">File URLs</Label>
                        <Field
                          component={renderTextField}
                          type="text"
                          name="fileUrls"
                          className="email-input"
                          placeholder="Copy your link here"
                          disabled
                        />

                        <RiFileCopyLine
                          style={{
                            position: 'absolute',
                            right: '0.1%',
                            height: '38px',
                            width: '29px',
                            border: '1px solid #5088C6',
                            color: '#3870AF',
                            cursor: 'pointer',
                            backgroundColor: '#E9ECEF',
                          }}
                          onClick={() => CopyToClipBoard(url1)}
                          id="CopyUrl1"
                          data-tip
                          data-for="CopyUrl1"
                          className="copyUrl"
                          onMouseLeave={() => {
                            setToolTip(false);
                            setCopy(false);
                          }}
                          onMouseEnter={() => setToolTip(true)}
                        />
                      </FormGroup>
                      {toolTip && (
                        <ReactTooltip
                          id="CopyUrl1"
                          effect="solid"
                          place="right"
                          type="info"
                          className="bg-dark copyUrl1 copyUrl copy-url-tooltip-st"
                        >
                          {copy ? 'Copied...' : 'Click here to copy URL.'}
                        </ReactTooltip>
                      )}
                      <fieldset
                        className=" rounded-3 p-2"
                        style={{ width: '95%', border: '1px dashed #5088C6', marginBottom: '30px' }}
                      >
                        <legend
                          className="float-none w-auto px-1"
                          style={{ color: '#717D88', fontSize: '11px', marginBottom: '0px' }}
                        >
                          Upload Survey
                        </legend>
                        {data && data[0]?.name ? (
                          <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div
                                style={{
                                  backgroundColor: '#EDEDED',
                                  borderRadius: '5px',
                                  width: 'max-content',
                                }}
                              >
                                <p style={{ margin: 'auto', padding: '5px' }}>
                                  {' '}
                                  <img src={UploadFormImg} /> {data[0].name}
                                </p>
                              </div>
                              <div>
                                <img
                                  src={CrossImg}
                                  style={{ cursor: 'pointer', marginLeft: '10px' ,marginTop:'10px'}}
                                  onClick={(e) => setData([])}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <Dropzone onDrop={(file) => setData(file)}>
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <img src={UploadImg} style={{ cursor: 'pointer' }} />
                                  </span>
                                  <p style={{ textAlign: 'center' }}>
                                    Drag and drop here or{' '}
                                    <span
                                      style={{
                                        color: '#3870AF',
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <a>Browse files</a>
                                    </span>
                                  </p>
                                </>
                              </div>
                            )}
                          </Dropzone>
                        )}
                      </fieldset>
                    </>
                  )}
                  {activeTab === 2 && (
                    <>
                      <FormGroup className="textOnInput mb-3">
                        <Label for="exampleEmail">File URLs</Label>
                        <Field
                          component={renderTextField}
                          type="text"
                          name="fileUrls2"
                          className="email-input"
                          placeholder="Copy your link here"
                          disabled
                        />

                        <RiFileCopyLine
                          style={{
                            position: 'absolute',
                            right: '0.1%',
                            height: '38px',
                            width: '29px',
                            border: '1px solid #5088C6',
                            color: '#3870AF',
                            cursor: 'pointer',
                            backgroundColor: '#E9ECEF',
                          }}
                          onClick={() => CopyToClipBoard(url2)}
                          id="CopyUrl2"
                          data-tip
                          data-for="CopyUrl2"
                          className="copyUrl"
                          onMouseLeave={() => {
                            setToolTip(false);
                            setCopy(false);
                          }}
                          onMouseEnter={() => setToolTip(true)}
                        />
                      </FormGroup>
                      {toolTip && (
                        <ReactTooltip
                          id="CopyUrl2"
                          effect="solid"
                          place="right"
                          type="info"
                          className="bg-dark  copyUrl copy-url-tooltip-st"
                        >
                          {copy ? 'Copied...' : 'Click here to copy URL.'}
                        </ReactTooltip>
                      )}
                      <fieldset
                        className=" rounded-3 p-2"
                        style={{ width: '95%', border: '1px dashed #5088C6', marginBottom: '30px' }}
                      >
                        <legend
                          className="float-none w-auto px-1"
                          style={{ color: '#717D88', fontSize: '11px', marginBottom: '0px' }}
                        >
                          Upload Survey
                        </legend>
                        {data2 && data2[0]?.name ? (
                          <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div
                                style={{
                                  backgroundColor: '#EDEDED',
                                  borderRadius: '5px',
                                  width: 'max-content',
                                }}
                              >
                                <p style={{ margin: 'auto', padding: '5px' }}>
                                  {' '}
                                  <img src={UploadFormImg} /> {data2[0].name}
                                </p>
                              </div>
                              <div>
                                <img
                                  src={CrossImg}
                                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                                  onClick={(e) => setData2([])}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <Dropzone onDrop={(file) => setData2(file)}>
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <img src={UploadImg} style={{ cursor: 'pointer' }} />
                                  </span>
                                  <p style={{ textAlign: 'center' }}>
                                    Drag and drop here or{' '}
                                    <span
                                      style={{
                                        color: '#3870AF',
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <a>Browse files</a>
                                    </span>
                                  </p>
                                </>
                              </div>
                            )}
                          </Dropzone>
                        )}
                      </fieldset>
                    </>
                  )}
                  {activeTab === 3 && (
                    <>
                      <FormGroup className="textOnInput mb-3">
                        <Label for="exampleEmail">File URLs</Label>
                        <Field
                          component={renderTextField}
                          type="text"
                          name="fileUrls3"
                          className="email-input"
                          placeholder="Copy your link here"
                          disabled
                        />

                        <RiFileCopyLine
                          style={{
                            position: 'absolute',
                            right: '0.1%',
                            height: '38px',
                            width: '29px',
                            border: '1px solid #5088C6',
                            color: '#3870AF',
                            cursor: 'pointer',
                            backgroundColor: '#E9ECEF',
                          }}
                          onClick={() => CopyToClipBoard(url3)}
                          id="CopyUrl3"
                          data-tip
                          data-for="CopyUrl3"
                          className="copyUrl"
                          onMouseLeave={() => {
                            setToolTip(false);
                            setCopy(false);
                          }}
                          onMouseEnter={() => setToolTip(true)}
                        />
                      </FormGroup>
                      {toolTip && (
                        <ReactTooltip
                          id="CopyUrl3"
                          effect="solid"
                          place="right"
                          type="info"
                          className="bg-dark copyUrl copy-url-tooltip-st"
                        >
                          {copy ? 'Copied...' : 'Click here to copy URL.'}
                        </ReactTooltip>
                      )}
                      <fieldset
                        className=" rounded-3 p-2"
                        style={{ width: '95%', border: '1px dashed #5088C6', marginBottom: '30px' }}
                      >
                        <legend
                          className="float-none w-auto px-1"
                          style={{ color: '#717D88', fontSize: '11px', marginBottom: '0px' }}
                        >
                          Upload Survey
                        </legend>
                        {data3 && data3[0]?.name ? (
                          <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div
                                style={{
                                  backgroundColor: '#EDEDED',
                                  borderRadius: '5px',
                                  width: 'max-content',
                                }}
                              >
                                <p style={{ margin: 'auto', padding: '5px' }}>
                                  {' '}
                                  <img src={UploadFormImg} /> {data3[0].name}
                                </p>
                              </div>
                              <div>
                                <img
                                  src={CrossImg}
                                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                                  onClick={(e) => setData3([])}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <Dropzone onDrop={(file) => setData3(file)}>
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <img src={UploadImg} style={{ cursor: 'pointer' }} />
                                  </span>
                                  <p style={{ textAlign: 'center' }}>
                                    Drag and drop here or{' '}
                                    <span
                                      style={{
                                        color: '#3870AF',
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <a>Browse files</a>
                                    </span>
                                  </p>
                                </>
                              </div>
                            )}
                          </Dropzone>
                        )}
                      </fieldset>
                    </>
                  )}
                  {activeTab === 4 && (
                    <>
                      <FormGroup className="textOnInput mb-3">
                        <Label for="exampleEmail">File URLs</Label>
                        <Field
                          component={renderTextField}
                          type="text"
                          name="fileUrls4"
                          className="email-input"
                          placeholder="Copy your link here"
                          disabled
                        />

                        <RiFileCopyLine
                          style={{
                            position: 'absolute',
                            right: '0.1%',
                            height: '38px',
                            width: '29px',
                            border: '1px solid #5088C6',
                            color: '#3870AF',
                            cursor: 'pointer',
                            backgroundColor: '#E9ECEF',
                          }}
                          onClick={() => CopyToClipBoard(url4)}
                          id="CopyUrl4"
                          data-tip
                          data-for="CopyUrl4"
                          className="copyUrl"
                          onMouseLeave={() => {
                            setToolTip(false);
                            setCopy(false);
                          }}
                          onMouseEnter={() => setToolTip(true)}
                        />
                      </FormGroup>

                      {toolTip && (
                        <ReactTooltip
                          id="CopyUrl4"
                          effect="solid"
                          place="right"
                          type="info"
                          className="bg-dark copyUrl copy-url-tooltip-st"
                        >
                          {copy ? 'Copied...' : 'Click here to copy URL.'}
                        </ReactTooltip>
                      )}
                      <fieldset
                        className=" rounded-3 p-2"
                        style={{ width: '95%', border: '1px dashed #5088C6', marginBottom: '30px' }}
                      >
                        <legend
                          className="float-none w-auto px-1"
                          style={{ color: '#717D88', fontSize: '11px', marginBottom: '0px' }}
                        >
                          Upload Survey
                        </legend>
                        {data4 && data4[0]?.name ? (
                          <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div
                                style={{
                                  backgroundColor: '#EDEDED',
                                  borderRadius: '5px',
                                  width: 'max-content',
                                }}
                              >
                                <p style={{ margin: 'auto', padding: '5px' }}>
                                  {' '}
                                  <img src={UploadFormImg} /> {data4[0].name}
                                </p>
                              </div>
                              <div>
                                <img
                                  src={CrossImg}
                                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                                  onClick={(e) => setData4([])}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <Dropzone onDrop={(file) => setData4(file)}>
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <img src={UploadImg} style={{ cursor: 'pointer' }} />
                                  </span>
                                  <p style={{ textAlign: 'center' }}>
                                    Drag and drop here or{' '}
                                    <span
                                      style={{
                                        color: '#3870AF',
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <a>Browse files</a>
                                    </span>
                                  </p>
                                </>
                              </div>
                            )}
                          </Dropzone>
                        )}
                      </fieldset>
                    </>
                  )}
                  {activeTab === 5 && (
                    <>
                      <FormGroup className="textOnInput mb-3">
                        <Label for="exampleEmail">File URLs</Label>
                        <Field
                          component={renderTextField}
                          type="text"
                          name="fileUrls5"
                          className="email-input"
                          placeholder="Copy your link here"
                          disabled
                        />

                        <RiFileCopyLine
                          style={{
                            position: 'absolute',
                            right: '0.1%',
                            height: '38px',
                            width: '29px',
                            border: '1px solid #5088C6',
                            color: '#3870AF',
                            cursor: 'pointer',
                            backgroundColor: '#E9ECEF',
                          }}
                          onClick={() => CopyToClipBoard(url5)}
                          id="CopyUrl5"
                          data-tip
                          data-for="CopyUrl5"
                          className="copyUrl"
                          onMouseLeave={() => {
                            setToolTip(false);
                            setCopy(false);
                          }}
                          onMouseEnter={() => setToolTip(true)}
                        />
                      </FormGroup>

                      {toolTip && (
                        <ReactTooltip
                          id="CopyUrl5"
                          effect="solid"
                          place="right"
                          type="info"
                          className="bg-dark  copyUrl copy-url-tooltip-st"
                        >
                          {copy ? 'Copied...' : 'Click here to copy URL.'}
                        </ReactTooltip>
                      )}
                      <fieldset
                        className=" rounded-3 p-2"
                        style={{ width: '95%', border: '1px dashed #5088C6', marginBottom: '30px' }}
                      >
                        <legend
                          className="float-none w-auto px-1"
                          style={{ color: '#717D88', fontSize: '11px', marginBottom: '0px' }}
                        >
                          Upload Survey
                        </legend>
                        {data5 && data5[0]?.name ? (
                          <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div
                                style={{
                                  backgroundColor: '#EDEDED',
                                  borderRadius: '5px',
                                  width: 'max-content',
                                }}
                              >
                                <p style={{ margin: 'auto', padding: '5px' }}>
                                  {' '}
                                  <img src={UploadFormImg} /> {data5[0].name}
                                </p>
                              </div>
                              <div>
                                <img
                                  src={CrossImg}
                                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                                  onClick={(e) => setData5([])}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <Dropzone onDrop={(file) => setData5(file)}>
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <img src={UploadImg} style={{ cursor: 'pointer' }} />
                                  </span>
                                  <p style={{ textAlign: 'center' }}>
                                    Drag and drop here or{' '}
                                    <span
                                      style={{
                                        color: '#3870AF',
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <a>Browse files</a>
                                    </span>
                                  </p>
                                </>
                              </div>
                            )}
                          </Dropzone>
                        )}
                      </fieldset>
                    </>
                  )}
                </div>
              </Col>

              <Col lg={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                {activeTab !== 0 && (
                  <div>
                    {handleShowDownloadLink() ? (
                      <a
                        style={{
                          color: '#5088C6',
                          textDecoration: 'underline',
                          fontSize: '13px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => handleClick(xmlLink, xmlname)}
                        target="_blank"
                        download
                      >
                        Download form template
                      </a>
                    ) : (
                      ''
                    )}
                    <br />
                    <a style={{ color: '#5088C6', fontSize: '13px' }}>
                      Need help?{' '}
                      <a
                        // onClick={() => history.push('/user-guide')}
                        style={{ textDecoration: 'underline', fontSize: '13px', cursor: 'pointer' }}
                        target="_blank"
                        href={`/user-guide-details?topicId=${FIELD_DATA_MODULE.id}&&sub-topic-name=Field Data Integration`}
                      >
                        View user guide
                      </a>
                    </a>
                  </div>
                )}

                {activeTab === 0 ? (
                  ''
                ) : (
                  <div style={{ display: 'flex', marginRight: '4%' }}>
                    <Button
                      onClick={(e) => handleClickNext(e)}
                      style={{
                        height: '48px',
                        backgroundColor: '#3870AF',
                        marginLeft: '10px',
                        marginRight: '5%',
                      }}
                      disabled={hanldeDisable()}
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </Col>
              {activeTab === 0 && (
                <p
                  style={{
                    textAlign: 'center',
                    fontFamily: 'National',
                    marginTop: '50px',
                    marginRight: '35px',
                    marginTop: '75px',
                    paddingRight: '0px',
                  }}
                  className="mrgLft1"
                >
                  Please select the required component from the left side panel.
                </p>
              )}
            </Col> */}
          </Row>
        </div>
      </form>
    </Container>
  );
};

export default compose(
  reduxForm({
    form: 'ConfigurationPageForm',
  }),
)(ConfigurationPage);
