import React from 'react';
import { t } from 'react-multi-lang';

const Loader = (props) => {
  return (
    <>
      <div className="loader-container">
        <div
          className={
            props?.isStaticResourceData
              ? 'static-resource-loader'
              : props?.style?.left
              ? `rgst-ldr`
              : props?.style?.right
              ? `register-loader`
              : props?.style?.top
              ? `odk-loader`
              : props?.isManageStaticResourceData 
              ? 'manage-static-resource-loader'
              : props?.isODKUploadSurvey 
              ? `odk-upload-loader`
              : `loader`
          }
        >
          <div class="loadingio-spinner-spinner-m63cop84tg">
            <div class="ldio-3ucpol5dcb2">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          {/* {props.showText && <p className="loader-idsi-vwi">{t('LOADER.LOADER_TEXT')}</p>} */}
          {!props.showLoadingText && <p className="loader-idsi-vwi">{t('LOADER.LOADER_TEXT')}</p>}
          {/* {props.showLoadingText && (
            <p className="loader-exl-vwi">
              Please wait! We are creating Excel Sheet in your Google Drive folder.
            </p>
          )} */}
          {props.showLoadingText && (
            <p className="loader-exl-vwi">
              Please wait! Configuration under process.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Loader;
