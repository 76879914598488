import React, { useState, useEffect } from 'react';
import { t } from 'react-multi-lang';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import _, { truncate } from 'lodash';
import Select from 'react-select';
import { Col, Row, Collapse, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import {
  TEMPORAL_AGGREGATION,
  CROP_HEALTH_MODULE,
  CROP_MONITOR_MIN_MAX_HIDE_INDICATORS_SLUG,
} from '../../../Components/constants';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { AiOutlineCaretUp } from 'react-icons/ai';
import { BiPlus, BiMinus } from 'react-icons/bi';
import ReactTooltip from 'react-tooltip';

const Agriculture = (props) => {
  const {
    selectedParam,
    stateList,
    handleSelectedItem,
    state,
    country,
    districtList,
    sensorsList,
    handleSelectedSensor,
    handleCompareSelectedSensor,
    selectedSensor,
    compareSelectedSensor,
    isUserClickedOnPlus,
    handleSelectedCropParam,
    handleSelectedCompareParam,
    temporalAggregation,
    handleTemporalAggregation,
    handleParams,
    setCompareParam,
    initialSelectedDate,
    handleStartDate,
    handleEndDate,
    isCompare,
    handleCompareToggle,
    handleCompareSelectedItem,
    compareCountry,
    setCategoryWiseCompareAgricultureData: setCategoryWiseCompareData,
    categoryWiseCompareAgricultureData: categoryWiseCompareData,
    compareStateList,
    previousActiveTab,
    previousTabSwitch,
    openAgricultureCollapse: openCollapse,
    setOpenAgricultureCollapse: setOpenCollapse,
    compareState,
    compareDistrictList,
    selectedCompareDistrict,
    finalSelectedDate,
    selectedLocation,
    compareHandler,
    handleFinalStartDate,
    compareParam,
    handleFinalEndDate,
    compareError,
    selectedDistrict,
    setSensorsList,
    sensorsCompareList,
    setSensorsCompareList,
    selectedSensorParam,
    selectedCompareSensorParam,
    setSelectedCompareSensorParam,
    //tab switching
    activeTabSwitch,
    handleTab1,
    handleTab2,
    indicatorsList,
    selectedIndicators,
    handleSelectedIndicators,
    selectedIndicatorsParam,
    activeTab,
    indicatorsCompareList,
    handleSelectedCompareIndicators,
    selectedCompareIndicators,
    setSelectedCompareIndicators,
    selectedCompareIndicatorsParam,
    setSelectedCompareIndicatorsParam,
    error,
    errorMessage,
    setInvalidDate,
    isClickedOnPlus,
    tempState,
    setTempState,
    minError,
    isPreviousCompareClick,
    setIsPreviousCompareClick,
    setCompareTempState,
    compareMinError,
    compareTempState,
    cropWiseProRenderState,
    setCropWiseProRenderState,
    compareCropWiseProRenderState,
    setCompareCropWiseProRenderState,
    cropArea,
    compareCropArea,
    season,
    compareSeason,
    practise,
    comparePractise,
    seasonList,
    practiseList,
    cropYear,
    renderCropYearOptions,
    compareCropYear,
    renderCompareCropYearOptions,
    setSelectedParam,
    setInitialSelectedDate,
    setFinalSelectedDate
  } = props;
  const [selectedSensorCategoryList, setSelectedSensorCategoryList] = useState([]);
  const [categoryWiseData, setCategoryWiseData] = useState([]);
  const [selectedCompareSensorCategoryList, setSelectedCompareSensorCategoryList] = useState([]);

  // indicators to  sensors
  const [selectedIndicatorToSensorList, setSelectedIndicatorToSensorList] = useState([]);
  const [selectedCompareIndicatorToSensorList, setSelectedCompareIndicatorToSensorList] = useState(
    [],
  );
  const [temCwpState, setTemCwpState] = useState(false);

  useEffect(() => {
    if (activeTab !== previousActiveTab) {
      resetStates();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTabSwitch !== previousTabSwitch) {
      resetStates();
    }
  }, [activeTabSwitch]);

  useEffect(() => {
    if (selectedParam?.slug === 'cwp' && _.isEmpty(compareParam)) {
      setTemCwpState(true);
    } else if (selectedParam?.slug !== 'cwp' && _.isEmpty(compareParam)) {
      setTemCwpState(false);
    } else if (selectedParam?.slug === 'cwp' && compareParam?.slug === 'cwp') {
      setTemCwpState(true);
    } else if (selectedParam?.slug !== 'cwp' && compareParam?.slug === 'cwp') {
      setTemCwpState(false);
    } else if (selectedParam?.slug === 'cwp' && compareParam?.slug !== 'cwp') {
      setTemCwpState(false);
    }
  }, [selectedParam, compareParam]);

  useEffect(() => {
    if (isUserClickedOnPlus) {
      setCategoryWiseCompareData([]);
      setSelectedCompareIndicatorToSensorList([]);
    }
    // setSelectedCompareSensorCategoryList([]);
    // categoryWiseCompareData?.map((i) => {
    //   i?.data?.map((x) => {
    //       x.checked = false;
    //   });
    // });
    // setCategoryWiseCompareData([...categoryWiseCompareData]);
    sensorsCompareList?.map((i, index) => {
      i.checked = false;
    });
    setSensorsCompareList([...sensorsCompareList]);
  }, [isClickedOnPlus]);

  // sensors to indicators
  useEffect(() => {
    if (!_.isEmpty(selectedSensor)) {
      let category_list = [];
      selectedSensor?.data?.map((i) => {
        i?.crop_monitor_sensor?.map((a) => {
          if (!category_list.includes(a?.category_name)) {
            category_list.push(a?.category_name);
          }
        });
      });
      setSelectedSensorCategoryList(category_list);
    }
  }, [selectedSensor]);
  useEffect(() => {
    if (!_.isEmpty(compareSelectedSensor)) {
      let category_list = [];
      compareSelectedSensor?.data?.map((i) => {
        i?.crop_monitor_sensor?.map((a) => {
          if (!category_list.includes(a?.category_name)) {
            category_list.push(a?.category_name);
          }
        });
      });
      setSelectedCompareSensorCategoryList(category_list);
    }
  }, [compareSelectedSensor]);

  useEffect(() => {
    if (!_.isEmpty(selectedSensorCategoryList)) {
      let category_wise_data = [];
      // let data =[] ;
      selectedSensor?.data?.map((i) => {
        selectedSensorCategoryList?.map((y) => {
          category_wise_data.push({
            category_name: y,
            data: i?.crop_monitor_sensor?.filter((x) => x.category_name === y),
            category_collapse: true,
          });
          // data=  i?.crop_monitor_sensor?.filter((x) => x.category_name === y)
        });
      });
      category_wise_data?.map((z) => {
        z?.data?.map((p) => {
          p.checked = false;
        });
      });
      category_wise_data?.map((i) => {
        i?.data?.map((y, index) => {
          y.paramIndex = index;
          y.sensor_name = selectedSensor?.name;
        });
      });
      setCategoryWiseData(category_wise_data);
      // handleParams(category_wise_data);
    }
  }, [selectedSensorCategoryList]);

  useEffect(() => {
    if (categoryWiseData?.length) {
      categoryWiseData.forEach((list) => list.data.forEach((item) => (item.checked = false)));
    }
  }, [selectedSensor]);

  useEffect(() => {
    if (categoryWiseCompareData?.length) {
      categoryWiseCompareData.forEach((list) =>
        list.data.forEach((item) => (item.checked = false)),
      );
    }
  }, [compareSelectedSensor]);

  useEffect(() => {
    if (selectedCompareSensorCategoryList?.length) {
      let category_wise_data = [];
      compareSelectedSensor?.data?.map((i) => {
        selectedCompareSensorCategoryList?.map((y) => {
          category_wise_data.push({
            category_name: y,
            data: i?.crop_monitor_sensor?.filter((x) => x.category_name === y),
            category_collapse: true,
          });
          // data=  i?.crop_monitor_sensor?.filter((x) => x.category_name === y)
        });
      });
      category_wise_data?.map((z) => {
        z?.data?.map((p) => {
          p.checked = false;
          p.sensor_name = compareSelectedSensor?.name;
        });
      });
      setCategoryWiseCompareData(category_wise_data);
    }
  }, [selectedCompareSensorCategoryList]);

  // useEffect(() => {
  //   if (!selectedLocation?.region?.length === false) {
  //     setIsPreviousCompareClick(openCollapse.agriculture_compare)
  //     setOpenCollapse((prev) => {
  //       return {
  //         ...prev,
  //         agriculture_compare: false,
  //       };
  //     });
  //   }
  // }, [selectedLocation?.region?.length]);

  useEffect(() => {
    if (selectedLocation?.region?.length) {
      if (openCollapse?.agriculture_compare) {
        setOpenCollapse((prev) => {
          return { ...prev, agriculture_compare: false };
        });
      }
      setIsPreviousCompareClick(openCollapse?.agriculture_compare);
    } else if (selectedLocation?.region !== null && isPreviousCompareClick) {
      setIsPreviousCompareClick(openCollapse?.agriculture_compare);
      setOpenCollapse((prev) => {
        return { ...prev, agriculture_compare: true };
      });
    }
  }, [selectedLocation?.region]);

  useEffect(() => {
    renderCropYearOptions(season);
  }, [season]);

  const renderCropAreaOptions = () => {
    const options = [
      { slug: 'sown_area', value: 'sown_area', label: 'Sown area', id: 'sown_area' },
      {
        slug: 'harvested_area',
        value: 'harvested_area',
        label: 'Harvested area',
        id: 'harvested_area',
      },
      { slug: 'yield', value: 'yield', label: 'Yield', id: 'yield' },
    ];
    return options;
  };

  const resetStates = () => {
    setSelectedIndicatorToSensorList([]);
    setSelectedCompareIndicatorToSensorList([]);
    // setSelectedIndicators({});
    setSelectedCompareSensorParam({});
    setSelectedCompareIndicatorsParam({});
    setCompareParam({});
    setCategoryWiseData([]);
    setCategoryWiseCompareData([]);
    // setSelectedSensor({});
    setSelectedCompareIndicators({});
    setIsPreviousCompareClick(openCollapse?.agriculture_compare);
    setOpenCollapse((prev) => {
      return {
        ...prev,
        agriculture_compare: false,
      };
    });
    setCropWiseProRenderState(false);
    setCompareCropWiseProRenderState(false);
  };

  const handleCateogryToggle = (item) => {
    // compareHandler();
    if (item === 'agriculture-location') {
      openCollapse.agriculture_location = !openCollapse.agriculture_location;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-datetime') {
      openCollapse.agriculture_datetime = !openCollapse.agriculture_datetime;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-sensors') {
      openCollapse.agriculture_sensors = !openCollapse.agriculture_sensors;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-indicators') {
      openCollapse.agriculture_indicators = !openCollapse.agriculture_indicators;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-compare-indicators') {
      openCollapse.agriculture_compare_indicators = !openCollapse.agriculture_compare_indicators;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-vegetation') {
      openCollapse.agriculture_vegetation = !openCollapse.agriculture_vegetation;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-compare') {
      compareHandler();
      setIsPreviousCompareClick(openCollapse.agriculture_compare);
      openCollapse.agriculture_compare = !openCollapse.agriculture_compare;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'compare-agriculture-sensors') {
      openCollapse.compare_agriculture_sensors = !openCollapse.compare_agriculture_sensors;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-gldas') {
      openCollapse.agriculture_gldas = !openCollapse.agriculture_gldas;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-raw-data') {
      openCollapse.agriculture_raw_data = !openCollapse.agriculture_raw_data;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-compare-raw-data') {
      openCollapse.agriculture_compare_raw_data = !openCollapse.agriculture_compare_raw_data;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-tab2-indicators') {
      openCollapse.agriculture_tab2_indicators = !openCollapse.agriculture_tab2_indicators;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-tab2-sensors') {
      openCollapse.agriculture_tab2_sensors = !openCollapse.agriculture_tab2_sensors;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-compare-tab2-indicators') {
      openCollapse.agriculture_compare_tab2_indicators =
        !openCollapse.agriculture_compare_tab2_indicators;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'agriculture-compare-tab2-sensors') {
      openCollapse.agriculture_compare_tab2_sensors =
        !openCollapse.agriculture_compare_tab2_sensors;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'crop-wise-production') {
      openCollapse.agriculture_crop_wise_production =
        !openCollapse.agriculture_crop_wise_production;
      setOpenCollapse({ ...openCollapse });
    } else if (item === 'compare-crop-wise-production') {
      openCollapse.agriculture_compare_crop_wise_production =
        !openCollapse.agriculture_compare_crop_wise_production;
      setOpenCollapse({ ...openCollapse });
    }
  };

  useEffect(() => {
    let isInvalidDate = false;
    let isStartDateInvalid =
      document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true' ? true : false;
    let isEndDateInvalid =
      document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true' ? true : false;
    let isCompareStartDateInvalid =
      document.getElementById('compareStartDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    let isCompareEndDateInvalid =
      document.getElementById('compareEndDate')?.getAttribute('aria-invalid') === 'true'
        ? true
        : false;
    if (
      isStartDateInvalid ||
      isEndDateInvalid ||
      isCompareStartDateInvalid ||
      isCompareEndDateInvalid
    ) {
      isInvalidDate = true;
    } else {
      isInvalidDate = false;
    }
    // setError(isInvalidDate);
    setInvalidDate(isInvalidDate);
  }, [initialSelectedDate, finalSelectedDate]);

  // indicators to sensors upper section
  useEffect(() => {
    if (!_.isEmpty(selectedIndicators)) {
      let sensors_list = [];
      selectedIndicators?.data?.map((i) => {
        if (!sensors_list.includes(i?.sensor_name)) {
          sensors_list.push(i);
        }
      });
      sensors_list?.map((i, index) => {
        // i.checked = false;
        i.paramIndex = index;
      });
      setSelectedIndicatorToSensorList(sensors_list);
    }
  }, [selectedIndicators]);

  useEffect(() => {
    //need to uncheck all sensors once select any indicator
    if (selectedIndicatorToSensorList?.length) {
      selectedIndicatorToSensorList.forEach((list) => (list.checked = false));
    }
  }, [selectedIndicatorToSensorList]);

  // indicators to sensors compare section
  useEffect(() => {
    if (!_.isEmpty(selectedCompareIndicators)) {
      let sensors_list = [];
      selectedCompareIndicators?.data?.map((i) => {
        if (!sensors_list.includes(i?.sensor_name)) {
          sensors_list.push(i);
        }
      });
      sensors_list?.map((i, index) => {
        i.paramIndex = index;
      });
      setSelectedCompareIndicatorToSensorList(sensors_list);
    }
  }, [selectedCompareIndicators]);

  const handleInfo = (e, indices) => {
    e?.stopPropagation();
    window.open(
      `/user-guide-details?topicId=${CROP_HEALTH_MODULE.id}&&sub-topic-name=${indices?.name}&topic_name=Crop Health Monitor`,
    );
  };

  const handleIndicatorToSensorsInfo = (e, indices) => {
    e?.stopPropagation();
    window.open(
      `/user-guide-details?topicId=${CROP_HEALTH_MODULE.id}&&sub-topic-name=${indices?.sensor_name}&topic_name=Crop Health Monitor`,
    );
  };
  const renderAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map((param) => {
          let data = {
            value: param.name,
            label: `${param.label}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };
  const renderCWPAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params?.length) {
        params?.map((param) => {
          let data = {
            slug: param.value,
            value: param.value,
            label: `${param.label}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };
  // for districts
  const renderAPIDistrictParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map((param) => {
          let data = {
            value: param.name,
            // label: `${param.name}`,
            label: `${param.label}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleCompareLocationRender = () => {
    let isGraphCompare = isCompare?.find((item) => item.name === 'graph' && item.selected);
    if (isGraphCompare) {
      return (
        <>
          <div className="pt-2  mb-3">
            <div className="mb-3 mx-3">
              <Select
                id="multi-select"
                name="country"
                placeholder="Select Country"
                // options={renderAPIParameterOptions(countryList)}
                // onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={selectedLocation.country}
                defaultValue={selectedLocation.country}
                isDisabled={true}
              />
            </div>
            {compareCountry && (
              <div className="mb-3 mx-3">
                <Select
                  id="multi-select"
                  name="state"
                  placeholder="Select Province"
                  options={renderAPIParameterOptions(compareStateList)}
                  onChange={handleCompareSelectedItem}
                  isMulti={false}
                  required={true}
                  value={compareState}
                />
              </div>
            )}
            {compareState && compareState.value !== 'Sri Lanka' && (
              <div className="mt-1 mx-3 mb-3">
                <Select
                  id="multi-select"
                  name="district"
                  placeholder="Select District"
                  options={renderAPIDistrictParameterOptions(compareDistrictList)}
                  onChange={handleCompareSelectedItem}
                  isMulti={false}
                  required={true}
                  value={selectedCompareDistrict}
                />
              </div>
            )}
          </div>
        </>
      );
    }
  };

  const handleCompareTab1Indices = () => {
    return (
      <>
        <span onClick={() => handleCateogryToggle('compare-agriculture-sensors')}>
          <p className="agriculture-headings-lable">
            Sensors
            <span className="category-span">
              <span className="category-span">
                {openCollapse.compare_agriculture_sensors ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="agriculture_sensors"
          isOpen={openCollapse.compare_agriculture_sensors}
          className="collapse-moadal"
        >
          {handleCompareTab1Sensors(sensorsCompareList)}
        </Collapse>
        {categoryWiseCompareData?.length ? (
          <>
            <span onClick={() => handleCateogryToggle('agriculture-compare-indicators')}>
              <p className="agriculture-headings-lable">
                Indicators
                <span className="category-span">
                  <span className="category-span">
                    {openCollapse.agriculture_compare_indicators ? (
                      <AiOutlineCaretUp size={13} />
                    ) : (
                      <AiOutlineCaretDown size={13} />
                    )}
                  </span>
                </span>
              </p>
            </span>
            <Collapse
              id="agriculture_compare_indicators"
              isOpen={openCollapse.agriculture_compare_indicators}
              className="collapse-moadal"
            >
              {handleRenderCategoryWiseTab1CompareData()}
            </Collapse>
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  const handleRenderCategoryWiseTab2CompareData = () => {
    return (
      // <>
      <div className="mb-2">
        {indicatorsCompareList?.length &&
          indicatorsCompareList?.map((i) => {
            return (
              <>
                <p className="indicators-headers">{i?.category}</p>
                <FormGroup check>
                  <Row>
                    {i?.data?.map((item) => {
                      return (
                        <>
                          <Col sm={12} lg={12} md={12} className="radio-button-alignment mt-1">
                            <label
                              style={{
                                fontFamily: 'National',
                                fontSize: '15px',
                                color: '#363636',
                              }}
                              // for={item.name}
                              for={`${item.name}-compare`}
                            >
                              {item.name}
                            </label>
                            <span className="radio-icon-button">
                              <input
                                type="radio"
                                // id={item.name}
                                id={`${item.name}-compare`}
                                name={'selected_tab2_indicator'}
                                value={item.slug}
                                checked={item?.name === selectedCompareIndicatorsParam?.name}
                                onChange={() => handleSelectedCompareIndicators(item)}
                                onClick={() => {
                                  handleCheckCompareIndices(item);
                                }}
                              />
                              <i
                                className={
                                  item?.data[0]?.slug === 'composite_crop_health_index'
                                    ? 'cursor-ponter fa fa-info info-icon-edit-btn crop-index-i-button'
                                    : // : item?.data[0]?.sensor_name === 'GLDAS'
                                      // ? 'cursor-ponter fa fa-info info-icon-edit-btn i-button others-icon'
                                      'cursor-ponter fa fa-info info-icon-edit-btn i-button'
                                }
                                id="info-icon-tool-tip"
                                data-tip
                                data-for="info-icon-tool-tip"
                                onClick={(e) => handleInfo(e, item)}
                              />
                              <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                                Click here to know more.
                              </ReactTooltip>
                            </span>
                          </Col>
                          {/* {compareCropWiseProRenderState &&
                            item?.data[0]?.slug === 'cwp' &&
                            handleCompareCropWiseProduction()} */}
                        </>
                      );
                    })}
                  </Row>
                </FormGroup>
              </>
            );
          })}
      </div>
      // </>
    );
  };
  const handleTab2CompareSensorsList = (list) => {
    return (
      <FormGroup check>
        <div className="mb-2">
          <Row>
            {list?.length &&
              list?.map((item, index) => {
                return (
                  <>
                    <Col sm={12} lg={12} md={12} className="mt-1 sensors-radios-buttons">
                      <label
                        style={{
                          fontFamily: 'National',
                          fontSize: '15px',
                          color: '#363636',
                        }}
                        // for={item.sensor_name === 'Others' ? 'No sensor' : item.sensor_name}
                        for={index}
                      >
                        {item.sensor_name === 'Others' ? 'No sensor' : item.sensor_name}
                      </label>
                      <span className="radio-icon-button">
                        <input
                          type="radio"
                          id={index}
                          name="radio-tab2-comapare-button"
                          className=""
                          value={item.sensor_name}
                          checked={item?.slug === compareParam?.slug}
                          // checked={item?.slug === 'cwp'? true : item?.slug === compareParam?.slug}
                          onChange={() => handleSelectedCompareIndices(item)}
                        />
                        <i
                          // className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                          className={
                            item?.sensor_name == 'Others'
                              ? 'cursor-ponter fa fa-info info-icon-edit-btn i-button others-icon'
                              : 'cursor-ponter fa fa-info info-icon-edit-btn i-button'
                          }
                          id="info-icon-tool-tip"
                          data-tip
                          data-for="info-icon-tool-tip"
                          onClick={(e) => handleIndicatorToSensorsInfo(e, item)}
                        />
                        <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                          Click here to know more.
                        </ReactTooltip>
                      </span>
                    </Col>
                  </>
                );
              })}
          </Row>
        </div>
      </FormGroup>
    );
  };
  const handleCompareTab2Indices = () => {
    return (
      <>
        <span onClick={() => handleCateogryToggle('agriculture-compare-tab2-indicators')}>
          <p className="agriculture-headings-lable">
            Indicators
            <span className="category-span">
              <span className="category-span">
                {openCollapse.agriculture_compare_tab2_indicators ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="agriculture_compare_tab2_indicators"
          isOpen={openCollapse.agriculture_compare_tab2_indicators}
          className="collapse-moadal"
        >
          {handleRenderCategoryWiseTab2CompareData()}
        </Collapse>

        {selectedCompareIndicatorToSensorList?.length ? (
          <>
            <span onClick={() => handleCateogryToggle('agriculture-compare-tab2-sensors')}>
              <p className="agriculture-headings-lable">
                Sensors
                <span className="category-span">
                  <span className="category-span">
                    {openCollapse.agriculture_compare_tab2_sensors ? (
                      <AiOutlineCaretUp size={13} />
                    ) : (
                      <AiOutlineCaretDown size={13} />
                    )}
                  </span>
                </span>
              </p>
            </span>
            <Collapse
              id="agriculture_compare_tab2_sensors"
              isOpen={openCollapse.agriculture_compare_tab2_sensors}
              className="collapse-moadal"
            >
              {handleTab2CompareSensorsList(selectedCompareIndicatorToSensorList)}
            </Collapse>
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  const handleSelectedIndices = (value) => {
    const end_date = moment(value?.max_date, 'YYYY-MM-DD');
    const start_date = end_date.clone().subtract(30, 'days');
    const start_date_str = start_date.format('YYYY-MM-DD');
    setInitialSelectedDate((prevdate) => {
      return {
        ...prevdate,
        startDate: start_date_str,
        endDate: value?.max_date,
      };
    });
    //setSelectedParam(data);
    if (activeTabSwitch === 'tab1') {
      categoryWiseData?.map((i) => {
        i?.data?.map((x) => {
          if (x.slug === value.slug) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      });
      let x = categoryWiseData.filter((item) => item.category_name === value?.category_name);
      handleParams(x);
      setCategoryWiseData([...categoryWiseData]);
      handleSelectedCropParam(value);
    } else if (activeTabSwitch === 'tab2') {
      selectedIndicatorToSensorList?.map((i, index) => {
        if (i?.sensor_name === value?.sensor_name) {
          i.checked = !i.checked;
        }
      });
      let category_wise_data = [];
      if (value?.slug === 'cwp') {
        category_wise_data.push({
          data: [value],
        });
        setCropWiseProRenderState(true);
      } else {
        setCropWiseProRenderState(false);
        category_wise_data.push({
          data: selectedIndicatorToSensorList.map((i) => i),
        });
      }
      let x = indicatorsList.filter((item) => item?.category === value?.category_name);
      handleParams(category_wise_data);
      setSelectedIndicatorToSensorList([...selectedIndicatorToSensorList]);
      handleSelectedCropParam(value);
    }
  };

  const handleSelectedCompareIndices = (value) => {
    const compare_end_date = moment(value?.max_date, 'YYYY-MM-DD');
    const compare_start_date = compare_end_date.clone().subtract(30, 'days');
    const compare_start_date_str = compare_start_date.format('YYYY-MM-DD');
    setFinalSelectedDate((prevdate) => {
      return {
        ...prevdate,
        startDate: compare_start_date_str,
        endDate: value?.max_date,
      };
    });
    if (activeTabSwitch === 'tab1') {
      categoryWiseCompareData?.map((i) => {
        i?.data?.map((x) => {
          if (x.slug === value.slug) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      });
      setCategoryWiseCompareData([...categoryWiseCompareData]);
      handleSelectedCompareParam(value);
    } else if (activeTabSwitch === 'tab2') {
      selectedCompareIndicatorToSensorList?.map((i, index) => {
        i.checked = false;
      });
      selectedCompareIndicatorToSensorList?.map((i, index) => {
        if (i.sensor_name === value?.sensor_name) {
          i.checked = !i.checked;
        }
      });
      if (value?.slug === 'cwp') {
        setCompareCropWiseProRenderState(true);
      } else {
        setCompareCropWiseProRenderState(false);
      }
      setSelectedCompareIndicatorToSensorList([...selectedCompareIndicatorToSensorList]);
      handleSelectedCompareParam(value);
    }
  };

  const handleSelectedSensorIndices = (value) => {
    //setSelectedParam(data)
    sensorsList?.map((i, index) => {
      i.checked = false;
    });
    sensorsList?.map((i, index) => {
      if (i.name === value?.name) {
        i.checked = !i.checked;
      }
    });
    setSensorsList([...sensorsList]);
    // handleSelectedCropParam(value);
  };

  const handleTab1SensorsList = (list) => {
    return (
      <FormGroup check>
        <div className="mb-2">
          <Row>
            {list?.map((item) => {
              return (
                <>
                  <Col sm={12} lg={12} md={12} className="mt-1 sensors-radios-buttons">
                    <label
                      style={{
                        fontFamily: 'National',
                        fontSize: '15px',
                        color: '#363636',
                      }}
                      for={item.name}
                    >
                      {item.name}
                    </label>
                    <span className="radio-icon-button">
                      <input
                        type="radio"
                        id={item.name}
                        name="radio-button"
                        className=""
                        value={item.name}
                        checked={item.name === selectedSensorParam?.name}
                        onChange={() => {
                          handleSelectedSensorIndices(item);
                        }}
                        onClick={() => {
                          handleSelectedSensor(item);
                          setCropWiseProRenderState(false);
                        }}
                      />
                      <i
                        className={
                          // item?.name == 'Others'
                          // ? 'cursor-ponter fa fa-info info-icon-edit-btn i-button others-icon'
                          // :
                          'cursor-ponter fa fa-info info-icon-edit-btn i-button'
                        }
                        id="info-icon-tool-tip"
                        data-tip
                        data-for="info-icon-tool-tip"
                        onClick={(e) => handleInfo(e, item)}
                      />
                      <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                        Click here to know more.
                      </ReactTooltip>
                    </span>
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      </FormGroup>
    );
  };
  const handleCropWiseProduction = () => {
    return (
      <>
        <span onClick={() => handleCateogryToggle('crop-wise-production')}>
          <p
            className={
              activeTabSwitch === 'tab1' ? 'sub-collapse-style mt-2' : 'sub-collapse-style1 mt-2'
            }
          >
            Crop wise Production
            <span className="category-span">
              <span className="category-span">
                {openCollapse.agriculture_crop_wise_production ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="agriculture_crop_wise_production"
          isOpen={openCollapse?.agriculture_crop_wise_production}
          className="collapse-moadal"
        >
          <div className="advance-search-fields mb-3">
            <div className={activeTabSwitch === 'tab1' ? 'mt-2 mb-3' : 'mt-2 mb-3 mx-3'}>
              <span className="raw-data-date-title">Season</span>
              <Select
                id="season-select"
                name="Season"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Season"
                options={renderCWPAPIParameterOptions(seasonList)}
                onChange={handleSelectedItem}
                isMulti={false}
                required={true}
                value={season}
              />
              <span className="raw-data-date-title">Practise</span>
              <Select
                id="practise-select"
                name="Practise"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Practise"
                options={renderCWPAPIParameterOptions(practiseList)}
                onChange={handleSelectedItem}
                isMulti={false}
                required={true}
                value={practise}
              />
              <span className="raw-data-date-title">Crop area</span>
              <Select
                id="crop-area-select"
                name="CropArea"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Crop area"
                options={renderCropAreaOptions()}
                onChange={handleSelectedItem}
                isMulti={false}
                required={true}
                value={cropArea}
              />
              <span className="raw-data-date-title">Crop year</span>
              <Select
                id="crop-area-select"
                name="CropYear"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Crop year"
                options={renderCropYearOptions(season)}
                onChange={handleSelectedItem}
                isMulti={false}
                required={true}
                value={cropYear}
              />
            </div>
          </div>
        </Collapse>
      </>
    );
  };
  // handleSelectedIndices = (value

  const handleCheckIndices = (value) => {
    if (activeTabSwitch === 'tab2') {
      if (value?.data[0]?.slug === 'cwp') {
        // setSelectedParam(value?.data[0])
        // setCropWiseProRenderState(true);
        // handleSelectedIndices(value?.data[0])
      } else {
        setCropWiseProRenderState(false);
      }
    } else {
      if (value?.slug === 'cwp') {
        setCropWiseProRenderState(true);
      } else {
        setCropWiseProRenderState(false);
      }
    }
  };
  const handleCheckCompareIndices = (value) => {
    if (activeTabSwitch === 'tab2') {
      if (value?.data[0]?.slug === 'cwp') {
        // setCompareParam(value?.data[0])
        // setCompareCropWiseProRenderState(true);
      } else {
        setCompareCropWiseProRenderState(false);
      }
    } else {
      if (value?.slug === 'cwp') {
        setCompareCropWiseProRenderState(true);
      } else {
        setCompareCropWiseProRenderState(false);
      }
    }
  };

  const handleRenderCategoryWiseData = () => {
    return (
      <>
        {categoryWiseData?.map((i) => {
          return (
            <>
              <p className="indicators-headers">{i?.category_name}</p>
              <FormGroup check className="tab1-indicator-list">
                <Row>
                  {/* {i?.data?.map((item) => { */}
                  {/* {categoryWiseData?.length && */}
                  {i?.data?.map((item) => {
                    // return i?.data?.map((item) => {
                    return (
                      <>
                        <Col sm={12} lg={12} md={12} className="radio-button-alignment mt-1">
                          <label
                            style={{
                              fontFamily: 'National',
                              fontSize: '15px',
                              color: '#363636',
                            }}
                            for={item.name}
                          >
                            {item.name}
                          </label>
                          <span className="radio-icon-button">
                            <input
                              type="radio"
                              id={item.name}
                              name={'indicies'}
                              value={item.slug}
                              checked={item.slug === selectedParam?.slug}
                              onChange={() => handleSelectedIndices(item)}
                              onClick={() => {
                                handleCheckIndices(item);
                              }}
                            />
                            <i
                              className={
                                item?.slug === 'composite_crop_health_index'
                                  ? 'cursor-ponter fa fa-info info-icon-edit-btn crop-index-i-button'
                                  : // : item?.sensor_name === 'GLDAS'
                                    // ? 'cursor-ponter fa fa-info info-icon-edit-btn i-button others-icon'
                                    'cursor-ponter fa fa-info info-icon-edit-btn i-button'
                              }
                              id="info-icon-tool-tip"
                              data-tip
                              data-for="info-icon-tool-tip"
                              onClick={(e) => handleInfo(e, item)}
                            />
                            <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                              Click here to know more.
                            </ReactTooltip>
                          </span>
                        </Col>
                        {cropWiseProRenderState &&
                          item?.slug === 'cwp' &&
                          handleCropWiseProduction()}
                      </>
                    );
                    // });
                  })}
                </Row>
              </FormGroup>
            </>
          );
        })}
      </>
    );
  };
  const handleSelectedCompareSensorIndices = (value) => {
    //setSelectedParam(data)
    sensorsCompareList?.map((i, index) => {
      i.checked = false;
    });
    sensorsCompareList?.map((i, index) => {
      if (i.name === value?.name) {
        i.checked = !i.checked;
      }
    });
    setSensorsCompareList([...sensorsCompareList]);
    // handleSelectedCompareParam(value);
  };

  const handleCompareTab1Sensors = (list) => {
    return (
      <FormGroup check>
        <div className="mb-2">
          <Row>
            {list?.map((item, index) => {
              return (
                <>
                  <Col sm={12} lg={12} md={12} className="mt-1 sensors-radios-buttons">
                    <label
                      style={{
                        fontFamily: 'National',
                        fontSize: '15px',
                        color: '#363636',
                      }}
                      for={item?.data[0]?.id}
                    >
                      {item.name}
                    </label>
                    <span className="radio-icon-button">
                      <input
                        type="radio"
                        id={item?.data[0]?.id}
                        name={'radio-compare-button'}
                        value={item.name}
                        checked={item.name === selectedCompareSensorParam?.name}
                        // checked={item.checked}
                        onChange={() => {
                          handleSelectedCompareSensorIndices(item);
                        }}
                        onClick={() => {
                          handleCompareSelectedSensor(item);
                          setCompareCropWiseProRenderState(false);
                        }}
                      />
                      <i
                        className={
                          // item?.name == 'Others'
                          // ? 'cursor-ponter fa fa-info info-icon-edit-btn i-button others-icon'
                          // :
                          'cursor-ponter fa fa-info info-icon-edit-btn i-button'
                        }
                        // className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                        id="info-icon-tool-tip"
                        data-tip
                        data-for="info-icon-tool-tip"
                        onClick={(e) => handleInfo(e, item)}
                      />
                      <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                        Click here to know more.
                      </ReactTooltip>
                    </span>
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      </FormGroup>
    );
  };
  const handleCompareCropWiseProduction = () => {
    return (
      <>
        <span onClick={() => handleCateogryToggle('compare-crop-wise-production')}>
          <p
            className={
              activeTabSwitch === 'tab1' ? 'sub-collapse-style mt-2' : 'sub-collapse-style1 mt-2'
            }
          >
            Crop wise Production
            <span className="category-span">
              <span className="category-span">
                {openCollapse.agriculture_compare_crop_wise_production ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="agriculture_compare_crop_wise_production"
          isOpen={openCollapse?.agriculture_compare_crop_wise_production}
          className="collapse-moadal"
        >
          <div className="advance-search-fields mb-3">
            <div className={activeTabSwitch === 'tab1' ? 'mt-2 mb-3' : 'mt-2 mb-3 mx-3'}>
              <span className="raw-data-date-title">Season</span>
              <Select
                id="compare-season-select"
                name="Season"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Season"
                options={renderCWPAPIParameterOptions(seasonList)}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={compareSeason}
              />
              <span className="raw-data-date-title">Practise</span>
              <Select
                id="compare-practise-select"
                name="Practise"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Practise"
                options={renderCWPAPIParameterOptions(practiseList)}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={comparePractise}
              />
              <span className="raw-data-date-title">Crop area</span>
              <Select
                id="compare-crop-area-select"
                name="CropArea"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Crop area"
                options={renderCropAreaOptions()}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={compareCropArea}
              />
              <span className="raw-data-date-title">Crop year</span>
              <Select
                id="compare-crop-area-select"
                name="CropYear"
                className="sub-collapse-dropdown mt-2 mb-2"
                placeholder="Crop year"
                options={renderCompareCropYearOptions(compareSeason)}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={compareCropYear}
              />
            </div>
          </div>
        </Collapse>
      </>
    );
  };

  const handleRenderCategoryWiseTab1CompareData = () => {
    return (
      <>
        {categoryWiseCompareData?.map((i) => {
          return (
            <>
              <p className="indicators-headers">{i?.category_name}</p>
              <FormGroup check>
                <Row>
                  {i?.data?.map((item) => {
                    return (
                      <>
                        <Label check>
                          <Col sm={12} lg={12} md={12} className="radio-button-alignment mt-1">
                            <label
                              style={{
                                fontFamily: 'National',
                                fontSize: '15px',
                                color: '#363636',
                              }}
                              for={item.id}
                            >
                              {item.name}
                            </label>
                            <span className="radio-icon-button">
                              <input
                                type="radio"
                                id={item.id}
                                name={'compare-indicies'}
                                value={item.slug}
                                checked={item?.slug === compareParam?.slug}
                                onChange={() => handleSelectedCompareIndices(item)}
                                onClick={() => {
                                  handleCheckCompareIndices(item);
                                }}
                              />
                              <i
                                className={
                                  item?.slug === 'composite_crop_health_index'
                                    ? 'cursor-ponter fa fa-info info-icon-edit-btn crop-index-i-button'
                                    : // : item?.sensor_name === 'GLDAS'
                                      // ? 'cursor-ponter fa fa-info info-icon-edit-btn i-button others-icon'
                                      'cursor-ponter fa fa-info info-icon-edit-btn i-button'
                                }
                                id="info-icon-tool-tip"
                                data-tip
                                data-for="info-icon-tool-tip"
                                onClick={(e) => handleInfo(e, item)}
                              />
                              <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                                Click here to know more.
                              </ReactTooltip>
                            </span>
                          </Col>
                        </Label>
                        {compareCropWiseProRenderState &&
                          item?.slug === 'cwp' &&
                          handleCompareCropWiseProduction()}
                      </>
                    );
                  })}
                </Row>
              </FormGroup>
            </>
          );
        })}
      </>
    );
  };
  const handleLocationRender = () => {
    return (
      <div className="advance-search-fields mb-3">
        <div className="mx-3 mt-3 mb-3">
          <Select
            id="multi-select"
            name="country"
            placeholder="select country"
            // options={renderAPIParameterOptions(countryList)}
            onChange={handleSelectedItem}
            isMulti={false}
            value={selectedLocation.country}
            defaultValue={selectedLocation.country}
            required={true}
            isDisabled={true}
          />
          {country && (
            <Select
              id="multi-select"
              name="state"
              className="mt-3 mb-2"
              placeholder="Select Province"
              options={renderAPIParameterOptions(stateList)}
              onChange={handleSelectedItem}
              isMulti={false}
              required={true}
              value={state}
            />
          )}
          {state && state.value !== 'Sri Lanka' && (
            <Select
              id="multi-select"
              className="mt-3"
              name="district"
              placeholder="Select District"
              options={renderAPIDistrictParameterOptions(districtList)}
              onChange={handleSelectedItem}
              isMulti={false}
              required={true}
              value={selectedDistrict}
            />
          )}
        </div>
      </div>
    );
  };
  const handleAgriculturerCompare = () => {
    return (
      <div className="advance-search-fields mb-3">
        <div className="mx-3 mt-2 mb-3">
          <span>
            <FormGroup check>
              <Row className="compare-style">
                {isCompare?.map((item) => {
                  return (
                    <>
                      <Col sm={4} lg={4} md={4} className="mt-2">
                        <Label check>
                          <Input
                            type="radio"
                            name="radio1"
                            value={item.value}
                            checked={item.selected}
                            onChange={handleCompareToggle}
                          />{' '}
                          {item.value}
                        </Label>
                      </Col>{' '}
                    </>
                  );
                })}
              </Row>
            </FormGroup>
          </span>
        </div>
      </div>
    );
  };
  const renderCompareDates = (isCompare) => {
    let isGraphCompare = isCompare?.find((item) => item.name === 'graph');
    if (!isGraphCompare?.selected) {
      return (
        <>
          <div className="mx-3 mt-2 mb-3">
            <Input
              type="date"
              placeholder="Start date"
              id="compareStartDate"
              bsSize="sm"
              className="mt-3 my-3 input-style"
              value={finalSelectedDate.startDate}
              onChange={handleFinalStartDate}
              min={compareParam?.min_date}
              // max={finalSelectedDate.maxStartDate}
              max={finalSelectedDate.maxEndDate}
              disabled={_.isEmpty(compareParam)}
              invalid={
                new Date(compareParam?.min_date) > new Date(finalSelectedDate.startDate) ||
                new Date(finalSelectedDate.maxStartDate) < new Date(finalSelectedDate.startDate) ||
                finalSelectedDate.startDate === 'Invalid date'
              }
            />
            {!compareError?.showError &&
              (new Date(compareParam?.min_date) > new Date(finalSelectedDate.startDate) ||
                new Date(finalSelectedDate.maxStartDate) < new Date(finalSelectedDate.startDate) ||
                finalSelectedDate.startDate === 'Invalid date') && (
                // document.getElementById('compareStartDate')?.getAttribute('aria-invalid') ===
                // 'true'
                <FormFeedback invalid>Invalid date</FormFeedback>
              )}
            <Input
              type="date"
              placeholder="End date"
              id="compareEndDate"
              bsSize="sm"
              className="mt-1 my-3 input-style"
              value={finalSelectedDate.endDate}
              onChange={handleFinalEndDate}
              min={finalSelectedDate?.minEndDate}
              // max={maxEndDate}
              max={finalSelectedDate.maxEndDate}
              disabled={_.isEmpty(compareParam)}
              invalid={
                new Date(finalSelectedDate?.minEndDate) > new Date(finalSelectedDate.endDate) ||
                new Date(finalSelectedDate.maxEndDate) < new Date(finalSelectedDate?.endDate) ||
                finalSelectedDate?.endDate === 'Invalid date' ||
                compareError?.showError
              }
            />
            {/* {compareError?.showError && (
            <div className="drought-monitor-error-msg">
              <span style={{ float: 'left' }}>{compareError?.msg}</span>
            </div>
          )} */}
            {(new Date(finalSelectedDate?.minEndDate) > new Date(finalSelectedDate.endDate) ||
              new Date(finalSelectedDate.maxEndDate) < new Date(finalSelectedDate?.endDate) ||
              finalSelectedDate?.endDate === 'Invalid date' ||
              compareError?.showError) && (
              // document.getElementById('compareEndDate')?.getAttribute('aria-invalid') === 'true'
              <FormFeedback invalid>
                {compareError?.msg ? compareError?.msg : 'Invalid date'}
              </FormFeedback>
            )}
          </div>
        </>
      );
    }
  };
  const handleCompareMinMaxRender = () => {
    return (
      <div className="mt-3 temp-style">
        <div className="mt-2  mx-2">
          <Row>
            <Col className="mx-2">
              {' '}
              <Input
                type="number"
                placeholder="Min."
                bsSize="sm"
                className="mt-1 mb-3 p-2 temp-input"
                value={compareTempState.min || ''}
                disabled={_.isEmpty(compareParam)}
                onChange={(e) => {
                  setCompareTempState((prev) => {
                    return {
                      ...prev,
                      min: e.target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col className="mx-2">
              <Input
                type="number"
                placeholder="Max."
                bsSize="sm"
                className="mt-1 mb-3 p-2 temp-input"
                value={compareTempState.max || ''}
                disabled={_.isEmpty(compareParam)}
                onChange={(e) => {
                  setCompareTempState((prev) => {
                    return {
                      ...prev,
                      max: e.target.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  const handleDateTimeRender = () => {
    return (
      <div className="advance-search-fields mb-3">
        <div className="mx-3 mt-2 mb-3">
          <Input
            type="date"
            name="startDate"
            placeholder="Start date"
            bsSize="sm"
            id="startDate"
            className="my-3 input-style"
            value={initialSelectedDate.startDate}
            onChange={(e) => handleStartDate(e)}
            min={initialSelectedDate.minStartDate}
            // max={initialSelectedDate.maxStartDate}
            max={initialSelectedDate.maxEndDate}
            disabled={_.isEmpty(selectedParam)}
            invalid={
              new Date(initialSelectedDate.minStartDate) >
                new Date(initialSelectedDate?.startDate) ||
              new Date(initialSelectedDate.maxStartDate) <
                new Date(initialSelectedDate?.startDate) ||
              initialSelectedDate?.startDate === 'Invalid date'
            }
          />

          {!error &&
            (new Date(initialSelectedDate.minStartDate) >
              new Date(initialSelectedDate?.startDate) ||
              new Date(initialSelectedDate.maxStartDate) <
                new Date(initialSelectedDate?.startDate) ||
              initialSelectedDate?.startDate === 'Invalid date') && (
              // document.getElementById('startDate')?.getAttribute('aria-invalid') === 'true'
              <FormFeedback invalid>Invalid date</FormFeedback>
            )}
          <Input
            type="date"
            name="endDate"
            id="endDate"
            placeholder="End date"
            bsSize="sm"
            className="my-2 input-style"
            value={initialSelectedDate.endDate}
            onChange={(e) => handleEndDate(e)}
            min={initialSelectedDate.startDate}
            max={initialSelectedDate.maxEndDate}
            disabled={_.isEmpty(selectedParam)}
            invalid={
              new Date(initialSelectedDate.minEndDate) > new Date(initialSelectedDate?.endDate) ||
              new Date(initialSelectedDate.maxEndDate) < new Date(initialSelectedDate?.endDate) ||
              initialSelectedDate?.endDate === 'Invalid date' ||
              error
            }
          />

          {(new Date(initialSelectedDate.minEndDate) > new Date(initialSelectedDate?.endDate) ||
            new Date(initialSelectedDate.maxEndDate) < new Date(initialSelectedDate?.endDate) ||
            initialSelectedDate?.endDate === 'Invalid date' ||
            error) && (
            //  document.getElementById('endDate')?.getAttribute('aria-invalid') === 'true'
            <FormFeedback invalid>{errorMessage ? errorMessage : 'Invalid date'}</FormFeedback>
          )}
        </div>
      </div>
    );
  };

  const handleMinMaxRender = () => {
    return (
      <div className="mt-3 temp-style">
        <div className="mt-2  mx-2">
          <Row>
            <Col className="mx-2">
              {' '}
              <Input
                type="number"
                placeholder="Min."
                bsSize="sm"
                id="minValue"
                className="mt-1 mb-3 p-2 temp-input"
                value={tempState.min || ''}
                disabled={_.isEmpty(selectedParam)}
                // min={2}
                // max={5}
                // invalid = {(tempState?.min !== null && (tempState?.min< 2 || tempState?.min> 5 ))}
                onChange={(e) => {
                  setTempState((prev) => {
                    return {
                      ...prev,
                      min: e.target.value,
                    };
                  });
                }}
              />
              {/* {(tempState?.min !== null && (tempState?.min< 2 || tempState?.min> 5 )) ? 
            <FormFeedback invalid>{'Invalid date'}</FormFeedback> : "" } */}
              {/* {(tempState?.min !== null && (tempState?.min<2)) ? 
            <FormFeedback >{`min value should not be less than 2`}</FormFeedback> : 
            (tempState?.min !== null && (tempState?.min>5)) ? 
            <FormFeedback >{`min value should not be greater than 5`}</FormFeedback> : 
            (tempState?.min === null) ? 
            <FormFeedback >{`Invalid min`}</FormFeedback> : "" } */}
            </Col>
            <Col className="mx-2">
              <Input
                type="number"
                placeholder="Max."
                bsSize="sm"
                id="maxValue"
                className="mt-1 mb-3 p-2 temp-input"
                value={tempState.max || ''}
                // min={2}
                // max={5}
                // invalid = {(tempState?.max !== null && (tempState?.max< 2 || tempState?.max> 5 ))}
                disabled={_.isEmpty(selectedParam)}
                onChange={(e) => {
                  setTempState((prev) => {
                    return {
                      ...prev,
                      max: e.target.value,
                    };
                  });
                }}
              />
              {/* {(tempState?.max !== null && (tempState?.max< 2 || tempState?.max> 5 )) ? 
            <FormFeedback invalid>{'Invalid date'}</FormFeedback> : "" } */}
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  const FirstTab = () => {
    return (
      <div className="FirstTab mt-3">
        <span onClick={() => handleCateogryToggle('agriculture-sensors')}>
          <p
            className="agriculture-headings-lable"
            //style={checkboxStyle}
          >
            Sensors
            <span className="category-span">
              <span className="category-span">
                {openCollapse.agriculture_sensors ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="agriculture_sensors"
          isOpen={openCollapse.agriculture_sensors}
          className="collapse-moadal"
        >
          {handleTab1SensorsList(sensorsList)}
        </Collapse>

        {categoryWiseData?.length ? (
          <>
            <span onClick={() => handleCateogryToggle('agriculture-indicators')}>
              <p
                className="agriculture-headings-lable"
                //style={checkboxStyle}
              >
                {/* {item.category} */}
                Indicators
                <span className="category-span">
                  <span className="category-span">
                    {openCollapse.agriculture_indicators ? (
                      <AiOutlineCaretUp size={13} />
                    ) : (
                      <AiOutlineCaretDown size={13} />
                    )}
                  </span>
                </span>
              </p>
            </span>
            <Collapse
              id="agriculture_indicators"
              isOpen={openCollapse.agriculture_indicators}
              className="collapse-moadal"
            >
              {handleRenderCategoryWiseData()}
            </Collapse>
          </>
        ) : (
          ''
        )}
      </div>
    );
  };

  const handleTab2IndicatorsList = (indicatorsList) => {
    return (
      <>
        {indicatorsList?.length &&
          indicatorsList?.map((i) => {
            return (
              <>
                <p className="indicators-headers">{i?.category}</p>
                <FormGroup check>
                  <Row>
                    {i?.data?.map((item) => {
                      return (
                        <>
                          <Col sm={12} lg={12} md={12} className="radio-button-alignment mt-1">
                            <label
                              style={{
                                fontFamily: 'National',
                                fontSize: '15px',
                                color: '#363636',
                              }}
                              for={item.name}
                            >
                              {item.name}
                            </label>
                            <span className="radio-icon-button">
                              <input
                                type="radio"
                                id={item.name}
                                name={'indicies'}
                                value={item.slug}
                                checked={item?.name === selectedIndicatorsParam?.name}
                                onChange={() => handleSelectedIndicators(item)}
                                onClick={() => {
                                  handleCheckIndices(item);
                                }}
                              />
                              <i
                                className={
                                  item?.data[0]?.slug === 'composite_crop_health_index'
                                    ? 'cursor-ponter fa fa-info info-icon-edit-btn crop-index-i-button'
                                    : // : item?.data[0]?.sensor_name === 'GLDAS'
                                      // ? 'cursor-ponter fa fa-info info-icon-edit-btn i-button others-icon'
                                      'cursor-ponter fa fa-info info-icon-edit-btn i-button'
                                }
                                id="info-icon-tool-tip"
                                data-tip
                                data-for="info-icon-tool-tip"
                                onClick={(e) => handleInfo(e, item)}
                              />
                              <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                                Click here to know more.
                              </ReactTooltip>
                            </span>
                          </Col>
                          {/* {cropWiseProRenderState &&
                            item?.data[0]?.slug === 'cwp' &&
                            handleCropWiseProduction()} */}
                        </>
                      );
                    })}
                  </Row>
                </FormGroup>
              </>
            );
          })}
      </>
    );
  };

  const handleTab2SensorsList = (list) => {
    return (
      <FormGroup check>
        <div className="mb-2">
          <Row>
            {list?.length &&
              list?.map((item) => {
                return (
                  <>
                    <Col sm={12} lg={12} md={12} className="mt-1 sensors-radios-buttons mt-1">
                      <label
                        style={{
                          fontFamily: 'National',
                          fontSize: '15px',
                          color: '#363636',
                        }}
                        // for={item.sensor_name === 'Others' ? 'No sensor' : item.sensor_name}
                        for={item.sensor_name}
                      >
                        {item.sensor_name}
                        {/* {item.sensor_name === 'Others' ? 'No sensor' : item.sensor_name} */}
                      </label>
                      <span className="radio-icon-button">
                        <input
                          type="radio"
                          id={item.sensor_name}
                          name="radio-button"
                          className=""
                          value={item.sensor_name}
                          // checked={item?.slug === 'cwp'? true : item?.slug === selectedParam?.slug}
                          checked={item?.slug === selectedParam?.slug}
                          // checked={item?.slug === selectedParam?.slug}
                          onChange={() => {
                            handleSelectedIndices(item);
                          }}
                        />
                        <i
                          className={
                            item?.sensor_name == 'Others'
                              ? 'cursor-ponter fa fa-info info-icon-edit-btn i-button others-icon'
                              : 'cursor-ponter fa fa-info info-icon-edit-btn i-button'
                          }
                          // className="cursor-ponter fa fa-info info-icon-edit-btn i-button"
                          id="info-icon-tool-tip"
                          data-tip
                          data-for="info-icon-tool-tip"
                          onClick={(e) => handleIndicatorToSensorsInfo(e, item)}
                        />
                        <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                          Click here to know more.
                        </ReactTooltip>
                      </span>
                    </Col>
                  </>
                );
              })}
          </Row>
        </div>
      </FormGroup>
    );
  };
  const SecondTab = () => {
    return (
      <div className="FirstTab mt-3">
        <span onClick={() => handleCateogryToggle('agriculture-tab2-indicators')}>
          <p
            className="agriculture-headings-lable"
            //style={checkboxStyle}
          >
            Indicators
            <span className="category-span">
              <span className="category-span">
                {openCollapse.agriculture_tab2_indicators ? (
                  <AiOutlineCaretUp size={13} />
                ) : (
                  <AiOutlineCaretDown size={13} />
                )}
              </span>
            </span>
          </p>
        </span>
        <Collapse
          id="agriculture_tab2_indicators"
          isOpen={openCollapse.agriculture_tab2_indicators}
          className="collapse-moadal"
        >
          {handleTab2IndicatorsList(indicatorsList)}
        </Collapse>

        {selectedIndicatorToSensorList?.length ? (
          <>
            <span onClick={() => handleCateogryToggle('agriculture-tab2-sensors')}>
              <p
                className="agriculture-headings-lable"
                //style={checkboxStyle}
              >
                Sensors
                <span className="category-span">
                  <span className="category-span">
                    {openCollapse.agriculture_tab2_sensors ? (
                      <AiOutlineCaretUp size={13} />
                    ) : (
                      <AiOutlineCaretDown size={13} />
                    )}
                  </span>
                </span>
              </p>
            </span>
            <Collapse
              id="agriculture_tab2_sensors"
              isOpen={openCollapse.agriculture_tab2_sensors}
              className="collapse-moadal"
            >
              {handleTab2SensorsList(selectedIndicatorToSensorList)}
            </Collapse>
          </>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <div className="forecast-monitoring">
      <>
        <div className="advance-search-fields">
          <div className="data-source-field-one">
            <span onClick={() => handleCateogryToggle('agriculture-location')}>
              <p
                className="agriculture-headings-lable"
                //style={checkboxStyle}
              >
                Location
                <span className="category-span">
                  <span className="category-span">
                    {openCollapse.agriculture_location ? (
                      <AiOutlineCaretUp size={13} />
                    ) : (
                      <AiOutlineCaretDown size={13} />
                    )}
                  </span>
                </span>
              </p>
            </span>
            <Collapse
              id="agriculture_location"
              isOpen={openCollapse.agriculture_location}
              className="collapse-moadal"
            >
              {handleLocationRender()}
            </Collapse>

            <ul className="navtabs-switch">
              <li
                className={activeTabSwitch === 'tab1' ? 'active tabButton1' : 'tabButton2'}
                onClick={handleTab1}
              >
                Sensors
              </li>
              <li
                className={activeTabSwitch === 'tab2' ? 'active tabButton1' : 'tabButton2'}
                onClick={handleTab2}
              >
                Indicators
              </li>
            </ul>
            <div className="outlet">{activeTabSwitch === 'tab1' ? FirstTab() : SecondTab()}</div>

            {selectedParam?.slug === 'cwp' ? (
              <>
                {activeTabSwitch === 'tab1'
                  ? ''
                  : cropWiseProRenderState && handleCropWiseProduction()}
              </>
            ) : (
              <>
                <span onClick={() => handleCateogryToggle('agriculture-datetime')}>
                  <p
                    className="agriculture-headings-lable"
                    //style={checkboxStyle}
                  >
                    Timeframe & Range
                    <span className="category-span">
                      <span className="category-span">
                        {openCollapse.agriculture_datetime ? (
                          <AiOutlineCaretUp size={13} />
                        ) : (
                          <AiOutlineCaretDown size={13} />
                        )}
                      </span>
                    </span>
                  </p>
                </span>
                <Collapse
                  id="agriculture_datetime"
                  isOpen={openCollapse.agriculture_datetime}
                  className="collapse-moadal"
                >
                  {handleDateTimeRender()}
                  {!CROP_MONITOR_MIN_MAX_HIDE_INDICATORS_SLUG?.includes(selectedParam?.slug) &&
                    handleMinMaxRender()}
                  <div className="mx-3 mb-2">
                    {minError ? (
                      <FormFeedback invalid>
                        {t('DROUGHT_MONITORING.DROUGHT_MIN_MAX_ERROR_MESSAGE')}
                      </FormFeedback>
                    ) : (
                      ''
                    )}
                  </div>
                </Collapse>
              </>
            )}
            <>
              <div
                className={!selectedLocation?.region?.length ? 'enable-compare' : 'disable-compare'}
              >
                <span onClick={() => handleCateogryToggle('agriculture-compare')}>
                  <p
                    className={
                      selectedLocation?.region?.length
                        ? 'compare-disabled agriculture-headings-lable'
                        : 'agriculture-headings-lable'
                    }
                    //style={checkboxStyle}
                  >
                    Compare
                    <span className="category-span">
                      <span className="category-span">
                        {openCollapse.agriculture_compare && !selectedLocation?.region?.length ? (
                          <BiMinus size={25} />
                        ) : (
                          <BiPlus size={25} />
                        )}
                      </span>
                    </span>
                  </p>
                </span>
                <Collapse
                  id="agriculture_compare"
                  isOpen={openCollapse.agriculture_compare && !selectedLocation?.region?.length}
                  className="collapse-moadal pb-2"
                  style={{ background: 'rgba(221, 145, 3, 0.2)' }}
                >
                  {handleAgriculturerCompare()}
                  {handleCompareLocationRender()}
                  {activeTabSwitch === 'tab1'
                    ? handleCompareTab1Indices()
                    : handleCompareTab2Indices()}
                  {compareParam?.slug === 'cwp' ? (
                    <>
                      {activeTabSwitch === 'tab1'
                        ? ''
                        : compareCropWiseProRenderState && handleCompareCropWiseProduction()}
                    </>
                  ) : (
                    renderCompareDates(isCompare)
                  )}
                  {!CROP_MONITOR_MIN_MAX_HIDE_INDICATORS_SLUG?.includes(compareParam?.slug) &&
                    handleCompareMinMaxRender()}
                  {/* {handleCompareMinMaxRender()} */}
                  <div className="mx-3 mb-2">
                    {compareMinError ? (
                      <FormFeedback invalid>
                        {t('DROUGHT_MONITORING.DROUGHT_MIN_MAX_ERROR_MESSAGE')}
                      </FormFeedback>
                    ) : (
                      ''
                    )}
                  </div>
                </Collapse>
              </div>
            </>
            {temCwpState ? (
              ''
            ) : (
              <div className="mt-3 temp-style">
                <div className="mt-2 mb-3 mx-3">
                  <Select
                    id="multi-select"
                    name="aggregation"
                    className="mt-2 mb-3"
                    maxMenuHeight={100}
                    menuPlacement="auto"
                    placeholder="Temporal Aggregation"
                    options={TEMPORAL_AGGREGATION}
                    value={temporalAggregation}
                    onChange={handleTemporalAggregation}
                    isMulti={false}
                    required={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
};
export default Agriculture;